import React, { useRef, useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import SidebarElement from "../SidebarElement";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { AiOutlinePlus } from "react-icons/ai";
import Word, { DetectorElement, NewDetector, NewWord } from "./Word";
import { useAuth } from "../../auth/AuthContext";
import {
  getCategoryColorComp,
  getCategoryColor,
  getWordByRank,
  getWordByWord,
} from "../../grammar";
import gg from "../../grammar";
import { STATES, StateButton } from "../../util/StateButton";
import {
  IoSaveOutline,
  IoSearchOutline,
  IoCloseOutline,
  IoTrashBinOutline,
  IoSave,
  IoCloudUploadOutline,
  IoArrowRedoOutline,
  IoAddOutline,
} from "react-icons/io5";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WordElement from "./Word";
import EditorNavbar from "../EditorNavbar";
import { Dialog } from "@headlessui/react";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

// local database for words and detectors
// us synchronized with the sever on save

let allDetectorWords = [];
let allWords = [];

export default function Words() {
  const { userConfig } = useAuth();

  // word import dialog
  let [isOpen, setIsOpen] = useState(false);

  const [loadingWords, setLoadingWords] = useState(true);
  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const [safeDetectorState, setSafeDetecotrState] = useState(
    STATES.STATE_NORMAL
  );

  // words and detectors that are displayed
  const [detectors, setDetectors] = useState([]);
  const [words, setWords] = useState([]);

  const searchRef = useRef(null);

  const [searchStr, setSearchStr] = useState("");
  const [editWordIndex, setEditWordIndex] = useState(-1);
  const [editDetectorIndex, setEditDetectorIndex] = useState(-1);
  const [firstElement, setFirstElement] = useState({});

  // set local database once words and detectors are loaded
  // display everything by putting it in the hook
  useEffect(() => {
    async function fetchData() {
      const data = await getDoc(
        doc(getFirestore(), "words", userConfig.target_lang)
      );
      allWords = data.data().wRank;
      setWords(allWords);
      const wData = await getDoc(
        doc(getFirestore(), "words", userConfig.target_lang + "-d")
      );
      const wData1 = await getDoc(
        doc(getFirestore(), "words", userConfig.target_lang + "-d1")
      );
      // modify the detecor words to have an id that corresponds to the index in
      // the local db so we can reference to it from the sorted state
      let detectorWordsPre = [...wData.data().words, ...wData1.data().words];
      detectorWordsPre.forEach((item, index) => {
        item.id = index;
        detectorWordsPre[index] = item;
      });
      allDetectorWords = detectorWordsPre;
      setDetectors(allDetectorWords.slice(0, 100)); // limit to 100 entries in list
      setLoadingWords(false);
    }
    fetchData();
  }, [userConfig]);

  function searchChange(srStr) {
    if (srStr.length === 0) {
      stopSearch();
    } else {
      let filteredWords = filterWords(srStr);
      let firstElement = filteredWords[0];
      let filteredDetectorWords = filterDetectorWords(firstElement);
      setFirstElement(firstElement);
      setSearchStr(srStr);
      setDetectors(filteredDetectorWords);
      setWords(filteredWords);
    }
  }

  function filterWords(srStr) {
    let res = [
      ...allWords.filter((item) => {
        if (srStr.length > 0) {
          if (item.RA == srStr) {
            return true;
          } else if (item.R === srStr) {
            return true;
          } else if (item.T === srStr) {
            return true;
          } else if (item.E.includes(srStr)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
    ];

    return res;
  }

  // get the detector words for a word
  function filterDetectorWords(word) {
    let filter = allDetectorWords.filter((item) => {
      if (item.O === word.R) {
        return true;
      } else {
        return false;
      }
    });
    return [...filter];
  }

  function stopSearch() {
    searchRef.current.value = "";
    setSearchStr("");
    setFirstElement({});
    setWords([...allWords]);
    setDetectors(allDetectorWords.slice(0, 100));
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const t1 = reorder(allWords, result.source.index, result.destination.index);
    allWords = t1;
    setWords(allWords);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  async function save() {
    setSafeState(STATES.STATE_PROCESS);
    let saveWords = applyRank(words);
    await setDoc(doc(getFirestore(), "words", userConfig.target_lang), {
      wRank: saveWords,
    });

    setWords(saveWords);

    setSafeState(STATES.STATE_NORMAL);
  }

  async function saveDetectors() {
    setSafeDetecotrState(STATES.STATE_PROCESS);

    //allDetectorWords = [...allDetectorWords,...df];

    if (allDetectorWords.length > 18000) {
      await setDoc(
        doc(getFirestore(), "words", userConfig.target_lang + "-d"),
        {
          words: allDetectorWords.slice(0, 18000),
        }
      );
      await setDoc(
        doc(getFirestore(), "words", userConfig.target_lang + "-d1"),
        {
          words: allDetectorWords.slice(18000, allDetectorWords.length), //allDetectorWords,
        }
      );
    } else {
      await setDoc(
        doc(getFirestore(), "words", userConfig.target_lang + "-d"),
        {
          words: allDetectorWords,
        }
      );
    }

    setSafeDetecotrState(STATES.STATE_NORMAL);
  }

  function applyRank() {
    let ret = [];
    allWords.forEach((item, i) => {
      ret.push({
        RA: i,
        R: item.R,
        E: item.E,
        T: item.T,
        G: item.G,
        A: item.A,
      });
    });
    return ret;
  }

  function newWord(info) {
    let t1 = [...allWords];
    if (info.RA === "-") {
      info.RA = allWords.length;
    }
    t1.splice(info.RA, 0, info);
    allWords = t1;
    setWords(allWords);
  }

  function newDetector(info) {
    info.O = firstElement.R;
    info.id = allDetectorWords.length + 100;
    let t1 = [...allDetectorWords];
    t1.push(info);
    allDetectorWords = t1;
    if (Object.keys(firstElement).length !== 0) {
      let filteredDetectorWords = filterDetectorWords(firstElement);
      setDetectors(filteredDetectorWords);
    } else {
      setDetectors(allDetectorWords.slice(0, 100));
    }
  }

  function deleteDetector(id) {
    let t1 = [...allDetectorWords];
    t1.splice(id, 1);
    allDetectorWords = t1;
    if (Object.keys(firstElement).length !== 0) {
      let filteredDetectorWords = filterDetectorWords(firstElement);
      setDetectors(filteredDetectorWords);
    } else {
      setDetectors(allDetectorWords.slice(0, 100));
    }
  }

  function deleteWord(index) {
    let t1 = [...allWords];
    delete t1[index];
    allWords = t1;
    setWords(allWords);
  }

  function editWord(id, info) {
    let t1 = [...allWords];
    t1[id] = info;
    allWords = t1;
    setWords(allWords);
    setEditWordIndex(-1);
  }

  function editDetector(info) {
    let t1 = [...allDetectorWords];
    info.O = t1[info.id].O;
    t1[info.id] = info;
    allDetectorWords = t1;
    if (Object.keys(firstElement).length !== 0) {
      let filteredDetectorWords = filterDetectorWords(firstElement);
      setDetectors(filteredDetectorWords);
    } else {
      setDetectors(allDetectorWords.slice(0, 100));
    }

    setEditDetectorIndex(-1);
  }

  function addWordData() {
    let ret = [];
    allWords.forEach((item, i) => {
      let info = getWordByWord(item.R)[0];
      if (info) {
        ret.push({
          R: item.R,
          RA: item.RA,
          E: item.E,
          T: item.T,
          A: info.A,
          G: info.G,
        });
      } else {
        ret.push({
          R: item.R,
          RA: item.RA,
          E: item.E,
          T: item.T,
          A: "-",
          G: "-",
        });
      }
    });

    return ret;
  }

  function changeWordStructure() {
    let ret = [];
    //gg.words.forEach((item) => {
    //  ret.push({R:item.R,T:item.T,O:item.O});
    //});
    return ret;
  }

  function addNewWords(val) {
    let t1 = [...allWords];

    val.forEach((item) => {
      if (item.RA === "-" || item.RA > allWords.length) {
        item.RA = allWords.length;
      }
      t1.splice(item.RA, 0, item);
      
    });

    allWords = t1;
    setWords(allWords);
    console.log("raWords", val);
  }

  function addNewDetectors(val){
    let t1 = [...allDetectorWords];
    val.forEach((item) => {
      item.id = allDetectorWords.length + 100;
      t1.push(item);
    });
    
    allDetectorWords = t1;

    if (Object.keys(firstElement).length !== 0) {
      let filteredDetectorWords = filterDetectorWords(firstElement);
      setDetectors(filteredDetectorWords);
    } else {
      setDetectors(allDetectorWords.slice(0, 100));
    }
  }

  return (
    <div className="flex flex-col w-full flex-1 overflow-hidden dark:bg-slate-900 text-slate-500 dark:text-slate-200">
      <ImportWordsDialog
        isOpen={isOpen}
        setIsOpen={(val) => {
          setIsOpen(val);
        }}
        onDWords={addNewDetectors}
        onRWords={addNewWords}
      ></ImportWordsDialog>
      <div className="flex flex-row">
        <EditorNavbar />
        <div className="h-16 flex flex-row w-full items-center  border-slate-200 dark:border-slate-800 border-b p-2 justify-end h-16">
          <div className="flex flex-row items-center basis-1/3 border rounded-full bg-white border-slate-200 dark:dark:bg-slate-800 dark:border-slate-800">
            <input
              onChange={(event) => {
                searchChange(event.target.value);
              }}
              type="text"
              className="flex flex-1 rounded-full bg-white dark:bg-slate-800 outline-none focus:outline-none p-2"
              ref={searchRef}
            ></input>
            {searchStr.length > 0 ? (
              <IoCloseOutline
                onClick={stopSearch}
                className="cursor-pointer flex  dark:bg-slate-800 h-6 w-6 mr-3"
              />
            ) : (
              <IoSearchOutline className="flex h-6 w-6 mr-3" />
            )}
          </div>

          <div className="flex flex-row justify-end items-center">
            <IoCloudUploadOutline
              onClick={() => {
                setIsOpen(true);
              }}
              className="cursor-pointer mx-4 h-6 w-6 "
            />
            <StateButton
              className="mx-4 "
              size="6"
              onClick={save}
              state={safeState}
            >
              <IoSaveOutline className="h-6 w-6 " />
            </StateButton>
            <StateButton
              className="mx-4"
              size="6"
              onClick={saveDetectors}
              state={safeDetectorState}
            >
              <IoSave className="h-6 w-6 " />
            </StateButton>
          </div>
        </div>
      </div>

      {!loadingWords && (
        <div className="flex flex-row flex-1 overflow-hidden">
          <div className="overflow-auto flex-1">
            {searchStr.length === 0 ? (
              <NewWord newWord={(info) => newWord(info)} />
            ) : (
              <div></div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {words.map((s, index) => (
                      <Draggable
                        key={index + "id"}
                        draggableId={index + "id"}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <WordElement
                              s={s}
                              safe={(info) => {
                                editWord(s.RA, info);
                              }}
                              edit={() => {
                                setEditWordIndex(index);
                              }}
                              editState={editWordIndex === index}
                              deleteWord={(id) => {
                                deleteWord(id);
                              }}
                              search={() => {
                                searchRef.current.value = s.R;
                                searchChange(s.R);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="overflow-auto border-l">
            {Object.keys(firstElement).length !== 0 ? (
              <NewDetector newDetector={(info) => newDetector(info)} />
            ) : (
              ""
            )}
            {detectors.map((s) => (
              <DetectorElement
                s={s}
                key={s.id + "detector"}
                safe={(info) => {
                  editDetector(info);
                }}
                edit={(id) => {
                  setEditDetectorIndex(id);
                }}
                editState={editDetectorIndex === s.id}
                deleteWord={(id) => {
                  deleteDetector(id);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function ImportWordsDialog({ isOpen, setIsOpen, onRWords, onDWords }) {
  const [json, setJson] = useState("");

  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const [words, setWords] = useState([]);

  useEffect(() => {}, []);

  const handleJsonChange = (event) => {
    // 👇️ access textarea value
    setJson(event.target.value);
  };

  function transform() {
    setWords(JSON.parse(json));
  }

  function imp() {
    setIsOpen(false);
    if (words.length > 0) {
      if (Object.keys(words[0]).length > 4) {
        onRWords(words);
      } else {
        onDWords(words);
      }
    }
  }

  function clear() {
    setJson("");
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div
        className="fixed inset-0 flex flex-col items-center justify-center p-8 inset-0 bg-black/30 z-50"
        aria-hidden="true"
      >
        <Dialog.Panel className="flex flex-col h-full w-full rounded bg-white rounded bg-white rounded-xl z-50">
          <Dialog.Title className="flex flex-row text-xl rounded-t p-4 justify-between content-center font-bold bg-sky-700 text-white">
            <div className="flex flex-row content-center">
              <IoCloudUploadOutline className=" mr-2 h-6 w-6" />
              <p>Import words</p>
            </div>
            <IoCloseOutline
              onClick={() => {
                setIsOpen(false);
              }}
              className="cursor-pointer h-6 w-6"
            />
          </Dialog.Title>
          <div className="flex flex-row w-full border-b">
            <div className="flex flex-1 justify-end">
              <IoTrashBinOutline
                onClick={clear}
                className="cursor-pointer text-slate-500 m-4 h-6 w-6 "
              />
              <IoArrowRedoOutline
                onClick={transform}
                className="cursor-pointer text-slate-500 m-4 h-6 w-6 "
              />
            </div>
            <div className="flex flex-1 justify-end">
              <StateButton
                className="m-4 text-slate-500"
                size="6"
                onClick={() => {}}
                state={safeState}
              >
                <IoCloudUploadOutline className="h-6 w-6 " onClick={imp} />
              </StateButton>
            </div>
          </div>
          <div className="flex flex-row flex-1 overflow-hidden">
            <textarea
              className="flex flex-1 flex-col bg-sky-100 outline-none border-r p-2"
              value={json}
              onChange={handleJsonChange}
            ></textarea>
            <div className="flex flex-1 flex-col overflow-auto">
              <table className="flex-1 table-auto">
                <tbody className="">
                  {words.map((s) => (
                    <tr className="">
                      <td className="">{s.RA}</td>
                      <td className="">{s.O}</td>
                      <td className="font-bold">{s.R}</td>
                      <td className="">{s.T}</td>
                      <td className="">{s.A}</td>
                      <td className="">{s.G}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
