import { Disclosure, Popover } from '@headlessui/react'
import { ReactComponent as UserIcon } from '../svg/user.svg';
import {IoExtensionPuzzleOutline, 
  IoLogOutOutline,
   IoSettingsOutline,
    IoGolfOutline ,
     IoBookOutline,
      IoFlagOutline,
      IoChevronDown,
       IoTextOutline, 
       IoPeopleOutline} from 'react-icons/io5';
import React, {useState, useEffect} from 'react';
import { doc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { getAuth } from "firebase/auth"; 
import { useAuth } from '../auth/AuthContext';
import {Link, useNavigate } from 'react-router-dom';
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { createCheckoutSession } from '../stripe/createCheckoutSession';
import SidebarElement from '../editor/SidebarElement';

function DropDownItem(props){
    return (
        <Link to={props.link}>
        <div className="whitespace-nowrap cursor-pointer flex flex-row p-4 pr-10 align-center rounded-l hover:bg-slate-100 dark:hover:bg-slate-900">
         {props.children}
        <p>{props.name}</p>
      </div>
      </Link>
    )
}

function StoryItem(props){
  return (
      <Popover.Button onClick={props.click} className="flex w-full flex-row justify-between whitespace-nowrap cursor-pointer flex  flex-row p-4 pr-10 align-center rounded-l hover:bg-slate-100 dark:hover:bg-slate-900">
       {props.children} {props.read?<IoCheckmarkCircleOutline className="h-6 w-6 text-sky-400"></IoCheckmarkCircleOutline>:""}

    </Popover.Button>
  )
}

export function QuizDropDown({storySelected, levelSelected, settings}) {
  const [stories, setStories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);


  const {logout, currentUserState, currentUser, userConfig} = useAuth();

  const navigate = useNavigate();

  function l(){
    logout();
    navigate('/');
  }

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (document) =>{
        let tempStories = [];
        let data = document.data();

        if(!data.hasOwnProperty("ids")){
          setLoading(false);
          return;
        }
        
        for(let i = 0; i < data.ids.length; i++){
          if(data.states[i] === 1 || currentUserState.admin)
            tempStories.push({"timeStamp": data.timeStamps[i],"level":data.levels[i], "read": false,"id": data.ids[i], "name": data.names[i], "le": i});
        };

        tempStories.sort((a,b) => {
          if(a.level > b.level){
            return 1;
          }else if(a.level === b.level){
            return 0;
          }else{
            return -1;
          }
        })
        
        setStories(tempStories);

        onSnapshot(doc(getFirestore(),"user_config",currentUser.uid+""), (doc) =>{
          let data = doc.data();
          let hasNext = false;
          if(tempStories.length > 0){
            setStories(tempStories.map((s) => {
              let isIn = false;
              let count = 1;
              for(let i = 0; i < data.stories.length; i++){
                if(typeof data.stories[i] === "string"){
                  if(data.stories[i] === s.id){
                    isIn = true;
                  }
                }else{
                  if(data.stories[i].id === s.id){
                    isIn = true;
                    count = data.stories[i].count;
                  }
                }
              }
              if(isIn){
                s.read = true;
                s.count = count
              }else{
                if(!hasNext){
                  hasNext = true;
                }
              }
              return s;
            }));
          }

          setLoading(false);
        });
    });
    if(currentUserState.admin){
      onSnapshot(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
        let tempLevels = [];
        let data = doc.data();
  
        if(!data.hasOwnProperty("ids")){
          setLoading(false);
          return;
        }
        
        for(let i = 0; i < data.ids.length; i++){
          tempLevels.push({"id": data.ids[i], "name": data.names[i], "le": i});
        };
  
        setLevels(tempLevels);
        setLoading(false);
    });
    }
    
  }, [userConfig]);
  return (
    <Popover className="relative z-50">
    <Popover.Button className="focus:outline-none">{currentUser.photoURL?<img src={currentUser.photoURL+""} referrerPolicy="no-referrer" alt="profile" className="h-10 w-10 mt-2 rounded-full" />:<UserIcon className="h-10 w-10 mt-2"></UserIcon>}</Popover.Button>
    <Popover.Panel className="text-slate-500 dark:text-slate-200 w-screen sm:w-fit fixed sm:absolute left-0 shadow rounded bg-white dark:bg-slate-800 mt-2">
      <div className="flex flex-col sm:flex-row">
      {((stories.length>0)) &&<div className="flex flex-col hide-lg">
        <p className="ml-4 mt-2 mb-2 font-bold">Stories</p>
          <hr></hr>
          <div className="max-h-60 overflow-scroll flex flex-col">
  
              {!loading && 

      <>
    <Disclosure defaultOpen={true}>
    {({ open }) => (
      <>
        <Disclosure.Button className="py-2 w-full">
        <div className="flex flex-row items-center justify-between mx-4 rounded text-sky-500 font-bold pl-2 bg-sky-100 dark:bg-slate-900">
          <div>A Level</div>
          <IoChevronDown className={`${open ? 'rotate-180 transform' : ''} m-2 h-4 w-4`}></IoChevronDown>
        </div>
        </Disclosure.Button>
        <Disclosure.Panel>
          {stories.map((temp,i) => (
              <>
              {temp.level < 300?
               <div key={temp.id} onClick={() => {storySelected(temp.id);}}>
               <SidebarElement count={temp.count} read={temp.read} name={temp.name} ></SidebarElement>
           </div>:""}
              </>
            ))}  
        </Disclosure.Panel>
      </>
      )}
    
    </Disclosure> 

    <Disclosure>
    {({ open }) => (
      <>
    <Disclosure.Button className="py-2 w-full">
    <div className="flex flex-row items-center justify-between mx-4 rounded text-sky-500 font-bold pl-2 bg-sky-100 dark:bg-slate-900">
          <div>B Level</div>
          <IoChevronDown className={`${open ? 'rotate-180 transform' : ''} m-2 h-4 w-4`}></IoChevronDown>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel>
      {stories.map((temp,i) => (
          <>
          {temp.level < 600&& temp.level >= 300?
          <div key={temp.id} onClick={() => {storySelected(temp.id);}}>
          <SidebarElement count={temp.count} read={temp.read} name={temp.name} ></SidebarElement>
      </div>:""}
          </>
        ))}     
      </Disclosure.Panel>
      </>
      )}
      </Disclosure>
      <Disclosure>
        {({ open }) => (
      <>
      <Disclosure.Button className="py-2 w-full">
      <div className="flex flex-row items-center justify-between mx-4 rounded text-sky-500 font-bold pl-2 bg-sky-100 dark:bg-slate-900">
          <div>C Level</div>
          <IoChevronDown className={`${open ? 'rotate-180 transform' : ''} m-2 h-4 w-4`}></IoChevronDown>
        </div>
      </Disclosure.Button>
      <Disclosure.Panel>
      {stories.map((temp,i) => (
          <>
          {temp.level >= 600?
          <div key={temp.id} onClick={() => {storySelected(temp.id);}}>
          <SidebarElement count={temp.count} read={temp.read} name={temp.name} ></SidebarElement>
      </div>:""}
          </>
        ))}   
         {stories[stories.length-1].level<600?<div className="ml-4">no stories here</div>:""}         
      </Disclosure.Panel>
      </>
      )}
     
      </Disclosure>
      </>

              }
          </div>
        </div>}
      
        <div className="flex flex-col ">
          {/*userIsUnlimited?
            <div><div className="cursor-pointer inline items-center text-xs rounded-full bg-sky-500 px-1 m-1 text-white">unlimited</div></div>:
            <div className="cursor-pointer flex justify-center items-center rounded-full bg-sky-500 p-2 m-1 text-white"
              onClick={() => createCheckoutSession(user.uid)}>Get Unlimited</div>
            */}
          <div className="hide-sm">
          {currentUserState.admin && <DropDownItem name="Stories" link="/edit/stories"><IoBookOutline className="text-sky-500 h-6 w-6 mr-4"></IoBookOutline></DropDownItem>}
          {currentUserState.admin && <DropDownItem name="Story Dashboard" link="/word_dashboard"><IoGolfOutline className="text-sky-500 h-6 w-6 mr-4"></IoGolfOutline></DropDownItem>}
          {currentUserState.admin && <DropDownItem name="Blocks" link="/edit/blocks"><IoExtensionPuzzleOutline className="text-sky-500 h-6 w-6 mr-4"></IoExtensionPuzzleOutline></DropDownItem>}
          {currentUserState.admin && <DropDownItem name="Flags" link="/flags"><IoFlagOutline className="text-sky-500 h-6 w-6 mr-4"></IoFlagOutline></DropDownItem>}
          {currentUserState.admin && <DropDownItem name="Words" link="/edit/words"><IoTextOutline className="text-sky-500 h-6 w-6 mr-4"></IoTextOutline></DropDownItem>}
          {currentUserState.admin && <DropDownItem name="Users" link="/users"><IoPeopleOutline className="text-sky-500 h-6 w-6 mr-4"></IoPeopleOutline></DropDownItem>}
          </div>
          {currentUserState.admin && <hr className="hidden sm:visible"></hr>}
          {!settings&&<DropDownItem name="Settings" link="/settings"><IoSettingsOutline className="text-sky-500 h-6 w-6 mr-4"></IoSettingsOutline></DropDownItem>}
          <div onClick={l} className="whitespace-nowrap cursor-pointer flex flex-row p-4 pr-10 align-center rounded-l hover:bg-slate-100 dark:hover:bg-slate-900">
            <IoLogOutOutline className="text-sky-500 h-6 w-6 mr-4"></IoLogOutOutline>
            <p>Logout</p>
          </div>
        </div>
      </div>
    </Popover.Panel>
    </Popover>
  )
}