import React, { useState } from "react";
import NavbarPublic from "../navbar/NavbarPublic";

export default function PrivacyPolicy() {
  return (
    <div>
      <NavbarPublic />
      <div className="container">
        <div className="flex  flex-col justify-between  mt-8">
          <a className="font-bold text-sky-700" href="/terms">
            Terms and Conditions
          </a>
          <a className="font-bold text-sky-700" href="/cookie">
            Cookie Policy
          </a>
          <a className="font-bold text-sky-700" href="/company">
            Company Details
          </a>
        </div>
        <h1 className="text-3xl mt-8">Privacy Policy</h1>

     
     
     
        
     
     
     
      </div>
    </div>
  );
}
