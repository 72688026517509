import { Popover } from '@headlessui/react'
import {IoBuild, IoLogOutOutline, IoSettingsOutline} from 'react-icons/io5';
import React, {useState, useEffect} from 'react';
import { doc, onSnapshot , getFirestore } from "firebase/firestore"; 
//import { getCategoryColorComp,getWordByRankSmall,SearchForWordInRank,SearchForWord } from "../../grammar";
import { AiOutlineMinus,AiFillMinusCircle } from 'react-icons/ai'
import { copyTextToClipboard } from './clipboard';
import { useWords } from '../Words/WordContext';
import { useAuth } from '../../auth/AuthContext';


export default function WordItem({w,onDelete,deletable,c,r,click,showType}) {
  const [color, setColor] = useState("slate");
  const [name, setName] = useState("");
  const [rank, setRank] = useState("");
  const [wdO, setWdO] = useState([]);
  const {userConfig} = useAuth();
  const {findWordInfoByDetector, getWordByRank, getCategoryColor} = useWords();
  
  useEffect(() => {
    let wordObj;

    let word = w;

    if(userConfig.target_lang === "es-ES"){
      word = word.replace("á","a");
      word = word.replace("é","e");
      word = word.replace("í","i");
      word = word.replace("ó","o");
      word = word.replace("ú","u");
    }
    
    if(typeof(w) === "number"){
      wordObj = getWordByRank(w);
    }else{
      wordObj = findWordInfoByDetector(word);
      setWdO(wordObj);
    }
    if(wordObj[0]){
      let category = (wordObj[0]["T"].split(",")[0]);
      setColor(getCategoryColor(category));
      setName(wordObj[0]["R"]);
      setRank(wordObj[0]["RA"]);
    }else{
      setColor(getCategoryColor(""));
      setName(w);
    }
  },[w])

  return (
    <span className="relative inline-block whitespace-nowrap">
        <div onClick={()=>{click(wdO)}} className={`rounded-full bg-${c?c:color}-300 text-${c?c:color}-700	p-2 mr-2 cursor-pointer flex flex-row content-center`}>
          <p className="mx-1">{name}{showType?" - "+wdO[1]:"" }</p>
          {deletable?<AiFillMinusCircle className={`cursor-pointer text-${c?c:color}-500 h-6 w-6 pl-2"`} onClick={onDelete}></AiFillMinusCircle>:""}  
          {r?<p className={`rounded-full font-bold`}>{rank}</p>:""}  
        </div> 
    </span>
  )
}