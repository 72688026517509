

/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from 'react';

import {useNavigate } from 'react-router-dom';
import {FcGoogle} from 'react-icons/fc';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../svg/logo.svg';
import { IoPaperPlane } from "react-icons/io5";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ReactComponent as Spinner } from '../svg/spinner.svg';
import QuizView from './QuizView';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as HiBlue } from '../svg/blue_hi.svg';
import QuizView_1 from './QuizView_1';

// add later <MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} />

/**
 * component with all signup functionality
 * @return {object} jsx sign in component
 */


export default function Share() {
  const functions = getFunctions();
    const getSharedStory = httpsCallable(functions, 'getSharedStory');
    const params = useParams();
    console.log(params.id);
    let [states, setStates] = useState([]);
    const [md,setMD] = useState("");
    const [lang, setLang] = useState("");
    const [title, setTitle] = useState("");
    const [state,setState] = useState("loading"); // referring to states in QuizView.js

    function getLang(direction,target_lang){
      if(direction===1){
        return target_lang.substring(6,11);
      }else if(direction===0){
        return target_lang.substring(6,11);
      }else if(direction===3){
        return "en-US";
      }else if(direction===4){
        return "en-US";
      }else if(direction===5){
        return target_lang.substring(6,11);
      }else if(direction===6){
        return target_lang.substring(6,11);
      }else{
        return "en-US";
      }
    }

    useEffect(()=>{
        const configObj= {"address": params.id};
        getStory(configObj);
    },[]);

    function getStory(configObj){
      getSharedStory(configObj).then((result)=>{
        console.log(result.data);
        setMD(result.data[1]);
        setTitle(result.data[0]);
        setLang(result.data[3].substring(6,11));
        console.log(result);
        let st = result.data[2].states.map((item)=>{
          item.primary = true;
          if(item.direction === 6){
            item.audio = [];
            for(let i = 0; i < item.fragments; i++){
              item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction,result.data[3])}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
            }
          }else{
            item.audio = (`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction,result.data[3])}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`); 
          }
          
          if(item.deconstruct){
            item.deconstruct = item.deconstruct.map((dec)=>{
              if(dec.direction === 6){
                dec.audio = [];
                for(let i = 0; i < dec.fragments; i++){
                  dec.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(dec.direction,result.data[3])}%2Faudio_${dec.hash[i]}.mp3?alt=media&token=${dec.uuid[i]}`); 
                }
              }else{
                dec.audio =  (`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(dec.direction,result.data[3])}%2Faudio_${dec.hash}.mp3?alt=media&token=${dec.uuid}`); 
              }
              
              return dec;
            })
          }
          return item});
        setStates(st);
        setState("loading"); // referring to states in QuizView.js
    });
    }

  return (
    <div className="h-screen w-full md:px-32 dark:bg-slate-900">
      <QuizView_1 givenStates={states} givenState={state} lang={lang}></QuizView_1>
    </div>
  );
}
