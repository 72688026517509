import React, { useState } from 'react';
import NavbarPublic from '../navbar/NavbarPublic';

export default function CompanyDetails() {
  return (
    <div>
        <NavbarPublic/>
        <div className="container">
            <h1 className="text-3xl mt-8">Company Details</h1>
            <p className="font-bold">Address</p>
            <p>nova UG (haftungsbeschränkt)</p>
            <p>Ziegelgrund 25</p>
            <p>D-87755 Kirchhaslach</p>
            <p className="font-bold mt-4">Contact</p>
            <p>Joshua Schmidt</p>
            <p>E-Mail: info@lang.blue</p>
        </div>
    </div>
  );
}
