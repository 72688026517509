import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackOutline} from 'react-icons/io5';
import { ReactComponent as FlagRU } from '../svg/flag_ru.svg';
import { ReactComponent as FlagUS } from '../svg/flag_us.svg';
import { QuizDropDown } from './QuizDropDown';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { LanguageDropDown } from './LanguageDropDown';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function Navbar({role, xp, onStartLevel, onStartStory, percentage, settings=false}) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col select-none shadow-md z-40">
        <div className="w-full p-2 text-slate-700 z-40 p">
          <div className="mx-auto container flex flex-row justify-between">
            <div className={`flex flex-row items-center ${!settings?"bg-gray-100 dark:bg-slate-800":""} h-10 my-2 sm:m-1 rounded-full`}>
            {settings && <IoArrowBackOutline onClick={()=>{navigate('/learn');}} className="cursor-pointer text-sky-700 h-6 w-6 mr-6"/>}
              <QuizDropDown settings={settings} levelSelected={(id)=>{onStartLevel(id);}} storySelected={(id)=>{onStartStory(id);}} className="z-20 "></QuizDropDown>
              {!settings&&<div className="flex flex-row items-center  rounded-full h-10 px-3">
                <p className="font-bold text-amber-500">{xp} XP</p>
              </div>}
            </div>  
            <div className="flex flex-row items-center mx-2 justify-center md:grow">
              <p className="font-bold text-slate-500">{settings?"Settings":"Learn"}{role?<span className="text-sm bg-violet-400 text-white rounded px-1 m-1">{role}</span>:""}</p>
            </div>
          </div>
          <div className='z-40 h-1 w-full bg-white dark:bg-slate-900'>
                <div
                    style={{ width: percentage}}
                    className={`h-full bg-sky-400 dark:bg-sky-600`}>
                </div>
        </div>
        </div>
       
    </div>
  );
}