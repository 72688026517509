import React from 'react';
import { IoWarningOutline, IoSync } from 'react-icons/io5';

export const STATES = {
  NORMAL: 0,
  PROCESS: 1,
  ERROR: 2,
};

export function StateButton(props) {
  const { state, onClick, size, children } = props;

  // Use a switch statement to render the appropriate icon or children element
  let content;
  switch (state) {
    case STATES.NORMAL:
      content = <div onClick={onClick} className="cursor-pointer">{children}</div>;
      break;
    case STATES.PROCESS:
      content = (
        <IoSync
          onClick={onClick}
          className={`animate-spin h-${size} w-${size}`}
        />
      );
      break;
    case STATES.ERROR:
      content = (
        <IoWarningOutline
          onClick={onClick}
          className={`cursor-pointer h-${size} w-${size} text-red-500`}
        />
      );
      break;
    default:
      content = <div onClick={onClick} className="cursor-pointer">{children}</div>;
  }

  return <div className={props.className}>{content}</div>;
}
