import React, {useState} from 'react';
import Words from './Words';
/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function WordEditor() {
  return (
    <div className="flex flex-col h-screen w-full">
      
      <Words></Words>
    </div>
  );
}