import React, { useState, useEffect } from "react";
import Navbar from "../AILearn/Navbar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from "../auth/AuthContext";
import { JapaneseWords } from "./Japanese";

import AudioPlayer from "../util/AudioPlayer";

import {
  IoAdd,
  IoChatbubblesOutline,
  IoLogoYoutube,
  IoRepeat,
  IoLanguage,
  IoCheckmarkDoneOutline,
} from "react-icons/io5";
import { StateButton, STATES } from "../util/StateButton";

const STATE_LOADING = "loading";
const STATE_NO_ACCESS = "noAccess";
const STATE_START = "start";
const STATE_NEXT = "next";
const STATE_INIT = "init";

const PASS_ENGLISH = 0;
const PASS_DECODED = 1;
const PASS_ROMANJI = 2;
const PASS_KANA = 3;
const PASS_KANJI = 4;

const JP_SHORT = "jp-JP";

const IGNORE_LIST = [
  "！",
  "は",
  "。",
  "、",
  "の",
  "を",
  "が",
  "「",
  "」",
  "（",
  "）",
  ":",
];

export function JapaneseWord({ word, key, pass, onTouched, reset }) {
  let [state, setState] = useState(pass);

  useEffect(() => {
    IGNORE_LIST.includes(word[0]) || word[3] === "-"
      ? pass === PASS_DECODED
        ? setState(PASS_ROMANJI)
        : setState(PASS_KANA)
      : setState(pass);
  }, [pass]);

  useEffect(() => {
    IGNORE_LIST.includes(word[0]) || word[3] === "-"
      ? pass === PASS_DECODED
        ? setState(PASS_ROMANJI)
        : setState(PASS_KANA)
      : setState(pass);
  }, [reset]);

  return (
    <div
      className="inline-block ml-2 inline-flex flex-col cursor-pointer dark:text-slate-200 hover:bg-black/20 dark:hover:bg-white/20"
      key={key}
      onClick={() => {
        onTouched();
        if (state === PASS_KANJI) {
          setState(PASS_KANA);
        } else if (state === PASS_KANA) {
          setState(PASS_ROMANJI);
        } else if (state === PASS_ROMANJI) {
          setState(PASS_DECODED);
        } else if (state === PASS_DECODED) {
          setState(PASS_KANJI);
        }
      }}
    >
      <div>
        {state === PASS_KANJI ? (
          <div className="whitespace-nowrap font-bold">{word[0]}</div>
        ) : (
          ""
        )}
        {state === PASS_KANA ? (
          <div className="whitespace-nowrap ">{word[1]}&#xfeff;</div>
        ) : (
          ""
        )}
        {state === PASS_ROMANJI ? (
          <div className="whitespace-nowrap ">{word[2]}&#xfeff;</div>
        ) : (
          ""
        )}
        {state === PASS_DECODED ? (
          <div className="whitespace-nowrap font-thin text-slate-600 dark:text-slate-100 bg-slate-100 hover:bg-slate-200 dark:hover:bg-white/20">
            {word[3]}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export function JapaneseLine({ text, translation, pass, onTouched, reset }) {
  const functions = getFunctions();
  const getJapaneseSentenceExplanation = httpsCallable(
    functions,
    "getJapaneseSentenceExplanation"
  );
  const [content, setContent] = useState();

  useEffect(() => {
    async function fetch() {
      let temp = await getJapaneseSentenceExplanation({ sentence: text });
      setContent(JSON.parse(temp.data));
    }
    fetch();
  }, []);

  return (
    <div>
      <div className="text-3xl">
        {content?.map((item, i) => {
          return (
            <JapaneseWord
              onTouched={onTouched}
              pass={pass}
              key={i}
              reset={reset}
              word={item}
            />
          );
        })}
      </div>
    </div>
  );
}

let lastPass = PASS_ENGLISH;

function Paragraph({ paragraph }) {
  const functions = getFunctions();
  const getAudioLink = httpsCallable(functions, "getAudioLink");
  const [showTranslation, setShowTranslation] = useState(false);
  const [audioLink, setAudioLink] = useState();

  const [repeat, setRepeat] = useState(false);

  const [pass, setPass] = useState(PASS_ENGLISH);
  const [reset, setReset] = useState(false);

  const [sentences, setSentences] = useState([paragraph.text]);

  useEffect(() => {
    async function fetch() {
      let gender = "female";
      let voice = "ja-JP-Wavenet-A";
      let temp = await getAudioLink({
        text: paragraph.text,
        lC: JP_SHORT,
        voice: voice,
        gender: gender,
      });
      setAudioLink(temp.data.link);
    }
    fetch();
  }, []);

  return (
    <div className={`mt-8`}>
      <div></div>
      {pass === PASS_ENGLISH || showTranslation? (
        <div className="text-3xl dark:text-slate-100">
          {paragraph.translation}
        </div>
      ) : (
        <div>
          {sentences.map((sentence) => {
            return (
              <JapaneseLine
                onTouched={() => {
                  setRepeat(true);
                }}
                reset={reset}
                text={sentence}
                pass={pass}
              ></JapaneseLine>
            );
          })}
        </div>
      )}
      <div className="flex flex-row justify-between  items-center gap-2 mt-4">
        <div className="flex flex-row items-center gap-2 mt-4">
          <IoLanguage
            onClick={() => {
              setShowTranslation(!showTranslation);
            }}
            className="cursor-pointer w-8 h-8 p-1 bg-black/10 dark:bg-white/10 rounded-full text-black/40 dark:text-white/40"
          ></IoLanguage>
          {audioLink ? (
            <AudioPlayer
              inactive={400}
              color={"gray"}
              size={6}
              audio={audioLink}
              play={false}
            ></AudioPlayer>
          ) : (
            ""
          )}
        </div>
        {repeat ? (
          <div>
            <IoRepeat
              className="text-green-500 cursor-pointer w-8 h-8"
              onClick={() => {
                setRepeat(false);
                setReset(!reset);
              }}
            ></IoRepeat>
          </div>
        ) : (
          <div>
            {pass === PASS_ENGLISH ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_DECODED;
                  setPass(PASS_DECODED);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_DECODED ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_ROMANJI;
                  setPass(PASS_ROMANJI);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_ROMANJI ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_KANA;
                  setPass(PASS_KANA);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_KANA ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_KANJI;
                  setPass(PASS_KANJI);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}

let current = 0;

export default function ReadTexts() {
  const functions = getFunctions();
  const getParagraph = httpsCallable(functions, "getParagraph");

  const [currentWord, setCurrentWord] = useState(1);

  const [state, setState] = useState(STATE_LOADING);
  const [paragraphs, setParagraphs] = useState([]);

  const { currentUserState, userConfig, currentUser } = useAuth();
  const [xp, setXP] = useState(currentUserState.xp);
  const [role, setRole] = useState();
  const [paragraphState, setParagraphState] = useState(STATES.NORMAL);
  const [newparagraphState, setNewParagraphState] = useState(STATES.NORMAL);

  function parseJapanese(text) {

    let allSentences = JSON.parse(text);


    let pp = [];

    allSentences.forEach((sentence) => {
      pp.push({
        text: sentence[0],
        translation: sentence[1],
      });
    });

    return pp;
  }

  useEffect(() => {
    async function fetch() {
      const params = {
        botId: "HcVm5gME0IrlzSunqGRw",
        targetLang: "Japanese",
        sourceLang: "English",
        paragraphs: [],
        currentWord: JapaneseWords[currentWord][0],
      };

      const result = await getParagraph(params);

      setParagraphs(parseJapanese(result.data));

      setState(STATE_START);
    }
    fetch();
  }, []);

  async function getNextParagraph() {
    setParagraphState(STATES.PROCESS);
    const params = {
      botId: "HcVm5gME0IrlzSunqGRw",
      targetLang: "Japanese",
      sourceLang: "English",
      paragraphs: paragraphs.length > 4 ? paragraphs.slice(-4) : paragraphs,
      currentWord: JapaneseWords[current][0],
    };

    console.log(params);

    const result = await getParagraph(params);
    setParagraphState(STATES.NORMAL);

    let pp = parseJapanese(result.data);

    setParagraphs([...paragraphs, ...pp]);

    setState(STATE_START);
  }

  // "今日の朝、私は窓から見た桜の木がとても美しかった。桜の花は白とピンクの色で、空に向かって優雅に舞っていた。私は窓の外に一時間以上も見とれてしまった。見ている間に、春が本当に来たんだなと思った。しかし、この美しい景色が一週間くらいしか続かないのは残念だ。さいわい、今日は気持ちいい天気だったので、桜を見ることで私の日常生活にほんの少しの幸せを加えることができた。"

  return (
    <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
      <Navbar role={role} xp={xp} percentage={`${0 * 100}%`}></Navbar>
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col h-full overflow-visible pb-2">
            <div className="relative w-full h-full overflow-scroll">
              <div className="mx-10 pt-10 flex flex-col ">
                {state === STATE_LOADING ? (
                  "Loading..."
                ) : state === STATE_START ? (
                  <div className="flex flex-col p-4 max-w-4xl w-full mx-auto">
                    {paragraphs.map((paragraph, index) => {
                      return <Paragraph paragraph={paragraph}></Paragraph>;
                    })}
                    <div className="flex flex-row gap-8">
                    <StateButton state={paragraphState}>
                      {" "}
                      <div
                        className="mt-10 flex flex-row items-center text-gray-500 cursor-pointer"
                        onClick={() => {
                          getNextParagraph();
                        }}
                      >
                        <IoAdd className="w-6 h-6 mr-1"></IoAdd>More
                      </div>
                    </StateButton>

                    <StateButton state={newparagraphState}>
                      {" "}
                      <div
                        className="mt-10 flex flex-row items-center text-gray-500 cursor-pointer"
                        onClick={() => {
                          current = current + 1;
                          getNextParagraph();
                        }}
                      >
                        <IoAdd className="w-6 h-6 mr-1"></IoAdd>Next Word
                      </div>
                    </StateButton>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
