import React, { useState, useEffect } from 'react';
import useContentful from './useContentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


export default function Blogs() {
    let [articles, setArticles] = useState([]);
    const { getArticles } = useContentful();
    useEffect(()=>{
        getArticles().then((response) => {
            setArticles(response.items);
            console.log(response);
        });
    },[]);
    return (
        <div>
            {articles.map((article,i) => 
                <div key={i}>
                    <h1>{article.fields.title}</h1>
                    <p>{article.fields.author.fields.name}</p>
                    <p>{article.fields.created}</p>
                    <div>{documentToReactComponents(article.fields.body)}</div>
                </div>
            )}
        </div>
    );
}
