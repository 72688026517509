import { createClient } from 'contentful';

const useContentful = () => {
    const client = createClient({
        space: "ktkb92fdrlfk",
        accessToken: "GI9z4RhpM9_0wqHkejsO-tJmmj3xXjDo_3abf_hMCjw"
    });
    
    const getArticles = async () => {
        try {
            const articles = await client.getEntries({
                content_type: "post",
                select: "fields",
            });
            return articles;
        } catch (e) {
            console.log(e);
        }
    };

    return { getArticles };
};

export default useContentful;