import React, {useEffect, useState} from 'react';
import EditorNavbar from '../editor/EditorNavbar';
import Sidebar from '../editor/Sidebar';
import { IoFlagOutline } from "react-icons/io5";
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore, orderBy, limit } from "firebase/firestore"; 
import FlagView from './FlagView';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export const FlagTypes = { 
  0: {name: "Wrong Translation",color:"red"},
  1: {name: "More explanation",color:"sky"},
  2: {name: "Custom",color:"orange"},
  3: {name: "Idea",color:"green"}
}

function FlagElement({flag, onClick, index, selected}) {
  return (
    <div onClick={onClick} className={`flex flex-row ${index===selected?"bg-slate-200":""} justify-between items-center px-4 cursor-pointer border-slate-200 border-b h-14 text-slate-500 hover:bg-slate-200 whitespace-nowrap`}>
      <IoFlagOutline className={`h-6 w-6  text-${FlagTypes[flag.type].color}-500`}></IoFlagOutline> <p>{FlagTypes[flag.type].name}</p> 
    </div>
  );
}

function compare( a, b ) {
  if ( a.date < b.date ){
    return -1;
  }
  if ( a.date > b.date ){
    return 1;
  }
  return 0;
}


export default function FlagManager() {
  const [flags, setFlags] = useState([]);
  const [selected, setSelected] = useState(-1);

  useEffect(()=>{
    let unsubscribe = onSnapshot(collection(getFirestore(),"Flags"), orderBy("date"), (colSnapshot) =>{
      let flags = []
      colSnapshot.forEach((doc) =>{
        let d = doc.data();
        d.id = doc.id;
        flags.push(d);
      })
      flags.sort(compare);
      setFlags(flags);
      if(flags.length>0){
        setSelected(0);
      }else{
        setSelected(-1);
        setFlags([]);
      }
    });

    return () => {  
      unsubscribe();
    }; 
  },[]);

  function select(index){
    setSelected(index);
  }

  return (
    <div className="flex flex-col h-screen w-full dark:bg-slate-900 text-slate-500 dark:text-slate-200">
      <EditorNavbar name="Flags"></EditorNavbar>
      <div className="flex flex-row h-full w-full">
      <Sidebar>
        {flags.map((flag,index) =>{
          return <FlagElement key={index} index={index} selected={selected} onClick={()=>{select(index)}} key={index} flag={flag}/>
        })}
      </Sidebar>
      {flags[selected]?<FlagView flag={flags[selected]}></FlagView>:<p className="p-4">no Flag to display</p>}
      </div>
   
    </div>
  );
}