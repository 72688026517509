import React, {useState} from 'react';
import EditorNavbar from '../EditorNavbar';
import Levels from './Levels';
/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function LevelEditor() {
  return (
    <div className="flex flex-col h-screen w-full">
      <EditorNavbar/>
      <Levels></Levels>
    </div>
  );
}