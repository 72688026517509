import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackOutline} from 'react-icons/io5';
import { ReactComponent as FlagRU } from '../svg/flag_ru.svg';
import { ReactComponent as FlagUS } from '../svg/flag_us.svg';
import { QuizDropDown } from './QuizDropDown';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { LanguageDropDown } from './LanguageDropDown';
import { ReactComponent as LogoBlue } from '../svg/logo_blue.svg';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function NavbarPublic() {
  return (
    <div className="w-full flex flex-col">
        <div className="w-full p-2 text-slate-600 z-40 bg-white p">
          <div className="mx-auto container flex flex-row justify-between">
            <div className="flex flex-row items-center">
               <a href="/"> <LogoBlue className='w-8 h-8 m-2'></LogoBlue></a>
            </div>  
            <div className="flex flex-col grow items-center mx-2 justify-center">
            </div>
          </div>
        </div>
        <hr/>
        <div className='z-40 h-1 w-full bg-slate-300'>
                <div
                    style={{ width: "100%"}}
                    className={`h-full bg-sky-500`}>
                </div>
        </div>
    </div>
  );
}