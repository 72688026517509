import React from 'react';
import PropTypes from 'prop-types';
import { IoCheckmarkCircleOutline } from "react-icons/io5";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function SidebarElement({read, count, name, current, level, state, n}) {
  /**
   * opens popup to add document in firebase
   */

   function getEmojiFromString(s){
    let emoji = "";
    let str = "";
    for(let i = 0; i < s.length; i++){
      if(!"abcdefghijklmnopqrstuvwxyz ?!'1234567890,.-".includes(s[i].toLocaleLowerCase())){
          emoji += (s[i]);
      }else{
          str += (s[i]);
      }
    }
    return {emoji: emoji, str: str};
  }

  function badge(count){
    if(count === 1){
      return <div className='h-2 w-8 rounded-full bg-slate-200 dark:bg-slate-800'>
                <div
                    style={{ width: "25%"}}
                    className={`h-full bg-sky-500 rounded-full`}>
                </div>
        </div>;
    }else if(count ===2){
      return <div className='h-2 w-8 rounded-full bg-slate-200 dark:bg-slate-800'>
      <div
          style={{ width: "50%"}}
          className={`h-full bg-sky-500 rounded-full`}>
      </div>
</div>;
    }else if(count ===3){
      return <div className=' h-2 w-8 rounded-full bg-slate-200 dark:bg-slate-800'>
      <div
          style={{ width: "75%"}}
          className={`h-full bg-sky-500 rounded-full`}>
      </div>
</div>;
    }else if(count >= 4 && count < 10){
      return <IoCheckmarkCircleOutline className='h-6 w-6 text-sky-500'>
      </IoCheckmarkCircleOutline>;
    }else{
      return <IoCheckmarkCircleOutline className=' h-6 w-6 text-amber-500'>
      </IoCheckmarkCircleOutline>;
    }
  }

  return (
    <div className={`flex flex-row items-center justify-between cursor-pointer border-dotted border-slate-200 dark:border-slate-700 border-b  ${state===0?"dark:bg-sky-800 bg-sky-100 hover:dark:bg-sky-700 hover:bg-sky-200":"hover:bg-slate-100 dark:hover:bg-slate-800"} text-slate-500 dark:text-slate-200 whitespace-nowrap ${current === name ? "dark:bg-slate-800 bg-slate-100" : "" }`}>
      {getEmojiFromString(name).emoji&&<p className="flex text-3xl ml-4">{getEmojiFromString(name).emoji}</p>}
      <p className="flex flex-1 truncate ml-2">{getEmojiFromString(name).str}</p> 
      <div className="flex flex-row items-center h-14 mr-2">
        {read?badge(count):""}
        {level?<p className="text-amber-500 font-bold">{level}</p>:""}
        {n?<p className="flex items-center text-sm px-1 ml-2 bg-amber-500 text-white rounded font-bold">new</p>:""}
      </div>
      
    </div>
  );
}

SidebarElement.propTypes = {
  name: PropTypes.string,
  current: PropTypes.string
};
