export function getCategoryColor(category) {
    switch (category) {
        case "noun":
            return "red";
        case "verb":
            return "cyan";
        case "adjective":
            return "lime";
        case "preposition":
            return "green";
        case "particle":
            return "gray";
        case "pronoun":
            return "lime";
        case "expression":
            return "white";
        default:
            return "amber";
    }
}

export function getCategoryColorComp(category) {
    if (category) {
        switch (category[0]) {
            case "N":
                return "red";
            case "V":
                return "cyan";
            case "A":
                return "lime";
            case "P":
                return "green";
            case "T":
                return "orange";
            case "E":
                return "gray";
            default:
                return "amber";
        }
    } else {
        return "amber";
    }
}

export function getWordByRankSmall(wordRa) {
    let returnArr = [];
    wRank.forEach((item) => {
        if (item["number"] === wordRa) {
            returnArr.push(item);
        }
    });

    return returnArr;
}

export function getWordRank(word) {
    let ret = -1;
    for (let i = 0; i < words.length; i++) {
        if (words[i].R === word) {
            ret = words[i].RA;
            break;
        }
    }
    return ret;
}

// {"R":"быть","E":"to be","T":"VI","A":"I","O":"быть","RA":8,"P":0,"G":"-","":""}
export function SearchForWord(word) {
    let returnArr = [];
    words.forEach((item) => {
        if (item["R"] === word) {
            returnArr.push(item);
        }
    });

    return returnArr;
}

export function SearchForWordInRank(word) {
    let returnArr = [];
    wRank.forEach((item) => {
        if (item["rus"] === word) {
            returnArr.push(item);
        }
    });

    return returnArr;
}

export function getWordByWord(word) {
    let returnArr = [];
    words.forEach((item) => {
        if (item["O"] === word) {
            returnArr.push(item);
        }
    });

    return returnArr;
}

export function getWordByRank(rank) {
    let returnArr = [];
    words.forEach((item) => {
        if (item["RA"] === rank) {
            returnArr.push(item);
        }
    });

    return returnArr;
}
// R Russian, E english, T Type, A Animate, O Original, RA Rank, P Person, G Gender
export const wRank = [{
        number: 0,
        rus: "и",
        eng: "and, though",
        type: "conjunction"
    },
    {
        number: 1,
        rus: "в",
        eng: "in, at",
        type: "preposition"
    },
    {
        number: 2,
        rus: "не",
        eng: "not",
        type: "particle"
    },
    {
        number: 3,
        rus: "он",
        eng: "he",
        type: "pronoun"
    },
    {
        number: 4,
        rus: "на",
        eng: "on, it, at, to",
        type: "preposition"
    },
    {
        number: 5,
        rus: "я",
        eng: "I",
        type: "pronoun"
    },
    {
        number: 6,
        rus: "что",
        eng: "what, that, why",
        type: "сonjunction, pronoun",
    },
    {
        number: 7,
        rus: "тот",
        eng: "that",
        type: "adjective, pronoun"
    },
    {
        number: 8,
        rus: "быть",
        eng: "to be",
        type: "verb"
    },
    {
        number: 9,
        rus: "с",
        eng: "with, and, from, of",
        type: "preposition"
    },
    {
        number: 10,
        rus: "а",
        eng: "while, and, but",
        type: "conjunction"
    },
    {
        number: 11,
        rus: "весь",
        eng: "all, everything",
        type: "pron, noun"
    },
    {
        number: 12,
        rus: "это",
        eng: "that, this, it",
        type: "pronoun"
    },
    {
        number: 13,
        rus: "как",
        eng: "how, what, as, like",
        type: "adverb, conj"
    },
    {
        number: 14,
        rus: "она",
        eng: "she",
        type: "pronoun"
    },
    {
        number: 15,
        rus: "по",
        eng: "on, along, by",
        type: "preposition"
    },
    {
        number: 16,
        rus: "но",
        eng: "but",
        type: "conjunction, noun"
    },
    {
        number: 17,
        rus: "они",
        eng: "they",
        type: "pronoun"
    },
    {
        number: 17,
        rus: "меня",
        eng: "me, I",
        type: "pronoun"
    },
    {
        number: 18,
        rus: "к",
        eng: "to, for, by",
        type: "preposition"
    },
    {
        number: 19,
        rus: "у",
        eng: "by, with, of",
        type: "preposition"
    },
    {
        number: 20,
        rus: "ты",
        eng: "you, thou",
        type: "pronoun"
    },
    {
        number: 21,
        rus: "из",
        eng: "from, of, in",
        type: "preposition"
    },
    {
        number: 22,
        rus: "мы",
        eng: "we",
        type: "pronoun"
    },
    {
        number: 23,
        rus: "за",
        eng: "behind, over, at, after",
        type: "preposition",
    },
    {
        number: 24,
        rus: "вы",
        eng: "you",
        type: "pronoun"
    },
    {
        number: 25,
        rus: "так",
        eng: "so, thus, then",
        type: "adverb"
    },
    {
        number: 26,
        rus: "же",
        eng: "and, as for, but, same",
        type: "conj, particle",
    },
    {
        number: 27,
        rus: "от",
        eng: "from, of, for",
        type: "preposition"
    },
    {
        number: 28,
        rus: "сказать",
        eng: "to say, to speak",
        type: "verb"
    },
    {
        number: 29,
        rus: "этот",
        eng: "this",
        type: "pronoun"
    },
    {
        number: 30,
        rus: "который",
        eng: "which, who, that",
        type: "pronoun"
    },
    {
        number: 31,
        rus: "мочь",
        eng: "be able",
        type: "verb"
    },
    {
        number: 32,
        rus: "человек",
        eng: "man, person",
        type: "noun"
    },
    {
        number: 33,
        rus: "о",
        eng: "of, about, against",
        type: "preposition"
    },
    {
        number: 34,
        rus: "один",
        eng: "one, some, alone",
        type: "cardinal number, pronoun",
    },
    {
        number: 35,
        rus: "ещё",
        eng: "still, yet",
        type: "adverb"
    },
    {
        number: 36,
        rus: "бы",
        eng: "would",
        type: "particle"
    },
    {
        number: 37,
        rus: "такой",
        eng: "such, so, some",
        type: "pronoun"
    },
    {
        number: 38,
        rus: "только",
        eng: "only, merely, but",
        type: "adverb, conj"
    },
    {
        number: 39,
        rus: "себя",
        eng: "myself, himself, herself",
        type: "pron, particle",
    },
    {
        number: 40,
        rus: "своё",
        eng: "one's own, my, our",
        type: "pronoun, noun"
    },
    {
        number: 41,
        rus: "какой",
        eng: "what, which, how",
        type: "pronoun"
    },
    {
        number: 42,
        rus: "когда",
        eng: "when, while, as",
        type: "adverb, conj"
    },
    {
        number: 43,
        rus: "уже",
        eng: "already, by now",
        type: "adverb"
    },
    {
        number: 44,
        rus: "для",
        eng: "for, to",
        type: "preposition"
    },
    {
        number: 45,
        rus: "вот",
        eng: "here, there, this is, that's",
        type: "particle",
    },
    {
        number: 46,
        rus: "кто",
        eng: "who, that, some",
        type: "pronoun"
    },
    {
        number: 47,
        rus: "да",
        eng: "yes, but",
        type: "conj, particle"
    },
    {
        number: 47,
        rus: "привет",
        eng: "hello",
        type: "particle"
    },
    {
        number: 47,
        rus: "здравствуйте",
        eng: "hello",
        type: "particle"
    },
    {
        number: 48,
        rus: "говорить",
        eng: "to say, to tell, to speak",
        type: "verb",
    },
    {
        number: 49,
        rus: "год",
        eng: "year",
        type: "noun"
    },
    {
        number: 51,
        rus: "знать",
        eng: "to know, be aware",
        type: "verb"
    },
    {
        number: 52,
        rus: "мой",
        eng: "my, mine",
        type: "pronoun"
    },
    {
        number: 52,
        rus: "их",
        eng: "their, theirs",
        type: "pronoun"
    },
    {
        number: 53,
        rus: "до",
        eng: "to, up to, about, before",
        type: "preposition",
    },
    {
        number: 54,
        rus: "или",
        eng: "or",
        type: "conjunction"
    },
    {
        number: 55,
        rus: "если",
        eng: "if",
        type: "conjunction"
    },
    {
        number: 56,
        rus: "время",
        eng: "time, season",
        type: "noun"
    },
    {
        number: 57,
        rus: "рука",
        eng: "hand, arm",
        type: "noun"
    },
    {
        number: 58,
        rus: "нет",
        eng: "no, not, but",
        type: "negation"
    },
    {
        number: 59,
        rus: "самый",
        eng: "most, the very, the same",
        type: "pronoun",
    },
    {
        number: 60,
        rus: "ни",
        eng: "not a, not, neither… nor",
        type: "conj, particle",
    },
    {
        number: 61,
        rus: "стать",
        eng: "to become, begin, come",
        type: "verb"
    },
    {
        number: 62,
        rus: "большой",
        eng: "big, large, important",
        type: "adjective",
    },
    {
        number: 63,
        rus: "даже",
        eng: "even",
        type: "particle"
    },
    {
        number: 64,
        rus: "другой",
        eng: "other, another, different",
        type: "pronoun",
    },
    {
        number: 65,
        rus: "наш",
        eng: "our, ours",
        type: "pronoun"
    },
    {
        number: 66,
        rus: "свой",
        eng: "my, our, your",
        type: "pronoun"
    },
    {
        number: 67,
        rus: "ну",
        eng: "now, right, well, come on",
        type: "interj, particle",
    },
    {
        number: 68,
        rus: "под",
        eng: "under, for, towards, to",
        type: "preposition",
    },
    {
        number: 69,
        rus: "где",
        eng: "where",
        type: "adverb"
    },
    {
        number: 69,
        rus: "приятно",
        eng: "pleasantly",
        type: "adverb"
    },
    {
        number: 70,
        rus: "дело",
        eng: "business, affair, matter",
        type: "noun"
    },
    {
        number: 71,
        rus: "есть",
        eng: "to eat, to be",
        type: "verb"
    },
    {
        number: 72,
        rus: "сам",
        eng: "myself, yourself",
        type: "pronoun"
    },
    {
        number: 73,
        rus: "раз",
        eng: "time, once, since",
        type: "noun, adverb"
    },
    {
        number: 74,
        rus: "чтобы",
        eng: "that, in order that",
        type: "conjunction, particle",
    },
    {
        number: 75,
        rus: "два",
        eng: "two",
        type: "cardinal number"
    },
    {
        number: 76,
        rus: "там",
        eng: "there, then",
        type: "adverb"
    },
    {
        number: 77,
        rus: "чем",
        eng: "than; instead of",
        type: "conjunction, particle",
    },
    {
        number: 78,
        rus: "глаз",
        eng: "eye; sight",
        type: "noun"
    },
    {
        number: 79,
        rus: "жизнь",
        eng: "life",
        type: "noun"
    },
    {
        number: 80,
        rus: "первый",
        eng: "first, front, former",
        type: "adjective, number",
    },
    {
        number: 81,
        rus: "день",
        eng: "day",
        type: "noun"
    },
    {
        number: 82,
        rus: "тут",
        eng: "here, now, then",
        type: "adverb"
    },
    {
        number: 83,
        rus: "во",
        eng: "in, at; super, exactly",
        type: "preposition, particle",
    },
    {
        number: 84,
        rus: "ничто",
        eng: "nothing",
        type: "pronoun"
    },
    {
        number: 85,
        rus: "потом",
        eng: "afterwards, then",
        type: "adverb"
    },
    {
        number: 86,
        rus: "очень",
        eng: "very",
        type: "adverb"
    },
    {
        number: 87,
        rus: "со",
        eng: "with",
        type: "preposition"
    },
    {
        number: 88,
        rus: "хотеть",
        eng: "to want, like",
        type: "verb"
    },
    {
        number: 89,
        rus: "ли",
        eng: "whether, if",
        type: "conj, particle"
    },
    {
        number: 90,
        rus: "при",
        eng: "attached to, in the presence of",
        type: "preposition",
    },
    {
        number: 91,
        rus: "голова",
        eng: "head, mind, brains",
        type: "noun"
    },
    {
        number: 92,
        rus: "надо",
        eng: "over, above, ought to",
        type: "prep, verb"
    },
    {
        number: 93,
        rus: "без",
        eng: "without",
        type: "preposition"
    },
    {
        number: 94,
        rus: "видеть",
        eng: "to see",
        type: "verb"
    },
    {
        number: 95,
        rus: "идти",
        eng: "to go, come",
        type: "verb"
    },
    {
        number: 96,
        rus: "теперь",
        eng: "now, nowadays",
        type: "adverb"
    },
    {
        number: 97,
        rus: "тоже",
        eng: "also, as well, too",
        type: "adverb"
    },
    {
        number: 98,
        rus: "стоять",
        eng: "to stand, be, stand up",
        type: "verb"
    },
    {
        number: 99,
        rus: "друг",
        eng: "friend",
        type: "noun"
    },
    {
        number: 100,
        rus: "дом",
        eng: "house, home",
        type: "noun"
    },
    {
        number: 102,
        rus: "сейчас",
        eng: "now, presently, soon",
        type: "adverb"
    },
    {
        number: 103,
        rus: "можно",
        eng: "one can",
        type: "predicate"
    },
    {
        number: 104,
        rus: "после",
        eng: "after, afterwards",
        type: "preposition"
    },
    {
        number: 105,
        rus: "слово",
        eng: "word",
        type: "noun"
    },
    {
        number: 106,
        rus: "здесь",
        eng: "here",
        type: "adverb"
    },
    {
        number: 107,
        rus: "думать",
        eng: "to think; believe",
        type: "verb"
    },
    {
        number: 108,
        rus: "место",
        eng: "place; seat",
        type: "noun"
    },
    {
        number: 109,
        rus: "спросить",
        eng: "to ask",
        type: "verb"
    },
    {
        number: 110,
        rus: "через",
        eng: "through, across",
        type: "preposition"
    },
    {
        number: 111,
        rus: "лицо",
        eng: "face; person",
        type: "noun"
    },
    {
        number: 112,
        rus: "что",
        eng: "what, which, that",
        type: "pronoun"
    },
    {
        number: 113,
        rus: "тогда",
        eng: "then",
        type: "adverb"
    },
    {
        number: 114,
        rus: "ведь",
        eng: "you see, you know",
        type: "conj"
    },
    {
        number: 115,
        rus: "хороший",
        eng: "good, nice",
        type: "adjective"
    },
    {
        number: 116,
        rus: "каждый",
        eng: "each, every",
        type: "pronoun"
    },
    {
        number: 117,
        rus: "новый",
        eng: "new; modern",
        type: "adjective"
    },
    {
        number: 118,
        rus: "жить",
        eng: "to live",
        type: "verb"
    },
    {
        number: 119,
        rus: "должный",
        eng: "due, proper",
        type: "adjective"
    },
    {
        number: 120,
        rus: "смотреть",
        eng: "to look, watch",
        type: "verb"
    },
    {
        number: 121,
        rus: "почему",
        eng: "why",
        type: "adverb"
    },
    {
        number: 122,
        rus: "потому",
        eng: "that's why",
        type: "adverb"
    },
    {
        number: 123,
        rus: "сторона",
        eng: "side, party",
        type: "noun"
    },
    {
        number: 124,
        rus: "просто",
        eng: "simply",
        type: "adverb"
    },
    {
        number: 125,
        rus: "нога",
        eng: "foot, leg",
        type: "noun"
    },
    {
        number: 126,
        rus: "сидеть",
        eng: "to sit",
        type: "verb"
    },
    {
        number: 127,
        rus: "понять",
        eng: "to understand; realize",
        type: "verb"
    },
    {
        number: 128,
        rus: "иметь",
        eng: "to have, own",
        type: "verb"
    },
    {
        number: 129,
        rus: "конечный",
        eng: "final, last",
        type: "adjective"
    },
    {
        number: 130,
        rus: "делать",
        eng: "to do, make",
        type: "verb"
    },
    {
        number: 131,
        rus: "вдруг",
        eng: "suddenly",
        type: "adverb"
    },
    {
        number: 132,
        rus: "над",
        eng: "above, over",
        type: "preposition"
    },
    {
        number: 133,
        rus: "взять",
        eng: "to take",
        type: "verb"
    },
    {
        number: 134,
        rus: "никто",
        eng: "nobody",
        type: "pronoun"
    },
    {
        number: 135,
        rus: "сделать",
        eng: "to do, make, finish",
        type: "verb"
    },
    {
        number: 136,
        rus: "дверь",
        eng: "door",
        type: "noun"
    },
    {
        number: 137,
        rus: "перед",
        eng: "before, in front of",
        type: "preposition",
    },
    {
        number: 138,
        rus: "нужный",
        eng: "necessary",
        type: "adjective"
    },
    {
        number: 139,
        rus: "понимать",
        eng: "to understand",
        type: "verb"
    },
    {
        number: 140,
        rus: "казаться",
        eng: "to seem, appear",
        type: "verb",
    },
    {
        number: 141,
        rus: "работа",
        eng: "work, job",
        type: "noun"
    },
    {
        number: 142,
        rus: "три",
        eng: "three",
        type: "cardinal number"
    },
    {
        number: 143,
        rus: "ваш",
        eng: "yours",
        type: "pronoun"
    },
    {
        number: 143,
        rus: "ваша",
        eng: "yours",
        type: "pronoun"
    },
    {
        number: 144,
        rus: "уж",
        eng: "really, already",
        type: "adverb, particle"
    },
    {
        number: 145,
        rus: "земля",
        eng: "earth, land, soil",
        type: "noun"
    },
    {
        number: 146,
        rus: "конец",
        eng: "end, distance",
        type: "noun"
    },
    {
        number: 147,
        rus: "несколько",
        eng: "several, some",
        type: "adverb"
    },
    {
        number: 148,
        rus: "час",
        eng: "hour, time",
        type: "noun"
    },
    {
        number: 149,
        rus: "голос",
        eng: "voice",
        type: "noun"
    },
    {
        number: 150,
        rus: "город",
        eng: "town, city",
        type: "noun"
    },
    {
        number: 151,
        rus: "последний",
        eng: "last, the latest, new",
        type: "adjective",
    },
    {
        number: 153,
        rus: "пока",
        eng: "for the present",
        type: "adverb"
    },
    {
        number: 154,
        rus: "хорошо",
        eng: "well",
        type: "adverb"
    },
    {
        number: 155,
        rus: "давать",
        eng: "to give; let, allow",
        type: "verb"
    },
    {
        number: 156,
        rus: "вода",
        eng: "water",
        type: "noun"
    },
    {
        number: 157,
        rus: "более",
        eng: "more",
        type: "adverb"
    },
    {
        number: 158,
        rus: "хотя",
        eng: "although",
        type: "conjunction"
    },
    {
        number: 159,
        rus: "всегда",
        eng: "always",
        type: "adverb"
    },
    {
        number: 160,
        rus: "второй",
        eng: "second",
        type: "ordinal number"
    },
    {
        number: 161,
        rus: "куда",
        eng: "where, what for, much",
        type: "adverb"
    },
    {
        number: 162,
        rus: "пойти",
        eng: "to go",
        type: "verb"
    },
    {
        number: 163,
        rus: "стол",
        eng: "table, desk; board",
        type: "noun"
    },
    {
        number: 164,
        rus: "ребёнок",
        eng: "child, kid, infant",
        type: "noun"
    },
    {
        number: 165,
        rus: "увидеть",
        eng: "to see",
        type: "verb"
    },
    {
        number: 166,
        rus: "сила",
        eng: "strength, force",
        type: "noun"
    },
    {
        number: 167,
        rus: "отец",
        eng: "father",
        type: "noun"
    },
    {
        number: 168,
        rus: "женщина",
        eng: "woman",
        type: "noun"
    },
    {
        number: 169,
        rus: "машина",
        eng: "car, machine, engine",
        type: "noun"
    },
    {
        number: 170,
        rus: "случай",
        eng: "case, occasion, incident",
        type: "noun"
    },
    {
        number: 171,
        rus: "ночь",
        eng: "night",
        type: "noun"
    },
    {
        number: 172,
        rus: "сразу",
        eng: "at once, right away, just",
        type: "adverb",
    },
    {
        number: 173,
        rus: "мир",
        eng: "world, peace",
        type: "noun"
    },
    {
        number: 174,
        rus: "совсем",
        eng: "quite, entirely, totally",
        type: "adverb",
    },
    {
        number: 175,
        rus: "остаться",
        eng: "to remain, stay",
        type: "verb"
    },
    {
        number: 176,
        rus: "об",
        eng: "about, of",
        type: "preposition"
    },
    {
        number: 177,
        rus: "вид",
        eng: "appearance, look, view",
        type: "noun"
    },
    {
        number: 178,
        rus: "выйти",
        eng: "to go out, come out, appear",
        type: "verb",
    },
    {
        number: 179,
        rus: "дать",
        eng: "to give",
        type: "verb",
    },
    {
        number: 180,
        rus: "работать",
        eng: "to work",
        type: "verb"
    },
    {
        number: 181,
        rus: "любить",
        eng: "to love",
        type: "verb"
    },
    {
        number: 182,
        rus: "старый",
        eng: "old",
        type: "adjective"
    },
    {
        number: 183,
        rus: "почти",
        eng: "almost",
        type: "adverb"
    },
    {
        number: 184,
        rus: "ряд",
        eng: "row, line",
        type: "noun"
    },
    {
        number: 185,
        rus: "оказаться",
        eng: "find oneself, turn out",
        type: "verb",
    },
    {
        number: 186,
        rus: "начало",
        eng: "beginning, origin, source",
        type: "noun",
    },
    {
        number: 187,
        rus: "твой",
        eng: "your, yours (informal)",
        type: "pronoun"
    },
    {
        number: 188,
        rus: "вопрос",
        eng: "question, matter, problem",
        type: "noun",
    },
    {
        number: 189,
        rus: "много",
        eng: "many, much",
        type: "adverb"
    },
    {
        number: 190,
        rus: "война",
        eng: "war",
        type: "noun"
    },
    {
        number: 191,
        rus: "снова",
        eng: "again",
        type: "adverb"
    },
    {
        number: 192,
        rus: "ответить",
        eng: "to answer, reply",
        type: "verb"
    },
    {
        number: 193,
        rus: "между",
        eng: "between, among",
        type: "preposition"
    },
    {
        number: 194,
        rus: "подумать",
        eng: "to think",
        type: "verb"
    },
    {
        number: 195,
        rus: "опять",
        eng: "again",
        type: "adverb"
    },
    {
        number: 196,
        rus: "белый",
        eng: "white",
        type: "adjective"
    },
    {
        number: 197,
        rus: "деньги",
        eng: "money",
        type: "noun"
    },
    {
        number: 198,
        rus: "значить",
        eng: "to mean, signify",
        type: "verb"
    },
    {
        number: 199,
        rus: "про",
        eng: "about, for",
        type: "preposition"
    },
    {
        number: 200,
        rus: "лишь",
        eng: "only, as soon as",
        type: "adverb, conj"
    },
    {
        number: 201,
        rus: "минута",
        eng: "minute, moment",
        type: "noun"
    },
    {
        number: 202,
        rus: "жена",
        eng: "wife",
        type: "noun"
    },
    {
        number: 204,
        rus: "посмотреть",
        eng: "to take a look, watch, inspect",
        type: "verb",
    },
    {
        number: 205,
        rus: "правда",
        eng: "truth",
        type: "noun"
    },
    {
        number: 206,
        rus: "главный",
        eng: "main, chief",
        type: "adjective"
    },
    {
        number: 207,
        rus: "страна",
        eng: "country",
        type: "noun"
    },
    {
        number: 208,
        rus: "свет",
        eng: "light;world",
        type: "noun"
    },
    {
        number: 209,
        rus: "ждать",
        eng: "to wait",
        type: "verb"
    },
    {
        number: 210,
        rus: "мать",
        eng: "mother",
        type: "noun"
    },
    {
        number: 211,
        rus: "будто",
        eng: "as if, as though",
        type: "conjunction"
    },
    {
        number: 212,
        rus: "никогда",
        eng: "never",
        type: "adverb"
    },
    {
        number: 213,
        rus: "товарищ",
        eng: "comrade, friend",
        type: "noun"
    },
    {
        number: 214,
        rus: "дорога",
        eng: "road, way, journey",
        type: "noun"
    },
    {
        number: 215,
        rus: "однако",
        eng: "however, though",
        type: "conj, misc"
    },
    {
        number: 216,
        rus: "лежать",
        eng: "to lie, be situated",
        type: "verb"
    },
    {
        number: 217,
        rus: "именно",
        eng: "namely, just",
        type: "misc"
    },
    {
        number: 218,
        rus: "окно",
        eng: "window, windowsill",
        type: "noun"
    },
    {
        number: 219,
        rus: "никакой",
        eng: "no, none",
        type: "pronoun"
    },
    {
        number: 220,
        rus: "найти",
        eng: "to find, discover, consider",
        type: "verb",
    },
    {
        number: 221,
        rus: "писать",
        eng: "to write",
        type: "verb"
    },
    {
        number: 222,
        rus: "комната",
        eng: "a room",
        type: "noun"
    },
    {
        number: 224,
        rus: "часть",
        eng: "part, share, department",
        type: "noun"
    },
    {
        number: 225,
        rus: "вообще",
        eng: "in general, altogether, on the whole",
        type: "adverb",
    },
    {
        number: 226,
        rus: "книга",
        eng: "a book",
        type: "noun"
    },
    {
        number: 227,
        rus: "маленький",
        eng: "small, little",
        type: "adjective"
    },
    {
        number: 228,
        rus: "улица",
        eng: "street",
        type: "noun",
    },
    {
        number: 229,
        rus: "решить",
        eng: "to decide, solve",
        type: "verb"
    },
    {
        number: 230,
        rus: "далёкий",
        eng: "distant, remote",
        type: "adjective"
    },
    {
        number: 231,
        rus: "душа",
        eng: "soul, spirit",
        type: "noun"
    },
    {
        number: 232,
        rus: "чуть",
        eng: "hardly, slightly",
        type: "adverb"
    },
    {
        number: 233,
        rus: "вернуться",
        eng: "to return",
        type: "verb"
    },
    {
        number: 234,
        rus: "утро",
        eng: "morning",
        type: "noun"
    },
    {
        number: 235,
        rus: "некоторый",
        eng: "some",
        type: "pronoun"
    },
    {
        number: 236,
        rus: "считать",
        eng: "to count, consider",
        type: "verb"
    },
    {
        number: 237,
        rus: "сколько",
        eng: "how much, how many",
        type: "cardinal"
    },
    {
        number: 238,
        rus: "помнить",
        eng: "to remember",
        type: "verb"
    },
    {
        number: 239,
        rus: "вечер",
        eng: "evening",
        type: "noun"
    },
    {
        number: 240,
        rus: "пол",
        eng: "floor; sex",
        type: "noun"
    },
    {
        number: 241,
        rus: "таки",
        eng: "after all",
        type: "particle"
    },
    {
        number: 242,
        rus: "получить",
        eng: "to receive, get, obtain",
        type: "verb",
    },
    {
        number: 243,
        rus: "народ",
        eng: "people, nation",
        type: "noun"
    },
    {
        number: 244,
        rus: "плечо",
        eng: "shoulder, upper arm",
        type: "noun"
    },
    {
        number: 245,
        rus: "хоть",
        eng: "even, if you want, though",
        type: "conjunction",
    },
    {
        number: 246,
        rus: "сегодня",
        eng: "today",
        type: "adverb"
    },
    {
        number: 247,
        rus: "бог",
        eng: "god",
        type: "noun"
    },
    {
        number: 248,
        rus: "вместе",
        eng: "together",
        type: "adverb"
    },
    {
        number: 249,
        rus: "взгляд",
        eng: "look, glance; view",
        type: "noun"
    },
    {
        number: 250,
        rus: "ходить",
        eng: "to go, walk",
        type: "verb"
    },
    {
        number: 251,
        rus: "зачем",
        eng: "what for, why",
        type: "adverb"
    },
    {
        number: 252,
        rus: "советский",
        eng: "Soviet",
        type: "adjective"
    },
    {
        number: 253,
        rus: "русский",
        eng: "Russian",
        type: "adjective"
    },
    {
        number: 254,
        rus: "бывать",
        eng: "be, visit, happen",
        type: "verb"
    },
    {
        number: 255,
        rus: "полный",
        eng: "full, complete",
        type: "adj"
    },
    {
        number: 256,
        rus: "прийти",
        eng: "to come, arrive",
        type: "verb"
    },
    {
        number: 257,
        rus: "палец",
        eng: "finger, toe",
        type: "noun"
    },
    {
        number: 259,
        rus: "любой",
        eng: "any, every",
        type: "pronoun"
    },
    {
        number: 260,
        rus: "история",
        eng: "history, story, event",
        type: "noun"
    },
    {
        number: 261,
        rus: "наконец",
        eng: "at last, finally",
        type: "adverb"
    },
    {
        number: 262,
        rus: "мысль",
        eng: "thought, idea",
        type: "noun"
    },
    {
        number: 263,
        rus: "узнать",
        eng: "to know, learn, recognize",
        type: "verb",
    },
    {
        number: 264,
        rus: "назад",
        eng: "back, backwards",
        type: "adverb"
    },
    {
        number: 265,
        rus: "общий",
        eng: "general, common",
        type: "adjective"
    },
    {
        number: 266,
        rus: "заметить",
        eng: "to notice, observe",
        type: "verb"
    },
    {
        number: 267,
        rus: "словно",
        eng: "as if, like",
        type: "conjunction"
    },
    {
        number: 268,
        rus: "прошлый",
        eng: "past",
        type: "adjective"
    },
    {
        number: 269,
        rus: "уйти",
        eng: "to leave, go away",
        type: "verb"
    },
    {
        number: 270,
        rus: "известный",
        eng: "well-known, famous",
        type: "adjective",
    },
    {
        number: 271,
        rus: "давно",
        eng: "long ago",
        type: "adverb"
    },
    {
        number: 272,
        rus: "слышать",
        eng: "to hear",
        type: "verb"
    },
    {
        number: 273,
        rus: "слушать",
        eng: "to listen, hear",
        type: "verb"
    },
    {
        number: 274,
        rus: "бояться",
        eng: "to be afraid, fear",
        type: "verb"
    },
    {
        number: 275,
        rus: "сын",
        eng: "son",
        type: "noun"
    },
    {
        number: 276,
        rus: "нельзя",
        eng: "it is impossible, can't",
        type: "predicate",
    },
    {
        number: 277,
        rus: "прямо",
        eng: "straight, frankly",
        type: "adverb"
    },
    {
        number: 278,
        rus: "долго",
        eng: "for a long time",
        type: "adverb"
    },
    {
        number: 279,
        rus: "быстро",
        eng: "fast, quickly",
        type: "adverb"
    },
    {
        number: 280,
        rus: "лес",
        eng: "forest",
        type: "noun"
    },
    {
        number: 281,
        rus: "похожий",
        eng: "similar, alike",
        type: "adjective"
    },
    {
        number: 282,
        rus: "пора",
        eng: "time; pore",
        type: "noun"
    },
    {
        number: 283,
        rus: "пять",
        eng: "five",
        type: "cardinal number"
    },
    {
        number: 284,
        rus: "глядеть",
        eng: "to look, gaze",
        type: "verb"
    },
    {
        number: 285,
        rus: "оно",
        eng: "it",
        type: "pronoun"
    },
    {
        number: 286,
        rus: "сесть",
        eng: "to sit",
        type: "verb"
    },
    {
        number: 287,
        rus: "имя",
        eng: "name",
        type: "noun"
    },
    {
        number: 288,
        rus: "ж",
        eng: "and, as for, but",
        type: "misc"
    },
    {
        number: 289,
        rus: "разговор",
        eng: "talk, conversation",
        type: "noun"
    },
    {
        number: 290,
        rus: "тело",
        eng: "body",
        type: "noun"
    },
    {
        number: 291,
        rus: "молодой",
        eng: "young; bridegroom (as a noun)",
        type: "adjective",
    },
    {
        number: 292,
        rus: "стена",
        eng: "wall",
        type: "noun"
    },
    {
        number: 293,
        rus: "красный",
        eng: "red",
        type: "adjective"
    },
    {
        number: 294,
        rus: "читать",
        eng: "to read",
        type: "verb"
    },
    {
        number: 295,
        rus: "право",
        eng: "right",
        type: "noun"
    },
    {
        number: 296,
        rus: "старик",
        eng: "old man",
        type: "noun"
    },
    {
        number: 297,
        rus: "ранний",
        eng: "early",
        type: "adjective"
    },
    {
        number: 298,
        rus: "хотеться",
        eng: "want, like",
        type: "misc (impersonal)",
    },
    {
        number: 299,
        rus: "мама",
        eng: "mummy, mum",
        type: "noun"
    },
    {
        number: 300,
        rus: "оставаться",
        eng: "to remain, stay",
        type: "verb"
    },
    {
        number: 301,
        rus: "высокий",
        eng: "tall, high",
        type: "adjective"
    },
    {
        number: 302,
        rus: "путь",
        eng: "way, track, path",
        type: "noun"
    },
    {
        number: 303,
        rus: "поэтому",
        eng: "therefore",
        type: "adverb"
    },
    {
        number: 305,
        rus: "совершенно",
        eng: "absolutely, quite",
        type: "adverb"
    },
    {
        number: 306,
        rus: "кроме",
        eng: "except, besides",
        type: "preposition"
    },
    {
        number: 307,
        rus: "тысяча",
        eng: "a thousand",
        type: "cardinal number"
    },
    {
        number: 308,
        rus: "месяц",
        eng: "month",
        type: "noun"
    },
    {
        number: 309,
        rus: "брать",
        eng: "to take; hire",
        type: "verb"
    },
    {
        number: 310,
        rus: "написать",
        eng: "to write",
        type: "verb"
    },
    {
        number: 311,
        rus: "целый",
        eng: "intact, whole, entire",
        type: "adjective",
    },
    {
        number: 312,
        rus: "огромный",
        eng: "huge, enormous",
        type: "adjective"
    },
    {
        number: 313,
        rus: "начинать",
        eng: "to begin",
        type: "verb"
    },
    {
        number: 314,
        rus: "спина",
        eng: "back",
        type: "noun"
    },
    {
        number: 315,
        rus: "настоящий",
        eng: "present;real, true",
        type: "adjective",
    },
    {
        number: 316,
        rus: "пусть",
        eng: "let's, though",
        type: "conj, particle"
    },
    {
        number: 317,
        rus: "язык",
        eng: "tongue, language",
        type: "noun"
    },
    {
        number: 318,
        rus: "точно",
        eng: "exactly",
        type: "adverb"
    },
    {
        number: 319,
        rus: "среди",
        eng: "among",
        type: "preposition"
    },
    {
        number: 320,
        rus: "чувствовать",
        eng: "to feel",
        type: "verb"
    },
    {
        number: 321,
        rus: "сердце",
        eng: "heart",
        type: "noun"
    },
    {
        number: 322,
        rus: "вести",
        eng: "to lead",
        type: "verb"
    },
    {
        number: 323,
        rus: "иногда",
        eng: "sometimes",
        type: "adverb"
    },
    {
        number: 324,
        rus: "мальчик",
        eng: "boy",
        type: "noun"
    },
    {
        number: 325,
        rus: "успеть",
        eng: "to be in time, be successful",
        type: "verb",
    },
    {
        number: 326,
        rus: "небо",
        eng: "sky",
        type: "noun"
    },
    {
        number: 327,
        rus: "живой",
        eng: "living, live, lively",
        type: "adjective"
    },
    {
        number: 328,
        rus: "смерть",
        eng: "death",
        type: "noun"
    },
    {
        number: 329,
        rus: "продолжать",
        eng: "to continue",
        type: "verb"
    },
    {
        number: 330,
        rus: "девушка",
        eng: "girl, miss",
        type: "noun"
    },
    {
        number: 331,
        rus: "образ",
        eng: "shape, form, image",
        type: "noun"
    },
    {
        number: 332,
        rus: "ко",
        eng: "to, towards, by",
        type: "preposition"
    },
    {
        number: 333,
        rus: "забыть",
        eng: "to forget",
        type: "verb"
    },
    {
        number: 334,
        rus: "вокруг",
        eng: "around",
        type: "preposition"
    },
    {
        number: 335,
        rus: "письмо",
        eng: "letter",
        type: "noun"
    },
    {
        number: 336,
        rus: "власть",
        eng: "power",
        type: "noun"
    },
    {
        number: 337,
        rus: "чёрный",
        eng: "black",
        type: "adjective"
    },
    {
        number: 338,
        rus: "пройти",
        eng: "to pass, go by, be over",
        type: "verb"
    },
    {
        number: 339,
        rus: "появиться",
        eng: "to appear, show up",
        type: "verb"
    },
    {
        number: 340,
        rus: "воздух",
        eng: "air",
        type: "noun"
    },
    {
        number: 341,
        rus: "разный",
        eng: "different",
        type: "adjective"
    },
    {
        number: 342,
        rus: "выходить",
        eng: "to go out; nurse",
        type: "verb"
    },
    {
        number: 343,
        rus: "просить",
        eng: "to ask",
        type: "verb"
    },
    {
        number: 344,
        rus: "брат",
        eng: "brother",
        type: "noun"
    },
    {
        number: 345,
        rus: "собственный",
        eng: "one's own",
        type: "adjective",
    },
    {
        number: 346,
        rus: "отношение",
        eng: "relationship, attitude",
        type: "noun",
    },
    {
        number: 347,
        rus: "затем",
        eng: "then, after that",
        type: "adverb"
    },
    {
        number: 348,
        rus: "пытаться",
        eng: "to try",
        type: "verb"
    },
    {
        number: 349,
        rus: "показать",
        eng: "to show, display",
        type: "verb"
    },
    {
        number: 350,
        rus: "вспомнить",
        eng: "to remember, recall",
        type: "verb"
    },
    {
        number: 351,
        rus: "система",
        eng: "system",
        type: "noun"
    },
    {
        number: 352,
        rus: "четыре",
        eng: "four",
        type: "cardinal number"
    },
    {
        number: 353,
        rus: "квартира",
        eng: "flat, apartment",
        type: "noun"
    },
    {
        number: 354,
        rus: "держать",
        eng: "to hold, keep",
        type: "verb"
    },
    {
        number: 355,
        rus: "также",
        eng: "also, as well, too",
        type: "misc"
    },
    {
        number: 356,
        rus: "любовь",
        eng: "love",
        type: "noun"
    },
    {
        number: 357,
        rus: "солдат",
        eng: "soldier",
        type: "noun"
    },
    {
        number: 358,
        rus: "откуда",
        eng: "where… from",
        type: "adverb"
    },
    {
        number: 359,
        rus: "чтоб",
        eng: "that, in order that,",
        type: "misc",
    },
    {
        number: 360,
        rus: "называть",
        eng: "to call, name,",
        type: "verb",
    },
    {
        number: 361,
        rus: "третий",
        eng: "third",
        type: "ordinal number"
    },
    {
        number: 362,
        rus: "хозяин",
        eng: "master, boss, host",
        type: "noun"
    },
    {
        number: 363,
        rus: "вроде",
        eng: "like, not unlike",
        type: "preposition, particle",
    },
    {
        number: 364,
        rus: "уходить",
        eng: "to leave, go away",
        type: "verb"
    },
    {
        number: 365,
        rus: "подойти",
        eng: "to approach, come up",
        type: "verb",
    },
    {
        number: 366,
        rus: "поднять",
        eng: "to lift, raise",
        type: "verb"
    },
    {
        number: 367,
        rus: "особенно",
        eng: "especially, particularly",
        type: "adverb",
    },
    {
        number: 368,
        rus: "спрашивать",
        eng: "to ask, inquire",
        type: "verb"
    },
    {
        number: 369,
        rus: "начальник",
        eng: "chief, head, superior",
        type: "noun"
    },
    {
        number: 370,
        rus: "оба",
        eng: "both",
        type: "numeral"
    },
    {
        number: 371,
        rus: "бросить",
        eng: "to throw",
        type: "verb"
    },
    {
        number: 372,
        rus: "школа",
        eng: "school",
        type: "noun"
    },
    {
        number: 373,
        rus: "парень",
        eng: "boy, fellow, guy",
        type: "noun"
    },
    {
        number: 374,
        rus: "кровь",
        eng: "blood",
        type: "noun"
    },
    {
        number: 375,
        rus: "двадцать",
        eng: "twenty",
        type: "cardinal numberinal number",
    },
    {
        number: 376,
        rus: "солнце",
        eng: "sun",
        type: "noun"
    },
    {
        number: 377,
        rus: "неделя",
        eng: "week",
        type: "noun"
    },
    {
        number: 378,
        rus: "послать",
        eng: "to send, dispatch",
        type: "verb"
    },
    {
        number: 379,
        rus: "находиться",
        eng: "to be found, turn up",
        type: "verb"
    },
    {
        number: 380,
        rus: "ребята",
        eng: "guys, children",
        type: "noun"
    },
    {
        number: 381,
        rus: "поставить",
        eng: "to put, place, set",
        type: "verb"
    },
    {
        number: 382,
        rus: "встать",
        eng: "to get up, rise, stand up",
        type: "verb",
    },
    {
        number: 383,
        rus: "например",
        eng: "for example, for instance",
        type: "misc",
    },
    {
        number: 384,
        rus: "шаг",
        eng: "step",
        type: "noun"
    },
    {
        number: 385,
        rus: "мужчина",
        eng: "man, male",
        type: "noun"
    },
    {
        number: 386,
        rus: "равно",
        eng: "alike, in like manner",
        type: "adverb"
    },
    {
        number: 387,
        rus: "нос",
        eng: "nose",
        type: "noun"
    },
    {
        number: 388,
        rus: "мало",
        eng: "little, few,",
        type: "adverb"
    },
    {
        number: 389,
        rus: "внимание",
        eng: "attention",
        type: "noun"
    },
    {
        number: 390,
        rus: "капитан",
        eng: "captain, master",
        type: "noun"
    },
    {
        number: 391,
        rus: "ухо",
        eng: "ear",
        type: "noun"
    },
    {
        number: 392,
        rus: "туда",
        eng: "there",
        type: "adverb"
    },
    {
        number: 393,
        rus: "сюда",
        eng: "here",
        type: "adverb"
    },
    {
        number: 394,
        rus: "играть",
        eng: "to play",
        type: "verb"
    },
    {
        number: 395,
        rus: "следовать",
        eng: "to follow, come next",
        type: "verb"
    },
    {
        number: 396,
        rus: "рассказать",
        eng: "to tell, narrate",
        type: "verb",
    },
    {
        number: 397,
        rus: "великий",
        eng: "great",
        type: "adjective"
    },
    {
        number: 398,
        rus: "действительно",
        eng: "indeed, really",
        type: "adverb"
    },
    {
        number: 399,
        rus: "слишком",
        eng: "too, too much",
        type: "adverb"
    },
    {
        number: 400,
        rus: "тяжёлый",
        eng: "heavy",
        type: "adjective"
    },
    {
        number: 401,
        rus: "спать",
        eng: "to sleep",
        type: "verb"
    },
    {
        number: 402,
        rus: "оставить",
        eng: "to leave, abandon",
        type: "verb"
    },
    {
        number: 403,
        rus: "войти",
        eng: "to enter, come in",
        type: "verb"
    },
    {
        number: 404,
        rus: "длинный",
        eng: "long",
        type: "adjective"
    },
    {
        number: 406,
        rus: "чувство",
        eng: "feeling",
        type: "noun"
    },
    {
        number: 407,
        rus: "молчать",
        eng: "to keep silence",
        type: "verb"
    },
    {
        number: 408,
        rus: "рассказывать",
        eng: "to tell, narrate,",
        type: "verb",
    },
    {
        number: 409,
        rus: "отвечать",
        eng: "to answer, reply,",
        type: "verb",
    },
    {
        number: 410,
        rus: "становиться",
        eng: "to stand; to become",
        type: "verb"
    },
    {
        number: 411,
        rus: "остановиться",
        eng: "to stop",
        type: "verb"
    },
    {
        number: 412,
        rus: "берег",
        eng: "bank, shore, coast",
        type: "noun"
    },
    {
        number: 413,
        rus: "семья",
        eng: "family",
        type: "noun"
    },
    {
        number: 414,
        rus: "искать",
        eng: "to search",
        type: "verb"
    },
    {
        number: 415,
        rus: "генерал",
        eng: "general",
        type: "noun"
    },
    {
        number: 416,
        rus: "момент",
        eng: "moment, instant",
        type: "noun"
    },
    {
        number: 417,
        rus: "десять",
        eng: "ten",
        type: "cardinal number"
    },
    {
        number: 418,
        rus: "начать",
        eng: "to begin",
        type: "verb"
    },
    {
        number: 419,
        rus: "следующий",
        eng: "next, following",
        type: "adjective"
    },
    {
        number: 420,
        rus: "личный",
        eng: "personal",
        type: "adjective"
    },
    {
        number: 421,
        rus: "труд",
        eng: "labour, work",
        type: "noun"
    },
    {
        number: 422,
        rus: "верить",
        eng: "to believe",
        type: "verb",
    },
    {
        number: 423,
        rus: "группа",
        eng: "group",
        type: "noun"
    },
    {
        number: 424,
        rus: "немного",
        eng: "a little",
        type: "adverb"
    },
    {
        number: 425,
        rus: "впрочем",
        eng: "however, though",
        type: "conjunction"
    },
    {
        number: 426,
        rus: "видно",
        eng: "evidently, obviously",
        type: "misc"
    },
    {
        number: 427,
        rus: "являться",
        eng: "to appear",
        type: "verb"
    },
    {
        number: 428,
        rus: "муж",
        eng: "husband",
        type: "noun"
    },
    {
        number: 429,
        rus: "разве",
        eng: "really?, perhaps",
        type: "particle"
    },
    {
        number: 430,
        rus: "движение",
        eng: "movement",
        type: "noun"
    },
    {
        number: 431,
        rus: "порядок",
        eng: "order",
        type: "noun"
    },
    {
        number: 432,
        rus: "ответ",
        eng: "answer, reply",
        type: "noun"
    },
    {
        number: 433,
        rus: "тихо",
        eng: "quietly, softly, slowly",
        type: "adverb"
    },
    {
        number: 434,
        rus: "знакомый",
        eng: "familiar, acquainted",
        type: "adjective",
    },
    {
        number: 435,
        rus: "газета",
        eng: "newspaper",
        type: "noun"
    },
    {
        number: 436,
        rus: "помощь",
        eng: "help",
        type: "noun"
    },
    {
        number: 437,
        rus: "сильный",
        eng: "strong, powerful",
        type: "adjective"
    },
    {
        number: 438,
        rus: "скорый",
        eng: "quick, fast",
        type: "adjective"
    },
    {
        number: 439,
        rus: "собака",
        eng: "dog",
        type: "noun"
    },
    {
        number: 440,
        rus: "дерево",
        eng: "tree",
        type: "noun"
    },
    {
        number: 441,
        rus: "снег",
        eng: "snow",
        type: "noun"
    },
    {
        number: 442,
        rus: "сон",
        eng: "dream",
        type: "noun"
    },
    {
        number: 443,
        rus: "смысл",
        eng: "sense, meaning, purpose",
        type: "noun"
    },
    {
        number: 444,
        rus: "смочь",
        eng: "to be able",
        type: "verb"
    },
    {
        number: 445,
        rus: "против",
        eng: "against, opposite, contrary to",
        type: "preposition",
    },
    {
        number: 446,
        rus: "бежать",
        eng: "to run, hurry",
        type: "verb"
    },
    {
        number: 447,
        rus: "двор",
        eng: "yard, court",
        type: "noun"
    },
    {
        number: 448,
        rus: "форма",
        eng: "form, shape, uniform",
        type: "noun"
    },
    {
        number: 449,
        rus: "простой",
        eng: "simple, easy, plain",
        type: "adjective",
    },
    {
        number: 450,
        rus: "приехать",
        eng: "to arrive, come",
        type: "verb"
    },
    {
        number: 451,
        rus: "иной",
        eng: "different, other",
        type: "pronoun"
    },
    {
        number: 452,
        rus: "кричать",
        eng: "to cry, shout",
        type: "verb"
    },
    {
        number: 453,
        rus: "возможность",
        eng: "possibility, opportunity, chance",
        type: "noun",
    },
    {
        number: 454,
        rus: "общество",
        eng: "society",
        type: "noun"
    },
    {
        number: 455,
        rus: "зелёный",
        eng: "green",
        type: "adjective"
    },
    {
        number: 456,
        rus: "грудь",
        eng: "breast, chest",
        type: "noun"
    },
    {
        number: 457,
        rus: "угол",
        eng: "corner, angle",
        type: "noun"
    },
    {
        number: 458,
        rus: "открыть",
        eng: "to open",
        type: "verb"
    },
    {
        number: 459,
        rus: "происходить",
        eng: "to happen, occur, take place",
        type: "verb",
    },
    {
        number: 460,
        rus: "ладно",
        eng: "in harmony, well, all right",
        type: "adverb",
    },
    {
        number: 461,
        rus: "чёрный",
        eng: 'black (as in "she wears black")',
        type: "noun",
    },
    {
        number: 462,
        rus: "век",
        eng: "century, age",
        type: "noun"
    },
    {
        number: 463,
        rus: "карман",
        eng: "pocket",
        type: "noun"
    },
    {
        number: 464,
        rus: "ехать",
        eng: "to go, ride, drive, travel",
        type: "verb of motion",
    },
    {
        number: 465,
        rus: "немец",
        eng: "German",
        type: "noun"
    },
    {
        number: 466,
        rus: "наверное",
        eng: "probably, most likely",
        type: "adverb",
    },
    {
        number: 467,
        rus: "губа",
        eng: "lip",
        type: "noun"
    },
    {
        number: 468,
        rus: "дядя",
        eng: "uncle",
        type: "noun"
    },
    {
        number: 469,
        rus: "приходить",
        eng: "to come, arrive",
        type: "verb",
    },
    {
        number: 470,
        rus: "часто",
        eng: "often",
        type: "adverb"
    },
    {
        number: 471,
        rus: "домой",
        eng: 'home(as in "she came home")',
        type: "adverb",
    },
    {
        number: 472,
        rus: "огонь",
        eng: "fire",
        type: "noun"
    },
    {
        number: 473,
        rus: "писатель",
        eng: "writer",
        type: "noun"
    },
    {
        number: 474,
        rus: "армия",
        eng: "army",
        type: "noun"
    },
    {
        number: 475,
        rus: "состояние",
        eng: "state, condition; fortune",
        type: "noun",
    },
    {
        number: 476,
        rus: "зуб",
        eng: "tooth",
        type: "noun"
    },
    {
        number: 477,
        rus: "очередь",
        eng: "line, queue, turn",
        type: "noun"
    },
    {
        number: 478,
        rus: "кой",
        eng: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        type: "pronoun",
    },
    {
        number: 479,
        rus: "подняться",
        eng: "to rise, climb",
        type: "verb"
    },
    {
        number: 480,
        rus: "камень",
        eng: "stone",
        type: "noun"
    },
    {
        number: 481,
        rus: "гость",
        eng: "guest",
        type: "noun"
    },
    {
        number: 482,
        rus: "показаться",
        eng: "to appear, come in sight",
        type: "verb",
    },
    {
        number: 483,
        rus: "ветер",
        eng: "wind",
        type: "noun"
    },
    {
        number: 484,
        rus: "собираться",
        eng: "to gather together, assemble; intend",
        type: "verb",
    },
    {
        number: 485,
        rus: "попасть",
        eng: "to hit; to find oneself",
        type: "verb"
    },
    {
        number: 486,
        rus: "принять",
        eng: "to take, admit, accept",
        type: "verb",
    },
    {
        number: 487,
        rus: "сначала",
        eng: "at first, from the beginning",
        type: "adverb",
    },
    {
        number: 488,
        rus: "либо",
        eng: "or",
        type: "conjunction"
    },
    {
        number: 489,
        rus: "поехать",
        eng: "to depart, set off",
        type: "verb"
    },
    {
        number: 490,
        rus: "услышать",
        eng: "to hear",
        type: "verb"
    },
    {
        number: 491,
        rus: "уметь",
        eng: "to be able, know, can",
        type: "verb"
    },
    {
        number: 492,
        rus: "случиться",
        eng: "to happen",
        type: "verb"
    },
    {
        number: 493,
        rus: "странный",
        eng: "strange",
        type: "adjective"
    },
    {
        number: 494,
        rus: "единственный",
        eng: "only, sole",
        type: "adjective"
    },
    {
        number: 495,
        rus: "рота",
        eng: "company (military)",
        type: "noun"
    },
    {
        number: 496,
        rus: "закон",
        eng: "law, act, statute",
        type: "noun"
    },
    {
        number: 497,
        rus: "короткий",
        eng: "short",
        type: "adjective"
    },
    {
        number: 498,
        rus: "море",
        eng: "sea",
        type: "noun"
    },
    {
        number: 499,
        rus: "добрый",
        eng: "kind",
        type: "adjective"
    },
    {
        number: 500,
        rus: "тёмный",
        eng: "dark",
        type: "adjective"
    },
    {
        number: 501,
        rus: "гора",
        eng: "mountain, hill",
        type: "noun"
    },
    {
        number: 502,
        rus: "врач",
        eng: "physician, doctor",
        type: "noun"
    },
    {
        number: 503,
        rus: "край",
        eng: "border, edge; land, country",
        type: "noun",
    },
    {
        number: 504,
        rus: "стараться",
        eng: "to try, endeavour",
        type: "verb"
    },
    {
        number: 505,
        rus: "лучший",
        eng: "better, best",
        type: "adjective"
    },
    {
        number: 507,
        rus: "река",
        eng: "river",
        type: "noun"
    },
    {
        number: 508,
        rus: "военный",
        eng: "military",
        type: "adjective"
    },
    {
        number: 509,
        rus: "мера",
        eng: "measure, step",
        type: "noun"
    },
    {
        number: 510,
        rus: "страшный",
        eng: "terrible, frightful",
        type: "adjective",
    },
    {
        number: 511,
        rus: "вполне",
        eng: "quite, fully",
        type: "adverb"
    },
    {
        number: 512,
        rus: "звать",
        eng: "to call",
        type: "verb"
    },
    {
        number: 513,
        rus: "произойти",
        eng: "to happen, occur, take place",
        type: "verb",
    },
    {
        number: 514,
        rus: "вперед",
        eng: "forward",
        type: "preposition"
    },
    {
        number: 515,
        rus: "медленно",
        eng: "slowly",
        type: "adverb"
    },
    {
        number: 516,
        rus: "возле",
        eng: "by, near, close by",
        type: "preposition"
    },
    {
        number: 517,
        rus: "никак",
        eng: "in now way, by no means",
        type: "adverb"
    },
    {
        number: 518,
        rus: "заниматься",
        eng: "to be occupied, engage",
        type: "verb",
    },
    {
        number: 519,
        rus: "действие",
        eng: "action, effect",
        type: "noun"
    },
    {
        number: 520,
        rus: "довольно",
        eng: "enough; rather",
        type: "adverb"
    },
    {
        number: 521,
        rus: "вещь",
        eng: "thing",
        type: "noun"
    },
    {
        number: 522,
        rus: "необходимый",
        eng: "necessary",
        type: "adjective"
    },
    {
        number: 523,
        rus: "ход",
        eng: "move",
        type: "noun"
    },
    {
        number: 524,
        rus: "боль",
        eng: "pain",
        type: "noun"
    },
    {
        number: 525,
        rus: "судьба",
        eng: "fate, fortune, destiny",
        type: "noun"
    },
    {
        number: 526,
        rus: "причина",
        eng: "cause, reason, motive",
        type: "noun"
    },
    {
        number: 527,
        rus: "положить",
        eng: "to lay down, put down, place",
        type: "verb",
    },
    {
        number: 528,
        rus: "едва",
        eng: "hardly, just, barely",
        type: "adverb"
    },
    {
        number: 529,
        rus: "черта",
        eng: "line, boundary; trait",
        type: "noun"
    },
    {
        number: 530,
        rus: "девочка",
        eng: "girl, little girl",
        type: "noun",
    },
    {
        number: 531,
        rus: "лёгкий",
        eng: "light, easy",
        type: "adjective"
    },
    {
        number: 532,
        rus: "волос",
        eng: "hair",
        type: "noun"
    },
    {
        number: 533,
        rus: "купить",
        eng: "to buy, purchase",
        type: "verb"
    },
    {
        number: 534,
        rus: "номер",
        eng: "number, size, room, issue",
        type: "noun"
    },
    {
        number: 535,
        rus: "основной",
        eng: "main",
        type: "adjective"
    },
    {
        number: 536,
        rus: "широкий",
        eng: "wide",
        type: "adjective"
    },
    {
        number: 537,
        rus: "умереть",
        eng: "to die",
        type: "verb"
    },
    {
        number: 538,
        rus: "далеко",
        eng: "far, far off",
        type: "adverb"
    },
    {
        number: 539,
        rus: "плохо",
        eng: "badly",
        type: "adverb"
    },
    {
        number: 540,
        rus: "глава",
        eng: "head, chief",
        type: "noun"
    },
    {
        number: 541,
        rus: "красивый",
        eng: "beautiful",
        type: "adjective",
    },
    {
        number: 542,
        rus: "серый",
        eng: "grey; dull",
        type: "adjective"
    },
    {
        number: 543,
        rus: "пить",
        eng: "to drink",
        type: "verb"
    },
    {
        number: 544,
        rus: "командир",
        eng: "commander, commanding officer",
        type: "noun",
    },
    {
        number: 545,
        rus: "обычно",
        eng: "usually",
        type: "adverb"
    },
    {
        number: 546,
        rus: "партия",
        eng: "party",
        type: "noun"
    },
    {
        number: 547,
        rus: "проблема",
        eng: "problem, issue",
        type: "noun"
    },
    {
        number: 548,
        rus: "страх",
        eng: "fear",
        type: "noun"
    },
    {
        number: 549,
        rus: "проходить",
        eng: "to pass, go;study",
        type: "verb",
    },
    {
        number: 550,
        rus: "ясно",
        eng: "clear, clearly",
        type: "adverb"
    },
    {
        number: 551,
        rus: "снять",
        eng: "to take away, take off;photograph",
        type: "verb",
    },
    {
        number: 552,
        rus: "бумага",
        eng: "paper",
        type: "noun"
    },
    {
        number: 553,
        rus: "герой",
        eng: "hero",
        type: "noun"
    },
    {
        number: 554,
        rus: "пара",
        eng: "pair, couple",
        type: "noun"
    },
    {
        number: 555,
        rus: "государство",
        eng: "State",
        type: "noun"
    },
    {
        number: 556,
        rus: "деревня",
        eng: "village, country",
        type: "noun"
    },
    {
        number: 557,
        rus: "речь",
        eng: "speech",
        type: "noun"
    },
    {
        number: 558,
        rus: "начаться",
        eng: "to begin",
        type: "verb"
    },
    {
        number: 559,
        rus: "средство",
        eng: "means, remedy",
        type: "noun"
    },
    {
        number: 560,
        rus: "положение",
        eng: "position, posture, condition, state",
        type: "noun",
    },
    {
        number: 561,
        rus: "связь",
        eng: "tie, bond; connection, relation",
        type: "noun",
    },
    {
        number: 562,
        rus: "скоро",
        eng: "quickly, fast, soon",
        type: "adverb"
    },
    {
        number: 563,
        rus: "небольшой",
        eng: "small, not great",
        type: "adjective"
    },
    {
        number: 564,
        rus: "представлять",
        eng: "to present, introduce, imagine",
        type: "verb",
    },
    {
        number: 565,
        rus: "завтра",
        eng: "tomorrow",
        type: "adverb"
    },
    {
        number: 566,
        rus: "объяснить",
        eng: "to explain",
        type: "verb"
    },
    {
        number: 567,
        rus: "пустой",
        eng: "empty, hollow; idle",
        type: "adjective"
    },
    {
        number: 568,
        rus: "произнести",
        eng: "to pronounce, say, utter",
        type: "verb",
    },
    {
        number: 569,
        rus: "человеческий",
        eng: "human",
        type: "adjective"
    },
    {
        number: 570,
        rus: "нравиться",
        eng: "to please, like",
        type: "verb"
    },
    {
        number: 571,
        rus: "однажды",
        eng: "once, one day",
        type: "adverb"
    },
    {
        number: 572,
        rus: "мимо",
        eng: "past, by",
        type: "preposition"
    },
    {
        number: 573,
        rus: "иначе",
        eng: "otherwise, differentlyl",
        type: "adverb"
    },
    {
        number: 574,
        rus: "существовать",
        eng: "to exist, to be",
        type: "verb"
    },
    {
        number: 575,
        rus: "класс",
        eng: "class",
        type: "noun"
    },
    {
        number: 576,
        rus: "удаться",
        eng: "turn out well, succeed, manage",
        type: "verb",
    },
    {
        number: 577,
        rus: "толстый",
        eng: "thick, heavy, fat",
        type: "adjective"
    },
    {
        number: 578,
        rus: "цель",
        eng: "goal, object, target",
        type: "noun"
    },
    {
        number: 579,
        rus: "сквозь",
        eng: "through",
        type: "preposition"
    },
    {
        number: 580,
        rus: "прийтись",
        eng: "to fit, fall; have to",
        type: "verb"
    },
    {
        number: 581,
        rus: "чистый",
        eng: "clean, pure",
        type: "adjective"
    },
    {
        number: 582,
        rus: "знать",
        eng: "to know",
        type: "noun"
    },
    {
        number: 583,
        rus: "прежний",
        eng: "former",
        type: "adjective"
    },
    {
        number: 584,
        rus: "профессор",
        eng: "professor",
        type: "noun"
    },
    {
        number: 585,
        rus: "господин",
        eng: "gentleman, Mr.",
        type: "noun"
    },
    {
        number: 586,
        rus: "счастье",
        eng: "happiness, luck",
        type: "noun"
    },
    {
        number: 587,
        rus: "худой",
        eng: "thin, skinny",
        type: "adjective",
    },
    {
        number: 588,
        rus: "дух",
        eng: "spirit",
        type: "noun"
    },
    {
        number: 589,
        rus: "план",
        eng: "plan",
        type: "noun"
    },
    {
        number: 590,
        rus: "чужой",
        eng: "somebody else's;strange, foreign",
        type: "adjective",
    },
    {
        number: 591,
        rus: "зал",
        eng: "hall",
        type: "noun"
    },
    {
        number: 592,
        rus: "представить",
        eng: "to presetn, produce, introduce",
        type: "verb",
    },
    {
        number: 593,
        rus: "особый",
        eng: "special",
        type: "adjective"
    },
    {
        number: 594,
        rus: "директор",
        eng: "director, manager",
        type: "noun"
    },
    {
        number: 595,
        rus: "бывший",
        eng: "former, ex-",
        type: "adjective"
    },
    {
        number: 596,
        rus: "память",
        eng: "memory",
        type: "noun"
    },
    {
        number: 597,
        rus: "близкий",
        eng: "near, similar; intimate",
        type: "adjective",
    },
    {
        number: 598,
        rus: "сей",
        eng: "this",
        type: "pronoun"
    },
    {
        number: 599,
        rus: "результат",
        eng: "result, outcome",
        type: "noun"
    },
    {
        number: 600,
        rus: "больной",
        eng: "sick",
        type: "adjective"
    },
    {
        number: 601,
        rus: "данный",
        eng: "given, present",
        type: "adjective"
    },
    {
        number: 602,
        rus: "кстати",
        eng: "to the point, at the same time",
        type: "adverb",
    },
    {
        number: 603,
        rus: "назвать",
        eng: "to call, name",
        type: "verb"
    },
    {
        number: 604,
        rus: "след",
        eng: "track, footprint",
        type: "noun"
    },
    {
        number: 605,
        rus: "улыбаться",
        eng: "to smile",
        type: "verb"
    },
    {
        number: 606,
        rus: "бутылка",
        eng: "bottle",
        type: "noun"
    },
    {
        number: 608,
        rus: "трудно",
        eng: "with difficulty",
        type: "adverb"
    },
    {
        number: 609,
        rus: "условие",
        eng: "condition, term",
        type: "noun"
    },
    {
        number: 610,
        rus: "прежде",
        eng: "before",
        type: "preposition"
    },
    {
        number: 611,
        rus: "ум",
        eng: "mind, brains, intellect",
        type: "noun"
    },
    {
        number: 612,
        rus: "улыбнуться",
        eng: "to smile",
        type: "verb"
    },
    {
        number: 613,
        rus: "процесс",
        eng: "process",
        type: "noun"
    },
    {
        number: 614,
        rus: "картина",
        eng: "picture, painting",
        type: "noun"
    },
    {
        number: 615,
        rus: "вместо",
        eng: "instead",
        type: "preposition"
    },
    {
        number: 616,
        rus: "старший",
        eng: "elder, senior",
        type: "adjective"
    },
    {
        number: 617,
        rus: "легко",
        eng: "easily, lightly",
        type: "adverb"
    },
    {
        number: 618,
        rus: "центр",
        eng: "center",
        type: "noun"
    },
    {
        number: 619,
        rus: "подобный",
        eng: "similar, like",
        type: "adjective"
    },
    {
        number: 620,
        rus: "возможно",
        eng: 'as… as possible(as in "as soon as possible")',
        type: "adverb",
    },
    {
        number: 621,
        rus: "около",
        eng: "by, near",
        type: "preposition"
    },
    {
        number: 622,
        rus: "смеяться",
        eng: "to laugh",
        type: "verb"
    },
    {
        number: 623,
        rus: "сто",
        eng: "hundred",
        type: "cardinal numberinal number",
    },
    {
        number: 624,
        rus: "будущее",
        eng: "future",
        type: "noun"
    },
    {
        number: 625,
        rus: "хватать",
        eng: "to snatch, seize; be sufficient",
        type: "verb",
    },
    {
        number: 626,
        rus: "число",
        eng: "number",
        type: "noun"
    },
    {
        number: 627,
        rus: "всякое",
        eng: "any, every",
        type: "pronoun"
    },
    {
        number: 628,
        rus: "рубль",
        eng: "ruble",
        type: "noun"
    },
    {
        number: 629,
        rus: "почувствовать",
        eng: "to feel",
        type: "verb"
    },
    {
        number: 630,
        rus: "принести",
        eng: "to bring",
        type: "verb",
    },
    {
        number: 631,
        rus: "вера",
        eng: "faith, belief",
        type: "noun"
    },
    {
        number: 632,
        rus: "вовсе",
        eng: "quiet; not … at all",
        type: "adverb"
    },
    {
        number: 633,
        rus: "удар",
        eng: "blow, stroke",
        type: "noun"
    },
    {
        number: 634,
        rus: "телефон",
        eng: "telephone",
        type: "noun"
    },
    {
        number: 635,
        rus: "колено",
        eng: "knee",
        type: "noun"
    },
    {
        number: 636,
        rus: "согласиться",
        eng: "to agree, consent",
        type: "verb"
    },
    {
        number: 637,
        rus: "мало",
        eng: "little, few, not enough",
        type: "cardinal numberinal number",
    },
    {
        number: 638,
        rus: "коридор",
        eng: "corridor, passage",
        type: "noun"
    },
    {
        number: 639,
        rus: "мужик",
        eng: "muzhik, man",
        type: "noun"
    },
    {
        number: 640,
        rus: "правый",
        eng: "right",
        type: "adjective"
    },
    {
        number: 641,
        rus: "автор",
        eng: "author",
        type: "noun"
    },
    {
        number: 642,
        rus: "холодный",
        eng: "cold, cool",
        type: "adjective"
    },
    {
        number: 643,
        rus: "хватить",
        eng: "to snatch, seize, suffice",
        type: "verb",
    },
    {
        number: 644,
        rus: "многие",
        eng: "many",
        type: "pronoun"
    },
    {
        number: 645,
        rus: "встреча",
        eng: "meeting, reception",
        type: "noun"
    },
    {
        number: 646,
        rus: "кабинет",
        eng: "study, room, office suite",
        type: "noun",
    },
    {
        number: 647,
        rus: "документ",
        eng: "document",
        type: "noun"
    },
    {
        number: 648,
        rus: "самолёт",
        eng: "aircraft, aeroplane",
        type: "noun"
    },
    {
        number: 649,
        rus: "вниз",
        eng: "down, downwards",
        type: "adverb"
    },
    {
        number: 650,
        rus: "принимать",
        eng: "to take, admit, accept",
        type: "verb",
    },
    {
        number: 651,
        rus: "игра",
        eng: "game, play",
        type: "noun"
    },
    {
        number: 652,
        rus: "рассказ",
        eng: "story",
        type: "noun"
    },
    {
        number: 653,
        rus: "хлеб",
        eng: "bread",
        type: "noun"
    },
    {
        number: 654,
        rus: "развитие",
        eng: "development",
        type: "noun"
    },
    {
        number: 655,
        rus: "убить",
        eng: "to kill",
        type: "verb"
    },
    {
        number: 656,
        rus: "родной",
        eng: "own, native, dear",
        type: "adjective"
    },
    {
        number: 657,
        rus: "открытый",
        eng: "open",
        type: "adjective"
    },
    {
        number: 658,
        rus: "менее",
        eng: "less",
        type: "adverb"
    },
    {
        number: 659,
        rus: "предложить",
        eng: "to offer, propose, suggest",
        type: "verb",
    },
    {
        number: 660,
        rus: "жёлтый",
        eng: "yellow",
        type: "adjective"
    },
    {
        number: 661,
        rus: "приходиться",
        eng: "to fit, fall; have to",
        type: "verb",
    },
    {
        number: 662,
        rus: "выпить",
        eng: "to drink",
        type: "verb"
    },
    {
        number: 663,
        rus: "крикнуть",
        eng: "to cry, shout",
        type: "verb",
    },
    {
        number: 664,
        rus: "трубка",
        eng: "tube, roll, pipe",
        type: "noun"
    },
    {
        number: 665,
        rus: "враг",
        eng: "enemy",
        type: "noun"
    },
    {
        number: 666,
        rus: "показывать",
        eng: "to show, display",
        type: "verb"
    },
    {
        number: 667,
        rus: "двое",
        eng: "two",
        type: "cardinal number"
    },
    {
        number: 668,
        rus: "доктор",
        eng: "doctor",
        type: "noun"
    },
    {
        number: 669,
        rus: "ладонь",
        eng: "palm",
        type: "noun"
    },
    {
        number: 670,
        rus: "вызвать",
        eng: "to call, send",
        type: "verb"
    },
    {
        number: 671,
        rus: "спокойно",
        eng: "quietly",
        type: "adverb"
    },
    {
        number: 672,
        rus: "попросить",
        eng: "to ask",
        type: "verb"
    },
    {
        number: 673,
        rus: "наука",
        eng: "science",
        type: "noun"
    },
    {
        number: 674,
        rus: "лейтенант",
        eng: "lieutenant",
        type: "noun"
    },
    {
        number: 675,
        rus: "служба",
        eng: "service, work",
        type: "noun"
    },
    {
        number: 676,
        rus: "оказываться",
        eng: "to turn out, find oneself",
        type: "verb",
    },
    {
        number: 677,
        rus: "привести",
        eng: "to bring",
        type: "verb"
    },
    {
        number: 678,
        rus: "сорок",
        eng: "forty",
        type: "cardinal number"
    },
    {
        number: 679,
        rus: "счёт",
        eng: "bill, account",
        type: "noun"
    },
    {
        number: 680,
        rus: "возвращаться",
        eng: "to return",
        type: "verb"
    },
    {
        number: 681,
        rus: "золотой",
        eng: "golden",
        type: "adjective"
    },
    {
        number: 682,
        rus: "местный",
        eng: "local",
        type: "adjective"
    },
    {
        number: 683,
        rus: "кухня",
        eng: "kitchen",
        type: "noun"
    },
    {
        number: 684,
        rus: "крупный",
        eng: "large, big, prominent",
        type: "adjective",
    },
    {
        number: 685,
        rus: "решение",
        eng: "decision, conclusion",
        type: "noun"
    },
    {
        number: 686,
        rus: "молодая",
        eng: "bride; young (as an adjective)",
        type: "noun",
    },
    {
        number: 687,
        rus: "тридцать",
        eng: "thirty",
        type: "cardinal number"
    },
    {
        number: 688,
        rus: "роман",
        eng: "novel, romance",
        type: "noun"
    },
    {
        number: 689,
        rus: "требовать",
        eng: "to demand",
        type: "verb"
    },
    {
        number: 690,
        rus: "компания",
        eng: "company",
        type: "noun"
    },
    {
        number: 691,
        rus: "частый",
        eng: "frequent",
        type: "adjective"
    },
    {
        number: 692,
        rus: "российский",
        eng: "Russian",
        type: "adjective"
    },
    {
        number: 693,
        rus: "рабочий",
        eng: "working",
        type: "adjective"
    },
    {
        number: 694,
        rus: "потерять",
        eng: "to lose",
        type: "verb"
    },
    {
        number: 695,
        rus: "течение",
        eng: "current",
        type: "noun"
    },
    {
        number: 696,
        rus: "синий",
        eng: "dark blue",
        type: "adjective"
    },
    {
        number: 697,
        rus: "столько",
        eng: "so much, so many",
        type: "adverb"
    },
    {
        number: 698,
        rus: "тёплый",
        eng: "warm",
        type: "adjective"
    },
    {
        number: 699,
        rus: "метр",
        eng: "meter, metre",
        type: "noun"
    },
    {
        number: 700,
        rus: "достать",
        eng: "to reach; get, obtain",
        type: "verb"
    },
    {
        number: 701,
        rus: "железный",
        eng: "ferreous, iron",
        type: "adjective"
    },
    {
        number: 702,
        rus: "институт",
        eng: "institute",
        type: "noun"
    },
    {
        number: 703,
        rus: "сообщить",
        eng: "to report, let know",
        type: "verb"
    },
    {
        number: 704,
        rus: "интерес",
        eng: "interes",
        type: "noun"
    },
    {
        number: 705,
        rus: "обычный",
        eng: "usual, ordinary",
        type: "adjective"
    },
    {
        number: 706,
        rus: "появляться",
        eng: "to appear, show up",
        type: "verb",
    },
    {
        number: 707,
        rus: "упасть",
        eng: "to fall",
        type: "verb"
    },
    {
        number: 709,
        rus: "остальной",
        eng: "remaining, the rest of",
        type: "adjective",
    },
    {
        number: 710,
        rus: "половина",
        eng: "half",
        type: "noun"
    },
    {
        number: 711,
        rus: "московский",
        eng: "Moscow",
        type: "adjective"
    },
    {
        number: 712,
        rus: "шесть",
        eng: "six",
        type: "cardinal number"
    },
    {
        number: 713,
        rus: "получиться",
        eng: "to come, arrive, turn out",
        type: "verb",
    },
    {
        number: 714,
        rus: "качество",
        eng: "quality",
        type: "noun"
    },
    {
        number: 715,
        rus: "бой",
        eng: "battle, fight, combat",
        type: "noun"
    },
    {
        number: 716,
        rus: "шея",
        eng: "neck",
        type: "noun"
    },
    {
        number: 717,
        rus: "вон",
        eng: "out",
        type: "adverb"
    },
    {
        number: 718,
        rus: "идея",
        eng: "idea",
        type: "noun"
    },
    {
        number: 719,
        rus: "видимо",
        eng: "apparently, seemingly",
        type: "adverb"
    },
    {
        number: 720,
        rus: "достаточно",
        eng: "enough, sufficiently",
        type: "adverb",
    },
    {
        number: 721,
        rus: "провести",
        eng: "to accompany, set off; conduct; spend",
        type: "verb",
    },
    {
        number: 722,
        rus: "важный",
        eng: "important",
        type: "adjective"
    },
    {
        number: 723,
        rus: "трава",
        eng: "grass",
        type: "noun"
    },
    {
        number: 724,
        rus: "дед",
        eng: "grandfather, old man",
        type: "noun"
    },
    {
        number: 725,
        rus: "сознание",
        eng: "consciousness",
        type: "noun"
    },
    {
        number: 726,
        rus: "родитель",
        eng: "parent",
        type: "noun",
    },
    {
        number: 727,
        rus: "простить",
        eng: "to forgive",
        type: "verb"
    },
    {
        number: 728,
        rus: "бить",
        eng: "to beat, hit",
        type: "verb"
    },
    {
        number: 729,
        rus: "чай",
        eng: "tea",
        type: "noun"
    },
    {
        number: 730,
        rus: "поздний",
        eng: "late",
        type: "adjective"
    },
    {
        number: 731,
        rus: "кивнуть",
        eng: "to nod",
        type: "verb"
    },
    {
        number: 732,
        rus: "род",
        eng: "family, clan, generation",
        type: "noun"
    },
    {
        number: 733,
        rus: "исчезнуть",
        eng: "to disappear",
        type: "verb"
    },
    {
        number: 734,
        rus: "тонкий",
        eng: "thin",
        type: "adjective"
    },
    {
        number: 735,
        rus: "немецкий",
        eng: "German",
        type: "adjective"
    },
    {
        number: 736,
        rus: "звук",
        eng: "sound",
        type: "noun"
    },
    {
        number: 737,
        rus: "отдать",
        eng: "to return, give back",
        type: "verb"
    },
    {
        number: 738,
        rus: "магазин",
        eng: "shop, store",
        type: "noun"
    },
    {
        number: 739,
        rus: "президент",
        eng: "president; director",
        type: "noun"
    },
    {
        number: 740,
        rus: "поэт",
        eng: "poet",
        type: "noun"
    },
    {
        number: 741,
        rus: "спасибо",
        eng: "gratitude, thanks",
        type: "noun"
    },
    {
        number: 742,
        rus: "болезнь",
        eng: "illness, sickness, disease",
        type: "noun",
    },
    {
        number: 743,
        rus: "событие",
        eng: "event",
        type: "noun"
    },
    {
        number: 744,
        rus: "помочь",
        eng: "to help",
        type: "verb"
    },
    {
        number: 745,
        rus: "кожа",
        eng: "skin",
        type: "noun"
    },
    {
        number: 746,
        rus: "лист",
        eng: "leaf, sheet",
        type: "noun"
    },
    {
        number: 747,
        rus: "слать",
        eng: "to send",
        type: "verb"
    },
    {
        number: 748,
        rus: "вспоминать",
        eng: "to remember, recall",
        type: "verb"
    },
    {
        number: 749,
        rus: "прекрасный",
        eng: "beautiful, fine",
        type: "adjective"
    },
    {
        number: 750,
        rus: "слеза",
        eng: "tear",
        type: "noun"
    },
    {
        number: 751,
        rus: "надежда",
        eng: "hope",
        type: "noun"
    },
    {
        number: 752,
        rus: "молча",
        eng: "silently, without a word",
        type: "adverb",
    },
    {
        number: 753,
        rus: "сильно",
        eng: "strongly",
        type: "adverb"
    },
    {
        number: 754,
        rus: "верный",
        eng: "correct; faithful; reliable",
        type: "adjective",
    },
    {
        number: 755,
        rus: "литература",
        eng: "literature",
        type: "noun"
    },
    {
        number: 756,
        rus: "оружие",
        eng: "weapon",
        type: "noun"
    },
    {
        number: 757,
        rus: "готовый",
        eng: "ready",
        type: "adjective"
    },
    {
        number: 758,
        rus: "запах",
        eng: "smell, odour, scent",
        type: "noun"
    },
    {
        number: 759,
        rus: "неожиданно",
        eng: "unexpectedly, suddenly",
        type: "adverb",
    },
    {
        number: 760,
        rus: "вчера",
        eng: "yesterday",
        type: "adverb"
    },
    {
        number: 761,
        rus: "вздохнуть",
        eng: "to take breath, to sigh",
        type: "verb",
    },
    {
        number: 762,
        rus: "роль",
        eng: "role",
        type: "noun"
    },
    {
        number: 763,
        rus: "рост",
        eng: "growth, increase; height",
        type: "noun"
    },
    {
        number: 764,
        rus: "природа",
        eng: "nature",
        type: "noun"
    },
    {
        number: 765,
        rus: "политический",
        eng: "political",
        type: "adjective"
    },
    {
        number: 766,
        rus: "точка",
        eng: "dot, point",
        type: "noun"
    },
    {
        number: 767,
        rus: "звезда",
        eng: "star",
        type: "noun"
    },
    {
        number: 768,
        rus: "петь",
        eng: "to sing",
        type: "verb"
    },
    {
        number: 769,
        rus: "садиться",
        eng: "to sit down",
        type: "verb"
    },
    {
        number: 770,
        rus: "фамилия",
        eng: "surname",
        type: "noun"
    },
    {
        number: 771,
        rus: "характер",
        eng: "character, disposition, temper",
        type: "noun",
    },
    {
        number: 772,
        rus: "пожалуйста",
        eng: "please",
        type: "misc"
    },
    {
        number: 773,
        rus: "выше",
        eng: "higher",
        type: "adverb"
    },
    {
        number: 774,
        rus: "офицер",
        eng: "officer",
        type: "noun"
    },
    {
        number: 775,
        rus: "толпа",
        eng: "crowd",
        type: "noun"
    },
    {
        number: 776,
        rus: "перестать",
        eng: "to stop, cease",
        type: "verb"
    },
    {
        number: 777,
        rus: "придтись",
        eng: "to fit, fall; have to",
        type: "verb"
    },
    {
        number: 778,
        rus: "уровень",
        eng: "level",
        type: "noun"
    },
    {
        number: 779,
        rus: "неизвестный",
        eng: "unknown",
        type: "adjective"
    },
    {
        number: 780,
        rus: "кресло",
        eng: "armchair",
        type: "noun"
    },
    {
        number: 781,
        rus: "баба",
        eng: "woman, wife, old woman,",
        type: "noun",
    },
    {
        number: 782,
        rus: "секунда",
        eng: "a second",
        type: "noun"
    },
    {
        number: 783,
        rus: "пожаловать",
        eng: "to grant, bestow",
        type: "verb"
    },
    {
        number: 784,
        rus: "банк",
        eng: "bank",
        type: "noun"
    },
    {
        number: 785,
        rus: "опыт",
        eng: "experience",
        type: "noun"
    },
    {
        number: 786,
        rus: "тихий",
        eng: "quiet, low, scelent",
        type: "adjective"
    },
    {
        number: 787,
        rus: "поскольку",
        eng: "so far as, since",
        type: "misc"
    },
    {
        number: 788,
        rus: "сапог",
        eng: "(high) boot",
        type: "noun"
    },
    {
        number: 789,
        rus: "правило",
        eng: "rule",
        type: "noun"
    },
    {
        number: 790,
        rus: "стекло",
        eng: "glass",
        type: "noun"
    },
    {
        number: 791,
        rus: "получать",
        eng: "to receive, get",
        type: "verb",
    },
    {
        number: 792,
        rus: "внутренний",
        eng: "inside, internal",
        type: "adjective",
    },
    {
        number: 793,
        rus: "дочь",
        eng: "daughter",
        type: "noun"
    },
    {
        number: 794,
        rus: "называться",
        eng: "to call oneself",
        type: "verb"
    },
    {
        number: 795,
        rus: "надеяться",
        eng: "to hope",
        type: "verb"
    },
    {
        number: 796,
        rus: "член",
        eng: "member, limb",
        type: "noun"
    },
    {
        number: 797,
        rus: "протянуть",
        eng: "to stretch, extend",
        type: "verb"
    },
    {
        number: 798,
        rus: "государственный",
        eng: "State",
        type: "adjective"
    },
    {
        number: 799,
        rus: "десяток",
        eng: "ten, dozens",
        type: "noun"
    },
    {
        number: 800,
        rus: "глубокий",
        eng: "deep",
        type: "adjective"
    },
    {
        number: 801,
        rus: "цветок",
        eng: "flower",
        type: "noun"
    },
    {
        number: 803,
        rus: "желание",
        eng: "wish",
        type: "noun"
    },
    {
        number: 804,
        rus: "дождь",
        eng: "rain",
        type: "noun"
    },
    {
        number: 805,
        rus: "впереди",
        eng: "before, in front of",
        type: "preposition",
    },
    {
        number: 806,
        rus: "подходить",
        eng: "to come up, approach;to fit",
        type: "verb",
    },
    {
        number: 807,
        rus: "много",
        eng: "many, much, a lot of",
        type: "adverb",
    },
    {
        number: 808,
        rus: "лоб",
        eng: "forehead",
        type: "noun"
    },
    {
        number: 810,
        rus: "улыбка",
        eng: "smile",
        type: "noun"
    },
    {
        number: 811,
        rus: "борьба",
        eng: "fight, struggle",
        type: "noun"
    },
    {
        number: 812,
        rus: "ворот",
        eng: "collar",
        type: "noun"
    },
    {
        number: 813,
        rus: "ящик",
        eng: "box, case",
        type: "noun"
    },
    {
        number: 814,
        rus: "этаж",
        eng: "floor, storey",
        type: "noun"
    },
    {
        number: 815,
        rus: "служить",
        eng: "to serve",
        type: "verb"
    },
    {
        number: 816,
        rus: "вновь",
        eng: "again, once more",
        type: "adverb"
    },
    {
        number: 817,
        rus: "голубой",
        eng: "blue, sky-blue",
        type: "adjective"
    },
    {
        number: 818,
        rus: "нечего",
        eng: "there is nothing, there is no need",
        type: "misc",
    },
    {
        number: 819,
        rus: "революция",
        eng: "revolution",
        type: "noun"
    },
    {
        number: 820,
        rus: "впервые",
        eng: "for the first time",
        type: "adverb"
    },
    {
        number: 821,
        rus: "сосед",
        eng: "neighbour",
        type: "noun"
    },
    {
        number: 822,
        rus: "сестра",
        eng: "sister",
        type: "noun"
    },
    {
        number: 823,
        rus: "долгий",
        eng: "long",
        type: "adjective"
    },
    {
        number: 824,
        rus: "чей",
        eng: "whose",
        type: "pronoun"
    },
    {
        number: 825,
        rus: "поверить",
        eng: "to believe",
        type: "verb"
    },
    {
        number: 826,
        rus: "ситуация",
        eng: "situation",
        type: "noun"
    },
    {
        number: 827,
        rus: "взглянуть",
        eng: "to look, give a glance",
        type: "verb",
    },
    {
        number: 828,
        rus: "слабый",
        eng: "weak",
        type: "adjective"
    },
    {
        number: 829,
        rus: "количество",
        eng: "quantity",
        type: "noun"
    },
    {
        number: 830,
        rus: "вызывать",
        eng: "to call, send",
        type: "verb"
    },
    {
        number: 831,
        rus: "уверенный",
        eng: "confident, sure",
        type: "adjective"
    },
    {
        number: 832,
        rus: "выход",
        eng: "exit",
        type: "noun"
    },
    {
        number: 833,
        rus: "совет",
        eng: "advice",
        type: "noun",
    },
    {
        number: 834,
        rus: "дурак",
        eng: "fool, idiot",
        type: "noun"
    },
    {
        number: 835,
        rus: "любимый",
        eng: "dear, loved",
        type: "adjective"
    },
    {
        number: 836,
        rus: "союз",
        eng: "union, alliance; conjunction",
        type: "noun",
    },
    {
        number: 837,
        rus: "лето",
        eng: "summer",
        type: "noun"
    },
    {
        number: 838,
        rus: "ожидать",
        eng: "to expect",
        type: "verb"
    },
    {
        number: 839,
        rus: "пришлый",
        eng: "alien, strange",
        type: "adjective"
    },
    {
        number: 840,
        rus: "висеть",
        eng: "to hang",
        type: "verb"
    },
    {
        number: 841,
        rus: "граница",
        eng: "border",
        type: "noun"
    },
    {
        number: 842,
        rus: "цвет",
        eng: "colour, color",
        type: "noun"
    },
    {
        number: 843,
        rus: "серьёзный",
        eng: "serious",
        type: "adjective"
    },
    {
        number: 844,
        rus: "создать",
        eng: "to create, establish",
        type: "verb"
    },
    {
        number: 845,
        rus: "интересно",
        eng: "interestingly",
        type: "adverb"
    },
    {
        number: 846,
        rus: "свобода",
        eng: "freedom",
        type: "noun"
    },
    {
        number: 847,
        rus: "зато",
        eng: "but, but then",
        type: "conjunction"
    },
    {
        number: 848,
        rus: "стул",
        eng: "chair",
        type: "noun"
    },
    {
        number: 849,
        rus: "уехать",
        eng: "to leave, depart",
        type: "verb"
    },
    {
        number: 850,
        rus: "поезд",
        eng: "train",
        type: "noun"
    },
    {
        number: 851,
        rus: "музыка",
        eng: "music",
        type: "noun"
    },
    {
        number: 852,
        rus: "быстрый",
        eng: "quick, fast",
        type: "adjective"
    },
    {
        number: 853,
        rus: "тень",
        eng: "shadow",
        type: "noun"
    },
    {
        number: 854,
        rus: "лошадь",
        eng: "horse",
        type: "noun"
    },
    {
        number: 855,
        rus: "поле",
        eng: "field",
        type: "noun"
    },
    {
        number: 856,
        rus: "выглядеть",
        eng: "to look",
        type: "verb"
    },
    {
        number: 857,
        rus: "учиться",
        eng: "to study, learn",
        type: "verb"
    },
    {
        number: 858,
        rus: "левый",
        eng: "left",
        type: "adjective"
    },
    {
        number: 859,
        rus: "разговаривать",
        eng: "to talk, speak",
        type: "verb"
    },
    {
        number: 860,
        rus: "детский",
        eng: "child's, children's",
        type: "adjective",
    },
    {
        number: 861,
        rus: "тип",
        eng: "type, model",
        type: "noun"
    },
    {
        number: 862,
        rus: "суд",
        eng: "court, law-court",
        type: "noun"
    },
    {
        number: 863,
        rus: "связанный",
        eng: "related, connected, tied-up",
        type: "adjective",
    },
    {
        number: 864,
        rus: "горячий",
        eng: "hot",
        type: "adjective"
    },
    {
        number: 865,
        rus: "площадь",
        eng: "square, area",
        type: "noun"
    },
    {
        number: 866,
        rus: "помогать",
        eng: "to help, assist",
        type: "verb",
    },
    {
        number: 867,
        rus: "счастливый",
        eng: "happy",
        type: "adjective"
    },
    {
        number: 868,
        rus: "повернуться",
        eng: "to turn",
        type: "verb"
    },
    {
        number: 869,
        rus: "позволить",
        eng: "to allow, permit",
        type: "verb"
    },
    {
        number: 870,
        rus: "встретить",
        eng: "to meet",
        type: "verb"
    },
    {
        number: 871,
        rus: "радость",
        eng: "gladness, joy",
        type: "noun"
    },
    {
        number: 872,
        rus: "острый",
        eng: "sharp; spicy",
        type: "adjective"
    },
    {
        number: 873,
        rus: "возраст",
        eng: "age",
        type: "noun"
    },
    {
        number: 874,
        rus: "орган",
        eng: "organ, body",
        type: "noun"
    },
    {
        number: 875,
        rus: "карта",
        eng: "map",
        type: "noun"
    },
    {
        number: 876,
        rus: "входить",
        eng: "to enter, come in",
        type: "verb"
    },
    {
        number: 877,
        rus: "обнаружить",
        eng: "to discover, find out",
        type: "verb",
    },
    {
        number: 878,
        rus: "король",
        eng: "king",
        type: "noun"
    },
    {
        number: 879,
        rus: "слава",
        eng: "glory, fame",
        type: "noun"
    },
    {
        number: 880,
        rus: "полковник",
        eng: "colonel",
        type: "noun"
    },
    {
        number: 881,
        rus: "мелкий",
        eng: "shallow, small",
        type: "adjective"
    },
    {
        number: 882,
        rus: "бок",
        eng: "side, flank",
        type: "noun"
    },
    {
        number: 883,
        rus: "цена",
        eng: "price",
        type: "noun"
    },
    {
        number: 884,
        rus: "информация",
        eng: "information",
        type: "noun"
    },
    {
        number: 885,
        rus: "мозг",
        eng: "brain",
        type: "noun"
    },
    {
        number: 886,
        rus: "удовольствие",
        eng: "pleasure",
        type: "noun"
    },
    {
        number: 887,
        rus: "воля",
        eng: "will",
        type: "noun"
    },
    {
        number: 888,
        rus: "область",
        eng: "oblast, province; field",
        type: "noun"
    },
    {
        number: 889,
        rus: "крыша",
        eng: "roof",
        type: "noun"
    },
    {
        number: 890,
        rus: "нести",
        eng: "to carry",
        type: "verb"
    },
    {
        number: 891,
        rus: "обратно",
        eng: "back, backwards",
        type: "adverb"
    },
    {
        number: 892,
        rus: "современный",
        eng: "contemporary, modern, up-to-date",
        type: "adjective",
    },
    {
        number: 893,
        rus: "дама",
        eng: "lady, partner, queen",
        type: "noun"
    },
    {
        number: 894,
        rus: "семь",
        eng: "seven",
        type: "cardinal number"
    },
    {
        number: 895,
        rus: "весёлый",
        eng: "cheerful, merry",
        type: "adjective"
    },
    {
        number: 896,
        rus: "прислать",
        eng: "to send",
        type: "verb"
    },
    {
        number: 897,
        rus: "сад",
        eng: "garden, yard",
        type: "noun"
    },
    {
        number: 898,
        rus: "правительство",
        eng: "government",
        type: "noun"
    },
    {
        number: 899,
        rus: "милый",
        eng: "nice, sweet, dear",
        type: "adjective"
    },
    {
        number: 900,
        rus: "относиться",
        eng: "to treat, regard, relate",
        type: "verb",
    },
    {
        number: 901,
        rus: "возникать",
        eng: "to arise, appear",
        type: "verb",
    },
    {
        number: 902,
        rus: "мол",
        eng: "he says, they say, etc.",
        type: "misc"
    },
    {
        number: 903,
        rus: "повторить",
        eng: "to repeat",
        type: "verb"
    },
    {
        number: 904,
        rus: "название",
        eng: "name, title",
        type: "noun"
    },
    {
        number: 905,
        rus: "средний",
        eng: "average, middle",
        type: "adjective"
    },
    {
        number: 906,
        rus: "пример",
        eng: "example",
        type: "noun"
    },
    {
        number: 907,
        rus: "невозможно",
        eng: "impossible",
        type: "adverb"
    },
    {
        number: 908,
        rus: "зеркало",
        eng: "mirror",
        type: "noun"
    },
    {
        number: 909,
        rus: "погибнуть",
        eng: "to perish, be killed",
        type: "verb"
    },
    {
        number: 911,
        rus: "американский",
        eng: "American",
        type: "adjective"
    },
    {
        number: 912,
        rus: "дым",
        eng: "smoke",
        type: "noun"
    },
    {
        number: 913,
        rus: "гореть",
        eng: "to burn",
        type: "verb"
    },
    {
        number: 914,
        rus: "плакать",
        eng: "to cry",
        type: "verb"
    },
    {
        number: 915,
        rus: "весьма",
        eng: "very, highly, greatly",
        type: "adverb"
    },
    {
        number: 916,
        rus: "факт",
        eng: "fact",
        type: "noun"
    },
    {
        number: 917,
        rus: "двигаться",
        eng: "to move",
        type: "verb"
    },
    {
        number: 918,
        rus: "рыба",
        eng: "fish",
        type: "noun"
    },
    {
        number: 919,
        rus: "добавить",
        eng: "to add",
        type: "verb"
    },
    {
        number: 920,
        rus: "удивиться",
        eng: "to wonder, be surprised",
        type: "verb",
    },
    {
        number: 921,
        rus: "бабушка",
        eng: "grandmother",
        type: "noun"
    },
    {
        number: 922,
        rus: "вино",
        eng: "wine",
        type: "noun"
    },
    {
        number: 923,
        rus: "ибо",
        eng: "for",
        type: "conjunction"
    },
    {
        number: 924,
        rus: "учитель",
        eng: "teacher, instructor",
        type: "noun"
    },
    {
        number: 925,
        rus: "действовать",
        eng: "to act",
        type: "verb"
    },
    {
        number: 926,
        rus: "осторожно",
        eng: "carefully, cautiously",
        type: "adverb",
    },
    {
        number: 927,
        rus: "круг",
        eng: "circle",
        type: "noun",
    },
    {
        number: 928,
        rus: "папа",
        eng: "dad, daddy",
        type: "noun"
    },
    {
        number: 929,
        rus: "правильно",
        eng: "correctly, properly",
        type: "adverb"
    },
    {
        number: 930,
        rus: "недавно",
        eng: "recently, lately",
        type: "adverb"
    },
    {
        number: 931,
        rus: "держаться",
        eng: "to hold on, behave",
        type: "verb"
    },
    {
        number: 932,
        rus: "причём",
        eng: "moreover, and what's more",
        type: "adverb",
    },
    {
        number: 933,
        rus: "лететь",
        eng: "to fly",
        type: "verb"
    },
    {
        number: 934,
        rus: "носить",
        eng: "to wear",
        type: "verb"
    },
    {
        number: 935,
        rus: "повод",
        eng: "occasion, reason; bridle",
        type: "noun"
    },
    {
        number: 936,
        rus: "лагерь",
        eng: "camp",
        type: "noun"
    },
    {
        number: 937,
        rus: "птица",
        eng: "bird",
        type: "noun"
    },
    {
        number: 938,
        rus: "корабль",
        eng: "ship",
        type: "noun"
    },
    {
        number: 939,
        rus: "мнение",
        eng: "opinion",
        type: "noun"
    },
    {
        number: 940,
        rus: "ночной",
        eng: "night",
        type: "adjective"
    },
    {
        number: 941,
        rus: "здоровый",
        eng: "healthy",
        type: "adjective"
    },
    {
        number: 942,
        rus: "зима",
        eng: "winter",
        type: "noun"
    },
    {
        number: 943,
        rus: "сухой",
        eng: "dry",
        type: "adjective"
    },
    {
        number: 944,
        rus: "километр",
        eng: "kilometre, kilometer",
        type: "noun"
    },
    {
        number: 945,
        rus: "кровать",
        eng: "bed",
        type: "noun"
    },
    {
        number: 946,
        rus: "привыкнуть",
        eng: "to get used to, to grow accustomed to",
        type: "verb",
    },
    {
        number: 947,
        rus: "прочее",
        eng: "et cetera, other",
        type: "pronoun"
    },
    {
        number: 948,
        rus: "свободный",
        eng: "free",
        type: "adjective"
    },
    {
        number: 949,
        rus: "лестница",
        eng: "staircase",
        type: "noun"
    },
    {
        number: 950,
        rus: "неужели",
        eng: "really, surely",
        type: "particle"
    },
    {
        number: 951,
        rus: "обязательно",
        eng: "without fail, be sure",
        type: "adverb",
    },
    {
        number: 952,
        rus: "вверх",
        eng: "up, upwards",
        type: "adverb"
    },
    {
        number: 953,
        rus: "детство",
        eng: "childhood",
        type: "noun"
    },
    {
        number: 954,
        rus: "остров",
        eng: "island",
        type: "noun"
    },
    {
        number: 955,
        rus: "статья",
        eng: "article",
        type: "noun"
    },
    {
        number: 956,
        rus: "позвонить",
        eng: "to call, to telephone",
        type: "verb"
    },
    {
        number: 957,
        rus: "столь",
        eng: "so",
        type: "adverb"
    },
    {
        number: 958,
        rus: "мешать",
        eng: "to interfere, prevent; mix",
        type: "verb",
    },
    {
        number: 959,
        rus: "водка",
        eng: "vodka",
        type: "noun"
    },
    {
        number: 960,
        rus: "темнота",
        eng: "darkness",
        type: "noun"
    },
    {
        number: 961,
        rus: "возникнуть",
        eng: "to arise, spring up",
        type: "verb",
    },
    {
        number: 962,
        rus: "способный",
        eng: "capable (of)",
        type: "adjective"
    },
    {
        number: 963,
        rus: "станция",
        eng: "station",
        type: "noun"
    },
    {
        number: 964,
        rus: "желать",
        eng: "to wish",
        type: "verb"
    },
    {
        number: 965,
        rus: "попробовать",
        eng: "to try, test",
        type: "verb"
    },
    {
        number: 966,
        rus: "получаться",
        eng: "to come out",
        type: "verb"
    },
    {
        number: 967,
        rus: "гражданин",
        eng: "citizen",
        type: "noun"
    },
    {
        number: 968,
        rus: "странно",
        eng: "strangely",
        type: "adverb"
    },
    {
        number: 969,
        rus: "вскоре",
        eng: "soon, shortly",
        type: "adverb"
    },
    {
        number: 970,
        rus: "интересный",
        eng: "interesting",
        type: "adjective"
    },
    {
        number: 971,
        rus: "команда",
        eng: "team; command",
        type: "noun"
    },
    {
        number: 972,
        rus: "заболевание",
        eng: "disease, illness",
        type: "noun"
    },
    {
        number: 973,
        rus: "живот",
        eng: "stomach, belly",
        type: "noun"
    },
    {
        number: 974,
        rus: "ставить",
        eng: "to put, place, set",
        type: "verb"
    },
    {
        number: 975,
        rus: "ради",
        eng: "for the sake of",
        type: "preposition"
    },
    {
        number: 976,
        rus: "тишина",
        eng: "silence, stillness",
        type: "noun"
    },
    {
        number: 977,
        rus: "понятно",
        eng: "clearly, plainly",
        type: "adverb"
    },
    {
        number: 978,
        rus: "фронт",
        eng: "front",
        type: "noun"
    },
    {
        number: 979,
        rus: "щека",
        eng: "cheek",
        type: "noun"
    },
    {
        number: 980,
        rus: "страшно",
        eng: "terribly, awfully",
        type: "adverb"
    },
    {
        number: 981,
        rus: "район",
        eng: "area, region",
        type: "noun"
    },
    {
        number: 982,
        rus: "наверно",
        eng: "probably",
        type: "adverb"
    },
    {
        number: 983,
        rus: "проводить",
        eng: "to lead, escort; accompany",
        type: "verb",
    },
    {
        number: 984,
        rus: "выражение",
        eng: "expression",
        type: "noun"
    },
    {
        number: 985,
        rus: "слегка",
        eng: "slightly",
        type: "adverb"
    },
    {
        number: 986,
        rus: "мешок",
        eng: "bag, sack",
        type: "noun"
    },
    {
        number: 987,
        rus: "обещать",
        eng: "to promise",
        type: "verb"
    },
    {
        number: 988,
        rus: "дорогой",
        eng: "on the way",
        type: "adjective"
    },
    {
        number: 989,
        rus: "судить",
        eng: "to judge, try",
        type: "verb"
    },
    {
        number: 990,
        rus: "большинство",
        eng: "majority",
        type: "noun"
    },
    {
        number: 991,
        rus: "собраться",
        eng: "to gather, collect",
        type: "verb",
    },
    {
        number: 992,
        rus: "управление",
        eng: "operation, control",
        type: "noun"
    },
    {
        number: 993,
        rus: "колоть",
        eng: "to prick",
        type: "verb"
    },
    {
        number: 994,
        rus: "мокрый",
        eng: "wet",
        type: "adjective"
    },
    {
        number: 995,
        rus: "приказ",
        eng: "order",
        type: "noun"
    },
    {
        number: 996,
        rus: "прямой",
        eng: "straight",
        type: "adjective"
    },
    {
        number: 997,
        rus: "закричать",
        eng: "to shout, cry out",
        type: "verb"
    },
    {
        number: 998,
        rus: "кончиться",
        eng: "to end",
        type: "verb"
    },
    {
        number: 999,
        rus: "куст",
        eng: "bush",
        type: "noun"
    },
    {
        number: 1000,
        rus: "стрелять",
        eng: "to shoot",
        type: "verb"
    },
    {
        number: 1001,
        rus: "художник",
        eng: "painter, artist",
        type: "noun"
    },
    {
        number: 1002,
        rus: "знак",
        eng: "sign",
        type: "noun"
    },
    {
        number: 1003,
        rus: "завод",
        eng: "factory",
        type: "noun"
    },
    {
        number: 1004,
        rus: "кулак",
        eng: "fist",
        type: "noun"
    },
    {
        number: 1005,
        rus: "использовать",
        eng: "to use, utilize, make use of",
        type: "verb",
    },
    {
        number: 1006,
        rus: "стакан",
        eng: "glass",
        type: "noun"
    },
    {
        number: 1007,
        rus: "пахнуть",
        eng: "to smell",
        type: "verb"
    },
    {
        number: 1008,
        rus: "отсюда",
        eng: "from here",
        type: "adverb"
    },
    {
        number: 1009,
        rus: "рот",
        eng: "mouth",
        type: "noun"
    },
    {
        number: 1010,
        rus: "пора",
        eng: "it's time;at times, now and then",
        type: "misc",
    },
    {
        number: 1011,
        rus: "занят",
        eng: "busy",
        type: "adjective"
    },
    {
        number: 1012,
        rus: "уверен",
        eng: "sure",
        type: "adjective"
    },
    {
        number: 1013,
        rus: "устал",
        eng: "tired",
        type: "adjective"
    },
    {
        number: 1014,
        rus: "тома",
        eng: "Volume",
        type: "Noun"
    },
    {
        number: 1015,
        rus: "телевизор",
        eng: "TV",
        type: "Noun"
    },
    {
        number: 1016,
        rus: "что-то",
        eng: "something",
        type: "particle"
    },
    {
        number: 1017,
        rus: "москва",
        eng: "Moscow",
        type: "Noun"
    },
];
const words = [{
        R: "быть",
        E: "to be",
        T: "VI",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VN1",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду быть",
        E: "to be",
        T: "VF1",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VN2",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь быть",
        E: "to be",
        T: "VF2",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VN3",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет быть",
        E: "to be",
        T: "VF3",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VNW",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем быть",
        E: "to be",
        T: "VFW",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VNF",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете быть",
        E: "to be",
        T: "VFF",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to be",
        T: "VNT",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут быть",
        E: "to be",
        T: "VFT",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будь",
        E: "to be",
        T: "VI1",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будьте",
        E: "to be",
        T: "VI2",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "был",
        E: "to be",
        T: "VPM",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "была",
        E: "to be",
        T: "VPF",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "было",
        E: "to be",
        T: "VPN",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "были",
        E: "to be",
        T: "VPP",
        A: "I",
        O: "быть",
        RA: 8,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сказать",
        E: "to say, to speak",
        T: "VI",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажу",
        E: "to say, to speak",
        T: "VF1",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажешь",
        E: "to say, to speak",
        T: "VF2",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажет",
        E: "to say, to speak",
        T: "VF3",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажем",
        E: "to say, to speak",
        T: "VFW",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажете",
        E: "to say, to speak",
        T: "VFF",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажут",
        E: "to say, to speak",
        T: "VFT",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажи",
        E: "to say, to speak",
        T: "VI1",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скажите",
        E: "to say, to speak",
        T: "VI2",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сказал",
        E: "to say, to speak",
        T: "VPM",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сказала",
        E: "to say, to speak",
        T: "VPF",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сказало",
        E: "to say, to speak",
        T: "VPN",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сказали",
        E: "to say, to speak",
        T: "VPP",
        A: "P",
        O: "сказать",
        RA: 28,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мочь",
        E: "be able",
        T: "VI",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могу",
        E: "be able",
        T: "VN1",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду мочь",
        E: "be able",
        T: "VF1",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "можешь",
        E: "be able",
        T: "VN2",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь мочь",
        E: "be able",
        T: "VF2",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "может",
        E: "be able",
        T: "VN3",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет мочь",
        E: "be able",
        T: "VF3",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "можем",
        E: "be able",
        T: "VNW",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем мочь",
        E: "be able",
        T: "VFW",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "можете",
        E: "be able",
        T: "VNF",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете мочь",
        E: "be able",
        T: "VFF",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могут",
        E: "be able",
        T: "VNT",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут мочь",
        E: "be able",
        T: "VFT",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моги",
        E: "be able",
        T: "VI1",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могите",
        E: "be able",
        T: "VI2",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мог",
        E: "be able",
        T: "VPM",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могла",
        E: "be able",
        T: "VPF",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могло",
        E: "be able",
        T: "VPN",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "могли",
        E: "be able",
        T: "VPP",
        A: "I",
        O: "мочь",
        RA: 31,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорить",
        E: "to say, to tell, to speak",
        T: "VI",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорю",
        E: "to say, to tell, to speak",
        T: "VN1",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду говорить",
        E: "to say, to tell, to speak",
        T: "VF1",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говоришь",
        E: "to say, to tell, to speak",
        T: "VN2",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь говорить",
        E: "to say, to tell, to speak",
        T: "VF2",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорит",
        E: "to say, to tell, to speak",
        T: "VN3",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет говорить",
        E: "to say, to tell, to speak",
        T: "VF3",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорим",
        E: "to say, to tell, to speak",
        T: "VNW",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем говорить",
        E: "to say, to tell, to speak",
        T: "VFW",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорите",
        E: "to say, to tell, to speak",
        T: "VNF",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете говорить",
        E: "to say, to tell, to speak",
        T: "VFF",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорят",
        E: "to say, to tell, to speak",
        T: "VNT",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут говорить",
        E: "to say, to tell, to speak",
        T: "VFT",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говори",
        E: "to say, to tell, to speak",
        T: "VI1",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорите",
        E: "to say, to tell, to speak",
        T: "VI2",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорил",
        E: "to say, to tell, to speak",
        T: "VPM",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорила",
        E: "to say, to tell, to speak",
        T: "VPF",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорило",
        E: "to say, to tell, to speak",
        T: "VPN",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "говорили",
        E: "to say, to tell, to speak",
        T: "VPP",
        A: "I",
        O: "говорить",
        RA: 48,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знать",
        E: "to know, be aware",
        T: "VI",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знаю",
        E: "to know, be aware",
        T: "VN1",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду знать",
        E: "to know, be aware",
        T: "VF1",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знаешь",
        E: "to know, be aware",
        T: "VN2",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь знать",
        E: "to know, be aware",
        T: "VF2",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знает",
        E: "to know, be aware",
        T: "VN3",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет знать",
        E: "to know, be aware",
        T: "VF3",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знаем",
        E: "to know, be aware",
        T: "VNW",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем знать",
        E: "to know, be aware",
        T: "VFW",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знаете",
        E: "to know, be aware",
        T: "VNF",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете знать",
        E: "to know, be aware",
        T: "VFF",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знают",
        E: "to know, be aware",
        T: "VNT",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут знать",
        E: "to know, be aware",
        T: "VFT",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знай",
        E: "to know, be aware",
        T: "VI1",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знайте",
        E: "to know, be aware",
        T: "VI2",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знал",
        E: "to know, be aware",
        T: "VPM",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знала",
        E: "to know, be aware",
        T: "VPF",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знало",
        E: "to know, be aware",
        T: "VPN",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знали",
        E: "to know, be aware",
        T: "VPP",
        A: "I",
        O: "знать",
        RA: 51,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стать",
        E: "to become, begin, come",
        T: "VI",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стану",
        E: "to become, begin, come",
        T: "VF1",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станешь",
        E: "to become, begin, come",
        T: "VF2",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станет",
        E: "to become, begin, come",
        T: "VF3",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станем",
        E: "to become, begin, come",
        T: "VFW",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станете",
        E: "to become, begin, come",
        T: "VFF",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станут",
        E: "to become, begin, come",
        T: "VFT",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стань",
        E: "to become, begin, come",
        T: "VI1",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "станьте",
        E: "to become, begin, come",
        T: "VI2",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стал",
        E: "to become, begin, come",
        T: "VPM",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стала",
        E: "to become, begin, come",
        T: "VPF",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стало",
        E: "to become, begin, come",
        T: "VPN",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стали",
        E: "to become, begin, come",
        T: "VPP",
        A: "P",
        O: "стать",
        RA: 61,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "eat",
        T: "VI",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ем",
        E: "eat",
        T: "VN1",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду есть",
        E: "eat",
        T: "VF1",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ешь",
        E: "eat",
        T: "VN2",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь есть",
        E: "eat",
        T: "VF2",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ест",
        E: "eat",
        T: "VN3",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет есть",
        E: "eat",
        T: "VF3",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "едим",
        E: "eat",
        T: "VNW",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем есть",
        E: "eat",
        T: "VFW",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "едите",
        E: "eat",
        T: "VNF",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете есть",
        E: "eat",
        T: "VFF",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "едят",
        E: "eat",
        T: "VNT",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут есть",
        E: "eat",
        T: "VFT",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ешь",
        E: "eat",
        T: "VI1",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ешьте",
        E: "eat",
        T: "VI2",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ел",
        E: "eat",
        T: "VPM",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ела",
        E: "eat",
        T: "VPF",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ело",
        E: "eat",
        T: "VPN",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ели",
        E: "eat",
        T: "VPP",
        A: "I",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотеть",
        E: "to want, like",
        T: "VI",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хочу",
        E: "to want, like",
        T: "VN1",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду хотеть",
        E: "to want, like",
        T: "VF1",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хочешь",
        E: "to want, like",
        T: "VN2",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь хотеть",
        E: "to want, like",
        T: "VF2",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хочет",
        E: "to want, like",
        T: "VN3",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет хотеть",
        E: "to want, like",
        T: "VF3",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотим",
        E: "to want, like",
        T: "VNW",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем хотеть",
        E: "to want, like",
        T: "VFW",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотите",
        E: "to want, like",
        T: "VNF",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете хотеть",
        E: "to want, like",
        T: "VFF",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотят",
        E: "to want, like",
        T: "VNT",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут хотеть",
        E: "to want, like",
        T: "VFT",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хоти",
        E: "to want, like",
        T: "VI1",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотите",
        E: "to want, like",
        T: "VI2",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотел",
        E: "to want, like",
        T: "VPM",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотела",
        E: "to want, like",
        T: "VPF",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотело",
        E: "to want, like",
        T: "VPN",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотели",
        E: "to want, like",
        T: "VPP",
        A: "I",
        O: "хотеть",
        RA: 88,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видеть",
        E: "to see",
        T: "VI",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вижу",
        E: "to see",
        T: "VN1",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду видеть",
        E: "to see",
        T: "VF1",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видишь",
        E: "to see",
        T: "VN2",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь видеть",
        E: "to see",
        T: "VF2",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видит",
        E: "to see",
        T: "VN3",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет видеть",
        E: "to see",
        T: "VF3",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видим",
        E: "to see",
        T: "VNW",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем видеть",
        E: "to see",
        T: "VFW",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видите",
        E: "to see",
        T: "VNF",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете видеть",
        E: "to see",
        T: "VFF",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видят",
        E: "to see",
        T: "VNT",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут видеть",
        E: "to see",
        T: "VFT",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видь",
        E: "to see",
        T: "VI1",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видьте",
        E: "to see",
        T: "VI2",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видел",
        E: "to see",
        T: "VPM",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видела",
        E: "to see",
        T: "VPF",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видело",
        E: "to see",
        T: "VPN",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видели",
        E: "to see",
        T: "VPP",
        A: "I",
        O: "видеть",
        RA: 94,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идти",
        E: "to go, come",
        T: "VI",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иду",
        E: "to go, come",
        T: "VN1",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду идти",
        E: "to go, come",
        T: "VF1",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идёшь",
        E: "to go, come",
        T: "VN2",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь идти",
        E: "to go, come",
        T: "VF2",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идёт",
        E: "to go, come",
        T: "VN3",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет идти",
        E: "to go, come",
        T: "VF3",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идём",
        E: "to go, come",
        T: "VNW",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем идти",
        E: "to go, come",
        T: "VFW",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идёте",
        E: "to go, come",
        T: "VNF",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете идти",
        E: "to go, come",
        T: "VFF",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идут",
        E: "to go, come",
        T: "VNT",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут идти",
        E: "to go, come",
        T: "VFT",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иди",
        E: "to go, come",
        T: "VI1",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "идите",
        E: "to go, come",
        T: "VI2",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шёл",
        E: "to go, come",
        T: "VPM",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шла",
        E: "to go, come",
        T: "VPF",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шло",
        E: "to go, come",
        T: "VPN",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шли",
        E: "to go, come",
        T: "VPP",
        A: "I",
        O: "идти",
        RA: 95,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоять",
        E: "to stand, be, stand up",
        T: "VI",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стою",
        E: "to stand, be, stand up",
        T: "VN1",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду стоять",
        E: "to stand, be, stand up",
        T: "VF1",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоишь",
        E: "to stand, be, stand up",
        T: "VN2",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь стоять",
        E: "to stand, be, stand up",
        T: "VF2",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоит",
        E: "to stand, be, stand up",
        T: "VN3",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет стоять",
        E: "to stand, be, stand up",
        T: "VF3",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоим",
        E: "to stand, be, stand up",
        T: "VNW",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем стоять",
        E: "to stand, be, stand up",
        T: "VFW",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоите",
        E: "to stand, be, stand up",
        T: "VNF",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете стоять",
        E: "to stand, be, stand up",
        T: "VFF",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоят",
        E: "to stand, be, stand up",
        T: "VNT",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут стоять",
        E: "to stand, be, stand up",
        T: "VFT",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стой",
        E: "to stand, be, stand up",
        T: "VI1",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стойте",
        E: "to stand, be, stand up",
        T: "VI2",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стоял",
        E: "to stand, be, stand up",
        T: "VPM",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стояла",
        E: "to stand, be, stand up",
        T: "VPF",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стояло",
        E: "to stand, be, stand up",
        T: "VPN",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стояли",
        E: "to stand, be, stand up",
        T: "VPP",
        A: "I",
        O: "стоять",
        RA: 98,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думать",
        E: "to think",
        T: "VI",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думаю",
        E: "to think",
        T: "VN1",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду думать",
        E: "to think",
        T: "VF1",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думаешь",
        E: "to think",
        T: "VN2",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь думать",
        E: "to think",
        T: "VF2",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думает",
        E: "to think",
        T: "VN3",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет думать",
        E: "to think",
        T: "VF3",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думаем",
        E: "to think",
        T: "VNW",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем думать",
        E: "to think",
        T: "VFW",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думаете",
        E: "to think",
        T: "VNF",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете думать",
        E: "to think",
        T: "VFF",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думают",
        E: "to think",
        T: "VNT",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут думать",
        E: "to think",
        T: "VFT",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думай",
        E: "to think",
        T: "VI1",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думайте",
        E: "to think",
        T: "VI2",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думал",
        E: "to think",
        T: "VPM",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думала",
        E: "to think",
        T: "VPF",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думало",
        E: "to think",
        T: "VPN",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "думали",
        E: "to think",
        T: "VPP",
        A: "I",
        O: "думать",
        RA: 107,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросить",
        E: "to ask",
        T: "VI",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрошу",
        E: "to ask",
        T: "VF1",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросишь",
        E: "to ask",
        T: "VF2",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросит",
        E: "to ask",
        T: "VF3",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросим",
        E: "to ask",
        T: "VFW",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросите",
        E: "to ask",
        T: "VFF",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросят",
        E: "to ask",
        T: "VFT",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спроси",
        E: "to ask",
        T: "VI1",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросите",
        E: "to ask",
        T: "VI2",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросил",
        E: "to ask",
        T: "VPM",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросила",
        E: "to ask",
        T: "VPF",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросило",
        E: "to ask",
        T: "VPN",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спросили",
        E: "to ask",
        T: "VPP",
        A: "P",
        O: "спросить",
        RA: 109,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жить",
        E: "to live",
        T: "VI",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живу",
        E: "to live",
        T: "VN1",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду жить",
        E: "to live",
        T: "VF1",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живёшь",
        E: "to live",
        T: "VN2",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь жить",
        E: "to live",
        T: "VF2",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живёт",
        E: "to live",
        T: "VN3",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет жить",
        E: "to live",
        T: "VF3",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живём",
        E: "to live",
        T: "VNW",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем жить",
        E: "to live",
        T: "VFW",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живёте",
        E: "to live",
        T: "VNF",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете жить",
        E: "to live",
        T: "VFF",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живут",
        E: "to live",
        T: "VNT",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут жить",
        E: "to live",
        T: "VFT",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живи",
        E: "to live",
        T: "VI1",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живите",
        E: "to live",
        T: "VI2",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жил",
        E: "to live",
        T: "VPM",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жила",
        E: "to live",
        T: "VPF",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жило жило",
        E: "to live",
        T: "VPN",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жили",
        E: "to live",
        T: "VPP",
        A: "I",
        O: "жить",
        RA: 118,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотреть",
        E: "to look, watch",
        T: "VI",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрю",
        E: "to look, watch",
        T: "VN1",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду смотреть",
        E: "to look, watch",
        T: "VF1",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотришь",
        E: "to look, watch",
        T: "VN2",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь смотреть",
        E: "to look, watch",
        T: "VF2",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрит",
        E: "to look, watch",
        T: "VN3",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет смотреть",
        E: "to look, watch",
        T: "VF3",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрим",
        E: "to look, watch",
        T: "VNW",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем смотреть",
        E: "to look, watch",
        T: "VFW",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрите",
        E: "to look, watch",
        T: "VNF",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете смотреть",
        E: "to look, watch",
        T: "VFF",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрят",
        E: "to look, watch",
        T: "VNT",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут смотреть",
        E: "to look, watch",
        T: "VFT",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотри",
        E: "to look, watch",
        T: "VI1",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрите",
        E: "to look, watch",
        T: "VI2",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрел",
        E: "to look, watch",
        T: "VPM",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрела",
        E: "to look, watch",
        T: "VPF",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрело",
        E: "to look, watch",
        T: "VPN",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смотрели",
        E: "to look, watch",
        T: "VPP",
        A: "I",
        O: "смотреть",
        RA: 120,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидеть",
        E: "to sit",
        T: "VI",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сижу",
        E: "to sit",
        T: "VN1",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду сидеть",
        E: "to sit",
        T: "VF1",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидишь",
        E: "to sit",
        T: "VN2",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь сидеть",
        E: "to sit",
        T: "VF2",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидит",
        E: "to sit",
        T: "VN3",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет сидеть",
        E: "to sit",
        T: "VF3",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидим",
        E: "to sit",
        T: "VNW",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем сидеть",
        E: "to sit",
        T: "VFW",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидите",
        E: "to sit",
        T: "VNF",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете сидеть",
        E: "to sit",
        T: "VFF",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидят",
        E: "to sit",
        T: "VNT",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут сидеть",
        E: "to sit",
        T: "VFT",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сиди",
        E: "to sit",
        T: "VI1",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидите",
        E: "to sit",
        T: "VI2",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидел",
        E: "to sit",
        T: "VPM",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидела",
        E: "to sit",
        T: "VPF",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидело",
        E: "to sit",
        T: "VPN",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сидели",
        E: "to sit",
        T: "VPP",
        A: "I",
        O: "сидеть",
        RA: 126,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понять",
        E: "to understand",
        T: "VI",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойму",
        E: "to understand",
        T: "VF1",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймёшь",
        E: "to understand",
        T: "VF2",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймёт",
        E: "to understand",
        T: "VF3",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймём",
        E: "to understand",
        T: "VFW",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймёте",
        E: "to understand",
        T: "VFF",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймут",
        E: "to understand",
        T: "VFT",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойми",
        E: "to understand",
        T: "VI1",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поймите",
        E: "to understand",
        T: "VI2",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понял",
        E: "to understand",
        T: "VPM",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поняла",
        E: "to understand",
        T: "VPF",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поняло",
        E: "to understand",
        T: "VPN",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поняли",
        E: "to understand",
        T: "VPP",
        A: "P",
        O: "понять",
        RA: 127,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иметь",
        E: "to have, own",
        T: "VI",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имею",
        E: "to have, own",
        T: "VN1",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду иметь",
        E: "to have, own",
        T: "VF1",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имеешь",
        E: "to have, own",
        T: "VN2",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь иметь",
        E: "to have, own",
        T: "VF2",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имеет",
        E: "to have, own",
        T: "VN3",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет иметь",
        E: "to have, own",
        T: "VF3",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имеем",
        E: "to have, own",
        T: "VNW",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем иметь",
        E: "to have, own",
        T: "VFW",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имеете",
        E: "to have, own",
        T: "VNF",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете иметь",
        E: "to have, own",
        T: "VFF",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имеют",
        E: "to have, own",
        T: "VNT",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут иметь",
        E: "to have, own",
        T: "VFT",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имей",
        E: "to have, own",
        T: "VI1",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имейте",
        E: "to have, own",
        T: "VI2",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имел",
        E: "to have, own",
        T: "VPM",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имела",
        E: "to have, own",
        T: "VPF",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имело",
        E: "to have, own",
        T: "VPN",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "имели",
        E: "to have, own",
        T: "VPP",
        A: "I",
        O: "иметь",
        RA: 128,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делать",
        E: "to do, make",
        T: "VI",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делаю",
        E: "to do, make",
        T: "VN1",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду делать",
        E: "to do, make",
        T: "VF1",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делаешь",
        E: "to do, make",
        T: "VN2",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь делать",
        E: "to do, make",
        T: "VF2",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делает",
        E: "to do, make",
        T: "VN3",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет делать",
        E: "to do, make",
        T: "VF3",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делаем",
        E: "to do, make",
        T: "VNW",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем делать",
        E: "to do, make",
        T: "VFW",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делаете",
        E: "to do, make",
        T: "VNF",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете делать",
        E: "to do, make",
        T: "VFF",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делают",
        E: "to do, make",
        T: "VNT",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут делать",
        E: "to do, make",
        T: "VFT",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делай",
        E: "to do, make",
        T: "VI1",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делайте",
        E: "to do, make",
        T: "VI2",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делал",
        E: "to do, make",
        T: "VPM",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делала",
        E: "to do, make",
        T: "VPF",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делало",
        E: "to do, make",
        T: "VPN",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "делали",
        E: "to do, make",
        T: "VPP",
        A: "I",
        O: "делать",
        RA: 130,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взять",
        E: "to take",
        T: "VI",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьму",
        E: "to take",
        T: "VF1",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмёшь",
        E: "to take",
        T: "VF2",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмёт",
        E: "to take",
        T: "VF3",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмём",
        E: "to take",
        T: "VFW",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмёте",
        E: "to take",
        T: "VFF",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмут",
        E: "to take",
        T: "VFT",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьми",
        E: "to take",
        T: "VI1",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возьмите",
        E: "to take",
        T: "VI2",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взял",
        E: "to take",
        T: "VPM",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взяла",
        E: "to take",
        T: "VPF",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взяло взяло",
        E: "to take",
        T: "VPN",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взяли",
        E: "to take",
        T: "VPP",
        A: "P",
        O: "взять",
        RA: 133,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделать",
        E: "to do, make, finish",
        T: "VI",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделаю",
        E: "to do, make, finish",
        T: "VF1",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделаешь",
        E: "to do, make, finish",
        T: "VF2",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделает",
        E: "to do, make, finish",
        T: "VF3",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделаем",
        E: "to do, make, finish",
        T: "VFW",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделаете",
        E: "to do, make, finish",
        T: "VFF",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделают",
        E: "to do, make, finish",
        T: "VFT",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделай",
        E: "to do, make, finish",
        T: "VI1",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделайте",
        E: "to do, make, finish",
        T: "VI2",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделал",
        E: "to do, make, finish",
        T: "VPM",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделала",
        E: "to do, make, finish",
        T: "VPF",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделало",
        E: "to do, make, finish",
        T: "VPN",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сделали",
        E: "to do, make, finish",
        T: "VPP",
        A: "P",
        O: "сделать",
        RA: 135,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимать",
        E: "to understand",
        T: "VI",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимаю",
        E: "to understand",
        T: "VN1",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду понимать",
        E: "to understand",
        T: "VF1",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимаешь",
        E: "to understand",
        T: "VN2",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь понимать",
        E: "to understand",
        T: "VF2",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимает",
        E: "to understand",
        T: "VN3",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет понимать",
        E: "to understand",
        T: "VF3",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимаем",
        E: "to understand",
        T: "VNW",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем понимать",
        E: "to understand",
        T: "VFW",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимаете",
        E: "to understand",
        T: "VNF",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете понимать",
        E: "to understand",
        T: "VFF",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимают",
        E: "to understand",
        T: "VNT",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут понимать",
        E: "to understand",
        T: "VFT",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимай",
        E: "to understand",
        T: "VI1",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимайте",
        E: "to understand",
        T: "VI2",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимал",
        E: "to understand",
        T: "VPM",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимала",
        E: "to understand",
        T: "VPF",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимало",
        E: "to understand",
        T: "VPN",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понимали",
        E: "to understand",
        T: "VPP",
        A: "I",
        O: "понимать",
        RA: 139,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "казаться",
        E: "to seem, appear",
        T: "VI",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажусь",
        E: "to seem, appear",
        T: "VN1",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду казаться",
        E: "to seem, appear",
        T: "VF1",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажешься",
        E: "to seem, appear",
        T: "VN2",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь казаться",
        E: "to seem, appear",
        T: "VF2",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажется",
        E: "to seem, appear",
        T: "VN3",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет казаться",
        E: "to seem, appear",
        T: "VF3",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажемся",
        E: "to seem, appear",
        T: "VNW",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем казаться",
        E: "to seem, appear",
        T: "VFW",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажетесь",
        E: "to seem, appear",
        T: "VNF",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете казаться",
        E: "to seem, appear",
        T: "VFF",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажутся",
        E: "to seem, appear",
        T: "VNT",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут казаться",
        E: "to seem, appear",
        T: "VFT",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажись",
        E: "to seem, appear",
        T: "VI1",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кажитесь",
        E: "to seem, appear",
        T: "VI2",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "казался",
        E: "to seem, appear",
        T: "VPM",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "казалась",
        E: "to seem, appear",
        T: "VPF",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "казалось",
        E: "to seem, appear",
        T: "VPN",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "казались",
        E: "to seem, appear",
        T: "VPP",
        A: "I",
        O: "казаться",
        RA: 140,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давать",
        E: "to give; let, allow",
        T: "VI",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даю",
        E: "to give; let, allow",
        T: "VN1",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду давать",
        E: "to give; let, allow",
        T: "VF1",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даёшь",
        E: "to give; let, allow",
        T: "VN2",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь давать",
        E: "to give; let, allow",
        T: "VF2",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даёт",
        E: "to give; let, allow",
        T: "VN3",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет давать",
        E: "to give; let, allow",
        T: "VF3",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даём",
        E: "to give; let, allow",
        T: "VNW",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем давать",
        E: "to give; let, allow",
        T: "VFW",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даёте",
        E: "to give; let, allow",
        T: "VNF",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете давать",
        E: "to give; let, allow",
        T: "VFF",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дают",
        E: "to give; let, allow",
        T: "VNT",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут давать",
        E: "to give; let, allow",
        T: "VFT",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давай",
        E: "to give; let, allow",
        T: "VI1",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давайте",
        E: "to give; let, allow",
        T: "VI2",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давал",
        E: "to give; let, allow",
        T: "VPM",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давала",
        E: "to give; let, allow",
        T: "VPF",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давало",
        E: "to give; let, allow",
        T: "VPN",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давали",
        E: "to give; let, allow",
        T: "VPP",
        A: "I",
        O: "давать",
        RA: 155,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойти",
        E: "to go",
        T: "VI",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойду",
        E: "to go",
        T: "VF1",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдёшь",
        E: "to go",
        T: "VF2",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдёт",
        E: "to go",
        T: "VF3",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдём",
        E: "to go",
        T: "VFW",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдёте",
        E: "to go",
        T: "VFF",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдут",
        E: "to go",
        T: "VFT",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойди",
        E: "to go",
        T: "VI1",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойдите",
        E: "to go",
        T: "VI2",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошёл",
        E: "to go",
        T: "VPM",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошла",
        E: "to go",
        T: "VPF",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошло",
        E: "to go",
        T: "VPN",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошли",
        E: "to go",
        T: "VPP",
        A: "P",
        O: "пойти",
        RA: 162,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидеть",
        E: "to see",
        T: "VI",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увижу",
        E: "to see",
        T: "VF1",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидишь",
        E: "to see",
        T: "VF2",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидит",
        E: "to see",
        T: "VF3",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидим",
        E: "to see",
        T: "VFW",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидите",
        E: "to see",
        T: "VFF",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидят",
        E: "to see",
        T: "VFT",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидь",
        E: "to see",
        T: "VI1",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидьте",
        E: "to see",
        T: "VI2",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидел",
        E: "to see",
        T: "VPM",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидела",
        E: "to see",
        T: "VPF",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидело",
        E: "to see",
        T: "VPN",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "увидели",
        E: "to see",
        T: "VPP",
        A: "P",
        O: "увидеть",
        RA: 165,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаться",
        E: "to remain, stay",
        T: "VI",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останусь",
        E: "to remain, stay",
        T: "VF1",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останешься",
        E: "to remain, stay",
        T: "VF2",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останется",
        E: "to remain, stay",
        T: "VF3",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останемся",
        E: "to remain, stay",
        T: "VFW",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останетесь",
        E: "to remain, stay",
        T: "VFF",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останутся",
        E: "to remain, stay",
        T: "VFT",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останься",
        E: "to remain, stay",
        T: "VI1",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "останьтесь",
        E: "to remain, stay",
        T: "VI2",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остался",
        E: "to remain, stay",
        T: "VPM",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "осталась",
        E: "to remain, stay",
        T: "VPF",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "осталось",
        E: "to remain, stay",
        T: "VPN",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остались",
        E: "to remain, stay",
        T: "VPP",
        A: "P",
        O: "остаться",
        RA: 175,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйти",
        E: "to go out, come out, appear",
        T: "VI",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйду",
        E: "to go out, come out, appear",
        T: "VF1",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдешь",
        E: "to go out, come out, appear",
        T: "VF2",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдет",
        E: "to go out, come out, appear",
        T: "VF3",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдем",
        E: "to go out, come out, appear",
        T: "VFW",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдете",
        E: "to go out, come out, appear",
        T: "VFF",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдут",
        E: "to go out, come out, appear",
        T: "VFT",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйди",
        E: "to go out, come out, appear",
        T: "VI1",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выйдите",
        E: "to go out, come out, appear",
        T: "VI2",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вышел",
        E: "to go out, come out, appear",
        T: "VPM",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вышла",
        E: "to go out, come out, appear",
        T: "VPF",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вышло",
        E: "to go out, come out, appear",
        T: "VPN",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вышли",
        E: "to go out, come out, appear",
        T: "VPP",
        A: "P",
        O: "выйти",
        RA: 178,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дать",
        E: "to give",
        T: "VI",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дам",
        E: "to give",
        T: "VF1",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дашь",
        E: "to give",
        T: "VF2",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даст",
        E: "to give",
        T: "VF3",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дадим",
        E: "to give",
        T: "VFW",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дадите",
        E: "to give",
        T: "VFF",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дадут",
        E: "to give",
        T: "VFT",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дай",
        E: "to give",
        T: "VI1",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дайте",
        E: "to give",
        T: "VI2",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дал",
        E: "to give",
        T: "VPM",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дала",
        E: "to give",
        T: "VPF",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дало дало",
        E: "to give",
        T: "VPN",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дали",
        E: "to give",
        T: "VPP",
        A: "P",
        O: "дать",
        RA: 179,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работать",
        E: "to work",
        T: "VI",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работаю",
        E: "to work",
        T: "VN1",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду работать",
        E: "to work",
        T: "VF1",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работаешь",
        E: "to work",
        T: "VN2",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь работать",
        E: "to work",
        T: "VF2",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работает",
        E: "to work",
        T: "VN3",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет работать",
        E: "to work",
        T: "VF3",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работаем",
        E: "to work",
        T: "VNW",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем работать",
        E: "to work",
        T: "VFW",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работаете",
        E: "to work",
        T: "VNF",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете работать",
        E: "to work",
        T: "VFF",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работают",
        E: "to work",
        T: "VNT",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут работать",
        E: "to work",
        T: "VFT",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работай",
        E: "to work",
        T: "VI1",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работайте",
        E: "to work",
        T: "VI2",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работал",
        E: "to work",
        T: "VPM",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работала",
        E: "to work",
        T: "VPF",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работало",
        E: "to work",
        T: "VPN",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "работали",
        E: "to work",
        T: "VPP",
        A: "I",
        O: "работать",
        RA: 180,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любить",
        E: "to love",
        T: "VI",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "люблю",
        E: "to love",
        T: "VN1",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду любить",
        E: "to love",
        T: "VF1",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любишь",
        E: "to love",
        T: "VN2",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь любить",
        E: "to love",
        T: "VF2",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любит",
        E: "to love",
        T: "VN3",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет любить",
        E: "to love",
        T: "VF3",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любим",
        E: "to love",
        T: "VNW",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем любить",
        E: "to love",
        T: "VFW",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любите",
        E: "to love",
        T: "VNF",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете любить",
        E: "to love",
        T: "VFF",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любят",
        E: "to love",
        T: "VNT",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут любить",
        E: "to love",
        T: "VFT",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "люби",
        E: "to love",
        T: "VI1",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любите",
        E: "to love",
        T: "VI2",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любил",
        E: "to love",
        T: "VPM",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любила",
        E: "to love",
        T: "VPF",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любило",
        E: "to love",
        T: "VPN",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любили",
        E: "to love",
        T: "VPP",
        A: "I",
        O: "любить",
        RA: 181,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказаться",
        E: "find oneself, turn out",
        T: "VI",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажусь",
        E: "find oneself, turn out",
        T: "VF1",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажешься",
        E: "find oneself, turn out",
        T: "VF2",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажется",
        E: "find oneself, turn out",
        T: "VF3",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажемся",
        E: "find oneself, turn out",
        T: "VFW",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажетесь",
        E: "find oneself, turn out",
        T: "VFF",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажутся",
        E: "find oneself, turn out",
        T: "VFT",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажись",
        E: "find oneself, turn out",
        T: "VI1",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "окажитесь",
        E: "find oneself, turn out",
        T: "VI2",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказался",
        E: "find oneself, turn out",
        T: "VPM",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказалась",
        E: "find oneself, turn out",
        T: "VPF",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказалось",
        E: "find oneself, turn out",
        T: "VPN",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказались",
        E: "find oneself, turn out",
        T: "VPP",
        A: "P",
        O: "оказаться",
        RA: 185,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответить",
        E: "to answer, reply",
        T: "VI",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечу",
        E: "to answer, reply",
        T: "VF1",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответишь",
        E: "to answer, reply",
        T: "VF2",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответит",
        E: "to answer, reply",
        T: "VF3",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответим",
        E: "to answer, reply",
        T: "VFW",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответите",
        E: "to answer, reply",
        T: "VFF",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответят",
        E: "to answer, reply",
        T: "VFT",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответь",
        E: "to answer, reply",
        T: "VI1",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответьте",
        E: "to answer, reply",
        T: "VI2",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответил",
        E: "to answer, reply",
        T: "VPM",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответила",
        E: "to answer, reply",
        T: "VPF",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответило",
        E: "to answer, reply",
        T: "VPN",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ответили",
        E: "to answer, reply",
        T: "VPP",
        A: "P",
        O: "ответить",
        RA: 192,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумать",
        E: "to think",
        T: "VI",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумаю",
        E: "to think",
        T: "VF1",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумаешь",
        E: "to think",
        T: "VF2",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумает",
        E: "to think",
        T: "VF3",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумаем",
        E: "to think",
        T: "VFW",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумаете",
        E: "to think",
        T: "VFF",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумают",
        E: "to think",
        T: "VFT",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумай",
        E: "to think",
        T: "VI1",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумайте",
        E: "to think",
        T: "VI2",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумал",
        E: "to think",
        T: "VPM",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумала",
        E: "to think",
        T: "VPF",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумало",
        E: "to think",
        T: "VPN",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подумали",
        E: "to think",
        T: "VPP",
        A: "P",
        O: "подумать",
        RA: 194,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значить",
        E: "to mean, signify",
        T: "VI",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значу",
        E: "to mean, signify",
        T: "VN1",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду значить",
        E: "to mean, signify",
        T: "VF1",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значишь",
        E: "to mean, signify",
        T: "VN2",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь значить",
        E: "to mean, signify",
        T: "VF2",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значит",
        E: "to mean, signify",
        T: "VN3",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет значить",
        E: "to mean, signify",
        T: "VF3",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значим",
        E: "to mean, signify",
        T: "VNW",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем значить",
        E: "to mean, signify",
        T: "VFW",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значите",
        E: "to mean, signify",
        T: "VNF",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете значить",
        E: "to mean, signify",
        T: "VFF",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значат",
        E: "to mean, signify",
        T: "VNT",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут значить",
        E: "to mean, signify",
        T: "VFT",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значь",
        E: "to mean, signify",
        T: "VI1",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значьте",
        E: "to mean, signify",
        T: "VI2",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значил",
        E: "to mean, signify",
        T: "VPM",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значила",
        E: "to mean, signify",
        T: "VPF",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значило",
        E: "to mean, signify",
        T: "VPN",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "значили",
        E: "to mean, signify",
        T: "VPP",
        A: "I",
        O: "значить",
        RA: 198,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотреть",
        E: "to take a look, watch, inspect",
        T: "VI",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрю",
        E: "to take a look, watch, inspect",
        T: "VF1",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотришь",
        E: "to take a look, watch, inspect",
        T: "VF2",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрит",
        E: "to take a look, watch, inspect",
        T: "VF3",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрим",
        E: "to take a look, watch, inspect",
        T: "VFW",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрите",
        E: "to take a look, watch, inspect",
        T: "VFF",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрят",
        E: "to take a look, watch, inspect",
        T: "VFT",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотри",
        E: "to take a look, watch, inspect",
        T: "VI1",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрите",
        E: "to take a look, watch, inspect",
        T: "VI2",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрел",
        E: "to take a look, watch, inspect",
        T: "VPM",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрела",
        E: "to take a look, watch, inspect",
        T: "VPF",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрело",
        E: "to take a look, watch, inspect",
        T: "VPN",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "посмотрели",
        E: "to take a look, watch, inspect",
        T: "VPP",
        A: "P",
        O: "посмотреть",
        RA: 204,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждать",
        E: "to wait",
        T: "VI",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жду",
        E: "to wait",
        T: "VN1",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду ждать",
        E: "to wait",
        T: "VF1",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждёшь",
        E: "to wait",
        T: "VN2",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь ждать",
        E: "to wait",
        T: "VF2",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждёт",
        E: "to wait",
        T: "VN3",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет ждать",
        E: "to wait",
        T: "VF3",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждём",
        E: "to wait",
        T: "VNW",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем ждать",
        E: "to wait",
        T: "VFW",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждёте",
        E: "to wait",
        T: "VNF",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете ждать",
        E: "to wait",
        T: "VFF",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждут",
        E: "to wait",
        T: "VNT",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут ждать",
        E: "to wait",
        T: "VFT",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жди",
        E: "to wait",
        T: "VI1",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждите",
        E: "to wait",
        T: "VI2",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждал",
        E: "to wait",
        T: "VPM",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждала",
        E: "to wait",
        T: "VPF",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждало",
        E: "to wait",
        T: "VPN",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ждали",
        E: "to wait",
        T: "VPP",
        A: "I",
        O: "ждать",
        RA: 209,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежать",
        E: "to lie, be situated",
        T: "VI",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежу",
        E: "to lie, be situated",
        T: "VN1",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду лежать",
        E: "to lie, be situated",
        T: "VF1",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежишь",
        E: "to lie, be situated",
        T: "VN2",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь лежать",
        E: "to lie, be situated",
        T: "VF2",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежит",
        E: "to lie, be situated",
        T: "VN3",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет лежать",
        E: "to lie, be situated",
        T: "VF3",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежим",
        E: "to lie, be situated",
        T: "VNW",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем лежать",
        E: "to lie, be situated",
        T: "VFW",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежите",
        E: "to lie, be situated",
        T: "VNF",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете лежать",
        E: "to lie, be situated",
        T: "VFF",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежат",
        E: "to lie, be situated",
        T: "VNT",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут лежать",
        E: "to lie, be situated",
        T: "VFT",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежи",
        E: "to lie, be situated",
        T: "VI1",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежите",
        E: "to lie, be situated",
        T: "VI2",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежал",
        E: "to lie, be situated",
        T: "VPM",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежала",
        E: "to lie, be situated",
        T: "VPF",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежало",
        E: "to lie, be situated",
        T: "VPN",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лежали",
        E: "to lie, be situated",
        T: "VPP",
        A: "I",
        O: "лежать",
        RA: 216,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найти",
        E: "to find, discover, consider",
        T: "VI",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найду",
        E: "to find, discover, consider",
        T: "VF1",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдёшь",
        E: "to find, discover, consider",
        T: "VF2",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдёт",
        E: "to find, discover, consider",
        T: "VF3",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдём",
        E: "to find, discover, consider",
        T: "VFW",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдёте",
        E: "to find, discover, consider",
        T: "VFF",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдут",
        E: "to find, discover, consider",
        T: "VFT",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найди",
        E: "to find, discover, consider",
        T: "VI1",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "найдите",
        E: "to find, discover, consider",
        T: "VI2",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашёл",
        E: "to find, discover, consider",
        T: "VPM",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашла",
        E: "to find, discover, consider",
        T: "VPF",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашло",
        E: "to find, discover, consider",
        T: "VPN",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашли",
        E: "to find, discover, consider",
        T: "VPP",
        A: "P",
        O: "найти",
        RA: 220,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писать",
        E: "to write",
        T: "VI",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишу",
        E: "to write",
        T: "VN1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду писать",
        E: "to write",
        T: "VF1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишешь",
        E: "to write",
        T: "VN2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь писать",
        E: "to write",
        T: "VF2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишет",
        E: "to write",
        T: "VN3",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет писать",
        E: "to write",
        T: "VF3",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишем",
        E: "to write",
        T: "VNW",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем писать",
        E: "to write",
        T: "VFW",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишете",
        E: "to write",
        T: "VNF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете писать",
        E: "to write",
        T: "VFF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишут",
        E: "to write",
        T: "VNT",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут писать",
        E: "to write",
        T: "VFT",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пиши",
        E: "to write",
        T: "VI1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пишите",
        E: "to write",
        T: "VI2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писал",
        E: "to write",
        T: "VPM",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писала",
        E: "to write",
        T: "VPF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писало",
        E: "to write",
        T: "VPN",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писали",
        E: "to write",
        T: "VPP",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писать",
        E: "pee",
        T: "VI",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писаю",
        E: "pee",
        T: "VN1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду писать",
        E: "pee",
        T: "VF1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писаешь",
        E: "pee",
        T: "VN2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь писать",
        E: "pee",
        T: "VF2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писает",
        E: "pee",
        T: "VN3",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет писать",
        E: "pee",
        T: "VF3",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писаем",
        E: "pee",
        T: "VNW",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем писать",
        E: "pee",
        T: "VFW",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писаете",
        E: "pee",
        T: "VNF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете писать",
        E: "pee",
        T: "VFF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писают",
        E: "pee",
        T: "VNT",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут писать",
        E: "pee",
        T: "VFT",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писай",
        E: "pee",
        T: "VI1",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писайте",
        E: "pee",
        T: "VI2",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писал",
        E: "pee",
        T: "VPM",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писала",
        E: "pee",
        T: "VPF",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писало",
        E: "pee",
        T: "VPN",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "писали",
        E: "pee",
        T: "VPP",
        A: "I",
        O: "писать",
        RA: 221,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решить",
        E: "to decide, solve",
        T: "VI",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решу",
        E: "to decide, solve",
        T: "VF1",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решишь",
        E: "to decide, solve",
        T: "VF2",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решит",
        E: "to decide, solve",
        T: "VF3",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решим",
        E: "to decide, solve",
        T: "VFW",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решите",
        E: "to decide, solve",
        T: "VFF",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решат",
        E: "to decide, solve",
        T: "VFT",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "реши",
        E: "to decide, solve",
        T: "VI1",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решите",
        E: "to decide, solve",
        T: "VI2",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решил",
        E: "to decide, solve",
        T: "VPM",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решила",
        E: "to decide, solve",
        T: "VPF",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решило",
        E: "to decide, solve",
        T: "VPN",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "решили",
        E: "to decide, solve",
        T: "VPP",
        A: "P",
        O: "решить",
        RA: 229,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернуться",
        E: "to return",
        T: "VI",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернусь",
        E: "to return",
        T: "VF1",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернёшься",
        E: "to return",
        T: "VF2",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернётся",
        E: "to return",
        T: "VF3",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернёмся",
        E: "to return",
        T: "VFW",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернётесь",
        E: "to return",
        T: "VFF",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернутся",
        E: "to return",
        T: "VFT",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернись",
        E: "to return",
        T: "VI1",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернитесь",
        E: "to return",
        T: "VI2",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернулся",
        E: "to return",
        T: "VPM",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернулась",
        E: "to return",
        T: "VPF",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернулось",
        E: "to return",
        T: "VPN",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вернулись",
        E: "to return",
        T: "VPP",
        A: "P",
        O: "вернуться",
        RA: 233,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считать",
        E: "to count, consider",
        T: "VI",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считаю",
        E: "to count, consider",
        T: "VN1",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду считать",
        E: "to count, consider",
        T: "VF1",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считаешь",
        E: "to count, consider",
        T: "VN2",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь считать",
        E: "to count, consider",
        T: "VF2",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считает",
        E: "to count, consider",
        T: "VN3",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет считать",
        E: "to count, consider",
        T: "VF3",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считаем",
        E: "to count, consider",
        T: "VNW",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем считать",
        E: "to count, consider",
        T: "VFW",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считаете",
        E: "to count, consider",
        T: "VNF",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете считать",
        E: "to count, consider",
        T: "VFF",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считают",
        E: "to count, consider",
        T: "VNT",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут считать",
        E: "to count, consider",
        T: "VFT",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считай",
        E: "to count, consider",
        T: "VI1",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считайте",
        E: "to count, consider",
        T: "VI2",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считал",
        E: "to count, consider",
        T: "VPM",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считала",
        E: "to count, consider",
        T: "VPF",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считало",
        E: "to count, consider",
        T: "VPN",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "считали",
        E: "to count, consider",
        T: "VPP",
        A: "I",
        O: "считать",
        RA: 236,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнить",
        E: "to remember",
        T: "VI",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помню",
        E: "to remember",
        T: "VN1",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду помнить",
        E: "to remember",
        T: "VF1",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнишь",
        E: "to remember",
        T: "VN2",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь помнить",
        E: "to remember",
        T: "VF2",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнит",
        E: "to remember",
        T: "VN3",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет помнить",
        E: "to remember",
        T: "VF3",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помним",
        E: "to remember",
        T: "VNW",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем помнить",
        E: "to remember",
        T: "VFW",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помните",
        E: "to remember",
        T: "VNF",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете помнить",
        E: "to remember",
        T: "VFF",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнят",
        E: "to remember",
        T: "VNT",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут помнить",
        E: "to remember",
        T: "VFT",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помни",
        E: "to remember",
        T: "VI1",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помните",
        E: "to remember",
        T: "VI2",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнил",
        E: "to remember",
        T: "VPM",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнила",
        E: "to remember",
        T: "VPF",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнило",
        E: "to remember",
        T: "VPN",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помнили",
        E: "to remember",
        T: "VPP",
        A: "I",
        O: "помнить",
        RA: 238,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получить",
        E: "to receive, get, obtain",
        T: "VI",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получу",
        E: "to receive, get, obtain",
        T: "VF1",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получишь",
        E: "to receive, get, obtain",
        T: "VF2",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получит",
        E: "to receive, get, obtain",
        T: "VF3",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получим",
        E: "to receive, get, obtain",
        T: "VFW",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получите",
        E: "to receive, get, obtain",
        T: "VFF",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получат",
        E: "to receive, get, obtain",
        T: "VFT",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получи",
        E: "to receive, get, obtain",
        T: "VI1",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получите",
        E: "to receive, get, obtain",
        T: "VI2",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получил",
        E: "to receive, get, obtain",
        T: "VPM",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получила",
        E: "to receive, get, obtain",
        T: "VPF",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получило",
        E: "to receive, get, obtain",
        T: "VPN",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получили",
        E: "to receive, get, obtain",
        T: "VPP",
        A: "P",
        O: "получить",
        RA: 242,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходить",
        E: "to go, walk",
        T: "VI",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хожу",
        E: "to go, walk",
        T: "VN1",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду ходить",
        E: "to go, walk",
        T: "VF1",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходишь",
        E: "to go, walk",
        T: "VN2",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь ходить",
        E: "to go, walk",
        T: "VF2",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходит",
        E: "to go, walk",
        T: "VN3",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет ходить",
        E: "to go, walk",
        T: "VF3",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходим",
        E: "to go, walk",
        T: "VNW",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем ходить",
        E: "to go, walk",
        T: "VFW",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходите",
        E: "to go, walk",
        T: "VNF",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете ходить",
        E: "to go, walk",
        T: "VFF",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходят",
        E: "to go, walk",
        T: "VNT",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут ходить",
        E: "to go, walk",
        T: "VFT",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходи",
        E: "to go, walk",
        T: "VI1",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходите",
        E: "to go, walk",
        T: "VI2",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходил",
        E: "to go, walk",
        T: "VPM",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходила",
        E: "to go, walk",
        T: "VPF",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходило",
        E: "to go, walk",
        T: "VPN",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ходили",
        E: "to go, walk",
        T: "VPP",
        A: "I",
        O: "ходить",
        RA: 250,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывать",
        E: "be, visit, happen",
        T: "VI",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бываю",
        E: "be, visit, happen",
        T: "VN1",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду бывать",
        E: "be, visit, happen",
        T: "VF1",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бываешь",
        E: "be, visit, happen",
        T: "VN2",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь бывать",
        E: "be, visit, happen",
        T: "VF2",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывает",
        E: "be, visit, happen",
        T: "VN3",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет бывать",
        E: "be, visit, happen",
        T: "VF3",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бываем",
        E: "be, visit, happen",
        T: "VNW",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем бывать",
        E: "be, visit, happen",
        T: "VFW",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бываете",
        E: "be, visit, happen",
        T: "VNF",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете бывать",
        E: "be, visit, happen",
        T: "VFF",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывают",
        E: "be, visit, happen",
        T: "VNT",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут бывать",
        E: "be, visit, happen",
        T: "VFT",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывай",
        E: "be, visit, happen",
        T: "VI1",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывайте",
        E: "be, visit, happen",
        T: "VI2",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывал",
        E: "be, visit, happen",
        T: "VPM",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывала",
        E: "be, visit, happen",
        T: "VPF",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывало",
        E: "be, visit, happen",
        T: "VPN",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывали",
        E: "be, visit, happen",
        T: "VPP",
        A: "I",
        O: "бывать",
        RA: 254,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прийти",
        E: "to come, arrive",
        T: "VI",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приду",
        E: "to come, arrive",
        T: "VF1",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёшь",
        E: "to come, arrive",
        T: "VF2",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёт",
        E: "to come, arrive",
        T: "VF3",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придём",
        E: "to come, arrive",
        T: "VFW",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёте",
        E: "to come, arrive",
        T: "VFF",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придут",
        E: "to come, arrive",
        T: "VFT",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приди",
        E: "to come, arrive",
        T: "VI1",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придите",
        E: "to come, arrive",
        T: "VI2",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришёл",
        E: "to come, arrive",
        T: "VPM",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришла",
        E: "to come, arrive",
        T: "VPF",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришло",
        E: "to come, arrive",
        T: "VPN",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришли",
        E: "to come, arrive",
        T: "VPP",
        A: "P",
        O: "прийти",
        RA: 256,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнать",
        E: "to know, learn, recognize",
        T: "VI",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнаю",
        E: "to know, learn, recognize",
        T: "VF1",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнаешь",
        E: "to know, learn, recognize",
        T: "VF2",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнает",
        E: "to know, learn, recognize",
        T: "VF3",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнаем",
        E: "to know, learn, recognize",
        T: "VFW",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнаете",
        E: "to know, learn, recognize",
        T: "VFF",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнают",
        E: "to know, learn, recognize",
        T: "VFT",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнай",
        E: "to know, learn, recognize",
        T: "VI1",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнайте",
        E: "to know, learn, recognize",
        T: "VI2",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнал",
        E: "to know, learn, recognize",
        T: "VPM",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнала",
        E: "to know, learn, recognize",
        T: "VPF",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнало",
        E: "to know, learn, recognize",
        T: "VPN",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "узнали",
        E: "to know, learn, recognize",
        T: "VPP",
        A: "P",
        O: "узнать",
        RA: 263,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметить",
        E: "to notice, observe",
        T: "VI",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "замечу",
        E: "to notice, observe",
        T: "VF1",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметишь",
        E: "to notice, observe",
        T: "VF2",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметит",
        E: "to notice, observe",
        T: "VF3",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметим",
        E: "to notice, observe",
        T: "VFW",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметите",
        E: "to notice, observe",
        T: "VFF",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметят",
        E: "to notice, observe",
        T: "VFT",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметь",
        E: "to notice, observe",
        T: "VI1",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметьте",
        E: "to notice, observe",
        T: "VI2",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметил",
        E: "to notice, observe",
        T: "VPM",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметила",
        E: "to notice, observe",
        T: "VPF",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметило",
        E: "to notice, observe",
        T: "VPN",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заметили",
        E: "to notice, observe",
        T: "VPP",
        A: "P",
        O: "заметить",
        RA: 266,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйти",
        E: "to leave, go away",
        T: "VI",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйду",
        E: "to leave, go away",
        T: "VF1",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдёшь",
        E: "to leave, go away",
        T: "VF2",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдёт",
        E: "to leave, go away",
        T: "VF3",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдём",
        E: "to leave, go away",
        T: "VFW",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдёте",
        E: "to leave, go away",
        T: "VFF",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдут",
        E: "to leave, go away",
        T: "VFT",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйди",
        E: "to leave, go away",
        T: "VI1",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уйдите",
        E: "to leave, go away",
        T: "VI2",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ушёл",
        E: "to leave, go away",
        T: "VPM",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ушла",
        E: "to leave, go away",
        T: "VPF",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ушло",
        E: "to leave, go away",
        T: "VPN",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ушли",
        E: "to leave, go away",
        T: "VPP",
        A: "P",
        O: "уйти",
        RA: 269,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышать",
        E: "to hear",
        T: "VI",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышу",
        E: "to hear",
        T: "VN1",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду слышать",
        E: "to hear",
        T: "VF1",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышишь",
        E: "to hear",
        T: "VN2",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь слышать",
        E: "to hear",
        T: "VF2",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышит",
        E: "to hear",
        T: "VN3",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет слышать",
        E: "to hear",
        T: "VF3",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышим",
        E: "to hear",
        T: "VNW",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем слышать",
        E: "to hear",
        T: "VFW",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышите",
        E: "to hear",
        T: "VNF",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете слышать",
        E: "to hear",
        T: "VFF",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышат",
        E: "to hear",
        T: "VNT",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут слышать",
        E: "to hear",
        T: "VFT",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышь",
        E: "to hear",
        T: "VI1",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышьте",
        E: "to hear",
        T: "VI2",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышал",
        E: "to hear",
        T: "VPM",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышала",
        E: "to hear",
        T: "VPF",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышало",
        E: "to hear",
        T: "VPN",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слышали",
        E: "to hear",
        T: "VPP",
        A: "I",
        O: "слышать",
        RA: 272,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушать",
        E: "to listen, hear",
        T: "VI",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушаю",
        E: "to listen, hear",
        T: "VN1",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду слушать",
        E: "to listen, hear",
        T: "VF1",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушаешь",
        E: "to listen, hear",
        T: "VN2",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь слушать",
        E: "to listen, hear",
        T: "VF2",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушает",
        E: "to listen, hear",
        T: "VN3",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет слушать",
        E: "to listen, hear",
        T: "VF3",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушаем",
        E: "to listen, hear",
        T: "VNW",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем слушать",
        E: "to listen, hear",
        T: "VFW",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушаете",
        E: "to listen, hear",
        T: "VNF",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете слушать",
        E: "to listen, hear",
        T: "VFF",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушают",
        E: "to listen, hear",
        T: "VNT",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут слушать",
        E: "to listen, hear",
        T: "VFT",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушай",
        E: "to listen, hear",
        T: "VI1",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушайте",
        E: "to listen, hear",
        T: "VI2",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушал",
        E: "to listen, hear",
        T: "VPM",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушала",
        E: "to listen, hear",
        T: "VPF",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушало",
        E: "to listen, hear",
        T: "VPN",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слушали",
        E: "to listen, hear",
        T: "VPP",
        A: "I",
        O: "слушать",
        RA: 273,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бояться",
        E: "to be afraid, fear",
        T: "VI",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боюсь",
        E: "to be afraid, fear",
        T: "VN1",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду бояться",
        E: "to be afraid, fear",
        T: "VF1",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боишься",
        E: "to be afraid, fear",
        T: "VN2",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь бояться",
        E: "to be afraid, fear",
        T: "VF2",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боится",
        E: "to be afraid, fear",
        T: "VN3",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет бояться",
        E: "to be afraid, fear",
        T: "VF3",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боимся",
        E: "to be afraid, fear",
        T: "VNW",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем бояться",
        E: "to be afraid, fear",
        T: "VFW",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боитесь",
        E: "to be afraid, fear",
        T: "VNF",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете бояться",
        E: "to be afraid, fear",
        T: "VFF",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боятся",
        E: "to be afraid, fear",
        T: "VNT",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут бояться",
        E: "to be afraid, fear",
        T: "VFT",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бойся",
        E: "to be afraid, fear",
        T: "VI1",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бойтесь",
        E: "to be afraid, fear",
        T: "VI2",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боялся",
        E: "to be afraid, fear",
        T: "VPM",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боялась",
        E: "to be afraid, fear",
        T: "VPF",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боялось",
        E: "to be afraid, fear",
        T: "VPN",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "боялись",
        E: "to be afraid, fear",
        T: "VPP",
        A: "I",
        O: "бояться",
        RA: 274,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядеть",
        E: "to look, gaze",
        T: "VI",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "гляжу",
        E: "to look, gaze",
        T: "VN1",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду глядеть",
        E: "to look, gaze",
        T: "VF1",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядишь",
        E: "to look, gaze",
        T: "VN2",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь глядеть",
        E: "to look, gaze",
        T: "VF2",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядит",
        E: "to look, gaze",
        T: "VN3",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет глядеть",
        E: "to look, gaze",
        T: "VF3",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядим",
        E: "to look, gaze",
        T: "VNW",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем глядеть",
        E: "to look, gaze",
        T: "VFW",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядите",
        E: "to look, gaze",
        T: "VNF",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете глядеть",
        E: "to look, gaze",
        T: "VFF",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядят",
        E: "to look, gaze",
        T: "VNT",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут глядеть",
        E: "to look, gaze",
        T: "VFT",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "гляди",
        E: "to look, gaze",
        T: "VI1",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядите",
        E: "to look, gaze",
        T: "VI2",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядел",
        E: "to look, gaze",
        T: "VPM",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядела",
        E: "to look, gaze",
        T: "VPF",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядело",
        E: "to look, gaze",
        T: "VPN",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глядели",
        E: "to look, gaze",
        T: "VPP",
        A: "I",
        O: "глядеть",
        RA: 284,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сесть",
        E: "to sit",
        T: "VI",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сяду",
        E: "to sit",
        T: "VF1",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядешь",
        E: "to sit",
        T: "VF2",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядет",
        E: "to sit",
        T: "VF3",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядем",
        E: "to sit",
        T: "VFW",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядете",
        E: "to sit",
        T: "VFF",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядут",
        E: "to sit",
        T: "VFT",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядь",
        E: "to sit",
        T: "VI1",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сядьте",
        E: "to sit",
        T: "VI2",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сел",
        E: "to sit",
        T: "VPM",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "села",
        E: "to sit",
        T: "VPF",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "село",
        E: "to sit",
        T: "VPN",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сели",
        E: "to sit",
        T: "VPP",
        A: "P",
        O: "сесть",
        RA: 286,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читать",
        E: "to read",
        T: "VI",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читаю",
        E: "to read",
        T: "VN1",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду читать",
        E: "to read",
        T: "VF1",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читаешь",
        E: "to read",
        T: "VN2",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь читать",
        E: "to read",
        T: "VF2",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читает",
        E: "to read",
        T: "VN3",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет читать",
        E: "to read",
        T: "VF3",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читаем",
        E: "to read",
        T: "VNW",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем читать",
        E: "to read",
        T: "VFW",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читаете",
        E: "to read",
        T: "VNF",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете читать",
        E: "to read",
        T: "VFF",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читают",
        E: "to read",
        T: "VNT",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут читать",
        E: "to read",
        T: "VFT",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читай",
        E: "to read",
        T: "VI1",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читайте",
        E: "to read",
        T: "VI2",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читал",
        E: "to read",
        T: "VPM",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читала",
        E: "to read",
        T: "VPF",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читало",
        E: "to read",
        T: "VPN",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "читали",
        E: "to read",
        T: "VPP",
        A: "I",
        O: "читать",
        RA: 294,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставаться",
        E: "to remain, stay",
        T: "VI",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаюсь",
        E: "to remain, stay",
        T: "VN1",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду оставаться",
        E: "to remain, stay",
        T: "VF1",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаёшься",
        E: "to remain, stay",
        T: "VN2",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь оставаться",
        E: "to remain, stay",
        T: "VF2",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаётся",
        E: "to remain, stay",
        T: "VN3",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет оставаться",
        E: "to remain, stay",
        T: "VF3",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаёмся",
        E: "to remain, stay",
        T: "VNW",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем оставаться",
        E: "to remain, stay",
        T: "VFW",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаётесь",
        E: "to remain, stay",
        T: "VNF",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете оставаться",
        E: "to remain, stay",
        T: "VFF",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остаются",
        E: "to remain, stay",
        T: "VNT",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут оставаться",
        E: "to remain, stay",
        T: "VFT",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставайся",
        E: "to remain, stay",
        T: "VI1",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставайтесь",
        E: "to remain, stay",
        T: "VI2",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставался",
        E: "to remain, stay",
        T: "VPM",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставалась",
        E: "to remain, stay",
        T: "VPF",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставалось",
        E: "to remain, stay",
        T: "VPN",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставались",
        E: "to remain, stay",
        T: "VPP",
        A: "I",
        O: "оставаться",
        RA: 300,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брать",
        E: "to take",
        T: "VI",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "беру",
        E: "to take",
        T: "VN1",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду брать",
        E: "to take",
        T: "VF1",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берёшь",
        E: "to take",
        T: "VN2",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь брать",
        E: "to take",
        T: "VF2",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берёт",
        E: "to take",
        T: "VN3",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет брать",
        E: "to take",
        T: "VF3",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берём",
        E: "to take",
        T: "VNW",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем брать",
        E: "to take",
        T: "VFW",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берёте",
        E: "to take",
        T: "VNF",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете брать",
        E: "to take",
        T: "VFF",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берут",
        E: "to take",
        T: "VNT",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут брать",
        E: "to take",
        T: "VFT",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бери",
        E: "to take",
        T: "VI1",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "берите",
        E: "to take",
        T: "VI2",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брал",
        E: "to take",
        T: "VPM",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брала",
        E: "to take",
        T: "VPF",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брало",
        E: "to take",
        T: "VPN",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брали",
        E: "to take",
        T: "VPP",
        A: "I",
        O: "брать",
        RA: 309,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "написать",
        E: "to write",
        T: "VI",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишу",
        E: "to write",
        T: "VF1",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишешь",
        E: "to write",
        T: "VF2",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишет",
        E: "to write",
        T: "VF3",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишем",
        E: "to write",
        T: "VFW",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишете",
        E: "to write",
        T: "VFF",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишут",
        E: "to write",
        T: "VFT",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напиши",
        E: "to write",
        T: "VI1",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "напишите",
        E: "to write",
        T: "VI2",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "написал",
        E: "to write",
        T: "VPM",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "написала",
        E: "to write",
        T: "VPF",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "написало",
        E: "to write",
        T: "VPN",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "написали",
        E: "to write",
        T: "VPP",
        A: "P",
        O: "написать",
        RA: 310,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинать",
        E: "to begin",
        T: "VI",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинаю",
        E: "to begin",
        T: "VN1",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду начинать",
        E: "to begin",
        T: "VF1",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинаешь",
        E: "to begin",
        T: "VN2",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь начинать",
        E: "to begin",
        T: "VF2",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинает",
        E: "to begin",
        T: "VN3",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет начинать",
        E: "to begin",
        T: "VF3",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинаем",
        E: "to begin",
        T: "VNW",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем начинать",
        E: "to begin",
        T: "VFW",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинаете",
        E: "to begin",
        T: "VNF",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете начинать",
        E: "to begin",
        T: "VFF",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинают",
        E: "to begin",
        T: "VNT",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут начинать",
        E: "to begin",
        T: "VFT",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинай",
        E: "to begin",
        T: "VI1",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинайте",
        E: "to begin",
        T: "VI2",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинал",
        E: "to begin",
        T: "VPM",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинала",
        E: "to begin",
        T: "VPF",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинало",
        E: "to begin",
        T: "VPN",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начинали",
        E: "to begin",
        T: "VPP",
        A: "I",
        O: "начинать",
        RA: 313,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствовать",
        E: "to feel",
        T: "VI",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствую",
        E: "to feel",
        T: "VN1",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду чувствовать",
        E: "to feel",
        T: "VF1",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуешь",
        E: "to feel",
        T: "VN2",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь чувствовать",
        E: "to feel",
        T: "VF2",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствует",
        E: "to feel",
        T: "VN3",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет чувствовать",
        E: "to feel",
        T: "VF3",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуем",
        E: "to feel",
        T: "VNW",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем чувствовать",
        E: "to feel",
        T: "VFW",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуете",
        E: "to feel",
        T: "VNF",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете чувствовать",
        E: "to feel",
        T: "VFF",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуют",
        E: "to feel",
        T: "VNT",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут чувствовать",
        E: "to feel",
        T: "VFT",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуй",
        E: "to feel",
        T: "VI1",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствуйте",
        E: "to feel",
        T: "VI2",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствовал",
        E: "to feel",
        T: "VPM",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствовала",
        E: "to feel",
        T: "VPF",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствовало",
        E: "to feel",
        T: "VPN",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чувствовали",
        E: "to feel",
        T: "VPP",
        A: "I",
        O: "чувствовать",
        RA: 320,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вести",
        E: "to lead",
        T: "VI",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веду",
        E: "to lead",
        T: "VN1",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду вести",
        E: "to lead",
        T: "VF1",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведёшь",
        E: "to lead",
        T: "VN2",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь вести",
        E: "to lead",
        T: "VF2",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведёт",
        E: "to lead",
        T: "VN3",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет вести",
        E: "to lead",
        T: "VF3",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведём",
        E: "to lead",
        T: "VNW",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем вести",
        E: "to lead",
        T: "VFW",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведёте",
        E: "to lead",
        T: "VNF",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете вести",
        E: "to lead",
        T: "VFF",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведут",
        E: "to lead",
        T: "VNT",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут вести",
        E: "to lead",
        T: "VFT",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веди",
        E: "to lead",
        T: "VI1",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведите",
        E: "to lead",
        T: "VI2",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вёл",
        E: "to lead",
        T: "VPM",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вела",
        E: "to lead",
        T: "VPF",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вело",
        E: "to lead",
        T: "VPN",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вели",
        E: "to lead",
        T: "VPP",
        A: "I",
        O: "вести",
        RA: 322,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеть",
        E: "to be in time, be successful",
        T: "VI",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успею",
        E: "to be in time, be successful",
        T: "VF1",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеешь",
        E: "to be in time, be successful",
        T: "VF2",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеет",
        E: "to be in time, be successful",
        T: "VF3",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеем",
        E: "to be in time, be successful",
        T: "VFW",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеете",
        E: "to be in time, be successful",
        T: "VFF",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успеют",
        E: "to be in time, be successful",
        T: "VFT",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успей",
        E: "to be in time, be successful",
        T: "VI1",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успейте",
        E: "to be in time, be successful",
        T: "VI2",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успел",
        E: "to be in time, be successful",
        T: "VPM",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успела",
        E: "to be in time, be successful",
        T: "VPF",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успело",
        E: "to be in time, be successful",
        T: "VPN",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "успели",
        E: "to be in time, be successful",
        T: "VPP",
        A: "P",
        O: "успеть",
        RA: 325,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжать",
        E: "to continue",
        T: "VI",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжаю",
        E: "to continue",
        T: "VN1",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду продолжать",
        E: "to continue",
        T: "VF1",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжаешь",
        E: "to continue",
        T: "VN2",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь продолжать",
        E: "to continue",
        T: "VF2",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжает",
        E: "to continue",
        T: "VN3",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет продолжать",
        E: "to continue",
        T: "VF3",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжаем",
        E: "to continue",
        T: "VNW",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем продолжать",
        E: "to continue",
        T: "VFW",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжаете",
        E: "to continue",
        T: "VNF",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете продолжать",
        E: "to continue",
        T: "VFF",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжают",
        E: "to continue",
        T: "VNT",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут продолжать",
        E: "to continue",
        T: "VFT",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжай",
        E: "to continue",
        T: "VI1",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжайте",
        E: "to continue",
        T: "VI2",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжал",
        E: "to continue",
        T: "VPM",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжала",
        E: "to continue",
        T: "VPF",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжало",
        E: "to continue",
        T: "VPN",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "продолжали",
        E: "to continue",
        T: "VPP",
        A: "I",
        O: "продолжать",
        RA: 329,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забыть",
        E: "to forget",
        T: "VI",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забуду",
        E: "to forget",
        T: "VF1",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудешь",
        E: "to forget",
        T: "VF2",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудет",
        E: "to forget",
        T: "VF3",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудем",
        E: "to forget",
        T: "VFW",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудете",
        E: "to forget",
        T: "VFF",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудут",
        E: "to forget",
        T: "VFT",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудь",
        E: "to forget",
        T: "VI1",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забудьте",
        E: "to forget",
        T: "VI2",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забыл",
        E: "to forget",
        T: "VPM",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забыла",
        E: "to forget",
        T: "VPF",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забыло",
        E: "to forget",
        T: "VPN",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "забыли",
        E: "to forget",
        T: "VPP",
        A: "P",
        O: "забыть",
        RA: 333,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройти",
        E: "to pass, go by, be over",
        T: "VI",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройду",
        E: "to pass, go by, be over",
        T: "VF1",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдёшь",
        E: "to pass, go by, be over",
        T: "VF2",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдёт",
        E: "to pass, go by, be over",
        T: "VF3",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдём",
        E: "to pass, go by, be over",
        T: "VFW",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдёте",
        E: "to pass, go by, be over",
        T: "VFF",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдут",
        E: "to pass, go by, be over",
        T: "VFT",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройди",
        E: "to pass, go by, be over",
        T: "VI1",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пройдите",
        E: "to pass, go by, be over",
        T: "VI2",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошёл",
        E: "to pass, go by, be over",
        T: "VPM",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошла",
        E: "to pass, go by, be over",
        T: "VPF",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошло",
        E: "to pass, go by, be over",
        T: "VPN",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошли",
        E: "to pass, go by, be over",
        T: "VPP",
        A: "P",
        O: "пройти",
        RA: 338,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появиться",
        E: "to appear, show up",
        T: "VI",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появлюсь",
        E: "to appear, show up",
        T: "VF1",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появишься",
        E: "to appear, show up",
        T: "VF2",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появится",
        E: "to appear, show up",
        T: "VF3",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появимся",
        E: "to appear, show up",
        T: "VFW",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появитесь",
        E: "to appear, show up",
        T: "VFF",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появятся",
        E: "to appear, show up",
        T: "VFT",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появись",
        E: "to appear, show up",
        T: "VI1",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появитесь",
        E: "to appear, show up",
        T: "VI2",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появился",
        E: "to appear, show up",
        T: "VPM",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появилась",
        E: "to appear, show up",
        T: "VPF",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появилось",
        E: "to appear, show up",
        T: "VPN",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появились",
        E: "to appear, show up",
        T: "VPP",
        A: "P",
        O: "появиться",
        RA: 339,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходить",
        E: "to go out",
        T: "VI",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выхожу",
        E: "to go out",
        T: "VN1",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду выходить",
        E: "to go out",
        T: "VF1",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходишь",
        E: "to go out",
        T: "VN2",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь выходить",
        E: "to go out",
        T: "VF2",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходит",
        E: "to go out",
        T: "VN3",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет выходить",
        E: "to go out",
        T: "VF3",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходим",
        E: "to go out",
        T: "VNW",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем выходить",
        E: "to go out",
        T: "VFW",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходите",
        E: "to go out",
        T: "VNF",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете выходить",
        E: "to go out",
        T: "VFF",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходят",
        E: "to go out",
        T: "VNT",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут выходить",
        E: "to go out",
        T: "VFT",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходи",
        E: "to go out",
        T: "VI1",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходите",
        E: "to go out",
        T: "VI2",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходил",
        E: "to go out",
        T: "VPM",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходила",
        E: "to go out",
        T: "VPF",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходило",
        E: "to go out",
        T: "VPN",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходили",
        E: "to go out",
        T: "VPP",
        A: "I",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходить",
        E: "rear",
        T: "VI",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выхожу",
        E: "rear",
        T: "VF1",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходишь",
        E: "rear",
        T: "VF2",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходит",
        E: "rear",
        T: "VF3",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходим",
        E: "rear",
        T: "VFW",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходите",
        E: "rear",
        T: "VFF",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходят",
        E: "rear",
        T: "VFT",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходи",
        E: "rear",
        T: "VI1",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходите",
        E: "rear",
        T: "VI2",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходил",
        E: "rear",
        T: "VPM",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходила",
        E: "rear",
        T: "VPF",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходило",
        E: "rear",
        T: "VPN",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выходили",
        E: "rear",
        T: "VPP",
        A: "P",
        O: "выходить",
        RA: 342,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просить",
        E: "to ask",
        T: "VI",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошу",
        E: "to ask",
        T: "VN1",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду просить",
        E: "to ask",
        T: "VF1",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просишь",
        E: "to ask",
        T: "VN2",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь просить",
        E: "to ask",
        T: "VF2",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просит",
        E: "to ask",
        T: "VN3",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет просить",
        E: "to ask",
        T: "VF3",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просим",
        E: "to ask",
        T: "VNW",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем просить",
        E: "to ask",
        T: "VFW",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просите",
        E: "to ask",
        T: "VNF",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете просить",
        E: "to ask",
        T: "VFF",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просят",
        E: "to ask",
        T: "VNT",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут просить",
        E: "to ask",
        T: "VFT",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проси",
        E: "to ask",
        T: "VI1",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просите",
        E: "to ask",
        T: "VI2",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просил",
        E: "to ask",
        T: "VPM",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просила",
        E: "to ask",
        T: "VPF",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просило",
        E: "to ask",
        T: "VPN",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просили",
        E: "to ask",
        T: "VPP",
        A: "I",
        O: "просить",
        RA: 343,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаться",
        E: "to try",
        T: "VI",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаюсь",
        E: "to try",
        T: "VN1",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду пытаться",
        E: "to try",
        T: "VF1",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаешься",
        E: "to try",
        T: "VN2",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь пытаться",
        E: "to try",
        T: "VF2",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытается",
        E: "to try",
        T: "VN3",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет пытаться",
        E: "to try",
        T: "VF3",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаемся",
        E: "to try",
        T: "VNW",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем пытаться",
        E: "to try",
        T: "VFW",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаетесь",
        E: "to try",
        T: "VNF",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете пытаться",
        E: "to try",
        T: "VFF",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытаются",
        E: "to try",
        T: "VNT",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут пытаться",
        E: "to try",
        T: "VFT",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытайся",
        E: "to try",
        T: "VI1",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытайтесь",
        E: "to try",
        T: "VI2",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытался",
        E: "to try",
        T: "VPM",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пыталась",
        E: "to try",
        T: "VPF",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пыталось",
        E: "to try",
        T: "VPN",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пытались",
        E: "to try",
        T: "VPP",
        A: "I",
        O: "пытаться",
        RA: 348,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показать",
        E: "to show, display",
        T: "VI",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажу",
        E: "to show, display",
        T: "VF1",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажешь",
        E: "to show, display",
        T: "VF2",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажет",
        E: "to show, display",
        T: "VF3",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажем",
        E: "to show, display",
        T: "VFW",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажете",
        E: "to show, display",
        T: "VFF",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажут",
        E: "to show, display",
        T: "VFT",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажи",
        E: "to show, display",
        T: "VI1",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажите",
        E: "to show, display",
        T: "VI2",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показал",
        E: "to show, display",
        T: "VPM",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показала",
        E: "to show, display",
        T: "VPF",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показало",
        E: "to show, display",
        T: "VPN",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показали",
        E: "to show, display",
        T: "VPP",
        A: "P",
        O: "показать",
        RA: 349,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнить",
        E: "to remember, recall",
        T: "VI",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомню",
        E: "to remember, recall",
        T: "VF1",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнишь",
        E: "to remember, recall",
        T: "VF2",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнит",
        E: "to remember, recall",
        T: "VF3",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомним",
        E: "to remember, recall",
        T: "VFW",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомните",
        E: "to remember, recall",
        T: "VFF",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнят",
        E: "to remember, recall",
        T: "VFT",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомни",
        E: "to remember, recall",
        T: "VI1",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомните",
        E: "to remember, recall",
        T: "VI2",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнил",
        E: "to remember, recall",
        T: "VPM",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнила",
        E: "to remember, recall",
        T: "VPF",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнило",
        E: "to remember, recall",
        T: "VPN",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспомнили",
        E: "to remember, recall",
        T: "VPP",
        A: "P",
        O: "вспомнить",
        RA: 350,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держать",
        E: "to hold, keep",
        T: "VI",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держу",
        E: "to hold, keep",
        T: "VN1",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду держать",
        E: "to hold, keep",
        T: "VF1",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держишь",
        E: "to hold, keep",
        T: "VN2",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь держать",
        E: "to hold, keep",
        T: "VF2",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держит",
        E: "to hold, keep",
        T: "VN3",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет держать",
        E: "to hold, keep",
        T: "VF3",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держим",
        E: "to hold, keep",
        T: "VNW",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем держать",
        E: "to hold, keep",
        T: "VFW",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держите",
        E: "to hold, keep",
        T: "VNF",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете держать",
        E: "to hold, keep",
        T: "VFF",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держат",
        E: "to hold, keep",
        T: "VNT",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут держать",
        E: "to hold, keep",
        T: "VFT",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держи",
        E: "to hold, keep",
        T: "VI1",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держите",
        E: "to hold, keep",
        T: "VI2",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держал",
        E: "to hold, keep",
        T: "VPM",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держала",
        E: "to hold, keep",
        T: "VPF",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держало",
        E: "to hold, keep",
        T: "VPN",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держали",
        E: "to hold, keep",
        T: "VPP",
        A: "I",
        O: "держать",
        RA: 354,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называть",
        E: "to call, name,",
        T: "VI",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называю",
        E: "to call, name,",
        T: "VN1",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду называть",
        E: "to call, name,",
        T: "VF1",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называешь",
        E: "to call, name,",
        T: "VN2",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь называть",
        E: "to call, name,",
        T: "VF2",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называет",
        E: "to call, name,",
        T: "VN3",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет называть",
        E: "to call, name,",
        T: "VF3",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называем",
        E: "to call, name,",
        T: "VNW",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем называть",
        E: "to call, name,",
        T: "VFW",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называете",
        E: "to call, name,",
        T: "VNF",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете называть",
        E: "to call, name,",
        T: "VFF",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называют",
        E: "to call, name,",
        T: "VNT",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут называть",
        E: "to call, name,",
        T: "VFT",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называй",
        E: "to call, name,",
        T: "VI1",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называйте",
        E: "to call, name,",
        T: "VI2",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называл",
        E: "to call, name,",
        T: "VPM",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называла",
        E: "to call, name,",
        T: "VPF",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называло",
        E: "to call, name,",
        T: "VPN",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называли",
        E: "to call, name,",
        T: "VPP",
        A: "I",
        O: "называть",
        RA: 360,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходить",
        E: "to leave, go away",
        T: "VI",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ухожу",
        E: "to leave, go away",
        T: "VN1",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду уходить",
        E: "to leave, go away",
        T: "VF1",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходишь",
        E: "to leave, go away",
        T: "VN2",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь уходить",
        E: "to leave, go away",
        T: "VF2",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходит",
        E: "to leave, go away",
        T: "VN3",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет уходить",
        E: "to leave, go away",
        T: "VF3",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходим",
        E: "to leave, go away",
        T: "VNW",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем уходить",
        E: "to leave, go away",
        T: "VFW",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходите",
        E: "to leave, go away",
        T: "VNF",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете уходить",
        E: "to leave, go away",
        T: "VFF",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходят",
        E: "to leave, go away",
        T: "VNT",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут уходить",
        E: "to leave, go away",
        T: "VFT",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходи",
        E: "to leave, go away",
        T: "VI1",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходите",
        E: "to leave, go away",
        T: "VI2",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходил",
        E: "to leave, go away",
        T: "VPM",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходила",
        E: "to leave, go away",
        T: "VPF",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходило",
        E: "to leave, go away",
        T: "VPN",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уходили",
        E: "to leave, go away",
        T: "VPP",
        A: "I",
        O: "уходить",
        RA: 364,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойти",
        E: "to approach, come up",
        T: "VI",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойду",
        E: "to approach, come up",
        T: "VF1",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдёшь",
        E: "to approach, come up",
        T: "VF2",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдёт",
        E: "to approach, come up",
        T: "VF3",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдём",
        E: "to approach, come up",
        T: "VFW",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдёте",
        E: "to approach, come up",
        T: "VFF",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдут",
        E: "to approach, come up",
        T: "VFT",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойди",
        E: "to approach, come up",
        T: "VI1",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подойдите",
        E: "to approach, come up",
        T: "VI2",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подошёл",
        E: "to approach, come up",
        T: "VPM",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подошла",
        E: "to approach, come up",
        T: "VPF",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подошло",
        E: "to approach, come up",
        T: "VPN",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подошли",
        E: "to approach, come up",
        T: "VPP",
        A: "P",
        O: "подойти",
        RA: 365,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднять",
        E: "to lift, raise",
        T: "VI",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подниму",
        E: "to lift, raise",
        T: "VF1",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимешь",
        E: "to lift, raise",
        T: "VF2",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимет",
        E: "to lift, raise",
        T: "VF3",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимем",
        E: "to lift, raise",
        T: "VFW",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимете",
        E: "to lift, raise",
        T: "VFF",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимут",
        E: "to lift, raise",
        T: "VFT",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подними",
        E: "to lift, raise",
        T: "VI1",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимите",
        E: "to lift, raise",
        T: "VI2",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднял поднял",
        E: "to lift, raise",
        T: "VPM",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подняла",
        E: "to lift, raise",
        T: "VPF",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подняло подняло",
        E: "to lift, raise",
        T: "VPN",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подняли подняли",
        E: "to lift, raise",
        T: "VPP",
        A: "P",
        O: "поднять",
        RA: 366,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивать",
        E: "to ask, inquire",
        T: "VI",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашиваю",
        E: "to ask, inquire",
        T: "VN1",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду спрашивать",
        E: "to ask, inquire",
        T: "VF1",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашиваешь",
        E: "to ask, inquire",
        T: "VN2",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь спрашивать",
        E: "to ask, inquire",
        T: "VF2",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивает",
        E: "to ask, inquire",
        T: "VN3",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет спрашивать",
        E: "to ask, inquire",
        T: "VF3",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашиваем",
        E: "to ask, inquire",
        T: "VNW",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем спрашивать",
        E: "to ask, inquire",
        T: "VFW",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашиваете",
        E: "to ask, inquire",
        T: "VNF",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете спрашивать",
        E: "to ask, inquire",
        T: "VFF",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивают",
        E: "to ask, inquire",
        T: "VNT",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут спрашивать",
        E: "to ask, inquire",
        T: "VFT",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивай",
        E: "to ask, inquire",
        T: "VI1",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивайте",
        E: "to ask, inquire",
        T: "VI2",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивал",
        E: "to ask, inquire",
        T: "VPM",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивала",
        E: "to ask, inquire",
        T: "VPF",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивало",
        E: "to ask, inquire",
        T: "VPN",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спрашивали",
        E: "to ask, inquire",
        T: "VPP",
        A: "I",
        O: "спрашивать",
        RA: 368,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросить",
        E: "to throw",
        T: "VI",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брошу",
        E: "to throw",
        T: "VF1",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросишь",
        E: "to throw",
        T: "VF2",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросит",
        E: "to throw",
        T: "VF3",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросим",
        E: "to throw",
        T: "VFW",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросите",
        E: "to throw",
        T: "VFF",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросят",
        E: "to throw",
        T: "VFT",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "брось",
        E: "to throw",
        T: "VI1",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросьте",
        E: "to throw",
        T: "VI2",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросил",
        E: "to throw",
        T: "VPM",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросила",
        E: "to throw",
        T: "VPF",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросило",
        E: "to throw",
        T: "VPN",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бросили",
        E: "to throw",
        T: "VPP",
        A: "P",
        O: "бросить",
        RA: 371,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "послать",
        E: "to send, dispatch",
        T: "VI",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлю",
        E: "to send, dispatch",
        T: "VF1",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлёшь",
        E: "to send, dispatch",
        T: "VF2",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлёт",
        E: "to send, dispatch",
        T: "VF3",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлём",
        E: "to send, dispatch",
        T: "VFW",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлёте",
        E: "to send, dispatch",
        T: "VFF",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлют",
        E: "to send, dispatch",
        T: "VFT",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошли",
        E: "to send, dispatch",
        T: "VI1",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пошлите",
        E: "to send, dispatch",
        T: "VI2",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "послал",
        E: "to send, dispatch",
        T: "VPM",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "послала",
        E: "to send, dispatch",
        T: "VPF",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "послало",
        E: "to send, dispatch",
        T: "VPN",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "послали",
        E: "to send, dispatch",
        T: "VPP",
        A: "P",
        O: "послать",
        RA: 378,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находиться",
        E: "to be found, turn up",
        T: "VI",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нахожусь",
        E: "to be found, turn up",
        T: "VN1",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду находиться",
        E: "to be found, turn up",
        T: "VF1",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находишься",
        E: "to be found, turn up",
        T: "VN2",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь находиться",
        E: "to be found, turn up",
        T: "VF2",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находится",
        E: "to be found, turn up",
        T: "VN3",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет находиться",
        E: "to be found, turn up",
        T: "VF3",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находимся",
        E: "to be found, turn up",
        T: "VNW",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем находиться",
        E: "to be found, turn up",
        T: "VFW",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находитесь",
        E: "to be found, turn up",
        T: "VNF",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете находиться",
        E: "to be found, turn up",
        T: "VFF",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находятся",
        E: "to be found, turn up",
        T: "VNT",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут находиться",
        E: "to be found, turn up",
        T: "VFT",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находись",
        E: "to be found, turn up",
        T: "VI1",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находитесь",
        E: "to be found, turn up",
        T: "VI2",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находился",
        E: "to be found, turn up",
        T: "VPM",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находилась",
        E: "to be found, turn up",
        T: "VPF",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находилось",
        E: "to be found, turn up",
        T: "VPN",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "находились",
        E: "to be found, turn up",
        T: "VPP",
        A: "I",
        O: "находиться",
        RA: 379,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставить",
        E: "to put, place, set",
        T: "VI",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставлю",
        E: "to put, place, set",
        T: "VF1",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставишь",
        E: "to put, place, set",
        T: "VF2",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставит",
        E: "to put, place, set",
        T: "VF3",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставим",
        E: "to put, place, set",
        T: "VFW",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставите",
        E: "to put, place, set",
        T: "VFF",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставят",
        E: "to put, place, set",
        T: "VFT",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставь",
        E: "to put, place, set",
        T: "VI1",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставьте",
        E: "to put, place, set",
        T: "VI2",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставил",
        E: "to put, place, set",
        T: "VPM",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставила",
        E: "to put, place, set",
        T: "VPF",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставило",
        E: "to put, place, set",
        T: "VPN",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поставили",
        E: "to put, place, set",
        T: "VPP",
        A: "P",
        O: "поставить",
        RA: 381,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встать",
        E: "to get up, rise, stand up",
        T: "VI",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встану",
        E: "to get up, rise, stand up",
        T: "VF1",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встанешь",
        E: "to get up, rise, stand up",
        T: "VF2",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встанет",
        E: "to get up, rise, stand up",
        T: "VF3",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встанем",
        E: "to get up, rise, stand up",
        T: "VFW",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встанете",
        E: "to get up, rise, stand up",
        T: "VFF",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встанут",
        E: "to get up, rise, stand up",
        T: "VFT",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встань",
        E: "to get up, rise, stand up",
        T: "VI1",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встаньте",
        E: "to get up, rise, stand up",
        T: "VI2",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встал",
        E: "to get up, rise, stand up",
        T: "VPM",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встала",
        E: "to get up, rise, stand up",
        T: "VPF",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встало",
        E: "to get up, rise, stand up",
        T: "VPN",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встали",
        E: "to get up, rise, stand up",
        T: "VPP",
        A: "P",
        O: "встать",
        RA: 382,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играть",
        E: "to play",
        T: "VI",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играю",
        E: "to play",
        T: "VN1",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду играть",
        E: "to play",
        T: "VF1",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играешь",
        E: "to play",
        T: "VN2",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь играть",
        E: "to play",
        T: "VF2",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играет",
        E: "to play",
        T: "VN3",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет играть",
        E: "to play",
        T: "VF3",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играем",
        E: "to play",
        T: "VNW",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем играть",
        E: "to play",
        T: "VFW",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играете",
        E: "to play",
        T: "VNF",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете играть",
        E: "to play",
        T: "VFF",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играют",
        E: "to play",
        T: "VNT",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут играть",
        E: "to play",
        T: "VFT",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играй",
        E: "to play",
        T: "VI1",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играйте",
        E: "to play",
        T: "VI2",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играл",
        E: "to play",
        T: "VPM",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играла",
        E: "to play",
        T: "VPF",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играло",
        E: "to play",
        T: "VPN",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "играли",
        E: "to play",
        T: "VPP",
        A: "I",
        O: "играть",
        RA: 394,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следовать",
        E: "to follow, come next",
        T: "VI",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следую",
        E: "to follow, come next",
        T: "VN1",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду следовать",
        E: "to follow, come next",
        T: "VF1",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуешь",
        E: "to follow, come next",
        T: "VN2",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь следовать",
        E: "to follow, come next",
        T: "VF2",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следует",
        E: "to follow, come next",
        T: "VN3",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет следовать",
        E: "to follow, come next",
        T: "VF3",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуем",
        E: "to follow, come next",
        T: "VNW",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем следовать",
        E: "to follow, come next",
        T: "VFW",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуете",
        E: "to follow, come next",
        T: "VNF",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете следовать",
        E: "to follow, come next",
        T: "VFF",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуют",
        E: "to follow, come next",
        T: "VNT",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут следовать",
        E: "to follow, come next",
        T: "VFT",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуй",
        E: "to follow, come next",
        T: "VI1",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следуйте",
        E: "to follow, come next",
        T: "VI2",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следовал",
        E: "to follow, come next",
        T: "VPM",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следовала",
        E: "to follow, come next",
        T: "VPF",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следовало",
        E: "to follow, come next",
        T: "VPN",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следовали",
        E: "to follow, come next",
        T: "VPP",
        A: "I",
        O: "следовать",
        RA: 395,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказать",
        E: "to tell, narrate",
        T: "VI",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажу",
        E: "to tell, narrate",
        T: "VF1",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажешь",
        E: "to tell, narrate",
        T: "VF2",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажет",
        E: "to tell, narrate",
        T: "VF3",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажем",
        E: "to tell, narrate",
        T: "VFW",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажете",
        E: "to tell, narrate",
        T: "VFF",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажут",
        E: "to tell, narrate",
        T: "VFT",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажи",
        E: "to tell, narrate",
        T: "VI1",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "расскажите",
        E: "to tell, narrate",
        T: "VI2",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказал",
        E: "to tell, narrate",
        T: "VPM",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказала",
        E: "to tell, narrate",
        T: "VPF",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказало",
        E: "to tell, narrate",
        T: "VPN",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказали",
        E: "to tell, narrate",
        T: "VPP",
        A: "P",
        O: "рассказать",
        RA: 396,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спать",
        E: "to sleep",
        T: "VI",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сплю",
        E: "to sleep",
        T: "VN1",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду спать",
        E: "to sleep",
        T: "VF1",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спишь",
        E: "to sleep",
        T: "VN2",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь спать",
        E: "to sleep",
        T: "VF2",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спит",
        E: "to sleep",
        T: "VN3",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет спать",
        E: "to sleep",
        T: "VF3",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спим",
        E: "to sleep",
        T: "VNW",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем спать",
        E: "to sleep",
        T: "VFW",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спите",
        E: "to sleep",
        T: "VNF",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете спать",
        E: "to sleep",
        T: "VFF",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спят",
        E: "to sleep",
        T: "VNT",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут спать",
        E: "to sleep",
        T: "VFT",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спи",
        E: "to sleep",
        T: "VI1",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спите",
        E: "to sleep",
        T: "VI2",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спал",
        E: "to sleep",
        T: "VPM",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спала",
        E: "to sleep",
        T: "VPF",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спало",
        E: "to sleep",
        T: "VPN",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спали",
        E: "to sleep",
        T: "VPP",
        A: "I",
        O: "спать",
        RA: 401,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставить",
        E: "to leave, abandon",
        T: "VI",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставлю",
        E: "to leave, abandon",
        T: "VF1",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставишь",
        E: "to leave, abandon",
        T: "VF2",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставит",
        E: "to leave, abandon",
        T: "VF3",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставим",
        E: "to leave, abandon",
        T: "VFW",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставите",
        E: "to leave, abandon",
        T: "VFF",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставят",
        E: "to leave, abandon",
        T: "VFT",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставь",
        E: "to leave, abandon",
        T: "VI1",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставьте",
        E: "to leave, abandon",
        T: "VI2",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставил",
        E: "to leave, abandon",
        T: "VPM",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставила",
        E: "to leave, abandon",
        T: "VPF",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставило",
        E: "to leave, abandon",
        T: "VPN",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оставили",
        E: "to leave, abandon",
        T: "VPP",
        A: "P",
        O: "оставить",
        RA: 402,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войти",
        E: "to enter, come in",
        T: "VI",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войду",
        E: "to enter, come in",
        T: "VF1",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдёшь",
        E: "to enter, come in",
        T: "VF2",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдёт",
        E: "to enter, come in",
        T: "VF3",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдём",
        E: "to enter, come in",
        T: "VFW",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдёте",
        E: "to enter, come in",
        T: "VFF",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдут",
        E: "to enter, come in",
        T: "VFT",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войди",
        E: "to enter, come in",
        T: "VI1",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "войдите",
        E: "to enter, come in",
        T: "VI2",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вошёл",
        E: "to enter, come in",
        T: "VPM",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вошла",
        E: "to enter, come in",
        T: "VPF",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вошло",
        E: "to enter, come in",
        T: "VPN",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вошли",
        E: "to enter, come in",
        T: "VPP",
        A: "P",
        O: "войти",
        RA: 403,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчать",
        E: "to keep silence",
        T: "VI",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчу",
        E: "to keep silence",
        T: "VN1",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду молчать",
        E: "to keep silence",
        T: "VF1",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчишь",
        E: "to keep silence",
        T: "VN2",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь молчать",
        E: "to keep silence",
        T: "VF2",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчит",
        E: "to keep silence",
        T: "VN3",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет молчать",
        E: "to keep silence",
        T: "VF3",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчим",
        E: "to keep silence",
        T: "VNW",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем молчать",
        E: "to keep silence",
        T: "VFW",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчите",
        E: "to keep silence",
        T: "VNF",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете молчать",
        E: "to keep silence",
        T: "VFF",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчат",
        E: "to keep silence",
        T: "VNT",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут молчать",
        E: "to keep silence",
        T: "VFT",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчи",
        E: "to keep silence",
        T: "VI1",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчите",
        E: "to keep silence",
        T: "VI2",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчал",
        E: "to keep silence",
        T: "VPM",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчала",
        E: "to keep silence",
        T: "VPF",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчало",
        E: "to keep silence",
        T: "VPN",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молчали",
        E: "to keep silence",
        T: "VPP",
        A: "I",
        O: "молчать",
        RA: 407,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывать",
        E: "to tell, narrate,",
        T: "VI",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказываю",
        E: "to tell, narrate,",
        T: "VN1",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду рассказывать",
        E: "to tell, narrate,",
        T: "VF1",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказываешь",
        E: "to tell, narrate,",
        T: "VN2",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь рассказывать",
        E: "to tell, narrate,",
        T: "VF2",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывает",
        E: "to tell, narrate,",
        T: "VN3",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет рассказывать",
        E: "to tell, narrate,",
        T: "VF3",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказываем",
        E: "to tell, narrate,",
        T: "VNW",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем рассказывать",
        E: "to tell, narrate,",
        T: "VFW",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказываете",
        E: "to tell, narrate,",
        T: "VNF",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете рассказывать",
        E: "to tell, narrate,",
        T: "VFF",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывают",
        E: "to tell, narrate,",
        T: "VNT",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут рассказывать",
        E: "to tell, narrate,",
        T: "VFT",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывай",
        E: "to tell, narrate,",
        T: "VI1",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывайте",
        E: "to tell, narrate,",
        T: "VI2",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывал",
        E: "to tell, narrate,",
        T: "VPM",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывала",
        E: "to tell, narrate,",
        T: "VPF",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывало",
        E: "to tell, narrate,",
        T: "VPN",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рассказывали",
        E: "to tell, narrate,",
        T: "VPP",
        A: "I",
        O: "рассказывать",
        RA: 408,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечать",
        E: "to answer, reply,",
        T: "VI",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечаю",
        E: "to answer, reply,",
        T: "VN1",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду отвечать",
        E: "to answer, reply,",
        T: "VF1",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечаешь",
        E: "to answer, reply,",
        T: "VN2",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь отвечать",
        E: "to answer, reply,",
        T: "VF2",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечает",
        E: "to answer, reply,",
        T: "VN3",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет отвечать",
        E: "to answer, reply,",
        T: "VF3",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечаем",
        E: "to answer, reply,",
        T: "VNW",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем отвечать",
        E: "to answer, reply,",
        T: "VFW",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечаете",
        E: "to answer, reply,",
        T: "VNF",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете отвечать",
        E: "to answer, reply,",
        T: "VFF",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечают",
        E: "to answer, reply,",
        T: "VNT",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут отвечать",
        E: "to answer, reply,",
        T: "VFT",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечай",
        E: "to answer, reply,",
        T: "VI1",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечайте",
        E: "to answer, reply,",
        T: "VI2",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечал",
        E: "to answer, reply,",
        T: "VPM",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечала",
        E: "to answer, reply,",
        T: "VPF",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечало",
        E: "to answer, reply,",
        T: "VPN",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отвечали",
        E: "to answer, reply,",
        T: "VPP",
        A: "I",
        O: "отвечать",
        RA: 409,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становиться",
        E: "to stand, to become",
        T: "VI",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становлюсь",
        E: "to stand, to become",
        T: "VN1",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду становиться",
        E: "to stand, to become",
        T: "VF1",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становишься",
        E: "to stand, to become",
        T: "VN2",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь становиться",
        E: "to stand, to become",
        T: "VF2",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становится",
        E: "to stand, to become",
        T: "VN3",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет становиться",
        E: "to stand, to become",
        T: "VF3",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становимся",
        E: "to stand, to become",
        T: "VNW",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем становиться",
        E: "to stand, to become",
        T: "VFW",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становитесь",
        E: "to stand, to become",
        T: "VNF",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете становиться",
        E: "to stand, to become",
        T: "VFF",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становятся",
        E: "to stand, to become",
        T: "VNT",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут становиться",
        E: "to stand, to become",
        T: "VFT",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становись",
        E: "to stand, to become",
        T: "VI1",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становитесь",
        E: "to stand, to become",
        T: "VI2",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становился",
        E: "to stand, to become",
        T: "VPM",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становилась",
        E: "to stand, to become",
        T: "VPF",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становилось",
        E: "to stand, to become",
        T: "VPN",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "становились",
        E: "to stand, to become",
        T: "VPP",
        A: "I",
        O: "становиться",
        RA: 410,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановиться",
        E: "to stop",
        T: "VI",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановлюсь",
        E: "to stop",
        T: "VF1",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановишься",
        E: "to stop",
        T: "VF2",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановится",
        E: "to stop",
        T: "VF3",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановимся",
        E: "to stop",
        T: "VFW",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановитесь",
        E: "to stop",
        T: "VFF",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановятся",
        E: "to stop",
        T: "VFT",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановись",
        E: "to stop",
        T: "VI1",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановитесь",
        E: "to stop",
        T: "VI2",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановился",
        E: "to stop",
        T: "VPM",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановилась",
        E: "to stop",
        T: "VPF",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановилось",
        E: "to stop",
        T: "VPN",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остановились",
        E: "to stop",
        T: "VPP",
        A: "P",
        O: "остановиться",
        RA: 411,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "искать",
        E: "to search",
        T: "VI",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищу",
        E: "to search",
        T: "VN1",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду искать",
        E: "to search",
        T: "VF1",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищешь",
        E: "to search",
        T: "VN2",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь искать",
        E: "to search",
        T: "VF2",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищет",
        E: "to search",
        T: "VN3",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет искать",
        E: "to search",
        T: "VF3",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищем",
        E: "to search",
        T: "VNW",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем искать",
        E: "to search",
        T: "VFW",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищете",
        E: "to search",
        T: "VNF",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете искать",
        E: "to search",
        T: "VFF",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищут",
        E: "to search",
        T: "VNT",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут искать",
        E: "to search",
        T: "VFT",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищи",
        E: "to search",
        T: "VI1",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ищите",
        E: "to search",
        T: "VI2",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "искал",
        E: "to search",
        T: "VPM",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "искала",
        E: "to search",
        T: "VPF",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "искало",
        E: "to search",
        T: "VPN",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "искали",
        E: "to search",
        T: "VPP",
        A: "I",
        O: "искать",
        RA: 414,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начать",
        E: "to begin",
        T: "VI",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начну",
        E: "to begin",
        T: "VF1",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнёшь",
        E: "to begin",
        T: "VF2",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнёт",
        E: "to begin",
        T: "VF3",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнём",
        E: "to begin",
        T: "VFW",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнёте",
        E: "to begin",
        T: "VFF",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнут",
        E: "to begin",
        T: "VFT",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начни",
        E: "to begin",
        T: "VI1",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начните",
        E: "to begin",
        T: "VI2",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начал",
        E: "to begin",
        T: "VPM",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начала",
        E: "to begin",
        T: "VPF",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начало",
        E: "to begin",
        T: "VPN",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начали",
        E: "to begin",
        T: "VPP",
        A: "P",
        O: "начать",
        RA: 418,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верить",
        E: "to believe",
        T: "VI",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верю",
        E: "to believe",
        T: "VN1",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду верить",
        E: "to believe",
        T: "VF1",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веришь",
        E: "to believe",
        T: "VN2",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь верить",
        E: "to believe",
        T: "VF2",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верит",
        E: "to believe",
        T: "VN3",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет верить",
        E: "to believe",
        T: "VF3",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верим",
        E: "to believe",
        T: "VNW",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем верить",
        E: "to believe",
        T: "VFW",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верите",
        E: "to believe",
        T: "VNF",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете верить",
        E: "to believe",
        T: "VFF",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верят",
        E: "to believe",
        T: "VNT",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут верить",
        E: "to believe",
        T: "VFT",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верь",
        E: "to believe",
        T: "VI1",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верьте",
        E: "to believe",
        T: "VI2",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верил",
        E: "to believe",
        T: "VPM",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верила",
        E: "to believe",
        T: "VPF",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верило",
        E: "to believe",
        T: "VPN",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верили",
        E: "to believe",
        T: "VPP",
        A: "I",
        O: "верить",
        RA: 422,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являться",
        E: "to appear",
        T: "VI",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являюсь",
        E: "to appear",
        T: "VN1",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду являться",
        E: "to appear",
        T: "VF1",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являешься",
        E: "to appear",
        T: "VN2",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь являться",
        E: "to appear",
        T: "VF2",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "является",
        E: "to appear",
        T: "VN3",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет являться",
        E: "to appear",
        T: "VF3",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являемся",
        E: "to appear",
        T: "VNW",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем являться",
        E: "to appear",
        T: "VFW",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являетесь",
        E: "to appear",
        T: "VNF",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете являться",
        E: "to appear",
        T: "VFF",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являются",
        E: "to appear",
        T: "VNT",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут являться",
        E: "to appear",
        T: "VFT",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являйся",
        E: "to appear",
        T: "VI1",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являйтесь",
        E: "to appear",
        T: "VI2",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являлся",
        E: "to appear",
        T: "VPM",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являлась",
        E: "to appear",
        T: "VPF",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являлось",
        E: "to appear",
        T: "VPN",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "являлись",
        E: "to appear",
        T: "VPP",
        A: "I",
        O: "являться",
        RA: 427,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смочь",
        E: "to be able",
        T: "VI",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогу",
        E: "to be able",
        T: "VF1",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сможешь",
        E: "to be able",
        T: "VF2",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сможет",
        E: "to be able",
        T: "VF3",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сможем",
        E: "to be able",
        T: "VFW",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сможете",
        E: "to be able",
        T: "VFF",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогут",
        E: "to be able",
        T: "VFT",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смоги",
        E: "to be able",
        T: "VI1",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогите",
        E: "to be able",
        T: "VI2",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смог",
        E: "to be able",
        T: "VPM",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогла",
        E: "to be able",
        T: "VPF",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогло",
        E: "to be able",
        T: "VPN",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смогли",
        E: "to be able",
        T: "VPP",
        A: "P",
        O: "смочь",
        RA: 444,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежать",
        E: "to run, hurry",
        T: "VI",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бегу",
        E: "to run, hurry",
        T: "VN1",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду бежать",
        E: "to run, hurry",
        T: "VF1",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежишь",
        E: "to run, hurry",
        T: "VN2",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь бежать",
        E: "to run, hurry",
        T: "VF2",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежит",
        E: "to run, hurry",
        T: "VN3",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет бежать",
        E: "to run, hurry",
        T: "VF3",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежим",
        E: "to run, hurry",
        T: "VNW",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем бежать",
        E: "to run, hurry",
        T: "VFW",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежите",
        E: "to run, hurry",
        T: "VNF",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете бежать",
        E: "to run, hurry",
        T: "VFF",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бегут",
        E: "to run, hurry",
        T: "VNT",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут бежать",
        E: "to run, hurry",
        T: "VFT",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "беги",
        E: "to run, hurry",
        T: "VI1",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бегите",
        E: "to run, hurry",
        T: "VI2",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежал",
        E: "to run, hurry",
        T: "VPM",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежала",
        E: "to run, hurry",
        T: "VPF",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежало",
        E: "to run, hurry",
        T: "VPN",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бежали",
        E: "to run, hurry",
        T: "VPP",
        A: "I",
        O: "бежать",
        RA: 446,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приехать",
        E: "to arrive, come",
        T: "VI",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приеду",
        E: "to arrive, come",
        T: "VF1",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приедешь",
        E: "to arrive, come",
        T: "VF2",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приедет",
        E: "to arrive, come",
        T: "VF3",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приедем",
        E: "to arrive, come",
        T: "VFW",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приедете",
        E: "to arrive, come",
        T: "VFF",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приедут",
        E: "to arrive, come",
        T: "VFT",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приезжай",
        E: "to arrive, come",
        T: "VI1",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приезжайте",
        E: "to arrive, come",
        T: "VI2",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приехал",
        E: "to arrive, come",
        T: "VPM",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приехала",
        E: "to arrive, come",
        T: "VPF",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приехало",
        E: "to arrive, come",
        T: "VPN",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приехали",
        E: "to arrive, come",
        T: "VPP",
        A: "P",
        O: "приехать",
        RA: 450,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричать",
        E: "to cry, shout",
        T: "VI",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричу",
        E: "to cry, shout",
        T: "VN1",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду кричать",
        E: "to cry, shout",
        T: "VF1",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричишь",
        E: "to cry, shout",
        T: "VN2",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь кричать",
        E: "to cry, shout",
        T: "VF2",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричит",
        E: "to cry, shout",
        T: "VN3",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет кричать",
        E: "to cry, shout",
        T: "VF3",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричим",
        E: "to cry, shout",
        T: "VNW",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем кричать",
        E: "to cry, shout",
        T: "VFW",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричите",
        E: "to cry, shout",
        T: "VNF",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете кричать",
        E: "to cry, shout",
        T: "VFF",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричат",
        E: "to cry, shout",
        T: "VNT",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут кричать",
        E: "to cry, shout",
        T: "VFT",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричи",
        E: "to cry, shout",
        T: "VI1",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричите",
        E: "to cry, shout",
        T: "VI2",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричал",
        E: "to cry, shout",
        T: "VPM",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричала",
        E: "to cry, shout",
        T: "VPF",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричало",
        E: "to cry, shout",
        T: "VPN",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кричали",
        E: "to cry, shout",
        T: "VPP",
        A: "I",
        O: "кричать",
        RA: 452,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открыть",
        E: "to open",
        T: "VI",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открою",
        E: "to open",
        T: "VF1",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откроешь",
        E: "to open",
        T: "VF2",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откроет",
        E: "to open",
        T: "VF3",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откроем",
        E: "to open",
        T: "VFW",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откроете",
        E: "to open",
        T: "VFF",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откроют",
        E: "to open",
        T: "VFT",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открой",
        E: "to open",
        T: "VI1",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откройте",
        E: "to open",
        T: "VI2",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открыл",
        E: "to open",
        T: "VPM",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открыла",
        E: "to open",
        T: "VPF",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открыло",
        E: "to open",
        T: "VPN",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открыли",
        E: "to open",
        T: "VPP",
        A: "P",
        O: "открыть",
        RA: 458,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходить",
        E: "to happen, occur, take place",
        T: "VI",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происхожу",
        E: "to happen, occur, take place",
        T: "VN1",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду происходить",
        E: "to happen, occur, take place",
        T: "VF1",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходишь",
        E: "to happen, occur, take place",
        T: "VN2",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь происходить",
        E: "to happen, occur, take place",
        T: "VF2",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходит",
        E: "to happen, occur, take place",
        T: "VN3",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет происходить",
        E: "to happen, occur, take place",
        T: "VF3",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходим",
        E: "to happen, occur, take place",
        T: "VNW",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем происходить",
        E: "to happen, occur, take place",
        T: "VFW",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходите",
        E: "to happen, occur, take place",
        T: "VNF",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете происходить",
        E: "to happen, occur, take place",
        T: "VFF",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходят",
        E: "to happen, occur, take place",
        T: "VNT",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут происходить",
        E: "to happen, occur, take place",
        T: "VFT",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходи",
        E: "to happen, occur, take place",
        T: "VI1",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходите",
        E: "to happen, occur, take place",
        T: "VI2",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходил",
        E: "to happen, occur, take place",
        T: "VPM",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходила",
        E: "to happen, occur, take place",
        T: "VPF",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходило",
        E: "to happen, occur, take place",
        T: "VPN",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "происходили",
        E: "to happen, occur, take place",
        T: "VPP",
        A: "I",
        O: "происходить",
        RA: 459,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходить",
        E: "to come, arrive",
        T: "VI",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прихожу",
        E: "to come, arrive",
        T: "VN1",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду приходить",
        E: "to come, arrive",
        T: "VF1",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходишь",
        E: "to come, arrive",
        T: "VN2",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь приходить",
        E: "to come, arrive",
        T: "VF2",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходит",
        E: "to come, arrive",
        T: "VN3",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет приходить",
        E: "to come, arrive",
        T: "VF3",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходим",
        E: "to come, arrive",
        T: "VNW",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем приходить",
        E: "to come, arrive",
        T: "VFW",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходите",
        E: "to come, arrive",
        T: "VNF",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете приходить",
        E: "to come, arrive",
        T: "VFF",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходят",
        E: "to come, arrive",
        T: "VNT",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут приходить",
        E: "to come, arrive",
        T: "VFT",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходи",
        E: "to come, arrive",
        T: "VI1",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходите",
        E: "to come, arrive",
        T: "VI2",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходил",
        E: "to come, arrive",
        T: "VPM",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходила",
        E: "to come, arrive",
        T: "VPF",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходило",
        E: "to come, arrive",
        T: "VPN",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходили",
        E: "to come, arrive",
        T: "VPP",
        A: "I",
        O: "приходить",
        RA: 469,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подняться",
        E: "to rise, climb",
        T: "VI",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимусь",
        E: "to rise, climb",
        T: "VF1",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимешься",
        E: "to rise, climb",
        T: "VF2",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимется",
        E: "to rise, climb",
        T: "VF3",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимемся",
        E: "to rise, climb",
        T: "VFW",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подниметесь",
        E: "to rise, climb",
        T: "VFF",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимутся",
        E: "to rise, climb",
        T: "VFT",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимись",
        E: "to rise, climb",
        T: "VI1",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднимитесь",
        E: "to rise, climb",
        T: "VI2",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднялся поднялся",
        E: "to rise, climb",
        T: "VPM",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднялась",
        E: "to rise, climb",
        T: "VPF",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднялось поднялось",
        E: "to rise, climb",
        T: "VPN",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поднялись поднялись",
        E: "to rise, climb",
        T: "VPP",
        A: "P",
        O: "подняться",
        RA: 479,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показаться",
        E: "to appear, come in sight",
        T: "VI",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажусь",
        E: "to appear, come in sight",
        T: "VF1",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажешься",
        E: "to appear, come in sight",
        T: "VF2",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажется",
        E: "to appear, come in sight",
        T: "VF3",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажемся",
        E: "to appear, come in sight",
        T: "VFW",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажетесь",
        E: "to appear, come in sight",
        T: "VFF",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажутся",
        E: "to appear, come in sight",
        T: "VFT",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажись",
        E: "to appear, come in sight",
        T: "VI1",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "покажитесь",
        E: "to appear, come in sight",
        T: "VI2",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показался",
        E: "to appear, come in sight",
        T: "VPM",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показалась",
        E: "to appear, come in sight",
        T: "VPF",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показалось",
        E: "to appear, come in sight",
        T: "VPN",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показались",
        E: "to appear, come in sight",
        T: "VPP",
        A: "P",
        O: "показаться",
        RA: 482,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираться",
        E: "to gather together, assemble; intend",
        T: "VI",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираюсь",
        E: "to gather together, assemble; intend",
        T: "VN1",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду собираться",
        E: "to gather together, assemble; intend",
        T: "VF1",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираешься",
        E: "to gather together, assemble; intend",
        T: "VN2",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь собираться",
        E: "to gather together, assemble; intend",
        T: "VF2",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собирается",
        E: "to gather together, assemble; intend",
        T: "VN3",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет собираться",
        E: "to gather together, assemble; intend",
        T: "VF3",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираемся",
        E: "to gather together, assemble; intend",
        T: "VNW",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем собираться",
        E: "to gather together, assemble; intend",
        T: "VFW",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираетесь",
        E: "to gather together, assemble; intend",
        T: "VNF",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете собираться",
        E: "to gather together, assemble; intend",
        T: "VFF",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собираются",
        E: "to gather together, assemble; intend",
        T: "VNT",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут собираться",
        E: "to gather together, assemble; intend",
        T: "VFT",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собирайся",
        E: "to gather together, assemble; intend",
        T: "VI1",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собирайтесь",
        E: "to gather together, assemble; intend",
        T: "VI2",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собирался",
        E: "to gather together, assemble; intend",
        T: "VPM",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собиралась",
        E: "to gather together, assemble; intend",
        T: "VPF",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собиралось",
        E: "to gather together, assemble; intend",
        T: "VPN",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собирались",
        E: "to gather together, assemble; intend",
        T: "VPP",
        A: "I",
        O: "собираться",
        RA: 484,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попасть",
        E: "to hit",
        T: "VI",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попаду",
        E: "to hit",
        T: "VF1",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадёшь",
        E: "to hit",
        T: "VF2",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадёт",
        E: "to hit",
        T: "VF3",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадём",
        E: "to hit",
        T: "VFW",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадёте",
        E: "to hit",
        T: "VFF",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадут",
        E: "to hit",
        T: "VFT",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попади",
        E: "to hit",
        T: "VI1",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попадите",
        E: "to hit",
        T: "VI2",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попал",
        E: "to hit",
        T: "VPM",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попала",
        E: "to hit",
        T: "VPF",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попало",
        E: "to hit",
        T: "VPN",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попали",
        E: "to hit",
        T: "VPP",
        A: "P",
        O: "попасть",
        RA: 485,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принять",
        E: "to take, admit, accept",
        T: "VI",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приму",
        E: "to take, admit, accept",
        T: "VF1",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примешь",
        E: "to take, admit, accept",
        T: "VF2",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примет",
        E: "to take, admit, accept",
        T: "VF3",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примем",
        E: "to take, admit, accept",
        T: "VFW",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примете",
        E: "to take, admit, accept",
        T: "VFF",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примут",
        E: "to take, admit, accept",
        T: "VFT",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прими",
        E: "to take, admit, accept",
        T: "VI1",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "примите",
        E: "to take, admit, accept",
        T: "VI2",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принял",
        E: "to take, admit, accept",
        T: "VPM",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приняла",
        E: "to take, admit, accept",
        T: "VPF",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приняло",
        E: "to take, admit, accept",
        T: "VPN",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приняли",
        E: "to take, admit, accept",
        T: "VPP",
        A: "P",
        O: "принять",
        RA: 486,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поехать",
        E: "to depart, set off",
        T: "VI",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поеду",
        E: "to depart, set off",
        T: "VF1",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поедешь",
        E: "to depart, set off",
        T: "VF2",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поедет",
        E: "to depart, set off",
        T: "VF3",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поедем",
        E: "to depart, set off",
        T: "VFW",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поедете",
        E: "to depart, set off",
        T: "VFF",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поедут",
        E: "to depart, set off",
        T: "VFT",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поезжай",
        E: "to depart, set off",
        T: "VI1",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поезжайте",
        E: "to depart, set off",
        T: "VI2",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поехал",
        E: "to depart, set off",
        T: "VPM",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поехала",
        E: "to depart, set off",
        T: "VPF",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поехало",
        E: "to depart, set off",
        T: "VPN",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поехали",
        E: "to depart, set off",
        T: "VPP",
        A: "P",
        O: "поехать",
        RA: 489,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышать",
        E: "to hear",
        T: "VI",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышу",
        E: "to hear",
        T: "VF1",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышишь",
        E: "to hear",
        T: "VF2",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышит",
        E: "to hear",
        T: "VF3",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышим",
        E: "to hear",
        T: "VFW",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышите",
        E: "to hear",
        T: "VFF",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышат",
        E: "to hear",
        T: "VFT",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышь",
        E: "to hear",
        T: "VI1",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышьте",
        E: "to hear",
        T: "VI2",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышал",
        E: "to hear",
        T: "VPM",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышала",
        E: "to hear",
        T: "VPF",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышало",
        E: "to hear",
        T: "VPN",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "услышали",
        E: "to hear",
        T: "VPP",
        A: "P",
        O: "услышать",
        RA: 490,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уметь",
        E: "to be able, know, can",
        T: "VI",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умею",
        E: "to be able, know, can",
        T: "VN1",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду уметь",
        E: "to be able, know, can",
        T: "VF1",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умеешь",
        E: "to be able, know, can",
        T: "VN2",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь уметь",
        E: "to be able, know, can",
        T: "VF2",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умеет",
        E: "to be able, know, can",
        T: "VN3",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет уметь",
        E: "to be able, know, can",
        T: "VF3",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умеем",
        E: "to be able, know, can",
        T: "VNW",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем уметь",
        E: "to be able, know, can",
        T: "VFW",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умеете",
        E: "to be able, know, can",
        T: "VNF",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете уметь",
        E: "to be able, know, can",
        T: "VFF",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умеют",
        E: "to be able, know, can",
        T: "VNT",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут уметь",
        E: "to be able, know, can",
        T: "VFT",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умей",
        E: "to be able, know, can",
        T: "VI1",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умейте",
        E: "to be able, know, can",
        T: "VI2",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умел",
        E: "to be able, know, can",
        T: "VPM",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умела",
        E: "to be able, know, can",
        T: "VPF",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умело",
        E: "to be able, know, can",
        T: "VPN",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умели",
        E: "to be able, know, can",
        T: "VPP",
        A: "I",
        O: "уметь",
        RA: 491,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случиться",
        E: "to happen",
        T: "VI",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случусь",
        E: "to happen",
        T: "VF1",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случишься",
        E: "to happen",
        T: "VF2",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случится",
        E: "to happen",
        T: "VF3",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случимся",
        E: "to happen",
        T: "VFW",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случитесь",
        E: "to happen",
        T: "VFF",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случатся",
        E: "to happen",
        T: "VFT",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случись",
        E: "to happen",
        T: "VI1",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случитесь",
        E: "to happen",
        T: "VI2",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случился",
        E: "to happen",
        T: "VPM",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случилась",
        E: "to happen",
        T: "VPF",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случилось",
        E: "to happen",
        T: "VPN",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "случились",
        E: "to happen",
        T: "VPP",
        A: "P",
        O: "случиться",
        RA: 492,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараться",
        E: "to try, endeavour",
        T: "VI",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараюсь",
        E: "to try, endeavour",
        T: "VN1",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду стараться",
        E: "to try, endeavour",
        T: "VF1",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараешься",
        E: "to try, endeavour",
        T: "VN2",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь стараться",
        E: "to try, endeavour",
        T: "VF2",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старается",
        E: "to try, endeavour",
        T: "VN3",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет стараться",
        E: "to try, endeavour",
        T: "VF3",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараемся",
        E: "to try, endeavour",
        T: "VNW",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем стараться",
        E: "to try, endeavour",
        T: "VFW",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараетесь",
        E: "to try, endeavour",
        T: "VNF",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете стараться",
        E: "to try, endeavour",
        T: "VFF",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стараются",
        E: "to try, endeavour",
        T: "VNT",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут стараться",
        E: "to try, endeavour",
        T: "VFT",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старайся",
        E: "to try, endeavour",
        T: "VI1",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старайтесь",
        E: "to try, endeavour",
        T: "VI2",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старался",
        E: "to try, endeavour",
        T: "VPM",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старалась",
        E: "to try, endeavour",
        T: "VPF",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старалось",
        E: "to try, endeavour",
        T: "VPN",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старались",
        E: "to try, endeavour",
        T: "VPP",
        A: "I",
        O: "стараться",
        RA: 504,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "звать",
        E: "to call",
        T: "VI",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зову",
        E: "to call",
        T: "VN1",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду звать",
        E: "to call",
        T: "VF1",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовёшь",
        E: "to call",
        T: "VN2",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь звать",
        E: "to call",
        T: "VF2",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовёт",
        E: "to call",
        T: "VN3",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет звать",
        E: "to call",
        T: "VF3",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовём",
        E: "to call",
        T: "VNW",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем звать",
        E: "to call",
        T: "VFW",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовёте",
        E: "to call",
        T: "VNF",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете звать",
        E: "to call",
        T: "VFF",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовут",
        E: "to call",
        T: "VNT",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут звать",
        E: "to call",
        T: "VFT",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зови",
        E: "to call",
        T: "VI1",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зовите",
        E: "to call",
        T: "VI2",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "звал",
        E: "to call",
        T: "VPM",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "звала",
        E: "to call",
        T: "VPF",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "звало",
        E: "to call",
        T: "VPN",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "звали",
        E: "to call",
        T: "VPP",
        A: "I",
        O: "звать",
        RA: 512,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойти",
        E: "to happen, occur, take place",
        T: "VI",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойду",
        E: "to happen, occur, take place",
        T: "VF1",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдёшь",
        E: "to happen, occur, take place",
        T: "VF2",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдёт",
        E: "to happen, occur, take place",
        T: "VF3",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдём",
        E: "to happen, occur, take place",
        T: "VFW",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдёте",
        E: "to happen, occur, take place",
        T: "VFF",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдут",
        E: "to happen, occur, take place",
        T: "VFT",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойди",
        E: "to happen, occur, take place",
        T: "VI1",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произойдите",
        E: "to happen, occur, take place",
        T: "VI2",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произошёл",
        E: "to happen, occur, take place",
        T: "VPM",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произошла",
        E: "to happen, occur, take place",
        T: "VPF",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произошло",
        E: "to happen, occur, take place",
        T: "VPN",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произошли",
        E: "to happen, occur, take place",
        T: "VPP",
        A: "P",
        O: "произойти",
        RA: 513,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "заниматься",
        E: "to be occupied, engage",
        T: "VI",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимаюсь",
        E: "to be occupied, engage",
        T: "VN1",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду заниматься",
        E: "to be occupied, engage",
        T: "VF1",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимаешься",
        E: "to be occupied, engage",
        T: "VN2",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь заниматься",
        E: "to be occupied, engage",
        T: "VF2",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимается",
        E: "to be occupied, engage",
        T: "VN3",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет заниматься",
        E: "to be occupied, engage",
        T: "VF3",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимаемся",
        E: "to be occupied, engage",
        T: "VNW",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем заниматься",
        E: "to be occupied, engage",
        T: "VFW",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимаетесь",
        E: "to be occupied, engage",
        T: "VNF",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете заниматься",
        E: "to be occupied, engage",
        T: "VFF",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимаются",
        E: "to be occupied, engage",
        T: "VNT",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут заниматься",
        E: "to be occupied, engage",
        T: "VFT",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимайся",
        E: "to be occupied, engage",
        T: "VI1",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимайтесь",
        E: "to be occupied, engage",
        T: "VI2",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимался",
        E: "to be occupied, engage",
        T: "VPM",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занималась",
        E: "to be occupied, engage",
        T: "VPF",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занималось",
        E: "to be occupied, engage",
        T: "VPN",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занимались",
        E: "to be occupied, engage",
        T: "VPP",
        A: "I",
        O: "заниматься",
        RA: 518,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положить",
        E: "to lay down, put down, place",
        T: "VI",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положу",
        E: "to lay down, put down, place",
        T: "VF1",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положишь",
        E: "to lay down, put down, place",
        T: "VF2",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положит",
        E: "to lay down, put down, place",
        T: "VF3",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положим",
        E: "to lay down, put down, place",
        T: "VFW",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положите",
        E: "to lay down, put down, place",
        T: "VFF",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положат",
        E: "to lay down, put down, place",
        T: "VFT",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положи",
        E: "to lay down, put down, place",
        T: "VI1",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положите",
        E: "to lay down, put down, place",
        T: "VI2",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положил",
        E: "to lay down, put down, place",
        T: "VPM",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положила",
        E: "to lay down, put down, place",
        T: "VPF",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положило",
        E: "to lay down, put down, place",
        T: "VPN",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "положили",
        E: "to lay down, put down, place",
        T: "VPP",
        A: "P",
        O: "положить",
        RA: 527,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купить",
        E: "to buy, purchase",
        T: "VI",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "куплю",
        E: "to buy, purchase",
        T: "VF1",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купишь",
        E: "to buy, purchase",
        T: "VF2",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купит",
        E: "to buy, purchase",
        T: "VF3",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купим",
        E: "to buy, purchase",
        T: "VFW",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купите",
        E: "to buy, purchase",
        T: "VFF",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купят",
        E: "to buy, purchase",
        T: "VFT",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купи",
        E: "to buy, purchase",
        T: "VI1",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купите",
        E: "to buy, purchase",
        T: "VI2",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купил",
        E: "to buy, purchase",
        T: "VPM",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купила",
        E: "to buy, purchase",
        T: "VPF",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купило",
        E: "to buy, purchase",
        T: "VPN",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "купили",
        E: "to buy, purchase",
        T: "VPP",
        A: "P",
        O: "купить",
        RA: 533,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умереть",
        E: "to die",
        T: "VI",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умру",
        E: "to die",
        T: "VF1",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрёшь",
        E: "to die",
        T: "VF2",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрёт",
        E: "to die",
        T: "VF3",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрём",
        E: "to die",
        T: "VFW",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрёте",
        E: "to die",
        T: "VFF",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрут",
        E: "to die",
        T: "VFT",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умри",
        E: "to die",
        T: "VI1",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умрите",
        E: "to die",
        T: "VI2",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умер",
        E: "to die",
        T: "VPM",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умерла",
        E: "to die",
        T: "VPF",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умерло",
        E: "to die",
        T: "VPN",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "умерли",
        E: "to die",
        T: "VPP",
        A: "P",
        O: "умереть",
        RA: 537,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пить",
        E: "to drink",
        T: "VI",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пью",
        E: "to drink",
        T: "VN1",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду пить",
        E: "to drink",
        T: "VF1",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пьёшь",
        E: "to drink",
        T: "VN2",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь пить",
        E: "to drink",
        T: "VF2",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пьёт",
        E: "to drink",
        T: "VN3",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет пить",
        E: "to drink",
        T: "VF3",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пьём",
        E: "to drink",
        T: "VNW",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем пить",
        E: "to drink",
        T: "VFW",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пьёте",
        E: "to drink",
        T: "VNF",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете пить",
        E: "to drink",
        T: "VFF",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пьют",
        E: "to drink",
        T: "VNT",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут пить",
        E: "to drink",
        T: "VFT",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пей",
        E: "to drink",
        T: "VI1",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пейте",
        E: "to drink",
        T: "VI2",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пил",
        E: "to drink",
        T: "VPM",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пила",
        E: "to drink",
        T: "VPF",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пило",
        E: "to drink",
        T: "VPN",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пили",
        E: "to drink",
        T: "VPP",
        A: "I",
        O: "пить",
        RA: 543,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходить",
        E: "to pass, go;study",
        T: "VI",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прохожу",
        E: "to pass, go;study",
        T: "VN1",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду проходить",
        E: "to pass, go;study",
        T: "VF1",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходишь",
        E: "to pass, go;study",
        T: "VN2",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь проходить",
        E: "to pass, go;study",
        T: "VF2",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходит",
        E: "to pass, go;study",
        T: "VN3",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет проходить",
        E: "to pass, go;study",
        T: "VF3",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходим",
        E: "to pass, go;study",
        T: "VNW",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем проходить",
        E: "to pass, go;study",
        T: "VFW",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходите",
        E: "to pass, go;study",
        T: "VNF",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете проходить",
        E: "to pass, go;study",
        T: "VFF",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходят",
        E: "to pass, go;study",
        T: "VNT",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут проходить",
        E: "to pass, go;study",
        T: "VFT",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходи",
        E: "to pass, go;study",
        T: "VI1",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходите",
        E: "to pass, go;study",
        T: "VI2",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходил",
        E: "to pass, go;study",
        T: "VPM",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходила",
        E: "to pass, go;study",
        T: "VPF",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходило",
        E: "to pass, go;study",
        T: "VPN",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проходили",
        E: "to pass, go;study",
        T: "VPP",
        A: "I",
        O: "проходить",
        RA: 549,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снять",
        E: "to take away, take off;photograph",
        T: "VI",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сниму",
        E: "to take away, take off;photograph",
        T: "VF1",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимешь",
        E: "to take away, take off;photograph",
        T: "VF2",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимет",
        E: "to take away, take off;photograph",
        T: "VF3",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимем",
        E: "to take away, take off;photograph",
        T: "VFW",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимете",
        E: "to take away, take off;photograph",
        T: "VFF",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимут",
        E: "to take away, take off;photograph",
        T: "VFT",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сними",
        E: "to take away, take off;photograph",
        T: "VI1",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снимите",
        E: "to take away, take off;photograph",
        T: "VI2",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снял",
        E: "to take away, take off;photograph",
        T: "VPM",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сняла",
        E: "to take away, take off;photograph",
        T: "VPF",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сняло сняло",
        E: "to take away, take off;photograph",
        T: "VPN",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сняли",
        E: "to take away, take off;photograph",
        T: "VPP",
        A: "P",
        O: "снять",
        RA: 551,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начаться",
        E: "to begin",
        T: "VI",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнусь",
        E: "to begin",
        T: "VF1",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнёшься",
        E: "to begin",
        T: "VF2",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнётся",
        E: "to begin",
        T: "VF3",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнёмся",
        E: "to begin",
        T: "VFW",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнётесь",
        E: "to begin",
        T: "VFF",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнутся",
        E: "to begin",
        T: "VFT",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнись",
        E: "to begin",
        T: "VI1",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начнитесь",
        E: "to begin",
        T: "VI2",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начался начался начался",
        E: "to begin",
        T: "VPM",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "началась",
        E: "to begin",
        T: "VPF",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "началось началось",
        E: "to begin",
        T: "VPN",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "начались начались",
        E: "to begin",
        T: "VPP",
        A: "P",
        O: "начаться",
        RA: 558,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представлять",
        E: "to present, introduce, imagine",
        T: "VI",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляю",
        E: "to present, introduce, imagine",
        T: "VN1",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду представлять",
        E: "to present, introduce, imagine",
        T: "VF1",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляешь",
        E: "to present, introduce, imagine",
        T: "VN2",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь представлять",
        E: "to present, introduce, imagine",
        T: "VF2",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляет",
        E: "to present, introduce, imagine",
        T: "VN3",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет представлять",
        E: "to present, introduce, imagine",
        T: "VF3",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляем",
        E: "to present, introduce, imagine",
        T: "VNW",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем представлять",
        E: "to present, introduce, imagine",
        T: "VFW",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляете",
        E: "to present, introduce, imagine",
        T: "VNF",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете представлять",
        E: "to present, introduce, imagine",
        T: "VFF",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляют",
        E: "to present, introduce, imagine",
        T: "VNT",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут представлять",
        E: "to present, introduce, imagine",
        T: "VFT",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляй",
        E: "to present, introduce, imagine",
        T: "VI1",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляйте",
        E: "to present, introduce, imagine",
        T: "VI2",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представлял",
        E: "to present, introduce, imagine",
        T: "VPM",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляла",
        E: "to present, introduce, imagine",
        T: "VPF",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляло",
        E: "to present, introduce, imagine",
        T: "VPN",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представляли",
        E: "to present, introduce, imagine",
        T: "VPP",
        A: "I",
        O: "представлять",
        RA: 564,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснить",
        E: "to explain",
        T: "VI",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объясню",
        E: "to explain",
        T: "VF1",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснишь",
        E: "to explain",
        T: "VF2",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснит",
        E: "to explain",
        T: "VF3",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объясним",
        E: "to explain",
        T: "VFW",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объясните",
        E: "to explain",
        T: "VFF",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснят",
        E: "to explain",
        T: "VFT",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объясни",
        E: "to explain",
        T: "VI1",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объясните",
        E: "to explain",
        T: "VI2",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснил",
        E: "to explain",
        T: "VPM",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснила",
        E: "to explain",
        T: "VPF",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснило",
        E: "to explain",
        T: "VPN",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "объяснили",
        E: "to explain",
        T: "VPP",
        A: "P",
        O: "объяснить",
        RA: 566,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнести",
        E: "to pronounce, say, utter",
        T: "VI",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесу",
        E: "to pronounce, say, utter",
        T: "VF1",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесёшь",
        E: "to pronounce, say, utter",
        T: "VF2",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесёт",
        E: "to pronounce, say, utter",
        T: "VF3",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесём",
        E: "to pronounce, say, utter",
        T: "VFW",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесёте",
        E: "to pronounce, say, utter",
        T: "VFF",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесут",
        E: "to pronounce, say, utter",
        T: "VFT",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнеси",
        E: "to pronounce, say, utter",
        T: "VI1",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесите",
        E: "to pronounce, say, utter",
        T: "VI2",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнёс",
        E: "to pronounce, say, utter",
        T: "VPM",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесла",
        E: "to pronounce, say, utter",
        T: "VPF",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесло",
        E: "to pronounce, say, utter",
        T: "VPN",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "произнесли",
        E: "to pronounce, say, utter",
        T: "VPP",
        A: "P",
        O: "произнести",
        RA: 568,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравиться",
        E: "to please, like",
        T: "VI",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравлюсь",
        E: "to please, like",
        T: "VN1",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду нравиться",
        E: "to please, like",
        T: "VF1",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравишься",
        E: "to please, like",
        T: "VN2",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь нравиться",
        E: "to please, like",
        T: "VF2",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравится",
        E: "to please, like",
        T: "VN3",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет нравиться",
        E: "to please, like",
        T: "VF3",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравимся",
        E: "to please, like",
        T: "VNW",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем нравиться",
        E: "to please, like",
        T: "VFW",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравитесь",
        E: "to please, like",
        T: "VNF",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете нравиться",
        E: "to please, like",
        T: "VFF",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравятся",
        E: "to please, like",
        T: "VNT",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут нравиться",
        E: "to please, like",
        T: "VFT",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравься",
        E: "to please, like",
        T: "VI1",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравьтесь",
        E: "to please, like",
        T: "VI2",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравился",
        E: "to please, like",
        T: "VPM",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравилась",
        E: "to please, like",
        T: "VPF",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравилось",
        E: "to please, like",
        T: "VPN",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нравились",
        E: "to please, like",
        T: "VPP",
        A: "I",
        O: "нравиться",
        RA: 570,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существовать",
        E: "to exist, to be",
        T: "VI",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существую",
        E: "to exist, to be",
        T: "VN1",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду существовать",
        E: "to exist, to be",
        T: "VF1",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуешь",
        E: "to exist, to be",
        T: "VN2",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь существовать",
        E: "to exist, to be",
        T: "VF2",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существует",
        E: "to exist, to be",
        T: "VN3",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет существовать",
        E: "to exist, to be",
        T: "VF3",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуем",
        E: "to exist, to be",
        T: "VNW",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем существовать",
        E: "to exist, to be",
        T: "VFW",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуете",
        E: "to exist, to be",
        T: "VNF",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете существовать",
        E: "to exist, to be",
        T: "VFF",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуют",
        E: "to exist, to be",
        T: "VNT",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут существовать",
        E: "to exist, to be",
        T: "VFT",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуй",
        E: "to exist, to be",
        T: "VI1",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существуйте",
        E: "to exist, to be",
        T: "VI2",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существовал",
        E: "to exist, to be",
        T: "VPM",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существовала",
        E: "to exist, to be",
        T: "VPF",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существовало",
        E: "to exist, to be",
        T: "VPN",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "существовали",
        E: "to exist, to be",
        T: "VPP",
        A: "I",
        O: "существовать",
        RA: 574,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удаться",
        E: "turn out well, succeed, manage",
        T: "VI",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удамся",
        E: "turn out well, succeed, manage",
        T: "VF1",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удашься",
        E: "turn out well, succeed, manage",
        T: "VF2",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удастся",
        E: "turn out well, succeed, manage",
        T: "VF3",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удадимся",
        E: "turn out well, succeed, manage",
        T: "VFW",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удадитесь",
        E: "turn out well, succeed, manage",
        T: "VFF",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удадутся",
        E: "turn out well, succeed, manage",
        T: "VFT",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удайся",
        E: "turn out well, succeed, manage",
        T: "VI1",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удайтесь",
        E: "turn out well, succeed, manage",
        T: "VI2",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удался",
        E: "turn out well, succeed, manage",
        T: "VPM",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удалась",
        E: "turn out well, succeed, manage",
        T: "VPF",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удалось",
        E: "turn out well, succeed, manage",
        T: "VPN",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удались",
        E: "turn out well, succeed, manage",
        T: "VPP",
        A: "P",
        O: "удаться",
        RA: 576,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прийтись",
        E: "to fit, fall; have to",
        T: "VI",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придусь",
        E: "to fit, fall; have to",
        T: "VF1",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёшься",
        E: "to fit, fall; have to",
        T: "VF2",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придётся",
        E: "to fit, fall; have to",
        T: "VF3",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёмся",
        E: "to fit, fall; have to",
        T: "VFW",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придётесь",
        E: "to fit, fall; have to",
        T: "VFF",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придутся",
        E: "to fit, fall; have to",
        T: "VFT",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придись",
        E: "to fit, fall; have to",
        T: "VI1",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придитесь",
        E: "to fit, fall; have to",
        T: "VI2",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришёлся",
        E: "to fit, fall; have to",
        T: "VPM",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлась",
        E: "to fit, fall; have to",
        T: "VPF",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлось",
        E: "to fit, fall; have to",
        T: "VPN",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлись",
        E: "to fit, fall; have to",
        T: "VPP",
        A: "P",
        O: "прийтись",
        RA: 580,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представить",
        E: "to presetn, produce, introduce",
        T: "VI",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представлю",
        E: "to presetn, produce, introduce",
        T: "VF1",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представишь",
        E: "to presetn, produce, introduce",
        T: "VF2",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представит",
        E: "to presetn, produce, introduce",
        T: "VF3",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представим",
        E: "to presetn, produce, introduce",
        T: "VFW",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представите",
        E: "to presetn, produce, introduce",
        T: "VFF",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представят",
        E: "to presetn, produce, introduce",
        T: "VFT",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представь",
        E: "to presetn, produce, introduce",
        T: "VI1",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представьте",
        E: "to presetn, produce, introduce",
        T: "VI2",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представил",
        E: "to presetn, produce, introduce",
        T: "VPM",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представила",
        E: "to presetn, produce, introduce",
        T: "VPF",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представило",
        E: "to presetn, produce, introduce",
        T: "VPN",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "представили",
        E: "to presetn, produce, introduce",
        T: "VPP",
        A: "P",
        O: "представить",
        RA: 592,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назвать",
        E: "to call, name",
        T: "VI",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назову",
        E: "to call, name",
        T: "VF1",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовёшь",
        E: "to call, name",
        T: "VF2",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовёт",
        E: "to call, name",
        T: "VF3",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовём",
        E: "to call, name",
        T: "VFW",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовёте",
        E: "to call, name",
        T: "VFF",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовут",
        E: "to call, name",
        T: "VFT",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назови",
        E: "to call, name",
        T: "VI1",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назовите",
        E: "to call, name",
        T: "VI2",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назвал",
        E: "to call, name",
        T: "VPM",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назвала",
        E: "to call, name",
        T: "VPF",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назвало",
        E: "to call, name",
        T: "VPN",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назвали",
        E: "to call, name",
        T: "VPP",
        A: "P",
        O: "назвать",
        RA: 603,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаться",
        E: "to smile",
        T: "VI",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаюсь",
        E: "to smile",
        T: "VN1",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду улыбаться",
        E: "to smile",
        T: "VF1",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаешься",
        E: "to smile",
        T: "VN2",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь улыбаться",
        E: "to smile",
        T: "VF2",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбается",
        E: "to smile",
        T: "VN3",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет улыбаться",
        E: "to smile",
        T: "VF3",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаемся",
        E: "to smile",
        T: "VNW",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем улыбаться",
        E: "to smile",
        T: "VFW",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаетесь",
        E: "to smile",
        T: "VNF",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете улыбаться",
        E: "to smile",
        T: "VFF",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбаются",
        E: "to smile",
        T: "VNT",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут улыбаться",
        E: "to smile",
        T: "VFT",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбайся",
        E: "to smile",
        T: "VI1",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбайтесь",
        E: "to smile",
        T: "VI2",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбался",
        E: "to smile",
        T: "VPM",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбалась",
        E: "to smile",
        T: "VPF",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбалось",
        E: "to smile",
        T: "VPN",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбались",
        E: "to smile",
        T: "VPP",
        A: "I",
        O: "улыбаться",
        RA: 605,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнуться",
        E: "to smile",
        T: "VI",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнусь",
        E: "to smile",
        T: "VF1",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнёшься",
        E: "to smile",
        T: "VF2",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнётся",
        E: "to smile",
        T: "VF3",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнёмся",
        E: "to smile",
        T: "VFW",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнётесь",
        E: "to smile",
        T: "VFF",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнутся",
        E: "to smile",
        T: "VFT",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнись",
        E: "to smile",
        T: "VI1",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнитесь",
        E: "to smile",
        T: "VI2",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнулся",
        E: "to smile",
        T: "VPM",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнулась",
        E: "to smile",
        T: "VPF",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнулось",
        E: "to smile",
        T: "VPN",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "улыбнулись",
        E: "to smile",
        T: "VPP",
        A: "P",
        O: "улыбнуться",
        RA: 612,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеяться",
        E: "to laugh",
        T: "VI",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеюсь",
        E: "to laugh",
        T: "VN1",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду смеяться",
        E: "to laugh",
        T: "VF1",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеёшься",
        E: "to laugh",
        T: "VN2",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь смеяться",
        E: "to laugh",
        T: "VF2",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеётся",
        E: "to laugh",
        T: "VN3",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет смеяться",
        E: "to laugh",
        T: "VF3",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеёмся",
        E: "to laugh",
        T: "VNW",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем смеяться",
        E: "to laugh",
        T: "VFW",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеётесь",
        E: "to laugh",
        T: "VNF",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете смеяться",
        E: "to laugh",
        T: "VFF",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеются",
        E: "to laugh",
        T: "VNT",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут смеяться",
        E: "to laugh",
        T: "VFT",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смейся",
        E: "to laugh",
        T: "VI1",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смейтесь",
        E: "to laugh",
        T: "VI2",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеялся",
        E: "to laugh",
        T: "VPM",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеялась",
        E: "to laugh",
        T: "VPF",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеялось",
        E: "to laugh",
        T: "VPN",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "смеялись",
        E: "to laugh",
        T: "VPP",
        A: "I",
        O: "смеяться",
        RA: 622,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватать",
        E: "to snatch, seize; be sufficient",
        T: "VI",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватаю",
        E: "to snatch, seize; be sufficient",
        T: "VN1",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду хватать",
        E: "to snatch, seize; be sufficient",
        T: "VF1",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватаешь",
        E: "to snatch, seize; be sufficient",
        T: "VN2",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь хватать",
        E: "to snatch, seize; be sufficient",
        T: "VF2",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватает",
        E: "to snatch, seize; be sufficient",
        T: "VN3",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет хватать",
        E: "to snatch, seize; be sufficient",
        T: "VF3",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватаем",
        E: "to snatch, seize; be sufficient",
        T: "VNW",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем хватать",
        E: "to snatch, seize; be sufficient",
        T: "VFW",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватаете",
        E: "to snatch, seize; be sufficient",
        T: "VNF",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете хватать",
        E: "to snatch, seize; be sufficient",
        T: "VFF",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватают",
        E: "to snatch, seize; be sufficient",
        T: "VNT",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут хватать",
        E: "to snatch, seize; be sufficient",
        T: "VFT",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватай",
        E: "to snatch, seize; be sufficient",
        T: "VI1",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватайте",
        E: "to snatch, seize; be sufficient",
        T: "VI2",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватал",
        E: "to snatch, seize; be sufficient",
        T: "VPM",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватала",
        E: "to snatch, seize; be sufficient",
        T: "VPF",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватало",
        E: "to snatch, seize; be sufficient",
        T: "VPN",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватали",
        E: "to snatch, seize; be sufficient",
        T: "VPP",
        A: "I",
        O: "хватать",
        RA: 625,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствовать",
        E: "to feel",
        T: "VI",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствую",
        E: "to feel",
        T: "VF1",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуешь",
        E: "to feel",
        T: "VF2",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствует",
        E: "to feel",
        T: "VF3",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуем",
        E: "to feel",
        T: "VFW",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуете",
        E: "to feel",
        T: "VFF",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуют",
        E: "to feel",
        T: "VFT",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуй",
        E: "to feel",
        T: "VI1",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствуйте",
        E: "to feel",
        T: "VI2",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствовал",
        E: "to feel",
        T: "VPM",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствовала",
        E: "to feel",
        T: "VPF",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствовало",
        E: "to feel",
        T: "VPN",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почувствовали",
        E: "to feel",
        T: "VPP",
        A: "P",
        O: "почувствовать",
        RA: 629,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принести",
        E: "to bring",
        T: "VI",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесу",
        E: "to bring",
        T: "VF1",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесёшь",
        E: "to bring",
        T: "VF2",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесёт",
        E: "to bring",
        T: "VF3",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесём",
        E: "to bring",
        T: "VFW",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесёте",
        E: "to bring",
        T: "VFF",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесут",
        E: "to bring",
        T: "VFT",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принеси",
        E: "to bring",
        T: "VI1",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесите",
        E: "to bring",
        T: "VI2",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принёс",
        E: "to bring",
        T: "VPM",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесла",
        E: "to bring",
        T: "VPF",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесло",
        E: "to bring",
        T: "VPN",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принесли",
        E: "to bring",
        T: "VPP",
        A: "P",
        O: "принести",
        RA: 630,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласиться",
        E: "to agree, consent",
        T: "VI",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соглашусь",
        E: "to agree, consent",
        T: "VF1",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласишься",
        E: "to agree, consent",
        T: "VF2",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласится",
        E: "to agree, consent",
        T: "VF3",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласимся",
        E: "to agree, consent",
        T: "VFW",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласитесь",
        E: "to agree, consent",
        T: "VFF",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласятся",
        E: "to agree, consent",
        T: "VFT",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласись",
        E: "to agree, consent",
        T: "VI1",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласитесь",
        E: "to agree, consent",
        T: "VI2",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласился",
        E: "to agree, consent",
        T: "VPM",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласилась",
        E: "to agree, consent",
        T: "VPF",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласилось",
        E: "to agree, consent",
        T: "VPN",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "согласились",
        E: "to agree, consent",
        T: "VPP",
        A: "P",
        O: "согласиться",
        RA: 636,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватить",
        E: "to snatch, seize, suffice",
        T: "VI",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хвачу",
        E: "to snatch, seize, suffice",
        T: "VF1",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватишь",
        E: "to snatch, seize, suffice",
        T: "VF2",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватит",
        E: "to snatch, seize, suffice",
        T: "VF3",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватим",
        E: "to snatch, seize, suffice",
        T: "VFW",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватите",
        E: "to snatch, seize, suffice",
        T: "VFF",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватят",
        E: "to snatch, seize, suffice",
        T: "VFT",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хвати",
        E: "to snatch, seize, suffice",
        T: "VI1",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватите",
        E: "to snatch, seize, suffice",
        T: "VI2",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватил",
        E: "to snatch, seize, suffice",
        T: "VPM",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватила",
        E: "to snatch, seize, suffice",
        T: "VPF",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватило",
        E: "to snatch, seize, suffice",
        T: "VPN",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хватили",
        E: "to snatch, seize, suffice",
        T: "VPP",
        A: "P",
        O: "хватить",
        RA: 643,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимать",
        E: "to take, admit, accept",
        T: "VI",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимаю",
        E: "to take, admit, accept",
        T: "VN1",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду принимать",
        E: "to take, admit, accept",
        T: "VF1",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимаешь",
        E: "to take, admit, accept",
        T: "VN2",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь принимать",
        E: "to take, admit, accept",
        T: "VF2",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимает",
        E: "to take, admit, accept",
        T: "VN3",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет принимать",
        E: "to take, admit, accept",
        T: "VF3",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимаем",
        E: "to take, admit, accept",
        T: "VNW",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем принимать",
        E: "to take, admit, accept",
        T: "VFW",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимаете",
        E: "to take, admit, accept",
        T: "VNF",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете принимать",
        E: "to take, admit, accept",
        T: "VFF",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимают",
        E: "to take, admit, accept",
        T: "VNT",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут принимать",
        E: "to take, admit, accept",
        T: "VFT",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимай",
        E: "to take, admit, accept",
        T: "VI1",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимайте",
        E: "to take, admit, accept",
        T: "VI2",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимал",
        E: "to take, admit, accept",
        T: "VPM",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимала",
        E: "to take, admit, accept",
        T: "VPF",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимало",
        E: "to take, admit, accept",
        T: "VPN",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "принимали",
        E: "to take, admit, accept",
        T: "VPP",
        A: "I",
        O: "принимать",
        RA: 650,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убить",
        E: "to kill",
        T: "VI",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убью",
        E: "to kill",
        T: "VF1",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убьёшь",
        E: "to kill",
        T: "VF2",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убьёт",
        E: "to kill",
        T: "VF3",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убьём",
        E: "to kill",
        T: "VFW",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убьёте",
        E: "to kill",
        T: "VFF",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убьют",
        E: "to kill",
        T: "VFT",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убей",
        E: "to kill",
        T: "VI1",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убейте",
        E: "to kill",
        T: "VI2",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убил",
        E: "to kill",
        T: "VPM",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убила",
        E: "to kill",
        T: "VPF",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убило",
        E: "to kill",
        T: "VPN",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "убили",
        E: "to kill",
        T: "VPP",
        A: "P",
        O: "убить",
        RA: 655,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложить",
        E: "to offer, propose, suggest",
        T: "VI",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложу",
        E: "to offer, propose, suggest",
        T: "VF1",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложишь",
        E: "to offer, propose, suggest",
        T: "VF2",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложит",
        E: "to offer, propose, suggest",
        T: "VF3",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложим",
        E: "to offer, propose, suggest",
        T: "VFW",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложите",
        E: "to offer, propose, suggest",
        T: "VFF",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложат",
        E: "to offer, propose, suggest",
        T: "VFT",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложи",
        E: "to offer, propose, suggest",
        T: "VI1",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложите",
        E: "to offer, propose, suggest",
        T: "VI2",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложил",
        E: "to offer, propose, suggest",
        T: "VPM",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложила",
        E: "to offer, propose, suggest",
        T: "VPF",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложило",
        E: "to offer, propose, suggest",
        T: "VPN",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "предложили",
        E: "to offer, propose, suggest",
        T: "VPP",
        A: "P",
        O: "предложить",
        RA: 659,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходиться",
        E: "to fit, fall; have to",
        T: "VI",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прихожусь",
        E: "to fit, fall; have to",
        T: "VN1",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду приходиться",
        E: "to fit, fall; have to",
        T: "VF1",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходишься",
        E: "to fit, fall; have to",
        T: "VN2",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь приходиться",
        E: "to fit, fall; have to",
        T: "VF2",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходится",
        E: "to fit, fall; have to",
        T: "VN3",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет приходиться",
        E: "to fit, fall; have to",
        T: "VF3",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходимся",
        E: "to fit, fall; have to",
        T: "VNW",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем приходиться",
        E: "to fit, fall; have to",
        T: "VFW",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходитесь",
        E: "to fit, fall; have to",
        T: "VNF",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете приходиться",
        E: "to fit, fall; have to",
        T: "VFF",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходятся",
        E: "to fit, fall; have to",
        T: "VNT",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут приходиться",
        E: "to fit, fall; have to",
        T: "VFT",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходись",
        E: "to fit, fall; have to",
        T: "VI1",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходитесь",
        E: "to fit, fall; have to",
        T: "VI2",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходился",
        E: "to fit, fall; have to",
        T: "VPM",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходилась",
        E: "to fit, fall; have to",
        T: "VPF",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходилось",
        E: "to fit, fall; have to",
        T: "VPN",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приходились",
        E: "to fit, fall; have to",
        T: "VPP",
        A: "I",
        O: "приходиться",
        RA: 661,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпить",
        E: "to drink",
        T: "VI",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпью",
        E: "to drink",
        T: "VF1",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпьешь",
        E: "to drink",
        T: "VF2",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпьет",
        E: "to drink",
        T: "VF3",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпьем",
        E: "to drink",
        T: "VFW",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпьете",
        E: "to drink",
        T: "VFF",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпьют",
        E: "to drink",
        T: "VFT",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпей",
        E: "to drink",
        T: "VI1",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпейте",
        E: "to drink",
        T: "VI2",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпил",
        E: "to drink",
        T: "VPM",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпила",
        E: "to drink",
        T: "VPF",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпило",
        E: "to drink",
        T: "VPN",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выпили",
        E: "to drink",
        T: "VPP",
        A: "P",
        O: "выпить",
        RA: 662,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнуть",
        E: "to cry, shout",
        T: "VI",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикну",
        E: "to cry, shout",
        T: "VF1",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнешь",
        E: "to cry, shout",
        T: "VF2",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнет",
        E: "to cry, shout",
        T: "VF3",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнем",
        E: "to cry, shout",
        T: "VFW",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнете",
        E: "to cry, shout",
        T: "VFF",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнут",
        E: "to cry, shout",
        T: "VFT",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикни",
        E: "to cry, shout",
        T: "VI1",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикните",
        E: "to cry, shout",
        T: "VI2",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнул",
        E: "to cry, shout",
        T: "VPM",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнула",
        E: "to cry, shout",
        T: "VPF",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнуло",
        E: "to cry, shout",
        T: "VPN",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крикнули",
        E: "to cry, shout",
        T: "VPP",
        A: "P",
        O: "крикнуть",
        RA: 663,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывать",
        E: "to show, display",
        T: "VI",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показываю",
        E: "to show, display",
        T: "VN1",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду показывать",
        E: "to show, display",
        T: "VF1",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показываешь",
        E: "to show, display",
        T: "VN2",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь показывать",
        E: "to show, display",
        T: "VF2",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывает",
        E: "to show, display",
        T: "VN3",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет показывать",
        E: "to show, display",
        T: "VF3",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показываем",
        E: "to show, display",
        T: "VNW",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем показывать",
        E: "to show, display",
        T: "VFW",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показываете",
        E: "to show, display",
        T: "VNF",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете показывать",
        E: "to show, display",
        T: "VFF",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывают",
        E: "to show, display",
        T: "VNT",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут показывать",
        E: "to show, display",
        T: "VFT",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывай",
        E: "to show, display",
        T: "VI1",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывайте",
        E: "to show, display",
        T: "VI2",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывал",
        E: "to show, display",
        T: "VPM",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывала",
        E: "to show, display",
        T: "VPF",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывало",
        E: "to show, display",
        T: "VPN",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "показывали",
        E: "to show, display",
        T: "VPP",
        A: "I",
        O: "показывать",
        RA: 666,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызвать",
        E: "to call, send",
        T: "VI",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызову",
        E: "to call, send",
        T: "VF1",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовешь",
        E: "to call, send",
        T: "VF2",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовет",
        E: "to call, send",
        T: "VF3",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовем",
        E: "to call, send",
        T: "VFW",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовете",
        E: "to call, send",
        T: "VFF",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовут",
        E: "to call, send",
        T: "VFT",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызови",
        E: "to call, send",
        T: "VI1",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызовите",
        E: "to call, send",
        T: "VI2",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызвал",
        E: "to call, send",
        T: "VPM",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызвала",
        E: "to call, send",
        T: "VPF",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызвало",
        E: "to call, send",
        T: "VPN",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызвали",
        E: "to call, send",
        T: "VPP",
        A: "P",
        O: "вызвать",
        RA: 670,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросить",
        E: "to ask",
        T: "VI",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попрошу",
        E: "to ask",
        T: "VF1",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросишь",
        E: "to ask",
        T: "VF2",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросит",
        E: "to ask",
        T: "VF3",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросим",
        E: "to ask",
        T: "VFW",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросите",
        E: "to ask",
        T: "VFF",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросят",
        E: "to ask",
        T: "VFT",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попроси",
        E: "to ask",
        T: "VI1",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросите",
        E: "to ask",
        T: "VI2",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросил",
        E: "to ask",
        T: "VPM",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросила",
        E: "to ask",
        T: "VPF",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросило",
        E: "to ask",
        T: "VPN",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попросили",
        E: "to ask",
        T: "VPP",
        A: "P",
        O: "попросить",
        RA: 672,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываться",
        E: "to turn out, find oneself",
        T: "VI",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываюсь",
        E: "to turn out, find oneself",
        T: "VN1",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду оказываться",
        E: "to turn out, find oneself",
        T: "VF1",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываешься",
        E: "to turn out, find oneself",
        T: "VN2",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь оказываться",
        E: "to turn out, find oneself",
        T: "VF2",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывается",
        E: "to turn out, find oneself",
        T: "VN3",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет оказываться",
        E: "to turn out, find oneself",
        T: "VF3",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываемся",
        E: "to turn out, find oneself",
        T: "VNW",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем оказываться",
        E: "to turn out, find oneself",
        T: "VFW",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываетесь",
        E: "to turn out, find oneself",
        T: "VNF",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете оказываться",
        E: "to turn out, find oneself",
        T: "VFF",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказываются",
        E: "to turn out, find oneself",
        T: "VNT",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут оказываться",
        E: "to turn out, find oneself",
        T: "VFT",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывайся",
        E: "to turn out, find oneself",
        T: "VI1",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывайтесь",
        E: "to turn out, find oneself",
        T: "VI2",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывался",
        E: "to turn out, find oneself",
        T: "VPM",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывалась",
        E: "to turn out, find oneself",
        T: "VPF",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывалось",
        E: "to turn out, find oneself",
        T: "VPN",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оказывались",
        E: "to turn out, find oneself",
        T: "VPP",
        A: "I",
        O: "оказываться",
        RA: 676,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привести",
        E: "to bring",
        T: "VI",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведу",
        E: "to bring",
        T: "VF1",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведёшь",
        E: "to bring",
        T: "VF2",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведёт",
        E: "to bring",
        T: "VF3",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведём",
        E: "to bring",
        T: "VFW",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведёте",
        E: "to bring",
        T: "VFF",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведут",
        E: "to bring",
        T: "VFT",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведи",
        E: "to bring",
        T: "VI1",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "приведите",
        E: "to bring",
        T: "VI2",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привёл",
        E: "to bring",
        T: "VPM",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привела",
        E: "to bring",
        T: "VPF",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привело",
        E: "to bring",
        T: "VPN",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привели",
        E: "to bring",
        T: "VPP",
        A: "P",
        O: "привести",
        RA: 677,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаться",
        E: "to return",
        T: "VI",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаюсь",
        E: "to return",
        T: "VN1",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду возвращаться",
        E: "to return",
        T: "VF1",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаешься",
        E: "to return",
        T: "VN2",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь возвращаться",
        E: "to return",
        T: "VF2",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращается",
        E: "to return",
        T: "VN3",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет возвращаться",
        E: "to return",
        T: "VF3",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаемся",
        E: "to return",
        T: "VNW",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем возвращаться",
        E: "to return",
        T: "VFW",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаетесь",
        E: "to return",
        T: "VNF",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете возвращаться",
        E: "to return",
        T: "VFF",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращаются",
        E: "to return",
        T: "VNT",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут возвращаться",
        E: "to return",
        T: "VFT",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращайся",
        E: "to return",
        T: "VI1",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращайтесь",
        E: "to return",
        T: "VI2",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращался",
        E: "to return",
        T: "VPM",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращалась",
        E: "to return",
        T: "VPF",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращалось",
        E: "to return",
        T: "VPN",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возвращались",
        E: "to return",
        T: "VPP",
        A: "I",
        O: "возвращаться",
        RA: 680,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требовать",
        E: "to demand",
        T: "VI",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требую",
        E: "to demand",
        T: "VN1",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду требовать",
        E: "to demand",
        T: "VF1",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуешь",
        E: "to demand",
        T: "VN2",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь требовать",
        E: "to demand",
        T: "VF2",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требует",
        E: "to demand",
        T: "VN3",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет требовать",
        E: "to demand",
        T: "VF3",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуем",
        E: "to demand",
        T: "VNW",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем требовать",
        E: "to demand",
        T: "VFW",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуете",
        E: "to demand",
        T: "VNF",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете требовать",
        E: "to demand",
        T: "VFF",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуют",
        E: "to demand",
        T: "VNT",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут требовать",
        E: "to demand",
        T: "VFT",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуй",
        E: "to demand",
        T: "VI1",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требуйте",
        E: "to demand",
        T: "VI2",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требовал",
        E: "to demand",
        T: "VPM",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требовала",
        E: "to demand",
        T: "VPF",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требовало",
        E: "to demand",
        T: "VPN",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "требовали",
        E: "to demand",
        T: "VPP",
        A: "I",
        O: "требовать",
        RA: 689,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потерять",
        E: "to lose",
        T: "VI",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряю",
        E: "to lose",
        T: "VF1",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряешь",
        E: "to lose",
        T: "VF2",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряет",
        E: "to lose",
        T: "VF3",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряем",
        E: "to lose",
        T: "VFW",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряете",
        E: "to lose",
        T: "VFF",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряют",
        E: "to lose",
        T: "VFT",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряй",
        E: "to lose",
        T: "VI1",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряйте",
        E: "to lose",
        T: "VI2",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потерял",
        E: "to lose",
        T: "VPM",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряла",
        E: "to lose",
        T: "VPF",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряло",
        E: "to lose",
        T: "VPN",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потеряли",
        E: "to lose",
        T: "VPP",
        A: "P",
        O: "потерять",
        RA: 694,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достать",
        E: "to reach; get, obtain",
        T: "VI",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достану",
        E: "to reach; get, obtain",
        T: "VF1",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достанешь",
        E: "to reach; get, obtain",
        T: "VF2",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достанет",
        E: "to reach; get, obtain",
        T: "VF3",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достанем",
        E: "to reach; get, obtain",
        T: "VFW",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достанете",
        E: "to reach; get, obtain",
        T: "VFF",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достанут",
        E: "to reach; get, obtain",
        T: "VFT",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достань",
        E: "to reach; get, obtain",
        T: "VI1",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достаньте",
        E: "to reach; get, obtain",
        T: "VI2",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достал",
        E: "to reach; get, obtain",
        T: "VPM",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достала",
        E: "to reach; get, obtain",
        T: "VPF",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достало",
        E: "to reach; get, obtain",
        T: "VPN",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достали",
        E: "to reach; get, obtain",
        T: "VPP",
        A: "P",
        O: "достать",
        RA: 700,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщить",
        E: "to report, let know",
        T: "VI",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщу",
        E: "to report, let know",
        T: "VF1",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщишь",
        E: "to report, let know",
        T: "VF2",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщит",
        E: "to report, let know",
        T: "VF3",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщим",
        E: "to report, let know",
        T: "VFW",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщите",
        E: "to report, let know",
        T: "VFF",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщат",
        E: "to report, let know",
        T: "VFT",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщи",
        E: "to report, let know",
        T: "VI1",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщите",
        E: "to report, let know",
        T: "VI2",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщил",
        E: "to report, let know",
        T: "VPM",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщила",
        E: "to report, let know",
        T: "VPF",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщило",
        E: "to report, let know",
        T: "VPN",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сообщили",
        E: "to report, let know",
        T: "VPP",
        A: "P",
        O: "сообщить",
        RA: 703,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляться",
        E: "to appear, show up",
        T: "VI",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляюсь",
        E: "to appear, show up",
        T: "VN1",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду появляться",
        E: "to appear, show up",
        T: "VF1",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляешься",
        E: "to appear, show up",
        T: "VN2",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь появляться",
        E: "to appear, show up",
        T: "VF2",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляется",
        E: "to appear, show up",
        T: "VN3",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет появляться",
        E: "to appear, show up",
        T: "VF3",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляемся",
        E: "to appear, show up",
        T: "VNW",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем появляться",
        E: "to appear, show up",
        T: "VFW",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляетесь",
        E: "to appear, show up",
        T: "VNF",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете появляться",
        E: "to appear, show up",
        T: "VFF",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляются",
        E: "to appear, show up",
        T: "VNT",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут появляться",
        E: "to appear, show up",
        T: "VFT",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляйся",
        E: "to appear, show up",
        T: "VI1",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появляйтесь",
        E: "to appear, show up",
        T: "VI2",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появлялся",
        E: "to appear, show up",
        T: "VPM",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появлялась",
        E: "to appear, show up",
        T: "VPF",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появлялось",
        E: "to appear, show up",
        T: "VPN",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "появлялись",
        E: "to appear, show up",
        T: "VPP",
        A: "I",
        O: "появляться",
        RA: 706,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упасть",
        E: "to fall",
        T: "VI",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упаду",
        E: "to fall",
        T: "VF1",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадёшь",
        E: "to fall",
        T: "VF2",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадёт",
        E: "to fall",
        T: "VF3",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадём",
        E: "to fall",
        T: "VFW",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадёте",
        E: "to fall",
        T: "VFF",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадут",
        E: "to fall",
        T: "VFT",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упади",
        E: "to fall",
        T: "VI1",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упадите",
        E: "to fall",
        T: "VI2",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упал",
        E: "to fall",
        T: "VPM",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упала",
        E: "to fall",
        T: "VPF",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упало",
        E: "to fall",
        T: "VPN",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "упали",
        E: "to fall",
        T: "VPP",
        A: "P",
        O: "упасть",
        RA: 707,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получиться",
        E: "to come, arrive, turn out",
        T: "VI",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получусь",
        E: "to come, arrive, turn out",
        T: "VF1",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получишься",
        E: "to come, arrive, turn out",
        T: "VF2",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получится",
        E: "to come, arrive, turn out",
        T: "VF3",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получимся",
        E: "to come, arrive, turn out",
        T: "VFW",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получитесь",
        E: "to come, arrive, turn out",
        T: "VFF",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получатся",
        E: "to come, arrive, turn out",
        T: "VFT",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получись",
        E: "to come, arrive, turn out",
        T: "VI1",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получитесь",
        E: "to come, arrive, turn out",
        T: "VI2",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получился",
        E: "to come, arrive, turn out",
        T: "VPM",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получилась",
        E: "to come, arrive, turn out",
        T: "VPF",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получилось",
        E: "to come, arrive, turn out",
        T: "VPN",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получились",
        E: "to come, arrive, turn out",
        T: "VPP",
        A: "P",
        O: "получиться",
        RA: 713,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провести",
        E: "to accompany, set off; conduct; spend",
        T: "VI",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведу",
        E: "to accompany, set off; conduct; spend",
        T: "VF1",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведёшь",
        E: "to accompany, set off; conduct; spend",
        T: "VF2",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведёт",
        E: "to accompany, set off; conduct; spend",
        T: "VF3",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведём",
        E: "to accompany, set off; conduct; spend",
        T: "VFW",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведёте",
        E: "to accompany, set off; conduct; spend",
        T: "VFF",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведут",
        E: "to accompany, set off; conduct; spend",
        T: "VFT",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведи",
        E: "to accompany, set off; conduct; spend",
        T: "VI1",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проведите",
        E: "to accompany, set off; conduct; spend",
        T: "VI2",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провёл",
        E: "to accompany, set off; conduct; spend",
        T: "VPM",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провела",
        E: "to accompany, set off; conduct; spend",
        T: "VPF",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провело",
        E: "to accompany, set off; conduct; spend",
        T: "VPN",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провели",
        E: "to accompany, set off; conduct; spend",
        T: "VPP",
        A: "P",
        O: "провести",
        RA: 721,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простить",
        E: "to forgive",
        T: "VI",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прощу",
        E: "to forgive",
        T: "VF1",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простишь",
        E: "to forgive",
        T: "VF2",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простит",
        E: "to forgive",
        T: "VF3",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простим",
        E: "to forgive",
        T: "VFW",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простите",
        E: "to forgive",
        T: "VFF",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простят",
        E: "to forgive",
        T: "VFT",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прости",
        E: "to forgive",
        T: "VI1",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простите",
        E: "to forgive",
        T: "VI2",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простил",
        E: "to forgive",
        T: "VPM",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простила",
        E: "to forgive",
        T: "VPF",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простило",
        E: "to forgive",
        T: "VPN",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простили",
        E: "to forgive",
        T: "VPP",
        A: "P",
        O: "простить",
        RA: 727,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бить",
        E: "to beat, hit",
        T: "VI",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бью",
        E: "to beat, hit",
        T: "VN1",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду бить",
        E: "to beat, hit",
        T: "VF1",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бьёшь",
        E: "to beat, hit",
        T: "VN2",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь бить",
        E: "to beat, hit",
        T: "VF2",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бьёт",
        E: "to beat, hit",
        T: "VN3",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет бить",
        E: "to beat, hit",
        T: "VF3",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бьём",
        E: "to beat, hit",
        T: "VNW",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем бить",
        E: "to beat, hit",
        T: "VFW",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бьёте",
        E: "to beat, hit",
        T: "VNF",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете бить",
        E: "to beat, hit",
        T: "VFF",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бьют",
        E: "to beat, hit",
        T: "VNT",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут бить",
        E: "to beat, hit",
        T: "VFT",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бей",
        E: "to beat, hit",
        T: "VI1",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бейте",
        E: "to beat, hit",
        T: "VI2",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бил",
        E: "to beat, hit",
        T: "VPM",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "била",
        E: "to beat, hit",
        T: "VPF",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "било",
        E: "to beat, hit",
        T: "VPN",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "били",
        E: "to beat, hit",
        T: "VPP",
        A: "I",
        O: "бить",
        RA: 728,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнуть",
        E: "to nod",
        T: "VI",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивну",
        E: "to nod",
        T: "VF1",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнёшь",
        E: "to nod",
        T: "VF2",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнёт",
        E: "to nod",
        T: "VF3",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнём",
        E: "to nod",
        T: "VFW",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнёте",
        E: "to nod",
        T: "VFF",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнут",
        E: "to nod",
        T: "VFT",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивни",
        E: "to nod",
        T: "VI1",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивните",
        E: "to nod",
        T: "VI2",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнул",
        E: "to nod",
        T: "VPM",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнула",
        E: "to nod",
        T: "VPF",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнуло",
        E: "to nod",
        T: "VPN",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кивнули",
        E: "to nod",
        T: "VPP",
        A: "P",
        O: "кивнуть",
        RA: 731,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнуть",
        E: "to disappear",
        T: "VI",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезну",
        E: "to disappear",
        T: "VF1",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнешь",
        E: "to disappear",
        T: "VF2",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнет",
        E: "to disappear",
        T: "VF3",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнем",
        E: "to disappear",
        T: "VFW",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнете",
        E: "to disappear",
        T: "VFF",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезнут",
        E: "to disappear",
        T: "VFT",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезни",
        E: "to disappear",
        T: "VI1",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезните",
        E: "to disappear",
        T: "VI2",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчез",
        E: "to disappear",
        T: "VPM",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезла исчезнула",
        E: "to disappear",
        T: "VPF",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезло исчезнуло",
        E: "to disappear",
        T: "VPN",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "исчезли исчезнули",
        E: "to disappear",
        T: "VPP",
        A: "P",
        O: "исчезнуть",
        RA: 733,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдать",
        E: "to return, give back",
        T: "VI",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдам",
        E: "to return, give back",
        T: "VF1",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдашь",
        E: "to return, give back",
        T: "VF2",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдаст",
        E: "to return, give back",
        T: "VF3",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдадим",
        E: "to return, give back",
        T: "VFW",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдадите",
        E: "to return, give back",
        T: "VFF",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдадут",
        E: "to return, give back",
        T: "VFT",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдай",
        E: "to return, give back",
        T: "VI1",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдайте",
        E: "to return, give back",
        T: "VI2",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдал",
        E: "to return, give back",
        T: "VPM",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдала",
        E: "to return, give back",
        T: "VPF",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдало",
        E: "to return, give back",
        T: "VPN",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отдали",
        E: "to return, give back",
        T: "VPP",
        A: "P",
        O: "отдать",
        RA: 737,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помочь",
        E: "to help",
        T: "VI",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогу",
        E: "to help",
        T: "VF1",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поможешь",
        E: "to help",
        T: "VF2",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поможет",
        E: "to help",
        T: "VF3",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поможем",
        E: "to help",
        T: "VFW",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поможете",
        E: "to help",
        T: "VFF",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогут",
        E: "to help",
        T: "VFT",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помоги",
        E: "to help",
        T: "VI1",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогите",
        E: "to help",
        T: "VI2",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помог",
        E: "to help",
        T: "VPM",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогла",
        E: "to help",
        T: "VPF",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогло",
        E: "to help",
        T: "VPN",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогли",
        E: "to help",
        T: "VPP",
        A: "P",
        O: "помочь",
        RA: 744,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слать",
        E: "to send",
        T: "VI",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлю",
        E: "to send",
        T: "VN1",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду слать",
        E: "to send",
        T: "VF1",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлёшь",
        E: "to send",
        T: "VN2",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь слать",
        E: "to send",
        T: "VF2",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлёт",
        E: "to send",
        T: "VN3",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет слать",
        E: "to send",
        T: "VF3",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлём",
        E: "to send",
        T: "VNW",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем слать",
        E: "to send",
        T: "VFW",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлёте",
        E: "to send",
        T: "VNF",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете слать",
        E: "to send",
        T: "VFF",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлют",
        E: "to send",
        T: "VNT",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут слать",
        E: "to send",
        T: "VFT",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шли",
        E: "to send",
        T: "VI1",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шлите",
        E: "to send",
        T: "VI2",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слал",
        E: "to send",
        T: "VPM",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слала",
        E: "to send",
        T: "VPF",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слало",
        E: "to send",
        T: "VPN",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слали",
        E: "to send",
        T: "VPP",
        A: "I",
        O: "слать",
        RA: 747,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминать",
        E: "to remember, recall",
        T: "VI",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминаю",
        E: "to remember, recall",
        T: "VN1",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду вспоминать",
        E: "to remember, recall",
        T: "VF1",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминаешь",
        E: "to remember, recall",
        T: "VN2",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь вспоминать",
        E: "to remember, recall",
        T: "VF2",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминает",
        E: "to remember, recall",
        T: "VN3",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет вспоминать",
        E: "to remember, recall",
        T: "VF3",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминаем",
        E: "to remember, recall",
        T: "VNW",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем вспоминать",
        E: "to remember, recall",
        T: "VFW",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминаете",
        E: "to remember, recall",
        T: "VNF",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете вспоминать",
        E: "to remember, recall",
        T: "VFF",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминают",
        E: "to remember, recall",
        T: "VNT",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут вспоминать",
        E: "to remember, recall",
        T: "VFT",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминай",
        E: "to remember, recall",
        T: "VI1",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминайте",
        E: "to remember, recall",
        T: "VI2",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминал",
        E: "to remember, recall",
        T: "VPM",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминала",
        E: "to remember, recall",
        T: "VPF",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминало",
        E: "to remember, recall",
        T: "VPN",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вспоминали",
        E: "to remember, recall",
        T: "VPP",
        A: "I",
        O: "вспоминать",
        RA: 748,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнуть",
        E: "to take breath, to sigh",
        T: "VI",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохну",
        E: "to take breath, to sigh",
        T: "VF1",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнёшь",
        E: "to take breath, to sigh",
        T: "VF2",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнёт",
        E: "to take breath, to sigh",
        T: "VF3",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнём",
        E: "to take breath, to sigh",
        T: "VFW",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнёте",
        E: "to take breath, to sigh",
        T: "VFF",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнут",
        E: "to take breath, to sigh",
        T: "VFT",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохни",
        E: "to take breath, to sigh",
        T: "VI1",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохните",
        E: "to take breath, to sigh",
        T: "VI2",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнул",
        E: "to take breath, to sigh",
        T: "VPM",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнула",
        E: "to take breath, to sigh",
        T: "VPF",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнуло",
        E: "to take breath, to sigh",
        T: "VPN",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вздохнули",
        E: "to take breath, to sigh",
        T: "VPP",
        A: "P",
        O: "вздохнуть",
        RA: 761,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "петь",
        E: "to sing",
        T: "VI",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пою",
        E: "to sing",
        T: "VN1",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду петь",
        E: "to sing",
        T: "VF1",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поёшь",
        E: "to sing",
        T: "VN2",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь петь",
        E: "to sing",
        T: "VF2",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поёт",
        E: "to sing",
        T: "VN3",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет петь",
        E: "to sing",
        T: "VF3",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поём",
        E: "to sing",
        T: "VNW",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем петь",
        E: "to sing",
        T: "VFW",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поёте",
        E: "to sing",
        T: "VNF",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете петь",
        E: "to sing",
        T: "VFF",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поют",
        E: "to sing",
        T: "VNT",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут петь",
        E: "to sing",
        T: "VFT",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пой",
        E: "to sing",
        T: "VI1",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пойте",
        E: "to sing",
        T: "VI2",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пел",
        E: "to sing",
        T: "VPM",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пела",
        E: "to sing",
        T: "VPF",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пело",
        E: "to sing",
        T: "VPN",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пели",
        E: "to sing",
        T: "VPP",
        A: "I",
        O: "петь",
        RA: 768,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садиться",
        E: "to sit down",
        T: "VI",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сажусь",
        E: "to sit down",
        T: "VN1",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду садиться",
        E: "to sit down",
        T: "VF1",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садишься",
        E: "to sit down",
        T: "VN2",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь садиться",
        E: "to sit down",
        T: "VF2",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садится",
        E: "to sit down",
        T: "VN3",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет садиться",
        E: "to sit down",
        T: "VF3",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садимся",
        E: "to sit down",
        T: "VNW",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем садиться",
        E: "to sit down",
        T: "VFW",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садитесь",
        E: "to sit down",
        T: "VNF",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете садиться",
        E: "to sit down",
        T: "VFF",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садятся",
        E: "to sit down",
        T: "VNT",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут садиться",
        E: "to sit down",
        T: "VFT",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садись",
        E: "to sit down",
        T: "VI1",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садитесь",
        E: "to sit down",
        T: "VI2",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садился",
        E: "to sit down",
        T: "VPM",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садилась",
        E: "to sit down",
        T: "VPF",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садилось",
        E: "to sit down",
        T: "VPN",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "садились",
        E: "to sit down",
        T: "VPP",
        A: "I",
        O: "садиться",
        RA: 769,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестать",
        E: "to stop, cease",
        T: "VI",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестану",
        E: "to stop, cease",
        T: "VF1",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестанешь",
        E: "to stop, cease",
        T: "VF2",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестанет",
        E: "to stop, cease",
        T: "VF3",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестанем",
        E: "to stop, cease",
        T: "VFW",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестанете",
        E: "to stop, cease",
        T: "VFF",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестанут",
        E: "to stop, cease",
        T: "VFT",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестань",
        E: "to stop, cease",
        T: "VI1",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестаньте",
        E: "to stop, cease",
        T: "VI2",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестал",
        E: "to stop, cease",
        T: "VPM",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестала",
        E: "to stop, cease",
        T: "VPF",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестало",
        E: "to stop, cease",
        T: "VPN",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перестали",
        E: "to stop, cease",
        T: "VPP",
        A: "P",
        O: "перестать",
        RA: 776,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придтись",
        E: "to fit, fall; have to",
        T: "VI",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придусь",
        E: "to fit, fall; have to",
        T: "VF1",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёшься",
        E: "to fit, fall; have to",
        T: "VF2",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придётся",
        E: "to fit, fall; have to",
        T: "VF3",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придёмся",
        E: "to fit, fall; have to",
        T: "VFW",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придётесь",
        E: "to fit, fall; have to",
        T: "VFF",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придутся",
        E: "to fit, fall; have to",
        T: "VFT",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придись",
        E: "to fit, fall; have to",
        T: "VI1",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "придитесь",
        E: "to fit, fall; have to",
        T: "VI2",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришёлся",
        E: "to fit, fall; have to",
        T: "VPM",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлась",
        E: "to fit, fall; have to",
        T: "VPF",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлось",
        E: "to fit, fall; have to",
        T: "VPN",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлись",
        E: "to fit, fall; have to",
        T: "VPP",
        A: "P",
        O: "придтись",
        RA: 777,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожаловать",
        E: "to grant, bestow",
        T: "VI",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалую",
        E: "to grant, bestow",
        T: "VF1",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуешь",
        E: "to grant, bestow",
        T: "VF2",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалует",
        E: "to grant, bestow",
        T: "VF3",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуем",
        E: "to grant, bestow",
        T: "VFW",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуете",
        E: "to grant, bestow",
        T: "VFF",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуют",
        E: "to grant, bestow",
        T: "VFT",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуй",
        E: "to grant, bestow",
        T: "VI1",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуйте",
        E: "to grant, bestow",
        T: "VI2",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожаловал",
        E: "to grant, bestow",
        T: "VPM",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожаловала",
        E: "to grant, bestow",
        T: "VPF",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожаловало",
        E: "to grant, bestow",
        T: "VPN",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожаловали",
        E: "to grant, bestow",
        T: "VPP",
        A: "P",
        O: "пожаловать",
        RA: 783,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получать",
        E: "to receive, get",
        T: "VI",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаю",
        E: "to receive, get",
        T: "VN1",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду получать",
        E: "to receive, get",
        T: "VF1",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаешь",
        E: "to receive, get",
        T: "VN2",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь получать",
        E: "to receive, get",
        T: "VF2",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получает",
        E: "to receive, get",
        T: "VN3",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет получать",
        E: "to receive, get",
        T: "VF3",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаем",
        E: "to receive, get",
        T: "VNW",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем получать",
        E: "to receive, get",
        T: "VFW",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаете",
        E: "to receive, get",
        T: "VNF",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете получать",
        E: "to receive, get",
        T: "VFF",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получают",
        E: "to receive, get",
        T: "VNT",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут получать",
        E: "to receive, get",
        T: "VFT",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получай",
        E: "to receive, get",
        T: "VI1",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получайте",
        E: "to receive, get",
        T: "VI2",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получал",
        E: "to receive, get",
        T: "VPM",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получала",
        E: "to receive, get",
        T: "VPF",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получало",
        E: "to receive, get",
        T: "VPN",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получали",
        E: "to receive, get",
        T: "VPP",
        A: "I",
        O: "получать",
        RA: 791,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называться",
        E: "to call oneself",
        T: "VI",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называюсь",
        E: "to call oneself",
        T: "VN1",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду называться",
        E: "to call oneself",
        T: "VF1",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называешься",
        E: "to call oneself",
        T: "VN2",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь называться",
        E: "to call oneself",
        T: "VF2",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называется",
        E: "to call oneself",
        T: "VN3",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет называться",
        E: "to call oneself",
        T: "VF3",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называемся",
        E: "to call oneself",
        T: "VNW",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем называться",
        E: "to call oneself",
        T: "VFW",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называетесь",
        E: "to call oneself",
        T: "VNF",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете называться",
        E: "to call oneself",
        T: "VFF",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называются",
        E: "to call oneself",
        T: "VNT",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут называться",
        E: "to call oneself",
        T: "VFT",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называйся",
        E: "to call oneself",
        T: "VI1",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называйтесь",
        E: "to call oneself",
        T: "VI2",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назывался",
        E: "to call oneself",
        T: "VPM",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называлась",
        E: "to call oneself",
        T: "VPF",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "называлось",
        E: "to call oneself",
        T: "VPN",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назывались",
        E: "to call oneself",
        T: "VPP",
        A: "I",
        O: "называться",
        RA: 794,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеяться",
        E: "to hope",
        T: "VI",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеюсь",
        E: "to hope",
        T: "VN1",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду надеяться",
        E: "to hope",
        T: "VF1",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеешься",
        E: "to hope",
        T: "VN2",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь надеяться",
        E: "to hope",
        T: "VF2",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеется",
        E: "to hope",
        T: "VN3",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет надеяться",
        E: "to hope",
        T: "VF3",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеемся",
        E: "to hope",
        T: "VNW",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем надеяться",
        E: "to hope",
        T: "VFW",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеетесь",
        E: "to hope",
        T: "VNF",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете надеяться",
        E: "to hope",
        T: "VFF",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеются",
        E: "to hope",
        T: "VNT",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут надеяться",
        E: "to hope",
        T: "VFT",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надейся",
        E: "to hope",
        T: "VI1",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надейтесь",
        E: "to hope",
        T: "VI2",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеялся",
        E: "to hope",
        T: "VPM",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеялась",
        E: "to hope",
        T: "VPF",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеялось",
        E: "to hope",
        T: "VPN",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надеялись",
        E: "to hope",
        T: "VPP",
        A: "I",
        O: "надеяться",
        RA: 795,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянуть",
        E: "to stretch, extend",
        T: "VI",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протяну",
        E: "to stretch, extend",
        T: "VF1",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянешь",
        E: "to stretch, extend",
        T: "VF2",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянет",
        E: "to stretch, extend",
        T: "VF3",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянем",
        E: "to stretch, extend",
        T: "VFW",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянете",
        E: "to stretch, extend",
        T: "VFF",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянут",
        E: "to stretch, extend",
        T: "VFT",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протяни",
        E: "to stretch, extend",
        T: "VI1",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протяните",
        E: "to stretch, extend",
        T: "VI2",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянул",
        E: "to stretch, extend",
        T: "VPM",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянула",
        E: "to stretch, extend",
        T: "VPF",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянуло",
        E: "to stretch, extend",
        T: "VPN",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "протянули",
        E: "to stretch, extend",
        T: "VPP",
        A: "P",
        O: "протянуть",
        RA: 797,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходить",
        E: "to come up, approach;to fit",
        T: "VI",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подхожу",
        E: "to come up, approach;to fit",
        T: "VN1",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду подходить",
        E: "to come up, approach;to fit",
        T: "VF1",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходишь",
        E: "to come up, approach;to fit",
        T: "VN2",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь подходить",
        E: "to come up, approach;to fit",
        T: "VF2",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходит",
        E: "to come up, approach;to fit",
        T: "VN3",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет подходить",
        E: "to come up, approach;to fit",
        T: "VF3",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходим",
        E: "to come up, approach;to fit",
        T: "VNW",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем подходить",
        E: "to come up, approach;to fit",
        T: "VFW",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходите",
        E: "to come up, approach;to fit",
        T: "VNF",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете подходить",
        E: "to come up, approach;to fit",
        T: "VFF",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходят",
        E: "to come up, approach;to fit",
        T: "VNT",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут подходить",
        E: "to come up, approach;to fit",
        T: "VFT",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходи",
        E: "to come up, approach;to fit",
        T: "VI1",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходите",
        E: "to come up, approach;to fit",
        T: "VI2",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходил",
        E: "to come up, approach;to fit",
        T: "VPM",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходила",
        E: "to come up, approach;to fit",
        T: "VPF",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходило",
        E: "to come up, approach;to fit",
        T: "VPN",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подходили",
        E: "to come up, approach;to fit",
        T: "VPP",
        A: "I",
        O: "подходить",
        RA: 806,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служить",
        E: "to serve",
        T: "VI",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служу",
        E: "to serve",
        T: "VN1",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду служить",
        E: "to serve",
        T: "VF1",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служишь",
        E: "to serve",
        T: "VN2",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь служить",
        E: "to serve",
        T: "VF2",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служит",
        E: "to serve",
        T: "VN3",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет служить",
        E: "to serve",
        T: "VF3",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служим",
        E: "to serve",
        T: "VNW",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем служить",
        E: "to serve",
        T: "VFW",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служите",
        E: "to serve",
        T: "VNF",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете служить",
        E: "to serve",
        T: "VFF",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служат",
        E: "to serve",
        T: "VNT",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут служить",
        E: "to serve",
        T: "VFT",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служи",
        E: "to serve",
        T: "VI1",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служите",
        E: "to serve",
        T: "VI2",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служил",
        E: "to serve",
        T: "VPM",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служила",
        E: "to serve",
        T: "VPF",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служило",
        E: "to serve",
        T: "VPN",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "служили",
        E: "to serve",
        T: "VPP",
        A: "I",
        O: "служить",
        RA: 815,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверить",
        E: "to believe",
        T: "VI",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверю",
        E: "to believe",
        T: "VF1",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверишь",
        E: "to believe",
        T: "VF2",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверит",
        E: "to believe",
        T: "VF3",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверим",
        E: "to believe",
        T: "VFW",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверите",
        E: "to believe",
        T: "VFF",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверят",
        E: "to believe",
        T: "VFT",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверь",
        E: "to believe",
        T: "VI1",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверьте",
        E: "to believe",
        T: "VI2",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверил",
        E: "to believe",
        T: "VPM",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверила",
        E: "to believe",
        T: "VPF",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверило",
        E: "to believe",
        T: "VPN",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поверили",
        E: "to believe",
        T: "VPP",
        A: "P",
        O: "поверить",
        RA: 825,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянуть",
        E: "to look, give a glance",
        T: "VI",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взгляну",
        E: "to look, give a glance",
        T: "VF1",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянешь",
        E: "to look, give a glance",
        T: "VF2",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянет",
        E: "to look, give a glance",
        T: "VF3",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянем",
        E: "to look, give a glance",
        T: "VFW",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянете",
        E: "to look, give a glance",
        T: "VFF",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянут",
        E: "to look, give a glance",
        T: "VFT",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взгляни",
        E: "to look, give a glance",
        T: "VI1",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взгляните",
        E: "to look, give a glance",
        T: "VI2",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянул",
        E: "to look, give a glance",
        T: "VPM",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянула",
        E: "to look, give a glance",
        T: "VPF",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянуло",
        E: "to look, give a glance",
        T: "VPN",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "взглянули",
        E: "to look, give a glance",
        T: "VPP",
        A: "P",
        O: "взглянуть",
        RA: 827,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывать",
        E: "to call, send",
        T: "VI",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызываю",
        E: "to call, send",
        T: "VN1",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду вызывать",
        E: "to call, send",
        T: "VF1",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызываешь",
        E: "to call, send",
        T: "VN2",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь вызывать",
        E: "to call, send",
        T: "VF2",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывает",
        E: "to call, send",
        T: "VN3",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет вызывать",
        E: "to call, send",
        T: "VF3",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызываем",
        E: "to call, send",
        T: "VNW",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем вызывать",
        E: "to call, send",
        T: "VFW",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызываете",
        E: "to call, send",
        T: "VNF",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете вызывать",
        E: "to call, send",
        T: "VFF",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывают",
        E: "to call, send",
        T: "VNT",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут вызывать",
        E: "to call, send",
        T: "VFT",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывай",
        E: "to call, send",
        T: "VI1",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывайте",
        E: "to call, send",
        T: "VI2",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывал",
        E: "to call, send",
        T: "VPM",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывала",
        E: "to call, send",
        T: "VPF",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывало",
        E: "to call, send",
        T: "VPN",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывали",
        E: "to call, send",
        T: "VPP",
        A: "I",
        O: "вызывать",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидать",
        E: "to expect",
        T: "VI",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидаю",
        E: "to expect",
        T: "VN1",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду ожидать",
        E: "to expect",
        T: "VF1",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидаешь",
        E: "to expect",
        T: "VN2",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь ожидать",
        E: "to expect",
        T: "VF2",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидает",
        E: "to expect",
        T: "VN3",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет ожидать",
        E: "to expect",
        T: "VF3",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидаем",
        E: "to expect",
        T: "VNW",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем ожидать",
        E: "to expect",
        T: "VFW",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидаете",
        E: "to expect",
        T: "VNF",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете ожидать",
        E: "to expect",
        T: "VFF",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидают",
        E: "to expect",
        T: "VNT",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут ожидать",
        E: "to expect",
        T: "VFT",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидай",
        E: "to expect",
        T: "VI1",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидайте",
        E: "to expect",
        T: "VI2",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидал",
        E: "to expect",
        T: "VPM",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидала",
        E: "to expect",
        T: "VPF",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидало",
        E: "to expect",
        T: "VPN",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ожидали",
        E: "to expect",
        T: "VPP",
        A: "I",
        O: "ожидать",
        RA: 838,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висеть",
        E: "to hang",
        T: "VI",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вишу",
        E: "to hang",
        T: "VN1",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду висеть",
        E: "to hang",
        T: "VF1",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висишь",
        E: "to hang",
        T: "VN2",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь висеть",
        E: "to hang",
        T: "VF2",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висит",
        E: "to hang",
        T: "VN3",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет висеть",
        E: "to hang",
        T: "VF3",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висим",
        E: "to hang",
        T: "VNW",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем висеть",
        E: "to hang",
        T: "VFW",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висите",
        E: "to hang",
        T: "VNF",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете висеть",
        E: "to hang",
        T: "VFF",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висят",
        E: "to hang",
        T: "VNT",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут висеть",
        E: "to hang",
        T: "VFT",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "виси",
        E: "to hang",
        T: "VI1",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висите",
        E: "to hang",
        T: "VI2",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висел",
        E: "to hang",
        T: "VPM",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висела",
        E: "to hang",
        T: "VPF",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висело",
        E: "to hang",
        T: "VPN",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "висели",
        E: "to hang",
        T: "VPP",
        A: "I",
        O: "висеть",
        RA: 840,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создать",
        E: "to create, establish",
        T: "VI",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создам",
        E: "to create, establish",
        T: "VF1",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создашь",
        E: "to create, establish",
        T: "VF2",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создаст",
        E: "to create, establish",
        T: "VF3",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создадим",
        E: "to create, establish",
        T: "VFW",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создадите",
        E: "to create, establish",
        T: "VFF",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создадут",
        E: "to create, establish",
        T: "VFT",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создай",
        E: "to create, establish",
        T: "VI1",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создайте",
        E: "to create, establish",
        T: "VI2",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создал",
        E: "to create, establish",
        T: "VPM",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создала",
        E: "to create, establish",
        T: "VPF",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создало",
        E: "to create, establish",
        T: "VPN",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "создали",
        E: "to create, establish",
        T: "VPP",
        A: "P",
        O: "создать",
        RA: 844,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уехать",
        E: "to leave, depart",
        T: "VI",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уеду",
        E: "to leave, depart",
        T: "VF1",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уедешь",
        E: "to leave, depart",
        T: "VF2",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уедет",
        E: "to leave, depart",
        T: "VF3",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уедем",
        E: "to leave, depart",
        T: "VFW",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уедете",
        E: "to leave, depart",
        T: "VFF",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уедут",
        E: "to leave, depart",
        T: "VFT",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уезжай",
        E: "to leave, depart",
        T: "VI1",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уезжайте",
        E: "to leave, depart",
        T: "VI2",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уехал",
        E: "to leave, depart",
        T: "VPM",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уехала",
        E: "to leave, depart",
        T: "VPF",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уехало",
        E: "to leave, depart",
        T: "VPN",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уехали",
        E: "to leave, depart",
        T: "VPP",
        A: "P",
        O: "уехать",
        RA: 849,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядеть",
        E: "to look",
        T: "VI",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выгляжу",
        E: "to look",
        T: "VN1",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду выглядеть",
        E: "to look",
        T: "VF1",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядишь",
        E: "to look",
        T: "VN2",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь выглядеть",
        E: "to look",
        T: "VF2",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядит",
        E: "to look",
        T: "VN3",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет выглядеть",
        E: "to look",
        T: "VF3",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядим",
        E: "to look",
        T: "VNW",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем выглядеть",
        E: "to look",
        T: "VFW",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядите",
        E: "to look",
        T: "VNF",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете выглядеть",
        E: "to look",
        T: "VFF",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядят",
        E: "to look",
        T: "VNT",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут выглядеть",
        E: "to look",
        T: "VFT",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выгляди",
        E: "to look",
        T: "VI1",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядите",
        E: "to look",
        T: "VI2",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядел",
        E: "to look",
        T: "VPM",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядела",
        E: "to look",
        T: "VPF",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядело",
        E: "to look",
        T: "VPN",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выглядели",
        E: "to look",
        T: "VPP",
        A: "I",
        O: "выглядеть",
        RA: 856,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учиться",
        E: "to study, learn",
        T: "VI",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учусь",
        E: "to study, learn",
        T: "VN1",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду учиться",
        E: "to study, learn",
        T: "VF1",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учишься",
        E: "to study, learn",
        T: "VN2",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь учиться",
        E: "to study, learn",
        T: "VF2",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учится",
        E: "to study, learn",
        T: "VN3",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет учиться",
        E: "to study, learn",
        T: "VF3",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учимся",
        E: "to study, learn",
        T: "VNW",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем учиться",
        E: "to study, learn",
        T: "VFW",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учитесь",
        E: "to study, learn",
        T: "VNF",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете учиться",
        E: "to study, learn",
        T: "VFF",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учатся",
        E: "to study, learn",
        T: "VNT",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут учиться",
        E: "to study, learn",
        T: "VFT",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учись",
        E: "to study, learn",
        T: "VI1",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учитесь",
        E: "to study, learn",
        T: "VI2",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учился",
        E: "to study, learn",
        T: "VPM",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "училась",
        E: "to study, learn",
        T: "VPF",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "училось",
        E: "to study, learn",
        T: "VPN",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "учились",
        E: "to study, learn",
        T: "VPP",
        A: "I",
        O: "учиться",
        RA: 857,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривать",
        E: "to talk, speak",
        T: "VI",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговариваю",
        E: "to talk, speak",
        T: "VN1",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду разговаривать",
        E: "to talk, speak",
        T: "VF1",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговариваешь",
        E: "to talk, speak",
        T: "VN2",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь разговаривать",
        E: "to talk, speak",
        T: "VF2",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривает",
        E: "to talk, speak",
        T: "VN3",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет разговаривать",
        E: "to talk, speak",
        T: "VF3",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговариваем",
        E: "to talk, speak",
        T: "VNW",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем разговаривать",
        E: "to talk, speak",
        T: "VFW",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговариваете",
        E: "to talk, speak",
        T: "VNF",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете разговаривать",
        E: "to talk, speak",
        T: "VFF",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривают",
        E: "to talk, speak",
        T: "VNT",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут разговаривать",
        E: "to talk, speak",
        T: "VFT",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривай",
        E: "to talk, speak",
        T: "VI1",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривайте",
        E: "to talk, speak",
        T: "VI2",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривал",
        E: "to talk, speak",
        T: "VPM",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривала",
        E: "to talk, speak",
        T: "VPF",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривало",
        E: "to talk, speak",
        T: "VPN",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разговаривали",
        E: "to talk, speak",
        T: "VPP",
        A: "I",
        O: "разговаривать",
        RA: 859,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогать",
        E: "to help, assist",
        T: "VI",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогаю",
        E: "to help, assist",
        T: "VN1",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду помогать",
        E: "to help, assist",
        T: "VF1",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогаешь",
        E: "to help, assist",
        T: "VN2",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь помогать",
        E: "to help, assist",
        T: "VF2",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогает",
        E: "to help, assist",
        T: "VN3",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет помогать",
        E: "to help, assist",
        T: "VF3",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогаем",
        E: "to help, assist",
        T: "VNW",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем помогать",
        E: "to help, assist",
        T: "VFW",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогаете",
        E: "to help, assist",
        T: "VNF",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете помогать",
        E: "to help, assist",
        T: "VFF",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогают",
        E: "to help, assist",
        T: "VNT",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут помогать",
        E: "to help, assist",
        T: "VFT",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогай",
        E: "to help, assist",
        T: "VI1",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогайте",
        E: "to help, assist",
        T: "VI2",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогал",
        E: "to help, assist",
        T: "VPM",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогала",
        E: "to help, assist",
        T: "VPF",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогало",
        E: "to help, assist",
        T: "VPN",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "помогали",
        E: "to help, assist",
        T: "VPP",
        A: "I",
        O: "помогать",
        RA: 866,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернуться",
        E: "to turn",
        T: "VI",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернусь",
        E: "to turn",
        T: "VF1",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернёшься",
        E: "to turn",
        T: "VF2",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернётся",
        E: "to turn",
        T: "VF3",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернёмся",
        E: "to turn",
        T: "VFW",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернётесь",
        E: "to turn",
        T: "VFF",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернутся",
        E: "to turn",
        T: "VFT",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернись",
        E: "to turn",
        T: "VI1",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернитесь",
        E: "to turn",
        T: "VI2",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернулся",
        E: "to turn",
        T: "VPM",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернулась",
        E: "to turn",
        T: "VPF",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернулось",
        E: "to turn",
        T: "VPN",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повернулись",
        E: "to turn",
        T: "VPP",
        A: "P",
        O: "повернуться",
        RA: 868,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволить",
        E: "to allow, permit",
        T: "VI",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволю",
        E: "to allow, permit",
        T: "VF1",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволишь",
        E: "to allow, permit",
        T: "VF2",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволит",
        E: "to allow, permit",
        T: "VF3",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволим",
        E: "to allow, permit",
        T: "VFW",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволите",
        E: "to allow, permit",
        T: "VFF",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволят",
        E: "to allow, permit",
        T: "VFT",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволь",
        E: "to allow, permit",
        T: "VI1",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвольте",
        E: "to allow, permit",
        T: "VI2",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволил",
        E: "to allow, permit",
        T: "VPM",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволила",
        E: "to allow, permit",
        T: "VPF",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволило",
        E: "to allow, permit",
        T: "VPN",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позволили",
        E: "to allow, permit",
        T: "VPP",
        A: "P",
        O: "позволить",
        RA: 869,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретить",
        E: "to meet",
        T: "VI",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встречу",
        E: "to meet",
        T: "VF1",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретишь",
        E: "to meet",
        T: "VF2",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретит",
        E: "to meet",
        T: "VF3",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретим",
        E: "to meet",
        T: "VFW",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретите",
        E: "to meet",
        T: "VFF",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретят",
        E: "to meet",
        T: "VFT",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встреть",
        E: "to meet",
        T: "VI1",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретьте",
        E: "to meet",
        T: "VI2",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретил",
        E: "to meet",
        T: "VPM",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретила",
        E: "to meet",
        T: "VPF",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретило",
        E: "to meet",
        T: "VPN",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "встретили",
        E: "to meet",
        T: "VPP",
        A: "P",
        O: "встретить",
        RA: 870,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входить",
        E: "to enter, come in",
        T: "VI",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вхожу",
        E: "to enter, come in",
        T: "VN1",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду входить",
        E: "to enter, come in",
        T: "VF1",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входишь",
        E: "to enter, come in",
        T: "VN2",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь входить",
        E: "to enter, come in",
        T: "VF2",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входит",
        E: "to enter, come in",
        T: "VN3",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет входить",
        E: "to enter, come in",
        T: "VF3",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входим",
        E: "to enter, come in",
        T: "VNW",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем входить",
        E: "to enter, come in",
        T: "VFW",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входите",
        E: "to enter, come in",
        T: "VNF",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете входить",
        E: "to enter, come in",
        T: "VFF",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входят",
        E: "to enter, come in",
        T: "VNT",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут входить",
        E: "to enter, come in",
        T: "VFT",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входи",
        E: "to enter, come in",
        T: "VI1",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входите",
        E: "to enter, come in",
        T: "VI2",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входил",
        E: "to enter, come in",
        T: "VPM",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входила",
        E: "to enter, come in",
        T: "VPF",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входило",
        E: "to enter, come in",
        T: "VPN",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "входили",
        E: "to enter, come in",
        T: "VPP",
        A: "I",
        O: "входить",
        RA: 876,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружить",
        E: "to discover, find out",
        T: "VI",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружу",
        E: "to discover, find out",
        T: "VF1",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружишь",
        E: "to discover, find out",
        T: "VF2",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружит",
        E: "to discover, find out",
        T: "VF3",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружим",
        E: "to discover, find out",
        T: "VFW",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружите",
        E: "to discover, find out",
        T: "VFF",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружат",
        E: "to discover, find out",
        T: "VFT",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружь",
        E: "to discover, find out",
        T: "VI1",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружьте",
        E: "to discover, find out",
        T: "VI2",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружил",
        E: "to discover, find out",
        T: "VPM",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружила",
        E: "to discover, find out",
        T: "VPF",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружило",
        E: "to discover, find out",
        T: "VPN",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обнаружили",
        E: "to discover, find out",
        T: "VPP",
        A: "P",
        O: "обнаружить",
        RA: 877,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нести",
        E: "to carry",
        T: "VI",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несу",
        E: "to carry",
        T: "VN1",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду нести",
        E: "to carry",
        T: "VF1",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несёшь",
        E: "to carry",
        T: "VN2",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь нести",
        E: "to carry",
        T: "VF2",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несёт",
        E: "to carry",
        T: "VN3",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет нести",
        E: "to carry",
        T: "VF3",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несём",
        E: "to carry",
        T: "VNW",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем нести",
        E: "to carry",
        T: "VFW",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несёте",
        E: "to carry",
        T: "VNF",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете нести",
        E: "to carry",
        T: "VFF",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несут",
        E: "to carry",
        T: "VNT",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут нести",
        E: "to carry",
        T: "VFT",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неси",
        E: "to carry",
        T: "VI1",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несите",
        E: "to carry",
        T: "VI2",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нёс",
        E: "to carry",
        T: "VPM",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несла",
        E: "to carry",
        T: "VPF",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несло",
        E: "to carry",
        T: "VPN",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несли",
        E: "to carry",
        T: "VPP",
        A: "I",
        O: "нести",
        RA: 890,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прислать",
        E: "to send",
        T: "VI",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлю",
        E: "to send",
        T: "VF1",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлёшь",
        E: "to send",
        T: "VF2",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлёт",
        E: "to send",
        T: "VF3",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлём",
        E: "to send",
        T: "VFW",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлёте",
        E: "to send",
        T: "VFF",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлют",
        E: "to send",
        T: "VFT",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришли",
        E: "to send",
        T: "VI1",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлите",
        E: "to send",
        T: "VI2",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прислал",
        E: "to send",
        T: "VPM",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прислала",
        E: "to send",
        T: "VPF",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прислало",
        E: "to send",
        T: "VPN",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прислали",
        E: "to send",
        T: "VPP",
        A: "P",
        O: "прислать",
        RA: 896,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относиться",
        E: "to treat, regard, relate",
        T: "VI",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отношусь",
        E: "to treat, regard, relate",
        T: "VN1",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду относиться",
        E: "to treat, regard, relate",
        T: "VF1",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относишься",
        E: "to treat, regard, relate",
        T: "VN2",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь относиться",
        E: "to treat, regard, relate",
        T: "VF2",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относится",
        E: "to treat, regard, relate",
        T: "VN3",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет относиться",
        E: "to treat, regard, relate",
        T: "VF3",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относимся",
        E: "to treat, regard, relate",
        T: "VNW",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем относиться",
        E: "to treat, regard, relate",
        T: "VFW",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относитесь",
        E: "to treat, regard, relate",
        T: "VNF",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете относиться",
        E: "to treat, regard, relate",
        T: "VFF",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относятся",
        E: "to treat, regard, relate",
        T: "VNT",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут относиться",
        E: "to treat, regard, relate",
        T: "VFT",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относись",
        E: "to treat, regard, relate",
        T: "VI1",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относитесь",
        E: "to treat, regard, relate",
        T: "VI2",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относился",
        E: "to treat, regard, relate",
        T: "VPM",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относилась",
        E: "to treat, regard, relate",
        T: "VPF",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относилось",
        E: "to treat, regard, relate",
        T: "VPN",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "относились",
        E: "to treat, regard, relate",
        T: "VPP",
        A: "I",
        O: "относиться",
        RA: 900,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникать",
        E: "to arise, appear",
        T: "VI",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникаю",
        E: "to arise, appear",
        T: "VN1",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду возникать",
        E: "to arise, appear",
        T: "VF1",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникаешь",
        E: "to arise, appear",
        T: "VN2",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь возникать",
        E: "to arise, appear",
        T: "VF2",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникает",
        E: "to arise, appear",
        T: "VN3",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет возникать",
        E: "to arise, appear",
        T: "VF3",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникаем",
        E: "to arise, appear",
        T: "VNW",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем возникать",
        E: "to arise, appear",
        T: "VFW",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникаете",
        E: "to arise, appear",
        T: "VNF",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете возникать",
        E: "to arise, appear",
        T: "VFF",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникают",
        E: "to arise, appear",
        T: "VNT",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут возникать",
        E: "to arise, appear",
        T: "VFT",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникай",
        E: "to arise, appear",
        T: "VI1",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникайте",
        E: "to arise, appear",
        T: "VI2",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникал",
        E: "to arise, appear",
        T: "VPM",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникала",
        E: "to arise, appear",
        T: "VPF",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникало",
        E: "to arise, appear",
        T: "VPN",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникали",
        E: "to arise, appear",
        T: "VPP",
        A: "I",
        O: "возникать",
        RA: 901,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторить",
        E: "to repeat",
        T: "VI",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторю",
        E: "to repeat",
        T: "VF1",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторишь",
        E: "to repeat",
        T: "VF2",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторит",
        E: "to repeat",
        T: "VF3",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторим",
        E: "to repeat",
        T: "VFW",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторите",
        E: "to repeat",
        T: "VFF",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторят",
        E: "to repeat",
        T: "VFT",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повтори",
        E: "to repeat",
        T: "VI1",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторите",
        E: "to repeat",
        T: "VI2",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторил",
        E: "to repeat",
        T: "VPM",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторила",
        E: "to repeat",
        T: "VPF",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторило",
        E: "to repeat",
        T: "VPN",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "повторили",
        E: "to repeat",
        T: "VPP",
        A: "P",
        O: "повторить",
        RA: 903,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнуть",
        E: "to perish, be killed",
        T: "VI",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибну",
        E: "to perish, be killed",
        T: "VF1",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнешь",
        E: "to perish, be killed",
        T: "VF2",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнет",
        E: "to perish, be killed",
        T: "VF3",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнем",
        E: "to perish, be killed",
        T: "VFW",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнете",
        E: "to perish, be killed",
        T: "VFF",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибнут",
        E: "to perish, be killed",
        T: "VFT",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибни",
        E: "to perish, be killed",
        T: "VI1",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибните",
        E: "to perish, be killed",
        T: "VI2",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погиб погибнул",
        E: "to perish, be killed",
        T: "VPM",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибла погибнула",
        E: "to perish, be killed",
        T: "VPF",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибло погибнуло",
        E: "to perish, be killed",
        T: "VPN",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "погибли погибнули",
        E: "to perish, be killed",
        T: "VPP",
        A: "P",
        O: "погибнуть",
        RA: 909,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "гореть",
        E: "to burn",
        T: "VI",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горю",
        E: "to burn",
        T: "VN1",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду гореть",
        E: "to burn",
        T: "VF1",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горишь",
        E: "to burn",
        T: "VN2",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь гореть",
        E: "to burn",
        T: "VF2",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горит",
        E: "to burn",
        T: "VN3",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет гореть",
        E: "to burn",
        T: "VF3",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горим",
        E: "to burn",
        T: "VNW",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем гореть",
        E: "to burn",
        T: "VFW",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горите",
        E: "to burn",
        T: "VNF",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете гореть",
        E: "to burn",
        T: "VFF",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горят",
        E: "to burn",
        T: "VNT",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут гореть",
        E: "to burn",
        T: "VFT",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "гори",
        E: "to burn",
        T: "VI1",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горите",
        E: "to burn",
        T: "VI2",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горел",
        E: "to burn",
        T: "VPM",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горела",
        E: "to burn",
        T: "VPF",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горело",
        E: "to burn",
        T: "VPN",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горели",
        E: "to burn",
        T: "VPP",
        A: "I",
        O: "гореть",
        RA: 913,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плакать",
        E: "to cry",
        T: "VI",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачу",
        E: "to cry",
        T: "VN1",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду плакать",
        E: "to cry",
        T: "VF1",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачешь",
        E: "to cry",
        T: "VN2",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь плакать",
        E: "to cry",
        T: "VF2",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачет",
        E: "to cry",
        T: "VN3",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет плакать",
        E: "to cry",
        T: "VF3",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачем",
        E: "to cry",
        T: "VNW",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем плакать",
        E: "to cry",
        T: "VFW",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачете",
        E: "to cry",
        T: "VNF",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете плакать",
        E: "to cry",
        T: "VFF",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачут",
        E: "to cry",
        T: "VNT",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут плакать",
        E: "to cry",
        T: "VFT",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачь",
        E: "to cry",
        T: "VI1",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плачьте",
        E: "to cry",
        T: "VI2",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плакал",
        E: "to cry",
        T: "VPM",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плакала",
        E: "to cry",
        T: "VPF",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плакало",
        E: "to cry",
        T: "VPN",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плакали",
        E: "to cry",
        T: "VPP",
        A: "I",
        O: "плакать",
        RA: 914,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двигаться",
        E: "to move",
        T: "VI",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движусь",
        E: "to move",
        T: "VN1",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду двигаться",
        E: "to move",
        T: "VF1",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движешься",
        E: "to move",
        T: "VN2",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь двигаться",
        E: "to move",
        T: "VF2",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движется",
        E: "to move",
        T: "VN3",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет двигаться",
        E: "to move",
        T: "VF3",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движемся",
        E: "to move",
        T: "VNW",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем двигаться",
        E: "to move",
        T: "VFW",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движетесь",
        E: "to move",
        T: "VNF",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете двигаться",
        E: "to move",
        T: "VFF",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движутся",
        E: "to move",
        T: "VNT",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут двигаться",
        E: "to move",
        T: "VFT",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движься",
        E: "to move",
        T: "VI1",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "движьтесь",
        E: "to move",
        T: "VI2",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двигался",
        E: "to move",
        T: "VPM",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двигалась",
        E: "to move",
        T: "VPF",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двигалось",
        E: "to move",
        T: "VPN",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двигались",
        E: "to move",
        T: "VPP",
        A: "I",
        O: "двигаться",
        RA: 917,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавить",
        E: "to add",
        T: "VI",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавлю",
        E: "to add",
        T: "VF1",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавишь",
        E: "to add",
        T: "VF2",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавит",
        E: "to add",
        T: "VF3",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавим",
        E: "to add",
        T: "VFW",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавите",
        E: "to add",
        T: "VFF",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавят",
        E: "to add",
        T: "VFT",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавь",
        E: "to add",
        T: "VI1",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавьте",
        E: "to add",
        T: "VI2",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавил",
        E: "to add",
        T: "VPM",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавила",
        E: "to add",
        T: "VPF",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавило",
        E: "to add",
        T: "VPN",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добавили",
        E: "to add",
        T: "VPP",
        A: "P",
        O: "добавить",
        RA: 919,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивиться",
        E: "to wonder, be surprised",
        T: "VI",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивлюсь",
        E: "to wonder, be surprised",
        T: "VF1",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивишься",
        E: "to wonder, be surprised",
        T: "VF2",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивится",
        E: "to wonder, be surprised",
        T: "VF3",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивимся",
        E: "to wonder, be surprised",
        T: "VFW",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивитесь",
        E: "to wonder, be surprised",
        T: "VFF",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивятся",
        E: "to wonder, be surprised",
        T: "VFT",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивись",
        E: "to wonder, be surprised",
        T: "VI1",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивитесь",
        E: "to wonder, be surprised",
        T: "VI2",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивился",
        E: "to wonder, be surprised",
        T: "VPM",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивилась",
        E: "to wonder, be surprised",
        T: "VPF",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивилось",
        E: "to wonder, be surprised",
        T: "VPN",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "удивились",
        E: "to wonder, be surprised",
        T: "VPP",
        A: "P",
        O: "удивиться",
        RA: 920,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действовать",
        E: "to act",
        T: "VI",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действую",
        E: "to act",
        T: "VN1",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду действовать",
        E: "to act",
        T: "VF1",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуешь",
        E: "to act",
        T: "VN2",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь действовать",
        E: "to act",
        T: "VF2",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действует",
        E: "to act",
        T: "VN3",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет действовать",
        E: "to act",
        T: "VF3",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуем",
        E: "to act",
        T: "VNW",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем действовать",
        E: "to act",
        T: "VFW",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуете",
        E: "to act",
        T: "VNF",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете действовать",
        E: "to act",
        T: "VFF",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуют",
        E: "to act",
        T: "VNT",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут действовать",
        E: "to act",
        T: "VFT",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуй",
        E: "to act",
        T: "VI1",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действуйте",
        E: "to act",
        T: "VI2",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действовал",
        E: "to act",
        T: "VPM",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действовала",
        E: "to act",
        T: "VPF",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действовало",
        E: "to act",
        T: "VPN",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действовали",
        E: "to act",
        T: "VPP",
        A: "I",
        O: "действовать",
        RA: 925,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держаться",
        E: "to hold on, behave",
        T: "VI",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держусь",
        E: "to hold on, behave",
        T: "VN1",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду держаться",
        E: "to hold on, behave",
        T: "VF1",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держишься",
        E: "to hold on, behave",
        T: "VN2",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь держаться",
        E: "to hold on, behave",
        T: "VF2",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держится",
        E: "to hold on, behave",
        T: "VN3",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет держаться",
        E: "to hold on, behave",
        T: "VF3",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держимся",
        E: "to hold on, behave",
        T: "VNW",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем держаться",
        E: "to hold on, behave",
        T: "VFW",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держитесь",
        E: "to hold on, behave",
        T: "VNF",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете держаться",
        E: "to hold on, behave",
        T: "VFF",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держатся",
        E: "to hold on, behave",
        T: "VNT",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут держаться",
        E: "to hold on, behave",
        T: "VFT",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держись",
        E: "to hold on, behave",
        T: "VI1",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держитесь",
        E: "to hold on, behave",
        T: "VI2",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держался",
        E: "to hold on, behave",
        T: "VPM",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держалась",
        E: "to hold on, behave",
        T: "VPF",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держалось",
        E: "to hold on, behave",
        T: "VPN",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "держались",
        E: "to hold on, behave",
        T: "VPP",
        A: "I",
        O: "держаться",
        RA: 931,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лететь",
        E: "to fly",
        T: "VI",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лечу",
        E: "to fly",
        T: "VN1",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду лететь",
        E: "to fly",
        T: "VF1",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летишь",
        E: "to fly",
        T: "VN2",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь лететь",
        E: "to fly",
        T: "VF2",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летит",
        E: "to fly",
        T: "VN3",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет лететь",
        E: "to fly",
        T: "VF3",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летим",
        E: "to fly",
        T: "VNW",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем лететь",
        E: "to fly",
        T: "VFW",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летите",
        E: "to fly",
        T: "VNF",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете лететь",
        E: "to fly",
        T: "VFF",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летят",
        E: "to fly",
        T: "VNT",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут лететь",
        E: "to fly",
        T: "VFT",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лети",
        E: "to fly",
        T: "VI1",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летите",
        E: "to fly",
        T: "VI2",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летел",
        E: "to fly",
        T: "VPM",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летела",
        E: "to fly",
        T: "VPF",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летело",
        E: "to fly",
        T: "VPN",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "летели",
        E: "to fly",
        T: "VPP",
        A: "I",
        O: "лететь",
        RA: 933,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носить",
        E: "to wear",
        T: "VI",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ношу",
        E: "to wear",
        T: "VN1",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду носить",
        E: "to wear",
        T: "VF1",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носишь",
        E: "to wear",
        T: "VN2",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь носить",
        E: "to wear",
        T: "VF2",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носит",
        E: "to wear",
        T: "VN3",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет носить",
        E: "to wear",
        T: "VF3",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носим",
        E: "to wear",
        T: "VNW",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем носить",
        E: "to wear",
        T: "VFW",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носите",
        E: "to wear",
        T: "VNF",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете носить",
        E: "to wear",
        T: "VFF",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носят",
        E: "to wear",
        T: "VNT",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут носить",
        E: "to wear",
        T: "VFT",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носи",
        E: "to wear",
        T: "VI1",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носите",
        E: "to wear",
        T: "VI2",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носил",
        E: "to wear",
        T: "VPM",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носила",
        E: "to wear",
        T: "VPF",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носило",
        E: "to wear",
        T: "VPN",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "носили",
        E: "to wear",
        T: "VPP",
        A: "I",
        O: "носить",
        RA: 934,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнуть",
        E: "to get used to, to grow accustomed to",
        T: "VI",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкну",
        E: "to get used to, to grow accustomed to",
        T: "VF1",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнешь",
        E: "to get used to, to grow accustomed to",
        T: "VF2",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнет",
        E: "to get used to, to grow accustomed to",
        T: "VF3",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнем",
        E: "to get used to, to grow accustomed to",
        T: "VFW",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнете",
        E: "to get used to, to grow accustomed to",
        T: "VFF",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкнут",
        E: "to get used to, to grow accustomed to",
        T: "VFT",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкни",
        E: "to get used to, to grow accustomed to",
        T: "VI1",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкните",
        E: "to get used to, to grow accustomed to",
        T: "VI2",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привык",
        E: "to get used to, to grow accustomed to",
        T: "VPM",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкла",
        E: "to get used to, to grow accustomed to",
        T: "VPF",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкло",
        E: "to get used to, to grow accustomed to",
        T: "VPN",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "привыкли",
        E: "to get used to, to grow accustomed to",
        T: "VPP",
        A: "P",
        O: "привыкнуть",
        RA: 946,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонить",
        E: "to call, to telephone",
        T: "VI",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвоню",
        E: "to call, to telephone",
        T: "VF1",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонишь",
        E: "to call, to telephone",
        T: "VF2",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонит",
        E: "to call, to telephone",
        T: "VF3",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвоним",
        E: "to call, to telephone",
        T: "VFW",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвоните",
        E: "to call, to telephone",
        T: "VFF",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонят",
        E: "to call, to telephone",
        T: "VFT",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвони",
        E: "to call, to telephone",
        T: "VI1",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвоните",
        E: "to call, to telephone",
        T: "VI2",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонил",
        E: "to call, to telephone",
        T: "VPM",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонила",
        E: "to call, to telephone",
        T: "VPF",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонило",
        E: "to call, to telephone",
        T: "VPN",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позвонили",
        E: "to call, to telephone",
        T: "VPP",
        A: "P",
        O: "позвонить",
        RA: 956,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешать",
        E: "to interfere, prevent; mix",
        T: "VI",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешаю",
        E: "to interfere, prevent; mix",
        T: "VN1",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду мешать",
        E: "to interfere, prevent; mix",
        T: "VF1",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешаешь",
        E: "to interfere, prevent; mix",
        T: "VN2",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь мешать",
        E: "to interfere, prevent; mix",
        T: "VF2",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешает",
        E: "to interfere, prevent; mix",
        T: "VN3",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет мешать",
        E: "to interfere, prevent; mix",
        T: "VF3",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешаем",
        E: "to interfere, prevent; mix",
        T: "VNW",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем мешать",
        E: "to interfere, prevent; mix",
        T: "VFW",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешаете",
        E: "to interfere, prevent; mix",
        T: "VNF",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете мешать",
        E: "to interfere, prevent; mix",
        T: "VFF",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешают",
        E: "to interfere, prevent; mix",
        T: "VNT",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут мешать",
        E: "to interfere, prevent; mix",
        T: "VFT",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешай",
        E: "to interfere, prevent; mix",
        T: "VI1",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешайте",
        E: "to interfere, prevent; mix",
        T: "VI2",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешал",
        E: "to interfere, prevent; mix",
        T: "VPM",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешала",
        E: "to interfere, prevent; mix",
        T: "VPF",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешало",
        E: "to interfere, prevent; mix",
        T: "VPN",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мешали",
        E: "to interfere, prevent; mix",
        T: "VPP",
        A: "I",
        O: "мешать",
        RA: 958,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнуть",
        E: "to arise, spring up",
        T: "VI",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникну",
        E: "to arise, spring up",
        T: "VF1",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнешь",
        E: "to arise, spring up",
        T: "VF2",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнет",
        E: "to arise, spring up",
        T: "VF3",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнем",
        E: "to arise, spring up",
        T: "VFW",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнете",
        E: "to arise, spring up",
        T: "VFF",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникнут",
        E: "to arise, spring up",
        T: "VFT",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникни",
        E: "to arise, spring up",
        T: "VI1",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникните",
        E: "to arise, spring up",
        T: "VI2",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возник возникнул",
        E: "to arise, spring up",
        T: "VPM",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникла возникнула",
        E: "to arise, spring up",
        T: "VPF",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникло возникнуло",
        E: "to arise, spring up",
        T: "VPN",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возникли возникнули",
        E: "to arise, spring up",
        T: "VPP",
        A: "P",
        O: "возникнуть",
        RA: 961,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желать",
        E: "to wish",
        T: "VI",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желаю",
        E: "to wish",
        T: "VN1",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду желать",
        E: "to wish",
        T: "VF1",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желаешь",
        E: "to wish",
        T: "VN2",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь желать",
        E: "to wish",
        T: "VF2",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желает",
        E: "to wish",
        T: "VN3",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет желать",
        E: "to wish",
        T: "VF3",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желаем",
        E: "to wish",
        T: "VNW",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем желать",
        E: "to wish",
        T: "VFW",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желаете",
        E: "to wish",
        T: "VNF",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете желать",
        E: "to wish",
        T: "VFF",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желают",
        E: "to wish",
        T: "VNT",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут желать",
        E: "to wish",
        T: "VFT",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желай",
        E: "to wish",
        T: "VI1",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желайте",
        E: "to wish",
        T: "VI2",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желал",
        E: "to wish",
        T: "VPM",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желала",
        E: "to wish",
        T: "VPF",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желало",
        E: "to wish",
        T: "VPN",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желали",
        E: "to wish",
        T: "VPP",
        A: "I",
        O: "желать",
        RA: 964,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробовать",
        E: "to try, test",
        T: "VI",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробую",
        E: "to try, test",
        T: "VF1",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуешь",
        E: "to try, test",
        T: "VF2",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробует",
        E: "to try, test",
        T: "VF3",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуем",
        E: "to try, test",
        T: "VFW",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуете",
        E: "to try, test",
        T: "VFF",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуют",
        E: "to try, test",
        T: "VFT",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуй",
        E: "to try, test",
        T: "VI1",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробуйте",
        E: "to try, test",
        T: "VI2",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробовал",
        E: "to try, test",
        T: "VPM",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробовала",
        E: "to try, test",
        T: "VPF",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробовало",
        E: "to try, test",
        T: "VPN",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "попробовали",
        E: "to try, test",
        T: "VPP",
        A: "P",
        O: "попробовать",
        RA: 965,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаться",
        E: "to come out",
        T: "VI",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаюсь",
        E: "to come out",
        T: "VN1",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду получаться",
        E: "to come out",
        T: "VF1",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаешься",
        E: "to come out",
        T: "VN2",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь получаться",
        E: "to come out",
        T: "VF2",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получается",
        E: "to come out",
        T: "VN3",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет получаться",
        E: "to come out",
        T: "VF3",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаемся",
        E: "to come out",
        T: "VNW",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем получаться",
        E: "to come out",
        T: "VFW",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаетесь",
        E: "to come out",
        T: "VNF",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете получаться",
        E: "to come out",
        T: "VFF",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получаются",
        E: "to come out",
        T: "VNT",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут получаться",
        E: "to come out",
        T: "VFT",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получайся",
        E: "to come out",
        T: "VI1",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получайтесь",
        E: "to come out",
        T: "VI2",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получался",
        E: "to come out",
        T: "VPM",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получалась",
        E: "to come out",
        T: "VPF",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получалось",
        E: "to come out",
        T: "VPN",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "получались",
        E: "to come out",
        T: "VPP",
        A: "I",
        O: "получаться",
        RA: 966,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставить",
        E: "to put, place, set",
        T: "VI",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставлю",
        E: "to put, place, set",
        T: "VN1",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду ставить",
        E: "to put, place, set",
        T: "VF1",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставишь",
        E: "to put, place, set",
        T: "VN2",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь ставить",
        E: "to put, place, set",
        T: "VF2",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставит",
        E: "to put, place, set",
        T: "VN3",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет ставить",
        E: "to put, place, set",
        T: "VF3",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставим",
        E: "to put, place, set",
        T: "VNW",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем ставить",
        E: "to put, place, set",
        T: "VFW",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставите",
        E: "to put, place, set",
        T: "VNF",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете ставить",
        E: "to put, place, set",
        T: "VFF",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставят",
        E: "to put, place, set",
        T: "VNT",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут ставить",
        E: "to put, place, set",
        T: "VFT",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставь",
        E: "to put, place, set",
        T: "VI1",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставьте",
        E: "to put, place, set",
        T: "VI2",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставил",
        E: "to put, place, set",
        T: "VPM",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставила",
        E: "to put, place, set",
        T: "VPF",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставило",
        E: "to put, place, set",
        T: "VPN",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ставили",
        E: "to put, place, set",
        T: "VPP",
        A: "I",
        O: "ставить",
        RA: 974,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводить",
        E: "to lead, escort; accompany",
        T: "VI",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "провожу",
        E: "to lead, escort; accompany",
        T: "VN1",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду проводить",
        E: "to lead, escort; accompany",
        T: "VF1",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводишь",
        E: "to lead, escort; accompany",
        T: "VN2",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь проводить",
        E: "to lead, escort; accompany",
        T: "VF2",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводит",
        E: "to lead, escort; accompany",
        T: "VN3",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет проводить",
        E: "to lead, escort; accompany",
        T: "VF3",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводим",
        E: "to lead, escort; accompany",
        T: "VNW",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем проводить",
        E: "to lead, escort; accompany",
        T: "VFW",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводите",
        E: "to lead, escort; accompany",
        T: "VNF",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете проводить",
        E: "to lead, escort; accompany",
        T: "VFF",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводят",
        E: "to lead, escort; accompany",
        T: "VNT",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут проводить",
        E: "to lead, escort; accompany",
        T: "VFT",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводи",
        E: "to lead, escort; accompany",
        T: "VI1",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводите",
        E: "to lead, escort; accompany",
        T: "VI2",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводил",
        E: "to lead, escort; accompany",
        T: "VPM",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводила",
        E: "to lead, escort; accompany",
        T: "VPF",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводило",
        E: "to lead, escort; accompany",
        T: "VPN",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проводили",
        E: "to lead, escort; accompany",
        T: "VPP",
        A: "P",
        O: "проводить",
        RA: 983,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещать",
        E: "to promise",
        T: "VI",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещаю",
        E: "to promise",
        T: "VN1",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду обещать",
        E: "to promise",
        T: "VF1",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещаешь",
        E: "to promise",
        T: "VN2",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь обещать",
        E: "to promise",
        T: "VF2",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещает",
        E: "to promise",
        T: "VN3",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет обещать",
        E: "to promise",
        T: "VF3",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещаем",
        E: "to promise",
        T: "VNW",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем обещать",
        E: "to promise",
        T: "VFW",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещаете",
        E: "to promise",
        T: "VNF",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете обещать",
        E: "to promise",
        T: "VFF",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещают",
        E: "to promise",
        T: "VNT",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут обещать",
        E: "to promise",
        T: "VFT",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещай",
        E: "to promise",
        T: "VI1",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещайте",
        E: "to promise",
        T: "VI2",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещал",
        E: "to promise",
        T: "VPM",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещала",
        E: "to promise",
        T: "VPF",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещало",
        E: "to promise",
        T: "VPN",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обещали",
        E: "to promise",
        T: "VPP",
        A: "I",
        O: "обещать",
        RA: 987,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судить",
        E: "to judge, try",
        T: "VI",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сужу",
        E: "to judge, try",
        T: "VN1",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду судить",
        E: "to judge, try",
        T: "VF1",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судишь",
        E: "to judge, try",
        T: "VN2",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь судить",
        E: "to judge, try",
        T: "VF2",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судит",
        E: "to judge, try",
        T: "VN3",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет судить",
        E: "to judge, try",
        T: "VF3",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судим",
        E: "to judge, try",
        T: "VNW",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем судить",
        E: "to judge, try",
        T: "VFW",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судите",
        E: "to judge, try",
        T: "VNF",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете судить",
        E: "to judge, try",
        T: "VFF",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судят",
        E: "to judge, try",
        T: "VNT",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут судить",
        E: "to judge, try",
        T: "VFT",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "суди",
        E: "to judge, try",
        T: "VI1",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судите",
        E: "to judge, try",
        T: "VI2",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судил",
        E: "to judge, try",
        T: "VPM",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судила",
        E: "to judge, try",
        T: "VPF",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судило",
        E: "to judge, try",
        T: "VPN",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "судили",
        E: "to judge, try",
        T: "VPP",
        A: "I",
        O: "судить",
        RA: 989,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собраться",
        E: "to gather, collect",
        T: "VI",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберусь",
        E: "to gather, collect",
        T: "VF1",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберёшься",
        E: "to gather, collect",
        T: "VF2",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберётся",
        E: "to gather, collect",
        T: "VF3",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберёмся",
        E: "to gather, collect",
        T: "VFW",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберётесь",
        E: "to gather, collect",
        T: "VFF",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберутся",
        E: "to gather, collect",
        T: "VFT",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберись",
        E: "to gather, collect",
        T: "VI1",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "соберитесь",
        E: "to gather, collect",
        T: "VI2",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собрался",
        E: "to gather, collect",
        T: "VPM",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собралась",
        E: "to gather, collect",
        T: "VPF",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собралось собралось",
        E: "to gather, collect",
        T: "VPN",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собрались собрались",
        E: "to gather, collect",
        T: "VPP",
        A: "P",
        O: "собраться",
        RA: 991,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колоть",
        E: "to prick",
        T: "VI",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колю",
        E: "to prick",
        T: "VN1",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду колоть",
        E: "to prick",
        T: "VF1",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колешь",
        E: "to prick",
        T: "VN2",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь колоть",
        E: "to prick",
        T: "VF2",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колет",
        E: "to prick",
        T: "VN3",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет колоть",
        E: "to prick",
        T: "VF3",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колем",
        E: "to prick",
        T: "VNW",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем колоть",
        E: "to prick",
        T: "VFW",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колете",
        E: "to prick",
        T: "VNF",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете колоть",
        E: "to prick",
        T: "VFF",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колют",
        E: "to prick",
        T: "VNT",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут колоть",
        E: "to prick",
        T: "VFT",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коли",
        E: "to prick",
        T: "VI1",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колите",
        E: "to prick",
        T: "VI2",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колол",
        E: "to prick",
        T: "VPM",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "колола",
        E: "to prick",
        T: "VPF",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кололо",
        E: "to prick",
        T: "VPN",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кололи",
        E: "to prick",
        T: "VPP",
        A: "I",
        O: "колоть",
        RA: 993,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричать",
        E: "to shout, cry out",
        T: "VI",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричу",
        E: "to shout, cry out",
        T: "VF1",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричишь",
        E: "to shout, cry out",
        T: "VF2",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричит",
        E: "to shout, cry out",
        T: "VF3",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричим",
        E: "to shout, cry out",
        T: "VFW",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричите",
        E: "to shout, cry out",
        T: "VFF",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричат",
        E: "to shout, cry out",
        T: "VFT",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричи",
        E: "to shout, cry out",
        T: "VI1",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричите",
        E: "to shout, cry out",
        T: "VI2",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричал",
        E: "to shout, cry out",
        T: "VPM",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричала",
        E: "to shout, cry out",
        T: "VPF",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричало",
        E: "to shout, cry out",
        T: "VPN",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "закричали",
        E: "to shout, cry out",
        T: "VPP",
        A: "P",
        O: "закричать",
        RA: 997,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончиться",
        E: "to end",
        T: "VI",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончусь",
        E: "to end",
        T: "VF1",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончишься",
        E: "to end",
        T: "VF2",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончится",
        E: "to end",
        T: "VF3",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончимся",
        E: "to end",
        T: "VFW",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончитесь",
        E: "to end",
        T: "VFF",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончатся",
        E: "to end",
        T: "VFT",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончись",
        E: "to end",
        T: "VI1",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончитесь",
        E: "to end",
        T: "VI2",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончился",
        E: "to end",
        T: "VPM",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончилась",
        E: "to end",
        T: "VPF",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончилось",
        E: "to end",
        T: "VPN",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кончились",
        E: "to end",
        T: "VPP",
        A: "P",
        O: "кончиться",
        RA: 998,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стрелять",
        E: "to shoot",
        T: "VI",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляю",
        E: "to shoot",
        T: "VN1",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду стрелять",
        E: "to shoot",
        T: "VF1",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляешь",
        E: "to shoot",
        T: "VN2",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь стрелять",
        E: "to shoot",
        T: "VF2",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляет",
        E: "to shoot",
        T: "VN3",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет стрелять",
        E: "to shoot",
        T: "VF3",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляем",
        E: "to shoot",
        T: "VNW",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем стрелять",
        E: "to shoot",
        T: "VFW",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляете",
        E: "to shoot",
        T: "VNF",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете стрелять",
        E: "to shoot",
        T: "VFF",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляют",
        E: "to shoot",
        T: "VNT",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут стрелять",
        E: "to shoot",
        T: "VFT",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляй",
        E: "to shoot",
        T: "VI1",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляйте",
        E: "to shoot",
        T: "VI2",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стрелял",
        E: "to shoot",
        T: "VPM",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляла",
        E: "to shoot",
        T: "VPF",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляло",
        E: "to shoot",
        T: "VPN",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стреляли",
        E: "to shoot",
        T: "VPP",
        A: "I",
        O: "стрелять",
        RA: 1000,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использовать",
        E: "to use, utilize, make use of",
        T: "VI",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использую",
        E: "to use, utilize, make use of",
        T: "VN1",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду использовать",
        E: "to use, utilize, make use of",
        T: "VF1",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используешь",
        E: "to use, utilize, make use of",
        T: "VN2",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь использовать",
        E: "to use, utilize, make use of",
        T: "VF2",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использует",
        E: "to use, utilize, make use of",
        T: "VN3",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет использовать",
        E: "to use, utilize, make use of",
        T: "VF3",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используем",
        E: "to use, utilize, make use of",
        T: "VNW",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем использовать",
        E: "to use, utilize, make use of",
        T: "VFW",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используете",
        E: "to use, utilize, make use of",
        T: "VNF",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете использовать",
        E: "to use, utilize, make use of",
        T: "VFF",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используют",
        E: "to use, utilize, make use of",
        T: "VNT",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут использовать",
        E: "to use, utilize, make use of",
        T: "VFT",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используй",
        E: "to use, utilize, make use of",
        T: "VI1",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "используйте",
        E: "to use, utilize, make use of",
        T: "VI2",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использовал",
        E: "to use, utilize, make use of",
        T: "VPM",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использовала",
        E: "to use, utilize, make use of",
        T: "VPF",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использовало",
        E: "to use, utilize, make use of",
        T: "VPN",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "использовали",
        E: "to use, utilize, make use of",
        T: "VPP",
        A: "P",
        O: "использовать",
        RA: 1005,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнуть",
        E: "to smell",
        T: "VI",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахну",
        E: "to smell",
        T: "VN1",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнешь",
        E: "to smell",
        T: "VF1",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнет",
        E: "to smell",
        T: "VN2",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнем",
        E: "to smell",
        T: "VF2",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнете",
        E: "to smell",
        T: "VN3",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнут",
        E: "to smell",
        T: "VF3",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахни",
        E: "to smell",
        T: "VNW",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахните",
        E: "to smell",
        T: "VFW",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пах//пахнул",
        E: "to smell",
        T: "VNF",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахла",
        E: "to smell",
        T: "VFF",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахло",
        E: "to smell",
        T: "VNT",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахли",
        E: "to smell",
        T: "VFT",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "",
        E: "to smell",
        T: "VI2",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "",
        E: "to smell",
        T: "VPF",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "",
        E: "to smell",
        T: "VPP",
        A: "P",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнуть",
        E: "smell",
        T: "VI",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахну",
        E: "smell",
        T: "VN1",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "буду пахнуть",
        E: "smell",
        T: "VF1",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнёшь",
        E: "smell",
        T: "VN2",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будешь пахнуть",
        E: "smell",
        T: "VF2",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнёт",
        E: "smell",
        T: "VN3",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будет пахнуть",
        E: "smell",
        T: "VF3",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнём",
        E: "smell",
        T: "VNW",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будем пахнуть",
        E: "smell",
        T: "VFW",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнёте",
        E: "smell",
        T: "VNF",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будете пахнуть",
        E: "smell",
        T: "VFF",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнут",
        E: "smell",
        T: "VNT",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будут пахнуть",
        E: "smell",
        T: "VFT",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахни",
        E: "smell",
        T: "VI1",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахните",
        E: "smell",
        T: "VI2",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнул",
        E: "smell",
        T: "VPM",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнула",
        E: "smell",
        T: "VPF",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнуло",
        E: "smell",
        T: "VPN",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пахнули",
        E: "smell",
        T: "VPP",
        A: "I",
        O: "пахнуть",
        RA: 1007,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человек",
        E: "man, person",
        T: "NNS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "люди",
        E: "man, person",
        T: "NNP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "человека",
        E: "man, person",
        T: "NGS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "людей",
        E: "man, person",
        T: "NGP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "человеку",
        E: "man, person",
        T: "NDS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "людям",
        E: "man, person",
        T: "NDP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "человека",
        E: "man, person",
        T: "NAS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "людей",
        E: "man, person",
        T: "NAP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "человеком",
        E: "man, person",
        T: "NIS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "людьми",
        E: "man, person",
        T: "NIP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "человеке",
        E: "man, person",
        T: "NPS",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "людях",
        E: "man, person",
        T: "NPP",
        A: "A",
        O: "человек",
        RA: 32,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "год",
        E: "year",
        T: "NNS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годы",
        E: "year",
        T: "NNP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "года",
        E: "year",
        T: "NGS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годов",
        E: "year",
        T: "NGP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "году",
        E: "year",
        T: "NDS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годам",
        E: "year",
        T: "NDP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "год",
        E: "year",
        T: "NAS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годы",
        E: "year",
        T: "NAP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годом",
        E: "year",
        T: "NIS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годами",
        E: "year",
        T: "NIP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "году",
        E: "year",
        T: "NPS",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "годах",
        E: "year",
        T: "NPP",
        A: "I",
        O: "год",
        RA: 49,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "время",
        E: "time, season",
        T: "NNS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времена",
        E: "time, season",
        T: "NNP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времени",
        E: "time, season",
        T: "NGS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времён",
        E: "time, season",
        T: "NGP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времени",
        E: "time, season",
        T: "NDS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "временам",
        E: "time, season",
        T: "NDP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "время",
        E: "time, season",
        T: "NAS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времена",
        E: "time, season",
        T: "NAP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "временем",
        E: "time, season",
        T: "NIS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "временами",
        E: "time, season",
        T: "NIP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "времени",
        E: "time, season",
        T: "NPS",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "временах",
        E: "time, season",
        T: "NPP",
        A: "I",
        O: "время",
        RA: 56,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "рука",
        E: "hand, arm",
        T: "NNS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руки",
        E: "hand, arm",
        T: "NNP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руки",
        E: "hand, arm",
        T: "NGS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рук",
        E: "hand, arm",
        T: "NGP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руке",
        E: "hand, arm",
        T: "NDS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рукам",
        E: "hand, arm",
        T: "NDP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руку",
        E: "hand, arm",
        T: "NAS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руки",
        E: "hand, arm",
        T: "NAP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рукой",
        E: "hand, arm",
        T: "NIS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руками",
        E: "hand, arm",
        T: "NIP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руке",
        E: "hand, arm",
        T: "NPS",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "руках",
        E: "hand, arm",
        T: "NPP",
        A: "I",
        O: "рука",
        RA: 57,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дело",
        E: "business, affair, matter",
        T: "NNS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дела",
        E: "business, affair, matter",
        T: "NNP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дела",
        E: "business, affair, matter",
        T: "NGS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дел",
        E: "business, affair, matter",
        T: "NGP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "делу",
        E: "business, affair, matter",
        T: "NDS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "делам",
        E: "business, affair, matter",
        T: "NDP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дело",
        E: "business, affair, matter",
        T: "NAS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дела",
        E: "business, affair, matter",
        T: "NAP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "делом",
        E: "business, affair, matter",
        T: "NIS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "делами",
        E: "business, affair, matter",
        T: "NIP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деле",
        E: "business, affair, matter",
        T: "NPS",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "делах",
        E: "business, affair, matter",
        T: "NPP",
        A: "I",
        O: "дело",
        RA: 70,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "глаз",
        E: "eye",
        T: "NNS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глаза",
        E: "eye",
        T: "NNP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глаза",
        E: "eye",
        T: "NGS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глаз",
        E: "eye",
        T: "NGP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазу",
        E: "eye",
        T: "NDS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазам",
        E: "eye",
        T: "NDP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глаз",
        E: "eye",
        T: "NAS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глаза",
        E: "eye",
        T: "NAP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазом",
        E: "eye",
        T: "NIS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазами",
        E: "eye",
        T: "NIP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазе",
        E: "eye",
        T: "NPS",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глазах",
        E: "eye",
        T: "NPP",
        A: "I",
        O: "глаз",
        RA: 78,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "жизнь",
        E: "life",
        T: "NNS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизни",
        E: "life",
        T: "NNP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизни",
        E: "life",
        T: "NGS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизней",
        E: "life",
        T: "NGP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизни",
        E: "life",
        T: "NDS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизням",
        E: "life",
        T: "NDP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизнь",
        E: "life",
        T: "NAS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизни",
        E: "life",
        T: "NAP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизнью",
        E: "life",
        T: "NIS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизнями",
        E: "life",
        T: "NIP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизни",
        E: "life",
        T: "NPS",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жизнях",
        E: "life",
        T: "NPP",
        A: "I",
        O: "жизнь",
        RA: 79,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "день",
        E: "day",
        T: "NNS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дни",
        E: "day",
        T: "NNP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дня",
        E: "day",
        T: "NGS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дней",
        E: "day",
        T: "NGP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дню",
        E: "day",
        T: "NDS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дням",
        E: "day",
        T: "NDP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "день",
        E: "day",
        T: "NAS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дни",
        E: "day",
        T: "NAP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "днём",
        E: "day",
        T: "NIS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "днями",
        E: "day",
        T: "NIP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дне",
        E: "day",
        T: "NPS",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "днях",
        E: "day",
        T: "NPP",
        A: "I",
        O: "день",
        RA: 81,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голова",
        E: "head, mind, brains",
        T: "NNS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головы",
        E: "head, mind, brains",
        T: "NNP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головы",
        E: "head, mind, brains",
        T: "NGS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "голов",
        E: "head, mind, brains",
        T: "NGP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "голове",
        E: "head, mind, brains",
        T: "NDS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головам",
        E: "head, mind, brains",
        T: "NDP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "голову",
        E: "head, mind, brains",
        T: "NAS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головы",
        E: "head, mind, brains",
        T: "NAP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головой",
        E: "head, mind, brains",
        T: "NIS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головами",
        E: "head, mind, brains",
        T: "NIP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "голове",
        E: "head, mind, brains",
        T: "NPS",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "головах",
        E: "head, mind, brains",
        T: "NPP",
        A: "I",
        O: "голова",
        RA: 91,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "друг",
        E: "friend",
        T: "NNS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзья",
        E: "friend",
        T: "NNP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друга",
        E: "friend",
        T: "NGS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзей",
        E: "friend",
        T: "NGP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "другу",
        E: "friend",
        T: "NDS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзьям",
        E: "friend",
        T: "NDP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друга",
        E: "friend",
        T: "NAS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзей",
        E: "friend",
        T: "NAP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "другом",
        E: "friend",
        T: "NIS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзьями",
        E: "friend",
        T: "NIP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друге",
        E: "friend",
        T: "NPS",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "друзьях",
        E: "friend",
        T: "NPP",
        A: "A",
        O: "друг",
        RA: 99,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дом",
        E: "house, home",
        T: "NNS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дома",
        E: "house, home",
        T: "NNP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дома",
        E: "house, home",
        T: "NGS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "домов",
        E: "house, home",
        T: "NGP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дому",
        E: "house, home",
        T: "NDS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "домам",
        E: "house, home",
        T: "NDP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дом",
        E: "house, home",
        T: "NAS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дома",
        E: "house, home",
        T: "NAP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "домом",
        E: "house, home",
        T: "NIS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "домами",
        E: "house, home",
        T: "NIP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доме",
        E: "house, home",
        T: "NPS",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "домах",
        E: "house, home",
        T: "NPP",
        A: "I",
        O: "дом",
        RA: 100,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "слово",
        E: "word",
        T: "NNS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слова",
        E: "word",
        T: "NNP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слова",
        E: "word",
        T: "NGS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слов",
        E: "word",
        T: "NGP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слову",
        E: "word",
        T: "NDS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "словам",
        E: "word",
        T: "NDP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слово",
        E: "word",
        T: "NAS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слова",
        E: "word",
        T: "NAP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "словом",
        E: "word",
        T: "NIS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "словами",
        E: "word",
        T: "NIP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "слове",
        E: "word",
        T: "NPS",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "словах",
        E: "word",
        T: "NPP",
        A: "I",
        O: "слово",
        RA: 105,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "место",
        E: "place",
        T: "NNS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "места",
        E: "place",
        T: "NNP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "места",
        E: "place",
        T: "NGS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мест",
        E: "place",
        T: "NGP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "месту",
        E: "place",
        T: "NDS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "местам",
        E: "place",
        T: "NDP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "место",
        E: "place",
        T: "NAS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "места",
        E: "place",
        T: "NAP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "местом",
        E: "place",
        T: "NIS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "местами",
        E: "place",
        T: "NIP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "месте",
        E: "place",
        T: "NPS",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "местах",
        E: "place",
        T: "NPP",
        A: "I",
        O: "место",
        RA: 108,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицо",
        E: "face",
        T: "NNS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лица",
        E: "face",
        T: "NNP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лица",
        E: "face",
        T: "NGS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лиц",
        E: "face",
        T: "NGP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицу",
        E: "face",
        T: "NDS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицам",
        E: "face",
        T: "NDP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицо",
        E: "face",
        T: "NAS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лица",
        E: "face",
        T: "NAP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицом",
        E: "face",
        T: "NIS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицами",
        E: "face",
        T: "NIP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лице",
        E: "face",
        T: "NPS",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лицах",
        E: "face",
        T: "NPP",
        A: "I",
        O: "лицо",
        RA: 111,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сторона",
        E: "side, party",
        T: "NNS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороны",
        E: "side, party",
        T: "NNP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороны",
        E: "side, party",
        T: "NGS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сторон",
        E: "side, party",
        T: "NGP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороне",
        E: "side, party",
        T: "NDS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сторонам",
        E: "side, party",
        T: "NDP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сторону",
        E: "side, party",
        T: "NAS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороны",
        E: "side, party",
        T: "NAP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороной",
        E: "side, party",
        T: "NIS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сторонами",
        E: "side, party",
        T: "NIP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стороне",
        E: "side, party",
        T: "NPS",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сторонах",
        E: "side, party",
        T: "NPP",
        A: "I",
        O: "сторона",
        RA: 123,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "нога",
        E: "foot, leg",
        T: "NNS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ноги",
        E: "foot, leg",
        T: "NNP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ноги",
        E: "foot, leg",
        T: "NGS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ног",
        E: "foot, leg",
        T: "NGP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ноге",
        E: "foot, leg",
        T: "NDS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ногам",
        E: "foot, leg",
        T: "NDP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ногу",
        E: "foot, leg",
        T: "NAS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ноги",
        E: "foot, leg",
        T: "NAP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ногой",
        E: "foot, leg",
        T: "NIS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ногами",
        E: "foot, leg",
        T: "NIP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ноге",
        E: "foot, leg",
        T: "NPS",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ногах",
        E: "foot, leg",
        T: "NPP",
        A: "I",
        O: "нога",
        RA: 125,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверь",
        E: "door",
        T: "NNS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "двери",
        E: "door",
        T: "NNP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "двери",
        E: "door",
        T: "NGS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверей",
        E: "door",
        T: "NGP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "двери",
        E: "door",
        T: "NDS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверям",
        E: "door",
        T: "NDP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверь",
        E: "door",
        T: "NAS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "двери",
        E: "door",
        T: "NAP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверью",
        E: "door",
        T: "NIS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверями",
        E: "door",
        T: "NIP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "двери",
        E: "door",
        T: "NPS",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дверях",
        E: "door",
        T: "NPP",
        A: "I",
        O: "дверь",
        RA: 136,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работа",
        E: "work, job",
        T: "NNS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работы",
        E: "work, job",
        T: "NNP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работы",
        E: "work, job",
        T: "NGS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работ",
        E: "work, job",
        T: "NGP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работе",
        E: "work, job",
        T: "NDS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работам",
        E: "work, job",
        T: "NDP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работу",
        E: "work, job",
        T: "NAS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работы",
        E: "work, job",
        T: "NAP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работой",
        E: "work, job",
        T: "NIS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работами",
        E: "work, job",
        T: "NIP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работе",
        E: "work, job",
        T: "NPS",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "работах",
        E: "work, job",
        T: "NPP",
        A: "I",
        O: "работа",
        RA: 141,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земля",
        E: "earth, land, soil",
        T: "NNS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земли",
        E: "earth, land, soil",
        T: "NNP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земли",
        E: "earth, land, soil",
        T: "NGS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земель",
        E: "earth, land, soil",
        T: "NGP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земле",
        E: "earth, land, soil",
        T: "NDS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "землям",
        E: "earth, land, soil",
        T: "NDP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "землю",
        E: "earth, land, soil",
        T: "NAS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земли",
        E: "earth, land, soil",
        T: "NAP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "землёй",
        E: "earth, land, soil",
        T: "NIS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "землями",
        E: "earth, land, soil",
        T: "NIP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "земле",
        E: "earth, land, soil",
        T: "NPS",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "землях",
        E: "earth, land, soil",
        T: "NPP",
        A: "I",
        O: "земля",
        RA: 145,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "конец",
        E: "end, distance",
        T: "NNS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концы",
        E: "end, distance",
        T: "NNP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "конца",
        E: "end, distance",
        T: "NGS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концов",
        E: "end, distance",
        T: "NGP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концу",
        E: "end, distance",
        T: "NDS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концам",
        E: "end, distance",
        T: "NDP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "конец",
        E: "end, distance",
        T: "NAS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концы",
        E: "end, distance",
        T: "NAP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концом",
        E: "end, distance",
        T: "NIS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концами",
        E: "end, distance",
        T: "NIP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "конце",
        E: "end, distance",
        T: "NPS",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "концах",
        E: "end, distance",
        T: "NPP",
        A: "I",
        O: "конец",
        RA: 146,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "час",
        E: "hour, time",
        T: "NNS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часы",
        E: "hour, time",
        T: "NNP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часа",
        E: "hour, time",
        T: "NGS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часов",
        E: "hour, time",
        T: "NGP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часу",
        E: "hour, time",
        T: "NDS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часам",
        E: "hour, time",
        T: "NDP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "час",
        E: "hour, time",
        T: "NAS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часы",
        E: "hour, time",
        T: "NAP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часом",
        E: "hour, time",
        T: "NIS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часами",
        E: "hour, time",
        T: "NIP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часу",
        E: "hour, time",
        T: "NPS",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "часах",
        E: "hour, time",
        T: "NPP",
        A: "I",
        O: "час",
        RA: 148,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голос",
        E: "voice",
        T: "NNS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голоса",
        E: "voice",
        T: "NNP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голоса",
        E: "voice",
        T: "NGS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосов",
        E: "voice",
        T: "NGP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосу",
        E: "voice",
        T: "NDS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосам",
        E: "voice",
        T: "NDP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голос",
        E: "voice",
        T: "NAS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голоса",
        E: "voice",
        T: "NAP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосом",
        E: "voice",
        T: "NIS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосами",
        E: "voice",
        T: "NIP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосе",
        E: "voice",
        T: "NPS",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "голосах",
        E: "voice",
        T: "NPP",
        A: "I",
        O: "голос",
        RA: 149,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "город",
        E: "town, city",
        T: "NNS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "города",
        E: "town, city",
        T: "NNP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "города",
        E: "town, city",
        T: "NGS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городов",
        E: "town, city",
        T: "NGP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городу",
        E: "town, city",
        T: "NDS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городам",
        E: "town, city",
        T: "NDP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "город",
        E: "town, city",
        T: "NAS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "города",
        E: "town, city",
        T: "NAP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городом",
        E: "town, city",
        T: "NIS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городами",
        E: "town, city",
        T: "NIP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городе",
        E: "town, city",
        T: "NPS",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "городах",
        E: "town, city",
        T: "NPP",
        A: "I",
        O: "город",
        RA: 150,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вода",
        E: "water",
        T: "NNS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воды",
        E: "water",
        T: "NNP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воды",
        E: "water",
        T: "NGS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вод",
        E: "water",
        T: "NGP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воде",
        E: "water",
        T: "NDS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водам",
        E: "water",
        T: "NDP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воду",
        E: "water",
        T: "NAS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воды",
        E: "water",
        T: "NAP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водой",
        E: "water",
        T: "NIS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водами",
        E: "water",
        T: "NIP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воде",
        E: "water",
        T: "NPS",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водах",
        E: "water",
        T: "NPP",
        A: "I",
        O: "вода",
        RA: 156,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стол",
        E: "table, desk; board",
        T: "NNS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столы",
        E: "table, desk; board",
        T: "NNP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стола",
        E: "table, desk; board",
        T: "NGS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столов",
        E: "table, desk; board",
        T: "NGP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столу",
        E: "table, desk; board",
        T: "NDS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столам",
        E: "table, desk; board",
        T: "NDP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стол",
        E: "table, desk; board",
        T: "NAS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столы",
        E: "table, desk; board",
        T: "NAP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столом",
        E: "table, desk; board",
        T: "NIS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столами",
        E: "table, desk; board",
        T: "NIP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столе",
        E: "table, desk; board",
        T: "NPS",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "столах",
        E: "table, desk; board",
        T: "NPP",
        A: "I",
        O: "стол",
        RA: 163,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнок",
        E: "child, kid, infant",
        T: "NNS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дети",
        E: "child, kid, infant",
        T: "NNP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнка",
        E: "child, kid, infant",
        T: "NGS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "детей",
        E: "child, kid, infant",
        T: "NGP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнку",
        E: "child, kid, infant",
        T: "NDS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "детям",
        E: "child, kid, infant",
        T: "NDP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнка",
        E: "child, kid, infant",
        T: "NAS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "детей",
        E: "child, kid, infant",
        T: "NAP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнком",
        E: "child, kid, infant",
        T: "NIS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "детьми",
        E: "child, kid, infant",
        T: "NIP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ребёнке",
        E: "child, kid, infant",
        T: "NPS",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "детях",
        E: "child, kid, infant",
        T: "NPP",
        A: "A",
        O: "ребёнок",
        RA: 164,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сила",
        E: "strength, force",
        T: "NNS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силы",
        E: "strength, force",
        T: "NNP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силы",
        E: "strength, force",
        T: "NGS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сил",
        E: "strength, force",
        T: "NGP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силе",
        E: "strength, force",
        T: "NDS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силам",
        E: "strength, force",
        T: "NDP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силу",
        E: "strength, force",
        T: "NAS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силы",
        E: "strength, force",
        T: "NAP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силой",
        E: "strength, force",
        T: "NIS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силами",
        E: "strength, force",
        T: "NIP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силе",
        E: "strength, force",
        T: "NPS",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "силах",
        E: "strength, force",
        T: "NPP",
        A: "I",
        O: "сила",
        RA: 166,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "отец",
        E: "father",
        T: "NNS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцы",
        E: "father",
        T: "NNP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отца",
        E: "father",
        T: "NGS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцов",
        E: "father",
        T: "NGP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцу",
        E: "father",
        T: "NDS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцам",
        E: "father",
        T: "NDP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отца",
        E: "father",
        T: "NAS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцов",
        E: "father",
        T: "NAP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцом",
        E: "father",
        T: "NIS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцами",
        E: "father",
        T: "NIP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отце",
        E: "father",
        T: "NPS",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отцах",
        E: "father",
        T: "NPP",
        A: "A",
        O: "отец",
        RA: 167,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "женщина",
        E: "woman",
        T: "NNS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщины",
        E: "woman",
        T: "NNP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщины",
        E: "woman",
        T: "NGS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщин",
        E: "woman",
        T: "NGP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщине",
        E: "woman",
        T: "NDS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщинам",
        E: "woman",
        T: "NDP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщину",
        E: "woman",
        T: "NAS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщин",
        E: "woman",
        T: "NAP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщиной",
        E: "woman",
        T: "NIS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщинами",
        E: "woman",
        T: "NIP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщине",
        E: "woman",
        T: "NPS",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женщинах",
        E: "woman",
        T: "NPP",
        A: "A",
        O: "женщина",
        RA: 168,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машина",
        E: "car, machine, engine",
        T: "NNS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машины",
        E: "car, machine, engine",
        T: "NNP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машины",
        E: "car, machine, engine",
        T: "NGS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машин",
        E: "car, machine, engine",
        T: "NGP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машине",
        E: "car, machine, engine",
        T: "NDS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машинам",
        E: "car, machine, engine",
        T: "NDP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машину",
        E: "car, machine, engine",
        T: "NAS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машины",
        E: "car, machine, engine",
        T: "NAP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машиной",
        E: "car, machine, engine",
        T: "NIS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машинами",
        E: "car, machine, engine",
        T: "NIP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машине",
        E: "car, machine, engine",
        T: "NPS",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "машинах",
        E: "car, machine, engine",
        T: "NPP",
        A: "I",
        O: "машина",
        RA: 169,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "случай",
        E: "case, occasion, incident",
        T: "NNS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаи",
        E: "case, occasion, incident",
        T: "NNP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случая",
        E: "case, occasion, incident",
        T: "NGS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаев",
        E: "case, occasion, incident",
        T: "NGP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаю",
        E: "case, occasion, incident",
        T: "NDS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаям",
        E: "case, occasion, incident",
        T: "NDP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случай",
        E: "case, occasion, incident",
        T: "NAS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаи",
        E: "case, occasion, incident",
        T: "NAP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаем",
        E: "case, occasion, incident",
        T: "NIS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаями",
        E: "case, occasion, incident",
        T: "NIP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случае",
        E: "case, occasion, incident",
        T: "NPS",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "случаях",
        E: "case, occasion, incident",
        T: "NPP",
        A: "I",
        O: "случай",
        RA: 170,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ночь",
        E: "night",
        T: "NNS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночи",
        E: "night",
        T: "NNP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночи",
        E: "night",
        T: "NGS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночей",
        E: "night",
        T: "NGP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночи",
        E: "night",
        T: "NDS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночам",
        E: "night",
        T: "NDP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночь",
        E: "night",
        T: "NAS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночи",
        E: "night",
        T: "NAP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночью",
        E: "night",
        T: "NIS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночами",
        E: "night",
        T: "NIP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночи",
        E: "night",
        T: "NPS",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ночах",
        E: "night",
        T: "NPP",
        A: "I",
        O: "ночь",
        RA: 171,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мир",
        E: "world, peace",
        T: "NNS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "миры",
        E: "world, peace",
        T: "NNP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мира",
        E: "world, peace",
        T: "NGS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "миров",
        E: "world, peace",
        T: "NGP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "миру",
        E: "world, peace",
        T: "NDS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мирам",
        E: "world, peace",
        T: "NDP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мир",
        E: "world, peace",
        T: "NAS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "миры",
        E: "world, peace",
        T: "NAP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "миром",
        E: "world, peace",
        T: "NIS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мирами",
        E: "world, peace",
        T: "NIP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мире",
        E: "world, peace",
        T: "NPS",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мирах",
        E: "world, peace",
        T: "NPP",
        A: "I",
        O: "мир",
        RA: 173,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вид",
        E: "appearance, look, view",
        T: "NNS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "виды",
        E: "appearance, look, view",
        T: "NNP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вида",
        E: "appearance, look, view",
        T: "NGS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "видов",
        E: "appearance, look, view",
        T: "NGP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "виду",
        E: "appearance, look, view",
        T: "NDS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "видам",
        E: "appearance, look, view",
        T: "NDP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вид",
        E: "appearance, look, view",
        T: "NAS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "виды",
        E: "appearance, look, view",
        T: "NAP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "видом",
        E: "appearance, look, view",
        T: "NIS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "видами",
        E: "appearance, look, view",
        T: "NIP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "виде",
        E: "appearance, look, view",
        T: "NPS",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "видах",
        E: "appearance, look, view",
        T: "NPP",
        A: "I",
        O: "вид",
        RA: 177,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряд",
        E: "row, line",
        T: "NNS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряды",
        E: "row, line",
        T: "NNP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряда",
        E: "row, line",
        T: "NGS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рядов",
        E: "row, line",
        T: "NGP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряду",
        E: "row, line",
        T: "NDS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рядам",
        E: "row, line",
        T: "NDP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряд",
        E: "row, line",
        T: "NAS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряды",
        E: "row, line",
        T: "NAP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рядом",
        E: "row, line",
        T: "NIS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рядами",
        E: "row, line",
        T: "NIP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ряде",
        E: "row, line",
        T: "NPS",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рядах",
        E: "row, line",
        T: "NPP",
        A: "I",
        O: "ряд",
        RA: 184,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начало",
        E: "beginning, origin, source",
        T: "NNS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начала",
        E: "beginning, origin, source",
        T: "NNP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начала",
        E: "beginning, origin, source",
        T: "NGS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начал",
        E: "beginning, origin, source",
        T: "NGP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "началу",
        E: "beginning, origin, source",
        T: "NDS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "началам",
        E: "beginning, origin, source",
        T: "NDP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начало",
        E: "beginning, origin, source",
        T: "NAS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начала",
        E: "beginning, origin, source",
        T: "NAP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "началом",
        E: "beginning, origin, source",
        T: "NIS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "началами",
        E: "beginning, origin, source",
        T: "NIP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "начале",
        E: "beginning, origin, source",
        T: "NPS",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "началах",
        E: "beginning, origin, source",
        T: "NPP",
        A: "I",
        O: "начало",
        RA: 186,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вопрос",
        E: "question, matter, problem",
        T: "NNS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросы",
        E: "question, matter, problem",
        T: "NNP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопроса",
        E: "question, matter, problem",
        T: "NGS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросов",
        E: "question, matter, problem",
        T: "NGP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросу",
        E: "question, matter, problem",
        T: "NDS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросам",
        E: "question, matter, problem",
        T: "NDP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопрос",
        E: "question, matter, problem",
        T: "NAS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросы",
        E: "question, matter, problem",
        T: "NAP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросом",
        E: "question, matter, problem",
        T: "NIS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросами",
        E: "question, matter, problem",
        T: "NIP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросе",
        E: "question, matter, problem",
        T: "NPS",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вопросах",
        E: "question, matter, problem",
        T: "NPP",
        A: "I",
        O: "вопрос",
        RA: 188,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "война",
        E: "war",
        T: "NNS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войны",
        E: "war",
        T: "NNP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войны",
        E: "war",
        T: "NGS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войн",
        E: "war",
        T: "NGP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войне",
        E: "war",
        T: "NDS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войнам",
        E: "war",
        T: "NDP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войну",
        E: "war",
        T: "NAS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войны",
        E: "war",
        T: "NAP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войной",
        E: "war",
        T: "NIS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войнами",
        E: "war",
        T: "NIP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войне",
        E: "war",
        T: "NPS",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "войнах",
        E: "war",
        T: "NPP",
        A: "I",
        O: "война",
        RA: 190,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NNS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деньги",
        E: "money",
        T: "NNP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NGS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "денег",
        E: "money",
        T: "NGP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NDS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деньгам",
        E: "money",
        T: "NDP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NAS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деньги",
        E: "money",
        T: "NAP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NIS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деньгами",
        E: "money",
        T: "NIP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "money",
        T: "NPS",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деньгах",
        E: "money",
        T: "NPP",
        A: "I",
        O: "деньги",
        RA: 197,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "минута",
        E: "minute, moment",
        T: "NNS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуты",
        E: "minute, moment",
        T: "NNP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуты",
        E: "minute, moment",
        T: "NGS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минут",
        E: "minute, moment",
        T: "NGP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуте",
        E: "minute, moment",
        T: "NDS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минутам",
        E: "minute, moment",
        T: "NDP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуту",
        E: "minute, moment",
        T: "NAS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуты",
        E: "minute, moment",
        T: "NAP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минутой",
        E: "minute, moment",
        T: "NIS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минутами",
        E: "minute, moment",
        T: "NIP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минуте",
        E: "minute, moment",
        T: "NPS",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "минутах",
        E: "minute, moment",
        T: "NPP",
        A: "I",
        O: "минута",
        RA: 201,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жена",
        E: "wife",
        T: "NNS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жёны",
        E: "wife",
        T: "NNP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жены",
        E: "wife",
        T: "NGS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жён",
        E: "wife",
        T: "NGP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жене",
        E: "wife",
        T: "NDS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жёнам",
        E: "wife",
        T: "NDP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жену",
        E: "wife",
        T: "NAS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жён",
        E: "wife",
        T: "NAP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "женой",
        E: "wife",
        T: "NIS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жёнами",
        E: "wife",
        T: "NIP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жене",
        E: "wife",
        T: "NPS",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "жёнах",
        E: "wife",
        T: "NPP",
        A: "A",
        O: "жена",
        RA: 202,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правда",
        E: "truth",
        T: "NNS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правды",
        E: "truth",
        T: "NNP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правды",
        E: "truth",
        T: "NGS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правд",
        E: "truth",
        T: "NGP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правде",
        E: "truth",
        T: "NDS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правдам",
        E: "truth",
        T: "NDP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правду",
        E: "truth",
        T: "NAS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правды",
        E: "truth",
        T: "NAP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правдой",
        E: "truth",
        T: "NIS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правдами",
        E: "truth",
        T: "NIP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правде",
        E: "truth",
        T: "NPS",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "правдах",
        E: "truth",
        T: "NPP",
        A: "I",
        O: "правда",
        RA: 205,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страна",
        E: "country",
        T: "NNS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страны",
        E: "country",
        T: "NNP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страны",
        E: "country",
        T: "NGS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стран",
        E: "country",
        T: "NGP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стране",
        E: "country",
        T: "NDS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "странам",
        E: "country",
        T: "NDP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страну",
        E: "country",
        T: "NAS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страны",
        E: "country",
        T: "NAP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страной",
        E: "country",
        T: "NIS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "странами",
        E: "country",
        T: "NIP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стране",
        E: "country",
        T: "NPS",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "странах",
        E: "country",
        T: "NPP",
        A: "I",
        O: "страна",
        RA: 207,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свет",
        E: "light",
        T: "NNS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светы",
        E: "light",
        T: "NNP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "света",
        E: "light",
        T: "NGS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светов",
        E: "light",
        T: "NGP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "свету",
        E: "light",
        T: "NDS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светам",
        E: "light",
        T: "NDP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "свет",
        E: "light",
        T: "NAS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светы",
        E: "light",
        T: "NAP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светом",
        E: "light",
        T: "NIS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светами",
        E: "light",
        T: "NIP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "свете",
        E: "light",
        T: "NPS",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "светах",
        E: "light",
        T: "NPP",
        A: "I",
        O: "свет",
        RA: 208,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мать",
        E: "mother",
        T: "NNS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матери",
        E: "mother",
        T: "NNP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матери",
        E: "mother",
        T: "NGS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерей",
        E: "mother",
        T: "NGP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матери",
        E: "mother",
        T: "NDS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерям",
        E: "mother",
        T: "NDP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мать",
        E: "mother",
        T: "NAS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерей",
        E: "mother",
        T: "NAP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерью",
        E: "mother",
        T: "NIS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерями",
        E: "mother",
        T: "NIP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матери",
        E: "mother",
        T: "NPS",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "матерях",
        E: "mother",
        T: "NPP",
        A: "A",
        O: "мать",
        RA: 210,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "товарищ",
        E: "comrade, friend",
        T: "NNS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищи",
        E: "comrade, friend",
        T: "NNP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарища",
        E: "comrade, friend",
        T: "NGS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищей",
        E: "comrade, friend",
        T: "NGP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищу",
        E: "comrade, friend",
        T: "NDS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищам",
        E: "comrade, friend",
        T: "NDP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарища",
        E: "comrade, friend",
        T: "NAS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищей",
        E: "comrade, friend",
        T: "NAP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищем",
        E: "comrade, friend",
        T: "NIS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищами",
        E: "comrade, friend",
        T: "NIP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарище",
        E: "comrade, friend",
        T: "NPS",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "товарищах",
        E: "comrade, friend",
        T: "NPP",
        A: "A",
        O: "товарищ",
        RA: 213,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дорога",
        E: "road, way, journey",
        T: "NNS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дороги",
        E: "road, way, journey",
        T: "NNP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дороги",
        E: "road, way, journey",
        T: "NGS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорог",
        E: "road, way, journey",
        T: "NGP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дороге",
        E: "road, way, journey",
        T: "NDS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорогам",
        E: "road, way, journey",
        T: "NDP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорогу",
        E: "road, way, journey",
        T: "NAS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дороги",
        E: "road, way, journey",
        T: "NAP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорогой",
        E: "road, way, journey",
        T: "NIS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорогами",
        E: "road, way, journey",
        T: "NIP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дороге",
        E: "road, way, journey",
        T: "NPS",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дорогах",
        E: "road, way, journey",
        T: "NPP",
        A: "I",
        O: "дорога",
        RA: 214,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "окно",
        E: "window, windowsill",
        T: "NNS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окна",
        E: "window, windowsill",
        T: "NNP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окна",
        E: "window, windowsill",
        T: "NGS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окон",
        E: "window, windowsill",
        T: "NGP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окну",
        E: "window, windowsill",
        T: "NDS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окнам",
        E: "window, windowsill",
        T: "NDP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окно",
        E: "window, windowsill",
        T: "NAS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окна",
        E: "window, windowsill",
        T: "NAP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окном",
        E: "window, windowsill",
        T: "NIS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окнами",
        E: "window, windowsill",
        T: "NIP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окне",
        E: "window, windowsill",
        T: "NPS",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "окнах",
        E: "window, windowsill",
        T: "NPP",
        A: "I",
        O: "окно",
        RA: 218,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "комната",
        E: "a room",
        T: "NNS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнаты",
        E: "a room",
        T: "NNP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнаты",
        E: "a room",
        T: "NGS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнат",
        E: "a room",
        T: "NGP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнате",
        E: "a room",
        T: "NDS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнатам",
        E: "a room",
        T: "NDP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнату",
        E: "a room",
        T: "NAS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнаты",
        E: "a room",
        T: "NAP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнатой",
        E: "a room",
        T: "NIS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнатами",
        E: "a room",
        T: "NIP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнате",
        E: "a room",
        T: "NPS",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "комнатах",
        E: "a room",
        T: "NPP",
        A: "I",
        O: "комната",
        RA: 222,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "часть",
        E: "part, share, department",
        T: "NNS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "части",
        E: "part, share, department",
        T: "NNP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "части",
        E: "part, share, department",
        T: "NGS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "частей",
        E: "part, share, department",
        T: "NGP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "части",
        E: "part, share, department",
        T: "NDS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "частям",
        E: "part, share, department",
        T: "NDP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "часть",
        E: "part, share, department",
        T: "NAS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "части",
        E: "part, share, department",
        T: "NAP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "частью",
        E: "part, share, department",
        T: "NIS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "частями",
        E: "part, share, department",
        T: "NIP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "части",
        E: "part, share, department",
        T: "NPS",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "частях",
        E: "part, share, department",
        T: "NPP",
        A: "I",
        O: "часть",
        RA: 224,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книга",
        E: "a book",
        T: "NNS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книги",
        E: "a book",
        T: "NNP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книги",
        E: "a book",
        T: "NGS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книг",
        E: "a book",
        T: "NGP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книге",
        E: "a book",
        T: "NDS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книгам",
        E: "a book",
        T: "NDP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книгу",
        E: "a book",
        T: "NAS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книги",
        E: "a book",
        T: "NAP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книгой",
        E: "a book",
        T: "NIS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книгами",
        E: "a book",
        T: "NIP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книге",
        E: "a book",
        T: "NPS",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "книгах",
        E: "a book",
        T: "NPP",
        A: "I",
        O: "книга",
        RA: 226,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улица",
        E: "street",
        T: "NNS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицы",
        E: "street",
        T: "NNP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицы",
        E: "street",
        T: "NGS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улиц",
        E: "street",
        T: "NGP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улице",
        E: "street",
        T: "NDS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицам",
        E: "street",
        T: "NDP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицу",
        E: "street",
        T: "NAS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицы",
        E: "street",
        T: "NAP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицей",
        E: "street",
        T: "NIS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицами",
        E: "street",
        T: "NIP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улице",
        E: "street",
        T: "NPS",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улицах",
        E: "street",
        T: "NPP",
        A: "I",
        O: "улица",
        RA: 228,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душа",
        E: "soul, spirit",
        T: "NNS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "души",
        E: "soul, spirit",
        T: "NNP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "души",
        E: "soul, spirit",
        T: "NGS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душ",
        E: "soul, spirit",
        T: "NGP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душе",
        E: "soul, spirit",
        T: "NDS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душам",
        E: "soul, spirit",
        T: "NDP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душу",
        E: "soul, spirit",
        T: "NAS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "души",
        E: "soul, spirit",
        T: "NAP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душой",
        E: "soul, spirit",
        T: "NIS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душами",
        E: "soul, spirit",
        T: "NIP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душе",
        E: "soul, spirit",
        T: "NPS",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "душах",
        E: "soul, spirit",
        T: "NPP",
        A: "I",
        O: "душа",
        RA: 231,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "утро",
        E: "morning",
        T: "NNS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утра",
        E: "morning",
        T: "NNP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утра",
        E: "morning",
        T: "NGS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утр",
        E: "morning",
        T: "NGP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утру",
        E: "morning",
        T: "NDS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утрам",
        E: "morning",
        T: "NDP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утро",
        E: "morning",
        T: "NAS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утра",
        E: "morning",
        T: "NAP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утром",
        E: "morning",
        T: "NIS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утрами",
        E: "morning",
        T: "NIP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утре",
        E: "morning",
        T: "NPS",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "утрах",
        E: "morning",
        T: "NPP",
        A: "I",
        O: "утро",
        RA: 234,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вечер",
        E: "evening",
        T: "NNS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечера",
        E: "evening",
        T: "NNP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечера",
        E: "evening",
        T: "NGS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечеров",
        E: "evening",
        T: "NGP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечеру",
        E: "evening",
        T: "NDS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечерам",
        E: "evening",
        T: "NDP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечер",
        E: "evening",
        T: "NAS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечера",
        E: "evening",
        T: "NAP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечером",
        E: "evening",
        T: "NIS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечерами",
        E: "evening",
        T: "NIP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечере",
        E: "evening",
        T: "NPS",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вечерах",
        E: "evening",
        T: "NPP",
        A: "I",
        O: "вечер",
        RA: 239,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пол",
        E: "floor",
        T: "NNS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полы",
        E: "floor",
        T: "NNP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пола",
        E: "floor",
        T: "NGS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полов",
        E: "floor",
        T: "NGP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полу",
        E: "floor",
        T: "NDS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полам",
        E: "floor",
        T: "NDP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пол",
        E: "floor",
        T: "NAS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полы",
        E: "floor",
        T: "NAP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полом",
        E: "floor",
        T: "NIS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полами",
        E: "floor",
        T: "NIP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полу",
        E: "floor",
        T: "NPS",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полах",
        E: "floor",
        T: "NPP",
        A: "I",
        O: "пол",
        RA: 240,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народ",
        E: "people, nation",
        T: "NNS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народы",
        E: "people, nation",
        T: "NNP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народа",
        E: "people, nation",
        T: "NGS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народов",
        E: "people, nation",
        T: "NGP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народу",
        E: "people, nation",
        T: "NDS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народам",
        E: "people, nation",
        T: "NDP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народ",
        E: "people, nation",
        T: "NAS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народы",
        E: "people, nation",
        T: "NAP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народом",
        E: "people, nation",
        T: "NIS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народами",
        E: "people, nation",
        T: "NIP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народе",
        E: "people, nation",
        T: "NPS",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "народах",
        E: "people, nation",
        T: "NPP",
        A: "I",
        O: "народ",
        RA: 243,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "плечо",
        E: "shoulder, upper arm",
        T: "NNS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечи",
        E: "shoulder, upper arm",
        T: "NNP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плеча",
        E: "shoulder, upper arm",
        T: "NGS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плеч",
        E: "shoulder, upper arm",
        T: "NGP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечу",
        E: "shoulder, upper arm",
        T: "NDS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечам",
        E: "shoulder, upper arm",
        T: "NDP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечо",
        E: "shoulder, upper arm",
        T: "NAS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечи",
        E: "shoulder, upper arm",
        T: "NAP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечом",
        E: "shoulder, upper arm",
        T: "NIS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечами",
        E: "shoulder, upper arm",
        T: "NIP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плече",
        E: "shoulder, upper arm",
        T: "NPS",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "плечах",
        E: "shoulder, upper arm",
        T: "NPP",
        A: "I",
        O: "плечо",
        RA: 244,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "бог",
        E: "god",
        T: "NNS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боги",
        E: "god",
        T: "NNP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бога",
        E: "god",
        T: "NGS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богов",
        E: "god",
        T: "NGP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богу",
        E: "god",
        T: "NDS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богам",
        E: "god",
        T: "NDP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бога",
        E: "god",
        T: "NAS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богов",
        E: "god",
        T: "NAP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богом",
        E: "god",
        T: "NIS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богами",
        E: "god",
        T: "NIP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боге",
        E: "god",
        T: "NPS",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "богах",
        E: "god",
        T: "NPP",
        A: "A",
        O: "бог",
        RA: 247,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляд",
        E: "look, glance; view",
        T: "NNS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляды",
        E: "look, glance; view",
        T: "NNP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляда",
        E: "look, glance; view",
        T: "NGS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взглядов",
        E: "look, glance; view",
        T: "NGP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляду",
        E: "look, glance; view",
        T: "NDS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взглядам",
        E: "look, glance; view",
        T: "NDP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляд",
        E: "look, glance; view",
        T: "NAS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляды",
        E: "look, glance; view",
        T: "NAP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взглядом",
        E: "look, glance; view",
        T: "NIS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взглядами",
        E: "look, glance; view",
        T: "NIP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взгляде",
        E: "look, glance; view",
        T: "NPS",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "взглядах",
        E: "look, glance; view",
        T: "NPP",
        A: "I",
        O: "взгляд",
        RA: 249,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "палец",
        E: "finger, toe",
        T: "NNS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцы",
        E: "finger, toe",
        T: "NNP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальца",
        E: "finger, toe",
        T: "NGS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцев",
        E: "finger, toe",
        T: "NGP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцу",
        E: "finger, toe",
        T: "NDS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцам",
        E: "finger, toe",
        T: "NDP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "палец",
        E: "finger, toe",
        T: "NAS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцы",
        E: "finger, toe",
        T: "NAP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцем",
        E: "finger, toe",
        T: "NIS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцами",
        E: "finger, toe",
        T: "NIP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальце",
        E: "finger, toe",
        T: "NPS",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пальцах",
        E: "finger, toe",
        T: "NPP",
        A: "I",
        O: "палец",
        RA: 257,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "история",
        E: "history, story, event",
        T: "NNS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "истории",
        E: "history, story, event",
        T: "NNP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "истории",
        E: "history, story, event",
        T: "NGS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историй",
        E: "history, story, event",
        T: "NGP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "истории",
        E: "history, story, event",
        T: "NDS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историям",
        E: "history, story, event",
        T: "NDP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историю",
        E: "history, story, event",
        T: "NAS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "истории",
        E: "history, story, event",
        T: "NAP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историей",
        E: "history, story, event",
        T: "NIS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историями",
        E: "history, story, event",
        T: "NIP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "истории",
        E: "history, story, event",
        T: "NPS",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "историях",
        E: "history, story, event",
        T: "NPP",
        A: "I",
        O: "история",
        RA: 260,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысль",
        E: "thought, idea",
        T: "NNS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысли",
        E: "thought, idea",
        T: "NNP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысли",
        E: "thought, idea",
        T: "NGS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мыслей",
        E: "thought, idea",
        T: "NGP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысли",
        E: "thought, idea",
        T: "NDS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мыслям",
        E: "thought, idea",
        T: "NDP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысль",
        E: "thought, idea",
        T: "NAS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысли",
        E: "thought, idea",
        T: "NAP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мыслью",
        E: "thought, idea",
        T: "NIS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мыслями",
        E: "thought, idea",
        T: "NIP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мысли",
        E: "thought, idea",
        T: "NPS",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мыслях",
        E: "thought, idea",
        T: "NPP",
        A: "I",
        O: "мысль",
        RA: 262,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сын",
        E: "son",
        T: "NNS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновья",
        E: "son",
        T: "NNP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сына",
        E: "son",
        T: "NGS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновей",
        E: "son",
        T: "NGP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыну",
        E: "son",
        T: "NDS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновьям",
        E: "son",
        T: "NDP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сына",
        E: "son",
        T: "NAS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновей",
        E: "son",
        T: "NAP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыном",
        E: "son",
        T: "NIS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновьями",
        E: "son",
        T: "NIP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыне",
        E: "son",
        T: "NPS",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сыновьях",
        E: "son",
        T: "NPP",
        A: "A",
        O: "сын",
        RA: 275,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лес",
        E: "forest",
        T: "NNS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "леса",
        E: "forest",
        T: "NNP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "леса",
        E: "forest",
        T: "NGS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесов",
        E: "forest",
        T: "NGP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесу",
        E: "forest",
        T: "NDS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесам",
        E: "forest",
        T: "NDP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лес",
        E: "forest",
        T: "NAS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "леса",
        E: "forest",
        T: "NAP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесом",
        E: "forest",
        T: "NIS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесами",
        E: "forest",
        T: "NIP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "о лесе",
        E: "forest",
        T: "NPS",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лесах",
        E: "forest",
        T: "NPP",
        A: "I",
        O: "лес",
        RA: 280,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пора",
        E: "time",
        T: "NNS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поры",
        E: "time",
        T: "NNP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поры",
        E: "time",
        T: "NGS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пор",
        E: "time",
        T: "NGP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поре",
        E: "time",
        T: "NDS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "порам",
        E: "time",
        T: "NDP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пору",
        E: "time",
        T: "NAS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поры",
        E: "time",
        T: "NAP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "порой",
        E: "time",
        T: "NIS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "порами",
        E: "time",
        T: "NIP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поре",
        E: "time",
        T: "NPS",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "порах",
        E: "time",
        T: "NPP",
        A: "I",
        O: "пора",
        RA: 282,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "имя",
        E: "name",
        T: "NNS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имена",
        E: "name",
        T: "NNP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имени",
        E: "name",
        T: "NGS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имён",
        E: "name",
        T: "NGP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имени",
        E: "name",
        T: "NDS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "именам",
        E: "name",
        T: "NDP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имя",
        E: "name",
        T: "NAS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имена",
        E: "name",
        T: "NAP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "именем",
        E: "name",
        T: "NIS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "именами",
        E: "name",
        T: "NIP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "имени",
        E: "name",
        T: "NPS",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "именах",
        E: "name",
        T: "NPP",
        A: "I",
        O: "имя",
        RA: 287,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "разговор",
        E: "talk, conversation",
        T: "NNS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговоры",
        E: "talk, conversation",
        T: "NNP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговора",
        E: "talk, conversation",
        T: "NGS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговоров",
        E: "talk, conversation",
        T: "NGP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговору",
        E: "talk, conversation",
        T: "NDS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговорам",
        E: "talk, conversation",
        T: "NDP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговор",
        E: "talk, conversation",
        T: "NAS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговоры",
        E: "talk, conversation",
        T: "NAP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговором",
        E: "talk, conversation",
        T: "NIS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговорами",
        E: "talk, conversation",
        T: "NIP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговоре",
        E: "talk, conversation",
        T: "NPS",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "разговорах",
        E: "talk, conversation",
        T: "NPP",
        A: "I",
        O: "разговор",
        RA: 289,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "тело",
        E: "body",
        T: "NNS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тела",
        E: "body",
        T: "NNP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тела",
        E: "body",
        T: "NGS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тел",
        E: "body",
        T: "NGP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "телу",
        E: "body",
        T: "NDS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "телам",
        E: "body",
        T: "NDP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тело",
        E: "body",
        T: "NAS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тела",
        E: "body",
        T: "NAP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "телом",
        E: "body",
        T: "NIS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "телами",
        E: "body",
        T: "NIP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "теле",
        E: "body",
        T: "NPS",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "телах",
        E: "body",
        T: "NPP",
        A: "I",
        O: "тело",
        RA: 290,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стена",
        E: "wall",
        T: "NNS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стены",
        E: "wall",
        T: "NNP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стены",
        E: "wall",
        T: "NGS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стен",
        E: "wall",
        T: "NGP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стене",
        E: "wall",
        T: "NDS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стенам",
        E: "wall",
        T: "NDP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стену",
        E: "wall",
        T: "NAS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стены",
        E: "wall",
        T: "NAP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стеной",
        E: "wall",
        T: "NIS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стенами",
        E: "wall",
        T: "NIP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стене",
        E: "wall",
        T: "NPS",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стенах",
        E: "wall",
        T: "NPP",
        A: "I",
        O: "стена",
        RA: 292,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "право",
        E: "right",
        T: "NNS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "права",
        E: "right",
        T: "NNP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "права",
        E: "right",
        T: "NGS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "прав",
        E: "right",
        T: "NGP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "праву",
        E: "right",
        T: "NDS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правам",
        E: "right",
        T: "NDP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "право",
        E: "right",
        T: "NAS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "права",
        E: "right",
        T: "NAP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правом",
        E: "right",
        T: "NIS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правами",
        E: "right",
        T: "NIP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "праве",
        E: "right",
        T: "NPS",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правах",
        E: "right",
        T: "NPP",
        A: "I",
        O: "право",
        RA: 295,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "старик",
        E: "old man",
        T: "NNS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старики",
        E: "old man",
        T: "NNP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старика",
        E: "old man",
        T: "NGS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стариков",
        E: "old man",
        T: "NGP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старику",
        E: "old man",
        T: "NDS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старикам",
        E: "old man",
        T: "NDP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старика",
        E: "old man",
        T: "NAS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стариков",
        E: "old man",
        T: "NAP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стариком",
        E: "old man",
        T: "NIS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стариками",
        E: "old man",
        T: "NIP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "старике",
        E: "old man",
        T: "NPS",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стариках",
        E: "old man",
        T: "NPP",
        A: "A",
        O: "старик",
        RA: 296,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мама",
        E: "mummy, mum",
        T: "NNS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамы",
        E: "mummy, mum",
        T: "NNP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамы",
        E: "mummy, mum",
        T: "NGS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мам",
        E: "mummy, mum",
        T: "NGP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "маме",
        E: "mummy, mum",
        T: "NDS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамам",
        E: "mummy, mum",
        T: "NDP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "маму",
        E: "mummy, mum",
        T: "NAS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мам",
        E: "mummy, mum",
        T: "NAP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамой",
        E: "mummy, mum",
        T: "NIS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамами",
        E: "mummy, mum",
        T: "NIP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "маме",
        E: "mummy, mum",
        T: "NPS",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мамах",
        E: "mummy, mum",
        T: "NPP",
        A: "A",
        O: "мама",
        RA: 299,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "путь",
        E: "way, track, path",
        T: "NNS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пути",
        E: "way, track, path",
        T: "NNP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пути",
        E: "way, track, path",
        T: "NGS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путей",
        E: "way, track, path",
        T: "NGP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пути",
        E: "way, track, path",
        T: "NDS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путям",
        E: "way, track, path",
        T: "NDP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путь",
        E: "way, track, path",
        T: "NAS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пути",
        E: "way, track, path",
        T: "NAP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путём",
        E: "way, track, path",
        T: "NIS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путями",
        E: "way, track, path",
        T: "NIP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пути",
        E: "way, track, path",
        T: "NPS",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "путях",
        E: "way, track, path",
        T: "NPP",
        A: "I",
        O: "путь",
        RA: 302,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяц",
        E: "month",
        T: "NNS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцы",
        E: "month",
        T: "NNP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяца",
        E: "month",
        T: "NGS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцев",
        E: "month",
        T: "NGP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцу",
        E: "month",
        T: "NDS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцам",
        E: "month",
        T: "NDP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяц",
        E: "month",
        T: "NAS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцы",
        E: "month",
        T: "NAP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцем",
        E: "month",
        T: "NIS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцами",
        E: "month",
        T: "NIP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяце",
        E: "month",
        T: "NPS",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "месяцах",
        E: "month",
        T: "NPP",
        A: "I",
        O: "месяц",
        RA: 308,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "спина",
        E: "back",
        T: "NNS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спины",
        E: "back",
        T: "NNP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спины",
        E: "back",
        T: "NGS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спин",
        E: "back",
        T: "NGP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спине",
        E: "back",
        T: "NDS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спинам",
        E: "back",
        T: "NDP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спину",
        E: "back",
        T: "NAS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спины",
        E: "back",
        T: "NAP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спиной",
        E: "back",
        T: "NIS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спинами",
        E: "back",
        T: "NIP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спине",
        E: "back",
        T: "NPS",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "спинах",
        E: "back",
        T: "NPP",
        A: "I",
        O: "спина",
        RA: 314,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "язык",
        E: "tongue, language",
        T: "NNS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языки",
        E: "tongue, language",
        T: "NNP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языка",
        E: "tongue, language",
        T: "NGS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языков",
        E: "tongue, language",
        T: "NGP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языку",
        E: "tongue, language",
        T: "NDS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языкам",
        E: "tongue, language",
        T: "NDP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "язык",
        E: "tongue, language",
        T: "NAS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языки",
        E: "tongue, language",
        T: "NAP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языком",
        E: "tongue, language",
        T: "NIS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языками",
        E: "tongue, language",
        T: "NIP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языке",
        E: "tongue, language",
        T: "NPS",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "языках",
        E: "tongue, language",
        T: "NPP",
        A: "I",
        O: "язык",
        RA: 317,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сердце",
        E: "heart",
        T: "NNS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердца",
        E: "heart",
        T: "NNP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердца",
        E: "heart",
        T: "NGS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердец",
        E: "heart",
        T: "NGP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердцу",
        E: "heart",
        T: "NDS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердцам",
        E: "heart",
        T: "NDP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердце",
        E: "heart",
        T: "NAS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердца",
        E: "heart",
        T: "NAP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердцем",
        E: "heart",
        T: "NIS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердцами",
        E: "heart",
        T: "NIP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердце",
        E: "heart",
        T: "NPS",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сердцах",
        E: "heart",
        T: "NPP",
        A: "I",
        O: "сердце",
        RA: 321,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мальчик",
        E: "boy",
        T: "NNS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчики",
        E: "boy",
        T: "NNP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчика",
        E: "boy",
        T: "NGS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчиков",
        E: "boy",
        T: "NGP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчику",
        E: "boy",
        T: "NDS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчикам",
        E: "boy",
        T: "NDP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчика",
        E: "boy",
        T: "NAS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчиков",
        E: "boy",
        T: "NAP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчиком",
        E: "boy",
        T: "NIS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчиками",
        E: "boy",
        T: "NIP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчике",
        E: "boy",
        T: "NPS",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мальчиках",
        E: "boy",
        T: "NPP",
        A: "A",
        O: "мальчик",
        RA: 324,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "небо",
        E: "sky",
        T: "NNS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небеса",
        E: "sky",
        T: "NNP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "неба",
        E: "sky",
        T: "NGS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небес",
        E: "sky",
        T: "NGP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небу",
        E: "sky",
        T: "NDS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небесам",
        E: "sky",
        T: "NDP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небо",
        E: "sky",
        T: "NAS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небеса",
        E: "sky",
        T: "NAP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небом",
        E: "sky",
        T: "NIS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небесами",
        E: "sky",
        T: "NIP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небе",
        E: "sky",
        T: "NPS",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "небесах",
        E: "sky",
        T: "NPP",
        A: "I",
        O: "небо",
        RA: 326,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "смерть",
        E: "death",
        T: "NNS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерти",
        E: "death",
        T: "NNP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерти",
        E: "death",
        T: "NGS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смертей",
        E: "death",
        T: "NGP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерти",
        E: "death",
        T: "NDS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смертям",
        E: "death",
        T: "NDP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерть",
        E: "death",
        T: "NAS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерти",
        E: "death",
        T: "NAP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смертью",
        E: "death",
        T: "NIS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смертями",
        E: "death",
        T: "NIP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смерти",
        E: "death",
        T: "NPS",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "смертях",
        E: "death",
        T: "NPP",
        A: "I",
        O: "смерть",
        RA: 328,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушка",
        E: "girl, miss",
        T: "NNS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушки",
        E: "girl, miss",
        T: "NNP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушки",
        E: "girl, miss",
        T: "NGS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушек",
        E: "girl, miss",
        T: "NGP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушке",
        E: "girl, miss",
        T: "NDS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушкам",
        E: "girl, miss",
        T: "NDP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушку",
        E: "girl, miss",
        T: "NAS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушек",
        E: "girl, miss",
        T: "NAP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушкой",
        E: "girl, miss",
        T: "NIS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушками",
        E: "girl, miss",
        T: "NIP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушке",
        E: "girl, miss",
        T: "NPS",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девушках",
        E: "girl, miss",
        T: "NPP",
        A: "A",
        O: "девушка",
        RA: 330,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "образ",
        E: "shape, form, image",
        T: "NNS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образы",
        E: "shape, form, image",
        T: "NNP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образа",
        E: "shape, form, image",
        T: "NGS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образов",
        E: "shape, form, image",
        T: "NGP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образу",
        E: "shape, form, image",
        T: "NDS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образам",
        E: "shape, form, image",
        T: "NDP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образ",
        E: "shape, form, image",
        T: "NAS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образы",
        E: "shape, form, image",
        T: "NAP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образом",
        E: "shape, form, image",
        T: "NIS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образами",
        E: "shape, form, image",
        T: "NIP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образе",
        E: "shape, form, image",
        T: "NPS",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "образах",
        E: "shape, form, image",
        T: "NPP",
        A: "I",
        O: "образ",
        RA: 331,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "письмо",
        E: "letter",
        T: "NNS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письма",
        E: "letter",
        T: "NNP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письма",
        E: "letter",
        T: "NGS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "писем",
        E: "letter",
        T: "NGP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письму",
        E: "letter",
        T: "NDS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письмам",
        E: "letter",
        T: "NDP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письмо",
        E: "letter",
        T: "NAS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письма",
        E: "letter",
        T: "NAP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письмом",
        E: "letter",
        T: "NIS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письмами",
        E: "letter",
        T: "NIP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письме",
        E: "letter",
        T: "NPS",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "письмах",
        E: "letter",
        T: "NPP",
        A: "I",
        O: "письмо",
        RA: 335,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "власть",
        E: "power",
        T: "NNS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власти",
        E: "power",
        T: "NNP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власти",
        E: "power",
        T: "NGS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "властей",
        E: "power",
        T: "NGP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власти",
        E: "power",
        T: "NDS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "властям",
        E: "power",
        T: "NDP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власть",
        E: "power",
        T: "NAS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власти",
        E: "power",
        T: "NAP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "властью",
        E: "power",
        T: "NIS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "властями",
        E: "power",
        T: "NIP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "власти",
        E: "power",
        T: "NPS",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "властях",
        E: "power",
        T: "NPP",
        A: "I",
        O: "власть",
        RA: 336,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воздух",
        E: "air",
        T: "NNS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NNP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воздуха",
        E: "air",
        T: "NGS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NGP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воздуху",
        E: "air",
        T: "NDS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NDP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воздух",
        E: "air",
        T: "NAS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NAP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воздухом",
        E: "air",
        T: "NIS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NIP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воздухе",
        E: "air",
        T: "NPS",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "air",
        T: "NPP",
        A: "I",
        O: "воздух",
        RA: 340,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "брат",
        E: "brother",
        T: "NNS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братья",
        E: "brother",
        T: "NNP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "брата",
        E: "brother",
        T: "NGS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братьев",
        E: "brother",
        T: "NGP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "брату",
        E: "brother",
        T: "NDS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братьям",
        E: "brother",
        T: "NDP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "брата",
        E: "brother",
        T: "NAS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братьев",
        E: "brother",
        T: "NAP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братом",
        E: "brother",
        T: "NIS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братьями",
        E: "brother",
        T: "NIP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "брате",
        E: "brother",
        T: "NPS",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "братьях",
        E: "brother",
        T: "NPP",
        A: "A",
        O: "брат",
        RA: 344,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "отношение",
        E: "relationship, attitude",
        T: "NNS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношения",
        E: "relationship, attitude",
        T: "NNP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношения",
        E: "relationship, attitude",
        T: "NGS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношений",
        E: "relationship, attitude",
        T: "NGP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношению",
        E: "relationship, attitude",
        T: "NDS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношениям",
        E: "relationship, attitude",
        T: "NDP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношение",
        E: "relationship, attitude",
        T: "NAS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношения",
        E: "relationship, attitude",
        T: "NAP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношением",
        E: "relationship, attitude",
        T: "NIS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношениями",
        E: "relationship, attitude",
        T: "NIP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношении",
        E: "relationship, attitude",
        T: "NPS",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "отношениях",
        E: "relationship, attitude",
        T: "NPP",
        A: "I",
        O: "отношение",
        RA: 346,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "система",
        E: "system",
        T: "NNS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системы",
        E: "system",
        T: "NNP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системы",
        E: "system",
        T: "NGS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "систем",
        E: "system",
        T: "NGP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системе",
        E: "system",
        T: "NDS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системам",
        E: "system",
        T: "NDP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "систему",
        E: "system",
        T: "NAS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системы",
        E: "system",
        T: "NAP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системой",
        E: "system",
        T: "NIS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системами",
        E: "system",
        T: "NIP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системе",
        E: "system",
        T: "NPS",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "системах",
        E: "system",
        T: "NPP",
        A: "I",
        O: "система",
        RA: 351,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартира",
        E: "flat, apartment",
        T: "NNS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартиры",
        E: "flat, apartment",
        T: "NNP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартиры",
        E: "flat, apartment",
        T: "NGS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартир",
        E: "flat, apartment",
        T: "NGP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартире",
        E: "flat, apartment",
        T: "NDS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартирам",
        E: "flat, apartment",
        T: "NDP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартиру",
        E: "flat, apartment",
        T: "NAS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартиры",
        E: "flat, apartment",
        T: "NAP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартирой",
        E: "flat, apartment",
        T: "NIS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартирами",
        E: "flat, apartment",
        T: "NIP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартире",
        E: "flat, apartment",
        T: "NPS",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "квартирах",
        E: "flat, apartment",
        T: "NPP",
        A: "I",
        O: "квартира",
        RA: 353,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любовь",
        E: "love",
        T: "NNS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любви",
        E: "love",
        T: "NNP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любви",
        E: "love",
        T: "NGS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любвей",
        E: "love",
        T: "NGP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любви",
        E: "love",
        T: "NDS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любвям",
        E: "love",
        T: "NDP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любовь",
        E: "love",
        T: "NAS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любви",
        E: "love",
        T: "NAP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любовью",
        E: "love",
        T: "NIS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любвями",
        E: "love",
        T: "NIP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любви",
        E: "love",
        T: "NPS",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "любвях",
        E: "love",
        T: "NPP",
        A: "I",
        O: "любовь",
        RA: 356,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "солдат",
        E: "soldier",
        T: "NNS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдаты",
        E: "soldier",
        T: "NNP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдата",
        E: "soldier",
        T: "NGS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдат",
        E: "soldier",
        T: "NGP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдату",
        E: "soldier",
        T: "NDS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдатам",
        E: "soldier",
        T: "NDP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдата",
        E: "soldier",
        T: "NAS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдат",
        E: "soldier",
        T: "NAP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдатом",
        E: "soldier",
        T: "NIS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдатами",
        E: "soldier",
        T: "NIP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдате",
        E: "soldier",
        T: "NPS",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "солдатах",
        E: "soldier",
        T: "NPP",
        A: "A",
        O: "солдат",
        RA: 357,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяин",
        E: "master, boss, host",
        T: "NNS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяева",
        E: "master, boss, host",
        T: "NNP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяина",
        E: "master, boss, host",
        T: "NGS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяев",
        E: "master, boss, host",
        T: "NGP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяину",
        E: "master, boss, host",
        T: "NDS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяевам",
        E: "master, boss, host",
        T: "NDP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяина",
        E: "master, boss, host",
        T: "NAS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяев",
        E: "master, boss, host",
        T: "NAP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяином",
        E: "master, boss, host",
        T: "NIS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяевами",
        E: "master, boss, host",
        T: "NIP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяине",
        E: "master, boss, host",
        T: "NPS",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хозяевах",
        E: "master, boss, host",
        T: "NPP",
        A: "A",
        O: "хозяин",
        RA: 362,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальник",
        E: "chief, head, superior",
        T: "NNS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальники",
        E: "chief, head, superior",
        T: "NNP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальника",
        E: "chief, head, superior",
        T: "NGS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальников",
        E: "chief, head, superior",
        T: "NGP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальнику",
        E: "chief, head, superior",
        T: "NDS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальникам",
        E: "chief, head, superior",
        T: "NDP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальника",
        E: "chief, head, superior",
        T: "NAS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальников",
        E: "chief, head, superior",
        T: "NAP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальником",
        E: "chief, head, superior",
        T: "NIS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальниками",
        E: "chief, head, superior",
        T: "NIP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальнике",
        E: "chief, head, superior",
        T: "NPS",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "начальниках",
        E: "chief, head, superior",
        T: "NPP",
        A: "A",
        O: "начальник",
        RA: 369,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "школа",
        E: "school",
        T: "NNS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школы",
        E: "school",
        T: "NNP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школы",
        E: "school",
        T: "NGS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школ",
        E: "school",
        T: "NGP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школе",
        E: "school",
        T: "NDS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школам",
        E: "school",
        T: "NDP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школу",
        E: "school",
        T: "NAS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школы",
        E: "school",
        T: "NAP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школой",
        E: "school",
        T: "NIS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школами",
        E: "school",
        T: "NIP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школе",
        E: "school",
        T: "NPS",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "школах",
        E: "school",
        T: "NPP",
        A: "I",
        O: "школа",
        RA: 372,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "парень",
        E: "boy, fellow, guy",
        T: "NNS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парни",
        E: "boy, fellow, guy",
        T: "NNP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парня",
        E: "boy, fellow, guy",
        T: "NGS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парней",
        E: "boy, fellow, guy",
        T: "NGP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парню",
        E: "boy, fellow, guy",
        T: "NDS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парням",
        E: "boy, fellow, guy",
        T: "NDP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парня",
        E: "boy, fellow, guy",
        T: "NAS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парней",
        E: "boy, fellow, guy",
        T: "NAP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парнем",
        E: "boy, fellow, guy",
        T: "NIS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парнями",
        E: "boy, fellow, guy",
        T: "NIP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парне",
        E: "boy, fellow, guy",
        T: "NPS",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "парнях",
        E: "boy, fellow, guy",
        T: "NPP",
        A: "A",
        O: "парень",
        RA: 373,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кровь",
        E: "blood",
        T: "NNS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крови",
        E: "blood",
        T: "NNP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крови",
        E: "blood",
        T: "NGS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровей",
        E: "blood",
        T: "NGP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крови",
        E: "blood",
        T: "NDS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровям",
        E: "blood",
        T: "NDP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровь",
        E: "blood",
        T: "NAS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крови",
        E: "blood",
        T: "NAP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровью",
        E: "blood",
        T: "NIS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровями",
        E: "blood",
        T: "NIP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крови",
        E: "blood",
        T: "NPS",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровях",
        E: "blood",
        T: "NPP",
        A: "I",
        O: "кровь",
        RA: 374,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "солнце",
        E: "sun",
        T: "NNS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнца",
        E: "sun",
        T: "NNP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнца",
        E: "sun",
        T: "NGS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнц",
        E: "sun",
        T: "NGP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнцу",
        E: "sun",
        T: "NDS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнцам",
        E: "sun",
        T: "NDP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнце",
        E: "sun",
        T: "NAS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнца",
        E: "sun",
        T: "NAP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнцем",
        E: "sun",
        T: "NIS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнцами",
        E: "sun",
        T: "NIP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнце",
        E: "sun",
        T: "NPS",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "солнцах",
        E: "sun",
        T: "NPP",
        A: "I",
        O: "солнце",
        RA: 376,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "неделя",
        E: "week",
        T: "NNS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "недели",
        E: "week",
        T: "NNP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "недели",
        E: "week",
        T: "NGS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "недель",
        E: "week",
        T: "NGP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделе",
        E: "week",
        T: "NDS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделям",
        E: "week",
        T: "NDP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделю",
        E: "week",
        T: "NAS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "недели",
        E: "week",
        T: "NAP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделей",
        E: "week",
        T: "NIS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделями",
        E: "week",
        T: "NIP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделе",
        E: "week",
        T: "NPS",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "неделях",
        E: "week",
        T: "NPP",
        A: "I",
        O: "неделя",
        RA: 377,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NNS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребята",
        E: "guys, children",
        T: "NNP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NGS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребят",
        E: "guys, children",
        T: "NGP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NDS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребятам",
        E: "guys, children",
        T: "NDP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NAS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребят",
        E: "guys, children",
        T: "NAP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NIS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребятами",
        E: "guys, children",
        T: "NIP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "guys, children",
        T: "NPS",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ребятах",
        E: "guys, children",
        T: "NPP",
        A: "A",
        O: "ребята",
        RA: 380,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "шаг",
        E: "step",
        T: "NNS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шаги",
        E: "step",
        T: "NNP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шага",
        E: "step",
        T: "NGS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагов",
        E: "step",
        T: "NGP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагу",
        E: "step",
        T: "NDS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагам",
        E: "step",
        T: "NDP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шаг",
        E: "step",
        T: "NAS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шаги",
        E: "step",
        T: "NAP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагом",
        E: "step",
        T: "NIS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагами",
        E: "step",
        T: "NIP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шаге",
        E: "step",
        T: "NPS",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шагах",
        E: "step",
        T: "NPP",
        A: "I",
        O: "шаг",
        RA: 384,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчина",
        E: "man, male",
        T: "NNS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчины",
        E: "man, male",
        T: "NNP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчины",
        E: "man, male",
        T: "NGS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчин",
        E: "man, male",
        T: "NGP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчине",
        E: "man, male",
        T: "NDS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчинам",
        E: "man, male",
        T: "NDP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчину",
        E: "man, male",
        T: "NAS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчин",
        E: "man, male",
        T: "NAP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчиной",
        E: "man, male",
        T: "NIS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчинами",
        E: "man, male",
        T: "NIP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчине",
        E: "man, male",
        T: "NPS",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужчинах",
        E: "man, male",
        T: "NPP",
        A: "A",
        O: "мужчина",
        RA: 385,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "нос",
        E: "nose",
        T: "NNS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носы",
        E: "nose",
        T: "NNP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носа",
        E: "nose",
        T: "NGS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носов",
        E: "nose",
        T: "NGP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носу",
        E: "nose",
        T: "NDS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носам",
        E: "nose",
        T: "NDP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "нос",
        E: "nose",
        T: "NAS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носы",
        E: "nose",
        T: "NAP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носом",
        E: "nose",
        T: "NIS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носами",
        E: "nose",
        T: "NIP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "о носе",
        E: "nose",
        T: "NPS",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "носах",
        E: "nose",
        T: "NPP",
        A: "I",
        O: "нос",
        RA: 387,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "внимание",
        E: "attention",
        T: "NNS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "внимания",
        E: "attention",
        T: "NNP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "внимания",
        E: "attention",
        T: "NGS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманий",
        E: "attention",
        T: "NGP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманию",
        E: "attention",
        T: "NDS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманиям",
        E: "attention",
        T: "NDP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "внимание",
        E: "attention",
        T: "NAS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "внимания",
        E: "attention",
        T: "NAP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманием",
        E: "attention",
        T: "NIS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманиями",
        E: "attention",
        T: "NIP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "внимании",
        E: "attention",
        T: "NPS",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вниманиях",
        E: "attention",
        T: "NPP",
        A: "I",
        O: "внимание",
        RA: 389,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "капитан",
        E: "captain, master",
        T: "NNS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитаны",
        E: "captain, master",
        T: "NNP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитана",
        E: "captain, master",
        T: "NGS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитанов",
        E: "captain, master",
        T: "NGP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитану",
        E: "captain, master",
        T: "NDS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитанам",
        E: "captain, master",
        T: "NDP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитана",
        E: "captain, master",
        T: "NAS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитанов",
        E: "captain, master",
        T: "NAP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитаном",
        E: "captain, master",
        T: "NIS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитанами",
        E: "captain, master",
        T: "NIP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитане",
        E: "captain, master",
        T: "NPS",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "капитанах",
        E: "captain, master",
        T: "NPP",
        A: "A",
        O: "капитан",
        RA: 390,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ухо",
        E: "ear",
        T: "NNS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "уши",
        E: "ear",
        T: "NNP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "уха",
        E: "ear",
        T: "NGS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ушей",
        E: "ear",
        T: "NGP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "уху",
        E: "ear",
        T: "NDS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ушам",
        E: "ear",
        T: "NDP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ухо",
        E: "ear",
        T: "NAS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "уши",
        E: "ear",
        T: "NAP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ухом",
        E: "ear",
        T: "NIS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ушами",
        E: "ear",
        T: "NIP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ухе",
        E: "ear",
        T: "NPS",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ушах",
        E: "ear",
        T: "NPP",
        A: "I",
        O: "ухо",
        RA: 391,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувство",
        E: "feeling",
        T: "NNS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувства",
        E: "feeling",
        T: "NNP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувства",
        E: "feeling",
        T: "NGS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувств",
        E: "feeling",
        T: "NGP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувству",
        E: "feeling",
        T: "NDS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувствам",
        E: "feeling",
        T: "NDP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувство",
        E: "feeling",
        T: "NAS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувства",
        E: "feeling",
        T: "NAP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувством",
        E: "feeling",
        T: "NIS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувствами",
        E: "feeling",
        T: "NIP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувстве",
        E: "feeling",
        T: "NPS",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чувствах",
        E: "feeling",
        T: "NPP",
        A: "I",
        O: "чувство",
        RA: 406,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "берег",
        E: "bank, shore, coast",
        T: "NNS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берега",
        E: "bank, shore, coast",
        T: "NNP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берега",
        E: "bank, shore, coast",
        T: "NGS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегов",
        E: "bank, shore, coast",
        T: "NGP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегу",
        E: "bank, shore, coast",
        T: "NDS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегам",
        E: "bank, shore, coast",
        T: "NDP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берег",
        E: "bank, shore, coast",
        T: "NAS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берега",
        E: "bank, shore, coast",
        T: "NAP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегом",
        E: "bank, shore, coast",
        T: "NIS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегами",
        E: "bank, shore, coast",
        T: "NIP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "береге",
        E: "bank, shore, coast",
        T: "NPS",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "берегах",
        E: "bank, shore, coast",
        T: "NPP",
        A: "I",
        O: "берег",
        RA: 412,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "семья",
        E: "family",
        T: "NNS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьи",
        E: "family",
        T: "NNP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьи",
        E: "family",
        T: "NGS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семей",
        E: "family",
        T: "NGP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семье",
        E: "family",
        T: "NDS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьям",
        E: "family",
        T: "NDP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семью",
        E: "family",
        T: "NAS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьи",
        E: "family",
        T: "NAP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьёй",
        E: "family",
        T: "NIS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьями",
        E: "family",
        T: "NIP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семье",
        E: "family",
        T: "NPS",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "семьях",
        E: "family",
        T: "NPP",
        A: "I",
        O: "семья",
        RA: 413,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "генерал",
        E: "general",
        T: "NNS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералы",
        E: "general",
        T: "NNP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генерала",
        E: "general",
        T: "NGS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералов",
        E: "general",
        T: "NGP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералу",
        E: "general",
        T: "NDS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералам",
        E: "general",
        T: "NDP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генерала",
        E: "general",
        T: "NAS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералов",
        E: "general",
        T: "NAP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералом",
        E: "general",
        T: "NIS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералами",
        E: "general",
        T: "NIP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генерале",
        E: "general",
        T: "NPS",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "генералах",
        E: "general",
        T: "NPP",
        A: "I",
        O: "генерал",
        RA: 415,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "момент",
        E: "moment, instant",
        T: "NNS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моменты",
        E: "moment, instant",
        T: "NNP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "момента",
        E: "moment, instant",
        T: "NGS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моментов",
        E: "moment, instant",
        T: "NGP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моменту",
        E: "moment, instant",
        T: "NDS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моментам",
        E: "moment, instant",
        T: "NDP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "момент",
        E: "moment, instant",
        T: "NAS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моменты",
        E: "moment, instant",
        T: "NAP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моментом",
        E: "moment, instant",
        T: "NIS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моментами",
        E: "moment, instant",
        T: "NIP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моменте",
        E: "moment, instant",
        T: "NPS",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "моментах",
        E: "moment, instant",
        T: "NPP",
        A: "I",
        O: "момент",
        RA: 416,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труд",
        E: "labour, work",
        T: "NNS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труды",
        E: "labour, work",
        T: "NNP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труда",
        E: "labour, work",
        T: "NGS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "трудов",
        E: "labour, work",
        T: "NGP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труду",
        E: "labour, work",
        T: "NDS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "трудам",
        E: "labour, work",
        T: "NDP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труд",
        E: "labour, work",
        T: "NAS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труды",
        E: "labour, work",
        T: "NAP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "трудом",
        E: "labour, work",
        T: "NIS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "трудами",
        E: "labour, work",
        T: "NIP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "труде",
        E: "labour, work",
        T: "NPS",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "трудах",
        E: "labour, work",
        T: "NPP",
        A: "I",
        O: "труд",
        RA: 421,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "группа",
        E: "group",
        T: "NNS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группы",
        E: "group",
        T: "NNP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группы",
        E: "group",
        T: "NGS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "групп",
        E: "group",
        T: "NGP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группе",
        E: "group",
        T: "NDS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группам",
        E: "group",
        T: "NDP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группу",
        E: "group",
        T: "NAS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группы",
        E: "group",
        T: "NAP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группой",
        E: "group",
        T: "NIS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группами",
        E: "group",
        T: "NIP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группе",
        E: "group",
        T: "NPS",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "группах",
        E: "group",
        T: "NPP",
        A: "I",
        O: "группа",
        RA: 423,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "муж",
        E: "husband",
        T: "NNS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужья",
        E: "husband",
        T: "NNP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужа",
        E: "husband",
        T: "NGS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужей",
        E: "husband",
        T: "NGP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужу",
        E: "husband",
        T: "NDS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужьям",
        E: "husband",
        T: "NDP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужа",
        E: "husband",
        T: "NAS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужей",
        E: "husband",
        T: "NAP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужем",
        E: "husband",
        T: "NIS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужьями",
        E: "husband",
        T: "NIP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "муже",
        E: "husband",
        T: "NPS",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужьях",
        E: "husband",
        T: "NPP",
        A: "A",
        O: "муж",
        RA: 428,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "движение",
        E: "movement",
        T: "NNS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движения",
        E: "movement",
        T: "NNP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движения",
        E: "movement",
        T: "NGS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движений",
        E: "movement",
        T: "NGP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движению",
        E: "movement",
        T: "NDS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движениям",
        E: "movement",
        T: "NDP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движение",
        E: "movement",
        T: "NAS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движения",
        E: "movement",
        T: "NAP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движением",
        E: "movement",
        T: "NIS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движениями",
        E: "movement",
        T: "NIP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движении",
        E: "movement",
        T: "NPS",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "движениях",
        E: "movement",
        T: "NPP",
        A: "I",
        O: "движение",
        RA: 430,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "порядок",
        E: "order",
        T: "NNS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядки",
        E: "order",
        T: "NNP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядка",
        E: "order",
        T: "NGS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядков",
        E: "order",
        T: "NGP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядку",
        E: "order",
        T: "NDS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядкам",
        E: "order",
        T: "NDP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядок",
        E: "order",
        T: "NAS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядки",
        E: "order",
        T: "NAP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядком",
        E: "order",
        T: "NIS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядками",
        E: "order",
        T: "NIP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядке",
        E: "order",
        T: "NPS",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "порядках",
        E: "order",
        T: "NPP",
        A: "I",
        O: "порядок",
        RA: 431,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответ",
        E: "answer, reply",
        T: "NNS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответы",
        E: "answer, reply",
        T: "NNP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответа",
        E: "answer, reply",
        T: "NGS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответов",
        E: "answer, reply",
        T: "NGP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответу",
        E: "answer, reply",
        T: "NDS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответам",
        E: "answer, reply",
        T: "NDP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответ",
        E: "answer, reply",
        T: "NAS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответы",
        E: "answer, reply",
        T: "NAP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответом",
        E: "answer, reply",
        T: "NIS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответами",
        E: "answer, reply",
        T: "NIP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответе",
        E: "answer, reply",
        T: "NPS",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ответах",
        E: "answer, reply",
        T: "NPP",
        A: "I",
        O: "ответ",
        RA: 432,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "газета",
        E: "newspaper",
        T: "NNS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газеты",
        E: "newspaper",
        T: "NNP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газеты",
        E: "newspaper",
        T: "NGS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газет",
        E: "newspaper",
        T: "NGP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газете",
        E: "newspaper",
        T: "NDS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газетам",
        E: "newspaper",
        T: "NDP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газету",
        E: "newspaper",
        T: "NAS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газеты",
        E: "newspaper",
        T: "NAP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газетой",
        E: "newspaper",
        T: "NIS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газетами",
        E: "newspaper",
        T: "NIP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газете",
        E: "newspaper",
        T: "NPS",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "газетах",
        E: "newspaper",
        T: "NPP",
        A: "I",
        O: "газета",
        RA: 435,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощь",
        E: "help",
        T: "NNS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощи",
        E: "help",
        T: "NNP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощи",
        E: "help",
        T: "NGS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощей",
        E: "help",
        T: "NGP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощи",
        E: "help",
        T: "NDS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощам",
        E: "help",
        T: "NDP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощь",
        E: "help",
        T: "NAS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощи",
        E: "help",
        T: "NAP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощью",
        E: "help",
        T: "NIS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощами",
        E: "help",
        T: "NIP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощи",
        E: "help",
        T: "NPS",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "помощах",
        E: "help",
        T: "NPP",
        A: "I",
        O: "помощь",
        RA: 436,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собака",
        E: "dog",
        T: "NNS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаки",
        E: "dog",
        T: "NNP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаки",
        E: "dog",
        T: "NGS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собак",
        E: "dog",
        T: "NGP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаке",
        E: "dog",
        T: "NDS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собакам",
        E: "dog",
        T: "NDP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаку",
        E: "dog",
        T: "NAS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собак",
        E: "dog",
        T: "NAP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собакой",
        E: "dog",
        T: "NIS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаками",
        E: "dog",
        T: "NIP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаке",
        E: "dog",
        T: "NPS",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "собаках",
        E: "dog",
        T: "NPP",
        A: "A",
        O: "собака",
        RA: 439,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дерево",
        E: "tree",
        T: "NNS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревья",
        E: "tree",
        T: "NNP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дерева",
        E: "tree",
        T: "NGS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревьев",
        E: "tree",
        T: "NGP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дереву",
        E: "tree",
        T: "NDS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревьям",
        E: "tree",
        T: "NDP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дерево",
        E: "tree",
        T: "NAS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревья",
        E: "tree",
        T: "NAP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревом",
        E: "tree",
        T: "NIS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревьями",
        E: "tree",
        T: "NIP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дереве",
        E: "tree",
        T: "NPS",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревьях",
        E: "tree",
        T: "NPP",
        A: "I",
        O: "дерево",
        RA: 440,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "снег",
        E: "snow",
        T: "NNS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снега",
        E: "snow",
        T: "NNP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снега",
        E: "snow",
        T: "NGS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегов",
        E: "snow",
        T: "NGP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегу",
        E: "snow",
        T: "NDS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегам",
        E: "snow",
        T: "NDP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снег",
        E: "snow",
        T: "NAS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снега",
        E: "snow",
        T: "NAP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегом",
        E: "snow",
        T: "NIS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегами",
        E: "snow",
        T: "NIP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снеге",
        E: "snow",
        T: "NPS",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снегах",
        E: "snow",
        T: "NPP",
        A: "I",
        O: "снег",
        RA: 441,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сон",
        E: "dream",
        T: "NNS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сны",
        E: "dream",
        T: "NNP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сна",
        E: "dream",
        T: "NGS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снов",
        E: "dream",
        T: "NGP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сну",
        E: "dream",
        T: "NDS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снам",
        E: "dream",
        T: "NDP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сон",
        E: "dream",
        T: "NAS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сны",
        E: "dream",
        T: "NAP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сном",
        E: "dream",
        T: "NIS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снами",
        E: "dream",
        T: "NIP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сне",
        E: "dream",
        T: "NPS",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "снах",
        E: "dream",
        T: "NPP",
        A: "I",
        O: "сон",
        RA: 442,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смысл",
        E: "sense, meaning, purpose",
        T: "NNS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслы",
        E: "sense, meaning, purpose",
        T: "NNP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смысла",
        E: "sense, meaning, purpose",
        T: "NGS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслов",
        E: "sense, meaning, purpose",
        T: "NGP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслу",
        E: "sense, meaning, purpose",
        T: "NDS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслам",
        E: "sense, meaning, purpose",
        T: "NDP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смысл",
        E: "sense, meaning, purpose",
        T: "NAS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслы",
        E: "sense, meaning, purpose",
        T: "NAP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслом",
        E: "sense, meaning, purpose",
        T: "NIS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслами",
        E: "sense, meaning, purpose",
        T: "NIP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смысле",
        E: "sense, meaning, purpose",
        T: "NPS",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "смыслах",
        E: "sense, meaning, purpose",
        T: "NPP",
        A: "I",
        O: "смысл",
        RA: 443,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "двор",
        E: "yard, court",
        T: "NNS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворы",
        E: "yard, court",
        T: "NNP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "двора",
        E: "yard, court",
        T: "NGS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворов",
        E: "yard, court",
        T: "NGP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "двору",
        E: "yard, court",
        T: "NDS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворам",
        E: "yard, court",
        T: "NDP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "двор",
        E: "yard, court",
        T: "NAS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворы",
        E: "yard, court",
        T: "NAP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "двором",
        E: "yard, court",
        T: "NIS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворами",
        E: "yard, court",
        T: "NIP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворе",
        E: "yard, court",
        T: "NPS",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дворах",
        E: "yard, court",
        T: "NPP",
        A: "I",
        O: "двор",
        RA: 447,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "форма",
        E: "form, shape, uniform",
        T: "NNS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формы",
        E: "form, shape, uniform",
        T: "NNP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формы",
        E: "form, shape, uniform",
        T: "NGS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "форм",
        E: "form, shape, uniform",
        T: "NGP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "форме",
        E: "form, shape, uniform",
        T: "NDS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формам",
        E: "form, shape, uniform",
        T: "NDP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "форму",
        E: "form, shape, uniform",
        T: "NAS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формы",
        E: "form, shape, uniform",
        T: "NAP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формой",
        E: "form, shape, uniform",
        T: "NIS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формами",
        E: "form, shape, uniform",
        T: "NIP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "форме",
        E: "form, shape, uniform",
        T: "NPS",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "формах",
        E: "form, shape, uniform",
        T: "NPP",
        A: "I",
        O: "форма",
        RA: 448,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможность",
        E: "possibility, opportunity, chance",
        T: "NNS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможности",
        E: "possibility, opportunity, chance",
        T: "NNP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможности",
        E: "possibility, opportunity, chance",
        T: "NGS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможностей",
        E: "possibility, opportunity, chance",
        T: "NGP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможности",
        E: "possibility, opportunity, chance",
        T: "NDS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможностям",
        E: "possibility, opportunity, chance",
        T: "NDP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможность",
        E: "possibility, opportunity, chance",
        T: "NAS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможности",
        E: "possibility, opportunity, chance",
        T: "NAP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможностью",
        E: "possibility, opportunity, chance",
        T: "NIS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможностями",
        E: "possibility, opportunity, chance",
        T: "NIP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможности",
        E: "possibility, opportunity, chance",
        T: "NPS",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возможностях",
        E: "possibility, opportunity, chance",
        T: "NPP",
        A: "I",
        O: "возможность",
        RA: 453,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "общество",
        E: "society",
        T: "NNS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "общества",
        E: "society",
        T: "NNP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "общества",
        E: "society",
        T: "NGS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществ",
        E: "society",
        T: "NGP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществу",
        E: "society",
        T: "NDS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществам",
        E: "society",
        T: "NDP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "общество",
        E: "society",
        T: "NAS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "общества",
        E: "society",
        T: "NAP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществом",
        E: "society",
        T: "NIS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществами",
        E: "society",
        T: "NIP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществе",
        E: "society",
        T: "NPS",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "обществах",
        E: "society",
        T: "NPP",
        A: "I",
        O: "общество",
        RA: 454,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "грудь",
        E: "breast, chest",
        T: "NNS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "груди",
        E: "breast, chest",
        T: "NNP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "груди",
        E: "breast, chest",
        T: "NGS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудей",
        E: "breast, chest",
        T: "NGP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "груди",
        E: "breast, chest",
        T: "NDS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудям",
        E: "breast, chest",
        T: "NDP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудь",
        E: "breast, chest",
        T: "NAS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "груди",
        E: "breast, chest",
        T: "NAP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудью",
        E: "breast, chest",
        T: "NIS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудями",
        E: "breast, chest",
        T: "NIP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "груди",
        E: "breast, chest",
        T: "NPS",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "грудях",
        E: "breast, chest",
        T: "NPP",
        A: "I",
        O: "грудь",
        RA: 456,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "угол",
        E: "corner, angle",
        T: "NNS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углы",
        E: "corner, angle",
        T: "NNP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "угла",
        E: "corner, angle",
        T: "NGS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углов",
        E: "corner, angle",
        T: "NGP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углу",
        E: "corner, angle",
        T: "NDS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углам",
        E: "corner, angle",
        T: "NDP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "угол",
        E: "corner, angle",
        T: "NAS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углы",
        E: "corner, angle",
        T: "NAP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углом",
        E: "corner, angle",
        T: "NIS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углами",
        E: "corner, angle",
        T: "NIP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "угле",
        E: "corner, angle",
        T: "NPS",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "углах",
        E: "corner, angle",
        T: "NPP",
        A: "I",
        O: "угол",
        RA: 457,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "век",
        E: "century, age",
        T: "NNS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "века",
        E: "century, age",
        T: "NNP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "века",
        E: "century, age",
        T: "NGS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веков",
        E: "century, age",
        T: "NGP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веку",
        E: "century, age",
        T: "NDS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "векам",
        E: "century, age",
        T: "NDP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "век",
        E: "century, age",
        T: "NAS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "века",
        E: "century, age",
        T: "NAP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веком",
        E: "century, age",
        T: "NIS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веками",
        E: "century, age",
        T: "NIP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веке",
        E: "century, age",
        T: "NPS",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "веках",
        E: "century, age",
        T: "NPP",
        A: "I",
        O: "век",
        RA: 462,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карман",
        E: "pocket",
        T: "NNS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманы",
        E: "pocket",
        T: "NNP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кармана",
        E: "pocket",
        T: "NGS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманов",
        E: "pocket",
        T: "NGP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карману",
        E: "pocket",
        T: "NDS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманам",
        E: "pocket",
        T: "NDP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карман",
        E: "pocket",
        T: "NAS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманы",
        E: "pocket",
        T: "NAP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманом",
        E: "pocket",
        T: "NIS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманами",
        E: "pocket",
        T: "NIP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кармане",
        E: "pocket",
        T: "NPS",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "карманах",
        E: "pocket",
        T: "NPP",
        A: "I",
        O: "карман",
        RA: 463,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немец",
        E: "German",
        T: "NNS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцы",
        E: "German",
        T: "NNP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немца",
        E: "German",
        T: "NGS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцев",
        E: "German",
        T: "NGP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцу",
        E: "German",
        T: "NDS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцам",
        E: "German",
        T: "NDP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немца",
        E: "German",
        T: "NAS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцев",
        E: "German",
        T: "NAP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцем",
        E: "German",
        T: "NIS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцами",
        E: "German",
        T: "NIP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немце",
        E: "German",
        T: "NPS",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "немцах",
        E: "German",
        T: "NPP",
        A: "A",
        O: "немец",
        RA: 465,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "губа",
        E: "lip",
        T: "NNS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губы",
        E: "lip",
        T: "NNP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губы",
        E: "lip",
        T: "NGS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губ",
        E: "lip",
        T: "NGP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губе",
        E: "lip",
        T: "NDS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губам",
        E: "lip",
        T: "NDP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губу",
        E: "lip",
        T: "NAS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губы",
        E: "lip",
        T: "NAP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губой",
        E: "lip",
        T: "NIS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губами",
        E: "lip",
        T: "NIP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губе",
        E: "lip",
        T: "NPS",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "губах",
        E: "lip",
        T: "NPP",
        A: "I",
        O: "губа",
        RA: 467,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дядя",
        E: "uncle",
        T: "NNS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дяди",
        E: "uncle",
        T: "NNP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дяди",
        E: "uncle",
        T: "NGS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядей",
        E: "uncle",
        T: "NGP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дяде",
        E: "uncle",
        T: "NDS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядьям",
        E: "uncle",
        T: "NDP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядю",
        E: "uncle",
        T: "NAS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядей",
        E: "uncle",
        T: "NAP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядей",
        E: "uncle",
        T: "NIS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядьями",
        E: "uncle",
        T: "NIP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дяде",
        E: "uncle",
        T: "NPS",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дядьях",
        E: "uncle",
        T: "NPP",
        A: "A",
        O: "дядя",
        RA: 468,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огонь",
        E: "fire",
        T: "NNS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огни",
        E: "fire",
        T: "NNP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огня",
        E: "fire",
        T: "NGS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огней",
        E: "fire",
        T: "NGP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огню",
        E: "fire",
        T: "NDS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огням",
        E: "fire",
        T: "NDP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огонь",
        E: "fire",
        T: "NAS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огни",
        E: "fire",
        T: "NAP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огнём",
        E: "fire",
        T: "NIS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огнями",
        E: "fire",
        T: "NIP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огне",
        E: "fire",
        T: "NPS",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "огнях",
        E: "fire",
        T: "NPP",
        A: "I",
        O: "огонь",
        RA: 472,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писатель",
        E: "writer",
        T: "NNS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писатели",
        E: "writer",
        T: "NNP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателя",
        E: "writer",
        T: "NGS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателей",
        E: "writer",
        T: "NGP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателю",
        E: "writer",
        T: "NDS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателям",
        E: "writer",
        T: "NDP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателя",
        E: "writer",
        T: "NAS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателей",
        E: "writer",
        T: "NAP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателем",
        E: "writer",
        T: "NIS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателями",
        E: "writer",
        T: "NIP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателе",
        E: "writer",
        T: "NPS",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "писателях",
        E: "writer",
        T: "NPP",
        A: "A",
        O: "писатель",
        RA: 473,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "армия",
        E: "army",
        T: "NNS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армии",
        E: "army",
        T: "NNP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армии",
        E: "army",
        T: "NGS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армий",
        E: "army",
        T: "NGP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армии",
        E: "army",
        T: "NDS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армиям",
        E: "army",
        T: "NDP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армию",
        E: "army",
        T: "NAS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армии",
        E: "army",
        T: "NAP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армией",
        E: "army",
        T: "NIS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армиями",
        E: "army",
        T: "NIP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армии",
        E: "army",
        T: "NPS",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "армиях",
        E: "army",
        T: "NPP",
        A: "I",
        O: "армия",
        RA: 474,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "состояние",
        E: "state, condition; fortune",
        T: "NNS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояния",
        E: "state, condition; fortune",
        T: "NNP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояния",
        E: "state, condition; fortune",
        T: "NGS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояний",
        E: "state, condition; fortune",
        T: "NGP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состоянию",
        E: "state, condition; fortune",
        T: "NDS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояниям",
        E: "state, condition; fortune",
        T: "NDP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояние",
        E: "state, condition; fortune",
        T: "NAS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояния",
        E: "state, condition; fortune",
        T: "NAP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состоянием",
        E: "state, condition; fortune",
        T: "NIS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояниями",
        E: "state, condition; fortune",
        T: "NIP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состоянии",
        E: "state, condition; fortune",
        T: "NPS",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "состояниях",
        E: "state, condition; fortune",
        T: "NPP",
        A: "I",
        O: "состояние",
        RA: 475,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зуб",
        E: "tooth",
        T: "NNS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубы",
        E: "tooth",
        T: "NNP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зуба",
        E: "tooth",
        T: "NGS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубов",
        E: "tooth",
        T: "NGP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубу",
        E: "tooth",
        T: "NDS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубам",
        E: "tooth",
        T: "NDP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зуб",
        E: "tooth",
        T: "NAS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубы",
        E: "tooth",
        T: "NAP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубом",
        E: "tooth",
        T: "NIS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубами",
        E: "tooth",
        T: "NIP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубе",
        E: "tooth",
        T: "NPS",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зубах",
        E: "tooth",
        T: "NPP",
        A: "I",
        O: "зуб",
        RA: 476,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "очередь",
        E: "line, queue, turn",
        T: "NNS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очереди",
        E: "line, queue, turn",
        T: "NNP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очереди",
        E: "line, queue, turn",
        T: "NGS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередей",
        E: "line, queue, turn",
        T: "NGP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очереди",
        E: "line, queue, turn",
        T: "NDS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередям",
        E: "line, queue, turn",
        T: "NDP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередь",
        E: "line, queue, turn",
        T: "NAS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очереди",
        E: "line, queue, turn",
        T: "NAP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередью",
        E: "line, queue, turn",
        T: "NIS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередями",
        E: "line, queue, turn",
        T: "NIP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очереди",
        E: "line, queue, turn",
        T: "NPS",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "очередях",
        E: "line, queue, turn",
        T: "NPP",
        A: "I",
        O: "очередь",
        RA: 477,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "камень",
        E: "stone",
        T: "NNS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камни",
        E: "stone",
        T: "NNP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камня",
        E: "stone",
        T: "NGS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камней",
        E: "stone",
        T: "NGP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камню",
        E: "stone",
        T: "NDS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камням",
        E: "stone",
        T: "NDP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камень",
        E: "stone",
        T: "NAS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камни",
        E: "stone",
        T: "NAP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камнем",
        E: "stone",
        T: "NIS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камнями",
        E: "stone",
        T: "NIP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камне",
        E: "stone",
        T: "NPS",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "камнях",
        E: "stone",
        T: "NPP",
        A: "I",
        O: "камень",
        RA: 480,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гость",
        E: "guest",
        T: "NNS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гости",
        E: "guest",
        T: "NNP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостя",
        E: "guest",
        T: "NGS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостей",
        E: "guest",
        T: "NGP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостю",
        E: "guest",
        T: "NDS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостям",
        E: "guest",
        T: "NDP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостя",
        E: "guest",
        T: "NAS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостей",
        E: "guest",
        T: "NAP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостем",
        E: "guest",
        T: "NIS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостями",
        E: "guest",
        T: "NIP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "госте",
        E: "guest",
        T: "NPS",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гостях",
        E: "guest",
        T: "NPP",
        A: "A",
        O: "гость",
        RA: 481,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветер",
        E: "wind",
        T: "NNS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NNP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветра",
        E: "wind",
        T: "NGS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NGP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветру",
        E: "wind",
        T: "NDS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NDP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветер",
        E: "wind",
        T: "NAS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NAP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветром",
        E: "wind",
        T: "NIS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NIP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ветре",
        E: "wind",
        T: "NPS",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "",
        E: "wind",
        T: "NPP",
        A: "I",
        O: "ветер",
        RA: 483,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рота",
        E: "company (military)",
        T: "NNS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роты",
        E: "company (military)",
        T: "NNP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роты",
        E: "company (military)",
        T: "NGS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рот",
        E: "company (military)",
        T: "NGP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роте",
        E: "company (military)",
        T: "NDS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ротам",
        E: "company (military)",
        T: "NDP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роту",
        E: "company (military)",
        T: "NAS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роты",
        E: "company (military)",
        T: "NAP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ротой",
        E: "company (military)",
        T: "NIS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ротами",
        E: "company (military)",
        T: "NIP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роте",
        E: "company (military)",
        T: "NPS",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ротах",
        E: "company (military)",
        T: "NPP",
        A: "I",
        O: "рота",
        RA: 495,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "закон",
        E: "law, act, statute",
        T: "NNS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законы",
        E: "law, act, statute",
        T: "NNP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "закона",
        E: "law, act, statute",
        T: "NGS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законов",
        E: "law, act, statute",
        T: "NGP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "закону",
        E: "law, act, statute",
        T: "NDS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законам",
        E: "law, act, statute",
        T: "NDP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "закон",
        E: "law, act, statute",
        T: "NAS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законы",
        E: "law, act, statute",
        T: "NAP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законом",
        E: "law, act, statute",
        T: "NIS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законами",
        E: "law, act, statute",
        T: "NIP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законе",
        E: "law, act, statute",
        T: "NPS",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "законах",
        E: "law, act, statute",
        T: "NPP",
        A: "I",
        O: "закон",
        RA: 496,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "море",
        E: "sea",
        T: "NNS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "моря",
        E: "sea",
        T: "NNP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "моря",
        E: "sea",
        T: "NGS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морей",
        E: "sea",
        T: "NGP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морю",
        E: "sea",
        T: "NDS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морям",
        E: "sea",
        T: "NDP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "море",
        E: "sea",
        T: "NAS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "моря",
        E: "sea",
        T: "NAP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морем",
        E: "sea",
        T: "NIS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морями",
        E: "sea",
        T: "NIP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "море",
        E: "sea",
        T: "NPS",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "морях",
        E: "sea",
        T: "NPP",
        A: "I",
        O: "море",
        RA: 498,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "гора",
        E: "mountain, hill",
        T: "NNS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горы",
        E: "mountain, hill",
        T: "NNP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горы",
        E: "mountain, hill",
        T: "NGS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "гор",
        E: "mountain, hill",
        T: "NGP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горе",
        E: "mountain, hill",
        T: "NDS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горам",
        E: "mountain, hill",
        T: "NDP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "гору",
        E: "mountain, hill",
        T: "NAS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горы",
        E: "mountain, hill",
        T: "NAP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горой",
        E: "mountain, hill",
        T: "NIS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горами",
        E: "mountain, hill",
        T: "NIP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горе",
        E: "mountain, hill",
        T: "NPS",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "горах",
        E: "mountain, hill",
        T: "NPP",
        A: "I",
        O: "гора",
        RA: 501,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "врач",
        E: "physician, doctor",
        T: "NNS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачи",
        E: "physician, doctor",
        T: "NNP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врача",
        E: "physician, doctor",
        T: "NGS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачей",
        E: "physician, doctor",
        T: "NGP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачу",
        E: "physician, doctor",
        T: "NDS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачам",
        E: "physician, doctor",
        T: "NDP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врача",
        E: "physician, doctor",
        T: "NAS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачей",
        E: "physician, doctor",
        T: "NAP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачом",
        E: "physician, doctor",
        T: "NIS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачами",
        E: "physician, doctor",
        T: "NIP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "враче",
        E: "physician, doctor",
        T: "NPS",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врачах",
        E: "physician, doctor",
        T: "NPP",
        A: "A",
        O: "врач",
        RA: 502,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "край",
        E: "border, edge; land, country",
        T: "NNS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "края",
        E: "border, edge; land, country",
        T: "NNP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "края",
        E: "border, edge; land, country",
        T: "NGS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краёв",
        E: "border, edge; land, country",
        T: "NGP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краю",
        E: "border, edge; land, country",
        T: "NDS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краям",
        E: "border, edge; land, country",
        T: "NDP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "край",
        E: "border, edge; land, country",
        T: "NAS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "края",
        E: "border, edge; land, country",
        T: "NAP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краем",
        E: "border, edge; land, country",
        T: "NIS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краями",
        E: "border, edge; land, country",
        T: "NIP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "крае",
        E: "border, edge; land, country",
        T: "NPS",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "краях",
        E: "border, edge; land, country",
        T: "NPP",
        A: "I",
        O: "край",
        RA: 503,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "река",
        E: "river",
        T: "NNS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реки",
        E: "river",
        T: "NNP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реки",
        E: "river",
        T: "NGS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рек",
        E: "river",
        T: "NGP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реке",
        E: "river",
        T: "NDS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рекам",
        E: "river",
        T: "NDP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реку",
        E: "river",
        T: "NAS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реки",
        E: "river",
        T: "NAP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рекой",
        E: "river",
        T: "NIS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реками",
        E: "river",
        T: "NIP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реке",
        E: "river",
        T: "NPS",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "реках",
        E: "river",
        T: "NPP",
        A: "I",
        O: "река",
        RA: 507,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мера",
        E: "measure, step",
        T: "NNS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "меры",
        E: "measure, step",
        T: "NNP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "меры",
        E: "measure, step",
        T: "NGS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мер",
        E: "measure, step",
        T: "NGP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мере",
        E: "measure, step",
        T: "NDS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мерам",
        E: "measure, step",
        T: "NDP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "меру",
        E: "measure, step",
        T: "NAS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "меры",
        E: "measure, step",
        T: "NAP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мерой",
        E: "measure, step",
        T: "NIS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мерами",
        E: "measure, step",
        T: "NIP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мере",
        E: "measure, step",
        T: "NPS",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мерах",
        E: "measure, step",
        T: "NPP",
        A: "I",
        O: "мера",
        RA: 509,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "действие",
        E: "action, effect",
        T: "NNS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действия",
        E: "action, effect",
        T: "NNP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действия",
        E: "action, effect",
        T: "NGS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действий",
        E: "action, effect",
        T: "NGP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действию",
        E: "action, effect",
        T: "NDS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действиям",
        E: "action, effect",
        T: "NDP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действие",
        E: "action, effect",
        T: "NAS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действия",
        E: "action, effect",
        T: "NAP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действием",
        E: "action, effect",
        T: "NIS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действиями",
        E: "action, effect",
        T: "NIP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действии",
        E: "action, effect",
        T: "NPS",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "действиях",
        E: "action, effect",
        T: "NPP",
        A: "I",
        O: "действие",
        RA: 519,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вещь",
        E: "thing",
        T: "NNS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещи",
        E: "thing",
        T: "NNP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещи",
        E: "thing",
        T: "NGS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещей",
        E: "thing",
        T: "NGP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещи",
        E: "thing",
        T: "NDS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещам",
        E: "thing",
        T: "NDP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещь",
        E: "thing",
        T: "NAS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещи",
        E: "thing",
        T: "NAP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещью",
        E: "thing",
        T: "NIS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещами",
        E: "thing",
        T: "NIP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещи",
        E: "thing",
        T: "NPS",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вещах",
        E: "thing",
        T: "NPP",
        A: "I",
        O: "вещь",
        RA: 521,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ход",
        E: "move",
        T: "NNS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходы",
        E: "move",
        T: "NNP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хода",
        E: "move",
        T: "NGS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходов",
        E: "move",
        T: "NGP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходу",
        E: "move",
        T: "NDS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходам",
        E: "move",
        T: "NDP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ход",
        E: "move",
        T: "NAS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хода",
        E: "move",
        T: "NAP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходом",
        E: "move",
        T: "NIS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходами",
        E: "move",
        T: "NIP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходе",
        E: "move",
        T: "NPS",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ходах",
        E: "move",
        T: "NPP",
        A: "I",
        O: "ход",
        RA: 523,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боль",
        E: "pain",
        T: "NNS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боли",
        E: "pain",
        T: "NNP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боли",
        E: "pain",
        T: "NGS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болей",
        E: "pain",
        T: "NGP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боли",
        E: "pain",
        T: "NDS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болям",
        E: "pain",
        T: "NDP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боль",
        E: "pain",
        T: "NAS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боли",
        E: "pain",
        T: "NAP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болью",
        E: "pain",
        T: "NIS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болями",
        E: "pain",
        T: "NIP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "боли",
        E: "pain",
        T: "NPS",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болях",
        E: "pain",
        T: "NPP",
        A: "I",
        O: "боль",
        RA: 524,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьба",
        E: "fate, fortune, destiny",
        T: "NNS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбы",
        E: "fate, fortune, destiny",
        T: "NNP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбы",
        E: "fate, fortune, destiny",
        T: "NGS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судеб",
        E: "fate, fortune, destiny",
        T: "NGP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбе",
        E: "fate, fortune, destiny",
        T: "NDS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбам",
        E: "fate, fortune, destiny",
        T: "NDP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбу",
        E: "fate, fortune, destiny",
        T: "NAS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбы",
        E: "fate, fortune, destiny",
        T: "NAP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбой",
        E: "fate, fortune, destiny",
        T: "NIS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбами",
        E: "fate, fortune, destiny",
        T: "NIP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбе",
        E: "fate, fortune, destiny",
        T: "NPS",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "судьбах",
        E: "fate, fortune, destiny",
        T: "NPP",
        A: "I",
        O: "судьба",
        RA: 525,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причина",
        E: "cause, reason, motive",
        T: "NNS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причины",
        E: "cause, reason, motive",
        T: "NNP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причины",
        E: "cause, reason, motive",
        T: "NGS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причин",
        E: "cause, reason, motive",
        T: "NGP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причине",
        E: "cause, reason, motive",
        T: "NDS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причинам",
        E: "cause, reason, motive",
        T: "NDP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причину",
        E: "cause, reason, motive",
        T: "NAS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причины",
        E: "cause, reason, motive",
        T: "NAP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причиной",
        E: "cause, reason, motive",
        T: "NIS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причинами",
        E: "cause, reason, motive",
        T: "NIP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причине",
        E: "cause, reason, motive",
        T: "NPS",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "причинах",
        E: "cause, reason, motive",
        T: "NPP",
        A: "I",
        O: "причина",
        RA: 526,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черта",
        E: "line, boundary; trait",
        T: "NNS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черты",
        E: "line, boundary; trait",
        T: "NNP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черты",
        E: "line, boundary; trait",
        T: "NGS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черт",
        E: "line, boundary; trait",
        T: "NGP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черте",
        E: "line, boundary; trait",
        T: "NDS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "чертам",
        E: "line, boundary; trait",
        T: "NDP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черту",
        E: "line, boundary; trait",
        T: "NAS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черты",
        E: "line, boundary; trait",
        T: "NAP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "чертой",
        E: "line, boundary; trait",
        T: "NIS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "чертами",
        E: "line, boundary; trait",
        T: "NIP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "черте",
        E: "line, boundary; trait",
        T: "NPS",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "чертах",
        E: "line, boundary; trait",
        T: "NPP",
        A: "I",
        O: "черта",
        RA: 529,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочка",
        E: "girl, little girl",
        T: "NNS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочки",
        E: "girl, little girl",
        T: "NNP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочки",
        E: "girl, little girl",
        T: "NGS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочек",
        E: "girl, little girl",
        T: "NGP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочке",
        E: "girl, little girl",
        T: "NDS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочкам",
        E: "girl, little girl",
        T: "NDP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочку",
        E: "girl, little girl",
        T: "NAS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочек",
        E: "girl, little girl",
        T: "NAP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочкой",
        E: "girl, little girl",
        T: "NIS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочками",
        E: "girl, little girl",
        T: "NIP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочке",
        E: "girl, little girl",
        T: "NPS",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "девочках",
        E: "girl, little girl",
        T: "NPP",
        A: "A",
        O: "девочка",
        RA: 530,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волос",
        E: "hair",
        T: "NNS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосы",
        E: "hair",
        T: "NNP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волоса",
        E: "hair",
        T: "NGS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волос",
        E: "hair",
        T: "NGP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосу",
        E: "hair",
        T: "NDS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосам",
        E: "hair",
        T: "NDP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волос",
        E: "hair",
        T: "NAS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосы",
        E: "hair",
        T: "NAP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосом",
        E: "hair",
        T: "NIS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосами",
        E: "hair",
        T: "NIP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосе",
        E: "hair",
        T: "NPS",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "волосах",
        E: "hair",
        T: "NPP",
        A: "I",
        O: "волос",
        RA: 532,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номер",
        E: "number, size, room, issue",
        T: "NNS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номера",
        E: "number, size, room, issue",
        T: "NNP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номера",
        E: "number, size, room, issue",
        T: "NGS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номеров",
        E: "number, size, room, issue",
        T: "NGP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номеру",
        E: "number, size, room, issue",
        T: "NDS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номерам",
        E: "number, size, room, issue",
        T: "NDP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номер",
        E: "number, size, room, issue",
        T: "NAS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номера",
        E: "number, size, room, issue",
        T: "NAP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номером",
        E: "number, size, room, issue",
        T: "NIS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номерами",
        E: "number, size, room, issue",
        T: "NIP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номере",
        E: "number, size, room, issue",
        T: "NPS",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "номерах",
        E: "number, size, room, issue",
        T: "NPP",
        A: "I",
        O: "номер",
        RA: 534,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "глава",
        E: "head, chief",
        T: "NNS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главы",
        E: "head, chief",
        T: "NNP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главы",
        E: "head, chief",
        T: "NGS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "глав",
        E: "head, chief",
        T: "NGP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главе",
        E: "head, chief",
        T: "NDS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главам",
        E: "head, chief",
        T: "NDP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главу",
        E: "head, chief",
        T: "NAS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главы",
        E: "head, chief",
        T: "NAP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главой",
        E: "head, chief",
        T: "NIS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главами",
        E: "head, chief",
        T: "NIP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главе",
        E: "head, chief",
        T: "NPS",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "главах",
        E: "head, chief",
        T: "NPP",
        A: "I",
        O: "глава",
        RA: 540,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "командир",
        E: "commander, commanding officer",
        T: "NNS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командиры",
        E: "commander, commanding officer",
        T: "NNP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командира",
        E: "commander, commanding officer",
        T: "NGS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командиров",
        E: "commander, commanding officer",
        T: "NGP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командиру",
        E: "commander, commanding officer",
        T: "NDS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командирам",
        E: "commander, commanding officer",
        T: "NDP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командира",
        E: "commander, commanding officer",
        T: "NAS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командиров",
        E: "commander, commanding officer",
        T: "NAP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командиром",
        E: "commander, commanding officer",
        T: "NIS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командирами",
        E: "commander, commanding officer",
        T: "NIP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командире",
        E: "commander, commanding officer",
        T: "NPS",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "командирах",
        E: "commander, commanding officer",
        T: "NPP",
        A: "A",
        O: "командир",
        RA: 544,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "партия",
        E: "party",
        T: "NNS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партии",
        E: "party",
        T: "NNP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партии",
        E: "party",
        T: "NGS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партий",
        E: "party",
        T: "NGP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партии",
        E: "party",
        T: "NDS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партиям",
        E: "party",
        T: "NDP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партию",
        E: "party",
        T: "NAS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партии",
        E: "party",
        T: "NAP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партией",
        E: "party",
        T: "NIS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партиями",
        E: "party",
        T: "NIP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партии",
        E: "party",
        T: "NPS",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "партиях",
        E: "party",
        T: "NPP",
        A: "I",
        O: "партия",
        RA: 546,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблема",
        E: "problem, issue",
        T: "NNS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемы",
        E: "problem, issue",
        T: "NNP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемы",
        E: "problem, issue",
        T: "NGS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблем",
        E: "problem, issue",
        T: "NGP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблеме",
        E: "problem, issue",
        T: "NDS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемам",
        E: "problem, issue",
        T: "NDP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблему",
        E: "problem, issue",
        T: "NAS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемы",
        E: "problem, issue",
        T: "NAP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемой",
        E: "problem, issue",
        T: "NIS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемами",
        E: "problem, issue",
        T: "NIP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблеме",
        E: "problem, issue",
        T: "NPS",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "проблемах",
        E: "problem, issue",
        T: "NPP",
        A: "I",
        O: "проблема",
        RA: 547,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "страх",
        E: "fear",
        T: "NNS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхи",
        E: "fear",
        T: "NNP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страха",
        E: "fear",
        T: "NGS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхов",
        E: "fear",
        T: "NGP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страху",
        E: "fear",
        T: "NDS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхам",
        E: "fear",
        T: "NDP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страх",
        E: "fear",
        T: "NAS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхи",
        E: "fear",
        T: "NAP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхом",
        E: "fear",
        T: "NIS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхами",
        E: "fear",
        T: "NIP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхе",
        E: "fear",
        T: "NPS",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "страхах",
        E: "fear",
        T: "NPP",
        A: "I",
        O: "страх",
        RA: 548,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бумага",
        E: "paper",
        T: "NNS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаги",
        E: "paper",
        T: "NNP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаги",
        E: "paper",
        T: "NGS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаг",
        E: "paper",
        T: "NGP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаге",
        E: "paper",
        T: "NDS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумагам",
        E: "paper",
        T: "NDP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумагу",
        E: "paper",
        T: "NAS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаги",
        E: "paper",
        T: "NAP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумагой",
        E: "paper",
        T: "NIS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумагами",
        E: "paper",
        T: "NIP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумаге",
        E: "paper",
        T: "NPS",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бумагах",
        E: "paper",
        T: "NPP",
        A: "I",
        O: "бумага",
        RA: 552,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "герой",
        E: "hero",
        T: "NNS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "герои",
        E: "hero",
        T: "NNP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героя",
        E: "hero",
        T: "NGS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героев",
        E: "hero",
        T: "NGP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "герою",
        E: "hero",
        T: "NDS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героям",
        E: "hero",
        T: "NDP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героя",
        E: "hero",
        T: "NAS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героев",
        E: "hero",
        T: "NAP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героем",
        E: "hero",
        T: "NIS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героями",
        E: "hero",
        T: "NIP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "герое",
        E: "hero",
        T: "NPS",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "героях",
        E: "hero",
        T: "NPP",
        A: "A",
        O: "герой",
        RA: 553,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пара",
        E: "pair, couple",
        T: "NNS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пары",
        E: "pair, couple",
        T: "NNP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пары",
        E: "pair, couple",
        T: "NGS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пар",
        E: "pair, couple",
        T: "NGP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "паре",
        E: "pair, couple",
        T: "NDS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "парам",
        E: "pair, couple",
        T: "NDP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пару",
        E: "pair, couple",
        T: "NAS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "пары",
        E: "pair, couple",
        T: "NAP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "парой",
        E: "pair, couple",
        T: "NIS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "парами",
        E: "pair, couple",
        T: "NIP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "паре",
        E: "pair, couple",
        T: "NPS",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "парах",
        E: "pair, couple",
        T: "NPP",
        A: "I",
        O: "пара",
        RA: 554,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "государство",
        E: "State",
        T: "NNS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государства",
        E: "State",
        T: "NNP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государства",
        E: "State",
        T: "NGS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государств",
        E: "State",
        T: "NGP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государству",
        E: "State",
        T: "NDS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государствам",
        E: "State",
        T: "NDP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государство",
        E: "State",
        T: "NAS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государства",
        E: "State",
        T: "NAP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государством",
        E: "State",
        T: "NIS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государствами",
        E: "State",
        T: "NIP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государстве",
        E: "State",
        T: "NPS",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "государствах",
        E: "State",
        T: "NPP",
        A: "I",
        O: "государство",
        RA: 555,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деревня",
        E: "village, country",
        T: "NNS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревни",
        E: "village, country",
        T: "NNP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревни",
        E: "village, country",
        T: "NGS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревень",
        E: "village, country",
        T: "NGP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревне",
        E: "village, country",
        T: "NDS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревням",
        E: "village, country",
        T: "NDP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревню",
        E: "village, country",
        T: "NAS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревни",
        E: "village, country",
        T: "NAP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревней",
        E: "village, country",
        T: "NIS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревнями",
        E: "village, country",
        T: "NIP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревне",
        E: "village, country",
        T: "NPS",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "деревнях",
        E: "village, country",
        T: "NPP",
        A: "I",
        O: "деревня",
        RA: 556,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речь",
        E: "speech",
        T: "NNS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речи",
        E: "speech",
        T: "NNP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речи",
        E: "speech",
        T: "NGS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речей",
        E: "speech",
        T: "NGP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речи",
        E: "speech",
        T: "NDS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речам",
        E: "speech",
        T: "NDP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речь",
        E: "speech",
        T: "NAS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речи",
        E: "speech",
        T: "NAP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речью",
        E: "speech",
        T: "NIS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речами",
        E: "speech",
        T: "NIP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речи",
        E: "speech",
        T: "NPS",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "речах",
        E: "speech",
        T: "NPP",
        A: "I",
        O: "речь",
        RA: 557,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "средство",
        E: "means, remedy",
        T: "NNS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средства",
        E: "means, remedy",
        T: "NNP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средства",
        E: "means, remedy",
        T: "NGS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средств",
        E: "means, remedy",
        T: "NGP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средству",
        E: "means, remedy",
        T: "NDS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средствам",
        E: "means, remedy",
        T: "NDP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средство",
        E: "means, remedy",
        T: "NAS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средства",
        E: "means, remedy",
        T: "NAP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средством",
        E: "means, remedy",
        T: "NIS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средствами",
        E: "means, remedy",
        T: "NIP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средстве",
        E: "means, remedy",
        T: "NPS",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "средствах",
        E: "means, remedy",
        T: "NPP",
        A: "I",
        O: "средство",
        RA: 559,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положение",
        E: "position, posture, condition, state",
        T: "NNS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положения",
        E: "position, posture, condition, state",
        T: "NNP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положения",
        E: "position, posture, condition, state",
        T: "NGS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положений",
        E: "position, posture, condition, state",
        T: "NGP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положению",
        E: "position, posture, condition, state",
        T: "NDS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положениям",
        E: "position, posture, condition, state",
        T: "NDP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положение",
        E: "position, posture, condition, state",
        T: "NAS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положения",
        E: "position, posture, condition, state",
        T: "NAP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положением",
        E: "position, posture, condition, state",
        T: "NIS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положениями",
        E: "position, posture, condition, state",
        T: "NIP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положении",
        E: "position, posture, condition, state",
        T: "NPS",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "положениях",
        E: "position, posture, condition, state",
        T: "NPP",
        A: "I",
        O: "положение",
        RA: 560,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "связь",
        E: "tie, bond; connection, relation",
        T: "NNS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связи",
        E: "tie, bond; connection, relation",
        T: "NNP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связи",
        E: "tie, bond; connection, relation",
        T: "NGS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связей",
        E: "tie, bond; connection, relation",
        T: "NGP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связи",
        E: "tie, bond; connection, relation",
        T: "NDS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связям",
        E: "tie, bond; connection, relation",
        T: "NDP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связь",
        E: "tie, bond; connection, relation",
        T: "NAS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связи",
        E: "tie, bond; connection, relation",
        T: "NAP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связью",
        E: "tie, bond; connection, relation",
        T: "NIS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связями",
        E: "tie, bond; connection, relation",
        T: "NIP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связи",
        E: "tie, bond; connection, relation",
        T: "NPS",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "связях",
        E: "tie, bond; connection, relation",
        T: "NPP",
        A: "I",
        O: "связь",
        RA: 561,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "класс",
        E: "class",
        T: "NNS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классы",
        E: "class",
        T: "NNP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "класса",
        E: "class",
        T: "NGS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классов",
        E: "class",
        T: "NGP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классу",
        E: "class",
        T: "NDS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классам",
        E: "class",
        T: "NDP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "класс",
        E: "class",
        T: "NAS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классы",
        E: "class",
        T: "NAP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классом",
        E: "class",
        T: "NIS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классами",
        E: "class",
        T: "NIP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классе",
        E: "class",
        T: "NPS",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "классах",
        E: "class",
        T: "NPP",
        A: "I",
        O: "класс",
        RA: 575,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цель",
        E: "goal, object, target",
        T: "NNS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цели",
        E: "goal, object, target",
        T: "NNP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цели",
        E: "goal, object, target",
        T: "NGS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "целей",
        E: "goal, object, target",
        T: "NGP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цели",
        E: "goal, object, target",
        T: "NDS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "целям",
        E: "goal, object, target",
        T: "NDP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цель",
        E: "goal, object, target",
        T: "NAS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цели",
        E: "goal, object, target",
        T: "NAP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "целью",
        E: "goal, object, target",
        T: "NIS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "целями",
        E: "goal, object, target",
        T: "NIP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цели",
        E: "goal, object, target",
        T: "NPS",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "целях",
        E: "goal, object, target",
        T: "NPP",
        A: "I",
        O: "цель",
        RA: 578,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знать",
        E: "nobility",
        T: "NNS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NNP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знати",
        E: "nobility",
        T: "NGS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NGP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знати",
        E: "nobility",
        T: "NDS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NDP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знать",
        E: "nobility",
        T: "NAS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NAP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знатью",
        E: "nobility",
        T: "NIS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NIP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "знати",
        E: "nobility",
        T: "NPS",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "nobility",
        T: "NPP",
        A: "I",
        O: "знать",
        RA: 582,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "профессор",
        E: "professor",
        T: "NNS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессора",
        E: "professor",
        T: "NNP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессора",
        E: "professor",
        T: "NGS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессоров",
        E: "professor",
        T: "NGP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессору",
        E: "professor",
        T: "NDS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессорам",
        E: "professor",
        T: "NDP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессора",
        E: "professor",
        T: "NAS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессоров",
        E: "professor",
        T: "NAP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессором",
        E: "professor",
        T: "NIS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессорами",
        E: "professor",
        T: "NIP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессоре",
        E: "professor",
        T: "NPS",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "профессорах",
        E: "professor",
        T: "NPP",
        A: "A",
        O: "профессор",
        RA: 584,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господин",
        E: "gentleman, Mr.",
        T: "NNS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господа",
        E: "gentleman, Mr.",
        T: "NNP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господина",
        E: "gentleman, Mr.",
        T: "NGS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господ",
        E: "gentleman, Mr.",
        T: "NGP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господину",
        E: "gentleman, Mr.",
        T: "NDS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господам",
        E: "gentleman, Mr.",
        T: "NDP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господина",
        E: "gentleman, Mr.",
        T: "NAS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господ",
        E: "gentleman, Mr.",
        T: "NAP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господином",
        E: "gentleman, Mr.",
        T: "NIS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господами",
        E: "gentleman, Mr.",
        T: "NIP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господине",
        E: "gentleman, Mr.",
        T: "NPS",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "господах",
        E: "gentleman, Mr.",
        T: "NPP",
        A: "A",
        O: "господин",
        RA: 585,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счастье",
        E: "happiness, luck",
        T: "NNS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастья",
        E: "happiness, luck",
        T: "NNP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастья",
        E: "happiness, luck",
        T: "NGS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастий",
        E: "happiness, luck",
        T: "NGP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастью",
        E: "happiness, luck",
        T: "NDS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастьям",
        E: "happiness, luck",
        T: "NDP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастье",
        E: "happiness, luck",
        T: "NAS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастья",
        E: "happiness, luck",
        T: "NAP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастьем",
        E: "happiness, luck",
        T: "NIS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастьями",
        E: "happiness, luck",
        T: "NIP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастье",
        E: "happiness, luck",
        T: "NPS",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "счастьях",
        E: "happiness, luck",
        T: "NPP",
        A: "I",
        O: "счастье",
        RA: 586,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дух",
        E: "spirit",
        T: "NNS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духи",
        E: "spirit",
        T: "NNP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духа",
        E: "spirit",
        T: "NGS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духов",
        E: "spirit",
        T: "NGP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духу",
        E: "spirit",
        T: "NDS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духам",
        E: "spirit",
        T: "NDP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дух",
        E: "spirit",
        T: "NAS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духи",
        E: "spirit",
        T: "NAP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духом",
        E: "spirit",
        T: "NIS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духами",
        E: "spirit",
        T: "NIP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духе",
        E: "spirit",
        T: "NPS",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "духах",
        E: "spirit",
        T: "NPP",
        A: "I",
        O: "дух",
        RA: 588,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "план",
        E: "plan",
        T: "NNS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планы",
        E: "plan",
        T: "NNP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "плана",
        E: "plan",
        T: "NGS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планов",
        E: "plan",
        T: "NGP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "плану",
        E: "plan",
        T: "NDS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планам",
        E: "plan",
        T: "NDP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "план",
        E: "plan",
        T: "NAS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планы",
        E: "plan",
        T: "NAP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планом",
        E: "plan",
        T: "NIS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планами",
        E: "plan",
        T: "NIP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "плане",
        E: "plan",
        T: "NPS",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "планах",
        E: "plan",
        T: "NPP",
        A: "I",
        O: "план",
        RA: 589,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зал",
        E: "hall",
        T: "NNS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залы",
        E: "hall",
        T: "NNP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зала",
        E: "hall",
        T: "NGS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залов",
        E: "hall",
        T: "NGP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залу",
        E: "hall",
        T: "NDS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залам",
        E: "hall",
        T: "NDP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зал",
        E: "hall",
        T: "NAS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залы",
        E: "hall",
        T: "NAP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залом",
        E: "hall",
        T: "NIS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залами",
        E: "hall",
        T: "NIP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зале",
        E: "hall",
        T: "NPS",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "залах",
        E: "hall",
        T: "NPP",
        A: "I",
        O: "зал",
        RA: 591,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директор",
        E: "director, manager",
        T: "NNS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директора",
        E: "director, manager",
        T: "NNP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директора",
        E: "director, manager",
        T: "NGS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директоров",
        E: "director, manager",
        T: "NGP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директору",
        E: "director, manager",
        T: "NDS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директорам",
        E: "director, manager",
        T: "NDP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директора",
        E: "director, manager",
        T: "NAS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директоров",
        E: "director, manager",
        T: "NAP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директором",
        E: "director, manager",
        T: "NIS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директорами",
        E: "director, manager",
        T: "NIP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директоре",
        E: "director, manager",
        T: "NPS",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "директорах",
        E: "director, manager",
        T: "NPP",
        A: "A",
        O: "директор",
        RA: 594,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "память",
        E: "memory",
        T: "NNS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памяти",
        E: "memory",
        T: "NNP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памяти",
        E: "memory",
        T: "NGS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памятей",
        E: "memory",
        T: "NGP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памяти",
        E: "memory",
        T: "NDS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памятям",
        E: "memory",
        T: "NDP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "память",
        E: "memory",
        T: "NAS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памяти",
        E: "memory",
        T: "NAP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памятью",
        E: "memory",
        T: "NIS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памятями",
        E: "memory",
        T: "NIP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памяти",
        E: "memory",
        T: "NPS",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "памятях",
        E: "memory",
        T: "NPP",
        A: "I",
        O: "память",
        RA: 596,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "результат",
        E: "result, outcome",
        T: "NNS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результаты",
        E: "result, outcome",
        T: "NNP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результата",
        E: "result, outcome",
        T: "NGS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результатов",
        E: "result, outcome",
        T: "NGP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результату",
        E: "result, outcome",
        T: "NDS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результатам",
        E: "result, outcome",
        T: "NDP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результат",
        E: "result, outcome",
        T: "NAS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результаты",
        E: "result, outcome",
        T: "NAP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результатом",
        E: "result, outcome",
        T: "NIS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результатами",
        E: "result, outcome",
        T: "NIP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результате",
        E: "result, outcome",
        T: "NPS",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "результатах",
        E: "result, outcome",
        T: "NPP",
        A: "I",
        O: "результат",
        RA: 599,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "след",
        E: "track, footprint",
        T: "NNS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следы",
        E: "track, footprint",
        T: "NNP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следа",
        E: "track, footprint",
        T: "NGS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следов",
        E: "track, footprint",
        T: "NGP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следу",
        E: "track, footprint",
        T: "NDS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следам",
        E: "track, footprint",
        T: "NDP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "след",
        E: "track, footprint",
        T: "NAS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следы",
        E: "track, footprint",
        T: "NAP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следом",
        E: "track, footprint",
        T: "NIS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следами",
        E: "track, footprint",
        T: "NIP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следе",
        E: "track, footprint",
        T: "NPS",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "следах",
        E: "track, footprint",
        T: "NPP",
        A: "I",
        O: "след",
        RA: 604,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бутылка",
        E: "bottle",
        T: "NNS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылки",
        E: "bottle",
        T: "NNP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылки",
        E: "bottle",
        T: "NGS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылок",
        E: "bottle",
        T: "NGP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылке",
        E: "bottle",
        T: "NDS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылкам",
        E: "bottle",
        T: "NDP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылку",
        E: "bottle",
        T: "NAS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылки",
        E: "bottle",
        T: "NAP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылкой",
        E: "bottle",
        T: "NIS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылками",
        E: "bottle",
        T: "NIP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылке",
        E: "bottle",
        T: "NPS",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бутылках",
        E: "bottle",
        T: "NPP",
        A: "I",
        O: "бутылка",
        RA: 606,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "условие",
        E: "condition, term",
        T: "NNS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условия",
        E: "condition, term",
        T: "NNP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условия",
        E: "condition, term",
        T: "NGS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условий",
        E: "condition, term",
        T: "NGP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условию",
        E: "condition, term",
        T: "NDS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условиям",
        E: "condition, term",
        T: "NDP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условие",
        E: "condition, term",
        T: "NAS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условия",
        E: "condition, term",
        T: "NAP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условием",
        E: "condition, term",
        T: "NIS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условиями",
        E: "condition, term",
        T: "NIP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условии",
        E: "condition, term",
        T: "NPS",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "условиях",
        E: "condition, term",
        T: "NPP",
        A: "I",
        O: "условие",
        RA: 609,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "ум",
        E: "mind, brains, intellect",
        T: "NNS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умы",
        E: "mind, brains, intellect",
        T: "NNP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ума",
        E: "mind, brains, intellect",
        T: "NGS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умов",
        E: "mind, brains, intellect",
        T: "NGP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уму",
        E: "mind, brains, intellect",
        T: "NDS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умам",
        E: "mind, brains, intellect",
        T: "NDP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ум",
        E: "mind, brains, intellect",
        T: "NAS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умы",
        E: "mind, brains, intellect",
        T: "NAP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умом",
        E: "mind, brains, intellect",
        T: "NIS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умами",
        E: "mind, brains, intellect",
        T: "NIP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уме",
        E: "mind, brains, intellect",
        T: "NPS",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "умах",
        E: "mind, brains, intellect",
        T: "NPP",
        A: "I",
        O: "ум",
        RA: 611,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процесс",
        E: "process",
        T: "NNS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессы",
        E: "process",
        T: "NNP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процесса",
        E: "process",
        T: "NGS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессов",
        E: "process",
        T: "NGP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессу",
        E: "process",
        T: "NDS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессам",
        E: "process",
        T: "NDP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процесс",
        E: "process",
        T: "NAS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессы",
        E: "process",
        T: "NAP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессом",
        E: "process",
        T: "NIS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессами",
        E: "process",
        T: "NIP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессе",
        E: "process",
        T: "NPS",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "процессах",
        E: "process",
        T: "NPP",
        A: "I",
        O: "процесс",
        RA: 613,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "картина",
        E: "picture, painting",
        T: "NNS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картины",
        E: "picture, painting",
        T: "NNP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картины",
        E: "picture, painting",
        T: "NGS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картин",
        E: "picture, painting",
        T: "NGP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картине",
        E: "picture, painting",
        T: "NDS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картинам",
        E: "picture, painting",
        T: "NDP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картину",
        E: "picture, painting",
        T: "NAS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картины",
        E: "picture, painting",
        T: "NAP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картиной",
        E: "picture, painting",
        T: "NIS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картинами",
        E: "picture, painting",
        T: "NIP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картине",
        E: "picture, painting",
        T: "NPS",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картинах",
        E: "picture, painting",
        T: "NPP",
        A: "I",
        O: "картина",
        RA: 614,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "центр",
        E: "center",
        T: "NNS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центры",
        E: "center",
        T: "NNP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центра",
        E: "center",
        T: "NGS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центров",
        E: "center",
        T: "NGP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центру",
        E: "center",
        T: "NDS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центрам",
        E: "center",
        T: "NDP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центр",
        E: "center",
        T: "NAS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центры",
        E: "center",
        T: "NAP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центром",
        E: "center",
        T: "NIS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центрами",
        E: "center",
        T: "NIP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центре",
        E: "center",
        T: "NPS",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "центрах",
        E: "center",
        T: "NPP",
        A: "I",
        O: "центр",
        RA: 618,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "будущее",
        E: "future",
        T: "NNS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NNP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "будущего",
        E: "future",
        T: "NGS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NGP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "будущему",
        E: "future",
        T: "NDS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NDP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "будущее",
        E: "future",
        T: "NAS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NAP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "будущим",
        E: "future",
        T: "NIS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NIP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "будущем",
        E: "future",
        T: "NPS",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "future",
        T: "NPP",
        A: "I",
        O: "будущее",
        RA: 624,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "число",
        E: "number",
        T: "NNS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числа",
        E: "number",
        T: "NNP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числа",
        E: "number",
        T: "NGS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "чисел",
        E: "number",
        T: "NGP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числу",
        E: "number",
        T: "NDS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числам",
        E: "number",
        T: "NDP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "число",
        E: "number",
        T: "NAS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числа",
        E: "number",
        T: "NAP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числом",
        E: "number",
        T: "NIS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числами",
        E: "number",
        T: "NIP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числе",
        E: "number",
        T: "NPS",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "числах",
        E: "number",
        T: "NPP",
        A: "I",
        O: "число",
        RA: 626,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "рубль",
        E: "ruble",
        T: "NNS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рубли",
        E: "ruble",
        T: "NNP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рубля",
        E: "ruble",
        T: "NGS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублей",
        E: "ruble",
        T: "NGP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублю",
        E: "ruble",
        T: "NDS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублям",
        E: "ruble",
        T: "NDP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рубль",
        E: "ruble",
        T: "NAS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рубли",
        E: "ruble",
        T: "NAP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублём",
        E: "ruble",
        T: "NIS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублями",
        E: "ruble",
        T: "NIP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рубле",
        E: "ruble",
        T: "NPS",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рублях",
        E: "ruble",
        T: "NPP",
        A: "I",
        O: "рубль",
        RA: 628,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вера",
        E: "faith, belief",
        T: "NNS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "веры",
        E: "faith, belief",
        T: "NNP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "веры",
        E: "faith, belief",
        T: "NGS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вер",
        E: "faith, belief",
        T: "NGP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вере",
        E: "faith, belief",
        T: "NDS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "верам",
        E: "faith, belief",
        T: "NDP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "веру",
        E: "faith, belief",
        T: "NAS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "веры",
        E: "faith, belief",
        T: "NAP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "верой",
        E: "faith, belief",
        T: "NIS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "верами",
        E: "faith, belief",
        T: "NIP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вере",
        E: "faith, belief",
        T: "NPS",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "верах",
        E: "faith, belief",
        T: "NPP",
        A: "I",
        O: "вера",
        RA: 631,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "удар",
        E: "blow, stroke",
        T: "NNS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удары",
        E: "blow, stroke",
        T: "NNP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удара",
        E: "blow, stroke",
        T: "NGS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударов",
        E: "blow, stroke",
        T: "NGP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удару",
        E: "blow, stroke",
        T: "NDS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударам",
        E: "blow, stroke",
        T: "NDP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удар",
        E: "blow, stroke",
        T: "NAS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удары",
        E: "blow, stroke",
        T: "NAP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударом",
        E: "blow, stroke",
        T: "NIS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударами",
        E: "blow, stroke",
        T: "NIP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударе",
        E: "blow, stroke",
        T: "NPS",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ударах",
        E: "blow, stroke",
        T: "NPP",
        A: "I",
        O: "удар",
        RA: 633,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефон",
        E: "telephone",
        T: "NNS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефоны",
        E: "telephone",
        T: "NNP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефона",
        E: "telephone",
        T: "NGS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефонов",
        E: "telephone",
        T: "NGP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефону",
        E: "telephone",
        T: "NDS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефонам",
        E: "telephone",
        T: "NDP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефон",
        E: "telephone",
        T: "NAS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефоны",
        E: "telephone",
        T: "NAP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефоном",
        E: "telephone",
        T: "NIS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефонами",
        E: "telephone",
        T: "NIP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефоне",
        E: "telephone",
        T: "NPS",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телефонах",
        E: "telephone",
        T: "NPP",
        A: "I",
        O: "телефон",
        RA: 634,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "колено",
        E: "knee",
        T: "NNS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колени",
        E: "knee",
        T: "NNP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колена",
        E: "knee",
        T: "NGS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коленей",
        E: "knee",
        T: "NGP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колену",
        E: "knee",
        T: "NDS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коленям",
        E: "knee",
        T: "NDP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колено",
        E: "knee",
        T: "NAS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колени",
        E: "knee",
        T: "NAP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коленом",
        E: "knee",
        T: "NIS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коленями",
        E: "knee",
        T: "NIP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "колене",
        E: "knee",
        T: "NPS",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коленях",
        E: "knee",
        T: "NPP",
        A: "I",
        O: "колено",
        RA: 635,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "коридор",
        E: "corridor, passage",
        T: "NNS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридоры",
        E: "corridor, passage",
        T: "NNP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридора",
        E: "corridor, passage",
        T: "NGS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридоров",
        E: "corridor, passage",
        T: "NGP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридору",
        E: "corridor, passage",
        T: "NDS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридорам",
        E: "corridor, passage",
        T: "NDP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридор",
        E: "corridor, passage",
        T: "NAS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридоры",
        E: "corridor, passage",
        T: "NAP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридором",
        E: "corridor, passage",
        T: "NIS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридорами",
        E: "corridor, passage",
        T: "NIP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридоре",
        E: "corridor, passage",
        T: "NPS",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "коридорах",
        E: "corridor, passage",
        T: "NPP",
        A: "I",
        O: "коридор",
        RA: 638,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужик",
        E: "muzhik, man",
        T: "NNS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужики",
        E: "muzhik, man",
        T: "NNP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужика",
        E: "muzhik, man",
        T: "NGS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужиков",
        E: "muzhik, man",
        T: "NGP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужику",
        E: "muzhik, man",
        T: "NDS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужикам",
        E: "muzhik, man",
        T: "NDP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужика",
        E: "muzhik, man",
        T: "NAS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужиков",
        E: "muzhik, man",
        T: "NAP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужиком",
        E: "muzhik, man",
        T: "NIS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужиками",
        E: "muzhik, man",
        T: "NIP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужике",
        E: "muzhik, man",
        T: "NPS",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мужиках",
        E: "muzhik, man",
        T: "NPP",
        A: "A",
        O: "мужик",
        RA: 639,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "автор",
        E: "author",
        T: "NNS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторы",
        E: "author",
        T: "NNP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "автора",
        E: "author",
        T: "NGS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторов",
        E: "author",
        T: "NGP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "автору",
        E: "author",
        T: "NDS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторам",
        E: "author",
        T: "NDP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "автора",
        E: "author",
        T: "NAS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторов",
        E: "author",
        T: "NAP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "автором",
        E: "author",
        T: "NIS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторами",
        E: "author",
        T: "NIP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторе",
        E: "author",
        T: "NPS",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "авторах",
        E: "author",
        T: "NPP",
        A: "A",
        O: "автор",
        RA: 641,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "встреча",
        E: "meeting, reception",
        T: "NNS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречи",
        E: "meeting, reception",
        T: "NNP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречи",
        E: "meeting, reception",
        T: "NGS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встреч",
        E: "meeting, reception",
        T: "NGP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встрече",
        E: "meeting, reception",
        T: "NDS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречам",
        E: "meeting, reception",
        T: "NDP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречу",
        E: "meeting, reception",
        T: "NAS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречи",
        E: "meeting, reception",
        T: "NAP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречей",
        E: "meeting, reception",
        T: "NIS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречами",
        E: "meeting, reception",
        T: "NIP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встрече",
        E: "meeting, reception",
        T: "NPS",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "встречах",
        E: "meeting, reception",
        T: "NPP",
        A: "I",
        O: "встреча",
        RA: 645,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кабинет",
        E: "study, room, office suite",
        T: "NNS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинеты",
        E: "study, room, office suite",
        T: "NNP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинета",
        E: "study, room, office suite",
        T: "NGS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинетов",
        E: "study, room, office suite",
        T: "NGP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинету",
        E: "study, room, office suite",
        T: "NDS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинетам",
        E: "study, room, office suite",
        T: "NDP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинет",
        E: "study, room, office suite",
        T: "NAS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинеты",
        E: "study, room, office suite",
        T: "NAP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинетом",
        E: "study, room, office suite",
        T: "NIS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинетами",
        E: "study, room, office suite",
        T: "NIP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинете",
        E: "study, room, office suite",
        T: "NPS",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кабинетах",
        E: "study, room, office suite",
        T: "NPP",
        A: "I",
        O: "кабинет",
        RA: 646,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документ",
        E: "document",
        T: "NNS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документы",
        E: "document",
        T: "NNP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документа",
        E: "document",
        T: "NGS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документов",
        E: "document",
        T: "NGP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документу",
        E: "document",
        T: "NDS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документам",
        E: "document",
        T: "NDP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документ",
        E: "document",
        T: "NAS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документы",
        E: "document",
        T: "NAP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документом",
        E: "document",
        T: "NIS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документами",
        E: "document",
        T: "NIP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документе",
        E: "document",
        T: "NPS",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "документах",
        E: "document",
        T: "NPP",
        A: "I",
        O: "документ",
        RA: 647,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёт",
        E: "aircraft, aeroplane",
        T: "NNS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёты",
        E: "aircraft, aeroplane",
        T: "NNP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёта",
        E: "aircraft, aeroplane",
        T: "NGS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолётов",
        E: "aircraft, aeroplane",
        T: "NGP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёту",
        E: "aircraft, aeroplane",
        T: "NDS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолётам",
        E: "aircraft, aeroplane",
        T: "NDP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёт",
        E: "aircraft, aeroplane",
        T: "NAS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёты",
        E: "aircraft, aeroplane",
        T: "NAP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолётом",
        E: "aircraft, aeroplane",
        T: "NIS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолётами",
        E: "aircraft, aeroplane",
        T: "NIP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолёте",
        E: "aircraft, aeroplane",
        T: "NPS",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "самолётах",
        E: "aircraft, aeroplane",
        T: "NPP",
        A: "I",
        O: "самолёт",
        RA: 648,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "игра",
        E: "game, play",
        T: "NNS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игры",
        E: "game, play",
        T: "NNP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игры",
        E: "game, play",
        T: "NGS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игр",
        E: "game, play",
        T: "NGP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игре",
        E: "game, play",
        T: "NDS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "играм",
        E: "game, play",
        T: "NDP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игру",
        E: "game, play",
        T: "NAS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игры",
        E: "game, play",
        T: "NAP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игрой",
        E: "game, play",
        T: "NIS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "играми",
        E: "game, play",
        T: "NIP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "игре",
        E: "game, play",
        T: "NPS",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "играх",
        E: "game, play",
        T: "NPP",
        A: "I",
        O: "игра",
        RA: 651,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рассказ",
        E: "story",
        T: "NNS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказы",
        E: "story",
        T: "NNP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказа",
        E: "story",
        T: "NGS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказов",
        E: "story",
        T: "NGP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказу",
        E: "story",
        T: "NDS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказам",
        E: "story",
        T: "NDP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказ",
        E: "story",
        T: "NAS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказы",
        E: "story",
        T: "NAP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказом",
        E: "story",
        T: "NIS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказами",
        E: "story",
        T: "NIP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказе",
        E: "story",
        T: "NPS",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рассказах",
        E: "story",
        T: "NPP",
        A: "I",
        O: "рассказ",
        RA: 652,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлеб",
        E: "bread",
        T: "NNS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебы",
        E: "bread",
        T: "NNP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлеба",
        E: "bread",
        T: "NGS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебов",
        E: "bread",
        T: "NGP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебу",
        E: "bread",
        T: "NDS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебам",
        E: "bread",
        T: "NDP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлеб",
        E: "bread",
        T: "NAS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебы",
        E: "bread",
        T: "NAP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебом",
        E: "bread",
        T: "NIS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебами",
        E: "bread",
        T: "NIP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебе",
        E: "bread",
        T: "NPS",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "хлебах",
        E: "bread",
        T: "NPP",
        A: "I",
        O: "хлеб",
        RA: 653,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "развитие",
        E: "development",
        T: "NNS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развития",
        E: "development",
        T: "NNP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развития",
        E: "development",
        T: "NGS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитий",
        E: "development",
        T: "NGP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитию",
        E: "development",
        T: "NDS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитиям",
        E: "development",
        T: "NDP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитие",
        E: "development",
        T: "NAS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развития",
        E: "development",
        T: "NAP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитием",
        E: "development",
        T: "NIS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитиями",
        E: "development",
        T: "NIP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитии",
        E: "development",
        T: "NPS",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "развитиях",
        E: "development",
        T: "NPP",
        A: "I",
        O: "развитие",
        RA: 654,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "трубка",
        E: "tube, roll, pipe",
        T: "NNS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубки",
        E: "tube, roll, pipe",
        T: "NNP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубки",
        E: "tube, roll, pipe",
        T: "NGS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубок",
        E: "tube, roll, pipe",
        T: "NGP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубке",
        E: "tube, roll, pipe",
        T: "NDS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубкам",
        E: "tube, roll, pipe",
        T: "NDP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубку",
        E: "tube, roll, pipe",
        T: "NAS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубки",
        E: "tube, roll, pipe",
        T: "NAP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубкой",
        E: "tube, roll, pipe",
        T: "NIS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубками",
        E: "tube, roll, pipe",
        T: "NIP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубке",
        E: "tube, roll, pipe",
        T: "NPS",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трубках",
        E: "tube, roll, pipe",
        T: "NPP",
        A: "I",
        O: "трубка",
        RA: 664,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "враг",
        E: "enemy",
        T: "NNS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "враги",
        E: "enemy",
        T: "NNP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врага",
        E: "enemy",
        T: "NGS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагов",
        E: "enemy",
        T: "NGP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагу",
        E: "enemy",
        T: "NDS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагам",
        E: "enemy",
        T: "NDP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врага",
        E: "enemy",
        T: "NAS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагов",
        E: "enemy",
        T: "NAP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагом",
        E: "enemy",
        T: "NIS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагами",
        E: "enemy",
        T: "NIP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "враге",
        E: "enemy",
        T: "NPS",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "врагах",
        E: "enemy",
        T: "NPP",
        A: "A",
        O: "враг",
        RA: 665,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктор",
        E: "doctor",
        T: "NNS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктора",
        E: "doctor",
        T: "NNP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктора",
        E: "doctor",
        T: "NGS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторов",
        E: "doctor",
        T: "NGP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктору",
        E: "doctor",
        T: "NDS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторам",
        E: "doctor",
        T: "NDP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктора",
        E: "doctor",
        T: "NAS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторов",
        E: "doctor",
        T: "NAP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "доктором",
        E: "doctor",
        T: "NIS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторами",
        E: "doctor",
        T: "NIP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторе",
        E: "doctor",
        T: "NPS",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "докторах",
        E: "doctor",
        T: "NPP",
        A: "A",
        O: "доктор",
        RA: 668,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ладонь",
        E: "palm",
        T: "NNS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладони",
        E: "palm",
        T: "NNP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладони",
        E: "palm",
        T: "NGS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладоней",
        E: "palm",
        T: "NGP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладони",
        E: "palm",
        T: "NDS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладоням",
        E: "palm",
        T: "NDP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладонь",
        E: "palm",
        T: "NAS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладони",
        E: "palm",
        T: "NAP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладонью",
        E: "palm",
        T: "NIS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладонями",
        E: "palm",
        T: "NIP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладони",
        E: "palm",
        T: "NPS",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ладонях",
        E: "palm",
        T: "NPP",
        A: "I",
        O: "ладонь",
        RA: 669,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "наука",
        E: "science",
        T: "NNS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науки",
        E: "science",
        T: "NNP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науки",
        E: "science",
        T: "NGS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "наук",
        E: "science",
        T: "NGP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науке",
        E: "science",
        T: "NDS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "наукам",
        E: "science",
        T: "NDP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науку",
        E: "science",
        T: "NAS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науки",
        E: "science",
        T: "NAP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "наукой",
        E: "science",
        T: "NIS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науками",
        E: "science",
        T: "NIP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науке",
        E: "science",
        T: "NPS",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "науках",
        E: "science",
        T: "NPP",
        A: "I",
        O: "наука",
        RA: 673,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лейтенант",
        E: "lieutenant",
        T: "NNS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенанты",
        E: "lieutenant",
        T: "NNP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенанта",
        E: "lieutenant",
        T: "NGS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантов",
        E: "lieutenant",
        T: "NGP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенанту",
        E: "lieutenant",
        T: "NDS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантам",
        E: "lieutenant",
        T: "NDP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенанта",
        E: "lieutenant",
        T: "NAS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантов",
        E: "lieutenant",
        T: "NAP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантом",
        E: "lieutenant",
        T: "NIS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантами",
        E: "lieutenant",
        T: "NIP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенанте",
        E: "lieutenant",
        T: "NPS",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лейтенантах",
        E: "lieutenant",
        T: "NPP",
        A: "A",
        O: "лейтенант",
        RA: 674,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "служба",
        E: "service, work",
        T: "NNS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службы",
        E: "service, work",
        T: "NNP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службы",
        E: "service, work",
        T: "NGS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "служб",
        E: "service, work",
        T: "NGP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службе",
        E: "service, work",
        T: "NDS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службам",
        E: "service, work",
        T: "NDP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службу",
        E: "service, work",
        T: "NAS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службы",
        E: "service, work",
        T: "NAP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службой",
        E: "service, work",
        T: "NIS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службами",
        E: "service, work",
        T: "NIP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службе",
        E: "service, work",
        T: "NPS",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "службах",
        E: "service, work",
        T: "NPP",
        A: "I",
        O: "служба",
        RA: 675,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "счёт",
        E: "bill, account",
        T: "NNS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счета",
        E: "bill, account",
        T: "NNP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счёта",
        E: "bill, account",
        T: "NGS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счетов",
        E: "bill, account",
        T: "NGP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счёту",
        E: "bill, account",
        T: "NDS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счетам",
        E: "bill, account",
        T: "NDP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счёт",
        E: "bill, account",
        T: "NAS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счета",
        E: "bill, account",
        T: "NAP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счётом",
        E: "bill, account",
        T: "NIS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счетами",
        E: "bill, account",
        T: "NIP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счёте",
        E: "bill, account",
        T: "NPS",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "счетах",
        E: "bill, account",
        T: "NPP",
        A: "I",
        O: "счёт",
        RA: 679,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кухня",
        E: "kitchen",
        T: "NNS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухни",
        E: "kitchen",
        T: "NNP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухни",
        E: "kitchen",
        T: "NGS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухонь",
        E: "kitchen",
        T: "NGP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухне",
        E: "kitchen",
        T: "NDS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухням",
        E: "kitchen",
        T: "NDP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухню",
        E: "kitchen",
        T: "NAS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухни",
        E: "kitchen",
        T: "NAP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухней",
        E: "kitchen",
        T: "NIS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухнями",
        E: "kitchen",
        T: "NIP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухне",
        E: "kitchen",
        T: "NPS",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кухнях",
        E: "kitchen",
        T: "NPP",
        A: "I",
        O: "кухня",
        RA: 683,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "решение",
        E: "decision, conclusion",
        T: "NNS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решения",
        E: "decision, conclusion",
        T: "NNP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решения",
        E: "decision, conclusion",
        T: "NGS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решений",
        E: "decision, conclusion",
        T: "NGP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решению",
        E: "decision, conclusion",
        T: "NDS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решениям",
        E: "decision, conclusion",
        T: "NDP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решение",
        E: "decision, conclusion",
        T: "NAS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решения",
        E: "decision, conclusion",
        T: "NAP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решением",
        E: "decision, conclusion",
        T: "NIS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решениями",
        E: "decision, conclusion",
        T: "NIP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решении",
        E: "decision, conclusion",
        T: "NPS",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "решениях",
        E: "decision, conclusion",
        T: "NPP",
        A: "I",
        O: "решение",
        RA: 685,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "роман",
        E: "novel, romance",
        T: "NNS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романы",
        E: "novel, romance",
        T: "NNP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романа",
        E: "novel, romance",
        T: "NGS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романов",
        E: "novel, romance",
        T: "NGP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роману",
        E: "novel, romance",
        T: "NDS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романам",
        E: "novel, romance",
        T: "NDP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роман",
        E: "novel, romance",
        T: "NAS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романы",
        E: "novel, romance",
        T: "NAP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романом",
        E: "novel, romance",
        T: "NIS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романами",
        E: "novel, romance",
        T: "NIP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романе",
        E: "novel, romance",
        T: "NPS",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "романах",
        E: "novel, romance",
        T: "NPP",
        A: "I",
        O: "роман",
        RA: 688,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "компания",
        E: "company",
        T: "NNS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компании",
        E: "company",
        T: "NNP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компании",
        E: "company",
        T: "NGS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компаний",
        E: "company",
        T: "NGP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компании",
        E: "company",
        T: "NDS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компаниям",
        E: "company",
        T: "NDP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компанию",
        E: "company",
        T: "NAS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компании",
        E: "company",
        T: "NAP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компанией",
        E: "company",
        T: "NIS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компаниями",
        E: "company",
        T: "NIP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компании",
        E: "company",
        T: "NPS",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "компаниях",
        E: "company",
        T: "NPP",
        A: "I",
        O: "компания",
        RA: 690,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "течение",
        E: "current",
        T: "NNS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течения",
        E: "current",
        T: "NNP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течения",
        E: "current",
        T: "NGS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течений",
        E: "current",
        T: "NGP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течению",
        E: "current",
        T: "NDS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течениям",
        E: "current",
        T: "NDP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течение",
        E: "current",
        T: "NAS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течения",
        E: "current",
        T: "NAP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течением",
        E: "current",
        T: "NIS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течениями",
        E: "current",
        T: "NIP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течении",
        E: "current",
        T: "NPS",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "течениях",
        E: "current",
        T: "NPP",
        A: "I",
        O: "течение",
        RA: 695,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "метр",
        E: "meter, metre",
        T: "NNS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метры",
        E: "meter, metre",
        T: "NNP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метра",
        E: "meter, metre",
        T: "NGS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метров",
        E: "meter, metre",
        T: "NGP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метру",
        E: "meter, metre",
        T: "NDS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метрам",
        E: "meter, metre",
        T: "NDP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метр",
        E: "meter, metre",
        T: "NAS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метры",
        E: "meter, metre",
        T: "NAP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метром",
        E: "meter, metre",
        T: "NIS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метрами",
        E: "meter, metre",
        T: "NIP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метре",
        E: "meter, metre",
        T: "NPS",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "метрах",
        E: "meter, metre",
        T: "NPP",
        A: "I",
        O: "метр",
        RA: 699,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институт",
        E: "institute",
        T: "NNS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институты",
        E: "institute",
        T: "NNP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "института",
        E: "institute",
        T: "NGS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институтов",
        E: "institute",
        T: "NGP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институту",
        E: "institute",
        T: "NDS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институтам",
        E: "institute",
        T: "NDP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институт",
        E: "institute",
        T: "NAS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институты",
        E: "institute",
        T: "NAP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институтом",
        E: "institute",
        T: "NIS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институтами",
        E: "institute",
        T: "NIP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институте",
        E: "institute",
        T: "NPS",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "институтах",
        E: "institute",
        T: "NPP",
        A: "I",
        O: "институт",
        RA: 702,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интерес",
        E: "interes",
        T: "NNS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересы",
        E: "interes",
        T: "NNP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интереса",
        E: "interes",
        T: "NGS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересов",
        E: "interes",
        T: "NGP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересу",
        E: "interes",
        T: "NDS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересам",
        E: "interes",
        T: "NDP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интерес",
        E: "interes",
        T: "NAS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересы",
        E: "interes",
        T: "NAP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересом",
        E: "interes",
        T: "NIS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересами",
        E: "interes",
        T: "NIP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересе",
        E: "interes",
        T: "NPS",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "интересах",
        E: "interes",
        T: "NPP",
        A: "I",
        O: "интерес",
        RA: 704,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "половина",
        E: "half",
        T: "NNS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половины",
        E: "half",
        T: "NNP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половины",
        E: "half",
        T: "NGS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половин",
        E: "half",
        T: "NGP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половине",
        E: "half",
        T: "NDS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половинам",
        E: "half",
        T: "NDP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половину",
        E: "half",
        T: "NAS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половины",
        E: "half",
        T: "NAP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половиной",
        E: "half",
        T: "NIS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половинами",
        E: "half",
        T: "NIP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половине",
        E: "half",
        T: "NPS",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "половинах",
        E: "half",
        T: "NPP",
        A: "I",
        O: "половина",
        RA: 710,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "качество",
        E: "quality",
        T: "NNS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качества",
        E: "quality",
        T: "NNP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качества",
        E: "quality",
        T: "NGS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качеств",
        E: "quality",
        T: "NGP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качеству",
        E: "quality",
        T: "NDS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качествам",
        E: "quality",
        T: "NDP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качество",
        E: "quality",
        T: "NAS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качества",
        E: "quality",
        T: "NAP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качеством",
        E: "quality",
        T: "NIS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качествами",
        E: "quality",
        T: "NIP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качестве",
        E: "quality",
        T: "NPS",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "качествах",
        E: "quality",
        T: "NPP",
        A: "I",
        O: "качество",
        RA: 714,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "бой",
        E: "battle, fight, combat",
        T: "NNS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бои",
        E: "battle, fight, combat",
        T: "NNP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боя",
        E: "battle, fight, combat",
        T: "NGS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боёв",
        E: "battle, fight, combat",
        T: "NGP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бою",
        E: "battle, fight, combat",
        T: "NDS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боям",
        E: "battle, fight, combat",
        T: "NDP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бой",
        E: "battle, fight, combat",
        T: "NAS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бои",
        E: "battle, fight, combat",
        T: "NAP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боем",
        E: "battle, fight, combat",
        T: "NIS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боями",
        E: "battle, fight, combat",
        T: "NIP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бое",
        E: "battle, fight, combat",
        T: "NPS",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боях",
        E: "battle, fight, combat",
        T: "NPP",
        A: "I",
        O: "бой",
        RA: 715,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "шея",
        E: "neck",
        T: "NNS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеи",
        E: "neck",
        T: "NNP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеи",
        E: "neck",
        T: "NGS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шей",
        E: "neck",
        T: "NGP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шее",
        E: "neck",
        T: "NDS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеям",
        E: "neck",
        T: "NDP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шею",
        E: "neck",
        T: "NAS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеи",
        E: "neck",
        T: "NAP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеей",
        E: "neck",
        T: "NIS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеями",
        E: "neck",
        T: "NIP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шее",
        E: "neck",
        T: "NPS",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "шеях",
        E: "neck",
        T: "NPP",
        A: "I",
        O: "шея",
        RA: 716,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идея",
        E: "idea",
        T: "NNS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеи",
        E: "idea",
        T: "NNP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеи",
        E: "idea",
        T: "NGS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идей",
        E: "idea",
        T: "NGP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идее",
        E: "idea",
        T: "NDS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеям",
        E: "idea",
        T: "NDP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идею",
        E: "idea",
        T: "NAS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеи",
        E: "idea",
        T: "NAP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеей",
        E: "idea",
        T: "NIS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеями",
        E: "idea",
        T: "NIP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идее",
        E: "idea",
        T: "NPS",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "идеях",
        E: "idea",
        T: "NPP",
        A: "I",
        O: "идея",
        RA: 718,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трава",
        E: "grass",
        T: "NNS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травы",
        E: "grass",
        T: "NNP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травы",
        E: "grass",
        T: "NGS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "трав",
        E: "grass",
        T: "NGP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "траве",
        E: "grass",
        T: "NDS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травам",
        E: "grass",
        T: "NDP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "траву",
        E: "grass",
        T: "NAS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травы",
        E: "grass",
        T: "NAP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травой",
        E: "grass",
        T: "NIS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травами",
        E: "grass",
        T: "NIP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "траве",
        E: "grass",
        T: "NPS",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "травах",
        E: "grass",
        T: "NPP",
        A: "I",
        O: "трава",
        RA: 723,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дед",
        E: "grandfather, old man",
        T: "NNS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деды",
        E: "grandfather, old man",
        T: "NNP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деда",
        E: "grandfather, old man",
        T: "NGS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедов",
        E: "grandfather, old man",
        T: "NGP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деду",
        E: "grandfather, old man",
        T: "NDS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедам",
        E: "grandfather, old man",
        T: "NDP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деда",
        E: "grandfather, old man",
        T: "NAS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедов",
        E: "grandfather, old man",
        T: "NAP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедом",
        E: "grandfather, old man",
        T: "NIS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедами",
        E: "grandfather, old man",
        T: "NIP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "деде",
        E: "grandfather, old man",
        T: "NPS",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дедах",
        E: "grandfather, old man",
        T: "NPP",
        A: "A",
        O: "дед",
        RA: 724,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознание",
        E: "consciousness",
        T: "NNS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознания",
        E: "consciousness",
        T: "NNP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознания",
        E: "consciousness",
        T: "NGS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознаний",
        E: "consciousness",
        T: "NGP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознанию",
        E: "consciousness",
        T: "NDS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознаниям",
        E: "consciousness",
        T: "NDP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознание",
        E: "consciousness",
        T: "NAS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознания",
        E: "consciousness",
        T: "NAP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознанием",
        E: "consciousness",
        T: "NIS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознаниями",
        E: "consciousness",
        T: "NIP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознании",
        E: "consciousness",
        T: "NPS",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "сознаниях",
        E: "consciousness",
        T: "NPP",
        A: "I",
        O: "сознание",
        RA: 725,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "родитель",
        E: "parent",
        T: "NNS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родители",
        E: "parent",
        T: "NNP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителя",
        E: "parent",
        T: "NGS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителей",
        E: "parent",
        T: "NGP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителю",
        E: "parent",
        T: "NDS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителям",
        E: "parent",
        T: "NDP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителя",
        E: "parent",
        T: "NAS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителей",
        E: "parent",
        T: "NAP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителем",
        E: "parent",
        T: "NIS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителями",
        E: "parent",
        T: "NIP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителе",
        E: "parent",
        T: "NPS",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родителях",
        E: "parent",
        T: "NPP",
        A: "A",
        O: "родитель",
        RA: 726,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чай",
        E: "tea",
        T: "NNS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаи",
        E: "tea",
        T: "NNP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чая",
        E: "tea",
        T: "NGS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаёв",
        E: "tea",
        T: "NGP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаю",
        E: "tea",
        T: "NDS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаям",
        E: "tea",
        T: "NDP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чай",
        E: "tea",
        T: "NAS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаи",
        E: "tea",
        T: "NAP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаем",
        E: "tea",
        T: "NIS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаями",
        E: "tea",
        T: "NIP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чае",
        E: "tea",
        T: "NPS",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "чаях",
        E: "tea",
        T: "NPP",
        A: "I",
        O: "чай",
        RA: 729,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "род",
        E: "family, clan, generation",
        T: "NNS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роды",
        E: "family, clan, generation",
        T: "NNP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рода",
        E: "family, clan, generation",
        T: "NGS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родов",
        E: "family, clan, generation",
        T: "NGP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роду",
        E: "family, clan, generation",
        T: "NDS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родам",
        E: "family, clan, generation",
        T: "NDP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "род",
        E: "family, clan, generation",
        T: "NAS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роды",
        E: "family, clan, generation",
        T: "NAP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родом",
        E: "family, clan, generation",
        T: "NIS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родами",
        E: "family, clan, generation",
        T: "NIP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роде",
        E: "family, clan, generation",
        T: "NPS",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "родах",
        E: "family, clan, generation",
        T: "NPP",
        A: "I",
        O: "род",
        RA: 732,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звук",
        E: "sound",
        T: "NNS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуки",
        E: "sound",
        T: "NNP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звука",
        E: "sound",
        T: "NGS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуков",
        E: "sound",
        T: "NGP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуку",
        E: "sound",
        T: "NDS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звукам",
        E: "sound",
        T: "NDP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звук",
        E: "sound",
        T: "NAS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуки",
        E: "sound",
        T: "NAP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуком",
        E: "sound",
        T: "NIS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуками",
        E: "sound",
        T: "NIP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуке",
        E: "sound",
        T: "NPS",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "звуках",
        E: "sound",
        T: "NPP",
        A: "I",
        O: "звук",
        RA: 736,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазин",
        E: "shop, store",
        T: "NNS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазины",
        E: "shop, store",
        T: "NNP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазина",
        E: "shop, store",
        T: "NGS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазинов",
        E: "shop, store",
        T: "NGP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазину",
        E: "shop, store",
        T: "NDS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазинам",
        E: "shop, store",
        T: "NDP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазин",
        E: "shop, store",
        T: "NAS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазины",
        E: "shop, store",
        T: "NAP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазином",
        E: "shop, store",
        T: "NIS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазинами",
        E: "shop, store",
        T: "NIP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазине",
        E: "shop, store",
        T: "NPS",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "магазинах",
        E: "shop, store",
        T: "NPP",
        A: "I",
        O: "магазин",
        RA: 738,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президент",
        E: "president",
        T: "NNS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президенты",
        E: "president",
        T: "NNP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президента",
        E: "president",
        T: "NGS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентов",
        E: "president",
        T: "NGP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президенту",
        E: "president",
        T: "NDS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентам",
        E: "president",
        T: "NDP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президента",
        E: "president",
        T: "NAS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентов",
        E: "president",
        T: "NAP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентом",
        E: "president",
        T: "NIS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентами",
        E: "president",
        T: "NIP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президенте",
        E: "president",
        T: "NPS",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "президентах",
        E: "president",
        T: "NPP",
        A: "A",
        O: "президент",
        RA: 739,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэт",
        E: "poet",
        T: "NNS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэты",
        E: "poet",
        T: "NNP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэта",
        E: "poet",
        T: "NGS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтов",
        E: "poet",
        T: "NGP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэту",
        E: "poet",
        T: "NDS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтам",
        E: "poet",
        T: "NDP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэта",
        E: "poet",
        T: "NAS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтов",
        E: "poet",
        T: "NAP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтом",
        E: "poet",
        T: "NIS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтами",
        E: "poet",
        T: "NIP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэте",
        E: "poet",
        T: "NPS",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поэтах",
        E: "poet",
        T: "NPP",
        A: "A",
        O: "поэт",
        RA: 740,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "болезнь",
        E: "illness, sickness, disease",
        T: "NNS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезни",
        E: "illness, sickness, disease",
        T: "NNP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезни",
        E: "illness, sickness, disease",
        T: "NGS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезней",
        E: "illness, sickness, disease",
        T: "NGP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезни",
        E: "illness, sickness, disease",
        T: "NDS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезням",
        E: "illness, sickness, disease",
        T: "NDP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезнь",
        E: "illness, sickness, disease",
        T: "NAS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезни",
        E: "illness, sickness, disease",
        T: "NAP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезнью",
        E: "illness, sickness, disease",
        T: "NIS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезнями",
        E: "illness, sickness, disease",
        T: "NIP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезни",
        E: "illness, sickness, disease",
        T: "NPS",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "болезнях",
        E: "illness, sickness, disease",
        T: "NPP",
        A: "I",
        O: "болезнь",
        RA: 742,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "событие",
        E: "event",
        T: "NNS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "события",
        E: "event",
        T: "NNP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "события",
        E: "event",
        T: "NGS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событий",
        E: "event",
        T: "NGP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событию",
        E: "event",
        T: "NDS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событиям",
        E: "event",
        T: "NDP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событие",
        E: "event",
        T: "NAS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "события",
        E: "event",
        T: "NAP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событием",
        E: "event",
        T: "NIS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событиями",
        E: "event",
        T: "NIP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событии",
        E: "event",
        T: "NPS",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "событиях",
        E: "event",
        T: "NPP",
        A: "I",
        O: "событие",
        RA: 743,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кожа",
        E: "skin",
        T: "NNS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожи",
        E: "skin",
        T: "NNP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожи",
        E: "skin",
        T: "NGS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кож",
        E: "skin",
        T: "NGP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "коже",
        E: "skin",
        T: "NDS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожам",
        E: "skin",
        T: "NDP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожу",
        E: "skin",
        T: "NAS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожи",
        E: "skin",
        T: "NAP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожей",
        E: "skin",
        T: "NIS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожами",
        E: "skin",
        T: "NIP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "коже",
        E: "skin",
        T: "NPS",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кожах",
        E: "skin",
        T: "NPP",
        A: "I",
        O: "кожа",
        RA: 745,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лист",
        E: "leaf, sheet",
        T: "NNS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листья",
        E: "leaf, sheet",
        T: "NNP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листа",
        E: "leaf, sheet",
        T: "NGS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листьев",
        E: "leaf, sheet",
        T: "NGP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листу",
        E: "leaf, sheet",
        T: "NDS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листьям",
        E: "leaf, sheet",
        T: "NDP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лист",
        E: "leaf, sheet",
        T: "NAS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листья",
        E: "leaf, sheet",
        T: "NAP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листом",
        E: "leaf, sheet",
        T: "NIS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листьями",
        E: "leaf, sheet",
        T: "NIP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листе",
        E: "leaf, sheet",
        T: "NPS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листьях",
        E: "leaf, sheet",
        T: "NPP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лист",
        E: "sheet",
        T: "NNS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листы",
        E: "sheet",
        T: "NNP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листа",
        E: "sheet",
        T: "NGS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листов",
        E: "sheet",
        T: "NGP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листу",
        E: "sheet",
        T: "NDS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листам",
        E: "sheet",
        T: "NDP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лист",
        E: "sheet",
        T: "NAS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листы",
        E: "sheet",
        T: "NAP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листом",
        E: "sheet",
        T: "NIS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листами",
        E: "sheet",
        T: "NIP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листе",
        E: "sheet",
        T: "NPS",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "листах",
        E: "sheet",
        T: "NPP",
        A: "I",
        O: "лист",
        RA: 746,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "слеза",
        E: "tear",
        T: "NNS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слёзы",
        E: "tear",
        T: "NNP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезы",
        E: "tear",
        T: "NGS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слёз",
        E: "tear",
        T: "NGP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезе",
        E: "tear",
        T: "NDS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезам",
        E: "tear",
        T: "NDP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезу",
        E: "tear",
        T: "NAS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слёзы",
        E: "tear",
        T: "NAP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезой",
        E: "tear",
        T: "NIS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезами",
        E: "tear",
        T: "NIP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезе",
        E: "tear",
        T: "NPS",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слезах",
        E: "tear",
        T: "NPP",
        A: "I",
        O: "слеза",
        RA: 750,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежда",
        E: "hope",
        T: "NNS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежды",
        E: "hope",
        T: "NNP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежды",
        E: "hope",
        T: "NGS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежд",
        E: "hope",
        T: "NGP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежде",
        E: "hope",
        T: "NDS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надеждам",
        E: "hope",
        T: "NDP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежду",
        E: "hope",
        T: "NAS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежды",
        E: "hope",
        T: "NAP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надеждой",
        E: "hope",
        T: "NIS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надеждами",
        E: "hope",
        T: "NIP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надежде",
        E: "hope",
        T: "NPS",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "надеждах",
        E: "hope",
        T: "NPP",
        A: "I",
        O: "надежда",
        RA: 751,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литература",
        E: "literature",
        T: "NNS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературы",
        E: "literature",
        T: "NNP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературы",
        E: "literature",
        T: "NGS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литератур",
        E: "literature",
        T: "NGP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературе",
        E: "literature",
        T: "NDS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературам",
        E: "literature",
        T: "NDP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературу",
        E: "literature",
        T: "NAS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературы",
        E: "literature",
        T: "NAP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературой",
        E: "literature",
        T: "NIS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературами",
        E: "literature",
        T: "NIP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературе",
        E: "literature",
        T: "NPS",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "литературах",
        E: "literature",
        T: "NPP",
        A: "I",
        O: "литература",
        RA: 755,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "оружие",
        E: "weapon",
        T: "NNS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружия",
        E: "weapon",
        T: "NNP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружия",
        E: "weapon",
        T: "NGS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружий",
        E: "weapon",
        T: "NGP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружию",
        E: "weapon",
        T: "NDS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружиям",
        E: "weapon",
        T: "NDP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружие",
        E: "weapon",
        T: "NAS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружия",
        E: "weapon",
        T: "NAP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружием",
        E: "weapon",
        T: "NIS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружиями",
        E: "weapon",
        T: "NIP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружии",
        E: "weapon",
        T: "NPS",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "оружиях",
        E: "weapon",
        T: "NPP",
        A: "I",
        O: "оружие",
        RA: 756,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "запах",
        E: "smell, odour, scent",
        T: "NNS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахи",
        E: "smell, odour, scent",
        T: "NNP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запаха",
        E: "smell, odour, scent",
        T: "NGS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахов",
        E: "smell, odour, scent",
        T: "NGP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запаху",
        E: "smell, odour, scent",
        T: "NDS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахам",
        E: "smell, odour, scent",
        T: "NDP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запах",
        E: "smell, odour, scent",
        T: "NAS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахи",
        E: "smell, odour, scent",
        T: "NAP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахом",
        E: "smell, odour, scent",
        T: "NIS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахами",
        E: "smell, odour, scent",
        T: "NIP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахе",
        E: "smell, odour, scent",
        T: "NPS",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "запахах",
        E: "smell, odour, scent",
        T: "NPP",
        A: "I",
        O: "запах",
        RA: 758,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роль",
        E: "role",
        T: "NNS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роли",
        E: "role",
        T: "NNP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роли",
        E: "role",
        T: "NGS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ролей",
        E: "role",
        T: "NGP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роли",
        E: "role",
        T: "NDS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ролям",
        E: "role",
        T: "NDP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роль",
        E: "role",
        T: "NAS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роли",
        E: "role",
        T: "NAP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ролью",
        E: "role",
        T: "NIS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ролями",
        E: "role",
        T: "NIP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "роли",
        E: "role",
        T: "NPS",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ролях",
        E: "role",
        T: "NPP",
        A: "I",
        O: "роль",
        RA: 762,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рост",
        E: "growth, increase; height",
        T: "NNS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роста",
        E: "growth, increase; height",
        T: "NNP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роста",
        E: "growth, increase; height",
        T: "NGS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ростов",
        E: "growth, increase; height",
        T: "NGP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "росту",
        E: "growth, increase; height",
        T: "NDS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ростам",
        E: "growth, increase; height",
        T: "NDP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рост",
        E: "growth, increase; height",
        T: "NAS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "роста",
        E: "growth, increase; height",
        T: "NAP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ростом",
        E: "growth, increase; height",
        T: "NIS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ростами",
        E: "growth, increase; height",
        T: "NIP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "росте",
        E: "growth, increase; height",
        T: "NPS",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ростах",
        E: "growth, increase; height",
        T: "NPP",
        A: "I",
        O: "рост",
        RA: 763,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "природа",
        E: "nature",
        T: "NNS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природы",
        E: "nature",
        T: "NNP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природы",
        E: "nature",
        T: "NGS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природ",
        E: "nature",
        T: "NGP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природе",
        E: "nature",
        T: "NDS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природам",
        E: "nature",
        T: "NDP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природу",
        E: "nature",
        T: "NAS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природы",
        E: "nature",
        T: "NAP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природой",
        E: "nature",
        T: "NIS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природами",
        E: "nature",
        T: "NIP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природе",
        E: "nature",
        T: "NPS",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "природах",
        E: "nature",
        T: "NPP",
        A: "I",
        O: "природа",
        RA: 764,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точка",
        E: "dot, point",
        T: "NNS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точки",
        E: "dot, point",
        T: "NNP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точки",
        E: "dot, point",
        T: "NGS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точек",
        E: "dot, point",
        T: "NGP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точке",
        E: "dot, point",
        T: "NDS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точкам",
        E: "dot, point",
        T: "NDP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точку",
        E: "dot, point",
        T: "NAS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точки",
        E: "dot, point",
        T: "NAP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точкой",
        E: "dot, point",
        T: "NIS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точками",
        E: "dot, point",
        T: "NIP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точке",
        E: "dot, point",
        T: "NPS",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "точках",
        E: "dot, point",
        T: "NPP",
        A: "I",
        O: "точка",
        RA: 766,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звезда",
        E: "star",
        T: "NNS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёзды",
        E: "star",
        T: "NNP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звезды",
        E: "star",
        T: "NGS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёзд",
        E: "star",
        T: "NGP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звезде",
        E: "star",
        T: "NDS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёздам",
        E: "star",
        T: "NDP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звезду",
        E: "star",
        T: "NAS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёзды",
        E: "star",
        T: "NAP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звездой",
        E: "star",
        T: "NIS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёздами",
        E: "star",
        T: "NIP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звезде",
        E: "star",
        T: "NPS",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "звёздах",
        E: "star",
        T: "NPP",
        A: "I",
        O: "звезда",
        RA: 767,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилия",
        E: "surname",
        T: "NNS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилии",
        E: "surname",
        T: "NNP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилии",
        E: "surname",
        T: "NGS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилий",
        E: "surname",
        T: "NGP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилии",
        E: "surname",
        T: "NDS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилиям",
        E: "surname",
        T: "NDP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилию",
        E: "surname",
        T: "NAS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилии",
        E: "surname",
        T: "NAP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилией",
        E: "surname",
        T: "NIS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилиями",
        E: "surname",
        T: "NIP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилии",
        E: "surname",
        T: "NPS",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фамилиях",
        E: "surname",
        T: "NPP",
        A: "I",
        O: "фамилия",
        RA: 770,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "характер",
        E: "character, disposition, temper",
        T: "NNS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характеры",
        E: "character, disposition, temper",
        T: "NNP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характера",
        E: "character, disposition, temper",
        T: "NGS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характеров",
        E: "character, disposition, temper",
        T: "NGP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характеру",
        E: "character, disposition, temper",
        T: "NDS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характерам",
        E: "character, disposition, temper",
        T: "NDP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характер",
        E: "character, disposition, temper",
        T: "NAS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характеры",
        E: "character, disposition, temper",
        T: "NAP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характером",
        E: "character, disposition, temper",
        T: "NIS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характерами",
        E: "character, disposition, temper",
        T: "NIP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характере",
        E: "character, disposition, temper",
        T: "NPS",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "характерах",
        E: "character, disposition, temper",
        T: "NPP",
        A: "I",
        O: "характер",
        RA: 771,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицер",
        E: "officer",
        T: "NNS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицеры",
        E: "officer",
        T: "NNP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицера",
        E: "officer",
        T: "NGS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицеров",
        E: "officer",
        T: "NGP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицеру",
        E: "officer",
        T: "NDS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицерам",
        E: "officer",
        T: "NDP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицера",
        E: "officer",
        T: "NAS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицеров",
        E: "officer",
        T: "NAP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицером",
        E: "officer",
        T: "NIS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицерами",
        E: "officer",
        T: "NIP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицере",
        E: "officer",
        T: "NPS",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "офицерах",
        E: "officer",
        T: "NPP",
        A: "A",
        O: "офицер",
        RA: 774,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "толпа",
        E: "crowd",
        T: "NNS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпы",
        E: "crowd",
        T: "NNP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпы",
        E: "crowd",
        T: "NGS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толп",
        E: "crowd",
        T: "NGP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпе",
        E: "crowd",
        T: "NDS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпам",
        E: "crowd",
        T: "NDP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпу",
        E: "crowd",
        T: "NAS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпы",
        E: "crowd",
        T: "NAP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпой",
        E: "crowd",
        T: "NIS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпами",
        E: "crowd",
        T: "NIP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпе",
        E: "crowd",
        T: "NPS",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "толпах",
        E: "crowd",
        T: "NPP",
        A: "I",
        O: "толпа",
        RA: 775,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "уровень",
        E: "level",
        T: "NNS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровни",
        E: "level",
        T: "NNP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровня",
        E: "level",
        T: "NGS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровней",
        E: "level",
        T: "NGP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровню",
        E: "level",
        T: "NDS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровням",
        E: "level",
        T: "NDP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровень",
        E: "level",
        T: "NAS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровни",
        E: "level",
        T: "NAP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровнем",
        E: "level",
        T: "NIS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровнями",
        E: "level",
        T: "NIP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровне",
        E: "level",
        T: "NPS",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "уровнях",
        E: "level",
        T: "NPP",
        A: "I",
        O: "уровень",
        RA: 778,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кресло",
        E: "armchair",
        T: "NNS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресла",
        E: "armchair",
        T: "NNP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресла",
        E: "armchair",
        T: "NGS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресел",
        E: "armchair",
        T: "NGP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "креслу",
        E: "armchair",
        T: "NDS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "креслам",
        E: "armchair",
        T: "NDP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресло",
        E: "armchair",
        T: "NAS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресла",
        E: "armchair",
        T: "NAP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "креслом",
        E: "armchair",
        T: "NIS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "креслами",
        E: "armchair",
        T: "NIP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "кресле",
        E: "armchair",
        T: "NPS",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "креслах",
        E: "armchair",
        T: "NPP",
        A: "I",
        O: "кресло",
        RA: 780,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "баба",
        E: "woman, wife, old woman",
        T: "NNS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабы",
        E: "woman, wife, old woman",
        T: "NNP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабы",
        E: "woman, wife, old woman",
        T: "NGS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "баб",
        E: "woman, wife, old woman",
        T: "NGP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабе",
        E: "woman, wife, old woman",
        T: "NDS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабам",
        E: "woman, wife, old woman",
        T: "NDP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабу",
        E: "woman, wife, old woman",
        T: "NAS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "баб",
        E: "woman, wife, old woman",
        T: "NAP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабой",
        E: "woman, wife, old woman",
        T: "NIS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабами",
        E: "woman, wife, old woman",
        T: "NIP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабе",
        E: "woman, wife, old woman",
        T: "NPS",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабах",
        E: "woman, wife, old woman",
        T: "NPP",
        A: "A",
        O: "баба",
        RA: 781,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунда",
        E: "a second",
        T: "NNS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунды",
        E: "a second",
        T: "NNP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунды",
        E: "a second",
        T: "NGS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунд",
        E: "a second",
        T: "NGP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунде",
        E: "a second",
        T: "NDS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секундам",
        E: "a second",
        T: "NDP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунду",
        E: "a second",
        T: "NAS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунды",
        E: "a second",
        T: "NAP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секундой",
        E: "a second",
        T: "NIS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секундами",
        E: "a second",
        T: "NIP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секунде",
        E: "a second",
        T: "NPS",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "секундах",
        E: "a second",
        T: "NPP",
        A: "I",
        O: "секунда",
        RA: 782,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "банк",
        E: "bank",
        T: "NNS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банки",
        E: "bank",
        T: "NNP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банка",
        E: "bank",
        T: "NGS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банков",
        E: "bank",
        T: "NGP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банку",
        E: "bank",
        T: "NDS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банкам",
        E: "bank",
        T: "NDP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банк",
        E: "bank",
        T: "NAS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банки",
        E: "bank",
        T: "NAP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банком",
        E: "bank",
        T: "NIS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банками",
        E: "bank",
        T: "NIP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банке",
        E: "bank",
        T: "NPS",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "банках",
        E: "bank",
        T: "NPP",
        A: "I",
        O: "банк",
        RA: 784,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыт",
        E: "experience",
        T: "NNS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыты",
        E: "experience",
        T: "NNP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыта",
        E: "experience",
        T: "NGS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опытов",
        E: "experience",
        T: "NGP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыту",
        E: "experience",
        T: "NDS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опытам",
        E: "experience",
        T: "NDP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыт",
        E: "experience",
        T: "NAS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыты",
        E: "experience",
        T: "NAP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опытом",
        E: "experience",
        T: "NIS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опытами",
        E: "experience",
        T: "NIP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опыте",
        E: "experience",
        T: "NPS",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "опытах",
        E: "experience",
        T: "NPP",
        A: "I",
        O: "опыт",
        RA: 785,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапог",
        E: "(high) boot",
        T: "NNS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапоги",
        E: "(high) boot",
        T: "NNP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапога",
        E: "(high) boot",
        T: "NGS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапог",
        E: "(high) boot",
        T: "NGP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапогу",
        E: "(high) boot",
        T: "NDS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапогам",
        E: "(high) boot",
        T: "NDP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапог",
        E: "(high) boot",
        T: "NAS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапоги",
        E: "(high) boot",
        T: "NAP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапогом",
        E: "(high) boot",
        T: "NIS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапогами",
        E: "(high) boot",
        T: "NIP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапоге",
        E: "(high) boot",
        T: "NPS",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сапогах",
        E: "(high) boot",
        T: "NPP",
        A: "I",
        O: "сапог",
        RA: 788,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "правило",
        E: "rule",
        T: "NNS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "rule",
        T: "NNP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "rule",
        T: "NGS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правил",
        E: "rule",
        T: "NGP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилу",
        E: "rule",
        T: "NDS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилам",
        E: "rule",
        T: "NDP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правило",
        E: "rule",
        T: "NAS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "rule",
        T: "NAP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилом",
        E: "rule",
        T: "NIS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилами",
        E: "rule",
        T: "NIP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правиле",
        E: "rule",
        T: "NPS",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилах",
        E: "rule",
        T: "NPP",
        A: "A",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правило",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NNS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NNP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NGS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правил",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NGP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилу",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NDS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилам",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NDP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правило",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NAS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правила",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NAP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилом",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NIS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилами",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NIP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правиле",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NPS",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правилах",
        E: "(hunters jargon) tail / brush of an animal",
        T: "NPP",
        A: "I",
        O: "правило",
        RA: 789,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стекло",
        E: "glass",
        T: "NNS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёкла",
        E: "glass",
        T: "NNP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стекла",
        E: "glass",
        T: "NGS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёкол",
        E: "glass",
        T: "NGP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стеклу",
        E: "glass",
        T: "NDS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёклам",
        E: "glass",
        T: "NDP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стекло",
        E: "glass",
        T: "NAS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёкла",
        E: "glass",
        T: "NAP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стеклом",
        E: "glass",
        T: "NIS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёклами",
        E: "glass",
        T: "NIP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стекле",
        E: "glass",
        T: "NPS",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "стёклах",
        E: "glass",
        T: "NPP",
        A: "I",
        O: "стекло",
        RA: 790,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дочь",
        E: "daughter",
        T: "NNS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочери",
        E: "daughter",
        T: "NNP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочери",
        E: "daughter",
        T: "NGS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерей",
        E: "daughter",
        T: "NGP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочери",
        E: "daughter",
        T: "NDS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерям",
        E: "daughter",
        T: "NDP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочь",
        E: "daughter",
        T: "NAS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерей",
        E: "daughter",
        T: "NAP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерью",
        E: "daughter",
        T: "NIS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерьми",
        E: "daughter",
        T: "NIP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочери",
        E: "daughter",
        T: "NPS",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дочерях",
        E: "daughter",
        T: "NPP",
        A: "A",
        O: "дочь",
        RA: 793,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "член",
        E: "member, limb",
        T: "NNS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "члены",
        E: "member, limb",
        T: "NNP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "члена",
        E: "member, limb",
        T: "NGS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "членов",
        E: "member, limb",
        T: "NGP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "члену",
        E: "member, limb",
        T: "NDS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "членам",
        E: "member, limb",
        T: "NDP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "член",
        E: "member, limb",
        T: "NAS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "члены",
        E: "member, limb",
        T: "NAP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "членом",
        E: "member, limb",
        T: "NIS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "членами",
        E: "member, limb",
        T: "NIP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "члене",
        E: "member, limb",
        T: "NPS",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "членах",
        E: "member, limb",
        T: "NPP",
        A: "A",
        O: "член",
        RA: 796,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десяток",
        E: "ten, dozens",
        T: "NNS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятки",
        E: "ten, dozens",
        T: "NNP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятка",
        E: "ten, dozens",
        T: "NGS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятков",
        E: "ten, dozens",
        T: "NGP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятку",
        E: "ten, dozens",
        T: "NDS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десяткам",
        E: "ten, dozens",
        T: "NDP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десяток",
        E: "ten, dozens",
        T: "NAS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятки",
        E: "ten, dozens",
        T: "NAP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятком",
        E: "ten, dozens",
        T: "NIS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятками",
        E: "ten, dozens",
        T: "NIP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятке",
        E: "ten, dozens",
        T: "NPS",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "десятках",
        E: "ten, dozens",
        T: "NPP",
        A: "I",
        O: "десяток",
        RA: 799,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветок",
        E: "flower",
        T: "NNS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветы",
        E: "flower",
        T: "NNP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветка",
        E: "flower",
        T: "NGS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветов",
        E: "flower",
        T: "NGP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветку",
        E: "flower",
        T: "NDS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветам",
        E: "flower",
        T: "NDP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветок",
        E: "flower",
        T: "NAS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветы",
        E: "flower",
        T: "NAP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветком",
        E: "flower",
        T: "NIS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветами",
        E: "flower",
        T: "NIP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветке",
        E: "flower",
        T: "NPS",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветах",
        E: "flower",
        T: "NPP",
        A: "I",
        O: "цветок",
        RA: 801,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "желание",
        E: "wish",
        T: "NNS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желания",
        E: "wish",
        T: "NNP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желания",
        E: "wish",
        T: "NGS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желаний",
        E: "wish",
        T: "NGP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желанию",
        E: "wish",
        T: "NDS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желаниям",
        E: "wish",
        T: "NDP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желание",
        E: "wish",
        T: "NAS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желания",
        E: "wish",
        T: "NAP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желанием",
        E: "wish",
        T: "NIS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желаниями",
        E: "wish",
        T: "NIP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желании",
        E: "wish",
        T: "NPS",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "желаниях",
        E: "wish",
        T: "NPP",
        A: "I",
        O: "желание",
        RA: 803,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дождь",
        E: "rain",
        T: "NNS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дожди",
        E: "rain",
        T: "NNP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождя",
        E: "rain",
        T: "NGS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождей",
        E: "rain",
        T: "NGP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождю",
        E: "rain",
        T: "NDS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождям",
        E: "rain",
        T: "NDP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождь",
        E: "rain",
        T: "NAS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дожди",
        E: "rain",
        T: "NAP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождём",
        E: "rain",
        T: "NIS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождями",
        E: "rain",
        T: "NIP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дожде",
        E: "rain",
        T: "NPS",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дождях",
        E: "rain",
        T: "NPP",
        A: "I",
        O: "дождь",
        RA: 804,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лоб",
        E: "forehead",
        T: "NNS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбы",
        E: "forehead",
        T: "NNP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лба",
        E: "forehead",
        T: "NGS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбов",
        E: "forehead",
        T: "NGP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбу",
        E: "forehead",
        T: "NDS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбам",
        E: "forehead",
        T: "NDP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лоб",
        E: "forehead",
        T: "NAS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбы",
        E: "forehead",
        T: "NAP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбом",
        E: "forehead",
        T: "NIS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбами",
        E: "forehead",
        T: "NIP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбе",
        E: "forehead",
        T: "NPS",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лбах",
        E: "forehead",
        T: "NPP",
        A: "I",
        O: "лоб",
        RA: 808,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "улыбка",
        E: "smile",
        T: "NNS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбки",
        E: "smile",
        T: "NNP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбки",
        E: "smile",
        T: "NGS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбок",
        E: "smile",
        T: "NGP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбке",
        E: "smile",
        T: "NDS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбкам",
        E: "smile",
        T: "NDP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбку",
        E: "smile",
        T: "NAS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбки",
        E: "smile",
        T: "NAP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбкой",
        E: "smile",
        T: "NIS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбками",
        E: "smile",
        T: "NIP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбке",
        E: "smile",
        T: "NPS",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "улыбках",
        E: "smile",
        T: "NPP",
        A: "I",
        O: "улыбка",
        RA: 810,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьба",
        E: "fight, struggle",
        T: "NNS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NNP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьбы",
        E: "fight, struggle",
        T: "NGS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NGP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьбе",
        E: "fight, struggle",
        T: "NDS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NDP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьбу",
        E: "fight, struggle",
        T: "NAS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NAP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьбой",
        E: "fight, struggle",
        T: "NIS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NIP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "борьбе",
        E: "fight, struggle",
        T: "NPS",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "fight, struggle",
        T: "NPP",
        A: "I",
        O: "борьба",
        RA: 811,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ворот",
        E: "collar",
        T: "NNS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вороты",
        E: "collar",
        T: "NNP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ворота",
        E: "collar",
        T: "NGS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воротов",
        E: "collar",
        T: "NGP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вороту",
        E: "collar",
        T: "NDS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воротам",
        E: "collar",
        T: "NDP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ворот",
        E: "collar",
        T: "NAS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вороты",
        E: "collar",
        T: "NAP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воротом",
        E: "collar",
        T: "NIS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воротами",
        E: "collar",
        T: "NIP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "вороте",
        E: "collar",
        T: "NPS",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "воротах",
        E: "collar",
        T: "NPP",
        A: "I",
        O: "ворот",
        RA: 812,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящик",
        E: "box, case",
        T: "NNS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящики",
        E: "box, case",
        T: "NNP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящика",
        E: "box, case",
        T: "NGS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящиков",
        E: "box, case",
        T: "NGP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящику",
        E: "box, case",
        T: "NDS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящикам",
        E: "box, case",
        T: "NDP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящик",
        E: "box, case",
        T: "NAS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящики",
        E: "box, case",
        T: "NAP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящиком",
        E: "box, case",
        T: "NIS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящиками",
        E: "box, case",
        T: "NIP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящике",
        E: "box, case",
        T: "NPS",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ящиках",
        E: "box, case",
        T: "NPP",
        A: "I",
        O: "ящик",
        RA: 813,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этаж",
        E: "floor, storey",
        T: "NNS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажи",
        E: "floor, storey",
        T: "NNP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажа",
        E: "floor, storey",
        T: "NGS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажей",
        E: "floor, storey",
        T: "NGP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажу",
        E: "floor, storey",
        T: "NDS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажам",
        E: "floor, storey",
        T: "NDP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этаж",
        E: "floor, storey",
        T: "NAS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажи",
        E: "floor, storey",
        T: "NAP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажом",
        E: "floor, storey",
        T: "NIS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажами",
        E: "floor, storey",
        T: "NIP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этаже",
        E: "floor, storey",
        T: "NPS",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "этажах",
        E: "floor, storey",
        T: "NPP",
        A: "I",
        O: "этаж",
        RA: 814,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "революция",
        E: "revolution",
        T: "NNS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революции",
        E: "revolution",
        T: "NNP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революции",
        E: "revolution",
        T: "NGS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революций",
        E: "revolution",
        T: "NGP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революции",
        E: "revolution",
        T: "NDS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революциям",
        E: "revolution",
        T: "NDP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революцию",
        E: "revolution",
        T: "NAS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революции",
        E: "revolution",
        T: "NAP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революцией",
        E: "revolution",
        T: "NIS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революциями",
        E: "revolution",
        T: "NIP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революции",
        E: "revolution",
        T: "NPS",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "революциях",
        E: "revolution",
        T: "NPP",
        A: "I",
        O: "революция",
        RA: 819,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сосед",
        E: "neighbour",
        T: "NNS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседи",
        E: "neighbour",
        T: "NNP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседа",
        E: "neighbour",
        T: "NGS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседей",
        E: "neighbour",
        T: "NGP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседу",
        E: "neighbour",
        T: "NDS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседям",
        E: "neighbour",
        T: "NDP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседа",
        E: "neighbour",
        T: "NAS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседей",
        E: "neighbour",
        T: "NAP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседом",
        E: "neighbour",
        T: "NIS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседями",
        E: "neighbour",
        T: "NIP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседе",
        E: "neighbour",
        T: "NPS",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "соседях",
        E: "neighbour",
        T: "NPP",
        A: "A",
        O: "сосед",
        RA: 821,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сестра",
        E: "sister",
        T: "NNS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сёстры",
        E: "sister",
        T: "NNP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестры",
        E: "sister",
        T: "NGS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестёр",
        E: "sister",
        T: "NGP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестре",
        E: "sister",
        T: "NDS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сёстрам",
        E: "sister",
        T: "NDP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестру",
        E: "sister",
        T: "NAS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестёр",
        E: "sister",
        T: "NAP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестрой",
        E: "sister",
        T: "NIS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сёстрами",
        E: "sister",
        T: "NIP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сестре",
        E: "sister",
        T: "NPS",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сёстрах",
        E: "sister",
        T: "NPP",
        A: "A",
        O: "сестра",
        RA: 822,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуация",
        E: "situation",
        T: "NNS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуации",
        E: "situation",
        T: "NNP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуации",
        E: "situation",
        T: "NGS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуаций",
        E: "situation",
        T: "NGP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуации",
        E: "situation",
        T: "NDS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуациям",
        E: "situation",
        T: "NDP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуацию",
        E: "situation",
        T: "NAS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуации",
        E: "situation",
        T: "NAP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуацией",
        E: "situation",
        T: "NIS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуациями",
        E: "situation",
        T: "NIP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуации",
        E: "situation",
        T: "NPS",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ситуациях",
        E: "situation",
        T: "NPP",
        A: "I",
        O: "ситуация",
        RA: 826,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "количество",
        E: "quantity",
        T: "NNS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количества",
        E: "quantity",
        T: "NNP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количества",
        E: "quantity",
        T: "NGS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количеств",
        E: "quantity",
        T: "NGP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количеству",
        E: "quantity",
        T: "NDS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количествам",
        E: "quantity",
        T: "NDP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количество",
        E: "quantity",
        T: "NAS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количества",
        E: "quantity",
        T: "NAP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количеством",
        E: "quantity",
        T: "NIS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количествами",
        E: "quantity",
        T: "NIP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количестве",
        E: "quantity",
        T: "NPS",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "количествах",
        E: "quantity",
        T: "NPP",
        A: "I",
        O: "количество",
        RA: 829,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выход",
        E: "exit",
        T: "NNS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходы",
        E: "exit",
        T: "NNP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выхода",
        E: "exit",
        T: "NGS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходов",
        E: "exit",
        T: "NGP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходу",
        E: "exit",
        T: "NDS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходам",
        E: "exit",
        T: "NDP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выход",
        E: "exit",
        T: "NAS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходы",
        E: "exit",
        T: "NAP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходом",
        E: "exit",
        T: "NIS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходами",
        E: "exit",
        T: "NIP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходе",
        E: "exit",
        T: "NPS",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выходах",
        E: "exit",
        T: "NPP",
        A: "I",
        O: "выход",
        RA: 832,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "совет",
        E: "advice",
        T: "NNS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советы",
        E: "advice",
        T: "NNP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "совета",
        E: "advice",
        T: "NGS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советов",
        E: "advice",
        T: "NGP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "совету",
        E: "advice",
        T: "NDS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советам",
        E: "advice",
        T: "NDP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "совет",
        E: "advice",
        T: "NAS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советы",
        E: "advice",
        T: "NAP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советом",
        E: "advice",
        T: "NIS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советами",
        E: "advice",
        T: "NIP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "совете",
        E: "advice",
        T: "NPS",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "советах",
        E: "advice",
        T: "NPP",
        A: "I",
        O: "совет",
        RA: 833,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дурак",
        E: "fool, idiot",
        T: "NNS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураки",
        E: "fool, idiot",
        T: "NNP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дурака",
        E: "fool, idiot",
        T: "NGS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураков",
        E: "fool, idiot",
        T: "NGP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураку",
        E: "fool, idiot",
        T: "NDS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дуракам",
        E: "fool, idiot",
        T: "NDP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дурака",
        E: "fool, idiot",
        T: "NAS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураков",
        E: "fool, idiot",
        T: "NAP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураком",
        E: "fool, idiot",
        T: "NIS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураками",
        E: "fool, idiot",
        T: "NIP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураке",
        E: "fool, idiot",
        T: "NPS",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дураках",
        E: "fool, idiot",
        T: "NPP",
        A: "A",
        O: "дурак",
        RA: 834,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союз",
        E: "union, alliance; conjunction",
        T: "NNS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзы",
        E: "union, alliance; conjunction",
        T: "NNP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союза",
        E: "union, alliance; conjunction",
        T: "NGS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзов",
        E: "union, alliance; conjunction",
        T: "NGP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзу",
        E: "union, alliance; conjunction",
        T: "NDS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзам",
        E: "union, alliance; conjunction",
        T: "NDP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союз",
        E: "union, alliance; conjunction",
        T: "NAS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзы",
        E: "union, alliance; conjunction",
        T: "NAP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзом",
        E: "union, alliance; conjunction",
        T: "NIS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзами",
        E: "union, alliance; conjunction",
        T: "NIP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзе",
        E: "union, alliance; conjunction",
        T: "NPS",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "союзах",
        E: "union, alliance; conjunction",
        T: "NPP",
        A: "I",
        O: "союз",
        RA: 836,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лето",
        E: "summer",
        T: "NNS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лета",
        E: "summer",
        T: "NNP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лета",
        E: "summer",
        T: "NGS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лет",
        E: "summer",
        T: "NGP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лету",
        E: "summer",
        T: "NDS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "летам",
        E: "summer",
        T: "NDP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лето",
        E: "summer",
        T: "NAS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лета",
        E: "summer",
        T: "NAP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "летом",
        E: "summer",
        T: "NIS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "летами",
        E: "summer",
        T: "NIP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "лете",
        E: "summer",
        T: "NPS",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "летах",
        E: "summer",
        T: "NPP",
        A: "I",
        O: "лето",
        RA: 837,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "граница",
        E: "border",
        T: "NNS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границы",
        E: "border",
        T: "NNP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границы",
        E: "border",
        T: "NGS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границ",
        E: "border",
        T: "NGP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границе",
        E: "border",
        T: "NDS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границам",
        E: "border",
        T: "NDP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границу",
        E: "border",
        T: "NAS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границы",
        E: "border",
        T: "NAP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границей",
        E: "border",
        T: "NIS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границами",
        E: "border",
        T: "NIP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границе",
        E: "border",
        T: "NPS",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "границах",
        E: "border",
        T: "NPP",
        A: "I",
        O: "граница",
        RA: 841,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цвет",
        E: "colour, color",
        T: "NNS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвета",
        E: "colour, color",
        T: "NNP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвета",
        E: "colour, color",
        T: "NGS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветов",
        E: "colour, color",
        T: "NGP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвету",
        E: "colour, color",
        T: "NDS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветам",
        E: "colour, color",
        T: "NDP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвет",
        E: "colour, color",
        T: "NAS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвета",
        E: "colour, color",
        T: "NAP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветом",
        E: "colour, color",
        T: "NIS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветами",
        E: "colour, color",
        T: "NIP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цвете",
        E: "colour, color",
        T: "NPS",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цветах",
        E: "colour, color",
        T: "NPP",
        A: "I",
        O: "цвет",
        RA: 842,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "свобода",
        E: "freedom",
        T: "NNS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободы",
        E: "freedom",
        T: "NNP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободы",
        E: "freedom",
        T: "NGS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свобод",
        E: "freedom",
        T: "NGP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободе",
        E: "freedom",
        T: "NDS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободам",
        E: "freedom",
        T: "NDP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободу",
        E: "freedom",
        T: "NAS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободы",
        E: "freedom",
        T: "NAP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободой",
        E: "freedom",
        T: "NIS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободами",
        E: "freedom",
        T: "NIP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободе",
        E: "freedom",
        T: "NPS",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "свободах",
        E: "freedom",
        T: "NPP",
        A: "I",
        O: "свобода",
        RA: 846,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "стул",
        E: "chair",
        T: "NNS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стулья",
        E: "chair",
        T: "NNP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стула",
        E: "chair",
        T: "NGS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стульев",
        E: "chair",
        T: "NGP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стулу",
        E: "chair",
        T: "NDS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стульям",
        E: "chair",
        T: "NDP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стул",
        E: "chair",
        T: "NAS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стулья",
        E: "chair",
        T: "NAP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стулом",
        E: "chair",
        T: "NIS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стульями",
        E: "chair",
        T: "NIP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стуле",
        E: "chair",
        T: "NPS",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стульях",
        E: "chair",
        T: "NPP",
        A: "I",
        O: "стул",
        RA: 848,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезд",
        E: "train",
        T: "NNS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезда",
        E: "train",
        T: "NNP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезда",
        E: "train",
        T: "NGS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поездов",
        E: "train",
        T: "NGP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезду",
        E: "train",
        T: "NDS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поездам",
        E: "train",
        T: "NDP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезд",
        E: "train",
        T: "NAS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезда",
        E: "train",
        T: "NAP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поездом",
        E: "train",
        T: "NIS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поездами",
        E: "train",
        T: "NIP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поезде",
        E: "train",
        T: "NPS",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поездах",
        E: "train",
        T: "NPP",
        A: "I",
        O: "поезд",
        RA: 850,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "музыка",
        E: "music",
        T: "NNS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыки",
        E: "music",
        T: "NNP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыки",
        E: "music",
        T: "NGS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музык",
        E: "music",
        T: "NGP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыке",
        E: "music",
        T: "NDS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыкам",
        E: "music",
        T: "NDP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыку",
        E: "music",
        T: "NAS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыки",
        E: "music",
        T: "NAP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыкой",
        E: "music",
        T: "NIS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыками",
        E: "music",
        T: "NIP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыке",
        E: "music",
        T: "NPS",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "музыках",
        E: "music",
        T: "NPP",
        A: "I",
        O: "музыка",
        RA: 851,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тень",
        E: "shadow",
        T: "NNS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тени",
        E: "shadow",
        T: "NNP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тени",
        E: "shadow",
        T: "NGS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "теней",
        E: "shadow",
        T: "NGP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тени",
        E: "shadow",
        T: "NDS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "теням",
        E: "shadow",
        T: "NDP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тень",
        E: "shadow",
        T: "NAS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тени",
        E: "shadow",
        T: "NAP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тенью",
        E: "shadow",
        T: "NIS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тенями",
        E: "shadow",
        T: "NIP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тени",
        E: "shadow",
        T: "NPS",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тенях",
        E: "shadow",
        T: "NPP",
        A: "I",
        O: "тень",
        RA: 853,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадь",
        E: "horse",
        T: "NNS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошади",
        E: "horse",
        T: "NNP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошади",
        E: "horse",
        T: "NGS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадей",
        E: "horse",
        T: "NGP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошади",
        E: "horse",
        T: "NDS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадям",
        E: "horse",
        T: "NDP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадь",
        E: "horse",
        T: "NAS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадей",
        E: "horse",
        T: "NAP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадью",
        E: "horse",
        T: "NIS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадьми",
        E: "horse",
        T: "NIP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошади",
        E: "horse",
        T: "NPS",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лошадях",
        E: "horse",
        T: "NPP",
        A: "A",
        O: "лошадь",
        RA: 854,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "поле",
        E: "field",
        T: "NNS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "поля",
        E: "field",
        T: "NNP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "поля",
        E: "field",
        T: "NGS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полей",
        E: "field",
        T: "NGP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полю",
        E: "field",
        T: "NDS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полям",
        E: "field",
        T: "NDP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "поле",
        E: "field",
        T: "NAS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "поля",
        E: "field",
        T: "NAP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полем",
        E: "field",
        T: "NIS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полями",
        E: "field",
        T: "NIP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "поле",
        E: "field",
        T: "NPS",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "полях",
        E: "field",
        T: "NPP",
        A: "I",
        O: "поле",
        RA: 855,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тип",
        E: "type, model",
        T: "NNS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типы",
        E: "type, model",
        T: "NNP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типа",
        E: "type, model",
        T: "NGS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типов",
        E: "type, model",
        T: "NGP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типу",
        E: "type, model",
        T: "NDS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типам",
        E: "type, model",
        T: "NDP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "тип",
        E: "type, model",
        T: "NAS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типы",
        E: "type, model",
        T: "NAP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типом",
        E: "type, model",
        T: "NIS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типами",
        E: "type, model",
        T: "NIP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типе",
        E: "type, model",
        T: "NPS",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "типах",
        E: "type, model",
        T: "NPP",
        A: "I",
        O: "тип",
        RA: 861,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "суд",
        E: "court, law-court",
        T: "NNS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суды",
        E: "court, law-court",
        T: "NNP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суда",
        E: "court, law-court",
        T: "NGS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "судов",
        E: "court, law-court",
        T: "NGP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суду",
        E: "court, law-court",
        T: "NDS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "судам",
        E: "court, law-court",
        T: "NDP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суд",
        E: "court, law-court",
        T: "NAS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суды",
        E: "court, law-court",
        T: "NAP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "судом",
        E: "court, law-court",
        T: "NIS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "судами",
        E: "court, law-court",
        T: "NIP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "суде",
        E: "court, law-court",
        T: "NPS",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "судах",
        E: "court, law-court",
        T: "NPP",
        A: "I",
        O: "суд",
        RA: 862,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "площадь",
        E: "square, area",
        T: "NNS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площади",
        E: "square, area",
        T: "NNP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площади",
        E: "square, area",
        T: "NGS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадей",
        E: "square, area",
        T: "NGP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площади",
        E: "square, area",
        T: "NDS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадям",
        E: "square, area",
        T: "NDP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадь",
        E: "square, area",
        T: "NAS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площади",
        E: "square, area",
        T: "NAP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадью",
        E: "square, area",
        T: "NIS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадями",
        E: "square, area",
        T: "NIP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площади",
        E: "square, area",
        T: "NPS",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "площадях",
        E: "square, area",
        T: "NPP",
        A: "I",
        O: "площадь",
        RA: 865,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радость",
        E: "gladness, joy",
        T: "NNS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радости",
        E: "gladness, joy",
        T: "NNP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радости",
        E: "gladness, joy",
        T: "NGS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радостей",
        E: "gladness, joy",
        T: "NGP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радости",
        E: "gladness, joy",
        T: "NDS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радостям",
        E: "gladness, joy",
        T: "NDP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радость",
        E: "gladness, joy",
        T: "NAS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радости",
        E: "gladness, joy",
        T: "NAP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радостью",
        E: "gladness, joy",
        T: "NIS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радостями",
        E: "gladness, joy",
        T: "NIP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радости",
        E: "gladness, joy",
        T: "NPS",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "радостях",
        E: "gladness, joy",
        T: "NPP",
        A: "I",
        O: "радость",
        RA: 871,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "возраст",
        E: "age",
        T: "NNS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрасты",
        E: "age",
        T: "NNP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возраста",
        E: "age",
        T: "NGS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрастов",
        E: "age",
        T: "NGP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрасту",
        E: "age",
        T: "NDS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрастам",
        E: "age",
        T: "NDP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возраст",
        E: "age",
        T: "NAS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрасты",
        E: "age",
        T: "NAP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрастом",
        E: "age",
        T: "NIS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрастами",
        E: "age",
        T: "NIP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрасте",
        E: "age",
        T: "NPS",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "возрастах",
        E: "age",
        T: "NPP",
        A: "I",
        O: "возраст",
        RA: 873,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "орган",
        E: "organ, body",
        T: "NNS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органы",
        E: "organ, body",
        T: "NNP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органа",
        E: "organ, body",
        T: "NGS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органов",
        E: "organ, body",
        T: "NGP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органу",
        E: "organ, body",
        T: "NDS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органам",
        E: "organ, body",
        T: "NDP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "орган",
        E: "organ, body",
        T: "NAS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органы",
        E: "organ, body",
        T: "NAP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органом",
        E: "organ, body",
        T: "NIS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органами",
        E: "organ, body",
        T: "NIP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органе",
        E: "organ, body",
        T: "NPS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органах",
        E: "organ, body",
        T: "NPP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "орган",
        E: "organ (instrument)",
        T: "NNS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органы",
        E: "organ (instrument)",
        T: "NNP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органа",
        E: "organ (instrument)",
        T: "NGS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органов",
        E: "organ (instrument)",
        T: "NGP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органу",
        E: "organ (instrument)",
        T: "NDS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органам",
        E: "organ (instrument)",
        T: "NDP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "орган",
        E: "organ (instrument)",
        T: "NAS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органы",
        E: "organ (instrument)",
        T: "NAP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органом",
        E: "organ (instrument)",
        T: "NIS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органами",
        E: "organ (instrument)",
        T: "NIP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органе",
        E: "organ (instrument)",
        T: "NPS",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "органах",
        E: "organ (instrument)",
        T: "NPP",
        A: "I",
        O: "орган",
        RA: 874,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "карта",
        E: "map",
        T: "NNS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карты",
        E: "map",
        T: "NNP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карты",
        E: "map",
        T: "NGS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карт",
        E: "map",
        T: "NGP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карте",
        E: "map",
        T: "NDS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картам",
        E: "map",
        T: "NDP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карту",
        E: "map",
        T: "NAS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карты",
        E: "map",
        T: "NAP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картой",
        E: "map",
        T: "NIS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картами",
        E: "map",
        T: "NIP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "карте",
        E: "map",
        T: "NPS",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "картах",
        E: "map",
        T: "NPP",
        A: "I",
        O: "карта",
        RA: 875,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "король",
        E: "king",
        T: "NNS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "короли",
        E: "king",
        T: "NNP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "короля",
        E: "king",
        T: "NGS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королей",
        E: "king",
        T: "NGP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королю",
        E: "king",
        T: "NDS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королям",
        E: "king",
        T: "NDP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "короля",
        E: "king",
        T: "NAS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королей",
        E: "king",
        T: "NAP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королём",
        E: "king",
        T: "NIS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королями",
        E: "king",
        T: "NIP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "короле",
        E: "king",
        T: "NPS",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "королях",
        E: "king",
        T: "NPP",
        A: "A",
        O: "король",
        RA: 878,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "слава",
        E: "glory, fame",
        T: "NNS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славы",
        E: "glory, fame",
        T: "NNP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славы",
        E: "glory, fame",
        T: "NGS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "слав",
        E: "glory, fame",
        T: "NGP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славе",
        E: "glory, fame",
        T: "NDS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славам",
        E: "glory, fame",
        T: "NDP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славу",
        E: "glory, fame",
        T: "NAS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славы",
        E: "glory, fame",
        T: "NAP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славой",
        E: "glory, fame",
        T: "NIS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славами",
        E: "glory, fame",
        T: "NIP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славе",
        E: "glory, fame",
        T: "NPS",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "славах",
        E: "glory, fame",
        T: "NPP",
        A: "I",
        O: "слава",
        RA: 879,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "полковник",
        E: "colonel",
        T: "NNS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковники",
        E: "colonel",
        T: "NNP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковника",
        E: "colonel",
        T: "NGS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковников",
        E: "colonel",
        T: "NGP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковнику",
        E: "colonel",
        T: "NDS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковникам",
        E: "colonel",
        T: "NDP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковника",
        E: "colonel",
        T: "NAS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковников",
        E: "colonel",
        T: "NAP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковником",
        E: "colonel",
        T: "NIS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковниками",
        E: "colonel",
        T: "NIP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковнике",
        E: "colonel",
        T: "NPS",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "полковниках",
        E: "colonel",
        T: "NPP",
        A: "A",
        O: "полковник",
        RA: 880,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бок",
        E: "side, flank",
        T: "NNS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бока",
        E: "side, flank",
        T: "NNP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бока",
        E: "side, flank",
        T: "NGS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боков",
        E: "side, flank",
        T: "NGP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боку",
        E: "side, flank",
        T: "NDS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бокам",
        E: "side, flank",
        T: "NDP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бок",
        E: "side, flank",
        T: "NAS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "бока",
        E: "side, flank",
        T: "NAP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боком",
        E: "side, flank",
        T: "NIS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боками",
        E: "side, flank",
        T: "NIP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боке",
        E: "side, flank",
        T: "NPS",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "боках",
        E: "side, flank",
        T: "NPP",
        A: "I",
        O: "бок",
        RA: 882,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "цена",
        E: "price",
        T: "NNS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цены",
        E: "price",
        T: "NNP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цены",
        E: "price",
        T: "NGS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цен",
        E: "price",
        T: "NGP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цене",
        E: "price",
        T: "NDS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ценам",
        E: "price",
        T: "NDP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цену",
        E: "price",
        T: "NAS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цены",
        E: "price",
        T: "NAP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ценой",
        E: "price",
        T: "NIS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ценами",
        E: "price",
        T: "NIP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "цене",
        E: "price",
        T: "NPS",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "ценах",
        E: "price",
        T: "NPP",
        A: "I",
        O: "цена",
        RA: 883,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информация",
        E: "information",
        T: "NNS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NNP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информации",
        E: "information",
        T: "NGS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NGP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информации",
        E: "information",
        T: "NDS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NDP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информацию",
        E: "information",
        T: "NAS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NAP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информацией",
        E: "information",
        T: "NIS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NIP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "информации",
        E: "information",
        T: "NPS",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "information",
        T: "NPP",
        A: "I",
        O: "информация",
        RA: 884,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "мозг",
        E: "brain",
        T: "NNS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозги",
        E: "brain",
        T: "NNP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозга",
        E: "brain",
        T: "NGS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгов",
        E: "brain",
        T: "NGP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгу",
        E: "brain",
        T: "NDS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгам",
        E: "brain",
        T: "NDP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозг",
        E: "brain",
        T: "NAS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозги",
        E: "brain",
        T: "NAP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгом",
        E: "brain",
        T: "NIS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгами",
        E: "brain",
        T: "NIP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозге",
        E: "brain",
        T: "NPS",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мозгах",
        E: "brain",
        T: "NPP",
        A: "I",
        O: "мозг",
        RA: 885,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "удовольствие",
        E: "pleasure",
        T: "NNS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствия",
        E: "pleasure",
        T: "NNP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствия",
        E: "pleasure",
        T: "NGS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствий",
        E: "pleasure",
        T: "NGP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствию",
        E: "pleasure",
        T: "NDS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствиям",
        E: "pleasure",
        T: "NDP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствие",
        E: "pleasure",
        T: "NAS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствия",
        E: "pleasure",
        T: "NAP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствием",
        E: "pleasure",
        T: "NIS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствиями",
        E: "pleasure",
        T: "NIP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствии",
        E: "pleasure",
        T: "NPS",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "удовольствиях",
        E: "pleasure",
        T: "NPP",
        A: "I",
        O: "удовольствие",
        RA: 886,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "воля",
        E: "will",
        T: "NNS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воли",
        E: "will",
        T: "NNP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воли",
        E: "will",
        T: "NGS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воль",
        E: "will",
        T: "NGP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воле",
        E: "will",
        T: "NDS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волям",
        E: "will",
        T: "NDP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волю",
        E: "will",
        T: "NAS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воли",
        E: "will",
        T: "NAP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волей",
        E: "will",
        T: "NIS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волями",
        E: "will",
        T: "NIP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "воле",
        E: "will",
        T: "NPS",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "волях",
        E: "will",
        T: "NPP",
        A: "I",
        O: "воля",
        RA: 887,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "область",
        E: "oblast, province; field",
        T: "NNS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "области",
        E: "oblast, province; field",
        T: "NNP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "области",
        E: "oblast, province; field",
        T: "NGS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "областей",
        E: "oblast, province; field",
        T: "NGP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "области",
        E: "oblast, province; field",
        T: "NDS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "областям",
        E: "oblast, province; field",
        T: "NDP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "область",
        E: "oblast, province; field",
        T: "NAS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "области",
        E: "oblast, province; field",
        T: "NAP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "областью",
        E: "oblast, province; field",
        T: "NIS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "областями",
        E: "oblast, province; field",
        T: "NIP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "области",
        E: "oblast, province; field",
        T: "NPS",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "областях",
        E: "oblast, province; field",
        T: "NPP",
        A: "I",
        O: "область",
        RA: 888,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыша",
        E: "roof",
        T: "NNS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыши",
        E: "roof",
        T: "NNP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыши",
        E: "roof",
        T: "NGS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыш",
        E: "roof",
        T: "NGP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыше",
        E: "roof",
        T: "NDS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крышам",
        E: "roof",
        T: "NDP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крышу",
        E: "roof",
        T: "NAS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыши",
        E: "roof",
        T: "NAP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крышей",
        E: "roof",
        T: "NIS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крышами",
        E: "roof",
        T: "NIP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крыше",
        E: "roof",
        T: "NPS",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "крышах",
        E: "roof",
        T: "NPP",
        A: "I",
        O: "крыша",
        RA: 889,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дама",
        E: "lady, partner, queen",
        T: "NNS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамы",
        E: "lady, partner, queen",
        T: "NNP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамы",
        E: "lady, partner, queen",
        T: "NGS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дам",
        E: "lady, partner, queen",
        T: "NGP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "даме",
        E: "lady, partner, queen",
        T: "NDS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамам",
        E: "lady, partner, queen",
        T: "NDP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "даму",
        E: "lady, partner, queen",
        T: "NAS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дам",
        E: "lady, partner, queen",
        T: "NAP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамой",
        E: "lady, partner, queen",
        T: "NIS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамами",
        E: "lady, partner, queen",
        T: "NIP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "даме",
        E: "lady, partner, queen",
        T: "NPS",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "дамах",
        E: "lady, partner, queen",
        T: "NPP",
        A: "A",
        O: "дама",
        RA: 893,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "сад",
        E: "garden, yard",
        T: "NNS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сады",
        E: "garden, yard",
        T: "NNP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сада",
        E: "garden, yard",
        T: "NGS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "садов",
        E: "garden, yard",
        T: "NGP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "саду",
        E: "garden, yard",
        T: "NDS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "садам",
        E: "garden, yard",
        T: "NDP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сад",
        E: "garden, yard",
        T: "NAS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "сады",
        E: "garden, yard",
        T: "NAP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "садом",
        E: "garden, yard",
        T: "NIS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "садами",
        E: "garden, yard",
        T: "NIP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "саду",
        E: "garden, yard",
        T: "NPS",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "садах",
        E: "garden, yard",
        T: "NPP",
        A: "I",
        O: "сад",
        RA: 897,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "правительство",
        E: "government",
        T: "NNS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительства",
        E: "government",
        T: "NNP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительства",
        E: "government",
        T: "NGS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительств",
        E: "government",
        T: "NGP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительству",
        E: "government",
        T: "NDS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительствам",
        E: "government",
        T: "NDP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительство",
        E: "government",
        T: "NAS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительства",
        E: "government",
        T: "NAP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительством",
        E: "government",
        T: "NIS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительствами",
        E: "government",
        T: "NIP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительстве",
        E: "government",
        T: "NPS",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "правительствах",
        E: "government",
        T: "NPP",
        A: "I",
        O: "правительство",
        RA: 898,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "название",
        E: "name, title",
        T: "NNS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названия",
        E: "name, title",
        T: "NNP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названия",
        E: "name, title",
        T: "NGS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названий",
        E: "name, title",
        T: "NGP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названию",
        E: "name, title",
        T: "NDS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названиям",
        E: "name, title",
        T: "NDP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "название",
        E: "name, title",
        T: "NAS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названия",
        E: "name, title",
        T: "NAP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названием",
        E: "name, title",
        T: "NIS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названиями",
        E: "name, title",
        T: "NIP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названии",
        E: "name, title",
        T: "NPS",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "названиях",
        E: "name, title",
        T: "NPP",
        A: "I",
        O: "название",
        RA: 904,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "пример",
        E: "example",
        T: "NNS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примеры",
        E: "example",
        T: "NNP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примера",
        E: "example",
        T: "NGS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примеров",
        E: "example",
        T: "NGP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примеру",
        E: "example",
        T: "NDS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примерам",
        E: "example",
        T: "NDP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пример",
        E: "example",
        T: "NAS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примеры",
        E: "example",
        T: "NAP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примером",
        E: "example",
        T: "NIS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примерами",
        E: "example",
        T: "NIP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примере",
        E: "example",
        T: "NPS",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "примерах",
        E: "example",
        T: "NPP",
        A: "I",
        O: "пример",
        RA: 906,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "зеркало",
        E: "mirror",
        T: "NNS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркала",
        E: "mirror",
        T: "NNP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркала",
        E: "mirror",
        T: "NGS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркал",
        E: "mirror",
        T: "NGP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркалу",
        E: "mirror",
        T: "NDS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркалам",
        E: "mirror",
        T: "NDP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркало",
        E: "mirror",
        T: "NAS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркала",
        E: "mirror",
        T: "NAP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркалом",
        E: "mirror",
        T: "NIS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркалами",
        E: "mirror",
        T: "NIP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркале",
        E: "mirror",
        T: "NPS",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зеркалах",
        E: "mirror",
        T: "NPP",
        A: "I",
        O: "зеркало",
        RA: 908,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "дым",
        E: "smoke",
        T: "NNS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымы",
        E: "smoke",
        T: "NNP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дыма",
        E: "smoke",
        T: "NGS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымов",
        E: "smoke",
        T: "NGP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дыму",
        E: "smoke",
        T: "NDS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымам",
        E: "smoke",
        T: "NDP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дым",
        E: "smoke",
        T: "NAS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымы",
        E: "smoke",
        T: "NAP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымом",
        E: "smoke",
        T: "NIS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымами",
        E: "smoke",
        T: "NIP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дыме",
        E: "smoke",
        T: "NPS",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "дымах",
        E: "smoke",
        T: "NPP",
        A: "I",
        O: "дым",
        RA: 912,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факт",
        E: "fact",
        T: "NNS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факты",
        E: "fact",
        T: "NNP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факта",
        E: "fact",
        T: "NGS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фактов",
        E: "fact",
        T: "NGP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факту",
        E: "fact",
        T: "NDS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фактам",
        E: "fact",
        T: "NDP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факт",
        E: "fact",
        T: "NAS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факты",
        E: "fact",
        T: "NAP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фактом",
        E: "fact",
        T: "NIS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фактами",
        E: "fact",
        T: "NIP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "факте",
        E: "fact",
        T: "NPS",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фактах",
        E: "fact",
        T: "NPP",
        A: "I",
        O: "факт",
        RA: 916,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рыба",
        E: "fish",
        T: "NNS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбы",
        E: "fish",
        T: "NNP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбы",
        E: "fish",
        T: "NGS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыб",
        E: "fish",
        T: "NGP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбе",
        E: "fish",
        T: "NDS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбам",
        E: "fish",
        T: "NDP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбу",
        E: "fish",
        T: "NAS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыб",
        E: "fish",
        T: "NAP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбой",
        E: "fish",
        T: "NIS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбами",
        E: "fish",
        T: "NIP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбе",
        E: "fish",
        T: "NPS",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "рыбах",
        E: "fish",
        T: "NPP",
        A: "A",
        O: "рыба",
        RA: 918,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушка",
        E: "grandmother",
        T: "NNS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушки",
        E: "grandmother",
        T: "NNP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушки",
        E: "grandmother",
        T: "NGS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушек",
        E: "grandmother",
        T: "NGP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушке",
        E: "grandmother",
        T: "NDS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушкам",
        E: "grandmother",
        T: "NDP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушку",
        E: "grandmother",
        T: "NAS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушек",
        E: "grandmother",
        T: "NAP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушкой",
        E: "grandmother",
        T: "NIS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушками",
        E: "grandmother",
        T: "NIP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушке",
        E: "grandmother",
        T: "NPS",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "бабушках",
        E: "grandmother",
        T: "NPP",
        A: "A",
        O: "бабушка",
        RA: 921,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "вино",
        E: "wine",
        T: "NNS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вина",
        E: "wine",
        T: "NNP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вина",
        E: "wine",
        T: "NGS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вин",
        E: "wine",
        T: "NGP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вину",
        E: "wine",
        T: "NDS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "винам",
        E: "wine",
        T: "NDP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вино",
        E: "wine",
        T: "NAS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вина",
        E: "wine",
        T: "NAP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вином",
        E: "wine",
        T: "NIS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "винами",
        E: "wine",
        T: "NIP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "вине",
        E: "wine",
        T: "NPS",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "винах",
        E: "wine",
        T: "NPP",
        A: "I",
        O: "вино",
        RA: 922,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "учитель",
        E: "teacher, instructor",
        T: "NNS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителя",
        E: "teacher, instructor",
        T: "NNP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителя",
        E: "teacher, instructor",
        T: "NGS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителей",
        E: "teacher, instructor",
        T: "NGP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителю",
        E: "teacher, instructor",
        T: "NDS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителям",
        E: "teacher, instructor",
        T: "NDP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителя",
        E: "teacher, instructor",
        T: "NAS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителей",
        E: "teacher, instructor",
        T: "NAP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителем",
        E: "teacher, instructor",
        T: "NIS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителями",
        E: "teacher, instructor",
        T: "NIP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителе",
        E: "teacher, instructor",
        T: "NPS",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "учителях",
        E: "teacher, instructor",
        T: "NPP",
        A: "A",
        O: "учитель",
        RA: 924,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круг",
        E: "circle",
        T: "NNS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круги",
        E: "circle",
        T: "NNP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круга",
        E: "circle",
        T: "NGS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругов",
        E: "circle",
        T: "NGP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругу",
        E: "circle",
        T: "NDS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругам",
        E: "circle",
        T: "NDP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круг",
        E: "circle",
        T: "NAS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круги",
        E: "circle",
        T: "NAP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругом",
        E: "circle",
        T: "NIS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругами",
        E: "circle",
        T: "NIP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "круге",
        E: "circle",
        T: "NPS",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кругах",
        E: "circle",
        T: "NPP",
        A: "I",
        O: "круг",
        RA: 927,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папа",
        E: "dad, daddy",
        T: "NNS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папы",
        E: "dad, daddy",
        T: "NNP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папы",
        E: "dad, daddy",
        T: "NGS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пап",
        E: "dad, daddy",
        T: "NGP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папе",
        E: "dad, daddy",
        T: "NDS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папам",
        E: "dad, daddy",
        T: "NDP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папу",
        E: "dad, daddy",
        T: "NAS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "пап",
        E: "dad, daddy",
        T: "NAP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папой",
        E: "dad, daddy",
        T: "NIS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папами",
        E: "dad, daddy",
        T: "NIP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папе",
        E: "dad, daddy",
        T: "NPS",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "папах",
        E: "dad, daddy",
        T: "NPP",
        A: "A",
        O: "папа",
        RA: 928,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повод",
        E: "occasion, reason; bridle",
        T: "NNS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводы",
        E: "occasion, reason; bridle",
        T: "NNP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повода",
        E: "occasion, reason; bridle",
        T: "NGS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводов",
        E: "occasion, reason; bridle",
        T: "NGP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводу",
        E: "occasion, reason; bridle",
        T: "NDS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводам",
        E: "occasion, reason; bridle",
        T: "NDP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повод",
        E: "occasion, reason; bridle",
        T: "NAS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводы",
        E: "occasion, reason; bridle",
        T: "NAP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводом",
        E: "occasion, reason; bridle",
        T: "NIS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводами",
        E: "occasion, reason; bridle",
        T: "NIP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводе",
        E: "occasion, reason; bridle",
        T: "NPS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводах",
        E: "occasion, reason; bridle",
        T: "NPP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повод",
        E: "rein",
        T: "NNS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводья",
        E: "rein",
        T: "NNP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повода",
        E: "rein",
        T: "NGS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводьев",
        E: "rein",
        T: "NGP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводу",
        E: "rein",
        T: "NDS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводьям",
        E: "rein",
        T: "NDP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "повод",
        E: "rein",
        T: "NAS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводья",
        E: "rein",
        T: "NAP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводом",
        E: "rein",
        T: "NIS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводьями",
        E: "rein",
        T: "NIP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводе",
        E: "rein",
        T: "NPS",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "поводьях",
        E: "rein",
        T: "NPP",
        A: "I",
        O: "повод",
        RA: 935,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерь",
        E: "camp",
        T: "NNS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагеря",
        E: "camp",
        T: "NNP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагеря",
        E: "camp",
        T: "NGS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерей",
        E: "camp",
        T: "NGP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерю",
        E: "camp",
        T: "NDS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерям",
        E: "camp",
        T: "NDP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерь",
        E: "camp",
        T: "NAS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагеря",
        E: "camp",
        T: "NAP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерем",
        E: "camp",
        T: "NIS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерями",
        E: "camp",
        T: "NIP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагере",
        E: "camp",
        T: "NPS",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "лагерях",
        E: "camp",
        T: "NPP",
        A: "I",
        O: "лагерь",
        RA: 936,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "птица",
        E: "bird",
        T: "NNS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицы",
        E: "bird",
        T: "NNP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицы",
        E: "bird",
        T: "NGS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птиц",
        E: "bird",
        T: "NGP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птице",
        E: "bird",
        T: "NDS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицам",
        E: "bird",
        T: "NDP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицу",
        E: "bird",
        T: "NAS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птиц",
        E: "bird",
        T: "NAP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицей",
        E: "bird",
        T: "NIS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицами",
        E: "bird",
        T: "NIP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птице",
        E: "bird",
        T: "NPS",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "птицах",
        E: "bird",
        T: "NPP",
        A: "A",
        O: "птица",
        RA: 937,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "корабль",
        E: "ship",
        T: "NNS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "корабли",
        E: "ship",
        T: "NNP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "корабля",
        E: "ship",
        T: "NGS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблей",
        E: "ship",
        T: "NGP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблю",
        E: "ship",
        T: "NDS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблям",
        E: "ship",
        T: "NDP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "корабль",
        E: "ship",
        T: "NAS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "корабли",
        E: "ship",
        T: "NAP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблём",
        E: "ship",
        T: "NIS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблями",
        E: "ship",
        T: "NIP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "корабле",
        E: "ship",
        T: "NPS",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кораблях",
        E: "ship",
        T: "NPP",
        A: "I",
        O: "корабль",
        RA: 938,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мнение",
        E: "opinion",
        T: "NNS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнения",
        E: "opinion",
        T: "NNP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнения",
        E: "opinion",
        T: "NGS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнений",
        E: "opinion",
        T: "NGP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнению",
        E: "opinion",
        T: "NDS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнениям",
        E: "opinion",
        T: "NDP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнение",
        E: "opinion",
        T: "NAS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнения",
        E: "opinion",
        T: "NAP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнением",
        E: "opinion",
        T: "NIS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнениями",
        E: "opinion",
        T: "NIP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнении",
        E: "opinion",
        T: "NPS",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мнениях",
        E: "opinion",
        T: "NPP",
        A: "I",
        O: "мнение",
        RA: 939,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "зима",
        E: "winter",
        T: "NNS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимы",
        E: "winter",
        T: "NNP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимы",
        E: "winter",
        T: "NGS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зим",
        E: "winter",
        T: "NGP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зиме",
        E: "winter",
        T: "NDS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимам",
        E: "winter",
        T: "NDP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зиму",
        E: "winter",
        T: "NAS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимы",
        E: "winter",
        T: "NAP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимой",
        E: "winter",
        T: "NIS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимами",
        E: "winter",
        T: "NIP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зиме",
        E: "winter",
        T: "NPS",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "зимах",
        E: "winter",
        T: "NPP",
        A: "I",
        O: "зима",
        RA: 942,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "километр",
        E: "kilometre, kilometer",
        T: "NNS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километры",
        E: "kilometre, kilometer",
        T: "NNP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километра",
        E: "kilometre, kilometer",
        T: "NGS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километров",
        E: "kilometre, kilometer",
        T: "NGP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километру",
        E: "kilometre, kilometer",
        T: "NDS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километрам",
        E: "kilometre, kilometer",
        T: "NDP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километр",
        E: "kilometre, kilometer",
        T: "NAS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километры",
        E: "kilometre, kilometer",
        T: "NAP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километром",
        E: "kilometre, kilometer",
        T: "NIS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километрами",
        E: "kilometre, kilometer",
        T: "NIP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километре",
        E: "kilometre, kilometer",
        T: "NPS",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "километрах",
        E: "kilometre, kilometer",
        T: "NPP",
        A: "I",
        O: "километр",
        RA: 944,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кровать",
        E: "bed",
        T: "NNS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровати",
        E: "bed",
        T: "NNP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровати",
        E: "bed",
        T: "NGS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кроватей",
        E: "bed",
        T: "NGP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровати",
        E: "bed",
        T: "NDS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кроватям",
        E: "bed",
        T: "NDP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровать",
        E: "bed",
        T: "NAS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровати",
        E: "bed",
        T: "NAP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кроватью",
        E: "bed",
        T: "NIS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кроватями",
        E: "bed",
        T: "NIP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кровати",
        E: "bed",
        T: "NPS",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "кроватях",
        E: "bed",
        T: "NPP",
        A: "I",
        O: "кровать",
        RA: 945,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестница",
        E: "staircase",
        T: "NNS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницы",
        E: "staircase",
        T: "NNP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницы",
        E: "staircase",
        T: "NGS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестниц",
        E: "staircase",
        T: "NGP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестнице",
        E: "staircase",
        T: "NDS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницам",
        E: "staircase",
        T: "NDP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницу",
        E: "staircase",
        T: "NAS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницы",
        E: "staircase",
        T: "NAP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницей",
        E: "staircase",
        T: "NIS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницами",
        E: "staircase",
        T: "NIP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестнице",
        E: "staircase",
        T: "NPS",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "лестницах",
        E: "staircase",
        T: "NPP",
        A: "I",
        O: "лестница",
        RA: 949,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "детство",
        E: "childhood",
        T: "NNS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детства",
        E: "childhood",
        T: "NNP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детства",
        E: "childhood",
        T: "NGS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детств",
        E: "childhood",
        T: "NGP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детству",
        E: "childhood",
        T: "NDS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детствам",
        E: "childhood",
        T: "NDP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детство",
        E: "childhood",
        T: "NAS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детства",
        E: "childhood",
        T: "NAP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детством",
        E: "childhood",
        T: "NIS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детствами",
        E: "childhood",
        T: "NIP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детстве",
        E: "childhood",
        T: "NPS",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "детствах",
        E: "childhood",
        T: "NPP",
        A: "I",
        O: "детство",
        RA: 953,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "остров",
        E: "island",
        T: "NNS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "острова",
        E: "island",
        T: "NNP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "острова",
        E: "island",
        T: "NGS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "островов",
        E: "island",
        T: "NGP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "острову",
        E: "island",
        T: "NDS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "островам",
        E: "island",
        T: "NDP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "остров",
        E: "island",
        T: "NAS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "острова",
        E: "island",
        T: "NAP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "островом",
        E: "island",
        T: "NIS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "островами",
        E: "island",
        T: "NIP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "острове",
        E: "island",
        T: "NPS",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "островах",
        E: "island",
        T: "NPP",
        A: "I",
        O: "остров",
        RA: 954,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "статья",
        E: "article",
        T: "NNS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьи",
        E: "article",
        T: "NNP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьи",
        E: "article",
        T: "NGS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статей",
        E: "article",
        T: "NGP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статье",
        E: "article",
        T: "NDS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьям",
        E: "article",
        T: "NDP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статью",
        E: "article",
        T: "NAS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьи",
        E: "article",
        T: "NAP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьёй",
        E: "article",
        T: "NIS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьями",
        E: "article",
        T: "NIP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статье",
        E: "article",
        T: "NPS",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "статьях",
        E: "article",
        T: "NPP",
        A: "I",
        O: "статья",
        RA: 955,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водка",
        E: "vodka",
        T: "NNS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водки",
        E: "vodka",
        T: "NNP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водки",
        E: "vodka",
        T: "NGS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водок",
        E: "vodka",
        T: "NGP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водке",
        E: "vodka",
        T: "NDS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водкам",
        E: "vodka",
        T: "NDP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водку",
        E: "vodka",
        T: "NAS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водки",
        E: "vodka",
        T: "NAP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водкой",
        E: "vodka",
        T: "NIS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водками",
        E: "vodka",
        T: "NIP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водке",
        E: "vodka",
        T: "NPS",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "водках",
        E: "vodka",
        T: "NPP",
        A: "I",
        O: "водка",
        RA: 959,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темнота",
        E: "darkness",
        T: "NNS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NNP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темноты",
        E: "darkness",
        T: "NGS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NGP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темноте",
        E: "darkness",
        T: "NDS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NDP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темноту",
        E: "darkness",
        T: "NAS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NAP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темнотой",
        E: "darkness",
        T: "NIS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NIP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "темноте",
        E: "darkness",
        T: "NPS",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "darkness",
        T: "NPP",
        A: "I",
        O: "темнота",
        RA: 960,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станция",
        E: "station",
        T: "NNS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станции",
        E: "station",
        T: "NNP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станции",
        E: "station",
        T: "NGS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станций",
        E: "station",
        T: "NGP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станции",
        E: "station",
        T: "NDS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станциям",
        E: "station",
        T: "NDP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станцию",
        E: "station",
        T: "NAS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станции",
        E: "station",
        T: "NAP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станцией",
        E: "station",
        T: "NIS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станциями",
        E: "station",
        T: "NIP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станции",
        E: "station",
        T: "NPS",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "станциях",
        E: "station",
        T: "NPP",
        A: "I",
        O: "станция",
        RA: 963,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "гражданин",
        E: "citizen",
        T: "NNS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "граждане",
        E: "citizen",
        T: "NNP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданина",
        E: "citizen",
        T: "NGS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "граждан",
        E: "citizen",
        T: "NGP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданину",
        E: "citizen",
        T: "NDS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданам",
        E: "citizen",
        T: "NDP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданина",
        E: "citizen",
        T: "NAS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "граждан",
        E: "citizen",
        T: "NAP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданином",
        E: "citizen",
        T: "NIS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданами",
        E: "citizen",
        T: "NIP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданине",
        E: "citizen",
        T: "NPS",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "гражданах",
        E: "citizen",
        T: "NPP",
        A: "A",
        O: "гражданин",
        RA: 967,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "команда",
        E: "team",
        T: "NNS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команды",
        E: "team",
        T: "NNP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команды",
        E: "team",
        T: "NGS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команд",
        E: "team",
        T: "NGP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команде",
        E: "team",
        T: "NDS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "командам",
        E: "team",
        T: "NDP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команду",
        E: "team",
        T: "NAS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команды",
        E: "team",
        T: "NAP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "командой",
        E: "team",
        T: "NIS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "командами",
        E: "team",
        T: "NIP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "команде",
        E: "team",
        T: "NPS",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "командах",
        E: "team",
        T: "NPP",
        A: "I",
        O: "команда",
        RA: 971,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "заболевание",
        E: "disease, illness",
        T: "NNS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболевания",
        E: "disease, illness",
        T: "NNP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболевания",
        E: "disease, illness",
        T: "NGS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеваний",
        E: "disease, illness",
        T: "NGP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеванию",
        E: "disease, illness",
        T: "NDS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеваниям",
        E: "disease, illness",
        T: "NDP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболевание",
        E: "disease, illness",
        T: "NAS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболевания",
        E: "disease, illness",
        T: "NAP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеванием",
        E: "disease, illness",
        T: "NIS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеваниями",
        E: "disease, illness",
        T: "NIP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболевании",
        E: "disease, illness",
        T: "NPS",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "заболеваниях",
        E: "disease, illness",
        T: "NPP",
        A: "I",
        O: "заболевание",
        RA: 972,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "живот",
        E: "stomach, belly",
        T: "NNS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животы",
        E: "stomach, belly",
        T: "NNP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "живота",
        E: "stomach, belly",
        T: "NGS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животов",
        E: "stomach, belly",
        T: "NGP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животу",
        E: "stomach, belly",
        T: "NDS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животам",
        E: "stomach, belly",
        T: "NDP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "живот",
        E: "stomach, belly",
        T: "NAS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животы",
        E: "stomach, belly",
        T: "NAP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животом",
        E: "stomach, belly",
        T: "NIS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животами",
        E: "stomach, belly",
        T: "NIP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животе",
        E: "stomach, belly",
        T: "NPS",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "животах",
        E: "stomach, belly",
        T: "NPP",
        A: "I",
        O: "живот",
        RA: 973,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "тишина",
        E: "silence, stillness",
        T: "NNS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NNP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тишины",
        E: "silence, stillness",
        T: "NGS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NGP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тишине",
        E: "silence, stillness",
        T: "NDS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NDP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тишину",
        E: "silence, stillness",
        T: "NAS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NAP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тишиной",
        E: "silence, stillness",
        T: "NIS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NIP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тишине",
        E: "silence, stillness",
        T: "NPS",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "silence, stillness",
        T: "NPP",
        A: "I",
        O: "тишина",
        RA: 976,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "фронт",
        E: "front",
        T: "NNS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронты",
        E: "front",
        T: "NNP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронта",
        E: "front",
        T: "NGS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронтов",
        E: "front",
        T: "NGP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронту",
        E: "front",
        T: "NDS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронтам",
        E: "front",
        T: "NDP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронт",
        E: "front",
        T: "NAS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронты",
        E: "front",
        T: "NAP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронтом",
        E: "front",
        T: "NIS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронтами",
        E: "front",
        T: "NIP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронте",
        E: "front",
        T: "NPS",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "фронтах",
        E: "front",
        T: "NPP",
        A: "I",
        O: "фронт",
        RA: 978,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "щека",
        E: "cheek",
        T: "NNS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щёки",
        E: "cheek",
        T: "NNP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щеки",
        E: "cheek",
        T: "NGS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щёк",
        E: "cheek",
        T: "NGP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щеке",
        E: "cheek",
        T: "NDS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щекам",
        E: "cheek",
        T: "NDP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щёку",
        E: "cheek",
        T: "NAS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щёки",
        E: "cheek",
        T: "NAP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щекой",
        E: "cheek",
        T: "NIS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щеками",
        E: "cheek",
        T: "NIP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щеке",
        E: "cheek",
        T: "NPS",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "щеках",
        E: "cheek",
        T: "NPP",
        A: "I",
        O: "щека",
        RA: 979,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "район",
        E: "area, region",
        T: "NNS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районы",
        E: "area, region",
        T: "NNP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "района",
        E: "area, region",
        T: "NGS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районов",
        E: "area, region",
        T: "NGP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "району",
        E: "area, region",
        T: "NDS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районам",
        E: "area, region",
        T: "NDP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "район",
        E: "area, region",
        T: "NAS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районы",
        E: "area, region",
        T: "NAP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районом",
        E: "area, region",
        T: "NIS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районами",
        E: "area, region",
        T: "NIP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районе",
        E: "area, region",
        T: "NPS",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "районах",
        E: "area, region",
        T: "NPP",
        A: "I",
        O: "район",
        RA: 981,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "выражение",
        E: "expression",
        T: "NNS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражения",
        E: "expression",
        T: "NNP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражения",
        E: "expression",
        T: "NGS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражений",
        E: "expression",
        T: "NGP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражению",
        E: "expression",
        T: "NDS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражениям",
        E: "expression",
        T: "NDP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражение",
        E: "expression",
        T: "NAS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражения",
        E: "expression",
        T: "NAP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражением",
        E: "expression",
        T: "NIS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражениями",
        E: "expression",
        T: "NIP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражении",
        E: "expression",
        T: "NPS",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "выражениях",
        E: "expression",
        T: "NPP",
        A: "I",
        O: "выражение",
        RA: 984,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "мешок",
        E: "bag, sack",
        T: "NNS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешки",
        E: "bag, sack",
        T: "NNP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешка",
        E: "bag, sack",
        T: "NGS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешков",
        E: "bag, sack",
        T: "NGP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешку",
        E: "bag, sack",
        T: "NDS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешкам",
        E: "bag, sack",
        T: "NDP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешок",
        E: "bag, sack",
        T: "NAS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешки",
        E: "bag, sack",
        T: "NAP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешком",
        E: "bag, sack",
        T: "NIS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешками",
        E: "bag, sack",
        T: "NIP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешке",
        E: "bag, sack",
        T: "NPS",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "мешках",
        E: "bag, sack",
        T: "NPP",
        A: "I",
        O: "мешок",
        RA: 986,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "большинство",
        E: "majority",
        T: "NNS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NNP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "большинства",
        E: "majority",
        T: "NGS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NGP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "большинству",
        E: "majority",
        T: "NDS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NDP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "большинство",
        E: "majority",
        T: "NAS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NAP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "большинством",
        E: "majority",
        T: "NIS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NIP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "большинстве",
        E: "majority",
        T: "NPS",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "",
        E: "majority",
        T: "NPP",
        A: "I",
        O: "большинство",
        RA: 990,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управление",
        E: "operation, control",
        T: "NNS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управления",
        E: "operation, control",
        T: "NNP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управления",
        E: "operation, control",
        T: "NGS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлений",
        E: "operation, control",
        T: "NGP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлению",
        E: "operation, control",
        T: "NDS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлениям",
        E: "operation, control",
        T: "NDP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управление",
        E: "operation, control",
        T: "NAS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управления",
        E: "operation, control",
        T: "NAP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлением",
        E: "operation, control",
        T: "NIS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлениями",
        E: "operation, control",
        T: "NIP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлении",
        E: "operation, control",
        T: "NPS",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "управлениях",
        E: "operation, control",
        T: "NPP",
        A: "I",
        O: "управление",
        RA: 992,
        P: 0,
        G: "N",
        "": "",
    },
    {
        R: "приказ",
        E: "order",
        T: "NNS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказы",
        E: "order",
        T: "NNP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказа",
        E: "order",
        T: "NGS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказов",
        E: "order",
        T: "NGP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказу",
        E: "order",
        T: "NDS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказам",
        E: "order",
        T: "NDP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказ",
        E: "order",
        T: "NAS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказы",
        E: "order",
        T: "NAP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказом",
        E: "order",
        T: "NIS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказами",
        E: "order",
        T: "NIP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказе",
        E: "order",
        T: "NPS",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "приказах",
        E: "order",
        T: "NPP",
        A: "I",
        O: "приказ",
        RA: 995,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "куст",
        E: "bush",
        T: "NNS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кусты",
        E: "bush",
        T: "NNP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "куста",
        E: "bush",
        T: "NGS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кустов",
        E: "bush",
        T: "NGP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кусту",
        E: "bush",
        T: "NDS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кустам",
        E: "bush",
        T: "NDP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "куст",
        E: "bush",
        T: "NAS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кусты",
        E: "bush",
        T: "NAP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кустом",
        E: "bush",
        T: "NIS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кустами",
        E: "bush",
        T: "NIP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кусте",
        E: "bush",
        T: "NPS",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кустах",
        E: "bush",
        T: "NPP",
        A: "I",
        O: "куст",
        RA: 999,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художник",
        E: "painter, artist",
        T: "NNS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художники",
        E: "painter, artist",
        T: "NNP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художника",
        E: "painter, artist",
        T: "NGS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художников",
        E: "painter, artist",
        T: "NGP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художнику",
        E: "painter, artist",
        T: "NDS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художникам",
        E: "painter, artist",
        T: "NDP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художника",
        E: "painter, artist",
        T: "NAS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художников",
        E: "painter, artist",
        T: "NAP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художником",
        E: "painter, artist",
        T: "NIS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художниками",
        E: "painter, artist",
        T: "NIP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художнике",
        E: "painter, artist",
        T: "NPS",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "художниках",
        E: "painter, artist",
        T: "NPP",
        A: "A",
        O: "художник",
        RA: 1001,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знак",
        E: "sign",
        T: "NNS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаки",
        E: "sign",
        T: "NNP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знака",
        E: "sign",
        T: "NGS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаков",
        E: "sign",
        T: "NGP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаку",
        E: "sign",
        T: "NDS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знакам",
        E: "sign",
        T: "NDP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знак",
        E: "sign",
        T: "NAS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаки",
        E: "sign",
        T: "NAP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаком",
        E: "sign",
        T: "NIS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаками",
        E: "sign",
        T: "NIP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаке",
        E: "sign",
        T: "NPS",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "знаках",
        E: "sign",
        T: "NPP",
        A: "I",
        O: "знак",
        RA: 1002,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "завод",
        E: "factory",
        T: "NNS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводы",
        E: "factory",
        T: "NNP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "завода",
        E: "factory",
        T: "NGS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводов",
        E: "factory",
        T: "NGP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводу",
        E: "factory",
        T: "NDS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводам",
        E: "factory",
        T: "NDP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "завод",
        E: "factory",
        T: "NAS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводы",
        E: "factory",
        T: "NAP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводом",
        E: "factory",
        T: "NIS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводами",
        E: "factory",
        T: "NIP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводе",
        E: "factory",
        T: "NPS",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "заводах",
        E: "factory",
        T: "NPP",
        A: "I",
        O: "завод",
        RA: 1003,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулак",
        E: "fist",
        T: "NNS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаки",
        E: "fist",
        T: "NNP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулака",
        E: "fist",
        T: "NGS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаков",
        E: "fist",
        T: "NGP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаку",
        E: "fist",
        T: "NDS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулакам",
        E: "fist",
        T: "NDP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулак",
        E: "fist",
        T: "NAS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаки",
        E: "fist",
        T: "NAP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаком",
        E: "fist",
        T: "NIS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаками",
        E: "fist",
        T: "NIP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаке",
        E: "fist",
        T: "NPS",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "кулаках",
        E: "fist",
        T: "NPP",
        A: "I",
        O: "кулак",
        RA: 1004,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стакан",
        E: "glass",
        T: "NNS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканы",
        E: "glass",
        T: "NNP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стакана",
        E: "glass",
        T: "NGS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканов",
        E: "glass",
        T: "NGP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стакану",
        E: "glass",
        T: "NDS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканам",
        E: "glass",
        T: "NDP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стакан",
        E: "glass",
        T: "NAS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканы",
        E: "glass",
        T: "NAP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканом",
        E: "glass",
        T: "NIS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканами",
        E: "glass",
        T: "NIP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стакане",
        E: "glass",
        T: "NPS",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "стаканах",
        E: "glass",
        T: "NPP",
        A: "I",
        O: "стакан",
        RA: 1006,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рот",
        E: "mouth",
        T: "NNS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рты",
        E: "mouth",
        T: "NNP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рта",
        E: "mouth",
        T: "NGS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ртов",
        E: "mouth",
        T: "NGP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рту",
        E: "mouth",
        T: "NDS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ртам",
        E: "mouth",
        T: "NDP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рот",
        E: "mouth",
        T: "NAS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рты",
        E: "mouth",
        T: "NAP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ртом",
        E: "mouth",
        T: "NIS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ртами",
        E: "mouth",
        T: "NIP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "рте",
        E: "mouth",
        T: "NPS",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "ртах",
        E: "mouth",
        T: "NPP",
        A: "I",
        O: "рот",
        RA: 1009,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизор",
        E: "TV",
        T: "NNS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизоры",
        E: "TV",
        T: "NNP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизора",
        E: "TV",
        T: "NGS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизоров",
        E: "TV",
        T: "NGP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизору",
        E: "TV",
        T: "NDS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизорам",
        E: "TV",
        T: "NDP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизор",
        E: "TV",
        T: "NAS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизоры",
        E: "TV",
        T: "NAP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизором",
        E: "TV",
        T: "NIS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизорами",
        E: "TV",
        T: "NIP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизоре",
        E: "TV",
        T: "NPS",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "телевизорах",
        E: "TV",
        T: "NPP",
        A: "I",
        O: "телевизор",
        RA: 1015,
        P: 0,
        G: "M",
        "": "",
    },
    {
        R: "москва",
        E: "Moscow",
        T: "NNS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NNP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "москвы",
        E: "Moscow",
        T: "NGS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NGP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "москве",
        E: "Moscow",
        T: "NDS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NDP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "москву",
        E: "Moscow",
        T: "NAS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NAP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "москвой",
        E: "Moscow",
        T: "NIS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NIP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "москве",
        E: "Moscow",
        T: "NPS",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "",
        E: "Moscow",
        T: "NPP",
        A: "I",
        O: "москва",
        RA: 1017,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "тот",
        E: "that",
        T: "ORIGINAL",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тот",
        E: "that",
        T: "ANM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "та",
        E: "that",
        T: "ANF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "то",
        E: "that",
        T: "ANN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "те",
        E: "that",
        T: "ANP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "того",
        E: "that",
        T: "AGM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "той",
        E: "that",
        T: "AGF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "того",
        E: "that",
        T: "AGN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тех",
        E: "that",
        T: "AGP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тому",
        E: "that",
        T: "ADM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "той",
        E: "that",
        T: "ADF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тому",
        E: "that",
        T: "ADN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тем",
        E: "that",
        T: "ADP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тот",
        E: "that",
        T: "AAM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ту",
        E: "that",
        T: "AAF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "то",
        E: "that",
        T: "AAN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "те",
        E: "that",
        T: "AAP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тем",
        E: "that",
        T: "AIM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "той",
        E: "that",
        T: "AIF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тем",
        E: "that",
        T: "AIN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "теми",
        E: "that",
        T: "AIP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "том",
        E: "that",
        T: "APM",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "той",
        E: "that",
        T: "APF",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "том",
        E: "that",
        T: "APN",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тех",
        E: "that",
        T: "APP",
        A: "-",
        O: "тот",
        RA: 7,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весь",
        E: "all, everything",
        T: "ORIGINAL",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весь",
        E: "all, everything",
        T: "ANM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вся",
        E: "all, everything",
        T: "ANF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всё",
        E: "all, everything",
        T: "ANN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "все",
        E: "all, everything",
        T: "ANP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всего",
        E: "all, everything",
        T: "AGM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всей",
        E: "all, everything",
        T: "AGF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всего",
        E: "all, everything",
        T: "AGN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всех",
        E: "all, everything",
        T: "AGP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всему",
        E: "all, everything",
        T: "ADM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всей",
        E: "all, everything",
        T: "ADF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всему",
        E: "all, everything",
        T: "ADN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всем",
        E: "all, everything",
        T: "ADP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весь",
        E: "all, everything",
        T: "AAM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всю",
        E: "all, everything",
        T: "AAF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всё",
        E: "all, everything",
        T: "AAN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "все",
        E: "all, everything",
        T: "AAP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всем",
        E: "all, everything",
        T: "AIM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всей",
        E: "all, everything",
        T: "AIF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всем",
        E: "all, everything",
        T: "AIN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всеми",
        E: "all, everything",
        T: "AIP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всём",
        E: "all, everything",
        T: "APM",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всей",
        E: "all, everything",
        T: "APF",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всём",
        E: "all, everything",
        T: "APN",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всех",
        E: "all, everything",
        T: "APP",
        A: "-",
        O: "весь",
        RA: 11,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этот",
        E: "this",
        T: "ORIGINAL",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этот",
        E: "this",
        T: "ANM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "эта",
        E: "this",
        T: "ANF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "это",
        E: "this",
        T: "ANN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "эти",
        E: "this",
        T: "ANP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этого",
        E: "this",
        T: "AGM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этой",
        E: "this",
        T: "AGF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этого",
        E: "this",
        T: "AGN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этих",
        E: "this",
        T: "AGP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этому",
        E: "this",
        T: "ADM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этой",
        E: "this",
        T: "ADF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этому",
        E: "this",
        T: "ADN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этим",
        E: "this",
        T: "ADP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этот",
        E: "this",
        T: "AAM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "эту",
        E: "this",
        T: "AAF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "это",
        E: "this",
        T: "AAN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "эти",
        E: "this",
        T: "AAP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этим",
        E: "this",
        T: "AIM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этой",
        E: "this",
        T: "AIF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этим",
        E: "this",
        T: "AIN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этими",
        E: "this",
        T: "AIP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этом",
        E: "this",
        T: "APM",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этой",
        E: "this",
        T: "APF",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этом",
        E: "this",
        T: "APN",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "этих",
        E: "this",
        T: "APP",
        A: "-",
        O: "этот",
        RA: 29,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "который",
        E: "which, who, that",
        T: "ORIGINAL",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "который",
        E: "which, who, that",
        T: "ANM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которая",
        E: "which, who, that",
        T: "ANF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которое",
        E: "which, who, that",
        T: "ANN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которые",
        E: "which, who, that",
        T: "ANP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которого",
        E: "which, who, that",
        T: "AGM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которой",
        E: "which, who, that",
        T: "AGF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которого",
        E: "which, who, that",
        T: "AGN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которых",
        E: "which, who, that",
        T: "AGP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которому",
        E: "which, who, that",
        T: "ADM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которой",
        E: "which, who, that",
        T: "ADF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которому",
        E: "which, who, that",
        T: "ADN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которым",
        E: "which, who, that",
        T: "ADP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "который",
        E: "which, who, that",
        T: "AAM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которую",
        E: "which, who, that",
        T: "AAF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которое",
        E: "which, who, that",
        T: "AAN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которые",
        E: "which, who, that",
        T: "AAP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которым",
        E: "which, who, that",
        T: "AIM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которой",
        E: "which, who, that",
        T: "AIF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которым",
        E: "which, who, that",
        T: "AIN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которыми",
        E: "which, who, that",
        T: "AIP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "котором",
        E: "which, who, that",
        T: "APM",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которой",
        E: "which, who, that",
        T: "APF",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "котором",
        E: "which, who, that",
        T: "APN",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "которых",
        E: "which, who, that",
        T: "APP",
        A: "-",
        O: "который",
        RA: 30,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "ORIGINAL",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "ANM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такая",
        E: "such, so, some",
        T: "ANF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такое",
        E: "such, so, some",
        T: "ANN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такие",
        E: "such, so, some",
        T: "ANP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такого",
        E: "such, so, some",
        T: "AGM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "AGF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такого",
        E: "such, so, some",
        T: "AGN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таких",
        E: "such, so, some",
        T: "AGP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такому",
        E: "such, so, some",
        T: "ADM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "ADF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такому",
        E: "such, so, some",
        T: "ADN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таким",
        E: "such, so, some",
        T: "ADP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "AAM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такую",
        E: "such, so, some",
        T: "AAF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такое",
        E: "such, so, some",
        T: "AAN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такие",
        E: "such, so, some",
        T: "AAP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таким",
        E: "such, so, some",
        T: "AIM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "AIF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таким",
        E: "such, so, some",
        T: "AIN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такими",
        E: "such, so, some",
        T: "AIP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таком",
        E: "such, so, some",
        T: "APM",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "такой",
        E: "such, so, some",
        T: "APF",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таком",
        E: "such, so, some",
        T: "APN",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таких",
        E: "such, so, some",
        T: "APP",
        A: "-",
        O: "такой",
        RA: 37,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "ORIGINAL",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "ANM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какая",
        E: "what, which, how",
        T: "ANF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какое",
        E: "what, which, how",
        T: "ANN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какие",
        E: "what, which, how",
        T: "ANP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какого",
        E: "what, which, how",
        T: "AGM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "AGF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какого",
        E: "what, which, how",
        T: "AGN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каких",
        E: "what, which, how",
        T: "AGP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какому",
        E: "what, which, how",
        T: "ADM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "ADF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какому",
        E: "what, which, how",
        T: "ADN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каким",
        E: "what, which, how",
        T: "ADP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "AAM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какую",
        E: "what, which, how",
        T: "AAF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какое",
        E: "what, which, how",
        T: "AAN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какие",
        E: "what, which, how",
        T: "AAP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каким",
        E: "what, which, how",
        T: "AIM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "AIF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каким",
        E: "what, which, how",
        T: "AIN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какими",
        E: "what, which, how",
        T: "AIP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каком",
        E: "what, which, how",
        T: "APM",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "какой",
        E: "what, which, how",
        T: "APF",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каком",
        E: "what, which, how",
        T: "APN",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каких",
        E: "what, which, how",
        T: "APP",
        A: "-",
        O: "какой",
        RA: 41,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мой",
        E: "my, mine",
        T: "ORIGINAL",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мой",
        E: "my, mine",
        T: "ANM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моя",
        E: "my, mine",
        T: "ANF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моё",
        E: "my, mine",
        T: "ANN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мои",
        E: "my, mine",
        T: "ANP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моего",
        E: "my, mine",
        T: "AGM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моей",
        E: "my, mine",
        T: "AGF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моего",
        E: "my, mine",
        T: "AGN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моих",
        E: "my, mine",
        T: "AGP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моему",
        E: "my, mine",
        T: "ADM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моей",
        E: "my, mine",
        T: "ADF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моему",
        E: "my, mine",
        T: "ADN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моим",
        E: "my, mine",
        T: "ADP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мой",
        E: "my, mine",
        T: "AAM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мою",
        E: "my, mine",
        T: "AAF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моё",
        E: "my, mine",
        T: "AAN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мои",
        E: "my, mine",
        T: "AAP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моим",
        E: "my, mine",
        T: "AIM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моей",
        E: "my, mine",
        T: "AIF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моим",
        E: "my, mine",
        T: "AIN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моими",
        E: "my, mine",
        T: "AIP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моём",
        E: "my, mine",
        T: "APM",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моей",
        E: "my, mine",
        T: "APF",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моём",
        E: "my, mine",
        T: "APN",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моих",
        E: "my, mine",
        T: "APP",
        A: "-",
        O: "мой",
        RA: 52,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самый",
        E: "most, the very, the same",
        T: "ORIGINAL",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самый",
        E: "most, the very, the same",
        T: "ANM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самая",
        E: "most, the very, the same",
        T: "ANF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самое",
        E: "most, the very, the same",
        T: "ANN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самые",
        E: "most, the very, the same",
        T: "ANP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самого",
        E: "most, the very, the same",
        T: "AGM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "most, the very, the same",
        T: "AGF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самого",
        E: "most, the very, the same",
        T: "AGN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самых",
        E: "most, the very, the same",
        T: "AGP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самому",
        E: "most, the very, the same",
        T: "ADM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "most, the very, the same",
        T: "ADF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самому",
        E: "most, the very, the same",
        T: "ADN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самым",
        E: "most, the very, the same",
        T: "ADP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самый",
        E: "most, the very, the same",
        T: "AAM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самую",
        E: "most, the very, the same",
        T: "AAF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самое",
        E: "most, the very, the same",
        T: "AAN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самые",
        E: "most, the very, the same",
        T: "AAP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самым",
        E: "most, the very, the same",
        T: "AIM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "most, the very, the same",
        T: "AIF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самым",
        E: "most, the very, the same",
        T: "AIN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самыми",
        E: "most, the very, the same",
        T: "AIP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самом",
        E: "most, the very, the same",
        T: "APM",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "most, the very, the same",
        T: "APF",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самом",
        E: "most, the very, the same",
        T: "APN",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самых",
        E: "most, the very, the same",
        T: "APP",
        A: "-",
        O: "самый",
        RA: 59,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "ORIGINAL",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "ANM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большая",
        E: "big, large, important",
        T: "ANF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большое",
        E: "big, large, important",
        T: "ANN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большие",
        E: "big, large, important",
        T: "ANP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большого",
        E: "big, large, important",
        T: "AGM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "AGF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большого",
        E: "big, large, important",
        T: "AGN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больших",
        E: "big, large, important",
        T: "AGP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большому",
        E: "big, large, important",
        T: "ADM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "ADF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большому",
        E: "big, large, important",
        T: "ADN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большим",
        E: "big, large, important",
        T: "ADP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большого",
        E: "big, large, important",
        T: "AAM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большую",
        E: "big, large, important",
        T: "AAF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большое",
        E: "big, large, important",
        T: "AAN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больших",
        E: "big, large, important",
        T: "AAP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большим",
        E: "big, large, important",
        T: "AIM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "AIF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большим",
        E: "big, large, important",
        T: "AIN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большими",
        E: "big, large, important",
        T: "AIP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большом",
        E: "big, large, important",
        T: "APM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большой",
        E: "big, large, important",
        T: "APF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "большом",
        E: "big, large, important",
        T: "APN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больших",
        E: "big, large, important",
        T: "APP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велик",
        E: "big, large, important",
        T: "ASM",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велика",
        E: "big, large, important",
        T: "ASF",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велико",
        E: "big, large, important",
        T: "ASN",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велики",
        E: "big, large, important",
        T: "ASP",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больше",
        E: "big, large, important",
        T: "AC1",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больший",
        E: "big, large, important",
        T: "AC2",
        A: "-",
        O: "большой",
        RA: 62,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "ORIGINAL",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "ANM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другая",
        E: "other, another, different",
        T: "ANF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другое",
        E: "other, another, different",
        T: "ANN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другие",
        E: "other, another, different",
        T: "ANP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другого",
        E: "other, another, different",
        T: "AGM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "AGF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другого",
        E: "other, another, different",
        T: "AGN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "других",
        E: "other, another, different",
        T: "AGP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другому",
        E: "other, another, different",
        T: "ADM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "ADF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другому",
        E: "other, another, different",
        T: "ADN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другим",
        E: "other, another, different",
        T: "ADP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "AAM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другую",
        E: "other, another, different",
        T: "AAF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другое",
        E: "other, another, different",
        T: "AAN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другие",
        E: "other, another, different",
        T: "AAP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другим",
        E: "other, another, different",
        T: "AIM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "AIF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другим",
        E: "other, another, different",
        T: "AIN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другими",
        E: "other, another, different",
        T: "AIP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другом",
        E: "other, another, different",
        T: "APM",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другой",
        E: "other, another, different",
        T: "APF",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "другом",
        E: "other, another, different",
        T: "APN",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "других",
        E: "other, another, different",
        T: "APP",
        A: "-",
        O: "другой",
        RA: 64,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свой",
        E: "my, our, your",
        T: "ORIGINAL",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свой",
        E: "my, our, your",
        T: "ANM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своя",
        E: "my, our, your",
        T: "ANF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своё",
        E: "my, our, your",
        T: "ANN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свои",
        E: "my, our, your",
        T: "ANP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своего",
        E: "my, our, your",
        T: "AGM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своей",
        E: "my, our, your",
        T: "AGF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своего",
        E: "my, our, your",
        T: "AGN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своих",
        E: "my, our, your",
        T: "AGP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своему",
        E: "my, our, your",
        T: "ADM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своей",
        E: "my, our, your",
        T: "ADF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своему",
        E: "my, our, your",
        T: "ADN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своим",
        E: "my, our, your",
        T: "ADP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свой",
        E: "my, our, your",
        T: "AAM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свою",
        E: "my, our, your",
        T: "AAF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своё",
        E: "my, our, your",
        T: "AAN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свои",
        E: "my, our, your",
        T: "AAP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своим",
        E: "my, our, your",
        T: "AIM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своей",
        E: "my, our, your",
        T: "AIF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своим",
        E: "my, our, your",
        T: "AIN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своими",
        E: "my, our, your",
        T: "AIP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своём",
        E: "my, our, your",
        T: "APM",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своей",
        E: "my, our, your",
        T: "APF",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своём",
        E: "my, our, your",
        T: "APN",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "своих",
        E: "my, our, your",
        T: "APP",
        A: "-",
        O: "свой",
        RA: 66,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сам",
        E: "myself, yourself",
        T: "ORIGINAL",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сам",
        E: "myself, yourself",
        T: "ANM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сама",
        E: "myself, yourself",
        T: "ANF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "само",
        E: "myself, yourself",
        T: "ANN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сами",
        E: "myself, yourself",
        T: "ANP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самого",
        E: "myself, yourself",
        T: "AGM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "myself, yourself",
        T: "AGF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самого",
        E: "myself, yourself",
        T: "AGN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самих",
        E: "myself, yourself",
        T: "AGP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самому",
        E: "myself, yourself",
        T: "ADM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "myself, yourself",
        T: "ADF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самому",
        E: "myself, yourself",
        T: "ADN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самим",
        E: "myself, yourself",
        T: "ADP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сам",
        E: "myself, yourself",
        T: "AAM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "саму",
        E: "myself, yourself",
        T: "AAF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "само",
        E: "myself, yourself",
        T: "AAN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сами",
        E: "myself, yourself",
        T: "AAP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самим",
        E: "myself, yourself",
        T: "AIM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "myself, yourself",
        T: "AIF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самим",
        E: "myself, yourself",
        T: "AIN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самими",
        E: "myself, yourself",
        T: "AIP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самом",
        E: "myself, yourself",
        T: "APM",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самой",
        E: "myself, yourself",
        T: "APF",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самом",
        E: "myself, yourself",
        T: "APN",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "самих",
        E: "myself, yourself",
        T: "APP",
        A: "-",
        O: "сам",
        RA: 72,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первый",
        E: "first, front, former",
        T: "ORIGINAL",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первый",
        E: "first, front, former",
        T: "ANM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первая",
        E: "first, front, former",
        T: "ANF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первое",
        E: "first, front, former",
        T: "ANN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первые",
        E: "first, front, former",
        T: "ANP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первого",
        E: "first, front, former",
        T: "AGM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первой",
        E: "first, front, former",
        T: "AGF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первого",
        E: "first, front, former",
        T: "AGN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первых",
        E: "first, front, former",
        T: "AGP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первому",
        E: "first, front, former",
        T: "ADM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первой",
        E: "first, front, former",
        T: "ADF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первому",
        E: "first, front, former",
        T: "ADN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первым",
        E: "first, front, former",
        T: "ADP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первый",
        E: "first, front, former",
        T: "AAM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первую",
        E: "first, front, former",
        T: "AAF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первое",
        E: "first, front, former",
        T: "AAN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первые",
        E: "first, front, former",
        T: "AAP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первым",
        E: "first, front, former",
        T: "AIM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первой",
        E: "first, front, former",
        T: "AIF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первым",
        E: "first, front, former",
        T: "AIN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первыми",
        E: "first, front, former",
        T: "AIP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первом",
        E: "first, front, former",
        T: "APM",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первой",
        E: "first, front, former",
        T: "APF",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первом",
        E: "first, front, former",
        T: "APN",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "первых",
        E: "first, front, former",
        T: "APP",
        A: "-",
        O: "первый",
        RA: 80,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороший",
        E: "good, nice",
        T: "ORIGINAL",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороший",
        E: "good, nice",
        T: "ANM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошая",
        E: "good, nice",
        T: "ANF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошее",
        E: "good, nice",
        T: "ANN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошие",
        E: "good, nice",
        T: "ANP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошего",
        E: "good, nice",
        T: "AGM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошей",
        E: "good, nice",
        T: "AGF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошего",
        E: "good, nice",
        T: "AGN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороших",
        E: "good, nice",
        T: "AGP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошему",
        E: "good, nice",
        T: "ADM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошей",
        E: "good, nice",
        T: "ADF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошему",
        E: "good, nice",
        T: "ADN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошим",
        E: "good, nice",
        T: "ADP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошего",
        E: "good, nice",
        T: "AAM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошую",
        E: "good, nice",
        T: "AAF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошее",
        E: "good, nice",
        T: "AAN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороших",
        E: "good, nice",
        T: "AAP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошим",
        E: "good, nice",
        T: "AIM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошей",
        E: "good, nice",
        T: "AIF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошим",
        E: "good, nice",
        T: "AIN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошими",
        E: "good, nice",
        T: "AIP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошем",
        E: "good, nice",
        T: "APM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошей",
        E: "good, nice",
        T: "APF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошем",
        E: "good, nice",
        T: "APN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороших",
        E: "good, nice",
        T: "APP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорош",
        E: "good, nice",
        T: "ASM",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороша",
        E: "good, nice",
        T: "ASF",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошо",
        E: "good, nice",
        T: "ASN",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хороши",
        E: "good, nice",
        T: "ASP",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучше",
        E: "good, nice",
        T: "AC1",
        A: "-",
        O: "хороший",
        RA: 115,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждый",
        E: "each, every",
        T: "ORIGINAL",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждый",
        E: "each, every",
        T: "ANM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждая",
        E: "each, every",
        T: "ANF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждое",
        E: "each, every",
        T: "ANN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждые",
        E: "each, every",
        T: "ANP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждого",
        E: "each, every",
        T: "AGM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждой",
        E: "each, every",
        T: "AGF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждого",
        E: "each, every",
        T: "AGN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждых",
        E: "each, every",
        T: "AGP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждому",
        E: "each, every",
        T: "ADM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждой",
        E: "each, every",
        T: "ADF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждому",
        E: "each, every",
        T: "ADN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждым",
        E: "each, every",
        T: "ADP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждого",
        E: "each, every",
        T: "AAM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждую",
        E: "each, every",
        T: "AAF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждое",
        E: "each, every",
        T: "AAN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждых",
        E: "each, every",
        T: "AAP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждым",
        E: "each, every",
        T: "AIM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждой",
        E: "each, every",
        T: "AIF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждым",
        E: "each, every",
        T: "AIN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждыми",
        E: "each, every",
        T: "AIP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждом",
        E: "each, every",
        T: "APM",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждой",
        E: "each, every",
        T: "APF",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждом",
        E: "each, every",
        T: "APN",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "каждых",
        E: "each, every",
        T: "APP",
        A: "-",
        O: "каждый",
        RA: 116,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новый",
        E: "new",
        T: "ORIGINAL",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новый",
        E: "new",
        T: "ANM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новая",
        E: "new",
        T: "ANF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новое",
        E: "new",
        T: "ANN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новые",
        E: "new",
        T: "ANP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нового",
        E: "new",
        T: "AGM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новой",
        E: "new",
        T: "AGF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нового",
        E: "new",
        T: "AGN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новых",
        E: "new",
        T: "AGP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новому",
        E: "new",
        T: "ADM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новой",
        E: "new",
        T: "ADF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новому",
        E: "new",
        T: "ADN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новым",
        E: "new",
        T: "ADP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новый",
        E: "new",
        T: "AAM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новую",
        E: "new",
        T: "AAF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новое",
        E: "new",
        T: "AAN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новые",
        E: "new",
        T: "AAP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новым",
        E: "new",
        T: "AIM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новой",
        E: "new",
        T: "AIF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новым",
        E: "new",
        T: "AIN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новыми",
        E: "new",
        T: "AIP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новом",
        E: "new",
        T: "APM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новой",
        E: "new",
        T: "APF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новом",
        E: "new",
        T: "APN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новых",
        E: "new",
        T: "APP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нов",
        E: "new",
        T: "ASM",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нова",
        E: "new",
        T: "ASF",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ново",
        E: "new",
        T: "ASN",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новы",
        E: "new",
        T: "ASP",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "новее",
        E: "new",
        T: "AC1",
        A: "-",
        O: "новый",
        RA: 117,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должный",
        E: "due, proper",
        T: "ORIGINAL",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должный",
        E: "due, proper",
        T: "ANM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должная",
        E: "due, proper",
        T: "ANF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должное",
        E: "due, proper",
        T: "ANN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должные",
        E: "due, proper",
        T: "ANP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должного",
        E: "due, proper",
        T: "AGM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должной",
        E: "due, proper",
        T: "AGF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должного",
        E: "due, proper",
        T: "AGN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должных",
        E: "due, proper",
        T: "AGP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должному",
        E: "due, proper",
        T: "ADM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должной",
        E: "due, proper",
        T: "ADF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должному",
        E: "due, proper",
        T: "ADN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должным",
        E: "due, proper",
        T: "ADP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должный",
        E: "due, proper",
        T: "AAM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должную",
        E: "due, proper",
        T: "AAF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должное",
        E: "due, proper",
        T: "AAN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должные",
        E: "due, proper",
        T: "AAP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должным",
        E: "due, proper",
        T: "AIM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должной",
        E: "due, proper",
        T: "AIF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должным",
        E: "due, proper",
        T: "AIN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должными",
        E: "due, proper",
        T: "AIP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должном",
        E: "due, proper",
        T: "APM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должной",
        E: "due, proper",
        T: "APF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должном",
        E: "due, proper",
        T: "APN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должных",
        E: "due, proper",
        T: "APP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должен",
        E: "due, proper",
        T: "ASM",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должна",
        E: "due, proper",
        T: "ASF",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должно",
        E: "due, proper",
        T: "ASN",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "должны",
        E: "due, proper",
        T: "ASP",
        A: "-",
        O: "должный",
        RA: 119,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечный",
        E: "final, last",
        T: "ORIGINAL",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечный",
        E: "final, last",
        T: "ANM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечная",
        E: "final, last",
        T: "ANF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечное",
        E: "final, last",
        T: "ANN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечные",
        E: "final, last",
        T: "ANP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечного",
        E: "final, last",
        T: "AGM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечной",
        E: "final, last",
        T: "AGF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечного",
        E: "final, last",
        T: "AGN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечных",
        E: "final, last",
        T: "AGP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечному",
        E: "final, last",
        T: "ADM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечной",
        E: "final, last",
        T: "ADF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечному",
        E: "final, last",
        T: "ADN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечным",
        E: "final, last",
        T: "ADP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечный",
        E: "final, last",
        T: "AAM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечную",
        E: "final, last",
        T: "AAF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечное",
        E: "final, last",
        T: "AAN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечные",
        E: "final, last",
        T: "AAP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечным",
        E: "final, last",
        T: "AIM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечной",
        E: "final, last",
        T: "AIF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечным",
        E: "final, last",
        T: "AIN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечными",
        E: "final, last",
        T: "AIP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечном",
        E: "final, last",
        T: "APM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечной",
        E: "final, last",
        T: "APF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечном",
        E: "final, last",
        T: "APN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечных",
        E: "final, last",
        T: "APP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечен",
        E: "final, last",
        T: "ASM",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечна",
        E: "final, last",
        T: "ASF",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечно",
        E: "final, last",
        T: "ASN",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "конечны",
        E: "final, last",
        T: "ASP",
        A: "-",
        O: "конечный",
        RA: 129,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужный",
        E: "necessary",
        T: "ORIGINAL",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужный",
        E: "necessary",
        T: "ANM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужная",
        E: "necessary",
        T: "ANF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужное",
        E: "necessary",
        T: "ANN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужные",
        E: "necessary",
        T: "ANP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужного",
        E: "necessary",
        T: "AGM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужной",
        E: "necessary",
        T: "AGF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужного",
        E: "necessary",
        T: "AGN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужных",
        E: "necessary",
        T: "AGP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужному",
        E: "necessary",
        T: "ADM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужной",
        E: "necessary",
        T: "ADF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужному",
        E: "necessary",
        T: "ADN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужным",
        E: "necessary",
        T: "ADP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужный",
        E: "necessary",
        T: "AAM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужную",
        E: "necessary",
        T: "AAF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужное",
        E: "necessary",
        T: "AAN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужные",
        E: "necessary",
        T: "AAP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужным",
        E: "necessary",
        T: "AIM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужной",
        E: "necessary",
        T: "AIF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужным",
        E: "necessary",
        T: "AIN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужными",
        E: "necessary",
        T: "AIP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужном",
        E: "necessary",
        T: "APM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужной",
        E: "necessary",
        T: "APF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужном",
        E: "necessary",
        T: "APN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужных",
        E: "necessary",
        T: "APP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужен",
        E: "necessary",
        T: "ASM",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужна",
        E: "necessary",
        T: "ASF",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужно",
        E: "necessary",
        T: "ASN",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нужны",
        E: "necessary",
        T: "ASP",
        A: "-",
        O: "нужный",
        RA: 138,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последний",
        E: "last, the latest, new",
        T: "ORIGINAL",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последний",
        E: "last, the latest, new",
        T: "ANM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последняя",
        E: "last, the latest, new",
        T: "ANF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнее",
        E: "last, the latest, new",
        T: "ANN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последние",
        E: "last, the latest, new",
        T: "ANP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнего",
        E: "last, the latest, new",
        T: "AGM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последней",
        E: "last, the latest, new",
        T: "AGF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнего",
        E: "last, the latest, new",
        T: "AGN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последних",
        E: "last, the latest, new",
        T: "AGP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнему",
        E: "last, the latest, new",
        T: "ADM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последней",
        E: "last, the latest, new",
        T: "ADF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнему",
        E: "last, the latest, new",
        T: "ADN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последним",
        E: "last, the latest, new",
        T: "ADP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последний",
        E: "last, the latest, new",
        T: "AAM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнюю",
        E: "last, the latest, new",
        T: "AAF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнее",
        E: "last, the latest, new",
        T: "AAN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последние",
        E: "last, the latest, new",
        T: "AAP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последним",
        E: "last, the latest, new",
        T: "AIM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последней",
        E: "last, the latest, new",
        T: "AIF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последним",
        E: "last, the latest, new",
        T: "AIN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последними",
        E: "last, the latest, new",
        T: "AIP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнем",
        E: "last, the latest, new",
        T: "APM",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последней",
        E: "last, the latest, new",
        T: "APF",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последнем",
        E: "last, the latest, new",
        T: "APN",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "последних",
        E: "last, the latest, new",
        T: "APP",
        A: "-",
        O: "последний",
        RA: 151,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "ORIGINAL",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "ANM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторая",
        E: "second",
        T: "ANF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второе",
        E: "second",
        T: "ANN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторые",
        E: "second",
        T: "ANP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второго",
        E: "second",
        T: "AGM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "AGF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второго",
        E: "second",
        T: "AGN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторых",
        E: "second",
        T: "AGP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второму",
        E: "second",
        T: "ADM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "ADF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второму",
        E: "second",
        T: "ADN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторым",
        E: "second",
        T: "ADP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второго",
        E: "second",
        T: "AAM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторую",
        E: "second",
        T: "AAF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второе",
        E: "second",
        T: "AAN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторых",
        E: "second",
        T: "AAP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторым",
        E: "second",
        T: "AIM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "AIF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторым",
        E: "second",
        T: "AIN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторыми",
        E: "second",
        T: "AIP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "втором",
        E: "second",
        T: "APM",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "второй",
        E: "second",
        T: "APF",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "втором",
        E: "second",
        T: "APN",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вторых",
        E: "second",
        T: "APP",
        A: "-",
        O: "второй",
        RA: 160,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старый",
        E: "old",
        T: "ORIGINAL",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старый",
        E: "old",
        T: "ANM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старая",
        E: "old",
        T: "ANF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старое",
        E: "old",
        T: "ANN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старые",
        E: "old",
        T: "ANP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старого",
        E: "old",
        T: "AGM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старой",
        E: "old",
        T: "AGF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старого",
        E: "old",
        T: "AGN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старых",
        E: "old",
        T: "AGP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старому",
        E: "old",
        T: "ADM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старой",
        E: "old",
        T: "ADF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старому",
        E: "old",
        T: "ADN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старым",
        E: "old",
        T: "ADP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старый",
        E: "old",
        T: "AAM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старую",
        E: "old",
        T: "AAF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старое",
        E: "old",
        T: "AAN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старых",
        E: "old",
        T: "AAP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старым",
        E: "old",
        T: "AIM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старой",
        E: "old",
        T: "AIF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старым",
        E: "old",
        T: "AIN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старыми",
        E: "old",
        T: "AIP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старом",
        E: "old",
        T: "APM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старой",
        E: "old",
        T: "APF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старом",
        E: "old",
        T: "APN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старых",
        E: "old",
        T: "APP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стар",
        E: "old",
        T: "ASM",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стара",
        E: "old",
        T: "ASF",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старо старо",
        E: "old",
        T: "ASN",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "стары стары",
        E: "old",
        T: "ASP",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старше",
        E: "old",
        T: "AC1",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старее",
        E: "old",
        T: "AC2",
        A: "-",
        O: "старый",
        RA: 182,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твой",
        E: "your, yours (informal)",
        T: "ORIGINAL",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твой",
        E: "your, yours (informal)",
        T: "ANM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоя",
        E: "your, yours (informal)",
        T: "ANF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоё",
        E: "your, yours (informal)",
        T: "ANN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твои",
        E: "your, yours (informal)",
        T: "ANP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоего",
        E: "your, yours (informal)",
        T: "AGM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоей",
        E: "your, yours (informal)",
        T: "AGF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоего",
        E: "your, yours (informal)",
        T: "AGN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоих",
        E: "your, yours (informal)",
        T: "AGP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоему",
        E: "your, yours (informal)",
        T: "ADM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоей",
        E: "your, yours (informal)",
        T: "ADF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоему",
        E: "your, yours (informal)",
        T: "ADN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоим",
        E: "your, yours (informal)",
        T: "ADP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твой",
        E: "your, yours (informal)",
        T: "AAM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твою",
        E: "your, yours (informal)",
        T: "AAF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоё",
        E: "your, yours (informal)",
        T: "AAN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твои",
        E: "your, yours (informal)",
        T: "AAP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоим",
        E: "your, yours (informal)",
        T: "AIM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоей",
        E: "your, yours (informal)",
        T: "AIF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоим",
        E: "your, yours (informal)",
        T: "AIN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоими",
        E: "your, yours (informal)",
        T: "AIP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоём",
        E: "your, yours (informal)",
        T: "APM",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоей",
        E: "your, yours (informal)",
        T: "APF",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоём",
        E: "your, yours (informal)",
        T: "APN",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "твоих",
        E: "your, yours (informal)",
        T: "APP",
        A: "-",
        O: "твой",
        RA: 187,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белый",
        E: "white",
        T: "ORIGINAL",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белый",
        E: "white",
        T: "ANM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белая",
        E: "white",
        T: "ANF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белое",
        E: "white",
        T: "ANN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белые",
        E: "white",
        T: "ANP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белого",
        E: "white",
        T: "AGM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белой",
        E: "white",
        T: "AGF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белого",
        E: "white",
        T: "AGN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белых",
        E: "white",
        T: "AGP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белому",
        E: "white",
        T: "ADM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белой",
        E: "white",
        T: "ADF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белому",
        E: "white",
        T: "ADN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белым",
        E: "white",
        T: "ADP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белый",
        E: "white",
        T: "AAM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белую",
        E: "white",
        T: "AAF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белое",
        E: "white",
        T: "AAN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белые",
        E: "white",
        T: "AAP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белым",
        E: "white",
        T: "AIM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белой",
        E: "white",
        T: "AIF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белым",
        E: "white",
        T: "AIN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белыми",
        E: "white",
        T: "AIP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белом",
        E: "white",
        T: "APM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белой",
        E: "white",
        T: "APF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белом",
        E: "white",
        T: "APN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белых",
        E: "white",
        T: "APP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бел",
        E: "white",
        T: "ASM",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бела",
        E: "white",
        T: "ASF",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бело бело",
        E: "white",
        T: "ASN",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белы белы",
        E: "white",
        T: "ASP",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белее",
        E: "white",
        T: "AC1",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "белей",
        E: "white",
        T: "AC2",
        A: "-",
        O: "белый",
        RA: 196,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главный",
        E: "main, chief",
        T: "ORIGINAL",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главный",
        E: "main, chief",
        T: "ANM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главная",
        E: "main, chief",
        T: "ANF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главное",
        E: "main, chief",
        T: "ANN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главные",
        E: "main, chief",
        T: "ANP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главного",
        E: "main, chief",
        T: "AGM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главной",
        E: "main, chief",
        T: "AGF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главного",
        E: "main, chief",
        T: "AGN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главных",
        E: "main, chief",
        T: "AGP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главному",
        E: "main, chief",
        T: "ADM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главной",
        E: "main, chief",
        T: "ADF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главному",
        E: "main, chief",
        T: "ADN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главным",
        E: "main, chief",
        T: "ADP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главный",
        E: "main, chief",
        T: "AAM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главную",
        E: "main, chief",
        T: "AAF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главное",
        E: "main, chief",
        T: "AAN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главные",
        E: "main, chief",
        T: "AAP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главным",
        E: "main, chief",
        T: "AIM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главной",
        E: "main, chief",
        T: "AIF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главным",
        E: "main, chief",
        T: "AIN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главными",
        E: "main, chief",
        T: "AIP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главном",
        E: "main, chief",
        T: "APM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главной",
        E: "main, chief",
        T: "APF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главном",
        E: "main, chief",
        T: "APN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главных",
        E: "main, chief",
        T: "APP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главен",
        E: "main, chief",
        T: "ASM",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главна",
        E: "main, chief",
        T: "ASF",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главно",
        E: "main, chief",
        T: "ASN",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главны",
        E: "main, chief",
        T: "ASP",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "главнее",
        E: "main, chief",
        T: "AC1",
        A: "-",
        O: "главный",
        RA: 206,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "ORIGINAL",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "ANM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакая",
        E: "no, none",
        T: "ANF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакое",
        E: "no, none",
        T: "ANN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакие",
        E: "no, none",
        T: "ANP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакого",
        E: "no, none",
        T: "AGM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "AGF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакого",
        E: "no, none",
        T: "AGN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаких",
        E: "no, none",
        T: "AGP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакому",
        E: "no, none",
        T: "ADM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "ADF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакому",
        E: "no, none",
        T: "ADN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаким",
        E: "no, none",
        T: "ADP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "AAM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакую",
        E: "no, none",
        T: "AAF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакое",
        E: "no, none",
        T: "AAN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакие",
        E: "no, none",
        T: "AAP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаким",
        E: "no, none",
        T: "AIM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "AIF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаким",
        E: "no, none",
        T: "AIN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакими",
        E: "no, none",
        T: "AIP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаком",
        E: "no, none",
        T: "APM",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никакой",
        E: "no, none",
        T: "APF",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаком",
        E: "no, none",
        T: "APN",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никаких",
        E: "no, none",
        T: "APP",
        A: "-",
        O: "никакой",
        RA: 219,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленький",
        E: "small, little",
        T: "ORIGINAL",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленький",
        E: "small, little",
        T: "ANM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькая",
        E: "small, little",
        T: "ANF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькое",
        E: "small, little",
        T: "ANN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькие",
        E: "small, little",
        T: "ANP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленького",
        E: "small, little",
        T: "AGM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькой",
        E: "small, little",
        T: "AGF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленького",
        E: "small, little",
        T: "AGN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьких",
        E: "small, little",
        T: "AGP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькому",
        E: "small, little",
        T: "ADM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькой",
        E: "small, little",
        T: "ADF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькому",
        E: "small, little",
        T: "ADN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьким",
        E: "small, little",
        T: "ADP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленький",
        E: "small, little",
        T: "AAM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькую",
        E: "small, little",
        T: "AAF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькое",
        E: "small, little",
        T: "AAN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькие",
        E: "small, little",
        T: "AAP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьким",
        E: "small, little",
        T: "AIM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькой",
        E: "small, little",
        T: "AIF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьким",
        E: "small, little",
        T: "AIN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькими",
        E: "small, little",
        T: "AIP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьком",
        E: "small, little",
        T: "APM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленькой",
        E: "small, little",
        T: "APF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьком",
        E: "small, little",
        T: "APN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "маленьких",
        E: "small, little",
        T: "APP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мал",
        E: "small, little",
        T: "ASM",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мала",
        E: "small, little",
        T: "ASF",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мало",
        E: "small, little",
        T: "ASN",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "малы",
        E: "small, little",
        T: "ASP",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "меньше",
        E: "small, little",
        T: "AC1",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "меньший",
        E: "small, little",
        T: "AC2",
        A: "-",
        O: "маленький",
        RA: 227,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкий",
        E: "distant, remote",
        T: "ORIGINAL",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкий",
        E: "distant, remote",
        T: "ANM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкая",
        E: "distant, remote",
        T: "ANF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкое",
        E: "distant, remote",
        T: "ANN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкие",
        E: "distant, remote",
        T: "ANP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкого",
        E: "distant, remote",
        T: "AGM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкой",
        E: "distant, remote",
        T: "AGF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкого",
        E: "distant, remote",
        T: "AGN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёких",
        E: "distant, remote",
        T: "AGP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкому",
        E: "distant, remote",
        T: "ADM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкой",
        E: "distant, remote",
        T: "ADF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкому",
        E: "distant, remote",
        T: "ADN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёким",
        E: "distant, remote",
        T: "ADP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкий",
        E: "distant, remote",
        T: "AAM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкую",
        E: "distant, remote",
        T: "AAF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкое",
        E: "distant, remote",
        T: "AAN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкие",
        E: "distant, remote",
        T: "AAP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёким",
        E: "distant, remote",
        T: "AIM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкой",
        E: "distant, remote",
        T: "AIF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёким",
        E: "distant, remote",
        T: "AIN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкими",
        E: "distant, remote",
        T: "AIP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёком",
        E: "distant, remote",
        T: "APM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёкой",
        E: "distant, remote",
        T: "APF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёком",
        E: "distant, remote",
        T: "APN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёких",
        E: "distant, remote",
        T: "APP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далёк",
        E: "distant, remote",
        T: "ASM",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далека",
        E: "distant, remote",
        T: "ASF",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далеко",
        E: "distant, remote",
        T: "ASN",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далеки",
        E: "distant, remote",
        T: "ASP",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дальше",
        E: "distant, remote",
        T: "AC1",
        A: "-",
        O: "далёкий",
        RA: 230,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторый",
        E: "some",
        T: "ORIGINAL",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторый",
        E: "some",
        T: "ANM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторая",
        E: "some",
        T: "ANF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторое",
        E: "some",
        T: "ANN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторые",
        E: "some",
        T: "ANP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторого",
        E: "some",
        T: "AGM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторой",
        E: "some",
        T: "AGF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторого",
        E: "some",
        T: "AGN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторых",
        E: "some",
        T: "AGP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторому",
        E: "some",
        T: "ADM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторой",
        E: "some",
        T: "ADF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторому",
        E: "some",
        T: "ADN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторым",
        E: "some",
        T: "ADP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторого",
        E: "some",
        T: "AAM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторую",
        E: "some",
        T: "AAF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторое",
        E: "some",
        T: "AAN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторых",
        E: "some",
        T: "AAP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторым",
        E: "some",
        T: "AIM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторой",
        E: "some",
        T: "AIF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторым",
        E: "some",
        T: "AIN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторыми",
        E: "some",
        T: "AIP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некотором",
        E: "some",
        T: "APM",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторой",
        E: "some",
        T: "APF",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некотором",
        E: "some",
        T: "APN",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "некоторых",
        E: "some",
        T: "APP",
        A: "-",
        O: "некоторый",
        RA: 235,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советский",
        E: "Soviet",
        T: "ORIGINAL",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советский",
        E: "Soviet",
        T: "ANM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советская",
        E: "Soviet",
        T: "ANF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советское",
        E: "Soviet",
        T: "ANN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советские",
        E: "Soviet",
        T: "ANP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советского",
        E: "Soviet",
        T: "AGM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советской",
        E: "Soviet",
        T: "AGF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советского",
        E: "Soviet",
        T: "AGN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советских",
        E: "Soviet",
        T: "AGP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советскому",
        E: "Soviet",
        T: "ADM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советской",
        E: "Soviet",
        T: "ADF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советскому",
        E: "Soviet",
        T: "ADN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советским",
        E: "Soviet",
        T: "ADP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советский",
        E: "Soviet",
        T: "AAM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советскую",
        E: "Soviet",
        T: "AAF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советское",
        E: "Soviet",
        T: "AAN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советские",
        E: "Soviet",
        T: "AAP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советским",
        E: "Soviet",
        T: "AIM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советской",
        E: "Soviet",
        T: "AIF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советским",
        E: "Soviet",
        T: "AIN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советскими",
        E: "Soviet",
        T: "AIP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советском",
        E: "Soviet",
        T: "APM",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советской",
        E: "Soviet",
        T: "APF",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советском",
        E: "Soviet",
        T: "APN",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "советских",
        E: "Soviet",
        T: "APP",
        A: "-",
        O: "советский",
        RA: 252,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русский",
        E: "Russian",
        T: "ORIGINAL",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русский",
        E: "Russian",
        T: "ANM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русская",
        E: "Russian",
        T: "ANF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русское",
        E: "Russian",
        T: "ANN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русские",
        E: "Russian",
        T: "ANP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русского",
        E: "Russian",
        T: "AGM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русской",
        E: "Russian",
        T: "AGF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русского",
        E: "Russian",
        T: "AGN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русских",
        E: "Russian",
        T: "AGP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русскому",
        E: "Russian",
        T: "ADM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русской",
        E: "Russian",
        T: "ADF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русскому",
        E: "Russian",
        T: "ADN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русским",
        E: "Russian",
        T: "ADP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русского",
        E: "Russian",
        T: "AAM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русскую",
        E: "Russian",
        T: "AAF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русское",
        E: "Russian",
        T: "AAN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русских",
        E: "Russian",
        T: "AAP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русским",
        E: "Russian",
        T: "AIM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русской",
        E: "Russian",
        T: "AIF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русским",
        E: "Russian",
        T: "AIN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русскими",
        E: "Russian",
        T: "AIP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русском",
        E: "Russian",
        T: "APM",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русской",
        E: "Russian",
        T: "APF",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русском",
        E: "Russian",
        T: "APN",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "русских",
        E: "Russian",
        T: "APP",
        A: "-",
        O: "русский",
        RA: 253,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полный",
        E: "full, complete",
        T: "ORIGINAL",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полный",
        E: "full, complete",
        T: "ANM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полная",
        E: "full, complete",
        T: "ANF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полное",
        E: "full, complete",
        T: "ANN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полные",
        E: "full, complete",
        T: "ANP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полного",
        E: "full, complete",
        T: "AGM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полной",
        E: "full, complete",
        T: "AGF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полного",
        E: "full, complete",
        T: "AGN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полных",
        E: "full, complete",
        T: "AGP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полному",
        E: "full, complete",
        T: "ADM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полной",
        E: "full, complete",
        T: "ADF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полному",
        E: "full, complete",
        T: "ADN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полным",
        E: "full, complete",
        T: "ADP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полный",
        E: "full, complete",
        T: "AAM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полную",
        E: "full, complete",
        T: "AAF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полное",
        E: "full, complete",
        T: "AAN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полные",
        E: "full, complete",
        T: "AAP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полным",
        E: "full, complete",
        T: "AIM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полной",
        E: "full, complete",
        T: "AIF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полным",
        E: "full, complete",
        T: "AIN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полными",
        E: "full, complete",
        T: "AIP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полном",
        E: "full, complete",
        T: "APM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полной",
        E: "full, complete",
        T: "APF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полном",
        E: "full, complete",
        T: "APN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полных",
        E: "full, complete",
        T: "APP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полон",
        E: "full, complete",
        T: "ASM",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полна",
        E: "full, complete",
        T: "ASF",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полно",
        E: "full, complete",
        T: "ASN",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полны",
        E: "full, complete",
        T: "ASP",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "полнее",
        E: "full, complete",
        T: "AC1",
        A: "-",
        O: "полный",
        RA: 255,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "ORIGINAL",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "ANM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любая",
        E: "any, every",
        T: "ANF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любое",
        E: "any, every",
        T: "ANN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любые",
        E: "any, every",
        T: "ANP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любого",
        E: "any, every",
        T: "AGM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "AGF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любого",
        E: "any, every",
        T: "AGN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любых",
        E: "any, every",
        T: "AGP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любому",
        E: "any, every",
        T: "ADM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "ADF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любому",
        E: "any, every",
        T: "ADN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любым",
        E: "any, every",
        T: "ADP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "AAM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любую",
        E: "any, every",
        T: "AAF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любое",
        E: "any, every",
        T: "AAN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любые",
        E: "any, every",
        T: "AAP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любым",
        E: "any, every",
        T: "AIM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "AIF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любым",
        E: "any, every",
        T: "AIN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любыми",
        E: "any, every",
        T: "AIP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любом",
        E: "any, every",
        T: "APM",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любой",
        E: "any, every",
        T: "APF",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любом",
        E: "any, every",
        T: "APN",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любых",
        E: "any, every",
        T: "APP",
        A: "-",
        O: "любой",
        RA: 259,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общий",
        E: "general, common",
        T: "ORIGINAL",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общий",
        E: "general, common",
        T: "ANM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общая",
        E: "general, common",
        T: "ANF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общее",
        E: "general, common",
        T: "ANN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общие",
        E: "general, common",
        T: "ANP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общего",
        E: "general, common",
        T: "AGM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общей",
        E: "general, common",
        T: "AGF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общего",
        E: "general, common",
        T: "AGN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общих",
        E: "general, common",
        T: "AGP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общему",
        E: "general, common",
        T: "ADM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общей",
        E: "general, common",
        T: "ADF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общему",
        E: "general, common",
        T: "ADN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общим",
        E: "general, common",
        T: "ADP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общего",
        E: "general, common",
        T: "AAM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общую",
        E: "general, common",
        T: "AAF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общее",
        E: "general, common",
        T: "AAN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общих",
        E: "general, common",
        T: "AAP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общим",
        E: "general, common",
        T: "AIM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общей",
        E: "general, common",
        T: "AIF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общим",
        E: "general, common",
        T: "AIN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общими",
        E: "general, common",
        T: "AIP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общем",
        E: "general, common",
        T: "APM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общей",
        E: "general, common",
        T: "APF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общем",
        E: "general, common",
        T: "APN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общих",
        E: "general, common",
        T: "APP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общ",
        E: "general, common",
        T: "ASM",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обща",
        E: "general, common",
        T: "ASF",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обще",
        E: "general, common",
        T: "ASN",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "общи",
        E: "general, common",
        T: "ASP",
        A: "-",
        O: "общий",
        RA: 265,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлый",
        E: "past",
        T: "ORIGINAL",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлый",
        E: "past",
        T: "ANM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлая",
        E: "past",
        T: "ANF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлое",
        E: "past",
        T: "ANN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлые",
        E: "past",
        T: "ANP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлого",
        E: "past",
        T: "AGM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлой",
        E: "past",
        T: "AGF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлого",
        E: "past",
        T: "AGN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлых",
        E: "past",
        T: "AGP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлому",
        E: "past",
        T: "ADM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлой",
        E: "past",
        T: "ADF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлому",
        E: "past",
        T: "ADN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлым",
        E: "past",
        T: "ADP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлый",
        E: "past",
        T: "AAM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлую",
        E: "past",
        T: "AAF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлое",
        E: "past",
        T: "AAN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлые",
        E: "past",
        T: "AAP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлым",
        E: "past",
        T: "AIM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлой",
        E: "past",
        T: "AIF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлым",
        E: "past",
        T: "AIN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлыми",
        E: "past",
        T: "AIP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлом",
        E: "past",
        T: "APM",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлой",
        E: "past",
        T: "APF",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлом",
        E: "past",
        T: "APN",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прошлых",
        E: "past",
        T: "APP",
        A: "-",
        O: "прошлый",
        RA: 268,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известный",
        E: "well-known, famous",
        T: "ORIGINAL",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известный",
        E: "well-known, famous",
        T: "ANM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известная",
        E: "well-known, famous",
        T: "ANF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известное",
        E: "well-known, famous",
        T: "ANN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известные",
        E: "well-known, famous",
        T: "ANP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известного",
        E: "well-known, famous",
        T: "AGM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известной",
        E: "well-known, famous",
        T: "AGF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известного",
        E: "well-known, famous",
        T: "AGN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известных",
        E: "well-known, famous",
        T: "AGP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известному",
        E: "well-known, famous",
        T: "ADM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известной",
        E: "well-known, famous",
        T: "ADF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известному",
        E: "well-known, famous",
        T: "ADN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известным",
        E: "well-known, famous",
        T: "ADP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известный",
        E: "well-known, famous",
        T: "AAM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известную",
        E: "well-known, famous",
        T: "AAF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известное",
        E: "well-known, famous",
        T: "AAN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известные",
        E: "well-known, famous",
        T: "AAP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известным",
        E: "well-known, famous",
        T: "AIM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известной",
        E: "well-known, famous",
        T: "AIF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известным",
        E: "well-known, famous",
        T: "AIN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известными",
        E: "well-known, famous",
        T: "AIP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известном",
        E: "well-known, famous",
        T: "APM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известной",
        E: "well-known, famous",
        T: "APF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известном",
        E: "well-known, famous",
        T: "APN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известных",
        E: "well-known, famous",
        T: "APP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известен",
        E: "well-known, famous",
        T: "ASM",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известна",
        E: "well-known, famous",
        T: "ASF",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известно",
        E: "well-known, famous",
        T: "ASN",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "известны",
        E: "well-known, famous",
        T: "ASP",
        A: "-",
        O: "известный",
        RA: 270,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожий",
        E: "similar, alike",
        T: "ORIGINAL",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожий",
        E: "similar, alike",
        T: "ANM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожая",
        E: "similar, alike",
        T: "ANF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожее",
        E: "similar, alike",
        T: "ANN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожие",
        E: "similar, alike",
        T: "ANP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожего",
        E: "similar, alike",
        T: "AGM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожей",
        E: "similar, alike",
        T: "AGF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожего",
        E: "similar, alike",
        T: "AGN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожих",
        E: "similar, alike",
        T: "AGP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожему",
        E: "similar, alike",
        T: "ADM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожей",
        E: "similar, alike",
        T: "ADF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожему",
        E: "similar, alike",
        T: "ADN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожим",
        E: "similar, alike",
        T: "ADP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожий",
        E: "similar, alike",
        T: "AAM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожую",
        E: "similar, alike",
        T: "AAF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожее",
        E: "similar, alike",
        T: "AAN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожие",
        E: "similar, alike",
        T: "AAP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожим",
        E: "similar, alike",
        T: "AIM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожей",
        E: "similar, alike",
        T: "AIF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожим",
        E: "similar, alike",
        T: "AIN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожими",
        E: "similar, alike",
        T: "AIP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожем",
        E: "similar, alike",
        T: "APM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожей",
        E: "similar, alike",
        T: "APF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожем",
        E: "similar, alike",
        T: "APN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожих",
        E: "similar, alike",
        T: "APP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похож",
        E: "similar, alike",
        T: "ASM",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожа",
        E: "similar, alike",
        T: "ASF",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похоже",
        E: "similar, alike",
        T: "ASN",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "похожи",
        E: "similar, alike",
        T: "ASP",
        A: "-",
        O: "похожий",
        RA: 281,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "ORIGINAL",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "ANM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодая",
        E: "young",
        T: "ANF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодое",
        E: "young",
        T: "ANN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодые",
        E: "young",
        T: "ANP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодого",
        E: "young",
        T: "AGM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "AGF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодого",
        E: "young",
        T: "AGN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодых",
        E: "young",
        T: "AGP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодому",
        E: "young",
        T: "ADM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "ADF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодому",
        E: "young",
        T: "ADN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодым",
        E: "young",
        T: "ADP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодого",
        E: "young",
        T: "AAM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодую",
        E: "young",
        T: "AAF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодое",
        E: "young",
        T: "AAN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодых",
        E: "young",
        T: "AAP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодым",
        E: "young",
        T: "AIM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "AIF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодым",
        E: "young",
        T: "AIN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодыми",
        E: "young",
        T: "AIP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодом",
        E: "young",
        T: "APM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодой",
        E: "young",
        T: "APF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодом",
        E: "young",
        T: "APN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодых",
        E: "young",
        T: "APP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молод",
        E: "young",
        T: "ASM",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молода",
        E: "young",
        T: "ASF",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодо",
        E: "young",
        T: "ASN",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молоды",
        E: "young",
        T: "ASP",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "моложе",
        E: "young",
        T: "AC1",
        A: "-",
        O: "молодой",
        RA: 291,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красный",
        E: "red",
        T: "ORIGINAL",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красный",
        E: "red",
        T: "ANM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красная",
        E: "red",
        T: "ANF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красное",
        E: "red",
        T: "ANN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красные",
        E: "red",
        T: "ANP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красного",
        E: "red",
        T: "AGM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красной",
        E: "red",
        T: "AGF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красного",
        E: "red",
        T: "AGN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красных",
        E: "red",
        T: "AGP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красному",
        E: "red",
        T: "ADM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красной",
        E: "red",
        T: "ADF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красному",
        E: "red",
        T: "ADN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красным",
        E: "red",
        T: "ADP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красный",
        E: "red",
        T: "AAM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красную",
        E: "red",
        T: "AAF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красное",
        E: "red",
        T: "AAN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красные",
        E: "red",
        T: "AAP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красным",
        E: "red",
        T: "AIM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красной",
        E: "red",
        T: "AIF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красным",
        E: "red",
        T: "AIN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красными",
        E: "red",
        T: "AIP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красном",
        E: "red",
        T: "APM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красной",
        E: "red",
        T: "APF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красном",
        E: "red",
        T: "APN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красных",
        E: "red",
        T: "APP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красен",
        E: "red",
        T: "ASM",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красна",
        E: "red",
        T: "ASF",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красно красно",
        E: "red",
        T: "ASN",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красны красны",
        E: "red",
        T: "ASP",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "краснее",
        E: "red",
        T: "AC1",
        A: "-",
        O: "красный",
        RA: 293,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранний",
        E: "early",
        T: "ORIGINAL",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранний",
        E: "early",
        T: "ANM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранняя",
        E: "early",
        T: "ANF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннее",
        E: "early",
        T: "ANN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранние",
        E: "early",
        T: "ANP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннего",
        E: "early",
        T: "AGM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранней",
        E: "early",
        T: "AGF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннего",
        E: "early",
        T: "AGN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранних",
        E: "early",
        T: "AGP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннему",
        E: "early",
        T: "ADM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранней",
        E: "early",
        T: "ADF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннему",
        E: "early",
        T: "ADN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранним",
        E: "early",
        T: "ADP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранний",
        E: "early",
        T: "AAM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннюю",
        E: "early",
        T: "AAF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннее",
        E: "early",
        T: "AAN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранние",
        E: "early",
        T: "AAP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранним",
        E: "early",
        T: "AIM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранней",
        E: "early",
        T: "AIF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранним",
        E: "early",
        T: "AIN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранними",
        E: "early",
        T: "AIP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннем",
        E: "early",
        T: "APM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранней",
        E: "early",
        T: "APF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "раннем",
        E: "early",
        T: "APN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранних",
        E: "early",
        T: "APP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранен",
        E: "early",
        T: "ASM",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рання",
        E: "early",
        T: "ASF",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранне",
        E: "early",
        T: "ASN",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ранни",
        E: "early",
        T: "ASP",
        A: "-",
        O: "ранний",
        RA: 297,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокий",
        E: "tall, high",
        T: "ORIGINAL",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокий",
        E: "tall, high",
        T: "ANM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокая",
        E: "tall, high",
        T: "ANF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокое",
        E: "tall, high",
        T: "ANN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокие",
        E: "tall, high",
        T: "ANP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокого",
        E: "tall, high",
        T: "AGM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокой",
        E: "tall, high",
        T: "AGF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокого",
        E: "tall, high",
        T: "AGN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоких",
        E: "tall, high",
        T: "AGP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокому",
        E: "tall, high",
        T: "ADM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокой",
        E: "tall, high",
        T: "ADF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокому",
        E: "tall, high",
        T: "ADN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоким",
        E: "tall, high",
        T: "ADP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокий",
        E: "tall, high",
        T: "AAM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокую",
        E: "tall, high",
        T: "AAF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокое",
        E: "tall, high",
        T: "AAN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокие",
        E: "tall, high",
        T: "AAP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоким",
        E: "tall, high",
        T: "AIM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокой",
        E: "tall, high",
        T: "AIF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоким",
        E: "tall, high",
        T: "AIN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокими",
        E: "tall, high",
        T: "AIP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоком",
        E: "tall, high",
        T: "APM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высокой",
        E: "tall, high",
        T: "APF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоком",
        E: "tall, high",
        T: "APN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоких",
        E: "tall, high",
        T: "APP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высок",
        E: "tall, high",
        T: "ASM",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высока",
        E: "tall, high",
        T: "ASF",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоко высоко",
        E: "tall, high",
        T: "ASN",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "высоки высоки",
        E: "tall, high",
        T: "ASP",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выше",
        E: "tall, high",
        T: "AC1",
        A: "-",
        O: "высокий",
        RA: 301,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целый",
        E: "intact, whole, entire",
        T: "ORIGINAL",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целый",
        E: "intact, whole, entire",
        T: "ANM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целая",
        E: "intact, whole, entire",
        T: "ANF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целое",
        E: "intact, whole, entire",
        T: "ANN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целые",
        E: "intact, whole, entire",
        T: "ANP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целого",
        E: "intact, whole, entire",
        T: "AGM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целой",
        E: "intact, whole, entire",
        T: "AGF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целого",
        E: "intact, whole, entire",
        T: "AGN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целых",
        E: "intact, whole, entire",
        T: "AGP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целому",
        E: "intact, whole, entire",
        T: "ADM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целой",
        E: "intact, whole, entire",
        T: "ADF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целому",
        E: "intact, whole, entire",
        T: "ADN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целым",
        E: "intact, whole, entire",
        T: "ADP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целого",
        E: "intact, whole, entire",
        T: "AAM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целую",
        E: "intact, whole, entire",
        T: "AAF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целое",
        E: "intact, whole, entire",
        T: "AAN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целых",
        E: "intact, whole, entire",
        T: "AAP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целым",
        E: "intact, whole, entire",
        T: "AIM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целой",
        E: "intact, whole, entire",
        T: "AIF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целым",
        E: "intact, whole, entire",
        T: "AIN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целыми",
        E: "intact, whole, entire",
        T: "AIP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целом",
        E: "intact, whole, entire",
        T: "APM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целой",
        E: "intact, whole, entire",
        T: "APF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целом",
        E: "intact, whole, entire",
        T: "APN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целых",
        E: "intact, whole, entire",
        T: "APP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "цел",
        E: "intact, whole, entire",
        T: "ASM",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "цела",
        E: "intact, whole, entire",
        T: "ASF",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "цело",
        E: "intact, whole, entire",
        T: "ASN",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "целы",
        E: "intact, whole, entire",
        T: "ASP",
        A: "-",
        O: "целый",
        RA: 311,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромный",
        E: "huge, enormous",
        T: "ORIGINAL",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромный",
        E: "huge, enormous",
        T: "ANM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромная",
        E: "huge, enormous",
        T: "ANF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромное",
        E: "huge, enormous",
        T: "ANN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромные",
        E: "huge, enormous",
        T: "ANP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромного",
        E: "huge, enormous",
        T: "AGM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромной",
        E: "huge, enormous",
        T: "AGF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромного",
        E: "huge, enormous",
        T: "AGN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромных",
        E: "huge, enormous",
        T: "AGP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромному",
        E: "huge, enormous",
        T: "ADM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромной",
        E: "huge, enormous",
        T: "ADF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромному",
        E: "huge, enormous",
        T: "ADN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромным",
        E: "huge, enormous",
        T: "ADP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромный",
        E: "huge, enormous",
        T: "AAM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромную",
        E: "huge, enormous",
        T: "AAF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромное",
        E: "huge, enormous",
        T: "AAN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромные",
        E: "huge, enormous",
        T: "AAP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромным",
        E: "huge, enormous",
        T: "AIM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромной",
        E: "huge, enormous",
        T: "AIF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромным",
        E: "huge, enormous",
        T: "AIN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромными",
        E: "huge, enormous",
        T: "AIP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромном",
        E: "huge, enormous",
        T: "APM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромной",
        E: "huge, enormous",
        T: "APF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромном",
        E: "huge, enormous",
        T: "APN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромных",
        E: "huge, enormous",
        T: "APP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромен",
        E: "huge, enormous",
        T: "ASM",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромна",
        E: "huge, enormous",
        T: "ASF",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромно",
        E: "huge, enormous",
        T: "ASN",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "огромны",
        E: "huge, enormous",
        T: "ASP",
        A: "-",
        O: "огромный",
        RA: 312,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящий",
        E: "present;real, true",
        T: "ORIGINAL",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящий",
        E: "present;real, true",
        T: "ANM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящая",
        E: "present;real, true",
        T: "ANF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящее",
        E: "present;real, true",
        T: "ANN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящие",
        E: "present;real, true",
        T: "ANP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящего",
        E: "present;real, true",
        T: "AGM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящей",
        E: "present;real, true",
        T: "AGF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящего",
        E: "present;real, true",
        T: "AGN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящих",
        E: "present;real, true",
        T: "AGP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящему",
        E: "present;real, true",
        T: "ADM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящей",
        E: "present;real, true",
        T: "ADF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящему",
        E: "present;real, true",
        T: "ADN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящим",
        E: "present;real, true",
        T: "ADP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящий",
        E: "present;real, true",
        T: "AAM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящую",
        E: "present;real, true",
        T: "AAF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящее",
        E: "present;real, true",
        T: "AAN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящие",
        E: "present;real, true",
        T: "AAP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящим",
        E: "present;real, true",
        T: "AIM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящей",
        E: "present;real, true",
        T: "AIF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящим",
        E: "present;real, true",
        T: "AIN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящими",
        E: "present;real, true",
        T: "AIP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящем",
        E: "present;real, true",
        T: "APM",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящей",
        E: "present;real, true",
        T: "APF",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящем",
        E: "present;real, true",
        T: "APN",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "настоящих",
        E: "present;real, true",
        T: "APP",
        A: "-",
        O: "настоящий",
        RA: 315,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "ORIGINAL",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "ANM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живая",
        E: "living, live, lively",
        T: "ANF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живое",
        E: "living, live, lively",
        T: "ANN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живые",
        E: "living, live, lively",
        T: "ANP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живого",
        E: "living, live, lively",
        T: "AGM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "AGF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живого",
        E: "living, live, lively",
        T: "AGN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живых",
        E: "living, live, lively",
        T: "AGP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живому",
        E: "living, live, lively",
        T: "ADM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "ADF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живому",
        E: "living, live, lively",
        T: "ADN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живым",
        E: "living, live, lively",
        T: "ADP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живого",
        E: "living, live, lively",
        T: "AAM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живую",
        E: "living, live, lively",
        T: "AAF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живое",
        E: "living, live, lively",
        T: "AAN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живых",
        E: "living, live, lively",
        T: "AAP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живым",
        E: "living, live, lively",
        T: "AIM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "AIF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живым",
        E: "living, live, lively",
        T: "AIN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живыми",
        E: "living, live, lively",
        T: "AIP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живом",
        E: "living, live, lively",
        T: "APM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живой",
        E: "living, live, lively",
        T: "APF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живом",
        E: "living, live, lively",
        T: "APN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живых",
        E: "living, live, lively",
        T: "APP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жив",
        E: "living, live, lively",
        T: "ASM",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жива",
        E: "living, live, lively",
        T: "ASF",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живо",
        E: "living, live, lively",
        T: "ASN",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "живы",
        E: "living, live, lively",
        T: "ASP",
        A: "-",
        O: "живой",
        RA: 327,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: "black",
        T: "ORIGINAL",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: "black",
        T: "ANM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрная",
        E: "black",
        T: "ANF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрное",
        E: "black",
        T: "ANN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрные",
        E: "black",
        T: "ANP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрного",
        E: "black",
        T: "AGM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: "black",
        T: "AGF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрного",
        E: "black",
        T: "AGN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрных",
        E: "black",
        T: "AGP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрному",
        E: "black",
        T: "ADM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: "black",
        T: "ADF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрному",
        E: "black",
        T: "ADN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: "black",
        T: "ADP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: "black",
        T: "AAM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрную",
        E: "black",
        T: "AAF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрное",
        E: "black",
        T: "AAN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрные",
        E: "black",
        T: "AAP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: "black",
        T: "AIM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: "black",
        T: "AIF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: "black",
        T: "AIN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрными",
        E: "black",
        T: "AIP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрном",
        E: "black",
        T: "APM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: "black",
        T: "APF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрном",
        E: "black",
        T: "APN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрных",
        E: "black",
        T: "APP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрен",
        E: "black",
        T: "ASM",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черна",
        E: "black",
        T: "ASF",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черно",
        E: "black",
        T: "ASN",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черны",
        E: "black",
        T: "ASP",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чернее",
        E: "black",
        T: "AC1",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черней",
        E: "black",
        T: "AC2",
        A: "-",
        O: "чёрный",
        RA: 337,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разный",
        E: "different",
        T: "ORIGINAL",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разный",
        E: "different",
        T: "ANM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разная",
        E: "different",
        T: "ANF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разное",
        E: "different",
        T: "ANN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разные",
        E: "different",
        T: "ANP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разного",
        E: "different",
        T: "AGM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разной",
        E: "different",
        T: "AGF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разного",
        E: "different",
        T: "AGN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разных",
        E: "different",
        T: "AGP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разному",
        E: "different",
        T: "ADM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разной",
        E: "different",
        T: "ADF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разному",
        E: "different",
        T: "ADN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разным",
        E: "different",
        T: "ADP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разный",
        E: "different",
        T: "AAM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разную",
        E: "different",
        T: "AAF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разное",
        E: "different",
        T: "AAN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разные",
        E: "different",
        T: "AAP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разным",
        E: "different",
        T: "AIM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разной",
        E: "different",
        T: "AIF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разным",
        E: "different",
        T: "AIN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разными",
        E: "different",
        T: "AIP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разном",
        E: "different",
        T: "APM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разной",
        E: "different",
        T: "APF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разном",
        E: "different",
        T: "APN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разных",
        E: "different",
        T: "APP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разен",
        E: "different",
        T: "ASM",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разна",
        E: "different",
        T: "ASF",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разно",
        E: "different",
        T: "ASN",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разны",
        E: "different",
        T: "ASP",
        A: "-",
        O: "разный",
        RA: 341,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственный",
        E: "one's own",
        T: "ORIGINAL",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственный",
        E: "one's own",
        T: "ANM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственная",
        E: "one's own",
        T: "ANF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственное",
        E: "one's own",
        T: "ANN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственные",
        E: "one's own",
        T: "ANP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственного",
        E: "one's own",
        T: "AGM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственной",
        E: "one's own",
        T: "AGF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственного",
        E: "one's own",
        T: "AGN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственных",
        E: "one's own",
        T: "AGP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственному",
        E: "one's own",
        T: "ADM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственной",
        E: "one's own",
        T: "ADF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственному",
        E: "one's own",
        T: "ADN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственным",
        E: "one's own",
        T: "ADP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственный",
        E: "one's own",
        T: "AAM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственную",
        E: "one's own",
        T: "AAF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственное",
        E: "one's own",
        T: "AAN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственные",
        E: "one's own",
        T: "AAP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственным",
        E: "one's own",
        T: "AIM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственной",
        E: "one's own",
        T: "AIF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственным",
        E: "one's own",
        T: "AIN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственными",
        E: "one's own",
        T: "AIP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственном",
        E: "one's own",
        T: "APM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственной",
        E: "one's own",
        T: "APF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственном",
        E: "one's own",
        T: "APN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственных",
        E: "one's own",
        T: "APP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собствен собственен",
        E: "one's own",
        T: "ASM",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственно",
        E: "one's own",
        T: "ASF",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственна",
        E: "one's own",
        T: "ASN",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собственны",
        E: "one's own",
        T: "ASP",
        A: "-",
        O: "собственный",
        RA: 345,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третий",
        E: "third",
        T: "ORIGINAL",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третий",
        E: "third",
        T: "ANM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третья",
        E: "third",
        T: "ANF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третье",
        E: "third",
        T: "ANN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьи",
        E: "third",
        T: "ANP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьего",
        E: "third",
        T: "AGM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьей",
        E: "third",
        T: "AGF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьего",
        E: "third",
        T: "AGN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьих",
        E: "third",
        T: "AGP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьему",
        E: "third",
        T: "ADM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьей",
        E: "third",
        T: "ADF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьему",
        E: "third",
        T: "ADN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьим",
        E: "third",
        T: "ADP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьего",
        E: "third",
        T: "AAM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третью",
        E: "third",
        T: "AAF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третье",
        E: "third",
        T: "AAN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьих",
        E: "third",
        T: "AAP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьим",
        E: "third",
        T: "AIM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьей",
        E: "third",
        T: "AIF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьим",
        E: "third",
        T: "AIN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьими",
        E: "third",
        T: "AIP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьем",
        E: "third",
        T: "APM",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьей",
        E: "third",
        T: "APF",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьем",
        E: "third",
        T: "APN",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "третьих",
        E: "third",
        T: "APP",
        A: "-",
        O: "третий",
        RA: 361,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великий",
        E: "great",
        T: "ORIGINAL",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великий",
        E: "great",
        T: "ANM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великая",
        E: "great",
        T: "ANF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великое",
        E: "great",
        T: "ANN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великие",
        E: "great",
        T: "ANP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великого",
        E: "great",
        T: "AGM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великой",
        E: "great",
        T: "AGF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великого",
        E: "great",
        T: "AGN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великих",
        E: "great",
        T: "AGP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великому",
        E: "great",
        T: "ADM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великой",
        E: "great",
        T: "ADF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великому",
        E: "great",
        T: "ADN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великим",
        E: "great",
        T: "ADP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великий",
        E: "great",
        T: "AAM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великую",
        E: "great",
        T: "AAF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великое",
        E: "great",
        T: "AAN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великие",
        E: "great",
        T: "AAP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великим",
        E: "great",
        T: "AIM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великой",
        E: "great",
        T: "AIF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великим",
        E: "great",
        T: "AIN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великими",
        E: "great",
        T: "AIP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великом",
        E: "great",
        T: "APM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великой",
        E: "great",
        T: "APF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великом",
        E: "great",
        T: "APN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "великих",
        E: "great",
        T: "APP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велик",
        E: "great",
        T: "ASM",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велика",
        E: "great",
        T: "ASF",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велико",
        E: "great",
        T: "ASN",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "велики",
        E: "great",
        T: "ASP",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больше",
        E: "great",
        T: "AC1",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "более",
        E: "great",
        T: "AC2",
        A: "-",
        O: "великий",
        RA: 397,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлый",
        E: "heavy",
        T: "ORIGINAL",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлый",
        E: "heavy",
        T: "ANM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлая",
        E: "heavy",
        T: "ANF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлое",
        E: "heavy",
        T: "ANN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлые",
        E: "heavy",
        T: "ANP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлого",
        E: "heavy",
        T: "AGM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлой",
        E: "heavy",
        T: "AGF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлого",
        E: "heavy",
        T: "AGN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлых",
        E: "heavy",
        T: "AGP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлому",
        E: "heavy",
        T: "ADM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлой",
        E: "heavy",
        T: "ADF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлому",
        E: "heavy",
        T: "ADN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлым",
        E: "heavy",
        T: "ADP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлый",
        E: "heavy",
        T: "AAM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлую",
        E: "heavy",
        T: "AAF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлое",
        E: "heavy",
        T: "AAN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлые",
        E: "heavy",
        T: "AAP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлым",
        E: "heavy",
        T: "AIM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлой",
        E: "heavy",
        T: "AIF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлым",
        E: "heavy",
        T: "AIN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлыми",
        E: "heavy",
        T: "AIP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлом",
        E: "heavy",
        T: "APM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлой",
        E: "heavy",
        T: "APF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлом",
        E: "heavy",
        T: "APN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёлых",
        E: "heavy",
        T: "APP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжёл",
        E: "heavy",
        T: "ASM",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжела",
        E: "heavy",
        T: "ASF",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжело",
        E: "heavy",
        T: "ASN",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжелы",
        E: "heavy",
        T: "ASP",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тяжелее",
        E: "heavy",
        T: "AC1",
        A: "-",
        O: "тяжёлый",
        RA: 400,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинный",
        E: "long",
        T: "ORIGINAL",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинный",
        E: "long",
        T: "ANM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинная",
        E: "long",
        T: "ANF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинное",
        E: "long",
        T: "ANN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинные",
        E: "long",
        T: "ANP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинного",
        E: "long",
        T: "AGM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинной",
        E: "long",
        T: "AGF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинного",
        E: "long",
        T: "AGN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинных",
        E: "long",
        T: "AGP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинному",
        E: "long",
        T: "ADM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинной",
        E: "long",
        T: "ADF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинному",
        E: "long",
        T: "ADN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинным",
        E: "long",
        T: "ADP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинный",
        E: "long",
        T: "AAM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинную",
        E: "long",
        T: "AAF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинное",
        E: "long",
        T: "AAN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинные",
        E: "long",
        T: "AAP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинным",
        E: "long",
        T: "AIM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинной",
        E: "long",
        T: "AIF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинным",
        E: "long",
        T: "AIN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинными",
        E: "long",
        T: "AIP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинном",
        E: "long",
        T: "APM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинной",
        E: "long",
        T: "APF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинном",
        E: "long",
        T: "APN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинных",
        E: "long",
        T: "APP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинен",
        E: "long",
        T: "ASM",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинна",
        E: "long",
        T: "ASF",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинно",
        E: "long",
        T: "ASN",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длинны",
        E: "long",
        T: "ASP",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "длиннее",
        E: "long",
        T: "AC1",
        A: "-",
        O: "длинный",
        RA: 404,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личный",
        E: "personal",
        T: "ORIGINAL",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личный",
        E: "personal",
        T: "ANM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личная",
        E: "personal",
        T: "ANF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личное",
        E: "personal",
        T: "ANN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личные",
        E: "personal",
        T: "ANP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личного",
        E: "personal",
        T: "AGM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личной",
        E: "personal",
        T: "AGF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личного",
        E: "personal",
        T: "AGN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личных",
        E: "personal",
        T: "AGP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личному",
        E: "personal",
        T: "ADM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личной",
        E: "personal",
        T: "ADF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личному",
        E: "personal",
        T: "ADN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личным",
        E: "personal",
        T: "ADP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личный",
        E: "personal",
        T: "AAM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личную",
        E: "personal",
        T: "AAF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личное",
        E: "personal",
        T: "AAN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личные",
        E: "personal",
        T: "AAP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личным",
        E: "personal",
        T: "AIM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личной",
        E: "personal",
        T: "AIF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личным",
        E: "personal",
        T: "AIN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личными",
        E: "personal",
        T: "AIP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личном",
        E: "personal",
        T: "APM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личной",
        E: "personal",
        T: "APF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личном",
        E: "personal",
        T: "APN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личных",
        E: "personal",
        T: "APP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личен",
        E: "personal",
        T: "ASM",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лична",
        E: "personal",
        T: "ASF",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лично",
        E: "personal",
        T: "ASN",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "личны",
        E: "personal",
        T: "ASP",
        A: "-",
        O: "личный",
        RA: 420,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомый",
        E: "acquainted",
        T: "ORIGINAL",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомый",
        E: "acquainted",
        T: "ANM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомая",
        E: "acquainted",
        T: "ANF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомое",
        E: "acquainted",
        T: "ANN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомые",
        E: "acquainted",
        T: "ANP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомого",
        E: "acquainted",
        T: "AGM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомой",
        E: "acquainted",
        T: "AGF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомого",
        E: "acquainted",
        T: "AGN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомых",
        E: "acquainted",
        T: "AGP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомому",
        E: "acquainted",
        T: "ADM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомой",
        E: "acquainted",
        T: "ADF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомому",
        E: "acquainted",
        T: "ADN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомым",
        E: "acquainted",
        T: "ADP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомый",
        E: "acquainted",
        T: "AAM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомую",
        E: "acquainted",
        T: "AAF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомое",
        E: "acquainted",
        T: "AAN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомые",
        E: "acquainted",
        T: "AAP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомым",
        E: "acquainted",
        T: "AIM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомой",
        E: "acquainted",
        T: "AIF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомым",
        E: "acquainted",
        T: "AIN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомыми",
        E: "acquainted",
        T: "AIP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомом",
        E: "acquainted",
        T: "APM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомой",
        E: "acquainted",
        T: "APF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомом",
        E: "acquainted",
        T: "APN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомых",
        E: "acquainted",
        T: "APP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знаком",
        E: "acquainted",
        T: "ASM",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакома",
        E: "acquainted",
        T: "ASF",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомо",
        E: "acquainted",
        T: "ASN",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "знакомы",
        E: "acquainted",
        T: "ASP",
        A: "-",
        O: "знакомый",
        RA: 434,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильный",
        E: "strong, powerful",
        T: "ORIGINAL",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильный",
        E: "strong, powerful",
        T: "ANM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильная",
        E: "strong, powerful",
        T: "ANF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильное",
        E: "strong, powerful",
        T: "ANN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильные",
        E: "strong, powerful",
        T: "ANP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильного",
        E: "strong, powerful",
        T: "AGM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильной",
        E: "strong, powerful",
        T: "AGF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильного",
        E: "strong, powerful",
        T: "AGN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильных",
        E: "strong, powerful",
        T: "AGP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильному",
        E: "strong, powerful",
        T: "ADM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильной",
        E: "strong, powerful",
        T: "ADF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильному",
        E: "strong, powerful",
        T: "ADN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильным",
        E: "strong, powerful",
        T: "ADP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильный",
        E: "strong, powerful",
        T: "AAM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильную",
        E: "strong, powerful",
        T: "AAF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильное",
        E: "strong, powerful",
        T: "AAN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильные",
        E: "strong, powerful",
        T: "AAP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильным",
        E: "strong, powerful",
        T: "AIM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильной",
        E: "strong, powerful",
        T: "AIF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильным",
        E: "strong, powerful",
        T: "AIN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильными",
        E: "strong, powerful",
        T: "AIP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильном",
        E: "strong, powerful",
        T: "APM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильной",
        E: "strong, powerful",
        T: "APF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильном",
        E: "strong, powerful",
        T: "APN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильных",
        E: "strong, powerful",
        T: "APP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "силён",
        E: "strong, powerful",
        T: "ASM",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильна",
        E: "strong, powerful",
        T: "ASF",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильно",
        E: "strong, powerful",
        T: "ASN",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильны сильны",
        E: "strong, powerful",
        T: "ASP",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильнее",
        E: "strong, powerful",
        T: "AC1",
        A: "-",
        O: "сильный",
        RA: 437,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорый",
        E: "quick, fast",
        T: "ORIGINAL",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорый",
        E: "quick, fast",
        T: "ANM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорая",
        E: "quick, fast",
        T: "ANF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорое",
        E: "quick, fast",
        T: "ANN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорые",
        E: "quick, fast",
        T: "ANP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорого",
        E: "quick, fast",
        T: "AGM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорой",
        E: "quick, fast",
        T: "AGF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорого",
        E: "quick, fast",
        T: "AGN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорых",
        E: "quick, fast",
        T: "AGP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорому",
        E: "quick, fast",
        T: "ADM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорой",
        E: "quick, fast",
        T: "ADF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорому",
        E: "quick, fast",
        T: "ADN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорым",
        E: "quick, fast",
        T: "ADP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорый",
        E: "quick, fast",
        T: "AAM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорую",
        E: "quick, fast",
        T: "AAF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорое",
        E: "quick, fast",
        T: "AAN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорые",
        E: "quick, fast",
        T: "AAP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорым",
        E: "quick, fast",
        T: "AIM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорой",
        E: "quick, fast",
        T: "AIF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорым",
        E: "quick, fast",
        T: "AIN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорыми",
        E: "quick, fast",
        T: "AIP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скором",
        E: "quick, fast",
        T: "APM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорой",
        E: "quick, fast",
        T: "APF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скором",
        E: "quick, fast",
        T: "APN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорых",
        E: "quick, fast",
        T: "APP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скор",
        E: "quick, fast",
        T: "ASM",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скора",
        E: "quick, fast",
        T: "ASF",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скоро",
        E: "quick, fast",
        T: "ASN",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скоры",
        E: "quick, fast",
        T: "ASP",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скорее",
        E: "quick, fast",
        T: "AC1",
        A: "-",
        O: "скорый",
        RA: 438,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "ORIGINAL",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "ANM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простая",
        E: "simple, easy, plain",
        T: "ANF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простое",
        E: "simple, easy, plain",
        T: "ANN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простые",
        E: "simple, easy, plain",
        T: "ANP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простого",
        E: "simple, easy, plain",
        T: "AGM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "AGF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простого",
        E: "simple, easy, plain",
        T: "AGN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простых",
        E: "simple, easy, plain",
        T: "AGP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простому",
        E: "simple, easy, plain",
        T: "ADM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "ADF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простому",
        E: "simple, easy, plain",
        T: "ADN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простым",
        E: "simple, easy, plain",
        T: "ADP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "AAM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простую",
        E: "simple, easy, plain",
        T: "AAF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простое",
        E: "simple, easy, plain",
        T: "AAN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простые",
        E: "simple, easy, plain",
        T: "AAP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простым",
        E: "simple, easy, plain",
        T: "AIM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "AIF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простым",
        E: "simple, easy, plain",
        T: "AIN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простыми",
        E: "simple, easy, plain",
        T: "AIP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простом",
        E: "simple, easy, plain",
        T: "APM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простой",
        E: "simple, easy, plain",
        T: "APF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простом",
        E: "simple, easy, plain",
        T: "APN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "простых",
        E: "simple, easy, plain",
        T: "APP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прост",
        E: "simple, easy, plain",
        T: "ASM",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проста",
        E: "simple, easy, plain",
        T: "ASF",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просто",
        E: "simple, easy, plain",
        T: "ASN",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просты просты",
        E: "simple, easy, plain",
        T: "ASP",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "проще",
        E: "simple, easy, plain",
        T: "AC1",
        A: "-",
        O: "простой",
        RA: 449,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "ORIGINAL",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "ANM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иная",
        E: "different, other",
        T: "ANF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иное",
        E: "different, other",
        T: "ANN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иные",
        E: "different, other",
        T: "ANP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иного",
        E: "different, other",
        T: "AGM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "AGF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иного",
        E: "different, other",
        T: "AGN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иных",
        E: "different, other",
        T: "AGP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иному",
        E: "different, other",
        T: "ADM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "ADF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иному",
        E: "different, other",
        T: "ADN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иным",
        E: "different, other",
        T: "ADP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "AAM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иную",
        E: "different, other",
        T: "AAF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иное",
        E: "different, other",
        T: "AAN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иные",
        E: "different, other",
        T: "AAP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иным",
        E: "different, other",
        T: "AIM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "AIF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иным",
        E: "different, other",
        T: "AIN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иными",
        E: "different, other",
        T: "AIP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ином",
        E: "different, other",
        T: "APM",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иной",
        E: "different, other",
        T: "APF",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ином",
        E: "different, other",
        T: "APN",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иных",
        E: "different, other",
        T: "APP",
        A: "-",
        O: "иной",
        RA: 451,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёный",
        E: "green",
        T: "ORIGINAL",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёный",
        E: "green",
        T: "ANM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёная",
        E: "green",
        T: "ANF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёное",
        E: "green",
        T: "ANN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёные",
        E: "green",
        T: "ANP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёного",
        E: "green",
        T: "AGM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёной",
        E: "green",
        T: "AGF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёного",
        E: "green",
        T: "AGN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёных",
        E: "green",
        T: "AGP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёному",
        E: "green",
        T: "ADM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёной",
        E: "green",
        T: "ADF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёному",
        E: "green",
        T: "ADN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёным",
        E: "green",
        T: "ADP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёный",
        E: "green",
        T: "AAM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёную",
        E: "green",
        T: "AAF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёное",
        E: "green",
        T: "AAN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёные",
        E: "green",
        T: "AAP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёным",
        E: "green",
        T: "AIM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёной",
        E: "green",
        T: "AIF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёным",
        E: "green",
        T: "AIN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёными",
        E: "green",
        T: "AIP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёном",
        E: "green",
        T: "APM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёной",
        E: "green",
        T: "APF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёном",
        E: "green",
        T: "APN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелёных",
        E: "green",
        T: "APP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелен",
        E: "green",
        T: "ASM",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелена",
        E: "green",
        T: "ASF",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелено",
        E: "green",
        T: "ASN",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зелены зелены",
        E: "green",
        T: "ASP",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зеленее",
        E: "green",
        T: "AC1",
        A: "-",
        O: "зелёный",
        RA: 455,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: 'black (as in "she wears black")',
        T: "ORIGINAL",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: 'black (as in "she wears black")',
        T: "ANM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрная",
        E: 'black (as in "she wears black")',
        T: "ANF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрное",
        E: 'black (as in "she wears black")',
        T: "ANN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрные",
        E: 'black (as in "she wears black")',
        T: "ANP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрного",
        E: 'black (as in "she wears black")',
        T: "AGM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: 'black (as in "she wears black")',
        T: "AGF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрного",
        E: 'black (as in "she wears black")',
        T: "AGN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрных",
        E: 'black (as in "she wears black")',
        T: "AGP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрному",
        E: 'black (as in "she wears black")',
        T: "ADM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: 'black (as in "she wears black")',
        T: "ADF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрному",
        E: 'black (as in "she wears black")',
        T: "ADN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: 'black (as in "she wears black")',
        T: "ADP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрный",
        E: 'black (as in "she wears black")',
        T: "AAM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрную",
        E: 'black (as in "she wears black")',
        T: "AAF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрное",
        E: 'black (as in "she wears black")',
        T: "AAN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрные",
        E: 'black (as in "she wears black")',
        T: "AAP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: 'black (as in "she wears black")',
        T: "AIM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: 'black (as in "she wears black")',
        T: "AIF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрным",
        E: 'black (as in "she wears black")',
        T: "AIN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрными",
        E: 'black (as in "she wears black")',
        T: "AIP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрном",
        E: 'black (as in "she wears black")',
        T: "APM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрной",
        E: 'black (as in "she wears black")',
        T: "APF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрном",
        E: 'black (as in "she wears black")',
        T: "APN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрных",
        E: 'black (as in "she wears black")',
        T: "APP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чёрен",
        E: 'black (as in "she wears black")',
        T: "ASM",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черна",
        E: 'black (as in "she wears black")',
        T: "ASF",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черно",
        E: 'black (as in "she wears black")',
        T: "ASN",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черны",
        E: 'black (as in "she wears black")',
        T: "ASP",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чернее",
        E: 'black (as in "she wears black")',
        T: "AC1",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "черней",
        E: 'black (as in "she wears black")',
        T: "AC2",
        A: "-",
        O: "чёрный",
        RA: 461,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кой",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ORIGINAL",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кой",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ANM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коя",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ANF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кое",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ANN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кои",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ANP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коего",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AGM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коей",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AGF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коего",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AGN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коих",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AGP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коему",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ADM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коей",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ADF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коему",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ADN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коим",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "ADP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кой",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AAM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кою",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AAF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кое",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AAN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кои",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AAP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коим",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AIM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коей",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AIF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коим",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AIN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коими",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "AIP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коем",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "APM",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коей",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "APF",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коем",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "APN",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коих",
        E: "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
        T: "APP",
        A: "-",
        O: "кой",
        RA: 478,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странный",
        E: "strange",
        T: "ORIGINAL",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странный",
        E: "strange",
        T: "ANM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странная",
        E: "strange",
        T: "ANF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странное",
        E: "strange",
        T: "ANN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странные",
        E: "strange",
        T: "ANP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странного",
        E: "strange",
        T: "AGM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странной",
        E: "strange",
        T: "AGF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странного",
        E: "strange",
        T: "AGN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странных",
        E: "strange",
        T: "AGP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странному",
        E: "strange",
        T: "ADM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странной",
        E: "strange",
        T: "ADF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странному",
        E: "strange",
        T: "ADN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странным",
        E: "strange",
        T: "ADP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странный",
        E: "strange",
        T: "AAM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странную",
        E: "strange",
        T: "AAF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странное",
        E: "strange",
        T: "AAN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странные",
        E: "strange",
        T: "AAP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странным",
        E: "strange",
        T: "AIM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странной",
        E: "strange",
        T: "AIF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странным",
        E: "strange",
        T: "AIN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странными",
        E: "strange",
        T: "AIP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странном",
        E: "strange",
        T: "APM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странной",
        E: "strange",
        T: "APF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странном",
        E: "strange",
        T: "APN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странных",
        E: "strange",
        T: "APP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странен",
        E: "strange",
        T: "ASM",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странна",
        E: "strange",
        T: "ASF",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странно",
        E: "strange",
        T: "ASN",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странны",
        E: "strange",
        T: "ASP",
        A: "-",
        O: "странный",
        RA: 493,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственный",
        E: "only, sole",
        T: "ORIGINAL",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственный",
        E: "only, sole",
        T: "ANM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственная",
        E: "only, sole",
        T: "ANF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственное",
        E: "only, sole",
        T: "ANN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственные",
        E: "only, sole",
        T: "ANP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственного",
        E: "only, sole",
        T: "AGM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственной",
        E: "only, sole",
        T: "AGF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственного",
        E: "only, sole",
        T: "AGN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственных",
        E: "only, sole",
        T: "AGP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственному",
        E: "only, sole",
        T: "ADM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственной",
        E: "only, sole",
        T: "ADF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственному",
        E: "only, sole",
        T: "ADN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственным",
        E: "only, sole",
        T: "ADP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственный",
        E: "only, sole",
        T: "AAM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственную",
        E: "only, sole",
        T: "AAF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственное",
        E: "only, sole",
        T: "AAN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственные",
        E: "only, sole",
        T: "AAP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственным",
        E: "only, sole",
        T: "AIM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственной",
        E: "only, sole",
        T: "AIF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственным",
        E: "only, sole",
        T: "AIN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственными",
        E: "only, sole",
        T: "AIP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственном",
        E: "only, sole",
        T: "APM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственной",
        E: "only, sole",
        T: "APF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственном",
        E: "only, sole",
        T: "APN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственных",
        E: "only, sole",
        T: "APP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственен",
        E: "only, sole",
        T: "ASM",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственна",
        E: "only, sole",
        T: "ASF",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственно",
        E: "only, sole",
        T: "ASN",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "единственны",
        E: "only, sole",
        T: "ASP",
        A: "-",
        O: "единственный",
        RA: 494,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткий",
        E: "short",
        T: "ORIGINAL",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткий",
        E: "short",
        T: "ANM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткая",
        E: "short",
        T: "ANF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткое",
        E: "short",
        T: "ANN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткие",
        E: "short",
        T: "ANP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткого",
        E: "short",
        T: "AGM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткой",
        E: "short",
        T: "AGF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткого",
        E: "short",
        T: "AGN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротких",
        E: "short",
        T: "AGP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткому",
        E: "short",
        T: "ADM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткой",
        E: "short",
        T: "ADF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткому",
        E: "short",
        T: "ADN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротким",
        E: "short",
        T: "ADP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткий",
        E: "short",
        T: "AAM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткую",
        E: "short",
        T: "AAF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткое",
        E: "short",
        T: "AAN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткие",
        E: "short",
        T: "AAP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротким",
        E: "short",
        T: "AIM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткой",
        E: "short",
        T: "AIF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротким",
        E: "short",
        T: "AIN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткими",
        E: "short",
        T: "AIP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротком",
        E: "short",
        T: "APM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короткой",
        E: "short",
        T: "APF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротком",
        E: "short",
        T: "APN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротких",
        E: "short",
        T: "APP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короток короток",
        E: "short",
        T: "ASM",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротка",
        E: "short",
        T: "ASF",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротко коротко коротко",
        E: "short",
        T: "ASN",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "коротки коротки коротки",
        E: "short",
        T: "ASP",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "короче",
        E: "short",
        T: "AC1",
        A: "-",
        O: "короткий",
        RA: 497,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрый",
        E: "kind",
        T: "ORIGINAL",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрый",
        E: "kind",
        T: "ANM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрая",
        E: "kind",
        T: "ANF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброе",
        E: "kind",
        T: "ANN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрые",
        E: "kind",
        T: "ANP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброго",
        E: "kind",
        T: "AGM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброй",
        E: "kind",
        T: "AGF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброго",
        E: "kind",
        T: "AGN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрых",
        E: "kind",
        T: "AGP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброму",
        E: "kind",
        T: "ADM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброй",
        E: "kind",
        T: "ADF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброму",
        E: "kind",
        T: "ADN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрым",
        E: "kind",
        T: "ADP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброго",
        E: "kind",
        T: "AAM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрую",
        E: "kind",
        T: "AAF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброе",
        E: "kind",
        T: "AAN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрых",
        E: "kind",
        T: "AAP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрым",
        E: "kind",
        T: "AIM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброй",
        E: "kind",
        T: "AIF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрым",
        E: "kind",
        T: "AIN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрыми",
        E: "kind",
        T: "AIP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добром",
        E: "kind",
        T: "APM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "доброй",
        E: "kind",
        T: "APF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добром",
        E: "kind",
        T: "APN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрых",
        E: "kind",
        T: "APP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добр",
        E: "kind",
        T: "ASM",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добра",
        E: "kind",
        T: "ASF",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добро",
        E: "kind",
        T: "ASN",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добры добры",
        E: "kind",
        T: "ASP",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "добрее",
        E: "kind",
        T: "AC1",
        A: "-",
        O: "добрый",
        RA: 499,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмный",
        E: "dark",
        T: "ORIGINAL",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмный",
        E: "dark",
        T: "ANM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмная",
        E: "dark",
        T: "ANF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмное",
        E: "dark",
        T: "ANN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмные",
        E: "dark",
        T: "ANP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмного",
        E: "dark",
        T: "AGM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмной",
        E: "dark",
        T: "AGF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмного",
        E: "dark",
        T: "AGN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмных",
        E: "dark",
        T: "AGP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмному",
        E: "dark",
        T: "ADM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмной",
        E: "dark",
        T: "ADF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмному",
        E: "dark",
        T: "ADN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмным",
        E: "dark",
        T: "ADP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмный",
        E: "dark",
        T: "AAM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмную",
        E: "dark",
        T: "AAF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмное",
        E: "dark",
        T: "AAN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмные",
        E: "dark",
        T: "AAP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмным",
        E: "dark",
        T: "AIM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмной",
        E: "dark",
        T: "AIF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмным",
        E: "dark",
        T: "AIN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмными",
        E: "dark",
        T: "AIP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмном",
        E: "dark",
        T: "APM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмной",
        E: "dark",
        T: "APF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмном",
        E: "dark",
        T: "APN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмных",
        E: "dark",
        T: "APP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёмен",
        E: "dark",
        T: "ASM",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "темна",
        E: "dark",
        T: "ASF",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "темно",
        E: "dark",
        T: "ASN",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "темны",
        E: "dark",
        T: "ASP",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "темнее",
        E: "dark",
        T: "AC1",
        A: "-",
        O: "тёмный",
        RA: 500,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучший",
        E: "better, best",
        T: "ORIGINAL",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучший",
        E: "better, best",
        T: "ANM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшая",
        E: "better, best",
        T: "ANF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшее",
        E: "better, best",
        T: "ANN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшие",
        E: "better, best",
        T: "ANP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшего",
        E: "better, best",
        T: "AGM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшей",
        E: "better, best",
        T: "AGF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшего",
        E: "better, best",
        T: "AGN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучших",
        E: "better, best",
        T: "AGP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшему",
        E: "better, best",
        T: "ADM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшей",
        E: "better, best",
        T: "ADF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшему",
        E: "better, best",
        T: "ADN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшим",
        E: "better, best",
        T: "ADP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучший",
        E: "better, best",
        T: "AAM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшую",
        E: "better, best",
        T: "AAF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшее",
        E: "better, best",
        T: "AAN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшие",
        E: "better, best",
        T: "AAP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшим",
        E: "better, best",
        T: "AIM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшей",
        E: "better, best",
        T: "AIF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшим",
        E: "better, best",
        T: "AIN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшими",
        E: "better, best",
        T: "AIP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшем",
        E: "better, best",
        T: "APM",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшей",
        E: "better, best",
        T: "APF",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучшем",
        E: "better, best",
        T: "APN",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лучших",
        E: "better, best",
        T: "APP",
        A: "-",
        O: "лучший",
        RA: 505,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военный",
        E: "military",
        T: "ORIGINAL",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военный",
        E: "military",
        T: "ANM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военная",
        E: "military",
        T: "ANF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военное",
        E: "military",
        T: "ANN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военные",
        E: "military",
        T: "ANP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военного",
        E: "military",
        T: "AGM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военной",
        E: "military",
        T: "AGF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военного",
        E: "military",
        T: "AGN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военных",
        E: "military",
        T: "AGP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военному",
        E: "military",
        T: "ADM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военной",
        E: "military",
        T: "ADF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военному",
        E: "military",
        T: "ADN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военным",
        E: "military",
        T: "ADP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военный",
        E: "military",
        T: "AAM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военную",
        E: "military",
        T: "AAF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военное",
        E: "military",
        T: "AAN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военные",
        E: "military",
        T: "AAP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военным",
        E: "military",
        T: "AIM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военной",
        E: "military",
        T: "AIF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военным",
        E: "military",
        T: "AIN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военными",
        E: "military",
        T: "AIP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военном",
        E: "military",
        T: "APM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военной",
        E: "military",
        T: "APF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военном",
        E: "military",
        T: "APN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военных",
        E: "military",
        T: "APP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военен",
        E: "military",
        T: "ASM",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военна",
        E: "military",
        T: "ASF",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военно",
        E: "military",
        T: "ASN",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "военны",
        E: "military",
        T: "ASP",
        A: "-",
        O: "военный",
        RA: 508,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшный",
        E: "terrible, frightful",
        T: "ORIGINAL",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшный",
        E: "terrible, frightful",
        T: "ANM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшная",
        E: "terrible, frightful",
        T: "ANF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшное",
        E: "terrible, frightful",
        T: "ANN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшные",
        E: "terrible, frightful",
        T: "ANP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшного",
        E: "terrible, frightful",
        T: "AGM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшной",
        E: "terrible, frightful",
        T: "AGF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшного",
        E: "terrible, frightful",
        T: "AGN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшных",
        E: "terrible, frightful",
        T: "AGP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшному",
        E: "terrible, frightful",
        T: "ADM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшной",
        E: "terrible, frightful",
        T: "ADF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшному",
        E: "terrible, frightful",
        T: "ADN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшным",
        E: "terrible, frightful",
        T: "ADP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшный",
        E: "terrible, frightful",
        T: "AAM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшную",
        E: "terrible, frightful",
        T: "AAF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшное",
        E: "terrible, frightful",
        T: "AAN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшные",
        E: "terrible, frightful",
        T: "AAP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшным",
        E: "terrible, frightful",
        T: "AIM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшной",
        E: "terrible, frightful",
        T: "AIF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшным",
        E: "terrible, frightful",
        T: "AIN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшными",
        E: "terrible, frightful",
        T: "AIP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшном",
        E: "terrible, frightful",
        T: "APM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшной",
        E: "terrible, frightful",
        T: "APF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшном",
        E: "terrible, frightful",
        T: "APN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшных",
        E: "terrible, frightful",
        T: "APP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшен",
        E: "terrible, frightful",
        T: "ASM",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшна",
        E: "terrible, frightful",
        T: "ASF",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшно",
        E: "terrible, frightful",
        T: "ASN",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшны страшны",
        E: "terrible, frightful",
        T: "ASP",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшнее",
        E: "terrible, frightful",
        T: "AC1",
        A: "-",
        O: "страшный",
        RA: 510,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимый",
        E: "necessary",
        T: "ORIGINAL",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимый",
        E: "necessary",
        T: "ANM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимая",
        E: "necessary",
        T: "ANF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимое",
        E: "necessary",
        T: "ANN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимые",
        E: "necessary",
        T: "ANP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимого",
        E: "necessary",
        T: "AGM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимой",
        E: "necessary",
        T: "AGF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимого",
        E: "necessary",
        T: "AGN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимых",
        E: "necessary",
        T: "AGP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимому",
        E: "necessary",
        T: "ADM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимой",
        E: "necessary",
        T: "ADF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимому",
        E: "necessary",
        T: "ADN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимым",
        E: "necessary",
        T: "ADP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимый",
        E: "necessary",
        T: "AAM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимую",
        E: "necessary",
        T: "AAF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимое",
        E: "necessary",
        T: "AAN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимые",
        E: "necessary",
        T: "AAP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимым",
        E: "necessary",
        T: "AIM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимой",
        E: "necessary",
        T: "AIF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимым",
        E: "necessary",
        T: "AIN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимыми",
        E: "necessary",
        T: "AIP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимом",
        E: "necessary",
        T: "APM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимой",
        E: "necessary",
        T: "APF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимом",
        E: "necessary",
        T: "APN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимых",
        E: "necessary",
        T: "APP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходим",
        E: "necessary",
        T: "ASM",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходима",
        E: "necessary",
        T: "ASF",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимо",
        E: "necessary",
        T: "ASN",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимы",
        E: "necessary",
        T: "ASP",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "необходимее",
        E: "necessary",
        T: "AC1",
        A: "-",
        O: "необходимый",
        RA: 522,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкий",
        E: "light, easy",
        T: "ORIGINAL",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкий",
        E: "light, easy",
        T: "ANM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкая",
        E: "light, easy",
        T: "ANF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкое",
        E: "light, easy",
        T: "ANN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкие",
        E: "light, easy",
        T: "ANP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкого",
        E: "light, easy",
        T: "AGM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкой",
        E: "light, easy",
        T: "AGF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкого",
        E: "light, easy",
        T: "AGN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгких",
        E: "light, easy",
        T: "AGP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкому",
        E: "light, easy",
        T: "ADM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкой",
        E: "light, easy",
        T: "ADF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкому",
        E: "light, easy",
        T: "ADN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгким",
        E: "light, easy",
        T: "ADP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкий",
        E: "light, easy",
        T: "AAM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкую",
        E: "light, easy",
        T: "AAF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкое",
        E: "light, easy",
        T: "AAN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкие",
        E: "light, easy",
        T: "AAP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгким",
        E: "light, easy",
        T: "AIM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкой",
        E: "light, easy",
        T: "AIF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгким",
        E: "light, easy",
        T: "AIN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкими",
        E: "light, easy",
        T: "AIP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгком",
        E: "light, easy",
        T: "APM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгкой",
        E: "light, easy",
        T: "APF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгком",
        E: "light, easy",
        T: "APN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгких",
        E: "light, easy",
        T: "APP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лёгок",
        E: "light, easy",
        T: "ASM",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "легка",
        E: "light, easy",
        T: "ASF",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "легко",
        E: "light, easy",
        T: "ASN",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "легки",
        E: "light, easy",
        T: "ASP",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "легче",
        E: "light, easy",
        T: "AC1",
        A: "-",
        O: "лёгкий",
        RA: 531,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "ORIGINAL",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "ANM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основная",
        E: "main",
        T: "ANF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основное",
        E: "main",
        T: "ANN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основные",
        E: "main",
        T: "ANP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основного",
        E: "main",
        T: "AGM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "AGF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основного",
        E: "main",
        T: "AGN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основных",
        E: "main",
        T: "AGP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основному",
        E: "main",
        T: "ADM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "ADF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основному",
        E: "main",
        T: "ADN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основным",
        E: "main",
        T: "ADP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "AAM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основную",
        E: "main",
        T: "AAF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основное",
        E: "main",
        T: "AAN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основные",
        E: "main",
        T: "AAP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основным",
        E: "main",
        T: "AIM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "AIF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основным",
        E: "main",
        T: "AIN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основными",
        E: "main",
        T: "AIP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основном",
        E: "main",
        T: "APM",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основной",
        E: "main",
        T: "APF",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основном",
        E: "main",
        T: "APN",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "основных",
        E: "main",
        T: "APP",
        A: "-",
        O: "основной",
        RA: 535,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкий",
        E: "wide",
        T: "ORIGINAL",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкий",
        E: "wide",
        T: "ANM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкая",
        E: "wide",
        T: "ANF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкое",
        E: "wide",
        T: "ANN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкие",
        E: "wide",
        T: "ANP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкого",
        E: "wide",
        T: "AGM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкой",
        E: "wide",
        T: "AGF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкого",
        E: "wide",
        T: "AGN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широких",
        E: "wide",
        T: "AGP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкому",
        E: "wide",
        T: "ADM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкой",
        E: "wide",
        T: "ADF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкому",
        E: "wide",
        T: "ADN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широким",
        E: "wide",
        T: "ADP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкий",
        E: "wide",
        T: "AAM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкую",
        E: "wide",
        T: "AAF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкое",
        E: "wide",
        T: "AAN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкие",
        E: "wide",
        T: "AAP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широким",
        E: "wide",
        T: "AIM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкой",
        E: "wide",
        T: "AIF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широким",
        E: "wide",
        T: "AIN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкими",
        E: "wide",
        T: "AIP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широком",
        E: "wide",
        T: "APM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широкой",
        E: "wide",
        T: "APF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широком",
        E: "wide",
        T: "APN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широких",
        E: "wide",
        T: "APP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широк",
        E: "wide",
        T: "ASM",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широка",
        E: "wide",
        T: "ASF",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широко широко",
        E: "wide",
        T: "ASN",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "широки широки",
        E: "wide",
        T: "ASP",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шире",
        E: "wide",
        T: "AC1",
        A: "-",
        O: "широкий",
        RA: 536,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивый",
        E: "beautiful",
        T: "ORIGINAL",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивый",
        E: "beautiful",
        T: "ANM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивая",
        E: "beautiful",
        T: "ANF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивое",
        E: "beautiful",
        T: "ANN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивые",
        E: "beautiful",
        T: "ANP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивого",
        E: "beautiful",
        T: "AGM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивой",
        E: "beautiful",
        T: "AGF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивого",
        E: "beautiful",
        T: "AGN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивых",
        E: "beautiful",
        T: "AGP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивому",
        E: "beautiful",
        T: "ADM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивой",
        E: "beautiful",
        T: "ADF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивому",
        E: "beautiful",
        T: "ADN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивым",
        E: "beautiful",
        T: "ADP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивый",
        E: "beautiful",
        T: "AAM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивую",
        E: "beautiful",
        T: "AAF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивое",
        E: "beautiful",
        T: "AAN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивые",
        E: "beautiful",
        T: "AAP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивым",
        E: "beautiful",
        T: "AIM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивой",
        E: "beautiful",
        T: "AIF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивым",
        E: "beautiful",
        T: "AIN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивыми",
        E: "beautiful",
        T: "AIP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивом",
        E: "beautiful",
        T: "APM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивой",
        E: "beautiful",
        T: "APF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивом",
        E: "beautiful",
        T: "APN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивых",
        E: "beautiful",
        T: "APP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красив",
        E: "beautiful",
        T: "ASM",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красива",
        E: "beautiful",
        T: "ASF",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красиво",
        E: "beautiful",
        T: "ASN",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивы",
        E: "beautiful",
        T: "ASP",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "красивее",
        E: "beautiful",
        T: "AC1",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "краше",
        E: "beautiful",
        T: "AC2",
        A: "-",
        O: "красивый",
        RA: 541,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серый",
        E: "grey",
        T: "ORIGINAL",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серый",
        E: "grey",
        T: "ANM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серая",
        E: "grey",
        T: "ANF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серое",
        E: "grey",
        T: "ANN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серые",
        E: "grey",
        T: "ANP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серого",
        E: "grey",
        T: "AGM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серой",
        E: "grey",
        T: "AGF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серого",
        E: "grey",
        T: "AGN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серых",
        E: "grey",
        T: "AGP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серому",
        E: "grey",
        T: "ADM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серой",
        E: "grey",
        T: "ADF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серому",
        E: "grey",
        T: "ADN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серым",
        E: "grey",
        T: "ADP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серый",
        E: "grey",
        T: "AAM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серую",
        E: "grey",
        T: "AAF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серое",
        E: "grey",
        T: "AAN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серые",
        E: "grey",
        T: "AAP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серым",
        E: "grey",
        T: "AIM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серой",
        E: "grey",
        T: "AIF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серым",
        E: "grey",
        T: "AIN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серыми",
        E: "grey",
        T: "AIP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сером",
        E: "grey",
        T: "APM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серой",
        E: "grey",
        T: "APF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сером",
        E: "grey",
        T: "APN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серых",
        E: "grey",
        T: "APP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сер",
        E: "grey",
        T: "ASM",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сера",
        E: "grey",
        T: "ASF",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серо",
        E: "grey",
        T: "ASN",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серы",
        E: "grey",
        T: "ASP",
        A: "-",
        O: "серый",
        RA: 542,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "ORIGINAL",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "ANM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшая",
        E: "small, not great",
        T: "ANF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшое",
        E: "small, not great",
        T: "ANN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшие",
        E: "small, not great",
        T: "ANP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшого",
        E: "small, not great",
        T: "AGM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "AGF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшого",
        E: "small, not great",
        T: "AGN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольших",
        E: "small, not great",
        T: "AGP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшому",
        E: "small, not great",
        T: "ADM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "ADF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшому",
        E: "small, not great",
        T: "ADN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшим",
        E: "small, not great",
        T: "ADP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "AAM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшую",
        E: "small, not great",
        T: "AAF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшое",
        E: "small, not great",
        T: "AAN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшие",
        E: "small, not great",
        T: "AAP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшим",
        E: "small, not great",
        T: "AIM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "AIF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшим",
        E: "small, not great",
        T: "AIN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшими",
        E: "small, not great",
        T: "AIP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшом",
        E: "small, not great",
        T: "APM",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшой",
        E: "small, not great",
        T: "APF",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольшом",
        E: "small, not great",
        T: "APN",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "небольших",
        E: "small, not great",
        T: "APP",
        A: "-",
        O: "небольшой",
        RA: 563,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "ORIGINAL",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "ANM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустая",
        E: "empty, hollow; idle",
        T: "ANF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустое",
        E: "empty, hollow; idle",
        T: "ANN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустые",
        E: "empty, hollow; idle",
        T: "ANP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустого",
        E: "empty, hollow; idle",
        T: "AGM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "AGF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустого",
        E: "empty, hollow; idle",
        T: "AGN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустых",
        E: "empty, hollow; idle",
        T: "AGP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустому",
        E: "empty, hollow; idle",
        T: "ADM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "ADF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустому",
        E: "empty, hollow; idle",
        T: "ADN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустым",
        E: "empty, hollow; idle",
        T: "ADP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустого",
        E: "empty, hollow; idle",
        T: "AAM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустую",
        E: "empty, hollow; idle",
        T: "AAF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустое",
        E: "empty, hollow; idle",
        T: "AAN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустых",
        E: "empty, hollow; idle",
        T: "AAP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустым",
        E: "empty, hollow; idle",
        T: "AIM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "AIF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустым",
        E: "empty, hollow; idle",
        T: "AIN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустыми",
        E: "empty, hollow; idle",
        T: "AIP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустом",
        E: "empty, hollow; idle",
        T: "APM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустой",
        E: "empty, hollow; idle",
        T: "APF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустом",
        E: "empty, hollow; idle",
        T: "APN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пустых",
        E: "empty, hollow; idle",
        T: "APP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пуст",
        E: "empty, hollow; idle",
        T: "ASM",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пуста",
        E: "empty, hollow; idle",
        T: "ASF",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пусто",
        E: "empty, hollow; idle",
        T: "ASN",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пусты",
        E: "empty, hollow; idle",
        T: "ASP",
        A: "-",
        O: "пустой",
        RA: 567,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческий",
        E: "human",
        T: "ORIGINAL",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческий",
        E: "human",
        T: "ANM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческая",
        E: "human",
        T: "ANF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческое",
        E: "human",
        T: "ANN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческие",
        E: "human",
        T: "ANP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческого",
        E: "human",
        T: "AGM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческой",
        E: "human",
        T: "AGF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческого",
        E: "human",
        T: "AGN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческих",
        E: "human",
        T: "AGP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческому",
        E: "human",
        T: "ADM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческой",
        E: "human",
        T: "ADF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческому",
        E: "human",
        T: "ADN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческим",
        E: "human",
        T: "ADP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческий",
        E: "human",
        T: "AAM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческую",
        E: "human",
        T: "AAF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческое",
        E: "human",
        T: "AAN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческие",
        E: "human",
        T: "AAP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческим",
        E: "human",
        T: "AIM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческой",
        E: "human",
        T: "AIF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческим",
        E: "human",
        T: "AIN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческими",
        E: "human",
        T: "AIP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческом",
        E: "human",
        T: "APM",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческой",
        E: "human",
        T: "APF",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческом",
        E: "human",
        T: "APN",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "человеческих",
        E: "human",
        T: "APP",
        A: "-",
        O: "человеческий",
        RA: 569,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстый",
        E: "thick, heavy, fat",
        T: "ORIGINAL",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстый",
        E: "thick, heavy, fat",
        T: "ANM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстая",
        E: "thick, heavy, fat",
        T: "ANF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстое",
        E: "thick, heavy, fat",
        T: "ANN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстые",
        E: "thick, heavy, fat",
        T: "ANP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстого",
        E: "thick, heavy, fat",
        T: "AGM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстой",
        E: "thick, heavy, fat",
        T: "AGF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстого",
        E: "thick, heavy, fat",
        T: "AGN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстых",
        E: "thick, heavy, fat",
        T: "AGP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстому",
        E: "thick, heavy, fat",
        T: "ADM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстой",
        E: "thick, heavy, fat",
        T: "ADF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстому",
        E: "thick, heavy, fat",
        T: "ADN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстым",
        E: "thick, heavy, fat",
        T: "ADP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстый",
        E: "thick, heavy, fat",
        T: "AAM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстую",
        E: "thick, heavy, fat",
        T: "AAF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстое",
        E: "thick, heavy, fat",
        T: "AAN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстые",
        E: "thick, heavy, fat",
        T: "AAP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстым",
        E: "thick, heavy, fat",
        T: "AIM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстой",
        E: "thick, heavy, fat",
        T: "AIF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстым",
        E: "thick, heavy, fat",
        T: "AIN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстыми",
        E: "thick, heavy, fat",
        T: "AIP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстом",
        E: "thick, heavy, fat",
        T: "APM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстой",
        E: "thick, heavy, fat",
        T: "APF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстом",
        E: "thick, heavy, fat",
        T: "APN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толстых",
        E: "thick, heavy, fat",
        T: "APP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толст",
        E: "thick, heavy, fat",
        T: "ASM",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толста",
        E: "thick, heavy, fat",
        T: "ASF",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толсто",
        E: "thick, heavy, fat",
        T: "ASN",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толсты",
        E: "thick, heavy, fat",
        T: "ASP",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "толще",
        E: "thick, heavy, fat",
        T: "AC1",
        A: "-",
        O: "толстый",
        RA: 577,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистый",
        E: "clean, pure",
        T: "ORIGINAL",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистый",
        E: "clean, pure",
        T: "ANM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистая",
        E: "clean, pure",
        T: "ANF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистое",
        E: "clean, pure",
        T: "ANN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистые",
        E: "clean, pure",
        T: "ANP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистого",
        E: "clean, pure",
        T: "AGM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистой",
        E: "clean, pure",
        T: "AGF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистого",
        E: "clean, pure",
        T: "AGN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистых",
        E: "clean, pure",
        T: "AGP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистому",
        E: "clean, pure",
        T: "ADM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистой",
        E: "clean, pure",
        T: "ADF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистому",
        E: "clean, pure",
        T: "ADN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистым",
        E: "clean, pure",
        T: "ADP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистый",
        E: "clean, pure",
        T: "AAM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистую",
        E: "clean, pure",
        T: "AAF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистое",
        E: "clean, pure",
        T: "AAN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистые",
        E: "clean, pure",
        T: "AAP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистым",
        E: "clean, pure",
        T: "AIM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистой",
        E: "clean, pure",
        T: "AIF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистым",
        E: "clean, pure",
        T: "AIN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистыми",
        E: "clean, pure",
        T: "AIP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистом",
        E: "clean, pure",
        T: "APM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистой",
        E: "clean, pure",
        T: "APF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистом",
        E: "clean, pure",
        T: "APN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чистых",
        E: "clean, pure",
        T: "APP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чист",
        E: "clean, pure",
        T: "ASM",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чиста",
        E: "clean, pure",
        T: "ASF",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чисто",
        E: "clean, pure",
        T: "ASN",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чисты чисты",
        E: "clean, pure",
        T: "ASP",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чище",
        E: "clean, pure",
        T: "AC1",
        A: "-",
        O: "чистый",
        RA: 581,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежний",
        E: "former",
        T: "ORIGINAL",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежний",
        E: "former",
        T: "ANM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежняя",
        E: "former",
        T: "ANF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнее",
        E: "former",
        T: "ANN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежние",
        E: "former",
        T: "ANP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнего",
        E: "former",
        T: "AGM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежней",
        E: "former",
        T: "AGF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнего",
        E: "former",
        T: "AGN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежних",
        E: "former",
        T: "AGP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнему",
        E: "former",
        T: "ADM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежней",
        E: "former",
        T: "ADF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнему",
        E: "former",
        T: "ADN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежним",
        E: "former",
        T: "ADP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежний",
        E: "former",
        T: "AAM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнюю",
        E: "former",
        T: "AAF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнее",
        E: "former",
        T: "AAN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежние",
        E: "former",
        T: "AAP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежним",
        E: "former",
        T: "AIM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежней",
        E: "former",
        T: "AIF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежним",
        E: "former",
        T: "AIN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежними",
        E: "former",
        T: "AIP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнем",
        E: "former",
        T: "APM",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежней",
        E: "former",
        T: "APF",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежнем",
        E: "former",
        T: "APN",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежних",
        E: "former",
        T: "APP",
        A: "-",
        O: "прежний",
        RA: 583,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "ORIGINAL",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "ANM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худая",
        E: "thin, skinny",
        T: "ANF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худое",
        E: "thin, skinny",
        T: "ANN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худые",
        E: "thin, skinny",
        T: "ANP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худого",
        E: "thin, skinny",
        T: "AGM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "AGF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худого",
        E: "thin, skinny",
        T: "AGN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худых",
        E: "thin, skinny",
        T: "AGP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худому",
        E: "thin, skinny",
        T: "ADM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "ADF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худому",
        E: "thin, skinny",
        T: "ADN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худым",
        E: "thin, skinny",
        T: "ADP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худого",
        E: "thin, skinny",
        T: "AAM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худую",
        E: "thin, skinny",
        T: "AAF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худое",
        E: "thin, skinny",
        T: "AAN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худых",
        E: "thin, skinny",
        T: "AAP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худым",
        E: "thin, skinny",
        T: "AIM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "AIF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худым",
        E: "thin, skinny",
        T: "AIN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худыми",
        E: "thin, skinny",
        T: "AIP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худом",
        E: "thin, skinny",
        T: "APM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худой",
        E: "thin, skinny",
        T: "APF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худом",
        E: "thin, skinny",
        T: "APN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худых",
        E: "thin, skinny",
        T: "APP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худ",
        E: "thin, skinny",
        T: "ASM",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худа",
        E: "thin, skinny",
        T: "ASF",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худо",
        E: "thin, skinny",
        T: "ASN",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худы",
        E: "thin, skinny",
        T: "ASP",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "худее",
        E: "thin, skinny",
        T: "AC1",
        A: "-",
        O: "худой",
        RA: 587,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "ORIGINAL",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "ANM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужая",
        E: "another person's",
        T: "ANF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужое",
        E: "another person's",
        T: "ANN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужие",
        E: "another person's",
        T: "ANP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужого",
        E: "another person's",
        T: "AGM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "AGF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужого",
        E: "another person's",
        T: "AGN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужих",
        E: "another person's",
        T: "AGP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужому",
        E: "another person's",
        T: "ADM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "ADF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужому",
        E: "another person's",
        T: "ADN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужим",
        E: "another person's",
        T: "ADP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "AAM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужую",
        E: "another person's",
        T: "AAF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужое",
        E: "another person's",
        T: "AAN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужие",
        E: "another person's",
        T: "AAP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужим",
        E: "another person's",
        T: "AIM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "AIF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужим",
        E: "another person's",
        T: "AIN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужими",
        E: "another person's",
        T: "AIP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужом",
        E: "another person's",
        T: "APM",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужой",
        E: "another person's",
        T: "APF",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужом",
        E: "another person's",
        T: "APN",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужих",
        E: "another person's",
        T: "APP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чужи",
        E: "another person's",
        T: "ASP",
        A: "-",
        O: "чужой",
        RA: 590,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особый",
        E: "special",
        T: "ORIGINAL",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особый",
        E: "special",
        T: "ANM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особая",
        E: "special",
        T: "ANF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особое",
        E: "special",
        T: "ANN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особые",
        E: "special",
        T: "ANP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особого",
        E: "special",
        T: "AGM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особой",
        E: "special",
        T: "AGF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особого",
        E: "special",
        T: "AGN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особых",
        E: "special",
        T: "AGP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особому",
        E: "special",
        T: "ADM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особой",
        E: "special",
        T: "ADF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особому",
        E: "special",
        T: "ADN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особым",
        E: "special",
        T: "ADP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особый",
        E: "special",
        T: "AAM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особую",
        E: "special",
        T: "AAF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особое",
        E: "special",
        T: "AAN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особые",
        E: "special",
        T: "AAP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особым",
        E: "special",
        T: "AIM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особой",
        E: "special",
        T: "AIF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особым",
        E: "special",
        T: "AIN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особыми",
        E: "special",
        T: "AIP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особом",
        E: "special",
        T: "APM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особой",
        E: "special",
        T: "APF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особом",
        E: "special",
        T: "APN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особых",
        E: "special",
        T: "APP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особ",
        E: "special",
        T: "ASM",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особа",
        E: "special",
        T: "ASF",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особо",
        E: "special",
        T: "ASN",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особы",
        E: "special",
        T: "ASP",
        A: "-",
        O: "особый",
        RA: 593,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкий",
        E: "near, similar; intimate",
        T: "ORIGINAL",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкий",
        E: "near, similar; intimate",
        T: "ANM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкая",
        E: "near, similar; intimate",
        T: "ANF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкое",
        E: "near, similar; intimate",
        T: "ANN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкие",
        E: "near, similar; intimate",
        T: "ANP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкого",
        E: "near, similar; intimate",
        T: "AGM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкой",
        E: "near, similar; intimate",
        T: "AGF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкого",
        E: "near, similar; intimate",
        T: "AGN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близких",
        E: "near, similar; intimate",
        T: "AGP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкому",
        E: "near, similar; intimate",
        T: "ADM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкой",
        E: "near, similar; intimate",
        T: "ADF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкому",
        E: "near, similar; intimate",
        T: "ADN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близким",
        E: "near, similar; intimate",
        T: "ADP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкий",
        E: "near, similar; intimate",
        T: "AAM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкую",
        E: "near, similar; intimate",
        T: "AAF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкое",
        E: "near, similar; intimate",
        T: "AAN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкие",
        E: "near, similar; intimate",
        T: "AAP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близким",
        E: "near, similar; intimate",
        T: "AIM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкой",
        E: "near, similar; intimate",
        T: "AIF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близким",
        E: "near, similar; intimate",
        T: "AIN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкими",
        E: "near, similar; intimate",
        T: "AIP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близком",
        E: "near, similar; intimate",
        T: "APM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близкой",
        E: "near, similar; intimate",
        T: "APF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близком",
        E: "near, similar; intimate",
        T: "APN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близких",
        E: "near, similar; intimate",
        T: "APP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близок",
        E: "near, similar; intimate",
        T: "ASM",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близка",
        E: "near, similar; intimate",
        T: "ASF",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близко",
        E: "near, similar; intimate",
        T: "ASN",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "близки",
        E: "near, similar; intimate",
        T: "ASP",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ближе",
        E: "near, similar; intimate",
        T: "AC1",
        A: "-",
        O: "близкий",
        RA: 597,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "ORIGINAL",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "ANM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больная",
        E: "sick",
        T: "ANF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больное",
        E: "sick",
        T: "ANN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больные",
        E: "sick",
        T: "ANP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больного",
        E: "sick",
        T: "AGM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "AGF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больного",
        E: "sick",
        T: "AGN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больных",
        E: "sick",
        T: "AGP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больному",
        E: "sick",
        T: "ADM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "ADF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больному",
        E: "sick",
        T: "ADN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больным",
        E: "sick",
        T: "ADP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "AAM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больную",
        E: "sick",
        T: "AAF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больное",
        E: "sick",
        T: "AAN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больные",
        E: "sick",
        T: "AAP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больным",
        E: "sick",
        T: "AIM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "AIF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больным",
        E: "sick",
        T: "AIN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больными",
        E: "sick",
        T: "AIP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больном",
        E: "sick",
        T: "APM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больной",
        E: "sick",
        T: "APF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больном",
        E: "sick",
        T: "APN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больных",
        E: "sick",
        T: "APP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "болен",
        E: "sick",
        T: "ASM",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больна",
        E: "sick",
        T: "ASF",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больно",
        E: "sick",
        T: "ASN",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больны",
        E: "sick",
        T: "ASP",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "больнее",
        E: "sick",
        T: "AC1",
        A: "-",
        O: "больной",
        RA: 600,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старший",
        E: "elder, senior",
        T: "ORIGINAL",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старший",
        E: "elder, senior",
        T: "ANM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшая",
        E: "elder, senior",
        T: "ANF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшее",
        E: "elder, senior",
        T: "ANN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшие",
        E: "elder, senior",
        T: "ANP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшего",
        E: "elder, senior",
        T: "AGM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшей",
        E: "elder, senior",
        T: "AGF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшего",
        E: "elder, senior",
        T: "AGN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старших",
        E: "elder, senior",
        T: "AGP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшему",
        E: "elder, senior",
        T: "ADM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшей",
        E: "elder, senior",
        T: "ADF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшему",
        E: "elder, senior",
        T: "ADN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшим",
        E: "elder, senior",
        T: "ADP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старший",
        E: "elder, senior",
        T: "AAM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшую",
        E: "elder, senior",
        T: "AAF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшее",
        E: "elder, senior",
        T: "AAN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшие",
        E: "elder, senior",
        T: "AAP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшим",
        E: "elder, senior",
        T: "AIM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшей",
        E: "elder, senior",
        T: "AIF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшим",
        E: "elder, senior",
        T: "AIN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшими",
        E: "elder, senior",
        T: "AIP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшем",
        E: "elder, senior",
        T: "APM",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшей",
        E: "elder, senior",
        T: "APF",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старшем",
        E: "elder, senior",
        T: "APN",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "старших",
        E: "elder, senior",
        T: "APP",
        A: "-",
        O: "старший",
        RA: 616,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобный",
        E: "similar, like",
        T: "ORIGINAL",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобный",
        E: "similar, like",
        T: "ANM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобная",
        E: "similar, like",
        T: "ANF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобное",
        E: "similar, like",
        T: "ANN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобные",
        E: "similar, like",
        T: "ANP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобного",
        E: "similar, like",
        T: "AGM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобной",
        E: "similar, like",
        T: "AGF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобного",
        E: "similar, like",
        T: "AGN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобных",
        E: "similar, like",
        T: "AGP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобному",
        E: "similar, like",
        T: "ADM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобной",
        E: "similar, like",
        T: "ADF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобному",
        E: "similar, like",
        T: "ADN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобным",
        E: "similar, like",
        T: "ADP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобный",
        E: "similar, like",
        T: "AAM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобную",
        E: "similar, like",
        T: "AAF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобное",
        E: "similar, like",
        T: "AAN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобные",
        E: "similar, like",
        T: "AAP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобным",
        E: "similar, like",
        T: "AIM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобной",
        E: "similar, like",
        T: "AIF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобным",
        E: "similar, like",
        T: "AIN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобными",
        E: "similar, like",
        T: "AIP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобном",
        E: "similar, like",
        T: "APM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобной",
        E: "similar, like",
        T: "APF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобном",
        E: "similar, like",
        T: "APN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобных",
        E: "similar, like",
        T: "APP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобен",
        E: "similar, like",
        T: "ASM",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобна",
        E: "similar, like",
        T: "ASF",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобно",
        E: "similar, like",
        T: "ASN",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "подобны",
        E: "similar, like",
        T: "ASP",
        A: "-",
        O: "подобный",
        RA: 619,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правый",
        E: "right",
        T: "ORIGINAL",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правый",
        E: "right",
        T: "ANM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правая",
        E: "right",
        T: "ANF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правое",
        E: "right",
        T: "ANN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правые",
        E: "right",
        T: "ANP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правого",
        E: "right",
        T: "AGM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правой",
        E: "right",
        T: "AGF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правого",
        E: "right",
        T: "AGN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правых",
        E: "right",
        T: "AGP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правому",
        E: "right",
        T: "ADM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правой",
        E: "right",
        T: "ADF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правому",
        E: "right",
        T: "ADN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правым",
        E: "right",
        T: "ADP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правый",
        E: "right",
        T: "AAM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правую",
        E: "right",
        T: "AAF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правое",
        E: "right",
        T: "AAN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правые",
        E: "right",
        T: "AAP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правым",
        E: "right",
        T: "AIM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правой",
        E: "right",
        T: "AIF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правым",
        E: "right",
        T: "AIN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правыми",
        E: "right",
        T: "AIP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правом",
        E: "right",
        T: "APM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правой",
        E: "right",
        T: "APF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правом",
        E: "right",
        T: "APN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правых",
        E: "right",
        T: "APP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прав",
        E: "right",
        T: "ASM",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "права",
        E: "right",
        T: "ASF",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "право",
        E: "right",
        T: "ASN",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правы правы",
        E: "right",
        T: "ASP",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правее",
        E: "right",
        T: "AC1",
        A: "-",
        O: "правый",
        RA: 640,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодный",
        E: "cold, cool",
        T: "ORIGINAL",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодный",
        E: "cold, cool",
        T: "ANM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодная",
        E: "cold, cool",
        T: "ANF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодное",
        E: "cold, cool",
        T: "ANN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодные",
        E: "cold, cool",
        T: "ANP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодного",
        E: "cold, cool",
        T: "AGM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодной",
        E: "cold, cool",
        T: "AGF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодного",
        E: "cold, cool",
        T: "AGN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодных",
        E: "cold, cool",
        T: "AGP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодному",
        E: "cold, cool",
        T: "ADM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодной",
        E: "cold, cool",
        T: "ADF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодному",
        E: "cold, cool",
        T: "ADN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодным",
        E: "cold, cool",
        T: "ADP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодного",
        E: "cold, cool",
        T: "AAM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодную",
        E: "cold, cool",
        T: "AAF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодное",
        E: "cold, cool",
        T: "AAN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодных",
        E: "cold, cool",
        T: "AAP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодным",
        E: "cold, cool",
        T: "AIM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодной",
        E: "cold, cool",
        T: "AIF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодным",
        E: "cold, cool",
        T: "AIN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодными",
        E: "cold, cool",
        T: "AIP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодном",
        E: "cold, cool",
        T: "APM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодной",
        E: "cold, cool",
        T: "APF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодном",
        E: "cold, cool",
        T: "APN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодных",
        E: "cold, cool",
        T: "APP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холоден",
        E: "cold, cool",
        T: "ASM",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодна",
        E: "cold, cool",
        T: "ASF",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодно",
        E: "cold, cool",
        T: "ASN",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холодны холодны",
        E: "cold, cool",
        T: "ASP",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "холоднее",
        E: "cold, cool",
        T: "AC1",
        A: "-",
        O: "холодный",
        RA: 642,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многие",
        E: "many",
        T: "ORIGINAL",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многое",
        E: "many",
        T: "AAM",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многие",
        E: "many",
        T: "AAF",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многого",
        E: "many",
        T: "AAN",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многих",
        E: "many",
        T: "AAP",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многому",
        E: "many",
        T: "AIM",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многим",
        E: "many",
        T: "AIF",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многое",
        E: "many",
        T: "AIN",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многие",
        E: "many",
        T: "AIP",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многим",
        E: "many",
        T: "APM",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многими",
        E: "many",
        T: "APF",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многом",
        E: "many",
        T: "APN",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "многих",
        E: "many",
        T: "APP",
        A: "-",
        O: "многие",
        RA: 644,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "ORIGINAL",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "ANM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родная",
        E: "own, native, dear",
        T: "ANF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родное",
        E: "own, native, dear",
        T: "ANN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родные",
        E: "own, native, dear",
        T: "ANP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родного",
        E: "own, native, dear",
        T: "AGM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "AGF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родного",
        E: "own, native, dear",
        T: "AGN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родных",
        E: "own, native, dear",
        T: "AGP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родному",
        E: "own, native, dear",
        T: "ADM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "ADF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родному",
        E: "own, native, dear",
        T: "ADN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родным",
        E: "own, native, dear",
        T: "ADP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "AAM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родную",
        E: "own, native, dear",
        T: "AAF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родное",
        E: "own, native, dear",
        T: "AAN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родные",
        E: "own, native, dear",
        T: "AAP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родным",
        E: "own, native, dear",
        T: "AIM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "AIF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родным",
        E: "own, native, dear",
        T: "AIN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родными",
        E: "own, native, dear",
        T: "AIP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родном",
        E: "own, native, dear",
        T: "APM",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родной",
        E: "own, native, dear",
        T: "APF",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родном",
        E: "own, native, dear",
        T: "APN",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "родных",
        E: "own, native, dear",
        T: "APP",
        A: "-",
        O: "родной",
        RA: 656,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтый",
        E: "yellow",
        T: "ORIGINAL",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтый",
        E: "yellow",
        T: "ANM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтая",
        E: "yellow",
        T: "ANF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтое",
        E: "yellow",
        T: "ANN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтые",
        E: "yellow",
        T: "ANP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтого",
        E: "yellow",
        T: "AGM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтой",
        E: "yellow",
        T: "AGF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтого",
        E: "yellow",
        T: "AGN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтых",
        E: "yellow",
        T: "AGP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтому",
        E: "yellow",
        T: "ADM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтой",
        E: "yellow",
        T: "ADF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтому",
        E: "yellow",
        T: "ADN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтым",
        E: "yellow",
        T: "ADP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтый",
        E: "yellow",
        T: "AAM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтую",
        E: "yellow",
        T: "AAF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтое",
        E: "yellow",
        T: "AAN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтые",
        E: "yellow",
        T: "AAP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтым",
        E: "yellow",
        T: "AIM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтой",
        E: "yellow",
        T: "AIF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтым",
        E: "yellow",
        T: "AIN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтыми",
        E: "yellow",
        T: "AIP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтом",
        E: "yellow",
        T: "APM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтой",
        E: "yellow",
        T: "APF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтом",
        E: "yellow",
        T: "APN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлтых",
        E: "yellow",
        T: "APP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлт",
        E: "yellow",
        T: "ASM",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желта",
        E: "yellow",
        T: "ASF",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлто желто",
        E: "yellow",
        T: "ASN",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "жёлты желты",
        E: "yellow",
        T: "ASP",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "желтее",
        E: "yellow",
        T: "AC1",
        A: "-",
        O: "жёлтый",
        RA: 660,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "ORIGINAL",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "ANM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотая",
        E: "golden",
        T: "ANF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотое",
        E: "golden",
        T: "ANN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотые",
        E: "golden",
        T: "ANP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотого",
        E: "golden",
        T: "AGM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "AGF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотого",
        E: "golden",
        T: "AGN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотых",
        E: "golden",
        T: "AGP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотому",
        E: "golden",
        T: "ADM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "ADF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотому",
        E: "golden",
        T: "ADN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотым",
        E: "golden",
        T: "ADP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотого",
        E: "golden",
        T: "AAM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотую",
        E: "golden",
        T: "AAF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотое",
        E: "golden",
        T: "AAN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотых",
        E: "golden",
        T: "AAP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотым",
        E: "golden",
        T: "AIM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "AIF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотым",
        E: "golden",
        T: "AIN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотыми",
        E: "golden",
        T: "AIP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотом",
        E: "golden",
        T: "APM",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотой",
        E: "golden",
        T: "APF",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотом",
        E: "golden",
        T: "APN",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "золотых",
        E: "golden",
        T: "APP",
        A: "-",
        O: "золотой",
        RA: 681,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местный",
        E: "local",
        T: "ORIGINAL",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местный",
        E: "local",
        T: "ANM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местная",
        E: "local",
        T: "ANF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местное",
        E: "local",
        T: "ANN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местные",
        E: "local",
        T: "ANP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местного",
        E: "local",
        T: "AGM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местной",
        E: "local",
        T: "AGF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местного",
        E: "local",
        T: "AGN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местных",
        E: "local",
        T: "AGP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местному",
        E: "local",
        T: "ADM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местной",
        E: "local",
        T: "ADF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местному",
        E: "local",
        T: "ADN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местным",
        E: "local",
        T: "ADP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местного",
        E: "local",
        T: "AAM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местную",
        E: "local",
        T: "AAF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местное",
        E: "local",
        T: "AAN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местных",
        E: "local",
        T: "AAP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местным",
        E: "local",
        T: "AIM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местной",
        E: "local",
        T: "AIF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местным",
        E: "local",
        T: "AIN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местными",
        E: "local",
        T: "AIP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местном",
        E: "local",
        T: "APM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местной",
        E: "local",
        T: "APF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местном",
        E: "local",
        T: "APN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местных",
        E: "local",
        T: "APP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местен",
        E: "local",
        T: "ASM",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местна",
        E: "local",
        T: "ASF",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местно",
        E: "local",
        T: "ASN",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "местны",
        E: "local",
        T: "ASP",
        A: "-",
        O: "местный",
        RA: 682,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупный",
        E: "large, big, prominent",
        T: "ORIGINAL",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупный",
        E: "large, big, prominent",
        T: "ANM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупная",
        E: "large, big, prominent",
        T: "ANF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупное",
        E: "large, big, prominent",
        T: "ANN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупные",
        E: "large, big, prominent",
        T: "ANP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупного",
        E: "large, big, prominent",
        T: "AGM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупной",
        E: "large, big, prominent",
        T: "AGF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупного",
        E: "large, big, prominent",
        T: "AGN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупных",
        E: "large, big, prominent",
        T: "AGP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупному",
        E: "large, big, prominent",
        T: "ADM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупной",
        E: "large, big, prominent",
        T: "ADF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупному",
        E: "large, big, prominent",
        T: "ADN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупным",
        E: "large, big, prominent",
        T: "ADP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупный",
        E: "large, big, prominent",
        T: "AAM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупную",
        E: "large, big, prominent",
        T: "AAF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупное",
        E: "large, big, prominent",
        T: "AAN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупные",
        E: "large, big, prominent",
        T: "AAP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупным",
        E: "large, big, prominent",
        T: "AIM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупной",
        E: "large, big, prominent",
        T: "AIF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупным",
        E: "large, big, prominent",
        T: "AIN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупными",
        E: "large, big, prominent",
        T: "AIP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупном",
        E: "large, big, prominent",
        T: "APM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупной",
        E: "large, big, prominent",
        T: "APF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупном",
        E: "large, big, prominent",
        T: "APN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупных",
        E: "large, big, prominent",
        T: "APP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупен",
        E: "large, big, prominent",
        T: "ASM",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупна",
        E: "large, big, prominent",
        T: "ASF",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупно",
        E: "large, big, prominent",
        T: "ASN",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупны",
        E: "large, big, prominent",
        T: "ASP",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "крупнее",
        E: "large, big, prominent",
        T: "AC1",
        A: "-",
        O: "крупный",
        RA: 684,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частый",
        E: "frequent",
        T: "ORIGINAL",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частый",
        E: "frequent",
        T: "ANM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частая",
        E: "frequent",
        T: "ANF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частое",
        E: "frequent",
        T: "ANN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частые",
        E: "frequent",
        T: "ANP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частого",
        E: "frequent",
        T: "AGM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частой",
        E: "frequent",
        T: "AGF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частого",
        E: "frequent",
        T: "AGN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частых",
        E: "frequent",
        T: "AGP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частому",
        E: "frequent",
        T: "ADM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частой",
        E: "frequent",
        T: "ADF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частому",
        E: "frequent",
        T: "ADN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частым",
        E: "frequent",
        T: "ADP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частый",
        E: "frequent",
        T: "AAM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частую",
        E: "frequent",
        T: "AAF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частое",
        E: "frequent",
        T: "AAN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частые",
        E: "frequent",
        T: "AAP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частым",
        E: "frequent",
        T: "AIM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частой",
        E: "frequent",
        T: "AIF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частым",
        E: "frequent",
        T: "AIN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частыми",
        E: "frequent",
        T: "AIP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частом",
        E: "frequent",
        T: "APM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частой",
        E: "frequent",
        T: "APF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частом",
        E: "frequent",
        T: "APN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "частых",
        E: "frequent",
        T: "APP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "част",
        E: "frequent",
        T: "ASM",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "часта",
        E: "frequent",
        T: "ASF",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "часто",
        E: "frequent",
        T: "ASN",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "часты",
        E: "frequent",
        T: "ASP",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чаще",
        E: "frequent",
        T: "AC1",
        A: "-",
        O: "частый",
        RA: 691,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российский",
        E: "Russian",
        T: "ORIGINAL",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российский",
        E: "Russian",
        T: "ANM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российская",
        E: "Russian",
        T: "ANF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российское",
        E: "Russian",
        T: "ANN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российские",
        E: "Russian",
        T: "ANP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российского",
        E: "Russian",
        T: "AGM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российской",
        E: "Russian",
        T: "AGF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российского",
        E: "Russian",
        T: "AGN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российских",
        E: "Russian",
        T: "AGP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российскому",
        E: "Russian",
        T: "ADM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российской",
        E: "Russian",
        T: "ADF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российскому",
        E: "Russian",
        T: "ADN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российским",
        E: "Russian",
        T: "ADP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российского",
        E: "Russian",
        T: "AAM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российскую",
        E: "Russian",
        T: "AAF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российское",
        E: "Russian",
        T: "AAN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российских",
        E: "Russian",
        T: "AAP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российским",
        E: "Russian",
        T: "AIM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российской",
        E: "Russian",
        T: "AIF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российским",
        E: "Russian",
        T: "AIN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российскими",
        E: "Russian",
        T: "AIP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российском",
        E: "Russian",
        T: "APM",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российской",
        E: "Russian",
        T: "APF",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российском",
        E: "Russian",
        T: "APN",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "российских",
        E: "Russian",
        T: "APP",
        A: "-",
        O: "российский",
        RA: 692,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочий",
        E: "working",
        T: "ORIGINAL",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочий",
        E: "working",
        T: "ANM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочая",
        E: "working",
        T: "ANF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочее",
        E: "working",
        T: "ANN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочие",
        E: "working",
        T: "ANP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочего",
        E: "working",
        T: "AGM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочей",
        E: "working",
        T: "AGF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочего",
        E: "working",
        T: "AGN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочих",
        E: "working",
        T: "AGP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочему",
        E: "working",
        T: "ADM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочей",
        E: "working",
        T: "ADF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочему",
        E: "working",
        T: "ADN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочим",
        E: "working",
        T: "ADP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочий",
        E: "working",
        T: "AAM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочую",
        E: "working",
        T: "AAF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочее",
        E: "working",
        T: "AAN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочие",
        E: "working",
        T: "AAP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочим",
        E: "working",
        T: "AIM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочей",
        E: "working",
        T: "AIF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочим",
        E: "working",
        T: "AIN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочими",
        E: "working",
        T: "AIP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочем",
        E: "working",
        T: "APM",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочей",
        E: "working",
        T: "APF",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочем",
        E: "working",
        T: "APN",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "рабочих",
        E: "working",
        T: "APP",
        A: "-",
        O: "рабочий",
        RA: 693,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синий",
        E: "dark blue",
        T: "ORIGINAL",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синий",
        E: "dark blue",
        T: "ANM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синяя",
        E: "dark blue",
        T: "ANF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синее",
        E: "dark blue",
        T: "ANN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синие",
        E: "dark blue",
        T: "ANP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синего",
        E: "dark blue",
        T: "AGM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синей",
        E: "dark blue",
        T: "AGF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синего",
        E: "dark blue",
        T: "AGN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синих",
        E: "dark blue",
        T: "AGP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синему",
        E: "dark blue",
        T: "ADM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синей",
        E: "dark blue",
        T: "ADF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синему",
        E: "dark blue",
        T: "ADN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синим",
        E: "dark blue",
        T: "ADP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синий",
        E: "dark blue",
        T: "AAM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синюю",
        E: "dark blue",
        T: "AAF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синее",
        E: "dark blue",
        T: "AAN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синие",
        E: "dark blue",
        T: "AAP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синим",
        E: "dark blue",
        T: "AIM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синей",
        E: "dark blue",
        T: "AIF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синим",
        E: "dark blue",
        T: "AIN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синими",
        E: "dark blue",
        T: "AIP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синем",
        E: "dark blue",
        T: "APM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синей",
        E: "dark blue",
        T: "APF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синем",
        E: "dark blue",
        T: "APN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синих",
        E: "dark blue",
        T: "APP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синь",
        E: "dark blue",
        T: "ASM",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синя",
        E: "dark blue",
        T: "ASF",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сине",
        E: "dark blue",
        T: "ASN",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сини",
        E: "dark blue",
        T: "ASP",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "синее",
        E: "dark blue",
        T: "AC1",
        A: "-",
        O: "синий",
        RA: 696,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплый",
        E: "warm",
        T: "ORIGINAL",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплый",
        E: "warm",
        T: "ANM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплая",
        E: "warm",
        T: "ANF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплое",
        E: "warm",
        T: "ANN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплые",
        E: "warm",
        T: "ANP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплого",
        E: "warm",
        T: "AGM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплой",
        E: "warm",
        T: "AGF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплого",
        E: "warm",
        T: "AGN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплых",
        E: "warm",
        T: "AGP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплому",
        E: "warm",
        T: "ADM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплой",
        E: "warm",
        T: "ADF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплому",
        E: "warm",
        T: "ADN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплым",
        E: "warm",
        T: "ADP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплого",
        E: "warm",
        T: "AAM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплую",
        E: "warm",
        T: "AAF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплое",
        E: "warm",
        T: "AAN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплых",
        E: "warm",
        T: "AAP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплым",
        E: "warm",
        T: "AIM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплой",
        E: "warm",
        T: "AIF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплым",
        E: "warm",
        T: "AIN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплыми",
        E: "warm",
        T: "AIP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплом",
        E: "warm",
        T: "APM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплой",
        E: "warm",
        T: "APF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплом",
        E: "warm",
        T: "APN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёплых",
        E: "warm",
        T: "APP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тёпел",
        E: "warm",
        T: "ASM",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тепла",
        E: "warm",
        T: "ASF",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тепло",
        E: "warm",
        T: "ASN",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "теплы",
        E: "warm",
        T: "ASP",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "теплее",
        E: "warm",
        T: "AC1",
        A: "-",
        O: "тёплый",
        RA: 698,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железный",
        E: "ferreous, iron",
        T: "ORIGINAL",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железный",
        E: "ferreous, iron",
        T: "ANM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железная",
        E: "ferreous, iron",
        T: "ANF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железное",
        E: "ferreous, iron",
        T: "ANN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железные",
        E: "ferreous, iron",
        T: "ANP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железного",
        E: "ferreous, iron",
        T: "AGM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железной",
        E: "ferreous, iron",
        T: "AGF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железного",
        E: "ferreous, iron",
        T: "AGN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железных",
        E: "ferreous, iron",
        T: "AGP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железному",
        E: "ferreous, iron",
        T: "ADM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железной",
        E: "ferreous, iron",
        T: "ADF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железному",
        E: "ferreous, iron",
        T: "ADN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железным",
        E: "ferreous, iron",
        T: "ADP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железный",
        E: "ferreous, iron",
        T: "AAM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железную",
        E: "ferreous, iron",
        T: "AAF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железное",
        E: "ferreous, iron",
        T: "AAN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железные",
        E: "ferreous, iron",
        T: "AAP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железным",
        E: "ferreous, iron",
        T: "AIM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железной",
        E: "ferreous, iron",
        T: "AIF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железным",
        E: "ferreous, iron",
        T: "AIN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железными",
        E: "ferreous, iron",
        T: "AIP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железном",
        E: "ferreous, iron",
        T: "APM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железной",
        E: "ferreous, iron",
        T: "APF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железном",
        E: "ferreous, iron",
        T: "APN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железных",
        E: "ferreous, iron",
        T: "APP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железен",
        E: "ferreous, iron",
        T: "ASM",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железна",
        E: "ferreous, iron",
        T: "ASF",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железно",
        E: "ferreous, iron",
        T: "ASN",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "железны",
        E: "ferreous, iron",
        T: "ASP",
        A: "-",
        O: "железный",
        RA: 701,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычный",
        E: "usual, ordinary",
        T: "ORIGINAL",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычный",
        E: "usual, ordinary",
        T: "ANM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычная",
        E: "usual, ordinary",
        T: "ANF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычное",
        E: "usual, ordinary",
        T: "ANN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычные",
        E: "usual, ordinary",
        T: "ANP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычного",
        E: "usual, ordinary",
        T: "AGM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычной",
        E: "usual, ordinary",
        T: "AGF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычного",
        E: "usual, ordinary",
        T: "AGN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычных",
        E: "usual, ordinary",
        T: "AGP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычному",
        E: "usual, ordinary",
        T: "ADM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычной",
        E: "usual, ordinary",
        T: "ADF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычному",
        E: "usual, ordinary",
        T: "ADN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычным",
        E: "usual, ordinary",
        T: "ADP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычный",
        E: "usual, ordinary",
        T: "AAM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычную",
        E: "usual, ordinary",
        T: "AAF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычное",
        E: "usual, ordinary",
        T: "AAN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычные",
        E: "usual, ordinary",
        T: "AAP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычным",
        E: "usual, ordinary",
        T: "AIM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычной",
        E: "usual, ordinary",
        T: "AIF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычным",
        E: "usual, ordinary",
        T: "AIN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычными",
        E: "usual, ordinary",
        T: "AIP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычном",
        E: "usual, ordinary",
        T: "APM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычной",
        E: "usual, ordinary",
        T: "APF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычном",
        E: "usual, ordinary",
        T: "APN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычных",
        E: "usual, ordinary",
        T: "APP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычен",
        E: "usual, ordinary",
        T: "ASM",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычна",
        E: "usual, ordinary",
        T: "ASF",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычно",
        E: "usual, ordinary",
        T: "ASN",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычны",
        E: "usual, ordinary",
        T: "ASP",
        A: "-",
        O: "обычный",
        RA: 705,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "ORIGINAL",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "ANM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальная",
        E: "remaining, the rest of",
        T: "ANF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальное",
        E: "remaining, the rest of",
        T: "ANN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальные",
        E: "remaining, the rest of",
        T: "ANP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остального",
        E: "remaining, the rest of",
        T: "AGM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "AGF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остального",
        E: "remaining, the rest of",
        T: "AGN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальных",
        E: "remaining, the rest of",
        T: "AGP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальному",
        E: "remaining, the rest of",
        T: "ADM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "ADF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальному",
        E: "remaining, the rest of",
        T: "ADN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальным",
        E: "remaining, the rest of",
        T: "ADP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "AAM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальную",
        E: "remaining, the rest of",
        T: "AAF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальное",
        E: "remaining, the rest of",
        T: "AAN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальные",
        E: "remaining, the rest of",
        T: "AAP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальным",
        E: "remaining, the rest of",
        T: "AIM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "AIF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальным",
        E: "remaining, the rest of",
        T: "AIN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальными",
        E: "remaining, the rest of",
        T: "AIP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальном",
        E: "remaining, the rest of",
        T: "APM",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальной",
        E: "remaining, the rest of",
        T: "APF",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальном",
        E: "remaining, the rest of",
        T: "APN",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остальных",
        E: "remaining, the rest of",
        T: "APP",
        A: "-",
        O: "остальной",
        RA: 709,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московский",
        E: "Moscow",
        T: "ORIGINAL",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московский",
        E: "Moscow",
        T: "ANM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московская",
        E: "Moscow",
        T: "ANF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московское",
        E: "Moscow",
        T: "ANN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московские",
        E: "Moscow",
        T: "ANP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московского",
        E: "Moscow",
        T: "AGM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московской",
        E: "Moscow",
        T: "AGF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московского",
        E: "Moscow",
        T: "AGN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московских",
        E: "Moscow",
        T: "AGP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московскому",
        E: "Moscow",
        T: "ADM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московской",
        E: "Moscow",
        T: "ADF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московскому",
        E: "Moscow",
        T: "ADN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московским",
        E: "Moscow",
        T: "ADP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московский",
        E: "Moscow",
        T: "AAM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московскую",
        E: "Moscow",
        T: "AAF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московское",
        E: "Moscow",
        T: "AAN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московские",
        E: "Moscow",
        T: "AAP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московским",
        E: "Moscow",
        T: "AIM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московской",
        E: "Moscow",
        T: "AIF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московским",
        E: "Moscow",
        T: "AIN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московскими",
        E: "Moscow",
        T: "AIP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московском",
        E: "Moscow",
        T: "APM",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московской",
        E: "Moscow",
        T: "APF",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московском",
        E: "Moscow",
        T: "APN",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "московских",
        E: "Moscow",
        T: "APP",
        A: "-",
        O: "московский",
        RA: 711,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важный",
        E: "important",
        T: "ORIGINAL",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важный",
        E: "important",
        T: "ANM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важная",
        E: "important",
        T: "ANF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важное",
        E: "important",
        T: "ANN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важные",
        E: "important",
        T: "ANP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важного",
        E: "important",
        T: "AGM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важной",
        E: "important",
        T: "AGF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важного",
        E: "important",
        T: "AGN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важных",
        E: "important",
        T: "AGP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важному",
        E: "important",
        T: "ADM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важной",
        E: "important",
        T: "ADF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важному",
        E: "important",
        T: "ADN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важным",
        E: "important",
        T: "ADP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важный",
        E: "important",
        T: "AAM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важную",
        E: "important",
        T: "AAF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важное",
        E: "important",
        T: "AAN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важные",
        E: "important",
        T: "AAP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важным",
        E: "important",
        T: "AIM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важной",
        E: "important",
        T: "AIF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важным",
        E: "important",
        T: "AIN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важными",
        E: "important",
        T: "AIP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важном",
        E: "important",
        T: "APM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важной",
        E: "important",
        T: "APF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важном",
        E: "important",
        T: "APN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важных",
        E: "important",
        T: "APP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важен",
        E: "important",
        T: "ASM",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важна",
        E: "important",
        T: "ASF",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важно",
        E: "important",
        T: "ASN",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важны важны",
        E: "important",
        T: "ASP",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "важнее",
        E: "important",
        T: "AC1",
        A: "-",
        O: "важный",
        RA: 722,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздний",
        E: "late",
        T: "ORIGINAL",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздний",
        E: "late",
        T: "ANM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздняя",
        E: "late",
        T: "ANF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднее",
        E: "late",
        T: "ANN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздние",
        E: "late",
        T: "ANP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднего",
        E: "late",
        T: "AGM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздней",
        E: "late",
        T: "AGF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднего",
        E: "late",
        T: "AGN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздних",
        E: "late",
        T: "AGP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднему",
        E: "late",
        T: "ADM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздней",
        E: "late",
        T: "ADF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднему",
        E: "late",
        T: "ADN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздним",
        E: "late",
        T: "ADP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздний",
        E: "late",
        T: "AAM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднюю",
        E: "late",
        T: "AAF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднее",
        E: "late",
        T: "AAN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздние",
        E: "late",
        T: "AAP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздним",
        E: "late",
        T: "AIM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздней",
        E: "late",
        T: "AIF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздним",
        E: "late",
        T: "AIN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздними",
        E: "late",
        T: "AIP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднем",
        E: "late",
        T: "APM",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздней",
        E: "late",
        T: "APF",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднем",
        E: "late",
        T: "APN",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поздних",
        E: "late",
        T: "APP",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позднее",
        E: "late",
        T: "AC1",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "позже",
        E: "late",
        T: "AC2",
        A: "-",
        O: "поздний",
        RA: 730,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкий",
        E: "thin",
        T: "ORIGINAL",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкий",
        E: "thin",
        T: "ANM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкая",
        E: "thin",
        T: "ANF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкое",
        E: "thin",
        T: "ANN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкие",
        E: "thin",
        T: "ANP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкого",
        E: "thin",
        T: "AGM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкой",
        E: "thin",
        T: "AGF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкого",
        E: "thin",
        T: "AGN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонких",
        E: "thin",
        T: "AGP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкому",
        E: "thin",
        T: "ADM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкой",
        E: "thin",
        T: "ADF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкому",
        E: "thin",
        T: "ADN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонким",
        E: "thin",
        T: "ADP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкий",
        E: "thin",
        T: "AAM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкую",
        E: "thin",
        T: "AAF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкое",
        E: "thin",
        T: "AAN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкие",
        E: "thin",
        T: "AAP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонким",
        E: "thin",
        T: "AIM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкой",
        E: "thin",
        T: "AIF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонким",
        E: "thin",
        T: "AIN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкими",
        E: "thin",
        T: "AIP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонком",
        E: "thin",
        T: "APM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонкой",
        E: "thin",
        T: "APF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонком",
        E: "thin",
        T: "APN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонких",
        E: "thin",
        T: "APP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонок",
        E: "thin",
        T: "ASM",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонка",
        E: "thin",
        T: "ASF",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонко",
        E: "thin",
        T: "ASN",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тонки",
        E: "thin",
        T: "ASP",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тоньше",
        E: "thin",
        T: "AC1",
        A: "-",
        O: "тонкий",
        RA: 734,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкий",
        E: "German",
        T: "ORIGINAL",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкий",
        E: "German",
        T: "ANM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкая",
        E: "German",
        T: "ANF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкое",
        E: "German",
        T: "ANN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкие",
        E: "German",
        T: "ANP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкого",
        E: "German",
        T: "AGM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкой",
        E: "German",
        T: "AGF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкого",
        E: "German",
        T: "AGN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецких",
        E: "German",
        T: "AGP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкому",
        E: "German",
        T: "ADM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкой",
        E: "German",
        T: "ADF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкому",
        E: "German",
        T: "ADN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецким",
        E: "German",
        T: "ADP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкий",
        E: "German",
        T: "AAM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкую",
        E: "German",
        T: "AAF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкое",
        E: "German",
        T: "AAN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкие",
        E: "German",
        T: "AAP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецким",
        E: "German",
        T: "AIM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкой",
        E: "German",
        T: "AIF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецким",
        E: "German",
        T: "AIN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкими",
        E: "German",
        T: "AIP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецком",
        E: "German",
        T: "APM",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецкой",
        E: "German",
        T: "APF",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецком",
        E: "German",
        T: "APN",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немецких",
        E: "German",
        T: "APP",
        A: "-",
        O: "немецкий",
        RA: 735,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасный",
        E: "beautiful, fine",
        T: "ORIGINAL",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасный",
        E: "beautiful, fine",
        T: "ANM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасная",
        E: "beautiful, fine",
        T: "ANF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасное",
        E: "beautiful, fine",
        T: "ANN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасные",
        E: "beautiful, fine",
        T: "ANP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасного",
        E: "beautiful, fine",
        T: "AGM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасной",
        E: "beautiful, fine",
        T: "AGF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасного",
        E: "beautiful, fine",
        T: "AGN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасных",
        E: "beautiful, fine",
        T: "AGP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасному",
        E: "beautiful, fine",
        T: "ADM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасной",
        E: "beautiful, fine",
        T: "ADF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасному",
        E: "beautiful, fine",
        T: "ADN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасным",
        E: "beautiful, fine",
        T: "ADP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасный",
        E: "beautiful, fine",
        T: "AAM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасную",
        E: "beautiful, fine",
        T: "AAF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасное",
        E: "beautiful, fine",
        T: "AAN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасные",
        E: "beautiful, fine",
        T: "AAP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасным",
        E: "beautiful, fine",
        T: "AIM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасной",
        E: "beautiful, fine",
        T: "AIF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасным",
        E: "beautiful, fine",
        T: "AIN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасными",
        E: "beautiful, fine",
        T: "AIP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасном",
        E: "beautiful, fine",
        T: "APM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасной",
        E: "beautiful, fine",
        T: "APF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасном",
        E: "beautiful, fine",
        T: "APN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасных",
        E: "beautiful, fine",
        T: "APP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасен",
        E: "beautiful, fine",
        T: "ASM",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасна",
        E: "beautiful, fine",
        T: "ASF",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасно",
        E: "beautiful, fine",
        T: "ASN",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекрасны",
        E: "beautiful, fine",
        T: "ASP",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прекраснее",
        E: "beautiful, fine",
        T: "AC1",
        A: "-",
        O: "прекрасный",
        RA: 749,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верный",
        E: "correct",
        T: "ORIGINAL",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верный",
        E: "correct",
        T: "ANM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верная",
        E: "correct",
        T: "ANF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верное",
        E: "correct",
        T: "ANN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верные",
        E: "correct",
        T: "ANP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верного",
        E: "correct",
        T: "AGM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верной",
        E: "correct",
        T: "AGF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верного",
        E: "correct",
        T: "AGN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верных",
        E: "correct",
        T: "AGP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верному",
        E: "correct",
        T: "ADM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верной",
        E: "correct",
        T: "ADF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верному",
        E: "correct",
        T: "ADN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верным",
        E: "correct",
        T: "ADP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верный",
        E: "correct",
        T: "AAM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верную",
        E: "correct",
        T: "AAF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верное",
        E: "correct",
        T: "AAN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верные",
        E: "correct",
        T: "AAP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верным",
        E: "correct",
        T: "AIM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верной",
        E: "correct",
        T: "AIF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верным",
        E: "correct",
        T: "AIN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верными",
        E: "correct",
        T: "AIP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верном",
        E: "correct",
        T: "APM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верной",
        E: "correct",
        T: "APF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верном",
        E: "correct",
        T: "APN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верных",
        E: "correct",
        T: "APP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верен",
        E: "correct",
        T: "ASM",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верна",
        E: "correct",
        T: "ASF",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верно",
        E: "correct",
        T: "ASN",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "верны верны",
        E: "correct",
        T: "ASP",
        A: "-",
        O: "верный",
        RA: 754,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовый",
        E: "ready",
        T: "ORIGINAL",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовый",
        E: "ready",
        T: "ANM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовая",
        E: "ready",
        T: "ANF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовое",
        E: "ready",
        T: "ANN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовые",
        E: "ready",
        T: "ANP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готового",
        E: "ready",
        T: "AGM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовой",
        E: "ready",
        T: "AGF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готового",
        E: "ready",
        T: "AGN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовых",
        E: "ready",
        T: "AGP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовому",
        E: "ready",
        T: "ADM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовой",
        E: "ready",
        T: "ADF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовому",
        E: "ready",
        T: "ADN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовым",
        E: "ready",
        T: "ADP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовый",
        E: "ready",
        T: "AAM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовую",
        E: "ready",
        T: "AAF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовое",
        E: "ready",
        T: "AAN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовые",
        E: "ready",
        T: "AAP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовым",
        E: "ready",
        T: "AIM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовой",
        E: "ready",
        T: "AIF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовым",
        E: "ready",
        T: "AIN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовыми",
        E: "ready",
        T: "AIP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовом",
        E: "ready",
        T: "APM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовой",
        E: "ready",
        T: "APF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовом",
        E: "ready",
        T: "APN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовых",
        E: "ready",
        T: "APP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готов",
        E: "ready",
        T: "ASM",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готова",
        E: "ready",
        T: "ASF",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готово",
        E: "ready",
        T: "ASN",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "готовы",
        E: "ready",
        T: "ASP",
        A: "-",
        O: "готовый",
        RA: 757,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политический",
        E: "political",
        T: "ORIGINAL",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политический",
        E: "political",
        T: "ANM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическая",
        E: "political",
        T: "ANF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическое",
        E: "political",
        T: "ANN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политические",
        E: "political",
        T: "ANP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политического",
        E: "political",
        T: "AGM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политической",
        E: "political",
        T: "AGF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политического",
        E: "political",
        T: "AGN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политических",
        E: "political",
        T: "AGP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическому",
        E: "political",
        T: "ADM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политической",
        E: "political",
        T: "ADF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическому",
        E: "political",
        T: "ADN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическим",
        E: "political",
        T: "ADP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политический",
        E: "political",
        T: "AAM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическую",
        E: "political",
        T: "AAF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическое",
        E: "political",
        T: "AAN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политические",
        E: "political",
        T: "AAP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическим",
        E: "political",
        T: "AIM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политической",
        E: "political",
        T: "AIF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическим",
        E: "political",
        T: "AIN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическими",
        E: "political",
        T: "AIP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическом",
        E: "political",
        T: "APM",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политической",
        E: "political",
        T: "APF",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политическом",
        E: "political",
        T: "APN",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "политических",
        E: "political",
        T: "APP",
        A: "-",
        O: "политический",
        RA: 765,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестный",
        E: "unknown",
        T: "ORIGINAL",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестный",
        E: "unknown",
        T: "ANM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестная",
        E: "unknown",
        T: "ANF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестное",
        E: "unknown",
        T: "ANN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестные",
        E: "unknown",
        T: "ANP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестного",
        E: "unknown",
        T: "AGM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестной",
        E: "unknown",
        T: "AGF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестного",
        E: "unknown",
        T: "AGN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестных",
        E: "unknown",
        T: "AGP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестному",
        E: "unknown",
        T: "ADM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестной",
        E: "unknown",
        T: "ADF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестному",
        E: "unknown",
        T: "ADN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестным",
        E: "unknown",
        T: "ADP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестный",
        E: "unknown",
        T: "AAM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестную",
        E: "unknown",
        T: "AAF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестное",
        E: "unknown",
        T: "AAN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестные",
        E: "unknown",
        T: "AAP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестным",
        E: "unknown",
        T: "AIM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестной",
        E: "unknown",
        T: "AIF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестным",
        E: "unknown",
        T: "AIN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестными",
        E: "unknown",
        T: "AIP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестном",
        E: "unknown",
        T: "APM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестной",
        E: "unknown",
        T: "APF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестном",
        E: "unknown",
        T: "APN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестных",
        E: "unknown",
        T: "APP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестен",
        E: "unknown",
        T: "ASM",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестна",
        E: "unknown",
        T: "ASF",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестно",
        E: "unknown",
        T: "ASN",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неизвестны",
        E: "unknown",
        T: "ASP",
        A: "-",
        O: "неизвестный",
        RA: 779,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихий",
        E: "quiet, low, scelent",
        T: "ORIGINAL",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихий",
        E: "quiet, low, scelent",
        T: "ANM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихая",
        E: "quiet, low, scelent",
        T: "ANF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихое",
        E: "quiet, low, scelent",
        T: "ANN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихие",
        E: "quiet, low, scelent",
        T: "ANP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихого",
        E: "quiet, low, scelent",
        T: "AGM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихой",
        E: "quiet, low, scelent",
        T: "AGF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихого",
        E: "quiet, low, scelent",
        T: "AGN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихих",
        E: "quiet, low, scelent",
        T: "AGP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихому",
        E: "quiet, low, scelent",
        T: "ADM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихой",
        E: "quiet, low, scelent",
        T: "ADF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихому",
        E: "quiet, low, scelent",
        T: "ADN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихим",
        E: "quiet, low, scelent",
        T: "ADP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихий",
        E: "quiet, low, scelent",
        T: "AAM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихую",
        E: "quiet, low, scelent",
        T: "AAF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихое",
        E: "quiet, low, scelent",
        T: "AAN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихие",
        E: "quiet, low, scelent",
        T: "AAP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихим",
        E: "quiet, low, scelent",
        T: "AIM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихой",
        E: "quiet, low, scelent",
        T: "AIF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихим",
        E: "quiet, low, scelent",
        T: "AIN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихими",
        E: "quiet, low, scelent",
        T: "AIP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихом",
        E: "quiet, low, scelent",
        T: "APM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихой",
        E: "quiet, low, scelent",
        T: "APF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихом",
        E: "quiet, low, scelent",
        T: "APN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихих",
        E: "quiet, low, scelent",
        T: "APP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тих",
        E: "quiet, low, scelent",
        T: "ASM",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тиха",
        E: "quiet, low, scelent",
        T: "ASF",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихо",
        E: "quiet, low, scelent",
        T: "ASN",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихи тихи",
        E: "quiet, low, scelent",
        T: "ASP",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тише",
        E: "quiet, low, scelent",
        T: "AC1",
        A: "-",
        O: "тихий",
        RA: 786,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренний",
        E: "inside, internal",
        T: "ORIGINAL",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренний",
        E: "inside, internal",
        T: "ANM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренняя",
        E: "inside, internal",
        T: "ANF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннее",
        E: "inside, internal",
        T: "ANN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренние",
        E: "inside, internal",
        T: "ANP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннего",
        E: "inside, internal",
        T: "AGM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренней",
        E: "inside, internal",
        T: "AGF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннего",
        E: "inside, internal",
        T: "AGN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренних",
        E: "inside, internal",
        T: "AGP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннему",
        E: "inside, internal",
        T: "ADM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренней",
        E: "inside, internal",
        T: "ADF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннему",
        E: "inside, internal",
        T: "ADN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренним",
        E: "inside, internal",
        T: "ADP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренний",
        E: "inside, internal",
        T: "AAM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннюю",
        E: "inside, internal",
        T: "AAF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннее",
        E: "inside, internal",
        T: "AAN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренние",
        E: "inside, internal",
        T: "AAP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренним",
        E: "inside, internal",
        T: "AIM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренней",
        E: "inside, internal",
        T: "AIF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренним",
        E: "inside, internal",
        T: "AIN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренними",
        E: "inside, internal",
        T: "AIP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннем",
        E: "inside, internal",
        T: "APM",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренней",
        E: "inside, internal",
        T: "APF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутреннем",
        E: "inside, internal",
        T: "APN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренних",
        E: "inside, internal",
        T: "APP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутрення",
        E: "inside, internal",
        T: "ASF",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренне",
        E: "inside, internal",
        T: "ASN",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "внутренни",
        E: "inside, internal",
        T: "ASP",
        A: "-",
        O: "внутренний",
        RA: 792,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственный",
        E: "State",
        T: "ORIGINAL",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственный",
        E: "State",
        T: "ANM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственная",
        E: "State",
        T: "ANF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственное",
        E: "State",
        T: "ANN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственные",
        E: "State",
        T: "ANP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственного",
        E: "State",
        T: "AGM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственной",
        E: "State",
        T: "AGF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственного",
        E: "State",
        T: "AGN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственных",
        E: "State",
        T: "AGP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственному",
        E: "State",
        T: "ADM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственной",
        E: "State",
        T: "ADF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственному",
        E: "State",
        T: "ADN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственным",
        E: "State",
        T: "ADP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственный",
        E: "State",
        T: "AAM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственную",
        E: "State",
        T: "AAF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственное",
        E: "State",
        T: "AAN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственные",
        E: "State",
        T: "AAP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственным",
        E: "State",
        T: "AIM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственной",
        E: "State",
        T: "AIF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственным",
        E: "State",
        T: "AIN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственными",
        E: "State",
        T: "AIP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственном",
        E: "State",
        T: "APM",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственной",
        E: "State",
        T: "APF",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственном",
        E: "State",
        T: "APN",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "государственных",
        E: "State",
        T: "APP",
        A: "-",
        O: "государственный",
        RA: 798,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокий",
        E: "deep",
        T: "ORIGINAL",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокий",
        E: "deep",
        T: "ANM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокая",
        E: "deep",
        T: "ANF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокое",
        E: "deep",
        T: "ANN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокие",
        E: "deep",
        T: "ANP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокого",
        E: "deep",
        T: "AGM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокой",
        E: "deep",
        T: "AGF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокого",
        E: "deep",
        T: "AGN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоких",
        E: "deep",
        T: "AGP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокому",
        E: "deep",
        T: "ADM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокой",
        E: "deep",
        T: "ADF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокому",
        E: "deep",
        T: "ADN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоким",
        E: "deep",
        T: "ADP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокий",
        E: "deep",
        T: "AAM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокую",
        E: "deep",
        T: "AAF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокое",
        E: "deep",
        T: "AAN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокие",
        E: "deep",
        T: "AAP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоким",
        E: "deep",
        T: "AIM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокой",
        E: "deep",
        T: "AIF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоким",
        E: "deep",
        T: "AIN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокими",
        E: "deep",
        T: "AIP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоком",
        E: "deep",
        T: "APM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубокой",
        E: "deep",
        T: "APF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоком",
        E: "deep",
        T: "APN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоких",
        E: "deep",
        T: "APP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубок",
        E: "deep",
        T: "ASM",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубока",
        E: "deep",
        T: "ASF",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоко глубоко",
        E: "deep",
        T: "ASN",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубоки глубоки",
        E: "deep",
        T: "ASP",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "глубже",
        E: "deep",
        T: "AC1",
        A: "-",
        O: "глубокий",
        RA: 800,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "ORIGINAL",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "ANM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубая",
        E: "blue, sky-blue",
        T: "ANF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубое",
        E: "blue, sky-blue",
        T: "ANN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубые",
        E: "blue, sky-blue",
        T: "ANP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубого",
        E: "blue, sky-blue",
        T: "AGM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "AGF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубого",
        E: "blue, sky-blue",
        T: "AGN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубых",
        E: "blue, sky-blue",
        T: "AGP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубому",
        E: "blue, sky-blue",
        T: "ADM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "ADF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубому",
        E: "blue, sky-blue",
        T: "ADN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубым",
        E: "blue, sky-blue",
        T: "ADP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "AAM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубую",
        E: "blue, sky-blue",
        T: "AAF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубое",
        E: "blue, sky-blue",
        T: "AAN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубые",
        E: "blue, sky-blue",
        T: "AAP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубым",
        E: "blue, sky-blue",
        T: "AIM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "AIF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубым",
        E: "blue, sky-blue",
        T: "AIN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубыми",
        E: "blue, sky-blue",
        T: "AIP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубом",
        E: "blue, sky-blue",
        T: "APM",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубой",
        E: "blue, sky-blue",
        T: "APF",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубом",
        E: "blue, sky-blue",
        T: "APN",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубых",
        E: "blue, sky-blue",
        T: "APP",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "голубее",
        E: "blue, sky-blue",
        T: "AC1",
        A: "-",
        O: "голубой",
        RA: 817,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгий",
        E: "long",
        T: "ORIGINAL",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгий",
        E: "long",
        T: "ANM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгая",
        E: "long",
        T: "ANF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгое",
        E: "long",
        T: "ANN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгие",
        E: "long",
        T: "ANP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгого",
        E: "long",
        T: "AGM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгой",
        E: "long",
        T: "AGF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгого",
        E: "long",
        T: "AGN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгих",
        E: "long",
        T: "AGP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгому",
        E: "long",
        T: "ADM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгой",
        E: "long",
        T: "ADF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгому",
        E: "long",
        T: "ADN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгим",
        E: "long",
        T: "ADP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгий",
        E: "long",
        T: "AAM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгую",
        E: "long",
        T: "AAF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгое",
        E: "long",
        T: "AAN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгие",
        E: "long",
        T: "AAP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгим",
        E: "long",
        T: "AIM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгой",
        E: "long",
        T: "AIF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгим",
        E: "long",
        T: "AIN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгими",
        E: "long",
        T: "AIP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгом",
        E: "long",
        T: "APM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгой",
        E: "long",
        T: "APF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгом",
        E: "long",
        T: "APN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долгих",
        E: "long",
        T: "APP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долог",
        E: "long",
        T: "ASM",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долга",
        E: "long",
        T: "ASF",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долго",
        E: "long",
        T: "ASN",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долги",
        E: "long",
        T: "ASP",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дольше",
        E: "long",
        T: "AC1",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долее",
        E: "long",
        T: "AC2",
        A: "-",
        O: "долгий",
        RA: 823,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чей",
        E: "whose",
        T: "ORIGINAL",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чей",
        E: "whose",
        T: "ANM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чья",
        E: "whose",
        T: "ANF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьё",
        E: "whose",
        T: "ANN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьи",
        E: "whose",
        T: "ANP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьего",
        E: "whose",
        T: "AGM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьей",
        E: "whose",
        T: "AGF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьего",
        E: "whose",
        T: "AGN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьих",
        E: "whose",
        T: "AGP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьему",
        E: "whose",
        T: "ADM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьей",
        E: "whose",
        T: "ADF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьему",
        E: "whose",
        T: "ADN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьим",
        E: "whose",
        T: "ADP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чей",
        E: "whose",
        T: "AAM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чью",
        E: "whose",
        T: "AAF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьё",
        E: "whose",
        T: "AAN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьи",
        E: "whose",
        T: "AAP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьим",
        E: "whose",
        T: "AIM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьей",
        E: "whose",
        T: "AIF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьим",
        E: "whose",
        T: "AIN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьими",
        E: "whose",
        T: "AIP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьём",
        E: "whose",
        T: "APM",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьей",
        E: "whose",
        T: "APF",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьём",
        E: "whose",
        T: "APN",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чьих",
        E: "whose",
        T: "APP",
        A: "-",
        O: "чей",
        RA: 824,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабый",
        E: "weak",
        T: "ORIGINAL",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабый",
        E: "weak",
        T: "ANM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабая",
        E: "weak",
        T: "ANF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабое",
        E: "weak",
        T: "ANN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабые",
        E: "weak",
        T: "ANP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабого",
        E: "weak",
        T: "AGM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабой",
        E: "weak",
        T: "AGF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабого",
        E: "weak",
        T: "AGN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабых",
        E: "weak",
        T: "AGP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабому",
        E: "weak",
        T: "ADM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабой",
        E: "weak",
        T: "ADF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабому",
        E: "weak",
        T: "ADN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабым",
        E: "weak",
        T: "ADP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабый",
        E: "weak",
        T: "AAM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабую",
        E: "weak",
        T: "AAF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабое",
        E: "weak",
        T: "AAN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабые",
        E: "weak",
        T: "AAP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабым",
        E: "weak",
        T: "AIM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабой",
        E: "weak",
        T: "AIF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабым",
        E: "weak",
        T: "AIN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабыми",
        E: "weak",
        T: "AIP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабом",
        E: "weak",
        T: "APM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабой",
        E: "weak",
        T: "APF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабом",
        E: "weak",
        T: "APN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабых",
        E: "weak",
        T: "APP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слаб",
        E: "weak",
        T: "ASM",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слаба",
        E: "weak",
        T: "ASF",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабо",
        E: "weak",
        T: "ASN",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабы",
        E: "weak",
        T: "ASP",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слабее",
        E: "weak",
        T: "AC1",
        A: "-",
        O: "слабый",
        RA: 828,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлый",
        E: "alien, strange",
        T: "ORIGINAL",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлый",
        E: "alien, strange",
        T: "ANM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлая",
        E: "alien, strange",
        T: "ANF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлое",
        E: "alien, strange",
        T: "ANN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлые",
        E: "alien, strange",
        T: "ANP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлого",
        E: "alien, strange",
        T: "AGM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлой",
        E: "alien, strange",
        T: "AGF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлого",
        E: "alien, strange",
        T: "AGN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлых",
        E: "alien, strange",
        T: "AGP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлому",
        E: "alien, strange",
        T: "ADM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлой",
        E: "alien, strange",
        T: "ADF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлому",
        E: "alien, strange",
        T: "ADN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлым",
        E: "alien, strange",
        T: "ADP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлый",
        E: "alien, strange",
        T: "AAM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлую",
        E: "alien, strange",
        T: "AAF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлое",
        E: "alien, strange",
        T: "AAN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлые",
        E: "alien, strange",
        T: "AAP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлым",
        E: "alien, strange",
        T: "AIM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлой",
        E: "alien, strange",
        T: "AIF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлым",
        E: "alien, strange",
        T: "AIN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлыми",
        E: "alien, strange",
        T: "AIP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлом",
        E: "alien, strange",
        T: "APM",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлой",
        E: "alien, strange",
        T: "APF",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлом",
        E: "alien, strange",
        T: "APN",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пришлых",
        E: "alien, strange",
        T: "APP",
        A: "-",
        O: "пришлый",
        RA: 839,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзный",
        E: "serious",
        T: "ORIGINAL",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзный",
        E: "serious",
        T: "ANM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзная",
        E: "serious",
        T: "ANF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзное",
        E: "serious",
        T: "ANN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзные",
        E: "serious",
        T: "ANP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзного",
        E: "serious",
        T: "AGM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзной",
        E: "serious",
        T: "AGF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзного",
        E: "serious",
        T: "AGN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзных",
        E: "serious",
        T: "AGP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзному",
        E: "serious",
        T: "ADM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзной",
        E: "serious",
        T: "ADF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзному",
        E: "serious",
        T: "ADN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзным",
        E: "serious",
        T: "ADP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзный",
        E: "serious",
        T: "AAM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзную",
        E: "serious",
        T: "AAF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзное",
        E: "serious",
        T: "AAN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзные",
        E: "serious",
        T: "AAP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзным",
        E: "serious",
        T: "AIM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзной",
        E: "serious",
        T: "AIF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзным",
        E: "serious",
        T: "AIN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзными",
        E: "serious",
        T: "AIP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзном",
        E: "serious",
        T: "APM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзной",
        E: "serious",
        T: "APF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзном",
        E: "serious",
        T: "APN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзных",
        E: "serious",
        T: "APP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзен",
        E: "serious",
        T: "ASM",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзна",
        E: "serious",
        T: "ASF",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзно",
        E: "serious",
        T: "ASN",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёзны",
        E: "serious",
        T: "ASP",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "серьёнее серьёзней",
        E: "serious",
        T: "AC1",
        A: "-",
        O: "серьёзный",
        RA: 843,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрый",
        E: "quick, fast",
        T: "ORIGINAL",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрый",
        E: "quick, fast",
        T: "ANM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрая",
        E: "quick, fast",
        T: "ANF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрое",
        E: "quick, fast",
        T: "ANN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрые",
        E: "quick, fast",
        T: "ANP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрого",
        E: "quick, fast",
        T: "AGM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрой",
        E: "quick, fast",
        T: "AGF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрого",
        E: "quick, fast",
        T: "AGN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрых",
        E: "quick, fast",
        T: "AGP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрому",
        E: "quick, fast",
        T: "ADM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрой",
        E: "quick, fast",
        T: "ADF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрому",
        E: "quick, fast",
        T: "ADN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрым",
        E: "quick, fast",
        T: "ADP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрый",
        E: "quick, fast",
        T: "AAM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быструю",
        E: "quick, fast",
        T: "AAF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрое",
        E: "quick, fast",
        T: "AAN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрые",
        E: "quick, fast",
        T: "AAP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрым",
        E: "quick, fast",
        T: "AIM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрой",
        E: "quick, fast",
        T: "AIF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрым",
        E: "quick, fast",
        T: "AIN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрыми",
        E: "quick, fast",
        T: "AIP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстром",
        E: "quick, fast",
        T: "APM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрой",
        E: "quick, fast",
        T: "APF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстром",
        E: "quick, fast",
        T: "APN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрых",
        E: "quick, fast",
        T: "APP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстр",
        E: "quick, fast",
        T: "ASM",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстра",
        E: "quick, fast",
        T: "ASF",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстро",
        E: "quick, fast",
        T: "ASN",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстры быстры",
        E: "quick, fast",
        T: "ASP",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстрее",
        E: "quick, fast",
        T: "AC1",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "побыстрее",
        E: "quick, fast",
        T: "AC2",
        A: "-",
        O: "быстрый",
        RA: 852,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левый",
        E: "left",
        T: "ORIGINAL",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левый",
        E: "left",
        T: "ANM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левая",
        E: "left",
        T: "ANF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левое",
        E: "left",
        T: "ANN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левые",
        E: "left",
        T: "ANP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левого",
        E: "left",
        T: "AGM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левой",
        E: "left",
        T: "AGF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левого",
        E: "left",
        T: "AGN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левых",
        E: "left",
        T: "AGP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левому",
        E: "left",
        T: "ADM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левой",
        E: "left",
        T: "ADF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левому",
        E: "left",
        T: "ADN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левым",
        E: "left",
        T: "ADP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левого",
        E: "left",
        T: "AAM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левую",
        E: "left",
        T: "AAF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левое",
        E: "left",
        T: "AAN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левых",
        E: "left",
        T: "AAP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левым",
        E: "left",
        T: "AIM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левой",
        E: "left",
        T: "AIF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левым",
        E: "left",
        T: "AIN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левыми",
        E: "left",
        T: "AIP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левом",
        E: "left",
        T: "APM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левой",
        E: "left",
        T: "APF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левом",
        E: "left",
        T: "APN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левых",
        E: "left",
        T: "APP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лев",
        E: "left",
        T: "ASM",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лева лева",
        E: "left",
        T: "ASF",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лево",
        E: "left",
        T: "ASN",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "левы",
        E: "left",
        T: "ASP",
        A: "-",
        O: "левый",
        RA: 858,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детский",
        E: "child's, children's",
        T: "ORIGINAL",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детский",
        E: "child's, children's",
        T: "ANM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детская",
        E: "child's, children's",
        T: "ANF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детское",
        E: "child's, children's",
        T: "ANN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детские",
        E: "child's, children's",
        T: "ANP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детского",
        E: "child's, children's",
        T: "AGM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детской",
        E: "child's, children's",
        T: "AGF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детского",
        E: "child's, children's",
        T: "AGN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детских",
        E: "child's, children's",
        T: "AGP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детскому",
        E: "child's, children's",
        T: "ADM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детской",
        E: "child's, children's",
        T: "ADF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детскому",
        E: "child's, children's",
        T: "ADN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детским",
        E: "child's, children's",
        T: "ADP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детский",
        E: "child's, children's",
        T: "AAM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детскую",
        E: "child's, children's",
        T: "AAF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детское",
        E: "child's, children's",
        T: "AAN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детские",
        E: "child's, children's",
        T: "AAP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детским",
        E: "child's, children's",
        T: "AIM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детской",
        E: "child's, children's",
        T: "AIF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детским",
        E: "child's, children's",
        T: "AIN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детскими",
        E: "child's, children's",
        T: "AIP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детском",
        E: "child's, children's",
        T: "APM",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детской",
        E: "child's, children's",
        T: "APF",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детском",
        E: "child's, children's",
        T: "APN",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "детских",
        E: "child's, children's",
        T: "APP",
        A: "-",
        O: "детский",
        RA: 860,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячий",
        E: "hot",
        T: "ORIGINAL",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячий",
        E: "hot",
        T: "ANM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячая",
        E: "hot",
        T: "ANF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячее",
        E: "hot",
        T: "ANN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячие",
        E: "hot",
        T: "ANP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячего",
        E: "hot",
        T: "AGM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячей",
        E: "hot",
        T: "AGF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячего",
        E: "hot",
        T: "AGN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячих",
        E: "hot",
        T: "AGP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячему",
        E: "hot",
        T: "ADM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячей",
        E: "hot",
        T: "ADF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячему",
        E: "hot",
        T: "ADN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячим",
        E: "hot",
        T: "ADP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячего",
        E: "hot",
        T: "AAM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячую",
        E: "hot",
        T: "AAF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячее",
        E: "hot",
        T: "AAN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячих",
        E: "hot",
        T: "AAP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячим",
        E: "hot",
        T: "AIM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячей",
        E: "hot",
        T: "AIF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячим",
        E: "hot",
        T: "AIN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячими",
        E: "hot",
        T: "AIP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячем",
        E: "hot",
        T: "APM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячей",
        E: "hot",
        T: "APF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячем",
        E: "hot",
        T: "APN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячих",
        E: "hot",
        T: "APP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горяч",
        E: "hot",
        T: "ASM",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горяча",
        E: "hot",
        T: "ASF",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячо",
        E: "hot",
        T: "ASN",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "горячи",
        E: "hot",
        T: "ASP",
        A: "-",
        O: "горячий",
        RA: 864,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливый",
        E: "happy",
        T: "ORIGINAL",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливый",
        E: "happy",
        T: "ANM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливая",
        E: "happy",
        T: "ANF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливое",
        E: "happy",
        T: "ANN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливые",
        E: "happy",
        T: "ANP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливого",
        E: "happy",
        T: "AGM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливой",
        E: "happy",
        T: "AGF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливого",
        E: "happy",
        T: "AGN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливых",
        E: "happy",
        T: "AGP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливому",
        E: "happy",
        T: "ADM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливой",
        E: "happy",
        T: "ADF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливому",
        E: "happy",
        T: "ADN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливым",
        E: "happy",
        T: "ADP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливого",
        E: "happy",
        T: "AAM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливую",
        E: "happy",
        T: "AAF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливое",
        E: "happy",
        T: "AAN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливых",
        E: "happy",
        T: "AAP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливым",
        E: "happy",
        T: "AIM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливой",
        E: "happy",
        T: "AIF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливым",
        E: "happy",
        T: "AIN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливыми",
        E: "happy",
        T: "AIP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливом",
        E: "happy",
        T: "APM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливой",
        E: "happy",
        T: "APF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливом",
        E: "happy",
        T: "APN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливых",
        E: "happy",
        T: "APP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастлив",
        E: "happy",
        T: "ASM",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастлива",
        E: "happy",
        T: "ASF",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливо",
        E: "happy",
        T: "ASN",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливы",
        E: "happy",
        T: "ASP",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "счастливее",
        E: "happy",
        T: "AC1",
        A: "-",
        O: "счастливый",
        RA: 867,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острый",
        E: "sharp",
        T: "ORIGINAL",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острый",
        E: "sharp",
        T: "ANM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острая",
        E: "sharp",
        T: "ANF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острое",
        E: "sharp",
        T: "ANN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острые",
        E: "sharp",
        T: "ANP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острого",
        E: "sharp",
        T: "AGM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острой",
        E: "sharp",
        T: "AGF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острого",
        E: "sharp",
        T: "AGN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острых",
        E: "sharp",
        T: "AGP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острому",
        E: "sharp",
        T: "ADM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острой",
        E: "sharp",
        T: "ADF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острому",
        E: "sharp",
        T: "ADN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острым",
        E: "sharp",
        T: "ADP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острый",
        E: "sharp",
        T: "AAM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острую",
        E: "sharp",
        T: "AAF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острое",
        E: "sharp",
        T: "AAN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острые",
        E: "sharp",
        T: "AAP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острым",
        E: "sharp",
        T: "AIM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острой",
        E: "sharp",
        T: "AIF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острым",
        E: "sharp",
        T: "AIN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острыми",
        E: "sharp",
        T: "AIP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остром",
        E: "sharp",
        T: "APM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острой",
        E: "sharp",
        T: "APF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остром",
        E: "sharp",
        T: "APN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острых",
        E: "sharp",
        T: "APP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остр",
        E: "sharp",
        T: "ASM",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остра",
        E: "sharp",
        T: "ASF",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остро",
        E: "sharp",
        T: "ASN",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "остры",
        E: "sharp",
        T: "ASP",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "острее",
        E: "sharp",
        T: "AC1",
        A: "-",
        O: "острый",
        RA: 872,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкий",
        E: "shallow, small",
        T: "ORIGINAL",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкий",
        E: "shallow, small",
        T: "ANM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкая",
        E: "shallow, small",
        T: "ANF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкое",
        E: "shallow, small",
        T: "ANN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкие",
        E: "shallow, small",
        T: "ANP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкого",
        E: "shallow, small",
        T: "AGM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкой",
        E: "shallow, small",
        T: "AGF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкого",
        E: "shallow, small",
        T: "AGN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелких",
        E: "shallow, small",
        T: "AGP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкому",
        E: "shallow, small",
        T: "ADM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкой",
        E: "shallow, small",
        T: "ADF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкому",
        E: "shallow, small",
        T: "ADN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелким",
        E: "shallow, small",
        T: "ADP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкий",
        E: "shallow, small",
        T: "AAM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкую",
        E: "shallow, small",
        T: "AAF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкое",
        E: "shallow, small",
        T: "AAN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкие",
        E: "shallow, small",
        T: "AAP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелким",
        E: "shallow, small",
        T: "AIM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкой",
        E: "shallow, small",
        T: "AIF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелким",
        E: "shallow, small",
        T: "AIN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкими",
        E: "shallow, small",
        T: "AIP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелком",
        E: "shallow, small",
        T: "APM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелкой",
        E: "shallow, small",
        T: "APF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелком",
        E: "shallow, small",
        T: "APN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелких",
        E: "shallow, small",
        T: "APP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелок",
        E: "shallow, small",
        T: "ASM",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелка",
        E: "shallow, small",
        T: "ASF",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелко",
        E: "shallow, small",
        T: "ASN",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мелки мелки",
        E: "shallow, small",
        T: "ASP",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мельче",
        E: "shallow, small",
        T: "AC1",
        A: "-",
        O: "мелкий",
        RA: 881,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современный",
        E: "contemporary, modern, up-to-date",
        T: "ORIGINAL",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современный",
        E: "contemporary, modern, up-to-date",
        T: "ANM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современная",
        E: "contemporary, modern, up-to-date",
        T: "ANF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современное",
        E: "contemporary, modern, up-to-date",
        T: "ANN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современные",
        E: "contemporary, modern, up-to-date",
        T: "ANP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современного",
        E: "contemporary, modern, up-to-date",
        T: "AGM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современной",
        E: "contemporary, modern, up-to-date",
        T: "AGF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современного",
        E: "contemporary, modern, up-to-date",
        T: "AGN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современных",
        E: "contemporary, modern, up-to-date",
        T: "AGP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современному",
        E: "contemporary, modern, up-to-date",
        T: "ADM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современной",
        E: "contemporary, modern, up-to-date",
        T: "ADF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современному",
        E: "contemporary, modern, up-to-date",
        T: "ADN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современным",
        E: "contemporary, modern, up-to-date",
        T: "ADP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современный",
        E: "contemporary, modern, up-to-date",
        T: "AAM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современную",
        E: "contemporary, modern, up-to-date",
        T: "AAF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современное",
        E: "contemporary, modern, up-to-date",
        T: "AAN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современные",
        E: "contemporary, modern, up-to-date",
        T: "AAP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современным",
        E: "contemporary, modern, up-to-date",
        T: "AIM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современной",
        E: "contemporary, modern, up-to-date",
        T: "AIF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современным",
        E: "contemporary, modern, up-to-date",
        T: "AIN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современными",
        E: "contemporary, modern, up-to-date",
        T: "AIP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современном",
        E: "contemporary, modern, up-to-date",
        T: "APM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современной",
        E: "contemporary, modern, up-to-date",
        T: "APF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современном",
        E: "contemporary, modern, up-to-date",
        T: "APN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современных",
        E: "contemporary, modern, up-to-date",
        T: "APP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современен",
        E: "contemporary, modern, up-to-date",
        T: "ASM",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современна",
        E: "contemporary, modern, up-to-date",
        T: "ASF",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современно",
        E: "contemporary, modern, up-to-date",
        T: "ASN",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "современны",
        E: "contemporary, modern, up-to-date",
        T: "ASP",
        A: "-",
        O: "современный",
        RA: 892,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлый",
        E: "cheerful, merry",
        T: "ORIGINAL",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлый",
        E: "cheerful, merry",
        T: "ANM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлая",
        E: "cheerful, merry",
        T: "ANF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлое",
        E: "cheerful, merry",
        T: "ANN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлые",
        E: "cheerful, merry",
        T: "ANP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлого",
        E: "cheerful, merry",
        T: "AGM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлой",
        E: "cheerful, merry",
        T: "AGF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлого",
        E: "cheerful, merry",
        T: "AGN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлых",
        E: "cheerful, merry",
        T: "AGP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлому",
        E: "cheerful, merry",
        T: "ADM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлой",
        E: "cheerful, merry",
        T: "ADF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлому",
        E: "cheerful, merry",
        T: "ADN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлым",
        E: "cheerful, merry",
        T: "ADP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлый",
        E: "cheerful, merry",
        T: "AAM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлую",
        E: "cheerful, merry",
        T: "AAF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлое",
        E: "cheerful, merry",
        T: "AAN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлые",
        E: "cheerful, merry",
        T: "AAP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлым",
        E: "cheerful, merry",
        T: "AIM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлой",
        E: "cheerful, merry",
        T: "AIF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлым",
        E: "cheerful, merry",
        T: "AIN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлыми",
        E: "cheerful, merry",
        T: "AIP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлом",
        E: "cheerful, merry",
        T: "APM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлой",
        E: "cheerful, merry",
        T: "APF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлом",
        E: "cheerful, merry",
        T: "APN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весёлых",
        E: "cheerful, merry",
        T: "APP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весел",
        E: "cheerful, merry",
        T: "ASM",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весела",
        E: "cheerful, merry",
        T: "ASF",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весело",
        E: "cheerful, merry",
        T: "ASN",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веселы веселы",
        E: "cheerful, merry",
        T: "ASP",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веселее",
        E: "cheerful, merry",
        T: "AC1",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "веселей",
        E: "cheerful, merry",
        T: "AC2",
        A: "-",
        O: "весёлый",
        RA: 895,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милый",
        E: "nice, sweet, dear",
        T: "ORIGINAL",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милый",
        E: "nice, sweet, dear",
        T: "ANM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милая",
        E: "nice, sweet, dear",
        T: "ANF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милое",
        E: "nice, sweet, dear",
        T: "ANN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милые",
        E: "nice, sweet, dear",
        T: "ANP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милого",
        E: "nice, sweet, dear",
        T: "AGM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милой",
        E: "nice, sweet, dear",
        T: "AGF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милого",
        E: "nice, sweet, dear",
        T: "AGN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милых",
        E: "nice, sweet, dear",
        T: "AGP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милому",
        E: "nice, sweet, dear",
        T: "ADM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милой",
        E: "nice, sweet, dear",
        T: "ADF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милому",
        E: "nice, sweet, dear",
        T: "ADN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милым",
        E: "nice, sweet, dear",
        T: "ADP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милый",
        E: "nice, sweet, dear",
        T: "AAM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милую",
        E: "nice, sweet, dear",
        T: "AAF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милое",
        E: "nice, sweet, dear",
        T: "AAN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милые",
        E: "nice, sweet, dear",
        T: "AAP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милым",
        E: "nice, sweet, dear",
        T: "AIM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милой",
        E: "nice, sweet, dear",
        T: "AIF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милым",
        E: "nice, sweet, dear",
        T: "AIN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милыми",
        E: "nice, sweet, dear",
        T: "AIP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милом",
        E: "nice, sweet, dear",
        T: "APM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милой",
        E: "nice, sweet, dear",
        T: "APF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милом",
        E: "nice, sweet, dear",
        T: "APN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милых",
        E: "nice, sweet, dear",
        T: "APP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мил",
        E: "nice, sweet, dear",
        T: "ASM",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мила",
        E: "nice, sweet, dear",
        T: "ASF",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мило",
        E: "nice, sweet, dear",
        T: "ASN",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милы милы",
        E: "nice, sweet, dear",
        T: "ASP",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "милее",
        E: "nice, sweet, dear",
        T: "AC1",
        A: "-",
        O: "милый",
        RA: 899,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средний",
        E: "average, middle",
        T: "ORIGINAL",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средний",
        E: "average, middle",
        T: "ANM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средняя",
        E: "average, middle",
        T: "ANF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднее",
        E: "average, middle",
        T: "ANN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средние",
        E: "average, middle",
        T: "ANP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднего",
        E: "average, middle",
        T: "AGM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средней",
        E: "average, middle",
        T: "AGF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднего",
        E: "average, middle",
        T: "AGN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средних",
        E: "average, middle",
        T: "AGP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднему",
        E: "average, middle",
        T: "ADM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средней",
        E: "average, middle",
        T: "ADF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднему",
        E: "average, middle",
        T: "ADN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средним",
        E: "average, middle",
        T: "ADP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднего",
        E: "average, middle",
        T: "AAM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднюю",
        E: "average, middle",
        T: "AAF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднее",
        E: "average, middle",
        T: "AAN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средних",
        E: "average, middle",
        T: "AAP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средним",
        E: "average, middle",
        T: "AIM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средней",
        E: "average, middle",
        T: "AIF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средним",
        E: "average, middle",
        T: "AIN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средними",
        E: "average, middle",
        T: "AIP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднем",
        E: "average, middle",
        T: "APM",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средней",
        E: "average, middle",
        T: "APF",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среднем",
        E: "average, middle",
        T: "APN",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "средних",
        E: "average, middle",
        T: "APP",
        A: "-",
        O: "средний",
        RA: 905,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американский",
        E: "American",
        T: "ORIGINAL",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американский",
        E: "American",
        T: "ANM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американская",
        E: "American",
        T: "ANF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американское",
        E: "American",
        T: "ANN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американские",
        E: "American",
        T: "ANP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американского",
        E: "American",
        T: "AGM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американской",
        E: "American",
        T: "AGF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американского",
        E: "American",
        T: "AGN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американских",
        E: "American",
        T: "AGP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американскому",
        E: "American",
        T: "ADM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американской",
        E: "American",
        T: "ADF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американскому",
        E: "American",
        T: "ADN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американским",
        E: "American",
        T: "ADP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американский",
        E: "American",
        T: "AAM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американскую",
        E: "American",
        T: "AAF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американское",
        E: "American",
        T: "AAN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американские",
        E: "American",
        T: "AAP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американским",
        E: "American",
        T: "AIM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американской",
        E: "American",
        T: "AIF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американским",
        E: "American",
        T: "AIN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американскими",
        E: "American",
        T: "AIP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американском",
        E: "American",
        T: "APM",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американской",
        E: "American",
        T: "APF",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американском",
        E: "American",
        T: "APN",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "американских",
        E: "American",
        T: "APP",
        A: "-",
        O: "американский",
        RA: 911,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "ORIGINAL",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "ANM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночная",
        E: "night",
        T: "ANF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночное",
        E: "night",
        T: "ANN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночные",
        E: "night",
        T: "ANP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночного",
        E: "night",
        T: "AGM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "AGF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночного",
        E: "night",
        T: "AGN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночных",
        E: "night",
        T: "AGP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночному",
        E: "night",
        T: "ADM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "ADF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночному",
        E: "night",
        T: "ADN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночным",
        E: "night",
        T: "ADP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "AAM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночную",
        E: "night",
        T: "AAF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночное",
        E: "night",
        T: "AAN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночные",
        E: "night",
        T: "AAP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночным",
        E: "night",
        T: "AIM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "AIF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночным",
        E: "night",
        T: "AIN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночными",
        E: "night",
        T: "AIP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночном",
        E: "night",
        T: "APM",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночной",
        E: "night",
        T: "APF",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночном",
        E: "night",
        T: "APN",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ночных",
        E: "night",
        T: "APP",
        A: "-",
        O: "ночной",
        RA: 940,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровый",
        E: "healthy",
        T: "ORIGINAL",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровый",
        E: "healthy",
        T: "ANM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровая",
        E: "healthy",
        T: "ANF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровое",
        E: "healthy",
        T: "ANN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровые",
        E: "healthy",
        T: "ANP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здорового",
        E: "healthy",
        T: "AGM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровой",
        E: "healthy",
        T: "AGF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здорового",
        E: "healthy",
        T: "AGN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровых",
        E: "healthy",
        T: "AGP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровому",
        E: "healthy",
        T: "ADM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровой",
        E: "healthy",
        T: "ADF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровому",
        E: "healthy",
        T: "ADN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровым",
        E: "healthy",
        T: "ADP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровый",
        E: "healthy",
        T: "AAM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровую",
        E: "healthy",
        T: "AAF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровое",
        E: "healthy",
        T: "AAN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровые",
        E: "healthy",
        T: "AAP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровым",
        E: "healthy",
        T: "AIM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровой",
        E: "healthy",
        T: "AIF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровым",
        E: "healthy",
        T: "AIN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровыми",
        E: "healthy",
        T: "AIP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровом",
        E: "healthy",
        T: "APM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровой",
        E: "healthy",
        T: "APF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровом",
        E: "healthy",
        T: "APN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровых",
        E: "healthy",
        T: "APP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоров",
        E: "healthy",
        T: "ASM",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здорова",
        E: "healthy",
        T: "ASF",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здорово",
        E: "healthy",
        T: "ASN",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здоровы",
        E: "healthy",
        T: "ASP",
        A: "-",
        O: "здоровый",
        RA: 941,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "ORIGINAL",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "ANM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухая",
        E: "dry",
        T: "ANF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухое",
        E: "dry",
        T: "ANN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухие",
        E: "dry",
        T: "ANP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухого",
        E: "dry",
        T: "AGM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "AGF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухого",
        E: "dry",
        T: "AGN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухих",
        E: "dry",
        T: "AGP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухому",
        E: "dry",
        T: "ADM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "ADF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухому",
        E: "dry",
        T: "ADN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухим",
        E: "dry",
        T: "ADP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "AAM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухую",
        E: "dry",
        T: "AAF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухое",
        E: "dry",
        T: "AAN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухие",
        E: "dry",
        T: "AAP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухим",
        E: "dry",
        T: "AIM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "AIF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухим",
        E: "dry",
        T: "AIN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухими",
        E: "dry",
        T: "AIP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухом",
        E: "dry",
        T: "APM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухой",
        E: "dry",
        T: "APF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухом",
        E: "dry",
        T: "APN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухих",
        E: "dry",
        T: "APP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сух",
        E: "dry",
        T: "ASM",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "суха",
        E: "dry",
        T: "ASF",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухо",
        E: "dry",
        T: "ASN",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сухи",
        E: "dry",
        T: "ASP",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "суше",
        E: "dry",
        T: "AC1",
        A: "-",
        O: "сухой",
        RA: 943,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободный",
        E: "free",
        T: "ORIGINAL",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободный",
        E: "free",
        T: "ANM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободная",
        E: "free",
        T: "ANF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободное",
        E: "free",
        T: "ANN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободные",
        E: "free",
        T: "ANP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободного",
        E: "free",
        T: "AGM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободной",
        E: "free",
        T: "AGF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободного",
        E: "free",
        T: "AGN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободных",
        E: "free",
        T: "AGP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободному",
        E: "free",
        T: "ADM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободной",
        E: "free",
        T: "ADF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободному",
        E: "free",
        T: "ADN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободным",
        E: "free",
        T: "ADP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободный",
        E: "free",
        T: "AAM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободную",
        E: "free",
        T: "AAF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободное",
        E: "free",
        T: "AAN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободные",
        E: "free",
        T: "AAP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободным",
        E: "free",
        T: "AIM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободной",
        E: "free",
        T: "AIF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободным",
        E: "free",
        T: "AIN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободными",
        E: "free",
        T: "AIP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободном",
        E: "free",
        T: "APM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободной",
        E: "free",
        T: "APF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободном",
        E: "free",
        T: "APN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободных",
        E: "free",
        T: "APP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободен",
        E: "free",
        T: "ASM",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободна",
        E: "free",
        T: "ASF",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободно",
        E: "free",
        T: "ASN",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "свободны",
        E: "free",
        T: "ASP",
        A: "-",
        O: "свободный",
        RA: 948,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способный",
        E: "capable (of)",
        T: "ORIGINAL",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способный",
        E: "capable (of)",
        T: "ANM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способная",
        E: "capable (of)",
        T: "ANF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способное",
        E: "capable (of)",
        T: "ANN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способные",
        E: "capable (of)",
        T: "ANP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способного",
        E: "capable (of)",
        T: "AGM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способной",
        E: "capable (of)",
        T: "AGF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способного",
        E: "capable (of)",
        T: "AGN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способных",
        E: "capable (of)",
        T: "AGP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способному",
        E: "capable (of)",
        T: "ADM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способной",
        E: "capable (of)",
        T: "ADF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способному",
        E: "capable (of)",
        T: "ADN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способным",
        E: "capable (of)",
        T: "ADP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способный",
        E: "capable (of)",
        T: "AAM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способную",
        E: "capable (of)",
        T: "AAF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способное",
        E: "capable (of)",
        T: "AAN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способные",
        E: "capable (of)",
        T: "AAP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способным",
        E: "capable (of)",
        T: "AIM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способной",
        E: "capable (of)",
        T: "AIF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способным",
        E: "capable (of)",
        T: "AIN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способными",
        E: "capable (of)",
        T: "AIP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способном",
        E: "capable (of)",
        T: "APM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способной",
        E: "capable (of)",
        T: "APF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способном",
        E: "capable (of)",
        T: "APN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способных",
        E: "capable (of)",
        T: "APP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способен",
        E: "capable (of)",
        T: "ASM",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способна",
        E: "capable (of)",
        T: "ASF",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способно",
        E: "capable (of)",
        T: "ASN",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "способны",
        E: "capable (of)",
        T: "ASP",
        A: "-",
        O: "способный",
        RA: 962,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересный",
        E: "interesting",
        T: "ORIGINAL",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересный",
        E: "interesting",
        T: "ANM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересная",
        E: "interesting",
        T: "ANF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересное",
        E: "interesting",
        T: "ANN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересные",
        E: "interesting",
        T: "ANP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересного",
        E: "interesting",
        T: "AGM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересной",
        E: "interesting",
        T: "AGF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересного",
        E: "interesting",
        T: "AGN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересных",
        E: "interesting",
        T: "AGP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересному",
        E: "interesting",
        T: "ADM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересной",
        E: "interesting",
        T: "ADF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересному",
        E: "interesting",
        T: "ADN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересным",
        E: "interesting",
        T: "ADP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересный",
        E: "interesting",
        T: "AAM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересную",
        E: "interesting",
        T: "AAF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересное",
        E: "interesting",
        T: "AAN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересные",
        E: "interesting",
        T: "AAP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересным",
        E: "interesting",
        T: "AIM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересной",
        E: "interesting",
        T: "AIF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересным",
        E: "interesting",
        T: "AIN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересными",
        E: "interesting",
        T: "AIP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересном",
        E: "interesting",
        T: "APM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересной",
        E: "interesting",
        T: "APF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересном",
        E: "interesting",
        T: "APN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересных",
        E: "interesting",
        T: "APP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересен",
        E: "interesting",
        T: "ASM",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересна",
        E: "interesting",
        T: "ASF",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересно",
        E: "interesting",
        T: "ASN",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересны",
        E: "interesting",
        T: "ASP",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интереснее",
        E: "interesting",
        T: "AC1",
        A: "-",
        O: "интересный",
        RA: 970,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "ORIGINAL",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "ANM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогая",
        E: "on the way",
        T: "ANF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогое",
        E: "on the way",
        T: "ANN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогие",
        E: "on the way",
        T: "ANP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогого",
        E: "on the way",
        T: "AGM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "AGF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогого",
        E: "on the way",
        T: "AGN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогих",
        E: "on the way",
        T: "AGP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогому",
        E: "on the way",
        T: "ADM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "ADF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогому",
        E: "on the way",
        T: "ADN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогим",
        E: "on the way",
        T: "ADP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "AAM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогую",
        E: "on the way",
        T: "AAF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогое",
        E: "on the way",
        T: "AAN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогие",
        E: "on the way",
        T: "AAP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогим",
        E: "on the way",
        T: "AIM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "AIF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогим",
        E: "on the way",
        T: "AIN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогими",
        E: "on the way",
        T: "AIP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогом",
        E: "on the way",
        T: "APM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогой",
        E: "on the way",
        T: "APF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогом",
        E: "on the way",
        T: "APN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорогих",
        E: "on the way",
        T: "APP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорог",
        E: "on the way",
        T: "ASM",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорога",
        E: "on the way",
        T: "ASF",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дорого",
        E: "on the way",
        T: "ASN",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дороги",
        E: "on the way",
        T: "ASP",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "дороже",
        E: "on the way",
        T: "AC1",
        A: "-",
        O: "дорогой",
        RA: 988,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрый",
        E: "wet",
        T: "ORIGINAL",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрый",
        E: "wet",
        T: "ANM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрая",
        E: "wet",
        T: "ANF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрое",
        E: "wet",
        T: "ANN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрые",
        E: "wet",
        T: "ANP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрого",
        E: "wet",
        T: "AGM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрой",
        E: "wet",
        T: "AGF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрого",
        E: "wet",
        T: "AGN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрых",
        E: "wet",
        T: "AGP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрому",
        E: "wet",
        T: "ADM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрой",
        E: "wet",
        T: "ADF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрому",
        E: "wet",
        T: "ADN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрым",
        E: "wet",
        T: "ADP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрый",
        E: "wet",
        T: "AAM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрую",
        E: "wet",
        T: "AAF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрое",
        E: "wet",
        T: "AAN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрые",
        E: "wet",
        T: "AAP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрым",
        E: "wet",
        T: "AIM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрой",
        E: "wet",
        T: "AIF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрым",
        E: "wet",
        T: "AIN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрыми",
        E: "wet",
        T: "AIP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокром",
        E: "wet",
        T: "APM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрой",
        E: "wet",
        T: "APF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокром",
        E: "wet",
        T: "APN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокрых",
        E: "wet",
        T: "APP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокр",
        E: "wet",
        T: "ASM",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокра",
        E: "wet",
        T: "ASF",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокро",
        E: "wet",
        T: "ASN",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мокры",
        E: "wet",
        T: "ASP",
        A: "-",
        O: "мокрый",
        RA: 994,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "ORIGINAL",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "ANM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямая",
        E: "straight",
        T: "ANF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямое",
        E: "straight",
        T: "ANN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямые",
        E: "straight",
        T: "ANP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямого",
        E: "straight",
        T: "AGM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "AGF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямого",
        E: "straight",
        T: "AGN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямых",
        E: "straight",
        T: "AGP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямому",
        E: "straight",
        T: "ADM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "ADF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямому",
        E: "straight",
        T: "ADN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямым",
        E: "straight",
        T: "ADP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "AAM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямую",
        E: "straight",
        T: "AAF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямое",
        E: "straight",
        T: "AAN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямые",
        E: "straight",
        T: "AAP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямым",
        E: "straight",
        T: "AIM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "AIF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямым",
        E: "straight",
        T: "AIN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямыми",
        E: "straight",
        T: "AIP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямом",
        E: "straight",
        T: "APM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямой",
        E: "straight",
        T: "APF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямом",
        E: "straight",
        T: "APN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямых",
        E: "straight",
        T: "APP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прям",
        E: "straight",
        T: "ASM",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пряма",
        E: "straight",
        T: "ASF",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямо",
        E: "straight",
        T: "ASN",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямы",
        E: "straight",
        T: "ASP",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямее",
        E: "straight",
        T: "AC1",
        A: "-",
        O: "прямой",
        RA: 996,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "и",
        E: "and, though",
        T: "C",
        A: "-",
        O: "и",
        RA: 0,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "в",
        E: "in, at",
        T: "R",
        A: "-",
        O: "в",
        RA: 1,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "не",
        E: "not",
        T: "T",
        A: "-",
        O: "не",
        RA: 2,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "на",
        E: "on, it, at, to",
        T: "R",
        A: "-",
        O: "на",
        RA: 4,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "с",
        E: "with, and, from, of",
        T: "R",
        A: "-",
        O: "с",
        RA: 9,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "а",
        E: "while, and, but",
        T: "C",
        A: "-",
        O: "а",
        RA: 10,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "как",
        E: "how, what, as, like",
        T: "D",
        A: "-",
        O: "как",
        RA: 13,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "по",
        E: "on, along, by",
        T: "R",
        A: "-",
        O: "по",
        RA: 15,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "но",
        E: "but",
        T: "C",
        A: "-",
        O: "но",
        RA: 16,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "к",
        E: "to, for, by",
        T: "R",
        A: "-",
        O: "к",
        RA: 18,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "у",
        E: "by, with, of",
        T: "R",
        A: "-",
        O: "у",
        RA: 19,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "из",
        E: "from, of, in",
        T: "R",
        A: "-",
        O: "из",
        RA: 21,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "за",
        E: "behind, over, at, after",
        T: "R",
        A: "-",
        O: "за",
        RA: 23,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "так",
        E: "so, thus, then",
        T: "D",
        A: "-",
        O: "так",
        RA: 25,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "же",
        E: "and, as for, but, same",
        T: "T",
        A: "-",
        O: "же",
        RA: 26,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "от",
        E: "from, of, for",
        T: "R",
        A: "-",
        O: "от",
        RA: 27,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "о",
        E: "of, about, against",
        T: "R",
        A: "-",
        O: "о",
        RA: 33,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ещё",
        E: "still, yet",
        T: "D",
        A: "-",
        O: "ещё",
        RA: 35,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бы",
        E: "would",
        T: "T",
        A: "-",
        O: "бы",
        RA: 36,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "только",
        E: "only, merely, but",
        T: "D",
        A: "-",
        O: "только",
        RA: 38,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "когда",
        E: "when, while, as",
        T: "D",
        A: "-",
        O: "когда",
        RA: 42,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уже",
        E: "already, by now",
        T: "D",
        A: "-",
        O: "уже",
        RA: 43,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "для",
        E: "for, to",
        T: "R",
        A: "-",
        O: "для",
        RA: 44,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вот",
        E: "here, there, this is, that's",
        T: "T",
        A: "-",
        O: "вот",
        RA: 45,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "да",
        E: "yes, but",
        T: "T",
        A: "-",
        O: "да",
        RA: 47,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "до",
        E: "to, up to, about, before",
        T: "R",
        A: "-",
        O: "до",
        RA: 53,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "или",
        E: "or",
        T: "C",
        A: "-",
        O: "или",
        RA: 54,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "если",
        E: "if",
        T: "C",
        A: "-",
        O: "если",
        RA: 55,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нет",
        E: "no, not, but",
        T: "T",
        A: "-",
        O: "нет",
        RA: 58,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ни",
        E: "not a, not, neither… nor",
        T: "T",
        A: "-",
        O: "ни",
        RA: 60,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "даже",
        E: "even",
        T: "T",
        A: "-",
        O: "даже",
        RA: 63,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ну",
        E: "now, right, well, come on",
        T: "T",
        A: "-",
        O: "ну",
        RA: 67,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "под",
        E: "under, for, towards, to",
        T: "R",
        A: "-",
        O: "под",
        RA: 68,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "где",
        E: "where",
        T: "D",
        A: "-",
        O: "где",
        RA: 69,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "есть",
        E: "to eat, to be",
        T: "T",
        A: "-",
        O: "есть",
        RA: 71,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чтобы",
        E: "that, in order that",
        T: "C",
        A: "-",
        O: "чтобы",
        RA: 74,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "два",
        E: "two",
        T: "T",
        A: "-",
        O: "два",
        RA: 75,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "там",
        E: "there, then",
        T: "D",
        A: "-",
        O: "там",
        RA: 76,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чем",
        E: "than",
        T: "C",
        A: "-",
        O: "чем",
        RA: 77,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тут",
        E: "here, now, then",
        T: "D",
        A: "-",
        O: "тут",
        RA: 82,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "во",
        E: "in, at; super, exactly",
        T: "R",
        A: "-",
        O: "во",
        RA: 83,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потом",
        E: "afterwards, then",
        T: "D",
        A: "-",
        O: "потом",
        RA: 85,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "очень",
        E: "very",
        T: "D",
        A: "-",
        O: "очень",
        RA: 86,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "со",
        E: "with",
        T: "R",
        A: "-",
        O: "со",
        RA: 87,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "ли",
        E: "whether, if",
        T: "T",
        A: "-",
        O: "ли",
        RA: 89,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "при",
        E: "attached to, in the presence of, by, ",
        T: "R",
        A: "-",
        O: "при",
        RA: 90,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "надо",
        E: "over, above, ought to",
        T: "T",
        A: "-",
        O: "надо",
        RA: 92,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "без",
        E: "without",
        T: "R",
        A: "-",
        O: "без",
        RA: 93,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "теперь",
        E: "now, nowadays",
        T: "D",
        A: "-",
        O: "теперь",
        RA: 96,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тоже",
        E: "also, as well, too",
        T: "D",
        A: "-",
        O: "тоже",
        RA: 97,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сейчас",
        E: "now, presently, soon",
        T: "D",
        A: "-",
        O: "сейчас",
        RA: 102,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "можно",
        E: "one can",
        T: "T",
        A: "-",
        O: "можно",
        RA: 103,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "после",
        E: "after, afterwards",
        T: "R",
        A: "-",
        O: "после",
        RA: 104,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "здесь",
        E: "here",
        T: "D",
        A: "-",
        O: "здесь",
        RA: 106,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "через",
        E: "through, across",
        T: "R",
        A: "-",
        O: "через",
        RA: 110,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тогда",
        E: "then",
        T: "D",
        A: "-",
        O: "тогда",
        RA: 113,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ведь",
        E: "you see, you know",
        T: "T",
        A: "-",
        O: "ведь",
        RA: 114,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почему",
        E: "why",
        T: "D",
        A: "-",
        O: "почему",
        RA: 121,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "потому",
        E: "that's why",
        T: "D",
        A: "-",
        O: "потому",
        RA: 122,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "просто",
        E: "simply",
        T: "D",
        A: "-",
        O: "просто",
        RA: 124,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вдруг",
        E: "suddenly",
        T: "D",
        A: "-",
        O: "вдруг",
        RA: 131,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "над",
        E: "above, over",
        T: "R",
        A: "-",
        O: "над",
        RA: 132,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "перед",
        E: "in front of +instrumental case",
        T: "T",
        A: "-",
        O: "перед",
        RA: 137,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "три",
        E: "three",
        T: "T",
        A: "-",
        O: "три",
        RA: 142,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уж",
        E: "really, already",
        T: "D",
        A: "-",
        O: "уж",
        RA: 144,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "несколько",
        E: "several, some",
        T: "D",
        A: "-",
        O: "несколько",
        RA: 147,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пока",
        E: "for the present",
        T: "D",
        A: "-",
        O: "пока",
        RA: 153,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хорошо",
        E: "well",
        T: "D",
        A: "-",
        O: "хорошо",
        RA: 154,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "более",
        E: "more",
        T: "D",
        A: "-",
        O: "более",
        RA: 157,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хотя",
        E: "although",
        T: "C",
        A: "-",
        O: "хотя",
        RA: 158,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всегда",
        E: "always",
        T: "D",
        A: "-",
        O: "всегда",
        RA: 159,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "куда",
        E: "where, what for, much",
        T: "D",
        A: "-",
        O: "куда",
        RA: 161,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сразу",
        E: "at once, right away, just",
        T: "D",
        A: "-",
        O: "сразу",
        RA: 172,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "совсем",
        E: "quite, entirely, totally",
        T: "D",
        A: "-",
        O: "совсем",
        RA: 174,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "об",
        E: "about, of",
        T: "R",
        A: "-",
        O: "об",
        RA: 176,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "почти",
        E: "almost",
        T: "D",
        A: "-",
        O: "почти",
        RA: 183,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "много",
        E: "many, much",
        T: "D",
        A: "-",
        O: "много",
        RA: 189,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "снова",
        E: "again",
        T: "D",
        A: "-",
        O: "снова",
        RA: 191,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "между",
        E: "between, among",
        T: "R",
        A: "-",
        O: "между",
        RA: 193,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "опять",
        E: "again",
        T: "D",
        A: "-",
        O: "опять",
        RA: 195,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "про",
        E: "about, for",
        T: "R",
        A: "-",
        O: "про",
        RA: 199,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "лишь",
        E: "only, as soon as",
        T: "D",
        A: "-",
        O: "лишь",
        RA: 200,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "будто",
        E: "as if, as though",
        T: "C",
        A: "-",
        O: "будто",
        RA: 211,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никогда",
        E: "never",
        T: "D",
        A: "-",
        O: "никогда",
        RA: 212,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "однако",
        E: "however, though",
        T: "T",
        A: "-",
        O: "однако",
        RA: 215,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "именно",
        E: "namely, just",
        T: "T",
        A: "-",
        O: "именно",
        RA: 217,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вообще",
        E: "in general, altogether, on the whole",
        T: "D",
        A: "-",
        O: "вообще",
        RA: 225,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чуть",
        E: "hardly, slightly",
        T: "D",
        A: "-",
        O: "чуть",
        RA: 232,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сколько",
        E: "how much, how many",
        T: "T",
        A: "-",
        O: "сколько",
        RA: 237,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "таки",
        E: "after all",
        T: "T",
        A: "-",
        O: "таки",
        RA: 241,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "хоть",
        E: "even, if you want, though",
        T: "C",
        A: "-",
        O: "хоть",
        RA: 245,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сегодня",
        E: "today",
        T: "D",
        A: "-",
        O: "сегодня",
        RA: 246,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вместе",
        E: "together",
        T: "D",
        A: "-",
        O: "вместе",
        RA: 248,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зачем",
        E: "what for, why",
        T: "D",
        A: "-",
        O: "зачем",
        RA: 251,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наконец",
        E: "at last, finally",
        T: "D",
        A: "-",
        O: "наконец",
        RA: 261,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "назад",
        E: "back, backwards",
        T: "D",
        A: "-",
        O: "назад",
        RA: 264,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "словно",
        E: "as if, like",
        T: "C",
        A: "-",
        O: "словно",
        RA: 267,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "давно",
        E: "long ago",
        T: "D",
        A: "-",
        O: "давно",
        RA: 271,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нельзя",
        E: "it is impossible, can't",
        T: "T",
        A: "-",
        O: "нельзя",
        RA: 276,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прямо",
        E: "straight, frankly",
        T: "D",
        A: "-",
        O: "прямо",
        RA: 277,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "долго",
        E: "for a long time",
        T: "D",
        A: "-",
        O: "долго",
        RA: 278,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "быстро",
        E: "fast, quickly",
        T: "D",
        A: "-",
        O: "быстро",
        RA: 279,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ж",
        E: "and, as for, but",
        T: "T",
        A: "-",
        O: "ж",
        RA: 288,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "право",
        E: "right",
        T: "T",
        A: "-",
        O: "право",
        RA: 295,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поэтому",
        E: "therefore",
        T: "D",
        A: "-",
        O: "поэтому",
        RA: 303,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "совершенно",
        E: "absolutely, quite",
        T: "D",
        A: "-",
        O: "совершенно",
        RA: 305,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кроме",
        E: "except, besides",
        T: "R",
        A: "-",
        O: "кроме",
        RA: 306,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пусть",
        E: "let's, though",
        T: "T",
        A: "-",
        O: "пусть",
        RA: 316,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "точно",
        E: "exactly",
        T: "D",
        A: "-",
        O: "точно",
        RA: 318,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "среди",
        E: "among",
        T: "R",
        A: "-",
        O: "среди",
        RA: 319,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иногда",
        E: "sometimes",
        T: "D",
        A: "-",
        O: "иногда",
        RA: 323,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ко",
        E: "to, towards, by",
        T: "R",
        A: "-",
        O: "ко",
        RA: 332,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вокруг",
        E: "around",
        T: "R",
        A: "-",
        O: "вокруг",
        RA: 334,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "затем",
        E: "then, after that",
        T: "D",
        A: "-",
        O: "затем",
        RA: 347,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "четыре",
        E: "four",
        T: "T",
        A: "-",
        O: "четыре",
        RA: 352,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "также",
        E: "also, as well, too",
        T: "T",
        A: "-",
        O: "также",
        RA: 355,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "откуда",
        E: "where… from",
        T: "D",
        A: "-",
        O: "откуда",
        RA: 358,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чтоб",
        E: "that, in order that,",
        T: "T",
        A: "-",
        O: "чтоб",
        RA: 359,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вроде",
        E: "like, not unlike",
        T: "R",
        A: "-",
        O: "вроде",
        RA: 363,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "особенно",
        E: "especially, particularly",
        T: "D",
        A: "-",
        O: "особенно",
        RA: 367,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оба",
        E: "both",
        T: "T",
        A: "-",
        O: "оба",
        RA: 370,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двадцать",
        E: "twenty",
        T: "T",
        A: "-",
        O: "двадцать",
        RA: 375,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "например",
        E: "for example, for instance",
        T: "T",
        A: "-",
        O: "например",
        RA: 383,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "равно",
        E: "alike, in like manner",
        T: "D",
        A: "-",
        O: "равно",
        RA: 386,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мало",
        E: "little, few,",
        T: "D",
        A: "-",
        O: "мало",
        RA: 388,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "туда",
        E: "there",
        T: "D",
        A: "-",
        O: "туда",
        RA: 392,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сюда",
        E: "here",
        T: "D",
        A: "-",
        O: "сюда",
        RA: 393,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "действительно",
        E: "indeed, really",
        T: "D",
        A: "-",
        O: "действительно",
        RA: 398,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слишком",
        E: "too, too much",
        T: "D",
        A: "-",
        O: "слишком",
        RA: 399,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "десять",
        E: "ten",
        T: "T",
        A: "-",
        O: "десять",
        RA: 417,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "следующий",
        E: "next, following",
        T: "T",
        A: "-",
        O: "следующий",
        RA: 419,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "немного",
        E: "a little",
        T: "D",
        A: "-",
        O: "немного",
        RA: 424,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "впрочем",
        E: "however, though",
        T: "C",
        A: "-",
        O: "впрочем",
        RA: 425,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видно",
        E: "evidently, obviously",
        T: "T",
        A: "-",
        O: "видно",
        RA: 426,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "разве",
        E: "really?, perhaps",
        T: "T",
        A: "-",
        O: "разве",
        RA: 429,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тихо",
        E: "quietly, softly, slowly",
        T: "D",
        A: "-",
        O: "тихо",
        RA: 433,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "против",
        E: "against, opposite, contrary to",
        T: "R",
        A: "-",
        O: "против",
        RA: 445,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ладно",
        E: "in harmony, well, all right",
        T: "D",
        A: "-",
        O: "ладно",
        RA: 460,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наверное",
        E: "probably, most likely",
        T: "D",
        A: "-",
        O: "наверное",
        RA: 466,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "часто",
        E: "often",
        T: "D",
        A: "-",
        O: "часто",
        RA: 470,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "домой",
        E: 'home(as in "she came home")',
        T: "D",
        A: "-",
        O: "домой",
        RA: 471,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сначала",
        E: "at first, from the beginning",
        T: "D",
        A: "-",
        O: "сначала",
        RA: 487,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "либо",
        E: "or",
        T: "C",
        A: "-",
        O: "либо",
        RA: 488,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вполне",
        E: "quite, fully",
        T: "D",
        A: "-",
        O: "вполне",
        RA: 511,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вперед",
        E: "forward",
        T: "R",
        A: "-",
        O: "вперед",
        RA: 514,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "медленно",
        E: "slowly",
        T: "D",
        A: "-",
        O: "медленно",
        RA: 515,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возле",
        E: "by, near, close by",
        T: "R",
        A: "-",
        O: "возле",
        RA: 516,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никак",
        E: "in now way, by no means",
        T: "D",
        A: "-",
        O: "никак",
        RA: 517,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "довольно",
        E: "enough",
        T: "D",
        A: "-",
        O: "довольно",
        RA: 520,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "едва",
        E: "hardly, just, barely",
        T: "D",
        A: "-",
        O: "едва",
        RA: 528,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "далеко",
        E: "far, far off",
        T: "D",
        A: "-",
        O: "далеко",
        RA: 538,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "плохо",
        E: "badly",
        T: "D",
        A: "-",
        O: "плохо",
        RA: 539,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обычно",
        E: "usually",
        T: "D",
        A: "-",
        O: "обычно",
        RA: 545,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ясно",
        E: "clear, clearly",
        T: "D",
        A: "-",
        O: "ясно",
        RA: 550,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "скоро",
        E: "quickly, fast, soon",
        T: "D",
        A: "-",
        O: "скоро",
        RA: 562,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "завтра",
        E: "tomorrow",
        T: "D",
        A: "-",
        O: "завтра",
        RA: 565,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "однажды",
        E: "once, one day",
        T: "D",
        A: "-",
        O: "однажды",
        RA: 571,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мимо",
        E: "past, by",
        T: "R",
        A: "-",
        O: "мимо",
        RA: 572,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "иначе",
        E: "otherwise, differentlyl",
        T: "D",
        A: "-",
        O: "иначе",
        RA: 573,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сквозь",
        E: "through",
        T: "R",
        A: "-",
        O: "сквозь",
        RA: 579,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "бывший",
        E: "former, ex-",
        T: "T",
        A: "-",
        O: "бывший",
        RA: 595,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "данный",
        E: "given, present",
        T: "T",
        A: "-",
        O: "данный",
        RA: 601,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кстати",
        E: "to the point, at the same time",
        T: "D",
        A: "-",
        O: "кстати",
        RA: 602,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "трудно",
        E: "with difficulty",
        T: "D",
        A: "-",
        O: "трудно",
        RA: 608,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "прежде",
        E: "before",
        T: "R",
        A: "-",
        O: "прежде",
        RA: 610,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вместо",
        E: "instead",
        T: "R",
        A: "-",
        O: "вместо",
        RA: 615,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "легко",
        E: "easily, lightly",
        T: "D",
        A: "-",
        O: "легко",
        RA: 617,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "возможно",
        E: 'as… as possible(as in "as soon as possible")',
        T: "D",
        A: "-",
        O: "возможно",
        RA: 620,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "около",
        E: "by, near",
        T: "R",
        A: "-",
        O: "около",
        RA: 621,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сто",
        E: "hundred",
        T: "T",
        A: "-",
        O: "сто",
        RA: 623,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вовсе",
        E: "quiet",
        T: "D",
        A: "-",
        O: "вовсе",
        RA: 632,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мало",
        E: "little, few, not enough",
        T: "T",
        A: "-",
        O: "мало",
        RA: 637,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вниз",
        E: "down, downwards",
        T: "D",
        A: "-",
        O: "вниз",
        RA: 649,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "открытый",
        E: "open",
        T: "T",
        A: "-",
        O: "открытый",
        RA: 657,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "менее",
        E: "less",
        T: "D",
        A: "-",
        O: "менее",
        RA: 658,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "двое",
        E: "two",
        T: "T",
        A: "-",
        O: "двое",
        RA: 667,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спокойно",
        E: "quietly",
        T: "D",
        A: "-",
        O: "спокойно",
        RA: 671,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сорок",
        E: "forty",
        T: "T",
        A: "-",
        O: "сорок",
        RA: 678,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молодая",
        E: "Young",
        T: "T",
        A: "-",
        O: "молодая",
        RA: 686,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "столько",
        E: "so much, so many",
        T: "D",
        A: "-",
        O: "столько",
        RA: 697,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "шесть",
        E: "six",
        T: "T",
        A: "-",
        O: "шесть",
        RA: 712,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вон",
        E: "out",
        T: "D",
        A: "-",
        O: "вон",
        RA: 717,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "видимо",
        E: "apparently, seemingly",
        T: "D",
        A: "-",
        O: "видимо",
        RA: 719,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "достаточно",
        E: "enough, sufficiently",
        T: "D",
        A: "-",
        O: "достаточно",
        RA: 720,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "спасибо",
        E: "gratitude, thanks",
        T: "T",
        A: "-",
        O: "спасибо",
        RA: 741,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "молча",
        E: "silently, without a word",
        T: "D",
        A: "-",
        O: "молча",
        RA: 752,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сильно",
        E: "strongly",
        T: "D",
        A: "-",
        O: "сильно",
        RA: 753,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неожиданно",
        E: "unexpectedly, suddenly",
        T: "D",
        A: "-",
        O: "неожиданно",
        RA: 759,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вчера",
        E: "yesterday",
        T: "D",
        A: "-",
        O: "вчера",
        RA: 760,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "пожалуйста",
        E: "please",
        T: "T",
        A: "-",
        O: "пожалуйста",
        RA: 772,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "выше",
        E: "higher",
        T: "D",
        A: "-",
        O: "выше",
        RA: 773,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "поскольку",
        E: "so far as, since",
        T: "T",
        A: "-",
        O: "поскольку",
        RA: 787,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "впереди",
        E: "before, in front of",
        T: "R",
        A: "-",
        O: "впереди",
        RA: 805,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "много",
        E: "many, much, a lot of",
        T: "D",
        A: "-",
        O: "много",
        RA: 807,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вновь",
        E: "again, once more",
        T: "D",
        A: "-",
        O: "вновь",
        RA: 816,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нечего",
        E: "there is nothing, there is no need",
        T: "T",
        A: "-",
        O: "нечего",
        RA: 818,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "впервые",
        E: "for the first time",
        T: "D",
        A: "-",
        O: "впервые",
        RA: 820,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вызывать(see#663)",
        E: "to call, send",
        T: "T",
        A: "-",
        O: "вызывать(see#663)",
        RA: 830,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уверенный",
        E: "confident, sure",
        T: "T",
        A: "-",
        O: "уверенный",
        RA: 831,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "любимый",
        E: "dear, loved",
        T: "T",
        A: "-",
        O: "любимый",
        RA: 835,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "интересно",
        E: "interestingly",
        T: "D",
        A: "-",
        O: "интересно",
        RA: 845,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "зато",
        E: "but, but then",
        T: "C",
        A: "-",
        O: "зато",
        RA: 847,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "связанный",
        E: "related, connected, tied-up",
        T: "T",
        A: "-",
        O: "связанный",
        RA: 863,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обратно",
        E: "back, backwards",
        T: "D",
        A: "-",
        O: "обратно",
        RA: 891,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "семь",
        E: "seven",
        T: "T",
        A: "-",
        O: "семь",
        RA: 894,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "невозможно",
        E: "impossible",
        T: "D",
        A: "-",
        O: "невозможно",
        RA: 907,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "весьма",
        E: "very, highly, greatly",
        T: "D",
        A: "-",
        O: "весьма",
        RA: 915,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ибо",
        E: "for",
        T: "C",
        A: "-",
        O: "ибо",
        RA: 923,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "осторожно",
        E: "carefully, cautiously",
        T: "D",
        A: "-",
        O: "осторожно",
        RA: 926,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "правильно",
        E: "correctly, properly",
        T: "D",
        A: "-",
        O: "правильно",
        RA: 929,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "недавно",
        E: "recently, lately",
        T: "D",
        A: "-",
        O: "недавно",
        RA: 930,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "причём",
        E: "moreover, and what's more",
        T: "D",
        A: "-",
        O: "причём",
        RA: 932,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "неужели",
        E: "really, surely",
        T: "T",
        A: "-",
        O: "неужели",
        RA: 950,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "обязательно",
        E: "without fail, be sure",
        T: "D",
        A: "-",
        O: "обязательно",
        RA: 951,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вверх",
        E: "up, upwards",
        T: "D",
        A: "-",
        O: "вверх",
        RA: 952,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "столь",
        E: "so",
        T: "D",
        A: "-",
        O: "столь",
        RA: 957,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "странно",
        E: "strangely",
        T: "D",
        A: "-",
        O: "странно",
        RA: 968,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вскоре",
        E: "soon, shortly",
        T: "D",
        A: "-",
        O: "вскоре",
        RA: 969,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ради",
        E: "for the sake of",
        T: "R",
        A: "-",
        O: "ради",
        RA: 975,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "понятно",
        E: "clearly, plainly",
        T: "D",
        A: "-",
        O: "понятно",
        RA: 977,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "страшно",
        E: "terribly, awfully",
        T: "D",
        A: "-",
        O: "страшно",
        RA: 980,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наверно",
        E: "probably",
        T: "D",
        A: "-",
        O: "наверно",
        RA: 982,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "слегка",
        E: "slightly",
        T: "D",
        A: "-",
        O: "слегка",
        RA: 985,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "отсюда",
        E: "from here",
        T: "D",
        A: "-",
        O: "отсюда",
        RA: 1008,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "он",
        E: "he",
        T: "PN",
        A: "-",
        O: "он",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "его",
        E: "he",
        T: "PG",
        A: "-",
        O: "его",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "него",
        E: "he",
        T: "PG1",
        A: "-",
        O: "него",
        RA: 3,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ему",
        E: "he",
        T: "PD",
        A: "-",
        O: "ему",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "нему",
        E: "he",
        T: "PG1",
        A: "-",
        O: "нему",
        RA: 3,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "его",
        E: "he",
        T: "PA",
        A: "-",
        O: "его",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "него",
        E: "he",
        T: "PG1",
        A: "-",
        O: "него",
        RA: 3,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "им",
        E: "he",
        T: "PI",
        A: "-",
        O: "им",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "ним",
        E: "he",
        T: "PG1",
        A: "-",
        O: "ним",
        RA: 3,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нём",
        E: "he",
        T: "PP",
        A: "-",
        O: "нём",
        RA: 3,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "я",
        E: "I",
        T: "PN",
        A: "-",
        O: "я",
        RA: 5,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "меня",
        E: "I",
        T: "PG",
        A: "-",
        O: "меня",
        RA: 5,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мне",
        E: "I",
        T: "PD",
        A: "-",
        O: "мне",
        RA: 5,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "меня",
        E: "I",
        T: "PA",
        A: "-",
        O: "меня",
        RA: 5,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мной",
        E: "I",
        T: "PI",
        A: "-",
        O: "мной",
        RA: 5,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мне",
        E: "I",
        T: "PP",
        A: "-",
        O: "мне",
        RA: 5,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "что",
        E: "what",
        T: "PN",
        A: "-",
        O: "что",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чего",
        E: "what",
        T: "PG",
        A: "-",
        O: "чего",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чему",
        E: "what",
        T: "PD",
        A: "-",
        O: "чему",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "что",
        E: "what",
        T: "PA",
        A: "-",
        O: "что",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чем",
        E: "what",
        T: "PI",
        A: "-",
        O: "чем",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "чём",
        E: "what",
        T: "PP",
        A: "-",
        O: "чём",
        RA: 6,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "она",
        E: "she",
        T: "PN",
        A: "-",
        O: "она",
        RA: 14,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "её",
        E: "she",
        T: "PG",
        A: "-",
        O: "её",
        RA: 14,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "неё",
        E: "she",
        T: "PG1",
        A: "-",
        O: "неё",
        RA: 14,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ей",
        E: "she",
        T: "PD",
        A: "-",
        O: "ей",
        RA: 14,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "ней",
        E: "she",
        T: "PD1",
        A: "-",
        O: "ней",
        RA: 14,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "её",
        E: "she",
        T: "PA",
        A: "-",
        O: "её",
        RA: 14,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "неё",
        E: "she",
        T: "PA1",
        A: "-",
        O: "неё",
        RA: 14,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ей",
        E: "she",
        T: "PI",
        A: "-",
        O: "ей",
        RA: 14,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "ней",
        E: "she",
        T: "PP",
        A: "-",
        O: "ней",
        RA: 14,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "они",
        E: "they",
        T: "PN",
        A: "-",
        O: "они",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "их",
        E: "they",
        T: "PG",
        A: "-",
        O: "их",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "них",
        E: "they",
        T: "PG1",
        A: "-",
        O: "них",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "им",
        E: "they",
        T: "PD",
        A: "-",
        O: "им",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ним",
        E: "they",
        T: "PD1",
        A: "-",
        O: "ним",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "их",
        E: "they",
        T: "PA",
        A: "-",
        O: "их",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "них",
        E: "they",
        T: "PA1",
        A: "-",
        O: "них",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ими",
        E: "they",
        T: "PI",
        A: "-",
        O: "ими",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ними",
        E: "they",
        T: "PI1",
        A: "-",
        O: "ними",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "них",
        E: "they",
        T: "PP",
        A: "-",
        O: "них",
        RA: 17,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ты",
        E: "you",
        T: "PN",
        A: "-",
        O: "ты",
        RA: 20,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "тебя",
        E: "you",
        T: "PG",
        A: "-",
        O: "тебя",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тебе",
        E: "you",
        T: "PD",
        A: "-",
        O: "тебе",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тебя",
        E: "you",
        T: "PA",
        A: "-",
        O: "тебя",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тобой",
        E: "you",
        T: "PI",
        A: "-",
        O: "тобой",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тобою",
        E: "you",
        T: "PI1",
        A: "-",
        O: "тобою",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "тебе",
        E: "you",
        T: "PP",
        A: "-",
        O: "тебе",
        RA: 20,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "мы",
        E: "we",
        T: "PN",
        A: "-",
        O: "мы",
        RA: 22,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "нас",
        E: "we",
        T: "PG",
        A: "-",
        O: "нас",
        RA: 22,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нам",
        E: "we",
        T: "PD",
        A: "-",
        O: "нам",
        RA: 22,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нас",
        E: "we",
        T: "PA",
        A: "-",
        O: "нас",
        RA: 22,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нами",
        E: "we",
        T: "PI",
        A: "-",
        O: "нами",
        RA: 22,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нас",
        E: "we",
        T: "PP",
        A: "-",
        O: "нас",
        RA: 22,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вы",
        E: "you",
        T: "PN",
        A: "-",
        O: "вы",
        RA: 24,
        P: 0,
        G: "-",
        "": ""
    },
    {
        R: "вас",
        E: "you",
        T: "PG",
        A: "-",
        O: "вас",
        RA: 24,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вам",
        E: "you",
        T: "PD",
        A: "-",
        O: "вам",
        RA: 24,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вас",
        E: "you",
        T: "PA",
        A: "-",
        O: "вас",
        RA: 24,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вами",
        E: "you",
        T: "PI",
        A: "-",
        O: "вами",
        RA: 24,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "вас",
        E: "you",
        T: "PP",
        A: "-",
        O: "вас",
        RA: 24,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "себя",
        E: "self",
        T: "PG",
        A: "-",
        O: "себя",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "себе",
        E: "self",
        T: "PD",
        A: "-",
        O: "себе",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "себя",
        E: "self",
        T: "PA",
        A: "-",
        O: "себя",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собой",
        E: "self",
        T: "PI",
        A: "-",
        O: "собой",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "собою",
        E: "self",
        T: "PI1",
        A: "-",
        O: "собою",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "себе",
        E: "self",
        T: "PP",
        A: "-",
        O: "себе",
        RA: 39,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кто",
        E: "who",
        T: "PN",
        A: "-",
        O: "кто",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кого",
        E: "who",
        T: "PG",
        A: "-",
        O: "кого",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кому",
        E: "who",
        T: "PD",
        A: "-",
        O: "кому",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кого",
        E: "who",
        T: "PA",
        A: "-",
        O: "кого",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "кем",
        E: "who",
        T: "PI",
        A: "-",
        O: "кем",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ком",
        E: "who",
        T: "PP",
        A: "-",
        O: "ком",
        RA: 46,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наша",
        E: "our",
        T: "PNS",
        A: "F",
        O: "наша",
        RA: 65,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "нашей",
        E: "our",
        T: "PGS",
        A: "F",
        O: "нашей",
        RA: 65,
        P: 0,
        G: "F",
        "": "",
    },
    {
        R: "наш",
        E: "our",
        T: "PNS",
        A: "-",
        O: "наш",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашего",
        E: "our",
        T: "PGS",
        A: "-",
        O: "нашего",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашему",
        E: "our",
        T: "PDS",
        A: "-",
        O: "нашему",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашего",
        E: "our",
        T: "PAS",
        A: "-",
        O: "нашего",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашем",
        E: "our",
        T: "PIS",
        A: "-",
        O: "нашем",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашим",
        E: "our",
        T: "PPS",
        A: "-",
        O: "нашим",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наши",
        E: "our",
        T: "PNP",
        A: "-",
        O: "наши",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наших",
        E: "our",
        T: "PGP",
        A: "-",
        O: "наших",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашим",
        E: "our",
        T: "PDP",
        A: "-",
        O: "нашим",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наши",
        E: "our",
        T: "PAP",
        A: "-",
        O: "наши",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "наших",
        E: "our",
        T: "PIP",
        A: "-",
        O: "наших",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "нашими",
        E: "our",
        T: "PPP",
        A: "-",
        O: "нашими",
        RA: 65,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ничто",
        E: "nothing",
        T: "PN",
        A: "-",
        O: "ничто",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ничего",
        E: "nothing",
        T: "PG",
        A: "-",
        O: "ничего",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ничему",
        E: "nothing",
        T: "PD",
        A: "-",
        O: "ничему",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ничто",
        E: "nothing",
        T: "PA",
        A: "-",
        O: "ничто",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ничем",
        E: "nothing",
        T: "PI",
        A: "-",
        O: "ничем",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ни",
        E: "nothing",
        T: "PP",
        A: "-",
        O: "ни",
        RA: 84,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никто",
        E: "nobody",
        T: "PN",
        A: "-",
        O: "никто",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никого",
        E: "nobody",
        T: "PG",
        A: "-",
        O: "никого",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никому",
        E: "nobody",
        T: "PD",
        A: "-",
        O: "никому",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никого",
        E: "nobody",
        T: "PA",
        A: "-",
        O: "никого",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "никем",
        E: "nobody",
        T: "PI",
        A: "-",
        O: "никем",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ни",
        E: "nobody",
        T: "PP",
        A: "-",
        O: "ни",
        RA: 134,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "оно",
        E: "it",
        T: "PN",
        A: "-",
        O: "оно",
        RA: 285,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "сей",
        E: "this",
        T: "PN",
        A: "-",
        O: "сей",
        RA: 598,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "всякое",
        E: "any, every",
        T: "PN",
        A: "-",
        O: "всякое",
        RA: 627,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "что-то",
        E: "something",
        T: "P",
        A: "-",
        O: "что-то",
        RA: 1015,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "занят",
        E: "busy",
        T: "ASM",
        A: "-",
        O: "занят",
        RA: 1016,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "устал",
        E: "tired",
        T: "ASM",
        A: "-",
        O: "устал",
        RA: 1017,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "уверен",
        E: "sure",
        T: "ASM",
        A: "-",
        O: "уверен",
        RA: 1018,
        P: 0,
        G: "-",
        "": "",
    },
    {
        R: "ваша",
        E: "yours",
        T: "PNS",
        A: "F",
        O: "ваша",
        RA: 143,
        P: 0,
        G: "F",
        "": "",
    },
];


const def = {
    wRank: wRank,
    words: words
}
export default def; 