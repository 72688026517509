import React, {useState} from 'react';
import EditorNavbar from '../EditorNavbar';
import Stories from './Stories';
/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function StoryEditor() {
  return (
    <div className="flex flex-col h-screen w-full">
      <Stories></Stories>
    </div>
  );
}