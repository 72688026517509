import React, { useState, useEffect} from 'react';

import { getFunctions, httpsCallable } from "firebase/functions";

import { ReactComponent as Spinner } from '../svg/spinner.svg';
import SpeechBubble from '../util/SpeechBubble';
import { CSSTransition,TransitionGroup } from 'react-transition-group';
import StoryView from './StoryView';
import Navbar from '../navbar/Navbar';
import language from '../language.json';
import {IoFlag, IoPlayBackCircleOutline} from 'react-icons/io5';
import Flag from './Flag';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { fireEvent } from '@testing-library/react';
import KeySymbol from './KeySymbol';
import {Howl} from 'howler';
import SpeechBubbleLocal from '../util/SpeechBubble_local';

/**
 * component with all signup functionality
 * @return {object} jsx signup in component
 */

const STATE_FEEDBACK = "feedback";
const STATE_ASK = "ask";
const STATE_NEW = "new";
const STATE_START = "start";
const STATE_LOADING = "loading";
const STATE_END = "end"
const STATE_CHOICE = "choice"

const MODE_STORY = "story";

let currentId;
let currentMode;
let sentences = [];
let context;

let last_time_stamp = 0;

let colorCarousell = ["blue","red","lime","fuchsia","orange",];
let colorCounter = 0;

let speechbubble1 = {};
const audioSuccess = new Howl({
  src: "https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/success.mp3?alt=media&token=08afbf66-1fe7-40c7-90ec-3614edacf2bb",
  html5: true,
});


export default function QuizView_1({givenStates,lang}) {
  let [textBubbles, setTextBubbles] = useState([]);
  let [state, setState] = useState(STATE_START);
  let [current, setCurrent] = useState(0);
  let [isOpen, setIsOpen] = useState(false);
  let [playing, setPlaying] = useState(true);

  sentences = givenStates

  const functions = getFunctions();
  const getBlock = httpsCallable(functions, 'getBlock');
  
  useEffect(() => {
    console.log(givenStates);
    if(state === STATE_END){
      setState(STATE_START);
    }else{
      getWelcomeBlock();
    }
  },[]);

  async function getWelcomeBlock(){

    let block = [];

    block.push({
        "text": "Hi",
        "direction": 9,
        'correct': false,
        'primary': true,
        'hide': false
      });



      block.push({
        "text": "press on start to begin",
        "direction": 1,
        'correct': false,
        'primary': true,
        'hide': false
      });

    console.log(block);

    autoplayStates(block)
  }



  function getLang(direction){
    if(direction===1){
      return lang;
    }else if(direction===0){
      return lang;
    }else if(direction===3){
      return "en-US";
    }else if(direction===4){
      return "en-US";
    }else if(direction===5){
      return lang;
    }else if(direction===6){
      return lang;
    }else{
      return "en-US";
    }
  }

  useEffect(() => {
    document.addEventListener('keydown',keyEvent);
    return () => {
      document.removeEventListener("keydown", keyEvent);
    };
  },[state,current]);


  const keyEvent = (event) => {
    if(!isOpen){
      console.log(state);
      if(event.key === " "){
        if(state===STATE_NEW||state===STATE_ASK||state===STATE_START){
          onNext();
        }
      }else if(event.key === "f"){
        if(state===STATE_FEEDBACK)
          onRight();
      }else if(event.key === "d"){
        if(state===STATE_FEEDBACK){
          onWrong();
        }else if(state===STATE_CHOICE){
          loadBlock();
        }
      }else if(event.key === "a"){
        if(state!==STATE_LOADING){
          back();
        }
      }
    }
  }

  function playAudio(c){
    if(typeof speechbubble1.pause === 'function'){
      speechbubble1.pause();
    }
    speechbubble1 = new Howl({
      src: sentences[c].audio,
      html5: true,
      autoplay: true,
      onend: function(){
        setPlaying(false);
      },
      onstop: function(){
        setPlaying(false);
      },
      onplay: function(){
        setPlaying(true);
      }
    });
  }

  function back(){
    if(textBubbles.length>3){
      let tB = [...textBubbles];
      tB.pop();
      setTextBubbles(tB);
      setCurrent(current-1);
      setStateQuestionOrInfo(tB[tB.length-1].direction);
    }
  }

  async function onNext(){
    
    // check if not last element to continue
    if(current < sentences.length){
      // set state by bubble type
      // in current is already the next bubble type saved
      if(sentences[current].direction === 5 && state !== STATE_CHOICE){
        setState(STATE_CHOICE);
      } else if (sentences[current].direction === 5 && state === STATE_CHOICE){
        setState(STATE_ASK);
        let tB = [...textBubbles];
        let s = sentences[current+1];
        tB.push(s);
        setTextBubbles(tB);
        setCurrent(current+2);
        setStateQuestionOrInfo(sentences[current].direction);
      } else{
        if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
          if(sentences[current].audio){

            playAudio(current);
          }
        }
        
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);
        setCurrent(current+1);
        setStateQuestionOrInfo(sentences[current].direction);
      }
      
    }else{
      // reset 
      setCurrent(0)
      setState(STATE_END);

      autoplayStates(getFinishedTBs());
    }
  }

  async function loadBlock(){
    setState(STATE_LOADING);
    let configObj = {storyId:null};
    configObj.storyId = sentences[current].text; // only ever checks the first element
    configObj.lang = `${"en-US"}-${lang}`
    let block = await getBlock(configObj);

    // init the audio in the block states
    let audioFiles = [];

    colorCounter = 0;

    block = block.data.map((item)=>{
      if(item.direction === 6){
        item.audio = [];
        item.color = colorCarousell[colorCounter];
        if(item.fragments && item.uuid && item.hash){
          for(let i = 0; i < item.fragments.length; i++){
            item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
          }
        }
        
      }else{
        item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
      }
      
      audioFiles.push({ url: `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}` });
      return item
    });

    colorCounter++;

    
   
    // add the block states to the sentences
    sentences.splice(current, 1); // remove the block element to not call it again
    sentences.splice(current, 0, ...block);
    let tB = [...textBubbles];
    let s = sentences[current];
    tB.push(s);
    setTextBubbles(tB);

    if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
      if(sentences[current].audio){

        playAudio(current);
      }
    }

    // ready for next state
    setStateQuestionOrInfo(sentences[current].direction);
    setCurrent(current+1);
    setState(STATE_NEW);
  }

  

  async function loadBlockLink(blID){
    setState(STATE_LOADING);
    let configObj = {storyId:null};
    configObj.storyId = blID; // only ever checks the first element
    configObj.lang = `${"en-US"}-${lang}`
    let block = await getBlock(configObj);

    // init the audio in the block states
    let audioFiles = [];
    block = block.data.map((item)=>{
      if(item.direction === 6){
        item.audio = [];
        item.color = colorCarousell[colorCounter];
        if(item.fragments && item.uuid && item.hash){
          for(let i = 0; i < item.fragments.length; i++){
            item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
          }
        }
      }else{
        item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
      }
      audioFiles.push({ url: `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}` });
      return item
    });

    colorCounter++;

   
    // add the block states to the sentences
    sentences.splice(current, 0, ...block);
    let tB = [...textBubbles];
    let s = sentences[current];
    tB.push(s);
  
    setTextBubbles(tB); 

    if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
      if(sentences[current].audio){

        playAudio(current);
      }
    }

    // ready for next state
    setStateQuestionOrInfo(sentences[current].direction);
    setCurrent(current+1);
    setState(STATE_NEW);
  }

  async function autoplayStates(st){
    let tB = [...textBubbles];
    for(let i = 0; i < st.length; i++){
      let tB1 = [...tB];
      tB1.push(st[i]);
      tB = tB1;
      setTextBubbles(tB1);
      await delay(1000);
    }
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

 
  async function onWrong(){
    
    if(sentences[current-1].deconstruct){
      if(sentences[current-1].deconstruct[0].direction===5){

        // get block from server
        setState(STATE_LOADING);
        let configObj = {storyId:null};
        configObj.storyId = sentences[current-1].deconstruct[0].text; // only ever checks the first element
        configObj.lang = `${"en-US"}-${lang}`
        let block = await getBlock(configObj);

        console.log("block data",block.data);

        // init the audio in the block states
        colorCounter = 0;
        block = block.data.map((item)=>{
          if(item.direction === 6){
            item.audio = [];
            item.color = colorCarousell[colorCounter];
            if(item.fragments && item.uuid && item.hash){
              for(let i = 0; i < item.fragments.length; i++){
                item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
              }
            }
          }else{
            item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
          }
          return item
        });

        colorCounter++;

        console.log(block);
        // check if there are more blocks in the deconstruct and append them to the block
        // to be encountered in the next function

        if(sentences[current-1].deconstruct.length > 1){
          sentences[current-1].deconstruct.shift();
          block = [...block, ...sentences[current-1].deconstruct]
        }

        // add the block states to the sentences
        sentences.splice(current, 0, ...block);
        delete sentences[current-1].deconstruct;
        sentences.splice(current+block.length, 0, ...[sentences[current-2],sentences[current-1]]);
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);

        // ready for next state
        setStateQuestionOrInfo(sentences[current].direction);
        setCurrent(current+1);
        setState(STATE_NEW);
      }else{
        sentences.splice(current, 0, ...sentences[current-1].deconstruct);
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);
        setStateQuestionOrInfo(sentences[current].direction);
        setCurrent(current+1);
      }
      
    }else{
      let tB = [...textBubbles];
      let s = sentences[current-2];
      tB.push(s);
      setTextBubbles(tB);
      setCurrent(current-1);
      // set state by bubble type
      setStateQuestionOrInfo(sentences[current].direction);
    }
  }

  async function onRight(){
    audioSuccess.play()
    onNext();
  }
  
  function getFinishedTBs(){
    let tB = [];

    tB.push({
        "text": "Hi",
        "direction": 9,
        'correct': false,
        'primary': true,
        'hide': false
      });

      tB.push({
        "text": "Hi",
        "direction": 10,
        'correct': false,
        'primary': true,
        'hide': false
      });
    return tB;
  }

  function setStateQuestionOrInfo(bubbleType){
    let lastType = -1;
    if(current>0){
      lastType = sentences[current-1].direction;
    }
    
    switch(bubbleType){
      case 1: // normal left
        if(lastType===3||lastType===4){
          setState(STATE_FEEDBACK)
        }else{
          setState(STATE_ASK)
        }
        
        break;
      case 0: // normal right
        if(lastType===3||lastType===4){
          setState(STATE_FEEDBACK)
        }else{
          setState(STATE_ASK)
        }
        break;
      case 2: // center
        setState(STATE_ASK)
        break;
      case 3: // annotation left
        setState(STATE_ASK)
        break;
      case 4: // annotation right
        setState(STATE_ASK)
        break;
      default:
        break;
    }
  }


  function onBlock(blID){
    if(blID.startsWith("LINK_")){
      window.open("https://"+blID.substring(5), '_blank', 'noopener,noreferrer');
    }else if(blID.startsWith("YOUTUBE_")){
      window.open("https://"+blID.substring(8), '_blank', 'noopener,noreferrer');
    }else{
      loadBlockLink(blID);
    }
  }

  function hasDeconstruct(){
    if(sentences[current-1]){
      if (sentences[current-1].hasOwnProperty('deconstruct')){
        if (sentences[current-1].deconstruct[0].direction === 5){
          if (sentences[current-1].deconstruct[0].btn){
            return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{sentences[current-1].deconstruct[0].btn}<KeySymbol color={"sky"} symbol={"D"}/></p>;
          } else {
            return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["explain"]["en-US"]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
          }
          
        }else{
          return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["train sentence"]["en-US"]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
        }
      }else{
        return <p onClick={onWrong} className="btn-action bg-red-200 flex flex-1 text-center text-red-500 dark:bg-red-700 dark:text-red-200">{language["I didn't know (n)"]["en-US"]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
      }
    }
    
  }

  function hasNextBlock(){
    if(sentences[current]){
      if (sentences[current].direction === 5){
        if (sentences[current].btn){
          return <p onClick={loadBlock} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{sentences[current].btn}<KeySymbol color={"sky"} symbol={"D"}/></p>;
        } else {
          return <p onClick={onNext} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["explain"]["en-US"]}</p>;
        }
      }
    }
  }

//<p className="animate-spin bg-slate-300 rounded-full border-2 border-slate-300 m-2 text-slate-700 font-bold"><Spinner className="spinner h-12 w-12 "/></p>

  return (
    <div className="flex flex-col h-full w-full select-none sm:select-auto">
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col absolute_height overflow-visible pb-2">
            <div className="relative scroll-height w-full h-full">
              <TransitionGroup component="div" className="w-full absolute bottom-0 left-0">
                {textBubbles.map((item, index) => {
                      return <CSSTransition key={index} timeout={700} classNames="item">
                          <SpeechBubbleLocal index={index} 
                            length={textBubbles.length} 
                            audio={item.audio} 
                            onBlock={onBlock}
                            color={item.color}
                            playing={playing}
                            lang={lang}
                            primary={item.primary}
                            onPlay={()=>{speechbubble1.playing()?speechbubble1.stop():speechbubble1.play()}}
                            correct={item.correct} 
                            fragments={item.fragments}
                            direction={item.direction} 
                            hide={item.hide} 
                            text={(item.text)}/>
                        </CSSTransition>
                })}
              </TransitionGroup>
             
            </div>
          </div>
          <div className="w-full p-2 text-slate-600 select-none">
              <div className=" container flex flex-row justify-center">
  
              {(() => {
                  switch (state) {
                    case STATE_LOADING:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                               <div className="lds-ripple"><div></div><div></div></div>
                            </div>
                    case STATE_NEW:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                               <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                <p onClick={onNext} className="btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["ok"]["en-US"]}<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                            </div>
                    case STATE_START:
                        return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                  <p onClick={onNext} className="btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">start</p>
                              </div>
                    case STATE_ASK:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                <p onClick={onNext} className="btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["next"]["en-US"]}<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                            </div>
                    case STATE_CHOICE:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                  <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                  {hasNextBlock()}
                                  <p onClick={onNext} className="btn-action bg-lime-200 text-lime-700 dark:bg-lime-900 dark:text-lime-200">skip<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                              </div>
                    case STATE_FEEDBACK:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                  <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                  {hasDeconstruct()}
                                  <p onClick={onRight} className="btn-action bg-lime-200 whitespace-nowrap text-lime-700 dark:bg-lime-900 dark:text-lime-200">{language["I knew (y)"]["en-US"]}<KeySymbol color={"lime"} symbol={"F"}/></p>
                              </div>
                    case STATE_END:
                      return <></>
                    default:
                      return null
                  }
                })()}
                
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
