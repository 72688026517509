import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import Flag from "../learn/Flag";
import Navbar from "./Navbar";
import BotView from "./BotView";
import { ReactComponent as Spinner } from "../svg/spinner.svg";
import {
  collection,
  addDoc,
  setDoc,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { query, orderBy, limit } from "firebase/firestore";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import ChatSpeechBubble from "./ChatSpeechBubble";

const STATE_LOADING = "loading";
const STATE_NO_ACCESS = "noAccess";
const STATE_START = "start";
const STATE_NEXT = "next";
const STATE_LOAD_NEXT = "load_next";

let sentences = [];
let conversation = [];

export const TargetLangDictionary = {
  "en-US": "English",
  "es-ES": "Spanish",
  "fr-FR": "French",
  "de-DE": "German",
  "it-IT": "Italian",
  "pt-PT": "Portuguese",
  "ru-RU": "Russian",
  "jp-JP": "Japanese",
};

let selectedBotData = {};

export default function Quiz() {
  let [textBubbles, setTextBubbles] = useState([]);
  let [state, setState] = useState(STATE_LOADING);
  let [bot, setSelctedBot] = useState(
    localStorage.getItem("BOT_STORAGE_ID")
      ? localStorage.getItem("BOT_STORAGE_ID")
      : "HcVm5gME0IrlzSunqGRw"
  );
  let [botIcon, setBotIcon] = useState();
  let [current, setCurrent] = useState(0);
  let [topics, setTopics] = useState();
  let [currentTopics, setCurrentTopics] = useState();
  let [words, setWords] = useState([]);
 
  const { currentUserState, userConfig, currentUser } = useAuth();
  let [role, setRole] = useState();

  let [xp, setXP] = useState(currentUserState.xp);
  let [wordRank, setWordRank] = useState(
    currentUserState.wordRank ? currentUserState.wordRank : {}
  );

  const functions = getFunctions();
  const getConversation = httpsCallable(functions, "getConversation");
  const addWordRank = httpsCallable(functions, "addWordRank");

  useEffect(() => {
    checkTrial();
  }, [currentUser, currentUserState]);

  async function getTopics() {
    // get one document from the topics collection sorted by date, only get the latest
    const citiesRef = collection(getFirestore(), "topics");

    const q = query(citiesRef, orderBy("sort"), limit(1));

    const querySnapshot = await getDocs(q);
    let tempTopics = [];
    querySnapshot.forEach((doc) => {
      setCurrentTopics(doc.data().topics);
      tempTopics = doc.data().topics;
    });

    return tempTopics;
  }

  useEffect(() => {
    if (localStorage.getItem("BOT_STORAGE_ID")) {
      botSelected(bot);
    }

    if (localStorage.getItem("WORDS_" + userConfig.target_lang)) {
      setWords(
        JSON.parse(localStorage.getItem("WORDS_" + userConfig.target_lang))
      );
    } else {
      // get doc "ru-RU" from words collection
      fetch();
    }

    async function fetch() {
      const docSnap = await getDoc(
        doc(getFirestore(), "words", userConfig.target_lang)
      );
      localStorage.setItem(
        "WORDS_" + userConfig.target_lang,
        JSON.stringify(docSnap.data().wRank)
      );
      setWords(docSnap.data().wRank);
    }
  }, []);

  async function getCustomClaimRole() {
    await currentUser.getIdToken(true);
    const decodedToken = await currentUser.getIdTokenResult();
    setRole(decodedToken.claims.stripeRole);
    return decodedToken.claims.stripeRole;
  }

  async function checkTrial() {
    let created = currentUser.metadata.createdAt * 1;
    let trialPeriod = 0;
    if (currentUserState.access) {
      trialPeriod = currentUserState.access;
    }

    let time = Date.now();
    if (created + trialPeriod < time) {
      let role = await getCustomClaimRole();
      if (role === "unlimited") {
      } else {
        setNoAccess();
      }
    } else {
      setRole("free");
    }
  }

  async function botSelected(botId) {
    setSelctedBot(botId);
    localStorage.setItem("BOT_STORAGE_ID", botId);
    let st = await getDoc(doc(getFirestore(), "chat", botId));
    let botData = st.data();

    selectedBotData = botData;
    setBotIcon(botData.icon);
    setTopics(botData.topics);
    let topics = null;
    if (currentTopics) {
      topics = currentTopics;
    } else {
    }

    topics = await getTopics();
    sentences = [];
    setTextBubbles([
      {
        data: {
          direction: 9,
          name: botData.name,
          description: botData.description,
          icon: botData.icon,
          topics: botData.topics,
          currentTopics: topics,
        },
      },

      {
        data: {
          direction: 12,
          name: botData.name,
          description: botData.description,
          icon: botData.icon,
          topics: botData.topics,
          currentTopics: topics,
        },
      },
    ]);
    setState(STATE_START);
  }

  async function updateWordRank(wr) {
    let authToken = await getAuth().currentUser.getIdToken(true);
    addWordRank({authToken:authToken, wordRank: wr});
  }

  function onStart(topic) {
    setState(STATE_LOADING);

    //bot.prompt.replace("{{target_lang}}",TargetLangDictionary[userConfig.target_lang])
    const params = {
      botId: bot,
      context: {
        name: "Joshua",
        targetLang: TargetLangDictionary[userConfig.target_lang],
        sourceLang: "English",
      },
      topic: topic,
      conversation: [],
    };

    if(words.length > 0){
      console.log("word Rank",wordRank, wordRank[userConfig.target_lang]);
      let wr;

      if(wordRank[userConfig.target_lang] === undefined){
        wordRank[userConfig.target_lang] = 0;
        console.log("update word rank", wordRank);
        updateWordRank(wordRank);
        wr = 0;
      }else{
        wr = wordRank[userConfig.target_lang];
      }
      params.context.currentWord = words[wr];
    }

    getConversation(params).then((result) => {
      try {
        let data = result.data.response;
        conversation.push(data);
        data = data.split("\n");
        data = data.map((item) => {
          return item.trim();
        });
        data = data.map((item) => {
          return item.split("(");
        });
        data = data.filter((item) => {
          return item.length > 0 && item[0] !== "";
        });
        let data_target = data.map((item) => {
          return item[0];
        });
        let data_source = data.map((item) => {
          return item[1];
        });
        data_target = data_target.map((item) => {
          return item.split(":")[1];
        });
        data_source = data_source.map((item) => {
          if (item.includes(":")) {
            return item.split(":")[1].slice(0, -1);
          } else {
            return item.slice(0, -1);
          }
        });

        // remove quotation mark if at the beginning or end of strong of all items in data source
        data_source = data_source.map((item) => {
          item = item.replace('"', "");
          item = item.trim();
          return item;
        });

        data_target = data_target.map((item) => {
          item = item.replace('"', "");
          item = item.trim();
          return item;
        });

        textBubbles.push({
          data: {
            direction: 1,
            text: data_target[0],
            translation: data_source[0],
            hide: false,
            primary: false,
            icon: botIcon,
            topic: topic,
            targetLang: TargetLangDictionary[userConfig.target_lang],
          },
        });

        sentences.push({
          data: {
            direction: 0,
            text: data_target[1],
            hide: false,
            primary: false,
            translation: data_source[0],
            topic: topic,
            targetLang: TargetLangDictionary[userConfig.target_lang],
          },
        });

        for (let i = 2; i < data_target.length; i++) {
          if (i % 2 === 0) {
            sentences.push({
              data: {
                direction: 1,
                text: data_target[i],
                translation: data_source[i],
                hide: false,
                primary: false,
                icon: botIcon,
                topic: topic,
                targetLang: TargetLangDictionary[userConfig.target_lang],
              },
            });
          } else {
            if (true) {
              sentences.push({
                data: {
                  direction: 0,
                  text: data_target[i],
                  translation: data_source[i],
                  hide: false,
                  primary: false,
                  topic: topic,
                  targetLang: TargetLangDictionary[userConfig.target_lang],
                },
              });
            } else {
              sentences.push({
                data: {
                  direction: 4,
                  text: `say: "` + data_source[i].trim() + `"`,
                  hide: false,
                  primary: false,
                  targetLang: TargetLangDictionary[userConfig.target_lang],
                },
              });

              sentences.push({
                data: {
                  direction: 0,
                  text: data_target[i],
                  hide: false,
                  primary: false,
                  targetLang: TargetLangDictionary[userConfig.target_lang],
                },
              });
            }
          }
        }

        setTextBubbles(textBubbles);

        setState(STATE_NEXT);
      } catch (error) {
        onStart(topic);
      }
    });
  }

  function onNext() {
    // check if not last element to continue
    if (current < sentences.length) {
      let tB = [...textBubbles];
      let s = sentences[current];
      tB.push(s);
      setTextBubbles(tB);
      setCurrent(current + 1);
      if (current === sentences.length - 1) {
        newStory();
      }
    } else {
      newStory();
    }
  }

  function newStory() {
    let tB = [...textBubbles];
    let s = {
      data: {
        direction: 12,
        name: selectedBotData.name,
        description: selectedBotData.description,
        icon: selectedBotData.icon,
        topics: selectedBotData.topics,
        currentTopics: currentTopics,
      },
    };
    tB.push(s);
    setTextBubbles(tB);
    setState(STATE_START);
  }

  function setNoAccess() {
    setState(STATE_NO_ACCESS);
    setTextBubbles([
      {
        data: {
          direction: 11,
          correct: false,
          hide: false,
        },
      },
    ]);
  }

  return (
    <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
      <Navbar
        role={role}
        xp={xp}
        percentage={`${(current / sentences.length) * 100}%`}
      >
        {Object.keys(wordRank).length > 0 ?<div className=" hidden font-bold bg-gray-200 h-10 -mr-2 flex flex-row items-center rounded-full">
          <div className="mx-2">Words{" "}
          {wordRank[userConfig.target_lang]
            ? wordRank[userConfig.target_lang]
            : 0}
          {" / "}
          {words.length}
          </div>
        </div>:""}
      </Navbar>
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <BotView
          select={(botId) => {
            botSelected(botId);
          }}
        ></BotView>
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col absolute_height overflow-visible pb-2">
            <div className="relative scroll-height w-full h-full">
              <TransitionGroup
                component="div"
                className="w-full absolute bottom-0 left-0"
              >
                {textBubbles.map((item, index) => {
                  return (
                    <CSSTransition key={index} timeout={700} classNames="item">
                      <ChatSpeechBubble
                        index={index}
                        length={textBubbles.length}
                        onTopic={(topic) => {
                          onStart(topic);
                        }}
                        data={item.data}
                      />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </div>
          </div>
          <div className="w-full p-2 text-slate-600 select-none">
            <div className=" flex flex-row justify-center">
              {(() => {
                switch (state) {
                  case STATE_LOADING:
                    return (
                      <div className="flex flex-row items-center pb-20 sm:pb-4">
                        <div className="lds-ripple">
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    );
                  case STATE_START:
                    return "select a topic";
                  case STATE_NEXT:
                    return (
                      <p
                        onClick={onNext}
                        className="cursor-pointer animate-pulse bg-slate-200 dark:bg-slate-500 rounded-full border-2 border-slate-400 p-4 m-2 text-slate-600 dark:text-white font-bold"
                      >
                        next
                      </p>
                    );
                  case STATE_LOAD_NEXT:
                    return (
                      <p
                        onClick={onStart}
                        className="cursor-pointer animate-pulse bg-slate-200 dark:bg-slate-500 rounded-full border-2 border-slate-400 p-4 m-2 text-slate-600 dark:text-white font-bold"
                      >
                        load next
                      </p>
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
