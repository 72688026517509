import React from 'react';
import PropTypes from 'prop-types';
import {
    IoTrashOutline
} from 'react-icons/io5';
import { FlagTypes } from './FlagManager';
import { collection, addDoc, setDoc, doc, getDoc, deleteDoc , getFirestore } from "firebase/firestore"; 

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function FlagView({flag}) {
  /**
   * opens popup to add document in firebase
   */

  function del(){
    deleteDoc(doc(getFirestore(), "Flags", flag.id));
  }

  return (
    <div className="flex flex-col h-full w-full dark:bg-slate-900 dark:text-slate-200 text-slate-500">
        <div className="flex flex-row w-full items-center border-slate-200 dark:border-slate-700 border-b px-4 justify-between h-14">
            <p>{FlagTypes[flag.type].name}</p>
            <div className=" flex flex-1 flex-row justify-end items-center ">
                <IoTrashOutline onClick={del} className="cursor-pointer text-red-500 mr-4 h-6 w-6"/>
            </div>
        </div>  
        <div className="p-4 flex-col flex">
            <div className="w-full flex flex-row">
                <p className="pr-4 font-bold">{flag.context.origin_lang}</p>
                <p className="pr-4 font-bold">{flag.context.target_lang}</p>
                <p className="pr-4 font-bold">{flag.context.current_id}</p>
                <p className="pr-4 font-bold">{flag.context.current_state}</p>
                <p className="pr-4 font-bold">{new Date(flag.date).toLocaleString('en-GB')}</p>
            </div>
            <p className="py-2 text-sky-500">{flag.text}</p>
            <div>
                {flag.context.sentences.map((state, index)=>{
                    return <p key={index} className={`${flag.context.current_state===index?"font-bold":""}`}>{state.text}</p>
                })}
            </div>
        </div>
    </div>
  );
}