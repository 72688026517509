import { Popover } from '@headlessui/react'
import React, {useState, useEffect} from 'react';
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore"; 
import {db} from '../firebase.js';
import { useAuth } from '../auth/AuthContext';
import { ReactComponent as RussianFlag } from "../svg/flag_ru.svg";
import { ReactComponent as SpanishFlag } from "../svg/flag_es.svg";
import { ReactComponent as FrenchFlag } from "../svg/flag_fr.svg";
import { ReactComponent as GermanFlag } from "../svg/flag_de.svg";
import { ReactComponent as ItalianFlag } from "../svg/flag_it.svg";
import { ReactComponent as JapaneseFlag } from "../svg/flag_jp.svg";
import { IoEyeOutline, IoEyeOffOutline, IoFlaskOutline } from "react-icons/io5";

/* complex language drop down
<div className={`cursor-pointer hover:bg-slate-200 ${getRounded(props.pos,props.len)} flex flex-row items-center h-10 p-2`} onClick={() => {changeLanguage(props.originLang,props.targetLang);}}>
            <Flag lang={props.originLang} className="h-6 w-6 pl-2"/>
            <IoArrowForwardOutline className="h-4 w-4 text-slate-400 mx-1"/>
            <Flag lang={props.targetLang} className="h-6 w-6 pr-2"/>
            <StateIcon state={props.state} className="h-6 w-6 pl-2 text-slate-400 mr-2"></StateIcon>
        </div>*/

const USER_STORAGE_CONFIG = "user_storage_config";

export function LanguageDropDown() {

    const {userConfig,currentUser} = useAuth();

    function setSelected(target){
        console.log("set selected", target);
        setDoc(doc(getFirestore(), "user_config", currentUser.uid), {
            origin_lang: userConfig.origin_lang,
            target_lang: target, 
          },{merge: true});   
    }

  return (
    <div className="flex flex-row pl-4 cursor-pointer">
         <JapaneseFlag
        className={`${
            userConfig.target_lang === "jp-JP"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("jp-JP");
        }}
        ></JapaneseFlag>
        <ItalianFlag
        className={`${
            userConfig.target_lang === "it-IT"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("it-IT");
        }}
        ></ItalianFlag>
        <GermanFlag
        className={`${
            userConfig.target_lang === "de-DE"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("de-DE");
        }}
        ></GermanFlag>
        <FrenchFlag
        className={`${
            userConfig.target_lang === "fr-FR"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("fr-FR");
        }}
        ></FrenchFlag>
        <RussianFlag
        className={`${
            userConfig.target_lang === "ru-RU"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("ru-RU");
        }}
        ></RussianFlag>
        <SpanishFlag
        className={`${
            userConfig.target_lang === "es-ES"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
            setSelected("es-ES");
        }}
        ></SpanishFlag>
    </div>
  )
}

