import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BsPatchCheckFill } from "react-icons/bs";
import AudioPlayer from './AudioPlayer';
import ReactMarkdown from 'react-markdown';
import { IoLinkOutline,IoChatbubblesOutline,
  IoLogoYoutube } from 'react-icons/io5';
import { ReactComponent as Blue } from '../svg/blue_chat.svg';
import { ReactComponent as Jim } from '../svg/jim_p.svg';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';
import { ReactComponent as HiBlue } from '../svg/blue_hi.svg';

/**
 * returns component for
 * @return {object} jsx documents component
 */

 function getDirection(direction){
  if(direction===1){
    return "justify-start";
  }else if(direction===0){
    return "justify-end";
  }else if(direction===3){
    return "justify-start";
  }else if(direction===4){
    return "justify-end";
  }else{
    return "justify-center";
  }
}

function get_bubble_class(index, length, direction){
  if(direction===1){
    if(index===length-1){
      return "sb-left2";
    }else if(index===length-2){
      return "sb-left1";
    }else{
      return "sb-left";
    }
  }else if(direction===0){
    if(index===length-1){
      return "sb-right2";
    }else if(index===length-2){
      return "sb-right1";
    }else{
      return "sb-right";
    }
  }else{
    return "";
  }
}

function getDesign(direction, position){
  if(direction===1){
    return "sb";
  }else if(direction===0){
    return "sb";
  }else if(direction===3){
    if(position){
      return "sb-annotation-left-small text-slate-700 dark:text-slate-300"; 
    }else{
      return "sb-annotation-left text-slate-700 dark:text-slate-300";
    }
    
  }else if(direction===4){
    if(position){
      return "sb-annotation-right-small text-slate-700 dark:text-slate-300"; 
    }else{
      return "sb-annotation-right text-slate-700 dark:text-slate-300";
    }
  }else{
    return "border-4 border-dotted p-4 border-slate-200 dark:border-slate-700 dark:text-slate-300 font-bold";
  }
}

export function MDSpeechbubble({lang,fragments,audio,color,onBlock,text}){
  function getAudioLink(text){
    if(fragments && audio){
      for(let i = 0; i < fragments.length; i++){
        if(fragments[i] === text[0]){
          return <AudioPlayer inactive={400} color={"amber"} size={6} lang={lang} audio={audio[i]} play={false}><div className="inline-flex flex-row items-center"><div className="">{text[0]}</div></div></AudioPlayer>;
        }
      }
    }
   
    return text[0];
  }

  function getLink(href, children, ...props){
    if(href.startsWith("STORY_")){
      return <div className='p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="mr-2">
                {children[0]}
              </div>
              <IoChatbubblesOutline className="w-6 h-6"></IoChatbubblesOutline>
        </div>
      </div>
    }else if(href.startsWith("LINK_")){
      return <div className='p-1 underline rounded inline text-sky-500 dark:bg-sky-900 dark:text-sky-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="">
                {children[0]}
              </div>
        </div>
      </div>
    }else if(href.startsWith("YOUTUBE_")){
      return <div className='p-1 bg-red-200 rounded inline text-red-700  dark:bg-red-900 dark:text-red-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="mr-2">
                {children[0]}
              </div>
              <IoLogoYoutube className="w-6 h-6"></IoLogoYoutube>
        </div>
      </div>
    }else{
      return <div className='p-1 bg-sky-200 rounded inline text-sky-700 dark:bg-sky-900 dark:text-sky-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
      {getBlockLink(children)}
    </div>
    }
   
  }


  function getBlockLink(text){
    return <div className="inline-flex flex-row items-center">
              <div className="mr-2">
                {text[0]}
              </div>
              <IoLinkOutline className="w-6 h-6"></IoLinkOutline>
            </div>;
  }
  return <div className={`border-l-4 md:text-xl text-slate-600 dark:text-slate-300 border-${color?color:"slate"}-400 dark:border-${color?color:"slate"}-900 border-dotted px-2 pb-1 mx-1 md:mx-2 my-1 ml-2 md:ml-4 md:pl-4`}>
       <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} components={{
                p: ({node, ...props}) => <p className='mb-2 leading-loose md:mb-4' {...props} />,
                ul: ({node, ...props}) => <ul className='list-disc list-outside ml-4' {...props} />,
                ol: ({node, ...props}) => <ol className='list-decimal list-outside ml-4' {...props} />,
                li: ({node, ...props}) => <li className='mb-2' {...props} />,
                hr: ({node, ...props}) => <div className='w-32 h-2 border-b-4 border-dotted border-slate-200 dark:border-slate-700 my-6' {...props} />,
                em: ({node, ...props}) => <span className='text-amber-500' {...props} />,
                table: ({node, ...props}) => <table cellPadding={8} className=""{...props} />,
                a: ({node,href,children, ...props}) => getLink(href,children, props),
                td: ({node,children, ...props}) => (<td className='m-2 dark:border-slate-800' {...props}>{children}</td>),
                h2: ({node,children, ...props}) => (<h2 className='text-2xl mb-4' {...props}>{children}</h2>),
                h1: ({node,children, ...props}) => (<h1 className='text-3xl mb-4' {...props}>{children}</h1>),
                code: ({node,children, ...props}) => (<p className='p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-400' {...props}>{getAudioLink(children)}</p>)
              }}/>
      </div>;
}

function getType(playing, text,primary, direction,color, index,onBlock, length,onPlay, correct, audio,fragments, hide, lang){
  if(direction === 6){
    return <MDSpeechbubble lang={lang} audio={audio} color={color} onBlock={onBlock} fragments={fragments} direction={direction} text={text}/>;
  }else if(direction === 9){
    return <div className="flex flex-col items-center mx-4 mt-2">
      <div className="flex flex-col border-4 dark:border-slate-700 border-dotted items-center">
        <div className="px-4 pt-4 text-2xl text-slate-500 dark:text-slate-200">Get access to a vast library of <strong>dialogs</strong> for every situation, explanations and</div>
        <div className="px-4 pb-2 w-full text-2xl text-sky-500">new dialogs every day!</div>
        <div className="flex flex-row">
        <Link to={"/signin"}>
        <div className="flex flex-row w-full m-2 justify-center">
          <div className="flex justify-center bg-sky-200 rounded text-slate-500 font-bold pointer-cursor p-4">Sign in</div>
        </div>
        </Link> 
        <Link to={"/signup"}>
        <div className="flex flex-row w-full m-2 justify-center">
          <div className="flex justify-center bg-sky-500 rounded text-white font-bold pointer-cursor p-4">Sign up</div>
        </div>
        </Link>   
        </div>
      
          
        <HiBlue className="h-32 w-32 sm:h-64 sm:w-64 mt-10">
        </HiBlue>
       
      </div>
    
  </div>;
  }else if(direction === 10){
    console.log("lang",lang);
    if(lang === "ru-RU"){
      return  <div className="flex flex-row w-full justify-center">
      <div className="grid grid-cols-3 sm:grid-cols-4 mt-4 max-w-xl">
      <a href={"/share/buying_bread"}>
        <div className="flex animate-pulse flex-col  text-center min-h-[16vh] m-2 justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
          <div className="text-3xl">🥖</div>
          <div className="font-bold">Buying bread</div>
        </div>
      </a>
      <a href={"/share/making_friends_ru"}>
      <div className="flex flex-col  text-center m-2 justify-center min-h-[16vh] items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🍻</div>
        <div className="font-bold">Making Friends</div>
      </div>
      </a>
      <a href={"/share/do_you_have_plans_ru"}>
      <div className="flex flex-col  text-center m-2 justify-center min-h-[16vh] items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🗓️</div>
        <div className="font-bold">What are your plans?</div>
      </div>
      </a>
      <a href={"/share/buying_groceries_ru"}>
      <div className="hidden sm:flex flex-col text-center m-2 min-h-[16vh] justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🛒</div>
        <div className="font-bold">Buying groceries</div>
      </div>
      </a>
    </div>
      </div>
    }else if (lang === "es-ES"){
      return  <div className="flex flex-row w-full justify-center">
      <div className="grid grid-cols-3 sm:grid-cols-4 mt-4 max-w-xl">
      <a href={"/share/buying_eggs"}>
      <div className="flex animate-pulse flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🥚</div>
        <div className="font-bold">Market, buying eggs</div>
      </div>
      </a>
      <a href={"/share/making_friends_es"}>
      <div className="flex flex-col  text-center m-2 min-h-[16vh] justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🍻</div>
        <div className="font-bold">Making friends</div>
      </div>
      </a>
      <a href={"/share/what_are_your_plans_es"}>
      <div className="flex flex-col  text-center m-2 min-h-[16vh] justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🗓️</div>
        <div className="font-bold">What are your plans?</div>
      </div>
      </a>
      <a href={"/share/buying_groceries_es"}>
      <div className="hidden sm:flex flex-col text-center m-2 min-h-[16vh] justify-center items-center bg-sky-500 dark:bg-sky-900 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
        <div className="text-3xl">🛒</div>
        <div className="font-bold">Buying groceries</div>
      </div>
      </a>
    </div>
      </div>
    }else {
      return <div>No recomendations</div>
    }
    
  }else {
    return <div className={`flex relative ${direction===0&&primary?"pr-10":""}  ${direction===1&&primary?"pl-10":""}  items-center ${index===length-1&&(direction===1||direction===0||direction===2)?"cursor-pointer":""} flex-row ${getDirection(direction)}`} onClick={()=>{if(index===length-1&&(direction===1||direction===0||direction===2)){onPlay()}}}>
    {direction===0&&correct?<BsPatchCheckFill className="h-6 w-6 text-slate-300"></BsPatchCheckFill>:""}
    {(direction===0)&&index===length-1&&audio?"":""}
    
    {(direction===1&&primary)?<Blue className="absolute rounded-full bg-white top-1 w-16 h-16 border-4 left-0 border-white dark:border-slate-900"></Blue>:""}
    <div className={`sp-max-w-mobile sm:sp-max-w overflow-visible z-10 ${direction===1||direction===0?"cShadow":""} ${getDesign(direction,index<length-2)} ${playing&&index===length-1?"blob":""} rounded ${index===length-2?"special1":""} ${index===length-1?"special":""} ${get_bubble_class(index,length,direction)}`}>
      {(index<length-1&&(direction===0||direction===1)&&hide)?"...":text}
    </div>
    {(direction===0&&primary)?<Jim className="absolute rounded-full w-16 h-16 border-4 right-0 top-1 bg-white border-white dark:border-slate-900"></Jim>:""}
    {(direction===1||direction===2)&&index===length-1&&audio?"":""}
    {direction===1&&correct?<BsPatchCheckFill className="h-6 w-6 text-slate-300"></BsPatchCheckFill>:""}
  </div>
  }
}


export default function SpeechBubbleLocal({lang, playing, text,primary, direction,color, index,onBlock, length,onPlay, correct=false, audio,fragments, hide=true}) {
  return (<>{getType(playing, text,primary, direction,color, index,onBlock, length,onPlay, correct, audio,fragments, hide, lang)}</>);
} 

SpeechBubbleLocal.propTypes = {
  text: PropTypes.string,
  direction: PropTypes.number,
};
