import React from 'react';
import {useNavigate} from 'react-router-dom';
import {IoArrowBackOutline} from 'react-icons/io5';
import { ReactComponent as FlagRU } from '../svg/flag_ru.svg';
import {IoBookOutline, IoGolfOutline, IoSettingsOutline} from 'react-icons/io5';

import { Popover } from '@headlessui/react'
import { LanguageDropDown } from '../navbar/LanguageDropDown';

function EditorDropDown({select, current}) {
  return (
    <Popover className="relative">
      <Popover.Button>{current}</Popover.Button>

      <Popover.Panel className="absolute z-10 shadow rounded bg-white">
        <div className="flex flex-col">
          <div className="cursor-pointer flex text-slate flex-row p-4 pr-10 align-center rounded-l hover:bg-slate-100" onClick={()=>{select("Levels")}}><IoGolfOutline className="text-sky-700 h-6 w-6 mr-4"></IoGolfOutline>Levels</div>
          <div className="cursor-pointer flex text-slate flex-row p-4 pr-10 align-center rounded-l hover:bg-slate-100" onClick={()=>{select("Stories")}}><IoBookOutline className="text-sky-700 h-6 w-6 mr-4"></IoBookOutline>Stories</div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function EditorNavbar({select, current, name=null}) {

  const navigate = useNavigate();

  return (
    <div className="relative z-50 w-full py-2 items-center flex text-slate-600 flex-row justify-start border-slate-200 border-dotted border-b dark:bg-slate-900 dark:border-slate-700">
        <div className="flex flex-row mr-4">
            <IoArrowBackOutline onClick={()=>{navigate('/learn');}} className="cursor-pointer text-sky-500 h-6 w-6 ml-6"/>
        </div>
        {name!==null?<p className="font-bold text-sky-600">{name}</p>:<EditorDropDown select={select} current={current}></EditorDropDown>}
        
        <LanguageDropDown></LanguageDropDown>
    </div>
  );
}
