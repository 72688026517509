const PEP = {
    он: {
      N: ["он", "he"],
      G: ["его", "he"],
      D: ["ему", "he"],
      A: ["его", "he"],
      P: ["нём", "he"],
      I: ["им", "he"],
      G1: ["него", "he"],
      D1: ["нему", "he"],
      A1: ["него", "he"],
      P1: ["них", "he"],
      I1: ["ним", "he"],
    },
    оно: {
      N: ["oнo", "it"],
      G: ["его", "it"],
      D: ["ему", "it"],
      A: ["его", "it"],
      P: ["нём", "it"],
      I: ["им", "it"],
      G1: ["него", "it"],
      D1: ["нему", "it"],
      A1: ["него", "it"],
      P1: ["них", "it"],
      I1: ["ним", "it"],
    },
    я: {
      N: ["я", "I"],
      G: ["меня", "I"],
      D: ["мне", "I"],
      A: ["меня", "I"],
      P: ["мной", "I"],
      I: ["мне", "I"],
    },
    она: {
      N: ["она", "she"],
      G: ["её", "she"],
      D: ["ей", "she"],
      A: ["её", "she"],
      P: ["ней", "she"],
      I: ["ей", "she"],
    },
    они: {
      N: ["они", "they"],
      G: ["их", "they"],
      D: ["им", "they"],
      A: ["их", "they"],
      P: ["них", "they"],
      I: ["ими", "they"],
    },
    ты: {
      N: ["ты", "you"],
      G: ["тебя", "you"],
      D: ["тебе", "you"],
      A: ["тебя", "you"],
      P: ["тебе", "you"],
      I: ["тобой", "you"],
    },
    мы: {
      N: ["мы", "you"],
      G: ["нас", "you"],
      D: ["нам", "you"],
      A: ["нас", "you"],
      P: ["нас", "you"],
      I: ["нами", "you"],
    },
    вы: {
      N: ["вы", "you"],
      G: ["вас", "you"],
      D: ["вам", "you"],
      A: ["вас", "you"],
      P: ["вас", "you"],
      I: ["вами", "you"],
    },
  };
  
  const PPP = {
    мой: {
      NM: ["мой", "my"],
      NN: ["моё", "my"],
      NF: ["моя", "my"],
      NP: ["мои", "my"],
      GM: ["моего", "my"],
      GN: ["моего", "my"],
      GF: ["моей", "my"],
      GP: ["моих", "my"],
      DM: ["моему", "my"],
      DN: ["моему", "my"],
      DF: ["моей", "my"],
      DP: ["моим", "my"],
      AM: ["моего", "my"],
      AN: ["моё", "my"],
      AF: ["мою", "my"],
      AP: ["моих", "my"],
      IM: ["моим", "my"],
      IN: ["моим", "my"],
      IF: ["моей", "my"],
      IP: ["моими", "my"],
      PM: ["моём", "my"],
      PN: ["моём", "my"],
      PF: ["моей", "my"],
      PP: ["моих", "my"],
    },
    твой: {
      NM: ["твой", "your"],
      NN: ["твоё", "your"],
      NF: ["твоя", "your"],
      NP: ["твои", "your"],
      GM: ["твоего", "your"],
      GN: ["твоего", "your"],
      GF: ["твоей", "your"],
      GP: ["твоих", "your"],
      DM: ["твоему", "your"],
      DN: ["твоему", "your"],
      DF: ["твоей", "your"],
      DP: ["твоим", "your"],
      AM: ["твоего", "your"],
      AN: ["твоё", "your"],
      AF: ["твою", "your"],
      AP: ["твоих", "your"],
      IM: ["твоим", "your"],
      IN: ["твоим", "your"],
      IF: ["твоей", "your"],
      IP: ["твоими", "your"],
      PM: ["твоём", "your"],
      PN: ["твоём", "your"],
      PF: ["твоей", "your"],
      PP: ["твоих", "your"],
    },
    наш: {
      NM: ["наш", "our"],
      NN: ["наше", "our"],
      NF: ["наша", "our"],
      NP: ["наши", "our"],
      GM: ["нашего", "our"],
      GN: ["нашего", "our"],
      GF: ["нашей", "our"],
      GP: ["наших", "our"],
      DM: ["нашему", "our"],
      DN: ["нашему", "our"],
      DF: ["нашей", "our"],
      DP: ["нашим", "our"],
      AM: ["нашего", "our"],
      AN: ["наше", "our"],
      AF: ["нашу", "our"],
      AP: ["наших", "our"],
      IM: ["нашим", "our"],
      IN: ["нашим", "our"],
      IF: ["нашей", "our"],
      IP: ["нашими", "our"],
      PM: ["нашем", "our"],
      PN: ["нашем", "our"],
      PF: ["нашей", "our"],
      PP: ["наших", "our"],
    },
    ваш: {
      NM: ["ваш", "your"],
      NN: ["ваше", "your"],
      NF: ["ваша", "your"],
      NP: ["ваши", "your"],
      GM: ["вашего", "your"],
      GN: ["вашего", "your"],
      GF: ["вашей", "your"],
      GP: ["ваших", "your"],
      DM: ["вашему", "your"],
      DN: ["вашему", "your"],
      DF: ["вашей", "your"],
      DP: ["вашим", "your"],
      AM: ["вашего", "your"],
      AN: ["ваше", "your"],
      AF: ["вашу", "your"],
      AP: ["ваших", "your"],
      IM: ["вашим", "your"],
      IN: ["вашим", "your"],
      IF: ["вашей", "your"],
      IP: ["вашими", "your"],
      PM: ["вашем", "your"],
      PN: ["вашем", "your"],
      PF: ["вашей", "your"],
      PP: ["ваших", "your"],
    },
    свой: {
      NM: ["свой", "ones own"],
      NN: ["своё", "ones own"],
      NF: ["своя", "ones own"],
      NP: ["свои", "ones own"],
      GM: ["своего", "ones own"],
      GN: ["своего", "ones own"],
      GF: ["своей", "ones own"],
      GP: ["своих", "ones own"],
      DM: ["своему", "ones own"],
      DN: ["своему", "ones own"],
      DF: ["своей", "ones own"],
      DP: ["своим", "ones own"],
      AM: ["своего", "ones own"],
      AN: ["своё", "ones own"],
      AF: ["свою", "ones own"],
      AP: ["своих", "ones own"],
      IM: ["своим", "ones own"],
      IN: ["своим", "ones own"],
      IF: ["своей", "ones own"],
      IP: ["своими", "ones own"],
      PM: ["своём", "ones own"],
      PN: ["своём", "ones own"],
      PF: ["своей", "ones own"],
      PP: ["своих", "ones own"],
    },
    его: {
      NM: ["его", "his"],
      NN: ["его", "his"],
      NF: ["его", "his"],
      NP: ["его", "his"],
      GM: ["его", "his"],
      GN: ["его", "his"],
      GF: ["его", "his"],
      GP: ["его", "his"],
      DM: ["его", "his"],
      DN: ["его", "his"],
      DF: ["его", "his"],
      DP: ["его", "his"],
      AM: ["его", "his"],
      AN: ["его", "his"],
      AF: ["его", "his"],
      AP: ["его", "his"],
      IM: ["его", "his"],
      IN: ["его", "his"],
      IF: ["его", "his"],
      IP: ["его", "his"],
      PM: ["его", "his"],
      PN: ["его", "his"],
      PF: ["его", "his"],
      PP: ["его", "his"],
    },
    её: {
      NM: ["её", "her"],
      NN: ["её", "her"],
      NF: ["её", "her"],
      NP: ["её", "her"],
      GM: ["её", "her"],
      GN: ["её", "her"],
      GF: ["её", "her"],
      GP: ["её", "her"],
      DM: ["её", "her"],
      DN: ["её", "her"],
      DF: ["её", "her"],
      DP: ["её", "her"],
      AM: ["её", "her"],
      AN: ["её", "her"],
      AF: ["её", "her"],
      AP: ["её", "her"],
      IM: ["её", "her"],
      IN: ["её", "her"],
      IF: ["её", "her"],
      IP: ["её", "her"],
      PM: ["её", "her"],
      PN: ["её", "her"],
      PF: ["её", "her"],
      PP: ["её", "her"],
    },
    их: {
      NM: ["их", "their"],
      NN: ["их", "their"],
      NF: ["их", "their"],
      NP: ["их", "their"],
      GM: ["их", "their"],
      GN: ["их", "their"],
      GF: ["их", "their"],
      GP: ["их", "their"],
      DM: ["их", "their"],
      DN: ["их", "their"],
      DF: ["их", "their"],
      DP: ["их", "their"],
      AM: ["их", "their"],
      AN: ["их", "their"],
      AF: ["их", "their"],
      AP: ["их", "their"],
      IM: ["их", "their"],
      IN: ["их", "their"],
      IF: ["их", "their"],
      IP: ["их", "their"],
      PM: ["их", "their"],
      PN: ["их", "their"],
      PF: ["их", "their"],
      PP: ["их", "their"],
    },
  };
  
  const PN = {
    я: ["singular", "M", "1"],
    он: ["singular", "M", "3"],
    она: ["singular", "F", "3"],
    оно: ["singular", "N", "3"],
    ты: ["singular", "M", "2"],
    мы: ["plural", "N", "W"],
    вы: ["singular", "M", "F"],
    они: ["plural", "N", "T"],
  };
  
  const Living = [
    "человек",
    "друг",
    "ребёнок",
    "отец",
    "женщина",
    "жена",
    "мать",
    "товарищ",
    "сын",
    "старик",
    "мама",
    "мальчик",
    "девушка",
    "брат",
    "солдат",
    "хозяин",
    "начальник",
    "парень",
    "ребята",
    "мужчина",
    "капитан",
    "муж",
    "собака",
    "немец",
    "дядя",
    "писатель",
    "гость",
    "врач",
    "девочка",
    "командир",
    "герой",
    "профессор",
    "господин",
    "директор",
    "мужик",
    "автор",
    "враг",
    "доктор",
    "лейтенант",
    "дед",
    "родитель",
    "президент",
    "поэт",
    "офицер",
    "дочь",
    "член",
    "сосед",
    "сестра",
    "дурак",
    "лошадь",
    "король",
    "полковник",
    "дама",
    "рыба",
    "бабушка",
    "учитель",
    "папа",
    "птица",
    "гражданин",
    "художник",
  ];
  
  const nouns = {
    человек: {
      NNS: ["человек", "man, person", "M"],
      NNP: ["люди", "man, person", "M"],
      NGS: ["человека", "man, person", "M"],
      NGP: ["людей", "man, person", "M"],
      NDS: ["человеку", "man, person", "M"],
      NDP: ["людям", "man, person", "M"],
      NAS: ["человека", "man, person", "M"],
      NAP: ["людей", "man, person", "M"],
      NIS: ["человеком", "man, person", "M"],
      NIP: ["людьми", "man, person", "M"],
      NPS: ["человеке", "man, person", "M"],
      NPP: ["людях", "man, person", "M"],
    },
    год: {
      NNS: ["год", "year", "M"],
      NNP: ["годы", "year", "M"],
      NGS: ["года", "year", "M"],
      NGP: ["годов", "year", "M"],
      NDS: ["году", "year", "M"],
      NDP: ["годам", "year", "M"],
      NAS: ["год", "year", "M"],
      NAP: ["годы", "year", "M"],
      NIS: ["годом", "year", "M"],
      NIP: ["годами", "year", "M"],
      NPS: ["году", "year", "M"],
      NPP: ["годах", "year", "M"],
    },
    время: {
      NNS: ["время", "time, season", "N"],
      NNP: ["времена", "time, season", "N"],
      NGS: ["времени", "time, season", "N"],
      NGP: ["времён", "time, season", "N"],
      NDS: ["времени", "time, season", "N"],
      NDP: ["временам", "time, season", "N"],
      NAS: ["время", "time, season", "N"],
      NAP: ["времена", "time, season", "N"],
      NIS: ["временем", "time, season", "N"],
      NIP: ["временами", "time, season", "N"],
      NPS: ["времени", "time, season", "N"],
      NPP: ["временах", "time, season", "N"],
    },
    рука: {
      NNS: ["рука", "hand, arm", "F"],
      NNP: ["руки", "hand, arm", "F"],
      NGS: ["руки", "hand, arm", "F"],
      NGP: ["рук", "hand, arm", "F"],
      NDS: ["руке", "hand, arm", "F"],
      NDP: ["рукам", "hand, arm", "F"],
      NAS: ["руку", "hand, arm", "F"],
      NAP: ["руки", "hand, arm", "F"],
      NIS: ["рукой", "hand, arm", "F"],
      NIP: ["руками", "hand, arm", "F"],
      NPS: ["руке", "hand, arm", "F"],
      NPP: ["руках", "hand, arm", "F"],
    },
    дело: {
      NNS: ["дело", "business, affair, matter", "N"],
      NNP: ["дела", "business, affair, matter", "N"],
      NGS: ["дела", "business, affair, matter", "N"],
      NGP: ["дел", "business, affair, matter", "N"],
      NDS: ["делу", "business, affair, matter", "N"],
      NDP: ["делам", "business, affair, matter", "N"],
      NAS: ["дело", "business, affair, matter", "N"],
      NAP: ["дела", "business, affair, matter", "N"],
      NIS: ["делом", "business, affair, matter", "N"],
      NIP: ["делами", "business, affair, matter", "N"],
      NPS: ["деле", "business, affair, matter", "N"],
      NPP: ["делах", "business, affair, matter", "N"],
    },
    глаз: {
      NNS: ["глаз", "eye; sight", "M"],
      NNP: ["глаза", "eye; sight", "M"],
      NGS: ["глаза", "eye; sight", "M"],
      NGP: ["глаз", "eye; sight", "M"],
      NDS: ["глазу", "eye; sight", "M"],
      NDP: ["глазам", "eye; sight", "M"],
      NAS: ["глаз", "eye; sight", "M"],
      NAP: ["глаза", "eye; sight", "M"],
      NIS: ["глазом", "eye; sight", "M"],
      NIP: ["глазами", "eye; sight", "M"],
      NPS: ["глазе", "eye; sight", "M"],
      NPP: ["глазах", "eye; sight", "M"],
    },
    жизнь: {
      NNS: ["жизнь", "life", "F"],
      NNP: ["жизни", "life", "F"],
      NGS: ["жизни", "life", "F"],
      NGP: ["жизней", "life", "F"],
      NDS: ["жизни", "life", "F"],
      NDP: ["жизням", "life", "F"],
      NAS: ["жизнь", "life", "F"],
      NAP: ["жизни", "life", "F"],
      NIS: ["жизнью", "life", "F"],
      NIP: ["жизнями", "life", "F"],
      NPS: ["жизни", "life", "F"],
      NPP: ["жизнях", "life", "F"],
    },
    день: {
      NNS: ["день", "day", "M"],
      NNP: ["дни", "day", "M"],
      NGS: ["дня", "day", "M"],
      NGP: ["дней", "day", "M"],
      NDS: ["дню", "day", "M"],
      NDP: ["дням", "day", "M"],
      NAS: ["день", "day", "M"],
      NAP: ["дни", "day", "M"],
      NIS: ["днём", "day", "M"],
      NIP: ["днями", "day", "M"],
      NPS: ["дне", "day", "M"],
      NPP: ["днях", "day", "M"],
    },
    голова: {
      NNS: ["голова", "head, mind, brains", "F"],
      NNP: ["головы", "head, mind, brains", "F"],
      NGS: ["головы", "head, mind, brains", "F"],
      NGP: ["голов", "head, mind, brains", "F"],
      NDS: ["голове", "head, mind, brains", "F"],
      NDP: ["головам", "head, mind, brains", "F"],
      NAS: ["голову", "head, mind, brains", "F"],
      NAP: ["головы", "head, mind, brains", "F"],
      NIS: ["головой", "head, mind, brains", "F"],
      NIP: ["головами", "head, mind, brains", "F"],
      NPS: ["голове", "head, mind, brains", "F"],
      NPP: ["головах", "head, mind, brains", "F"],
    },
    друг: {
      NNS: ["друг", "friend", "M"],
      NNP: ["друзья", "friend", "M"],
      NGS: ["друга", "friend", "M"],
      NGP: ["друзей", "friend", "M"],
      NDS: ["другу", "friend", "M"],
      NDP: ["друзьям", "friend", "M"],
      NAS: ["друга", "friend", "M"],
      NAP: ["друзей", "friend", "M"],
      NIS: ["другом", "friend", "M"],
      NIP: ["друзьями", "friend", "M"],
      NPS: ["друге", "friend", "M"],
      NPP: ["друзьях", "friend", "M"],
    },
    дом: {
      NNS: ["дом", "house, home", "M"],
      NNP: ["дома", "house, home", "M"],
      NGS: ["дома", "house, home", "M"],
      NGP: ["домов", "house, home", "M"],
      NDS: ["дому", "house, home", "M"],
      NDP: ["домам", "house, home", "M"],
      NAS: ["дом", "house, home", "M"],
      NAP: ["дома", "house, home", "M"],
      NIS: ["домом", "house, home", "M"],
      NIP: ["домами", "house, home", "M"],
      NPS: ["доме", "house, home", "M"],
      NPP: ["домах", "house, home", "M"],
    },
    слово: {
      NNS: ["слово", "word", "N"],
      NNP: ["слова", "word", "N"],
      NGS: ["слова", "word", "N"],
      NGP: ["слов", "word", "N"],
      NDS: ["слову", "word", "N"],
      NDP: ["словам", "word", "N"],
      NAS: ["слово", "word", "N"],
      NAP: ["слова", "word", "N"],
      NIS: ["словом", "word", "N"],
      NIP: ["словами", "word", "N"],
      NPS: ["слове", "word", "N"],
      NPP: ["словах", "word", "N"],
    },
    место: {
      NNS: ["место", "place; seat", "N"],
      NNP: ["места", "place; seat", "N"],
      NGS: ["места", "place; seat", "N"],
      NGP: ["мест", "place; seat", "N"],
      NDS: ["месту", "place; seat", "N"],
      NDP: ["местам", "place; seat", "N"],
      NAS: ["место", "place; seat", "N"],
      NAP: ["места", "place; seat", "N"],
      NIS: ["местом", "place; seat", "N"],
      NIP: ["местами", "place; seat", "N"],
      NPS: ["месте", "place; seat", "N"],
      NPP: ["местах", "place; seat", "N"],
    },
    лицо: {
      NNS: ["лицо", "face; person", "N"],
      NNP: ["лица", "face; person", "N"],
      NGS: ["лица", "face; person", "N"],
      NGP: ["лиц", "face; person", "N"],
      NDS: ["лицу", "face; person", "N"],
      NDP: ["лицам", "face; person", "N"],
      NAS: ["лицо", "face; person", "N"],
      NAP: ["лица", "face; person", "N"],
      NIS: ["лицом", "face; person", "N"],
      NIP: ["лицами", "face; person", "N"],
      NPS: ["лице", "face; person", "N"],
      NPP: ["лицах", "face; person", "N"],
    },
    сторона: {
      NNS: ["сторона", "side, party", "F"],
      NNP: ["стороны", "side, party", "F"],
      NGS: ["стороны", "side, party", "F"],
      NGP: ["сторон", "side, party", "F"],
      NDS: ["стороне", "side, party", "F"],
      NDP: ["сторонам", "side, party", "F"],
      NAS: ["сторону", "side, party", "F"],
      NAP: ["стороны", "side, party", "F"],
      NIS: ["стороной", "side, party", "F"],
      NIP: ["сторонами", "side, party", "F"],
      NPS: ["стороне", "side, party", "F"],
      NPP: ["сторонах", "side, party", "F"],
    },
    нога: {
      NNS: ["нога", "foot, leg", "F"],
      NNP: ["ноги", "foot, leg", "F"],
      NGS: ["ноги", "foot, leg", "F"],
      NGP: ["ног", "foot, leg", "F"],
      NDS: ["ноге", "foot, leg", "F"],
      NDP: ["ногам", "foot, leg", "F"],
      NAS: ["ногу", "foot, leg", "F"],
      NAP: ["ноги", "foot, leg", "F"],
      NIS: ["ногой", "foot, leg", "F"],
      NIP: ["ногами", "foot, leg", "F"],
      NPS: ["ноге", "foot, leg", "F"],
      NPP: ["ногах", "foot, leg", "F"],
    },
    дверь: {
      NNS: ["дверь", "door", "F"],
      NNP: ["двери", "door", "F"],
      NGS: ["двери", "door", "F"],
      NGP: ["дверей", "door", "F"],
      NDS: ["двери", "door", "F"],
      NDP: ["дверям", "door", "F"],
      NAS: ["дверь", "door", "F"],
      NAP: ["двери", "door", "F"],
      NIS: ["дверью", "door", "F"],
      NIP: ["дверями", "door", "F"],
      NPS: ["двери", "door", "F"],
      NPP: ["дверях", "door", "F"],
    },
    работа: {
      NNS: ["работа", "work, job", "F"],
      NNP: ["работы", "work, job", "F"],
      NGS: ["работы", "work, job", "F"],
      NGP: ["работ", "work, job", "F"],
      NDS: ["работе", "work, job", "F"],
      NDP: ["работам", "work, job", "F"],
      NAS: ["работу", "work, job", "F"],
      NAP: ["работы", "work, job", "F"],
      NIS: ["работой", "work, job", "F"],
      NIP: ["работами", "work, job", "F"],
      NPS: ["работе", "work, job", "F"],
      NPP: ["работах", "work, job", "F"],
    },
    земля: {
      NNS: ["земля", "earth, land, soil", "F"],
      NNP: ["земли", "earth, land, soil", "F"],
      NGS: ["земли", "earth, land, soil", "F"],
      NGP: ["земель", "earth, land, soil", "F"],
      NDS: ["земле", "earth, land, soil", "F"],
      NDP: ["землям", "earth, land, soil", "F"],
      NAS: ["землю", "earth, land, soil", "F"],
      NAP: ["земли", "earth, land, soil", "F"],
      NIS: ["землёй", "earth, land, soil", "F"],
      NIP: ["землями", "earth, land, soil", "F"],
      NPS: ["земле", "earth, land, soil", "F"],
      NPP: ["землях", "earth, land, soil", "F"],
    },
    конец: {
      NNS: ["конец", "end, distance", "M"],
      NNP: ["концы", "end, distance", "M"],
      NGS: ["конца", "end, distance", "M"],
      NGP: ["концов", "end, distance", "M"],
      NDS: ["концу", "end, distance", "M"],
      NDP: ["концам", "end, distance", "M"],
      NAS: ["конец", "end, distance", "M"],
      NAP: ["концы", "end, distance", "M"],
      NIS: ["концом", "end, distance", "M"],
      NIP: ["концами", "end, distance", "M"],
      NPS: ["конце", "end, distance", "M"],
      NPP: ["концах", "end, distance", "M"],
    },
    час: {
      NNS: ["час", "hour, time", "M"],
      NNP: ["часы", "hour, time", "M"],
      NGS: ["часа", "hour, time", "M"],
      NGP: ["часов", "hour, time", "M"],
      NDS: ["часу", "hour, time", "M"],
      NDP: ["часам", "hour, time", "M"],
      NAS: ["час", "hour, time", "M"],
      NAP: ["часы", "hour, time", "M"],
      NIS: ["часом", "hour, time", "M"],
      NIP: ["часами", "hour, time", "M"],
      NPS: ["часу", "hour, time", "M"],
      NPP: ["часах", "hour, time", "M"],
    },
    голос: {
      NNS: ["голос", "voice", "M"],
      NNP: ["голоса", "voice", "M"],
      NGS: ["голоса", "voice", "M"],
      NGP: ["голосов", "voice", "M"],
      NDS: ["голосу", "voice", "M"],
      NDP: ["голосам", "voice", "M"],
      NAS: ["голос", "voice", "M"],
      NAP: ["голоса", "voice", "M"],
      NIS: ["голосом", "voice", "M"],
      NIP: ["голосами", "voice", "M"],
      NPS: ["голосе", "voice", "M"],
      NPP: ["голосах", "voice", "M"],
    },
    город: {
      NNS: ["город", "town, city", "M"],
      NNP: ["города", "town, city", "M"],
      NGS: ["города", "town, city", "M"],
      NGP: ["городов", "town, city", "M"],
      NDS: ["городу", "town, city", "M"],
      NDP: ["городам", "town, city", "M"],
      NAS: ["город", "town, city", "M"],
      NAP: ["города", "town, city", "M"],
      NIS: ["городом", "town, city", "M"],
      NIP: ["городами", "town, city", "M"],
      NPS: ["городе", "town, city", "M"],
      NPP: ["городах", "town, city", "M"],
    },
    вода: {
      NNS: ["вода", "water", "F"],
      NNP: ["воды", "water", "F"],
      NGS: ["воды", "water", "F"],
      NGP: ["вод", "water", "F"],
      NDS: ["воде", "water", "F"],
      NDP: ["водам", "water", "F"],
      NAS: ["воду", "water", "F"],
      NAP: ["воды", "water", "F"],
      NIS: ["водой", "water", "F"],
      NIP: ["водами", "water", "F"],
      NPS: ["воде", "water", "F"],
      NPP: ["водах", "water", "F"],
    },
    стол: {
      NNS: ["стол", "table, desk; board", "M"],
      NNP: ["столы", "table, desk; board", "M"],
      NGS: ["стола", "table, desk; board", "M"],
      NGP: ["столов", "table, desk; board", "M"],
      NDS: ["столу", "table, desk; board", "M"],
      NDP: ["столам", "table, desk; board", "M"],
      NAS: ["стол", "table, desk; board", "M"],
      NAP: ["столы", "table, desk; board", "M"],
      NIS: ["столом", "table, desk; board", "M"],
      NIP: ["столами", "table, desk; board", "M"],
      NPS: ["столе", "table, desk; board", "M"],
      NPP: ["столах", "table, desk; board", "M"],
    },
    ребёнок: {
      NNS: ["ребёнок", "child, kid, infant", "M"],
      NNP: ["дети", "child, kid, infant", "M"],
      NGS: ["ребёнка", "child, kid, infant", "M"],
      NGP: ["детей", "child, kid, infant", "M"],
      NDS: ["ребёнку", "child, kid, infant", "M"],
      NDP: ["детям", "child, kid, infant", "M"],
      NAS: ["ребёнка", "child, kid, infant", "M"],
      NAP: ["детей", "child, kid, infant", "M"],
      NIS: ["ребёнком", "child, kid, infant", "M"],
      NIP: ["детьми", "child, kid, infant", "M"],
      NPS: ["ребёнке", "child, kid, infant", "M"],
      NPP: ["детях", "child, kid, infant", "M"],
    },
    сила: {
      NNS: ["сила", "strength, force", "F"],
      NNP: ["силы", "strength, force", "F"],
      NGS: ["силы", "strength, force", "F"],
      NGP: ["сил", "strength, force", "F"],
      NDS: ["силе", "strength, force", "F"],
      NDP: ["силам", "strength, force", "F"],
      NAS: ["силу", "strength, force", "F"],
      NAP: ["силы", "strength, force", "F"],
      NIS: ["силой", "strength, force", "F"],
      NIP: ["силами", "strength, force", "F"],
      NPS: ["силе", "strength, force", "F"],
      NPP: ["силах", "strength, force", "F"],
    },
    отец: {
      NNS: ["отец", "father", "M"],
      NNP: ["отцы", "father", "M"],
      NGS: ["отца", "father", "M"],
      NGP: ["отцов", "father", "M"],
      NDS: ["отцу", "father", "M"],
      NDP: ["отцам", "father", "M"],
      NAS: ["отца", "father", "M"],
      NAP: ["отцов", "father", "M"],
      NIS: ["отцом", "father", "M"],
      NIP: ["отцами", "father", "M"],
      NPS: ["отце", "father", "M"],
      NPP: ["отцах", "father", "M"],
    },
    женщина: {
      NNS: ["женщина", "woman", "F"],
      NNP: ["женщины", "woman", "F"],
      NGS: ["женщины", "woman", "F"],
      NGP: ["женщин", "woman", "F"],
      NDS: ["женщине", "woman", "F"],
      NDP: ["женщинам", "woman", "F"],
      NAS: ["женщину", "woman", "F"],
      NAP: ["женщин", "woman", "F"],
      NIS: ["женщиной", "woman", "F"],
      NIP: ["женщинами", "woman", "F"],
      NPS: ["женщине", "woman", "F"],
      NPP: ["женщинах", "woman", "F"],
    },
    машина: {
      NNS: ["машина", "car, machine, engine", "F"],
      NNP: ["машины", "car, machine, engine", "F"],
      NGS: ["машины", "car, machine, engine", "F"],
      NGP: ["машин", "car, machine, engine", "F"],
      NDS: ["машине", "car, machine, engine", "F"],
      NDP: ["машинам", "car, machine, engine", "F"],
      NAS: ["машину", "car, machine, engine", "F"],
      NAP: ["машины", "car, machine, engine", "F"],
      NIS: ["машиной", "car, machine, engine", "F"],
      NIP: ["машинами", "car, machine, engine", "F"],
      NPS: ["машине", "car, machine, engine", "F"],
      NPP: ["машинах", "car, machine, engine", "F"],
    },
    случай: {
      NNS: ["случай", "case, occasion, incident", "M"],
      NNP: ["случаи", "case, occasion, incident", "M"],
      NGS: ["случая", "case, occasion, incident", "M"],
      NGP: ["случаев", "case, occasion, incident", "M"],
      NDS: ["случаю", "case, occasion, incident", "M"],
      NDP: ["случаям", "case, occasion, incident", "M"],
      NAS: ["случай", "case, occasion, incident", "M"],
      NAP: ["случаи", "case, occasion, incident", "M"],
      NIS: ["случаем", "case, occasion, incident", "M"],
      NIP: ["случаями", "case, occasion, incident", "M"],
      NPS: ["случае", "case, occasion, incident", "M"],
      NPP: ["случаях", "case, occasion, incident", "M"],
    },
    ночь: {
      NNS: ["ночь", "night", "F"],
      NNP: ["ночи", "night", "F"],
      NGS: ["ночи", "night", "F"],
      NGP: ["ночей", "night", "F"],
      NDS: ["ночи", "night", "F"],
      NDP: ["ночам", "night", "F"],
      NAS: ["ночь", "night", "F"],
      NAP: ["ночи", "night", "F"],
      NIS: ["ночью", "night", "F"],
      NIP: ["ночами", "night", "F"],
      NPS: ["ночи", "night", "F"],
      NPP: ["ночах", "night", "F"],
    },
    мир: {
      NNS: ["мир", "world, peace", "M"],
      NNP: ["миры", "world, peace", "M"],
      NGS: ["мира", "world, peace", "M"],
      NGP: ["миров", "world, peace", "M"],
      NDS: ["миру", "world, peace", "M"],
      NDP: ["мирам", "world, peace", "M"],
      NAS: ["мир", "world, peace", "M"],
      NAP: ["миры", "world, peace", "M"],
      NIS: ["миром", "world, peace", "M"],
      NIP: ["мирами", "world, peace", "M"],
      NPS: ["мире", "world, peace", "M"],
      NPP: ["мирах", "world, peace", "M"],
    },
    вид: {
      NNS: ["вид", "appearance, look, view", "M"],
      NNP: ["виды", "appearance, look, view", "M"],
      NGS: ["вида", "appearance, look, view", "M"],
      NGP: ["видов", "appearance, look, view", "M"],
      NDS: ["виду", "appearance, look, view", "M"],
      NDP: ["видам", "appearance, look, view", "M"],
      NAS: ["вид", "appearance, look, view", "M"],
      NAP: ["виды", "appearance, look, view", "M"],
      NIS: ["видом", "appearance, look, view", "M"],
      NIP: ["видами", "appearance, look, view", "M"],
      NPS: ["виде", "appearance, look, view", "M"],
      NPP: ["видах", "appearance, look, view", "M"],
    },
    ряд: {
      NNS: ["ряд", "row, line", "M"],
      NNP: ["ряды", "row, line", "M"],
      NGS: ["ряда", "row, line", "M"],
      NGP: ["рядов", "row, line", "M"],
      NDS: ["ряду", "row, line", "M"],
      NDP: ["рядам", "row, line", "M"],
      NAS: ["ряд", "row, line", "M"],
      NAP: ["ряды", "row, line", "M"],
      NIS: ["рядом", "row, line", "M"],
      NIP: ["рядами", "row, line", "M"],
      NPS: ["ряде", "row, line", "M"],
      NPP: ["рядах", "row, line", "M"],
    },
    начало: {
      NNS: ["начало", "beginning, origin, source", "N"],
      NNP: ["начала", "beginning, origin, source", "N"],
      NGS: ["начала", "beginning, origin, source", "N"],
      NGP: ["начал", "beginning, origin, source", "N"],
      NDS: ["началу", "beginning, origin, source", "N"],
      NDP: ["началам", "beginning, origin, source", "N"],
      NAS: ["начало", "beginning, origin, source", "N"],
      NAP: ["начала", "beginning, origin, source", "N"],
      NIS: ["началом", "beginning, origin, source", "N"],
      NIP: ["началами", "beginning, origin, source", "N"],
      NPS: ["начале", "beginning, origin, source", "N"],
      NPP: ["началах", "beginning, origin, source", "N"],
    },
    вопрос: {
      NNS: ["вопрос", "question, matter, problem", "M"],
      NNP: ["вопросы", "question, matter, problem", "M"],
      NGS: ["вопроса", "question, matter, problem", "M"],
      NGP: ["вопросов", "question, matter, problem", "M"],
      NDS: ["вопросу", "question, matter, problem", "M"],
      NDP: ["вопросам", "question, matter, problem", "M"],
      NAS: ["вопрос", "question, matter, problem", "M"],
      NAP: ["вопросы", "question, matter, problem", "M"],
      NIS: ["вопросом", "question, matter, problem", "M"],
      NIP: ["вопросами", "question, matter, problem", "M"],
      NPS: ["вопросе", "question, matter, problem", "M"],
      NPP: ["вопросах", "question, matter, problem", "M"],
    },
    война: {
      NNS: ["война", "war", "F"],
      NNP: ["войны", "war", "F"],
      NGS: ["войны", "war", "F"],
      NGP: ["войн", "war", "F"],
      NDS: ["войне", "war", "F"],
      NDP: ["войнам", "war", "F"],
      NAS: ["войну", "war", "F"],
      NAP: ["войны", "war", "F"],
      NIS: ["войной", "war", "F"],
      NIP: ["войнами", "war", "F"],
      NPS: ["войне", "war", "F"],
      NPP: ["войнах", "war", "F"],
    },
    деньги: {
      NNS: ["", "money", "N"],
      NNP: ["деньги", "money", "N"],
      NGS: ["", "money", "N"],
      NGP: ["денег", "money", "N"],
      NDS: ["", "money", "N"],
      NDP: ["деньгам", "money", "N"],
      NAS: ["", "money", "N"],
      NAP: ["деньги", "money", "N"],
      NIS: ["", "money", "N"],
      NIP: ["деньгами", "money", "N"],
      NPS: ["", "money", "N"],
      NPP: ["деньгах", "money", "N"],
    },
    минута: {
      NNS: ["минута", "minute, moment", "F"],
      NNP: ["минуты", "minute, moment", "F"],
      NGS: ["минуты", "minute, moment", "F"],
      NGP: ["минут", "minute, moment", "F"],
      NDS: ["минуте", "minute, moment", "F"],
      NDP: ["минутам", "minute, moment", "F"],
      NAS: ["минуту", "minute, moment", "F"],
      NAP: ["минуты", "minute, moment", "F"],
      NIS: ["минутой", "minute, moment", "F"],
      NIP: ["минутами", "minute, moment", "F"],
      NPS: ["минуте", "minute, moment", "F"],
      NPP: ["минутах", "minute, moment", "F"],
    },
    жена: {
      NNS: ["жена", "wife", "F"],
      NNP: ["жёны", "wife", "F"],
      NGS: ["жены", "wife", "F"],
      NGP: ["жён", "wife", "F"],
      NDS: ["жене", "wife", "F"],
      NDP: ["жёнам", "wife", "F"],
      NAS: ["жену", "wife", "F"],
      NAP: ["жён", "wife", "F"],
      NIS: ["женой", "wife", "F"],
      NIP: ["жёнами", "wife", "F"],
      NPS: ["жене", "wife", "F"],
      NPP: ["жёнах", "wife", "F"],
    },
    правда: {
      NNS: ["правда", "truth", "F"],
      NNP: ["правды", "truth", "F"],
      NGS: ["правды", "truth", "F"],
      NGP: ["правд", "truth", "F"],
      NDS: ["правде", "truth", "F"],
      NDP: ["правдам", "truth", "F"],
      NAS: ["правду", "truth", "F"],
      NAP: ["правды", "truth", "F"],
      NIS: ["правдой", "truth", "F"],
      NIP: ["правдами", "truth", "F"],
      NPS: ["правде", "truth", "F"],
      NPP: ["правдах", "truth", "F"],
    },
    страна: {
      NNS: ["страна", "country", "F"],
      NNP: ["страны", "country", "F"],
      NGS: ["страны", "country", "F"],
      NGP: ["стран", "country", "F"],
      NDS: ["стране", "country", "F"],
      NDP: ["странам", "country", "F"],
      NAS: ["страну", "country", "F"],
      NAP: ["страны", "country", "F"],
      NIS: ["страной", "country", "F"],
      NIP: ["странами", "country", "F"],
      NPS: ["стране", "country", "F"],
      NPP: ["странах", "country", "F"],
    },
    свет: {
      NNS: ["свет", "light", "M"],
      NNP: ["светы", "light", "M"],
      NGS: ["света", "light", "M"],
      NGP: ["светов", "light", "M"],
      NDS: ["свету", "light", "M"],
      NDP: ["светам", "light", "M"],
      NAS: ["свет", "light", "M"],
      NAP: ["светы", "light", "M"],
      NIS: ["светом", "light", "M"],
      NIP: ["светами", "light", "M"],
      NPS: ["свете", "light", "M"],
      NPP: ["светах", "light", "M"],
    },
    мать: {
      NNS: ["мать", "mother", "F"],
      NNP: ["матери", "mother", "F"],
      NGS: ["матери", "mother", "F"],
      NGP: ["матерей", "mother", "F"],
      NDS: ["матери", "mother", "F"],
      NDP: ["матерям", "mother", "F"],
      NAS: ["мать", "mother", "F"],
      NAP: ["матерей", "mother", "F"],
      NIS: ["матерью", "mother", "F"],
      NIP: ["матерями", "mother", "F"],
      NPS: ["матери", "mother", "F"],
      NPP: ["матерях", "mother", "F"],
    },
    товарищ: {
      NNS: ["товарищ", "comrade, friend", "M"],
      NNP: ["товарищи", "comrade, friend", "M"],
      NGS: ["товарища", "comrade, friend", "M"],
      NGP: ["товарищей", "comrade, friend", "M"],
      NDS: ["товарищу", "comrade, friend", "M"],
      NDP: ["товарищам", "comrade, friend", "M"],
      NAS: ["товарища", "comrade, friend", "M"],
      NAP: ["товарищей", "comrade, friend", "M"],
      NIS: ["товарищем", "comrade, friend", "M"],
      NIP: ["товарищами", "comrade, friend", "M"],
      NPS: ["товарище", "comrade, friend", "M"],
      NPP: ["товарищах", "comrade, friend", "M"],
    },
    дорога: {
      NNS: ["дорога", "road, way, journey", "F"],
      NNP: ["дороги", "road, way, journey", "F"],
      NGS: ["дороги", "road, way, journey", "F"],
      NGP: ["дорог", "road, way, journey", "F"],
      NDS: ["дороге", "road, way, journey", "F"],
      NDP: ["дорогам", "road, way, journey", "F"],
      NAS: ["дорогу", "road, way, journey", "F"],
      NAP: ["дороги", "road, way, journey", "F"],
      NIS: ["дорогой", "road, way, journey", "F"],
      NIP: ["дорогами", "road, way, journey", "F"],
      NPS: ["дороге", "road, way, journey", "F"],
      NPP: ["дорогах", "road, way, journey", "F"],
    },
    окно: {
      NNS: ["окно", "window, windowsill", "N"],
      NNP: ["окна", "window, windowsill", "N"],
      NGS: ["окна", "window, windowsill", "N"],
      NGP: ["окон", "window, windowsill", "N"],
      NDS: ["окну", "window, windowsill", "N"],
      NDP: ["окнам", "window, windowsill", "N"],
      NAS: ["окно", "window, windowsill", "N"],
      NAP: ["окна", "window, windowsill", "N"],
      NIS: ["окном", "window, windowsill", "N"],
      NIP: ["окнами", "window, windowsill", "N"],
      NPS: ["окне", "window, windowsill", "N"],
      NPP: ["окнах", "window, windowsill", "N"],
    },
    комната: {
      NNS: ["комната", "a room", "F"],
      NNP: ["комнаты", "a room", "F"],
      NGS: ["комнаты", "a room", "F"],
      NGP: ["комнат", "a room", "F"],
      NDS: ["комнате", "a room", "F"],
      NDP: ["комнатам", "a room", "F"],
      NAS: ["комнату", "a room", "F"],
      NAP: ["комнаты", "a room", "F"],
      NIS: ["комнатой", "a room", "F"],
      NIP: ["комнатами", "a room", "F"],
      NPS: ["комнате", "a room", "F"],
      NPP: ["комнатах", "a room", "F"],
    },
    часть: {
      NNS: ["часть", "part, share, department", "F"],
      NNP: ["части", "part, share, department", "F"],
      NGS: ["части", "part, share, department", "F"],
      NGP: ["частей", "part, share, department", "F"],
      NDS: ["части", "part, share, department", "F"],
      NDP: ["частям", "part, share, department", "F"],
      NAS: ["часть", "part, share, department", "F"],
      NAP: ["части", "part, share, department", "F"],
      NIS: ["частью", "part, share, department", "F"],
      NIP: ["частями", "part, share, department", "F"],
      NPS: ["части", "part, share, department", "F"],
      NPP: ["частях", "part, share, department", "F"],
    },
    книга: {
      NNS: ["книга", "a book", "F"],
      NNP: ["книги", "a book", "F"],
      NGS: ["книги", "a book", "F"],
      NGP: ["книг", "a book", "F"],
      NDS: ["книге", "a book", "F"],
      NDP: ["книгам", "a book", "F"],
      NAS: ["книгу", "a book", "F"],
      NAP: ["книги", "a book", "F"],
      NIS: ["книгой", "a book", "F"],
      NIP: ["книгами", "a book", "F"],
      NPS: ["книге", "a book", "F"],
      NPP: ["книгах", "a book", "F"],
    },
    улица: {
      NNS: ["улица", "street", "F"],
      NNP: ["улицы", "street", "F"],
      NGS: ["улицы", "street", "F"],
      NGP: ["улиц", "street", "F"],
      NDS: ["улице", "street", "F"],
      NDP: ["улицам", "street", "F"],
      NAS: ["улицу", "street", "F"],
      NAP: ["улицы", "street", "F"],
      NIS: ["улицей", "street", "F"],
      NIP: ["улицами", "street", "F"],
      NPS: ["улице", "street", "F"],
      NPP: ["улицах", "street", "F"],
    },
    душа: {
      NNS: ["душа", "soul, spirit", "F"],
      NNP: ["души", "soul, spirit", "F"],
      NGS: ["души", "soul, spirit", "F"],
      NGP: ["душ", "soul, spirit", "F"],
      NDS: ["душе", "soul, spirit", "F"],
      NDP: ["душам", "soul, spirit", "F"],
      NAS: ["душу", "soul, spirit", "F"],
      NAP: ["души", "soul, spirit", "F"],
      NIS: ["душой", "soul, spirit", "F"],
      NIP: ["душами", "soul, spirit", "F"],
      NPS: ["душе", "soul, spirit", "F"],
      NPP: ["душах", "soul, spirit", "F"],
    },
    утро: {
      NNS: ["утро", "morning", "N"],
      NNP: ["утра", "morning", "N"],
      NGS: ["утра", "morning", "N"],
      NGP: ["утр", "morning", "N"],
      NDS: ["утру", "morning", "N"],
      NDP: ["утрам", "morning", "N"],
      NAS: ["утро", "morning", "N"],
      NAP: ["утра", "morning", "N"],
      NIS: ["утром", "morning", "N"],
      NIP: ["утрами", "morning", "N"],
      NPS: ["утре", "morning", "N"],
      NPP: ["утрах", "morning", "N"],
    },
    вечер: {
      NNS: ["вечер", "evening", "M"],
      NNP: ["вечера", "evening", "M"],
      NGS: ["вечера", "evening", "M"],
      NGP: ["вечеров", "evening", "M"],
      NDS: ["вечеру", "evening", "M"],
      NDP: ["вечерам", "evening", "M"],
      NAS: ["вечер", "evening", "M"],
      NAP: ["вечера", "evening", "M"],
      NIS: ["вечером", "evening", "M"],
      NIP: ["вечерами", "evening", "M"],
      NPS: ["вечере", "evening", "M"],
      NPP: ["вечерах", "evening", "M"],
    },
    пол: {
      NNS: ["пол", "floor; sex", "M"],
      NNP: ["полы", "floor; sex", "M"],
      NGS: ["пола", "floor; sex", "M"],
      NGP: ["полов", "floor; sex", "M"],
      NDS: ["полу", "floor; sex", "M"],
      NDP: ["полам", "floor; sex", "M"],
      NAS: ["пол", "floor; sex", "M"],
      NAP: ["полы", "floor; sex", "M"],
      NIS: ["полом", "floor; sex", "M"],
      NIP: ["полами", "floor; sex", "M"],
      NPS: ["полу", "floor; sex", "M"],
      NPP: ["полах", "floor; sex", "M"],
    },
    народ: {
      NNS: ["народ", "people, nation", "M"],
      NNP: ["народы", "people, nation", "M"],
      NGS: ["народа", "people, nation", "M"],
      NGP: ["народов", "people, nation", "M"],
      NDS: ["народу", "people, nation", "M"],
      NDP: ["народам", "people, nation", "M"],
      NAS: ["народ", "people, nation", "M"],
      NAP: ["народы", "people, nation", "M"],
      NIS: ["народом", "people, nation", "M"],
      NIP: ["народами", "people, nation", "M"],
      NPS: ["народе", "people, nation", "M"],
      NPP: ["народах", "people, nation", "M"],
    },
    плечо: {
      NNS: ["плечо", "shoulder, upper arm", "N"],
      NNP: ["плечи", "shoulder, upper arm", "N"],
      NGS: ["плеча", "shoulder, upper arm", "N"],
      NGP: ["плеч", "shoulder, upper arm", "N"],
      NDS: ["плечу", "shoulder, upper arm", "N"],
      NDP: ["плечам", "shoulder, upper arm", "N"],
      NAS: ["плечо", "shoulder, upper arm", "N"],
      NAP: ["плечи", "shoulder, upper arm", "N"],
      NIS: ["плечом", "shoulder, upper arm", "N"],
      NIP: ["плечами", "shoulder, upper arm", "N"],
      NPS: ["плече", "shoulder, upper arm", "N"],
      NPP: ["плечах", "shoulder, upper arm", "N"],
    },
    бог: {
      NNS: ["бог", "god", "M"],
      NNP: ["боги", "god", "M"],
      NGS: ["бога", "god", "M"],
      NGP: ["богов", "god", "M"],
      NDS: ["богу", "god", "M"],
      NDP: ["богам", "god", "M"],
      NAS: ["бога", "god", "M"],
      NAP: ["богов", "god", "M"],
      NIS: ["богом", "god", "M"],
      NIP: ["богами", "god", "M"],
      NPS: ["боге", "god", "M"],
      NPP: ["богах", "god", "M"],
    },
    взгляд: {
      NNS: ["взгляд", "look, glance; view", "M"],
      NNP: ["взгляды", "look, glance; view", "M"],
      NGS: ["взгляда", "look, glance; view", "M"],
      NGP: ["взглядов", "look, glance; view", "M"],
      NDS: ["взгляду", "look, glance; view", "M"],
      NDP: ["взглядам", "look, glance; view", "M"],
      NAS: ["взгляд", "look, glance; view", "M"],
      NAP: ["взгляды", "look, glance; view", "M"],
      NIS: ["взглядом", "look, glance; view", "M"],
      NIP: ["взглядами", "look, glance; view", "M"],
      NPS: ["взгляде", "look, glance; view", "M"],
      NPP: ["взглядах", "look, glance; view", "M"],
    },
    палец: {
      NNS: ["палец", "finger, toe", "M"],
      NNP: ["пальцы", "finger, toe", "M"],
      NGS: ["пальца", "finger, toe", "M"],
      NGP: ["пальцев", "finger, toe", "M"],
      NDS: ["пальцу", "finger, toe", "M"],
      NDP: ["пальцам", "finger, toe", "M"],
      NAS: ["палец", "finger, toe", "M"],
      NAP: ["пальцы", "finger, toe", "M"],
      NIS: ["пальцем", "finger, toe", "M"],
      NIP: ["пальцами", "finger, toe", "M"],
      NPS: ["пальце", "finger, toe", "M"],
      NPP: ["пальцах", "finger, toe", "M"],
    },
    история: {
      NNS: ["история", "history, story, event", "F"],
      NNP: ["истории", "history, story, event", "F"],
      NGS: ["истории", "history, story, event", "F"],
      NGP: ["историй", "history, story, event", "F"],
      NDS: ["истории", "history, story, event", "F"],
      NDP: ["историям", "history, story, event", "F"],
      NAS: ["историю", "history, story, event", "F"],
      NAP: ["истории", "history, story, event", "F"],
      NIS: ["историей", "history, story, event", "F"],
      NIP: ["историями", "history, story, event", "F"],
      NPS: ["истории", "history, story, event", "F"],
      NPP: ["историях", "history, story, event", "F"],
    },
    мысль: {
      NNS: ["мысль", "thought, idea", "F"],
      NNP: ["мысли", "thought, idea", "F"],
      NGS: ["мысли", "thought, idea", "F"],
      NGP: ["мыслей", "thought, idea", "F"],
      NDS: ["мысли", "thought, idea", "F"],
      NDP: ["мыслям", "thought, idea", "F"],
      NAS: ["мысль", "thought, idea", "F"],
      NAP: ["мысли", "thought, idea", "F"],
      NIS: ["мыслью", "thought, idea", "F"],
      NIP: ["мыслями", "thought, idea", "F"],
      NPS: ["мысли", "thought, idea", "F"],
      NPP: ["мыслях", "thought, idea", "F"],
    },
    сын: {
      NNS: ["сын", "son", "M"],
      NNP: ["сыновья", "son", "M"],
      NGS: ["сына", "son", "M"],
      NGP: ["сыновей", "son", "M"],
      NDS: ["сыну", "son", "M"],
      NDP: ["сыновьям", "son", "M"],
      NAS: ["сына", "son", "M"],
      NAP: ["сыновей", "son", "M"],
      NIS: ["сыном", "son", "M"],
      NIP: ["сыновьями", "son", "M"],
      NPS: ["сыне", "son", "M"],
      NPP: ["сыновьях", "son", "M"],
    },
    лес: {
      NNS: ["лес", "forest", "M"],
      NNP: ["леса", "forest", "M"],
      NGS: ["леса", "forest", "M"],
      NGP: ["лесов", "forest", "M"],
      NDS: ["лесу", "forest", "M"],
      NDP: ["лесам", "forest", "M"],
      NAS: ["лес", "forest", "M"],
      NAP: ["леса", "forest", "M"],
      NIS: ["лесом", "forest", "M"],
      NIP: ["лесами", "forest", "M"],
      NPS: ["о лесе", "forest", "M"],
      NPP: ["лесах", "forest", "M"],
    },
    пора: {
      NNS: ["пора", "time", "F"],
      NNP: ["поры", "time", "F"],
      NGS: ["поры", "time", "F"],
      NGP: ["пор", "time", "F"],
      NDS: ["поре", "time", "F"],
      NDP: ["порам", "time", "F"],
      NAS: ["пору", "time", "F"],
      NAP: ["поры", "time", "F"],
      NIS: ["порой", "time", "F"],
      NIP: ["порами", "time", "F"],
      NPS: ["поре", "time", "F"],
      NPP: ["порах", "time", "F"],
    },
    имя: {
      NNS: ["имя", "name", "N"],
      NNP: ["имена", "name", "N"],
      NGS: ["имени", "name", "N"],
      NGP: ["имён", "name", "N"],
      NDS: ["имени", "name", "N"],
      NDP: ["именам", "name", "N"],
      NAS: ["имя", "name", "N"],
      NAP: ["имена", "name", "N"],
      NIS: ["именем", "name", "N"],
      NIP: ["именами", "name", "N"],
      NPS: ["имени", "name", "N"],
      NPP: ["именах", "name", "N"],
    },
    разговор: {
      NNS: ["разговор", "talk, conversation", "M"],
      NNP: ["разговоры", "talk, conversation", "M"],
      NGS: ["разговора", "talk, conversation", "M"],
      NGP: ["разговоров", "talk, conversation", "M"],
      NDS: ["разговору", "talk, conversation", "M"],
      NDP: ["разговорам", "talk, conversation", "M"],
      NAS: ["разговор", "talk, conversation", "M"],
      NAP: ["разговоры", "talk, conversation", "M"],
      NIS: ["разговором", "talk, conversation", "M"],
      NIP: ["разговорами", "talk, conversation", "M"],
      NPS: ["разговоре", "talk, conversation", "M"],
      NPP: ["разговорах", "talk, conversation", "M"],
    },
    тело: {
      NNS: ["тело", "body", "N"],
      NNP: ["тела", "body", "N"],
      NGS: ["тела", "body", "N"],
      NGP: ["тел", "body", "N"],
      NDS: ["телу", "body", "N"],
      NDP: ["телам", "body", "N"],
      NAS: ["тело", "body", "N"],
      NAP: ["тела", "body", "N"],
      NIS: ["телом", "body", "N"],
      NIP: ["телами", "body", "N"],
      NPS: ["теле", "body", "N"],
      NPP: ["телах", "body", "N"],
    },
    стена: {
      NNS: ["стена", "wall", "F"],
      NNP: ["стены", "wall", "F"],
      NGS: ["стены", "wall", "F"],
      NGP: ["стен", "wall", "F"],
      NDS: ["стене", "wall", "F"],
      NDP: ["стенам", "wall", "F"],
      NAS: ["стену", "wall", "F"],
      NAP: ["стены", "wall", "F"],
      NIS: ["стеной", "wall", "F"],
      NIP: ["стенами", "wall", "F"],
      NPS: ["стене", "wall", "F"],
      NPP: ["стенах", "wall", "F"],
    },
    право: {
      NNS: ["право", "right", "N"],
      NNP: ["права", "right", "N"],
      NGS: ["права", "right", "N"],
      NGP: ["прав", "right", "N"],
      NDS: ["праву", "right", "N"],
      NDP: ["правам", "right", "N"],
      NAS: ["право", "right", "N"],
      NAP: ["права", "right", "N"],
      NIS: ["правом", "right", "N"],
      NIP: ["правами", "right", "N"],
      NPS: ["праве", "right", "N"],
      NPP: ["правах", "right", "N"],
    },
    старик: {
      NNS: ["старик", "old man", "M"],
      NNP: ["старики", "old man", "M"],
      NGS: ["старика", "old man", "M"],
      NGP: ["стариков", "old man", "M"],
      NDS: ["старику", "old man", "M"],
      NDP: ["старикам", "old man", "M"],
      NAS: ["старика", "old man", "M"],
      NAP: ["стариков", "old man", "M"],
      NIS: ["стариком", "old man", "M"],
      NIP: ["стариками", "old man", "M"],
      NPS: ["старике", "old man", "M"],
      NPP: ["стариках", "old man", "M"],
    },
    мама: {
      NNS: ["мама", "mummy, mum", "F"],
      NNP: ["мамы", "mummy, mum", "F"],
      NGS: ["мамы", "mummy, mum", "F"],
      NGP: ["мам", "mummy, mum", "F"],
      NDS: ["маме", "mummy, mum", "F"],
      NDP: ["мамам", "mummy, mum", "F"],
      NAS: ["маму", "mummy, mum", "F"],
      NAP: ["мам", "mummy, mum", "F"],
      NIS: ["мамой", "mummy, mum", "F"],
      NIP: ["мамами", "mummy, mum", "F"],
      NPS: ["маме", "mummy, mum", "F"],
      NPP: ["мамах", "mummy, mum", "F"],
    },
    путь: {
      NNS: ["путь", "way, track, path", "M"],
      NNP: ["пути", "way, track, path", "M"],
      NGS: ["пути", "way, track, path", "M"],
      NGP: ["путей", "way, track, path", "M"],
      NDS: ["пути", "way, track, path", "M"],
      NDP: ["путям", "way, track, path", "M"],
      NAS: ["путь", "way, track, path", "M"],
      NAP: ["пути", "way, track, path", "M"],
      NIS: ["путём", "way, track, path", "M"],
      NIP: ["путями", "way, track, path", "M"],
      NPS: ["пути", "way, track, path", "M"],
      NPP: ["путях", "way, track, path", "M"],
    },
    месяц: {
      NNS: ["месяц", "month", "M"],
      NNP: ["месяцы", "month", "M"],
      NGS: ["месяца", "month", "M"],
      NGP: ["месяцев", "month", "M"],
      NDS: ["месяцу", "month", "M"],
      NDP: ["месяцам", "month", "M"],
      NAS: ["месяц", "month", "M"],
      NAP: ["месяцы", "month", "M"],
      NIS: ["месяцем", "month", "M"],
      NIP: ["месяцами", "month", "M"],
      NPS: ["месяце", "month", "M"],
      NPP: ["месяцах", "month", "M"],
    },
    спина: {
      NNS: ["спина", "back", "F"],
      NNP: ["спины", "back", "F"],
      NGS: ["спины", "back", "F"],
      NGP: ["спин", "back", "F"],
      NDS: ["спине", "back", "F"],
      NDP: ["спинам", "back", "F"],
      NAS: ["спину", "back", "F"],
      NAP: ["спины", "back", "F"],
      NIS: ["спиной", "back", "F"],
      NIP: ["спинами", "back", "F"],
      NPS: ["спине", "back", "F"],
      NPP: ["спинах", "back", "F"],
    },
    язык: {
      NNS: ["язык", "tongue, language", "M"],
      NNP: ["языки", "tongue, language", "M"],
      NGS: ["языка", "tongue, language", "M"],
      NGP: ["языков", "tongue, language", "M"],
      NDS: ["языку", "tongue, language", "M"],
      NDP: ["языкам", "tongue, language", "M"],
      NAS: ["язык", "tongue, language", "M"],
      NAP: ["языки", "tongue, language", "M"],
      NIS: ["языком", "tongue, language", "M"],
      NIP: ["языками", "tongue, language", "M"],
      NPS: ["языке", "tongue, language", "M"],
      NPP: ["языках", "tongue, language", "M"],
    },
    сердце: {
      NNS: ["сердце", "heart", "N"],
      NNP: ["сердца", "heart", "N"],
      NGS: ["сердца", "heart", "N"],
      NGP: ["сердец", "heart", "N"],
      NDS: ["сердцу", "heart", "N"],
      NDP: ["сердцам", "heart", "N"],
      NAS: ["сердце", "heart", "N"],
      NAP: ["сердца", "heart", "N"],
      NIS: ["сердцем", "heart", "N"],
      NIP: ["сердцами", "heart", "N"],
      NPS: ["сердце", "heart", "N"],
      NPP: ["сердцах", "heart", "N"],
    },
    мальчик: {
      NNS: ["мальчик", "boy", "M"],
      NNP: ["мальчики", "boy", "M"],
      NGS: ["мальчика", "boy", "M"],
      NGP: ["мальчиков", "boy", "M"],
      NDS: ["мальчику", "boy", "M"],
      NDP: ["мальчикам", "boy", "M"],
      NAS: ["мальчика", "boy", "M"],
      NAP: ["мальчиков", "boy", "M"],
      NIS: ["мальчиком", "boy", "M"],
      NIP: ["мальчиками", "boy", "M"],
      NPS: ["мальчике", "boy", "M"],
      NPP: ["мальчиках", "boy", "M"],
    },
    небо: {
      NNS: ["небо", "sky", "N"],
      NNP: ["небеса", "sky", "N"],
      NGS: ["неба", "sky", "N"],
      NGP: ["небес", "sky", "N"],
      NDS: ["небу", "sky", "N"],
      NDP: ["небесам", "sky", "N"],
      NAS: ["небо", "sky", "N"],
      NAP: ["небеса", "sky", "N"],
      NIS: ["небом", "sky", "N"],
      NIP: ["небесами", "sky", "N"],
      NPS: ["небе", "sky", "N"],
      NPP: ["небесах", "sky", "N"],
    },
    смерть: {
      NNS: ["смерть", "death", "F"],
      NNP: ["смерти", "death", "F"],
      NGS: ["смерти", "death", "F"],
      NGP: ["смертей", "death", "F"],
      NDS: ["смерти", "death", "F"],
      NDP: ["смертям", "death", "F"],
      NAS: ["смерть", "death", "F"],
      NAP: ["смерти", "death", "F"],
      NIS: ["смертью", "death", "F"],
      NIP: ["смертями", "death", "F"],
      NPS: ["смерти", "death", "F"],
      NPP: ["смертях", "death", "F"],
    },
    девушка: {
      NNS: ["девушка", "girl, miss", "F"],
      NNP: ["девушки", "girl, miss", "F"],
      NGS: ["девушки", "girl, miss", "F"],
      NGP: ["девушек", "girl, miss", "F"],
      NDS: ["девушке", "girl, miss", "F"],
      NDP: ["девушкам", "girl, miss", "F"],
      NAS: ["девушку", "girl, miss", "F"],
      NAP: ["девушек", "girl, miss", "F"],
      NIS: ["девушкой", "girl, miss", "F"],
      NIP: ["девушками", "girl, miss", "F"],
      NPS: ["девушке", "girl, miss", "F"],
      NPP: ["девушках", "girl, miss", "F"],
    },
    образ: {
      NNS: ["образ", "shape, form, image", "M"],
      NNP: ["образы", "shape, form, image", "M"],
      NGS: ["образа", "shape, form, image", "M"],
      NGP: ["образов", "shape, form, image", "M"],
      NDS: ["образу", "shape, form, image", "M"],
      NDP: ["образам", "shape, form, image", "M"],
      NAS: ["образ", "shape, form, image", "M"],
      NAP: ["образы", "shape, form, image", "M"],
      NIS: ["образом", "shape, form, image", "M"],
      NIP: ["образами", "shape, form, image", "M"],
      NPS: ["образе", "shape, form, image", "M"],
      NPP: ["образах", "shape, form, image", "M"],
    },
    письмо: {
      NNS: ["письмо", "letter", "N"],
      NNP: ["письма", "letter", "N"],
      NGS: ["письма", "letter", "N"],
      NGP: ["писем", "letter", "N"],
      NDS: ["письму", "letter", "N"],
      NDP: ["письмам", "letter", "N"],
      NAS: ["письмо", "letter", "N"],
      NAP: ["письма", "letter", "N"],
      NIS: ["письмом", "letter", "N"],
      NIP: ["письмами", "letter", "N"],
      NPS: ["письме", "letter", "N"],
      NPP: ["письмах", "letter", "N"],
    },
    власть: {
      NNS: ["власть", "power", "F"],
      NNP: ["власти", "power", "F"],
      NGS: ["власти", "power", "F"],
      NGP: ["властей", "power", "F"],
      NDS: ["власти", "power", "F"],
      NDP: ["властям", "power", "F"],
      NAS: ["власть", "power", "F"],
      NAP: ["власти", "power", "F"],
      NIS: ["властью", "power", "F"],
      NIP: ["властями", "power", "F"],
      NPS: ["власти", "power", "F"],
      NPP: ["властях", "power", "F"],
    },
    воздух: {
      NNS: ["воздух", "air", "M"],
      NNP: ["", "air", "M"],
      NGS: ["воздуха", "air", "M"],
      NGP: ["", "air", "M"],
      NDS: ["воздуху", "air", "M"],
      NDP: ["", "air", "M"],
      NAS: ["воздух", "air", "M"],
      NAP: ["", "air", "M"],
      NIS: ["воздухом", "air", "M"],
      NIP: ["", "air", "M"],
      NPS: ["воздухе", "air", "M"],
      NPP: ["", "air", "M"],
    },
    брат: {
      NNS: ["брат", "brother", "M"],
      NNP: ["братья", "brother", "M"],
      NGS: ["брата", "brother", "M"],
      NGP: ["братьев", "brother", "M"],
      NDS: ["брату", "brother", "M"],
      NDP: ["братьям", "brother", "M"],
      NAS: ["брата", "brother", "M"],
      NAP: ["братьев", "brother", "M"],
      NIS: ["братом", "brother", "M"],
      NIP: ["братьями", "brother", "M"],
      NPS: ["брате", "brother", "M"],
      NPP: ["братьях", "brother", "M"],
    },
    отношение: {
      NNS: ["отношение", "relationship, attitude", "N"],
      NNP: ["отношения", "relationship, attitude", "N"],
      NGS: ["отношения", "relationship, attitude", "N"],
      NGP: ["отношений", "relationship, attitude", "N"],
      NDS: ["отношению", "relationship, attitude", "N"],
      NDP: ["отношениям", "relationship, attitude", "N"],
      NAS: ["отношение", "relationship, attitude", "N"],
      NAP: ["отношения", "relationship, attitude", "N"],
      NIS: ["отношением", "relationship, attitude", "N"],
      NIP: ["отношениями", "relationship, attitude", "N"],
      NPS: ["отношении", "relationship, attitude", "N"],
      NPP: ["отношениях", "relationship, attitude", "N"],
    },
    система: {
      NNS: ["система", "system", "F"],
      NNP: ["системы", "system", "F"],
      NGS: ["системы", "system", "F"],
      NGP: ["систем", "system", "F"],
      NDS: ["системе", "system", "F"],
      NDP: ["системам", "system", "F"],
      NAS: ["систему", "system", "F"],
      NAP: ["системы", "system", "F"],
      NIS: ["системой", "system", "F"],
      NIP: ["системами", "system", "F"],
      NPS: ["системе", "system", "F"],
      NPP: ["системах", "system", "F"],
    },
    квартира: {
      NNS: ["квартира", "flat, apartment", "F"],
      NNP: ["квартиры", "flat, apartment", "F"],
      NGS: ["квартиры", "flat, apartment", "F"],
      NGP: ["квартир", "flat, apartment", "F"],
      NDS: ["квартире", "flat, apartment", "F"],
      NDP: ["квартирам", "flat, apartment", "F"],
      NAS: ["квартиру", "flat, apartment", "F"],
      NAP: ["квартиры", "flat, apartment", "F"],
      NIS: ["квартирой", "flat, apartment", "F"],
      NIP: ["квартирами", "flat, apartment", "F"],
      NPS: ["квартире", "flat, apartment", "F"],
      NPP: ["квартирах", "flat, apartment", "F"],
    },
    любовь: {
      NNS: ["любовь", "love", "F"],
      NNP: ["любви", "love", "F"],
      NGS: ["любви", "love", "F"],
      NGP: ["любвей", "love", "F"],
      NDS: ["любви", "love", "F"],
      NDP: ["любвям", "love", "F"],
      NAS: ["любовь", "love", "F"],
      NAP: ["любви", "love", "F"],
      NIS: ["любовью", "love", "F"],
      NIP: ["любвями", "love", "F"],
      NPS: ["любви", "love", "F"],
      NPP: ["любвях", "love", "F"],
    },
    солдат: {
      NNS: ["солдат", "soldier", "M"],
      NNP: ["солдаты", "soldier", "M"],
      NGS: ["солдата", "soldier", "M"],
      NGP: ["солдат", "soldier", "M"],
      NDS: ["солдату", "soldier", "M"],
      NDP: ["солдатам", "soldier", "M"],
      NAS: ["солдата", "soldier", "M"],
      NAP: ["солдат", "soldier", "M"],
      NIS: ["солдатом", "soldier", "M"],
      NIP: ["солдатами", "soldier", "M"],
      NPS: ["солдате", "soldier", "M"],
      NPP: ["солдатах", "soldier", "M"],
    },
    хозяин: {
      NNS: ["хозяин", "master, boss, host", "M"],
      NNP: ["хозяева", "master, boss, host", "M"],
      NGS: ["хозяина", "master, boss, host", "M"],
      NGP: ["хозяев", "master, boss, host", "M"],
      NDS: ["хозяину", "master, boss, host", "M"],
      NDP: ["хозяевам", "master, boss, host", "M"],
      NAS: ["хозяина", "master, boss, host", "M"],
      NAP: ["хозяев", "master, boss, host", "M"],
      NIS: ["хозяином", "master, boss, host", "M"],
      NIP: ["хозяевами", "master, boss, host", "M"],
      NPS: ["хозяине", "master, boss, host", "M"],
      NPP: ["хозяевах", "master, boss, host", "M"],
    },
    начальник: {
      NNS: ["начальник", "chief, head, superior", "M"],
      NNP: ["начальники", "chief, head, superior", "M"],
      NGS: ["начальника", "chief, head, superior", "M"],
      NGP: ["начальников", "chief, head, superior", "M"],
      NDS: ["начальнику", "chief, head, superior", "M"],
      NDP: ["начальникам", "chief, head, superior", "M"],
      NAS: ["начальника", "chief, head, superior", "M"],
      NAP: ["начальников", "chief, head, superior", "M"],
      NIS: ["начальником", "chief, head, superior", "M"],
      NIP: ["начальниками", "chief, head, superior", "M"],
      NPS: ["начальнике", "chief, head, superior", "M"],
      NPP: ["начальниках", "chief, head, superior", "M"],
    },
    школа: {
      NNS: ["школа", "school", "F"],
      NNP: ["школы", "school", "F"],
      NGS: ["школы", "school", "F"],
      NGP: ["школ", "school", "F"],
      NDS: ["школе", "school", "F"],
      NDP: ["школам", "school", "F"],
      NAS: ["школу", "school", "F"],
      NAP: ["школы", "school", "F"],
      NIS: ["школой", "school", "F"],
      NIP: ["школами", "school", "F"],
      NPS: ["школе", "school", "F"],
      NPP: ["школах", "school", "F"],
    },
    парень: {
      NNS: ["парень", "boy, fellow, guy", "M"],
      NNP: ["парни", "boy, fellow, guy", "M"],
      NGS: ["парня", "boy, fellow, guy", "M"],
      NGP: ["парней", "boy, fellow, guy", "M"],
      NDS: ["парню", "boy, fellow, guy", "M"],
      NDP: ["парням", "boy, fellow, guy", "M"],
      NAS: ["парня", "boy, fellow, guy", "M"],
      NAP: ["парней", "boy, fellow, guy", "M"],
      NIS: ["парнем", "boy, fellow, guy", "M"],
      NIP: ["парнями", "boy, fellow, guy", "M"],
      NPS: ["парне", "boy, fellow, guy", "M"],
      NPP: ["парнях", "boy, fellow, guy", "M"],
    },
    кровь: {
      NNS: ["кровь", "blood", "F"],
      NNP: ["крови", "blood", "F"],
      NGS: ["крови", "blood", "F"],
      NGP: ["кровей", "blood", "F"],
      NDS: ["крови", "blood", "F"],
      NDP: ["кровям", "blood", "F"],
      NAS: ["кровь", "blood", "F"],
      NAP: ["крови", "blood", "F"],
      NIS: ["кровью", "blood", "F"],
      NIP: ["кровями", "blood", "F"],
      NPS: ["крови", "blood", "F"],
      NPP: ["кровях", "blood", "F"],
    },
    солнце: {
      NNS: ["солнце", "sun", "N"],
      NNP: ["солнца", "sun", "N"],
      NGS: ["солнца", "sun", "N"],
      NGP: ["солнц", "sun", "N"],
      NDS: ["солнцу", "sun", "N"],
      NDP: ["солнцам", "sun", "N"],
      NAS: ["солнце", "sun", "N"],
      NAP: ["солнца", "sun", "N"],
      NIS: ["солнцем", "sun", "N"],
      NIP: ["солнцами", "sun", "N"],
      NPS: ["солнце", "sun", "N"],
      NPP: ["солнцах", "sun", "N"],
    },
    неделя: {
      NNS: ["неделя", "week", "F"],
      NNP: ["недели", "week", "F"],
      NGS: ["недели", "week", "F"],
      NGP: ["недель", "week", "F"],
      NDS: ["неделе", "week", "F"],
      NDP: ["неделям", "week", "F"],
      NAS: ["неделю", "week", "F"],
      NAP: ["недели", "week", "F"],
      NIS: ["неделей", "week", "F"],
      NIP: ["неделями", "week", "F"],
      NPS: ["неделе", "week", "F"],
      NPP: ["неделях", "week", "F"],
    },
    ребята: {
      NNS: ["", "guys, children", "N"],
      NNP: ["ребята", "guys, children", "N"],
      NGS: ["", "guys, children", "N"],
      NGP: ["ребят", "guys, children", "N"],
      NDS: ["", "guys, children", "N"],
      NDP: ["ребятам", "guys, children", "N"],
      NAS: ["", "guys, children", "N"],
      NAP: ["ребят", "guys, children", "N"],
      NIS: ["", "guys, children", "N"],
      NIP: ["ребятами", "guys, children", "N"],
      NPS: ["", "guys, children", "N"],
      NPP: ["ребятах", "guys, children", "N"],
    },
    шаг: {
      NNS: ["шаг", "step", "M"],
      NNP: ["шаги", "step", "M"],
      NGS: ["шага", "step", "M"],
      NGP: ["шагов", "step", "M"],
      NDS: ["шагу", "step", "M"],
      NDP: ["шагам", "step", "M"],
      NAS: ["шаг", "step", "M"],
      NAP: ["шаги", "step", "M"],
      NIS: ["шагом", "step", "M"],
      NIP: ["шагами", "step", "M"],
      NPS: ["шаге", "step", "M"],
      NPP: ["шагах", "step", "M"],
    },
    мужчина: {
      NNS: ["мужчина", "man, male", "M"],
      NNP: ["мужчины", "man, male", "M"],
      NGS: ["мужчины", "man, male", "M"],
      NGP: ["мужчин", "man, male", "M"],
      NDS: ["мужчине", "man, male", "M"],
      NDP: ["мужчинам", "man, male", "M"],
      NAS: ["мужчину", "man, male", "M"],
      NAP: ["мужчин", "man, male", "M"],
      NIS: ["мужчиной", "man, male", "M"],
      NIP: ["мужчинами", "man, male", "M"],
      NPS: ["мужчине", "man, male", "M"],
      NPP: ["мужчинах", "man, male", "M"],
    },
    нос: {
      NNS: ["нос", "nose", "M"],
      NNP: ["носы", "nose", "M"],
      NGS: ["носа", "nose", "M"],
      NGP: ["носов", "nose", "M"],
      NDS: ["носу", "nose", "M"],
      NDP: ["носам", "nose", "M"],
      NAS: ["нос", "nose", "M"],
      NAP: ["носы", "nose", "M"],
      NIS: ["носом", "nose", "M"],
      NIP: ["носами", "nose", "M"],
      NPS: ["о носе", "nose", "M"],
      NPP: ["носах", "nose", "M"],
    },
    внимание: {
      NNS: ["внимание", "attention", "N"],
      NNP: ["внимания", "attention", "N"],
      NGS: ["внимания", "attention", "N"],
      NGP: ["вниманий", "attention", "N"],
      NDS: ["вниманию", "attention", "N"],
      NDP: ["вниманиям", "attention", "N"],
      NAS: ["внимание", "attention", "N"],
      NAP: ["внимания", "attention", "N"],
      NIS: ["вниманием", "attention", "N"],
      NIP: ["вниманиями", "attention", "N"],
      NPS: ["внимании", "attention", "N"],
      NPP: ["вниманиях", "attention", "N"],
    },
    капитан: {
      NNS: ["капитан", "captain, master", "M"],
      NNP: ["капитаны", "captain, master", "M"],
      NGS: ["капитана", "captain, master", "M"],
      NGP: ["капитанов", "captain, master", "M"],
      NDS: ["капитану", "captain, master", "M"],
      NDP: ["капитанам", "captain, master", "M"],
      NAS: ["капитана", "captain, master", "M"],
      NAP: ["капитанов", "captain, master", "M"],
      NIS: ["капитаном", "captain, master", "M"],
      NIP: ["капитанами", "captain, master", "M"],
      NPS: ["капитане", "captain, master", "M"],
      NPP: ["капитанах", "captain, master", "M"],
    },
    ухо: {
      NNS: ["ухо", "ear", "N"],
      NNP: ["уши", "ear", "N"],
      NGS: ["уха", "ear", "N"],
      NGP: ["ушей", "ear", "N"],
      NDS: ["уху", "ear", "N"],
      NDP: ["ушам", "ear", "N"],
      NAS: ["ухо", "ear", "N"],
      NAP: ["уши", "ear", "N"],
      NIS: ["ухом", "ear", "N"],
      NIP: ["ушами", "ear", "N"],
      NPS: ["ухе", "ear", "N"],
      NPP: ["ушах", "ear", "N"],
    },
    чувство: {
      NNS: ["чувство", "feeling", "N"],
      NNP: ["чувства", "feeling", "N"],
      NGS: ["чувства", "feeling", "N"],
      NGP: ["чувств", "feeling", "N"],
      NDS: ["чувству", "feeling", "N"],
      NDP: ["чувствам", "feeling", "N"],
      NAS: ["чувство", "feeling", "N"],
      NAP: ["чувства", "feeling", "N"],
      NIS: ["чувством", "feeling", "N"],
      NIP: ["чувствами", "feeling", "N"],
      NPS: ["чувстве", "feeling", "N"],
      NPP: ["чувствах", "feeling", "N"],
    },
    берег: {
      NNS: ["берег", "bank, shore, coast", "M"],
      NNP: ["берега", "bank, shore, coast", "M"],
      NGS: ["берега", "bank, shore, coast", "M"],
      NGP: ["берегов", "bank, shore, coast", "M"],
      NDS: ["берегу", "bank, shore, coast", "M"],
      NDP: ["берегам", "bank, shore, coast", "M"],
      NAS: ["берег", "bank, shore, coast", "M"],
      NAP: ["берега", "bank, shore, coast", "M"],
      NIS: ["берегом", "bank, shore, coast", "M"],
      NIP: ["берегами", "bank, shore, coast", "M"],
      NPS: ["береге", "bank, shore, coast", "M"],
      NPP: ["берегах", "bank, shore, coast", "M"],
    },
    семья: {
      NNS: ["семья", "family", "F"],
      NNP: ["семьи", "family", "F"],
      NGS: ["семьи", "family", "F"],
      NGP: ["семей", "family", "F"],
      NDS: ["семье", "family", "F"],
      NDP: ["семьям", "family", "F"],
      NAS: ["семью", "family", "F"],
      NAP: ["семьи", "family", "F"],
      NIS: ["семьёй", "family", "F"],
      NIP: ["семьями", "family", "F"],
      NPS: ["семье", "family", "F"],
      NPP: ["семьях", "family", "F"],
    },
    генерал: {
      NNS: ["генерал", "general", "M"],
      NNP: ["генералы", "general", "M"],
      NGS: ["генерала", "general", "M"],
      NGP: ["генералов", "general", "M"],
      NDS: ["генералу", "general", "M"],
      NDP: ["генералам", "general", "M"],
      NAS: ["генерала", "general", "M"],
      NAP: ["генералов", "general", "M"],
      NIS: ["генералом", "general", "M"],
      NIP: ["генералами", "general", "M"],
      NPS: ["генерале", "general", "M"],
      NPP: ["генералах", "general", "M"],
    },
    момент: {
      NNS: ["момент", "moment, instant", "M"],
      NNP: ["моменты", "moment, instant", "M"],
      NGS: ["момента", "moment, instant", "M"],
      NGP: ["моментов", "moment, instant", "M"],
      NDS: ["моменту", "moment, instant", "M"],
      NDP: ["моментам", "moment, instant", "M"],
      NAS: ["момент", "moment, instant", "M"],
      NAP: ["моменты", "moment, instant", "M"],
      NIS: ["моментом", "moment, instant", "M"],
      NIP: ["моментами", "moment, instant", "M"],
      NPS: ["моменте", "moment, instant", "M"],
      NPP: ["моментах", "moment, instant", "M"],
    },
    труд: {
      NNS: ["труд", "labour, work", "M"],
      NNP: ["труды", "labour, work", "M"],
      NGS: ["труда", "labour, work", "M"],
      NGP: ["трудов", "labour, work", "M"],
      NDS: ["труду", "labour, work", "M"],
      NDP: ["трудам", "labour, work", "M"],
      NAS: ["труд", "labour, work", "M"],
      NAP: ["труды", "labour, work", "M"],
      NIS: ["трудом", "labour, work", "M"],
      NIP: ["трудами", "labour, work", "M"],
      NPS: ["труде", "labour, work", "M"],
      NPP: ["трудах", "labour, work", "M"],
    },
    группа: {
      NNS: ["группа", "group", "F"],
      NNP: ["группы", "group", "F"],
      NGS: ["группы", "group", "F"],
      NGP: ["групп", "group", "F"],
      NDS: ["группе", "group", "F"],
      NDP: ["группам", "group", "F"],
      NAS: ["группу", "group", "F"],
      NAP: ["группы", "group", "F"],
      NIS: ["группой", "group", "F"],
      NIP: ["группами", "group", "F"],
      NPS: ["группе", "group", "F"],
      NPP: ["группах", "group", "F"],
    },
    муж: {
      NNS: ["муж", "husband", "M"],
      NNP: ["мужья", "husband", "M"],
      NGS: ["мужа", "husband", "M"],
      NGP: ["мужей", "husband", "M"],
      NDS: ["мужу", "husband", "M"],
      NDP: ["мужьям", "husband", "M"],
      NAS: ["мужа", "husband", "M"],
      NAP: ["мужей", "husband", "M"],
      NIS: ["мужем", "husband", "M"],
      NIP: ["мужьями", "husband", "M"],
      NPS: ["муже", "husband", "M"],
      NPP: ["мужьях", "husband", "M"],
    },
    движение: {
      NNS: ["движение", "movement", "N"],
      NNP: ["движения", "movement", "N"],
      NGS: ["движения", "movement", "N"],
      NGP: ["движений", "movement", "N"],
      NDS: ["движению", "movement", "N"],
      NDP: ["движениям", "movement", "N"],
      NAS: ["движение", "movement", "N"],
      NAP: ["движения", "movement", "N"],
      NIS: ["движением", "movement", "N"],
      NIP: ["движениями", "movement", "N"],
      NPS: ["движении", "movement", "N"],
      NPP: ["движениях", "movement", "N"],
    },
    порядок: {
      NNS: ["порядок", "order", "M"],
      NNP: ["порядки", "order", "M"],
      NGS: ["порядка", "order", "M"],
      NGP: ["порядков", "order", "M"],
      NDS: ["порядку", "order", "M"],
      NDP: ["порядкам", "order", "M"],
      NAS: ["порядок", "order", "M"],
      NAP: ["порядки", "order", "M"],
      NIS: ["порядком", "order", "M"],
      NIP: ["порядками", "order", "M"],
      NPS: ["порядке", "order", "M"],
      NPP: ["порядках", "order", "M"],
    },
    ответ: {
      NNS: ["ответ", "answer, reply", "M"],
      NNP: ["ответы", "answer, reply", "M"],
      NGS: ["ответа", "answer, reply", "M"],
      NGP: ["ответов", "answer, reply", "M"],
      NDS: ["ответу", "answer, reply", "M"],
      NDP: ["ответам", "answer, reply", "M"],
      NAS: ["ответ", "answer, reply", "M"],
      NAP: ["ответы", "answer, reply", "M"],
      NIS: ["ответом", "answer, reply", "M"],
      NIP: ["ответами", "answer, reply", "M"],
      NPS: ["ответе", "answer, reply", "M"],
      NPP: ["ответах", "answer, reply", "M"],
    },
    газета: {
      NNS: ["газета", "newspaper", "F"],
      NNP: ["газеты", "newspaper", "F"],
      NGS: ["газеты", "newspaper", "F"],
      NGP: ["газет", "newspaper", "F"],
      NDS: ["газете", "newspaper", "F"],
      NDP: ["газетам", "newspaper", "F"],
      NAS: ["газету", "newspaper", "F"],
      NAP: ["газеты", "newspaper", "F"],
      NIS: ["газетой", "newspaper", "F"],
      NIP: ["газетами", "newspaper", "F"],
      NPS: ["газете", "newspaper", "F"],
      NPP: ["газетах", "newspaper", "F"],
    },
    помощь: {
      NNS: ["помощь", "help", "F"],
      NNP: ["помощи", "help", "F"],
      NGS: ["помощи", "help", "F"],
      NGP: ["помощей", "help", "F"],
      NDS: ["помощи", "help", "F"],
      NDP: ["помощам", "help", "F"],
      NAS: ["помощь", "help", "F"],
      NAP: ["помощи", "help", "F"],
      NIS: ["помощью", "help", "F"],
      NIP: ["помощами", "help", "F"],
      NPS: ["помощи", "help", "F"],
      NPP: ["помощах", "help", "F"],
    },
    собака: {
      NNS: ["собака", "dog", "F"],
      NNP: ["собаки", "dog", "F"],
      NGS: ["собаки", "dog", "F"],
      NGP: ["собак", "dog", "F"],
      NDS: ["собаке", "dog", "F"],
      NDP: ["собакам", "dog", "F"],
      NAS: ["собаку", "dog", "F"],
      NAP: ["собак", "dog", "F"],
      NIS: ["собакой", "dog", "F"],
      NIP: ["собаками", "dog", "F"],
      NPS: ["собаке", "dog", "F"],
      NPP: ["собаках", "dog", "F"],
    },
    дерево: {
      NNS: ["дерево", "tree", "N"],
      NNP: ["деревья", "tree", "N"],
      NGS: ["дерева", "tree", "N"],
      NGP: ["деревьев", "tree", "N"],
      NDS: ["дереву", "tree", "N"],
      NDP: ["деревьям", "tree", "N"],
      NAS: ["дерево", "tree", "N"],
      NAP: ["деревья", "tree", "N"],
      NIS: ["деревом", "tree", "N"],
      NIP: ["деревьями", "tree", "N"],
      NPS: ["дереве", "tree", "N"],
      NPP: ["деревьях", "tree", "N"],
    },
    снег: {
      NNS: ["снег", "snow", "M"],
      NNP: ["снега", "snow", "M"],
      NGS: ["снега", "snow", "M"],
      NGP: ["снегов", "snow", "M"],
      NDS: ["снегу", "snow", "M"],
      NDP: ["снегам", "snow", "M"],
      NAS: ["снег", "snow", "M"],
      NAP: ["снега", "snow", "M"],
      NIS: ["снегом", "snow", "M"],
      NIP: ["снегами", "snow", "M"],
      NPS: ["снеге", "snow", "M"],
      NPP: ["снегах", "snow", "M"],
    },
    сон: {
      NNS: ["сон", "dream", "M"],
      NNP: ["сны", "dream", "M"],
      NGS: ["сна", "dream", "M"],
      NGP: ["снов", "dream", "M"],
      NDS: ["сну", "dream", "M"],
      NDP: ["снам", "dream", "M"],
      NAS: ["сон", "dream", "M"],
      NAP: ["сны", "dream", "M"],
      NIS: ["сном", "dream", "M"],
      NIP: ["снами", "dream", "M"],
      NPS: ["сне", "dream", "M"],
      NPP: ["снах", "dream", "M"],
    },
    смысл: {
      NNS: ["смысл", "sense, meaning, purpose", "M"],
      NNP: ["смыслы", "sense, meaning, purpose", "M"],
      NGS: ["смысла", "sense, meaning, purpose", "M"],
      NGP: ["смыслов", "sense, meaning, purpose", "M"],
      NDS: ["смыслу", "sense, meaning, purpose", "M"],
      NDP: ["смыслам", "sense, meaning, purpose", "M"],
      NAS: ["смысл", "sense, meaning, purpose", "M"],
      NAP: ["смыслы", "sense, meaning, purpose", "M"],
      NIS: ["смыслом", "sense, meaning, purpose", "M"],
      NIP: ["смыслами", "sense, meaning, purpose", "M"],
      NPS: ["смысле", "sense, meaning, purpose", "M"],
      NPP: ["смыслах", "sense, meaning, purpose", "M"],
    },
    двор: {
      NNS: ["двор", "yard, court", "M"],
      NNP: ["дворы", "yard, court", "M"],
      NGS: ["двора", "yard, court", "M"],
      NGP: ["дворов", "yard, court", "M"],
      NDS: ["двору", "yard, court", "M"],
      NDP: ["дворам", "yard, court", "M"],
      NAS: ["двор", "yard, court", "M"],
      NAP: ["дворы", "yard, court", "M"],
      NIS: ["двором", "yard, court", "M"],
      NIP: ["дворами", "yard, court", "M"],
      NPS: ["дворе", "yard, court", "M"],
      NPP: ["дворах", "yard, court", "M"],
    },
    форма: {
      NNS: ["форма", "form, shape, uniform", "F"],
      NNP: ["формы", "form, shape, uniform", "F"],
      NGS: ["формы", "form, shape, uniform", "F"],
      NGP: ["форм", "form, shape, uniform", "F"],
      NDS: ["форме", "form, shape, uniform", "F"],
      NDP: ["формам", "form, shape, uniform", "F"],
      NAS: ["форму", "form, shape, uniform", "F"],
      NAP: ["формы", "form, shape, uniform", "F"],
      NIS: ["формой", "form, shape, uniform", "F"],
      NIP: ["формами", "form, shape, uniform", "F"],
      NPS: ["форме", "form, shape, uniform", "F"],
      NPP: ["формах", "form, shape, uniform", "F"],
    },
    возможность: {
      NNS: ["возможность", "possibility, opportunity, chance", "F"],
      NNP: ["возможности", "possibility, opportunity, chance", "F"],
      NGS: ["возможности", "possibility, opportunity, chance", "F"],
      NGP: ["возможностей", "possibility, opportunity, chance", "F"],
      NDS: ["возможности", "possibility, opportunity, chance", "F"],
      NDP: ["возможностям", "possibility, opportunity, chance", "F"],
      NAS: ["возможность", "possibility, opportunity, chance", "F"],
      NAP: ["возможности", "possibility, opportunity, chance", "F"],
      NIS: ["возможностью", "possibility, opportunity, chance", "F"],
      NIP: ["возможностями", "possibility, opportunity, chance", "F"],
      NPS: ["возможности", "possibility, opportunity, chance", "F"],
      NPP: ["возможностях", "possibility, opportunity, chance", "F"],
    },
    общество: {
      NNS: ["общество", "society", "N"],
      NNP: ["общества", "society", "N"],
      NGS: ["общества", "society", "N"],
      NGP: ["обществ", "society", "N"],
      NDS: ["обществу", "society", "N"],
      NDP: ["обществам", "society", "N"],
      NAS: ["общество", "society", "N"],
      NAP: ["общества", "society", "N"],
      NIS: ["обществом", "society", "N"],
      NIP: ["обществами", "society", "N"],
      NPS: ["обществе", "society", "N"],
      NPP: ["обществах", "society", "N"],
    },
    грудь: {
      NNS: ["грудь", "breast, chest", "F"],
      NNP: ["груди", "breast, chest", "F"],
      NGS: ["груди", "breast, chest", "F"],
      NGP: ["грудей", "breast, chest", "F"],
      NDS: ["груди", "breast, chest", "F"],
      NDP: ["грудям", "breast, chest", "F"],
      NAS: ["грудь", "breast, chest", "F"],
      NAP: ["груди", "breast, chest", "F"],
      NIS: ["грудью", "breast, chest", "F"],
      NIP: ["грудями", "breast, chest", "F"],
      NPS: ["груди", "breast, chest", "F"],
      NPP: ["грудях", "breast, chest", "F"],
    },
    угол: {
      NNS: ["угол", "corner, angle", "M"],
      NNP: ["углы", "corner, angle", "M"],
      NGS: ["угла", "corner, angle", "M"],
      NGP: ["углов", "corner, angle", "M"],
      NDS: ["углу", "corner, angle", "M"],
      NDP: ["углам", "corner, angle", "M"],
      NAS: ["угол", "corner, angle", "M"],
      NAP: ["углы", "corner, angle", "M"],
      NIS: ["углом", "corner, angle", "M"],
      NIP: ["углами", "corner, angle", "M"],
      NPS: ["угле", "corner, angle", "M"],
      NPP: ["углах", "corner, angle", "M"],
    },
    век: {
      NNS: ["век", "century, age", "M"],
      NNP: ["века", "century, age", "M"],
      NGS: ["века", "century, age", "M"],
      NGP: ["веков", "century, age", "M"],
      NDS: ["веку", "century, age", "M"],
      NDP: ["векам", "century, age", "M"],
      NAS: ["век", "century, age", "M"],
      NAP: ["века", "century, age", "M"],
      NIS: ["веком", "century, age", "M"],
      NIP: ["веками", "century, age", "M"],
      NPS: ["веке", "century, age", "M"],
      NPP: ["веках", "century, age", "M"],
    },
    карман: {
      NNS: ["карман", "pocket", "M"],
      NNP: ["карманы", "pocket", "M"],
      NGS: ["кармана", "pocket", "M"],
      NGP: ["карманов", "pocket", "M"],
      NDS: ["карману", "pocket", "M"],
      NDP: ["карманам", "pocket", "M"],
      NAS: ["карман", "pocket", "M"],
      NAP: ["карманы", "pocket", "M"],
      NIS: ["карманом", "pocket", "M"],
      NIP: ["карманами", "pocket", "M"],
      NPS: ["кармане", "pocket", "M"],
      NPP: ["карманах", "pocket", "M"],
    },
    немец: {
      NNS: ["немец", "German", "M"],
      NNP: ["немцы", "German", "M"],
      NGS: ["немца", "German", "M"],
      NGP: ["немцев", "German", "M"],
      NDS: ["немцу", "German", "M"],
      NDP: ["немцам", "German", "M"],
      NAS: ["немца", "German", "M"],
      NAP: ["немцев", "German", "M"],
      NIS: ["немцем", "German", "M"],
      NIP: ["немцами", "German", "M"],
      NPS: ["немце", "German", "M"],
      NPP: ["немцах", "German", "M"],
    },
    губа: {
      NNS: ["губа", "lip", "F"],
      NNP: ["губы", "lip", "F"],
      NGS: ["губы", "lip", "F"],
      NGP: ["губ", "lip", "F"],
      NDS: ["губе", "lip", "F"],
      NDP: ["губам", "lip", "F"],
      NAS: ["губу", "lip", "F"],
      NAP: ["губы", "lip", "F"],
      NIS: ["губой", "lip", "F"],
      NIP: ["губами", "lip", "F"],
      NPS: ["губе", "lip", "F"],
      NPP: ["губах", "lip", "F"],
    },
    дядя: {
      NNS: ["дядя", "uncle", "M"],
      NNP: ["дяди", "uncle", "M"],
      NGS: ["дяди", "uncle", "M"],
      NGP: ["дядей", "uncle", "M"],
      NDS: ["дяде", "uncle", "M"],
      NDP: ["дядьям", "uncle", "M"],
      NAS: ["дядю", "uncle", "M"],
      NAP: ["дядей", "uncle", "M"],
      NIS: ["дядей", "uncle", "M"],
      NIP: ["дядьями", "uncle", "M"],
      NPS: ["дяде", "uncle", "M"],
      NPP: ["дядьях", "uncle", "M"],
    },
    огонь: {
      NNS: ["огонь", "fire", "M"],
      NNP: ["огни", "fire", "M"],
      NGS: ["огня", "fire", "M"],
      NGP: ["огней", "fire", "M"],
      NDS: ["огню", "fire", "M"],
      NDP: ["огням", "fire", "M"],
      NAS: ["огонь", "fire", "M"],
      NAP: ["огни", "fire", "M"],
      NIS: ["огнём", "fire", "M"],
      NIP: ["огнями", "fire", "M"],
      NPS: ["огне", "fire", "M"],
      NPP: ["огнях", "fire", "M"],
    },
    писатель: {
      NNS: ["писатель", "writer", "M"],
      NNP: ["писатели", "writer", "M"],
      NGS: ["писателя", "writer", "M"],
      NGP: ["писателей", "writer", "M"],
      NDS: ["писателю", "writer", "M"],
      NDP: ["писателям", "writer", "M"],
      NAS: ["писателя", "writer", "M"],
      NAP: ["писателей", "writer", "M"],
      NIS: ["писателем", "writer", "M"],
      NIP: ["писателями", "writer", "M"],
      NPS: ["писателе", "writer", "M"],
      NPP: ["писателях", "writer", "M"],
    },
    армия: {
      NNS: ["армия", "army", "F"],
      NNP: ["армии", "army", "F"],
      NGS: ["армии", "army", "F"],
      NGP: ["армий", "army", "F"],
      NDS: ["армии", "army", "F"],
      NDP: ["армиям", "army", "F"],
      NAS: ["армию", "army", "F"],
      NAP: ["армии", "army", "F"],
      NIS: ["армией", "army", "F"],
      NIP: ["армиями", "army", "F"],
      NPS: ["армии", "army", "F"],
      NPP: ["армиях", "army", "F"],
    },
    состояние: {
      NNS: ["состояние", "state, condition; fortune", "N"],
      NNP: ["состояния", "state, condition; fortune", "N"],
      NGS: ["состояния", "state, condition; fortune", "N"],
      NGP: ["состояний", "state, condition; fortune", "N"],
      NDS: ["состоянию", "state, condition; fortune", "N"],
      NDP: ["состояниям", "state, condition; fortune", "N"],
      NAS: ["состояние", "state, condition; fortune", "N"],
      NAP: ["состояния", "state, condition; fortune", "N"],
      NIS: ["состоянием", "state, condition; fortune", "N"],
      NIP: ["состояниями", "state, condition; fortune", "N"],
      NPS: ["состоянии", "state, condition; fortune", "N"],
      NPP: ["состояниях", "state, condition; fortune", "N"],
    },
    зуб: {
      NNS: ["зуб", "tooth", "M"],
      NNP: ["зубы", "tooth", "M"],
      NGS: ["зуба", "tooth", "M"],
      NGP: ["зубов", "tooth", "M"],
      NDS: ["зубу", "tooth", "M"],
      NDP: ["зубам", "tooth", "M"],
      NAS: ["зуб", "tooth", "M"],
      NAP: ["зубы", "tooth", "M"],
      NIS: ["зубом", "tooth", "M"],
      NIP: ["зубами", "tooth", "M"],
      NPS: ["зубе", "tooth", "M"],
      NPP: ["зубах", "tooth", "M"],
    },
    очередь: {
      NNS: ["очередь", "line, queue, turn", "F"],
      NNP: ["очереди", "line, queue, turn", "F"],
      NGS: ["очереди", "line, queue, turn", "F"],
      NGP: ["очередей", "line, queue, turn", "F"],
      NDS: ["очереди", "line, queue, turn", "F"],
      NDP: ["очередям", "line, queue, turn", "F"],
      NAS: ["очередь", "line, queue, turn", "F"],
      NAP: ["очереди", "line, queue, turn", "F"],
      NIS: ["очередью", "line, queue, turn", "F"],
      NIP: ["очередями", "line, queue, turn", "F"],
      NPS: ["очереди", "line, queue, turn", "F"],
      NPP: ["очередях", "line, queue, turn", "F"],
    },
    камень: {
      NNS: ["камень", "stone", "M"],
      NNP: ["камни", "stone", "M"],
      NGS: ["камня", "stone", "M"],
      NGP: ["камней", "stone", "M"],
      NDS: ["камню", "stone", "M"],
      NDP: ["камням", "stone", "M"],
      NAS: ["камень", "stone", "M"],
      NAP: ["камни", "stone", "M"],
      NIS: ["камнем", "stone", "M"],
      NIP: ["камнями", "stone", "M"],
      NPS: ["камне", "stone", "M"],
      NPP: ["камнях", "stone", "M"],
    },
    гость: {
      NNS: ["гость", "guest", "M"],
      NNP: ["гости", "guest", "M"],
      NGS: ["гостя", "guest", "M"],
      NGP: ["гостей", "guest", "M"],
      NDS: ["гостю", "guest", "M"],
      NDP: ["гостям", "guest", "M"],
      NAS: ["гостя", "guest", "M"],
      NAP: ["гостей", "guest", "M"],
      NIS: ["гостем", "guest", "M"],
      NIP: ["гостями", "guest", "M"],
      NPS: ["госте", "guest", "M"],
      NPP: ["гостях", "guest", "M"],
    },
    ветер: {
      NNS: ["ветер", "wind", "M"],
      NNP: ["", "wind", "M"],
      NGS: ["ветра", "wind", "M"],
      NGP: ["", "wind", "M"],
      NDS: ["ветру", "wind", "M"],
      NDP: ["", "wind", "M"],
      NAS: ["ветер", "wind", "M"],
      NAP: ["", "wind", "M"],
      NIS: ["ветром", "wind", "M"],
      NIP: ["", "wind", "M"],
      NPS: ["ветре", "wind", "M"],
      NPP: ["", "wind", "M"],
    },
    рота: {
      NNS: ["рота", "company (military)", "F"],
      NNP: ["роты", "company (military)", "F"],
      NGS: ["роты", "company (military)", "F"],
      NGP: ["рот", "company (military)", "F"],
      NDS: ["роте", "company (military)", "F"],
      NDP: ["ротам", "company (military)", "F"],
      NAS: ["роту", "company (military)", "F"],
      NAP: ["роты", "company (military)", "F"],
      NIS: ["ротой", "company (military)", "F"],
      NIP: ["ротами", "company (military)", "F"],
      NPS: ["роте", "company (military)", "F"],
      NPP: ["ротах", "company (military)", "F"],
    },
    закон: {
      NNS: ["закон", "law, act, statute", "M"],
      NNP: ["законы", "law, act, statute", "M"],
      NGS: ["закона", "law, act, statute", "M"],
      NGP: ["законов", "law, act, statute", "M"],
      NDS: ["закону", "law, act, statute", "M"],
      NDP: ["законам", "law, act, statute", "M"],
      NAS: ["закон", "law, act, statute", "M"],
      NAP: ["законы", "law, act, statute", "M"],
      NIS: ["законом", "law, act, statute", "M"],
      NIP: ["законами", "law, act, statute", "M"],
      NPS: ["законе", "law, act, statute", "M"],
      NPP: ["законах", "law, act, statute", "M"],
    },
    море: {
      NNS: ["море", "sea", "N"],
      NNP: ["моря", "sea", "N"],
      NGS: ["моря", "sea", "N"],
      NGP: ["морей", "sea", "N"],
      NDS: ["морю", "sea", "N"],
      NDP: ["морям", "sea", "N"],
      NAS: ["море", "sea", "N"],
      NAP: ["моря", "sea", "N"],
      NIS: ["морем", "sea", "N"],
      NIP: ["морями", "sea", "N"],
      NPS: ["море", "sea", "N"],
      NPP: ["морях", "sea", "N"],
    },
    гора: {
      NNS: ["гора", "mountain, hill", "F"],
      NNP: ["горы", "mountain, hill", "F"],
      NGS: ["горы", "mountain, hill", "F"],
      NGP: ["гор", "mountain, hill", "F"],
      NDS: ["горе", "mountain, hill", "F"],
      NDP: ["горам", "mountain, hill", "F"],
      NAS: ["гору", "mountain, hill", "F"],
      NAP: ["горы", "mountain, hill", "F"],
      NIS: ["горой", "mountain, hill", "F"],
      NIP: ["горами", "mountain, hill", "F"],
      NPS: ["горе", "mountain, hill", "F"],
      NPP: ["горах", "mountain, hill", "F"],
    },
    врач: {
      NNS: ["врач", "physician, doctor", "M"],
      NNP: ["врачи", "physician, doctor", "M"],
      NGS: ["врача", "physician, doctor", "M"],
      NGP: ["врачей", "physician, doctor", "M"],
      NDS: ["врачу", "physician, doctor", "M"],
      NDP: ["врачам", "physician, doctor", "M"],
      NAS: ["врача", "physician, doctor", "M"],
      NAP: ["врачей", "physician, doctor", "M"],
      NIS: ["врачом", "physician, doctor", "M"],
      NIP: ["врачами", "physician, doctor", "M"],
      NPS: ["враче", "physician, doctor", "M"],
      NPP: ["врачах", "physician, doctor", "M"],
    },
    край: {
      NNS: ["край", "border, edge; land, country", "M"],
      NNP: ["края", "border, edge; land, country", "M"],
      NGS: ["края", "border, edge; land, country", "M"],
      NGP: ["краёв", "border, edge; land, country", "M"],
      NDS: ["краю", "border, edge; land, country", "M"],
      NDP: ["краям", "border, edge; land, country", "M"],
      NAS: ["край", "border, edge; land, country", "M"],
      NAP: ["края", "border, edge; land, country", "M"],
      NIS: ["краем", "border, edge; land, country", "M"],
      NIP: ["краями", "border, edge; land, country", "M"],
      NPS: ["крае", "border, edge; land, country", "M"],
      NPP: ["краях", "border, edge; land, country", "M"],
    },
    река: {
      NNS: ["река", "river", "F"],
      NNP: ["реки", "river", "F"],
      NGS: ["реки", "river", "F"],
      NGP: ["рек", "river", "F"],
      NDS: ["реке", "river", "F"],
      NDP: ["рекам", "river", "F"],
      NAS: ["реку", "river", "F"],
      NAP: ["реки", "river", "F"],
      NIS: ["рекой", "river", "F"],
      NIP: ["реками", "river", "F"],
      NPS: ["реке", "river", "F"],
      NPP: ["реках", "river", "F"],
    },
    мера: {
      NNS: ["мера", "measure, step", "F"],
      NNP: ["меры", "measure, step", "F"],
      NGS: ["меры", "measure, step", "F"],
      NGP: ["мер", "measure, step", "F"],
      NDS: ["мере", "measure, step", "F"],
      NDP: ["мерам", "measure, step", "F"],
      NAS: ["меру", "measure, step", "F"],
      NAP: ["меры", "measure, step", "F"],
      NIS: ["мерой", "measure, step", "F"],
      NIP: ["мерами", "measure, step", "F"],
      NPS: ["мере", "measure, step", "F"],
      NPP: ["мерах", "measure, step", "F"],
    },
    действие: {
      NNS: ["действие", "action, effect", "N"],
      NNP: ["действия", "action, effect", "N"],
      NGS: ["действия", "action, effect", "N"],
      NGP: ["действий", "action, effect", "N"],
      NDS: ["действию", "action, effect", "N"],
      NDP: ["действиям", "action, effect", "N"],
      NAS: ["действие", "action, effect", "N"],
      NAP: ["действия", "action, effect", "N"],
      NIS: ["действием", "action, effect", "N"],
      NIP: ["действиями", "action, effect", "N"],
      NPS: ["действии", "action, effect", "N"],
      NPP: ["действиях", "action, effect", "N"],
    },
    вещь: {
      NNS: ["вещь", "thing", "F"],
      NNP: ["вещи", "thing", "F"],
      NGS: ["вещи", "thing", "F"],
      NGP: ["вещей", "thing", "F"],
      NDS: ["вещи", "thing", "F"],
      NDP: ["вещам", "thing", "F"],
      NAS: ["вещь", "thing", "F"],
      NAP: ["вещи", "thing", "F"],
      NIS: ["вещью", "thing", "F"],
      NIP: ["вещами", "thing", "F"],
      NPS: ["вещи", "thing", "F"],
      NPP: ["вещах", "thing", "F"],
    },
    ход: {
      NNS: ["ход", "move", "M"],
      NNP: ["ходы", "move", "M"],
      NGS: ["хода", "move", "M"],
      NGP: ["ходов", "move", "M"],
      NDS: ["ходу", "move", "M"],
      NDP: ["ходам", "move", "M"],
      NAS: ["ход", "move", "M"],
      NAP: ["хода", "move", "M"],
      NIS: ["ходом", "move", "M"],
      NIP: ["ходами", "move", "M"],
      NPS: ["ходе", "move", "M"],
      NPP: ["ходах", "move", "M"],
    },
    боль: {
      NNS: ["боль", "pain", "F"],
      NNP: ["боли", "pain", "F"],
      NGS: ["боли", "pain", "F"],
      NGP: ["болей", "pain", "F"],
      NDS: ["боли", "pain", "F"],
      NDP: ["болям", "pain", "F"],
      NAS: ["боль", "pain", "F"],
      NAP: ["боли", "pain", "F"],
      NIS: ["болью", "pain", "F"],
      NIP: ["болями", "pain", "F"],
      NPS: ["боли", "pain", "F"],
      NPP: ["болях", "pain", "F"],
    },
    судьба: {
      NNS: ["судьба", "fate, fortune, destiny", "F"],
      NNP: ["судьбы", "fate, fortune, destiny", "F"],
      NGS: ["судьбы", "fate, fortune, destiny", "F"],
      NGP: ["судеб", "fate, fortune, destiny", "F"],
      NDS: ["судьбе", "fate, fortune, destiny", "F"],
      NDP: ["судьбам", "fate, fortune, destiny", "F"],
      NAS: ["судьбу", "fate, fortune, destiny", "F"],
      NAP: ["судьбы", "fate, fortune, destiny", "F"],
      NIS: ["судьбой", "fate, fortune, destiny", "F"],
      NIP: ["судьбами", "fate, fortune, destiny", "F"],
      NPS: ["судьбе", "fate, fortune, destiny", "F"],
      NPP: ["судьбах", "fate, fortune, destiny", "F"],
    },
    причина: {
      NNS: ["причина", "cause, reason, motive", "F"],
      NNP: ["причины", "cause, reason, motive", "F"],
      NGS: ["причины", "cause, reason, motive", "F"],
      NGP: ["причин", "cause, reason, motive", "F"],
      NDS: ["причине", "cause, reason, motive", "F"],
      NDP: ["причинам", "cause, reason, motive", "F"],
      NAS: ["причину", "cause, reason, motive", "F"],
      NAP: ["причины", "cause, reason, motive", "F"],
      NIS: ["причиной", "cause, reason, motive", "F"],
      NIP: ["причинами", "cause, reason, motive", "F"],
      NPS: ["причине", "cause, reason, motive", "F"],
      NPP: ["причинах", "cause, reason, motive", "F"],
    },
    черта: {
      NNS: ["черта", "line, boundary; trait", "F"],
      NNP: ["черты", "line, boundary; trait", "F"],
      NGS: ["черты", "line, boundary; trait", "F"],
      NGP: ["черт", "line, boundary; trait", "F"],
      NDS: ["черте", "line, boundary; trait", "F"],
      NDP: ["чертам", "line, boundary; trait", "F"],
      NAS: ["черту", "line, boundary; trait", "F"],
      NAP: ["черты", "line, boundary; trait", "F"],
      NIS: ["чертой", "line, boundary; trait", "F"],
      NIP: ["чертами", "line, boundary; trait", "F"],
      NPS: ["черте", "line, boundary; trait", "F"],
      NPP: ["чертах", "line, boundary; trait", "F"],
    },
    девочка: {
      NNS: ["девочка", "girl, little girl", "F"],
      NNP: ["девочки", "girl, little girl", "F"],
      NGS: ["девочки", "girl, little girl", "F"],
      NGP: ["девочек", "girl, little girl", "F"],
      NDS: ["девочке", "girl, little girl", "F"],
      NDP: ["девочкам", "girl, little girl", "F"],
      NAS: ["девочку", "girl, little girl", "F"],
      NAP: ["девочек", "girl, little girl", "F"],
      NIS: ["девочкой", "girl, little girl", "F"],
      NIP: ["девочками", "girl, little girl", "F"],
      NPS: ["девочке", "girl, little girl", "F"],
      NPP: ["девочках", "girl, little girl", "F"],
    },
    волос: {
      NNS: ["волос", "hair", "M"],
      NNP: ["волосы", "hair", "M"],
      NGS: ["волоса", "hair", "M"],
      NGP: ["волос", "hair", "M"],
      NDS: ["волосу", "hair", "M"],
      NDP: ["волосам", "hair", "M"],
      NAS: ["волос", "hair", "M"],
      NAP: ["волосы", "hair", "M"],
      NIS: ["волосом", "hair", "M"],
      NIP: ["волосами", "hair", "M"],
      NPS: ["волосе", "hair", "M"],
      NPP: ["волосах", "hair", "M"],
    },
    номер: {
      NNS: ["номер", "number, size, room, issue", "M"],
      NNP: ["номера", "number, size, room, issue", "M"],
      NGS: ["номера", "number, size, room, issue", "M"],
      NGP: ["номеров", "number, size, room, issue", "M"],
      NDS: ["номеру", "number, size, room, issue", "M"],
      NDP: ["номерам", "number, size, room, issue", "M"],
      NAS: ["номер", "number, size, room, issue", "M"],
      NAP: ["номера", "number, size, room, issue", "M"],
      NIS: ["номером", "number, size, room, issue", "M"],
      NIP: ["номерами", "number, size, room, issue", "M"],
      NPS: ["номере", "number, size, room, issue", "M"],
      NPP: ["номерах", "number, size, room, issue", "M"],
    },
    глава: {
      NNS: ["глава", "head, chief", "F"],
      NNP: ["главы", "head, chief", "F"],
      NGS: ["главы", "head, chief", "F"],
      NGP: ["глав", "head, chief", "F"],
      NDS: ["главе", "head, chief", "F"],
      NDP: ["главам", "head, chief", "F"],
      NAS: ["главу", "head, chief", "F"],
      NAP: ["главы", "head, chief", "F"],
      NIS: ["главой", "head, chief", "F"],
      NIP: ["главами", "head, chief", "F"],
      NPS: ["главе", "head, chief", "F"],
      NPP: ["главах", "head, chief", "F"],
    },
    командир: {
      NNS: ["командир", "commander, commanding officer", "M"],
      NNP: ["командиры", "commander, commanding officer", "M"],
      NGS: ["командира", "commander, commanding officer", "M"],
      NGP: ["командиров", "commander, commanding officer", "M"],
      NDS: ["командиру", "commander, commanding officer", "M"],
      NDP: ["командирам", "commander, commanding officer", "M"],
      NAS: ["командира", "commander, commanding officer", "M"],
      NAP: ["командиров", "commander, commanding officer", "M"],
      NIS: ["командиром", "commander, commanding officer", "M"],
      NIP: ["командирами", "commander, commanding officer", "M"],
      NPS: ["командире", "commander, commanding officer", "M"],
      NPP: ["командирах", "commander, commanding officer", "M"],
    },
    партия: {
      NNS: ["партия", "party", "F"],
      NNP: ["партии", "party", "F"],
      NGS: ["партии", "party", "F"],
      NGP: ["партий", "party", "F"],
      NDS: ["партии", "party", "F"],
      NDP: ["партиям", "party", "F"],
      NAS: ["партию", "party", "F"],
      NAP: ["партии", "party", "F"],
      NIS: ["партией", "party", "F"],
      NIP: ["партиями", "party", "F"],
      NPS: ["партии", "party", "F"],
      NPP: ["партиях", "party", "F"],
    },
    проблема: {
      NNS: ["проблема", "problem, issue", "F"],
      NNP: ["проблемы", "problem, issue", "F"],
      NGS: ["проблемы", "problem, issue", "F"],
      NGP: ["проблем", "problem, issue", "F"],
      NDS: ["проблеме", "problem, issue", "F"],
      NDP: ["проблемам", "problem, issue", "F"],
      NAS: ["проблему", "problem, issue", "F"],
      NAP: ["проблемы", "problem, issue", "F"],
      NIS: ["проблемой", "problem, issue", "F"],
      NIP: ["проблемами", "problem, issue", "F"],
      NPS: ["проблеме", "problem, issue", "F"],
      NPP: ["проблемах", "problem, issue", "F"],
    },
    страх: {
      NNS: ["страх", "fear", "M"],
      NNP: ["страхи", "fear", "M"],
      NGS: ["страха", "fear", "M"],
      NGP: ["страхов", "fear", "M"],
      NDS: ["страху", "fear", "M"],
      NDP: ["страхам", "fear", "M"],
      NAS: ["страх", "fear", "M"],
      NAP: ["страхи", "fear", "M"],
      NIS: ["страхом", "fear", "M"],
      NIP: ["страхами", "fear", "M"],
      NPS: ["страхе", "fear", "M"],
      NPP: ["страхах", "fear", "M"],
    },
    бумага: {
      NNS: ["бумага", "paper", "F"],
      NNP: ["бумаги", "paper", "F"],
      NGS: ["бумаги", "paper", "F"],
      NGP: ["бумаг", "paper", "F"],
      NDS: ["бумаге", "paper", "F"],
      NDP: ["бумагам", "paper", "F"],
      NAS: ["бумагу", "paper", "F"],
      NAP: ["бумаги", "paper", "F"],
      NIS: ["бумагой", "paper", "F"],
      NIP: ["бумагами", "paper", "F"],
      NPS: ["бумаге", "paper", "F"],
      NPP: ["бумагах", "paper", "F"],
    },
    герой: {
      NNS: ["герой", "hero", "M"],
      NNP: ["герои", "hero", "M"],
      NGS: ["героя", "hero", "M"],
      NGP: ["героев", "hero", "M"],
      NDS: ["герою", "hero", "M"],
      NDP: ["героям", "hero", "M"],
      NAS: ["героя", "hero", "M"],
      NAP: ["героев", "hero", "M"],
      NIS: ["героем", "hero", "M"],
      NIP: ["героями", "hero", "M"],
      NPS: ["герое", "hero", "M"],
      NPP: ["героях", "hero", "M"],
    },
    пара: {
      NNS: ["пара", "pair, couple", "F"],
      NNP: ["пары", "pair, couple", "F"],
      NGS: ["пары", "pair, couple", "F"],
      NGP: ["пар", "pair, couple", "F"],
      NDS: ["паре", "pair, couple", "F"],
      NDP: ["парам", "pair, couple", "F"],
      NAS: ["пару", "pair, couple", "F"],
      NAP: ["пары", "pair, couple", "F"],
      NIS: ["парой", "pair, couple", "F"],
      NIP: ["парами", "pair, couple", "F"],
      NPS: ["паре", "pair, couple", "F"],
      NPP: ["парах", "pair, couple", "F"],
    },
    государство: {
      NNS: ["государство", "State", "N"],
      NNP: ["государства", "State", "N"],
      NGS: ["государства", "State", "N"],
      NGP: ["государств", "State", "N"],
      NDS: ["государству", "State", "N"],
      NDP: ["государствам", "State", "N"],
      NAS: ["государство", "State", "N"],
      NAP: ["государства", "State", "N"],
      NIS: ["государством", "State", "N"],
      NIP: ["государствами", "State", "N"],
      NPS: ["государстве", "State", "N"],
      NPP: ["государствах", "State", "N"],
    },
    деревня: {
      NNS: ["деревня", "village, country", "F"],
      NNP: ["деревни", "village, country", "F"],
      NGS: ["деревни", "village, country", "F"],
      NGP: ["деревень", "village, country", "F"],
      NDS: ["деревне", "village, country", "F"],
      NDP: ["деревням", "village, country", "F"],
      NAS: ["деревню", "village, country", "F"],
      NAP: ["деревни", "village, country", "F"],
      NIS: ["деревней", "village, country", "F"],
      NIP: ["деревнями", "village, country", "F"],
      NPS: ["деревне", "village, country", "F"],
      NPP: ["деревнях", "village, country", "F"],
    },
    речь: {
      NNS: ["речь", "speech", "F"],
      NNP: ["речи", "speech", "F"],
      NGS: ["речи", "speech", "F"],
      NGP: ["речей", "speech", "F"],
      NDS: ["речи", "speech", "F"],
      NDP: ["речам", "speech", "F"],
      NAS: ["речь", "speech", "F"],
      NAP: ["речи", "speech", "F"],
      NIS: ["речью", "speech", "F"],
      NIP: ["речами", "speech", "F"],
      NPS: ["речи", "speech", "F"],
      NPP: ["речах", "speech", "F"],
    },
    средство: {
      NNS: ["средство", "means, remedy", "N"],
      NNP: ["средства", "means, remedy", "N"],
      NGS: ["средства", "means, remedy", "N"],
      NGP: ["средств", "means, remedy", "N"],
      NDS: ["средству", "means, remedy", "N"],
      NDP: ["средствам", "means, remedy", "N"],
      NAS: ["средство", "means, remedy", "N"],
      NAP: ["средства", "means, remedy", "N"],
      NIS: ["средством", "means, remedy", "N"],
      NIP: ["средствами", "means, remedy", "N"],
      NPS: ["средстве", "means, remedy", "N"],
      NPP: ["средствах", "means, remedy", "N"],
    },
    положение: {
      NNS: ["положение", "position, posture, condition, state", "N"],
      NNP: ["положения", "position, posture, condition, state", "N"],
      NGS: ["положения", "position, posture, condition, state", "N"],
      NGP: ["положений", "position, posture, condition, state", "N"],
      NDS: ["положению", "position, posture, condition, state", "N"],
      NDP: ["положениям", "position, posture, condition, state", "N"],
      NAS: ["положение", "position, posture, condition, state", "N"],
      NAP: ["положения", "position, posture, condition, state", "N"],
      NIS: ["положением", "position, posture, condition, state", "N"],
      NIP: ["положениями", "position, posture, condition, state", "N"],
      NPS: ["положении", "position, posture, condition, state", "N"],
      NPP: ["положениях", "position, posture, condition, state", "N"],
    },
    связь: {
      NNS: ["связь", "tie, bond; connection, relation", "F"],
      NNP: ["связи", "tie, bond; connection, relation", "F"],
      NGS: ["связи", "tie, bond; connection, relation", "F"],
      NGP: ["связей", "tie, bond; connection, relation", "F"],
      NDS: ["связи", "tie, bond; connection, relation", "F"],
      NDP: ["связям", "tie, bond; connection, relation", "F"],
      NAS: ["связь", "tie, bond; connection, relation", "F"],
      NAP: ["связи", "tie, bond; connection, relation", "F"],
      NIS: ["связью", "tie, bond; connection, relation", "F"],
      NIP: ["связями", "tie, bond; connection, relation", "F"],
      NPS: ["связи", "tie, bond; connection, relation", "F"],
      NPP: ["связях", "tie, bond; connection, relation", "F"],
    },
    класс: {
      NNS: ["класс", "class", "M"],
      NNP: ["классы", "class", "M"],
      NGS: ["класса", "class", "M"],
      NGP: ["классов", "class", "M"],
      NDS: ["классу", "class", "M"],
      NDP: ["классам", "class", "M"],
      NAS: ["класс", "class", "M"],
      NAP: ["классы", "class", "M"],
      NIS: ["классом", "class", "M"],
      NIP: ["классами", "class", "M"],
      NPS: ["классе", "class", "M"],
      NPP: ["классах", "class", "M"],
    },
    цель: {
      NNS: ["цель", "goal, object, target", "F"],
      NNP: ["цели", "goal, object, target", "F"],
      NGS: ["цели", "goal, object, target", "F"],
      NGP: ["целей", "goal, object, target", "F"],
      NDS: ["цели", "goal, object, target", "F"],
      NDP: ["целям", "goal, object, target", "F"],
      NAS: ["цель", "goal, object, target", "F"],
      NAP: ["цели", "goal, object, target", "F"],
      NIS: ["целью", "goal, object, target", "F"],
      NIP: ["целями", "goal, object, target", "F"],
      NPS: ["цели", "goal, object, target", "F"],
      NPP: ["целях", "goal, object, target", "F"],
    },
    знать: {
      NNS: ["знать", "nobility", "F"],
      NNP: ["", "nobility", "F"],
      NGS: ["знати", "nobility", "F"],
      NGP: ["", "nobility", "F"],
      NDS: ["знати", "nobility", "F"],
      NDP: ["", "nobility", "F"],
      NAS: ["знать", "nobility", "F"],
      NAP: ["", "nobility", "F"],
      NIS: ["знатью", "nobility", "F"],
      NIP: ["", "nobility", "F"],
      NPS: ["знати", "nobility", "F"],
      NPP: ["", "nobility", "F"],
    },
    профессор: {
      NNS: ["профессор", "professor", "M"],
      NNP: ["профессора", "professor", "M"],
      NGS: ["профессора", "professor", "M"],
      NGP: ["профессоров", "professor", "M"],
      NDS: ["профессору", "professor", "M"],
      NDP: ["профессорам", "professor", "M"],
      NAS: ["профессора", "professor", "M"],
      NAP: ["профессоров", "professor", "M"],
      NIS: ["профессором", "professor", "M"],
      NIP: ["профессорами", "professor", "M"],
      NPS: ["профессоре", "professor", "M"],
      NPP: ["профессорах", "professor", "M"],
    },
    господин: {
      NNS: ["господин", "gentleman, Mr.", "M"],
      NNP: ["господа", "gentleman, Mr.", "M"],
      NGS: ["господина", "gentleman, Mr.", "M"],
      NGP: ["господ", "gentleman, Mr.", "M"],
      NDS: ["господину", "gentleman, Mr.", "M"],
      NDP: ["господам", "gentleman, Mr.", "M"],
      NAS: ["господина", "gentleman, Mr.", "M"],
      NAP: ["господ", "gentleman, Mr.", "M"],
      NIS: ["господином", "gentleman, Mr.", "M"],
      NIP: ["господами", "gentleman, Mr.", "M"],
      NPS: ["господине", "gentleman, Mr.", "M"],
      NPP: ["господах", "gentleman, Mr.", "M"],
    },
    счастье: {
      NNS: ["счастье", "happiness, luck", "N"],
      NNP: ["счастья", "happiness, luck", "N"],
      NGS: ["счастья", "happiness, luck", "N"],
      NGP: ["счастий", "happiness, luck", "N"],
      NDS: ["счастью", "happiness, luck", "N"],
      NDP: ["счастьям", "happiness, luck", "N"],
      NAS: ["счастье", "happiness, luck", "N"],
      NAP: ["счастья", "happiness, luck", "N"],
      NIS: ["счастьем", "happiness, luck", "N"],
      NIP: ["счастьями", "happiness, luck", "N"],
      NPS: ["счастье", "happiness, luck", "N"],
      NPP: ["счастьях", "happiness, luck", "N"],
    },
    дух: {
      NNS: ["дух", "spirit", "M"],
      NNP: ["духи", "spirit", "M"],
      NGS: ["духа", "spirit", "M"],
      NGP: ["духов", "spirit", "M"],
      NDS: ["духу", "spirit", "M"],
      NDP: ["духам", "spirit", "M"],
      NAS: ["дух", "spirit", "M"],
      NAP: ["духи", "spirit", "M"],
      NIS: ["духом", "spirit", "M"],
      NIP: ["духами", "spirit", "M"],
      NPS: ["духе", "spirit", "M"],
      NPP: ["духах", "spirit", "M"],
    },
    план: {
      NNS: ["план", "plan", "M"],
      NNP: ["планы", "plan", "M"],
      NGS: ["плана", "plan", "M"],
      NGP: ["планов", "plan", "M"],
      NDS: ["плану", "plan", "M"],
      NDP: ["планам", "plan", "M"],
      NAS: ["план", "plan", "M"],
      NAP: ["планы", "plan", "M"],
      NIS: ["планом", "plan", "M"],
      NIP: ["планами", "plan", "M"],
      NPS: ["плане", "plan", "M"],
      NPP: ["планах", "plan", "M"],
    },
    зал: {
      NNS: ["зал", "hall", "M"],
      NNP: ["залы", "hall", "M"],
      NGS: ["зала", "hall", "M"],
      NGP: ["залов", "hall", "M"],
      NDS: ["залу", "hall", "M"],
      NDP: ["залам", "hall", "M"],
      NAS: ["зал", "hall", "M"],
      NAP: ["залы", "hall", "M"],
      NIS: ["залом", "hall", "M"],
      NIP: ["залами", "hall", "M"],
      NPS: ["зале", "hall", "M"],
      NPP: ["залах", "hall", "M"],
    },
    директор: {
      NNS: ["директор", "director, manager", "M"],
      NNP: ["директора", "director, manager", "M"],
      NGS: ["директора", "director, manager", "M"],
      NGP: ["директоров", "director, manager", "M"],
      NDS: ["директору", "director, manager", "M"],
      NDP: ["директорам", "director, manager", "M"],
      NAS: ["директора", "director, manager", "M"],
      NAP: ["директоров", "director, manager", "M"],
      NIS: ["директором", "director, manager", "M"],
      NIP: ["директорами", "director, manager", "M"],
      NPS: ["директоре", "director, manager", "M"],
      NPP: ["директорах", "director, manager", "M"],
    },
    память: {
      NNS: ["память", "memory", "F"],
      NNP: ["памяти", "memory", "F"],
      NGS: ["памяти", "memory", "F"],
      NGP: ["памятей", "memory", "F"],
      NDS: ["памяти", "memory", "F"],
      NDP: ["памятям", "memory", "F"],
      NAS: ["память", "memory", "F"],
      NAP: ["памяти", "memory", "F"],
      NIS: ["памятью", "memory", "F"],
      NIP: ["памятями", "memory", "F"],
      NPS: ["памяти", "memory", "F"],
      NPP: ["памятях", "memory", "F"],
    },
    результат: {
      NNS: ["результат", "result, outcome", "M"],
      NNP: ["результаты", "result, outcome", "M"],
      NGS: ["результата", "result, outcome", "M"],
      NGP: ["результатов", "result, outcome", "M"],
      NDS: ["результату", "result, outcome", "M"],
      NDP: ["результатам", "result, outcome", "M"],
      NAS: ["результат", "result, outcome", "M"],
      NAP: ["результаты", "result, outcome", "M"],
      NIS: ["результатом", "result, outcome", "M"],
      NIP: ["результатами", "result, outcome", "M"],
      NPS: ["результате", "result, outcome", "M"],
      NPP: ["результатах", "result, outcome", "M"],
    },
    след: {
      NNS: ["след", "track, footprint", "M"],
      NNP: ["следы", "track, footprint", "M"],
      NGS: ["следа", "track, footprint", "M"],
      NGP: ["следов", "track, footprint", "M"],
      NDS: ["следу", "track, footprint", "M"],
      NDP: ["следам", "track, footprint", "M"],
      NAS: ["след", "track, footprint", "M"],
      NAP: ["следы", "track, footprint", "M"],
      NIS: ["следом", "track, footprint", "M"],
      NIP: ["следами", "track, footprint", "M"],
      NPS: ["следе", "track, footprint", "M"],
      NPP: ["следах", "track, footprint", "M"],
    },
    бутылка: {
      NNS: ["бутылка", "bottle", "F"],
      NNP: ["бутылки", "bottle", "F"],
      NGS: ["бутылки", "bottle", "F"],
      NGP: ["бутылок", "bottle", "F"],
      NDS: ["бутылке", "bottle", "F"],
      NDP: ["бутылкам", "bottle", "F"],
      NAS: ["бутылку", "bottle", "F"],
      NAP: ["бутылки", "bottle", "F"],
      NIS: ["бутылкой", "bottle", "F"],
      NIP: ["бутылками", "bottle", "F"],
      NPS: ["бутылке", "bottle", "F"],
      NPP: ["бутылках", "bottle", "F"],
    },
    условие: {
      NNS: ["условие", "condition, term", "N"],
      NNP: ["условия", "condition, term", "N"],
      NGS: ["условия", "condition, term", "N"],
      NGP: ["условий", "condition, term", "N"],
      NDS: ["условию", "condition, term", "N"],
      NDP: ["условиям", "condition, term", "N"],
      NAS: ["условие", "condition, term", "N"],
      NAP: ["условия", "condition, term", "N"],
      NIS: ["условием", "condition, term", "N"],
      NIP: ["условиями", "condition, term", "N"],
      NPS: ["условии", "condition, term", "N"],
      NPP: ["условиях", "condition, term", "N"],
    },
    ум: {
      NNS: ["ум", "mind, brains, intellect", "M"],
      NNP: ["умы", "mind, brains, intellect", "M"],
      NGS: ["ума", "mind, brains, intellect", "M"],
      NGP: ["умов", "mind, brains, intellect", "M"],
      NDS: ["уму", "mind, brains, intellect", "M"],
      NDP: ["умам", "mind, brains, intellect", "M"],
      NAS: ["ум", "mind, brains, intellect", "M"],
      NAP: ["умы", "mind, brains, intellect", "M"],
      NIS: ["умом", "mind, brains, intellect", "M"],
      NIP: ["умами", "mind, brains, intellect", "M"],
      NPS: ["уме", "mind, brains, intellect", "M"],
      NPP: ["умах", "mind, brains, intellect", "M"],
    },
    процесс: {
      NNS: ["процесс", "process", "M"],
      NNP: ["процессы", "process", "M"],
      NGS: ["процесса", "process", "M"],
      NGP: ["процессов", "process", "M"],
      NDS: ["процессу", "process", "M"],
      NDP: ["процессам", "process", "M"],
      NAS: ["процесс", "process", "M"],
      NAP: ["процессы", "process", "M"],
      NIS: ["процессом", "process", "M"],
      NIP: ["процессами", "process", "M"],
      NPS: ["процессе", "process", "M"],
      NPP: ["процессах", "process", "M"],
    },
    картина: {
      NNS: ["картина", "picture, painting", "F"],
      NNP: ["картины", "picture, painting", "F"],
      NGS: ["картины", "picture, painting", "F"],
      NGP: ["картин", "picture, painting", "F"],
      NDS: ["картине", "picture, painting", "F"],
      NDP: ["картинам", "picture, painting", "F"],
      NAS: ["картину", "picture, painting", "F"],
      NAP: ["картины", "picture, painting", "F"],
      NIS: ["картиной", "picture, painting", "F"],
      NIP: ["картинами", "picture, painting", "F"],
      NPS: ["картине", "picture, painting", "F"],
      NPP: ["картинах", "picture, painting", "F"],
    },
    центр: {
      NNS: ["центр", "center", "M"],
      NNP: ["центры", "center", "M"],
      NGS: ["центра", "center", "M"],
      NGP: ["центров", "center", "M"],
      NDS: ["центру", "center", "M"],
      NDP: ["центрам", "center", "M"],
      NAS: ["центр", "center", "M"],
      NAP: ["центры", "center", "M"],
      NIS: ["центром", "center", "M"],
      NIP: ["центрами", "center", "M"],
      NPS: ["центре", "center", "M"],
      NPP: ["центрах", "center", "M"],
    },
    будущее: {
      NNS: ["будущее", "future", "N"],
      NNP: ["", "future", "N"],
      NGS: ["будущего", "future", "N"],
      NGP: ["", "future", "N"],
      NDS: ["будущему", "future", "N"],
      NDP: ["", "future", "N"],
      NAS: ["будущее", "future", "N"],
      NAP: ["", "future", "N"],
      NIS: ["будущим", "future", "N"],
      NIP: ["", "future", "N"],
      NPS: ["будущем", "future", "N"],
      NPP: ["", "future", "N"],
    },
    число: {
      NNS: ["число", "number", "N"],
      NNP: ["числа", "number", "N"],
      NGS: ["числа", "number", "N"],
      NGP: ["чисел", "number", "N"],
      NDS: ["числу", "number", "N"],
      NDP: ["числам", "number", "N"],
      NAS: ["число", "number", "N"],
      NAP: ["числа", "number", "N"],
      NIS: ["числом", "number", "N"],
      NIP: ["числами", "number", "N"],
      NPS: ["числе", "number", "N"],
      NPP: ["числах", "number", "N"],
    },
    рубль: {
      NNS: ["рубль", "ruble", "M"],
      NNP: ["рубли", "ruble", "M"],
      NGS: ["рубля", "ruble", "M"],
      NGP: ["рублей", "ruble", "M"],
      NDS: ["рублю", "ruble", "M"],
      NDP: ["рублям", "ruble", "M"],
      NAS: ["рубль", "ruble", "M"],
      NAP: ["рубли", "ruble", "M"],
      NIS: ["рублём", "ruble", "M"],
      NIP: ["рублями", "ruble", "M"],
      NPS: ["рубле", "ruble", "M"],
      NPP: ["рублях", "ruble", "M"],
    },
    вера: {
      NNS: ["вера", "faith, belief", "F"],
      NNP: ["веры", "faith, belief", "F"],
      NGS: ["веры", "faith, belief", "F"],
      NGP: ["вер", "faith, belief", "F"],
      NDS: ["вере", "faith, belief", "F"],
      NDP: ["верам", "faith, belief", "F"],
      NAS: ["веру", "faith, belief", "F"],
      NAP: ["веры", "faith, belief", "F"],
      NIS: ["верой", "faith, belief", "F"],
      NIP: ["верами", "faith, belief", "F"],
      NPS: ["вере", "faith, belief", "F"],
      NPP: ["верах", "faith, belief", "F"],
    },
    удар: {
      NNS: ["удар", "blow, stroke", "M"],
      NNP: ["удары", "blow, stroke", "M"],
      NGS: ["удара", "blow, stroke", "M"],
      NGP: ["ударов", "blow, stroke", "M"],
      NDS: ["удару", "blow, stroke", "M"],
      NDP: ["ударам", "blow, stroke", "M"],
      NAS: ["удар", "blow, stroke", "M"],
      NAP: ["удары", "blow, stroke", "M"],
      NIS: ["ударом", "blow, stroke", "M"],
      NIP: ["ударами", "blow, stroke", "M"],
      NPS: ["ударе", "blow, stroke", "M"],
      NPP: ["ударах", "blow, stroke", "M"],
    },
    телефон: {
      NNS: ["телефон", "telephone", "M"],
      NNP: ["телефоны", "telephone", "M"],
      NGS: ["телефона", "telephone", "M"],
      NGP: ["телефонов", "telephone", "M"],
      NDS: ["телефону", "telephone", "M"],
      NDP: ["телефонам", "telephone", "M"],
      NAS: ["телефон", "telephone", "M"],
      NAP: ["телефоны", "telephone", "M"],
      NIS: ["телефоном", "telephone", "M"],
      NIP: ["телефонами", "telephone", "M"],
      NPS: ["телефоне", "telephone", "M"],
      NPP: ["телефонах", "telephone", "M"],
    },
    колено: {
      NNS: ["колено", "knee", "N"],
      NNP: ["колени", "knee", "N"],
      NGS: ["колена", "knee", "N"],
      NGP: ["коленей", "knee", "N"],
      NDS: ["колену", "knee", "N"],
      NDP: ["коленям", "knee", "N"],
      NAS: ["колено", "knee", "N"],
      NAP: ["колени", "knee", "N"],
      NIS: ["коленом", "knee", "N"],
      NIP: ["коленями", "knee", "N"],
      NPS: ["колене", "knee", "N"],
      NPP: ["коленях", "knee", "N"],
    },
    коридор: {
      NNS: ["коридор", "corridor, passage", "M"],
      NNP: ["коридоры", "corridor, passage", "M"],
      NGS: ["коридора", "corridor, passage", "M"],
      NGP: ["коридоров", "corridor, passage", "M"],
      NDS: ["коридору", "corridor, passage", "M"],
      NDP: ["коридорам", "corridor, passage", "M"],
      NAS: ["коридор", "corridor, passage", "M"],
      NAP: ["коридоры", "corridor, passage", "M"],
      NIS: ["коридором", "corridor, passage", "M"],
      NIP: ["коридорами", "corridor, passage", "M"],
      NPS: ["коридоре", "corridor, passage", "M"],
      NPP: ["коридорах", "corridor, passage", "M"],
    },
    мужик: {
      NNS: ["мужик", "muzhik, man", "M"],
      NNP: ["мужики", "muzhik, man", "M"],
      NGS: ["мужика", "muzhik, man", "M"],
      NGP: ["мужиков", "muzhik, man", "M"],
      NDS: ["мужику", "muzhik, man", "M"],
      NDP: ["мужикам", "muzhik, man", "M"],
      NAS: ["мужика", "muzhik, man", "M"],
      NAP: ["мужиков", "muzhik, man", "M"],
      NIS: ["мужиком", "muzhik, man", "M"],
      NIP: ["мужиками", "muzhik, man", "M"],
      NPS: ["мужике", "muzhik, man", "M"],
      NPP: ["мужиках", "muzhik, man", "M"],
    },
    автор: {
      NNS: ["автор", "author", "M"],
      NNP: ["авторы", "author", "M"],
      NGS: ["автора", "author", "M"],
      NGP: ["авторов", "author", "M"],
      NDS: ["автору", "author", "M"],
      NDP: ["авторам", "author", "M"],
      NAS: ["автора", "author", "M"],
      NAP: ["авторов", "author", "M"],
      NIS: ["автором", "author", "M"],
      NIP: ["авторами", "author", "M"],
      NPS: ["авторе", "author", "M"],
      NPP: ["авторах", "author", "M"],
    },
    встреча: {
      NNS: ["встреча", "meeting, reception", "F"],
      NNP: ["встречи", "meeting, reception", "F"],
      NGS: ["встречи", "meeting, reception", "F"],
      NGP: ["встреч", "meeting, reception", "F"],
      NDS: ["встрече", "meeting, reception", "F"],
      NDP: ["встречам", "meeting, reception", "F"],
      NAS: ["встречу", "meeting, reception", "F"],
      NAP: ["встречи", "meeting, reception", "F"],
      NIS: ["встречей", "meeting, reception", "F"],
      NIP: ["встречами", "meeting, reception", "F"],
      NPS: ["встрече", "meeting, reception", "F"],
      NPP: ["встречах", "meeting, reception", "F"],
    },
    кабинет: {
      NNS: ["кабинет", "study, room, office suite", "M"],
      NNP: ["кабинеты", "study, room, office suite", "M"],
      NGS: ["кабинета", "study, room, office suite", "M"],
      NGP: ["кабинетов", "study, room, office suite", "M"],
      NDS: ["кабинету", "study, room, office suite", "M"],
      NDP: ["кабинетам", "study, room, office suite", "M"],
      NAS: ["кабинет", "study, room, office suite", "M"],
      NAP: ["кабинеты", "study, room, office suite", "M"],
      NIS: ["кабинетом", "study, room, office suite", "M"],
      NIP: ["кабинетами", "study, room, office suite", "M"],
      NPS: ["кабинете", "study, room, office suite", "M"],
      NPP: ["кабинетах", "study, room, office suite", "M"],
    },
    документ: {
      NNS: ["документ", "document", "M"],
      NNP: ["документы", "document", "M"],
      NGS: ["документа", "document", "M"],
      NGP: ["документов", "document", "M"],
      NDS: ["документу", "document", "M"],
      NDP: ["документам", "document", "M"],
      NAS: ["документ", "document", "M"],
      NAP: ["документы", "document", "M"],
      NIS: ["документом", "document", "M"],
      NIP: ["документами", "document", "M"],
      NPS: ["документе", "document", "M"],
      NPP: ["документах", "document", "M"],
    },
    самолёт: {
      NNS: ["самолёт", "aircraft, aeroplane", "M"],
      NNP: ["самолёты", "aircraft, aeroplane", "M"],
      NGS: ["самолёта", "aircraft, aeroplane", "M"],
      NGP: ["самолётов", "aircraft, aeroplane", "M"],
      NDS: ["самолёту", "aircraft, aeroplane", "M"],
      NDP: ["самолётам", "aircraft, aeroplane", "M"],
      NAS: ["самолёт", "aircraft, aeroplane", "M"],
      NAP: ["самолёты", "aircraft, aeroplane", "M"],
      NIS: ["самолётом", "aircraft, aeroplane", "M"],
      NIP: ["самолётами", "aircraft, aeroplane", "M"],
      NPS: ["самолёте", "aircraft, aeroplane", "M"],
      NPP: ["самолётах", "aircraft, aeroplane", "M"],
    },
    игра: {
      NNS: ["игра", "game, play", "F"],
      NNP: ["игры", "game, play", "F"],
      NGS: ["игры", "game, play", "F"],
      NGP: ["игр", "game, play", "F"],
      NDS: ["игре", "game, play", "F"],
      NDP: ["играм", "game, play", "F"],
      NAS: ["игру", "game, play", "F"],
      NAP: ["игры", "game, play", "F"],
      NIS: ["игрой", "game, play", "F"],
      NIP: ["играми", "game, play", "F"],
      NPS: ["игре", "game, play", "F"],
      NPP: ["играх", "game, play", "F"],
    },
    рассказ: {
      NNS: ["рассказ", "story", "M"],
      NNP: ["рассказы", "story", "M"],
      NGS: ["рассказа", "story", "M"],
      NGP: ["рассказов", "story", "M"],
      NDS: ["рассказу", "story", "M"],
      NDP: ["рассказам", "story", "M"],
      NAS: ["рассказ", "story", "M"],
      NAP: ["рассказы", "story", "M"],
      NIS: ["рассказом", "story", "M"],
      NIP: ["рассказами", "story", "M"],
      NPS: ["рассказе", "story", "M"],
      NPP: ["рассказах", "story", "M"],
    },
    хлеб: {
      NNS: ["хлеб", "bread", "M"],
      NNP: ["хлебы", "bread", "M"],
      NGS: ["хлеба", "bread", "M"],
      NGP: ["хлебов", "bread", "M"],
      NDS: ["хлебу", "bread", "M"],
      NDP: ["хлебам", "bread", "M"],
      NAS: ["хлеб", "bread", "M"],
      NAP: ["хлебы", "bread", "M"],
      NIS: ["хлебом", "bread", "M"],
      NIP: ["хлебами", "bread", "M"],
      NPS: ["хлебе", "bread", "M"],
      NPP: ["хлебах", "bread", "M"],
    },
    развитие: {
      NNS: ["развитие", "development", "N"],
      NNP: ["развития", "development", "N"],
      NGS: ["развития", "development", "N"],
      NGP: ["развитий", "development", "N"],
      NDS: ["развитию", "development", "N"],
      NDP: ["развитиям", "development", "N"],
      NAS: ["развитие", "development", "N"],
      NAP: ["развития", "development", "N"],
      NIS: ["развитием", "development", "N"],
      NIP: ["развитиями", "development", "N"],
      NPS: ["развитии", "development", "N"],
      NPP: ["развитиях", "development", "N"],
    },
    трубка: {
      NNS: ["трубка", "tube, roll, pipe", "F"],
      NNP: ["трубки", "tube, roll, pipe", "F"],
      NGS: ["трубки", "tube, roll, pipe", "F"],
      NGP: ["трубок", "tube, roll, pipe", "F"],
      NDS: ["трубке", "tube, roll, pipe", "F"],
      NDP: ["трубкам", "tube, roll, pipe", "F"],
      NAS: ["трубку", "tube, roll, pipe", "F"],
      NAP: ["трубки", "tube, roll, pipe", "F"],
      NIS: ["трубкой", "tube, roll, pipe", "F"],
      NIP: ["трубками", "tube, roll, pipe", "F"],
      NPS: ["трубке", "tube, roll, pipe", "F"],
      NPP: ["трубках", "tube, roll, pipe", "F"],
    },
    враг: {
      NNS: ["враг", "enemy", "M"],
      NNP: ["враги", "enemy", "M"],
      NGS: ["врага", "enemy", "M"],
      NGP: ["врагов", "enemy", "M"],
      NDS: ["врагу", "enemy", "M"],
      NDP: ["врагам", "enemy", "M"],
      NAS: ["врага", "enemy", "M"],
      NAP: ["врагов", "enemy", "M"],
      NIS: ["врагом", "enemy", "M"],
      NIP: ["врагами", "enemy", "M"],
      NPS: ["враге", "enemy", "M"],
      NPP: ["врагах", "enemy", "M"],
    },
    доктор: {
      NNS: ["доктор", "doctor", "M"],
      NNP: ["доктора", "doctor", "M"],
      NGS: ["доктора", "doctor", "M"],
      NGP: ["докторов", "doctor", "M"],
      NDS: ["доктору", "doctor", "M"],
      NDP: ["докторам", "doctor", "M"],
      NAS: ["доктора", "doctor", "M"],
      NAP: ["докторов", "doctor", "M"],
      NIS: ["доктором", "doctor", "M"],
      NIP: ["докторами", "doctor", "M"],
      NPS: ["докторе", "doctor", "M"],
      NPP: ["докторах", "doctor", "M"],
    },
    ладонь: {
      NNS: ["ладонь", "palm", "F"],
      NNP: ["ладони", "palm", "F"],
      NGS: ["ладони", "palm", "F"],
      NGP: ["ладоней", "palm", "F"],
      NDS: ["ладони", "palm", "F"],
      NDP: ["ладоням", "palm", "F"],
      NAS: ["ладонь", "palm", "F"],
      NAP: ["ладони", "palm", "F"],
      NIS: ["ладонью", "palm", "F"],
      NIP: ["ладонями", "palm", "F"],
      NPS: ["ладони", "palm", "F"],
      NPP: ["ладонях", "palm", "F"],
    },
    наука: {
      NNS: ["наука", "science", "F"],
      NNP: ["науки", "science", "F"],
      NGS: ["науки", "science", "F"],
      NGP: ["наук", "science", "F"],
      NDS: ["науке", "science", "F"],
      NDP: ["наукам", "science", "F"],
      NAS: ["науку", "science", "F"],
      NAP: ["науки", "science", "F"],
      NIS: ["наукой", "science", "F"],
      NIP: ["науками", "science", "F"],
      NPS: ["науке", "science", "F"],
      NPP: ["науках", "science", "F"],
    },
    лейтенант: {
      NNS: ["лейтенант", "lieutenant", "M"],
      NNP: ["лейтенанты", "lieutenant", "M"],
      NGS: ["лейтенанта", "lieutenant", "M"],
      NGP: ["лейтенантов", "lieutenant", "M"],
      NDS: ["лейтенанту", "lieutenant", "M"],
      NDP: ["лейтенантам", "lieutenant", "M"],
      NAS: ["лейтенанта", "lieutenant", "M"],
      NAP: ["лейтенантов", "lieutenant", "M"],
      NIS: ["лейтенантом", "lieutenant", "M"],
      NIP: ["лейтенантами", "lieutenant", "M"],
      NPS: ["лейтенанте", "lieutenant", "M"],
      NPP: ["лейтенантах", "lieutenant", "M"],
    },
    служба: {
      NNS: ["служба", "service, work", "F"],
      NNP: ["службы", "service, work", "F"],
      NGS: ["службы", "service, work", "F"],
      NGP: ["служб", "service, work", "F"],
      NDS: ["службе", "service, work", "F"],
      NDP: ["службам", "service, work", "F"],
      NAS: ["службу", "service, work", "F"],
      NAP: ["службы", "service, work", "F"],
      NIS: ["службой", "service, work", "F"],
      NIP: ["службами", "service, work", "F"],
      NPS: ["службе", "service, work", "F"],
      NPP: ["службах", "service, work", "F"],
    },
    счёт: {
      NNS: ["счёт", "bill, account", "M"],
      NNP: ["счета", "bill, account", "M"],
      NGS: ["счёта", "bill, account", "M"],
      NGP: ["счетов", "bill, account", "M"],
      NDS: ["счёту", "bill, account", "M"],
      NDP: ["счетам", "bill, account", "M"],
      NAS: ["счёт", "bill, account", "M"],
      NAP: ["счета", "bill, account", "M"],
      NIS: ["счётом", "bill, account", "M"],
      NIP: ["счетами", "bill, account", "M"],
      NPS: ["счёте", "bill, account", "M"],
      NPP: ["счетах", "bill, account", "M"],
    },
    кухня: {
      NNS: ["кухня", "kitchen", "F"],
      NNP: ["кухни", "kitchen", "F"],
      NGS: ["кухни", "kitchen", "F"],
      NGP: ["кухонь", "kitchen", "F"],
      NDS: ["кухне", "kitchen", "F"],
      NDP: ["кухням", "kitchen", "F"],
      NAS: ["кухню", "kitchen", "F"],
      NAP: ["кухни", "kitchen", "F"],
      NIS: ["кухней", "kitchen", "F"],
      NIP: ["кухнями", "kitchen", "F"],
      NPS: ["кухне", "kitchen", "F"],
      NPP: ["кухнях", "kitchen", "F"],
    },
    решение: {
      NNS: ["решение", "decision, conclusion", "N"],
      NNP: ["решения", "decision, conclusion", "N"],
      NGS: ["решения", "decision, conclusion", "N"],
      NGP: ["решений", "decision, conclusion", "N"],
      NDS: ["решению", "decision, conclusion", "N"],
      NDP: ["решениям", "decision, conclusion", "N"],
      NAS: ["решение", "decision, conclusion", "N"],
      NAP: ["решения", "decision, conclusion", "N"],
      NIS: ["решением", "decision, conclusion", "N"],
      NIP: ["решениями", "decision, conclusion", "N"],
      NPS: ["решении", "decision, conclusion", "N"],
      NPP: ["решениях", "decision, conclusion", "N"],
    },
    роман: {
      NNS: ["роман", "novel, romance", "M"],
      NNP: ["романы", "novel, romance", "M"],
      NGS: ["романа", "novel, romance", "M"],
      NGP: ["романов", "novel, romance", "M"],
      NDS: ["роману", "novel, romance", "M"],
      NDP: ["романам", "novel, romance", "M"],
      NAS: ["роман", "novel, romance", "M"],
      NAP: ["романы", "novel, romance", "M"],
      NIS: ["романом", "novel, romance", "M"],
      NIP: ["романами", "novel, romance", "M"],
      NPS: ["романе", "novel, romance", "M"],
      NPP: ["романах", "novel, romance", "M"],
    },
    компания: {
      NNS: ["компания", "company", "F"],
      NNP: ["компании", "company", "F"],
      NGS: ["компании", "company", "F"],
      NGP: ["компаний", "company", "F"],
      NDS: ["компании", "company", "F"],
      NDP: ["компаниям", "company", "F"],
      NAS: ["компанию", "company", "F"],
      NAP: ["компании", "company", "F"],
      NIS: ["компанией", "company", "F"],
      NIP: ["компаниями", "company", "F"],
      NPS: ["компании", "company", "F"],
      NPP: ["компаниях", "company", "F"],
    },
    течение: {
      NNS: ["течение", "current", "N"],
      NNP: ["течения", "current", "N"],
      NGS: ["течения", "current", "N"],
      NGP: ["течений", "current", "N"],
      NDS: ["течению", "current", "N"],
      NDP: ["течениям", "current", "N"],
      NAS: ["течение", "current", "N"],
      NAP: ["течения", "current", "N"],
      NIS: ["течением", "current", "N"],
      NIP: ["течениями", "current", "N"],
      NPS: ["течении", "current", "N"],
      NPP: ["течениях", "current", "N"],
    },
    метр: {
      NNS: ["метр", "meter, metre", "M"],
      NNP: ["метры", "meter, metre", "M"],
      NGS: ["метра", "meter, metre", "M"],
      NGP: ["метров", "meter, metre", "M"],
      NDS: ["метру", "meter, metre", "M"],
      NDP: ["метрам", "meter, metre", "M"],
      NAS: ["метр", "meter, metre", "M"],
      NAP: ["метры", "meter, metre", "M"],
      NIS: ["метром", "meter, metre", "M"],
      NIP: ["метрами", "meter, metre", "M"],
      NPS: ["метре", "meter, metre", "M"],
      NPP: ["метрах", "meter, metre", "M"],
    },
    институт: {
      NNS: ["институт", "institute", "M"],
      NNP: ["институты", "institute", "M"],
      NGS: ["института", "institute", "M"],
      NGP: ["институтов", "institute", "M"],
      NDS: ["институту", "institute", "M"],
      NDP: ["институтам", "institute", "M"],
      NAS: ["институт", "institute", "M"],
      NAP: ["институты", "institute", "M"],
      NIS: ["институтом", "institute", "M"],
      NIP: ["институтами", "institute", "M"],
      NPS: ["институте", "institute", "M"],
      NPP: ["институтах", "institute", "M"],
    },
    интерес: {
      NNS: ["интерес", "interes", "M"],
      NNP: ["интересы", "interes", "M"],
      NGS: ["интереса", "interes", "M"],
      NGP: ["интересов", "interes", "M"],
      NDS: ["интересу", "interes", "M"],
      NDP: ["интересам", "interes", "M"],
      NAS: ["интерес", "interes", "M"],
      NAP: ["интересы", "interes", "M"],
      NIS: ["интересом", "interes", "M"],
      NIP: ["интересами", "interes", "M"],
      NPS: ["интересе", "interes", "M"],
      NPP: ["интересах", "interes", "M"],
    },
    половина: {
      NNS: ["половина", "half", "F"],
      NNP: ["половины", "half", "F"],
      NGS: ["половины", "half", "F"],
      NGP: ["половин", "half", "F"],
      NDS: ["половине", "half", "F"],
      NDP: ["половинам", "half", "F"],
      NAS: ["половину", "half", "F"],
      NAP: ["половины", "half", "F"],
      NIS: ["половиной", "half", "F"],
      NIP: ["половинами", "half", "F"],
      NPS: ["половине", "half", "F"],
      NPP: ["половинах", "half", "F"],
    },
    качество: {
      NNS: ["качество", "quality", "N"],
      NNP: ["качества", "quality", "N"],
      NGS: ["качества", "quality", "N"],
      NGP: ["качеств", "quality", "N"],
      NDS: ["качеству", "quality", "N"],
      NDP: ["качествам", "quality", "N"],
      NAS: ["качество", "quality", "N"],
      NAP: ["качества", "quality", "N"],
      NIS: ["качеством", "quality", "N"],
      NIP: ["качествами", "quality", "N"],
      NPS: ["качестве", "quality", "N"],
      NPP: ["качествах", "quality", "N"],
    },
    бой: {
      NNS: ["бой", "battle, fight, combat", "M"],
      NNP: ["бои", "battle, fight, combat", "M"],
      NGS: ["боя", "battle, fight, combat", "M"],
      NGP: ["боёв", "battle, fight, combat", "M"],
      NDS: ["бою", "battle, fight, combat", "M"],
      NDP: ["боям", "battle, fight, combat", "M"],
      NAS: ["бой", "battle, fight, combat", "M"],
      NAP: ["бои", "battle, fight, combat", "M"],
      NIS: ["боем", "battle, fight, combat", "M"],
      NIP: ["боями", "battle, fight, combat", "M"],
      NPS: ["бое", "battle, fight, combat", "M"],
      NPP: ["боях", "battle, fight, combat", "M"],
    },
    шея: {
      NNS: ["шея", "neck", "F"],
      NNP: ["шеи", "neck", "F"],
      NGS: ["шеи", "neck", "F"],
      NGP: ["шей", "neck", "F"],
      NDS: ["шее", "neck", "F"],
      NDP: ["шеям", "neck", "F"],
      NAS: ["шею", "neck", "F"],
      NAP: ["шеи", "neck", "F"],
      NIS: ["шеей", "neck", "F"],
      NIP: ["шеями", "neck", "F"],
      NPS: ["шее", "neck", "F"],
      NPP: ["шеях", "neck", "F"],
    },
    идея: {
      NNS: ["идея", "idea", "F"],
      NNP: ["идеи", "idea", "F"],
      NGS: ["идеи", "idea", "F"],
      NGP: ["идей", "idea", "F"],
      NDS: ["идее", "idea", "F"],
      NDP: ["идеям", "idea", "F"],
      NAS: ["идею", "idea", "F"],
      NAP: ["идеи", "idea", "F"],
      NIS: ["идеей", "idea", "F"],
      NIP: ["идеями", "idea", "F"],
      NPS: ["идее", "idea", "F"],
      NPP: ["идеях", "idea", "F"],
    },
    трава: {
      NNS: ["трава", "grass", "F"],
      NNP: ["травы", "grass", "F"],
      NGS: ["травы", "grass", "F"],
      NGP: ["трав", "grass", "F"],
      NDS: ["траве", "grass", "F"],
      NDP: ["травам", "grass", "F"],
      NAS: ["траву", "grass", "F"],
      NAP: ["травы", "grass", "F"],
      NIS: ["травой", "grass", "F"],
      NIP: ["травами", "grass", "F"],
      NPS: ["траве", "grass", "F"],
      NPP: ["травах", "grass", "F"],
    },
    дед: {
      NNS: ["дед", "grandfather, old man", "N"],
      NNP: ["деды", "grandfather, old man", "N"],
      NGS: ["деда", "grandfather, old man", "N"],
      NGP: ["дедов", "grandfather, old man", "N"],
      NDS: ["деду", "grandfather, old man", "N"],
      NDP: ["дедам", "grandfather, old man", "N"],
      NAS: ["деда", "grandfather, old man", "N"],
      NAP: ["дедов", "grandfather, old man", "N"],
      NIS: ["дедом", "grandfather, old man", "N"],
      NIP: ["дедами", "grandfather, old man", "N"],
      NPS: ["деде", "grandfather, old man", "N"],
      NPP: ["дедах", "grandfather, old man", "N"],
    },
    сознание: {
      NNS: ["сознание", "consciousness", "N"],
      NNP: ["сознания", "consciousness", "N"],
      NGS: ["сознания", "consciousness", "N"],
      NGP: ["сознаний", "consciousness", "N"],
      NDS: ["сознанию", "consciousness", "N"],
      NDP: ["сознаниям", "consciousness", "N"],
      NAS: ["сознание", "consciousness", "N"],
      NAP: ["сознания", "consciousness", "N"],
      NIS: ["сознанием", "consciousness", "N"],
      NIP: ["сознаниями", "consciousness", "N"],
      NPS: ["сознании", "consciousness", "N"],
      NPP: ["сознаниях", "consciousness", "N"],
    },
    родитель: {
      NNS: ["родитель", "parent; masterrussian, dot, com", "M"],
      NNP: ["родители", "parent; masterrussian, dot, com", "M"],
      NGS: ["родителя", "parent; masterrussian, dot, com", "M"],
      NGP: ["родителей", "parent; masterrussian, dot, com", "M"],
      NDS: ["родителю", "parent; masterrussian, dot, com", "M"],
      NDP: ["родителям", "parent; masterrussian, dot, com", "M"],
      NAS: ["родителя", "parent; masterrussian, dot, com", "M"],
      NAP: ["родителей", "parent; masterrussian, dot, com", "M"],
      NIS: ["родителем", "parent; masterrussian, dot, com", "M"],
      NIP: ["родителями", "parent; masterrussian, dot, com", "M"],
      NPS: ["родителе", "parent; masterrussian, dot, com", "M"],
      NPP: ["родителях", "parent; masterrussian, dot, com", "M"],
    },
    чай: {
      NNS: ["чай", "tea", "M"],
      NNP: ["чаи", "tea", "M"],
      NGS: ["чая", "tea", "M"],
      NGP: ["чаёв", "tea", "M"],
      NDS: ["чаю", "tea", "M"],
      NDP: ["чаям", "tea", "M"],
      NAS: ["чай", "tea", "M"],
      NAP: ["чаи", "tea", "M"],
      NIS: ["чаем", "tea", "M"],
      NIP: ["чаями", "tea", "M"],
      NPS: ["чае", "tea", "M"],
      NPP: ["чаях", "tea", "M"],
    },
    род: {
      NNS: ["род", "family, clan, generation", "M"],
      NNP: ["роды", "family, clan, generation", "M"],
      NGS: ["рода", "family, clan, generation", "M"],
      NGP: ["родов", "family, clan, generation", "M"],
      NDS: ["роду", "family, clan, generation", "M"],
      NDP: ["родам", "family, clan, generation", "M"],
      NAS: ["род", "family, clan, generation", "M"],
      NAP: ["роды", "family, clan, generation", "M"],
      NIS: ["родом", "family, clan, generation", "M"],
      NIP: ["родами", "family, clan, generation", "M"],
      NPS: ["роде", "family, clan, generation", "M"],
      NPP: ["родах", "family, clan, generation", "M"],
    },
    звук: {
      NNS: ["звук", "sound", "M"],
      NNP: ["звуки", "sound", "M"],
      NGS: ["звука", "sound", "M"],
      NGP: ["звуков", "sound", "M"],
      NDS: ["звуку", "sound", "M"],
      NDP: ["звукам", "sound", "M"],
      NAS: ["звук", "sound", "M"],
      NAP: ["звуки", "sound", "M"],
      NIS: ["звуком", "sound", "M"],
      NIP: ["звуками", "sound", "M"],
      NPS: ["звуке", "sound", "M"],
      NPP: ["звуках", "sound", "M"],
    },
    магазин: {
      NNS: ["магазин", "shop, store", "M"],
      NNP: ["магазины", "shop, store", "M"],
      NGS: ["магазина", "shop, store", "M"],
      NGP: ["магазинов", "shop, store", "M"],
      NDS: ["магазину", "shop, store", "M"],
      NDP: ["магазинам", "shop, store", "M"],
      NAS: ["магазин", "shop, store", "M"],
      NAP: ["магазины", "shop, store", "M"],
      NIS: ["магазином", "shop, store", "M"],
      NIP: ["магазинами", "shop, store", "M"],
      NPS: ["магазине", "shop, store", "M"],
      NPP: ["магазинах", "shop, store", "M"],
    },
    президент: {
      NNS: ["президент", "president; director", "M"],
      NNP: ["президенты", "president; director", "M"],
      NGS: ["президента", "president; director", "M"],
      NGP: ["президентов", "president; director", "M"],
      NDS: ["президенту", "president; director", "M"],
      NDP: ["президентам", "president; director", "M"],
      NAS: ["президента", "president; director", "M"],
      NAP: ["президентов", "president; director", "M"],
      NIS: ["президентом", "president; director", "M"],
      NIP: ["президентами", "president; director", "M"],
      NPS: ["президенте", "president; director", "M"],
      NPP: ["президентах", "president; director", "M"],
    },
    поэт: {
      NNS: ["поэт", "poet", "M"],
      NNP: ["поэты", "poet", "M"],
      NGS: ["поэта", "poet", "M"],
      NGP: ["поэтов", "poet", "M"],
      NDS: ["поэту", "poet", "M"],
      NDP: ["поэтам", "poet", "M"],
      NAS: ["поэта", "poet", "M"],
      NAP: ["поэтов", "poet", "M"],
      NIS: ["поэтом", "poet", "M"],
      NIP: ["поэтами", "poet", "M"],
      NPS: ["поэте", "poet", "M"],
      NPP: ["поэтах", "poet", "M"],
    },
    болезнь: {
      NNS: ["болезнь", "illness, sickness, disease", "F"],
      NNP: ["болезни", "illness, sickness, disease", "F"],
      NGS: ["болезни", "illness, sickness, disease", "F"],
      NGP: ["болезней", "illness, sickness, disease", "F"],
      NDS: ["болезни", "illness, sickness, disease", "F"],
      NDP: ["болезням", "illness, sickness, disease", "F"],
      NAS: ["болезнь", "illness, sickness, disease", "F"],
      NAP: ["болезни", "illness, sickness, disease", "F"],
      NIS: ["болезнью", "illness, sickness, disease", "F"],
      NIP: ["болезнями", "illness, sickness, disease", "F"],
      NPS: ["болезни", "illness, sickness, disease", "F"],
      NPP: ["болезнях", "illness, sickness, disease", "F"],
    },
    событие: {
      NNS: ["событие", "event", "N"],
      NNP: ["события", "event", "N"],
      NGS: ["события", "event", "N"],
      NGP: ["событий", "event", "N"],
      NDS: ["событию", "event", "N"],
      NDP: ["событиям", "event", "N"],
      NAS: ["событие", "event", "N"],
      NAP: ["события", "event", "N"],
      NIS: ["событием", "event", "N"],
      NIP: ["событиями", "event", "N"],
      NPS: ["событии", "event", "N"],
      NPP: ["событиях", "event", "N"],
    },
    кожа: {
      NNS: ["кожа", "skin", "F"],
      NNP: ["кожи", "skin", "F"],
      NGS: ["кожи", "skin", "F"],
      NGP: ["кож", "skin", "F"],
      NDS: ["коже", "skin", "F"],
      NDP: ["кожам", "skin", "F"],
      NAS: ["кожу", "skin", "F"],
      NAP: ["кожи", "skin", "F"],
      NIS: ["кожей", "skin", "F"],
      NIP: ["кожами", "skin", "F"],
      NPS: ["коже", "skin", "F"],
      NPP: ["кожах", "skin", "F"],
    },
    лист: {
      NNS: ["лист", "sheet", "M"],
      NNP: ["листы", "sheet", "M"],
      NGS: ["листа", "sheet", "M"],
      NGP: ["листов", "sheet", "M"],
      NDS: ["листу", "sheet", "M"],
      NDP: ["листам", "sheet", "M"],
      NAS: ["лист", "sheet", "M"],
      NAP: ["листы", "sheet", "M"],
      NIS: ["листом", "sheet", "M"],
      NIP: ["листами", "sheet", "M"],
      NPS: ["листе", "sheet", "M"],
      NPP: ["листах", "sheet", "M"],
    },
    слеза: {
      NNS: ["слеза", "tear", "F"],
      NNP: ["слёзы", "tear", "F"],
      NGS: ["слезы", "tear", "F"],
      NGP: ["слёз", "tear", "F"],
      NDS: ["слезе", "tear", "F"],
      NDP: ["слезам", "tear", "F"],
      NAS: ["слезу", "tear", "F"],
      NAP: ["слёзы", "tear", "F"],
      NIS: ["слезой", "tear", "F"],
      NIP: ["слезами", "tear", "F"],
      NPS: ["слезе", "tear", "F"],
      NPP: ["слезах", "tear", "F"],
    },
    надежда: {
      NNS: ["надежда", "hope", "F"],
      NNP: ["надежды", "hope", "F"],
      NGS: ["надежды", "hope", "F"],
      NGP: ["надежд", "hope", "F"],
      NDS: ["надежде", "hope", "F"],
      NDP: ["надеждам", "hope", "F"],
      NAS: ["надежду", "hope", "F"],
      NAP: ["надежды", "hope", "F"],
      NIS: ["надеждой", "hope", "F"],
      NIP: ["надеждами", "hope", "F"],
      NPS: ["надежде", "hope", "F"],
      NPP: ["надеждах", "hope", "F"],
    },
    литература: {
      NNS: ["литература", "literature", "F"],
      NNP: ["литературы", "literature", "F"],
      NGS: ["литературы", "literature", "F"],
      NGP: ["литератур", "literature", "F"],
      NDS: ["литературе", "literature", "F"],
      NDP: ["литературам", "literature", "F"],
      NAS: ["литературу", "literature", "F"],
      NAP: ["литературы", "literature", "F"],
      NIS: ["литературой", "literature", "F"],
      NIP: ["литературами", "literature", "F"],
      NPS: ["литературе", "literature", "F"],
      NPP: ["литературах", "literature", "F"],
    },
    оружие: {
      NNS: ["оружие", "weapon", "N"],
      NNP: ["оружия", "weapon", "N"],
      NGS: ["оружия", "weapon", "N"],
      NGP: ["оружий", "weapon", "N"],
      NDS: ["оружию", "weapon", "N"],
      NDP: ["оружиям", "weapon", "N"],
      NAS: ["оружие", "weapon", "N"],
      NAP: ["оружия", "weapon", "N"],
      NIS: ["оружием", "weapon", "N"],
      NIP: ["оружиями", "weapon", "N"],
      NPS: ["оружии", "weapon", "N"],
      NPP: ["оружиях", "weapon", "N"],
    },
    запах: {
      NNS: ["запах", "smell, odour, scent", "M"],
      NNP: ["запахи", "smell, odour, scent", "M"],
      NGS: ["запаха", "smell, odour, scent", "M"],
      NGP: ["запахов", "smell, odour, scent", "M"],
      NDS: ["запаху", "smell, odour, scent", "M"],
      NDP: ["запахам", "smell, odour, scent", "M"],
      NAS: ["запах", "smell, odour, scent", "M"],
      NAP: ["запахи", "smell, odour, scent", "M"],
      NIS: ["запахом", "smell, odour, scent", "M"],
      NIP: ["запахами", "smell, odour, scent", "M"],
      NPS: ["запахе", "smell, odour, scent", "M"],
      NPP: ["запахах", "smell, odour, scent", "M"],
    },
    роль: {
      NNS: ["роль", "role", "F"],
      NNP: ["роли", "role", "F"],
      NGS: ["роли", "role", "F"],
      NGP: ["ролей", "role", "F"],
      NDS: ["роли", "role", "F"],
      NDP: ["ролям", "role", "F"],
      NAS: ["роль", "role", "F"],
      NAP: ["роли", "role", "F"],
      NIS: ["ролью", "role", "F"],
      NIP: ["ролями", "role", "F"],
      NPS: ["роли", "role", "F"],
      NPP: ["ролях", "role", "F"],
    },
    рост: {
      NNS: ["рост", "growth, increase; height", "M"],
      NNP: ["роста", "growth, increase; height", "M"],
      NGS: ["роста", "growth, increase; height", "M"],
      NGP: ["ростов", "growth, increase; height", "M"],
      NDS: ["росту", "growth, increase; height", "M"],
      NDP: ["ростам", "growth, increase; height", "M"],
      NAS: ["рост", "growth, increase; height", "M"],
      NAP: ["роста", "growth, increase; height", "M"],
      NIS: ["ростом", "growth, increase; height", "M"],
      NIP: ["ростами", "growth, increase; height", "M"],
      NPS: ["росте", "growth, increase; height", "M"],
      NPP: ["ростах", "growth, increase; height", "M"],
    },
    природа: {
      NNS: ["природа", "nature", "F"],
      NNP: ["природы", "nature", "F"],
      NGS: ["природы", "nature", "F"],
      NGP: ["природ", "nature", "F"],
      NDS: ["природе", "nature", "F"],
      NDP: ["природам", "nature", "F"],
      NAS: ["природу", "nature", "F"],
      NAP: ["природы", "nature", "F"],
      NIS: ["природой", "nature", "F"],
      NIP: ["природами", "nature", "F"],
      NPS: ["природе", "nature", "F"],
      NPP: ["природах", "nature", "F"],
    },
    точка: {
      NNS: ["точка", "dot, point", "F"],
      NNP: ["точки", "dot, point", "F"],
      NGS: ["точки", "dot, point", "F"],
      NGP: ["точек", "dot, point", "F"],
      NDS: ["точке", "dot, point", "F"],
      NDP: ["точкам", "dot, point", "F"],
      NAS: ["точку", "dot, point", "F"],
      NAP: ["точки", "dot, point", "F"],
      NIS: ["точкой", "dot, point", "F"],
      NIP: ["точками", "dot, point", "F"],
      NPS: ["точке", "dot, point", "F"],
      NPP: ["точках", "dot, point", "F"],
    },
    звезда: {
      NNS: ["звезда", "star", "F"],
      NNP: ["звёзды", "star", "F"],
      NGS: ["звезды", "star", "F"],
      NGP: ["звёзд", "star", "F"],
      NDS: ["звезде", "star", "F"],
      NDP: ["звёздам", "star", "F"],
      NAS: ["звезду", "star", "F"],
      NAP: ["звёзды", "star", "F"],
      NIS: ["звездой", "star", "F"],
      NIP: ["звёздами", "star", "F"],
      NPS: ["звезде", "star", "F"],
      NPP: ["звёздах", "star", "F"],
    },
    фамилия: {
      NNS: ["фамилия", "surname", "F"],
      NNP: ["фамилии", "surname", "F"],
      NGS: ["фамилии", "surname", "F"],
      NGP: ["фамилий", "surname", "F"],
      NDS: ["фамилии", "surname", "F"],
      NDP: ["фамилиям", "surname", "F"],
      NAS: ["фамилию", "surname", "F"],
      NAP: ["фамилии", "surname", "F"],
      NIS: ["фамилией", "surname", "F"],
      NIP: ["фамилиями", "surname", "F"],
      NPS: ["фамилии", "surname", "F"],
      NPP: ["фамилиях", "surname", "F"],
    },
    характер: {
      NNS: ["характер", "character, disposition, temper", "M"],
      NNP: ["характеры", "character, disposition, temper", "M"],
      NGS: ["характера", "character, disposition, temper", "M"],
      NGP: ["характеров", "character, disposition, temper", "M"],
      NDS: ["характеру", "character, disposition, temper", "M"],
      NDP: ["характерам", "character, disposition, temper", "M"],
      NAS: ["характер", "character, disposition, temper", "M"],
      NAP: ["характеры", "character, disposition, temper", "M"],
      NIS: ["характером", "character, disposition, temper", "M"],
      NIP: ["характерами", "character, disposition, temper", "M"],
      NPS: ["характере", "character, disposition, temper", "M"],
      NPP: ["характерах", "character, disposition, temper", "M"],
    },
    офицер: {
      NNS: ["офицер", "officer", "M"],
      NNP: ["офицеры", "officer", "M"],
      NGS: ["офицера", "officer", "M"],
      NGP: ["офицеров", "officer", "M"],
      NDS: ["офицеру", "officer", "M"],
      NDP: ["офицерам", "officer", "M"],
      NAS: ["офицера", "officer", "M"],
      NAP: ["офицеров", "officer", "M"],
      NIS: ["офицером", "officer", "M"],
      NIP: ["офицерами", "officer", "M"],
      NPS: ["офицере", "officer", "M"],
      NPP: ["офицерах", "officer", "M"],
    },
    толпа: {
      NNS: ["толпа", "crowd", "F"],
      NNP: ["толпы", "crowd", "F"],
      NGS: ["толпы", "crowd", "F"],
      NGP: ["толп", "crowd", "F"],
      NDS: ["толпе", "crowd", "F"],
      NDP: ["толпам", "crowd", "F"],
      NAS: ["толпу", "crowd", "F"],
      NAP: ["толпы", "crowd", "F"],
      NIS: ["толпой", "crowd", "F"],
      NIP: ["толпами", "crowd", "F"],
      NPS: ["толпе", "crowd", "F"],
      NPP: ["толпах", "crowd", "F"],
    },
    уровень: {
      NNS: ["уровень", "level", "M"],
      NNP: ["уровни", "level", "M"],
      NGS: ["уровня", "level", "M"],
      NGP: ["уровней", "level", "M"],
      NDS: ["уровню", "level", "M"],
      NDP: ["уровням", "level", "M"],
      NAS: ["уровень", "level", "M"],
      NAP: ["уровни", "level", "M"],
      NIS: ["уровнем", "level", "M"],
      NIP: ["уровнями", "level", "M"],
      NPS: ["уровне", "level", "M"],
      NPP: ["уровнях", "level", "M"],
    },
    кресло: {
      NNS: ["кресло", "armchair", "N"],
      NNP: ["кресла", "armchair", "N"],
      NGS: ["кресла", "armchair", "N"],
      NGP: ["кресел", "armchair", "N"],
      NDS: ["креслу", "armchair", "N"],
      NDP: ["креслам", "armchair", "N"],
      NAS: ["кресло", "armchair", "N"],
      NAP: ["кресла", "armchair", "N"],
      NIS: ["креслом", "armchair", "N"],
      NIP: ["креслами", "armchair", "N"],
      NPS: ["кресле", "armchair", "N"],
      NPP: ["креслах", "armchair", "N"],
    },
    баба: {
      NNS: ["баба", "woman, wife, old woman", "F"],
      NNP: ["бабы", "woman, wife, old woman", "F"],
      NGS: ["бабы", "woman, wife, old woman", "F"],
      NGP: ["баб", "woman, wife, old woman", "F"],
      NDS: ["бабе", "woman, wife, old woman", "F"],
      NDP: ["бабам", "woman, wife, old woman", "F"],
      NAS: ["бабу", "woman, wife, old woman", "F"],
      NAP: ["баб", "woman, wife, old woman", "F"],
      NIS: ["бабой", "woman, wife, old woman", "F"],
      NIP: ["бабами", "woman, wife, old woman", "F"],
      NPS: ["бабе", "woman, wife, old woman", "F"],
      NPP: ["бабах", "woman, wife, old woman", "F"],
    },
    секунда: {
      NNS: ["секунда", "a second", "F"],
      NNP: ["секунды", "a second", "F"],
      NGS: ["секунды", "a second", "F"],
      NGP: ["секунд", "a second", "F"],
      NDS: ["секунде", "a second", "F"],
      NDP: ["секундам", "a second", "F"],
      NAS: ["секунду", "a second", "F"],
      NAP: ["секунды", "a second", "F"],
      NIS: ["секундой", "a second", "F"],
      NIP: ["секундами", "a second", "F"],
      NPS: ["секунде", "a second", "F"],
      NPP: ["секундах", "a second", "F"],
    },
    банк: {
      NNS: ["банк", "bank", "M"],
      NNP: ["банки", "bank", "M"],
      NGS: ["банка", "bank", "M"],
      NGP: ["банков", "bank", "M"],
      NDS: ["банку", "bank", "M"],
      NDP: ["банкам", "bank", "M"],
      NAS: ["банк", "bank", "M"],
      NAP: ["банки", "bank", "M"],
      NIS: ["банком", "bank", "M"],
      NIP: ["банками", "bank", "M"],
      NPS: ["банке", "bank", "M"],
      NPP: ["банках", "bank", "M"],
    },
    опыт: {
      NNS: ["опыт", "experience", "M"],
      NNP: ["опыты", "experience", "M"],
      NGS: ["опыта", "experience", "M"],
      NGP: ["опытов", "experience", "M"],
      NDS: ["опыту", "experience", "M"],
      NDP: ["опытам", "experience", "M"],
      NAS: ["опыт", "experience", "M"],
      NAP: ["опыты", "experience", "M"],
      NIS: ["опытом", "experience", "M"],
      NIP: ["опытами", "experience", "M"],
      NPS: ["опыте", "experience", "M"],
      NPP: ["опытах", "experience", "M"],
    },
    сапог: {
      NNS: ["сапог", "(high) boot", "M"],
      NNP: ["сапоги", "(high) boot", "M"],
      NGS: ["сапога", "(high) boot", "M"],
      NGP: ["сапог", "(high) boot", "M"],
      NDS: ["сапогу", "(high) boot", "M"],
      NDP: ["сапогам", "(high) boot", "M"],
      NAS: ["сапог", "(high) boot", "M"],
      NAP: ["сапоги", "(high) boot", "M"],
      NIS: ["сапогом", "(high) boot", "M"],
      NIP: ["сапогами", "(high) boot", "M"],
      NPS: ["сапоге", "(high) boot", "M"],
      NPP: ["сапогах", "(high) boot", "M"],
    },
    правило: {
      NNS: ["правило", "(hunters jargon) tail / brush of an animal", "N"],
      NNP: ["правила", "(hunters jargon) tail / brush of an animal", "N"],
      NGS: ["правила", "(hunters jargon) tail / brush of an animal", "N"],
      NGP: ["правил", "(hunters jargon) tail / brush of an animal", "N"],
      NDS: ["правилу", "(hunters jargon) tail / brush of an animal", "N"],
      NDP: ["правилам", "(hunters jargon) tail / brush of an animal", "N"],
      NAS: ["правило", "(hunters jargon) tail / brush of an animal", "N"],
      NAP: ["правила", "(hunters jargon) tail / brush of an animal", "N"],
      NIS: ["правилом", "(hunters jargon) tail / brush of an animal", "N"],
      NIP: ["правилами", "(hunters jargon) tail / brush of an animal", "N"],
      NPS: ["правиле", "(hunters jargon) tail / brush of an animal", "N"],
      NPP: ["правилах", "(hunters jargon) tail / brush of an animal", "N"],
    },
    стекло: {
      NNS: ["стекло", "glass", "N"],
      NNP: ["стёкла", "glass", "N"],
      NGS: ["стекла", "glass", "N"],
      NGP: ["стёкол", "glass", "N"],
      NDS: ["стеклу", "glass", "N"],
      NDP: ["стёклам", "glass", "N"],
      NAS: ["стекло", "glass", "N"],
      NAP: ["стёкла", "glass", "N"],
      NIS: ["стеклом", "glass", "N"],
      NIP: ["стёклами", "glass", "N"],
      NPS: ["стекле", "glass", "N"],
      NPP: ["стёклах", "glass", "N"],
    },
    дочь: {
      NNS: ["дочь", "daughter", "F"],
      NNP: ["дочери", "daughter", "F"],
      NGS: ["дочери", "daughter", "F"],
      NGP: ["дочерей", "daughter", "F"],
      NDS: ["дочери", "daughter", "F"],
      NDP: ["дочерям", "daughter", "F"],
      NAS: ["дочь", "daughter", "F"],
      NAP: ["дочерей", "daughter", "F"],
      NIS: ["дочерью", "daughter", "F"],
      NIP: ["дочерьми", "daughter", "F"],
      NPS: ["дочери", "daughter", "F"],
      NPP: ["дочерях", "daughter", "F"],
    },
    член: {
      NNS: ["член", "member, limb", "M"],
      NNP: ["члены", "member, limb", "M"],
      NGS: ["члена", "member, limb", "M"],
      NGP: ["членов", "member, limb", "M"],
      NDS: ["члену", "member, limb", "M"],
      NDP: ["членам", "member, limb", "M"],
      NAS: ["член", "member, limb", "M"],
      NAP: ["члены", "member, limb", "M"],
      NIS: ["членом", "member, limb", "M"],
      NIP: ["членами", "member, limb", "M"],
      NPS: ["члене", "member, limb", "M"],
      NPP: ["членах", "member, limb", "M"],
    },
    десяток: {
      NNS: ["десяток", "ten, dozens", "M"],
      NNP: ["десятки", "ten, dozens", "M"],
      NGS: ["десятка", "ten, dozens", "M"],
      NGP: ["десятков", "ten, dozens", "M"],
      NDS: ["десятку", "ten, dozens", "M"],
      NDP: ["десяткам", "ten, dozens", "M"],
      NAS: ["десяток", "ten, dozens", "M"],
      NAP: ["десятки", "ten, dozens", "M"],
      NIS: ["десятком", "ten, dozens", "M"],
      NIP: ["десятками", "ten, dozens", "M"],
      NPS: ["десятке", "ten, dozens", "M"],
      NPP: ["десятках", "ten, dozens", "M"],
    },
    цветок: {
      NNS: ["цветок", "flower", "M"],
      NNP: ["цветы", "flower", "M"],
      NGS: ["цветка", "flower", "M"],
      NGP: ["цветов", "flower", "M"],
      NDS: ["цветку", "flower", "M"],
      NDP: ["цветам", "flower", "M"],
      NAS: ["цветок", "flower", "M"],
      NAP: ["цветы", "flower", "M"],
      NIS: ["цветком", "flower", "M"],
      NIP: ["цветами", "flower", "M"],
      NPS: ["цветке", "flower", "M"],
      NPP: ["цветах", "flower", "M"],
    },
    желание: {
      NNS: ["желание", "wish", "N"],
      NNP: ["желания", "wish", "N"],
      NGS: ["желания", "wish", "N"],
      NGP: ["желаний", "wish", "N"],
      NDS: ["желанию", "wish", "N"],
      NDP: ["желаниям", "wish", "N"],
      NAS: ["желание", "wish", "N"],
      NAP: ["желания", "wish", "N"],
      NIS: ["желанием", "wish", "N"],
      NIP: ["желаниями", "wish", "N"],
      NPS: ["желании", "wish", "N"],
      NPP: ["желаниях", "wish", "N"],
    },
    дождь: {
      NNS: ["дождь", "rain", "M"],
      NNP: ["дожди", "rain", "M"],
      NGS: ["дождя", "rain", "M"],
      NGP: ["дождей", "rain", "M"],
      NDS: ["дождю", "rain", "M"],
      NDP: ["дождям", "rain", "M"],
      NAS: ["дождь", "rain", "M"],
      NAP: ["дожди", "rain", "M"],
      NIS: ["дождём", "rain", "M"],
      NIP: ["дождями", "rain", "M"],
      NPS: ["дожде", "rain", "M"],
      NPP: ["дождях", "rain", "M"],
    },
    лоб: {
      NNS: ["лоб", "forehead", "M"],
      NNP: ["лбы", "forehead", "M"],
      NGS: ["лба", "forehead", "M"],
      NGP: ["лбов", "forehead", "M"],
      NDS: ["лбу", "forehead", "M"],
      NDP: ["лбам", "forehead", "M"],
      NAS: ["лоб", "forehead", "M"],
      NAP: ["лбы", "forehead", "M"],
      NIS: ["лбом", "forehead", "M"],
      NIP: ["лбами", "forehead", "M"],
      NPS: ["лбе", "forehead", "M"],
      NPP: ["лбах", "forehead", "M"],
    },
    улыбка: {
      NNS: ["улыбка", "smile", "F"],
      NNP: ["улыбки", "smile", "F"],
      NGS: ["улыбки", "smile", "F"],
      NGP: ["улыбок", "smile", "F"],
      NDS: ["улыбке", "smile", "F"],
      NDP: ["улыбкам", "smile", "F"],
      NAS: ["улыбку", "smile", "F"],
      NAP: ["улыбки", "smile", "F"],
      NIS: ["улыбкой", "smile", "F"],
      NIP: ["улыбками", "smile", "F"],
      NPS: ["улыбке", "smile", "F"],
      NPP: ["улыбках", "smile", "F"],
    },
    борьба: {
      NNS: ["борьба", "fight, struggle", "F"],
      NNP: ["", "fight, struggle", "F"],
      NGS: ["борьбы", "fight, struggle", "F"],
      NGP: ["", "fight, struggle", "F"],
      NDS: ["борьбе", "fight, struggle", "F"],
      NDP: ["", "fight, struggle", "F"],
      NAS: ["борьбу", "fight, struggle", "F"],
      NAP: ["", "fight, struggle", "F"],
      NIS: ["борьбой", "fight, struggle", "F"],
      NIP: ["", "fight, struggle", "F"],
      NPS: ["борьбе", "fight, struggle", "F"],
      NPP: ["", "fight, struggle", "F"],
    },
    ворот: {
      NNS: ["ворот", "collar", "M"],
      NNP: ["вороты", "collar", "M"],
      NGS: ["ворота", "collar", "M"],
      NGP: ["воротов", "collar", "M"],
      NDS: ["вороту", "collar", "M"],
      NDP: ["воротам", "collar", "M"],
      NAS: ["ворот", "collar", "M"],
      NAP: ["вороты", "collar", "M"],
      NIS: ["воротом", "collar", "M"],
      NIP: ["воротами", "collar", "M"],
      NPS: ["вороте", "collar", "M"],
      NPP: ["воротах", "collar", "M"],
    },
    ящик: {
      NNS: ["ящик", "box, case", "M"],
      NNP: ["ящики", "box, case", "M"],
      NGS: ["ящика", "box, case", "M"],
      NGP: ["ящиков", "box, case", "M"],
      NDS: ["ящику", "box, case", "M"],
      NDP: ["ящикам", "box, case", "M"],
      NAS: ["ящик", "box, case", "M"],
      NAP: ["ящики", "box, case", "M"],
      NIS: ["ящиком", "box, case", "M"],
      NIP: ["ящиками", "box, case", "M"],
      NPS: ["ящике", "box, case", "M"],
      NPP: ["ящиках", "box, case", "M"],
    },
    этаж: {
      NNS: ["этаж", "floor, storey", "M"],
      NNP: ["этажи", "floor, storey", "M"],
      NGS: ["этажа", "floor, storey", "M"],
      NGP: ["этажей", "floor, storey", "M"],
      NDS: ["этажу", "floor, storey", "M"],
      NDP: ["этажам", "floor, storey", "M"],
      NAS: ["этаж", "floor, storey", "M"],
      NAP: ["этажи", "floor, storey", "M"],
      NIS: ["этажом", "floor, storey", "M"],
      NIP: ["этажами", "floor, storey", "M"],
      NPS: ["этаже", "floor, storey", "M"],
      NPP: ["этажах", "floor, storey", "M"],
    },
    революция: {
      NNS: ["революция", "revolution", "F"],
      NNP: ["революции", "revolution", "F"],
      NGS: ["революции", "revolution", "F"],
      NGP: ["революций", "revolution", "F"],
      NDS: ["революции", "revolution", "F"],
      NDP: ["революциям", "revolution", "F"],
      NAS: ["революцию", "revolution", "F"],
      NAP: ["революции", "revolution", "F"],
      NIS: ["революцией", "revolution", "F"],
      NIP: ["революциями", "revolution", "F"],
      NPS: ["революции", "revolution", "F"],
      NPP: ["революциях", "revolution", "F"],
    },
    сосед: {
      NNS: ["сосед", "neighbour", "M"],
      NNP: ["соседи", "neighbour", "M"],
      NGS: ["соседа", "neighbour", "M"],
      NGP: ["соседей", "neighbour", "M"],
      NDS: ["соседу", "neighbour", "M"],
      NDP: ["соседям", "neighbour", "M"],
      NAS: ["соседа", "neighbour", "M"],
      NAP: ["соседей", "neighbour", "M"],
      NIS: ["соседом", "neighbour", "M"],
      NIP: ["соседями", "neighbour", "M"],
      NPS: ["соседе", "neighbour", "M"],
      NPP: ["соседях", "neighbour", "M"],
    },
    сестра: {
      NNS: ["сестра", "sister", "F"],
      NNP: ["сёстры", "sister", "F"],
      NGS: ["сестры", "sister", "F"],
      NGP: ["сестёр", "sister", "F"],
      NDS: ["сестре", "sister", "F"],
      NDP: ["сёстрам", "sister", "F"],
      NAS: ["сестру", "sister", "F"],
      NAP: ["сестёр", "sister", "F"],
      NIS: ["сестрой", "sister", "F"],
      NIP: ["сёстрами", "sister", "F"],
      NPS: ["сестре", "sister", "F"],
      NPP: ["сёстрах", "sister", "F"],
    },
    ситуация: {
      NNS: ["ситуация", "situation", "F"],
      NNP: ["ситуации", "situation", "F"],
      NGS: ["ситуации", "situation", "F"],
      NGP: ["ситуаций", "situation", "F"],
      NDS: ["ситуации", "situation", "F"],
      NDP: ["ситуациям", "situation", "F"],
      NAS: ["ситуацию", "situation", "F"],
      NAP: ["ситуации", "situation", "F"],
      NIS: ["ситуацией", "situation", "F"],
      NIP: ["ситуациями", "situation", "F"],
      NPS: ["ситуации", "situation", "F"],
      NPP: ["ситуациях", "situation", "F"],
    },
    количество: {
      NNS: ["количество", "quantity", "N"],
      NNP: ["количества", "quantity", "N"],
      NGS: ["количества", "quantity", "N"],
      NGP: ["количеств", "quantity", "N"],
      NDS: ["количеству", "quantity", "N"],
      NDP: ["количествам", "quantity", "N"],
      NAS: ["количество", "quantity", "N"],
      NAP: ["количества", "quantity", "N"],
      NIS: ["количеством", "quantity", "N"],
      NIP: ["количествами", "quantity", "N"],
      NPS: ["количестве", "quantity", "N"],
      NPP: ["количествах", "quantity", "N"],
    },
    выход: {
      NNS: ["выход", "exit", "M"],
      NNP: ["выходы", "exit", "M"],
      NGS: ["выхода", "exit", "M"],
      NGP: ["выходов", "exit", "M"],
      NDS: ["выходу", "exit", "M"],
      NDP: ["выходам", "exit", "M"],
      NAS: ["выход", "exit", "M"],
      NAP: ["выходы", "exit", "M"],
      NIS: ["выходом", "exit", "M"],
      NIP: ["выходами", "exit", "M"],
      NPS: ["выходе", "exit", "M"],
      NPP: ["выходах", "exit", "M"],
    },
    совет: {
      NNS: ["совет", "advice; masterrussian, dot com", "M"],
      NNP: ["советы", "advice; masterrussian, dot com", "M"],
      NGS: ["совета", "advice; masterrussian, dot com", "M"],
      NGP: ["советов", "advice; masterrussian, dot com", "M"],
      NDS: ["совету", "advice; masterrussian, dot com", "M"],
      NDP: ["советам", "advice; masterrussian, dot com", "M"],
      NAS: ["совет", "advice; masterrussian, dot com", "M"],
      NAP: ["советы", "advice; masterrussian, dot com", "M"],
      NIS: ["советом", "advice; masterrussian, dot com", "M"],
      NIP: ["советами", "advice; masterrussian, dot com", "M"],
      NPS: ["совете", "advice; masterrussian, dot com", "M"],
      NPP: ["советах", "advice; masterrussian, dot com", "M"],
    },
    дурак: {
      NNS: ["дурак", "fool, idiot", "M"],
      NNP: ["дураки", "fool, idiot", "M"],
      NGS: ["дурака", "fool, idiot", "M"],
      NGP: ["дураков", "fool, idiot", "M"],
      NDS: ["дураку", "fool, idiot", "M"],
      NDP: ["дуракам", "fool, idiot", "M"],
      NAS: ["дурака", "fool, idiot", "M"],
      NAP: ["дураков", "fool, idiot", "M"],
      NIS: ["дураком", "fool, idiot", "M"],
      NIP: ["дураками", "fool, idiot", "M"],
      NPS: ["дураке", "fool, idiot", "M"],
      NPP: ["дураках", "fool, idiot", "M"],
    },
    союз: {
      NNS: ["союз", "union, alliance; conjunction", "M"],
      NNP: ["союзы", "union, alliance; conjunction", "M"],
      NGS: ["союза", "union, alliance; conjunction", "M"],
      NGP: ["союзов", "union, alliance; conjunction", "M"],
      NDS: ["союзу", "union, alliance; conjunction", "M"],
      NDP: ["союзам", "union, alliance; conjunction", "M"],
      NAS: ["союз", "union, alliance; conjunction", "M"],
      NAP: ["союзы", "union, alliance; conjunction", "M"],
      NIS: ["союзом", "union, alliance; conjunction", "M"],
      NIP: ["союзами", "union, alliance; conjunction", "M"],
      NPS: ["союзе", "union, alliance; conjunction", "M"],
      NPP: ["союзах", "union, alliance; conjunction", "M"],
    },
    лето: {
      NNS: ["лето", "summer", "N"],
      NNP: ["лета", "summer", "N"],
      NGS: ["лета", "summer", "N"],
      NGP: ["лет", "summer", "N"],
      NDS: ["лету", "summer", "N"],
      NDP: ["летам", "summer", "N"],
      NAS: ["лето", "summer", "N"],
      NAP: ["лета", "summer", "N"],
      NIS: ["летом", "summer", "N"],
      NIP: ["летами", "summer", "N"],
      NPS: ["лете", "summer", "N"],
      NPP: ["летах", "summer", "N"],
    },
    граница: {
      NNS: ["граница", "border", "F"],
      NNP: ["границы", "border", "F"],
      NGS: ["границы", "border", "F"],
      NGP: ["границ", "border", "F"],
      NDS: ["границе", "border", "F"],
      NDP: ["границам", "border", "F"],
      NAS: ["границу", "border", "F"],
      NAP: ["границы", "border", "F"],
      NIS: ["границей", "border", "F"],
      NIP: ["границами", "border", "F"],
      NPS: ["границе", "border", "F"],
      NPP: ["границах", "border", "F"],
    },
    цвет: {
      NNS: ["цвет", "colour, color", "M"],
      NNP: ["цвета", "colour, color", "M"],
      NGS: ["цвета", "colour, color", "M"],
      NGP: ["цветов", "colour, color", "M"],
      NDS: ["цвету", "colour, color", "M"],
      NDP: ["цветам", "colour, color", "M"],
      NAS: ["цвет", "colour, color", "M"],
      NAP: ["цвета", "colour, color", "M"],
      NIS: ["цветом", "colour, color", "M"],
      NIP: ["цветами", "colour, color", "M"],
      NPS: ["цвете", "colour, color", "M"],
      NPP: ["цветах", "colour, color", "M"],
    },
    свобода: {
      NNS: ["свобода", "freedom", "F"],
      NNP: ["свободы", "freedom", "F"],
      NGS: ["свободы", "freedom", "F"],
      NGP: ["свобод", "freedom", "F"],
      NDS: ["свободе", "freedom", "F"],
      NDP: ["свободам", "freedom", "F"],
      NAS: ["свободу", "freedom", "F"],
      NAP: ["свободы", "freedom", "F"],
      NIS: ["свободой", "freedom", "F"],
      NIP: ["свободами", "freedom", "F"],
      NPS: ["свободе", "freedom", "F"],
      NPP: ["свободах", "freedom", "F"],
    },
    стул: {
      NNS: ["стул", "chair", "M"],
      NNP: ["стулья", "chair", "M"],
      NGS: ["стула", "chair", "M"],
      NGP: ["стульев", "chair", "M"],
      NDS: ["стулу", "chair", "M"],
      NDP: ["стульям", "chair", "M"],
      NAS: ["стул", "chair", "M"],
      NAP: ["стулья", "chair", "M"],
      NIS: ["стулом", "chair", "M"],
      NIP: ["стульями", "chair", "M"],
      NPS: ["стуле", "chair", "M"],
      NPP: ["стульях", "chair", "M"],
    },
    поезд: {
      NNS: ["поезд", "train", "M"],
      NNP: ["поезда", "train", "M"],
      NGS: ["поезда", "train", "M"],
      NGP: ["поездов", "train", "M"],
      NDS: ["поезду", "train", "M"],
      NDP: ["поездам", "train", "M"],
      NAS: ["поезд", "train", "M"],
      NAP: ["поезда", "train", "M"],
      NIS: ["поездом", "train", "M"],
      NIP: ["поездами", "train", "M"],
      NPS: ["поезде", "train", "M"],
      NPP: ["поездах", "train", "M"],
    },
    музыка: {
      NNS: ["музыка", "music", "F"],
      NNP: ["музыки", "music", "F"],
      NGS: ["музыки", "music", "F"],
      NGP: ["музык", "music", "F"],
      NDS: ["музыке", "music", "F"],
      NDP: ["музыкам", "music", "F"],
      NAS: ["музыку", "music", "F"],
      NAP: ["музыки", "music", "F"],
      NIS: ["музыкой", "music", "F"],
      NIP: ["музыками", "music", "F"],
      NPS: ["музыке", "music", "F"],
      NPP: ["музыках", "music", "F"],
    },
    тень: {
      NNS: ["тень", "shadow", "F"],
      NNP: ["тени", "shadow", "F"],
      NGS: ["тени", "shadow", "F"],
      NGP: ["теней", "shadow", "F"],
      NDS: ["тени", "shadow", "F"],
      NDP: ["теням", "shadow", "F"],
      NAS: ["тень", "shadow", "F"],
      NAP: ["тени", "shadow", "F"],
      NIS: ["тенью", "shadow", "F"],
      NIP: ["тенями", "shadow", "F"],
      NPS: ["тени", "shadow", "F"],
      NPP: ["тенях", "shadow", "F"],
    },
    лошадь: {
      NNS: ["лошадь", "horse", "F"],
      NNP: ["лошади", "horse", "F"],
      NGS: ["лошади", "horse", "F"],
      NGP: ["лошадей", "horse", "F"],
      NDS: ["лошади", "horse", "F"],
      NDP: ["лошадям", "horse", "F"],
      NAS: ["лошадь", "horse", "F"],
      NAP: ["лошадей", "horse", "F"],
      NIS: ["лошадью", "horse", "F"],
      NIP: ["лошадьми", "horse", "F"],
      NPS: ["лошади", "horse", "F"],
      NPP: ["лошадях", "horse", "F"],
    },
    поле: {
      NNS: ["поле", "field", "N"],
      NNP: ["поля", "field", "N"],
      NGS: ["поля", "field", "N"],
      NGP: ["полей", "field", "N"],
      NDS: ["полю", "field", "N"],
      NDP: ["полям", "field", "N"],
      NAS: ["поле", "field", "N"],
      NAP: ["поля", "field", "N"],
      NIS: ["полем", "field", "N"],
      NIP: ["полями", "field", "N"],
      NPS: ["поле", "field", "N"],
      NPP: ["полях", "field", "N"],
    },
    тип: {
      NNS: ["тип", "type, model", "N"],
      NNP: ["типы", "type, model", "N"],
      NGS: ["типа", "type, model", "N"],
      NGP: ["типов", "type, model", "N"],
      NDS: ["типу", "type, model", "N"],
      NDP: ["типам", "type, model", "N"],
      NAS: ["тип", "type, model", "N"],
      NAP: ["типы", "type, model", "N"],
      NIS: ["типом", "type, model", "N"],
      NIP: ["типами", "type, model", "N"],
      NPS: ["типе", "type, model", "N"],
      NPP: ["типах", "type, model", "N"],
    },
    суд: {
      NNS: ["суд", "court, law-court", "M"],
      NNP: ["суды", "court, law-court", "M"],
      NGS: ["суда", "court, law-court", "M"],
      NGP: ["судов", "court, law-court", "M"],
      NDS: ["суду", "court, law-court", "M"],
      NDP: ["судам", "court, law-court", "M"],
      NAS: ["суд", "court, law-court", "M"],
      NAP: ["суды", "court, law-court", "M"],
      NIS: ["судом", "court, law-court", "M"],
      NIP: ["судами", "court, law-court", "M"],
      NPS: ["суде", "court, law-court", "M"],
      NPP: ["судах", "court, law-court", "M"],
    },
    площадь: {
      NNS: ["площадь", "square, area", "F"],
      NNP: ["площади", "square, area", "F"],
      NGS: ["площади", "square, area", "F"],
      NGP: ["площадей", "square, area", "F"],
      NDS: ["площади", "square, area", "F"],
      NDP: ["площадям", "square, area", "F"],
      NAS: ["площадь", "square, area", "F"],
      NAP: ["площади", "square, area", "F"],
      NIS: ["площадью", "square, area", "F"],
      NIP: ["площадями", "square, area", "F"],
      NPS: ["площади", "square, area", "F"],
      NPP: ["площадях", "square, area", "F"],
    },
    радость: {
      NNS: ["радость", "gladness, joy", "F"],
      NNP: ["радости", "gladness, joy", "F"],
      NGS: ["радости", "gladness, joy", "F"],
      NGP: ["радостей", "gladness, joy", "F"],
      NDS: ["радости", "gladness, joy", "F"],
      NDP: ["радостям", "gladness, joy", "F"],
      NAS: ["радость", "gladness, joy", "F"],
      NAP: ["радости", "gladness, joy", "F"],
      NIS: ["радостью", "gladness, joy", "F"],
      NIP: ["радостями", "gladness, joy", "F"],
      NPS: ["радости", "gladness, joy", "F"],
      NPP: ["радостях", "gladness, joy", "F"],
    },
    возраст: {
      NNS: ["возраст", "age", "M"],
      NNP: ["возрасты", "age", "M"],
      NGS: ["возраста", "age", "M"],
      NGP: ["возрастов", "age", "M"],
      NDS: ["возрасту", "age", "M"],
      NDP: ["возрастам", "age", "M"],
      NAS: ["возраст", "age", "M"],
      NAP: ["возрасты", "age", "M"],
      NIS: ["возрастом", "age", "M"],
      NIP: ["возрастами", "age", "M"],
      NPS: ["возрасте", "age", "M"],
      NPP: ["возрастах", "age", "M"],
    },
    орган: {
      NNS: ["орган", "organ (instrument)", "N"],
      NNP: ["органы", "organ (instrument)", "N"],
      NGS: ["органа", "organ (instrument)", "N"],
      NGP: ["органов", "organ (instrument)", "N"],
      NDS: ["органу", "organ (instrument)", "N"],
      NDP: ["органам", "organ (instrument)", "N"],
      NAS: ["орган", "organ (instrument)", "N"],
      NAP: ["органы", "organ (instrument)", "N"],
      NIS: ["органом", "organ (instrument)", "N"],
      NIP: ["органами", "organ (instrument)", "N"],
      NPS: ["органе", "organ (instrument)", "N"],
      NPP: ["органах", "organ (instrument)", "N"],
    },
    карта: {
      NNS: ["карта", "map", "F"],
      NNP: ["карты", "map", "F"],
      NGS: ["карты", "map", "F"],
      NGP: ["карт", "map", "F"],
      NDS: ["карте", "map", "F"],
      NDP: ["картам", "map", "F"],
      NAS: ["карту", "map", "F"],
      NAP: ["карты", "map", "F"],
      NIS: ["картой", "map", "F"],
      NIP: ["картами", "map", "F"],
      NPS: ["карте", "map", "F"],
      NPP: ["картах", "map", "F"],
    },
    король: {
      NNS: ["король", "king", "M"],
      NNP: ["короли", "king", "M"],
      NGS: ["короля", "king", "M"],
      NGP: ["королей", "king", "M"],
      NDS: ["королю", "king", "M"],
      NDP: ["королям", "king", "M"],
      NAS: ["короля", "king", "M"],
      NAP: ["королей", "king", "M"],
      NIS: ["королём", "king", "M"],
      NIP: ["королями", "king", "M"],
      NPS: ["короле", "king", "M"],
      NPP: ["королях", "king", "M"],
    },
    слава: {
      NNS: ["слава", "glory, fame", "F"],
      NNP: ["славы", "glory, fame", "F"],
      NGS: ["славы", "glory, fame", "F"],
      NGP: ["слав", "glory, fame", "F"],
      NDS: ["славе", "glory, fame", "F"],
      NDP: ["славам", "glory, fame", "F"],
      NAS: ["славу", "glory, fame", "F"],
      NAP: ["славы", "glory, fame", "F"],
      NIS: ["славой", "glory, fame", "F"],
      NIP: ["славами", "glory, fame", "F"],
      NPS: ["славе", "glory, fame", "F"],
      NPP: ["славах", "glory, fame", "F"],
    },
    полковник: {
      NNS: ["полковник", "colonel", "M"],
      NNP: ["полковники", "colonel", "M"],
      NGS: ["полковника", "colonel", "M"],
      NGP: ["полковников", "colonel", "M"],
      NDS: ["полковнику", "colonel", "M"],
      NDP: ["полковникам", "colonel", "M"],
      NAS: ["полковника", "colonel", "M"],
      NAP: ["полковников", "colonel", "M"],
      NIS: ["полковником", "colonel", "M"],
      NIP: ["полковниками", "colonel", "M"],
      NPS: ["полковнике", "colonel", "M"],
      NPP: ["полковниках", "colonel", "M"],
    },
    бок: {
      NNS: ["бок", "side, flank", "M"],
      NNP: ["бока", "side, flank", "M"],
      NGS: ["бока", "side, flank", "M"],
      NGP: ["боков", "side, flank", "M"],
      NDS: ["боку", "side, flank", "M"],
      NDP: ["бокам", "side, flank", "M"],
      NAS: ["бок", "side, flank", "M"],
      NAP: ["бока", "side, flank", "M"],
      NIS: ["боком", "side, flank", "M"],
      NIP: ["боками", "side, flank", "M"],
      NPS: ["боке", "side, flank", "M"],
      NPP: ["боках", "side, flank", "M"],
    },
    цена: {
      NNS: ["цена", "price", "F"],
      NNP: ["цены", "price", "F"],
      NGS: ["цены", "price", "F"],
      NGP: ["цен", "price", "F"],
      NDS: ["цене", "price", "F"],
      NDP: ["ценам", "price", "F"],
      NAS: ["цену", "price", "F"],
      NAP: ["цены", "price", "F"],
      NIS: ["ценой", "price", "F"],
      NIP: ["ценами", "price", "F"],
      NPS: ["цене", "price", "F"],
      NPP: ["ценах", "price", "F"],
    },
    информация: {
      NNS: ["информация", "information", "F"],
      NNP: ["", "information", "F"],
      NGS: ["информации", "information", "F"],
      NGP: ["", "information", "F"],
      NDS: ["информации", "information", "F"],
      NDP: ["", "information", "F"],
      NAS: ["информацию", "information", "F"],
      NAP: ["", "information", "F"],
      NIS: ["информацией", "information", "F"],
      NIP: ["", "information", "F"],
      NPS: ["информации", "information", "F"],
      NPP: ["", "information", "F"],
    },
    мозг: {
      NNS: ["мозг", "brain", "M"],
      NNP: ["мозги", "brain", "M"],
      NGS: ["мозга", "brain", "M"],
      NGP: ["мозгов", "brain", "M"],
      NDS: ["мозгу", "brain", "M"],
      NDP: ["мозгам", "brain", "M"],
      NAS: ["мозг", "brain", "M"],
      NAP: ["мозги", "brain", "M"],
      NIS: ["мозгом", "brain", "M"],
      NIP: ["мозгами", "brain", "M"],
      NPS: ["мозге", "brain", "M"],
      NPP: ["мозгах", "brain", "M"],
    },
    удовольствие: {
      NNS: ["удовольствие", "pleasure", "N"],
      NNP: ["удовольствия", "pleasure", "N"],
      NGS: ["удовольствия", "pleasure", "N"],
      NGP: ["удовольствий", "pleasure", "N"],
      NDS: ["удовольствию", "pleasure", "N"],
      NDP: ["удовольствиям", "pleasure", "N"],
      NAS: ["удовольствие", "pleasure", "N"],
      NAP: ["удовольствия", "pleasure", "N"],
      NIS: ["удовольствием", "pleasure", "N"],
      NIP: ["удовольствиями", "pleasure", "N"],
      NPS: ["удовольствии", "pleasure", "N"],
      NPP: ["удовольствиях", "pleasure", "N"],
    },
    воля: {
      NNS: ["воля", "will", "F"],
      NNP: ["воли", "will", "F"],
      NGS: ["воли", "will", "F"],
      NGP: ["воль", "will", "F"],
      NDS: ["воле", "will", "F"],
      NDP: ["волям", "will", "F"],
      NAS: ["волю", "will", "F"],
      NAP: ["воли", "will", "F"],
      NIS: ["волей", "will", "F"],
      NIP: ["волями", "will", "F"],
      NPS: ["воле", "will", "F"],
      NPP: ["волях", "will", "F"],
    },
    область: {
      NNS: ["область", "oblast, province; field", "F"],
      NNP: ["области", "oblast, province; field", "F"],
      NGS: ["области", "oblast, province; field", "F"],
      NGP: ["областей", "oblast, province; field", "F"],
      NDS: ["области", "oblast, province; field", "F"],
      NDP: ["областям", "oblast, province; field", "F"],
      NAS: ["область", "oblast, province; field", "F"],
      NAP: ["области", "oblast, province; field", "F"],
      NIS: ["областью", "oblast, province; field", "F"],
      NIP: ["областями", "oblast, province; field", "F"],
      NPS: ["области", "oblast, province; field", "F"],
      NPP: ["областях", "oblast, province; field", "F"],
    },
    крыша: {
      NNS: ["крыша", "roof", "F"],
      NNP: ["крыши", "roof", "F"],
      NGS: ["крыши", "roof", "F"],
      NGP: ["крыш", "roof", "F"],
      NDS: ["крыше", "roof", "F"],
      NDP: ["крышам", "roof", "F"],
      NAS: ["крышу", "roof", "F"],
      NAP: ["крыши", "roof", "F"],
      NIS: ["крышей", "roof", "F"],
      NIP: ["крышами", "roof", "F"],
      NPS: ["крыше", "roof", "F"],
      NPP: ["крышах", "roof", "F"],
    },
    дама: {
      NNS: ["дама", "lady, partner, queen", "F"],
      NNP: ["дамы", "lady, partner, queen", "F"],
      NGS: ["дамы", "lady, partner, queen", "F"],
      NGP: ["дам", "lady, partner, queen", "F"],
      NDS: ["даме", "lady, partner, queen", "F"],
      NDP: ["дамам", "lady, partner, queen", "F"],
      NAS: ["даму", "lady, partner, queen", "F"],
      NAP: ["дам", "lady, partner, queen", "F"],
      NIS: ["дамой", "lady, partner, queen", "F"],
      NIP: ["дамами", "lady, partner, queen", "F"],
      NPS: ["даме", "lady, partner, queen", "F"],
      NPP: ["дамах", "lady, partner, queen", "F"],
    },
    сад: {
      NNS: ["сад", "garden, yard", "M"],
      NNP: ["сады", "garden, yard", "M"],
      NGS: ["сада", "garden, yard", "M"],
      NGP: ["садов", "garden, yard", "M"],
      NDS: ["саду", "garden, yard", "M"],
      NDP: ["садам", "garden, yard", "M"],
      NAS: ["сад", "garden, yard", "M"],
      NAP: ["сады", "garden, yard", "M"],
      NIS: ["садом", "garden, yard", "M"],
      NIP: ["садами", "garden, yard", "M"],
      NPS: ["саду", "garden, yard", "M"],
      NPP: ["садах", "garden, yard", "M"],
    },
    правительство: {
      NNS: ["правительство", "government", "N"],
      NNP: ["правительства", "government", "N"],
      NGS: ["правительства", "government", "N"],
      NGP: ["правительств", "government", "N"],
      NDS: ["правительству", "government", "N"],
      NDP: ["правительствам", "government", "N"],
      NAS: ["правительство", "government", "N"],
      NAP: ["правительства", "government", "N"],
      NIS: ["правительством", "government", "N"],
      NIP: ["правительствами", "government", "N"],
      NPS: ["правительстве", "government", "N"],
      NPP: ["правительствах", "government", "N"],
    },
    название: {
      NNS: ["название", "name, title", "N"],
      NNP: ["названия", "name, title", "N"],
      NGS: ["названия", "name, title", "N"],
      NGP: ["названий", "name, title", "N"],
      NDS: ["названию", "name, title", "N"],
      NDP: ["названиям", "name, title", "N"],
      NAS: ["название", "name, title", "N"],
      NAP: ["названия", "name, title", "N"],
      NIS: ["названием", "name, title", "N"],
      NIP: ["названиями", "name, title", "N"],
      NPS: ["названии", "name, title", "N"],
      NPP: ["названиях", "name, title", "N"],
    },
    пример: {
      NNS: ["пример", "example", "M"],
      NNP: ["примеры", "example", "M"],
      NGS: ["примера", "example", "M"],
      NGP: ["примеров", "example", "M"],
      NDS: ["примеру", "example", "M"],
      NDP: ["примерам", "example", "M"],
      NAS: ["пример", "example", "M"],
      NAP: ["примеры", "example", "M"],
      NIS: ["примером", "example", "M"],
      NIP: ["примерами", "example", "M"],
      NPS: ["примере", "example", "M"],
      NPP: ["примерах", "example", "M"],
    },
    зеркало: {
      NNS: ["зеркало", "mirror", "N"],
      NNP: ["зеркала", "mirror", "N"],
      NGS: ["зеркала", "mirror", "N"],
      NGP: ["зеркал", "mirror", "N"],
      NDS: ["зеркалу", "mirror", "N"],
      NDP: ["зеркалам", "mirror", "N"],
      NAS: ["зеркало", "mirror", "N"],
      NAP: ["зеркала", "mirror", "N"],
      NIS: ["зеркалом", "mirror", "N"],
      NIP: ["зеркалами", "mirror", "N"],
      NPS: ["зеркале", "mirror", "N"],
      NPP: ["зеркалах", "mirror", "N"],
    },
    дым: {
      NNS: ["дым", "smoke", "M"],
      NNP: ["дымы", "smoke", "M"],
      NGS: ["дыма", "smoke", "M"],
      NGP: ["дымов", "smoke", "M"],
      NDS: ["дыму", "smoke", "M"],
      NDP: ["дымам", "smoke", "M"],
      NAS: ["дым", "smoke", "M"],
      NAP: ["дымы", "smoke", "M"],
      NIS: ["дымом", "smoke", "M"],
      NIP: ["дымами", "smoke", "M"],
      NPS: ["дыме", "smoke", "M"],
      NPP: ["дымах", "smoke", "M"],
    },
    факт: {
      NNS: ["факт", "fact", "M"],
      NNP: ["факты", "fact", "M"],
      NGS: ["факта", "fact", "M"],
      NGP: ["фактов", "fact", "M"],
      NDS: ["факту", "fact", "M"],
      NDP: ["фактам", "fact", "M"],
      NAS: ["факт", "fact", "M"],
      NAP: ["факты", "fact", "M"],
      NIS: ["фактом", "fact", "M"],
      NIP: ["фактами", "fact", "M"],
      NPS: ["факте", "fact", "M"],
      NPP: ["фактах", "fact", "M"],
    },
    рыба: {
      NNS: ["рыба", "fish", "F"],
      NNP: ["рыбы", "fish", "F"],
      NGS: ["рыбы", "fish", "F"],
      NGP: ["рыб", "fish", "F"],
      NDS: ["рыбе", "fish", "F"],
      NDP: ["рыбам", "fish", "F"],
      NAS: ["рыбу", "fish", "F"],
      NAP: ["рыб", "fish", "F"],
      NIS: ["рыбой", "fish", "F"],
      NIP: ["рыбами", "fish", "F"],
      NPS: ["рыбе", "fish", "F"],
      NPP: ["рыбах", "fish", "F"],
    },
    бабушка: {
      NNS: ["бабушка", "grandmother", "F"],
      NNP: ["бабушки", "grandmother", "F"],
      NGS: ["бабушки", "grandmother", "F"],
      NGP: ["бабушек", "grandmother", "F"],
      NDS: ["бабушке", "grandmother", "F"],
      NDP: ["бабушкам", "grandmother", "F"],
      NAS: ["бабушку", "grandmother", "F"],
      NAP: ["бабушек", "grandmother", "F"],
      NIS: ["бабушкой", "grandmother", "F"],
      NIP: ["бабушками", "grandmother", "F"],
      NPS: ["бабушке", "grandmother", "F"],
      NPP: ["бабушках", "grandmother", "F"],
    },
    вино: {
      NNS: ["вино", "wine", "N"],
      NNP: ["вина", "wine", "N"],
      NGS: ["вина", "wine", "N"],
      NGP: ["вин", "wine", "N"],
      NDS: ["вину", "wine", "N"],
      NDP: ["винам", "wine", "N"],
      NAS: ["вино", "wine", "N"],
      NAP: ["вина", "wine", "N"],
      NIS: ["вином", "wine", "N"],
      NIP: ["винами", "wine", "N"],
      NPS: ["вине", "wine", "N"],
      NPP: ["винах", "wine", "N"],
    },
    учитель: {
      NNS: ["учитель", "teacher, instructor", "M"],
      NNP: ["учителя", "teacher, instructor", "M"],
      NGS: ["учителя", "teacher, instructor", "M"],
      NGP: ["учителей", "teacher, instructor", "M"],
      NDS: ["учителю", "teacher, instructor", "M"],
      NDP: ["учителям", "teacher, instructor", "M"],
      NAS: ["учителя", "teacher, instructor", "M"],
      NAP: ["учителей", "teacher, instructor", "M"],
      NIS: ["учителем", "teacher, instructor", "M"],
      NIP: ["учителями", "teacher, instructor", "M"],
      NPS: ["учителе", "teacher, instructor", "M"],
      NPP: ["учителях", "teacher, instructor", "M"],
    },
    круг: {
      NNS: ["круг", "circle; masterrussian, dot com", "M"],
      NNP: ["круги", "circle; masterrussian, dot com", "M"],
      NGS: ["круга", "circle; masterrussian, dot com", "M"],
      NGP: ["кругов", "circle; masterrussian, dot com", "M"],
      NDS: ["кругу", "circle; masterrussian, dot com", "M"],
      NDP: ["кругам", "circle; masterrussian, dot com", "M"],
      NAS: ["круг", "circle; masterrussian, dot com", "M"],
      NAP: ["круги", "circle; masterrussian, dot com", "M"],
      NIS: ["кругом", "circle; masterrussian, dot com", "M"],
      NIP: ["кругами", "circle; masterrussian, dot com", "M"],
      NPS: ["круге", "circle; masterrussian, dot com", "M"],
      NPP: ["кругах", "circle; masterrussian, dot com", "M"],
    },
    папа: {
      NNS: ["папа", "dad, daddy", "M"],
      NNP: ["папы", "dad, daddy", "M"],
      NGS: ["папы", "dad, daddy", "M"],
      NGP: ["пап", "dad, daddy", "M"],
      NDS: ["папе", "dad, daddy", "M"],
      NDP: ["папам", "dad, daddy", "M"],
      NAS: ["папу", "dad, daddy", "M"],
      NAP: ["пап", "dad, daddy", "M"],
      NIS: ["папой", "dad, daddy", "M"],
      NIP: ["папами", "dad, daddy", "M"],
      NPS: ["папе", "dad, daddy", "M"],
      NPP: ["папах", "dad, daddy", "M"],
    },
    повод: {
      NNS: ["повод", "rein", "M"],
      NNP: ["поводья", "rein", "M"],
      NGS: ["повода", "rein", "M"],
      NGP: ["поводьев", "rein", "M"],
      NDS: ["поводу", "rein", "M"],
      NDP: ["поводьям", "rein", "M"],
      NAS: ["повод", "rein", "M"],
      NAP: ["поводья", "rein", "M"],
      NIS: ["поводом", "rein", "M"],
      NIP: ["поводьями", "rein", "M"],
      NPS: ["поводе", "rein", "M"],
      NPP: ["поводьях", "rein", "M"],
    },
    лагерь: {
      NNS: ["лагерь", "camp", "M"],
      NNP: ["лагеря", "camp", "M"],
      NGS: ["лагеря", "camp", "M"],
      NGP: ["лагерей", "camp", "M"],
      NDS: ["лагерю", "camp", "M"],
      NDP: ["лагерям", "camp", "M"],
      NAS: ["лагерь", "camp", "M"],
      NAP: ["лагеря", "camp", "M"],
      NIS: ["лагерем", "camp", "M"],
      NIP: ["лагерями", "camp", "M"],
      NPS: ["лагере", "camp", "M"],
      NPP: ["лагерях", "camp", "M"],
    },
    птица: {
      NNS: ["птица", "bird", "F"],
      NNP: ["птицы", "bird", "F"],
      NGS: ["птицы", "bird", "F"],
      NGP: ["птиц", "bird", "F"],
      NDS: ["птице", "bird", "F"],
      NDP: ["птицам", "bird", "F"],
      NAS: ["птицу", "bird", "F"],
      NAP: ["птиц", "bird", "F"],
      NIS: ["птицей", "bird", "F"],
      NIP: ["птицами", "bird", "F"],
      NPS: ["птице", "bird", "F"],
      NPP: ["птицах", "bird", "F"],
    },
    корабль: {
      NNS: ["корабль", "ship", "M"],
      NNP: ["корабли", "ship", "M"],
      NGS: ["корабля", "ship", "M"],
      NGP: ["кораблей", "ship", "M"],
      NDS: ["кораблю", "ship", "M"],
      NDP: ["кораблям", "ship", "M"],
      NAS: ["корабль", "ship", "M"],
      NAP: ["корабли", "ship", "M"],
      NIS: ["кораблём", "ship", "M"],
      NIP: ["кораблями", "ship", "M"],
      NPS: ["корабле", "ship", "M"],
      NPP: ["кораблях", "ship", "M"],
    },
    мнение: {
      NNS: ["мнение", "opinion", "N"],
      NNP: ["мнения", "opinion", "N"],
      NGS: ["мнения", "opinion", "N"],
      NGP: ["мнений", "opinion", "N"],
      NDS: ["мнению", "opinion", "N"],
      NDP: ["мнениям", "opinion", "N"],
      NAS: ["мнение", "opinion", "N"],
      NAP: ["мнения", "opinion", "N"],
      NIS: ["мнением", "opinion", "N"],
      NIP: ["мнениями", "opinion", "N"],
      NPS: ["мнении", "opinion", "N"],
      NPP: ["мнениях", "opinion", "N"],
    },
    зима: {
      NNS: ["зима", "winter", "F"],
      NNP: ["зимы", "winter", "F"],
      NGS: ["зимы", "winter", "F"],
      NGP: ["зим", "winter", "F"],
      NDS: ["зиме", "winter", "F"],
      NDP: ["зимам", "winter", "F"],
      NAS: ["зиму", "winter", "F"],
      NAP: ["зимы", "winter", "F"],
      NIS: ["зимой", "winter", "F"],
      NIP: ["зимами", "winter", "F"],
      NPS: ["зиме", "winter", "F"],
      NPP: ["зимах", "winter", "F"],
    },
    километр: {
      NNS: ["километр", "kilometre, kilometer", "M"],
      NNP: ["километры", "kilometre, kilometer", "M"],
      NGS: ["километра", "kilometre, kilometer", "M"],
      NGP: ["километров", "kilometre, kilometer", "M"],
      NDS: ["километру", "kilometre, kilometer", "M"],
      NDP: ["километрам", "kilometre, kilometer", "M"],
      NAS: ["километр", "kilometre, kilometer", "M"],
      NAP: ["километры", "kilometre, kilometer", "M"],
      NIS: ["километром", "kilometre, kilometer", "M"],
      NIP: ["километрами", "kilometre, kilometer", "M"],
      NPS: ["километре", "kilometre, kilometer", "M"],
      NPP: ["километрах", "kilometre, kilometer", "M"],
    },
    кровать: {
      NNS: ["кровать", "bed", "F"],
      NNP: ["кровати", "bed", "F"],
      NGS: ["кровати", "bed", "F"],
      NGP: ["кроватей", "bed", "F"],
      NDS: ["кровати", "bed", "F"],
      NDP: ["кроватям", "bed", "F"],
      NAS: ["кровать", "bed", "F"],
      NAP: ["кровати", "bed", "F"],
      NIS: ["кроватью", "bed", "F"],
      NIP: ["кроватями", "bed", "F"],
      NPS: ["кровати", "bed", "F"],
      NPP: ["кроватях", "bed", "F"],
    },
    лестница: {
      NNS: ["лестница", "staircase", "F"],
      NNP: ["лестницы", "staircase", "F"],
      NGS: ["лестницы", "staircase", "F"],
      NGP: ["лестниц", "staircase", "F"],
      NDS: ["лестнице", "staircase", "F"],
      NDP: ["лестницам", "staircase", "F"],
      NAS: ["лестницу", "staircase", "F"],
      NAP: ["лестницы", "staircase", "F"],
      NIS: ["лестницей", "staircase", "F"],
      NIP: ["лестницами", "staircase", "F"],
      NPS: ["лестнице", "staircase", "F"],
      NPP: ["лестницах", "staircase", "F"],
    },
    детство: {
      NNS: ["детство", "childhood", "N"],
      NNP: ["детства", "childhood", "N"],
      NGS: ["детства", "childhood", "N"],
      NGP: ["детств", "childhood", "N"],
      NDS: ["детству", "childhood", "N"],
      NDP: ["детствам", "childhood", "N"],
      NAS: ["детство", "childhood", "N"],
      NAP: ["детства", "childhood", "N"],
      NIS: ["детством", "childhood", "N"],
      NIP: ["детствами", "childhood", "N"],
      NPS: ["детстве", "childhood", "N"],
      NPP: ["детствах", "childhood", "N"],
    },
    остров: {
      NNS: ["остров", "island", "M"],
      NNP: ["острова", "island", "M"],
      NGS: ["острова", "island", "M"],
      NGP: ["островов", "island", "M"],
      NDS: ["острову", "island", "M"],
      NDP: ["островам", "island", "M"],
      NAS: ["остров", "island", "M"],
      NAP: ["острова", "island", "M"],
      NIS: ["островом", "island", "M"],
      NIP: ["островами", "island", "M"],
      NPS: ["острове", "island", "M"],
      NPP: ["островах", "island", "M"],
    },
    статья: {
      NNS: ["статья", "article", "F"],
      NNP: ["статьи", "article", "F"],
      NGS: ["статьи", "article", "F"],
      NGP: ["статей", "article", "F"],
      NDS: ["статье", "article", "F"],
      NDP: ["статьям", "article", "F"],
      NAS: ["статью", "article", "F"],
      NAP: ["статьи", "article", "F"],
      NIS: ["статьёй", "article", "F"],
      NIP: ["статьями", "article", "F"],
      NPS: ["статье", "article", "F"],
      NPP: ["статьях", "article", "F"],
    },
    водка: {
      NNS: ["водка", "vodka", "F"],
      NNP: ["водки", "vodka", "F"],
      NGS: ["водки", "vodka", "F"],
      NGP: ["водок", "vodka", "F"],
      NDS: ["водке", "vodka", "F"],
      NDP: ["водкам", "vodka", "F"],
      NAS: ["водку", "vodka", "F"],
      NAP: ["водки", "vodka", "F"],
      NIS: ["водкой", "vodka", "F"],
      NIP: ["водками", "vodka", "F"],
      NPS: ["водке", "vodka", "F"],
      NPP: ["водках", "vodka", "F"],
    },
    темнота: {
      NNS: ["темнота", "darkness", "F"],
      NNP: ["", "darkness", "F"],
      NGS: ["темноты", "darkness", "F"],
      NGP: ["", "darkness", "F"],
      NDS: ["темноте", "darkness", "F"],
      NDP: ["", "darkness", "F"],
      NAS: ["темноту", "darkness", "F"],
      NAP: ["", "darkness", "F"],
      NIS: ["темнотой", "darkness", "F"],
      NIP: ["", "darkness", "F"],
      NPS: ["темноте", "darkness", "F"],
      NPP: ["", "darkness", "F"],
    },
    станция: {
      NNS: ["станция", "station", "F"],
      NNP: ["станции", "station", "F"],
      NGS: ["станции", "station", "F"],
      NGP: ["станций", "station", "F"],
      NDS: ["станции", "station", "F"],
      NDP: ["станциям", "station", "F"],
      NAS: ["станцию", "station", "F"],
      NAP: ["станции", "station", "F"],
      NIS: ["станцией", "station", "F"],
      NIP: ["станциями", "station", "F"],
      NPS: ["станции", "station", "F"],
      NPP: ["станциях", "station", "F"],
    },
    гражданин: {
      NNS: ["гражданин", "citizen", "M"],
      NNP: ["граждане", "citizen", "M"],
      NGS: ["гражданина", "citizen", "M"],
      NGP: ["граждан", "citizen", "M"],
      NDS: ["гражданину", "citizen", "M"],
      NDP: ["гражданам", "citizen", "M"],
      NAS: ["гражданина", "citizen", "M"],
      NAP: ["граждан", "citizen", "M"],
      NIS: ["гражданином", "citizen", "M"],
      NIP: ["гражданами", "citizen", "M"],
      NPS: ["гражданине", "citizen", "M"],
      NPP: ["гражданах", "citizen", "M"],
    },
    команда: {
      NNS: ["команда", "team; command", "F"],
      NNP: ["команды", "team; command", "F"],
      NGS: ["команды", "team; command", "F"],
      NGP: ["команд", "team; command", "F"],
      NDS: ["команде", "team; command", "F"],
      NDP: ["командам", "team; command", "F"],
      NAS: ["команду", "team; command", "F"],
      NAP: ["команды", "team; command", "F"],
      NIS: ["командой", "team; command", "F"],
      NIP: ["командами", "team; command", "F"],
      NPS: ["команде", "team; command", "F"],
      NPP: ["командах", "team; command", "F"],
    },
    заболевание: {
      NNS: ["заболевание", "disease, illness", "N"],
      NNP: ["заболевания", "disease, illness", "N"],
      NGS: ["заболевания", "disease, illness", "N"],
      NGP: ["заболеваний", "disease, illness", "N"],
      NDS: ["заболеванию", "disease, illness", "N"],
      NDP: ["заболеваниям", "disease, illness", "N"],
      NAS: ["заболевание", "disease, illness", "N"],
      NAP: ["заболевания", "disease, illness", "N"],
      NIS: ["заболеванием", "disease, illness", "N"],
      NIP: ["заболеваниями", "disease, illness", "N"],
      NPS: ["заболевании", "disease, illness", "N"],
      NPP: ["заболеваниях", "disease, illness", "N"],
    },
    живот: {
      NNS: ["живот", "stomach, belly", "M"],
      NNP: ["животы", "stomach, belly", "M"],
      NGS: ["живота", "stomach, belly", "M"],
      NGP: ["животов", "stomach, belly", "M"],
      NDS: ["животу", "stomach, belly", "M"],
      NDP: ["животам", "stomach, belly", "M"],
      NAS: ["живот", "stomach, belly", "M"],
      NAP: ["животы", "stomach, belly", "M"],
      NIS: ["животом", "stomach, belly", "M"],
      NIP: ["животами", "stomach, belly", "M"],
      NPS: ["животе", "stomach, belly", "M"],
      NPP: ["животах", "stomach, belly", "M"],
    },
    тишина: {
      NNS: ["тишина", "silence, stillness", "F"],
      NNP: ["", "silence, stillness", "F"],
      NGS: ["тишины", "silence, stillness", "F"],
      NGP: ["", "silence, stillness", "F"],
      NDS: ["тишине", "silence, stillness", "F"],
      NDP: ["", "silence, stillness", "F"],
      NAS: ["тишину", "silence, stillness", "F"],
      NAP: ["", "silence, stillness", "F"],
      NIS: ["тишиной", "silence, stillness", "F"],
      NIP: ["", "silence, stillness", "F"],
      NPS: ["тишине", "silence, stillness", "F"],
      NPP: ["", "silence, stillness", "F"],
    },
    фронт: {
      NNS: ["фронт", "front", "M"],
      NNP: ["фронты", "front", "M"],
      NGS: ["фронта", "front", "M"],
      NGP: ["фронтов", "front", "M"],
      NDS: ["фронту", "front", "M"],
      NDP: ["фронтам", "front", "M"],
      NAS: ["фронт", "front", "M"],
      NAP: ["фронты", "front", "M"],
      NIS: ["фронтом", "front", "M"],
      NIP: ["фронтами", "front", "M"],
      NPS: ["фронте", "front", "M"],
      NPP: ["фронтах", "front", "M"],
    },
    щека: {
      NNS: ["щека", "cheek", "F"],
      NNP: ["щёки", "cheek", "F"],
      NGS: ["щеки", "cheek", "F"],
      NGP: ["щёк", "cheek", "F"],
      NDS: ["щеке", "cheek", "F"],
      NDP: ["щекам", "cheek", "F"],
      NAS: ["щёку", "cheek", "F"],
      NAP: ["щёки", "cheek", "F"],
      NIS: ["щекой", "cheek", "F"],
      NIP: ["щеками", "cheek", "F"],
      NPS: ["щеке", "cheek", "F"],
      NPP: ["щеках", "cheek", "F"],
    },
    район: {
      NNS: ["район", "area, region", "M"],
      NNP: ["районы", "area, region", "M"],
      NGS: ["района", "area, region", "M"],
      NGP: ["районов", "area, region", "M"],
      NDS: ["району", "area, region", "M"],
      NDP: ["районам", "area, region", "M"],
      NAS: ["район", "area, region", "M"],
      NAP: ["районы", "area, region", "M"],
      NIS: ["районом", "area, region", "M"],
      NIP: ["районами", "area, region", "M"],
      NPS: ["районе", "area, region", "M"],
      NPP: ["районах", "area, region", "M"],
    },
    выражение: {
      NNS: ["выражение", "expression", "N"],
      NNP: ["выражения", "expression", "N"],
      NGS: ["выражения", "expression", "N"],
      NGP: ["выражений", "expression", "N"],
      NDS: ["выражению", "expression", "N"],
      NDP: ["выражениям", "expression", "N"],
      NAS: ["выражение", "expression", "N"],
      NAP: ["выражения", "expression", "N"],
      NIS: ["выражением", "expression", "N"],
      NIP: ["выражениями", "expression", "N"],
      NPS: ["выражении", "expression", "N"],
      NPP: ["выражениях", "expression", "N"],
    },
    мешок: {
      NNS: ["мешок", "bag, sack", "M"],
      NNP: ["мешки", "bag, sack", "M"],
      NGS: ["мешка", "bag, sack", "M"],
      NGP: ["мешков", "bag, sack", "M"],
      NDS: ["мешку", "bag, sack", "M"],
      NDP: ["мешкам", "bag, sack", "M"],
      NAS: ["мешок", "bag, sack", "M"],
      NAP: ["мешки", "bag, sack", "M"],
      NIS: ["мешком", "bag, sack", "M"],
      NIP: ["мешками", "bag, sack", "M"],
      NPS: ["мешке", "bag, sack", "M"],
      NPP: ["мешках", "bag, sack", "M"],
    },
    большинство: {
      NNS: ["большинство", "majority", "N"],
      NNP: ["", "majority", "N"],
      NGS: ["большинства", "majority", "N"],
      NGP: ["", "majority", "N"],
      NDS: ["большинству", "majority", "N"],
      NDP: ["", "majority", "N"],
      NAS: ["большинство", "majority", "N"],
      NAP: ["", "majority", "N"],
      NIS: ["большинством", "majority", "N"],
      NIP: ["", "majority", "N"],
      NPS: ["большинстве", "majority", "N"],
      NPP: ["", "majority", "N"],
    },
    управление: {
      NNS: ["управление", "operation, control", "N"],
      NNP: ["управления", "operation, control", "N"],
      NGS: ["управления", "operation, control", "N"],
      NGP: ["управлений", "operation, control", "N"],
      NDS: ["управлению", "operation, control", "N"],
      NDP: ["управлениям", "operation, control", "N"],
      NAS: ["управление", "operation, control", "N"],
      NAP: ["управления", "operation, control", "N"],
      NIS: ["управлением", "operation, control", "N"],
      NIP: ["управлениями", "operation, control", "N"],
      NPS: ["управлении", "operation, control", "N"],
      NPP: ["управлениях", "operation, control", "N"],
    },
    приказ: {
      NNS: ["приказ", "order", "M"],
      NNP: ["приказы", "order", "M"],
      NGS: ["приказа", "order", "M"],
      NGP: ["приказов", "order", "M"],
      NDS: ["приказу", "order", "M"],
      NDP: ["приказам", "order", "M"],
      NAS: ["приказ", "order", "M"],
      NAP: ["приказы", "order", "M"],
      NIS: ["приказом", "order", "M"],
      NIP: ["приказами", "order", "M"],
      NPS: ["приказе", "order", "M"],
      NPP: ["приказах", "order", "M"],
    },
    куст: {
      NNS: ["куст", "bush", "M"],
      NNP: ["кусты", "bush", "M"],
      NGS: ["куста", "bush", "M"],
      NGP: ["кустов", "bush", "M"],
      NDS: ["кусту", "bush", "M"],
      NDP: ["кустам", "bush", "M"],
      NAS: ["куст", "bush", "M"],
      NAP: ["кусты", "bush", "M"],
      NIS: ["кустом", "bush", "M"],
      NIP: ["кустами", "bush", "M"],
      NPS: ["кусте", "bush", "M"],
      NPP: ["кустах", "bush", "M"],
    },
    художник: {
      NNS: ["художник", "painter, artist", "M"],
      NNP: ["художники", "painter, artist", "M"],
      NGS: ["художника", "painter, artist", "M"],
      NGP: ["художников", "painter, artist", "M"],
      NDS: ["художнику", "painter, artist", "M"],
      NDP: ["художникам", "painter, artist", "M"],
      NAS: ["художника", "painter, artist", "M"],
      NAP: ["художников", "painter, artist", "M"],
      NIS: ["художником", "painter, artist", "M"],
      NIP: ["художниками", "painter, artist", "M"],
      NPS: ["художнике", "painter, artist", "M"],
      NPP: ["художниках", "painter, artist", "M"],
    },
    знак: {
      NNS: ["знак", "sign", "M"],
      NNP: ["знаки", "sign", "M"],
      NGS: ["знака", "sign", "M"],
      NGP: ["знаков", "sign", "M"],
      NDS: ["знаку", "sign", "M"],
      NDP: ["знакам", "sign", "M"],
      NAS: ["знак", "sign", "M"],
      NAP: ["знаки", "sign", "M"],
      NIS: ["знаком", "sign", "M"],
      NIP: ["знаками", "sign", "M"],
      NPS: ["знаке", "sign", "M"],
      NPP: ["знаках", "sign", "M"],
    },
    завод: {
      NNS: ["завод", "factory", "M"],
      NNP: ["заводы", "factory", "M"],
      NGS: ["завода", "factory", "M"],
      NGP: ["заводов", "factory", "M"],
      NDS: ["заводу", "factory", "M"],
      NDP: ["заводам", "factory", "M"],
      NAS: ["завод", "factory", "M"],
      NAP: ["заводы", "factory", "M"],
      NIS: ["заводом", "factory", "M"],
      NIP: ["заводами", "factory", "M"],
      NPS: ["заводе", "factory", "M"],
      NPP: ["заводах", "factory", "M"],
    },
    кулак: {
      NNS: ["кулак", "fist", "M"],
      NNP: ["кулаки", "fist", "M"],
      NGS: ["кулака", "fist", "M"],
      NGP: ["кулаков", "fist", "M"],
      NDS: ["кулаку", "fist", "M"],
      NDP: ["кулакам", "fist", "M"],
      NAS: ["кулак", "fist", "M"],
      NAP: ["кулаки", "fist", "M"],
      NIS: ["кулаком", "fist", "M"],
      NIP: ["кулаками", "fist", "M"],
      NPS: ["кулаке", "fist", "M"],
      NPP: ["кулаках", "fist", "M"],
    },
    стакан: {
      NNS: ["стакан", "glass", "M"],
      NNP: ["стаканы", "glass", "M"],
      NGS: ["стакана", "glass", "M"],
      NGP: ["стаканов", "glass", "M"],
      NDS: ["стакану", "glass", "M"],
      NDP: ["стаканам", "glass", "M"],
      NAS: ["стакан", "glass", "M"],
      NAP: ["стаканы", "glass", "M"],
      NIS: ["стаканом", "glass", "M"],
      NIP: ["стаканами", "glass", "M"],
      NPS: ["стакане", "glass", "M"],
      NPP: ["стаканах", "glass", "M"],
    },
    рот: {
      NNS: ["рот", "mouth", "M"],
      NNP: ["рты", "mouth", "M"],
      NGS: ["рта", "mouth", "M"],
      NGP: ["ртов", "mouth", "M"],
      NDS: ["рту", "mouth", "M"],
      NDP: ["ртам", "mouth", "M"],
      NAS: ["рот", "mouth", "M"],
      NAP: ["рты", "mouth", "M"],
      NIS: ["ртом", "mouth", "M"],
      NIP: ["ртами", "mouth", "M"],
      NPS: ["рте", "mouth", "M"],
      NPP: ["ртах", "mouth", "M"],
    },
    телевизор: {
      NNS: ["телевизор", "TV", "M"],
      NNP: ["телевизоры", "TV", "M"],
      NGS: ["телевизора", "TV", "M"],
      NGP: ["телевизоров", "TV", "M"],
      NDS: ["телевизору", "TV", "M"],
      NDP: ["телевизорам", "TV", "M"],
      NAS: ["телевизор", "TV", "M"],
      NAP: ["телевизоры", "TV", "M"],
      NIS: ["телевизором", "TV", "M"],
      NIP: ["телевизорами", "TV", "M"],
      NPS: ["телевизоре", "TV", "M"],
      NPP: ["телевизорах", "TV", "M"],
    },
  };
  
  const verbs = {
    быть: {
      VI: ["быть", "to be"],
      VN1: ["есть", "to be"],
      VF1: ["буду быть", "to be"],
      VN2: ["есть", "to be"],
      VF2: ["будешь быть", "to be"],
      VN3: ["есть", "to be"],
      VF3: ["будет быть", "to be"],
      VNW: ["есть", "to be"],
      VFW: ["будем быть", "to be"],
      VNF: ["есть", "to be"],
      VFF: ["будете быть", "to be"],
      VNT: ["есть", "to be"],
      VFT: ["будут быть", "to be"],
      VI1: ["будь", "to be"],
      VI2: ["будьте", "to be"],
      VPM: ["был", "to be"],
      VPF: ["была", "to be"],
      VPN: ["было", "to be"],
      VPP: ["были", "to be"],
    },
    сказать: {
      VI: ["сказать", "to say, to speak"],
      VF1: ["скажу", "to say, to speak"],
      VF2: ["скажешь", "to say, to speak"],
      VF3: ["скажет", "to say, to speak"],
      VFW: ["скажем", "to say, to speak"],
      VFF: ["скажете", "to say, to speak"],
      VFT: ["скажут", "to say, to speak"],
      VI1: ["скажи", "to say, to speak"],
      VI2: ["скажите", "to say, to speak"],
      VPM: ["сказал", "to say, to speak"],
      VPF: ["сказала", "to say, to speak"],
      VPN: ["сказало", "to say, to speak"],
      VPP: ["сказали", "to say, to speak"],
    },
    мочь: {
      VI: ["мочь", "be able"],
      VN1: ["могу", "be able"],
      VF1: ["буду мочь", "be able"],
      VN2: ["можешь", "be able"],
      VF2: ["будешь мочь", "be able"],
      VN3: ["может", "be able"],
      VF3: ["будет мочь", "be able"],
      VNW: ["можем", "be able"],
      VFW: ["будем мочь", "be able"],
      VNF: ["можете", "be able"],
      VFF: ["будете мочь", "be able"],
      VNT: ["могут", "be able"],
      VFT: ["будут мочь", "be able"],
      VI1: ["моги", "be able"],
      VI2: ["могите", "be able"],
      VPM: ["мог", "be able"],
      VPF: ["могла", "be able"],
      VPN: ["могло", "be able"],
      VPP: ["могли", "be able"],
    },
    говорить: {
      VI: ["говорить", "to say, to tell, to speak"],
      VN1: ["говорю", "to say, to tell, to speak"],
      VF1: ["буду говорить", "to say, to tell, to speak"],
      VN2: ["говоришь", "to say, to tell, to speak"],
      VF2: ["будешь говорить", "to say, to tell, to speak"],
      VN3: ["говорит", "to say, to tell, to speak"],
      VF3: ["будет говорить", "to say, to tell, to speak"],
      VNW: ["говорим", "to say, to tell, to speak"],
      VFW: ["будем говорить", "to say, to tell, to speak"],
      VNF: ["говорите", "to say, to tell, to speak"],
      VFF: ["будете говорить", "to say, to tell, to speak"],
      VNT: ["говорят", "to say, to tell, to speak"],
      VFT: ["будут говорить", "to say, to tell, to speak"],
      VI1: ["говори", "to say, to tell, to speak"],
      VI2: ["говорите", "to say, to tell, to speak"],
      VPM: ["говорил", "to say, to tell, to speak"],
      VPF: ["говорила", "to say, to tell, to speak"],
      VPN: ["говорило", "to say, to tell, to speak"],
      VPP: ["говорили", "to say, to tell, to speak"],
    },
    знать: {
      VI: ["знать", "to know, be aware"],
      VN1: ["знаю", "to know, be aware"],
      VF1: ["буду знать", "to know, be aware"],
      VN2: ["знаешь", "to know, be aware"],
      VF2: ["будешь знать", "to know, be aware"],
      VN3: ["знает", "to know, be aware"],
      VF3: ["будет знать", "to know, be aware"],
      VNW: ["знаем", "to know, be aware"],
      VFW: ["будем знать", "to know, be aware"],
      VNF: ["знаете", "to know, be aware"],
      VFF: ["будете знать", "to know, be aware"],
      VNT: ["знают", "to know, be aware"],
      VFT: ["будут знать", "to know, be aware"],
      VI1: ["знай", "to know, be aware"],
      VI2: ["знайте", "to know, be aware"],
      VPM: ["знал", "to know, be aware"],
      VPF: ["знала", "to know, be aware"],
      VPN: ["знало", "to know, be aware"],
      VPP: ["знали", "to know, be aware"],
    },
    стать: {
      VI: ["стать", "to become, begin, come"],
      VF1: ["стану", "to become, begin, come"],
      VF2: ["станешь", "to become, begin, come"],
      VF3: ["станет", "to become, begin, come"],
      VFW: ["станем", "to become, begin, come"],
      VFF: ["станете", "to become, begin, come"],
      VFT: ["станут", "to become, begin, come"],
      VI1: ["стань", "to become, begin, come"],
      VI2: ["станьте", "to become, begin, come"],
      VPM: ["стал", "to become, begin, come"],
      VPF: ["стала", "to become, begin, come"],
      VPN: ["стало", "to become, begin, come"],
      VPP: ["стали", "to become, begin, come"],
    },
    есть: {
      VI: ["есть", "eat"],
      VN1: ["ем", "eat"],
      VF1: ["буду есть", "eat"],
      VN2: ["ешь", "eat"],
      VF2: ["будешь есть", "eat"],
      VN3: ["ест", "eat"],
      VF3: ["будет есть", "eat"],
      VNW: ["едим", "eat"],
      VFW: ["будем есть", "eat"],
      VNF: ["едите", "eat"],
      VFF: ["будете есть", "eat"],
      VNT: ["едят", "eat"],
      VFT: ["будут есть", "eat"],
      VI1: ["ешь", "eat"],
      VI2: ["ешьте", "eat"],
      VPM: ["ел", "eat"],
      VPF: ["ела", "eat"],
      VPN: ["ело", "eat"],
      VPP: ["ели", "eat"],
    },
    хотеть: {
      VI: ["хотеть", "to want, like"],
      VN1: ["хочу", "to want, like"],
      VF1: ["буду хотеть", "to want, like"],
      VN2: ["хочешь", "to want, like"],
      VF2: ["будешь хотеть", "to want, like"],
      VN3: ["хочет", "to want, like"],
      VF3: ["будет хотеть", "to want, like"],
      VNW: ["хотим", "to want, like"],
      VFW: ["будем хотеть", "to want, like"],
      VNF: ["хотите", "to want, like"],
      VFF: ["будете хотеть", "to want, like"],
      VNT: ["хотят", "to want, like"],
      VFT: ["будут хотеть", "to want, like"],
      VI1: ["хоти", "to want, like"],
      VI2: ["хотите", "to want, like"],
      VPM: ["хотел", "to want, like"],
      VPF: ["хотела", "to want, like"],
      VPN: ["хотело", "to want, like"],
      VPP: ["хотели", "to want, like"],
    },
    видеть: {
      VI: ["видеть", "to see"],
      VN1: ["вижу", "to see"],
      VF1: ["буду видеть", "to see"],
      VN2: ["видишь", "to see"],
      VF2: ["будешь видеть", "to see"],
      VN3: ["видит", "to see"],
      VF3: ["будет видеть", "to see"],
      VNW: ["видим", "to see"],
      VFW: ["будем видеть", "to see"],
      VNF: ["видите", "to see"],
      VFF: ["будете видеть", "to see"],
      VNT: ["видят", "to see"],
      VFT: ["будут видеть", "to see"],
      VI1: ["видь", "to see"],
      VI2: ["видьте", "to see"],
      VPM: ["видел", "to see"],
      VPF: ["видела", "to see"],
      VPN: ["видело", "to see"],
      VPP: ["видели", "to see"],
    },
    идти: {
      VI: ["идти", "to go, come"],
      VN1: ["иду", "to go, come"],
      VF1: ["буду идти", "to go, come"],
      VN2: ["идёшь", "to go, come"],
      VF2: ["будешь идти", "to go, come"],
      VN3: ["идёт", "to go, come"],
      VF3: ["будет идти", "to go, come"],
      VNW: ["идём", "to go, come"],
      VFW: ["будем идти", "to go, come"],
      VNF: ["идёте", "to go, come"],
      VFF: ["будете идти", "to go, come"],
      VNT: ["идут", "to go, come"],
      VFT: ["будут идти", "to go, come"],
      VI1: ["иди", "to go, come"],
      VI2: ["идите", "to go, come"],
      VPM: ["шёл", "to go, come"],
      VPF: ["шла", "to go, come"],
      VPN: ["шло", "to go, come"],
      VPP: ["шли", "to go, come"],
    },
    стоять: {
      VI: ["стоять", "to stand, be, stand up"],
      VN1: ["стою", "to stand, be, stand up"],
      VF1: ["буду стоять", "to stand, be, stand up"],
      VN2: ["стоишь", "to stand, be, stand up"],
      VF2: ["будешь стоять", "to stand, be, stand up"],
      VN3: ["стоит", "to stand, be, stand up"],
      VF3: ["будет стоять", "to stand, be, stand up"],
      VNW: ["стоим", "to stand, be, stand up"],
      VFW: ["будем стоять", "to stand, be, stand up"],
      VNF: ["стоите", "to stand, be, stand up"],
      VFF: ["будете стоять", "to stand, be, stand up"],
      VNT: ["стоят", "to stand, be, stand up"],
      VFT: ["будут стоять", "to stand, be, stand up"],
      VI1: ["стой", "to stand, be, stand up"],
      VI2: ["стойте", "to stand, be, stand up"],
      VPM: ["стоял", "to stand, be, stand up"],
      VPF: ["стояла", "to stand, be, stand up"],
      VPN: ["стояло", "to stand, be, stand up"],
      VPP: ["стояли", "to stand, be, stand up"],
    },
    думать: {
      VI: ["думать", "to think; believe"],
      VN1: ["думаю", "to think; believe"],
      VF1: ["буду думать", "to think; believe"],
      VN2: ["думаешь", "to think; believe"],
      VF2: ["будешь думать", "to think; believe"],
      VN3: ["думает", "to think; believe"],
      VF3: ["будет думать", "to think; believe"],
      VNW: ["думаем", "to think; believe"],
      VFW: ["будем думать", "to think; believe"],
      VNF: ["думаете", "to think; believe"],
      VFF: ["будете думать", "to think; believe"],
      VNT: ["думают", "to think; believe"],
      VFT: ["будут думать", "to think; believe"],
      VI1: ["думай", "to think; believe"],
      VI2: ["думайте", "to think; believe"],
      VPM: ["думал", "to think; believe"],
      VPF: ["думала", "to think; believe"],
      VPN: ["думало", "to think; believe"],
      VPP: ["думали", "to think; believe"],
    },
    спросить: {
      VI: ["спросить", "to ask"],
      VF1: ["спрошу", "to ask"],
      VF2: ["спросишь", "to ask"],
      VF3: ["спросит", "to ask"],
      VFW: ["спросим", "to ask"],
      VFF: ["спросите", "to ask"],
      VFT: ["спросят", "to ask"],
      VI1: ["спроси", "to ask"],
      VI2: ["спросите", "to ask"],
      VPM: ["спросил", "to ask"],
      VPF: ["спросила", "to ask"],
      VPN: ["спросило", "to ask"],
      VPP: ["спросили", "to ask"],
    },
    жить: {
      VI: ["жить", "to live"],
      VN1: ["живу", "to live"],
      VF1: ["буду жить", "to live"],
      VN2: ["живёшь", "to live"],
      VF2: ["будешь жить", "to live"],
      VN3: ["живёт", "to live"],
      VF3: ["будет жить", "to live"],
      VNW: ["живём", "to live"],
      VFW: ["будем жить", "to live"],
      VNF: ["живёте", "to live"],
      VFF: ["будете жить", "to live"],
      VNT: ["живут", "to live"],
      VFT: ["будут жить", "to live"],
      VI1: ["живи", "to live"],
      VI2: ["живите", "to live"],
      VPM: ["жил", "to live"],
      VPF: ["жила", "to live"],
      VPN: ["жило жило", "to live"],
      VPP: ["жили", "to live"],
    },
    смотреть: {
      VI: ["смотреть", "to look, watch"],
      VN1: ["смотрю", "to look, watch"],
      VF1: ["буду смотреть", "to look, watch"],
      VN2: ["смотришь", "to look, watch"],
      VF2: ["будешь смотреть", "to look, watch"],
      VN3: ["смотрит", "to look, watch"],
      VF3: ["будет смотреть", "to look, watch"],
      VNW: ["смотрим", "to look, watch"],
      VFW: ["будем смотреть", "to look, watch"],
      VNF: ["смотрите", "to look, watch"],
      VFF: ["будете смотреть", "to look, watch"],
      VNT: ["смотрят", "to look, watch"],
      VFT: ["будут смотреть", "to look, watch"],
      VI1: ["смотри", "to look, watch"],
      VI2: ["смотрите", "to look, watch"],
      VPM: ["смотрел", "to look, watch"],
      VPF: ["смотрела", "to look, watch"],
      VPN: ["смотрело", "to look, watch"],
      VPP: ["смотрели", "to look, watch"],
    },
    сидеть: {
      VI: ["сидеть", "to sit"],
      VN1: ["сижу", "to sit"],
      VF1: ["буду сидеть", "to sit"],
      VN2: ["сидишь", "to sit"],
      VF2: ["будешь сидеть", "to sit"],
      VN3: ["сидит", "to sit"],
      VF3: ["будет сидеть", "to sit"],
      VNW: ["сидим", "to sit"],
      VFW: ["будем сидеть", "to sit"],
      VNF: ["сидите", "to sit"],
      VFF: ["будете сидеть", "to sit"],
      VNT: ["сидят", "to sit"],
      VFT: ["будут сидеть", "to sit"],
      VI1: ["сиди", "to sit"],
      VI2: ["сидите", "to sit"],
      VPM: ["сидел", "to sit"],
      VPF: ["сидела", "to sit"],
      VPN: ["сидело", "to sit"],
      VPP: ["сидели", "to sit"],
    },
    понять: {
      VI: ["понять", "to understand; realize"],
      VF1: ["пойму", "to understand; realize"],
      VF2: ["поймёшь", "to understand; realize"],
      VF3: ["поймёт", "to understand; realize"],
      VFW: ["поймём", "to understand; realize"],
      VFF: ["поймёте", "to understand; realize"],
      VFT: ["поймут", "to understand; realize"],
      VI1: ["пойми", "to understand; realize"],
      VI2: ["поймите", "to understand; realize"],
      VPM: ["понял", "to understand; realize"],
      VPF: ["поняла", "to understand; realize"],
      VPN: ["поняло", "to understand; realize"],
      VPP: ["поняли", "to understand; realize"],
    },
    иметь: {
      VI: ["иметь", "to have, own"],
      VN1: ["имею", "to have, own"],
      VF1: ["буду иметь", "to have, own"],
      VN2: ["имеешь", "to have, own"],
      VF2: ["будешь иметь", "to have, own"],
      VN3: ["имеет", "to have, own"],
      VF3: ["будет иметь", "to have, own"],
      VNW: ["имеем", "to have, own"],
      VFW: ["будем иметь", "to have, own"],
      VNF: ["имеете", "to have, own"],
      VFF: ["будете иметь", "to have, own"],
      VNT: ["имеют", "to have, own"],
      VFT: ["будут иметь", "to have, own"],
      VI1: ["имей", "to have, own"],
      VI2: ["имейте", "to have, own"],
      VPM: ["имел", "to have, own"],
      VPF: ["имела", "to have, own"],
      VPN: ["имело", "to have, own"],
      VPP: ["имели", "to have, own"],
    },
    делать: {
      VI: ["делать", "to do, make"],
      VN1: ["делаю", "to do, make"],
      VF1: ["буду делать", "to do, make"],
      VN2: ["делаешь", "to do, make"],
      VF2: ["будешь делать", "to do, make"],
      VN3: ["делает", "to do, make"],
      VF3: ["будет делать", "to do, make"],
      VNW: ["делаем", "to do, make"],
      VFW: ["будем делать", "to do, make"],
      VNF: ["делаете", "to do, make"],
      VFF: ["будете делать", "to do, make"],
      VNT: ["делают", "to do, make"],
      VFT: ["будут делать", "to do, make"],
      VI1: ["делай", "to do, make"],
      VI2: ["делайте", "to do, make"],
      VPM: ["делал", "to do, make"],
      VPF: ["делала", "to do, make"],
      VPN: ["делало", "to do, make"],
      VPP: ["делали", "to do, make"],
    },
    взять: {
      VI: ["взять", "to take"],
      VF1: ["возьму", "to take"],
      VF2: ["возьмёшь", "to take"],
      VF3: ["возьмёт", "to take"],
      VFW: ["возьмём", "to take"],
      VFF: ["возьмёте", "to take"],
      VFT: ["возьмут", "to take"],
      VI1: ["возьми", "to take"],
      VI2: ["возьмите", "to take"],
      VPM: ["взял", "to take"],
      VPF: ["взяла", "to take"],
      VPN: ["взяло взяло", "to take"],
      VPP: ["взяли", "to take"],
    },
    сделать: {
      VI: ["сделать", "to do, make, finish"],
      VF1: ["сделаю", "to do, make, finish"],
      VF2: ["сделаешь", "to do, make, finish"],
      VF3: ["сделает", "to do, make, finish"],
      VFW: ["сделаем", "to do, make, finish"],
      VFF: ["сделаете", "to do, make, finish"],
      VFT: ["сделают", "to do, make, finish"],
      VI1: ["сделай", "to do, make, finish"],
      VI2: ["сделайте", "to do, make, finish"],
      VPM: ["сделал", "to do, make, finish"],
      VPF: ["сделала", "to do, make, finish"],
      VPN: ["сделало", "to do, make, finish"],
      VPP: ["сделали", "to do, make, finish"],
    },
    понимать: {
      VI: ["понимать", "to understand"],
      VN1: ["понимаю", "to understand"],
      VF1: ["буду понимать", "to understand"],
      VN2: ["понимаешь", "to understand"],
      VF2: ["будешь понимать", "to understand"],
      VN3: ["понимает", "to understand"],
      VF3: ["будет понимать", "to understand"],
      VNW: ["понимаем", "to understand"],
      VFW: ["будем понимать", "to understand"],
      VNF: ["понимаете", "to understand"],
      VFF: ["будете понимать", "to understand"],
      VNT: ["понимают", "to understand"],
      VFT: ["будут понимать", "to understand"],
      VI1: ["понимай", "to understand"],
      VI2: ["понимайте", "to understand"],
      VPM: ["понимал", "to understand"],
      VPF: ["понимала", "to understand"],
      VPN: ["понимало", "to understand"],
      VPP: ["понимали", "to understand"],
    },
    казаться: {
      VI: ["казаться", "to seem, appear; masterrussian, dot com"],
      VN1: ["кажусь", "to seem, appear; masterrussian, dot com"],
      VF1: ["буду казаться", "to seem, appear; masterrussian, dot com"],
      VN2: ["кажешься", "to seem, appear; masterrussian, dot com"],
      VF2: ["будешь казаться", "to seem, appear; masterrussian, dot com"],
      VN3: ["кажется", "to seem, appear; masterrussian, dot com"],
      VF3: ["будет казаться", "to seem, appear; masterrussian, dot com"],
      VNW: ["кажемся", "to seem, appear; masterrussian, dot com"],
      VFW: ["будем казаться", "to seem, appear; masterrussian, dot com"],
      VNF: ["кажетесь", "to seem, appear; masterrussian, dot com"],
      VFF: ["будете казаться", "to seem, appear; masterrussian, dot com"],
      VNT: ["кажутся", "to seem, appear; masterrussian, dot com"],
      VFT: ["будут казаться", "to seem, appear; masterrussian, dot com"],
      VI1: ["кажись", "to seem, appear; masterrussian, dot com"],
      VI2: ["кажитесь", "to seem, appear; masterrussian, dot com"],
      VPM: ["казался", "to seem, appear; masterrussian, dot com"],
      VPF: ["казалась", "to seem, appear; masterrussian, dot com"],
      VPN: ["казалось", "to seem, appear; masterrussian, dot com"],
      VPP: ["казались", "to seem, appear; masterrussian, dot com"],
    },
    давать: {
      VI: ["давать", "to give; let, allow"],
      VN1: ["даю", "to give; let, allow"],
      VF1: ["буду давать", "to give; let, allow"],
      VN2: ["даёшь", "to give; let, allow"],
      VF2: ["будешь давать", "to give; let, allow"],
      VN3: ["даёт", "to give; let, allow"],
      VF3: ["будет давать", "to give; let, allow"],
      VNW: ["даём", "to give; let, allow"],
      VFW: ["будем давать", "to give; let, allow"],
      VNF: ["даёте", "to give; let, allow"],
      VFF: ["будете давать", "to give; let, allow"],
      VNT: ["дают", "to give; let, allow"],
      VFT: ["будут давать", "to give; let, allow"],
      VI1: ["давай", "to give; let, allow"],
      VI2: ["давайте", "to give; let, allow"],
      VPM: ["давал", "to give; let, allow"],
      VPF: ["давала", "to give; let, allow"],
      VPN: ["давало", "to give; let, allow"],
      VPP: ["давали", "to give; let, allow"],
    },
    пойти: {
      VI: ["пойти", "to go"],
      VF1: ["пойду", "to go"],
      VF2: ["пойдёшь", "to go"],
      VF3: ["пойдёт", "to go"],
      VFW: ["пойдём", "to go"],
      VFF: ["пойдёте", "to go"],
      VFT: ["пойдут", "to go"],
      VI1: ["пойди", "to go"],
      VI2: ["пойдите", "to go"],
      VPM: ["пошёл", "to go"],
      VPF: ["пошла", "to go"],
      VPN: ["пошло", "to go"],
      VPP: ["пошли", "to go"],
    },
    увидеть: {
      VI: ["увидеть", "to see"],
      VF1: ["увижу", "to see"],
      VF2: ["увидишь", "to see"],
      VF3: ["увидит", "to see"],
      VFW: ["увидим", "to see"],
      VFF: ["увидите", "to see"],
      VFT: ["увидят", "to see"],
      VI1: ["увидь", "to see"],
      VI2: ["увидьте", "to see"],
      VPM: ["увидел", "to see"],
      VPF: ["увидела", "to see"],
      VPN: ["увидело", "to see"],
      VPP: ["увидели", "to see"],
    },
    остаться: {
      VI: ["остаться", "to remain, stay (see #297)"],
      VF1: ["останусь", "to remain, stay (see #297)"],
      VF2: ["останешься", "to remain, stay (see #297)"],
      VF3: ["останется", "to remain, stay (see #297)"],
      VFW: ["останемся", "to remain, stay (see #297)"],
      VFF: ["останетесь", "to remain, stay (see #297)"],
      VFT: ["останутся", "to remain, stay (see #297)"],
      VI1: ["останься", "to remain, stay (see #297)"],
      VI2: ["останьтесь", "to remain, stay (see #297)"],
      VPM: ["остался", "to remain, stay (see #297)"],
      VPF: ["осталась", "to remain, stay (see #297)"],
      VPN: ["осталось", "to remain, stay (see #297)"],
      VPP: ["остались", "to remain, stay (see #297)"],
    },
    выйти: {
      VI: ["выйти", "to go out, come out, appear"],
      VF1: ["выйду", "to go out, come out, appear"],
      VF2: ["выйдешь", "to go out, come out, appear"],
      VF3: ["выйдет", "to go out, come out, appear"],
      VFW: ["выйдем", "to go out, come out, appear"],
      VFF: ["выйдете", "to go out, come out, appear"],
      VFT: ["выйдут", "to go out, come out, appear"],
      VI1: ["выйди", "to go out, come out, appear"],
      VI2: ["выйдите", "to go out, come out, appear"],
      VPM: ["вышел", "to go out, come out, appear"],
      VPF: ["вышла", "to go out, come out, appear"],
      VPN: ["вышло", "to go out, come out, appear"],
      VPP: ["вышли", "to go out, come out, appear"],
    },
    дать: {
      VI: ["дать", "to give; masterrussian, dot com"],
      VF1: ["дам", "to give; masterrussian, dot com"],
      VF2: ["дашь", "to give; masterrussian, dot com"],
      VF3: ["даст", "to give; masterrussian, dot com"],
      VFW: ["дадим", "to give; masterrussian, dot com"],
      VFF: ["дадите", "to give; masterrussian, dot com"],
      VFT: ["дадут", "to give; masterrussian, dot com"],
      VI1: ["дай", "to give; masterrussian, dot com"],
      VI2: ["дайте", "to give; masterrussian, dot com"],
      VPM: ["дал", "to give; masterrussian, dot com"],
      VPF: ["дала", "to give; masterrussian, dot com"],
      VPN: ["дало дало", "to give; masterrussian, dot com"],
      VPP: ["дали", "to give; masterrussian, dot com"],
    },
    работать: {
      VI: ["работать", "to work"],
      VN1: ["работаю", "to work"],
      VF1: ["буду работать", "to work"],
      VN2: ["работаешь", "to work"],
      VF2: ["будешь работать", "to work"],
      VN3: ["работает", "to work"],
      VF3: ["будет работать", "to work"],
      VNW: ["работаем", "to work"],
      VFW: ["будем работать", "to work"],
      VNF: ["работаете", "to work"],
      VFF: ["будете работать", "to work"],
      VNT: ["работают", "to work"],
      VFT: ["будут работать", "to work"],
      VI1: ["работай", "to work"],
      VI2: ["работайте", "to work"],
      VPM: ["работал", "to work"],
      VPF: ["работала", "to work"],
      VPN: ["работало", "to work"],
      VPP: ["работали", "to work"],
    },
    любить: {
      VI: ["любить", "to love"],
      VN1: ["люблю", "to love"],
      VF1: ["буду любить", "to love"],
      VN2: ["любишь", "to love"],
      VF2: ["будешь любить", "to love"],
      VN3: ["любит", "to love"],
      VF3: ["будет любить", "to love"],
      VNW: ["любим", "to love"],
      VFW: ["будем любить", "to love"],
      VNF: ["любите", "to love"],
      VFF: ["будете любить", "to love"],
      VNT: ["любят", "to love"],
      VFT: ["будут любить", "to love"],
      VI1: ["люби", "to love"],
      VI2: ["любите", "to love"],
      VPM: ["любил", "to love"],
      VPF: ["любила", "to love"],
      VPN: ["любило", "to love"],
      VPP: ["любили", "to love"],
    },
    оказаться: {
      VI: ["оказаться", "find oneself, turn out"],
      VF1: ["окажусь", "find oneself, turn out"],
      VF2: ["окажешься", "find oneself, turn out"],
      VF3: ["окажется", "find oneself, turn out"],
      VFW: ["окажемся", "find oneself, turn out"],
      VFF: ["окажетесь", "find oneself, turn out"],
      VFT: ["окажутся", "find oneself, turn out"],
      VI1: ["окажись", "find oneself, turn out"],
      VI2: ["окажитесь", "find oneself, turn out"],
      VPM: ["оказался", "find oneself, turn out"],
      VPF: ["оказалась", "find oneself, turn out"],
      VPN: ["оказалось", "find oneself, turn out"],
      VPP: ["оказались", "find oneself, turn out"],
    },
    ответить: {
      VI: ["ответить", "to answer, reply (see #404)"],
      VF1: ["отвечу", "to answer, reply (see #404)"],
      VF2: ["ответишь", "to answer, reply (see #404)"],
      VF3: ["ответит", "to answer, reply (see #404)"],
      VFW: ["ответим", "to answer, reply (see #404)"],
      VFF: ["ответите", "to answer, reply (see #404)"],
      VFT: ["ответят", "to answer, reply (see #404)"],
      VI1: ["ответь", "to answer, reply (see #404)"],
      VI2: ["ответьте", "to answer, reply (see #404)"],
      VPM: ["ответил", "to answer, reply (see #404)"],
      VPF: ["ответила", "to answer, reply (see #404)"],
      VPN: ["ответило", "to answer, reply (see #404)"],
      VPP: ["ответили", "to answer, reply (see #404)"],
    },
    подумать: {
      VI: ["подумать", "to think"],
      VF1: ["подумаю", "to think"],
      VF2: ["подумаешь", "to think"],
      VF3: ["подумает", "to think"],
      VFW: ["подумаем", "to think"],
      VFF: ["подумаете", "to think"],
      VFT: ["подумают", "to think"],
      VI1: ["подумай", "to think"],
      VI2: ["подумайте", "to think"],
      VPM: ["подумал", "to think"],
      VPF: ["подумала", "to think"],
      VPN: ["подумало", "to think"],
      VPP: ["подумали", "to think"],
    },
    значить: {
      VI: ["значить", "to mean, signify"],
      VN1: ["значу", "to mean, signify"],
      VF1: ["буду значить", "to mean, signify"],
      VN2: ["значишь", "to mean, signify"],
      VF2: ["будешь значить", "to mean, signify"],
      VN3: ["значит", "to mean, signify"],
      VF3: ["будет значить", "to mean, signify"],
      VNW: ["значим", "to mean, signify"],
      VFW: ["будем значить", "to mean, signify"],
      VNF: ["значите", "to mean, signify"],
      VFF: ["будете значить", "to mean, signify"],
      VNT: ["значат", "to mean, signify"],
      VFT: ["будут значить", "to mean, signify"],
      VI1: ["значь", "to mean, signify"],
      VI2: ["значьте", "to mean, signify"],
      VPM: ["значил", "to mean, signify"],
      VPF: ["значила", "to mean, signify"],
      VPN: ["значило", "to mean, signify"],
      VPP: ["значили", "to mean, signify"],
    },
    посмотреть: {
      VI: ["посмотреть", "to take a look, watch, inspect"],
      VF1: ["посмотрю", "to take a look, watch, inspect"],
      VF2: ["посмотришь", "to take a look, watch, inspect"],
      VF3: ["посмотрит", "to take a look, watch, inspect"],
      VFW: ["посмотрим", "to take a look, watch, inspect"],
      VFF: ["посмотрите", "to take a look, watch, inspect"],
      VFT: ["посмотрят", "to take a look, watch, inspect"],
      VI1: ["посмотри", "to take a look, watch, inspect"],
      VI2: ["посмотрите", "to take a look, watch, inspect"],
      VPM: ["посмотрел", "to take a look, watch, inspect"],
      VPF: ["посмотрела", "to take a look, watch, inspect"],
      VPN: ["посмотрело", "to take a look, watch, inspect"],
      VPP: ["посмотрели", "to take a look, watch, inspect"],
    },
    ждать: {
      VI: ["ждать", "to wait"],
      VN1: ["жду", "to wait"],
      VF1: ["буду ждать", "to wait"],
      VN2: ["ждёшь", "to wait"],
      VF2: ["будешь ждать", "to wait"],
      VN3: ["ждёт", "to wait"],
      VF3: ["будет ждать", "to wait"],
      VNW: ["ждём", "to wait"],
      VFW: ["будем ждать", "to wait"],
      VNF: ["ждёте", "to wait"],
      VFF: ["будете ждать", "to wait"],
      VNT: ["ждут", "to wait"],
      VFT: ["будут ждать", "to wait"],
      VI1: ["жди", "to wait"],
      VI2: ["ждите", "to wait"],
      VPM: ["ждал", "to wait"],
      VPF: ["ждала", "to wait"],
      VPN: ["ждало", "to wait"],
      VPP: ["ждали", "to wait"],
    },
    лежать: {
      VI: ["лежать", "to lie, be situated"],
      VN1: ["лежу", "to lie, be situated"],
      VF1: ["буду лежать", "to lie, be situated"],
      VN2: ["лежишь", "to lie, be situated"],
      VF2: ["будешь лежать", "to lie, be situated"],
      VN3: ["лежит", "to lie, be situated"],
      VF3: ["будет лежать", "to lie, be situated"],
      VNW: ["лежим", "to lie, be situated"],
      VFW: ["будем лежать", "to lie, be situated"],
      VNF: ["лежите", "to lie, be situated"],
      VFF: ["будете лежать", "to lie, be situated"],
      VNT: ["лежат", "to lie, be situated"],
      VFT: ["будут лежать", "to lie, be situated"],
      VI1: ["лежи", "to lie, be situated"],
      VI2: ["лежите", "to lie, be situated"],
      VPM: ["лежал", "to lie, be situated"],
      VPF: ["лежала", "to lie, be situated"],
      VPN: ["лежало", "to lie, be situated"],
      VPP: ["лежали", "to lie, be situated"],
    },
    найти: {
      VI: ["найти", "to find, discover, consider"],
      VF1: ["найду", "to find, discover, consider"],
      VF2: ["найдёшь", "to find, discover, consider"],
      VF3: ["найдёт", "to find, discover, consider"],
      VFW: ["найдём", "to find, discover, consider"],
      VFF: ["найдёте", "to find, discover, consider"],
      VFT: ["найдут", "to find, discover, consider"],
      VI1: ["найди", "to find, discover, consider"],
      VI2: ["найдите", "to find, discover, consider"],
      VPM: ["нашёл", "to find, discover, consider"],
      VPF: ["нашла", "to find, discover, consider"],
      VPN: ["нашло", "to find, discover, consider"],
      VPP: ["нашли", "to find, discover, consider"],
    },
    писать: {
      VI: ["писать", "pee"],
      VN1: ["писаю", "pee"],
      VF1: ["буду писать", "pee"],
      VN2: ["писаешь", "pee"],
      VF2: ["будешь писать", "pee"],
      VN3: ["писает", "pee"],
      VF3: ["будет писать", "pee"],
      VNW: ["писаем", "pee"],
      VFW: ["будем писать", "pee"],
      VNF: ["писаете", "pee"],
      VFF: ["будете писать", "pee"],
      VNT: ["писают", "pee"],
      VFT: ["будут писать", "pee"],
      VI1: ["писай", "pee"],
      VI2: ["писайте", "pee"],
      VPM: ["писал", "pee"],
      VPF: ["писала", "pee"],
      VPN: ["писало", "pee"],
      VPP: ["писали", "pee"],
    },
    решить: {
      VI: ["решить", "to decide, solve"],
      VF1: ["решу", "to decide, solve"],
      VF2: ["решишь", "to decide, solve"],
      VF3: ["решит", "to decide, solve"],
      VFW: ["решим", "to decide, solve"],
      VFF: ["решите", "to decide, solve"],
      VFT: ["решат", "to decide, solve"],
      VI1: ["реши", "to decide, solve"],
      VI2: ["решите", "to decide, solve"],
      VPM: ["решил", "to decide, solve"],
      VPF: ["решила", "to decide, solve"],
      VPN: ["решило", "to decide, solve"],
      VPP: ["решили", "to decide, solve"],
    },
    вернуться: {
      VI: ["вернуться", "to return"],
      VF1: ["вернусь", "to return"],
      VF2: ["вернёшься", "to return"],
      VF3: ["вернётся", "to return"],
      VFW: ["вернёмся", "to return"],
      VFF: ["вернётесь", "to return"],
      VFT: ["вернутся", "to return"],
      VI1: ["вернись", "to return"],
      VI2: ["вернитесь", "to return"],
      VPM: ["вернулся", "to return"],
      VPF: ["вернулась", "to return"],
      VPN: ["вернулось", "to return"],
      VPP: ["вернулись", "to return"],
    },
    считать: {
      VI: ["считать", "to count, consider"],
      VN1: ["считаю", "to count, consider"],
      VF1: ["буду считать", "to count, consider"],
      VN2: ["считаешь", "to count, consider"],
      VF2: ["будешь считать", "to count, consider"],
      VN3: ["считает", "to count, consider"],
      VF3: ["будет считать", "to count, consider"],
      VNW: ["считаем", "to count, consider"],
      VFW: ["будем считать", "to count, consider"],
      VNF: ["считаете", "to count, consider"],
      VFF: ["будете считать", "to count, consider"],
      VNT: ["считают", "to count, consider"],
      VFT: ["будут считать", "to count, consider"],
      VI1: ["считай", "to count, consider"],
      VI2: ["считайте", "to count, consider"],
      VPM: ["считал", "to count, consider"],
      VPF: ["считала", "to count, consider"],
      VPN: ["считало", "to count, consider"],
      VPP: ["считали", "to count, consider"],
    },
    помнить: {
      VI: ["помнить", "to remember"],
      VN1: ["помню", "to remember"],
      VF1: ["буду помнить", "to remember"],
      VN2: ["помнишь", "to remember"],
      VF2: ["будешь помнить", "to remember"],
      VN3: ["помнит", "to remember"],
      VF3: ["будет помнить", "to remember"],
      VNW: ["помним", "to remember"],
      VFW: ["будем помнить", "to remember"],
      VNF: ["помните", "to remember"],
      VFF: ["будете помнить", "to remember"],
      VNT: ["помнят", "to remember"],
      VFT: ["будут помнить", "to remember"],
      VI1: ["помни", "to remember"],
      VI2: ["помните", "to remember"],
      VPM: ["помнил", "to remember"],
      VPF: ["помнила", "to remember"],
      VPN: ["помнило", "to remember"],
      VPP: ["помнили", "to remember"],
    },
    получить: {
      VI: ["получить", "to receive, get, obtain"],
      VF1: ["получу", "to receive, get, obtain"],
      VF2: ["получишь", "to receive, get, obtain"],
      VF3: ["получит", "to receive, get, obtain"],
      VFW: ["получим", "to receive, get, obtain"],
      VFF: ["получите", "to receive, get, obtain"],
      VFT: ["получат", "to receive, get, obtain"],
      VI1: ["получи", "to receive, get, obtain"],
      VI2: ["получите", "to receive, get, obtain"],
      VPM: ["получил", "to receive, get, obtain"],
      VPF: ["получила", "to receive, get, obtain"],
      VPN: ["получило", "to receive, get, obtain"],
      VPP: ["получили", "to receive, get, obtain"],
    },
    ходить: {
      VI: ["ходить", "to go, walk"],
      VN1: ["хожу", "to go, walk"],
      VF1: ["буду ходить", "to go, walk"],
      VN2: ["ходишь", "to go, walk"],
      VF2: ["будешь ходить", "to go, walk"],
      VN3: ["ходит", "to go, walk"],
      VF3: ["будет ходить", "to go, walk"],
      VNW: ["ходим", "to go, walk"],
      VFW: ["будем ходить", "to go, walk"],
      VNF: ["ходите", "to go, walk"],
      VFF: ["будете ходить", "to go, walk"],
      VNT: ["ходят", "to go, walk"],
      VFT: ["будут ходить", "to go, walk"],
      VI1: ["ходи", "to go, walk"],
      VI2: ["ходите", "to go, walk"],
      VPM: ["ходил", "to go, walk"],
      VPF: ["ходила", "to go, walk"],
      VPN: ["ходило", "to go, walk"],
      VPP: ["ходили", "to go, walk"],
    },
    бывать: {
      VI: ["бывать", "be, visit, happen"],
      VN1: ["бываю", "be, visit, happen"],
      VF1: ["буду бывать", "be, visit, happen"],
      VN2: ["бываешь", "be, visit, happen"],
      VF2: ["будешь бывать", "be, visit, happen"],
      VN3: ["бывает", "be, visit, happen"],
      VF3: ["будет бывать", "be, visit, happen"],
      VNW: ["бываем", "be, visit, happen"],
      VFW: ["будем бывать", "be, visit, happen"],
      VNF: ["бываете", "be, visit, happen"],
      VFF: ["будете бывать", "be, visit, happen"],
      VNT: ["бывают", "be, visit, happen"],
      VFT: ["будут бывать", "be, visit, happen"],
      VI1: ["бывай", "be, visit, happen"],
      VI2: ["бывайте", "be, visit, happen"],
      VPM: ["бывал", "be, visit, happen"],
      VPF: ["бывала", "be, visit, happen"],
      VPN: ["бывало", "be, visit, happen"],
      VPP: ["бывали", "be, visit, happen"],
    },
    прийти: {
      VI: ["прийти", "to come, arrive (see #464)"],
      VF1: ["приду", "to come, arrive (see #464)"],
      VF2: ["придёшь", "to come, arrive (see #464)"],
      VF3: ["придёт", "to come, arrive (see #464)"],
      VFW: ["придём", "to come, arrive (see #464)"],
      VFF: ["придёте", "to come, arrive (see #464)"],
      VFT: ["придут", "to come, arrive (see #464)"],
      VI1: ["приди", "to come, arrive (see #464)"],
      VI2: ["придите", "to come, arrive (see #464)"],
      VPM: ["пришёл", "to come, arrive (see #464)"],
      VPF: ["пришла", "to come, arrive (see #464)"],
      VPN: ["пришло", "to come, arrive (see #464)"],
      VPP: ["пришли", "to come, arrive (see #464)"],
    },
    узнать: {
      VI: ["узнать", "to know, learn, recognize"],
      VF1: ["узнаю", "to know, learn, recognize"],
      VF2: ["узнаешь", "to know, learn, recognize"],
      VF3: ["узнает", "to know, learn, recognize"],
      VFW: ["узнаем", "to know, learn, recognize"],
      VFF: ["узнаете", "to know, learn, recognize"],
      VFT: ["узнают", "to know, learn, recognize"],
      VI1: ["узнай", "to know, learn, recognize"],
      VI2: ["узнайте", "to know, learn, recognize"],
      VPM: ["узнал", "to know, learn, recognize"],
      VPF: ["узнала", "to know, learn, recognize"],
      VPN: ["узнало", "to know, learn, recognize"],
      VPP: ["узнали", "to know, learn, recognize"],
    },
    заметить: {
      VI: ["заметить", "to notice, observe"],
      VF1: ["замечу", "to notice, observe"],
      VF2: ["заметишь", "to notice, observe"],
      VF3: ["заметит", "to notice, observe"],
      VFW: ["заметим", "to notice, observe"],
      VFF: ["заметите", "to notice, observe"],
      VFT: ["заметят", "to notice, observe"],
      VI1: ["заметь", "to notice, observe"],
      VI2: ["заметьте", "to notice, observe"],
      VPM: ["заметил", "to notice, observe"],
      VPF: ["заметила", "to notice, observe"],
      VPN: ["заметило", "to notice, observe"],
      VPP: ["заметили", "to notice, observe"],
    },
    уйти: {
      VI: ["уйти", "to leave, go away"],
      VF1: ["уйду", "to leave, go away"],
      VF2: ["уйдёшь", "to leave, go away"],
      VF3: ["уйдёт", "to leave, go away"],
      VFW: ["уйдём", "to leave, go away"],
      VFF: ["уйдёте", "to leave, go away"],
      VFT: ["уйдут", "to leave, go away"],
      VI1: ["уйди", "to leave, go away"],
      VI2: ["уйдите", "to leave, go away"],
      VPM: ["ушёл", "to leave, go away"],
      VPF: ["ушла", "to leave, go away"],
      VPN: ["ушло", "to leave, go away"],
      VPP: ["ушли", "to leave, go away"],
    },
    слышать: {
      VI: ["слышать", "to hear"],
      VN1: ["слышу", "to hear"],
      VF1: ["буду слышать", "to hear"],
      VN2: ["слышишь", "to hear"],
      VF2: ["будешь слышать", "to hear"],
      VN3: ["слышит", "to hear"],
      VF3: ["будет слышать", "to hear"],
      VNW: ["слышим", "to hear"],
      VFW: ["будем слышать", "to hear"],
      VNF: ["слышите", "to hear"],
      VFF: ["будете слышать", "to hear"],
      VNT: ["слышат", "to hear"],
      VFT: ["будут слышать", "to hear"],
      VI1: ["слышь", "to hear"],
      VI2: ["слышьте", "to hear"],
      VPM: ["слышал", "to hear"],
      VPF: ["слышала", "to hear"],
      VPN: ["слышало", "to hear"],
      VPP: ["слышали", "to hear"],
    },
    слушать: {
      VI: ["слушать", "to listen, hear"],
      VN1: ["слушаю", "to listen, hear"],
      VF1: ["буду слушать", "to listen, hear"],
      VN2: ["слушаешь", "to listen, hear"],
      VF2: ["будешь слушать", "to listen, hear"],
      VN3: ["слушает", "to listen, hear"],
      VF3: ["будет слушать", "to listen, hear"],
      VNW: ["слушаем", "to listen, hear"],
      VFW: ["будем слушать", "to listen, hear"],
      VNF: ["слушаете", "to listen, hear"],
      VFF: ["будете слушать", "to listen, hear"],
      VNT: ["слушают", "to listen, hear"],
      VFT: ["будут слушать", "to listen, hear"],
      VI1: ["слушай", "to listen, hear"],
      VI2: ["слушайте", "to listen, hear"],
      VPM: ["слушал", "to listen, hear"],
      VPF: ["слушала", "to listen, hear"],
      VPN: ["слушало", "to listen, hear"],
      VPP: ["слушали", "to listen, hear"],
    },
    бояться: {
      VI: ["бояться", "to be afraid, fear"],
      VN1: ["боюсь", "to be afraid, fear"],
      VF1: ["буду бояться", "to be afraid, fear"],
      VN2: ["боишься", "to be afraid, fear"],
      VF2: ["будешь бояться", "to be afraid, fear"],
      VN3: ["боится", "to be afraid, fear"],
      VF3: ["будет бояться", "to be afraid, fear"],
      VNW: ["боимся", "to be afraid, fear"],
      VFW: ["будем бояться", "to be afraid, fear"],
      VNF: ["боитесь", "to be afraid, fear"],
      VFF: ["будете бояться", "to be afraid, fear"],
      VNT: ["боятся", "to be afraid, fear"],
      VFT: ["будут бояться", "to be afraid, fear"],
      VI1: ["бойся", "to be afraid, fear"],
      VI2: ["бойтесь", "to be afraid, fear"],
      VPM: ["боялся", "to be afraid, fear"],
      VPF: ["боялась", "to be afraid, fear"],
      VPN: ["боялось", "to be afraid, fear"],
      VPP: ["боялись", "to be afraid, fear"],
    },
    глядеть: {
      VI: ["глядеть", "to look, gaze"],
      VN1: ["гляжу", "to look, gaze"],
      VF1: ["буду глядеть", "to look, gaze"],
      VN2: ["глядишь", "to look, gaze"],
      VF2: ["будешь глядеть", "to look, gaze"],
      VN3: ["глядит", "to look, gaze"],
      VF3: ["будет глядеть", "to look, gaze"],
      VNW: ["глядим", "to look, gaze"],
      VFW: ["будем глядеть", "to look, gaze"],
      VNF: ["глядите", "to look, gaze"],
      VFF: ["будете глядеть", "to look, gaze"],
      VNT: ["глядят", "to look, gaze"],
      VFT: ["будут глядеть", "to look, gaze"],
      VI1: ["гляди", "to look, gaze"],
      VI2: ["глядите", "to look, gaze"],
      VPM: ["глядел", "to look, gaze"],
      VPF: ["глядела", "to look, gaze"],
      VPN: ["глядело", "to look, gaze"],
      VPP: ["глядели", "to look, gaze"],
    },
    сесть: {
      VI: ["сесть", "to sit"],
      VF1: ["сяду", "to sit"],
      VF2: ["сядешь", "to sit"],
      VF3: ["сядет", "to sit"],
      VFW: ["сядем", "to sit"],
      VFF: ["сядете", "to sit"],
      VFT: ["сядут", "to sit"],
      VI1: ["сядь", "to sit"],
      VI2: ["сядьте", "to sit"],
      VPM: ["сел", "to sit"],
      VPF: ["села", "to sit"],
      VPN: ["село", "to sit"],
      VPP: ["сели", "to sit"],
    },
    читать: {
      VI: ["читать", "to read"],
      VN1: ["читаю", "to read"],
      VF1: ["буду читать", "to read"],
      VN2: ["читаешь", "to read"],
      VF2: ["будешь читать", "to read"],
      VN3: ["читает", "to read"],
      VF3: ["будет читать", "to read"],
      VNW: ["читаем", "to read"],
      VFW: ["будем читать", "to read"],
      VNF: ["читаете", "to read"],
      VFF: ["будете читать", "to read"],
      VNT: ["читают", "to read"],
      VFT: ["будут читать", "to read"],
      VI1: ["читай", "to read"],
      VI2: ["читайте", "to read"],
      VPM: ["читал", "to read"],
      VPF: ["читала", "to read"],
      VPN: ["читало", "to read"],
      VPP: ["читали", "to read"],
    },
    оставаться: {
      VI: ["оставаться", "to remain, stay (see #173)"],
      VN1: ["остаюсь", "to remain, stay (see #173)"],
      VF1: ["буду оставаться", "to remain, stay (see #173)"],
      VN2: ["остаёшься", "to remain, stay (see #173)"],
      VF2: ["будешь оставаться", "to remain, stay (see #173)"],
      VN3: ["остаётся", "to remain, stay (see #173)"],
      VF3: ["будет оставаться", "to remain, stay (see #173)"],
      VNW: ["остаёмся", "to remain, stay (see #173)"],
      VFW: ["будем оставаться", "to remain, stay (see #173)"],
      VNF: ["остаётесь", "to remain, stay (see #173)"],
      VFF: ["будете оставаться", "to remain, stay (see #173)"],
      VNT: ["остаются", "to remain, stay (see #173)"],
      VFT: ["будут оставаться", "to remain, stay (see #173)"],
      VI1: ["оставайся", "to remain, stay (see #173)"],
      VI2: ["оставайтесь", "to remain, stay (see #173)"],
      VPM: ["оставался", "to remain, stay (see #173)"],
      VPF: ["оставалась", "to remain, stay (see #173)"],
      VPN: ["оставалось", "to remain, stay (see #173)"],
      VPP: ["оставались", "to remain, stay (see #173)"],
    },
    брать: {
      VI: ["брать", "to take; hire"],
      VN1: ["беру", "to take; hire"],
      VF1: ["буду брать", "to take; hire"],
      VN2: ["берёшь", "to take; hire"],
      VF2: ["будешь брать", "to take; hire"],
      VN3: ["берёт", "to take; hire"],
      VF3: ["будет брать", "to take; hire"],
      VNW: ["берём", "to take; hire"],
      VFW: ["будем брать", "to take; hire"],
      VNF: ["берёте", "to take; hire"],
      VFF: ["будете брать", "to take; hire"],
      VNT: ["берут", "to take; hire"],
      VFT: ["будут брать", "to take; hire"],
      VI1: ["бери", "to take; hire"],
      VI2: ["берите", "to take; hire"],
      VPM: ["брал", "to take; hire"],
      VPF: ["брала", "to take; hire"],
      VPN: ["брало", "to take; hire"],
      VPP: ["брали", "to take; hire"],
    },
    написать: {
      VI: ["написать", "to write"],
      VF1: ["напишу", "to write"],
      VF2: ["напишешь", "to write"],
      VF3: ["напишет", "to write"],
      VFW: ["напишем", "to write"],
      VFF: ["напишете", "to write"],
      VFT: ["напишут", "to write"],
      VI1: ["напиши", "to write"],
      VI2: ["напишите", "to write"],
      VPM: ["написал", "to write"],
      VPF: ["написала", "to write"],
      VPN: ["написало", "to write"],
      VPP: ["написали", "to write"],
    },
    начинать: {
      VI: ["начинать", "to begin (see #413)"],
      VN1: ["начинаю", "to begin (see #413)"],
      VF1: ["буду начинать", "to begin (see #413)"],
      VN2: ["начинаешь", "to begin (see #413)"],
      VF2: ["будешь начинать", "to begin (see #413)"],
      VN3: ["начинает", "to begin (see #413)"],
      VF3: ["будет начинать", "to begin (see #413)"],
      VNW: ["начинаем", "to begin (see #413)"],
      VFW: ["будем начинать", "to begin (see #413)"],
      VNF: ["начинаете", "to begin (see #413)"],
      VFF: ["будете начинать", "to begin (see #413)"],
      VNT: ["начинают", "to begin (see #413)"],
      VFT: ["будут начинать", "to begin (see #413)"],
      VI1: ["начинай", "to begin (see #413)"],
      VI2: ["начинайте", "to begin (see #413)"],
      VPM: ["начинал", "to begin (see #413)"],
      VPF: ["начинала", "to begin (see #413)"],
      VPN: ["начинало", "to begin (see #413)"],
      VPP: ["начинали", "to begin (see #413)"],
    },
    чувствовать: {
      VI: ["чувствовать", "to feel"],
      VN1: ["чувствую", "to feel"],
      VF1: ["буду чувствовать", "to feel"],
      VN2: ["чувствуешь", "to feel"],
      VF2: ["будешь чувствовать", "to feel"],
      VN3: ["чувствует", "to feel"],
      VF3: ["будет чувствовать", "to feel"],
      VNW: ["чувствуем", "to feel"],
      VFW: ["будем чувствовать", "to feel"],
      VNF: ["чувствуете", "to feel"],
      VFF: ["будете чувствовать", "to feel"],
      VNT: ["чувствуют", "to feel"],
      VFT: ["будут чувствовать", "to feel"],
      VI1: ["чувствуй", "to feel"],
      VI2: ["чувствуйте", "to feel"],
      VPM: ["чувствовал", "to feel"],
      VPF: ["чувствовала", "to feel"],
      VPN: ["чувствовало", "to feel"],
      VPP: ["чувствовали", "to feel"],
    },
    вести: {
      VI: ["вести", "to lead"],
      VN1: ["веду", "to lead"],
      VF1: ["буду вести", "to lead"],
      VN2: ["ведёшь", "to lead"],
      VF2: ["будешь вести", "to lead"],
      VN3: ["ведёт", "to lead"],
      VF3: ["будет вести", "to lead"],
      VNW: ["ведём", "to lead"],
      VFW: ["будем вести", "to lead"],
      VNF: ["ведёте", "to lead"],
      VFF: ["будете вести", "to lead"],
      VNT: ["ведут", "to lead"],
      VFT: ["будут вести", "to lead"],
      VI1: ["веди", "to lead"],
      VI2: ["ведите", "to lead"],
      VPM: ["вёл", "to lead"],
      VPF: ["вела", "to lead"],
      VPN: ["вело", "to lead"],
      VPP: ["вели", "to lead"],
    },
    успеть: {
      VI: ["успеть", "to be in time, be successful"],
      VF1: ["успею", "to be in time, be successful"],
      VF2: ["успеешь", "to be in time, be successful"],
      VF3: ["успеет", "to be in time, be successful"],
      VFW: ["успеем", "to be in time, be successful"],
      VFF: ["успеете", "to be in time, be successful"],
      VFT: ["успеют", "to be in time, be successful"],
      VI1: ["успей", "to be in time, be successful"],
      VI2: ["успейте", "to be in time, be successful"],
      VPM: ["успел", "to be in time, be successful"],
      VPF: ["успела", "to be in time, be successful"],
      VPN: ["успело", "to be in time, be successful"],
      VPP: ["успели", "to be in time, be successful"],
    },
    продолжать: {
      VI: ["продолжать", "to continue"],
      VN1: ["продолжаю", "to continue"],
      VF1: ["буду продолжать", "to continue"],
      VN2: ["продолжаешь", "to continue"],
      VF2: ["будешь продолжать", "to continue"],
      VN3: ["продолжает", "to continue"],
      VF3: ["будет продолжать", "to continue"],
      VNW: ["продолжаем", "to continue"],
      VFW: ["будем продолжать", "to continue"],
      VNF: ["продолжаете", "to continue"],
      VFF: ["будете продолжать", "to continue"],
      VNT: ["продолжают", "to continue"],
      VFT: ["будут продолжать", "to continue"],
      VI1: ["продолжай", "to continue"],
      VI2: ["продолжайте", "to continue"],
      VPM: ["продолжал", "to continue"],
      VPF: ["продолжала", "to continue"],
      VPN: ["продолжало", "to continue"],
      VPP: ["продолжали", "to continue"],
    },
    забыть: {
      VI: ["забыть", "to forget"],
      VF1: ["забуду", "to forget"],
      VF2: ["забудешь", "to forget"],
      VF3: ["забудет", "to forget"],
      VFW: ["забудем", "to forget"],
      VFF: ["забудете", "to forget"],
      VFT: ["забудут", "to forget"],
      VI1: ["забудь", "to forget"],
      VI2: ["забудьте", "to forget"],
      VPM: ["забыл", "to forget"],
      VPF: ["забыла", "to forget"],
      VPN: ["забыло", "to forget"],
      VPP: ["забыли", "to forget"],
    },
    пройти: {
      VI: ["пройти", "to pass, go by, be over (see #543)"],
      VF1: ["пройду", "to pass, go by, be over (see #543)"],
      VF2: ["пройдёшь", "to pass, go by, be over (see #543)"],
      VF3: ["пройдёт", "to pass, go by, be over (see #543)"],
      VFW: ["пройдём", "to pass, go by, be over (see #543)"],
      VFF: ["пройдёте", "to pass, go by, be over (see #543)"],
      VFT: ["пройдут", "to pass, go by, be over (see #543)"],
      VI1: ["пройди", "to pass, go by, be over (see #543)"],
      VI2: ["пройдите", "to pass, go by, be over (see #543)"],
      VPM: ["прошёл", "to pass, go by, be over (see #543)"],
      VPF: ["прошла", "to pass, go by, be over (see #543)"],
      VPN: ["прошло", "to pass, go by, be over (see #543)"],
      VPP: ["прошли", "to pass, go by, be over (see #543)"],
    },
    появиться: {
      VI: ["появиться", "to appear, show up (see #699)"],
      VF1: ["появлюсь", "to appear, show up (see #699)"],
      VF2: ["появишься", "to appear, show up (see #699)"],
      VF3: ["появится", "to appear, show up (see #699)"],
      VFW: ["появимся", "to appear, show up (see #699)"],
      VFF: ["появитесь", "to appear, show up (see #699)"],
      VFT: ["появятся", "to appear, show up (see #699)"],
      VI1: ["появись", "to appear, show up (see #699)"],
      VI2: ["появитесь", "to appear, show up (see #699)"],
      VPM: ["появился", "to appear, show up (see #699)"],
      VPF: ["появилась", "to appear, show up (see #699)"],
      VPN: ["появилось", "to appear, show up (see #699)"],
      VPP: ["появились", "to appear, show up (see #699)"],
    },
    выходить: {
      VI: ["выходить", "rear"],
      VN1: ["выхожу", "to go out; nurse (see #176)"],
      VF1: ["выхожу", "rear"],
      VN2: ["выходишь", "to go out; nurse (see #176)"],
      VF2: ["выходишь", "rear"],
      VN3: ["выходит", "to go out; nurse (see #176)"],
      VF3: ["выходит", "rear"],
      VNW: ["выходим", "to go out; nurse (see #176)"],
      VFW: ["выходим", "rear"],
      VNF: ["выходите", "to go out; nurse (see #176)"],
      VFF: ["выходите", "rear"],
      VNT: ["выходят", "to go out; nurse (see #176)"],
      VFT: ["выходят", "rear"],
      VI1: ["выходи", "rear"],
      VI2: ["выходите", "rear"],
      VPM: ["выходил", "rear"],
      VPF: ["выходила", "rear"],
      VPN: ["выходило", "rear"],
      VPP: ["выходили", "rear"],
    },
    просить: {
      VI: ["просить", "to ask"],
      VN1: ["прошу", "to ask"],
      VF1: ["буду просить", "to ask"],
      VN2: ["просишь", "to ask"],
      VF2: ["будешь просить", "to ask"],
      VN3: ["просит", "to ask"],
      VF3: ["будет просить", "to ask"],
      VNW: ["просим", "to ask"],
      VFW: ["будем просить", "to ask"],
      VNF: ["просите", "to ask"],
      VFF: ["будете просить", "to ask"],
      VNT: ["просят", "to ask"],
      VFT: ["будут просить", "to ask"],
      VI1: ["проси", "to ask"],
      VI2: ["просите", "to ask"],
      VPM: ["просил", "to ask"],
      VPF: ["просила", "to ask"],
      VPN: ["просило", "to ask"],
      VPP: ["просили", "to ask"],
    },
    пытаться: {
      VI: ["пытаться", "to try"],
      VN1: ["пытаюсь", "to try"],
      VF1: ["буду пытаться", "to try"],
      VN2: ["пытаешься", "to try"],
      VF2: ["будешь пытаться", "to try"],
      VN3: ["пытается", "to try"],
      VF3: ["будет пытаться", "to try"],
      VNW: ["пытаемся", "to try"],
      VFW: ["будем пытаться", "to try"],
      VNF: ["пытаетесь", "to try"],
      VFF: ["будете пытаться", "to try"],
      VNT: ["пытаются", "to try"],
      VFT: ["будут пытаться", "to try"],
      VI1: ["пытайся", "to try"],
      VI2: ["пытайтесь", "to try"],
      VPM: ["пытался", "to try"],
      VPF: ["пыталась", "to try"],
      VPN: ["пыталось", "to try"],
      VPP: ["пытались", "to try"],
    },
    показать: {
      VI: ["показать", "to show, display"],
      VF1: ["покажу", "to show, display"],
      VF2: ["покажешь", "to show, display"],
      VF3: ["покажет", "to show, display"],
      VFW: ["покажем", "to show, display"],
      VFF: ["покажете", "to show, display"],
      VFT: ["покажут", "to show, display"],
      VI1: ["покажи", "to show, display"],
      VI2: ["покажите", "to show, display"],
      VPM: ["показал", "to show, display"],
      VPF: ["показала", "to show, display"],
      VPN: ["показало", "to show, display"],
      VPP: ["показали", "to show, display"],
    },
    вспомнить: {
      VI: ["вспомнить", "to remember, recall"],
      VF1: ["вспомню", "to remember, recall"],
      VF2: ["вспомнишь", "to remember, recall"],
      VF3: ["вспомнит", "to remember, recall"],
      VFW: ["вспомним", "to remember, recall"],
      VFF: ["вспомните", "to remember, recall"],
      VFT: ["вспомнят", "to remember, recall"],
      VI1: ["вспомни", "to remember, recall"],
      VI2: ["вспомните", "to remember, recall"],
      VPM: ["вспомнил", "to remember, recall"],
      VPF: ["вспомнила", "to remember, recall"],
      VPN: ["вспомнило", "to remember, recall"],
      VPP: ["вспомнили", "to remember, recall"],
    },
    держать: {
      VI: ["держать", "to hold, keep"],
      VN1: ["держу", "to hold, keep"],
      VF1: ["буду держать", "to hold, keep"],
      VN2: ["держишь", "to hold, keep"],
      VF2: ["будешь держать", "to hold, keep"],
      VN3: ["держит", "to hold, keep"],
      VF3: ["будет держать", "to hold, keep"],
      VNW: ["держим", "to hold, keep"],
      VFW: ["будем держать", "to hold, keep"],
      VNF: ["держите", "to hold, keep"],
      VFF: ["будете держать", "to hold, keep"],
      VNT: ["держат", "to hold, keep"],
      VFT: ["будут держать", "to hold, keep"],
      VI1: ["держи", "to hold, keep"],
      VI2: ["держите", "to hold, keep"],
      VPM: ["держал", "to hold, keep"],
      VPF: ["держала", "to hold, keep"],
      VPN: ["держало", "to hold, keep"],
      VPP: ["держали", "to hold, keep"],
    },
    называть: {
      VI: ["называть", "to call, name, (See #597)"],
      VN1: ["называю", "to call, name, (See #597)"],
      VF1: ["буду называть", "to call, name, (See #597)"],
      VN2: ["называешь", "to call, name, (See #597)"],
      VF2: ["будешь называть", "to call, name, (See #597)"],
      VN3: ["называет", "to call, name, (See #597)"],
      VF3: ["будет называть", "to call, name, (See #597)"],
      VNW: ["называем", "to call, name, (See #597)"],
      VFW: ["будем называть", "to call, name, (See #597)"],
      VNF: ["называете", "to call, name, (See #597)"],
      VFF: ["будете называть", "to call, name, (See #597)"],
      VNT: ["называют", "to call, name, (See #597)"],
      VFT: ["будут называть", "to call, name, (See #597)"],
      VI1: ["называй", "to call, name, (See #597)"],
      VI2: ["называйте", "to call, name, (See #597)"],
      VPM: ["называл", "to call, name, (See #597)"],
      VPF: ["называла", "to call, name, (See #597)"],
      VPN: ["называло", "to call, name, (See #597)"],
      VPP: ["называли", "to call, name, (See #597)"],
    },
    уходить: {
      VI: ["уходить", "to leave, go away"],
      VN1: ["ухожу", "to leave, go away"],
      VF1: ["буду уходить", "to leave, go away"],
      VN2: ["уходишь", "to leave, go away"],
      VF2: ["будешь уходить", "to leave, go away"],
      VN3: ["уходит", "to leave, go away"],
      VF3: ["будет уходить", "to leave, go away"],
      VNW: ["уходим", "to leave, go away"],
      VFW: ["будем уходить", "to leave, go away"],
      VNF: ["уходите", "to leave, go away"],
      VFF: ["будете уходить", "to leave, go away"],
      VNT: ["уходят", "to leave, go away"],
      VFT: ["будут уходить", "to leave, go away"],
      VI1: ["уходи", "to leave, go away"],
      VI2: ["уходите", "to leave, go away"],
      VPM: ["уходил", "to leave, go away"],
      VPF: ["уходила", "to leave, go away"],
      VPN: ["уходило", "to leave, go away"],
      VPP: ["уходили", "to leave, go away"],
    },
    подойти: {
      VI: ["подойти", "to approach, come up(See #798)"],
      VF1: ["подойду", "to approach, come up(See #798)"],
      VF2: ["подойдёшь", "to approach, come up(See #798)"],
      VF3: ["подойдёт", "to approach, come up(See #798)"],
      VFW: ["подойдём", "to approach, come up(See #798)"],
      VFF: ["подойдёте", "to approach, come up(See #798)"],
      VFT: ["подойдут", "to approach, come up(See #798)"],
      VI1: ["подойди", "to approach, come up(See #798)"],
      VI2: ["подойдите", "to approach, come up(See #798)"],
      VPM: ["подошёл", "to approach, come up(See #798)"],
      VPF: ["подошла", "to approach, come up(See #798)"],
      VPN: ["подошло", "to approach, come up(See #798)"],
      VPP: ["подошли", "to approach, come up(See #798)"],
    },
    поднять: {
      VI: ["поднять", "to lift, raise"],
      VF1: ["подниму", "to lift, raise"],
      VF2: ["поднимешь", "to lift, raise"],
      VF3: ["поднимет", "to lift, raise"],
      VFW: ["поднимем", "to lift, raise"],
      VFF: ["поднимете", "to lift, raise"],
      VFT: ["поднимут", "to lift, raise"],
      VI1: ["подними", "to lift, raise"],
      VI2: ["поднимите", "to lift, raise"],
      VPM: ["поднял поднял", "to lift, raise"],
      VPF: ["подняла", "to lift, raise"],
      VPN: ["подняло подняло", "to lift, raise"],
      VPP: ["подняли подняли", "to lift, raise"],
    },
    спрашивать: {
      VI: ["спрашивать", "to ask, inquire"],
      VN1: ["спрашиваю", "to ask, inquire"],
      VF1: ["буду спрашивать", "to ask, inquire"],
      VN2: ["спрашиваешь", "to ask, inquire"],
      VF2: ["будешь спрашивать", "to ask, inquire"],
      VN3: ["спрашивает", "to ask, inquire"],
      VF3: ["будет спрашивать", "to ask, inquire"],
      VNW: ["спрашиваем", "to ask, inquire"],
      VFW: ["будем спрашивать", "to ask, inquire"],
      VNF: ["спрашиваете", "to ask, inquire"],
      VFF: ["будете спрашивать", "to ask, inquire"],
      VNT: ["спрашивают", "to ask, inquire"],
      VFT: ["будут спрашивать", "to ask, inquire"],
      VI1: ["спрашивай", "to ask, inquire"],
      VI2: ["спрашивайте", "to ask, inquire"],
      VPM: ["спрашивал", "to ask, inquire"],
      VPF: ["спрашивала", "to ask, inquire"],
      VPN: ["спрашивало", "to ask, inquire"],
      VPP: ["спрашивали", "to ask, inquire"],
    },
    бросить: {
      VI: ["бросить", "to throw"],
      VF1: ["брошу", "to throw"],
      VF2: ["бросишь", "to throw"],
      VF3: ["бросит", "to throw"],
      VFW: ["бросим", "to throw"],
      VFF: ["бросите", "to throw"],
      VFT: ["бросят", "to throw"],
      VI1: ["брось", "to throw"],
      VI2: ["бросьте", "to throw"],
      VPM: ["бросил", "to throw"],
      VPF: ["бросила", "to throw"],
      VPN: ["бросило", "to throw"],
      VPP: ["бросили", "to throw"],
    },
    послать: {
      VI: ["послать", "to send, dispatch"],
      VF1: ["пошлю", "to send, dispatch"],
      VF2: ["пошлёшь", "to send, dispatch"],
      VF3: ["пошлёт", "to send, dispatch"],
      VFW: ["пошлём", "to send, dispatch"],
      VFF: ["пошлёте", "to send, dispatch"],
      VFT: ["пошлют", "to send, dispatch"],
      VI1: ["пошли", "to send, dispatch"],
      VI2: ["пошлите", "to send, dispatch"],
      VPM: ["послал", "to send, dispatch"],
      VPF: ["послала", "to send, dispatch"],
      VPN: ["послало", "to send, dispatch"],
      VPP: ["послали", "to send, dispatch"],
    },
    находиться: {
      VI: ["находиться", "to be found, turn up"],
      VN1: ["нахожусь", "to be found, turn up"],
      VF1: ["буду находиться", "to be found, turn up"],
      VN2: ["находишься", "to be found, turn up"],
      VF2: ["будешь находиться", "to be found, turn up"],
      VN3: ["находится", "to be found, turn up"],
      VF3: ["будет находиться", "to be found, turn up"],
      VNW: ["находимся", "to be found, turn up"],
      VFW: ["будем находиться", "to be found, turn up"],
      VNF: ["находитесь", "to be found, turn up"],
      VFF: ["будете находиться", "to be found, turn up"],
      VNT: ["находятся", "to be found, turn up"],
      VFT: ["будут находиться", "to be found, turn up"],
      VI1: ["находись", "to be found, turn up"],
      VI2: ["находитесь", "to be found, turn up"],
      VPM: ["находился", "to be found, turn up"],
      VPF: ["находилась", "to be found, turn up"],
      VPN: ["находилось", "to be found, turn up"],
      VPP: ["находились", "to be found, turn up"],
    },
    поставить: {
      VI: ["поставить", "to put, place, set"],
      VF1: ["поставлю", "to put, place, set"],
      VF2: ["поставишь", "to put, place, set"],
      VF3: ["поставит", "to put, place, set"],
      VFW: ["поставим", "to put, place, set"],
      VFF: ["поставите", "to put, place, set"],
      VFT: ["поставят", "to put, place, set"],
      VI1: ["поставь", "to put, place, set"],
      VI2: ["поставьте", "to put, place, set"],
      VPM: ["поставил", "to put, place, set"],
      VPF: ["поставила", "to put, place, set"],
      VPN: ["поставило", "to put, place, set"],
      VPP: ["поставили", "to put, place, set"],
    },
    встать: {
      VI: ["встать", "to get up, rise, stand up"],
      VF1: ["встану", "to get up, rise, stand up"],
      VF2: ["встанешь", "to get up, rise, stand up"],
      VF3: ["встанет", "to get up, rise, stand up"],
      VFW: ["встанем", "to get up, rise, stand up"],
      VFF: ["встанете", "to get up, rise, stand up"],
      VFT: ["встанут", "to get up, rise, stand up"],
      VI1: ["встань", "to get up, rise, stand up"],
      VI2: ["встаньте", "to get up, rise, stand up"],
      VPM: ["встал", "to get up, rise, stand up"],
      VPF: ["встала", "to get up, rise, stand up"],
      VPN: ["встало", "to get up, rise, stand up"],
      VPP: ["встали", "to get up, rise, stand up"],
    },
    играть: {
      VI: ["играть", "to play"],
      VN1: ["играю", "to play"],
      VF1: ["буду играть", "to play"],
      VN2: ["играешь", "to play"],
      VF2: ["будешь играть", "to play"],
      VN3: ["играет", "to play"],
      VF3: ["будет играть", "to play"],
      VNW: ["играем", "to play"],
      VFW: ["будем играть", "to play"],
      VNF: ["играете", "to play"],
      VFF: ["будете играть", "to play"],
      VNT: ["играют", "to play"],
      VFT: ["будут играть", "to play"],
      VI1: ["играй", "to play"],
      VI2: ["играйте", "to play"],
      VPM: ["играл", "to play"],
      VPF: ["играла", "to play"],
      VPN: ["играло", "to play"],
      VPP: ["играли", "to play"],
    },
    следовать: {
      VI: ["следовать", "to follow, come next"],
      VN1: ["следую", "to follow, come next"],
      VF1: ["буду следовать", "to follow, come next"],
      VN2: ["следуешь", "to follow, come next"],
      VF2: ["будешь следовать", "to follow, come next"],
      VN3: ["следует", "to follow, come next"],
      VF3: ["будет следовать", "to follow, come next"],
      VNW: ["следуем", "to follow, come next"],
      VFW: ["будем следовать", "to follow, come next"],
      VNF: ["следуете", "to follow, come next"],
      VFF: ["будете следовать", "to follow, come next"],
      VNT: ["следуют", "to follow, come next"],
      VFT: ["будут следовать", "to follow, come next"],
      VI1: ["следуй", "to follow, come next"],
      VI2: ["следуйте", "to follow, come next"],
      VPM: ["следовал", "to follow, come next"],
      VPF: ["следовала", "to follow, come next"],
      VPN: ["следовало", "to follow, come next"],
      VPP: ["следовали", "to follow, come next"],
    },
    рассказать: {
      VI: ["рассказать", "to tell, narrate(See #403)"],
      VF1: ["расскажу", "to tell, narrate(See #403)"],
      VF2: ["расскажешь", "to tell, narrate(See #403)"],
      VF3: ["расскажет", "to tell, narrate(See #403)"],
      VFW: ["расскажем", "to tell, narrate(See #403)"],
      VFF: ["расскажете", "to tell, narrate(See #403)"],
      VFT: ["расскажут", "to tell, narrate(See #403)"],
      VI1: ["расскажи", "to tell, narrate(See #403)"],
      VI2: ["расскажите", "to tell, narrate(See #403)"],
      VPM: ["рассказал", "to tell, narrate(See #403)"],
      VPF: ["рассказала", "to tell, narrate(See #403)"],
      VPN: ["рассказало", "to tell, narrate(See #403)"],
      VPP: ["рассказали", "to tell, narrate(See #403)"],
    },
    спать: {
      VI: ["спать", "to sleep"],
      VN1: ["сплю", "to sleep"],
      VF1: ["буду спать", "to sleep"],
      VN2: ["спишь", "to sleep"],
      VF2: ["будешь спать", "to sleep"],
      VN3: ["спит", "to sleep"],
      VF3: ["будет спать", "to sleep"],
      VNW: ["спим", "to sleep"],
      VFW: ["будем спать", "to sleep"],
      VNF: ["спите", "to sleep"],
      VFF: ["будете спать", "to sleep"],
      VNT: ["спят", "to sleep"],
      VFT: ["будут спать", "to sleep"],
      VI1: ["спи", "to sleep"],
      VI2: ["спите", "to sleep"],
      VPM: ["спал", "to sleep"],
      VPF: ["спала", "to sleep"],
      VPN: ["спало", "to sleep"],
      VPP: ["спали", "to sleep"],
    },
    оставить: {
      VI: ["оставить", "to leave, abandon"],
      VF1: ["оставлю", "to leave, abandon"],
      VF2: ["оставишь", "to leave, abandon"],
      VF3: ["оставит", "to leave, abandon"],
      VFW: ["оставим", "to leave, abandon"],
      VFF: ["оставите", "to leave, abandon"],
      VFT: ["оставят", "to leave, abandon"],
      VI1: ["оставь", "to leave, abandon"],
      VI2: ["оставьте", "to leave, abandon"],
      VPM: ["оставил", "to leave, abandon"],
      VPF: ["оставила", "to leave, abandon"],
      VPN: ["оставило", "to leave, abandon"],
      VPP: ["оставили", "to leave, abandon"],
    },
    войти: {
      VI: ["войти", "to enter, come in"],
      VF1: ["войду", "to enter, come in"],
      VF2: ["войдёшь", "to enter, come in"],
      VF3: ["войдёт", "to enter, come in"],
      VFW: ["войдём", "to enter, come in"],
      VFF: ["войдёте", "to enter, come in"],
      VFT: ["войдут", "to enter, come in"],
      VI1: ["войди", "to enter, come in"],
      VI2: ["войдите", "to enter, come in"],
      VPM: ["вошёл", "to enter, come in"],
      VPF: ["вошла", "to enter, come in"],
      VPN: ["вошло", "to enter, come in"],
      VPP: ["вошли", "to enter, come in"],
    },
    молчать: {
      VI: ["молчать", "to keep silence"],
      VN1: ["молчу", "to keep silence"],
      VF1: ["буду молчать", "to keep silence"],
      VN2: ["молчишь", "to keep silence"],
      VF2: ["будешь молчать", "to keep silence"],
      VN3: ["молчит", "to keep silence"],
      VF3: ["будет молчать", "to keep silence"],
      VNW: ["молчим", "to keep silence"],
      VFW: ["будем молчать", "to keep silence"],
      VNF: ["молчите", "to keep silence"],
      VFF: ["будете молчать", "to keep silence"],
      VNT: ["молчат", "to keep silence"],
      VFT: ["будут молчать", "to keep silence"],
      VI1: ["молчи", "to keep silence"],
      VI2: ["молчите", "to keep silence"],
      VPM: ["молчал", "to keep silence"],
      VPF: ["молчала", "to keep silence"],
      VPN: ["молчало", "to keep silence"],
      VPP: ["молчали", "to keep silence"],
    },
    рассказывать: {
      VI: ["рассказывать", "to tell, narrate, (See #392)"],
      VN1: ["рассказываю", "to tell, narrate, (See #392)"],
      VF1: ["буду рассказывать", "to tell, narrate, (See #392)"],
      VN2: ["рассказываешь", "to tell, narrate, (See #392)"],
      VF2: ["будешь рассказывать", "to tell, narrate, (See #392)"],
      VN3: ["рассказывает", "to tell, narrate, (See #392)"],
      VF3: ["будет рассказывать", "to tell, narrate, (See #392)"],
      VNW: ["рассказываем", "to tell, narrate, (See #392)"],
      VFW: ["будем рассказывать", "to tell, narrate, (See #392)"],
      VNF: ["рассказываете", "to tell, narrate, (See #392)"],
      VFF: ["будете рассказывать", "to tell, narrate, (See #392)"],
      VNT: ["рассказывают", "to tell, narrate, (See #392)"],
      VFT: ["будут рассказывать", "to tell, narrate, (See #392)"],
      VI1: ["рассказывай", "to tell, narrate, (See #392)"],
      VI2: ["рассказывайте", "to tell, narrate, (See #392)"],
      VPM: ["рассказывал", "to tell, narrate, (See #392)"],
      VPF: ["рассказывала", "to tell, narrate, (See #392)"],
      VPN: ["рассказывало", "to tell, narrate, (See #392)"],
      VPP: ["рассказывали", "to tell, narrate, (See #392)"],
    },
    отвечать: {
      VI: ["отвечать", "to answer, reply, (See #190)"],
      VN1: ["отвечаю", "to answer, reply, (See #190)"],
      VF1: ["буду отвечать", "to answer, reply, (See #190)"],
      VN2: ["отвечаешь", "to answer, reply, (See #190)"],
      VF2: ["будешь отвечать", "to answer, reply, (See #190)"],
      VN3: ["отвечает", "to answer, reply, (See #190)"],
      VF3: ["будет отвечать", "to answer, reply, (See #190)"],
      VNW: ["отвечаем", "to answer, reply, (See #190)"],
      VFW: ["будем отвечать", "to answer, reply, (See #190)"],
      VNF: ["отвечаете", "to answer, reply, (See #190)"],
      VFF: ["будете отвечать", "to answer, reply, (See #190)"],
      VNT: ["отвечают", "to answer, reply, (See #190)"],
      VFT: ["будут отвечать", "to answer, reply, (See #190)"],
      VI1: ["отвечай", "to answer, reply, (See #190)"],
      VI2: ["отвечайте", "to answer, reply, (See #190)"],
      VPM: ["отвечал", "to answer, reply, (See #190)"],
      VPF: ["отвечала", "to answer, reply, (See #190)"],
      VPN: ["отвечало", "to answer, reply, (See #190)"],
      VPP: ["отвечали", "to answer, reply, (See #190)"],
    },
    становиться: {
      VI: ["становиться", "to stand; to become"],
      VN1: ["становлюсь", "to stand; to become"],
      VF1: ["буду становиться", "to stand; to become"],
      VN2: ["становишься", "to stand; to become"],
      VF2: ["будешь становиться", "to stand; to become"],
      VN3: ["становится", "to stand; to become"],
      VF3: ["будет становиться", "to stand; to become"],
      VNW: ["становимся", "to stand; to become"],
      VFW: ["будем становиться", "to stand; to become"],
      VNF: ["становитесь", "to stand; to become"],
      VFF: ["будете становиться", "to stand; to become"],
      VNT: ["становятся", "to stand; to become"],
      VFT: ["будут становиться", "to stand; to become"],
      VI1: ["становись", "to stand; to become"],
      VI2: ["становитесь", "to stand; to become"],
      VPM: ["становился", "to stand; to become"],
      VPF: ["становилась", "to stand; to become"],
      VPN: ["становилось", "to stand; to become"],
      VPP: ["становились", "to stand; to become"],
    },
    остановиться: {
      VI: ["остановиться", "to stop"],
      VF1: ["остановлюсь", "to stop"],
      VF2: ["остановишься", "to stop"],
      VF3: ["остановится", "to stop"],
      VFW: ["остановимся", "to stop"],
      VFF: ["остановитесь", "to stop"],
      VFT: ["остановятся", "to stop"],
      VI1: ["остановись", "to stop"],
      VI2: ["остановитесь", "to stop"],
      VPM: ["остановился", "to stop"],
      VPF: ["остановилась", "to stop"],
      VPN: ["остановилось", "to stop"],
      VPP: ["остановились", "to stop"],
    },
    искать: {
      VI: ["искать", "to search"],
      VN1: ["ищу", "to search"],
      VF1: ["буду искать", "to search"],
      VN2: ["ищешь", "to search"],
      VF2: ["будешь искать", "to search"],
      VN3: ["ищет", "to search"],
      VF3: ["будет искать", "to search"],
      VNW: ["ищем", "to search"],
      VFW: ["будем искать", "to search"],
      VNF: ["ищете", "to search"],
      VFF: ["будете искать", "to search"],
      VNT: ["ищут", "to search"],
      VFT: ["будут искать", "to search"],
      VI1: ["ищи", "to search"],
      VI2: ["ищите", "to search"],
      VPM: ["искал", "to search"],
      VPF: ["искала", "to search"],
      VPN: ["искало", "to search"],
      VPP: ["искали", "to search"],
    },
    начать: {
      VI: ["начать", "to begin(See #309)"],
      VF1: ["начну", "to begin(See #309)"],
      VF2: ["начнёшь", "to begin(See #309)"],
      VF3: ["начнёт", "to begin(See #309)"],
      VFW: ["начнём", "to begin(See #309)"],
      VFF: ["начнёте", "to begin(See #309)"],
      VFT: ["начнут", "to begin(See #309)"],
      VI1: ["начни", "to begin(See #309)"],
      VI2: ["начните", "to begin(See #309)"],
      VPM: ["начал", "to begin(See #309)"],
      VPF: ["начала", "to begin(See #309)"],
      VPN: ["начало", "to begin(See #309)"],
      VPP: ["начали", "to begin(See #309)"],
    },
    верить: {
      VI: ["верить", "to believe; masterrussian, dot com"],
      VN1: ["верю", "to believe; masterrussian, dot com"],
      VF1: ["буду верить", "to believe; masterrussian, dot com"],
      VN2: ["веришь", "to believe; masterrussian, dot com"],
      VF2: ["будешь верить", "to believe; masterrussian, dot com"],
      VN3: ["верит", "to believe; masterrussian, dot com"],
      VF3: ["будет верить", "to believe; masterrussian, dot com"],
      VNW: ["верим", "to believe; masterrussian, dot com"],
      VFW: ["будем верить", "to believe; masterrussian, dot com"],
      VNF: ["верите", "to believe; masterrussian, dot com"],
      VFF: ["будете верить", "to believe; masterrussian, dot com"],
      VNT: ["верят", "to believe; masterrussian, dot com"],
      VFT: ["будут верить", "to believe; masterrussian, dot com"],
      VI1: ["верь", "to believe; masterrussian, dot com"],
      VI2: ["верьте", "to believe; masterrussian, dot com"],
      VPM: ["верил", "to believe; masterrussian, dot com"],
      VPF: ["верила", "to believe; masterrussian, dot com"],
      VPN: ["верило", "to believe; masterrussian, dot com"],
      VPP: ["верили", "to believe; masterrussian, dot com"],
    },
    являться: {
      VI: ["являться", "to appear"],
      VN1: ["являюсь", "to appear"],
      VF1: ["буду являться", "to appear"],
      VN2: ["являешься", "to appear"],
      VF2: ["будешь являться", "to appear"],
      VN3: ["является", "to appear"],
      VF3: ["будет являться", "to appear"],
      VNW: ["являемся", "to appear"],
      VFW: ["будем являться", "to appear"],
      VNF: ["являетесь", "to appear"],
      VFF: ["будете являться", "to appear"],
      VNT: ["являются", "to appear"],
      VFT: ["будут являться", "to appear"],
      VI1: ["являйся", "to appear"],
      VI2: ["являйтесь", "to appear"],
      VPM: ["являлся", "to appear"],
      VPF: ["являлась", "to appear"],
      VPN: ["являлось", "to appear"],
      VPP: ["являлись", "to appear"],
    },
    смочь: {
      VI: ["смочь", "to be able"],
      VF1: ["смогу", "to be able"],
      VF2: ["сможешь", "to be able"],
      VF3: ["сможет", "to be able"],
      VFW: ["сможем", "to be able"],
      VFF: ["сможете", "to be able"],
      VFT: ["смогут", "to be able"],
      VI1: ["смоги", "to be able"],
      VI2: ["смогите", "to be able"],
      VPM: ["смог", "to be able"],
      VPF: ["смогла", "to be able"],
      VPN: ["смогло", "to be able"],
      VPP: ["смогли", "to be able"],
    },
    бежать: {
      VI: ["бежать", "to run, hurry"],
      VN1: ["бегу", "to run, hurry"],
      VF1: ["буду бежать", "to run, hurry"],
      VN2: ["бежишь", "to run, hurry"],
      VF2: ["будешь бежать", "to run, hurry"],
      VN3: ["бежит", "to run, hurry"],
      VF3: ["будет бежать", "to run, hurry"],
      VNW: ["бежим", "to run, hurry"],
      VFW: ["будем бежать", "to run, hurry"],
      VNF: ["бежите", "to run, hurry"],
      VFF: ["будете бежать", "to run, hurry"],
      VNT: ["бегут", "to run, hurry"],
      VFT: ["будут бежать", "to run, hurry"],
      VI1: ["беги", "to run, hurry"],
      VI2: ["бегите", "to run, hurry"],
      VPM: ["бежал", "to run, hurry"],
      VPF: ["бежала", "to run, hurry"],
      VPN: ["бежало", "to run, hurry"],
      VPP: ["бежали", "to run, hurry"],
    },
    приехать: {
      VI: ["приехать", "to arrive, come"],
      VF1: ["приеду", "to arrive, come"],
      VF2: ["приедешь", "to arrive, come"],
      VF3: ["приедет", "to arrive, come"],
      VFW: ["приедем", "to arrive, come"],
      VFF: ["приедете", "to arrive, come"],
      VFT: ["приедут", "to arrive, come"],
      VI1: ["приезжай", "to arrive, come"],
      VI2: ["приезжайте", "to arrive, come"],
      VPM: ["приехал", "to arrive, come"],
      VPF: ["приехала", "to arrive, come"],
      VPN: ["приехало", "to arrive, come"],
      VPP: ["приехали", "to arrive, come"],
    },
    кричать: {
      VI: ["кричать", "to cry, shout(See #656)"],
      VN1: ["кричу", "to cry, shout(See #656)"],
      VF1: ["буду кричать", "to cry, shout(See #656)"],
      VN2: ["кричишь", "to cry, shout(See #656)"],
      VF2: ["будешь кричать", "to cry, shout(See #656)"],
      VN3: ["кричит", "to cry, shout(See #656)"],
      VF3: ["будет кричать", "to cry, shout(See #656)"],
      VNW: ["кричим", "to cry, shout(See #656)"],
      VFW: ["будем кричать", "to cry, shout(See #656)"],
      VNF: ["кричите", "to cry, shout(See #656)"],
      VFF: ["будете кричать", "to cry, shout(See #656)"],
      VNT: ["кричат", "to cry, shout(See #656)"],
      VFT: ["будут кричать", "to cry, shout(See #656)"],
      VI1: ["кричи", "to cry, shout(See #656)"],
      VI2: ["кричите", "to cry, shout(See #656)"],
      VPM: ["кричал", "to cry, shout(See #656)"],
      VPF: ["кричала", "to cry, shout(See #656)"],
      VPN: ["кричало", "to cry, shout(See #656)"],
      VPP: ["кричали", "to cry, shout(See #656)"],
    },
    открыть: {
      VI: ["открыть", "to open"],
      VF1: ["открою", "to open"],
      VF2: ["откроешь", "to open"],
      VF3: ["откроет", "to open"],
      VFW: ["откроем", "to open"],
      VFF: ["откроете", "to open"],
      VFT: ["откроют", "to open"],
      VI1: ["открой", "to open"],
      VI2: ["откройте", "to open"],
      VPM: ["открыл", "to open"],
      VPF: ["открыла", "to open"],
      VPN: ["открыло", "to open"],
      VPP: ["открыли", "to open"],
    },
    происходить: {
      VI: ["происходить", "to happen, occur, take place"],
      VN1: ["происхожу", "to happen, occur, take place"],
      VF1: ["буду происходить", "to happen, occur, take place"],
      VN2: ["происходишь", "to happen, occur, take place"],
      VF2: ["будешь происходить", "to happen, occur, take place"],
      VN3: ["происходит", "to happen, occur, take place"],
      VF3: ["будет происходить", "to happen, occur, take place"],
      VNW: ["происходим", "to happen, occur, take place"],
      VFW: ["будем происходить", "to happen, occur, take place"],
      VNF: ["происходите", "to happen, occur, take place"],
      VFF: ["будете происходить", "to happen, occur, take place"],
      VNT: ["происходят", "to happen, occur, take place"],
      VFT: ["будут происходить", "to happen, occur, take place"],
      VI1: ["происходи", "to happen, occur, take place"],
      VI2: ["происходите", "to happen, occur, take place"],
      VPM: ["происходил", "to happen, occur, take place"],
      VPF: ["происходила", "to happen, occur, take place"],
      VPN: ["происходило", "to happen, occur, take place"],
      VPP: ["происходили", "to happen, occur, take place"],
    },
    приходить: {
      VI: ["приходить", "to come, arrive(See #253)"],
      VN1: ["прихожу", "to come, arrive(See #253)"],
      VF1: ["буду приходить", "to come, arrive(See #253)"],
      VN2: ["приходишь", "to come, arrive(See #253)"],
      VF2: ["будешь приходить", "to come, arrive(See #253)"],
      VN3: ["приходит", "to come, arrive(See #253)"],
      VF3: ["будет приходить", "to come, arrive(See #253)"],
      VNW: ["приходим", "to come, arrive(See #253)"],
      VFW: ["будем приходить", "to come, arrive(See #253)"],
      VNF: ["приходите", "to come, arrive(See #253)"],
      VFF: ["будете приходить", "to come, arrive(See #253)"],
      VNT: ["приходят", "to come, arrive(See #253)"],
      VFT: ["будут приходить", "to come, arrive(See #253)"],
      VI1: ["приходи", "to come, arrive(See #253)"],
      VI2: ["приходите", "to come, arrive(See #253)"],
      VPM: ["приходил", "to come, arrive(See #253)"],
      VPF: ["приходила", "to come, arrive(See #253)"],
      VPN: ["приходило", "to come, arrive(See #253)"],
      VPP: ["приходили", "to come, arrive(See #253)"],
    },
    подняться: {
      VI: ["подняться", "to rise, climb"],
      VF1: ["поднимусь", "to rise, climb"],
      VF2: ["поднимешься", "to rise, climb"],
      VF3: ["поднимется", "to rise, climb"],
      VFW: ["поднимемся", "to rise, climb"],
      VFF: ["подниметесь", "to rise, climb"],
      VFT: ["поднимутся", "to rise, climb"],
      VI1: ["поднимись", "to rise, climb"],
      VI2: ["поднимитесь", "to rise, climb"],
      VPM: ["поднялся поднялся", "to rise, climb"],
      VPF: ["поднялась", "to rise, climb"],
      VPN: ["поднялось поднялось", "to rise, climb"],
      VPP: ["поднялись поднялись", "to rise, climb"],
    },
    показаться: {
      VI: ["показаться", "to appear, come in sight"],
      VF1: ["покажусь", "to appear, come in sight"],
      VF2: ["покажешься", "to appear, come in sight"],
      VF3: ["покажется", "to appear, come in sight"],
      VFW: ["покажемся", "to appear, come in sight"],
      VFF: ["покажетесь", "to appear, come in sight"],
      VFT: ["покажутся", "to appear, come in sight"],
      VI1: ["покажись", "to appear, come in sight"],
      VI2: ["покажитесь", "to appear, come in sight"],
      VPM: ["показался", "to appear, come in sight"],
      VPF: ["показалась", "to appear, come in sight"],
      VPN: ["показалось", "to appear, come in sight"],
      VPP: ["показались", "to appear, come in sight"],
    },
    собираться: {
      VI: ["собираться", "to gather together, assemble; intend(See #981)"],
      VN1: ["собираюсь", "to gather together, assemble; intend(See #981)"],
      VF1: ["буду собираться", "to gather together, assemble; intend(See #981)"],
      VN2: ["собираешься", "to gather together, assemble; intend(See #981)"],
      VF2: [
        "будешь собираться",
        "to gather together, assemble; intend(See #981)",
      ],
      VN3: ["собирается", "to gather together, assemble; intend(See #981)"],
      VF3: ["будет собираться", "to gather together, assemble; intend(See #981)"],
      VNW: ["собираемся", "to gather together, assemble; intend(See #981)"],
      VFW: ["будем собираться", "to gather together, assemble; intend(See #981)"],
      VNF: ["собираетесь", "to gather together, assemble; intend(See #981)"],
      VFF: [
        "будете собираться",
        "to gather together, assemble; intend(See #981)",
      ],
      VNT: ["собираются", "to gather together, assemble; intend(See #981)"],
      VFT: ["будут собираться", "to gather together, assemble; intend(See #981)"],
      VI1: ["собирайся", "to gather together, assemble; intend(See #981)"],
      VI2: ["собирайтесь", "to gather together, assemble; intend(See #981)"],
      VPM: ["собирался", "to gather together, assemble; intend(See #981)"],
      VPF: ["собиралась", "to gather together, assemble; intend(See #981)"],
      VPN: ["собиралось", "to gather together, assemble; intend(See #981)"],
      VPP: ["собирались", "to gather together, assemble; intend(See #981)"],
    },
    попасть: {
      VI: ["попасть", "to hit; to find oneself"],
      VF1: ["попаду", "to hit; to find oneself"],
      VF2: ["попадёшь", "to hit; to find oneself"],
      VF3: ["попадёт", "to hit; to find oneself"],
      VFW: ["попадём", "to hit; to find oneself"],
      VFF: ["попадёте", "to hit; to find oneself"],
      VFT: ["попадут", "to hit; to find oneself"],
      VI1: ["попади", "to hit; to find oneself"],
      VI2: ["попадите", "to hit; to find oneself"],
      VPM: ["попал", "to hit; to find oneself"],
      VPF: ["попала", "to hit; to find oneself"],
      VPN: ["попало", "to hit; to find oneself"],
      VPP: ["попали", "to hit; to find oneself"],
    },
    принять: {
      VI: ["принять", "to take, admit, accept(See #643)"],
      VF1: ["приму", "to take, admit, accept(See #643)"],
      VF2: ["примешь", "to take, admit, accept(See #643)"],
      VF3: ["примет", "to take, admit, accept(See #643)"],
      VFW: ["примем", "to take, admit, accept(See #643)"],
      VFF: ["примете", "to take, admit, accept(See #643)"],
      VFT: ["примут", "to take, admit, accept(See #643)"],
      VI1: ["прими", "to take, admit, accept(See #643)"],
      VI2: ["примите", "to take, admit, accept(See #643)"],
      VPM: ["принял", "to take, admit, accept(See #643)"],
      VPF: ["приняла", "to take, admit, accept(See #643)"],
      VPN: ["приняло", "to take, admit, accept(See #643)"],
      VPP: ["приняли", "to take, admit, accept(See #643)"],
    },
    поехать: {
      VI: ["поехать", "to depart, set off"],
      VF1: ["поеду", "to depart, set off"],
      VF2: ["поедешь", "to depart, set off"],
      VF3: ["поедет", "to depart, set off"],
      VFW: ["поедем", "to depart, set off"],
      VFF: ["поедете", "to depart, set off"],
      VFT: ["поедут", "to depart, set off"],
      VI1: ["поезжай", "to depart, set off"],
      VI2: ["поезжайте", "to depart, set off"],
      VPM: ["поехал", "to depart, set off"],
      VPF: ["поехала", "to depart, set off"],
      VPN: ["поехало", "to depart, set off"],
      VPP: ["поехали", "to depart, set off"],
    },
    услышать: {
      VI: ["услышать", "to hear"],
      VF1: ["услышу", "to hear"],
      VF2: ["услышишь", "to hear"],
      VF3: ["услышит", "to hear"],
      VFW: ["услышим", "to hear"],
      VFF: ["услышите", "to hear"],
      VFT: ["услышат", "to hear"],
      VI1: ["услышь", "to hear"],
      VI2: ["услышьте", "to hear"],
      VPM: ["услышал", "to hear"],
      VPF: ["услышала", "to hear"],
      VPN: ["услышало", "to hear"],
      VPP: ["услышали", "to hear"],
    },
    уметь: {
      VI: ["уметь", "to be able, know, can"],
      VN1: ["умею", "to be able, know, can"],
      VF1: ["буду уметь", "to be able, know, can"],
      VN2: ["умеешь", "to be able, know, can"],
      VF2: ["будешь уметь", "to be able, know, can"],
      VN3: ["умеет", "to be able, know, can"],
      VF3: ["будет уметь", "to be able, know, can"],
      VNW: ["умеем", "to be able, know, can"],
      VFW: ["будем уметь", "to be able, know, can"],
      VNF: ["умеете", "to be able, know, can"],
      VFF: ["будете уметь", "to be able, know, can"],
      VNT: ["умеют", "to be able, know, can"],
      VFT: ["будут уметь", "to be able, know, can"],
      VI1: ["умей", "to be able, know, can"],
      VI2: ["умейте", "to be able, know, can"],
      VPM: ["умел", "to be able, know, can"],
      VPF: ["умела", "to be able, know, can"],
      VPN: ["умело", "to be able, know, can"],
      VPP: ["умели", "to be able, know, can"],
    },
    случиться: {
      VI: ["случиться", "to happen"],
      VF1: ["случусь", "to happen"],
      VF2: ["случишься", "to happen"],
      VF3: ["случится", "to happen"],
      VFW: ["случимся", "to happen"],
      VFF: ["случитесь", "to happen"],
      VFT: ["случатся", "to happen"],
      VI1: ["случись", "to happen"],
      VI2: ["случитесь", "to happen"],
      VPM: ["случился", "to happen"],
      VPF: ["случилась", "to happen"],
      VPN: ["случилось", "to happen"],
      VPP: ["случились", "to happen"],
    },
    стараться: {
      VI: ["стараться", "to try, endeavour"],
      VN1: ["стараюсь", "to try, endeavour"],
      VF1: ["буду стараться", "to try, endeavour"],
      VN2: ["стараешься", "to try, endeavour"],
      VF2: ["будешь стараться", "to try, endeavour"],
      VN3: ["старается", "to try, endeavour"],
      VF3: ["будет стараться", "to try, endeavour"],
      VNW: ["стараемся", "to try, endeavour"],
      VFW: ["будем стараться", "to try, endeavour"],
      VNF: ["стараетесь", "to try, endeavour"],
      VFF: ["будете стараться", "to try, endeavour"],
      VNT: ["стараются", "to try, endeavour"],
      VFT: ["будут стараться", "to try, endeavour"],
      VI1: ["старайся", "to try, endeavour"],
      VI2: ["старайтесь", "to try, endeavour"],
      VPM: ["старался", "to try, endeavour"],
      VPF: ["старалась", "to try, endeavour"],
      VPN: ["старалось", "to try, endeavour"],
      VPP: ["старались", "to try, endeavour"],
    },
    звать: {
      VI: ["звать", "to call"],
      VN1: ["зову", "to call"],
      VF1: ["буду звать", "to call"],
      VN2: ["зовёшь", "to call"],
      VF2: ["будешь звать", "to call"],
      VN3: ["зовёт", "to call"],
      VF3: ["будет звать", "to call"],
      VNW: ["зовём", "to call"],
      VFW: ["будем звать", "to call"],
      VNF: ["зовёте", "to call"],
      VFF: ["будете звать", "to call"],
      VNT: ["зовут", "to call"],
      VFT: ["будут звать", "to call"],
      VI1: ["зови", "to call"],
      VI2: ["зовите", "to call"],
      VPM: ["звал", "to call"],
      VPF: ["звала", "to call"],
      VPN: ["звало", "to call"],
      VPP: ["звали", "to call"],
    },
    произойти: {
      VI: ["произойти", "to happen, occur, take place"],
      VF1: ["произойду", "to happen, occur, take place"],
      VF2: ["произойдёшь", "to happen, occur, take place"],
      VF3: ["произойдёт", "to happen, occur, take place"],
      VFW: ["произойдём", "to happen, occur, take place"],
      VFF: ["произойдёте", "to happen, occur, take place"],
      VFT: ["произойдут", "to happen, occur, take place"],
      VI1: ["произойди", "to happen, occur, take place"],
      VI2: ["произойдите", "to happen, occur, take place"],
      VPM: ["произошёл", "to happen, occur, take place"],
      VPF: ["произошла", "to happen, occur, take place"],
      VPN: ["произошло", "to happen, occur, take place"],
      VPP: ["произошли", "to happen, occur, take place"],
    },
    заниматься: {
      VI: ["заниматься", "to be occupied, engage"],
      VN1: ["занимаюсь", "to be occupied, engage"],
      VF1: ["буду заниматься", "to be occupied, engage"],
      VN2: ["занимаешься", "to be occupied, engage"],
      VF2: ["будешь заниматься", "to be occupied, engage"],
      VN3: ["занимается", "to be occupied, engage"],
      VF3: ["будет заниматься", "to be occupied, engage"],
      VNW: ["занимаемся", "to be occupied, engage"],
      VFW: ["будем заниматься", "to be occupied, engage"],
      VNF: ["занимаетесь", "to be occupied, engage"],
      VFF: ["будете заниматься", "to be occupied, engage"],
      VNT: ["занимаются", "to be occupied, engage"],
      VFT: ["будут заниматься", "to be occupied, engage"],
      VI1: ["занимайся", "to be occupied, engage"],
      VI2: ["занимайтесь", "to be occupied, engage"],
      VPM: ["занимался", "to be occupied, engage"],
      VPF: ["занималась", "to be occupied, engage"],
      VPN: ["занималось", "to be occupied, engage"],
      VPP: ["занимались", "to be occupied, engage"],
    },
    положить: {
      VI: ["положить", "to lay down, put down, place"],
      VF1: ["положу", "to lay down, put down, place"],
      VF2: ["положишь", "to lay down, put down, place"],
      VF3: ["положит", "to lay down, put down, place"],
      VFW: ["положим", "to lay down, put down, place"],
      VFF: ["положите", "to lay down, put down, place"],
      VFT: ["положат", "to lay down, put down, place"],
      VI1: ["положи", "to lay down, put down, place"],
      VI2: ["положите", "to lay down, put down, place"],
      VPM: ["положил", "to lay down, put down, place"],
      VPF: ["положила", "to lay down, put down, place"],
      VPN: ["положило", "to lay down, put down, place"],
      VPP: ["положили", "to lay down, put down, place"],
    },
    купить: {
      VI: ["купить", "to buy, purchase"],
      VF1: ["куплю", "to buy, purchase"],
      VF2: ["купишь", "to buy, purchase"],
      VF3: ["купит", "to buy, purchase"],
      VFW: ["купим", "to buy, purchase"],
      VFF: ["купите", "to buy, purchase"],
      VFT: ["купят", "to buy, purchase"],
      VI1: ["купи", "to buy, purchase"],
      VI2: ["купите", "to buy, purchase"],
      VPM: ["купил", "to buy, purchase"],
      VPF: ["купила", "to buy, purchase"],
      VPN: ["купило", "to buy, purchase"],
      VPP: ["купили", "to buy, purchase"],
    },
    умереть: {
      VI: ["умереть", "to die"],
      VF1: ["умру", "to die"],
      VF2: ["умрёшь", "to die"],
      VF3: ["умрёт", "to die"],
      VFW: ["умрём", "to die"],
      VFF: ["умрёте", "to die"],
      VFT: ["умрут", "to die"],
      VI1: ["умри", "to die"],
      VI2: ["умрите", "to die"],
      VPM: ["умер", "to die"],
      VPF: ["умерла", "to die"],
      VPN: ["умерло", "to die"],
      VPP: ["умерли", "to die"],
    },
    пить: {
      VI: ["пить", "to drink"],
      VN1: ["пью", "to drink"],
      VF1: ["буду пить", "to drink"],
      VN2: ["пьёшь", "to drink"],
      VF2: ["будешь пить", "to drink"],
      VN3: ["пьёт", "to drink"],
      VF3: ["будет пить", "to drink"],
      VNW: ["пьём", "to drink"],
      VFW: ["будем пить", "to drink"],
      VNF: ["пьёте", "to drink"],
      VFF: ["будете пить", "to drink"],
      VNT: ["пьют", "to drink"],
      VFT: ["будут пить", "to drink"],
      VI1: ["пей", "to drink"],
      VI2: ["пейте", "to drink"],
      VPM: ["пил", "to drink"],
      VPF: ["пила", "to drink"],
      VPN: ["пило", "to drink"],
      VPP: ["пили", "to drink"],
    },
    проходить: {
      VI: ["проходить", "to pass, go;study(See #334)"],
      VN1: ["прохожу", "to pass, go;study(See #334)"],
      VF1: ["буду проходить", "to pass, go;study(See #334)"],
      VN2: ["проходишь", "to pass, go;study(See #334)"],
      VF2: ["будешь проходить", "to pass, go;study(See #334)"],
      VN3: ["проходит", "to pass, go;study(See #334)"],
      VF3: ["будет проходить", "to pass, go;study(See #334)"],
      VNW: ["проходим", "to pass, go;study(See #334)"],
      VFW: ["будем проходить", "to pass, go;study(See #334)"],
      VNF: ["проходите", "to pass, go;study(See #334)"],
      VFF: ["будете проходить", "to pass, go;study(See #334)"],
      VNT: ["проходят", "to pass, go;study(See #334)"],
      VFT: ["будут проходить", "to pass, go;study(See #334)"],
      VI1: ["проходи", "to pass, go;study(See #334)"],
      VI2: ["проходите", "to pass, go;study(See #334)"],
      VPM: ["проходил", "to pass, go;study(See #334)"],
      VPF: ["проходила", "to pass, go;study(See #334)"],
      VPN: ["проходило", "to pass, go;study(See #334)"],
      VPP: ["проходили", "to pass, go;study(See #334)"],
    },
    снять: {
      VI: ["снять", "to take away, take off;photograph"],
      VF1: ["сниму", "to take away, take off;photograph"],
      VF2: ["снимешь", "to take away, take off;photograph"],
      VF3: ["снимет", "to take away, take off;photograph"],
      VFW: ["снимем", "to take away, take off;photograph"],
      VFF: ["снимете", "to take away, take off;photograph"],
      VFT: ["снимут", "to take away, take off;photograph"],
      VI1: ["сними", "to take away, take off;photograph"],
      VI2: ["снимите", "to take away, take off;photograph"],
      VPM: ["снял", "to take away, take off;photograph"],
      VPF: ["сняла", "to take away, take off;photograph"],
      VPN: ["сняло сняло", "to take away, take off;photograph"],
      VPP: ["сняли", "to take away, take off;photograph"],
    },
    начаться: {
      VI: ["начаться", "to begin"],
      VF1: ["начнусь", "to begin"],
      VF2: ["начнёшься", "to begin"],
      VF3: ["начнётся", "to begin"],
      VFW: ["начнёмся", "to begin"],
      VFF: ["начнётесь", "to begin"],
      VFT: ["начнутся", "to begin"],
      VI1: ["начнись", "to begin"],
      VI2: ["начнитесь", "to begin"],
      VPM: ["начался начался начался", "to begin"],
      VPF: ["началась", "to begin"],
      VPN: ["началось началось", "to begin"],
      VPP: ["начались начались", "to begin"],
    },
    представлять: {
      VI: ["представлять", "to present, introduce, imagine(See #586)"],
      VN1: ["представляю", "to present, introduce, imagine(See #586)"],
      VF1: ["буду представлять", "to present, introduce, imagine(See #586)"],
      VN2: ["представляешь", "to present, introduce, imagine(See #586)"],
      VF2: ["будешь представлять", "to present, introduce, imagine(See #586)"],
      VN3: ["представляет", "to present, introduce, imagine(See #586)"],
      VF3: ["будет представлять", "to present, introduce, imagine(See #586)"],
      VNW: ["представляем", "to present, introduce, imagine(See #586)"],
      VFW: ["будем представлять", "to present, introduce, imagine(See #586)"],
      VNF: ["представляете", "to present, introduce, imagine(See #586)"],
      VFF: ["будете представлять", "to present, introduce, imagine(See #586)"],
      VNT: ["представляют", "to present, introduce, imagine(See #586)"],
      VFT: ["будут представлять", "to present, introduce, imagine(See #586)"],
      VI1: ["представляй", "to present, introduce, imagine(See #586)"],
      VI2: ["представляйте", "to present, introduce, imagine(See #586)"],
      VPM: ["представлял", "to present, introduce, imagine(See #586)"],
      VPF: ["представляла", "to present, introduce, imagine(See #586)"],
      VPN: ["представляло", "to present, introduce, imagine(See #586)"],
      VPP: ["представляли", "to present, introduce, imagine(See #586)"],
    },
    объяснить: {
      VI: ["объяснить", "to explain"],
      VF1: ["объясню", "to explain"],
      VF2: ["объяснишь", "to explain"],
      VF3: ["объяснит", "to explain"],
      VFW: ["объясним", "to explain"],
      VFF: ["объясните", "to explain"],
      VFT: ["объяснят", "to explain"],
      VI1: ["объясни", "to explain"],
      VI2: ["объясните", "to explain"],
      VPM: ["объяснил", "to explain"],
      VPF: ["объяснила", "to explain"],
      VPN: ["объяснило", "to explain"],
      VPP: ["объяснили", "to explain"],
    },
    произнести: {
      VI: ["произнести", "to pronounce, say, utter"],
      VF1: ["произнесу", "to pronounce, say, utter"],
      VF2: ["произнесёшь", "to pronounce, say, utter"],
      VF3: ["произнесёт", "to pronounce, say, utter"],
      VFW: ["произнесём", "to pronounce, say, utter"],
      VFF: ["произнесёте", "to pronounce, say, utter"],
      VFT: ["произнесут", "to pronounce, say, utter"],
      VI1: ["произнеси", "to pronounce, say, utter"],
      VI2: ["произнесите", "to pronounce, say, utter"],
      VPM: ["произнёс", "to pronounce, say, utter"],
      VPF: ["произнесла", "to pronounce, say, utter"],
      VPN: ["произнесло", "to pronounce, say, utter"],
      VPP: ["произнесли", "to pronounce, say, utter"],
    },
    нравиться: {
      VI: ["нравиться", "to please, like"],
      VN1: ["нравлюсь", "to please, like"],
      VF1: ["буду нравиться", "to please, like"],
      VN2: ["нравишься", "to please, like"],
      VF2: ["будешь нравиться", "to please, like"],
      VN3: ["нравится", "to please, like"],
      VF3: ["будет нравиться", "to please, like"],
      VNW: ["нравимся", "to please, like"],
      VFW: ["будем нравиться", "to please, like"],
      VNF: ["нравитесь", "to please, like"],
      VFF: ["будете нравиться", "to please, like"],
      VNT: ["нравятся", "to please, like"],
      VFT: ["будут нравиться", "to please, like"],
      VI1: ["нравься", "to please, like"],
      VI2: ["нравьтесь", "to please, like"],
      VPM: ["нравился", "to please, like"],
      VPF: ["нравилась", "to please, like"],
      VPN: ["нравилось", "to please, like"],
      VPP: ["нравились", "to please, like"],
    },
    существовать: {
      VI: ["существовать", "to exist, to be"],
      VN1: ["существую", "to exist, to be"],
      VF1: ["буду существовать", "to exist, to be"],
      VN2: ["существуешь", "to exist, to be"],
      VF2: ["будешь существовать", "to exist, to be"],
      VN3: ["существует", "to exist, to be"],
      VF3: ["будет существовать", "to exist, to be"],
      VNW: ["существуем", "to exist, to be"],
      VFW: ["будем существовать", "to exist, to be"],
      VNF: ["существуете", "to exist, to be"],
      VFF: ["будете существовать", "to exist, to be"],
      VNT: ["существуют", "to exist, to be"],
      VFT: ["будут существовать", "to exist, to be"],
      VI1: ["существуй", "to exist, to be"],
      VI2: ["существуйте", "to exist, to be"],
      VPM: ["существовал", "to exist, to be"],
      VPF: ["существовала", "to exist, to be"],
      VPN: ["существовало", "to exist, to be"],
      VPP: ["существовали", "to exist, to be"],
    },
    удаться: {
      VI: ["удаться", "turn out well, succeed, manage"],
      VF1: ["удамся", "turn out well, succeed, manage"],
      VF2: ["удашься", "turn out well, succeed, manage"],
      VF3: ["удастся", "turn out well, succeed, manage"],
      VFW: ["удадимся", "turn out well, succeed, manage"],
      VFF: ["удадитесь", "turn out well, succeed, manage"],
      VFT: ["удадутся", "turn out well, succeed, manage"],
      VI1: ["удайся", "turn out well, succeed, manage"],
      VI2: ["удайтесь", "turn out well, succeed, manage"],
      VPM: ["удался", "turn out well, succeed, manage"],
      VPF: ["удалась", "turn out well, succeed, manage"],
      VPN: ["удалось", "turn out well, succeed, manage"],
      VPP: ["удались", "turn out well, succeed, manage"],
    },
    прийтись: {
      VI: ["прийтись", "to fit, fall; have to"],
      VF1: ["придусь", "to fit, fall; have to"],
      VF2: ["придёшься", "to fit, fall; have to"],
      VF3: ["придётся", "to fit, fall; have to"],
      VFW: ["придёмся", "to fit, fall; have to"],
      VFF: ["придётесь", "to fit, fall; have to"],
      VFT: ["придутся", "to fit, fall; have to"],
      VI1: ["придись", "to fit, fall; have to"],
      VI2: ["придитесь", "to fit, fall; have to"],
      VPM: ["пришёлся", "to fit, fall; have to"],
      VPF: ["пришлась", "to fit, fall; have to"],
      VPN: ["пришлось", "to fit, fall; have to"],
      VPP: ["пришлись", "to fit, fall; have to"],
    },
    представить: {
      VI: ["представить", "to presetn, produce, introduce(See #558)"],
      VF1: ["представлю", "to presetn, produce, introduce(See #558)"],
      VF2: ["представишь", "to presetn, produce, introduce(See #558)"],
      VF3: ["представит", "to presetn, produce, introduce(See #558)"],
      VFW: ["представим", "to presetn, produce, introduce(See #558)"],
      VFF: ["представите", "to presetn, produce, introduce(See #558)"],
      VFT: ["представят", "to presetn, produce, introduce(See #558)"],
      VI1: ["представь", "to presetn, produce, introduce(See #558)"],
      VI2: ["представьте", "to presetn, produce, introduce(See #558)"],
      VPM: ["представил", "to presetn, produce, introduce(See #558)"],
      VPF: ["представила", "to presetn, produce, introduce(See #558)"],
      VPN: ["представило", "to presetn, produce, introduce(See #558)"],
      VPP: ["представили", "to presetn, produce, introduce(See #558)"],
    },
    назвать: {
      VI: ["назвать", "to call, name(See #356)"],
      VF1: ["назову", "to call, name(See #356)"],
      VF2: ["назовёшь", "to call, name(See #356)"],
      VF3: ["назовёт", "to call, name(See #356)"],
      VFW: ["назовём", "to call, name(See #356)"],
      VFF: ["назовёте", "to call, name(See #356)"],
      VFT: ["назовут", "to call, name(See #356)"],
      VI1: ["назови", "to call, name(See #356)"],
      VI2: ["назовите", "to call, name(See #356)"],
      VPM: ["назвал", "to call, name(See #356)"],
      VPF: ["назвала", "to call, name(See #356)"],
      VPN: ["назвало", "to call, name(See #356)"],
      VPP: ["назвали", "to call, name(See #356)"],
    },
    улыбаться: {
      VI: ["улыбаться", "to smile(See #605)"],
      VN1: ["улыбаюсь", "to smile(See #605)"],
      VF1: ["буду улыбаться", "to smile(See #605)"],
      VN2: ["улыбаешься", "to smile(See #605)"],
      VF2: ["будешь улыбаться", "to smile(See #605)"],
      VN3: ["улыбается", "to smile(See #605)"],
      VF3: ["будет улыбаться", "to smile(See #605)"],
      VNW: ["улыбаемся", "to smile(See #605)"],
      VFW: ["будем улыбаться", "to smile(See #605)"],
      VNF: ["улыбаетесь", "to smile(See #605)"],
      VFF: ["будете улыбаться", "to smile(See #605)"],
      VNT: ["улыбаются", "to smile(See #605)"],
      VFT: ["будут улыбаться", "to smile(See #605)"],
      VI1: ["улыбайся", "to smile(See #605)"],
      VI2: ["улыбайтесь", "to smile(See #605)"],
      VPM: ["улыбался", "to smile(See #605)"],
      VPF: ["улыбалась", "to smile(See #605)"],
      VPN: ["улыбалось", "to smile(See #605)"],
      VPP: ["улыбались", "to smile(See #605)"],
    },
    улыбнуться: {
      VI: ["улыбнуться", "to smile(See #599)"],
      VF1: ["улыбнусь", "to smile(See #599)"],
      VF2: ["улыбнёшься", "to smile(See #599)"],
      VF3: ["улыбнётся", "to smile(See #599)"],
      VFW: ["улыбнёмся", "to smile(See #599)"],
      VFF: ["улыбнётесь", "to smile(See #599)"],
      VFT: ["улыбнутся", "to smile(See #599)"],
      VI1: ["улыбнись", "to smile(See #599)"],
      VI2: ["улыбнитесь", "to smile(See #599)"],
      VPM: ["улыбнулся", "to smile(See #599)"],
      VPF: ["улыбнулась", "to smile(See #599)"],
      VPN: ["улыбнулось", "to smile(See #599)"],
      VPP: ["улыбнулись", "to smile(See #599)"],
    },
    смеяться: {
      VI: ["смеяться", "to laugh"],
      VN1: ["смеюсь", "to laugh"],
      VF1: ["буду смеяться", "to laugh"],
      VN2: ["смеёшься", "to laugh"],
      VF2: ["будешь смеяться", "to laugh"],
      VN3: ["смеётся", "to laugh"],
      VF3: ["будет смеяться", "to laugh"],
      VNW: ["смеёмся", "to laugh"],
      VFW: ["будем смеяться", "to laugh"],
      VNF: ["смеётесь", "to laugh"],
      VFF: ["будете смеяться", "to laugh"],
      VNT: ["смеются", "to laugh"],
      VFT: ["будут смеяться", "to laugh"],
      VI1: ["смейся", "to laugh"],
      VI2: ["смейтесь", "to laugh"],
      VPM: ["смеялся", "to laugh"],
      VPF: ["смеялась", "to laugh"],
      VPN: ["смеялось", "to laugh"],
      VPP: ["смеялись", "to laugh"],
    },
    "хватать ": {
      VI: ["хватать ", "to snatch, seize; be sufficient(See #636)"],
      VN1: ["хватаю", "to snatch, seize; be sufficient(See #636)"],
      VF1: ["буду хватать", "to snatch, seize; be sufficient(See #636)"],
      VN2: ["хватаешь", "to snatch, seize; be sufficient(See #636)"],
      VF2: ["будешь хватать", "to snatch, seize; be sufficient(See #636)"],
      VN3: ["хватает", "to snatch, seize; be sufficient(See #636)"],
      VF3: ["будет хватать", "to snatch, seize; be sufficient(See #636)"],
      VNW: ["хватаем", "to snatch, seize; be sufficient(See #636)"],
      VFW: ["будем хватать", "to snatch, seize; be sufficient(See #636)"],
      VNF: ["хватаете", "to snatch, seize; be sufficient(See #636)"],
      VFF: ["будете хватать", "to snatch, seize; be sufficient(See #636)"],
      VNT: ["хватают", "to snatch, seize; be sufficient(See #636)"],
      VFT: ["будут хватать", "to snatch, seize; be sufficient(See #636)"],
      VI1: ["хватай", "to snatch, seize; be sufficient(See #636)"],
      VI2: ["хватайте", "to snatch, seize; be sufficient(See #636)"],
      VPM: ["хватал", "to snatch, seize; be sufficient(See #636)"],
      VPF: ["хватала", "to snatch, seize; be sufficient(See #636)"],
      VPN: ["хватало", "to snatch, seize; be sufficient(See #636)"],
      VPP: ["хватали", "to snatch, seize; be sufficient(See #636)"],
    },
    почувствовать: {
      VI: ["почувствовать", "to feel"],
      VF1: ["почувствую", "to feel"],
      VF2: ["почувствуешь", "to feel"],
      VF3: ["почувствует", "to feel"],
      VFW: ["почувствуем", "to feel"],
      VFF: ["почувствуете", "to feel"],
      VFT: ["почувствуют", "to feel"],
      VI1: ["почувствуй", "to feel"],
      VI2: ["почувствуйте", "to feel"],
      VPM: ["почувствовал", "to feel"],
      VPF: ["почувствовала", "to feel"],
      VPN: ["почувствовало", "to feel"],
      VPP: ["почувствовали", "to feel"],
    },
    принести: {
      VI: ["принести", "to bring; masterrussian"],
      VF1: ["принесу", "to bring; masterrussian"],
      VF2: ["принесёшь", "to bring; masterrussian"],
      VF3: ["принесёт", "to bring; masterrussian"],
      VFW: ["принесём", "to bring; masterrussian"],
      VFF: ["принесёте", "to bring; masterrussian"],
      VFT: ["принесут", "to bring; masterrussian"],
      VI1: ["принеси", "to bring; masterrussian"],
      VI2: ["принесите", "to bring; masterrussian"],
      VPM: ["принёс", "to bring; masterrussian"],
      VPF: ["принесла", "to bring; masterrussian"],
      VPN: ["принесло", "to bring; masterrussian"],
      VPP: ["принесли", "to bring; masterrussian"],
    },
    согласиться: {
      VI: ["согласиться", "to agree, consent"],
      VF1: ["соглашусь", "to agree, consent"],
      VF2: ["согласишься", "to agree, consent"],
      VF3: ["согласится", "to agree, consent"],
      VFW: ["согласимся", "to agree, consent"],
      VFF: ["согласитесь", "to agree, consent"],
      VFT: ["согласятся", "to agree, consent"],
      VI1: ["согласись", "to agree, consent"],
      VI2: ["согласитесь", "to agree, consent"],
      VPM: ["согласился", "to agree, consent"],
      VPF: ["согласилась", "to agree, consent"],
      VPN: ["согласилось", "to agree, consent"],
      VPP: ["согласились", "to agree, consent"],
    },
    хватить: {
      VI: ["хватить", "to snatch, seize, suffice(See #618)"],
      VF1: ["хвачу", "to snatch, seize, suffice(See #618)"],
      VF2: ["хватишь", "to snatch, seize, suffice(See #618)"],
      VF3: ["хватит", "to snatch, seize, suffice(See #618)"],
      VFW: ["хватим", "to snatch, seize, suffice(See #618)"],
      VFF: ["хватите", "to snatch, seize, suffice(See #618)"],
      VFT: ["хватят", "to snatch, seize, suffice(See #618)"],
      VI1: ["хвати", "to snatch, seize, suffice(See #618)"],
      VI2: ["хватите", "to snatch, seize, suffice(See #618)"],
      VPM: ["хватил", "to snatch, seize, suffice(See #618)"],
      VPF: ["хватила", "to snatch, seize, suffice(See #618)"],
      VPN: ["хватило", "to snatch, seize, suffice(See #618)"],
      VPP: ["хватили", "to snatch, seize, suffice(See #618)"],
    },
    принимать: {
      VI: ["принимать", "to take, admit, accept (See #481)"],
      VN1: ["принимаю", "to take, admit, accept (See #481)"],
      VF1: ["буду принимать", "to take, admit, accept (See #481)"],
      VN2: ["принимаешь", "to take, admit, accept (See #481)"],
      VF2: ["будешь принимать", "to take, admit, accept (See #481)"],
      VN3: ["принимает", "to take, admit, accept (See #481)"],
      VF3: ["будет принимать", "to take, admit, accept (See #481)"],
      VNW: ["принимаем", "to take, admit, accept (See #481)"],
      VFW: ["будем принимать", "to take, admit, accept (See #481)"],
      VNF: ["принимаете", "to take, admit, accept (See #481)"],
      VFF: ["будете принимать", "to take, admit, accept (See #481)"],
      VNT: ["принимают", "to take, admit, accept (See #481)"],
      VFT: ["будут принимать", "to take, admit, accept (See #481)"],
      VI1: ["принимай", "to take, admit, accept (See #481)"],
      VI2: ["принимайте", "to take, admit, accept (See #481)"],
      VPM: ["принимал", "to take, admit, accept (See #481)"],
      VPF: ["принимала", "to take, admit, accept (See #481)"],
      VPN: ["принимало", "to take, admit, accept (See #481)"],
      VPP: ["принимали", "to take, admit, accept (See #481)"],
    },
    убить: {
      VI: ["убить", "to kill"],
      VF1: ["убью", "to kill"],
      VF2: ["убьёшь", "to kill"],
      VF3: ["убьёт", "to kill"],
      VFW: ["убьём", "to kill"],
      VFF: ["убьёте", "to kill"],
      VFT: ["убьют", "to kill"],
      VI1: ["убей", "to kill"],
      VI2: ["убейте", "to kill"],
      VPM: ["убил", "to kill"],
      VPF: ["убила", "to kill"],
      VPN: ["убило", "to kill"],
      VPP: ["убили", "to kill"],
    },
    предложить: {
      VI: ["предложить", "to offer, propose, suggest"],
      VF1: ["предложу", "to offer, propose, suggest"],
      VF2: ["предложишь", "to offer, propose, suggest"],
      VF3: ["предложит", "to offer, propose, suggest"],
      VFW: ["предложим", "to offer, propose, suggest"],
      VFF: ["предложите", "to offer, propose, suggest"],
      VFT: ["предложат", "to offer, propose, suggest"],
      VI1: ["предложи", "to offer, propose, suggest"],
      VI2: ["предложите", "to offer, propose, suggest"],
      VPM: ["предложил", "to offer, propose, suggest"],
      VPF: ["предложила", "to offer, propose, suggest"],
      VPN: ["предложило", "to offer, propose, suggest"],
      VPP: ["предложили", "to offer, propose, suggest"],
    },
    приходиться: {
      VI: ["приходиться", "to fit, fall; have to"],
      VN1: ["прихожусь", "to fit, fall; have to"],
      VF1: ["буду приходиться", "to fit, fall; have to"],
      VN2: ["приходишься", "to fit, fall; have to"],
      VF2: ["будешь приходиться", "to fit, fall; have to"],
      VN3: ["приходится", "to fit, fall; have to"],
      VF3: ["будет приходиться", "to fit, fall; have to"],
      VNW: ["приходимся", "to fit, fall; have to"],
      VFW: ["будем приходиться", "to fit, fall; have to"],
      VNF: ["приходитесь", "to fit, fall; have to"],
      VFF: ["будете приходиться", "to fit, fall; have to"],
      VNT: ["приходятся", "to fit, fall; have to"],
      VFT: ["будут приходиться", "to fit, fall; have to"],
      VI1: ["приходись", "to fit, fall; have to"],
      VI2: ["приходитесь", "to fit, fall; have to"],
      VPM: ["приходился", "to fit, fall; have to"],
      VPF: ["приходилась", "to fit, fall; have to"],
      VPN: ["приходилось", "to fit, fall; have to"],
      VPP: ["приходились", "to fit, fall; have to"],
    },
    выпить: {
      VI: ["выпить", "to drink"],
      VF1: ["выпью", "to drink"],
      VF2: ["выпьешь", "to drink"],
      VF3: ["выпьет", "to drink"],
      VFW: ["выпьем", "to drink"],
      VFF: ["выпьете", "to drink"],
      VFT: ["выпьют", "to drink"],
      VI1: ["выпей", "to drink"],
      VI2: ["выпейте", "to drink"],
      VPM: ["выпил", "to drink"],
      VPF: ["выпила", "to drink"],
      VPN: ["выпило", "to drink"],
      VPP: ["выпили", "to drink"],
    },
    крикнуть: {
      VI: ["крикнуть", "to cry, shout(See #447)"],
      VF1: ["крикну", "to cry, shout(See #447)"],
      VF2: ["крикнешь", "to cry, shout(See #447)"],
      VF3: ["крикнет", "to cry, shout(See #447)"],
      VFW: ["крикнем", "to cry, shout(See #447)"],
      VFF: ["крикнете", "to cry, shout(See #447)"],
      VFT: ["крикнут", "to cry, shout(See #447)"],
      VI1: ["крикни", "to cry, shout(See #447)"],
      VI2: ["крикните", "to cry, shout(See #447)"],
      VPM: ["крикнул", "to cry, shout(See #447)"],
      VPF: ["крикнула", "to cry, shout(See #447)"],
      VPN: ["крикнуло", "to cry, shout(See #447)"],
      VPP: ["крикнули", "to cry, shout(See #447)"],
    },
    показывать: {
      VI: ["показывать", "to show, display"],
      VN1: ["показываю", "to show, display"],
      VF1: ["буду показывать", "to show, display"],
      VN2: ["показываешь", "to show, display"],
      VF2: ["будешь показывать", "to show, display"],
      VN3: ["показывает", "to show, display"],
      VF3: ["будет показывать", "to show, display"],
      VNW: ["показываем", "to show, display"],
      VFW: ["будем показывать", "to show, display"],
      VNF: ["показываете", "to show, display"],
      VFF: ["будете показывать", "to show, display"],
      VNT: ["показывают", "to show, display"],
      VFT: ["будут показывать", "to show, display"],
      VI1: ["показывай", "to show, display"],
      VI2: ["показывайте", "to show, display"],
      VPM: ["показывал", "to show, display"],
      VPF: ["показывала", "to show, display"],
      VPN: ["показывало", "to show, display"],
      VPP: ["показывали", "to show, display"],
    },
    вызвать: {
      VI: ["вызвать", "to call, send(See #821)"],
      VF1: ["вызову", "to call, send(See #821)"],
      VF2: ["вызовешь", "to call, send(See #821)"],
      VF3: ["вызовет", "to call, send(See #821)"],
      VFW: ["вызовем", "to call, send(See #821)"],
      VFF: ["вызовете", "to call, send(See #821)"],
      VFT: ["вызовут", "to call, send(See #821)"],
      VI1: ["вызови", "to call, send(See #821)"],
      VI2: ["вызовите", "to call, send(See #821)"],
      VPM: ["вызвал", "to call, send(See #821)"],
      VPF: ["вызвала", "to call, send(See #821)"],
      VPN: ["вызвало", "to call, send(See #821)"],
      VPP: ["вызвали", "to call, send(See #821)"],
    },
    попросить: {
      VI: ["попросить", "to ask"],
      VF1: ["попрошу", "to ask"],
      VF2: ["попросишь", "to ask"],
      VF3: ["попросит", "to ask"],
      VFW: ["попросим", "to ask"],
      VFF: ["попросите", "to ask"],
      VFT: ["попросят", "to ask"],
      VI1: ["попроси", "to ask"],
      VI2: ["попросите", "to ask"],
      VPM: ["попросил", "to ask"],
      VPF: ["попросила", "to ask"],
      VPN: ["попросило", "to ask"],
      VPP: ["попросили", "to ask"],
    },
    оказываться: {
      VI: ["оказываться", "to turn out, find oneself(See #183)"],
      VN1: ["оказываюсь", "to turn out, find oneself(See #183)"],
      VF1: ["буду оказываться", "to turn out, find oneself(See #183)"],
      VN2: ["оказываешься", "to turn out, find oneself(See #183)"],
      VF2: ["будешь оказываться", "to turn out, find oneself(See #183)"],
      VN3: ["оказывается", "to turn out, find oneself(See #183)"],
      VF3: ["будет оказываться", "to turn out, find oneself(See #183)"],
      VNW: ["оказываемся", "to turn out, find oneself(See #183)"],
      VFW: ["будем оказываться", "to turn out, find oneself(See #183)"],
      VNF: ["оказываетесь", "to turn out, find oneself(See #183)"],
      VFF: ["будете оказываться", "to turn out, find oneself(See #183)"],
      VNT: ["оказываются", "to turn out, find oneself(See #183)"],
      VFT: ["будут оказываться", "to turn out, find oneself(See #183)"],
      VI1: ["оказывайся", "to turn out, find oneself(See #183)"],
      VI2: ["оказывайтесь", "to turn out, find oneself(See #183)"],
      VPM: ["оказывался", "to turn out, find oneself(See #183)"],
      VPF: ["оказывалась", "to turn out, find oneself(See #183)"],
      VPN: ["оказывалось", "to turn out, find oneself(See #183)"],
      VPP: ["оказывались", "to turn out, find oneself(See #183)"],
    },
    привести: {
      VI: ["привести", "to bring"],
      VF1: ["приведу", "to bring"],
      VF2: ["приведёшь", "to bring"],
      VF3: ["приведёт", "to bring"],
      VFW: ["приведём", "to bring"],
      VFF: ["приведёте", "to bring"],
      VFT: ["приведут", "to bring"],
      VI1: ["приведи", "to bring"],
      VI2: ["приведите", "to bring"],
      VPM: ["привёл", "to bring"],
      VPF: ["привела", "to bring"],
      VPN: ["привело", "to bring"],
      VPP: ["привели", "to bring"],
    },
    возвращаться: {
      VI: ["возвращаться", "to return"],
      VN1: ["возвращаюсь", "to return"],
      VF1: ["буду возвращаться", "to return"],
      VN2: ["возвращаешься", "to return"],
      VF2: ["будешь возвращаться", "to return"],
      VN3: ["возвращается", "to return"],
      VF3: ["будет возвращаться", "to return"],
      VNW: ["возвращаемся", "to return"],
      VFW: ["будем возвращаться", "to return"],
      VNF: ["возвращаетесь", "to return"],
      VFF: ["будете возвращаться", "to return"],
      VNT: ["возвращаются", "to return"],
      VFT: ["будут возвращаться", "to return"],
      VI1: ["возвращайся", "to return"],
      VI2: ["возвращайтесь", "to return"],
      VPM: ["возвращался", "to return"],
      VPF: ["возвращалась", "to return"],
      VPN: ["возвращалось", "to return"],
      VPP: ["возвращались", "to return"],
    },
    требовать: {
      VI: ["требовать", "to demand"],
      VN1: ["требую", "to demand"],
      VF1: ["буду требовать", "to demand"],
      VN2: ["требуешь", "to demand"],
      VF2: ["будешь требовать", "to demand"],
      VN3: ["требует", "to demand"],
      VF3: ["будет требовать", "to demand"],
      VNW: ["требуем", "to demand"],
      VFW: ["будем требовать", "to demand"],
      VNF: ["требуете", "to demand"],
      VFF: ["будете требовать", "to demand"],
      VNT: ["требуют", "to demand"],
      VFT: ["будут требовать", "to demand"],
      VI1: ["требуй", "to demand"],
      VI2: ["требуйте", "to demand"],
      VPM: ["требовал", "to demand"],
      VPF: ["требовала", "to demand"],
      VPN: ["требовало", "to demand"],
      VPP: ["требовали", "to demand"],
    },
    потерять: {
      VI: ["потерять", "to lose"],
      VF1: ["потеряю", "to lose"],
      VF2: ["потеряешь", "to lose"],
      VF3: ["потеряет", "to lose"],
      VFW: ["потеряем", "to lose"],
      VFF: ["потеряете", "to lose"],
      VFT: ["потеряют", "to lose"],
      VI1: ["потеряй", "to lose"],
      VI2: ["потеряйте", "to lose"],
      VPM: ["потерял", "to lose"],
      VPF: ["потеряла", "to lose"],
      VPN: ["потеряло", "to lose"],
      VPP: ["потеряли", "to lose"],
    },
    достать: {
      VI: ["достать", "to reach; get, obtain"],
      VF1: ["достану", "to reach; get, obtain"],
      VF2: ["достанешь", "to reach; get, obtain"],
      VF3: ["достанет", "to reach; get, obtain"],
      VFW: ["достанем", "to reach; get, obtain"],
      VFF: ["достанете", "to reach; get, obtain"],
      VFT: ["достанут", "to reach; get, obtain"],
      VI1: ["достань", "to reach; get, obtain"],
      VI2: ["достаньте", "to reach; get, obtain"],
      VPM: ["достал", "to reach; get, obtain"],
      VPF: ["достала", "to reach; get, obtain"],
      VPN: ["достало", "to reach; get, obtain"],
      VPP: ["достали", "to reach; get, obtain"],
    },
    сообщить: {
      VI: ["сообщить", "to report, let know"],
      VF1: ["сообщу", "to report, let know"],
      VF2: ["сообщишь", "to report, let know"],
      VF3: ["сообщит", "to report, let know"],
      VFW: ["сообщим", "to report, let know"],
      VFF: ["сообщите", "to report, let know"],
      VFT: ["сообщат", "to report, let know"],
      VI1: ["сообщи", "to report, let know"],
      VI2: ["сообщите", "to report, let know"],
      VPM: ["сообщил", "to report, let know"],
      VPF: ["сообщила", "to report, let know"],
      VPN: ["сообщило", "to report, let know"],
      VPP: ["сообщили", "to report, let know"],
    },
    появляться: {
      VI: ["появляться", "to appear, show up(See #335)"],
      VN1: ["появляюсь", "to appear, show up(See #335)"],
      VF1: ["буду появляться", "to appear, show up(See #335)"],
      VN2: ["появляешься", "to appear, show up(See #335)"],
      VF2: ["будешь появляться", "to appear, show up(See #335)"],
      VN3: ["появляется", "to appear, show up(See #335)"],
      VF3: ["будет появляться", "to appear, show up(See #335)"],
      VNW: ["появляемся", "to appear, show up(See #335)"],
      VFW: ["будем появляться", "to appear, show up(See #335)"],
      VNF: ["появляетесь", "to appear, show up(See #335)"],
      VFF: ["будете появляться", "to appear, show up(See #335)"],
      VNT: ["появляются", "to appear, show up(See #335)"],
      VFT: ["будут появляться", "to appear, show up(See #335)"],
      VI1: ["появляйся", "to appear, show up(See #335)"],
      VI2: ["появляйтесь", "to appear, show up(See #335)"],
      VPM: ["появлялся", "to appear, show up(See #335)"],
      VPF: ["появлялась", "to appear, show up(See #335)"],
      VPN: ["появлялось", "to appear, show up(See #335)"],
      VPP: ["появлялись", "to appear, show up(See #335)"],
    },
    упасть: {
      VI: ["упасть", "to fall"],
      VF1: ["упаду", "to fall"],
      VF2: ["упадёшь", "to fall"],
      VF3: ["упадёт", "to fall"],
      VFW: ["упадём", "to fall"],
      VFF: ["упадёте", "to fall"],
      VFT: ["упадут", "to fall"],
      VI1: ["упади", "to fall"],
      VI2: ["упадите", "to fall"],
      VPM: ["упал", "to fall"],
      VPF: ["упала", "to fall"],
      VPN: ["упало", "to fall"],
      VPP: ["упали", "to fall"],
    },
    получиться: {
      VI: ["получиться", "to come, arrive, turn out"],
      VF1: ["получусь", "to come, arrive, turn out"],
      VF2: ["получишься", "to come, arrive, turn out"],
      VF3: ["получится", "to come, arrive, turn out"],
      VFW: ["получимся", "to come, arrive, turn out"],
      VFF: ["получитесь", "to come, arrive, turn out"],
      VFT: ["получатся", "to come, arrive, turn out"],
      VI1: ["получись", "to come, arrive, turn out"],
      VI2: ["получитесь", "to come, arrive, turn out"],
      VPM: ["получился", "to come, arrive, turn out"],
      VPF: ["получилась", "to come, arrive, turn out"],
      VPN: ["получилось", "to come, arrive, turn out"],
      VPP: ["получились", "to come, arrive, turn out"],
    },
    провести: {
      VI: ["провести", "to accompany, set off; conduct; spend (See #973)"],
      VF1: ["проведу", "to accompany, set off; conduct; spend (See #973)"],
      VF2: ["проведёшь", "to accompany, set off; conduct; spend (See #973)"],
      VF3: ["проведёт", "to accompany, set off; conduct; spend (See #973)"],
      VFW: ["проведём", "to accompany, set off; conduct; spend (See #973)"],
      VFF: ["проведёте", "to accompany, set off; conduct; spend (See #973)"],
      VFT: ["проведут", "to accompany, set off; conduct; spend (See #973)"],
      VI1: ["проведи", "to accompany, set off; conduct; spend (See #973)"],
      VI2: ["проведите", "to accompany, set off; conduct; spend (See #973)"],
      VPM: ["провёл", "to accompany, set off; conduct; spend (See #973)"],
      VPF: ["провела", "to accompany, set off; conduct; spend (See #973)"],
      VPN: ["провело", "to accompany, set off; conduct; spend (See #973)"],
      VPP: ["провели", "to accompany, set off; conduct; spend (See #973)"],
    },
    простить: {
      VI: ["простить", "to forgive"],
      VF1: ["прощу", "to forgive"],
      VF2: ["простишь", "to forgive"],
      VF3: ["простит", "to forgive"],
      VFW: ["простим", "to forgive"],
      VFF: ["простите", "to forgive"],
      VFT: ["простят", "to forgive"],
      VI1: ["прости", "to forgive"],
      VI2: ["простите", "to forgive"],
      VPM: ["простил", "to forgive"],
      VPF: ["простила", "to forgive"],
      VPN: ["простило", "to forgive"],
      VPP: ["простили", "to forgive"],
    },
    бить: {
      VI: ["бить", "to beat, hit"],
      VN1: ["бью", "to beat, hit"],
      VF1: ["буду бить", "to beat, hit"],
      VN2: ["бьёшь", "to beat, hit"],
      VF2: ["будешь бить", "to beat, hit"],
      VN3: ["бьёт", "to beat, hit"],
      VF3: ["будет бить", "to beat, hit"],
      VNW: ["бьём", "to beat, hit"],
      VFW: ["будем бить", "to beat, hit"],
      VNF: ["бьёте", "to beat, hit"],
      VFF: ["будете бить", "to beat, hit"],
      VNT: ["бьют", "to beat, hit"],
      VFT: ["будут бить", "to beat, hit"],
      VI1: ["бей", "to beat, hit"],
      VI2: ["бейте", "to beat, hit"],
      VPM: ["бил", "to beat, hit"],
      VPF: ["била", "to beat, hit"],
      VPN: ["било", "to beat, hit"],
      VPP: ["били", "to beat, hit"],
    },
    кивнуть: {
      VI: ["кивнуть", "to nod"],
      VF1: ["кивну", "to nod"],
      VF2: ["кивнёшь", "to nod"],
      VF3: ["кивнёт", "to nod"],
      VFW: ["кивнём", "to nod"],
      VFF: ["кивнёте", "to nod"],
      VFT: ["кивнут", "to nod"],
      VI1: ["кивни", "to nod"],
      VI2: ["кивните", "to nod"],
      VPM: ["кивнул", "to nod"],
      VPF: ["кивнула", "to nod"],
      VPN: ["кивнуло", "to nod"],
      VPP: ["кивнули", "to nod"],
    },
    исчезнуть: {
      VI: ["исчезнуть", "to disappear"],
      VF1: ["исчезну", "to disappear"],
      VF2: ["исчезнешь", "to disappear"],
      VF3: ["исчезнет", "to disappear"],
      VFW: ["исчезнем", "to disappear"],
      VFF: ["исчезнете", "to disappear"],
      VFT: ["исчезнут", "to disappear"],
      VI1: ["исчезни", "to disappear"],
      VI2: ["исчезните", "to disappear"],
      VPM: ["исчез", "to disappear"],
      VPF: ["исчезла исчезнула", "to disappear"],
      VPN: ["исчезло исчезнуло", "to disappear"],
      VPP: ["исчезли исчезнули", "to disappear"],
    },
    отдать: {
      VI: ["отдать", "to return, give back"],
      VF1: ["отдам", "to return, give back"],
      VF2: ["отдашь", "to return, give back"],
      VF3: ["отдаст", "to return, give back"],
      VFW: ["отдадим", "to return, give back"],
      VFF: ["отдадите", "to return, give back"],
      VFT: ["отдадут", "to return, give back"],
      VI1: ["отдай", "to return, give back"],
      VI2: ["отдайте", "to return, give back"],
      VPM: ["отдал", "to return, give back"],
      VPF: ["отдала", "to return, give back"],
      VPN: ["отдало", "to return, give back"],
      VPP: ["отдали", "to return, give back"],
    },
    помочь: {
      VI: ["помочь", "to help (See #857)"],
      VF1: ["помогу", "to help (See #857)"],
      VF2: ["поможешь", "to help (See #857)"],
      VF3: ["поможет", "to help (See #857)"],
      VFW: ["поможем", "to help (See #857)"],
      VFF: ["поможете", "to help (See #857)"],
      VFT: ["помогут", "to help (See #857)"],
      VI1: ["помоги", "to help (See #857)"],
      VI2: ["помогите", "to help (See #857)"],
      VPM: ["помог", "to help (See #857)"],
      VPF: ["помогла", "to help (See #857)"],
      VPN: ["помогло", "to help (See #857)"],
      VPP: ["помогли", "to help (See #857)"],
    },
    слать: {
      VI: ["слать", "to send"],
      VN1: ["шлю", "to send"],
      VF1: ["буду слать", "to send"],
      VN2: ["шлёшь", "to send"],
      VF2: ["будешь слать", "to send"],
      VN3: ["шлёт", "to send"],
      VF3: ["будет слать", "to send"],
      VNW: ["шлём", "to send"],
      VFW: ["будем слать", "to send"],
      VNF: ["шлёте", "to send"],
      VFF: ["будете слать", "to send"],
      VNT: ["шлют", "to send"],
      VFT: ["будут слать", "to send"],
      VI1: ["шли", "to send"],
      VI2: ["шлите", "to send"],
      VPM: ["слал", "to send"],
      VPF: ["слала", "to send"],
      VPN: ["слало", "to send"],
      VPP: ["слали", "to send"],
    },
    вспоминать: {
      VI: ["вспоминать", "to remember, recall"],
      VN1: ["вспоминаю", "to remember, recall"],
      VF1: ["буду вспоминать", "to remember, recall"],
      VN2: ["вспоминаешь", "to remember, recall"],
      VF2: ["будешь вспоминать", "to remember, recall"],
      VN3: ["вспоминает", "to remember, recall"],
      VF3: ["будет вспоминать", "to remember, recall"],
      VNW: ["вспоминаем", "to remember, recall"],
      VFW: ["будем вспоминать", "to remember, recall"],
      VNF: ["вспоминаете", "to remember, recall"],
      VFF: ["будете вспоминать", "to remember, recall"],
      VNT: ["вспоминают", "to remember, recall"],
      VFT: ["будут вспоминать", "to remember, recall"],
      VI1: ["вспоминай", "to remember, recall"],
      VI2: ["вспоминайте", "to remember, recall"],
      VPM: ["вспоминал", "to remember, recall"],
      VPF: ["вспоминала", "to remember, recall"],
      VPN: ["вспоминало", "to remember, recall"],
      VPP: ["вспоминали", "to remember, recall"],
    },
    вздохнуть: {
      VI: ["вздохнуть", "to take breath, to sigh"],
      VF1: ["вздохну", "to take breath, to sigh"],
      VF2: ["вздохнёшь", "to take breath, to sigh"],
      VF3: ["вздохнёт", "to take breath, to sigh"],
      VFW: ["вздохнём", "to take breath, to sigh"],
      VFF: ["вздохнёте", "to take breath, to sigh"],
      VFT: ["вздохнут", "to take breath, to sigh"],
      VI1: ["вздохни", "to take breath, to sigh"],
      VI2: ["вздохните", "to take breath, to sigh"],
      VPM: ["вздохнул", "to take breath, to sigh"],
      VPF: ["вздохнула", "to take breath, to sigh"],
      VPN: ["вздохнуло", "to take breath, to sigh"],
      VPP: ["вздохнули", "to take breath, to sigh"],
    },
    петь: {
      VI: ["петь", "to sing"],
      VN1: ["пою", "to sing"],
      VF1: ["буду петь", "to sing"],
      VN2: ["поёшь", "to sing"],
      VF2: ["будешь петь", "to sing"],
      VN3: ["поёт", "to sing"],
      VF3: ["будет петь", "to sing"],
      VNW: ["поём", "to sing"],
      VFW: ["будем петь", "to sing"],
      VNF: ["поёте", "to sing"],
      VFF: ["будете петь", "to sing"],
      VNT: ["поют", "to sing"],
      VFT: ["будут петь", "to sing"],
      VI1: ["пой", "to sing"],
      VI2: ["пойте", "to sing"],
      VPM: ["пел", "to sing"],
      VPF: ["пела", "to sing"],
      VPN: ["пело", "to sing"],
      VPP: ["пели", "to sing"],
    },
    садиться: {
      VI: ["садиться", "to sit down"],
      VN1: ["сажусь", "to sit down"],
      VF1: ["буду садиться", "to sit down"],
      VN2: ["садишься", "to sit down"],
      VF2: ["будешь садиться", "to sit down"],
      VN3: ["садится", "to sit down"],
      VF3: ["будет садиться", "to sit down"],
      VNW: ["садимся", "to sit down"],
      VFW: ["будем садиться", "to sit down"],
      VNF: ["садитесь", "to sit down"],
      VFF: ["будете садиться", "to sit down"],
      VNT: ["садятся", "to sit down"],
      VFT: ["будут садиться", "to sit down"],
      VI1: ["садись", "to sit down"],
      VI2: ["садитесь", "to sit down"],
      VPM: ["садился", "to sit down"],
      VPF: ["садилась", "to sit down"],
      VPN: ["садилось", "to sit down"],
      VPP: ["садились", "to sit down"],
    },
    перестать: {
      VI: ["перестать", "to stop, cease"],
      VF1: ["перестану", "to stop, cease"],
      VF2: ["перестанешь", "to stop, cease"],
      VF3: ["перестанет", "to stop, cease"],
      VFW: ["перестанем", "to stop, cease"],
      VFF: ["перестанете", "to stop, cease"],
      VFT: ["перестанут", "to stop, cease"],
      VI1: ["перестань", "to stop, cease"],
      VI2: ["перестаньте", "to stop, cease"],
      VPM: ["перестал", "to stop, cease"],
      VPF: ["перестала", "to stop, cease"],
      VPN: ["перестало", "to stop, cease"],
      VPP: ["перестали", "to stop, cease"],
    },
    придтись: {
      VI: ["придтись", "to fit, fall; have to"],
      VF1: ["придусь", "to fit, fall; have to"],
      VF2: ["придёшься", "to fit, fall; have to"],
      VF3: ["придётся", "to fit, fall; have to"],
      VFW: ["придёмся", "to fit, fall; have to"],
      VFF: ["придётесь", "to fit, fall; have to"],
      VFT: ["придутся", "to fit, fall; have to"],
      VI1: ["придись", "to fit, fall; have to"],
      VI2: ["придитесь", "to fit, fall; have to"],
      VPM: ["пришёлся", "to fit, fall; have to"],
      VPF: ["пришлась", "to fit, fall; have to"],
      VPN: ["пришлось", "to fit, fall; have to"],
      VPP: ["пришлись", "to fit, fall; have to"],
    },
    пожаловать: {
      VI: ["пожаловать", "to grant, bestow"],
      VF1: ["пожалую", "to grant, bestow"],
      VF2: ["пожалуешь", "to grant, bestow"],
      VF3: ["пожалует", "to grant, bestow"],
      VFW: ["пожалуем", "to grant, bestow"],
      VFF: ["пожалуете", "to grant, bestow"],
      VFT: ["пожалуют", "to grant, bestow"],
      VI1: ["пожалуй", "to grant, bestow"],
      VI2: ["пожалуйте", "to grant, bestow"],
      VPM: ["пожаловал", "to grant, bestow"],
      VPF: ["пожаловала", "to grant, bestow"],
      VPN: ["пожаловало", "to grant, bestow"],
      VPP: ["пожаловали", "to grant, bestow"],
    },
    получать: {
      VI: ["получать", "to receive, get (See #239)"],
      VN1: ["получаю", "to receive, get (See #239)"],
      VF1: ["буду получать", "to receive, get (See #239)"],
      VN2: ["получаешь", "to receive, get (See #239)"],
      VF2: ["будешь получать", "to receive, get (See #239)"],
      VN3: ["получает", "to receive, get (See #239)"],
      VF3: ["будет получать", "to receive, get (See #239)"],
      VNW: ["получаем", "to receive, get (See #239)"],
      VFW: ["будем получать", "to receive, get (See #239)"],
      VNF: ["получаете", "to receive, get (See #239)"],
      VFF: ["будете получать", "to receive, get (See #239)"],
      VNT: ["получают", "to receive, get (See #239)"],
      VFT: ["будут получать", "to receive, get (See #239)"],
      VI1: ["получай", "to receive, get (See #239)"],
      VI2: ["получайте", "to receive, get (See #239)"],
      VPM: ["получал", "to receive, get (See #239)"],
      VPF: ["получала", "to receive, get (See #239)"],
      VPN: ["получало", "to receive, get (See #239)"],
      VPP: ["получали", "to receive, get (See #239)"],
    },
    называться: {
      VI: ["называться", "to call oneself"],
      VN1: ["называюсь", "to call oneself"],
      VF1: ["буду называться", "to call oneself"],
      VN2: ["называешься", "to call oneself"],
      VF2: ["будешь называться", "to call oneself"],
      VN3: ["называется", "to call oneself"],
      VF3: ["будет называться", "to call oneself"],
      VNW: ["называемся", "to call oneself"],
      VFW: ["будем называться", "to call oneself"],
      VNF: ["называетесь", "to call oneself"],
      VFF: ["будете называться", "to call oneself"],
      VNT: ["называются", "to call oneself"],
      VFT: ["будут называться", "to call oneself"],
      VI1: ["называйся", "to call oneself"],
      VI2: ["называйтесь", "to call oneself"],
      VPM: ["назывался", "to call oneself"],
      VPF: ["называлась", "to call oneself"],
      VPN: ["называлось", "to call oneself"],
      VPP: ["назывались", "to call oneself"],
    },
    надеяться: {
      VI: ["надеяться", "to hope"],
      VN1: ["надеюсь", "to hope"],
      VF1: ["буду надеяться", "to hope"],
      VN2: ["надеешься", "to hope"],
      VF2: ["будешь надеяться", "to hope"],
      VN3: ["надеется", "to hope"],
      VF3: ["будет надеяться", "to hope"],
      VNW: ["надеемся", "to hope"],
      VFW: ["будем надеяться", "to hope"],
      VNF: ["надеетесь", "to hope"],
      VFF: ["будете надеяться", "to hope"],
      VNT: ["надеются", "to hope"],
      VFT: ["будут надеяться", "to hope"],
      VI1: ["надейся", "to hope"],
      VI2: ["надейтесь", "to hope"],
      VPM: ["надеялся", "to hope"],
      VPF: ["надеялась", "to hope"],
      VPN: ["надеялось", "to hope"],
      VPP: ["надеялись", "to hope"],
    },
    протянуть: {
      VI: ["протянуть", "to stretch, extend"],
      VF1: ["протяну", "to stretch, extend"],
      VF2: ["протянешь", "to stretch, extend"],
      VF3: ["протянет", "to stretch, extend"],
      VFW: ["протянем", "to stretch, extend"],
      VFF: ["протянете", "to stretch, extend"],
      VFT: ["протянут", "to stretch, extend"],
      VI1: ["протяни", "to stretch, extend"],
      VI2: ["протяните", "to stretch, extend"],
      VPM: ["протянул", "to stretch, extend"],
      VPF: ["протянула", "to stretch, extend"],
      VPN: ["протянуло", "to stretch, extend"],
      VPP: ["протянули", "to stretch, extend"],
    },
    подходить: {
      VI: ["подходить", "to come up, approach;to fit(See #361)"],
      VN1: ["подхожу", "to come up, approach;to fit(See #361)"],
      VF1: ["буду подходить", "to come up, approach;to fit(See #361)"],
      VN2: ["подходишь", "to come up, approach;to fit(See #361)"],
      VF2: ["будешь подходить", "to come up, approach;to fit(See #361)"],
      VN3: ["подходит", "to come up, approach;to fit(See #361)"],
      VF3: ["будет подходить", "to come up, approach;to fit(See #361)"],
      VNW: ["подходим", "to come up, approach;to fit(See #361)"],
      VFW: ["будем подходить", "to come up, approach;to fit(See #361)"],
      VNF: ["подходите", "to come up, approach;to fit(See #361)"],
      VFF: ["будете подходить", "to come up, approach;to fit(See #361)"],
      VNT: ["подходят", "to come up, approach;to fit(See #361)"],
      VFT: ["будут подходить", "to come up, approach;to fit(See #361)"],
      VI1: ["подходи", "to come up, approach;to fit(See #361)"],
      VI2: ["подходите", "to come up, approach;to fit(See #361)"],
      VPM: ["подходил", "to come up, approach;to fit(See #361)"],
      VPF: ["подходила", "to come up, approach;to fit(See #361)"],
      VPN: ["подходило", "to come up, approach;to fit(See #361)"],
      VPP: ["подходили", "to come up, approach;to fit(See #361)"],
    },
    служить: {
      VI: ["служить", "to serve"],
      VN1: ["служу", "to serve"],
      VF1: ["буду служить", "to serve"],
      VN2: ["служишь", "to serve"],
      VF2: ["будешь служить", "to serve"],
      VN3: ["служит", "to serve"],
      VF3: ["будет служить", "to serve"],
      VNW: ["служим", "to serve"],
      VFW: ["будем служить", "to serve"],
      VNF: ["служите", "to serve"],
      VFF: ["будете служить", "to serve"],
      VNT: ["служат", "to serve"],
      VFT: ["будут служить", "to serve"],
      VI1: ["служи", "to serve"],
      VI2: ["служите", "to serve"],
      VPM: ["служил", "to serve"],
      VPF: ["служила", "to serve"],
      VPN: ["служило", "to serve"],
      VPP: ["служили", "to serve"],
    },
    поверить: {
      VI: ["поверить", "to believe"],
      VF1: ["поверю", "to believe"],
      VF2: ["поверишь", "to believe"],
      VF3: ["поверит", "to believe"],
      VFW: ["поверим", "to believe"],
      VFF: ["поверите", "to believe"],
      VFT: ["поверят", "to believe"],
      VI1: ["поверь", "to believe"],
      VI2: ["поверьте", "to believe"],
      VPM: ["поверил", "to believe"],
      VPF: ["поверила", "to believe"],
      VPN: ["поверило", "to believe"],
      VPP: ["поверили", "to believe"],
    },
    взглянуть: {
      VI: ["взглянуть", "to look, give a glance"],
      VF1: ["взгляну", "to look, give a glance"],
      VF2: ["взглянешь", "to look, give a glance"],
      VF3: ["взглянет", "to look, give a glance"],
      VFW: ["взглянем", "to look, give a glance"],
      VFF: ["взглянете", "to look, give a glance"],
      VFT: ["взглянут", "to look, give a glance"],
      VI1: ["взгляни", "to look, give a glance"],
      VI2: ["взгляните", "to look, give a glance"],
      VPM: ["взглянул", "to look, give a glance"],
      VPF: ["взглянула", "to look, give a glance"],
      VPN: ["взглянуло", "to look, give a glance"],
      VPP: ["взглянули", "to look, give a glance"],
    },
    вызывать: {
      VI: ["вызывать", "to call, send"],
      VN1: ["вызываю", "to call, send"],
      VF1: ["буду вызывать", "to call, send"],
      VN2: ["вызываешь", "to call, send"],
      VF2: ["будешь вызывать", "to call, send"],
      VN3: ["вызывает", "to call, send"],
      VF3: ["будет вызывать", "to call, send"],
      VNW: ["вызываем", "to call, send"],
      VFW: ["будем вызывать", "to call, send"],
      VNF: ["вызываете", "to call, send"],
      VFF: ["будете вызывать", "to call, send"],
      VNT: ["вызывают", "to call, send"],
      VFT: ["будут вызывать", "to call, send"],
      VI1: ["вызывай", "to call, send"],
      VI2: ["вызывайте", "to call, send"],
      VPM: ["вызывал", "to call, send"],
      VPF: ["вызывала", "to call, send"],
      VPN: ["вызывало", "to call, send"],
      VPP: ["вызывали", "to call, send"],
    },
    ожидать: {
      VI: ["ожидать", "to expect"],
      VN1: ["ожидаю", "to expect"],
      VF1: ["буду ожидать", "to expect"],
      VN2: ["ожидаешь", "to expect"],
      VF2: ["будешь ожидать", "to expect"],
      VN3: ["ожидает", "to expect"],
      VF3: ["будет ожидать", "to expect"],
      VNW: ["ожидаем", "to expect"],
      VFW: ["будем ожидать", "to expect"],
      VNF: ["ожидаете", "to expect"],
      VFF: ["будете ожидать", "to expect"],
      VNT: ["ожидают", "to expect"],
      VFT: ["будут ожидать", "to expect"],
      VI1: ["ожидай", "to expect"],
      VI2: ["ожидайте", "to expect"],
      VPM: ["ожидал", "to expect"],
      VPF: ["ожидала", "to expect"],
      VPN: ["ожидало", "to expect"],
      VPP: ["ожидали", "to expect"],
    },
    висеть: {
      VI: ["висеть", "to hang"],
      VN1: ["вишу", "to hang"],
      VF1: ["буду висеть", "to hang"],
      VN2: ["висишь", "to hang"],
      VF2: ["будешь висеть", "to hang"],
      VN3: ["висит", "to hang"],
      VF3: ["будет висеть", "to hang"],
      VNW: ["висим", "to hang"],
      VFW: ["будем висеть", "to hang"],
      VNF: ["висите", "to hang"],
      VFF: ["будете висеть", "to hang"],
      VNT: ["висят", "to hang"],
      VFT: ["будут висеть", "to hang"],
      VI1: ["виси", "to hang"],
      VI2: ["висите", "to hang"],
      VPM: ["висел", "to hang"],
      VPF: ["висела", "to hang"],
      VPN: ["висело", "to hang"],
      VPP: ["висели", "to hang"],
    },
    создать: {
      VI: ["создать", "to create, establish"],
      VF1: ["создам", "to create, establish"],
      VF2: ["создашь", "to create, establish"],
      VF3: ["создаст", "to create, establish"],
      VFW: ["создадим", "to create, establish"],
      VFF: ["создадите", "to create, establish"],
      VFT: ["создадут", "to create, establish"],
      VI1: ["создай", "to create, establish"],
      VI2: ["создайте", "to create, establish"],
      VPM: ["создал", "to create, establish"],
      VPF: ["создала", "to create, establish"],
      VPN: ["создало", "to create, establish"],
      VPP: ["создали", "to create, establish"],
    },
    уехать: {
      VI: ["уехать", "to leave, depart"],
      VF1: ["уеду", "to leave, depart"],
      VF2: ["уедешь", "to leave, depart"],
      VF3: ["уедет", "to leave, depart"],
      VFW: ["уедем", "to leave, depart"],
      VFF: ["уедете", "to leave, depart"],
      VFT: ["уедут", "to leave, depart"],
      VI1: ["уезжай", "to leave, depart"],
      VI2: ["уезжайте", "to leave, depart"],
      VPM: ["уехал", "to leave, depart"],
      VPF: ["уехала", "to leave, depart"],
      VPN: ["уехало", "to leave, depart"],
      VPP: ["уехали", "to leave, depart"],
    },
    выглядеть: {
      VI: ["выглядеть", "to look"],
      VN1: ["выгляжу", "to look"],
      VF1: ["буду выглядеть", "to look"],
      VN2: ["выглядишь", "to look"],
      VF2: ["будешь выглядеть", "to look"],
      VN3: ["выглядит", "to look"],
      VF3: ["будет выглядеть", "to look"],
      VNW: ["выглядим", "to look"],
      VFW: ["будем выглядеть", "to look"],
      VNF: ["выглядите", "to look"],
      VFF: ["будете выглядеть", "to look"],
      VNT: ["выглядят", "to look"],
      VFT: ["будут выглядеть", "to look"],
      VI1: ["выгляди", "to look"],
      VI2: ["выглядите", "to look"],
      VPM: ["выглядел", "to look"],
      VPF: ["выглядела", "to look"],
      VPN: ["выглядело", "to look"],
      VPP: ["выглядели", "to look"],
    },
    учиться: {
      VI: ["учиться", "to study, learn"],
      VN1: ["учусь", "to study, learn"],
      VF1: ["буду учиться", "to study, learn"],
      VN2: ["учишься", "to study, learn"],
      VF2: ["будешь учиться", "to study, learn"],
      VN3: ["учится", "to study, learn"],
      VF3: ["будет учиться", "to study, learn"],
      VNW: ["учимся", "to study, learn"],
      VFW: ["будем учиться", "to study, learn"],
      VNF: ["учитесь", "to study, learn"],
      VFF: ["будете учиться", "to study, learn"],
      VNT: ["учатся", "to study, learn"],
      VFT: ["будут учиться", "to study, learn"],
      VI1: ["учись", "to study, learn"],
      VI2: ["учитесь", "to study, learn"],
      VPM: ["учился", "to study, learn"],
      VPF: ["училась", "to study, learn"],
      VPN: ["училось", "to study, learn"],
      VPP: ["учились", "to study, learn"],
    },
    разговаривать: {
      VI: ["разговаривать", "to talk, speak"],
      VN1: ["разговариваю", "to talk, speak"],
      VF1: ["буду разговаривать", "to talk, speak"],
      VN2: ["разговариваешь", "to talk, speak"],
      VF2: ["будешь разговаривать", "to talk, speak"],
      VN3: ["разговаривает", "to talk, speak"],
      VF3: ["будет разговаривать", "to talk, speak"],
      VNW: ["разговариваем", "to talk, speak"],
      VFW: ["будем разговаривать", "to talk, speak"],
      VNF: ["разговариваете", "to talk, speak"],
      VFF: ["будете разговаривать", "to talk, speak"],
      VNT: ["разговаривают", "to talk, speak"],
      VFT: ["будут разговаривать", "to talk, speak"],
      VI1: ["разговаривай", "to talk, speak"],
      VI2: ["разговаривайте", "to talk, speak"],
      VPM: ["разговаривал", "to talk, speak"],
      VPF: ["разговаривала", "to talk, speak"],
      VPN: ["разговаривало", "to talk, speak"],
      VPP: ["разговаривали", "to talk, speak"],
    },
    помогать: {
      VI: ["помогать", "to help, assist(See #736)"],
      VN1: ["помогаю", "to help, assist(See #736)"],
      VF1: ["буду помогать", "to help, assist(See #736)"],
      VN2: ["помогаешь", "to help, assist(See #736)"],
      VF2: ["будешь помогать", "to help, assist(See #736)"],
      VN3: ["помогает", "to help, assist(See #736)"],
      VF3: ["будет помогать", "to help, assist(See #736)"],
      VNW: ["помогаем", "to help, assist(See #736)"],
      VFW: ["будем помогать", "to help, assist(See #736)"],
      VNF: ["помогаете", "to help, assist(See #736)"],
      VFF: ["будете помогать", "to help, assist(See #736)"],
      VNT: ["помогают", "to help, assist(See #736)"],
      VFT: ["будут помогать", "to help, assist(See #736)"],
      VI1: ["помогай", "to help, assist(See #736)"],
      VI2: ["помогайте", "to help, assist(See #736)"],
      VPM: ["помогал", "to help, assist(See #736)"],
      VPF: ["помогала", "to help, assist(See #736)"],
      VPN: ["помогало", "to help, assist(See #736)"],
      VPP: ["помогали", "to help, assist(See #736)"],
    },
    повернуться: {
      VI: ["повернуться", "to turn"],
      VF1: ["повернусь", "to turn"],
      VF2: ["повернёшься", "to turn"],
      VF3: ["повернётся", "to turn"],
      VFW: ["повернёмся", "to turn"],
      VFF: ["повернётесь", "to turn"],
      VFT: ["повернутся", "to turn"],
      VI1: ["повернись", "to turn"],
      VI2: ["повернитесь", "to turn"],
      VPM: ["повернулся", "to turn"],
      VPF: ["повернулась", "to turn"],
      VPN: ["повернулось", "to turn"],
      VPP: ["повернулись", "to turn"],
    },
    позволить: {
      VI: ["позволить", "to allow, permit"],
      VF1: ["позволю", "to allow, permit"],
      VF2: ["позволишь", "to allow, permit"],
      VF3: ["позволит", "to allow, permit"],
      VFW: ["позволим", "to allow, permit"],
      VFF: ["позволите", "to allow, permit"],
      VFT: ["позволят", "to allow, permit"],
      VI1: ["позволь", "to allow, permit"],
      VI2: ["позвольте", "to allow, permit"],
      VPM: ["позволил", "to allow, permit"],
      VPF: ["позволила", "to allow, permit"],
      VPN: ["позволило", "to allow, permit"],
      VPP: ["позволили", "to allow, permit"],
    },
    встретить: {
      VI: ["встретить", "to meet"],
      VF1: ["встречу", "to meet"],
      VF2: ["встретишь", "to meet"],
      VF3: ["встретит", "to meet"],
      VFW: ["встретим", "to meet"],
      VFF: ["встретите", "to meet"],
      VFT: ["встретят", "to meet"],
      VI1: ["встреть", "to meet"],
      VI2: ["встретьте", "to meet"],
      VPM: ["встретил", "to meet"],
      VPF: ["встретила", "to meet"],
      VPN: ["встретило", "to meet"],
      VPP: ["встретили", "to meet"],
    },
    входить: {
      VI: ["входить", "to enter, come in"],
      VN1: ["вхожу", "to enter, come in"],
      VF1: ["буду входить", "to enter, come in"],
      VN2: ["входишь", "to enter, come in"],
      VF2: ["будешь входить", "to enter, come in"],
      VN3: ["входит", "to enter, come in"],
      VF3: ["будет входить", "to enter, come in"],
      VNW: ["входим", "to enter, come in"],
      VFW: ["будем входить", "to enter, come in"],
      VNF: ["входите", "to enter, come in"],
      VFF: ["будете входить", "to enter, come in"],
      VNT: ["входят", "to enter, come in"],
      VFT: ["будут входить", "to enter, come in"],
      VI1: ["входи", "to enter, come in"],
      VI2: ["входите", "to enter, come in"],
      VPM: ["входил", "to enter, come in"],
      VPF: ["входила", "to enter, come in"],
      VPN: ["входило", "to enter, come in"],
      VPP: ["входили", "to enter, come in"],
    },
    обнаружить: {
      VI: ["обнаружить", "to discover, find out"],
      VF1: ["обнаружу", "to discover, find out"],
      VF2: ["обнаружишь", "to discover, find out"],
      VF3: ["обнаружит", "to discover, find out"],
      VFW: ["обнаружим", "to discover, find out"],
      VFF: ["обнаружите", "to discover, find out"],
      VFT: ["обнаружат", "to discover, find out"],
      VI1: ["обнаружь", "to discover, find out"],
      VI2: ["обнаружьте", "to discover, find out"],
      VPM: ["обнаружил", "to discover, find out"],
      VPF: ["обнаружила", "to discover, find out"],
      VPN: ["обнаружило", "to discover, find out"],
      VPP: ["обнаружили", "to discover, find out"],
    },
    нести: {
      VI: ["нести", "to carry"],
      VN1: ["несу", "to carry"],
      VF1: ["буду нести", "to carry"],
      VN2: ["несёшь", "to carry"],
      VF2: ["будешь нести", "to carry"],
      VN3: ["несёт", "to carry"],
      VF3: ["будет нести", "to carry"],
      VNW: ["несём", "to carry"],
      VFW: ["будем нести", "to carry"],
      VNF: ["несёте", "to carry"],
      VFF: ["будете нести", "to carry"],
      VNT: ["несут", "to carry"],
      VFT: ["будут нести", "to carry"],
      VI1: ["неси", "to carry"],
      VI2: ["несите", "to carry"],
      VPM: ["нёс", "to carry"],
      VPF: ["несла", "to carry"],
      VPN: ["несло", "to carry"],
      VPP: ["несли", "to carry"],
    },
    прислать: {
      VI: ["прислать", "to send"],
      VF1: ["пришлю", "to send"],
      VF2: ["пришлёшь", "to send"],
      VF3: ["пришлёт", "to send"],
      VFW: ["пришлём", "to send"],
      VFF: ["пришлёте", "to send"],
      VFT: ["пришлют", "to send"],
      VI1: ["пришли", "to send"],
      VI2: ["пришлите", "to send"],
      VPM: ["прислал", "to send"],
      VPF: ["прислала", "to send"],
      VPN: ["прислало", "to send"],
      VPP: ["прислали", "to send"],
    },
    относиться: {
      VI: ["относиться", "to treat, regard, relate"],
      VN1: ["отношусь", "to treat, regard, relate"],
      VF1: ["буду относиться", "to treat, regard, relate"],
      VN2: ["относишься", "to treat, regard, relate"],
      VF2: ["будешь относиться", "to treat, regard, relate"],
      VN3: ["относится", "to treat, regard, relate"],
      VF3: ["будет относиться", "to treat, regard, relate"],
      VNW: ["относимся", "to treat, regard, relate"],
      VFW: ["будем относиться", "to treat, regard, relate"],
      VNF: ["относитесь", "to treat, regard, relate"],
      VFF: ["будете относиться", "to treat, regard, relate"],
      VNT: ["относятся", "to treat, regard, relate"],
      VFT: ["будут относиться", "to treat, regard, relate"],
      VI1: ["относись", "to treat, regard, relate"],
      VI2: ["относитесь", "to treat, regard, relate"],
      VPM: ["относился", "to treat, regard, relate"],
      VPF: ["относилась", "to treat, regard, relate"],
      VPN: ["относилось", "to treat, regard, relate"],
      VPP: ["относились", "to treat, regard, relate"],
    },
    возникать: {
      VI: ["возникать", "to arise, appear(See #951)"],
      VN1: ["возникаю", "to arise, appear(See #951)"],
      VF1: ["буду возникать", "to arise, appear(See #951)"],
      VN2: ["возникаешь", "to arise, appear(See #951)"],
      VF2: ["будешь возникать", "to arise, appear(See #951)"],
      VN3: ["возникает", "to arise, appear(See #951)"],
      VF3: ["будет возникать", "to arise, appear(See #951)"],
      VNW: ["возникаем", "to arise, appear(See #951)"],
      VFW: ["будем возникать", "to arise, appear(See #951)"],
      VNF: ["возникаете", "to arise, appear(See #951)"],
      VFF: ["будете возникать", "to arise, appear(See #951)"],
      VNT: ["возникают", "to arise, appear(See #951)"],
      VFT: ["будут возникать", "to arise, appear(See #951)"],
      VI1: ["возникай", "to arise, appear(See #951)"],
      VI2: ["возникайте", "to arise, appear(See #951)"],
      VPM: ["возникал", "to arise, appear(See #951)"],
      VPF: ["возникала", "to arise, appear(See #951)"],
      VPN: ["возникало", "to arise, appear(See #951)"],
      VPP: ["возникали", "to arise, appear(See #951)"],
    },
    повторить: {
      VI: ["повторить", "to repeat"],
      VF1: ["повторю", "to repeat"],
      VF2: ["повторишь", "to repeat"],
      VF3: ["повторит", "to repeat"],
      VFW: ["повторим", "to repeat"],
      VFF: ["повторите", "to repeat"],
      VFT: ["повторят", "to repeat"],
      VI1: ["повтори", "to repeat"],
      VI2: ["повторите", "to repeat"],
      VPM: ["повторил", "to repeat"],
      VPF: ["повторила", "to repeat"],
      VPN: ["повторило", "to repeat"],
      VPP: ["повторили", "to repeat"],
    },
    погибнуть: {
      VI: ["погибнуть", "to perish, be killed"],
      VF1: ["погибну", "to perish, be killed"],
      VF2: ["погибнешь", "to perish, be killed"],
      VF3: ["погибнет", "to perish, be killed"],
      VFW: ["погибнем", "to perish, be killed"],
      VFF: ["погибнете", "to perish, be killed"],
      VFT: ["погибнут", "to perish, be killed"],
      VI1: ["погибни", "to perish, be killed"],
      VI2: ["погибните", "to perish, be killed"],
      VPM: ["погиб погибнул", "to perish, be killed"],
      VPF: ["погибла погибнула", "to perish, be killed"],
      VPN: ["погибло погибнуло", "to perish, be killed"],
      VPP: ["погибли погибнули", "to perish, be killed"],
    },
    гореть: {
      VI: ["гореть", "to burn"],
      VN1: ["горю", "to burn"],
      VF1: ["буду гореть", "to burn"],
      VN2: ["горишь", "to burn"],
      VF2: ["будешь гореть", "to burn"],
      VN3: ["горит", "to burn"],
      VF3: ["будет гореть", "to burn"],
      VNW: ["горим", "to burn"],
      VFW: ["будем гореть", "to burn"],
      VNF: ["горите", "to burn"],
      VFF: ["будете гореть", "to burn"],
      VNT: ["горят", "to burn"],
      VFT: ["будут гореть", "to burn"],
      VI1: ["гори", "to burn"],
      VI2: ["горите", "to burn"],
      VPM: ["горел", "to burn"],
      VPF: ["горела", "to burn"],
      VPN: ["горело", "to burn"],
      VPP: ["горели", "to burn"],
    },
    плакать: {
      VI: ["плакать", "to cry"],
      VN1: ["плачу", "to cry"],
      VF1: ["буду плакать", "to cry"],
      VN2: ["плачешь", "to cry"],
      VF2: ["будешь плакать", "to cry"],
      VN3: ["плачет", "to cry"],
      VF3: ["будет плакать", "to cry"],
      VNW: ["плачем", "to cry"],
      VFW: ["будем плакать", "to cry"],
      VNF: ["плачете", "to cry"],
      VFF: ["будете плакать", "to cry"],
      VNT: ["плачут", "to cry"],
      VFT: ["будут плакать", "to cry"],
      VI1: ["плачь", "to cry"],
      VI2: ["плачьте", "to cry"],
      VPM: ["плакал", "to cry"],
      VPF: ["плакала", "to cry"],
      VPN: ["плакало", "to cry"],
      VPP: ["плакали", "to cry"],
    },
    двигаться: {
      VI: ["двигаться", "to move"],
      VN1: ["движусь", "to move"],
      VF1: ["буду двигаться", "to move"],
      VN2: ["движешься", "to move"],
      VF2: ["будешь двигаться", "to move"],
      VN3: ["движется", "to move"],
      VF3: ["будет двигаться", "to move"],
      VNW: ["движемся", "to move"],
      VFW: ["будем двигаться", "to move"],
      VNF: ["движетесь", "to move"],
      VFF: ["будете двигаться", "to move"],
      VNT: ["движутся", "to move"],
      VFT: ["будут двигаться", "to move"],
      VI1: ["движься", "to move"],
      VI2: ["движьтесь", "to move"],
      VPM: ["двигался", "to move"],
      VPF: ["двигалась", "to move"],
      VPN: ["двигалось", "to move"],
      VPP: ["двигались", "to move"],
    },
    добавить: {
      VI: ["добавить", "to add"],
      VF1: ["добавлю", "to add"],
      VF2: ["добавишь", "to add"],
      VF3: ["добавит", "to add"],
      VFW: ["добавим", "to add"],
      VFF: ["добавите", "to add"],
      VFT: ["добавят", "to add"],
      VI1: ["добавь", "to add"],
      VI2: ["добавьте", "to add"],
      VPM: ["добавил", "to add"],
      VPF: ["добавила", "to add"],
      VPN: ["добавило", "to add"],
      VPP: ["добавили", "to add"],
    },
    удивиться: {
      VI: ["удивиться", "to wonder, be surprised"],
      VF1: ["удивлюсь", "to wonder, be surprised"],
      VF2: ["удивишься", "to wonder, be surprised"],
      VF3: ["удивится", "to wonder, be surprised"],
      VFW: ["удивимся", "to wonder, be surprised"],
      VFF: ["удивитесь", "to wonder, be surprised"],
      VFT: ["удивятся", "to wonder, be surprised"],
      VI1: ["удивись", "to wonder, be surprised"],
      VI2: ["удивитесь", "to wonder, be surprised"],
      VPM: ["удивился", "to wonder, be surprised"],
      VPF: ["удивилась", "to wonder, be surprised"],
      VPN: ["удивилось", "to wonder, be surprised"],
      VPP: ["удивились", "to wonder, be surprised"],
    },
    действовать: {
      VI: ["действовать", "to act"],
      VN1: ["действую", "to act"],
      VF1: ["буду действовать", "to act"],
      VN2: ["действуешь", "to act"],
      VF2: ["будешь действовать", "to act"],
      VN3: ["действует", "to act"],
      VF3: ["будет действовать", "to act"],
      VNW: ["действуем", "to act"],
      VFW: ["будем действовать", "to act"],
      VNF: ["действуете", "to act"],
      VFF: ["будете действовать", "to act"],
      VNT: ["действуют", "to act"],
      VFT: ["будут действовать", "to act"],
      VI1: ["действуй", "to act"],
      VI2: ["действуйте", "to act"],
      VPM: ["действовал", "to act"],
      VPF: ["действовала", "to act"],
      VPN: ["действовало", "to act"],
      VPP: ["действовали", "to act"],
    },
    держаться: {
      VI: ["держаться", "to hold on, behave"],
      VN1: ["держусь", "to hold on, behave"],
      VF1: ["буду держаться", "to hold on, behave"],
      VN2: ["держишься", "to hold on, behave"],
      VF2: ["будешь держаться", "to hold on, behave"],
      VN3: ["держится", "to hold on, behave"],
      VF3: ["будет держаться", "to hold on, behave"],
      VNW: ["держимся", "to hold on, behave"],
      VFW: ["будем держаться", "to hold on, behave"],
      VNF: ["держитесь", "to hold on, behave"],
      VFF: ["будете держаться", "to hold on, behave"],
      VNT: ["держатся", "to hold on, behave"],
      VFT: ["будут держаться", "to hold on, behave"],
      VI1: ["держись", "to hold on, behave"],
      VI2: ["держитесь", "to hold on, behave"],
      VPM: ["держался", "to hold on, behave"],
      VPF: ["держалась", "to hold on, behave"],
      VPN: ["держалось", "to hold on, behave"],
      VPP: ["держались", "to hold on, behave"],
    },
    лететь: {
      VI: ["лететь", "to fly"],
      VN1: ["лечу", "to fly"],
      VF1: ["буду лететь", "to fly"],
      VN2: ["летишь", "to fly"],
      VF2: ["будешь лететь", "to fly"],
      VN3: ["летит", "to fly"],
      VF3: ["будет лететь", "to fly"],
      VNW: ["летим", "to fly"],
      VFW: ["будем лететь", "to fly"],
      VNF: ["летите", "to fly"],
      VFF: ["будете лететь", "to fly"],
      VNT: ["летят", "to fly"],
      VFT: ["будут лететь", "to fly"],
      VI1: ["лети", "to fly"],
      VI2: ["летите", "to fly"],
      VPM: ["летел", "to fly"],
      VPF: ["летела", "to fly"],
      VPN: ["летело", "to fly"],
      VPP: ["летели", "to fly"],
    },
    носить: {
      VI: ["носить", "to wear"],
      VN1: ["ношу", "to wear"],
      VF1: ["буду носить", "to wear"],
      VN2: ["носишь", "to wear"],
      VF2: ["будешь носить", "to wear"],
      VN3: ["носит", "to wear"],
      VF3: ["будет носить", "to wear"],
      VNW: ["носим", "to wear"],
      VFW: ["будем носить", "to wear"],
      VNF: ["носите", "to wear"],
      VFF: ["будете носить", "to wear"],
      VNT: ["носят", "to wear"],
      VFT: ["будут носить", "to wear"],
      VI1: ["носи", "to wear"],
      VI2: ["носите", "to wear"],
      VPM: ["носил", "to wear"],
      VPF: ["носила", "to wear"],
      VPN: ["носило", "to wear"],
      VPP: ["носили", "to wear"],
    },
    привыкнуть: {
      VI: ["привыкнуть", "to get used to, to grow accustomed to"],
      VF1: ["привыкну", "to get used to, to grow accustomed to"],
      VF2: ["привыкнешь", "to get used to, to grow accustomed to"],
      VF3: ["привыкнет", "to get used to, to grow accustomed to"],
      VFW: ["привыкнем", "to get used to, to grow accustomed to"],
      VFF: ["привыкнете", "to get used to, to grow accustomed to"],
      VFT: ["привыкнут", "to get used to, to grow accustomed to"],
      VI1: ["привыкни", "to get used to, to grow accustomed to"],
      VI2: ["привыкните", "to get used to, to grow accustomed to"],
      VPM: ["привык", "to get used to, to grow accustomed to"],
      VPF: ["привыкла", "to get used to, to grow accustomed to"],
      VPN: ["привыкло", "to get used to, to grow accustomed to"],
      VPP: ["привыкли", "to get used to, to grow accustomed to"],
    },
    позвонить: {
      VI: ["позвонить", "to call, to telephone"],
      VF1: ["позвоню", "to call, to telephone"],
      VF2: ["позвонишь", "to call, to telephone"],
      VF3: ["позвонит", "to call, to telephone"],
      VFW: ["позвоним", "to call, to telephone"],
      VFF: ["позвоните", "to call, to telephone"],
      VFT: ["позвонят", "to call, to telephone"],
      VI1: ["позвони", "to call, to telephone"],
      VI2: ["позвоните", "to call, to telephone"],
      VPM: ["позвонил", "to call, to telephone"],
      VPF: ["позвонила", "to call, to telephone"],
      VPN: ["позвонило", "to call, to telephone"],
      VPP: ["позвонили", "to call, to telephone"],
    },
    мешать: {
      VI: ["мешать", "to interfere, prevent; mix"],
      VN1: ["мешаю", "to interfere, prevent; mix"],
      VF1: ["буду мешать", "to interfere, prevent; mix"],
      VN2: ["мешаешь", "to interfere, prevent; mix"],
      VF2: ["будешь мешать", "to interfere, prevent; mix"],
      VN3: ["мешает", "to interfere, prevent; mix"],
      VF3: ["будет мешать", "to interfere, prevent; mix"],
      VNW: ["мешаем", "to interfere, prevent; mix"],
      VFW: ["будем мешать", "to interfere, prevent; mix"],
      VNF: ["мешаете", "to interfere, prevent; mix"],
      VFF: ["будете мешать", "to interfere, prevent; mix"],
      VNT: ["мешают", "to interfere, prevent; mix"],
      VFT: ["будут мешать", "to interfere, prevent; mix"],
      VI1: ["мешай", "to interfere, prevent; mix"],
      VI2: ["мешайте", "to interfere, prevent; mix"],
      VPM: ["мешал", "to interfere, prevent; mix"],
      VPF: ["мешала", "to interfere, prevent; mix"],
      VPN: ["мешало", "to interfere, prevent; mix"],
      VPP: ["мешали", "to interfere, prevent; mix"],
    },
    возникнуть: {
      VI: ["возникнуть", "to arise, spring up(See #892)"],
      VF1: ["возникну", "to arise, spring up(See #892)"],
      VF2: ["возникнешь", "to arise, spring up(See #892)"],
      VF3: ["возникнет", "to arise, spring up(See #892)"],
      VFW: ["возникнем", "to arise, spring up(See #892)"],
      VFF: ["возникнете", "to arise, spring up(See #892)"],
      VFT: ["возникнут", "to arise, spring up(See #892)"],
      VI1: ["возникни", "to arise, spring up(See #892)"],
      VI2: ["возникните", "to arise, spring up(See #892)"],
      VPM: ["возник возникнул", "to arise, spring up(See #892)"],
      VPF: ["возникла возникнула", "to arise, spring up(See #892)"],
      VPN: ["возникло возникнуло", "to arise, spring up(See #892)"],
      VPP: ["возникли возникнули", "to arise, spring up(See #892)"],
    },
    желать: {
      VI: ["желать", "to wish"],
      VN1: ["желаю", "to wish"],
      VF1: ["буду желать", "to wish"],
      VN2: ["желаешь", "to wish"],
      VF2: ["будешь желать", "to wish"],
      VN3: ["желает", "to wish"],
      VF3: ["будет желать", "to wish"],
      VNW: ["желаем", "to wish"],
      VFW: ["будем желать", "to wish"],
      VNF: ["желаете", "to wish"],
      VFF: ["будете желать", "to wish"],
      VNT: ["желают", "to wish"],
      VFT: ["будут желать", "to wish"],
      VI1: ["желай", "to wish"],
      VI2: ["желайте", "to wish"],
      VPM: ["желал", "to wish"],
      VPF: ["желала", "to wish"],
      VPN: ["желало", "to wish"],
      VPP: ["желали", "to wish"],
    },
    попробовать: {
      VI: ["попробовать", "to try, test"],
      VF1: ["попробую", "to try, test"],
      VF2: ["попробуешь", "to try, test"],
      VF3: ["попробует", "to try, test"],
      VFW: ["попробуем", "to try, test"],
      VFF: ["попробуете", "to try, test"],
      VFT: ["попробуют", "to try, test"],
      VI1: ["попробуй", "to try, test"],
      VI2: ["попробуйте", "to try, test"],
      VPM: ["попробовал", "to try, test"],
      VPF: ["попробовала", "to try, test"],
      VPN: ["попробовало", "to try, test"],
      VPP: ["попробовали", "to try, test"],
    },
    получаться: {
      VI: ["получаться", "to come out"],
      VN1: ["получаюсь", "to come out"],
      VF1: ["буду получаться", "to come out"],
      VN2: ["получаешься", "to come out"],
      VF2: ["будешь получаться", "to come out"],
      VN3: ["получается", "to come out"],
      VF3: ["будет получаться", "to come out"],
      VNW: ["получаемся", "to come out"],
      VFW: ["будем получаться", "to come out"],
      VNF: ["получаетесь", "to come out"],
      VFF: ["будете получаться", "to come out"],
      VNT: ["получаются", "to come out"],
      VFT: ["будут получаться", "to come out"],
      VI1: ["получайся", "to come out"],
      VI2: ["получайтесь", "to come out"],
      VPM: ["получался", "to come out"],
      VPF: ["получалась", "to come out"],
      VPN: ["получалось", "to come out"],
      VPP: ["получались", "to come out"],
    },
    ставить: {
      VI: ["ставить", "to put, place, set"],
      VN1: ["ставлю", "to put, place, set"],
      VF1: ["буду ставить", "to put, place, set"],
      VN2: ["ставишь", "to put, place, set"],
      VF2: ["будешь ставить", "to put, place, set"],
      VN3: ["ставит", "to put, place, set"],
      VF3: ["будет ставить", "to put, place, set"],
      VNW: ["ставим", "to put, place, set"],
      VFW: ["будем ставить", "to put, place, set"],
      VNF: ["ставите", "to put, place, set"],
      VFF: ["будете ставить", "to put, place, set"],
      VNT: ["ставят", "to put, place, set"],
      VFT: ["будут ставить", "to put, place, set"],
      VI1: ["ставь", "to put, place, set"],
      VI2: ["ставьте", "to put, place, set"],
      VPM: ["ставил", "to put, place, set"],
      VPF: ["ставила", "to put, place, set"],
      VPN: ["ставило", "to put, place, set"],
      VPP: ["ставили", "to put, place, set"],
    },
    проводить: {
      VI: ["проводить", "to lead, escort; accompany(See #713)"],
      VN1: ["провожу", "to lead, escort; accompany(See #713)"],
      VF1: ["буду проводить", "to lead, escort; accompany(See #713)"],
      VN2: ["проводишь", "to lead, escort; accompany(See #713)"],
      VF2: ["будешь проводить", "to lead, escort; accompany(See #713)"],
      VN3: ["проводит", "to lead, escort; accompany(See #713)"],
      VF3: ["будет проводить", "to lead, escort; accompany(See #713)"],
      VNW: ["проводим", "to lead, escort; accompany(See #713)"],
      VFW: ["будем проводить", "to lead, escort; accompany(See #713)"],
      VNF: ["проводите", "to lead, escort; accompany(See #713)"],
      VFF: ["будете проводить", "to lead, escort; accompany(See #713)"],
      VNT: ["проводят", "to lead, escort; accompany(See #713)"],
      VFT: ["будут проводить", "to lead, escort; accompany(See #713)"],
      VI1: ["проводи", "to lead, escort; accompany(See #713)"],
      VI2: ["проводите", "to lead, escort; accompany(See #713)"],
      VPM: ["проводил", "to lead, escort; accompany(See #713)"],
      VPF: ["проводила", "to lead, escort; accompany(See #713)"],
      VPN: ["проводило", "to lead, escort; accompany(See #713)"],
      VPP: ["проводили", "to lead, escort; accompany(See #713)"],
    },
    обещать: {
      VI: ["обещать", "to promise"],
      VN1: ["обещаю", "to promise"],
      VF1: ["буду обещать", "to promise"],
      VN2: ["обещаешь", "to promise"],
      VF2: ["будешь обещать", "to promise"],
      VN3: ["обещает", "to promise"],
      VF3: ["будет обещать", "to promise"],
      VNW: ["обещаем", "to promise"],
      VFW: ["будем обещать", "to promise"],
      VNF: ["обещаете", "to promise"],
      VFF: ["будете обещать", "to promise"],
      VNT: ["обещают", "to promise"],
      VFT: ["будут обещать", "to promise"],
      VI1: ["обещай", "to promise"],
      VI2: ["обещайте", "to promise"],
      VPM: ["обещал", "to promise"],
      VPF: ["обещала", "to promise"],
      VPN: ["обещало", "to promise"],
      VPP: ["обещали", "to promise"],
    },
    судить: {
      VI: ["судить", "to judge, try"],
      VN1: ["сужу", "to judge, try"],
      VF1: ["буду судить", "to judge, try"],
      VN2: ["судишь", "to judge, try"],
      VF2: ["будешь судить", "to judge, try"],
      VN3: ["судит", "to judge, try"],
      VF3: ["будет судить", "to judge, try"],
      VNW: ["судим", "to judge, try"],
      VFW: ["будем судить", "to judge, try"],
      VNF: ["судите", "to judge, try"],
      VFF: ["будете судить", "to judge, try"],
      VNT: ["судят", "to judge, try"],
      VFT: ["будут судить", "to judge, try"],
      VI1: ["суди", "to judge, try"],
      VI2: ["судите", "to judge, try"],
      VPM: ["судил", "to judge, try"],
      VPF: ["судила", "to judge, try"],
      VPN: ["судило", "to judge, try"],
      VPP: ["судили", "to judge, try"],
    },
    собраться: {
      VI: ["собраться", "to gather, collect(See #479)"],
      VF1: ["соберусь", "to gather, collect(See #479)"],
      VF2: ["соберёшься", "to gather, collect(See #479)"],
      VF3: ["соберётся", "to gather, collect(See #479)"],
      VFW: ["соберёмся", "to gather, collect(See #479)"],
      VFF: ["соберётесь", "to gather, collect(See #479)"],
      VFT: ["соберутся", "to gather, collect(See #479)"],
      VI1: ["соберись", "to gather, collect(See #479)"],
      VI2: ["соберитесь", "to gather, collect(See #479)"],
      VPM: ["собрался", "to gather, collect(See #479)"],
      VPF: ["собралась", "to gather, collect(See #479)"],
      VPN: ["собралось собралось", "to gather, collect(See #479)"],
      VPP: ["собрались собрались", "to gather, collect(See #479)"],
    },
    колоть: {
      VI: ["колоть", "to prick"],
      VN1: ["колю", "to prick"],
      VF1: ["буду колоть", "to prick"],
      VN2: ["колешь", "to prick"],
      VF2: ["будешь колоть", "to prick"],
      VN3: ["колет", "to prick"],
      VF3: ["будет колоть", "to prick"],
      VNW: ["колем", "to prick"],
      VFW: ["будем колоть", "to prick"],
      VNF: ["колете", "to prick"],
      VFF: ["будете колоть", "to prick"],
      VNT: ["колют", "to prick"],
      VFT: ["будут колоть", "to prick"],
      VI1: ["коли", "to prick"],
      VI2: ["колите", "to prick"],
      VPM: ["колол", "to prick"],
      VPF: ["колола", "to prick"],
      VPN: ["кололо", "to prick"],
      VPP: ["кололи", "to prick"],
    },
    закричать: {
      VI: ["закричать", "to shout, cry out"],
      VF1: ["закричу", "to shout, cry out"],
      VF2: ["закричишь", "to shout, cry out"],
      VF3: ["закричит", "to shout, cry out"],
      VFW: ["закричим", "to shout, cry out"],
      VFF: ["закричите", "to shout, cry out"],
      VFT: ["закричат", "to shout, cry out"],
      VI1: ["закричи", "to shout, cry out"],
      VI2: ["закричите", "to shout, cry out"],
      VPM: ["закричал", "to shout, cry out"],
      VPF: ["закричала", "to shout, cry out"],
      VPN: ["закричало", "to shout, cry out"],
      VPP: ["закричали", "to shout, cry out"],
    },
    кончиться: {
      VI: ["кончиться", "to end"],
      VF1: ["кончусь", "to end"],
      VF2: ["кончишься", "to end"],
      VF3: ["кончится", "to end"],
      VFW: ["кончимся", "to end"],
      VFF: ["кончитесь", "to end"],
      VFT: ["кончатся", "to end"],
      VI1: ["кончись", "to end"],
      VI2: ["кончитесь", "to end"],
      VPM: ["кончился", "to end"],
      VPF: ["кончилась", "to end"],
      VPN: ["кончилось", "to end"],
      VPP: ["кончились", "to end"],
    },
    стрелять: {
      VI: ["стрелять", "to shoot"],
      VN1: ["стреляю", "to shoot"],
      VF1: ["буду стрелять", "to shoot"],
      VN2: ["стреляешь", "to shoot"],
      VF2: ["будешь стрелять", "to shoot"],
      VN3: ["стреляет", "to shoot"],
      VF3: ["будет стрелять", "to shoot"],
      VNW: ["стреляем", "to shoot"],
      VFW: ["будем стрелять", "to shoot"],
      VNF: ["стреляете", "to shoot"],
      VFF: ["будете стрелять", "to shoot"],
      VNT: ["стреляют", "to shoot"],
      VFT: ["будут стрелять", "to shoot"],
      VI1: ["стреляй", "to shoot"],
      VI2: ["стреляйте", "to shoot"],
      VPM: ["стрелял", "to shoot"],
      VPF: ["стреляла", "to shoot"],
      VPN: ["стреляло", "to shoot"],
      VPP: ["стреляли", "to shoot"],
    },
    использовать: {
      VI: ["использовать", "to use, utilize, make use of"],
      VN1: ["использую", "to use, utilize, make use of"],
      VF1: ["буду использовать", "to use, utilize, make use of"],
      VN2: ["используешь", "to use, utilize, make use of"],
      VF2: ["будешь использовать", "to use, utilize, make use of"],
      VN3: ["использует", "to use, utilize, make use of"],
      VF3: ["будет использовать", "to use, utilize, make use of"],
      VNW: ["используем", "to use, utilize, make use of"],
      VFW: ["будем использовать", "to use, utilize, make use of"],
      VNF: ["используете", "to use, utilize, make use of"],
      VFF: ["будете использовать", "to use, utilize, make use of"],
      VNT: ["используют", "to use, utilize, make use of"],
      VFT: ["будут использовать", "to use, utilize, make use of"],
      VI1: ["используй", "to use, utilize, make use of"],
      VI2: ["используйте", "to use, utilize, make use of"],
      VPM: ["использовал", "to use, utilize, make use of"],
      VPF: ["использовала", "to use, utilize, make use of"],
      VPN: ["использовало", "to use, utilize, make use of"],
      VPP: ["использовали", "to use, utilize, make use of"],
    },
  };
  
  const adj = {
    тот: {
      ANM: ["тот", "that"],
      ANF: ["та", "that"],
      ANN: ["то", "that"],
      ANP: ["те", "that"],
      AGM: ["того", "that"],
      AGF: ["той", "that"],
      AGN: ["того", "that"],
      AGP: ["тех", "that"],
      ADM: ["тому", "that"],
      ADF: ["той", "that"],
      ADN: ["тому", "that"],
      ADP: ["тем", "that"],
      AAM: ["тот", "that"],
      AAF: ["ту", "that"],
      AAN: ["то", "that"],
      AAP: ["те", "that"],
      AIM: ["тем", "that"],
      AIF: ["той", "that"],
      AIN: ["тем", "that"],
      AIP: ["теми", "that"],
      APM: ["том", "that"],
      APF: ["той", "that"],
      APN: ["том", "that"],
      APP: ["тех", "that"],
    },
    весь: {
      ANM: ["весь", "all, everything"],
      ANF: ["вся", "all, everything"],
      ANN: ["всё", "all, everything"],
      ANP: ["все", "all, everything"],
      AGM: ["всего", "all, everything"],
      AGF: ["всей", "all, everything"],
      AGN: ["всего", "all, everything"],
      AGP: ["всех", "all, everything"],
      ADM: ["всему", "all, everything"],
      ADF: ["всей", "all, everything"],
      ADN: ["всему", "all, everything"],
      ADP: ["всем", "all, everything"],
      AAM: ["весь", "all, everything"],
      AAF: ["всю", "all, everything"],
      AAN: ["всё", "all, everything"],
      AAP: ["все", "all, everything"],
      AIM: ["всем", "all, everything"],
      AIF: ["всей", "all, everything"],
      AIN: ["всем", "all, everything"],
      AIP: ["всеми", "all, everything"],
      APM: ["всём", "all, everything"],
      APF: ["всей", "all, everything"],
      APN: ["всём", "all, everything"],
      APP: ["всех", "all, everything"],
    },
    этот: {
      ANM: ["этот", "this"],
      ANF: ["эта", "this"],
      ANN: ["это", "this"],
      ANP: ["эти", "this"],
      AGM: ["этого", "this"],
      AGF: ["этой", "this"],
      AGN: ["этого", "this"],
      AGP: ["этих", "this"],
      ADM: ["этому", "this"],
      ADF: ["этой", "this"],
      ADN: ["этому", "this"],
      ADP: ["этим", "this"],
      AAM: ["этот", "this"],
      AAF: ["эту", "this"],
      AAN: ["это", "this"],
      AAP: ["эти", "this"],
      AIM: ["этим", "this"],
      AIF: ["этой", "this"],
      AIN: ["этим", "this"],
      AIP: ["этими", "this"],
      APM: ["этом", "this"],
      APF: ["этой", "this"],
      APN: ["этом", "this"],
      APP: ["этих", "this"],
    },
    который: {
      ANM: ["который", "which, who, that"],
      ANF: ["которая", "which, who, that"],
      ANN: ["которое", "which, who, that"],
      ANP: ["которые", "which, who, that"],
      AGM: ["которого", "which, who, that"],
      AGF: ["которой", "which, who, that"],
      AGN: ["которого", "which, who, that"],
      AGP: ["которых", "which, who, that"],
      ADM: ["которому", "which, who, that"],
      ADF: ["которой", "which, who, that"],
      ADN: ["которому", "which, who, that"],
      ADP: ["которым", "which, who, that"],
      AAM: ["который", "which, who, that"],
      AAF: ["которую", "which, who, that"],
      AAN: ["которое", "which, who, that"],
      AAP: ["которые", "which, who, that"],
      AIM: ["которым", "which, who, that"],
      AIF: ["которой", "which, who, that"],
      AIN: ["которым", "which, who, that"],
      AIP: ["которыми", "which, who, that"],
      APM: ["котором", "which, who, that"],
      APF: ["которой", "which, who, that"],
      APN: ["котором", "which, who, that"],
      APP: ["которых", "which, who, that"],
    },
    такой: {
      ANM: ["такой", "such, so, some"],
      ANF: ["такая", "such, so, some"],
      ANN: ["такое", "such, so, some"],
      ANP: ["такие", "such, so, some"],
      AGM: ["такого", "such, so, some"],
      AGF: ["такой", "such, so, some"],
      AGN: ["такого", "such, so, some"],
      AGP: ["таких", "such, so, some"],
      ADM: ["такому", "such, so, some"],
      ADF: ["такой", "such, so, some"],
      ADN: ["такому", "such, so, some"],
      ADP: ["таким", "such, so, some"],
      AAM: ["такой", "such, so, some"],
      AAF: ["такую", "such, so, some"],
      AAN: ["такое", "such, so, some"],
      AAP: ["такие", "such, so, some"],
      AIM: ["таким", "such, so, some"],
      AIF: ["такой", "such, so, some"],
      AIN: ["таким", "such, so, some"],
      AIP: ["такими", "such, so, some"],
      APM: ["таком", "such, so, some"],
      APF: ["такой", "such, so, some"],
      APN: ["таком", "such, so, some"],
      APP: ["таких", "such, so, some"],
    },
    какой: {
      ANM: ["какой", "what, which, how"],
      ANF: ["какая", "what, which, how"],
      ANN: ["какое", "what, which, how"],
      ANP: ["какие", "what, which, how"],
      AGM: ["какого", "what, which, how"],
      AGF: ["какой", "what, which, how"],
      AGN: ["какого", "what, which, how"],
      AGP: ["каких", "what, which, how"],
      ADM: ["какому", "what, which, how"],
      ADF: ["какой", "what, which, how"],
      ADN: ["какому", "what, which, how"],
      ADP: ["каким", "what, which, how"],
      AAM: ["какой", "what, which, how"],
      AAF: ["какую", "what, which, how"],
      AAN: ["какое", "what, which, how"],
      AAP: ["какие", "what, which, how"],
      AIM: ["каким", "what, which, how"],
      AIF: ["какой", "what, which, how"],
      AIN: ["каким", "what, which, how"],
      AIP: ["какими", "what, which, how"],
      APM: ["каком", "what, which, how"],
      APF: ["какой", "what, which, how"],
      APN: ["каком", "what, which, how"],
      APP: ["каких", "what, which, how"],
    },
    мой: {
      ANM: ["мой", "my, mine"],
      ANF: ["моя", "my, mine"],
      ANN: ["моё", "my, mine"],
      ANP: ["мои", "my, mine"],
      AGM: ["моего", "my, mine"],
      AGF: ["моей", "my, mine"],
      AGN: ["моего", "my, mine"],
      AGP: ["моих", "my, mine"],
      ADM: ["моему", "my, mine"],
      ADF: ["моей", "my, mine"],
      ADN: ["моему", "my, mine"],
      ADP: ["моим", "my, mine"],
      AAM: ["мой", "my, mine"],
      AAF: ["мою", "my, mine"],
      AAN: ["моё", "my, mine"],
      AAP: ["мои", "my, mine"],
      AIM: ["моим", "my, mine"],
      AIF: ["моей", "my, mine"],
      AIN: ["моим", "my, mine"],
      AIP: ["моими", "my, mine"],
      APM: ["моём", "my, mine"],
      APF: ["моей", "my, mine"],
      APN: ["моём", "my, mine"],
      APP: ["моих", "my, mine"],
    },
    самый: {
      ANM: ["самый", "most, the very, the same"],
      ANF: ["самая", "most, the very, the same"],
      ANN: ["самое", "most, the very, the same"],
      ANP: ["самые", "most, the very, the same"],
      AGM: ["самого", "most, the very, the same"],
      AGF: ["самой", "most, the very, the same"],
      AGN: ["самого", "most, the very, the same"],
      AGP: ["самых", "most, the very, the same"],
      ADM: ["самому", "most, the very, the same"],
      ADF: ["самой", "most, the very, the same"],
      ADN: ["самому", "most, the very, the same"],
      ADP: ["самым", "most, the very, the same"],
      AAM: ["самый", "most, the very, the same"],
      AAF: ["самую", "most, the very, the same"],
      AAN: ["самое", "most, the very, the same"],
      AAP: ["самые", "most, the very, the same"],
      AIM: ["самым", "most, the very, the same"],
      AIF: ["самой", "most, the very, the same"],
      AIN: ["самым", "most, the very, the same"],
      AIP: ["самыми", "most, the very, the same"],
      APM: ["самом", "most, the very, the same"],
      APF: ["самой", "most, the very, the same"],
      APN: ["самом", "most, the very, the same"],
      APP: ["самых", "most, the very, the same"],
    },
    большой: {
      ANM: ["большой", "big, large, important"],
      ANF: ["большая", "big, large, important"],
      ANN: ["большое", "big, large, important"],
      ANP: ["большие", "big, large, important"],
      AGM: ["большого", "big, large, important"],
      AGF: ["большой", "big, large, important"],
      AGN: ["большого", "big, large, important"],
      AGP: ["больших", "big, large, important"],
      ADM: ["большому", "big, large, important"],
      ADF: ["большой", "big, large, important"],
      ADN: ["большому", "big, large, important"],
      ADP: ["большим", "big, large, important"],
      AAM: ["большого", "big, large, important"],
      AAF: ["большую", "big, large, important"],
      AAN: ["большое", "big, large, important"],
      AAP: ["больших", "big, large, important"],
      AIM: ["большим", "big, large, important"],
      AIF: ["большой", "big, large, important"],
      AIN: ["большим", "big, large, important"],
      AIP: ["большими", "big, large, important"],
      APM: ["большом", "big, large, important"],
      APF: ["большой", "big, large, important"],
      APN: ["большом", "big, large, important"],
      APP: ["больших", "big, large, important"],
      ASM: ["велик", "big, large, important"],
      ASF: ["велика", "big, large, important"],
      ASN: ["велико", "big, large, important"],
      ASP: ["велики", "big, large, important"],
      AC1: ["больше", "big, large, important"],
      AC2: ["больший", "big, large, important"],
    },
    другой: {
      ANM: ["другой", "other, another, different"],
      ANF: ["другая", "other, another, different"],
      ANN: ["другое", "other, another, different"],
      ANP: ["другие", "other, another, different"],
      AGM: ["другого", "other, another, different"],
      AGF: ["другой", "other, another, different"],
      AGN: ["другого", "other, another, different"],
      AGP: ["других", "other, another, different"],
      ADM: ["другому", "other, another, different"],
      ADF: ["другой", "other, another, different"],
      ADN: ["другому", "other, another, different"],
      ADP: ["другим", "other, another, different"],
      AAM: ["другой", "other, another, different"],
      AAF: ["другую", "other, another, different"],
      AAN: ["другое", "other, another, different"],
      AAP: ["другие", "other, another, different"],
      AIM: ["другим", "other, another, different"],
      AIF: ["другой", "other, another, different"],
      AIN: ["другим", "other, another, different"],
      AIP: ["другими", "other, another, different"],
      APM: ["другом", "other, another, different"],
      APF: ["другой", "other, another, different"],
      APN: ["другом", "other, another, different"],
      APP: ["других", "other, another, different"],
    },
    свой: {
      ANM: ["свой", "my, our, your"],
      ANF: ["своя", "my, our, your"],
      ANN: ["своё", "my, our, your"],
      ANP: ["свои", "my, our, your"],
      AGM: ["своего", "my, our, your"],
      AGF: ["своей", "my, our, your"],
      AGN: ["своего", "my, our, your"],
      AGP: ["своих", "my, our, your"],
      ADM: ["своему", "my, our, your"],
      ADF: ["своей", "my, our, your"],
      ADN: ["своему", "my, our, your"],
      ADP: ["своим", "my, our, your"],
      AAM: ["свой", "my, our, your"],
      AAF: ["свою", "my, our, your"],
      AAN: ["своё", "my, our, your"],
      AAP: ["свои", "my, our, your"],
      AIM: ["своим", "my, our, your"],
      AIF: ["своей", "my, our, your"],
      AIN: ["своим", "my, our, your"],
      AIP: ["своими", "my, our, your"],
      APM: ["своём", "my, our, your"],
      APF: ["своей", "my, our, your"],
      APN: ["своём", "my, our, your"],
      APP: ["своих", "my, our, your"],
    },
    сам: {
      ANM: ["сам", "myself, yourself"],
      ANF: ["сама", "myself, yourself"],
      ANN: ["само", "myself, yourself"],
      ANP: ["сами", "myself, yourself"],
      AGM: ["самого", "myself, yourself"],
      AGF: ["самой", "myself, yourself"],
      AGN: ["самого", "myself, yourself"],
      AGP: ["самих", "myself, yourself"],
      ADM: ["самому", "myself, yourself"],
      ADF: ["самой", "myself, yourself"],
      ADN: ["самому", "myself, yourself"],
      ADP: ["самим", "myself, yourself"],
      AAM: ["сам", "myself, yourself"],
      AAF: ["саму", "myself, yourself"],
      AAN: ["само", "myself, yourself"],
      AAP: ["сами", "myself, yourself"],
      AIM: ["самим", "myself, yourself"],
      AIF: ["самой", "myself, yourself"],
      AIN: ["самим", "myself, yourself"],
      AIP: ["самими", "myself, yourself"],
      APM: ["самом", "myself, yourself"],
      APF: ["самой", "myself, yourself"],
      APN: ["самом", "myself, yourself"],
      APP: ["самих", "myself, yourself"],
    },
    первый: {
      ANM: ["первый", "first, front, former"],
      ANF: ["первая", "first, front, former"],
      ANN: ["первое", "first, front, former"],
      ANP: ["первые", "first, front, former"],
      AGM: ["первого", "first, front, former"],
      AGF: ["первой", "first, front, former"],
      AGN: ["первого", "first, front, former"],
      AGP: ["первых", "first, front, former"],
      ADM: ["первому", "first, front, former"],
      ADF: ["первой", "first, front, former"],
      ADN: ["первому", "first, front, former"],
      ADP: ["первым", "first, front, former"],
      AAM: ["первый", "first, front, former"],
      AAF: ["первую", "first, front, former"],
      AAN: ["первое", "first, front, former"],
      AAP: ["первые", "first, front, former"],
      AIM: ["первым", "first, front, former"],
      AIF: ["первой", "first, front, former"],
      AIN: ["первым", "first, front, former"],
      AIP: ["первыми", "first, front, former"],
      APM: ["первом", "first, front, former"],
      APF: ["первой", "first, front, former"],
      APN: ["первом", "first, front, former"],
      APP: ["первых", "first, front, former"],
    },
    хороший: {
      ANM: ["хороший", "good, nice (see #152)"],
      ANF: ["хорошая", "good, nice (see #152)"],
      ANN: ["хорошее", "good, nice (see #152)"],
      ANP: ["хорошие", "good, nice (see #152)"],
      AGM: ["хорошего", "good, nice (see #152)"],
      AGF: ["хорошей", "good, nice (see #152)"],
      AGN: ["хорошего", "good, nice (see #152)"],
      AGP: ["хороших", "good, nice (see #152)"],
      ADM: ["хорошему", "good, nice (see #152)"],
      ADF: ["хорошей", "good, nice (see #152)"],
      ADN: ["хорошему", "good, nice (see #152)"],
      ADP: ["хорошим", "good, nice (see #152)"],
      AAM: ["хорошего", "good, nice (see #152)"],
      AAF: ["хорошую", "good, nice (see #152)"],
      AAN: ["хорошее", "good, nice (see #152)"],
      AAP: ["хороших", "good, nice (see #152)"],
      AIM: ["хорошим", "good, nice (see #152)"],
      AIF: ["хорошей", "good, nice (see #152)"],
      AIN: ["хорошим", "good, nice (see #152)"],
      AIP: ["хорошими", "good, nice (see #152)"],
      APM: ["хорошем", "good, nice (see #152)"],
      APF: ["хорошей", "good, nice (see #152)"],
      APN: ["хорошем", "good, nice (see #152)"],
      APP: ["хороших", "good, nice (see #152)"],
      ASM: ["хорош", "good, nice (see #152)"],
      ASF: ["хороша", "good, nice (see #152)"],
      ASN: ["хорошо", "good, nice (see #152)"],
      ASP: ["хороши", "good, nice (see #152)"],
      AC1: ["лучше", "good, nice (see #152)"],
    },
    каждый: {
      ANM: ["каждый", "each, every"],
      ANF: ["каждая", "each, every"],
      ANN: ["каждое", "each, every"],
      ANP: ["каждые", "each, every"],
      AGM: ["каждого", "each, every"],
      AGF: ["каждой", "each, every"],
      AGN: ["каждого", "each, every"],
      AGP: ["каждых", "each, every"],
      ADM: ["каждому", "each, every"],
      ADF: ["каждой", "each, every"],
      ADN: ["каждому", "each, every"],
      ADP: ["каждым", "each, every"],
      AAM: ["каждого", "each, every"],
      AAF: ["каждую", "each, every"],
      AAN: ["каждое", "each, every"],
      AAP: ["каждых", "each, every"],
      AIM: ["каждым", "each, every"],
      AIF: ["каждой", "each, every"],
      AIN: ["каждым", "each, every"],
      AIP: ["каждыми", "each, every"],
      APM: ["каждом", "each, every"],
      APF: ["каждой", "each, every"],
      APN: ["каждом", "each, every"],
      APP: ["каждых", "each, every"],
    },
    новый: {
      ANM: ["новый", "new; modern"],
      ANF: ["новая", "new; modern"],
      ANN: ["новое", "new; modern"],
      ANP: ["новые", "new; modern"],
      AGM: ["нового", "new; modern"],
      AGF: ["новой", "new; modern"],
      AGN: ["нового", "new; modern"],
      AGP: ["новых", "new; modern"],
      ADM: ["новому", "new; modern"],
      ADF: ["новой", "new; modern"],
      ADN: ["новому", "new; modern"],
      ADP: ["новым", "new; modern"],
      AAM: ["новый", "new; modern"],
      AAF: ["новую", "new; modern"],
      AAN: ["новое", "new; modern"],
      AAP: ["новые", "new; modern"],
      AIM: ["новым", "new; modern"],
      AIF: ["новой", "new; modern"],
      AIN: ["новым", "new; modern"],
      AIP: ["новыми", "new; modern"],
      APM: ["новом", "new; modern"],
      APF: ["новой", "new; modern"],
      APN: ["новом", "new; modern"],
      APP: ["новых", "new; modern"],
      ASM: ["нов", "new; modern"],
      ASF: ["нова", "new; modern"],
      ASN: ["ново", "new; modern"],
      ASP: ["новы ", "new; modern"],
      AC1: ["новее", "new; modern"],
    },
    должный: {
      ANM: ["должный", "due, proper"],
      ANF: ["должная", "due, proper"],
      ANN: ["должное", "due, proper"],
      ANP: ["должные", "due, proper"],
      AGM: ["должного", "due, proper"],
      AGF: ["должной", "due, proper"],
      AGN: ["должного", "due, proper"],
      AGP: ["должных", "due, proper"],
      ADM: ["должному", "due, proper"],
      ADF: ["должной", "due, proper"],
      ADN: ["должному", "due, proper"],
      ADP: ["должным", "due, proper"],
      AAM: ["должный", "due, proper"],
      AAF: ["должную", "due, proper"],
      AAN: ["должное", "due, proper"],
      AAP: ["должные", "due, proper"],
      AIM: ["должным", "due, proper"],
      AIF: ["должной", "due, proper"],
      AIN: ["должным", "due, proper"],
      AIP: ["должными", "due, proper"],
      APM: ["должном", "due, proper"],
      APF: ["должной", "due, proper"],
      APN: ["должном", "due, proper"],
      APP: ["должных", "due, proper"],
      ASM: ["должен", "due, proper"],
      ASF: ["должна", "due, proper"],
      ASN: ["должно", "due, proper"],
      ASP: ["должны", "due, proper"],
    },
    конечный: {
      ANM: ["конечный", "final, last"],
      ANF: ["конечная", "final, last"],
      ANN: ["конечное", "final, last"],
      ANP: ["конечные", "final, last"],
      AGM: ["конечного", "final, last"],
      AGF: ["конечной", "final, last"],
      AGN: ["конечного", "final, last"],
      AGP: ["конечных", "final, last"],
      ADM: ["конечному", "final, last"],
      ADF: ["конечной", "final, last"],
      ADN: ["конечному", "final, last"],
      ADP: ["конечным", "final, last"],
      AAM: ["конечный", "final, last"],
      AAF: ["конечную", "final, last"],
      AAN: ["конечное", "final, last"],
      AAP: ["конечные", "final, last"],
      AIM: ["конечным", "final, last"],
      AIF: ["конечной", "final, last"],
      AIN: ["конечным", "final, last"],
      AIP: ["конечными", "final, last"],
      APM: ["конечном", "final, last"],
      APF: ["конечной", "final, last"],
      APN: ["конечном", "final, last"],
      APP: ["конечных", "final, last"],
      ASM: ["конечен", "final, last"],
      ASF: ["конечна", "final, last"],
      ASN: ["конечно", "final, last"],
      ASP: ["конечны", "final, last"],
    },
    нужный: {
      ANM: ["нужный", "necessary"],
      ANF: ["нужная", "necessary"],
      ANN: ["нужное", "necessary"],
      ANP: ["нужные", "necessary"],
      AGM: ["нужного", "necessary"],
      AGF: ["нужной", "necessary"],
      AGN: ["нужного", "necessary"],
      AGP: ["нужных", "necessary"],
      ADM: ["нужному", "necessary"],
      ADF: ["нужной", "necessary"],
      ADN: ["нужному", "necessary"],
      ADP: ["нужным", "necessary"],
      AAM: ["нужный", "necessary"],
      AAF: ["нужную", "necessary"],
      AAN: ["нужное", "necessary"],
      AAP: ["нужные", "necessary"],
      AIM: ["нужным", "necessary"],
      AIF: ["нужной", "necessary"],
      AIN: ["нужным", "necessary"],
      AIP: ["нужными", "necessary"],
      APM: ["нужном", "necessary"],
      APF: ["нужной", "necessary"],
      APN: ["нужном", "necessary"],
      APP: ["нужных", "necessary"],
      ASM: ["нужен", "necessary"],
      ASF: ["нужна", "necessary"],
      ASN: ["нужно", "necessary"],
      ASP: ["нужны", "necessary"],
    },
    последний: {
      ANM: ["последний", "last, the latest, new"],
      ANF: ["последняя", "last, the latest, new"],
      ANN: ["последнее", "last, the latest, new"],
      ANP: ["последние", "last, the latest, new"],
      AGM: ["последнего", "last, the latest, new"],
      AGF: ["последней", "last, the latest, new"],
      AGN: ["последнего", "last, the latest, new"],
      AGP: ["последних", "last, the latest, new"],
      ADM: ["последнему", "last, the latest, new"],
      ADF: ["последней", "last, the latest, new"],
      ADN: ["последнему", "last, the latest, new"],
      ADP: ["последним", "last, the latest, new"],
      AAM: ["последний", "last, the latest, new"],
      AAF: ["последнюю", "last, the latest, new"],
      AAN: ["последнее", "last, the latest, new"],
      AAP: ["последние", "last, the latest, new"],
      AIM: ["последним", "last, the latest, new"],
      AIF: ["последней", "last, the latest, new"],
      AIN: ["последним", "last, the latest, new"],
      AIP: ["последними", "last, the latest, new"],
      APM: ["последнем", "last, the latest, new"],
      APF: ["последней", "last, the latest, new"],
      APN: ["последнем", "last, the latest, new"],
      APP: ["последних", "last, the latest, new"],
    },
    второй: {
      ANM: ["второй", "second"],
      ANF: ["вторая", "second"],
      ANN: ["второе", "second"],
      ANP: ["вторые", "second"],
      AGM: ["второго", "second"],
      AGF: ["второй", "second"],
      AGN: ["второго", "second"],
      AGP: ["вторых", "second"],
      ADM: ["второму", "second"],
      ADF: ["второй", "second"],
      ADN: ["второму", "second"],
      ADP: ["вторым", "second"],
      AAM: ["второго", "second"],
      AAF: ["вторую", "second"],
      AAN: ["второе", "second"],
      AAP: ["вторых", "second"],
      AIM: ["вторым", "second"],
      AIF: ["второй", "second"],
      AIN: ["вторым", "second"],
      AIP: ["вторыми", "second"],
      APM: ["втором", "second"],
      APF: ["второй", "second"],
      APN: ["втором", "second"],
      APP: ["вторых", "second"],
    },
    старый: {
      ANM: ["старый", "old"],
      ANF: ["старая", "old"],
      ANN: ["старое", "old"],
      ANP: ["старые", "old"],
      AGM: ["старого", "old"],
      AGF: ["старой", "old"],
      AGN: ["старого", "old"],
      AGP: ["старых", "old"],
      ADM: ["старому", "old"],
      ADF: ["старой", "old"],
      ADN: ["старому", "old"],
      ADP: ["старым", "old"],
      AAM: ["старый", "old"],
      AAF: ["старую", "old"],
      AAN: ["старое", "old"],
      AAP: ["старых", "old"],
      AIM: ["старым", "old"],
      AIF: ["старой", "old"],
      AIN: ["старым", "old"],
      AIP: ["старыми", "old"],
      APM: ["старом", "old"],
      APF: ["старой", "old"],
      APN: ["старом", "old"],
      APP: ["старых", "old"],
      ASM: ["стар", "old"],
      ASF: ["стара", "old"],
      ASN: ["старо старо", "old"],
      ASP: ["стары стары", "old"],
      AC1: ["старше", "old"],
      AC2: ["старее", "old"],
    },
    твой: {
      ANM: ["твой", "your, yours (informal)"],
      ANF: ["твоя", "your, yours (informal)"],
      ANN: ["твоё", "your, yours (informal)"],
      ANP: ["твои", "your, yours (informal)"],
      AGM: ["твоего", "your, yours (informal)"],
      AGF: ["твоей", "your, yours (informal)"],
      AGN: ["твоего", "your, yours (informal)"],
      AGP: ["твоих", "your, yours (informal)"],
      ADM: ["твоему", "your, yours (informal)"],
      ADF: ["твоей", "your, yours (informal)"],
      ADN: ["твоему", "your, yours (informal)"],
      ADP: ["твоим", "your, yours (informal)"],
      AAM: ["твой", "your, yours (informal)"],
      AAF: ["твою", "your, yours (informal)"],
      AAN: ["твоё", "your, yours (informal)"],
      AAP: ["твои", "your, yours (informal)"],
      AIM: ["твоим", "your, yours (informal)"],
      AIF: ["твоей", "your, yours (informal)"],
      AIN: ["твоим", "your, yours (informal)"],
      AIP: ["твоими", "your, yours (informal)"],
      APM: ["твоём", "your, yours (informal)"],
      APF: ["твоей", "your, yours (informal)"],
      APN: ["твоём", "your, yours (informal)"],
      APP: ["твоих", "your, yours (informal)"],
    },
    белый: {
      ANM: ["белый", "white"],
      ANF: ["белая", "white"],
      ANN: ["белое", "white"],
      ANP: ["белые", "white"],
      AGM: ["белого", "white"],
      AGF: ["белой", "white"],
      AGN: ["белого", "white"],
      AGP: ["белых", "white"],
      ADM: ["белому", "white"],
      ADF: ["белой", "white"],
      ADN: ["белому", "white"],
      ADP: ["белым", "white"],
      AAM: ["белый", "white"],
      AAF: ["белую", "white"],
      AAN: ["белое", "white"],
      AAP: ["белые", "white"],
      AIM: ["белым", "white"],
      AIF: ["белой", "white"],
      AIN: ["белым", "white"],
      AIP: ["белыми", "white"],
      APM: ["белом", "white"],
      APF: ["белой", "white"],
      APN: ["белом", "white"],
      APP: ["белых", "white"],
      ASM: ["бел", "white"],
      ASF: ["бела", "white"],
      ASN: ["бело бело", "white"],
      ASP: ["белы белы", "white"],
      AC1: ["белее", "white"],
      AC2: ["белей", "white"],
    },
    главный: {
      ANM: ["главный", "main, chief"],
      ANF: ["главная", "main, chief"],
      ANN: ["главное", "main, chief"],
      ANP: ["главные", "main, chief"],
      AGM: ["главного", "main, chief"],
      AGF: ["главной", "main, chief"],
      AGN: ["главного", "main, chief"],
      AGP: ["главных", "main, chief"],
      ADM: ["главному", "main, chief"],
      ADF: ["главной", "main, chief"],
      ADN: ["главному", "main, chief"],
      ADP: ["главным", "main, chief"],
      AAM: ["главный", "main, chief"],
      AAF: ["главную", "main, chief"],
      AAN: ["главное", "main, chief"],
      AAP: ["главные", "main, chief"],
      AIM: ["главным", "main, chief"],
      AIF: ["главной", "main, chief"],
      AIN: ["главным", "main, chief"],
      AIP: ["главными", "main, chief"],
      APM: ["главном", "main, chief"],
      APF: ["главной", "main, chief"],
      APN: ["главном", "main, chief"],
      APP: ["главных", "main, chief"],
      ASM: ["главен", "main, chief"],
      ASF: ["главна", "main, chief"],
      ASN: ["главно", "main, chief"],
      ASP: ["главны", "main, chief"],
      AC1: ["главнее", "main, chief"],
    },
    никакой: {
      ANM: ["никакой", "no, none"],
      ANF: ["никакая", "no, none"],
      ANN: ["никакое", "no, none"],
      ANP: ["никакие", "no, none"],
      AGM: ["никакого", "no, none"],
      AGF: ["никакой", "no, none"],
      AGN: ["никакого", "no, none"],
      AGP: ["никаких", "no, none"],
      ADM: ["никакому", "no, none"],
      ADF: ["никакой", "no, none"],
      ADN: ["никакому", "no, none"],
      ADP: ["никаким", "no, none"],
      AAM: ["никакой", "no, none"],
      AAF: ["никакую", "no, none"],
      AAN: ["никакое", "no, none"],
      AAP: ["никакие", "no, none"],
      AIM: ["никаким", "no, none"],
      AIF: ["никакой", "no, none"],
      AIN: ["никаким", "no, none"],
      AIP: ["никакими", "no, none"],
      APM: ["никаком", "no, none"],
      APF: ["никакой", "no, none"],
      APN: ["никаком", "no, none"],
      APP: ["никаких", "no, none"],
    },
    маленький: {
      ANM: ["маленький", "small, little"],
      ANF: ["маленькая", "small, little"],
      ANN: ["маленькое", "small, little"],
      ANP: ["маленькие", "small, little"],
      AGM: ["маленького", "small, little"],
      AGF: ["маленькой", "small, little"],
      AGN: ["маленького", "small, little"],
      AGP: ["маленьких", "small, little"],
      ADM: ["маленькому", "small, little"],
      ADF: ["маленькой", "small, little"],
      ADN: ["маленькому", "small, little"],
      ADP: ["маленьким", "small, little"],
      AAM: ["маленький", "small, little"],
      AAF: ["маленькую", "small, little"],
      AAN: ["маленькое", "small, little"],
      AAP: ["маленькие", "small, little"],
      AIM: ["маленьким", "small, little"],
      AIF: ["маленькой", "small, little"],
      AIN: ["маленьким", "small, little"],
      AIP: ["маленькими", "small, little"],
      APM: ["маленьком", "small, little"],
      APF: ["маленькой", "small, little"],
      APN: ["маленьком", "small, little"],
      APP: ["маленьких", "small, little"],
      ASM: ["мал", "small, little"],
      ASF: ["мала", "small, little"],
      ASN: ["мало", "small, little"],
      ASP: ["малы", "small, little"],
      AC1: ["меньше", "small, little"],
      AC2: ["меньший", "small, little"],
    },
    далёкий: {
      ANM: ["далёкий", "distant, remote"],
      ANF: ["далёкая", "distant, remote"],
      ANN: ["далёкое", "distant, remote"],
      ANP: ["далёкие", "distant, remote"],
      AGM: ["далёкого", "distant, remote"],
      AGF: ["далёкой", "distant, remote"],
      AGN: ["далёкого", "distant, remote"],
      AGP: ["далёких", "distant, remote"],
      ADM: ["далёкому", "distant, remote"],
      ADF: ["далёкой", "distant, remote"],
      ADN: ["далёкому", "distant, remote"],
      ADP: ["далёким", "distant, remote"],
      AAM: ["далёкий", "distant, remote"],
      AAF: ["далёкую", "distant, remote"],
      AAN: ["далёкое", "distant, remote"],
      AAP: ["далёкие", "distant, remote"],
      AIM: ["далёким", "distant, remote"],
      AIF: ["далёкой", "distant, remote"],
      AIN: ["далёким", "distant, remote"],
      AIP: ["далёкими", "distant, remote"],
      APM: ["далёком", "distant, remote"],
      APF: ["далёкой", "distant, remote"],
      APN: ["далёком", "distant, remote"],
      APP: ["далёких", "distant, remote"],
      ASM: ["далёк", "distant, remote"],
      ASF: ["далека", "distant, remote"],
      ASN: ["далеко", "distant, remote"],
      ASP: ["далеки", "distant, remote"],
      AC1: ["дальше", "distant, remote"],
    },
    некоторый: {
      ANM: ["некоторый", "some"],
      ANF: ["некоторая", "some"],
      ANN: ["некоторое", "some"],
      ANP: ["некоторые", "some"],
      AGM: ["некоторого", "some"],
      AGF: ["некоторой", "some"],
      AGN: ["некоторого", "some"],
      AGP: ["некоторых", "some"],
      ADM: ["некоторому", "some"],
      ADF: ["некоторой", "some"],
      ADN: ["некоторому", "some"],
      ADP: ["некоторым", "some"],
      AAM: ["некоторого", "some"],
      AAF: ["некоторую", "some"],
      AAN: ["некоторое", "some"],
      AAP: ["некоторых", "some"],
      AIM: ["некоторым", "some"],
      AIF: ["некоторой", "some"],
      AIN: ["некоторым", "some"],
      AIP: ["некоторыми", "some"],
      APM: ["некотором", "some"],
      APF: ["некоторой", "some"],
      APN: ["некотором", "some"],
      APP: ["некоторых", "some"],
    },
    советский: {
      ANM: ["советский", "Soviet"],
      ANF: ["советская", "Soviet"],
      ANN: ["советское", "Soviet"],
      ANP: ["советские", "Soviet"],
      AGM: ["советского", "Soviet"],
      AGF: ["советской", "Soviet"],
      AGN: ["советского", "Soviet"],
      AGP: ["советских", "Soviet"],
      ADM: ["советскому", "Soviet"],
      ADF: ["советской", "Soviet"],
      ADN: ["советскому", "Soviet"],
      ADP: ["советским", "Soviet"],
      AAM: ["советский", "Soviet"],
      AAF: ["советскую", "Soviet"],
      AAN: ["советское", "Soviet"],
      AAP: ["советские", "Soviet"],
      AIM: ["советским", "Soviet"],
      AIF: ["советской", "Soviet"],
      AIN: ["советским", "Soviet"],
      AIP: ["советскими", "Soviet"],
      APM: ["советском", "Soviet"],
      APF: ["советской", "Soviet"],
      APN: ["советском", "Soviet"],
      APP: ["советских", "Soviet"],
    },
    русский: {
      ANM: ["русский", "Russian"],
      ANF: ["русская", "Russian"],
      ANN: ["русское", "Russian"],
      ANP: ["русские", "Russian"],
      AGM: ["русского", "Russian"],
      AGF: ["русской", "Russian"],
      AGN: ["русского", "Russian"],
      AGP: ["русских", "Russian"],
      ADM: ["русскому", "Russian"],
      ADF: ["русской", "Russian"],
      ADN: ["русскому", "Russian"],
      ADP: ["русским", "Russian"],
      AAM: ["русского", "Russian"],
      AAF: ["русскую", "Russian"],
      AAN: ["русское", "Russian"],
      AAP: ["русских", "Russian"],
      AIM: ["русским", "Russian"],
      AIF: ["русской", "Russian"],
      AIN: ["русским", "Russian"],
      AIP: ["русскими", "Russian"],
      APM: ["русском", "Russian"],
      APF: ["русской", "Russian"],
      APN: ["русском", "Russian"],
      APP: ["русских", "Russian"],
    },
    полный: {
      ANM: ["полный", "full, complete"],
      ANF: ["полная", "full, complete"],
      ANN: ["полное", "full, complete"],
      ANP: ["полные", "full, complete"],
      AGM: ["полного", "full, complete"],
      AGF: ["полной", "full, complete"],
      AGN: ["полного", "full, complete"],
      AGP: ["полных", "full, complete"],
      ADM: ["полному", "full, complete"],
      ADF: ["полной", "full, complete"],
      ADN: ["полному", "full, complete"],
      ADP: ["полным", "full, complete"],
      AAM: ["полный", "full, complete"],
      AAF: ["полную", "full, complete"],
      AAN: ["полное", "full, complete"],
      AAP: ["полные", "full, complete"],
      AIM: ["полным", "full, complete"],
      AIF: ["полной", "full, complete"],
      AIN: ["полным", "full, complete"],
      AIP: ["полными", "full, complete"],
      APM: ["полном", "full, complete"],
      APF: ["полной", "full, complete"],
      APN: ["полном", "full, complete"],
      APP: ["полных", "full, complete"],
      ASM: ["полон", "full, complete"],
      ASF: ["полна", "full, complete"],
      ASN: ["полно", "full, complete"],
      ASP: ["полны", "full, complete"],
      AC1: ["полнее", "full, complete"],
    },
    любой: {
      ANM: ["любой", "any, every"],
      ANF: ["любая", "any, every"],
      ANN: ["любое", "any, every"],
      ANP: ["любые", "any, every"],
      AGM: ["любого", "any, every"],
      AGF: ["любой", "any, every"],
      AGN: ["любого", "any, every"],
      AGP: ["любых", "any, every"],
      ADM: ["любому", "any, every"],
      ADF: ["любой", "any, every"],
      ADN: ["любому", "any, every"],
      ADP: ["любым", "any, every"],
      AAM: ["любой", "any, every"],
      AAF: ["любую", "any, every"],
      AAN: ["любое", "any, every"],
      AAP: ["любые", "any, every"],
      AIM: ["любым", "any, every"],
      AIF: ["любой", "any, every"],
      AIN: ["любым", "any, every"],
      AIP: ["любыми", "any, every"],
      APM: ["любом", "any, every"],
      APF: ["любой", "any, every"],
      APN: ["любом", "any, every"],
      APP: ["любых", "any, every"],
    },
    общий: {
      ANM: ["общий", "general, common"],
      ANF: ["общая", "general, common"],
      ANN: ["общее", "general, common"],
      ANP: ["общие", "general, common"],
      AGM: ["общего", "general, common"],
      AGF: ["общей", "general, common"],
      AGN: ["общего", "general, common"],
      AGP: ["общих", "general, common"],
      ADM: ["общему", "general, common"],
      ADF: ["общей", "general, common"],
      ADN: ["общему", "general, common"],
      ADP: ["общим", "general, common"],
      AAM: ["общего", "general, common"],
      AAF: ["общую", "general, common"],
      AAN: ["общее", "general, common"],
      AAP: ["общих", "general, common"],
      AIM: ["общим", "general, common"],
      AIF: ["общей", "general, common"],
      AIN: ["общим", "general, common"],
      AIP: ["общими", "general, common"],
      APM: ["общем", "general, common"],
      APF: ["общей", "general, common"],
      APN: ["общем", "general, common"],
      APP: ["общих", "general, common"],
      ASM: ["общ", "general, common"],
      ASF: ["обща", "general, common"],
      ASN: ["обще", "general, common"],
      ASP: ["общи", "general, common"],
    },
    прошлый: {
      ANM: ["прошлый", "past"],
      ANF: ["прошлая", "past"],
      ANN: ["прошлое", "past"],
      ANP: ["прошлые", "past"],
      AGM: ["прошлого", "past"],
      AGF: ["прошлой", "past"],
      AGN: ["прошлого", "past"],
      AGP: ["прошлых", "past"],
      ADM: ["прошлому", "past"],
      ADF: ["прошлой", "past"],
      ADN: ["прошлому", "past"],
      ADP: ["прошлым", "past"],
      AAM: ["прошлый", "past"],
      AAF: ["прошлую", "past"],
      AAN: ["прошлое", "past"],
      AAP: ["прошлые", "past"],
      AIM: ["прошлым", "past"],
      AIF: ["прошлой", "past"],
      AIN: ["прошлым", "past"],
      AIP: ["прошлыми", "past"],
      APM: ["прошлом", "past"],
      APF: ["прошлой", "past"],
      APN: ["прошлом", "past"],
      APP: ["прошлых", "past"],
    },
    известный: {
      ANM: ["известный", "well-known, famous"],
      ANF: ["известная", "well-known, famous"],
      ANN: ["известное", "well-known, famous"],
      ANP: ["известные", "well-known, famous"],
      AGM: ["известного", "well-known, famous"],
      AGF: ["известной", "well-known, famous"],
      AGN: ["известного", "well-known, famous"],
      AGP: ["известных", "well-known, famous"],
      ADM: ["известному", "well-known, famous"],
      ADF: ["известной", "well-known, famous"],
      ADN: ["известному", "well-known, famous"],
      ADP: ["известным", "well-known, famous"],
      AAM: ["известный", "well-known, famous"],
      AAF: ["известную", "well-known, famous"],
      AAN: ["известное", "well-known, famous"],
      AAP: ["известные", "well-known, famous"],
      AIM: ["известным", "well-known, famous"],
      AIF: ["известной", "well-known, famous"],
      AIN: ["известным", "well-known, famous"],
      AIP: ["известными", "well-known, famous"],
      APM: ["известном", "well-known, famous"],
      APF: ["известной", "well-known, famous"],
      APN: ["известном", "well-known, famous"],
      APP: ["известных", "well-known, famous"],
      ASM: ["известен", "well-known, famous"],
      ASF: ["известна", "well-known, famous"],
      ASN: ["известно", "well-known, famous"],
      ASP: ["известны", "well-known, famous"],
    },
    похожий: {
      ANM: ["похожий", "similar, alike"],
      ANF: ["похожая", "similar, alike"],
      ANN: ["похожее", "similar, alike"],
      ANP: ["похожие", "similar, alike"],
      AGM: ["похожего", "similar, alike"],
      AGF: ["похожей", "similar, alike"],
      AGN: ["похожего", "similar, alike"],
      AGP: ["похожих", "similar, alike"],
      ADM: ["похожему", "similar, alike"],
      ADF: ["похожей", "similar, alike"],
      ADN: ["похожему", "similar, alike"],
      ADP: ["похожим", "similar, alike"],
      AAM: ["похожий", "similar, alike"],
      AAF: ["похожую", "similar, alike"],
      AAN: ["похожее", "similar, alike"],
      AAP: ["похожие", "similar, alike"],
      AIM: ["похожим", "similar, alike"],
      AIF: ["похожей", "similar, alike"],
      AIN: ["похожим", "similar, alike"],
      AIP: ["похожими", "similar, alike"],
      APM: ["похожем", "similar, alike"],
      APF: ["похожей", "similar, alike"],
      APN: ["похожем", "similar, alike"],
      APP: ["похожих", "similar, alike"],
      ASM: ["похож", "similar, alike"],
      ASF: ["похожа", "similar, alike"],
      ASN: ["похоже", "similar, alike"],
      ASP: ["похожи", "similar, alike"],
    },
    молодой: {
      ANM: ["молодой", "young (see #679); bridegroom (as a noun)"],
      ANF: ["молодая", "young (see #679); bridegroom (as a noun)"],
      ANN: ["молодое", "young (see #679); bridegroom (as a noun)"],
      ANP: ["молодые", "young (see #679); bridegroom (as a noun)"],
      AGM: ["молодого", "young (see #679); bridegroom (as a noun)"],
      AGF: ["молодой", "young (see #679); bridegroom (as a noun)"],
      AGN: ["молодого", "young (see #679); bridegroom (as a noun)"],
      AGP: ["молодых", "young (see #679); bridegroom (as a noun)"],
      ADM: ["молодому", "young (see #679); bridegroom (as a noun)"],
      ADF: ["молодой", "young (see #679); bridegroom (as a noun)"],
      ADN: ["молодому", "young (see #679); bridegroom (as a noun)"],
      ADP: ["молодым", "young (see #679); bridegroom (as a noun)"],
      AAM: ["молодого", "young (see #679); bridegroom (as a noun)"],
      AAF: ["молодую", "young (see #679); bridegroom (as a noun)"],
      AAN: ["молодое", "young (see #679); bridegroom (as a noun)"],
      AAP: ["молодых", "young (see #679); bridegroom (as a noun)"],
      AIM: ["молодым", "young (see #679); bridegroom (as a noun)"],
      AIF: ["молодой", "young (see #679); bridegroom (as a noun)"],
      AIN: ["молодым", "young (see #679); bridegroom (as a noun)"],
      AIP: ["молодыми", "young (see #679); bridegroom (as a noun)"],
      APM: ["молодом", "young (see #679); bridegroom (as a noun)"],
      APF: ["молодой", "young (see #679); bridegroom (as a noun)"],
      APN: ["молодом", "young (see #679); bridegroom (as a noun)"],
      APP: ["молодых", "young (see #679); bridegroom (as a noun)"],
      ASM: ["молод", "young (see #679); bridegroom (as a noun)"],
      ASF: ["молода", "young (see #679); bridegroom (as a noun)"],
      ASN: ["молодо", "young (see #679); bridegroom (as a noun)"],
      ASP: ["молоды", "young (see #679); bridegroom (as a noun)"],
      AC1: ["моложе", "young (see #679); bridegroom (as a noun)"],
    },
    красный: {
      ANM: ["красный", "red"],
      ANF: ["красная", "red"],
      ANN: ["красное", "red"],
      ANP: ["красные", "red"],
      AGM: ["красного", "red"],
      AGF: ["красной", "red"],
      AGN: ["красного", "red"],
      AGP: ["красных", "red"],
      ADM: ["красному", "red"],
      ADF: ["красной", "red"],
      ADN: ["красному", "red"],
      ADP: ["красным", "red"],
      AAM: ["красный", "red"],
      AAF: ["красную", "red"],
      AAN: ["красное", "red"],
      AAP: ["красные", "red"],
      AIM: ["красным", "red"],
      AIF: ["красной", "red"],
      AIN: ["красным", "red"],
      AIP: ["красными", "red"],
      APM: ["красном", "red"],
      APF: ["красной", "red"],
      APN: ["красном", "red"],
      APP: ["красных", "red"],
      ASM: ["красен", "red"],
      ASF: ["красна", "red"],
      ASN: ["красно красно", "red"],
      ASP: ["красны красны", "red"],
      AC1: ["краснее", "red"],
    },
    ранний: {
      ANM: ["ранний", "early"],
      ANF: ["ранняя", "early"],
      ANN: ["раннее", "early"],
      ANP: ["ранние", "early"],
      AGM: ["раннего", "early"],
      AGF: ["ранней", "early"],
      AGN: ["раннего", "early"],
      AGP: ["ранних", "early"],
      ADM: ["раннему", "early"],
      ADF: ["ранней", "early"],
      ADN: ["раннему", "early"],
      ADP: ["ранним", "early"],
      AAM: ["ранний", "early"],
      AAF: ["раннюю", "early"],
      AAN: ["раннее", "early"],
      AAP: ["ранние", "early"],
      AIM: ["ранним", "early"],
      AIF: ["ранней", "early"],
      AIN: ["ранним", "early"],
      AIP: ["ранними", "early"],
      APM: ["раннем", "early"],
      APF: ["ранней", "early"],
      APN: ["раннем", "early"],
      APP: ["ранних", "early"],
      ASM: ["ранен", "early"],
      ASF: ["рання", "early"],
      ASN: ["ранне", "early"],
      ASP: ["ранни", "early"],
    },
    высокий: {
      ANM: ["высокий", "tall, high"],
      ANF: ["высокая", "tall, high"],
      ANN: ["высокое", "tall, high"],
      ANP: ["высокие", "tall, high"],
      AGM: ["высокого", "tall, high"],
      AGF: ["высокой", "tall, high"],
      AGN: ["высокого", "tall, high"],
      AGP: ["высоких", "tall, high"],
      ADM: ["высокому", "tall, high"],
      ADF: ["высокой", "tall, high"],
      ADN: ["высокому", "tall, high"],
      ADP: ["высоким", "tall, high"],
      AAM: ["высокий", "tall, high"],
      AAF: ["высокую", "tall, high"],
      AAN: ["высокое", "tall, high"],
      AAP: ["высокие", "tall, high"],
      AIM: ["высоким", "tall, high"],
      AIF: ["высокой", "tall, high"],
      AIN: ["высоким", "tall, high"],
      AIP: ["высокими", "tall, high"],
      APM: ["высоком", "tall, high"],
      APF: ["высокой", "tall, high"],
      APN: ["высоком", "tall, high"],
      APP: ["высоких", "tall, high"],
      ASM: ["высок", "tall, high"],
      ASF: ["высока", "tall, high"],
      ASN: ["высоко высоко", "tall, high"],
      ASP: ["высоки высоки", "tall, high"],
      AC1: ["выше", "tall, high"],
    },
    целый: {
      ANM: ["целый", "intact, whole, entire"],
      ANF: ["целая", "intact, whole, entire"],
      ANN: ["целое", "intact, whole, entire"],
      ANP: ["целые", "intact, whole, entire"],
      AGM: ["целого", "intact, whole, entire"],
      AGF: ["целой", "intact, whole, entire"],
      AGN: ["целого", "intact, whole, entire"],
      AGP: ["целых", "intact, whole, entire"],
      ADM: ["целому", "intact, whole, entire"],
      ADF: ["целой", "intact, whole, entire"],
      ADN: ["целому", "intact, whole, entire"],
      ADP: ["целым", "intact, whole, entire"],
      AAM: ["целого", "intact, whole, entire"],
      AAF: ["целую", "intact, whole, entire"],
      AAN: ["целое", "intact, whole, entire"],
      AAP: ["целых", "intact, whole, entire"],
      AIM: ["целым", "intact, whole, entire"],
      AIF: ["целой", "intact, whole, entire"],
      AIN: ["целым", "intact, whole, entire"],
      AIP: ["целыми", "intact, whole, entire"],
      APM: ["целом", "intact, whole, entire"],
      APF: ["целой", "intact, whole, entire"],
      APN: ["целом", "intact, whole, entire"],
      APP: ["целых", "intact, whole, entire"],
      ASM: ["цел", "intact, whole, entire"],
      ASF: ["цела", "intact, whole, entire"],
      ASN: ["цело", "intact, whole, entire"],
      ASP: ["целы", "intact, whole, entire"],
    },
    огромный: {
      ANM: ["огромный", "huge, enormous"],
      ANF: ["огромная", "huge, enormous"],
      ANN: ["огромное", "huge, enormous"],
      ANP: ["огромные", "huge, enormous"],
      AGM: ["огромного", "huge, enormous"],
      AGF: ["огромной", "huge, enormous"],
      AGN: ["огромного", "huge, enormous"],
      AGP: ["огромных", "huge, enormous"],
      ADM: ["огромному", "huge, enormous"],
      ADF: ["огромной", "huge, enormous"],
      ADN: ["огромному", "huge, enormous"],
      ADP: ["огромным", "huge, enormous"],
      AAM: ["огромный", "huge, enormous"],
      AAF: ["огромную", "huge, enormous"],
      AAN: ["огромное", "huge, enormous"],
      AAP: ["огромные", "huge, enormous"],
      AIM: ["огромным", "huge, enormous"],
      AIF: ["огромной", "huge, enormous"],
      AIN: ["огромным", "huge, enormous"],
      AIP: ["огромными", "huge, enormous"],
      APM: ["огромном", "huge, enormous"],
      APF: ["огромной", "huge, enormous"],
      APN: ["огромном", "huge, enormous"],
      APP: ["огромных", "huge, enormous"],
      ASM: ["огромен", "huge, enormous"],
      ASF: ["огромна", "huge, enormous"],
      ASN: ["огромно", "huge, enormous"],
      ASP: ["огромны", "huge, enormous"],
    },
    настоящий: {
      ANM: ["настоящий", "present;real, true"],
      ANF: ["настоящая", "present;real, true"],
      ANN: ["настоящее", "present;real, true"],
      ANP: ["настоящие", "present;real, true"],
      AGM: ["настоящего", "present;real, true"],
      AGF: ["настоящей", "present;real, true"],
      AGN: ["настоящего", "present;real, true"],
      AGP: ["настоящих", "present;real, true"],
      ADM: ["настоящему", "present;real, true"],
      ADF: ["настоящей", "present;real, true"],
      ADN: ["настоящему", "present;real, true"],
      ADP: ["настоящим", "present;real, true"],
      AAM: ["настоящий", "present;real, true"],
      AAF: ["настоящую", "present;real, true"],
      AAN: ["настоящее", "present;real, true"],
      AAP: ["настоящие", "present;real, true"],
      AIM: ["настоящим", "present;real, true"],
      AIF: ["настоящей", "present;real, true"],
      AIN: ["настоящим", "present;real, true"],
      AIP: ["настоящими", "present;real, true"],
      APM: ["настоящем", "present;real, true"],
      APF: ["настоящей", "present;real, true"],
      APN: ["настоящем", "present;real, true"],
      APP: ["настоящих", "present;real, true"],
    },
    живой: {
      ANM: ["живой", "living, live, lively"],
      ANF: ["живая", "living, live, lively"],
      ANN: ["живое", "living, live, lively"],
      ANP: ["живые", "living, live, lively"],
      AGM: ["живого", "living, live, lively"],
      AGF: ["живой", "living, live, lively"],
      AGN: ["живого", "living, live, lively"],
      AGP: ["живых", "living, live, lively"],
      ADM: ["живому", "living, live, lively"],
      ADF: ["живой", "living, live, lively"],
      ADN: ["живому", "living, live, lively"],
      ADP: ["живым", "living, live, lively"],
      AAM: ["живого", "living, live, lively"],
      AAF: ["живую", "living, live, lively"],
      AAN: ["живое", "living, live, lively"],
      AAP: ["живых", "living, live, lively"],
      AIM: ["живым", "living, live, lively"],
      AIF: ["живой", "living, live, lively"],
      AIN: ["живым", "living, live, lively"],
      AIP: ["живыми", "living, live, lively"],
      APM: ["живом", "living, live, lively"],
      APF: ["живой", "living, live, lively"],
      APN: ["живом", "living, live, lively"],
      APP: ["живых", "living, live, lively"],
      ASM: ["жив", "living, live, lively"],
      ASF: ["жива", "living, live, lively"],
      ASN: ["живо", "living, live, lively"],
      ASP: ["живы", "living, live, lively"],
    },
    чёрный: {
      ANM: ["чёрный", 'black (as in "she wears black")(See #333)'],
      ANF: ["чёрная", 'black (as in "she wears black")(See #333)'],
      ANN: ["чёрное", 'black (as in "she wears black")(See #333)'],
      ANP: ["чёрные", 'black (as in "she wears black")(See #333)'],
      AGM: ["чёрного", 'black (as in "she wears black")(See #333)'],
      AGF: ["чёрной", 'black (as in "she wears black")(See #333)'],
      AGN: ["чёрного", 'black (as in "she wears black")(See #333)'],
      AGP: ["чёрных", 'black (as in "she wears black")(See #333)'],
      ADM: ["чёрному", 'black (as in "she wears black")(See #333)'],
      ADF: ["чёрной", 'black (as in "she wears black")(See #333)'],
      ADN: ["чёрному", 'black (as in "she wears black")(See #333)'],
      ADP: ["чёрным", 'black (as in "she wears black")(See #333)'],
      AAM: ["чёрный", 'black (as in "she wears black")(See #333)'],
      AAF: ["чёрную", 'black (as in "she wears black")(See #333)'],
      AAN: ["чёрное", 'black (as in "she wears black")(See #333)'],
      AAP: ["чёрные", 'black (as in "she wears black")(See #333)'],
      AIM: ["чёрным", 'black (as in "she wears black")(See #333)'],
      AIF: ["чёрной", 'black (as in "she wears black")(See #333)'],
      AIN: ["чёрным", 'black (as in "she wears black")(See #333)'],
      AIP: ["чёрными", 'black (as in "she wears black")(See #333)'],
      APM: ["чёрном", 'black (as in "she wears black")(See #333)'],
      APF: ["чёрной", 'black (as in "she wears black")(See #333)'],
      APN: ["чёрном", 'black (as in "she wears black")(See #333)'],
      APP: ["чёрных", 'black (as in "she wears black")(See #333)'],
      ASM: ["чёрен", 'black (as in "she wears black")(See #333)'],
      ASF: ["черна", 'black (as in "she wears black")(See #333)'],
      ASN: ["черно", 'black (as in "she wears black")(See #333)'],
      ASP: ["черны", 'black (as in "she wears black")(See #333)'],
      AC1: ["чернее", 'black (as in "she wears black")(See #333)'],
      AC2: ["черней", 'black (as in "she wears black")(See #333)'],
    },
    разный: {
      ANM: ["разный", "different"],
      ANF: ["разная", "different"],
      ANN: ["разное", "different"],
      ANP: ["разные", "different"],
      AGM: ["разного", "different"],
      AGF: ["разной", "different"],
      AGN: ["разного", "different"],
      AGP: ["разных", "different"],
      ADM: ["разному", "different"],
      ADF: ["разной", "different"],
      ADN: ["разному", "different"],
      ADP: ["разным", "different"],
      AAM: ["разный", "different"],
      AAF: ["разную", "different"],
      AAN: ["разное", "different"],
      AAP: ["разные", "different"],
      AIM: ["разным", "different"],
      AIF: ["разной", "different"],
      AIN: ["разным", "different"],
      AIP: ["разными", "different"],
      APM: ["разном", "different"],
      APF: ["разной", "different"],
      APN: ["разном", "different"],
      APP: ["разных", "different"],
      ASM: ["разен", "different"],
      ASF: ["разна", "different"],
      ASN: ["разно", "different"],
      ASP: ["разны", "different"],
    },
    собственный: {
      ANM: ["собственный", "one's own; masterrussian, dot com"],
      ANF: ["собственная", "one's own; masterrussian, dot com"],
      ANN: ["собственное", "one's own; masterrussian, dot com"],
      ANP: ["собственные", "one's own; masterrussian, dot com"],
      AGM: ["собственного", "one's own; masterrussian, dot com"],
      AGF: ["собственной", "one's own; masterrussian, dot com"],
      AGN: ["собственного", "one's own; masterrussian, dot com"],
      AGP: ["собственных", "one's own; masterrussian, dot com"],
      ADM: ["собственному", "one's own; masterrussian, dot com"],
      ADF: ["собственной", "one's own; masterrussian, dot com"],
      ADN: ["собственному", "one's own; masterrussian, dot com"],
      ADP: ["собственным", "one's own; masterrussian, dot com"],
      AAM: ["собственный", "one's own; masterrussian, dot com"],
      AAF: ["собственную", "one's own; masterrussian, dot com"],
      AAN: ["собственное", "one's own; masterrussian, dot com"],
      AAP: ["собственные", "one's own; masterrussian, dot com"],
      AIM: ["собственным", "one's own; masterrussian, dot com"],
      AIF: ["собственной", "one's own; masterrussian, dot com"],
      AIN: ["собственным", "one's own; masterrussian, dot com"],
      AIP: ["собственными", "one's own; masterrussian, dot com"],
      APM: ["собственном", "one's own; masterrussian, dot com"],
      APF: ["собственной", "one's own; masterrussian, dot com"],
      APN: ["собственном", "one's own; masterrussian, dot com"],
      APP: ["собственных", "one's own; masterrussian, dot com"],
      ASM: ["собствен собственен", "one's own; masterrussian, dot com"],
      ASF: ["собственно", "one's own; masterrussian, dot com"],
      ASN: ["собственна", "one's own; masterrussian, dot com"],
      ASP: ["собственны", "one's own; masterrussian, dot com"],
    },
    третий: {
      ANM: ["третий", "third"],
      ANF: ["третья", "third"],
      ANN: ["третье", "third"],
      ANP: ["третьи", "third"],
      AGM: ["третьего", "third"],
      AGF: ["третьей", "third"],
      AGN: ["третьего", "third"],
      AGP: ["третьих", "third"],
      ADM: ["третьему", "third"],
      ADF: ["третьей", "third"],
      ADN: ["третьему", "third"],
      ADP: ["третьим", "third"],
      AAM: ["третьего", "third"],
      AAF: ["третью", "third"],
      AAN: ["третье", "third"],
      AAP: ["третьих", "third"],
      AIM: ["третьим", "third"],
      AIF: ["третьей", "third"],
      AIN: ["третьим", "third"],
      AIP: ["третьими", "third"],
      APM: ["третьем", "third"],
      APF: ["третьей", "third"],
      APN: ["третьем", "third"],
      APP: ["третьих", "third"],
    },
    великий: {
      ANM: ["великий", "great"],
      ANF: ["великая", "great"],
      ANN: ["великое", "great"],
      ANP: ["великие", "great"],
      AGM: ["великого", "great"],
      AGF: ["великой", "great"],
      AGN: ["великого", "great"],
      AGP: ["великих", "great"],
      ADM: ["великому", "great"],
      ADF: ["великой", "great"],
      ADN: ["великому", "great"],
      ADP: ["великим", "great"],
      AAM: ["великий", "great"],
      AAF: ["великую", "great"],
      AAN: ["великое", "great"],
      AAP: ["великие", "great"],
      AIM: ["великим", "great"],
      AIF: ["великой", "great"],
      AIN: ["великим", "great"],
      AIP: ["великими", "great"],
      APM: ["великом", "great"],
      APF: ["великой", "great"],
      APN: ["великом", "great"],
      APP: ["великих", "great"],
      ASM: ["велик", "great"],
      ASF: ["велика", "great"],
      ASN: ["велико", "great"],
      ASP: ["велики", "great"],
      AC1: ["больше", "great"],
      AC2: ["более", "great"],
    },
    тяжёлый: {
      ANM: ["тяжёлый", "heavy"],
      ANF: ["тяжёлая", "heavy"],
      ANN: ["тяжёлое", "heavy"],
      ANP: ["тяжёлые", "heavy"],
      AGM: ["тяжёлого", "heavy"],
      AGF: ["тяжёлой", "heavy"],
      AGN: ["тяжёлого", "heavy"],
      AGP: ["тяжёлых", "heavy"],
      ADM: ["тяжёлому", "heavy"],
      ADF: ["тяжёлой", "heavy"],
      ADN: ["тяжёлому", "heavy"],
      ADP: ["тяжёлым", "heavy"],
      AAM: ["тяжёлый", "heavy"],
      AAF: ["тяжёлую", "heavy"],
      AAN: ["тяжёлое", "heavy"],
      AAP: ["тяжёлые", "heavy"],
      AIM: ["тяжёлым", "heavy"],
      AIF: ["тяжёлой", "heavy"],
      AIN: ["тяжёлым", "heavy"],
      AIP: ["тяжёлыми", "heavy"],
      APM: ["тяжёлом", "heavy"],
      APF: ["тяжёлой", "heavy"],
      APN: ["тяжёлом", "heavy"],
      APP: ["тяжёлых", "heavy"],
      ASM: ["тяжёл", "heavy"],
      ASF: ["тяжела", "heavy"],
      ASN: ["тяжело", "heavy"],
      ASP: ["тяжелы", "heavy"],
      AC1: ["тяжелее", "heavy"],
    },
    длинный: {
      ANM: ["длинный", "long"],
      ANF: ["длинная", "long"],
      ANN: ["длинное", "long"],
      ANP: ["длинные", "long"],
      AGM: ["длинного", "long"],
      AGF: ["длинной", "long"],
      AGN: ["длинного", "long"],
      AGP: ["длинных", "long"],
      ADM: ["длинному", "long"],
      ADF: ["длинной", "long"],
      ADN: ["длинному", "long"],
      ADP: ["длинным", "long"],
      AAM: ["длинный", "long"],
      AAF: ["длинную", "long"],
      AAN: ["длинное", "long"],
      AAP: ["длинные", "long"],
      AIM: ["длинным", "long"],
      AIF: ["длинной", "long"],
      AIN: ["длинным", "long"],
      AIP: ["длинными", "long"],
      APM: ["длинном", "long"],
      APF: ["длинной", "long"],
      APN: ["длинном", "long"],
      APP: ["длинных", "long"],
      ASM: ["длинен", "long"],
      ASF: ["длинна", "long"],
      ASN: ["длинно", "long"],
      ASP: ["длинны", "long"],
      AC1: ["длиннее", "long"],
    },
    личный: {
      ANM: ["личный", "personal"],
      ANF: ["личная", "personal"],
      ANN: ["личное", "personal"],
      ANP: ["личные", "personal"],
      AGM: ["личного", "personal"],
      AGF: ["личной", "personal"],
      AGN: ["личного", "personal"],
      AGP: ["личных", "personal"],
      ADM: ["личному", "personal"],
      ADF: ["личной", "personal"],
      ADN: ["личному", "personal"],
      ADP: ["личным", "personal"],
      AAM: ["личный", "personal"],
      AAF: ["личную", "personal"],
      AAN: ["личное", "personal"],
      AAP: ["личные", "personal"],
      AIM: ["личным", "personal"],
      AIF: ["личной", "personal"],
      AIN: ["личным", "personal"],
      AIP: ["личными", "personal"],
      APM: ["личном", "personal"],
      APF: ["личной", "personal"],
      APN: ["личном", "personal"],
      APP: ["личных", "personal"],
      ASM: ["личен", "personal"],
      ASF: ["лична", "personal"],
      ASN: ["лично", "personal"],
      ASP: ["личны", "personal"],
    },
    знакомый: {
      ANM: ["знакомый", "acquainted"],
      ANF: ["знакомая", "acquainted"],
      ANN: ["знакомое", "acquainted"],
      ANP: ["знакомые", "acquainted"],
      AGM: ["знакомого", "acquainted"],
      AGF: ["знакомой", "acquainted"],
      AGN: ["знакомого", "acquainted"],
      AGP: ["знакомых", "acquainted"],
      ADM: ["знакомому", "acquainted"],
      ADF: ["знакомой", "acquainted"],
      ADN: ["знакомому", "acquainted"],
      ADP: ["знакомым", "acquainted"],
      AAM: ["знакомый", "acquainted"],
      AAF: ["знакомую", "acquainted"],
      AAN: ["знакомое", "acquainted"],
      AAP: ["знакомые", "acquainted"],
      AIM: ["знакомым", "acquainted"],
      AIF: ["знакомой", "acquainted"],
      AIN: ["знакомым", "acquainted"],
      AIP: ["знакомыми", "acquainted"],
      APM: ["знакомом", "acquainted"],
      APF: ["знакомой", "acquainted"],
      APN: ["знакомом", "acquainted"],
      APP: ["знакомых", "acquainted"],
      ASM: ["знаком", "acquainted"],
      ASF: ["знакома", "acquainted"],
      ASN: ["знакомо", "acquainted"],
      ASP: ["знакомы", "acquainted"],
    },
    сильный: {
      ANM: ["сильный", "strong, powerful"],
      ANF: ["сильная", "strong, powerful"],
      ANN: ["сильное", "strong, powerful"],
      ANP: ["сильные", "strong, powerful"],
      AGM: ["сильного", "strong, powerful"],
      AGF: ["сильной", "strong, powerful"],
      AGN: ["сильного", "strong, powerful"],
      AGP: ["сильных", "strong, powerful"],
      ADM: ["сильному", "strong, powerful"],
      ADF: ["сильной", "strong, powerful"],
      ADN: ["сильному", "strong, powerful"],
      ADP: ["сильным", "strong, powerful"],
      AAM: ["сильный", "strong, powerful"],
      AAF: ["сильную", "strong, powerful"],
      AAN: ["сильное", "strong, powerful"],
      AAP: ["сильные", "strong, powerful"],
      AIM: ["сильным", "strong, powerful"],
      AIF: ["сильной", "strong, powerful"],
      AIN: ["сильным", "strong, powerful"],
      AIP: ["сильными", "strong, powerful"],
      APM: ["сильном", "strong, powerful"],
      APF: ["сильной", "strong, powerful"],
      APN: ["сильном", "strong, powerful"],
      APP: ["сильных", "strong, powerful"],
      ASM: ["силён", "strong, powerful"],
      ASF: ["сильна", "strong, powerful"],
      ASN: ["сильно", "strong, powerful"],
      ASP: ["сильны сильны", "strong, powerful"],
      AC1: ["сильнее", "strong, powerful"],
    },
    скорый: {
      ANM: ["скорый", "quick, fast"],
      ANF: ["скорая", "quick, fast"],
      ANN: ["скорое", "quick, fast"],
      ANP: ["скорые", "quick, fast"],
      AGM: ["скорого", "quick, fast"],
      AGF: ["скорой", "quick, fast"],
      AGN: ["скорого", "quick, fast"],
      AGP: ["скорых", "quick, fast"],
      ADM: ["скорому", "quick, fast"],
      ADF: ["скорой", "quick, fast"],
      ADN: ["скорому", "quick, fast"],
      ADP: ["скорым", "quick, fast"],
      AAM: ["скорый", "quick, fast"],
      AAF: ["скорую", "quick, fast"],
      AAN: ["скорое", "quick, fast"],
      AAP: ["скорые", "quick, fast"],
      AIM: ["скорым", "quick, fast"],
      AIF: ["скорой", "quick, fast"],
      AIN: ["скорым", "quick, fast"],
      AIP: ["скорыми", "quick, fast"],
      APM: ["скором", "quick, fast"],
      APF: ["скорой", "quick, fast"],
      APN: ["скором", "quick, fast"],
      APP: ["скорых", "quick, fast"],
      ASM: ["скор", "quick, fast"],
      ASF: ["скора", "quick, fast"],
      ASN: ["скоро", "quick, fast"],
      ASP: ["скоры", "quick, fast"],
      AC1: ["скорее", "quick, fast"],
    },
    простой: {
      ANM: ["простой", "simple, easy, plain"],
      ANF: ["простая", "simple, easy, plain"],
      ANN: ["простое", "simple, easy, plain"],
      ANP: ["простые", "simple, easy, plain"],
      AGM: ["простого", "simple, easy, plain"],
      AGF: ["простой", "simple, easy, plain"],
      AGN: ["простого", "simple, easy, plain"],
      AGP: ["простых", "simple, easy, plain"],
      ADM: ["простому", "simple, easy, plain"],
      ADF: ["простой", "simple, easy, plain"],
      ADN: ["простому", "simple, easy, plain"],
      ADP: ["простым", "simple, easy, plain"],
      AAM: ["простой", "simple, easy, plain"],
      AAF: ["простую", "simple, easy, plain"],
      AAN: ["простое", "simple, easy, plain"],
      AAP: ["простые", "simple, easy, plain"],
      AIM: ["простым", "simple, easy, plain"],
      AIF: ["простой", "simple, easy, plain"],
      AIN: ["простым", "simple, easy, plain"],
      AIP: ["простыми", "simple, easy, plain"],
      APM: ["простом", "simple, easy, plain"],
      APF: ["простой", "simple, easy, plain"],
      APN: ["простом", "simple, easy, plain"],
      APP: ["простых", "simple, easy, plain"],
      ASM: ["прост", "simple, easy, plain"],
      ASF: ["проста", "simple, easy, plain"],
      ASN: ["просто", "simple, easy, plain"],
      ASP: ["просты просты", "simple, easy, plain"],
      AC1: ["проще", "simple, easy, plain"],
    },
    иной: {
      ANM: ["иной", "different, other"],
      ANF: ["иная", "different, other"],
      ANN: ["иное", "different, other"],
      ANP: ["иные", "different, other"],
      AGM: ["иного", "different, other"],
      AGF: ["иной", "different, other"],
      AGN: ["иного", "different, other"],
      AGP: ["иных", "different, other"],
      ADM: ["иному", "different, other"],
      ADF: ["иной", "different, other"],
      ADN: ["иному", "different, other"],
      ADP: ["иным", "different, other"],
      AAM: ["иной", "different, other"],
      AAF: ["иную", "different, other"],
      AAN: ["иное", "different, other"],
      AAP: ["иные", "different, other"],
      AIM: ["иным", "different, other"],
      AIF: ["иной", "different, other"],
      AIN: ["иным", "different, other"],
      AIP: ["иными", "different, other"],
      APM: ["ином", "different, other"],
      APF: ["иной", "different, other"],
      APN: ["ином", "different, other"],
      APP: ["иных", "different, other"],
    },
    зелёный: {
      ANM: ["зелёный", "green"],
      ANF: ["зелёная", "green"],
      ANN: ["зелёное", "green"],
      ANP: ["зелёные", "green"],
      AGM: ["зелёного", "green"],
      AGF: ["зелёной", "green"],
      AGN: ["зелёного", "green"],
      AGP: ["зелёных", "green"],
      ADM: ["зелёному", "green"],
      ADF: ["зелёной", "green"],
      ADN: ["зелёному", "green"],
      ADP: ["зелёным", "green"],
      AAM: ["зелёный", "green"],
      AAF: ["зелёную", "green"],
      AAN: ["зелёное", "green"],
      AAP: ["зелёные", "green"],
      AIM: ["зелёным", "green"],
      AIF: ["зелёной", "green"],
      AIN: ["зелёным", "green"],
      AIP: ["зелёными", "green"],
      APM: ["зелёном", "green"],
      APF: ["зелёной", "green"],
      APN: ["зелёном", "green"],
      APP: ["зелёных", "green"],
      ASM: ["зелен", "green"],
      ASF: ["зелена", "green"],
      ASN: ["зелено", "green"],
      ASP: ["зелены зелены", "green"],
      AC1: ["зеленее", "green"],
    },
    кой: {
      ANM: [
        "кой",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ANF: [
        "коя",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ANN: [
        "кое",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ANP: [
        "кои",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AGM: [
        "коего",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AGF: [
        "коей",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AGN: [
        "коего",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AGP: [
        "коих",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ADM: [
        "коему",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ADF: [
        "коей",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ADN: [
        "коему",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      ADP: [
        "коим",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AAM: [
        "кой",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AAF: [
        "кою",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AAN: [
        "кое",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AAP: [
        "кои",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AIM: [
        "коим",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AIF: [
        "коей",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AIN: [
        "коим",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      AIP: [
        "коими",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      APM: [
        "коем",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      APF: [
        "коей",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      APN: [
        "коем",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
      APP: [
        "коих",
        "which (old-fashioned, literary); mostly found in set expressions: ни в ко́ем слу́чае, ни ко́им о́бразом, в кои ве́ки, на кой чёрт?",
      ],
    },
    странный: {
      ANM: ["странный", "strange"],
      ANF: ["странная", "strange"],
      ANN: ["странное", "strange"],
      ANP: ["странные", "strange"],
      AGM: ["странного", "strange"],
      AGF: ["странной", "strange"],
      AGN: ["странного", "strange"],
      AGP: ["странных", "strange"],
      ADM: ["странному", "strange"],
      ADF: ["странной", "strange"],
      ADN: ["странному", "strange"],
      ADP: ["странным", "strange"],
      AAM: ["странный", "strange"],
      AAF: ["странную", "strange"],
      AAN: ["странное", "strange"],
      AAP: ["странные", "strange"],
      AIM: ["странным", "strange"],
      AIF: ["странной", "strange"],
      AIN: ["странным", "strange"],
      AIP: ["странными", "strange"],
      APM: ["странном", "strange"],
      APF: ["странной", "strange"],
      APN: ["странном", "strange"],
      APP: ["странных", "strange"],
      ASM: ["странен", "strange"],
      ASF: ["странна", "strange"],
      ASN: ["странно", "strange"],
      ASP: ["странны", "strange"],
    },
    единственный: {
      ANM: ["единственный", "only, sole"],
      ANF: ["единственная", "only, sole"],
      ANN: ["единственное", "only, sole"],
      ANP: ["единственные", "only, sole"],
      AGM: ["единственного", "only, sole"],
      AGF: ["единственной", "only, sole"],
      AGN: ["единственного", "only, sole"],
      AGP: ["единственных", "only, sole"],
      ADM: ["единственному", "only, sole"],
      ADF: ["единственной", "only, sole"],
      ADN: ["единственному", "only, sole"],
      ADP: ["единственным", "only, sole"],
      AAM: ["единственный", "only, sole"],
      AAF: ["единственную", "only, sole"],
      AAN: ["единственное", "only, sole"],
      AAP: ["единственные", "only, sole"],
      AIM: ["единственным", "only, sole"],
      AIF: ["единственной", "only, sole"],
      AIN: ["единственным", "only, sole"],
      AIP: ["единственными", "only, sole"],
      APM: ["единственном", "only, sole"],
      APF: ["единственной", "only, sole"],
      APN: ["единственном", "only, sole"],
      APP: ["единственных", "only, sole"],
      ASM: ["единственен", "only, sole"],
      ASF: ["единственна", "only, sole"],
      ASN: ["единственно", "only, sole"],
      ASP: ["единственны", "only, sole"],
    },
    короткий: {
      ANM: ["короткий", "short"],
      ANF: ["короткая", "short"],
      ANN: ["короткое", "short"],
      ANP: ["короткие", "short"],
      AGM: ["короткого", "short"],
      AGF: ["короткой", "short"],
      AGN: ["короткого", "short"],
      AGP: ["коротких", "short"],
      ADM: ["короткому", "short"],
      ADF: ["короткой", "short"],
      ADN: ["короткому", "short"],
      ADP: ["коротким", "short"],
      AAM: ["короткий", "short"],
      AAF: ["короткую", "short"],
      AAN: ["короткое", "short"],
      AAP: ["короткие", "short"],
      AIM: ["коротким", "short"],
      AIF: ["короткой", "short"],
      AIN: ["коротким", "short"],
      AIP: ["короткими", "short"],
      APM: ["коротком", "short"],
      APF: ["короткой", "short"],
      APN: ["коротком", "short"],
      APP: ["коротких", "short"],
      ASM: ["короток короток", "short"],
      ASF: ["коротка", "short"],
      ASN: ["коротко коротко коротко", "short"],
      ASP: ["коротки коротки коротки", "short"],
      AC1: ["короче", "short"],
    },
    добрый: {
      ANM: ["добрый", "kind"],
      ANF: ["добрая", "kind"],
      ANN: ["доброе", "kind"],
      ANP: ["добрые", "kind"],
      AGM: ["доброго", "kind"],
      AGF: ["доброй", "kind"],
      AGN: ["доброго", "kind"],
      AGP: ["добрых", "kind"],
      ADM: ["доброму", "kind"],
      ADF: ["доброй", "kind"],
      ADN: ["доброму", "kind"],
      ADP: ["добрым", "kind"],
      AAM: ["доброго", "kind"],
      AAF: ["добрую", "kind"],
      AAN: ["доброе", "kind"],
      AAP: ["добрых", "kind"],
      AIM: ["добрым", "kind"],
      AIF: ["доброй", "kind"],
      AIN: ["добрым", "kind"],
      AIP: ["добрыми", "kind"],
      APM: ["добром", "kind"],
      APF: ["доброй", "kind"],
      APN: ["добром", "kind"],
      APP: ["добрых", "kind"],
      ASM: ["добр", "kind"],
      ASF: ["добра", "kind"],
      ASN: ["добро", "kind"],
      ASP: ["добры добры", "kind"],
      AC1: ["добрее", "kind"],
    },
    тёмный: {
      ANM: ["тёмный", "dark"],
      ANF: ["тёмная", "dark"],
      ANN: ["тёмное", "dark"],
      ANP: ["тёмные", "dark"],
      AGM: ["тёмного", "dark"],
      AGF: ["тёмной", "dark"],
      AGN: ["тёмного", "dark"],
      AGP: ["тёмных", "dark"],
      ADM: ["тёмному", "dark"],
      ADF: ["тёмной", "dark"],
      ADN: ["тёмному", "dark"],
      ADP: ["тёмным", "dark"],
      AAM: ["тёмный", "dark"],
      AAF: ["тёмную", "dark"],
      AAN: ["тёмное", "dark"],
      AAP: ["тёмные", "dark"],
      AIM: ["тёмным", "dark"],
      AIF: ["тёмной", "dark"],
      AIN: ["тёмным", "dark"],
      AIP: ["тёмными", "dark"],
      APM: ["тёмном", "dark"],
      APF: ["тёмной", "dark"],
      APN: ["тёмном", "dark"],
      APP: ["тёмных", "dark"],
      ASM: ["тёмен", "dark"],
      ASF: ["темна", "dark"],
      ASN: ["темно", "dark"],
      ASP: ["темны", "dark"],
      AC1: ["темнее", "dark"],
    },
    лучший: {
      ANM: ["лучший", "better, best"],
      ANF: ["лучшая", "better, best"],
      ANN: ["лучшее", "better, best"],
      ANP: ["лучшие", "better, best"],
      AGM: ["лучшего", "better, best"],
      AGF: ["лучшей", "better, best"],
      AGN: ["лучшего", "better, best"],
      AGP: ["лучших", "better, best"],
      ADM: ["лучшему", "better, best"],
      ADF: ["лучшей", "better, best"],
      ADN: ["лучшему", "better, best"],
      ADP: ["лучшим", "better, best"],
      AAM: ["лучший", "better, best"],
      AAF: ["лучшую", "better, best"],
      AAN: ["лучшее", "better, best"],
      AAP: ["лучшие", "better, best"],
      AIM: ["лучшим", "better, best"],
      AIF: ["лучшей", "better, best"],
      AIN: ["лучшим", "better, best"],
      AIP: ["лучшими", "better, best"],
      APM: ["лучшем", "better, best"],
      APF: ["лучшей", "better, best"],
      APN: ["лучшем", "better, best"],
      APP: ["лучших", "better, best"],
    },
    военный: {
      ANM: ["военный", "military"],
      ANF: ["военная", "military"],
      ANN: ["военное", "military"],
      ANP: ["военные", "military"],
      AGM: ["военного", "military"],
      AGF: ["военной", "military"],
      AGN: ["военного", "military"],
      AGP: ["военных", "military"],
      ADM: ["военному", "military"],
      ADF: ["военной", "military"],
      ADN: ["военному", "military"],
      ADP: ["военным", "military"],
      AAM: ["военный", "military"],
      AAF: ["военную", "military"],
      AAN: ["военное", "military"],
      AAP: ["военные", "military"],
      AIM: ["военным", "military"],
      AIF: ["военной", "military"],
      AIN: ["военным", "military"],
      AIP: ["военными", "military"],
      APM: ["военном", "military"],
      APF: ["военной", "military"],
      APN: ["военном", "military"],
      APP: ["военных", "military"],
      ASM: ["военен", "military"],
      ASF: ["военна", "military"],
      ASN: ["военно", "military"],
      ASP: ["военны", "military"],
    },
    страшный: {
      ANM: ["страшный", "terrible, frightful"],
      ANF: ["страшная", "terrible, frightful"],
      ANN: ["страшное", "terrible, frightful"],
      ANP: ["страшные", "terrible, frightful"],
      AGM: ["страшного", "terrible, frightful"],
      AGF: ["страшной", "terrible, frightful"],
      AGN: ["страшного", "terrible, frightful"],
      AGP: ["страшных", "terrible, frightful"],
      ADM: ["страшному", "terrible, frightful"],
      ADF: ["страшной", "terrible, frightful"],
      ADN: ["страшному", "terrible, frightful"],
      ADP: ["страшным", "terrible, frightful"],
      AAM: ["страшный", "terrible, frightful"],
      AAF: ["страшную", "terrible, frightful"],
      AAN: ["страшное", "terrible, frightful"],
      AAP: ["страшные", "terrible, frightful"],
      AIM: ["страшным", "terrible, frightful"],
      AIF: ["страшной", "terrible, frightful"],
      AIN: ["страшным", "terrible, frightful"],
      AIP: ["страшными", "terrible, frightful"],
      APM: ["страшном", "terrible, frightful"],
      APF: ["страшной", "terrible, frightful"],
      APN: ["страшном", "terrible, frightful"],
      APP: ["страшных", "terrible, frightful"],
      ASM: ["страшен", "terrible, frightful"],
      ASF: ["страшна", "terrible, frightful"],
      ASN: ["страшно", "terrible, frightful"],
      ASP: ["страшны страшны", "terrible, frightful"],
      AC1: ["страшнее", "terrible, frightful"],
    },
    необходимый: {
      ANM: ["необходимый", "necessary"],
      ANF: ["необходимая", "necessary"],
      ANN: ["необходимое", "necessary"],
      ANP: ["необходимые", "necessary"],
      AGM: ["необходимого", "necessary"],
      AGF: ["необходимой", "necessary"],
      AGN: ["необходимого", "necessary"],
      AGP: ["необходимых", "necessary"],
      ADM: ["необходимому", "necessary"],
      ADF: ["необходимой", "necessary"],
      ADN: ["необходимому", "necessary"],
      ADP: ["необходимым", "necessary"],
      AAM: ["необходимый", "necessary"],
      AAF: ["необходимую", "necessary"],
      AAN: ["необходимое", "necessary"],
      AAP: ["необходимые", "necessary"],
      AIM: ["необходимым", "necessary"],
      AIF: ["необходимой", "necessary"],
      AIN: ["необходимым", "necessary"],
      AIP: ["необходимыми", "necessary"],
      APM: ["необходимом", "necessary"],
      APF: ["необходимой", "necessary"],
      APN: ["необходимом", "necessary"],
      APP: ["необходимых", "necessary"],
      ASM: ["необходим", "necessary"],
      ASF: ["необходима", "necessary"],
      ASN: ["необходимо", "necessary"],
      ASP: ["необходимы", "necessary"],
      AC1: ["необходимее", "necessary"],
    },
    лёгкий: {
      ANM: ["лёгкий", "light, easy"],
      ANF: ["лёгкая", "light, easy"],
      ANN: ["лёгкое", "light, easy"],
      ANP: ["лёгкие", "light, easy"],
      AGM: ["лёгкого", "light, easy"],
      AGF: ["лёгкой", "light, easy"],
      AGN: ["лёгкого", "light, easy"],
      AGP: ["лёгких", "light, easy"],
      ADM: ["лёгкому", "light, easy"],
      ADF: ["лёгкой", "light, easy"],
      ADN: ["лёгкому", "light, easy"],
      ADP: ["лёгким", "light, easy"],
      AAM: ["лёгкий", "light, easy"],
      AAF: ["лёгкую", "light, easy"],
      AAN: ["лёгкое", "light, easy"],
      AAP: ["лёгкие", "light, easy"],
      AIM: ["лёгким", "light, easy"],
      AIF: ["лёгкой", "light, easy"],
      AIN: ["лёгким", "light, easy"],
      AIP: ["лёгкими", "light, easy"],
      APM: ["лёгком", "light, easy"],
      APF: ["лёгкой", "light, easy"],
      APN: ["лёгком", "light, easy"],
      APP: ["лёгких", "light, easy"],
      ASM: ["лёгок", "light, easy"],
      ASF: ["легка", "light, easy"],
      ASN: ["легко", "light, easy"],
      ASP: ["легки", "light, easy"],
      AC1: ["легче", "light, easy"],
    },
    основной: {
      ANM: ["основной", "main"],
      ANF: ["основная", "main"],
      ANN: ["основное", "main"],
      ANP: ["основные", "main"],
      AGM: ["основного", "main"],
      AGF: ["основной", "main"],
      AGN: ["основного", "main"],
      AGP: ["основных", "main"],
      ADM: ["основному", "main"],
      ADF: ["основной", "main"],
      ADN: ["основному", "main"],
      ADP: ["основным", "main"],
      AAM: ["основной", "main"],
      AAF: ["основную", "main"],
      AAN: ["основное", "main"],
      AAP: ["основные", "main"],
      AIM: ["основным", "main"],
      AIF: ["основной", "main"],
      AIN: ["основным", "main"],
      AIP: ["основными", "main"],
      APM: ["основном", "main"],
      APF: ["основной", "main"],
      APN: ["основном", "main"],
      APP: ["основных", "main"],
    },
    широкий: {
      ANM: ["широкий", "wide"],
      ANF: ["широкая", "wide"],
      ANN: ["широкое", "wide"],
      ANP: ["широкие", "wide"],
      AGM: ["широкого", "wide"],
      AGF: ["широкой", "wide"],
      AGN: ["широкого", "wide"],
      AGP: ["широких", "wide"],
      ADM: ["широкому", "wide"],
      ADF: ["широкой", "wide"],
      ADN: ["широкому", "wide"],
      ADP: ["широким", "wide"],
      AAM: ["широкий", "wide"],
      AAF: ["широкую", "wide"],
      AAN: ["широкое", "wide"],
      AAP: ["широкие", "wide"],
      AIM: ["широким", "wide"],
      AIF: ["широкой", "wide"],
      AIN: ["широким", "wide"],
      AIP: ["широкими", "wide"],
      APM: ["широком", "wide"],
      APF: ["широкой", "wide"],
      APN: ["широком", "wide"],
      APP: ["широких", "wide"],
      ASM: ["широк", "wide"],
      ASF: ["широка", "wide"],
      ASN: ["широко широко", "wide"],
      ASP: ["широки широки", "wide"],
      AC1: ["шире", "wide"],
    },
    красивый: {
      ANM: ["красивый", "beautiful; masterrussian, dot com"],
      ANF: ["красивая", "beautiful; masterrussian, dot com"],
      ANN: ["красивое", "beautiful; masterrussian, dot com"],
      ANP: ["красивые", "beautiful; masterrussian, dot com"],
      AGM: ["красивого", "beautiful; masterrussian, dot com"],
      AGF: ["красивой", "beautiful; masterrussian, dot com"],
      AGN: ["красивого", "beautiful; masterrussian, dot com"],
      AGP: ["красивых", "beautiful; masterrussian, dot com"],
      ADM: ["красивому", "beautiful; masterrussian, dot com"],
      ADF: ["красивой", "beautiful; masterrussian, dot com"],
      ADN: ["красивому", "beautiful; masterrussian, dot com"],
      ADP: ["красивым", "beautiful; masterrussian, dot com"],
      AAM: ["красивый", "beautiful; masterrussian, dot com"],
      AAF: ["красивую", "beautiful; masterrussian, dot com"],
      AAN: ["красивое", "beautiful; masterrussian, dot com"],
      AAP: ["красивые", "beautiful; masterrussian, dot com"],
      AIM: ["красивым", "beautiful; masterrussian, dot com"],
      AIF: ["красивой", "beautiful; masterrussian, dot com"],
      AIN: ["красивым", "beautiful; masterrussian, dot com"],
      AIP: ["красивыми", "beautiful; masterrussian, dot com"],
      APM: ["красивом", "beautiful; masterrussian, dot com"],
      APF: ["красивой", "beautiful; masterrussian, dot com"],
      APN: ["красивом", "beautiful; masterrussian, dot com"],
      APP: ["красивых", "beautiful; masterrussian, dot com"],
      ASM: ["красив", "beautiful; masterrussian, dot com"],
      ASF: ["красива", "beautiful; masterrussian, dot com"],
      ASN: ["красиво", "beautiful; masterrussian, dot com"],
      ASP: ["красивы", "beautiful; masterrussian, dot com"],
      AC1: ["красивее", "beautiful; masterrussian, dot com"],
      AC2: ["краше", "beautiful; masterrussian, dot com"],
    },
    серый: {
      ANM: ["серый", "grey; dull"],
      ANF: ["серая", "grey; dull"],
      ANN: ["серое", "grey; dull"],
      ANP: ["серые", "grey; dull"],
      AGM: ["серого", "grey; dull"],
      AGF: ["серой", "grey; dull"],
      AGN: ["серого", "grey; dull"],
      AGP: ["серых", "grey; dull"],
      ADM: ["серому", "grey; dull"],
      ADF: ["серой", "grey; dull"],
      ADN: ["серому", "grey; dull"],
      ADP: ["серым", "grey; dull"],
      AAM: ["серый", "grey; dull"],
      AAF: ["серую", "grey; dull"],
      AAN: ["серое", "grey; dull"],
      AAP: ["серые", "grey; dull"],
      AIM: ["серым", "grey; dull"],
      AIF: ["серой", "grey; dull"],
      AIN: ["серым", "grey; dull"],
      AIP: ["серыми", "grey; dull"],
      APM: ["сером", "grey; dull"],
      APF: ["серой", "grey; dull"],
      APN: ["сером", "grey; dull"],
      APP: ["серых", "grey; dull"],
      ASM: ["сер", "grey; dull"],
      ASF: ["сера", "grey; dull"],
      ASN: ["серо", "grey; dull"],
      ASP: ["серы", "grey; dull"],
    },
    небольшой: {
      ANM: ["небольшой", "small, not great"],
      ANF: ["небольшая", "small, not great"],
      ANN: ["небольшое", "small, not great"],
      ANP: ["небольшие", "small, not great"],
      AGM: ["небольшого", "small, not great"],
      AGF: ["небольшой", "small, not great"],
      AGN: ["небольшого", "small, not great"],
      AGP: ["небольших", "small, not great"],
      ADM: ["небольшому", "small, not great"],
      ADF: ["небольшой", "small, not great"],
      ADN: ["небольшому", "small, not great"],
      ADP: ["небольшим", "small, not great"],
      AAM: ["небольшой", "small, not great"],
      AAF: ["небольшую", "small, not great"],
      AAN: ["небольшое", "small, not great"],
      AAP: ["небольшие", "small, not great"],
      AIM: ["небольшим", "small, not great"],
      AIF: ["небольшой", "small, not great"],
      AIN: ["небольшим", "small, not great"],
      AIP: ["небольшими", "small, not great"],
      APM: ["небольшом", "small, not great"],
      APF: ["небольшой", "small, not great"],
      APN: ["небольшом", "small, not great"],
      APP: ["небольших", "small, not great"],
    },
    пустой: {
      ANM: ["пустой", "empty, hollow; idle"],
      ANF: ["пустая", "empty, hollow; idle"],
      ANN: ["пустое", "empty, hollow; idle"],
      ANP: ["пустые", "empty, hollow; idle"],
      AGM: ["пустого", "empty, hollow; idle"],
      AGF: ["пустой", "empty, hollow; idle"],
      AGN: ["пустого", "empty, hollow; idle"],
      AGP: ["пустых", "empty, hollow; idle"],
      ADM: ["пустому", "empty, hollow; idle"],
      ADF: ["пустой", "empty, hollow; idle"],
      ADN: ["пустому", "empty, hollow; idle"],
      ADP: ["пустым", "empty, hollow; idle"],
      AAM: ["пустого", "empty, hollow; idle"],
      AAF: ["пустую", "empty, hollow; idle"],
      AAN: ["пустое", "empty, hollow; idle"],
      AAP: ["пустых", "empty, hollow; idle"],
      AIM: ["пустым", "empty, hollow; idle"],
      AIF: ["пустой", "empty, hollow; idle"],
      AIN: ["пустым", "empty, hollow; idle"],
      AIP: ["пустыми", "empty, hollow; idle"],
      APM: ["пустом", "empty, hollow; idle"],
      APF: ["пустой", "empty, hollow; idle"],
      APN: ["пустом", "empty, hollow; idle"],
      APP: ["пустых", "empty, hollow; idle"],
      ASM: ["пуст", "empty, hollow; idle"],
      ASF: ["пуста", "empty, hollow; idle"],
      ASN: ["пусто", "empty, hollow; idle"],
      ASP: ["пусты", "empty, hollow; idle"],
    },
    человеческий: {
      ANM: ["человеческий", "human"],
      ANF: ["человеческая", "human"],
      ANN: ["человеческое", "human"],
      ANP: ["человеческие", "human"],
      AGM: ["человеческого", "human"],
      AGF: ["человеческой", "human"],
      AGN: ["человеческого", "human"],
      AGP: ["человеческих", "human"],
      ADM: ["человеческому", "human"],
      ADF: ["человеческой", "human"],
      ADN: ["человеческому", "human"],
      ADP: ["человеческим", "human"],
      AAM: ["человеческий", "human"],
      AAF: ["человеческую", "human"],
      AAN: ["человеческое", "human"],
      AAP: ["человеческие", "human"],
      AIM: ["человеческим", "human"],
      AIF: ["человеческой", "human"],
      AIN: ["человеческим", "human"],
      AIP: ["человеческими", "human"],
      APM: ["человеческом", "human"],
      APF: ["человеческой", "human"],
      APN: ["человеческом", "human"],
      APP: ["человеческих", "human"],
    },
    толстый: {
      ANM: ["толстый", "thick, heavy, fat"],
      ANF: ["толстая", "thick, heavy, fat"],
      ANN: ["толстое", "thick, heavy, fat"],
      ANP: ["толстые", "thick, heavy, fat"],
      AGM: ["толстого", "thick, heavy, fat"],
      AGF: ["толстой", "thick, heavy, fat"],
      AGN: ["толстого", "thick, heavy, fat"],
      AGP: ["толстых", "thick, heavy, fat"],
      ADM: ["толстому", "thick, heavy, fat"],
      ADF: ["толстой", "thick, heavy, fat"],
      ADN: ["толстому", "thick, heavy, fat"],
      ADP: ["толстым", "thick, heavy, fat"],
      AAM: ["толстый", "thick, heavy, fat"],
      AAF: ["толстую", "thick, heavy, fat"],
      AAN: ["толстое", "thick, heavy, fat"],
      AAP: ["толстые", "thick, heavy, fat"],
      AIM: ["толстым", "thick, heavy, fat"],
      AIF: ["толстой", "thick, heavy, fat"],
      AIN: ["толстым", "thick, heavy, fat"],
      AIP: ["толстыми", "thick, heavy, fat"],
      APM: ["толстом", "thick, heavy, fat"],
      APF: ["толстой", "thick, heavy, fat"],
      APN: ["толстом", "thick, heavy, fat"],
      APP: ["толстых", "thick, heavy, fat"],
      ASM: ["толст", "thick, heavy, fat"],
      ASF: ["толста", "thick, heavy, fat"],
      ASN: ["толсто", "thick, heavy, fat"],
      ASP: ["толсты", "thick, heavy, fat"],
      AC1: ["толще", "thick, heavy, fat"],
    },
    чистый: {
      ANM: ["чистый", "clean, pure"],
      ANF: ["чистая", "clean, pure"],
      ANN: ["чистое", "clean, pure"],
      ANP: ["чистые", "clean, pure"],
      AGM: ["чистого", "clean, pure"],
      AGF: ["чистой", "clean, pure"],
      AGN: ["чистого", "clean, pure"],
      AGP: ["чистых", "clean, pure"],
      ADM: ["чистому", "clean, pure"],
      ADF: ["чистой", "clean, pure"],
      ADN: ["чистому", "clean, pure"],
      ADP: ["чистым", "clean, pure"],
      AAM: ["чистый", "clean, pure"],
      AAF: ["чистую", "clean, pure"],
      AAN: ["чистое", "clean, pure"],
      AAP: ["чистые", "clean, pure"],
      AIM: ["чистым", "clean, pure"],
      AIF: ["чистой", "clean, pure"],
      AIN: ["чистым", "clean, pure"],
      AIP: ["чистыми", "clean, pure"],
      APM: ["чистом", "clean, pure"],
      APF: ["чистой", "clean, pure"],
      APN: ["чистом", "clean, pure"],
      APP: ["чистых", "clean, pure"],
      ASM: ["чист", "clean, pure"],
      ASF: ["чиста", "clean, pure"],
      ASN: ["чисто", "clean, pure"],
      ASP: ["чисты чисты", "clean, pure"],
      AC1: ["чище", "clean, pure"],
    },
    прежний: {
      ANM: ["прежний", "former"],
      ANF: ["прежняя", "former"],
      ANN: ["прежнее", "former"],
      ANP: ["прежние", "former"],
      AGM: ["прежнего", "former"],
      AGF: ["прежней", "former"],
      AGN: ["прежнего", "former"],
      AGP: ["прежних", "former"],
      ADM: ["прежнему", "former"],
      ADF: ["прежней", "former"],
      ADN: ["прежнему", "former"],
      ADP: ["прежним", "former"],
      AAM: ["прежний", "former"],
      AAF: ["прежнюю", "former"],
      AAN: ["прежнее", "former"],
      AAP: ["прежние", "former"],
      AIM: ["прежним", "former"],
      AIF: ["прежней", "former"],
      AIN: ["прежним", "former"],
      AIP: ["прежними", "former"],
      APM: ["прежнем", "former"],
      APF: ["прежней", "former"],
      APN: ["прежнем", "former"],
      APP: ["прежних", "former"],
    },
    худой: {
      ANM: ["худой", "thin, skinny; masterrussian.com"],
      ANF: ["худая", "thin, skinny; masterrussian.com"],
      ANN: ["худое", "thin, skinny; masterrussian.com"],
      ANP: ["худые", "thin, skinny; masterrussian.com"],
      AGM: ["худого", "thin, skinny; masterrussian.com"],
      AGF: ["худой", "thin, skinny; masterrussian.com"],
      AGN: ["худого", "thin, skinny; masterrussian.com"],
      AGP: ["худых", "thin, skinny; masterrussian.com"],
      ADM: ["худому", "thin, skinny; masterrussian.com"],
      ADF: ["худой", "thin, skinny; masterrussian.com"],
      ADN: ["худому", "thin, skinny; masterrussian.com"],
      ADP: ["худым", "thin, skinny; masterrussian.com"],
      AAM: ["худого", "thin, skinny; masterrussian.com"],
      AAF: ["худую", "thin, skinny; masterrussian.com"],
      AAN: ["худое", "thin, skinny; masterrussian.com"],
      AAP: ["худых", "thin, skinny; masterrussian.com"],
      AIM: ["худым", "thin, skinny; masterrussian.com"],
      AIF: ["худой", "thin, skinny; masterrussian.com"],
      AIN: ["худым", "thin, skinny; masterrussian.com"],
      AIP: ["худыми", "thin, skinny; masterrussian.com"],
      APM: ["худом", "thin, skinny; masterrussian.com"],
      APF: ["худой", "thin, skinny; masterrussian.com"],
      APN: ["худом", "thin, skinny; masterrussian.com"],
      APP: ["худых", "thin, skinny; masterrussian.com"],
      ASM: ["худ", "thin, skinny; masterrussian.com"],
      ASF: ["худа", "thin, skinny; masterrussian.com"],
      ASN: ["худо", "thin, skinny; masterrussian.com"],
      ASP: ["худы", "thin, skinny; masterrussian.com"],
      AC1: ["худее", "thin, skinny; masterrussian.com"],
    },
    чужой: {
      ANM: ["чужой", "another person's"],
      ANF: ["чужая", "another person's"],
      ANN: ["чужое", "another person's"],
      ANP: ["чужие", "another person's"],
      AGM: ["чужого", "another person's"],
      AGF: ["чужой", "another person's"],
      AGN: ["чужого", "another person's"],
      AGP: ["чужих", "another person's"],
      ADM: ["чужому", "another person's"],
      ADF: ["чужой", "another person's"],
      ADN: ["чужому", "another person's"],
      ADP: ["чужим", "another person's"],
      AAM: ["чужой", "another person's"],
      AAF: ["чужую", "another person's"],
      AAN: ["чужое", "another person's"],
      AAP: ["чужие", "another person's"],
      AIM: ["чужим", "another person's"],
      AIF: ["чужой", "another person's"],
      AIN: ["чужим", "another person's"],
      AIP: ["чужими", "another person's"],
      APM: ["чужом", "another person's"],
      APF: ["чужой", "another person's"],
      APN: ["чужом", "another person's"],
      APP: ["чужих", "another person's"],
      ASP: ["чужи", "another person's"],
    },
    особый: {
      ANM: ["особый", "special"],
      ANF: ["особая", "special"],
      ANN: ["особое", "special"],
      ANP: ["особые", "special"],
      AGM: ["особого", "special"],
      AGF: ["особой", "special"],
      AGN: ["особого", "special"],
      AGP: ["особых", "special"],
      ADM: ["особому", "special"],
      ADF: ["особой", "special"],
      ADN: ["особому", "special"],
      ADP: ["особым", "special"],
      AAM: ["особый", "special"],
      AAF: ["особую", "special"],
      AAN: ["особое", "special"],
      AAP: ["особые", "special"],
      AIM: ["особым", "special"],
      AIF: ["особой", "special"],
      AIN: ["особым", "special"],
      AIP: ["особыми", "special"],
      APM: ["особом", "special"],
      APF: ["особой", "special"],
      APN: ["особом", "special"],
      APP: ["особых", "special"],
      ASM: ["особ", "special"],
      ASF: ["особа", "special"],
      ASN: ["особо", "special"],
      ASP: ["особы", "special"],
    },
    близкий: {
      ANM: ["близкий", "near, similar; intimate"],
      ANF: ["близкая", "near, similar; intimate"],
      ANN: ["близкое", "near, similar; intimate"],
      ANP: ["близкие", "near, similar; intimate"],
      AGM: ["близкого", "near, similar; intimate"],
      AGF: ["близкой", "near, similar; intimate"],
      AGN: ["близкого", "near, similar; intimate"],
      AGP: ["близких", "near, similar; intimate"],
      ADM: ["близкому", "near, similar; intimate"],
      ADF: ["близкой", "near, similar; intimate"],
      ADN: ["близкому", "near, similar; intimate"],
      ADP: ["близким", "near, similar; intimate"],
      AAM: ["близкий", "near, similar; intimate"],
      AAF: ["близкую", "near, similar; intimate"],
      AAN: ["близкое", "near, similar; intimate"],
      AAP: ["близкие", "near, similar; intimate"],
      AIM: ["близким", "near, similar; intimate"],
      AIF: ["близкой", "near, similar; intimate"],
      AIN: ["близким", "near, similar; intimate"],
      AIP: ["близкими", "near, similar; intimate"],
      APM: ["близком", "near, similar; intimate"],
      APF: ["близкой", "near, similar; intimate"],
      APN: ["близком", "near, similar; intimate"],
      APP: ["близких", "near, similar; intimate"],
      ASM: ["близок", "near, similar; intimate"],
      ASF: ["близка", "near, similar; intimate"],
      ASN: ["близко", "near, similar; intimate"],
      ASP: ["близки", "near, similar; intimate"],
      AC1: ["ближе", "near, similar; intimate"],
    },
    больной: {
      ANM: ["больной", "sick"],
      ANF: ["больная", "sick"],
      ANN: ["больное", "sick"],
      ANP: ["больные", "sick"],
      AGM: ["больного", "sick"],
      AGF: ["больной", "sick"],
      AGN: ["больного", "sick"],
      AGP: ["больных", "sick"],
      ADM: ["больному", "sick"],
      ADF: ["больной", "sick"],
      ADN: ["больному", "sick"],
      ADP: ["больным", "sick"],
      AAM: ["больной", "sick"],
      AAF: ["больную", "sick"],
      AAN: ["больное", "sick"],
      AAP: ["больные", "sick"],
      AIM: ["больным", "sick"],
      AIF: ["больной", "sick"],
      AIN: ["больным", "sick"],
      AIP: ["больными", "sick"],
      APM: ["больном", "sick"],
      APF: ["больной", "sick"],
      APN: ["больном", "sick"],
      APP: ["больных", "sick"],
      ASM: ["болен", "sick"],
      ASF: ["больна", "sick"],
      ASN: ["больно", "sick"],
      ASP: ["больны", "sick"],
      AC1: ["больнее", "sick"],
    },
    старший: {
      ANM: ["старший", "elder, senior"],
      ANF: ["старшая", "elder, senior"],
      ANN: ["старшее", "elder, senior"],
      ANP: ["старшие", "elder, senior"],
      AGM: ["старшего", "elder, senior"],
      AGF: ["старшей", "elder, senior"],
      AGN: ["старшего", "elder, senior"],
      AGP: ["старших", "elder, senior"],
      ADM: ["старшему", "elder, senior"],
      ADF: ["старшей", "elder, senior"],
      ADN: ["старшему", "elder, senior"],
      ADP: ["старшим", "elder, senior"],
      AAM: ["старший", "elder, senior"],
      AAF: ["старшую", "elder, senior"],
      AAN: ["старшее", "elder, senior"],
      AAP: ["старшие", "elder, senior"],
      AIM: ["старшим", "elder, senior"],
      AIF: ["старшей", "elder, senior"],
      AIN: ["старшим", "elder, senior"],
      AIP: ["старшими", "elder, senior"],
      APM: ["старшем", "elder, senior"],
      APF: ["старшей", "elder, senior"],
      APN: ["старшем", "elder, senior"],
      APP: ["старших", "elder, senior"],
    },
    подобный: {
      ANM: ["подобный", "similar, like"],
      ANF: ["подобная", "similar, like"],
      ANN: ["подобное", "similar, like"],
      ANP: ["подобные", "similar, like"],
      AGM: ["подобного", "similar, like"],
      AGF: ["подобной", "similar, like"],
      AGN: ["подобного", "similar, like"],
      AGP: ["подобных", "similar, like"],
      ADM: ["подобному", "similar, like"],
      ADF: ["подобной", "similar, like"],
      ADN: ["подобному", "similar, like"],
      ADP: ["подобным", "similar, like"],
      AAM: ["подобный", "similar, like"],
      AAF: ["подобную", "similar, like"],
      AAN: ["подобное", "similar, like"],
      AAP: ["подобные", "similar, like"],
      AIM: ["подобным", "similar, like"],
      AIF: ["подобной", "similar, like"],
      AIN: ["подобным", "similar, like"],
      AIP: ["подобными", "similar, like"],
      APM: ["подобном", "similar, like"],
      APF: ["подобной", "similar, like"],
      APN: ["подобном", "similar, like"],
      APP: ["подобных", "similar, like"],
      ASM: ["подобен", "similar, like"],
      ASF: ["подобна", "similar, like"],
      ASN: ["подобно", "similar, like"],
      ASP: ["подобны", "similar, like"],
    },
    правый: {
      ANM: ["правый", "right"],
      ANF: ["правая", "right"],
      ANN: ["правое", "right"],
      ANP: ["правые", "right"],
      AGM: ["правого", "right"],
      AGF: ["правой", "right"],
      AGN: ["правого", "right"],
      AGP: ["правых", "right"],
      ADM: ["правому", "right"],
      ADF: ["правой", "right"],
      ADN: ["правому", "right"],
      ADP: ["правым", "right"],
      AAM: ["правый", "right"],
      AAF: ["правую", "right"],
      AAN: ["правое", "right"],
      AAP: ["правые", "right"],
      AIM: ["правым", "right"],
      AIF: ["правой", "right"],
      AIN: ["правым", "right"],
      AIP: ["правыми", "right"],
      APM: ["правом", "right"],
      APF: ["правой", "right"],
      APN: ["правом", "right"],
      APP: ["правых", "right"],
      ASM: ["прав", "right"],
      ASF: ["права", "right"],
      ASN: ["право", "right"],
      ASP: ["правы правы", "right"],
      AC1: ["правее", "right"],
    },
    холодный: {
      ANM: ["холодный", "cold, cool"],
      ANF: ["холодная", "cold, cool"],
      ANN: ["холодное", "cold, cool"],
      ANP: ["холодные", "cold, cool"],
      AGM: ["холодного", "cold, cool"],
      AGF: ["холодной", "cold, cool"],
      AGN: ["холодного", "cold, cool"],
      AGP: ["холодных", "cold, cool"],
      ADM: ["холодному", "cold, cool"],
      ADF: ["холодной", "cold, cool"],
      ADN: ["холодному", "cold, cool"],
      ADP: ["холодным", "cold, cool"],
      AAM: ["холодного", "cold, cool"],
      AAF: ["холодную", "cold, cool"],
      AAN: ["холодное", "cold, cool"],
      AAP: ["холодных", "cold, cool"],
      AIM: ["холодным", "cold, cool"],
      AIF: ["холодной", "cold, cool"],
      AIN: ["холодным", "cold, cool"],
      AIP: ["холодными", "cold, cool"],
      APM: ["холодном", "cold, cool"],
      APF: ["холодной", "cold, cool"],
      APN: ["холодном", "cold, cool"],
      APP: ["холодных", "cold, cool"],
      ASM: ["холоден", "cold, cool"],
      ASF: ["холодна", "cold, cool"],
      ASN: ["холодно", "cold, cool"],
      ASP: ["холодны холодны", "cold, cool"],
      AC1: ["холоднее", "cold, cool"],
    },
    многие: {
      AAM: ["многое", "many"],
      AAF: ["многие", "many"],
      AAN: ["многого", "many"],
      AAP: ["многих", "many"],
      AIM: ["многому", "many"],
      AIF: ["многим", "many"],
      AIN: ["многое", "many"],
      AIP: ["многие", "many"],
      APM: ["многим", "many"],
      APF: ["многими", "many"],
      APN: ["многом", "many"],
      APP: ["многих", "many"],
    },
    родной: {
      ANM: ["родной", "own, native, dear"],
      ANF: ["родная", "own, native, dear"],
      ANN: ["родное", "own, native, dear"],
      ANP: ["родные", "own, native, dear"],
      AGM: ["родного", "own, native, dear"],
      AGF: ["родной", "own, native, dear"],
      AGN: ["родного", "own, native, dear"],
      AGP: ["родных", "own, native, dear"],
      ADM: ["родному", "own, native, dear"],
      ADF: ["родной", "own, native, dear"],
      ADN: ["родному", "own, native, dear"],
      ADP: ["родным", "own, native, dear"],
      AAM: ["родной", "own, native, dear"],
      AAF: ["родную", "own, native, dear"],
      AAN: ["родное", "own, native, dear"],
      AAP: ["родные", "own, native, dear"],
      AIM: ["родным", "own, native, dear"],
      AIF: ["родной", "own, native, dear"],
      AIN: ["родным", "own, native, dear"],
      AIP: ["родными", "own, native, dear"],
      APM: ["родном", "own, native, dear"],
      APF: ["родной", "own, native, dear"],
      APN: ["родном", "own, native, dear"],
      APP: ["родных", "own, native, dear"],
    },
    жёлтый: {
      ANM: ["жёлтый", "yellow"],
      ANF: ["жёлтая", "yellow"],
      ANN: ["жёлтое", "yellow"],
      ANP: ["жёлтые", "yellow"],
      AGM: ["жёлтого", "yellow"],
      AGF: ["жёлтой", "yellow"],
      AGN: ["жёлтого", "yellow"],
      AGP: ["жёлтых", "yellow"],
      ADM: ["жёлтому", "yellow"],
      ADF: ["жёлтой", "yellow"],
      ADN: ["жёлтому", "yellow"],
      ADP: ["жёлтым", "yellow"],
      AAM: ["жёлтый", "yellow"],
      AAF: ["жёлтую", "yellow"],
      AAN: ["жёлтое", "yellow"],
      AAP: ["жёлтые", "yellow"],
      AIM: ["жёлтым", "yellow"],
      AIF: ["жёлтой", "yellow"],
      AIN: ["жёлтым", "yellow"],
      AIP: ["жёлтыми", "yellow"],
      APM: ["жёлтом", "yellow"],
      APF: ["жёлтой", "yellow"],
      APN: ["жёлтом", "yellow"],
      APP: ["жёлтых", "yellow"],
      ASM: ["жёлт", "yellow"],
      ASF: ["желта", "yellow"],
      ASN: ["жёлто желто", "yellow"],
      ASP: ["жёлты желты", "yellow"],
      AC1: ["желтее", "yellow"],
    },
    золотой: {
      ANM: ["золотой", "golden"],
      ANF: ["золотая", "golden"],
      ANN: ["золотое", "golden"],
      ANP: ["золотые", "golden"],
      AGM: ["золотого", "golden"],
      AGF: ["золотой", "golden"],
      AGN: ["золотого", "golden"],
      AGP: ["золотых", "golden"],
      ADM: ["золотому", "golden"],
      ADF: ["золотой", "golden"],
      ADN: ["золотому", "golden"],
      ADP: ["золотым", "golden"],
      AAM: ["золотого", "golden"],
      AAF: ["золотую", "golden"],
      AAN: ["золотое", "golden"],
      AAP: ["золотых", "golden"],
      AIM: ["золотым", "golden"],
      AIF: ["золотой", "golden"],
      AIN: ["золотым", "golden"],
      AIP: ["золотыми", "golden"],
      APM: ["золотом", "golden"],
      APF: ["золотой", "golden"],
      APN: ["золотом", "golden"],
      APP: ["золотых", "golden"],
    },
    местный: {
      ANM: ["местный", "local"],
      ANF: ["местная", "local"],
      ANN: ["местное", "local"],
      ANP: ["местные", "local"],
      AGM: ["местного", "local"],
      AGF: ["местной", "local"],
      AGN: ["местного", "local"],
      AGP: ["местных", "local"],
      ADM: ["местному", "local"],
      ADF: ["местной", "local"],
      ADN: ["местному", "local"],
      ADP: ["местным", "local"],
      AAM: ["местного", "local"],
      AAF: ["местную", "local"],
      AAN: ["местное", "local"],
      AAP: ["местных", "local"],
      AIM: ["местным", "local"],
      AIF: ["местной", "local"],
      AIN: ["местным", "local"],
      AIP: ["местными", "local"],
      APM: ["местном", "local"],
      APF: ["местной", "local"],
      APN: ["местном", "local"],
      APP: ["местных", "local"],
      ASM: ["местен", "local"],
      ASF: ["местна", "local"],
      ASN: ["местно", "local"],
      ASP: ["местны", "local"],
    },
    крупный: {
      ANM: ["крупный", "large, big, prominent"],
      ANF: ["крупная", "large, big, prominent"],
      ANN: ["крупное", "large, big, prominent"],
      ANP: ["крупные", "large, big, prominent"],
      AGM: ["крупного", "large, big, prominent"],
      AGF: ["крупной", "large, big, prominent"],
      AGN: ["крупного", "large, big, prominent"],
      AGP: ["крупных", "large, big, prominent"],
      ADM: ["крупному", "large, big, prominent"],
      ADF: ["крупной", "large, big, prominent"],
      ADN: ["крупному", "large, big, prominent"],
      ADP: ["крупным", "large, big, prominent"],
      AAM: ["крупный", "large, big, prominent"],
      AAF: ["крупную", "large, big, prominent"],
      AAN: ["крупное", "large, big, prominent"],
      AAP: ["крупные", "large, big, prominent"],
      AIM: ["крупным", "large, big, prominent"],
      AIF: ["крупной", "large, big, prominent"],
      AIN: ["крупным", "large, big, prominent"],
      AIP: ["крупными", "large, big, prominent"],
      APM: ["крупном", "large, big, prominent"],
      APF: ["крупной", "large, big, prominent"],
      APN: ["крупном", "large, big, prominent"],
      APP: ["крупных", "large, big, prominent"],
      ASM: ["крупен", "large, big, prominent"],
      ASF: ["крупна", "large, big, prominent"],
      ASN: ["крупно", "large, big, prominent"],
      ASP: ["крупны", "large, big, prominent"],
      AC1: ["крупнее", "large, big, prominent"],
    },
    частый: {
      ANM: ["частый", "frequent"],
      ANF: ["частая", "frequent"],
      ANN: ["частое", "frequent"],
      ANP: ["частые", "frequent"],
      AGM: ["частого", "frequent"],
      AGF: ["частой", "frequent"],
      AGN: ["частого", "frequent"],
      AGP: ["частых", "frequent"],
      ADM: ["частому", "frequent"],
      ADF: ["частой", "frequent"],
      ADN: ["частому", "frequent"],
      ADP: ["частым", "frequent"],
      AAM: ["частый", "frequent"],
      AAF: ["частую", "frequent"],
      AAN: ["частое", "frequent"],
      AAP: ["частые", "frequent"],
      AIM: ["частым", "frequent"],
      AIF: ["частой", "frequent"],
      AIN: ["частым", "frequent"],
      AIP: ["частыми", "frequent"],
      APM: ["частом", "frequent"],
      APF: ["частой", "frequent"],
      APN: ["частом", "frequent"],
      APP: ["частых", "frequent"],
      ASM: ["част", "frequent"],
      ASF: ["часта", "frequent"],
      ASN: ["часто", "frequent"],
      ASP: ["часты", "frequent"],
      AC1: ["чаще", "frequent"],
    },
    российский: {
      ANM: ["российский", "Russian"],
      ANF: ["российская", "Russian"],
      ANN: ["российское", "Russian"],
      ANP: ["российские", "Russian"],
      AGM: ["российского", "Russian"],
      AGF: ["российской", "Russian"],
      AGN: ["российского", "Russian"],
      AGP: ["российских", "Russian"],
      ADM: ["российскому", "Russian"],
      ADF: ["российской", "Russian"],
      ADN: ["российскому", "Russian"],
      ADP: ["российским", "Russian"],
      AAM: ["российского", "Russian"],
      AAF: ["российскую", "Russian"],
      AAN: ["российское", "Russian"],
      AAP: ["российских", "Russian"],
      AIM: ["российским", "Russian"],
      AIF: ["российской", "Russian"],
      AIN: ["российским", "Russian"],
      AIP: ["российскими", "Russian"],
      APM: ["российском", "Russian"],
      APF: ["российской", "Russian"],
      APN: ["российском", "Russian"],
      APP: ["российских", "Russian"],
    },
    рабочий: {
      ANM: ["рабочий", "working"],
      ANF: ["рабочая", "working"],
      ANN: ["рабочее", "working"],
      ANP: ["рабочие", "working"],
      AGM: ["рабочего", "working"],
      AGF: ["рабочей", "working"],
      AGN: ["рабочего", "working"],
      AGP: ["рабочих", "working"],
      ADM: ["рабочему", "working"],
      ADF: ["рабочей", "working"],
      ADN: ["рабочему", "working"],
      ADP: ["рабочим", "working"],
      AAM: ["рабочий", "working"],
      AAF: ["рабочую", "working"],
      AAN: ["рабочее", "working"],
      AAP: ["рабочие", "working"],
      AIM: ["рабочим", "working"],
      AIF: ["рабочей", "working"],
      AIN: ["рабочим", "working"],
      AIP: ["рабочими", "working"],
      APM: ["рабочем", "working"],
      APF: ["рабочей", "working"],
      APN: ["рабочем", "working"],
      APP: ["рабочих", "working"],
    },
    синий: {
      ANM: ["синий", "dark blue"],
      ANF: ["синяя", "dark blue"],
      ANN: ["синее", "dark blue"],
      ANP: ["синие", "dark blue"],
      AGM: ["синего", "dark blue"],
      AGF: ["синей", "dark blue"],
      AGN: ["синего", "dark blue"],
      AGP: ["синих", "dark blue"],
      ADM: ["синему", "dark blue"],
      ADF: ["синей", "dark blue"],
      ADN: ["синему", "dark blue"],
      ADP: ["синим", "dark blue"],
      AAM: ["синий", "dark blue"],
      AAF: ["синюю", "dark blue"],
      AAN: ["синее", "dark blue"],
      AAP: ["синие", "dark blue"],
      AIM: ["синим", "dark blue"],
      AIF: ["синей", "dark blue"],
      AIN: ["синим", "dark blue"],
      AIP: ["синими", "dark blue"],
      APM: ["синем", "dark blue"],
      APF: ["синей", "dark blue"],
      APN: ["синем", "dark blue"],
      APP: ["синих", "dark blue"],
      ASM: ["синь", "dark blue"],
      ASF: ["синя", "dark blue"],
      ASN: ["сине", "dark blue"],
      ASP: ["сини", "dark blue"],
      AC1: ["синее", "dark blue"],
    },
    тёплый: {
      ANM: ["тёплый", "warm"],
      ANF: ["тёплая", "warm"],
      ANN: ["тёплое", "warm"],
      ANP: ["тёплые", "warm"],
      AGM: ["тёплого", "warm"],
      AGF: ["тёплой", "warm"],
      AGN: ["тёплого", "warm"],
      AGP: ["тёплых", "warm"],
      ADM: ["тёплому", "warm"],
      ADF: ["тёплой", "warm"],
      ADN: ["тёплому", "warm"],
      ADP: ["тёплым", "warm"],
      AAM: ["тёплого", "warm"],
      AAF: ["тёплую", "warm"],
      AAN: ["тёплое", "warm"],
      AAP: ["тёплых", "warm"],
      AIM: ["тёплым", "warm"],
      AIF: ["тёплой", "warm"],
      AIN: ["тёплым", "warm"],
      AIP: ["тёплыми", "warm"],
      APM: ["тёплом", "warm"],
      APF: ["тёплой", "warm"],
      APN: ["тёплом", "warm"],
      APP: ["тёплых", "warm"],
      ASM: ["тёпел", "warm"],
      ASF: ["тепла", "warm"],
      ASN: ["тепло", "warm"],
      ASP: ["теплы", "warm"],
      AC1: ["теплее", "warm"],
    },
    железный: {
      ANM: ["железный", "ferreous, iron"],
      ANF: ["железная", "ferreous, iron"],
      ANN: ["железное", "ferreous, iron"],
      ANP: ["железные", "ferreous, iron"],
      AGM: ["железного", "ferreous, iron"],
      AGF: ["железной", "ferreous, iron"],
      AGN: ["железного", "ferreous, iron"],
      AGP: ["железных", "ferreous, iron"],
      ADM: ["железному", "ferreous, iron"],
      ADF: ["железной", "ferreous, iron"],
      ADN: ["железному", "ferreous, iron"],
      ADP: ["железным", "ferreous, iron"],
      AAM: ["железный", "ferreous, iron"],
      AAF: ["железную", "ferreous, iron"],
      AAN: ["железное", "ferreous, iron"],
      AAP: ["железные", "ferreous, iron"],
      AIM: ["железным", "ferreous, iron"],
      AIF: ["железной", "ferreous, iron"],
      AIN: ["железным", "ferreous, iron"],
      AIP: ["железными", "ferreous, iron"],
      APM: ["железном", "ferreous, iron"],
      APF: ["железной", "ferreous, iron"],
      APN: ["железном", "ferreous, iron"],
      APP: ["железных", "ferreous, iron"],
      ASM: ["железен", "ferreous, iron"],
      ASF: ["железна", "ferreous, iron"],
      ASN: ["железно", "ferreous, iron"],
      ASP: ["железны", "ferreous, iron"],
    },
    обычный: {
      ANM: ["обычный", "usual, ordinary"],
      ANF: ["обычная", "usual, ordinary"],
      ANN: ["обычное", "usual, ordinary"],
      ANP: ["обычные", "usual, ordinary"],
      AGM: ["обычного", "usual, ordinary"],
      AGF: ["обычной", "usual, ordinary"],
      AGN: ["обычного", "usual, ordinary"],
      AGP: ["обычных", "usual, ordinary"],
      ADM: ["обычному", "usual, ordinary"],
      ADF: ["обычной", "usual, ordinary"],
      ADN: ["обычному", "usual, ordinary"],
      ADP: ["обычным", "usual, ordinary"],
      AAM: ["обычный", "usual, ordinary"],
      AAF: ["обычную", "usual, ordinary"],
      AAN: ["обычное", "usual, ordinary"],
      AAP: ["обычные", "usual, ordinary"],
      AIM: ["обычным", "usual, ordinary"],
      AIF: ["обычной", "usual, ordinary"],
      AIN: ["обычным", "usual, ordinary"],
      AIP: ["обычными", "usual, ordinary"],
      APM: ["обычном", "usual, ordinary"],
      APF: ["обычной", "usual, ordinary"],
      APN: ["обычном", "usual, ordinary"],
      APP: ["обычных", "usual, ordinary"],
      ASM: ["обычен", "usual, ordinary"],
      ASF: ["обычна", "usual, ordinary"],
      ASN: ["обычно", "usual, ordinary"],
      ASP: ["обычны", "usual, ordinary"],
    },
    остальной: {
      ANM: ["остальной", "remaining, the rest of"],
      ANF: ["остальная", "remaining, the rest of"],
      ANN: ["остальное", "remaining, the rest of"],
      ANP: ["остальные", "remaining, the rest of"],
      AGM: ["остального", "remaining, the rest of"],
      AGF: ["остальной", "remaining, the rest of"],
      AGN: ["остального", "remaining, the rest of"],
      AGP: ["остальных", "remaining, the rest of"],
      ADM: ["остальному", "remaining, the rest of"],
      ADF: ["остальной", "remaining, the rest of"],
      ADN: ["остальному", "remaining, the rest of"],
      ADP: ["остальным", "remaining, the rest of"],
      AAM: ["остальной", "remaining, the rest of"],
      AAF: ["остальную", "remaining, the rest of"],
      AAN: ["остальное", "remaining, the rest of"],
      AAP: ["остальные", "remaining, the rest of"],
      AIM: ["остальным", "remaining, the rest of"],
      AIF: ["остальной", "remaining, the rest of"],
      AIN: ["остальным", "remaining, the rest of"],
      AIP: ["остальными", "remaining, the rest of"],
      APM: ["остальном", "remaining, the rest of"],
      APF: ["остальной", "remaining, the rest of"],
      APN: ["остальном", "remaining, the rest of"],
      APP: ["остальных", "remaining, the rest of"],
    },
    московский: {
      ANM: ["московский", "Moscow"],
      ANF: ["московская", "Moscow"],
      ANN: ["московское", "Moscow"],
      ANP: ["московские", "Moscow"],
      AGM: ["московского", "Moscow"],
      AGF: ["московской", "Moscow"],
      AGN: ["московского", "Moscow"],
      AGP: ["московских", "Moscow"],
      ADM: ["московскому", "Moscow"],
      ADF: ["московской", "Moscow"],
      ADN: ["московскому", "Moscow"],
      ADP: ["московским", "Moscow"],
      AAM: ["московский", "Moscow"],
      AAF: ["московскую", "Moscow"],
      AAN: ["московское", "Moscow"],
      AAP: ["московские", "Moscow"],
      AIM: ["московским", "Moscow"],
      AIF: ["московской", "Moscow"],
      AIN: ["московским", "Moscow"],
      AIP: ["московскими", "Moscow"],
      APM: ["московском", "Moscow"],
      APF: ["московской", "Moscow"],
      APN: ["московском", "Moscow"],
      APP: ["московских", "Moscow"],
    },
    важный: {
      ANM: ["важный", "important"],
      ANF: ["важная", "important"],
      ANN: ["важное", "important"],
      ANP: ["важные", "important"],
      AGM: ["важного", "important"],
      AGF: ["важной", "important"],
      AGN: ["важного", "important"],
      AGP: ["важных", "important"],
      ADM: ["важному", "important"],
      ADF: ["важной", "important"],
      ADN: ["важному", "important"],
      ADP: ["важным", "important"],
      AAM: ["важный", "important"],
      AAF: ["важную", "important"],
      AAN: ["важное", "important"],
      AAP: ["важные", "important"],
      AIM: ["важным", "important"],
      AIF: ["важной", "important"],
      AIN: ["важным", "important"],
      AIP: ["важными", "important"],
      APM: ["важном", "important"],
      APF: ["важной", "important"],
      APN: ["важном", "important"],
      APP: ["важных", "important"],
      ASM: ["важен", "important"],
      ASF: ["важна", "important"],
      ASN: ["важно", "important"],
      ASP: ["важны важны", "important"],
      AC1: ["важнее", "important"],
    },
    поздний: {
      ANM: ["поздний", "late"],
      ANF: ["поздняя", "late"],
      ANN: ["позднее", "late"],
      ANP: ["поздние", "late"],
      AGM: ["позднего", "late"],
      AGF: ["поздней", "late"],
      AGN: ["позднего", "late"],
      AGP: ["поздних", "late"],
      ADM: ["позднему", "late"],
      ADF: ["поздней", "late"],
      ADN: ["позднему", "late"],
      ADP: ["поздним", "late"],
      AAM: ["поздний", "late"],
      AAF: ["позднюю", "late"],
      AAN: ["позднее", "late"],
      AAP: ["поздние", "late"],
      AIM: ["поздним", "late"],
      AIF: ["поздней", "late"],
      AIN: ["поздним", "late"],
      AIP: ["поздними", "late"],
      APM: ["позднем", "late"],
      APF: ["поздней", "late"],
      APN: ["позднем", "late"],
      APP: ["поздних", "late"],
      AC1: ["позднее", "late"],
      AC2: ["позже", "late"],
    },
    тонкий: {
      ANM: ["тонкий", "thin"],
      ANF: ["тонкая", "thin"],
      ANN: ["тонкое", "thin"],
      ANP: ["тонкие", "thin"],
      AGM: ["тонкого", "thin"],
      AGF: ["тонкой", "thin"],
      AGN: ["тонкого", "thin"],
      AGP: ["тонких", "thin"],
      ADM: ["тонкому", "thin"],
      ADF: ["тонкой", "thin"],
      ADN: ["тонкому", "thin"],
      ADP: ["тонким", "thin"],
      AAM: ["тонкий", "thin"],
      AAF: ["тонкую", "thin"],
      AAN: ["тонкое", "thin"],
      AAP: ["тонкие", "thin"],
      AIM: ["тонким", "thin"],
      AIF: ["тонкой", "thin"],
      AIN: ["тонким", "thin"],
      AIP: ["тонкими", "thin"],
      APM: ["тонком", "thin"],
      APF: ["тонкой", "thin"],
      APN: ["тонком", "thin"],
      APP: ["тонких", "thin"],
      ASM: ["тонок", "thin"],
      ASF: ["тонка", "thin"],
      ASN: ["тонко", "thin"],
      ASP: ["тонки", "thin"],
      AC1: ["тоньше", "thin"],
    },
    немецкий: {
      ANM: ["немецкий", "German"],
      ANF: ["немецкая", "German"],
      ANN: ["немецкое", "German"],
      ANP: ["немецкие", "German"],
      AGM: ["немецкого", "German"],
      AGF: ["немецкой", "German"],
      AGN: ["немецкого", "German"],
      AGP: ["немецких", "German"],
      ADM: ["немецкому", "German"],
      ADF: ["немецкой", "German"],
      ADN: ["немецкому", "German"],
      ADP: ["немецким", "German"],
      AAM: ["немецкий", "German"],
      AAF: ["немецкую", "German"],
      AAN: ["немецкое", "German"],
      AAP: ["немецкие", "German"],
      AIM: ["немецким", "German"],
      AIF: ["немецкой", "German"],
      AIN: ["немецким", "German"],
      AIP: ["немецкими", "German"],
      APM: ["немецком", "German"],
      APF: ["немецкой", "German"],
      APN: ["немецком", "German"],
      APP: ["немецких", "German"],
    },
    прекрасный: {
      ANM: ["прекрасный", "beautiful, fine"],
      ANF: ["прекрасная", "beautiful, fine"],
      ANN: ["прекрасное", "beautiful, fine"],
      ANP: ["прекрасные", "beautiful, fine"],
      AGM: ["прекрасного", "beautiful, fine"],
      AGF: ["прекрасной", "beautiful, fine"],
      AGN: ["прекрасного", "beautiful, fine"],
      AGP: ["прекрасных", "beautiful, fine"],
      ADM: ["прекрасному", "beautiful, fine"],
      ADF: ["прекрасной", "beautiful, fine"],
      ADN: ["прекрасному", "beautiful, fine"],
      ADP: ["прекрасным", "beautiful, fine"],
      AAM: ["прекрасный", "beautiful, fine"],
      AAF: ["прекрасную", "beautiful, fine"],
      AAN: ["прекрасное", "beautiful, fine"],
      AAP: ["прекрасные", "beautiful, fine"],
      AIM: ["прекрасным", "beautiful, fine"],
      AIF: ["прекрасной", "beautiful, fine"],
      AIN: ["прекрасным", "beautiful, fine"],
      AIP: ["прекрасными", "beautiful, fine"],
      APM: ["прекрасном", "beautiful, fine"],
      APF: ["прекрасной", "beautiful, fine"],
      APN: ["прекрасном", "beautiful, fine"],
      APP: ["прекрасных", "beautiful, fine"],
      ASM: ["прекрасен", "beautiful, fine"],
      ASF: ["прекрасна", "beautiful, fine"],
      ASN: ["прекрасно", "beautiful, fine"],
      ASP: ["прекрасны", "beautiful, fine"],
      AC1: ["прекраснее", "beautiful, fine"],
    },
    верный: {
      ANM: ["верный", "correct; faithful; reliable"],
      ANF: ["верная", "correct; faithful; reliable"],
      ANN: ["верное", "correct; faithful; reliable"],
      ANP: ["верные", "correct; faithful; reliable"],
      AGM: ["верного", "correct; faithful; reliable"],
      AGF: ["верной", "correct; faithful; reliable"],
      AGN: ["верного", "correct; faithful; reliable"],
      AGP: ["верных", "correct; faithful; reliable"],
      ADM: ["верному", "correct; faithful; reliable"],
      ADF: ["верной", "correct; faithful; reliable"],
      ADN: ["верному", "correct; faithful; reliable"],
      ADP: ["верным", "correct; faithful; reliable"],
      AAM: ["верный", "correct; faithful; reliable"],
      AAF: ["верную", "correct; faithful; reliable"],
      AAN: ["верное", "correct; faithful; reliable"],
      AAP: ["верные", "correct; faithful; reliable"],
      AIM: ["верным", "correct; faithful; reliable"],
      AIF: ["верной", "correct; faithful; reliable"],
      AIN: ["верным", "correct; faithful; reliable"],
      AIP: ["верными", "correct; faithful; reliable"],
      APM: ["верном", "correct; faithful; reliable"],
      APF: ["верной", "correct; faithful; reliable"],
      APN: ["верном", "correct; faithful; reliable"],
      APP: ["верных", "correct; faithful; reliable"],
      ASM: ["верен", "correct; faithful; reliable"],
      ASF: ["верна", "correct; faithful; reliable"],
      ASN: ["верно", "correct; faithful; reliable"],
      ASP: ["верны верны", "correct; faithful; reliable"],
    },
    готовый: {
      ANM: ["готовый", "ready"],
      ANF: ["готовая", "ready"],
      ANN: ["готовое", "ready"],
      ANP: ["готовые", "ready"],
      AGM: ["готового", "ready"],
      AGF: ["готовой", "ready"],
      AGN: ["готового", "ready"],
      AGP: ["готовых", "ready"],
      ADM: ["готовому", "ready"],
      ADF: ["готовой", "ready"],
      ADN: ["готовому", "ready"],
      ADP: ["готовым", "ready"],
      AAM: ["готовый", "ready"],
      AAF: ["готовую", "ready"],
      AAN: ["готовое", "ready"],
      AAP: ["готовые", "ready"],
      AIM: ["готовым", "ready"],
      AIF: ["готовой", "ready"],
      AIN: ["готовым", "ready"],
      AIP: ["готовыми", "ready"],
      APM: ["готовом", "ready"],
      APF: ["готовой", "ready"],
      APN: ["готовом", "ready"],
      APP: ["готовых", "ready"],
      ASM: ["готов", "ready"],
      ASF: ["готова", "ready"],
      ASN: ["готово", "ready"],
      ASP: ["готовы", "ready"],
    },
    политический: {
      ANM: ["политический", "political"],
      ANF: ["политическая", "political"],
      ANN: ["политическое", "political"],
      ANP: ["политические", "political"],
      AGM: ["политического", "political"],
      AGF: ["политической", "political"],
      AGN: ["политического", "political"],
      AGP: ["политических", "political"],
      ADM: ["политическому", "political"],
      ADF: ["политической", "political"],
      ADN: ["политическому", "political"],
      ADP: ["политическим", "political"],
      AAM: ["политический", "political"],
      AAF: ["политическую", "political"],
      AAN: ["политическое", "political"],
      AAP: ["политические", "political"],
      AIM: ["политическим", "political"],
      AIF: ["политической", "political"],
      AIN: ["политическим", "political"],
      AIP: ["политическими", "political"],
      APM: ["политическом", "political"],
      APF: ["политической", "political"],
      APN: ["политическом", "political"],
      APP: ["политических", "political"],
    },
    неизвестный: {
      ANM: ["неизвестный", "unknown"],
      ANF: ["неизвестная", "unknown"],
      ANN: ["неизвестное", "unknown"],
      ANP: ["неизвестные", "unknown"],
      AGM: ["неизвестного", "unknown"],
      AGF: ["неизвестной", "unknown"],
      AGN: ["неизвестного", "unknown"],
      AGP: ["неизвестных", "unknown"],
      ADM: ["неизвестному", "unknown"],
      ADF: ["неизвестной", "unknown"],
      ADN: ["неизвестному", "unknown"],
      ADP: ["неизвестным", "unknown"],
      AAM: ["неизвестный", "unknown"],
      AAF: ["неизвестную", "unknown"],
      AAN: ["неизвестное", "unknown"],
      AAP: ["неизвестные", "unknown"],
      AIM: ["неизвестным", "unknown"],
      AIF: ["неизвестной", "unknown"],
      AIN: ["неизвестным", "unknown"],
      AIP: ["неизвестными", "unknown"],
      APM: ["неизвестном", "unknown"],
      APF: ["неизвестной", "unknown"],
      APN: ["неизвестном", "unknown"],
      APP: ["неизвестных", "unknown"],
      ASM: ["неизвестен", "unknown"],
      ASF: ["неизвестна", "unknown"],
      ASN: ["неизвестно", "unknown"],
      ASP: ["неизвестны", "unknown"],
    },
    тихий: {
      ANM: ["тихий", "quiet, low, scelent"],
      ANF: ["тихая", "quiet, low, scelent"],
      ANN: ["тихое", "quiet, low, scelent"],
      ANP: ["тихие", "quiet, low, scelent"],
      AGM: ["тихого", "quiet, low, scelent"],
      AGF: ["тихой", "quiet, low, scelent"],
      AGN: ["тихого", "quiet, low, scelent"],
      AGP: ["тихих", "quiet, low, scelent"],
      ADM: ["тихому", "quiet, low, scelent"],
      ADF: ["тихой", "quiet, low, scelent"],
      ADN: ["тихому", "quiet, low, scelent"],
      ADP: ["тихим", "quiet, low, scelent"],
      AAM: ["тихий", "quiet, low, scelent"],
      AAF: ["тихую", "quiet, low, scelent"],
      AAN: ["тихое", "quiet, low, scelent"],
      AAP: ["тихие", "quiet, low, scelent"],
      AIM: ["тихим", "quiet, low, scelent"],
      AIF: ["тихой", "quiet, low, scelent"],
      AIN: ["тихим", "quiet, low, scelent"],
      AIP: ["тихими", "quiet, low, scelent"],
      APM: ["тихом", "quiet, low, scelent"],
      APF: ["тихой", "quiet, low, scelent"],
      APN: ["тихом", "quiet, low, scelent"],
      APP: ["тихих", "quiet, low, scelent"],
      ASM: ["тих", "quiet, low, scelent"],
      ASF: ["тиха", "quiet, low, scelent"],
      ASN: ["тихо", "quiet, low, scelent"],
      ASP: ["тихи тихи", "quiet, low, scelent"],
      AC1: ["тише", "quiet, low, scelent"],
    },
    внутренний: {
      ANM: ["внутренний", "inside, internal"],
      ANF: ["внутренняя", "inside, internal"],
      ANN: ["внутреннее", "inside, internal"],
      ANP: ["внутренние", "inside, internal"],
      AGM: ["внутреннего", "inside, internal"],
      AGF: ["внутренней", "inside, internal"],
      AGN: ["внутреннего", "inside, internal"],
      AGP: ["внутренних", "inside, internal"],
      ADM: ["внутреннему", "inside, internal"],
      ADF: ["внутренней", "inside, internal"],
      ADN: ["внутреннему", "inside, internal"],
      ADP: ["внутренним", "inside, internal"],
      AAM: ["внутренний", "inside, internal"],
      AAF: ["внутреннюю", "inside, internal"],
      AAN: ["внутреннее", "inside, internal"],
      AAP: ["внутренние", "inside, internal"],
      AIM: ["внутренним", "inside, internal"],
      AIF: ["внутренней", "inside, internal"],
      AIN: ["внутренним", "inside, internal"],
      AIP: ["внутренними", "inside, internal"],
      APM: ["внутреннем", "inside, internal"],
      APF: ["внутренней", "inside, internal"],
      APN: ["внутреннем", "inside, internal"],
      APP: ["внутренних", "inside, internal"],
      ASF: ["внутрення", "inside, internal"],
      ASN: ["внутренне", "inside, internal"],
      ASP: ["внутренни", "inside, internal"],
    },
    государственный: {
      ANM: ["государственный", "State"],
      ANF: ["государственная", "State"],
      ANN: ["государственное", "State"],
      ANP: ["государственные", "State"],
      AGM: ["государственного", "State"],
      AGF: ["государственной", "State"],
      AGN: ["государственного", "State"],
      AGP: ["государственных", "State"],
      ADM: ["государственному", "State"],
      ADF: ["государственной", "State"],
      ADN: ["государственному", "State"],
      ADP: ["государственным", "State"],
      AAM: ["государственный", "State"],
      AAF: ["государственную", "State"],
      AAN: ["государственное", "State"],
      AAP: ["государственные", "State"],
      AIM: ["государственным", "State"],
      AIF: ["государственной", "State"],
      AIN: ["государственным", "State"],
      AIP: ["государственными", "State"],
      APM: ["государственном", "State"],
      APF: ["государственной", "State"],
      APN: ["государственном", "State"],
      APP: ["государственных", "State"],
    },
    глубокий: {
      ANM: ["глубокий", "deep"],
      ANF: ["глубокая", "deep"],
      ANN: ["глубокое", "deep"],
      ANP: ["глубокие", "deep"],
      AGM: ["глубокого", "deep"],
      AGF: ["глубокой", "deep"],
      AGN: ["глубокого", "deep"],
      AGP: ["глубоких", "deep"],
      ADM: ["глубокому", "deep"],
      ADF: ["глубокой", "deep"],
      ADN: ["глубокому", "deep"],
      ADP: ["глубоким", "deep"],
      AAM: ["глубокий", "deep"],
      AAF: ["глубокую", "deep"],
      AAN: ["глубокое", "deep"],
      AAP: ["глубокие", "deep"],
      AIM: ["глубоким", "deep"],
      AIF: ["глубокой", "deep"],
      AIN: ["глубоким", "deep"],
      AIP: ["глубокими", "deep"],
      APM: ["глубоком", "deep"],
      APF: ["глубокой", "deep"],
      APN: ["глубоком", "deep"],
      APP: ["глубоких", "deep"],
      ASM: ["глубок", "deep"],
      ASF: ["глубока", "deep"],
      ASN: ["глубоко глубоко", "deep"],
      ASP: ["глубоки глубоки", "deep"],
      AC1: ["глубже", "deep"],
    },
    голубой: {
      ANM: ["голубой", "blue, sky-blue"],
      ANF: ["голубая", "blue, sky-blue"],
      ANN: ["голубое", "blue, sky-blue"],
      ANP: ["голубые", "blue, sky-blue"],
      AGM: ["голубого", "blue, sky-blue"],
      AGF: ["голубой", "blue, sky-blue"],
      AGN: ["голубого", "blue, sky-blue"],
      AGP: ["голубых", "blue, sky-blue"],
      ADM: ["голубому", "blue, sky-blue"],
      ADF: ["голубой", "blue, sky-blue"],
      ADN: ["голубому", "blue, sky-blue"],
      ADP: ["голубым", "blue, sky-blue"],
      AAM: ["голубой", "blue, sky-blue"],
      AAF: ["голубую", "blue, sky-blue"],
      AAN: ["голубое", "blue, sky-blue"],
      AAP: ["голубые", "blue, sky-blue"],
      AIM: ["голубым", "blue, sky-blue"],
      AIF: ["голубой", "blue, sky-blue"],
      AIN: ["голубым", "blue, sky-blue"],
      AIP: ["голубыми", "blue, sky-blue"],
      APM: ["голубом", "blue, sky-blue"],
      APF: ["голубой", "blue, sky-blue"],
      APN: ["голубом", "blue, sky-blue"],
      APP: ["голубых", "blue, sky-blue"],
      AC1: ["голубее", "blue, sky-blue"],
    },
    долгий: {
      ANM: ["долгий", "long"],
      ANF: ["долгая", "long"],
      ANN: ["долгое", "long"],
      ANP: ["долгие", "long"],
      AGM: ["долгого", "long"],
      AGF: ["долгой", "long"],
      AGN: ["долгого", "long"],
      AGP: ["долгих", "long"],
      ADM: ["долгому", "long"],
      ADF: ["долгой", "long"],
      ADN: ["долгому", "long"],
      ADP: ["долгим", "long"],
      AAM: ["долгий", "long"],
      AAF: ["долгую", "long"],
      AAN: ["долгое", "long"],
      AAP: ["долгие", "long"],
      AIM: ["долгим", "long"],
      AIF: ["долгой", "long"],
      AIN: ["долгим", "long"],
      AIP: ["долгими", "long"],
      APM: ["долгом", "long"],
      APF: ["долгой", "long"],
      APN: ["долгом", "long"],
      APP: ["долгих", "long"],
      ASM: ["долог", "long"],
      ASF: ["долга", "long"],
      ASN: ["долго", "long"],
      ASP: ["долги", "long"],
      AC1: ["дольше", "long"],
      AC2: ["долее", "long"],
    },
    чей: {
      ANM: ["чей", "whose"],
      ANF: ["чья", "whose"],
      ANN: ["чьё", "whose"],
      ANP: ["чьи", "whose"],
      AGM: ["чьего", "whose"],
      AGF: ["чьей", "whose"],
      AGN: ["чьего", "whose"],
      AGP: ["чьих", "whose"],
      ADM: ["чьему", "whose"],
      ADF: ["чьей", "whose"],
      ADN: ["чьему", "whose"],
      ADP: ["чьим", "whose"],
      AAM: ["чей", "whose"],
      AAF: ["чью", "whose"],
      AAN: ["чьё", "whose"],
      AAP: ["чьи", "whose"],
      AIM: ["чьим", "whose"],
      AIF: ["чьей", "whose"],
      AIN: ["чьим", "whose"],
      AIP: ["чьими", "whose"],
      APM: ["чьём", "whose"],
      APF: ["чьей", "whose"],
      APN: ["чьём", "whose"],
      APP: ["чьих", "whose"],
    },
    слабый: {
      ANM: ["слабый", "weak"],
      ANF: ["слабая", "weak"],
      ANN: ["слабое", "weak"],
      ANP: ["слабые", "weak"],
      AGM: ["слабого", "weak"],
      AGF: ["слабой", "weak"],
      AGN: ["слабого", "weak"],
      AGP: ["слабых", "weak"],
      ADM: ["слабому", "weak"],
      ADF: ["слабой", "weak"],
      ADN: ["слабому", "weak"],
      ADP: ["слабым", "weak"],
      AAM: ["слабый", "weak"],
      AAF: ["слабую", "weak"],
      AAN: ["слабое", "weak"],
      AAP: ["слабые", "weak"],
      AIM: ["слабым", "weak"],
      AIF: ["слабой", "weak"],
      AIN: ["слабым", "weak"],
      AIP: ["слабыми", "weak"],
      APM: ["слабом", "weak"],
      APF: ["слабой", "weak"],
      APN: ["слабом", "weak"],
      APP: ["слабых", "weak"],
      ASM: ["слаб", "weak"],
      ASF: ["слаба", "weak"],
      ASN: ["слабо", "weak"],
      ASP: ["слабы", "weak"],
      AC1: ["слабее", "weak"],
    },
    пришлый: {
      ANM: ["пришлый", "alien, strange"],
      ANF: ["пришлая", "alien, strange"],
      ANN: ["пришлое", "alien, strange"],
      ANP: ["пришлые", "alien, strange"],
      AGM: ["пришлого", "alien, strange"],
      AGF: ["пришлой", "alien, strange"],
      AGN: ["пришлого", "alien, strange"],
      AGP: ["пришлых", "alien, strange"],
      ADM: ["пришлому", "alien, strange"],
      ADF: ["пришлой", "alien, strange"],
      ADN: ["пришлому", "alien, strange"],
      ADP: ["пришлым", "alien, strange"],
      AAM: ["пришлый", "alien, strange"],
      AAF: ["пришлую", "alien, strange"],
      AAN: ["пришлое", "alien, strange"],
      AAP: ["пришлые", "alien, strange"],
      AIM: ["пришлым", "alien, strange"],
      AIF: ["пришлой", "alien, strange"],
      AIN: ["пришлым", "alien, strange"],
      AIP: ["пришлыми", "alien, strange"],
      APM: ["пришлом", "alien, strange"],
      APF: ["пришлой", "alien, strange"],
      APN: ["пришлом", "alien, strange"],
      APP: ["пришлых", "alien, strange"],
    },
    серьёзный: {
      ANM: ["серьёзный", "serious"],
      ANF: ["серьёзная", "serious"],
      ANN: ["серьёзное", "serious"],
      ANP: ["серьёзные", "serious"],
      AGM: ["серьёзного", "serious"],
      AGF: ["серьёзной", "serious"],
      AGN: ["серьёзного", "serious"],
      AGP: ["серьёзных", "serious"],
      ADM: ["серьёзному", "serious"],
      ADF: ["серьёзной", "serious"],
      ADN: ["серьёзному", "serious"],
      ADP: ["серьёзным", "serious"],
      AAM: ["серьёзный", "serious"],
      AAF: ["серьёзную", "serious"],
      AAN: ["серьёзное", "serious"],
      AAP: ["серьёзные", "serious"],
      AIM: ["серьёзным", "serious"],
      AIF: ["серьёзной", "serious"],
      AIN: ["серьёзным", "serious"],
      AIP: ["серьёзными", "serious"],
      APM: ["серьёзном", "serious"],
      APF: ["серьёзной", "serious"],
      APN: ["серьёзном", "serious"],
      APP: ["серьёзных", "serious"],
      ASM: ["серьёзен", "serious"],
      ASF: ["серьёзна", "serious"],
      ASN: ["серьёзно", "serious"],
      ASP: ["серьёзны", "serious"],
      AC1: ["серьёнее серьёзней", "serious"],
    },
    быстрый: {
      ANM: ["быстрый", "quick, fast"],
      ANF: ["быстрая", "quick, fast"],
      ANN: ["быстрое", "quick, fast"],
      ANP: ["быстрые", "quick, fast"],
      AGM: ["быстрого", "quick, fast"],
      AGF: ["быстрой", "quick, fast"],
      AGN: ["быстрого", "quick, fast"],
      AGP: ["быстрых", "quick, fast"],
      ADM: ["быстрому", "quick, fast"],
      ADF: ["быстрой", "quick, fast"],
      ADN: ["быстрому", "quick, fast"],
      ADP: ["быстрым", "quick, fast"],
      AAM: ["быстрый", "quick, fast"],
      AAF: ["быструю", "quick, fast"],
      AAN: ["быстрое", "quick, fast"],
      AAP: ["быстрые", "quick, fast"],
      AIM: ["быстрым", "quick, fast"],
      AIF: ["быстрой", "quick, fast"],
      AIN: ["быстрым", "quick, fast"],
      AIP: ["быстрыми", "quick, fast"],
      APM: ["быстром", "quick, fast"],
      APF: ["быстрой", "quick, fast"],
      APN: ["быстром", "quick, fast"],
      APP: ["быстрых", "quick, fast"],
      ASM: ["быстр", "quick, fast"],
      ASF: ["быстра", "quick, fast"],
      ASN: ["быстро", "quick, fast"],
      ASP: ["быстры быстры", "quick, fast"],
      AC1: ["быстрее", "quick, fast"],
      AC2: ["побыстрее", "quick, fast"],
    },
    левый: {
      ANM: ["левый", "left"],
      ANF: ["левая", "left"],
      ANN: ["левое", "left"],
      ANP: ["левые", "left"],
      AGM: ["левого", "left"],
      AGF: ["левой", "left"],
      AGN: ["левого", "left"],
      AGP: ["левых", "left"],
      ADM: ["левому", "left"],
      ADF: ["левой", "left"],
      ADN: ["левому", "left"],
      ADP: ["левым", "left"],
      AAM: ["левого", "left"],
      AAF: ["левую", "left"],
      AAN: ["левое", "left"],
      AAP: ["левых", "left"],
      AIM: ["левым", "left"],
      AIF: ["левой", "left"],
      AIN: ["левым", "left"],
      AIP: ["левыми", "left"],
      APM: ["левом", "left"],
      APF: ["левой", "left"],
      APN: ["левом", "left"],
      APP: ["левых", "left"],
      ASM: ["лев", "left"],
      ASF: ["лева лева", "left"],
      ASN: ["лево", "left"],
      ASP: ["левы", "left"],
    },
    детский: {
      ANM: ["детский", "child's, children's"],
      ANF: ["детская", "child's, children's"],
      ANN: ["детское", "child's, children's"],
      ANP: ["детские", "child's, children's"],
      AGM: ["детского", "child's, children's"],
      AGF: ["детской", "child's, children's"],
      AGN: ["детского", "child's, children's"],
      AGP: ["детских", "child's, children's"],
      ADM: ["детскому", "child's, children's"],
      ADF: ["детской", "child's, children's"],
      ADN: ["детскому", "child's, children's"],
      ADP: ["детским", "child's, children's"],
      AAM: ["детский", "child's, children's"],
      AAF: ["детскую", "child's, children's"],
      AAN: ["детское", "child's, children's"],
      AAP: ["детские", "child's, children's"],
      AIM: ["детским", "child's, children's"],
      AIF: ["детской", "child's, children's"],
      AIN: ["детским", "child's, children's"],
      AIP: ["детскими", "child's, children's"],
      APM: ["детском", "child's, children's"],
      APF: ["детской", "child's, children's"],
      APN: ["детском", "child's, children's"],
      APP: ["детских", "child's, children's"],
    },
    горячий: {
      ANM: ["горячий", "hot"],
      ANF: ["горячая", "hot"],
      ANN: ["горячее", "hot"],
      ANP: ["горячие", "hot"],
      AGM: ["горячего", "hot"],
      AGF: ["горячей", "hot"],
      AGN: ["горячего", "hot"],
      AGP: ["горячих", "hot"],
      ADM: ["горячему", "hot"],
      ADF: ["горячей", "hot"],
      ADN: ["горячему", "hot"],
      ADP: ["горячим", "hot"],
      AAM: ["горячего", "hot"],
      AAF: ["горячую", "hot"],
      AAN: ["горячее", "hot"],
      AAP: ["горячих", "hot"],
      AIM: ["горячим", "hot"],
      AIF: ["горячей", "hot"],
      AIN: ["горячим", "hot"],
      AIP: ["горячими", "hot"],
      APM: ["горячем", "hot"],
      APF: ["горячей", "hot"],
      APN: ["горячем", "hot"],
      APP: ["горячих", "hot"],
      ASM: ["горяч", "hot"],
      ASF: ["горяча", "hot"],
      ASN: ["горячо", "hot"],
      ASP: ["горячи", "hot"],
    },
    счастливый: {
      ANM: ["счастливый", "happy"],
      ANF: ["счастливая", "happy"],
      ANN: ["счастливое", "happy"],
      ANP: ["счастливые", "happy"],
      AGM: ["счастливого", "happy"],
      AGF: ["счастливой", "happy"],
      AGN: ["счастливого", "happy"],
      AGP: ["счастливых", "happy"],
      ADM: ["счастливому", "happy"],
      ADF: ["счастливой", "happy"],
      ADN: ["счастливому", "happy"],
      ADP: ["счастливым", "happy"],
      AAM: ["счастливого", "happy"],
      AAF: ["счастливую", "happy"],
      AAN: ["счастливое", "happy"],
      AAP: ["счастливых", "happy"],
      AIM: ["счастливым", "happy"],
      AIF: ["счастливой", "happy"],
      AIN: ["счастливым", "happy"],
      AIP: ["счастливыми", "happy"],
      APM: ["счастливом", "happy"],
      APF: ["счастливой", "happy"],
      APN: ["счастливом", "happy"],
      APP: ["счастливых", "happy"],
      ASM: ["счастлив", "happy"],
      ASF: ["счастлива", "happy"],
      ASN: ["счастливо", "happy"],
      ASP: ["счастливы", "happy"],
      AC1: ["счастливее", "happy"],
    },
    острый: {
      ANM: ["острый", "sharp; spicy"],
      ANF: ["острая", "sharp; spicy"],
      ANN: ["острое", "sharp; spicy"],
      ANP: ["острые", "sharp; spicy"],
      AGM: ["острого", "sharp; spicy"],
      AGF: ["острой", "sharp; spicy"],
      AGN: ["острого", "sharp; spicy"],
      AGP: ["острых", "sharp; spicy"],
      ADM: ["острому", "sharp; spicy"],
      ADF: ["острой", "sharp; spicy"],
      ADN: ["острому", "sharp; spicy"],
      ADP: ["острым", "sharp; spicy"],
      AAM: ["острый", "sharp; spicy"],
      AAF: ["острую", "sharp; spicy"],
      AAN: ["острое", "sharp; spicy"],
      AAP: ["острые", "sharp; spicy"],
      AIM: ["острым", "sharp; spicy"],
      AIF: ["острой", "sharp; spicy"],
      AIN: ["острым", "sharp; spicy"],
      AIP: ["острыми", "sharp; spicy"],
      APM: ["остром", "sharp; spicy"],
      APF: ["острой", "sharp; spicy"],
      APN: ["остром", "sharp; spicy"],
      APP: ["острых", "sharp; spicy"],
      ASM: ["остр", "sharp; spicy"],
      ASF: ["остра", "sharp; spicy"],
      ASN: ["остро", "sharp; spicy"],
      ASP: ["остры", "sharp; spicy"],
      AC1: ["острее", "sharp; spicy"],
    },
    мелкий: {
      ANM: ["мелкий", "shallow, small"],
      ANF: ["мелкая", "shallow, small"],
      ANN: ["мелкое", "shallow, small"],
      ANP: ["мелкие", "shallow, small"],
      AGM: ["мелкого", "shallow, small"],
      AGF: ["мелкой", "shallow, small"],
      AGN: ["мелкого", "shallow, small"],
      AGP: ["мелких", "shallow, small"],
      ADM: ["мелкому", "shallow, small"],
      ADF: ["мелкой", "shallow, small"],
      ADN: ["мелкому", "shallow, small"],
      ADP: ["мелким", "shallow, small"],
      AAM: ["мелкий", "shallow, small"],
      AAF: ["мелкую", "shallow, small"],
      AAN: ["мелкое", "shallow, small"],
      AAP: ["мелкие", "shallow, small"],
      AIM: ["мелким", "shallow, small"],
      AIF: ["мелкой", "shallow, small"],
      AIN: ["мелким", "shallow, small"],
      AIP: ["мелкими", "shallow, small"],
      APM: ["мелком", "shallow, small"],
      APF: ["мелкой", "shallow, small"],
      APN: ["мелком", "shallow, small"],
      APP: ["мелких", "shallow, small"],
      ASM: ["мелок", "shallow, small"],
      ASF: ["мелка", "shallow, small"],
      ASN: ["мелко", "shallow, small"],
      ASP: ["мелки мелки", "shallow, small"],
      AC1: ["мельче", "shallow, small"],
    },
    современный: {
      ANM: ["современный", "contemporary, modern, up-to-date"],
      ANF: ["современная", "contemporary, modern, up-to-date"],
      ANN: ["современное", "contemporary, modern, up-to-date"],
      ANP: ["современные", "contemporary, modern, up-to-date"],
      AGM: ["современного", "contemporary, modern, up-to-date"],
      AGF: ["современной", "contemporary, modern, up-to-date"],
      AGN: ["современного", "contemporary, modern, up-to-date"],
      AGP: ["современных", "contemporary, modern, up-to-date"],
      ADM: ["современному", "contemporary, modern, up-to-date"],
      ADF: ["современной", "contemporary, modern, up-to-date"],
      ADN: ["современному", "contemporary, modern, up-to-date"],
      ADP: ["современным", "contemporary, modern, up-to-date"],
      AAM: ["современный", "contemporary, modern, up-to-date"],
      AAF: ["современную", "contemporary, modern, up-to-date"],
      AAN: ["современное", "contemporary, modern, up-to-date"],
      AAP: ["современные", "contemporary, modern, up-to-date"],
      AIM: ["современным", "contemporary, modern, up-to-date"],
      AIF: ["современной", "contemporary, modern, up-to-date"],
      AIN: ["современным", "contemporary, modern, up-to-date"],
      AIP: ["современными", "contemporary, modern, up-to-date"],
      APM: ["современном", "contemporary, modern, up-to-date"],
      APF: ["современной", "contemporary, modern, up-to-date"],
      APN: ["современном", "contemporary, modern, up-to-date"],
      APP: ["современных", "contemporary, modern, up-to-date"],
      ASM: ["современен", "contemporary, modern, up-to-date"],
      ASF: ["современна", "contemporary, modern, up-to-date"],
      ASN: ["современно", "contemporary, modern, up-to-date"],
      ASP: ["современны", "contemporary, modern, up-to-date"],
    },
    весёлый: {
      ANM: ["весёлый", "cheerful, merry"],
      ANF: ["весёлая", "cheerful, merry"],
      ANN: ["весёлое", "cheerful, merry"],
      ANP: ["весёлые", "cheerful, merry"],
      AGM: ["весёлого", "cheerful, merry"],
      AGF: ["весёлой", "cheerful, merry"],
      AGN: ["весёлого", "cheerful, merry"],
      AGP: ["весёлых", "cheerful, merry"],
      ADM: ["весёлому", "cheerful, merry"],
      ADF: ["весёлой", "cheerful, merry"],
      ADN: ["весёлому", "cheerful, merry"],
      ADP: ["весёлым", "cheerful, merry"],
      AAM: ["весёлый", "cheerful, merry"],
      AAF: ["весёлую", "cheerful, merry"],
      AAN: ["весёлое", "cheerful, merry"],
      AAP: ["весёлые", "cheerful, merry"],
      AIM: ["весёлым", "cheerful, merry"],
      AIF: ["весёлой", "cheerful, merry"],
      AIN: ["весёлым", "cheerful, merry"],
      AIP: ["весёлыми", "cheerful, merry"],
      APM: ["весёлом", "cheerful, merry"],
      APF: ["весёлой", "cheerful, merry"],
      APN: ["весёлом", "cheerful, merry"],
      APP: ["весёлых", "cheerful, merry"],
      ASM: ["весел", "cheerful, merry"],
      ASF: ["весела", "cheerful, merry"],
      ASN: ["весело", "cheerful, merry"],
      ASP: ["веселы веселы", "cheerful, merry"],
      AC1: ["веселее", "cheerful, merry"],
      AC2: ["веселей", "cheerful, merry"],
    },
    милый: {
      ANM: ["милый", "nice, sweet, dear"],
      ANF: ["милая", "nice, sweet, dear"],
      ANN: ["милое", "nice, sweet, dear"],
      ANP: ["милые", "nice, sweet, dear"],
      AGM: ["милого", "nice, sweet, dear"],
      AGF: ["милой", "nice, sweet, dear"],
      AGN: ["милого", "nice, sweet, dear"],
      AGP: ["милых", "nice, sweet, dear"],
      ADM: ["милому", "nice, sweet, dear"],
      ADF: ["милой", "nice, sweet, dear"],
      ADN: ["милому", "nice, sweet, dear"],
      ADP: ["милым", "nice, sweet, dear"],
      AAM: ["милый", "nice, sweet, dear"],
      AAF: ["милую", "nice, sweet, dear"],
      AAN: ["милое", "nice, sweet, dear"],
      AAP: ["милые", "nice, sweet, dear"],
      AIM: ["милым", "nice, sweet, dear"],
      AIF: ["милой", "nice, sweet, dear"],
      AIN: ["милым", "nice, sweet, dear"],
      AIP: ["милыми", "nice, sweet, dear"],
      APM: ["милом", "nice, sweet, dear"],
      APF: ["милой", "nice, sweet, dear"],
      APN: ["милом", "nice, sweet, dear"],
      APP: ["милых", "nice, sweet, dear"],
      ASM: ["мил", "nice, sweet, dear"],
      ASF: ["мила", "nice, sweet, dear"],
      ASN: ["мило", "nice, sweet, dear"],
      ASP: ["милы милы", "nice, sweet, dear"],
      AC1: ["милее", "nice, sweet, dear"],
    },
    средний: {
      ANM: ["средний", "average, middle"],
      ANF: ["средняя", "average, middle"],
      ANN: ["среднее", "average, middle"],
      ANP: ["средние", "average, middle"],
      AGM: ["среднего", "average, middle"],
      AGF: ["средней", "average, middle"],
      AGN: ["среднего", "average, middle"],
      AGP: ["средних", "average, middle"],
      ADM: ["среднему", "average, middle"],
      ADF: ["средней", "average, middle"],
      ADN: ["среднему", "average, middle"],
      ADP: ["средним", "average, middle"],
      AAM: ["среднего", "average, middle"],
      AAF: ["среднюю", "average, middle"],
      AAN: ["среднее", "average, middle"],
      AAP: ["средних", "average, middle"],
      AIM: ["средним", "average, middle"],
      AIF: ["средней", "average, middle"],
      AIN: ["средним", "average, middle"],
      AIP: ["средними", "average, middle"],
      APM: ["среднем", "average, middle"],
      APF: ["средней", "average, middle"],
      APN: ["среднем", "average, middle"],
      APP: ["средних", "average, middle"],
    },
    американский: {
      ANM: ["американский", "American"],
      ANF: ["американская", "American"],
      ANN: ["американское", "American"],
      ANP: ["американские", "American"],
      AGM: ["американского", "American"],
      AGF: ["американской", "American"],
      AGN: ["американского", "American"],
      AGP: ["американских", "American"],
      ADM: ["американскому", "American"],
      ADF: ["американской", "American"],
      ADN: ["американскому", "American"],
      ADP: ["американским", "American"],
      AAM: ["американский", "American"],
      AAF: ["американскую", "American"],
      AAN: ["американское", "American"],
      AAP: ["американские", "American"],
      AIM: ["американским", "American"],
      AIF: ["американской", "American"],
      AIN: ["американским", "American"],
      AIP: ["американскими", "American"],
      APM: ["американском", "American"],
      APF: ["американской", "American"],
      APN: ["американском", "American"],
      APP: ["американских", "American"],
    },
    ночной: {
      ANM: ["ночной", "night"],
      ANF: ["ночная", "night"],
      ANN: ["ночное", "night"],
      ANP: ["ночные", "night"],
      AGM: ["ночного", "night"],
      AGF: ["ночной", "night"],
      AGN: ["ночного", "night"],
      AGP: ["ночных", "night"],
      ADM: ["ночному", "night"],
      ADF: ["ночной", "night"],
      ADN: ["ночному", "night"],
      ADP: ["ночным", "night"],
      AAM: ["ночной", "night"],
      AAF: ["ночную", "night"],
      AAN: ["ночное", "night"],
      AAP: ["ночные", "night"],
      AIM: ["ночным", "night"],
      AIF: ["ночной", "night"],
      AIN: ["ночным", "night"],
      AIP: ["ночными", "night"],
      APM: ["ночном", "night"],
      APF: ["ночной", "night"],
      APN: ["ночном", "night"],
      APP: ["ночных", "night"],
    },
    здоровый: {
      ANM: ["здоровый", "healthy"],
      ANF: ["здоровая", "healthy"],
      ANN: ["здоровое", "healthy"],
      ANP: ["здоровые", "healthy"],
      AGM: ["здорового", "healthy"],
      AGF: ["здоровой", "healthy"],
      AGN: ["здорового", "healthy"],
      AGP: ["здоровых", "healthy"],
      ADM: ["здоровому", "healthy"],
      ADF: ["здоровой", "healthy"],
      ADN: ["здоровому", "healthy"],
      ADP: ["здоровым", "healthy"],
      AAM: ["здоровый", "healthy"],
      AAF: ["здоровую", "healthy"],
      AAN: ["здоровое", "healthy"],
      AAP: ["здоровые", "healthy"],
      AIM: ["здоровым", "healthy"],
      AIF: ["здоровой", "healthy"],
      AIN: ["здоровым", "healthy"],
      AIP: ["здоровыми", "healthy"],
      APM: ["здоровом", "healthy"],
      APF: ["здоровой", "healthy"],
      APN: ["здоровом", "healthy"],
      APP: ["здоровых", "healthy"],
      ASM: ["здоров", "healthy"],
      ASF: ["здорова", "healthy"],
      ASN: ["здорово", "healthy"],
      ASP: ["здоровы", "healthy"],
    },
    сухой: {
      ANM: ["сухой", "dry"],
      ANF: ["сухая", "dry"],
      ANN: ["сухое", "dry"],
      ANP: ["сухие", "dry"],
      AGM: ["сухого", "dry"],
      AGF: ["сухой", "dry"],
      AGN: ["сухого", "dry"],
      AGP: ["сухих", "dry"],
      ADM: ["сухому", "dry"],
      ADF: ["сухой", "dry"],
      ADN: ["сухому", "dry"],
      ADP: ["сухим", "dry"],
      AAM: ["сухой", "dry"],
      AAF: ["сухую", "dry"],
      AAN: ["сухое", "dry"],
      AAP: ["сухие", "dry"],
      AIM: ["сухим", "dry"],
      AIF: ["сухой", "dry"],
      AIN: ["сухим", "dry"],
      AIP: ["сухими", "dry"],
      APM: ["сухом", "dry"],
      APF: ["сухой", "dry"],
      APN: ["сухом", "dry"],
      APP: ["сухих", "dry"],
      ASM: ["сух", "dry"],
      ASF: ["суха", "dry"],
      ASN: ["сухо", "dry"],
      ASP: ["сухи", "dry"],
      AC1: ["суше", "dry"],
    },
    свободный: {
      ANM: ["свободный", "free"],
      ANF: ["свободная", "free"],
      ANN: ["свободное", "free"],
      ANP: ["свободные", "free"],
      AGM: ["свободного", "free"],
      AGF: ["свободной", "free"],
      AGN: ["свободного", "free"],
      AGP: ["свободных", "free"],
      ADM: ["свободному", "free"],
      ADF: ["свободной", "free"],
      ADN: ["свободному", "free"],
      ADP: ["свободным", "free"],
      AAM: ["свободный", "free"],
      AAF: ["свободную", "free"],
      AAN: ["свободное", "free"],
      AAP: ["свободные", "free"],
      AIM: ["свободным", "free"],
      AIF: ["свободной", "free"],
      AIN: ["свободным", "free"],
      AIP: ["свободными", "free"],
      APM: ["свободном", "free"],
      APF: ["свободной", "free"],
      APN: ["свободном", "free"],
      APP: ["свободных", "free"],
      ASM: ["свободен", "free"],
      ASF: ["свободна", "free"],
      ASN: ["свободно", "free"],
      ASP: ["свободны", "free"],
    },
    способный: {
      ANM: ["способный", "capable (of)"],
      ANF: ["способная", "capable (of)"],
      ANN: ["способное", "capable (of)"],
      ANP: ["способные", "capable (of)"],
      AGM: ["способного", "capable (of)"],
      AGF: ["способной", "capable (of)"],
      AGN: ["способного", "capable (of)"],
      AGP: ["способных", "capable (of)"],
      ADM: ["способному", "capable (of)"],
      ADF: ["способной", "capable (of)"],
      ADN: ["способному", "capable (of)"],
      ADP: ["способным", "capable (of)"],
      AAM: ["способный", "capable (of)"],
      AAF: ["способную", "capable (of)"],
      AAN: ["способное", "capable (of)"],
      AAP: ["способные", "capable (of)"],
      AIM: ["способным", "capable (of)"],
      AIF: ["способной", "capable (of)"],
      AIN: ["способным", "capable (of)"],
      AIP: ["способными", "capable (of)"],
      APM: ["способном", "capable (of)"],
      APF: ["способной", "capable (of)"],
      APN: ["способном", "capable (of)"],
      APP: ["способных", "capable (of)"],
      ASM: ["способен", "capable (of)"],
      ASF: ["способна", "capable (of)"],
      ASN: ["способно", "capable (of)"],
      ASP: ["способны", "capable (of)"],
    },
    интересный: {
      ANM: ["интересный", "interesting"],
      ANF: ["интересная", "interesting"],
      ANN: ["интересное", "interesting"],
      ANP: ["интересные", "interesting"],
      AGM: ["интересного", "interesting"],
      AGF: ["интересной", "interesting"],
      AGN: ["интересного", "interesting"],
      AGP: ["интересных", "interesting"],
      ADM: ["интересному", "interesting"],
      ADF: ["интересной", "interesting"],
      ADN: ["интересному", "interesting"],
      ADP: ["интересным", "interesting"],
      AAM: ["интересный", "interesting"],
      AAF: ["интересную", "interesting"],
      AAN: ["интересное", "interesting"],
      AAP: ["интересные", "interesting"],
      AIM: ["интересным", "interesting"],
      AIF: ["интересной", "interesting"],
      AIN: ["интересным", "interesting"],
      AIP: ["интересными", "interesting"],
      APM: ["интересном", "interesting"],
      APF: ["интересной", "interesting"],
      APN: ["интересном", "interesting"],
      APP: ["интересных", "interesting"],
      ASM: ["интересен", "interesting"],
      ASF: ["интересна", "interesting"],
      ASN: ["интересно", "interesting"],
      ASP: ["интересны", "interesting"],
      AC1: ["интереснее", "interesting"],
    },
    дорогой: {
      ANM: ["дорогой", "on the way"],
      ANF: ["дорогая", "on the way"],
      ANN: ["дорогое", "on the way"],
      ANP: ["дорогие", "on the way"],
      AGM: ["дорогого", "on the way"],
      AGF: ["дорогой", "on the way"],
      AGN: ["дорогого", "on the way"],
      AGP: ["дорогих", "on the way"],
      ADM: ["дорогому", "on the way"],
      ADF: ["дорогой", "on the way"],
      ADN: ["дорогому", "on the way"],
      ADP: ["дорогим", "on the way"],
      AAM: ["дорогой", "on the way"],
      AAF: ["дорогую", "on the way"],
      AAN: ["дорогое", "on the way"],
      AAP: ["дорогие", "on the way"],
      AIM: ["дорогим", "on the way"],
      AIF: ["дорогой", "on the way"],
      AIN: ["дорогим", "on the way"],
      AIP: ["дорогими", "on the way"],
      APM: ["дорогом", "on the way"],
      APF: ["дорогой", "on the way"],
      APN: ["дорогом", "on the way"],
      APP: ["дорогих", "on the way"],
      ASM: ["дорог", "on the way"],
      ASF: ["дорога", "on the way"],
      ASN: ["дорого", "on the way"],
      ASP: ["дороги", "on the way"],
      AC1: ["дороже", "on the way"],
    },
    мокрый: {
      ANM: ["мокрый", "wet"],
      ANF: ["мокрая", "wet"],
      ANN: ["мокрое", "wet"],
      ANP: ["мокрые", "wet"],
      AGM: ["мокрого", "wet"],
      AGF: ["мокрой", "wet"],
      AGN: ["мокрого", "wet"],
      AGP: ["мокрых", "wet"],
      ADM: ["мокрому", "wet"],
      ADF: ["мокрой", "wet"],
      ADN: ["мокрому", "wet"],
      ADP: ["мокрым", "wet"],
      AAM: ["мокрый", "wet"],
      AAF: ["мокрую", "wet"],
      AAN: ["мокрое", "wet"],
      AAP: ["мокрые", "wet"],
      AIM: ["мокрым", "wet"],
      AIF: ["мокрой", "wet"],
      AIN: ["мокрым", "wet"],
      AIP: ["мокрыми", "wet"],
      APM: ["мокром", "wet"],
      APF: ["мокрой", "wet"],
      APN: ["мокром", "wet"],
      APP: ["мокрых", "wet"],
      ASM: ["мокр", "wet"],
      ASF: ["мокра", "wet"],
      ASN: ["мокро", "wet"],
      ASP: ["мокры", "wet"],
    },
    прямой: {
      ANM: ["прямой", "straight"],
      ANF: ["прямая", "straight"],
      ANN: ["прямое", "straight"],
      ANP: ["прямые", "straight"],
      AGM: ["прямого", "straight"],
      AGF: ["прямой", "straight"],
      AGN: ["прямого", "straight"],
      AGP: ["прямых", "straight"],
      ADM: ["прямому", "straight"],
      ADF: ["прямой", "straight"],
      ADN: ["прямому", "straight"],
      ADP: ["прямым", "straight"],
      AAM: ["прямой", "straight"],
      AAF: ["прямую", "straight"],
      AAN: ["прямое", "straight"],
      AAP: ["прямые", "straight"],
      AIM: ["прямым", "straight"],
      AIF: ["прямой", "straight"],
      AIN: ["прямым", "straight"],
      AIP: ["прямыми", "straight"],
      APM: ["прямом", "straight"],
      APF: ["прямой", "straight"],
      APN: ["прямом", "straight"],
      APP: ["прямых", "straight"],
      ASM: ["прям", "straight"],
      ASF: ["пряма", "straight"],
      ASN: ["прямо", "straight"],
      ASP: ["прямы", "straight"],
      AC1: ["прямее", "straight"],
    },
  };
  
  const accusative_vocab = {
    PN: ["я", "он", "она", "ты", "мы", "вы", "они"],
    AN: ["большой", "новый", "хороший"],
    VN: ["знать", "хотеть", "видеть"],
    PP: ["мой", "твой", "её", "его", "наш", "ваш", "их"],
    U: [
      "один",
      "два",
      "три",
      "четыре",
      "пять",
      "шесть",
      "семь",
      "восемь",
      "девять",
      "десять",
    ],
    NN: Living,
  };
  
  function get_v_for_p(p, v) {
    if (PN[p][2] === "1") {
      return verbs[v]["VN1"][0];
    } else if (PN[p][2] === "2") {
      return verbs[v]["VN2"][0];
    } else if (PN[p][2] === "3") {
      return verbs[v]["VN3"][0];
    } else if (PN[p][2] === "W") {
      return verbs[v]["VNW"][0];
    } else if (PN[p][2] === "F") {
      return verbs[v]["VNF"][0];
    } else if (PN[p][2] === "T") {
      return verbs[v]["VNT"][0];
    }
  }
  
  function number_plur_sing(u) {
    if (u === "один") {
      return false;
    } else {
      return true;
    }
  }
  
  function get_case_from_number(u) {
    if (u === "один") {
      return "NS";
    } else if (u === "два") {
      return "GS";
    } else if (u === "три") {
      return "GS";
    } else if (u === "четыре") {
      return "GS";
    } else {
      return "GP";
    }
  }
  
  function get_n_for_u(u, NN) {
    if (u === "один") {
      return nouns[NN]["NNS"][0];
    } else if (u === "два") {
      return nouns[NN]["NGS"][0];
    } else if (u === "три") {
      return nouns[NN]["NGS"][0];
    } else if (u === "четыре") {
      return nouns[NN]["NGS"][0];
    } else {
      return nouns[NN]["NGP"][0];
    }
  }
  
  function check_singular_plural(p, NN) {
    if (PN[p][0] === "singular") {
      return nouns[NN]["NNS"][0];
    } else {
      return nouns[NN]["NNP"][0];
    }
  }
  
  const random = require("@aspiesoft/random-number-js");
  random.setAvoidRadius(1);
  
  function populate_structure(structure, vocab, time, gCase) {
    let sentence = [];
    let np_structure = structure[0][0];
    let vp_structure = structure[0][1];
  
    // evaluate noun phrase
    let first_np_data = evaluate_noun_phrase(np_structure, vocab, "N");
    let first_np = first_np_data[0];
    let person = first_np_data[1];
    let plural = first_np_data[2];
    let gender = first_np_data[3];
    sentence = first_np;
  
    // evaluate verb phrase 
    let vp = evaluate_verb_phrase(vp_structure, vocab, plural, person, gender,gCase,false, time,)
    sentence = [...sentence,...vp];
    return sentence;
  }
  
  function evaluate_verb_phrase(structure,vocab,plural,person,gender,gCase, negated=false, time="Present", ){
    let sentence = [];
  
    if(structure[0] === "VN"){
      let v = vocab["VN"][random(0, vocab["VN"].length - 1)];
      let verb = "";
    
      if(time==="Present"){
        if(verbs[v]["VN" + person]){
          verb = verbs[v]["VN" + person][0];
        }else{
          verb = verbs[v]["VF" + person][0];
        }
      }else if(time==="Past"){
        if(plural)
          verb = verbs[v]["VPP"][0];
        else
          if(gender === "F")
            verb = verbs[v]["VPF"][0];
          else if(gender === "N")
            verb = verbs[v]["VPN"][0];
          else
            verb = verbs[v]["VPM"][0];
      }else{
        verb = verbs[v]["VF" + person][0];
      }
  
      sentence.push(verb);
    }
    
    if(structure.length > 1){
      if (arraysEqual(structure, ["VN", "VI"])) {
        let verbInfinitive = vocab["VI"][random(0, vocab["VI"].length - 1)];
        sentence.push(verbInfinitive);
      } else if (isArray(structure[0])) {
        let second_np_data = evaluate_noun_phrase(structure[0], vocab, gCase,"1");
        let second_np = second_np_data[0];
        sentence = sentence.concat(second_np); 
      } else if (isArray(structure[1])) {
        let second_np_data = evaluate_noun_phrase(structure[1], vocab, gCase,"1");
        let second_np = second_np_data[0];
        sentence = sentence.concat(second_np); 
      } else if ( isArray(structure[2]) && structure[1] === "PRP" || 
                  isArray(structure[1]) && structure[0] === "PRP") {
        let prp = vocab["PRP"][random(0, vocab["PRP"].length - 1)];
        sentence.push(prp);
        let second_np_data = evaluate_noun_phrase(structure[2], vocab, gCase,"1");
        let second_np = second_np_data[0];
        sentence = sentence.concat(second_np); 
      }
    }
    return sentence;
  }
  
  function arraysEqual(a1, a2) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
  
  function evaluate_noun_phrase(structure, vocab, ncase, c="") {
    let sentence = [];
    let is_plural = false;
    let person = "";
    let gender = "N";
    if (arraysEqual(structure, ["U", "AN"+c, "NN"+c])) {
      let number = vocab["U"][random(0, vocab["U"].length - 1)];
      is_plural = number_plur_sing(number);
      let noun_case = get_case_from_number(number);
      let nn = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun = get_n_for_u(number, nn);
      let noun_gender = nouns[nn]["NNS"][2];
      gender = noun_gender;
      let adjective =
        adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]][
          "A" + noun_case[0] + (is_plural ? "P" : noun_gender)
        ];
      if (number == "один") {
        if (nouns[nn][2] == "F") {
          number = "одна";
        } else if (nouns[nn][2] == "N") {
          number = "одно";
        }
      }
      if (number == "два") {
        if (nouns[nn][2] == "F") {
          number = "две";
        } else if (nouns[nn][2] == "N") {
          number = "два";
        }
      }
      if (!is_plural) {
        person = "3";
        adjective =
          adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]]["AA" + noun_gender];
      } else {
        person = "T";
      }
      
      sentence.push(number);
      sentence.push(adjective[0]);
      sentence.push(noun);
    } else if (arraysEqual(structure, ["PP"+c, "AN"+c, "NN"+c])) {
      is_plural = false;
      let noun_case = ncase;
      let noun = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun_gender = nouns[noun]["NNS"][2];
      gender = noun_gender;
      let adjective = [];
      if (!is_plural) {
        adjective =
          adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]]["AA" + noun_gender];
      }
      person = "3";
      noun = nouns[noun]["N" + noun_case + "S"];
      adjective =
        adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]][
          "A" + noun_case + noun_gender
        ];
      let pp =
        PPP[vocab["PP"+c][random(0, vocab["PP"+c].length - 1)]][
          noun_case + noun_gender
        ];
      sentence.push(pp[0]);
      sentence.push(adjective[0]);
      sentence.push(noun[0]);
    } else if (arraysEqual(structure, ["PP"+c, "NN"+c])) {
      is_plural = false;
      let noun_case = ncase;
      let noun = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun_gender = nouns[noun]["NNS"][2];
      gender = noun_gender;
      person = "3";
      noun = nouns[noun]["N" + noun_case + "S"];
      let pp =
        PPP[vocab["PP"+c][random(0, vocab["PP"+c].length - 1)]][
          noun_case + noun_gender
        ];
      sentence.push(pp[0]);
      sentence.push(noun[0]);
    } else if (arraysEqual(structure, ["AN"+c, "NN"+c])) {
      is_plural = false;
      let noun_case = ncase;
      let noun = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun_gender = nouns[noun]["NNS"][2];
      gender = noun_gender;
      let adjective = [];
      if (!is_plural) {
        adjective =
          adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]]["AA" + noun_gender];
      }
      person = "3";
      noun = nouns[noun]["N" + noun_case + "S"];
      adjective =
        adj[vocab["AN"+c][random(0, vocab["AN"+c].length - 1)]][
          "A" + noun_case + noun_gender
        ];
      sentence.push(adjective[0]);
      sentence.push(noun[0]);
    } else if (arraysEqual(structure, ["NN"+c])) {
      is_plural = false;
      let noun = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun_gender = nouns[noun]["NNS"][2];
      gender = noun_gender;
      sentence.push(nouns[noun]["N" + ncase + "S"][0]);
      person = "3";
    } else if (arraysEqual(structure, ["PN"+c])) {
      let pronoun = vocab["PN"+c][random(0, vocab["PN"+c].length - 1)];
      person = PN[pronoun][2];
      is_plural = PN[pronoun][0] === "singular" ? false : true;
      gender = PN[pronoun][1];
      pronoun = PEP[pronoun][ncase];
      sentence.push(pronoun[0]);
    } else if (arraysEqual(structure, ["DM"+c])){
      
      let demonstrativePronoun =
        adj[vocab["DM"+c][random(0, vocab["DM"+c].length - 1)]][
          "A" + ncase + "M"
        ];
      sentence.push(demonstrativePronoun[0]);
    } else if (arraysEqual(structure, ["DM"+c,"NN"+c])){
      let noun = vocab["NN"+c][random(0, vocab["NN"+c].length - 1)];
      let noun_gender = nouns[noun]["NNS"][2];
  
      let demonstrativePronoun =
        adj[vocab["DM"+c][random(0, vocab["DM"+c].length - 1)]][
          "A" + ncase + noun_gender
        ];
      sentence.push(demonstrativePronoun[0]);
      sentence.push(nouns[noun]["N" + ncase + "S"][0]);
    }
  
    return [sentence, person, is_plural, gender];
  }
  
  function complie_grammar_rec(sentence, g) {
      let s = JSON.parse(JSON.stringify(sentence));
    for (var i = 0; i < s.length; i++) {
      let symbol = s[i];
      if (!Array.isArray(symbol)) {
        if (g[symbol]) {
          let r = random(0, g[symbol].length - 1);
          let ch = g[symbol][r];
          s[i] = ch;
        }
      } else {
        s[i] = complie_grammar_rec(symbol, g);
      }
    }
  
    if (terminla_check_rec(s, g)) {
      return s;
    } else {
      return complie_grammar_rec(s, g);
    }
  }
  
  function isArray(obj){
    return !!obj && obj.constructor === Array;
  }
  
  function terminla_check_rec(sentence, g) {
    let terminal = true;
    sentence.forEach((symbol) => {
      if (Array.isArray(symbol)) {
        if (!terminla_check_rec(symbol, g)) {
          terminal = false;
        }
      } else {
        if (g[symbol]) {
          terminal = false;
        }
      }
    });
  
    return terminal;
  }
  
  export function getSample(Mstructure,vocab,time,gCase){
    let structure = complie_grammar_rec(["S"], Mstructure);
    let s = populate_structure(structure, vocab,time,gCase).join(" ");
    return s;
  }