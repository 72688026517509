import React, { useState, useEffect, useRef} from 'react';
import Navbar from '../navbar/Navbar';
import {useAuth} from '../auth/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback } from 'react';
import { getAuth } from "firebase/auth";

export default function Feed(){

    const [role, setRole] = useState();
    const [page, setPage] = useState(1);

    const {currentUserState, userConfig, currentUser} = useAuth();
    const [xp, setXP] = useState(currentUserState.xp);
    const loader = useRef(null);

    const functions = getFunctions();
    const getFeed = httpsCallable(functions, 'getFeed');

    const [feed, setFeed] = useState([]);

    const handleObserver = useCallback(async (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            let configObj = {};
            let authToken = await getAuth().currentUser.getIdToken(true);
            configObj.authToken = authToken;
            configObj.lang = `${userConfig.target_lang}`
            let loadedFeed = await getFeed(configObj);
            setFeed([...feed,loadedFeed]);
            console.log("intersecting");
            setPage((prev) => prev + 1);
        }
      }, []);
    

    useEffect(() => {
        const option = {
          root: null,
          rootMargin: "0px",
          threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
      }, [handleObserver]);

    return  <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
        <Navbar role={role} xp={xp}  percentage={`${(100)}%`}></Navbar>
        <div className="relative scroll-height w-full h-full">

        <div ref={loader} />

        </div>
    </div>
}