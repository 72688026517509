import React, {useRef, useState, useEffect} from 'react';
import { setDoc, doc, getDoc, onSnapshot , getFirestore, deleteDoc, addDoc, collection  } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {IoAddOutline,
        IoRemove,
        IoSaveOutline,
        IoHammerOutline,
        IoEyeOutline,
        IoLink,
        IoUnlinkOutline,
        IoEyeOffOutline,
        IoReturnDownForward} from 'react-icons/io5';
import {MdAlignHorizontalLeft,
        MdAlignHorizontalRight,
        MdOutlineInfo,
        MdOutlineNoteAdd } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditorSpeechBubble } from '../EditorSpeechBubble';
import sparkMd5 from 'spark-md5';
import { ReactComponent as Spinner } from '../../svg/spinner.svg';
import {STATES, StateButton} from '../../util/StateButton';
import { useAuth } from '../../auth/AuthContext';

import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';

import { IoExtensionPuzzleOutline } from "react-icons/io5";
import WordItem from '../Level/WordItem';
import StorySettingDropDown from './StorySettingDropDown';
import { BlockChooser } from '../BlockChooser';
import { useWords } from '../Words/WordContext';
import { jsonEval } from '@firebase/util';
import { LinkEditor } from './LinkEditor';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

let wordStatistics = {};

export default function Story({story}) {
  const functions = getFunctions();
  const generateStoryAudio = httpsCallable(functions, 'generateStoryAudio');

  const {userConfig} = useAuth();

  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [editState, setEditState] = useState(-1);
  const [subEditState, setSubEditState] = useState(-1);
  const [sub1EditState, setSub1EditState] = useState(-1);
  const [generateAudioState, setGenerateAudioState] = useState(STATES.STATE_NORMAL);
  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const templateNameRef = useRef(null);
  const [words, setWords] = useState({});
  const [unknownWords, setUnknownWords] = useState({});
  const [state, setState] = useState(1);
  const [storyLevel, setStoryLevel] = useState(0);

  const {findWordByDetector, getWordByRank} = useWords();

  let [isOpen, setIsOpen] = useState(false);
  let [isLinkEditorOpen, setIsLinkEditorOpen] = useState(false);

  const [currentBlock, setCurrentBlock] = useState(0);
  const [blocks, setBlocks] = useState([]);
  const [loadingBlocks, setLoadingBlocks] = useState(true);
  const [linked, setLinked] = useState(false);
  const [blockContext, setBlockContext] = useState("");

  useEffect(() => {
    setState(story.state);
    onSnapshot(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,story.id+""), async (doc) => {
        let data = doc.data();
        if(data){
          if(data.states){
            setStates(data.states);
          } else {
            setStates([]);
          }
        }
        setLoading(false);
    });
    if(story.name && templateNameRef.current){
      templateNameRef.current.value = story.name;
    }

    onSnapshot(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
      let tempStories = [];
      let data = doc.data();

      if(!data.hasOwnProperty("ids")){
        setLoadingBlocks(false);
        return;
      }
      
      for(let i = 0; i < data.ids.length; i++){
        tempStories.push({"id": data.ids[i], "name": data.names[i], "le": i});
      };
      if(data.lastChange){
        setCurrentBlock(tempStories[data.lastChange]);
      }else{
        setCurrentBlock(tempStories[0]);
      }
      
      setBlocks(tempStories);
      setLoadingBlocks(false);
  });
    return () => {
      setStates([]);
    }
  },[story,userConfig]);

  useEffect(() => {
    wordAnalytics();
  },[states]) // <-- here put the parameter to listen

  function transformWordArray(ws){
    let ret = [];
    Object.keys(ws).forEach((item) => {
      ret.push({R:wordStatistics[item].R,N:words[item]});
    });
    return ret;
  }

  async function save(){
    setSafeState(STATES.STATE_PROCESS);
    let newToUser = false;
    const d = new Date();
    let tStamp = d.getTime();
    await setDoc(doc(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, story.id), {
      states: states,
      level: storyLevel,
    });

    const docSnap = await getDoc(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.vocab[story.id] = transformWordArray(wordStatistics);

      for(let i = 0; i < data.ids.length; i++){
        if(data.ids[i] === story.id){
          if(data.states[i] === 0 && state === 1){
            data.timeStamps[i] = d.getTime();
          }
          data.states[i] = state;
          data.levels[i] = storyLevel;
          data.names[i] = templateNameRef.current.value;
        }
    };

      await setDoc(doc(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
        states:  data.states,
        levels: data.levels,
        vocab: data.vocab, 
        lastChange: story.le,
        timeStamps: data.timeStamps,
      });
      setSafeState(STATES.STATE_NORMAL)
    }
  }

  async function remove(){
    const docSnap = await getDoc(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();

      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === story.id){
            data.ids.splice(i,1);
            data.names.splice(i,1);
            data.states.splice(i,1);
            data.levels.splice(i,1);
            data.timeStamps.splice(i,1);
            
          }
          delete data.vocab[story.id];
      };

      await deleteDoc(doc(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, story.id));
      await setDoc(doc(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
        states: data.states,
        timeStamps: data.timeStamps,
        levels: data.levels,
        vocab: data.vocab,
      },{merge: true});
    }
  }

  function newState(){
    if(editState !== -1&&subEditState !== -1){
      
      let tempState = {
        text: 'sample',
        direction: 5,
        hash: sparkMd5.hash('sample')
      }

      if(states[editState].deconstruct[subEditState].deconstruct){
        states[editState].deconstruct[subEditState].deconstruct = [...states[editState].deconstruct[subEditState].deconstruct,tempState];
      }else{
        states[editState].deconstruct[subEditState].deconstruct = [tempState];
      }
      
      
      let t1 = [...states];
      setStates(t1);
    } else if(editState !== -1){

      let lastStateType;
      if(states[editState].deconstruct){
        
        let subLength = states[editState].deconstruct.length-1;
        lastStateType = states[editState].deconstruct[subLength].direction;
      }else{
        lastStateType = states[editState].direction;
      }

      let tempState = {
        text: 'sample',
        direction: followingDirection(lastStateType),
        hash: sparkMd5.hash('sample')
      }

      if(states[editState].deconstruct){
        states[editState].deconstruct = [...states[editState].deconstruct,tempState];
      }else{
        states[editState].deconstruct = [tempState];
      }
      
      
      let t1 = [...states];
      setStates(t1);
    }else{
      let lastStateType;
      if(states.length-1 > -1){
        lastStateType = states[states.length-1].direction;
      }else {
        lastStateType = 3;
      }
      
      let tempState = {
        text: 'sample',
        direction: followingDirection(lastStateType),
        hash: sparkMd5.hash('sample')
      }
  
      let t1 = [...states,tempState];
      setStates(t1);
    }
    
  }

  function newStateAfter(){
    if(editState !== -1&&subEditState !== -1 && sub1EditState !== -1){
      
      let tempState = {
        text: 'sample',
        direction: 5,
        hash: sparkMd5.hash('sample')
      }

      if(states[editState].deconstruct[subEditState].deconstruct){
        states[editState].deconstruct[subEditState].deconstruct.splice(sub1EditState+1,0,tempState);
      }
      let t1 = [...states];
      setStates(t1);
    } else if (editState !== -1&&subEditState !== -1){

      let tempState = {
        text: 'sample',
        direction: 5,
        hash: sparkMd5.hash('sample')
      }

      if(states[editState].deconstruct){
        states[editState].deconstruct.splice(subEditState+1,0,tempState);
      }
      
      
      let t1 = [...states];
      setStates(t1);
    }
  }

  function followingDirection(lastDir){
    if(lastDir === 0){
      return 4;
    }else if(lastDir === 1){
      return 3;
    }else if(lastDir === 3){
      return 1;
    }else if(lastDir === 4){
      return 0;
    }else{
      return 4;
    }
  }

  function removeText(){
    if(subEditState !== -1&&sub1EditState !== -1){
      let t1 = [...states];
      t1[editState].deconstruct[subEditState].deconstruct.splice(sub1EditState, 1);
      if(t1[editState].deconstruct[subEditState].deconstruct.length === 0){
        delete t1[editState].deconstruct[subEditState].deconstruct
      }
      setStates(t1);
      setSub1EditState(-1);
    } else if(subEditState !== -1){
      let t1 = [...states];
      t1[editState].deconstruct.splice(subEditState, 1);
      if(t1[editState].deconstruct.length === 0){
        delete t1[editState].deconstruct
      }
      setStates(t1);
      setSubEditState(-1);
      setSub1EditState(-1);
    }else{
      let t1 = [...states];
      t1.splice(editState, 1);
      setStates(t1);
      setEditState(-1);
      setSubEditState(-1);
      setSub1EditState(-1);
    }
  }

  function updateText(id,text){
    let t1 = [...states];
    t1[id].text = text;
    t1[id].hash = sparkMd5.hash(text);
    setStates(t1);
  }

  function updateSubText(id,subId,text){
    let t1 = [...states];
    t1[id].deconstruct[subId].text = text;
    t1[id].deconstruct[subId].hash = sparkMd5.hash(text);
    setStates(t1);
  }

  function updateSub1Text(id,subId,sub1Id,text){
    let t1 = [...states];
    t1[id].deconstruct[subId].deconstruct[sub1Id].text = text;
    t1[id].deconstruct[subId].deconstruct[sub1Id].hash = sparkMd5.hash(text);
    setStates(t1);
  }

  function updateBtn(id,text){
    let t1 = [...states];
    t1[id].btn = text;
    setStates(t1);
  }

  function updateSubBtn(id,subId,text){
    let t1 = [...states];
    t1[id].deconstruct[subId].btn = text;
    setStates(t1);
  }

  function updateSub1Btn(id,subId,sub1Id,text){
    let t1 = [...states];
    t1[id].deconstruct[subId].deconstruct[sub1Id].btn = text;
    setStates(t1);
  }

  function updateDirection(direction){
    if(subEditState !== -1&&sub1EditState !== -1){
      let t1 = [...states];
      
      t1[editState].deconstruct[subEditState].deconstruct[sub1EditState].direction = direction;
      setStates(t1);
    } else if(subEditState !== -1){
      let t1 = [...states];
      t1[editState].deconstruct[subEditState].direction = direction;
      setStates(t1);
    }else{
      let t1 = [...states];
      t1[editState].direction = direction;
      setStates(t1);
    }
    
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const t1 = reorder(
      states,
      result.source.index,
      result.destination.index
    );
    setEditState(result.destination.index);
    setStates(t1);
    
  }

  function wordAnalytics(){
    let words = {};
    let unknownW = {};
    wordStatistics = {}
    states.forEach((s) =>{
      if(s.deconstruct){
        s.deconstruct.forEach((s1) =>{

        }); 
      }
      if(s.direction === 0 || s.direction === 1){
        let sentenceWords = s.text.split(" ");
        sentenceWords.forEach((word) => {
          word = word.replace(".","");
          word = word.replace("!","");
          word = word.replace("?","");
          word = word.replace("¿","");
          word = word.replace(",","");
          word = word.replace("...","");
          word = word.replace("..","");
          word = word.replace(" - ","");
          word = word.replace("  ","");
          if(userConfig.target_lang === "es-ES"){
            word = word.replace("á","a");
            word = word.replace("é","e");
            word = word.replace("í","i");
            word = word.replace("ó","o");
            word = word.replace("ú","u");
          }
          word = word.toLocaleLowerCase();
          let wordObj = findWordByDetector(word);
          if(wordObj){
            wordStatistics[wordObj.R] = wordObj;
            if(words[wordObj.R]){
              words[wordObj.R] = words[wordObj.R] + 1;
            }else{
              words[wordObj.R] = 1;
            }
          }else{
            unknownW[word] = 1;
          }
        });
      }
    });

    let storyState = {}

    let aggregate = 0;
    let wordCount = 0;

    Object.keys(words).forEach((word,i) => {
      let wObj = getWordDataForRank(word);
      if(wObj){
        wordCount += words[word];
        aggregate += (words[word]*rankWeight(wObj.RA));
      }
      
    });

    let sl = aggregate/wordCount;

    if(sl){
      setStoryLevel(sl);
    }

    setWords(words);
    setUnknownWords(unknownW);
  }

  function rankWeight(x){
    return (3/1000*(x*x))+1;
  }

  function getWordDataForRank(w){
    let wordObj;
    if(typeof(w) === "number"){
      wordObj = getWordByRank(w);
    }else{
      wordObj = findWordByDetector(w);
    }

    return wordObj;
  }

  function generateAudioForStory(){
    setGenerateAudioState(STATES.STATE_PROCESS);
    generateStoryAudio({id:story.id,origin_lang:userConfig.origin_lang,target_lang:userConfig.target_lang})
      .then(() => {
        setGenerateAudioState(STATES.STATE_NORMAL);
      })
      .catch((e) => {
        console.error(e);
        setGenerateAudioState(STATES.STATE_ERROR);
      });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  async function copyTo(origin_lang, target_lang){
      const docRef = await addDoc(collection(getFirestore(), "stories","lang",`${origin_lang}-${target_lang}`), {
        name: story.name,
        states: states
      });

      const docSnap = await getDoc(doc(getFirestore(),"stories","lang",`${origin_lang}-${target_lang}`,"index"));
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.ids.push(docRef.id)
        data.names.push(story.name);
        data.states.push(0);
        await setDoc(doc(getFirestore(), "stories","lang",`${origin_lang}-${target_lang}`, "index"), {
          ids: data.ids,
          names: data.names, 
          states: data.states,
        });
        alert("state copied to",origin_lang, target_lang);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
  }

  function onBlockSelect(block){
    console.log("sub edit states",subEditState, sub1EditState );
    if(sub1EditState !== -1 && subEditState !== -1){
      updateSub1Text(editState,subEditState,sub1EditState,block.id);
      updateSub1Btn(editState,subEditState,sub1EditState,block.name);
    }else if(subEditState !== -1){
      updateSubText(editState,subEditState,block.id)
      updateSubBtn(editState,subEditState,block.name)
    }else{
      updateText(editState,block.id)
      updateBtn(editState,block.name)
    }
    
  }

  return <div className="flex flex-row flex-1 w-full text-slate-500 dark:text-slate-400">
    {!true && 
        <Sidebar>
          <a href="/edit/blocks"><div className="flex flex-row border-b bg-sky-500 border-slate-200 dark:border-slate-700 items-center">
            <IoExtensionPuzzleOutline className="cursor-pointer h-6 w-6 text-white m-4"/>  
            <p className="text-white font-bold">Blocks</p>
          </div></a>
          {blocks.map((temp) => (
            <div key={temp.id} onClick={() => {setLinked(false);setCurrentBlock(temp)}}>
              <SidebarElement name={temp.name} current={currentBlock.name}></SidebarElement>
            </div>
          ))}   
        </Sidebar>
        }
        
    {!loading && 
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="lg:flex flex-row w-full items-center border-dotted border-slate-200 dark:border-slate-700 border-b p-2 justify-between lg:h-16">
        <p className="inline text-amber-500 mr-4">Level <span className="font-bold">{Math.round(storyLevel)}</span></p>
        <input type="text" defaultValue={story.name} className="inline lg:flex flex-1 no-outline bg-transparent dark:text-slate-200" ref={templateNameRef}></input>
        <div className="flex flex-1 justify-between items-center">
        <div className={`lg:flex ${editState!==-1?"text-sky-500":"text-slate-300"}`}>
          
          
          <div className="inline-flex h-10 rounded-md shadow-sm mr-2" role="group">
            <button type="button" className="bg-sky-500 text-slate-200 py-2 px-4 text-sm font-medium rounded-l-lg border-sky-500 hover:text-slate-200 border-slate-200 dark:border-slate-700 hover:bg-sky-600 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-slate-200">
              <IoAddOutline onClick={newState} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <IoReturnDownForward onClick={newStateAfter} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium rounded-r-md border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
             <IoRemove onClick={removeText} className="cursor-pointer h-6 w-6"/>
            </button>
          </div>
          <div className="inline lg:flex h-10 rounded-md shadow-sm mr-2" role="group">
            <button type="button" className="py-2 px-4 text-sm font-medium bg-grey rounded-l-lg border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdAlignHorizontalLeft onClick={()=>{updateDirection(1)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineNoteAdd onClick={()=>{updateDirection(3)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <IoExtensionPuzzleOutline onClick={()=>{updateDirection(5)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineInfo onClick={()=>{updateDirection(2)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineNoteAdd onClick={()=>{updateDirection(4)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium rounded-r-md border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-700 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdAlignHorizontalRight onClick={()=>{updateDirection(0)}} className="cursor-pointer h-6 w-6"/>
            </button>
          </div>
        </div>

      
        <div className="inline-blog flex lg:flex-1 flex-row justify-end items-center">
          {linked?
            <IoLink onClick={()=>{setIsLinkEditorOpen(true)}} className={`text-sky-500 cursor-pointer mr-4  h-6 w-6`}/>
            :<IoUnlinkOutline onClick={()=>{setIsLinkEditorOpen(true)}} className={`text-slate-300 cursor-pointer mr-4  h-6 w-6`}/>}
            
          {state===1?<IoEyeOutline onClick={()=>{setState(0)}} className="cursor-pointer mr-4 h-6 w-6"/>: <IoEyeOffOutline onClick={()=>{setState(1)}} className="cursor-pointer mr-4 text-slate-500 h-6 w-6"/>}
          <StateButton className="mr-4" size="6" onClick={generateAudioForStory} state={generateAudioState}><IoHammerOutline className="h-6 w-6 "/></StateButton>
          <StateButton className="mr-4" size="6" onClick={save} state={safeState}><IoSaveOutline className="h-6 w-6 "/></StateButton>
          <StorySettingDropDown onDelete={remove} onDuplicate={(lang)=>{copyTo(lang.origin_lang,lang.target_lang);}} origin_lang={userConfig.origin_lang} target_lang={userConfig.target_lang}></StorySettingDropDown>
        </div>
        </div>
      </div>

      <div className="flex flex-row width-word-chips items-center overflow-x-auto overflow-y-hidden border-slate-200 dark:border-slate-700 border-dotted border-b p-2 justify-start">
        {Object.keys(unknownWords).map((s,i) => (
           <WordItem key={i} w={s} r={true}></WordItem>
        ))}
        {Object.keys(words).map((s,i) => (
           <WordItem key={i} w={s} r={true}></WordItem>
        ))}
      </div>

      <div onClick={()=>{setEditState(-1);setSubEditState(-1);setSub1EditState(-1)}} className=" flex-1 flex h-full flex-col overflow-y-auto overflow-x-hidden">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {states.map((s, index) => (
                    <Draggable key={index+"draggable"} draggableId={index+"id"} index={index}>
                    {provided => ( 
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <EditorSpeechBubble 
                          edit={(editState===index)} 
                          direction={s.direction} 
                          select={(id)=>{setEditState(id);s.direction===5?setIsOpen(true):setIsOpen(false)}}
                          change={(text)=>{updateText(index,text)}}
                          id={index}
                          hash={s.hash} 
                          btn={s.btn}
                          token={s.uuid}
                          text={(s.text)} />

                        {s.deconstruct?
                          <div className="mx-8">
                            {s.deconstruct.map((subS, subIndex) => (
                              <>
                                  <EditorSpeechBubble 
                                    edit={(subEditState===subIndex&&index===editState)} 
                                    direction={subS.direction} 
                                    select={(id)=>{setBlockContext(subS.text); setSubEditState(subIndex);setEditState(index);subS.direction===5?setIsOpen(true):setIsOpen(false)}}
                                    change={(text)=>{updateSubText(index,subIndex,text)}}
                                    id={subIndex}
                                    key={`${index}-${subIndex}`}
                                    hash={subS.hash} 
                                    btn={subS.btn}
                                    token={subS.uuid}
                                    text={(subS.text)} />

                                    {subS.deconstruct?
                                      <div className="mx-16">
                                          {subS.deconstruct.map((subS1, sub1Index) => (
                                          <EditorSpeechBubble 
                                            edit={(subEditState===subIndex&&index===editState&&sub1Index===sub1EditState)} 
                                            direction={subS1.direction} 
                                            select={(id)=>{setBlockContext(subS.text); setSubEditState(subIndex);setEditState(index);setSub1EditState(sub1Index);subS1.direction===5?setIsOpen(true):setIsOpen(false)}}
                                            change={(text)=>{updateSub1Text(index,subIndex,sub1Index,text)}}
                                            id={sub1Index}
                                            key={`${index}-${subIndex}-${sub1Index}`}
                                            hash={subS1.hash} 
                                            btn={subS1.btn} 
                                            token={subS1.uuid}
                                            text={(subS1.text)} />
                                          
                                      ))}
                                        </div>
                                    :""}
                               </>

                                  
                            ))}
                            </div>
                        :""}

                      </div>
                    )}
                  </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <BlockChooser isOpen={isOpen} context={blockContext} setIsOpen={(val)=>{setIsOpen(val)}} blocks={blocks} selectedBlock={(block)=>{onBlockSelect(block)}}></BlockChooser>
      <LinkEditor linkExists={(val) => {setLinked(val);}} isOpen={isLinkEditorOpen} story={story} setIsOpen={(val)=>{setIsLinkEditorOpen(val)}}/>
    </div>
    
    }
  </div>;
}

  