import { Popover } from '@headlessui/react'
import {IoBuild, IoTrashOutline, IoSettingsOutline, IoArrowForwardOutline , IoCopyOutline } from 'react-icons/io5';
import React, {useState, useEffect} from 'react';
import { ReactComponent as FlagRU } from '../../svg/flag_ru.svg';
import { ReactComponent as FlagUS } from '../../svg/flag_us.svg';
import { ReactComponent as FlagDE } from '../../svg/flag_de.svg';
import { ReactComponent as FlagFR } from '../../svg/flag_fr.svg';
import { ReactComponent as FlagES } from '../../svg/flag_es.svg';

function Flag(props){
    switch(props.lang){
        case "en-US":
            return <FlagUS className={props.className}/>
        case "ru-RU":
            return <FlagRU className={props.className}/>
        case "de-DE":
            return <FlagDE className={props.className}/>
        case "fr-FR":
            return <FlagFR className={props.className}/>
        case "es-ES":
            return <FlagES className={props.className}/>
        default:
            return <FlagUS className={props.className}/>
    }
}


function LanguageChooserItem(props){

    function getRounded(pos, len){
        if(pos===1){
            return "";
        }else if(pos===len-1){
            return "rounded-b";
        }else{
            return "";
        }
    }

    return (
        <div className={`cursor-pointer hover:bg-slate-200 ${getRounded(props.pos,props.len)} flex flex-row items-center h-10 p-2 py-4`} onClick={() => {props.select(props.originLang,props.targetLang)}}>
            <IoCopyOutline className="h-6 w-6 text-slate-400 mx-1"/>
            <Flag lang={props.originLang} className="h-6 w-6 pl-2"/>
            <IoArrowForwardOutline className="h-4 w-4 text-slate-400 mx-1"/>
            <Flag lang={props.targetLang} className="h-6 w-6 pr-2"/>
        </div>
    )
}

export default function StorySettingDropDown({onDelete,onDuplicate,origin_lang,target_lang}) {

    let languages = [
        {origin_lang:"de-DE",target_lang:"en-US"},
        {origin_lang:"en-US",target_lang:"fr-FR"},
        {origin_lang:"en-US",target_lang:"es-ES"},
        {origin_lang:"en-US",target_lang:"ru-RU"}
    ]

  return (
    <Popover className="relative z-50">
    <Popover.Button className="focus:outline-none h-16 flex items-center">
        <IoSettingsOutline className="cursor-pointer h-6 w-6 mx-1"/>
    </Popover.Button>
    <Popover.Panel className=" absolute bg-white rounded shadow rounded bg-white right-1">
      <div className="flex flex-col">
        <div className={`cursor-pointer justify-between hover:bg-slate-200  flex flex-row items-center h-10 p-2 py-4`} onClick={onDelete}>
            <IoTrashOutline className="cursor-pointer h-6 w-6 text-red-500 mr-2"/>
            <p className="text-red-500">Remove</p>
        </div>
        <hr></hr>
        {languages.map((temp,index) => (
            <div key={index}>
                {((temp.origin_lang!==origin_lang||temp.target_lang!==target_lang))?
                    <>
                        <LanguageChooserItem pos={index} select={(a,b)=>{onDuplicate({origin_lang:a,target_lang:b})}} len={languages.length} originLang={temp.origin_lang} targetLang={temp.target_lang} state={temp.state} className="cursor-pointer"/>
                        {index<languages.length-1?<hr></hr>:""}
                    </>
                :""}
            </div>
        ))}     
      </div>
    </Popover.Panel>
    </Popover>
  )
}