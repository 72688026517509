import { Popover } from '@headlessui/react'
import {IoCheckmarkCircleSharp} from 'react-icons/io5';
import React, {useState, useEffect} from 'react';
import { doc, onSnapshot , getFirestore, arrayRemove } from "firebase/firestore"; 
import { getCategoryColor,getWordByRank, wRank } from "../../grammar";
import { copyTextToClipboard } from './clipboard';
import { useAuth } from '../../auth/AuthContext';

function WordItem({word}){
  return (
      <div onClick={()=>{copyTextToClipboard(word.rus)}} className={`whitespace-nowrap flex flex-row cursor-pointer align-center w-min text-slate rounded-full content-center bg-${getCategoryColor(word.type)}-300 p-2 mx-2 my-1`}>
        <div className="font-bold mr-2">{word.number}</div>{word.rus}<div className="italic text-slate-600 ml-2">{word.eng}</div>
        {word.used?<IoCheckmarkCircleSharp className="h-6 w-6 ml-1"></IoCheckmarkCircleSharp>:""}
    </div>
  )
}

export default function Words() {
  const [words, setWords] = useState(wRank);
  const {userConfig} = useAuth();

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), async (doc) => {
        let data = doc.data();
        let wArr = [];
        
        if(data){
          Object.keys(data.vocab).map((s) => {wArr = [...wArr,...data.vocab[s]]});
          for(let i = 0; i < words.length; i++){
            words[i]["used"] = false;
            for(let j = 0; j < wArr.length; j++){
              if(words[i].number === wArr[j]){
                words[i]["used"] = true;
              }
            }
          }
          setWords([...words]);
        }
    });
  },[userConfig]);

  return (
    <div className={`flex flex-none flex-col w-60 border-slate-200 border-r overflow-y-scroll overflow-x-`}>
        {words.map((temp,i) => (
                   <WordItem key={i} word={temp}></WordItem>
              ))}     
    </div>
  )
}