import React, { useRef, useState, useEffect } from "react";
import ArrayEditor from "./ArrayEditor";
import {
  setDoc,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
  addDoc,
	collection
} from "firebase/firestore";
import { getSample } from "./grammar";
import { IoSaveOutline } from 'react-icons/io5';
import { ReactComponent as Spinner } from '../../svg/spinner.svg';
import GeneratorDropdown from "./GeneratorDropdown";
import { useAuth } from "../../auth/AuthContext";

function Selector({ a, name }) {
  return (
    <div
      className={`rounded-full bg-${
        a ? "lime" : "slate"
      }-300	p-2 mr-2 cursor-pointer flex flex-row content-center`}
    >
      <p className="mx-2 whitespace-nowrap">{name}</p>
    </div>
  );
}

const cases = [
  {s:"N",n:"Nom."},
  {s:"G",n:"Gen."},
  {s:"D",n:"Dat."},
  {s:"A",n:"Acc."},
  {s:"I",n:"Ins."},
  {s:"P",n:"Pre."}
];

const nounPhrases = [
  { n: "adj noun", p: ["AN", "NN"] },
  { n: "num adj noun", p: ["U", "AN", "NN"] },
  { n: "noun", p: ["NN"] },
  { n: "pers.", p: ["PN"] },
  { n: "poss. adj noun", p: ["PP", "AN", "NN"] },
  { n: "poss. noun", p: ["PP", "NN"] },
  { n: "dem.", p: ["DM"] },
  { n: "dem. noun", p: ["DM","NN"] },
  { n: "int. pers", p: ["IP","PN"] },
  { n: "int. dem.", p: ["IP","DM"] },
];

const verbPhrases = [
  { n: "verb", p: ["VN"] },
  { n: "verb infini.", p: ["VN", "VI"] },
  { n: "verb NP", p: ["VN", "NP1"] },
  { n: "verb pre. NP", p: ["VN","PRP","NP1"] },
  { n: "pre. NP", p: ["PRP","NP1"] },
];

const nounPhrases1 = [
  { n: "adj noun", p: ["AN1", "NN1"] },
  { n: "num adj noun", p: ["U", "AN1", "NN1"] },
  { n: "noun", p: ["NN1"] },
  { n: "pers.", p: ["PN1"] },
  { n: "poss. adj noun", p: ["PP1", "AN1", "NN1"] },
  { n: "poss. noun", p: ["PP1", "NN1"] },
  { n: "dem.", p: ["DM1"] },
  { n: "dem. noun", p: ["DM1","NN1"] },
];

const times = ["Past","Present","Future"];

export function Generator({ gId,select,change,allVocab }) {
  const generatorNameRef = useRef(null);
  const [nPH, setNP] = useState(new Set());
  const [nP1H, setNP1] = useState(new Set());
  const [vPH, setVP] = useState(new Set());
  const [vocab, setVocab] = useState({});
  const [types, setTypes] = useState([]);
  const [samples, setSamples] = useState([]);
	const [safeState, setSafeState] = useState(false);
	const [time, setTime] = useState("Present");
  const [gCase, setCase] = useState("N");
  const [sId, setId] = useState(gId);
  const [allV, setAllV] = useState(allVocab);
  const {userConfig} = useAuth();

  useEffect(() => {
		onSnapshot(doc(getFirestore(),"cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,gId), (doc) =>{
			let data = doc.data();
			if(data){
        
				setNP(new Set(data.np));
				setVP(new Set(data.vp));
        
        if(data.np1){
          setNP1(new Set(data.np1));
        }
        if(data.case){
          setCase(data.case);
        }
				setTime(data.time);
        if(data.name && generatorNameRef.current){
          generatorNameRef.current.value = data.name;
        }

        if(allVocab)
          setAllV(allVocab);

        setVocab(data.vocab);
        updateTypes(data.np,data.vp,data.np1?data.np1:[],data.vocab);
				updateSample(data.np,data.vp,data.np1?data.np1:[],data.vocab,data.time,data.case);
        setId(gId);
			}
		});
	},[gId,allVocab,userConfig]);


	function getRequredVocabs(nP,vP,nP1){
    let vocabs = new Set([]);
    nP.forEach((item) => {
      JSON.parse(item).p.forEach((word) => {
        vocabs.add(word);
      })
    });
    vP.forEach((item) => {
      JSON.parse(item).p.forEach((word) => {
        if(word === "NP1"){
          nP1.forEach((item1) => {
            JSON.parse(item1).p.forEach((item2) => {
              vocabs.add(item2);
            });
            
          });
        }else{
          vocabs.add(word)
        }
      })
    });
    return vocabs;
  }

	function getStructure(nPs,vPs,nP1){
		return { S: [["NP", "VP"]], NP: nPs, VP: vPs, NP1: nP1 };
	}

  function getRawPhrases(p){
    let ps = [];
    p.forEach((item) => {
      ps.push(JSON.parse(item).p);
    });
		return ps;
  }

	function updateVocabs(reqVocabs,v){
		let new_vocab = {};
		reqVocabs.forEach((item) => {
			if (v[item]) {
				new_vocab[item] = JSON.parse(JSON.stringify(v[item]));
			} else {
				new_vocab[item] = [];
			}
		});
		setVocab(new_vocab);
	}

  function hasVocabforSamples(vocabs,v) {
    let ret = true
    vocabs.forEach((item) => {
      if (v[item]) {
        if (v[item].length < 1) {
          ret = false;
        }
      } else {
        ret = false;
      }
    });
    return ret;
  }

  function updateTypes(nP,vP,nP1,v){
    let reqVocabs = getRequredVocabs(nP,vP,nP1);
    updateVocabs(reqVocabs,v);
    setTypes(Array.from(reqVocabs));
  }

  function updateSample(nP,vP,nPS1,vocab,t,c){
    let reqVocabs = getRequredVocabs(nP,vP,nPS1);
  
		let nPs = getRawPhrases(nP);
		let vPs = getRawPhrases(vP);
    let nP1 = getRawPhrases(nPS1);

    let structure = getStructure(nPs,vPs,nP1);

  
    if (nPs.length > 0 && vPs.length > 0) {
      if (hasVocabforSamples(reqVocabs,vocab)) {
        let s = [];
        for (let i = 0; i < 5; i++) {
          let sample = getSample(structure, vocab,t,c);

          s.push(sample);
        }
        setSamples(s);
      } else {
        setSamples([]);
      }
    }
  }

	async function newCFG(name){
		const docRef = await addDoc(collection(getFirestore(), "cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`), {
			np: Array.from(nPH),
			vp: Array.from(vPH),
      vp1: Array.from(nP1H),
      case: gCase,
			vocab: vocab,
			time: time,
			name: name
		});

		const docSnap = await getDoc(doc(getFirestore(),"cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.ids.push(docRef.id);
        data.names.push(name);
        await setDoc(doc(getFirestore(), "cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
          ids: data.ids,
          names: data.names, 
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
	}

  async function save(){
		setSafeState(true);
		await setDoc(doc(getFirestore(), "cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, sId), {
      np: Array.from(nPH),
			vp: Array.from(vPH),
      np1: Array.from(nP1H),
      case: gCase,
			vocab: vocab,
			time: time,
			name: generatorNameRef.current.value
    });
		const docSnap = await getDoc(doc(getFirestore(),"cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
		if (docSnap.exists()) {
      let data = docSnap.data();
      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === sId){
            data.names[i] = generatorNameRef.current.value;
          }
      };

      await setDoc(doc(getFirestore(), "cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
      });
      setSafeState(false)
    }
	}

  return (
    <div className="flex flex-col bg-slate-100 rounded mx-5 my-2 p-2" onClick={(e)=>{e.stopPropagation();select(sId)}}>
			<div className="flex flex-row items-center">
			<GeneratorDropdown newCFG={newCFG} change={(id)=>{setId(id);change(id)}}></GeneratorDropdown>
			<input
        type="text"
        defaultValue="Name"
        className="flex-1 no-outline bg-slate-100 font-bold text-slate-600"
        ref={generatorNameRef}
      ></input>
			
			
			<div className="flex flex-row">
        {times.map((a, i) => (
          <div
            key={i}
            onClick={() => {
              setTime(a);
              updateSample(nPH,vPH,nP1H,vocab,a,gCase);
            }}
          >
								<div
					className={`rounded-full bg-red-${
						a === time ? "600" : "200"
					}	p-2 mr-2 cursor-pointer flex flex-row content-center`}
				>
					<p className="mx-2 whitespace-nowrap">{a}</p>
				</div>
          </div>
        ))}
      </div>
			{safeState?<p className="animate-spin bg-slate-500 rounded-full border-2 ml-4 mr-4 border-slate-400 text-white font-bold"><Spinner className="spinner h-6 w-6 "/></p>:<IoSaveOutline onClick={save} className="cursor-pointer h-6 w-6 text-slate-500 mx-4"/>}
			</div>
  
      <div className="flex flex-row mb-2 mt-2 width-word-chips-array overflow-x-scroll">
        {nounPhrases.map((a, i) => (
          <div
            key={i}
            onClick={() => {
              if(nPH.has(JSON.stringify(nounPhrases[i]))){
                nPH.delete(JSON.stringify(nounPhrases[i]));
              }else{
                nPH.add(JSON.stringify(nounPhrases[i]));
              }
              setNP(new Set(Array.from(nPH)));
              updateSample(new Set(Array.from(nPH)),vPH,nP1H,vocab,time,gCase);
              updateTypes(new Set(Array.from(nPH)),vPH,nP1H,vocab);
            }}
          >
            <Selector a={nPH.has(JSON.stringify(nounPhrases[i]))} name={nounPhrases[i].n}></Selector>
          </div>
        ))}
      </div>

      <div className="flex flex-row width-word-chips-array overflow-x-scroll">
        {verbPhrases.map((a, i) => (
          <div
            key={i}
            onClick={() => {
              if(vPH.has(JSON.stringify(verbPhrases[i]))){
                vPH.delete(JSON.stringify(verbPhrases[i]));
              }else{
                vPH.add(JSON.stringify(verbPhrases[i]));
              }
              setVP(new Set(Array.from(vPH)));
              updateSample(nPH,new Set(Array.from(vPH)),nP1H,vocab,time,gCase);
              updateTypes(nPH,new Set(Array.from(vPH)),nP1H,vocab);
            }}
          >
            <Selector a={vPH.has(JSON.stringify(verbPhrases[i]))} name={verbPhrases[i].n}></Selector>
          </div>
        ))}
        {cases.map((a, i) => (
          <div
            key={i}
            onClick={() => {
              setCase(a.s);
              updateSample(nPH,vPH,nP1H,vocab,time,a.s);
              updateTypes(nPH,vPH,nP1H,vocab);
            }}
          >
								<div
					className={`rounded-full bg-red-${
						a.s === gCase ? "600" : "200"
					}	p-2 mr-2 cursor-pointer flex flex-row content-center`}
				>
					<p className="mx-2 whitespace-nowrap">{a.n}</p>
				</div>
          </div>
        ))}
      </div>

      <div className="flex flex-row mb-2 mt-2 width-word-chips-array overflow-x-scroll">
        {nounPhrases1.map((a, i) => (
          <div
            key={i}
            onClick={() => {
              if(nP1H.has(JSON.stringify(nounPhrases1[i]))){
                nP1H.delete(JSON.stringify(nounPhrases1[i]));
              }else{
                nP1H.add(JSON.stringify(nounPhrases1[i]));
              }
              setNP1(new Set(Array.from(nP1H)));
              updateSample(nPH,vPH,new Set(Array.from(nP1H)),vocab,time,gCase);
              updateTypes(nPH,vPH,new Set(Array.from(nP1H)),vocab);
            }}
          >
            <Selector a={nP1H.has(JSON.stringify(nounPhrases1[i]))} name={nounPhrases1[i].n}></Selector>
          </div>
        ))}
      </div>

      <div className="flex flex-row width-word-chips-array overflow-x-scroll">
        {types.map((item, i) => (
          <div key={i} className="flex flex-col">
            <div>{item}</div>
            <ArrayEditor
              allVocab={allV}
              arr={vocab[item]}
              onChange={(items) => {
                let v = JSON.parse(JSON.stringify(vocab));
                v[item] = items;
                setVocab(v);
                updateSample(nPH,vPH,nP1H,v,time,gCase);
              }}
            ></ArrayEditor>
          </div>
        ))}
      </div>

      <div className="flex mt-2 flex-row width-word-chips-array overflow-x-scroll">
        {samples.map((item, i) => (
          <div
            key={i}
            className={`rounded-full bg-slate-300	p-2 mr-2 cursor-pointer flex flex-row content-center`}
          >
            <p className="mx-2 whitespace-nowrap">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
