import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import { useAuth } from '../../auth/AuthContext';
import { IoArrowBackOutline, IoBookOutline } from 'react-icons/io5';
import EditorNavbar from '../EditorNavbar';
import { LanguageDropDown } from '../../navbar/LanguageDropDown';
import { useNavigate } from 'react-router';
import { ResponsiveBar } from '@nivo/bar'
import { useWords } from './WordContext';
import WordStatItem from './WordStatItem';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export default function WordDashboard() {

    const [currentStory, setCurrentStory] = useState();
    const [words, setWords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [wordsStatistics,setWordStatistics] = useState({});
    const {getCategoryColor, getWordByRank, allWords} = useWords();
    const [allWd, setAllWords] = useState([]);
    const [highestVal, setHighestVal] = useState(0);
    const [topUnused, setTopUnused] = useState([]);
    const [progress, setProgress] = useState([]);
    const [topOneUsed, setTopOneUsed] = useState([]);

    const navigate = useNavigate();

    const {userConfig} = useAuth();

    function getWordsStats(words){
        let wordCount = {};
        Object.keys(words).forEach((key) => {
            words[key].forEach((word) => {
                if(wordCount[word.R]){
                    wordCount[word.R] = wordCount[word.R] + word.N
                }else{
                    wordCount[word.R] = word.N;
                }
            })
        });
        return wordCount;
    }

    function setHighest(words){
        let h = 0;
        Object.values(words).forEach((val) => {
            if(val > h){
                h = val;
            }
        });
        return h;
    }

    function top20Unused(stats){
        let ret = [];
        allWords.forEach((wordObj) => {
            if(stats[wordObj.R]){

            }else{
                if(ret.length < 50){
                    ret.push(wordObj);
                }
                
            }
        });
        return ret;
    }

    function top20OneUse(stats){
        let ret = [];
        allWords.forEach((wordObj) => {
            if(stats[wordObj.R]){
                if(stats[wordObj.R] === 1){
                    if(ret.length < 50){
                        ret.push(wordObj);
                    }
                }
                
            }
        });
        return ret;
    }

    function getProgress(allW,st){
        let allSize = allW.length;
        let done = 0;
        allW.forEach((t) => {
            done += (st[t.R]?1:0);
        })
        return [((done/allSize)*100).toFixed(2),done,allSize];
    }

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
        let tempStories = [];
        let data = doc.data();

        if(!data.hasOwnProperty("ids")){
          setLoading(false);
          return;
        }
        
        setWords(data.vocab);
        let ws = getWordsStats(data.vocab);
        let h = setHighest(ws);
        let un = top20Unused(ws);
        setProgress(getProgress(allWords,ws));
        setTopUnused(un);
        setTopOneUsed(top20OneUse(ws));
        setHighestVal(h);
        setWordStatistics(ws);
        setAllWords(allWords);
        setLoading(false);
    });
  }, [userConfig]);

  return (
    <div className="flex flex-col h-screen w-full dark:bg-slate-900 dark:text-slate-200 text-slate-500">
        <div className="flex flex-col h-full w-full flex-1 overflow-hidden">
            <div className="flex flex-row w-full h-16  border-b border-slate-200 dark:border-slate-700 justify-between items-center">
                <div className="flex flex-row mr-4 items-center">
                    <IoArrowBackOutline onClick={()=>{navigate('/learn');}} className="cursor-pointer  h-6 w-6 ml-6"/>
                    <IoBookOutline className="cursor-pointer h-6 w-6  m-4"/>  
                    <p className=" h-6">Story Dashboard</p>
                    <LanguageDropDown></LanguageDropDown>
                </div>
                <div className="flex flex-row">
                    <div className="pr-2 font-bold">Progress:</div>
                    <div className="pr-2">{progress[0]}% </div>
                    <div className="font-bold text-lime-500">{progress[1]}</div>
                    <div className="pr-2">/{progress[2]}</div>
                </div>
            </div>
        {!loading && 
            <div className="flex flex-row flex-1 overflow-hidden">
                <div className="overflow-auto">
                    {allWd.map((t,i) => (
                        <WordStatItem
                            key={i}
                            max={highestVal} 
                            usage={wordsStatistics[t.R]?wordsStatistics[t.R]:0}
                            wordObj={t}/>
                    ))}
                </div>
                <div className="flex overflow-auto">
                    <div className="flex flex-col">
                        {topUnused.map((t,i) => (
                            <div key={i} className="p-1 flex flex-row">
                                <div className="w-12 font-bold">{t.RA}</div>
                                <div className={`w-32 font-bold text-${getCategoryColor(t.T)}-500`}>{t.R}</div>
                                <div className="w-32">{t.E}</div>
                                <div className="w-32">{t.T}</div>
                            </div>
                        ))}
                    </div>
                   
                </div>
                <div className="flex overflow-auto">
                    <div className="flex flex-col">
                        {topOneUsed.map((t,i) => (
                            <div key={i} className="p-1 flex flex-row">
                                <div className="w-12 font-bold">{t.RA}</div>
                                <div className={`w-32 font-bold text-${getCategoryColor(t.T)}-500`}>{t.R}</div>
                                <div className="w-32">{t.E}</div>
                                <div className="w-32">{t.T}</div>
                            </div>
                        ))}
                    </div>
                   
                </div>
            </div>
        } 
        </div>
    </div>
  );
}
