import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import { IoArrowBackOutline, IoBookOutline } from 'react-icons/io5';
import EditorNavbar from '../EditorNavbar';
import { LanguageDropDown } from '../../navbar/LanguageDropDown';
import { useNavigate } from 'react-router';
import { ResponsiveBar } from '@nivo/bar'
import { useWords } from './WordContext';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export default function WordStatItem({max,usage, wordObj}) {

    let [hover, setHover] = useState(false);
    let {getCategoryColor} = useWords();

    function getHeight(r){
        if(wordObj){
            return 200*((usage>20?20:usage)/(max>20?20:max));
        } else {
            return 0;
        }
    }

  return (
    <div className="ws-container">
        <div style={{ width: `200px`}}
            className={`relative word-stat-item dark:bg-slate-900 bg-${getCategoryColor(wordObj.T)}-100`}>
            <div className="ws-words bg-transparent">{wordObj.R} {usage}</div>
            <div style={{ width: `${getHeight()}px` }}  className={`absolute left-0 right-0 bg-${usage > 0?getCategoryColor(wordObj.T):"slate"}-500 h-full`}>
            
            </div>
            
        </div>
    </div>
    
  );
}
