import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMenu, AiOutlineArrowLeft } from 'react-icons/ai';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function Sidebar({width=60, children}) {
  /**
   * opens popup to add document in firebase
   */

  let [small, setSmall] = useState(false);
 
  return (
    <div className={`flex flex-none h-full flex-col ${small?'w-12':'w-'+width} border-slate-200 border-dotted dark:border-slate-700 border-r overflow-y-scroll`}>
        {small?<div/>:<div>{children}</div>}
    </div>
  );
}

Sidebar.propTypes = {
    children: PropTypes.node,
 };
