import React, {useRef, useState, useEffect} from 'react';
import { setDoc, doc, getDoc, onSnapshot , getFirestore, deleteDoc  } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {IoAddOutline,
        IoRemove,
        IoSaveOutline,
        IoHammerOutline,
        IoLogoMarkdown,
        IoExtensionPuzzleOutline,
        IoAppsOutline,
        IoChatbubbleEllipsesOutline,
        IoTrashOutline} from 'react-icons/io5';
import {MdAlignHorizontalLeft,
        MdAlignHorizontalRight,
        MdOutlineInfo,
        MdOutlineNoteAdd } from "react-icons/md";
import { BlockChooser } from '../BlockChooser';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditorSpeechBubble } from '../EditorSpeechBubble';
import sparkMd5 from 'spark-md5';
import { ReactComponent as Spinner } from '../../svg/spinner.svg';
import {STATES, StateButton} from '../../util/StateButton';
import { useAuth } from '../../auth/AuthContext';
import remarkGfm from 'remark-gfm'

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export default function Block({block}) {
  const functions = getFunctions();
  const generateBlockAudio = httpsCallable(functions, 'generateBlockAudio');

  const {userConfig} = useAuth();

  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [editState, setEditState] = useState(-1);
  const [subEditState, setSubEditState] = useState(-1);
  const [generateAudioState, setGenerateAudioState] = useState(STATES.STATE_NORMAL);
  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const templateNameRef = useRef(null);
  const [words, setWords] = useState({});

  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,block.id+""), async (doc) => {
        let data = doc.data();
        if(data){
          if(data.states){
            setStates(data.states);
          } else {
            setStates([]);
          }
        }
        setLoading(false);
    });
    if(block.name && templateNameRef.current){
      templateNameRef.current.value = block.name;
    }
    return () => {
      setStates([]);
    }
  },[block,userConfig]);

  useEffect(() => {
    wordAnalytics();
  },[states]) // <-- here put the parameter to listen

  async function save(){
    setSafeState(STATES.STATE_PROCESS);
    await setDoc(doc(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, block.id), {
      states: states,
    });

    const docSnap = await getDoc(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === block.id){
            data.names[i] = templateNameRef.current.value;
          }
      };
      await setDoc(doc(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
        lastChange: block.le,
      });
      setSafeState(STATES.STATE_NORMAL)
    }
  }

  async function remove(){
    const docSnap = await getDoc(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();

      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === block.id){
            data.ids.splice(i,1);
            data.names.splice(i,1);
          }
      };

      await deleteDoc(doc(getFirestore(), "blocks", block.id));
      await setDoc(doc(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names
      });
    }
  }

  function newState(){
    if(editState !== -1){
      let tempState = {
        text: 'sample text',
        direction: 0,
        hash: sparkMd5.hash('sample text')
      }

      if(states[editState].deconstruct){
        states[editState].deconstruct = [...states[editState].deconstruct,tempState];
      }else{
        states[editState].deconstruct = [tempState];
      }
      
      
      let t1 = [...states];
      setStates(t1);
    }else{
      let tempState = {
        text: 'sample text',
        direction: 0,
        hash: sparkMd5.hash('sample text')
      }
  
      let t1 = [...states,tempState];
      setStates(t1);
    }
    
  }

  function removeText(){
    if(subEditState !== -1){
      let t1 = [...states];
      t1[editState].deconstruct.splice(subEditState, 1);
      if(t1[editState].deconstruct.length === 0){
        delete t1[editState].deconstruct
      }
      setStates(t1);
      setSubEditState(-1);
    }else{
      let t1 = [...states];
      t1.splice(editState, 1);
      setStates(t1);
      setEditState(-1);
    }
    
  }

  function getHashArray(text){
    let retArr = [];
    let spl = text.split("`");
        spl.forEach((st,i) => {
          if(i%2===1){
            retArr.push(sparkMd5.hash(st));
          }
        });
    return retArr;
  }

  function getTargetLangArray(text){
    let retArr = [];
    let spl = text.split("`");
        spl.forEach((st,i) => {
          if(i%2===1){
            retArr.push(st);
          }
        });
    return retArr;
  }

  function updateText(id,text){
    let t1 = [...states];
    t1[id].text = text;
    if(t1[id].direction===6){
      t1[id].hash = getHashArray(text);
      t1[id].fragments = getTargetLangArray(text);
    }else{
      t1[id].hash = sparkMd5.hash(text);
    }
    setStates(t1);
  }

  function updateSubText(id,subId,text){
    let t1 = [...states];
    t1[id].deconstruct[subId].text = text;
    t1[id].deconstruct[subId].hash = sparkMd5.hash(text);
    setStates(t1);
  }

  function onGenerated(st){
    setStates([...st]);
    setEditState(-1);
  }

  function generate(){
    setIsOpen(true);
  }

  function updateDirection(direction){
    if(subEditState !== -1){
      console.log("update directions", direction);
      let t1 = [...states];
      t1[editState].deconstruct[subEditState].direction = direction;
      setStates(t1);
    }else{
      console.log("update directions", direction);
      let t1 = [...states];
      t1[editState].direction = direction;
      setStates(t1);
    }
    
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const t1 = reorder(
      states,
      result.source.index,
      result.destination.index
    );
    setEditState(result.destination.index);
    setStates(t1);
    
  }

  function wordAnalytics(){
    let words = {};
    states.forEach((s,index) =>{
      if(s.direction === 0 || s.direction === 1){
        
        let sentenceWords = s.text.split(" ");
        sentenceWords.forEach((word) => {
          word = word.replace(".","");
          word = word.replace("!","");
          word = word.replace("?","");
          word = word.replace(",","");
          word = word.replace("-","");
          word = word.replace("  ","");
          word = word.toLocaleLowerCase();
          if(word){
            if(words[word]){
              words[word] = words[word] + 1;
            }else{
              words[word] = 1;
            }
          }
        });
      }
    });
    setWords(words);
  }

  function generateAudioForBlock(){
    let config = {id:block.id,origin_lang:userConfig.origin_lang,target_lang:userConfig.target_lang};
    console.log(config);
    setGenerateAudioState(STATES.STATE_PROCESS);
    generateBlockAudio(config)
      .then(() => {
        setGenerateAudioState(STATES.STATE_NORMAL);
      })
      .catch((e) => {
        setGenerateAudioState(STATES.STATE_ERROR);
      });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  return <div className="flex flex-1 w-full">
    {!loading && 
    <div className="flex flex-col h-full w-full">
      <div className="lg:flex h-16 flex-row w-full items-center border-slate-200 dark:border-slate-700 border-dotted border-b p-2 justify-between dark:border-slate-700">
        <input type="text" defaultValue={block.name} className="flex flex-1 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4" ref={templateNameRef}></input>
        <div className={`flex ${editState!==-1?"text-sky-500":"text-slate-300"}`}>
            <div className="inline-flex rounded-md shadow-sm mr-2" role="group">
              <button type="button" className="bg-sky-500 text-slate-200 py-2 px-4 text-sm font-medium rounded-l-lg border-sky-500 hover:text-slate-200 border-slate-200 dark:border-slate-700 hover:bg-sky-500 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-slate-200">
                <IoAddOutline onClick={newState} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium rounded-r-md border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800  focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <IoRemove onClick={removeText} className="cursor-pointer h-6 w-6"/>
              </button>
            </div>
            <div className="inline-flex rounded-md shadow-sm mr-2" role="group">
              <button type="button" className="py-2 px-4 text-sm font-medium rounded-l-lg border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <MdAlignHorizontalLeft onClick={()=>{updateDirection(1)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <MdOutlineNoteAdd onClick={()=>{updateDirection(3)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <MdOutlineInfo onClick={()=>{updateDirection(2)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <IoAppsOutline onClick={()=>{updateDirection(7)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <IoExtensionPuzzleOutline onClick={()=>{updateDirection(5)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <IoLogoMarkdown onClick={()=>{updateDirection(6)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <MdOutlineNoteAdd onClick={()=>{updateDirection(4)}} className="cursor-pointer h-6 w-6"/>
              </button>
              <button type="button" className="py-2 px-4 text-sm font-medium rounded-r-md border border-slate-200 dark:border-slate-700 hover:bg-slate-100 dark:hover:bg-slate-800 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
                <MdAlignHorizontalRight onClick={()=>{updateDirection(0)}} className="cursor-pointer h-6 w-6"/>
              </button>
            </div>
          </div>
        <div className="flex flex-row justify-end items-center">
            <IoChatbubbleEllipsesOutline className="mr-4 h-6 w-6" onClick={generate}/>
            <StateButton className="mr-4" size="6" onClick={generateAudioForBlock} state={generateAudioState}><IoHammerOutline className="h-6 w-6 "/></StateButton>
            <StateButton className="mr-4" size="6" onClick={save} state={safeState}><IoSaveOutline className="h-6 w-6 "/></StateButton>
            <IoTrashOutline onClick={remove} className="cursor-pointer h-6 w-6 text-red-500 mr-2"/>
          </div>
      </div>
      {Object.keys(words).length > 0?<div className="flex flex-row width-word-chips items-center overflow-x-auto overflow-y-hidden border-slate-200 dark:border-slate-700 border-b p-2 justify-start">
        
        {Object.keys(words).map((s,i) => (
          <span key={i} className="relative inline-block">
            <div className="rounded-full bg-amber-300	p-2 mr-2 cursor-pointer dark:text-amber-900">{s}</div>
            <span className="absolute top-2 right-3 inline-flex items-center justify-center px-1 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-amber-600 rounded-full">{words[s]}</span>
          </span>
        ))}
      </div>:""}
      

      <div onClick={()=>{setEditState(-1);setSubEditState(-1)}} className=" flex-1 flex h-full flex-col overflow-y-auto overflow-x-hidden">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {states.map((s, index) => (
                    <Draggable key={index+"id"} draggableId={index+"id"} index={index}>
                    {provided => ( 
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <EditorSpeechBubble 
                          edit={(editState===index)} 
                          direction={s.direction} 
                          select={(id)=>{setEditState(id);}}
                          change={(text)=>{updateText(index,text)}}
                          id={index}
                          fragments={s.fragments}
                          onBlock={(blockId) => {console.log("load new bl",blockId)}}
                          hash={s.hash} 
                          token={s.uuid}
                          text={(s.text)} />
                        {s.deconstruct?
                          <div className="mx-8">
                            {s.deconstruct.map((subS, subIndex) => (
                            
                                  <EditorSpeechBubble 
                                    edit={(subEditState===subIndex&&index===editState)} 
                                    direction={subS.direction} 
                                    select={(id)=>{setSubEditState(subIndex);setEditState(index);}}
                                    change={(text)=>{updateSubText(index,subIndex,text)}}
                                    id={subIndex}
                                    key={`${index}-${subIndex}`}
                                    hash={subS.hash} 
                                    token={subS.uuid}
                                    text={(subS.text)} />
                            
                        
                            ))}</div>
                        :""}
                      </div>
                    )}
                  </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <BlockChooser isOpen={isOpen} setIsOpen={(val)=>{setIsOpen(val)}} generator={true} selectedBlock={(block)=>{onGenerated(block)}}></BlockChooser>
    </div>
    }
  </div>;
}

  