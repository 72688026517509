/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from './AuthContext';
import {useNavigate } from 'react-router-dom';
import {FcGoogle} from 'react-icons/fc';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { db } from '../firebase';
import { StateButton, STATES } from '../util/StateButton';
import { getFunctions, httpsCallable } from "firebase/functions";
import { onSnapshot,doc } from 'firebase/firestore';

/**
 * component with all signup functionality
 * @return {object} jsx sign in component
 */
export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const {signup, signInWithGoogle, currentUser, reloadUserData} = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [googleLoginState, setGoogleLoginState] = useState(STATES.STATE_NORMAL);
  const [loginState, setLoginState] = useState(STATES.STATE_NORMAL);
  const navigate = useNavigate();

  const functions = getFunctions();
  const initNewUser = httpsCallable(functions, 'createUser');

  useEffect(() => { 
    if(currentUser){
      //navigate("/learn");
    }
  },[]);
  

  async function handleSubmit(e) {
    e.preventDefault();
    if (!passwordRef.current.value) {
      return setError('Password not provided');
    }

    if (!emailRef.current.value) {
      return setError('email not provided');
    }

    try {
      setLoginState(STATES.STATE_PROCESS);
      setError('');
      setLoading(true);
      let credential = await signup(emailRef.current.value, passwordRef.current.value);
      const unSub = onSnapshot(doc(db,"users",credential.user.uid),async (snap)=>{
        if(snap.data()){
          console.log(snap.data());
          unSub();
          await reloadUserData(credential.user.uid);
          window.location.href = "/learn";
        }
      });

    } catch {
      setError('No sign up possible');
    }

    setLoading(false);
  }

  async function googleLogin(){
    try{
      console.log("google login");
      setGoogleLoginState(STATES.STATE_PROCESS);
      let credential = await signInWithGoogle();
      console.log(credential,credential.user.uid);
      const unSub = onSnapshot(doc(db,"users",credential.user.uid),async (snap)=>{
        if(snap.data()){
          console.log(snap.data());
          unSub();
          await reloadUserData(credential.user.uid);
          window.location.href = "/learn";
        }
      });

    }catch(e){
      navigate("/signIn");
      setError('No sign up possible');
      console.log(e)
    } 
  }

  return (
    <div className="min-h-screen flex items-center dark:bg-slate-900  dark:text-slate-200 justify-center bg-slate-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="text-center text-5xl font-extrabold text-sky-500">Sign Up</h2>
        <h2 className="text-center text-xl">for 7 day free trial</h2>
        <div className="rounded-md">
          <form onSubmit={handleSubmit}>
            <p className="text-center text-sm text-red-600"> {error}
            </p>
            <div>
              <label htmlFor="email-address" className='text-slate-700 dark:text-slate-200'>Email address</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                        placeholder-slate-500 text-slate-500 rounded-full focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="email@mail.com"
                ref={emailRef}/>
            </div>
            <div className="mt-2">
              <label htmlFor="password" className="mt-3 text-slate-700 dark:text-slate-200">Password</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                placeholder-slate-500 text-slate-500 rounded-full focus:outline-none
                focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"
                id="password"
                name="password"
                type="password"
                placeholder="********"
                ref={passwordRef}/>
            </div>
            <div className="flex justify-between mt-4 dark:text-slate-200">
              <p>by signing up you agree to our</p>
              <a href="https://www.iubenda.com/terms-and-conditions/46179223" rel="noreferrer"  target="_blank" className="font-bold text-sky-700 dark:text-sky-300" title="Privacy Policy ">Terms and Conditions</a>
            </div>
            <div className="flex justify-between mt-2">
             <div onClick={googleLogin} className="cursor-pointer shadow flex flex-row justify-center items-center rounded-full bg-white text-center text-slate py-3 px-3 mr-3"><StateButton state={googleLoginState}><FcGoogle className="h-6 w-6"/></StateButton></div>
              <button disabled={loading} className="flex flex-row justify-center items-center rounded-full block w-full bg-sky-500 text-center text-white py-3"><StateButton state={loginState}></StateButton> Sign Up</button>
            </div>
            
          </form> 
        </div>
        
        <div className="flex justify-between">
          <a href="/signIn" className="font-medium text-sky-500 hover:text-sky-300">Sign In</a>
        </div>
      </div>
    </div>
  );
}
