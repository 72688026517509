/* eslint-disable react/prop-types */
import React from 'react';
import {Navigate , Route} from 'react-router-dom';
import {useAuth} from './AuthContext';
import { useEffect, useState } from 'react';
import { doc, onSnapshot , getFirestore  } from "firebase/firestore";

/**
 * wrapper for routes
 * @return {object} wrapper to makes routes private
 */
export default function PrivateRoute({ children }) {
  const {currentUser} = useAuth();
  return currentUser ? children : <Navigate  to="/signIn"/>;
}

/**
 * wrapper for routes
 * @return {object} wrapper to makes routes only for admin usable
 */
 export function AdminRoute({ children }) {
  const {currentUserState} = useAuth();
  let admin = false;

  if(currentUserState){
    if(currentUserState.hasOwnProperty("admin")){
      admin = currentUserState.admin;
    }
  }

  return admin ? children : <Navigate  to="/signIn"/>;
}