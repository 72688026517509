import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './util/index.css';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import { AuthProvider } from './auth/AuthContext';
import PrivateRouter, { AdminRoute, ContextRoute } from './auth/PrivateRouter';
import Quiz from './learn/Quiz';
import Landing from './landing';
import Blogs from './blog/Blogs';
import StoryEditor from './editor/Story/StoryEditor'
import LevelEditor from './editor/Level/LevelEditor';
import Settings from './settings/Settings';
import BlockEditor from './editor/Blocks/BlockEditor';
import PasswordForgotten from './auth/PasswordForgotten';
import FlagManager from './FlagManagement/FlagManager';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import CookiePolicy from './Legal/CookiePolicy';
import TermsAndConditions from './Legal/TermsAndConditions';
import CompanyDetails from './Legal/CompanyDetails';
import CookieConsent from "react-cookie-consent";
import Controller from './Controller';
import WordEditor from './editor/Words/WordEditor';
import { WordProvider } from './editor/Words/WordContext';
import WordDashboard from './editor/Words/WordDashboard';
import Share from './learn/Share';
import UserDashboard from './users/UserDashboard';
import { StoryProvider } from './learn/StoryContext';
import Landing_1 from './Landing_1';
import Feed from './learn/Feed';
import Chat from './AILearn/Chat';
import BotEditor from './AILearn/BotEditor';
import ReadJapanese from './read/ReadJapanese';
import ReadTexts from './read/ReadTexts';
import ReadSentences from './read/ReadSentences';


function App() {
    return (
    <div className="text-slate-700">
    <AuthProvider>
    <Router>
              <Routes>
                <Route path="/signin" element={<SignIn/>}/>
                <Route path="/landing_1" element={<Landing_1/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/forgot" element={<PasswordForgotten/>}/>
                <Route path='/' element={<Landing_1></Landing_1>} />
                <Route path="/blog" element={<Blogs/>}/>
                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                <Route path="/cookie" element={<CookiePolicy/>}/>
                <Route path="/company" element={<CompanyDetails/>}/>
                <Route path="/share/:id/" element={<Share/>}/>
                <Route path="/controller/:id/" element={<Controller/>}/>
                <Route path="/feed/" element={<Feed/>}/>
                <Route path="/read/japanese" element={<ReadJapanese/>}/>
                <Route path="/text/japanese" element={<ReadTexts/>}/>
                <Route path="/sentences/japanese" element={<ReadSentences/>}/>
                <Route path="/terms" element={<TermsAndConditions/>}/>
                <Route path="/learn" element={<PrivateRouter><Chat/></PrivateRouter>}/>
                <Route path="/chat" element={<PrivateRouter><Chat/></PrivateRouter>}/>
                <Route path="/settings" element={<PrivateRouter><Settings/></PrivateRouter>}/>
                <Route path="/flags" element={<AdminRoute><FlagManager></FlagManager></AdminRoute>}/>
                <Route path="/users" element={<AdminRoute><UserDashboard></UserDashboard></AdminRoute>}/>
                <Route path="/edit/bots" element={<AdminRoute><BotEditor></BotEditor></AdminRoute>}/>
                <Route path="/edit/words" element={<AdminRoute><WordEditor></WordEditor></AdminRoute>}/>
                <Route path="/edit/levels" element={<AdminRoute><LevelEditor></LevelEditor></AdminRoute>}/>
                <Route path="/edit/stories" element={<AdminRoute><StoryEditor></StoryEditor></AdminRoute>}/>
                <Route path="/edit/blocks" element={<AdminRoute><BlockEditor></BlockEditor></AdminRoute>}/>
                <Route path="/word_dashboard" element={<AdminRoute><WordDashboard></WordDashboard></AdminRoute>}/>
              </Routes>
          </Router>
    </AuthProvider>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#3B82F6" }}
        buttonStyle={{ color: "#3B82F6", fontSize: "15px", background: "#ffffff" }}
        expires={150}>This website uses cookies to enhance the user experience. <a href="https://www.iubenda.com/privacy-policy/46179223/cookie-policy" rel="noreferrer"  target="_blank" className="font-bold text-white" title="Privacy Policy ">Cookie Policy</a></CookieConsent>
    </div>
   
    );
}

export default App;
