import React, {useRef, useState, useEffect} from 'react';
import { setDoc, doc, getDoc, onSnapshot , getFirestore, deleteDoc  } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {IoAddOutline,
        IoRemove,
        IoSaveOutline,
        IoHammerOutline,
        IoTrashOutline} from 'react-icons/io5';
import {MdAlignHorizontalLeft,
        MdAlignHorizontalRight,
        MdOutlineInfo,
        MdVolumeUp,
        MdSettingsApplications,
        MdOutlineNoteAdd } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditorSpeechBubble } from '../EditorSpeechBubble';
import sparkMd5 from 'spark-md5';
import { ReactComponent as Spinner } from '../../svg/spinner.svg';
import { SearchForWord } from "../../grammar";
import WordItem from './WordItem';
import { Generator } from './Generator';
import { useAuth } from '../../auth/AuthContext';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export default function Level({lE,levelId,levelName,levelIndex}) {
  const functions = getFunctions();
  const generateLevelAudio = httpsCallable(functions, 'generateLevelAudio');
  const getAudioForState = httpsCallable(functions, 'getAudioForState');

  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [editState, setEditState] = useState(-1);
  const [generateAudioState, setGenerateAudioState] = useState(false);
  const [individualAudioState, setIndividualAudioState] = useState(false);
  const [safeState, setSafeState] = useState(false);
  const templateNameRef = useRef(null);
  const [words, setWords] = useState([]);
  const [allVocab, setAllVocab] = useState(new Set([]));

  const {userConfig} = useAuth();

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,levelId+""), async (d) => {
      let levelData = d.data();
      let ws = wordAnalytics(levelData.states);
      setWords(ws);
      setAllVocab(getAllLevelVocab(levelId,ws,levelIndex));
      if(levelName && templateNameRef.current){
        templateNameRef.current.value = levelName;
      } 
      setStates(levelData.states);
      setLoading(false);
    });
  },[levelId,levelIndex,userConfig]);

  function getAllLevelVocab(lvId,ws,data){
    if(lvId && data){
      let allV = new Set([]);
      const levelIds = data.ids;
        const levelVocabs = data.vocab;
        for(let i = 0; i < levelIds.length; i++){
          if(levelIds[i] === lvId){
            break;
          }else{
            if(levelVocabs[levelIds[i]] !== undefined){
              levelVocabs[levelIds[i]].forEach((item) => {
                allV.add(item);
              });
            }
          }
        }
      if(ws){
        ws.forEach((item) =>{
          allV.add(item);
        });
      }
      return allV;
    }else{
      return null;
    }
  }

  function wordAnalytics(sts){
    let words = new Set([]);
    sts.forEach((s) =>{
      if(s.direction === 0 || s.direction === 1){
        let sentenceWords = s.text.split(" ");
        sentenceWords.forEach((word) => {
          word = word.replace(".","");
          word = word.replace("!","");
          word = word.replace("?","");
          word = word.replace(",","");
          word = word.replace("-","");
          word = word.replace("  ","");
          word = word.toLocaleLowerCase();
        
          let w = SearchForWord(word);
          
        });
      }
    });

    return Array.from(words);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  // changes state

  async function save(){
    setSafeState(true);
    await setDoc(doc(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, levelId), {
      states: states,
      allVocab: Array.from(allVocab),
    });

    const docSnap = await getDoc(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === levelId){
            data.names[i] = templateNameRef.current.value;
          }
      };

      data.vocab[levelId] = words;

      await setDoc(doc(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
        lastChange: lE,
        vocab: data.vocab,
      });
      setSafeState(false)
    }
  }

  async function remove(){
    const docSnap = await getDoc(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();

      for(let i = 0; i < data.ids.length; i++){
          if(data.ids[i] === levelId){
            data.ids.splice(i,1);
            data.names.splice(i,1);
          }
      };

      if(data.vocab)
        delete data.vocab[levelId];

      await deleteDoc(doc(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, levelId));
      await setDoc(doc(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names,
        vocab: data.vocab
      });
    }
  }

  function newState(){
    let tempState = {
      text: 'быть',
      direction: 0,
      hash: sparkMd5.hash('быть')
    }

    let t1 = [...states,tempState];
    setStates(t1);
  }

  function removeText(){
    let t1 = [...states];
    t1.splice(editState, 1);
    setStates(t1);
    let ws = wordAnalytics(t1);
    setWords(ws);
    setAllVocab(getAllLevelVocab(levelId,ws,levelIndex));
  }

  function updateText(id,text){
    let t1 = [...states];
    t1[id].text = text;
    t1[id].hash = sparkMd5.hash(text);
    setStates(t1);
    let ws = wordAnalytics(t1);
    setWords(ws);
    setAllVocab(getAllLevelVocab(levelId,ws,levelIndex));
  }

  function updateDirection(direction){
    let t1 = [...states];
    t1[editState].direction = direction;
    setStates(t1);
    let ws = wordAnalytics(t1);
    setWords(ws);
    setAllVocab(getAllLevelVocab(levelId,ws,levelIndex));
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const t1 = reorder(
      states,
      result.source.index,
      result.destination.index
    );
    setEditState(result.destination.index);
    setStates(t1);
    
  }

  function generateAudioForlevel(){
    setGenerateAudioState(true);
    generateLevelAudio({id:levelId,origin_lang:userConfig.origin_lang,target_lang:userConfig.target_lang})
      .then(() => {
        setGenerateAudioState(false)
      });
  }

  function generateAudio(){
    let s = states[editState];
    let lC = userConfig.origin_lang;
    let gender = "MALE";
    let voice = userConfig.origin_lang+"-Wavenet-B";
    if(s.direction === 1 || s.direction === 0){
      lC = userConfig.target_lang;
      gender = "FEMALE";
      voice = userConfig.target_lang+"-Wavenet-C";
    }
      
    setIndividualAudioState(true);
    getAudioForState({text:s.text,hash:s.hash,lC:lC,gender:gender,n:voice,state:editState,id:levelId})
      .then(() => {
        setIndividualAudioState(false)
      });
  }

  return <div className="flex flex-1 w-full">
    {!loading && 
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-row w-full items-center border-slate-200 border-b p-2 justify-between">
        <input type="text" defaultValue={levelName} className="flex-1 no-outline bg-white" ref={templateNameRef}></input>
        <div className={`${editState!==-1?"text-sky-500":"text-slate-300"}`}>
          <div className="inline-flex rounded-md shadow-sm mr-2" role="group">
            <button type="button" className="bg-sky-500 text-slate-200 py-2 px-4 text-sm font-medium rounded-l-lg border-sky-500 hover:text-slate-200 border-slate-200 hover:bg-sky-500 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-slate-200">
              <IoAddOutline onClick={newState} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white rounded-r-md border border-slate-200 hover:bg-slate-100  focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
             <IoRemove onClick={removeText} className="cursor-pointer h-6 w-6"/>
            </button>
          </div>
          <div className="inline-flex rounded-md shadow-sm mr-2" role="group">
            <button type="button" className="py-2 px-4 text-sm font-medium bg-grey rounded-l-lg border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdAlignHorizontalLeft onClick={()=>{updateDirection(1)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineNoteAdd onClick={()=>{updateDirection(3)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineInfo onClick={()=>{updateDirection(2)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdSettingsApplications onClick={()=>{updateDirection(6)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdOutlineNoteAdd onClick={()=>{updateDirection(4)}} className="cursor-pointer h-6 w-6"/>
            </button>
            <button type="button" className="py-2 px-4 text-sm font-medium bg-white rounded-r-md border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
              <MdAlignHorizontalRight onClick={()=>{updateDirection(0)}} className="cursor-pointer h-6 w-6"/>
            </button>
          </div>
          <button type="button" className="py-2 px-4 text-sm font-medium bg-white rounded-md border border-slate-200 hover:bg-slate-100 hover:text-sky-500 focus:z-10 focus:ring-2 focus:ring-sky-500 focus:text-sky-500">
            {individualAudioState?<p className="animate-spin bg-slate-500 rounded-full border-2 mr-4 border-slate-400 text-white font-bold"><Spinner className="spinner h-6 w-6 "/></p>:<MdVolumeUp onClick={()=>{generateAudio()}} className="cursor-pointer h-6 w-6"/>}
          </button>
        </div>
      
        <div className="flex flex-1 flex-row justify-end items-center">
          {generateAudioState?<p className="animate-spin bg-slate-500 rounded-full border-2 mr-4 border-slate-400 text-white font-bold"><Spinner className="spinner h-6 w-6 "/></p>:<IoHammerOutline onClick={generateAudioForlevel} className="cursor-pointer text-slate-500 h-6 w-6 mr-4"/>}
          {safeState?<p className="animate-spin bg-slate-500 rounded-full border-2 mr-4 border-slate-400 text-white font-bold"><Spinner className="spinner h-6 w-6 "/></p>:<IoSaveOutline onClick={save} className="cursor-pointer h-6 w-6 text-slate-500 mr-4"/>}
          <IoTrashOutline onClick={remove} className="cursor-pointer h-6 w-6 text-red-500 mr-2"/>
        </div>
      </div>

      <div className="flex flex-row width-word-chips-levels items-center overflow-x-auto overflow-y-hidden border-slate-200 border-b p-2 justify-start">
        {(words).map((s,i) => (
          <WordItem key={i} w={s}></WordItem>
        ))}
      </div>

      <div onClick={()=>{setEditState(-1)}} className=" flex-1 flex h-full flex-col overflow-y-auto overflow-x-hidden">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {states.map((s, index) => (
                    <Draggable key={index+"id"} draggableId={index+"id"} index={index}>
                    {provided => ( 
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      > 
                      {s.direction===6?<Generator allVocab={allVocab} gId={s.text} change={(id)=>{updateText(index,id);}} select={()=>{setEditState(index);}}></Generator>:
                      <EditorSpeechBubble 
                          edit={(editState===index)} 
                          direction={s.direction} 
                          select={(id)=>{setEditState(id);}}
                          change={(text)=>{updateText(index,text)}}
                          id={index}
                          hash={s.hash} 
                          token={s.uuid}
                          text={(s.text)} />}
                        
                      </div>
                    )}
                  </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
    }
  </div>;
}

  