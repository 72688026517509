import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import Block from './Block';
import { useAuth } from '../../auth/AuthContext';
import { 
  IoExtensionPuzzle,
  IoCloseOutline,
  IoSearchOutline,
  IoAddOutline
} from 'react-icons/io5';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function Blocks() {

  const [currentBlock, setCurrentBlock] = useState();
  const [blocks, setBlocks] = useState([]);
  const [loadingBlocks, setLoadingBlocks] = useState(true);
  const newNameRef = useRef();

  const searchRef = useRef(null);
  const [searchStr, setSearchStr] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const {userConfig} = useAuth();

  async function newBlock(){
    if(newNameRef.current.value){
      const docRef = await addDoc(collection(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`), {
        name: newNameRef.current.value,
        states: [{
          text: 'sample text',
          direction: 0
        }]
      });

      const docSnap = await getDoc(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.ids.push(docRef.id)
        data.names.push(newNameRef.current.value)
        await setDoc(doc(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
          ids: data.ids,
          names: data.names, 
        });

        newNameRef.current.value = "";

        setCurrentBlock({
          name: newNameRef.current.value,
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
        let tempStories = [];
        let data = doc.data();

        if(!data.hasOwnProperty("ids")){
          setLoadingBlocks(false);
          return;
        }
        
        for(let i = 0; i < data.ids.length; i++){
          tempStories.push({"id": data.ids[i], "name": data.names[i], "le": i});
        };
        if(data.lastChange){
          setCurrentBlock(tempStories[data.lastChange]);
        }else{
          setCurrentBlock(tempStories[0]);
        }
        
        setBlocks(tempStories);
        setLoadingBlocks(false);
    });
  }, [userConfig]);

  function searchChange(srStr){
    setSearchStr(srStr);
    setSearchResult(blocks.filter((item)=>{
      if(item.name.toLowerCase().includes(srStr.toLowerCase())){
        return true;
      }else{
        return false;
      }
    }));
  }

  function stopSearch(){
    setSearchStr("");
    searchRef.current.value = "";
    setSearchResult([]);
  }

  return (
    <div className="flex flex-row w-screen overflow-hidden flex-1 dark:bg-slate-900 dark:text-slate-200 text-slate-500">
      {!loadingBlocks && 
        <div className="flex flex-col w-60 border-slate-200 border-dotted border-r dark:border-slate-700">
          <div className="flex flex-row items-center border-b border-dotted  border-slate-200 dark:border-slate-700 h-16">
              <input onChange={(event) => {searchChange(event.target.value)}} type="text" className="flex flex-1 rounded-full dark:bg-slate-900 dark:text-slate-200 outline-none focus:outline-none p-2" ref={searchRef}></input>
              {searchStr.length>0?<IoCloseOutline onClick={stopSearch} className="cursor-pointer flex h-6 w-6 mr-3"/>:<IoSearchOutline className="flex h-6 w-6 mr-3"/>}
          </div>
          <div className="flex flex-col flex-1 overflow-auto">
            {(searchResult.length>0?searchResult:blocks).map((temp,index) => (
              <div key={temp.id} onClick={() => {setCurrentBlock(temp)}}>
                <div className={`p-4 hover:bg-slate-100 dark:hover:bg-slate-800 dark:border-slate-700 ${index<blocks.length-1?"border-b border-dotted":""} p-2 cursor-pointer`} key={temp.id}>
                      <div><p className="font-bold">{temp.name}</p> <p>{temp.id}</p></div>
                    </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row border-t border-dotted border-slate-200 dark:border-slate-700 bg-green-100 dark:bg-green-900">
            <input type="text" placeholder="name" className="no-outline flex-1 min-w-0 p-4 dark:bg-green-900 bg-green-100" ref={newNameRef} />
              <AiOutlinePlus onClick={newBlock} className="cursor-pointer h-6 w-6 text-green-500 m-4"/>  
          </div>         
        </div>
        }
        {!loadingBlocks &&
          <Block block={currentBlock} />}
    </div>
  );
}
