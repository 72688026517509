import { useState, useRef, useEffect } from 'react'
import { setDoc,deleteDoc, doc, getDocs,query,where, addDoc , getFirestore, collection  } from "firebase/firestore";
import { Dialog } from '@headlessui/react'
import sparkMd5 from 'spark-md5';
import { 
  IoCloseOutline,
  IoLink,
  IoSaveOutline,
  IoCopyOutline,
  IoAdd,
  IoTrashOutline
} from 'react-icons/io5';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from '../../auth/AuthContext';
import { StateButton, STATES } from '../../util/StateButton';
import MDEditor from '@uiw/react-md-editor';
import { copyTextToClipboard } from '../Level/clipboard';

let currentID = "";

export function LinkEditor({story,isOpen,setIsOpen,linkExists}) {

  const titleRef = useRef(null);
  const addressRef = useRef(null);
  const [md, setMD] = useState("");
  const [address, setAddress] = useState("");
  const [title, setTitle] = useState("");
  const [existingLink, setExistingLink] = useState(false);

  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const [newState, setNewState] = useState(STATES.STATE_NORMAL);
  const [removeState, setRemoveState] = useState(STATES.STATE_NORMAL);

  const {userConfig} = useAuth();

  
  useEffect(()=>{
    async function fetchData(){
      const linkSnap = await getDocs(query(collection(getFirestore(),"share"),where("story_id","==",story.id)));
      let linkData = {};
      linkSnap.forEach((doc) => {
        linkData = doc.data();
        if(Object.keys(linkData).length > 0){
          currentID = doc.id;
        }
      });
      if(Object.keys(linkData).length > 0){
        setExistingLink(true);
        linkExists(true);
        console.log("set current id", currentID);
      }else{
        setExistingLink(false);
        linkExists(false);
      }
      setMD(linkData.content_md);
      setAddress(linkData.address);
      setTitle(linkData.title);
      console.log(linkData);
    }

    fetchData();
  },[story]);

  async function remove(){
    if(currentID){
      setRemoveState(STATES.STATE_PROCESS);
      await deleteDoc(doc(getFirestore(), "share", currentID));
      setExistingLink(false);
      linkExists(false);
      titleRef.current.value = "";
      addressRef.current.value = "";
      currentID = "";
      setMD("");
      setRemoveState(STATES.STATE_NORMAL);
    }
  }

  async function newLink(){
    if(titleRef.current.value &&
      addressRef.current.value &&
      md){
        setNewState(STATES.STATE_PROCESS);
        const docRef = await addDoc(collection(getFirestore(), "share"), {
          title: titleRef.current.value,
          address: addressRef.current.value,
          story_id: story.id,
          lang: `${userConfig.origin_lang}-${userConfig.target_lang}`,
          content_md: md
        });
        currentID = docRef.id;
        setExistingLink(true);
        linkExists(true);
        setNewState(STATES.STATE_NORMAL);
    }else{
      setNewState(STATES.STATE_ERROR);
    }
  }

  async function save(){
    console.log(titleRef.current.value ,
      addressRef.current.value ,
      md , currentID)
    if(titleRef.current.value &&
      addressRef.current.value &&
      md && currentID){
        setSafeState(STATES.STATE_PROCESS);
        await setDoc(doc(getFirestore(), "share",currentID), {
          title: titleRef.current.value,
          address: addressRef.current.value,
          story_id: story.id,
          lang: `${userConfig.origin_lang}-${userConfig.target_lang}`,
          content_md: md
        });
        setSafeState(STATES.STATE_NORMAL);
    }else{
      setSafeState(STATES.STATE_ERROR);
    }
  }

    return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
    <div className="fixed inset-0 flex items-center justify-center p-8 inset-0 bg-black/30 z-50" aria-hidden="true">
      <Dialog.Panel className="flex flex-col h-full w-full rounded bg-white rounded bg-white rounded-xl z-50">
        <Dialog.Title className="flex flex-row text-xl rounded-t py-2 px-4 justify-between items-center font-bold bg-sky-300 dark:bg-sky-900 dark:text-sky-300 text-sky-700">
          <div className="flex flex-row items-center">
            <IoLink  className=" mr-2 h-6 w-6"/>
            <p className="text-slate-300 mr-2 font-light w-16 truncate ...">{story.id}</p>
            <p>Share</p>
          </div>
            <div className="flex flex-row bg-white rounded-full shadow-l pl-2">
              <div className="flex flex-row  items-center border-slate-200 justify-start">
                <input defaultValue={title} onChange={(event) => {}} type="text" placeholder="Title" className="text-slate-500 flex outline-none focus:outline-none" ref={titleRef}></input>
                <input defaultValue={address} onChange={(event) => {}} type="text" placeholder="Address" className="text-slate-500 flex outline-none focus:outline-none" ref={addressRef}></input>
              </div>
              <div className="border-l">
                <StateButton onClick={()=>{copyTextToClipboard(`https://lang.blue/share/${addressRef.current.value}`)}} className="m-2 text-sky-500" size="6"  state={STATES.STATE_NORMAL}><IoCopyOutline className=" h-6 w-6 "/></StateButton>
              </div>
              <div className="border-l">
                <StateButton onClick={remove} className="m-2 text-sky-500" size="6"  state={removeState}><IoTrashOutline className="text-red-500 h-6 w-6 "/></StateButton>
              </div>
              <div className="border-l">
                { !existingLink?
                  <StateButton onClick={newLink} className="m-2 text-slate-500" size="6"  state={newState}><IoAdd className=" h-6 w-6 "/></StateButton>:
                  <StateButton onClick={save} className="m-2 text-slate-500" size="6"  state={safeState}><IoSaveOutline className=" h-6 w-6 "/></StateButton>
                }
              </div>
            </div>
          <IoCloseOutline onClick={()=>{setIsOpen(false)}} className="cursor-pointer h-8 w-8"/>
        </Dialog.Title>
        <div className="flex flex-row h-full overflow-auto">
        
          <div className="flex flex-col w-full">
            
           
            <div className="flex flex-col flex-1 overflow-auto">
            <MDEditor
              className="h-full flex-1"
              value={md}
              onChange={setMD}
            />
          
            </div>
          </div>
        </div>
      </Dialog.Panel>
      </div>
    </Dialog>
    
  )
}