import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import {onSnapshot ,addDoc, collection } from "firebase/firestore";
import { db } from '../firebase';
import { useAuth } from '../auth/AuthContext';
import { StateButton, STATES } from '../util/StateButton';
import {
  IoSend,
} from "react-icons/io5";
import { getFunctions, httpsCallable } from "firebase/functions";
import { applyActionCode, getAuth } from 'firebase/auth';

export default function ShopBubble(){

    const {currentUser} = useAuth();
    const [buyMonthState,setBuyMonthState] = useState(STATES.STATE_NORMAL);
    const [unlockState,setUnlockState] = useState(STATES.STATE_NORMAL);
    const [buyYearState,setBuyYearState] = useState(STATES.STATE_NORMAL);
    const functions = getFunctions();
    const applyVoucher = httpsCallable(functions, 'applyVoucher');
    const applyRef = useRef(null);

    async function buy(price_id){
        console.log("buy stuff", price_id);
        let docRef = await addDoc(collection(db,"customers",currentUser.uid,"checkout_sessions"),{
            price: price_id,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });
        console.log("creadted", docRef);
        const unsub = onSnapshot(docRef, (snap)=>{
            const { error, url } = snap.data();
            if (error) {
              // Show an error to your customer and
              // inspect your Cloud Function logs in the Firebase console.
              alert(`An error occured: ${error.message}`);
            }
            if (url) {
              // We have a Stripe Checkout URL, let's redirect.
              window.location.assign(url);
              unsub();
            }
        });
    }

    async function apply(){
      let authToken = await getAuth().currentUser.getIdToken(true);
      let configObj = {};
      configObj.id = applyRef.current.value
      configObj.authToken = authToken;
      applyVoucher(configObj).then((result)=> {
        if(result.data.code === 404){
          setUnlockState(STATES.STATE_ERROR);
        }else{
          window.location.reload();
          setUnlockState(STATES.STATE_NORMAL);
        }
      });
    }

    return <>
      <div className="flex flex-col sm:flex-row dark:border-slate-600 dark:text-slate-200 border-4 rounded-xl border-dotted py-10 m-4">
      <div className="flex flex-col items-center sm:w-1/2 dark:border-slate-600 border-b-4  sm:border-b-0 sm:border-r-4 border-dotted">
        <div className="text-4xl">Monthly</div>
        <div className="text-xl my-4 text-center">
          Unlimited access to all dialogs and grammar blocks
        </div>
        <div className="text-3xl text-lime-500">3.99$</div>

          <div onClick={()=>{setBuyMonthState(STATES.STATE_PROCESS);buy("price_1LIJIUFCiJw0d5T8pi5mHVnN")}} className="flex flex-row items-center cursor-pointer text-xl bg-sky-500 p-2 m-2 rounded text-white font-bold">
              <StateButton state={buyMonthState}></StateButton>Get Access
          </div>
      </div>
      <div className="flex flex-col items-center sm:w-1/2">
        <div className="text-4xl">Yearly</div>
        <div className="text-xl my-4 text-center">
          Unlimited access to all dialogs and grammar blocks
        </div>
        <div className="text-3xl text-lime-500">
          37.99${" "}
          <span className="bg-amber-300 text-amber-900 dark:bg-amber-900 dark:text-amber-500 px-1 rounded">
            -20%
          </span>
        </div>
          <div onClick={()=>{setBuyYearState(STATES.STATE_PROCESS);buy("price_1MHfEgFCiJw0d5T8OSEBgW4v")}} className="flex flex-row items-center cursor-pointer text-xl bg-sky-500 p-2 m-2 rounded text-white font-bold">
              <StateButton  state={buyYearState}></StateButton>Get Access
          </div>
      </div>
  </div>
  <div className="flex flex-row w-full items-center justify-center">
      <div className="dark:text-slate-300 mr-2">
        Apply voucher
      </div>
      <div className="flex flex-row items-center basis-1/3 text-amber-900 dark:text-amber-300 rounded-xl pr-3 bg-amber-300 dark:dark:bg-amber-900">
            <input
              onChange={()=>{
                if(unlockState === STATES.STATE_ERROR){
                  setUnlockState(STATES.STATE_NORMAL);
                }
              }}
              type="Code"
              className="flex flex-1 rounded-full bg-amber-300 dark:bg-amber-900 outline-none focus:outline-none p-2"
              ref={applyRef}
            ></input>
            <StateButton size={6} state={unlockState}>
              <IoSend onClick={()=>{setUnlockState(STATES.STATE_PROCESS); apply();}} className="cursor-pointer flex h-6 w-6" />
            </StateButton>
          </div>
    </div></>
}