import React, { useState, useEffect, useReducer} from 'react';
import {useAuth} from '../auth/AuthContext';
import {useNavigate} from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { ReactComponent as Spinner } from '../svg/spinner.svg';
import SpeechBubble from '../util/SpeechBubble';
import { CSSTransition,TransitionGroup } from 'react-transition-group';
import StoryView from './StoryView';
import Navbar from '../navbar/Navbar';
import language from '../language.json';
import {IoFlag, IoPlayBackCircleOutline} from 'react-icons/io5';
import Flag from './Flag';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { fireEvent } from '@testing-library/react';
import KeySymbol from './KeySymbol';
import {Howl} from 'howler';

/**
 * component with all signup functionality
 * @return {object} jsx signup in component
 */

const STATE_FEEDBACK = "feedback";
const STATE_ASK = "ask";
const STATE_NEW = "new";
const STATE_START = "start";
const STATE_LOADING = "loading";
const STATE_END = "end";
const STATE_CHOICE = "choice";
const STATE_NO_ACCESS = "noAccess";

const MODE_STORY = "story";

let currentId;
let currentMode;
let sentences = [];
let context;

let last_time_stamp = 0;

let colorCarousell = ["sky","red","lime","fuchsia","orange",];
let colorCounter = 0;

let speechbubble1 = {};
const audioSuccess = new Howl({
  src: "https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/success.mp3?alt=media&token=08afbf66-1fe7-40c7-90ec-3614edacf2bb",
  html5: true,
});

export default function Quiz() {
  let [textBubbles, setTextBubbles] = useState([]);
  let [state, setState] = useState(STATE_LOADING);
  let [current, setCurrent] = useState(0);
  let [nextStory, setNextStory] = useState(null);
  let [isOpen, setIsOpen] = useState(false);
  let [media, setMedia] = useState([]);
  let [playing, setPlaying] = useState(true);
  let [role, setRole] = useState();

  const {currentUserState, userConfig, currentUser} = useAuth();
  let [xp, setXP] = useState(currentUserState.xp);

  const functions = getFunctions();
  const storyFeedback = httpsCallable(functions, 'storyFeedback');
  const getStory = httpsCallable(functions, 'getStory');
  const getBlock = httpsCallable(functions, 'getBlock');
  const getLevel = httpsCallable(functions, 'getLevel');
  const addPoint = httpsCallable(functions, 'addPoint');
  
  async function getCustomClaimRole() {
    await currentUser.getIdToken(true);
    const decodedToken = await currentUser.getIdTokenResult();
    setRole(decodedToken.claims.stripeRole);
    console.log("role",role);
    return decodedToken.claims.stripeRole;
  }

  useEffect(() => {
    checkTrial();
  },[currentUser,currentUserState]);

  async function checkTrial(){
    let created = currentUser.metadata.createdAt*1;
    let trialPeriod = 0;
    if(currentUserState.access){
      trialPeriod = currentUserState.access;
    }

    console.log(currentUserState);
    
    let time = Date.now();
    if(created+trialPeriod<time){
      let role = await getCustomClaimRole();
      if(role === "unlimited"){
        getWelcomeBlock();
      }else{
        setNoAccess();
      }
      
    }else{
      setRole("free");
      getWelcomeBlock();
    }
  }

  function setNoAccess(){
    setState(STATE_NO_ACCESS);
    setTextBubbles([{
      "text": ``,
      "direction": 11,
      'correct': false,
      'hide': false
    }]);
  }

  async function getWelcomeBlock(){
    setState(STATE_LOADING);
    let configObj = {storyId:null};
    configObj.storyId = "WELCOME"; // only ever checks the first element
    configObj.authToken = await getAuth().currentUser.getIdToken(true);
    configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
    let block = await getBlock(configObj);
    block = block.data.map((item)=>{
      return item
    });

  
    block.push({
      "text": ``,
      "direction": 7,
      'correct': false,
      'hide': false
    });

    console.log(block);

    setState(STATE_START);
    autoplayStates(block)
  }

  function getLang(direction){
    if(direction===1){
      return userConfig.target_lang;
    }else if(direction===0){
      return userConfig.target_lang;
    }else if(direction===3){
      return userConfig.origin_lang;
    }else if(direction===4){
      return userConfig.origin_lang;
    }else if(direction===5){
      return userConfig.target_lang;
    }else if(direction===6){
      return userConfig.target_lang;
    }else{
      return userConfig.origin_lang;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown',keyEvent);
    return () => {
      document.removeEventListener("keydown", keyEvent);
    };
  },[state,current]);

  /*useEffect(() => {
    document.addEventListener('keydown',keyEvent);
    let snap = onSnapshot(doc(getFirestore(), "controll_session", "3JCCCdgz33JJ3MP38pEu"), (doc) =>{
      let data = doc.data();
      let d = data.press
      console.log(state,d,last_time_stamp,last_time_stamp!==data.stamp);
      if(last_time_stamp!==data.stamp){
        if(d === "b"){
          onNext();
        }else if(d === "m"){
          onRight();
        }else if(d === "n"){
          onWrong();
        }
        last_time_stamp = data.stamp;
      }
      
    });

    return () => {
      snap();
    };
  },[state,current]);*/

  const keyEvent = (event) => {
    if(!isOpen){
      console.log(state);
      if(event.key === " "){
        if(state===STATE_NEW||state===STATE_ASK){
          onNext();
        }else if(state===STATE_START){
          onStart();
        }
      }else if(event.key === "f"){
        if(state===STATE_FEEDBACK)
          onRight();
      }else if(event.key === "d"){
        if(state===STATE_FEEDBACK){
          onWrong();
        }else if(state===STATE_CHOICE){
          loadBlock();
        }
      }else if(event.key === "a"){
        if(state!==STATE_LOADING){
          back();
        }
      }
    }
  }

  async function onStartStory(id){
    if(state === STATE_NO_ACCESS){
      return;
    }
    currentMode = MODE_STORY;
    
    let authToken = await getAuth().currentUser.getIdToken(true);
    let configObj = {storyId:null};
    if(id){
      currentId = id;
      configObj.storyId = id;
    }
    configObj.stories = currentUserState.stories;
    configObj.authToken = authToken;
    configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
    setState(STATE_LOADING);
    getStory(configObj)
    .then((result) => {
      if(!id){
        currentId = result.data[0];
      }
      let audioFiles = []
      sentences = result.data[1].map((item)=>{
        item.primary = true;
        if(item.direction === 6){
          item.audio = [];
          for(let i = 0; i < item.fragments; i++){
            item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
          }
        }else{
          item.audio = (`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`); 
        }
        
        if(item.deconstruct){
          item.deconstruct = item.deconstruct.map((dec)=>{
            if(dec.direction === 6){
              dec.audio = [];
              for(let i = 0; i < dec.fragments; i++){
                dec.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(dec.direction)}%2Faudio_${dec.hash[i]}.mp3?alt=media&token=${dec.uuid[i]}`); 
              }
            }else{
              dec.audio =  (`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(dec.direction)}%2Faudio_${dec.hash}.mp3?alt=media&token=${dec.uuid}`); 
            }
            
            audioFiles.push({ url: `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(dec.direction)}%2Faudio_${dec.hash}.mp3?alt=media&token=${dec.uuid}` });
            return dec;
          })
        }
        return item});
      setMedia(audioFiles);
      setCurrent(1);
      let tB = [...textBubbles];
      let s = sentences[0];

      playAudio(0);

      tB.push(s);
      setTextBubbles(tB);
      
      setState(STATE_ASK);
    });
  }

  async function onStartLevel(id){
    try{
      let authToken = await getAuth().currentUser.getIdToken(true);

      let configObj = {storyId:null};
      if(id){
        configObj.storyId = id;
      }
      configObj.authToken = authToken;
      configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
      setState(STATE_LOADING);
      getLevel(configObj)
      .then((result) => {
        sentences = result.data[1].map((item)=>{item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`; return item})
        setCurrent(1);
        let tB = [...textBubbles];
        let s = result.data[0];
        tB = [];
        tB.push(s);
        setTextBubbles(tB);
        setState(STATE_ASK);
      });
    }catch(e){
      setState(STATE_START);
      console.error(e);
    }
    
  }

  function onStart(){
    onStartStory(nextStory);
  }

  function playAudio(c){
    if(typeof speechbubble1.pause === 'function'){
      speechbubble1.pause();
    }
    speechbubble1 = new Howl({
      src: sentences[c].audio,
      html5: true,
      autoplay: true,
      onend: function(){
        setPlaying(false);
      },
      onstop: function(){
        setPlaying(false);
      },
      onplay: function(){
        setPlaying(true);
      }
    });
  }

  function back(){
    if(textBubbles.length>3){
      let tB = [...textBubbles];
      tB.pop();
      setTextBubbles(tB);
      setCurrent(current-1);
      setStateQuestionOrInfo(tB[tB.length-1].direction);
    }
  }

  async function onNext(){
    
    // check if not last element to continue
    if(current < sentences.length){
      // set state by bubble type
      // in current is already the next bubble type saved
      if(sentences[current].direction === 5 && state !== STATE_CHOICE){
        setState(STATE_CHOICE);
      } else if (sentences[current].direction === 5 && state === STATE_CHOICE){
        setState(STATE_ASK);
        let tB = [...textBubbles];
        let s = sentences[current+1];
        tB.push(s);
        setTextBubbles(tB);
        setCurrent(current+2);
        setStateQuestionOrInfo(sentences[current].direction);
      } else{
        if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
          if(sentences[current].audio){

            playAudio(current);
          }
        }
        
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);
        setCurrent(current+1);
        setStateQuestionOrInfo(sentences[current].direction);
      }
      
    }else{
      setStoryFeedback();
      // reset 
      setCurrent(0)
      setState(STATE_END);

      autoplayStates(getFinishedTBs());
      
      //let tB = [...textBubbles];
      //let f = getFinishedTBs();
      //tB.push(f[0]);
      //tB.push(f[1]);
      //setTextBubbles(tB);
      
    }
  }

  async function loadBlock(){
    setState(STATE_LOADING);
    let configObj = {storyId:null};
    configObj.storyId = sentences[current].text; // only ever checks the first element
    configObj.authToken = await getAuth().currentUser.getIdToken(true);
    configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
    let block = await getBlock(configObj);

    // init the audio in the block states
    let audioFiles = [];

    colorCounter = 0;

    block = block.data.map((item)=>{
      if(item.direction === 6){
        item.audio = [];
        item.color = colorCarousell[colorCounter];
        if(item.fragments && item.uuid && item.hash){
          for(let i = 0; i < item.fragments.length; i++){
            item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
          }
        }
        
      }else{
        item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
      }
      
      audioFiles.push({ url: `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}` });
      return item
    });

    colorCounter++;

    
   
    // add the block states to the sentences
    sentences.splice(current, 1); // remove the block element to not call it again
    sentences.splice(current, 0, ...block);
    let tB = [...textBubbles];
    let s = sentences[current];
    tB.push(s);
    setTextBubbles(tB);

    if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
      if(sentences[current].audio){

        playAudio(current);
      }
    }

    // ready for next state
    setStateQuestionOrInfo(sentences[current].direction);
    setCurrent(current+1);
    setState(STATE_NEW);
  }

  

  async function loadBlockLink(blID){
    setState(STATE_LOADING);
    let configObj = {storyId:null};
    configObj.storyId = blID; // only ever checks the first element
    configObj.authToken = await getAuth().currentUser.getIdToken(true);
    configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
    let block = await getBlock(configObj);

    // init the audio in the block states
    let audioFiles = [];
    block = block.data.map((item)=>{
      if(item.direction === 6){
        item.audio = [];
        item.color = colorCarousell[colorCounter];
        if(item.fragments && item.uuid && item.hash){
          for(let i = 0; i < item.fragments.length; i++){
            item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
          }
        }
      }else{
        item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
      }
      audioFiles.push({ url: `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}` });
      return item
    });

    colorCounter++;

    setMedia(audioFiles);
   
    // add the block states to the sentences
    sentences.splice(current, 0, ...block);
    let tB = [...textBubbles];
    let s = sentences[current];
    tB.push(s);
  
    setTextBubbles(tB); 

    if(sentences[current].direction === 0 || sentences[current].direction === 1 || sentences[current].direction === 2  ){
      if(sentences[current].audio){

        playAudio(current);
      }
    }

    // ready for next state
    setStateQuestionOrInfo(sentences[current].direction);
    setCurrent(current+1);
    setState(STATE_NEW);
  }

  async function autoplayStates(st){
    let tB = [...textBubbles];
    for(let i = 0; i < st.length; i++){
      let tB1 = [...tB];
      tB1.push(st[i]);
      tB = tB1;
      setTextBubbles(tB1);
      await delay(1000);
    }
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  async function setStoryFeedback(){
    if(currentMode === MODE_STORY){
      let authToken = await getAuth().currentUser.getIdToken(true);
      const body = {
        authToken: authToken,
        storyId: currentId,
        lang: `${userConfig.origin_lang}-${userConfig.target_lang}`
      };
      storyFeedback(body);
    }
  }

  async function onWrong(){
    
    if(sentences[current-1].deconstruct){
      if(sentences[current-1].deconstruct[0].direction===5){

        // get block from server
        setState(STATE_LOADING);
        let configObj = {storyId:null};
        configObj.storyId = sentences[current-1].deconstruct[0].text; // only ever checks the first element
        configObj.authToken = await getAuth().currentUser.getIdToken(true);
        configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`
        let block = await getBlock(configObj);

        console.log("block data",block.data);

        // init the audio in the block states
        colorCounter = 0;
        block = block.data.map((item)=>{
          if(item.direction === 6){
            item.audio = [];
            item.color = colorCarousell[colorCounter];
            if(item.fragments && item.uuid && item.hash){
              for(let i = 0; i < item.fragments.length; i++){
                item.audio.push(`https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash[i]}.mp3?alt=media&token=${item.uuid[i]}`); 
              }
            }
          }else{
            item.audio = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${getLang(item.direction)}%2Faudio_${item.hash}.mp3?alt=media&token=${item.uuid}`;
          }
          return item
        });

        colorCounter++;

        console.log(block);
        // check if there are more blocks in the deconstruct and append them to the block
        // to be encountered in the next function

        if(sentences[current-1].deconstruct.length > 1){
          sentences[current-1].deconstruct.shift();
          block = [...block, ...sentences[current-1].deconstruct]
        }

        // add the block states to the sentences
        sentences.splice(current, 0, ...block);
        delete sentences[current-1].deconstruct;
        sentences.splice(current+block.length, 0, ...[sentences[current-2],sentences[current-1]]);
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);

        // ready for next state
        setStateQuestionOrInfo(sentences[current].direction);
        setCurrent(current+1);
        setState(STATE_NEW);
      }else{
        sentences.splice(current, 0, ...sentences[current-1].deconstruct);
        let tB = [...textBubbles];
        let s = sentences[current];
        tB.push(s);
        setTextBubbles(tB);
        setStateQuestionOrInfo(sentences[current].direction);
        setCurrent(current+1);
      }
      
    }else{
      let tB = [...textBubbles];
      let s = sentences[current-2];
      tB.push(s);
      setTextBubbles(tB);
      setCurrent(current-1);
      // set state by bubble type
      setStateQuestionOrInfo(sentences[current].direction);
    }
  }

  async function onRight(){
    
    setXP(xp+1);
    let authToken = await getAuth().currentUser.getIdToken(true);
    addPoint({authToken:authToken});
    audioSuccess.play()
    onNext();
  }
  
  function getFinishedTBs(){
    let tB = [];
    switch(userConfig.origin_lang){
      case "de-DE":
        tB.push({
          "text": `du machst einen tollen Job`,
          "direction": 1,
          'correct': false,
          'hide': false
        });
        tB.push({
          "text": `bist du bereit für eine weitere Lektion?`,
          "direction": 1,
          'correct': false,
          'hide': false
        });
        break;
      case "en-US":
      default:
        tB.push({
          "text": `you are doing great keep going`,
          "direction": 1,
          'correct': false,
          'hide': false
        });
        tB.push({
          "text": `ready for a new lesson?`,
          "direction": 1,
          'correct': false,
          'hide': false
        });
        break;
    }
    tB.push({
      "text": `## choose your next dialog`,
      "direction": 6,
      'correct': false,
      'hide': false
    });
    tB.push({
      "text": ``,
      "direction": 7,
      'correct': false,
      'hide': false
    });
    return tB;
  }

  function setStateQuestionOrInfo(bubbleType){
    let lastType = -1;
    if(current>0){
      lastType = sentences[current-1].direction;
    }
    
    switch(bubbleType){
      case 1: // normal left
        if(lastType===3||lastType===4){
          setState(STATE_FEEDBACK)
        }else{
          setState(STATE_ASK)
        }
        
        break;
      case 0: // normal right
        if(lastType===3||lastType===4){
          setState(STATE_FEEDBACK)
        }else{
          setState(STATE_ASK)
        }
        break;
      case 2: // center
        setState(STATE_ASK)
        break;
      case 3: // annotation left
        setState(STATE_ASK)
        break;
      case 4: // annotation right
        setState(STATE_ASK)
        break;
      default:
        break;
    }
  }

  function flag(){
    context = getContext();
    setIsOpen(true);
  }

  function onBlock(blID){
    if(blID.startsWith("STORY_")){
      onStartStory(blID.substring(6));
    }else if(blID.startsWith("LINK_")){
      window.open("https://"+blID.substring(5), '_blank', 'noopener,noreferrer');
    }else if(blID.startsWith("YOUTUBE_")){
      window.open("https://"+blID.substring(8), '_blank', 'noopener,noreferrer');
    }else{
      loadBlockLink(blID);
    }
  }

  function getContext(){
    let context = {
      origin_lang: userConfig.origin_lang,
      target_lang: userConfig.target_lang,
      current_id: currentId?currentId:null,
      sentences: sentences?sentences:[],
      current_state: current?current:null
    };
    return context;
  }
  
  function hasDeconstruct(){
    if(sentences[current-1]){
      if (sentences[current-1].hasOwnProperty('deconstruct')){
        if (sentences[current-1].deconstruct[0].direction === 5){
          if (sentences[current-1].deconstruct[0].btn){
            return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{sentences[current-1].deconstruct[0].btn}<KeySymbol color={"sky"} symbol={"D"}/></p>;
          } else {
            return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["explain"][userConfig.origin_lang]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
          }
          
        }else{
          return <p onClick={onWrong} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["train sentence"][userConfig.origin_lang]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
        }
      }else{
        return <p onClick={onWrong} className="btn-action bg-red-200 flex flex-1 text-center text-red-500 dark:bg-red-700 dark:text-red-200">{language["I didn't know (n)"][userConfig.origin_lang]}<KeySymbol color={"sky"} symbol={"D"}/></p>;
      }
    }
    
  }

  function hasNextBlock(){
    if(sentences[current]){
      if (sentences[current].direction === 5){
        if (sentences[current].btn){
          return <p onClick={loadBlock} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{sentences[current].btn}<KeySymbol color={"sky"} symbol={"D"}/></p>;
        } else {
          return <p onClick={onNext} className="btn-action bg-sky-200 flex flex-1 text-center text-sky-500 dark:bg-sky-700 dark:text-sky-200">{language["explain"][userConfig.origin_lang]}</p>;
        }
      }
    }
  }

//<p className="animate-spin bg-slate-300 rounded-full border-2 border-slate-300 m-2 text-slate-700 font-bold"><Spinner className="spinner h-12 w-12 "/></p>

  return (
    <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
      <Flag context={context} show={isOpen} close={() => {setIsOpen(false)}}></Flag>
      <IoFlag className="invisible sm:visible text-slate-500 fixed bottom-2 right-4 mb-20 sm:mb-4 w-10 h-10" onClick={flag}/>
      <Navbar role={role} xp={xp} onStartLevel={(id)=>{onStartLevel(id);}} onStartStory={(id)=>{onStartStory(id);}} percentage={`${(current/sentences.length*100)}%`}></Navbar>
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <StoryView onNextStory={(id)=>{setNextStory(id);}} select={(storyId)=>{onStartStory(storyId);}}></StoryView>
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col absolute_height overflow-visible pb-2">
            <div className="relative scroll-height w-full h-full">
              <TransitionGroup component="div" className="w-full absolute bottom-0 left-0">
                {textBubbles.map((item, index) => {
                      return <CSSTransition key={index} timeout={700} classNames="item">
                          <SpeechBubble index={index} 
                            length={textBubbles.length} 
                            audio={item.audio} 
                            onBlock={onBlock}
                            color={item.color}
                            playing={playing}
                            primary={item.primary}
                            onPlay={()=>{speechbubble1.playing()?speechbubble1.stop():speechbubble1.play()}}
                            correct={item.correct} 
                            fragments={item.fragments}
                            direction={item.direction} 
                            hide={item.hide} 
                            text={(item.text)}/>
                        </CSSTransition>
                })}
              </TransitionGroup>
             
            </div>
          </div>
          <div className="w-full p-2 text-slate-600 select-none">
              <div className=" container flex flex-row justify-center">
  
              {(() => {
                  switch (state) {
                    case STATE_LOADING:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                               <div className="lds-ripple"><div></div><div></div></div>
                            </div>
                    case STATE_NO_ACCESS:
                              return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                      Activate subscription
                               </div>
                    case STATE_START:
                      return <>{nextStory?<div className="flex flex-row items-center pb-20 sm:pb-4">  
                                <p onClick={onStart} className="btn-action animate-pulse bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["let's beginn!"][userConfig.origin_lang]}<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                            </div>:<p className="pb-20 sm:pb-4"> <div className="lds-ripple"><div></div><div></div></div></p>}</>
                    case STATE_NEW:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                              
                               <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                <p onClick={onNext} className="btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["ok"][userConfig.origin_lang]}<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                            </div>
                    case STATE_ASK:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                <p onClick={onNext} className="btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["next"][userConfig.origin_lang]}<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                            </div>
                    case STATE_CHOICE:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                  <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                  {hasNextBlock()}
                                  <p onClick={onNext} className="btn-action bg-lime-200 text-lime-700">skip<KeySymbol color={"slate"} symbol={"SPACE"}/></p>
                              </div>
                    case STATE_FEEDBACK:
                      return <div className="flex flex-row items-center pb-20 sm:pb-4">  
                                  <p onClick={back} className={`${textBubbles.length>3?"":"hidden"} btn-action bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200`}>back<KeySymbol color={"slate"} symbol={"A"}/></p>
                                  {hasDeconstruct()}
                                  <p onClick={onRight} className="btn-action bg-lime-200 whitespace-nowrap text-lime-700 dark:bg-lime-900 dark:text-lime-200">{language["I knew (y)"][userConfig.origin_lang]}<KeySymbol color={"lime"} symbol={"F"}/></p>
                              </div>
                    case STATE_END:
                      return <>{nextStory?<div className="flex flex-row items-center pb-20 sm:pb-4">  
                                <p onClick={onStart} className="btn-action animate-pulse bg-slate-200 text-slate-700 dark:bg-slate-700 dark:text-slate-200">{language["let's beginn!"][userConfig.origin_lang]}</p>
                            </div>:<p className="pb-20 sm:pb-4"> <div className="lds-ripple"><div></div><div></div></div></p>}
                            </>
                    default:
                      return null
                  }
                })()}
                
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}
