import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from '../util/AudioPlayer';
import { useAuth } from '../auth/AuthContext';
import { IoExtensionPuzzleOutline, 
  IoLinkOutline,
  IoChatbubblesOutline,
  IoLogoYoutube } from 'react-icons/io5';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import collapse from 'remark-collapse';


/**
 * returns component for documents
 * @return {object} jsx documents component
 */

 function get_bubble_class( direction){
  if(direction===1){
    return "sb-left";
  }else if(direction===0){
    return "sb-right";
  }else{
    return "";
  }
}

function getHighlightClass(edit){
  if(edit){
    return "sb-highlight";
  }
}

function getDirection(direction){
  if(direction===1){
    return "justify-start";
  }else if(direction===0){
    return "justify-end";
  }else if(direction===3){
    return "justify-start";
  }else if(direction===4){
    return "justify-end";
  }else{
    return "justify-center";
  }
}

function getDesign(direction){
  if(direction===1){
    return "sb";
  }else if(direction===0){
    return "sb";
  }else if(direction===3){
    return "sb-annotation-left-small text-slate-700 dark:text-slate-300";
  }else if(direction===4){
    return "sb-annotation-right-small text-slate-700 dark:text-slate-300";
  }else if(direction===5){
    return "sb-block flex flex-row items-center";
  }else{
    return "border-4 border-dotted p-4 border-slate-200 dark:border-slate-700 dark:text-slate-300 font-bold";
  }
}

function getInputDirection(direction){
  if(direction===1){
    return "";
  }else if(direction===0){
    return "sb-input-right";
  }else if(direction===3){
    return "";
  }else if(direction===4){
    return "sb-input-right";
  }else{
    return "";
  }
}

function getLang(direction,userConfig){
  if(direction===1){
    return userConfig.target_lang;
  }else if(direction===0){
    return userConfig.target_lang;
  }else if(direction===3){
    return userConfig.origin_lang;
  }else if(direction===4){
    return userConfig.origin_lang;
  }else{
    return userConfig.origin_lang;
  }
}

export function MDEditorSpeechbubble({edit,id,fragments,change,select,hash,token,onBlock,text}){
  const {userConfig} = useAuth();
  function getAudioLink(text){
    if(fragments && hash && token){
      for(let i = 0; i < fragments.length; i++){
        if(fragments[i] === text[0]){
          let link = `https://firebasestorage.googleapis.com/v0/b/words-a7a3c.appspot.com/o/Audio%2F${userConfig.target_lang}%2Faudio_${hash[i]}.mp3?alt=media&token=${token[i]}`;
          return <div className="inline-flex flex-row"><div className="mr-2">{text[0]}</div><AudioPlayer inactive={400} color={"amber"} size={6} lang={`${userConfig.target_lang}`} audio={link} play={false}></AudioPlayer></div>;
        }
      }
    }
   
    return text[0];
  }

  function getBlockLink(text){
    return <div className="inline-flex flex-row">
              <div className="mr-2">
                {text[0]}
              </div>
              <IoLinkOutline className="w-6 h-6"></IoLinkOutline>
            </div>;
  }


  function getLink(href, children, ...props){
    if(href.startsWith("STORY_")){
      return <div className='p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="mr-2">
                {children[0]}
              </div>
              <IoChatbubblesOutline className="w-6 h-6"></IoChatbubblesOutline>
        </div>
      </div>
    }else if(href.startsWith("LINK_")){
      return <div className='p-1 underline rounded inline text-sky-500 dark:bg-sky-900 dark:text-sky-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="">
                {children[0]}
              </div>
        </div>
      </div>
    }else if(href.startsWith("YOUTUBE_")){
      return <div className='p-1 bg-red-200 rounded inline text-red-700 dark:bg-red-900 dark:text-red-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
        <div className="inline-flex flex-row items-center">
              <div className="mr-2">
                {children[0]}
              </div>
              <IoLogoYoutube className="w-6 h-6"></IoLogoYoutube>
        </div>
      </div>
    }else{
      return <div className='p-1 bg-sky-200 rounded inline text-sky-700 dark:bg-sky-900 dark:text-sky-200 cursor-pointer' onClick={()=>{onBlock(href)}} {...props}>
      {getBlockLink(children)}
    </div>
    }
   
  }


  return <div className=" border-l-4 border-sky-300 text-slate-600 dark:text-slate-200 border-dotted px-2 pb-1 mx-2 my-1 ml-4 pl-4" onClick={(e)=>{e.stopPropagation();select(id)}}>
        {edit?
                <textarea
                  className='w-full mr-2 outline-none focus:bg-slate-100 dark:bg-slate-900 dark:focus:bg-slate-800 rounded p-2'
                  value={text}
                  rows={10}
                  onChange={(event)=>{change(event.target.value)}} 
                />
                :<ReactMarkdown children={text} remarkPlugins={[remarkGfm]} components={{
                  p: ({node, ...props}) => <p className='mb-2 leading-loose md:mb-4' {...props} />,
                  ul: ({node, ...props}) => <ul className='list-disc list-outside ml-4' {...props} />,
                  ol: ({node, ...props}) => <ol className='list-decimal list-outside ml-4' {...props} />,
                  li: ({node, ...props}) => <li className='mb-2' {...props} />,
                  hr: ({node, ...props}) => <div className='w-32 h-2 border-b-4 border-dotted border-slate-200 dark:border-slate-700 my-6' {...props} />,
                  em: ({node, ...props}) => <span className='text-amber-500' {...props} />,
                  table: ({node, ...props}) => <table cellPadding={8} className=""{...props} />,
                  a: ({node,href,children, ...props}) => getLink(href,children, props),
                  td: ({node,children, ...props}) => (<td className='m-2 dark:border-slate-800' {...props}>{children}</td>),
                  h2: ({node,children, ...props}) => (<h2 className='text-2xl mb-4' {...props}>{children}</h2>),
                  h1: ({node,children, ...props}) => (<h1 className='text-3xl mb-4' {...props}>{children}</h1>),
                  code: ({node,children, ...props}) => (<p className='p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-400' {...props}>{getAudioLink(children)}</p>)
                }}/>}
      </div>;
}


export function EditorSpeechBubble({text, btn, fragments,direction, id, edit, select,onBlock, change,hash, token}) {
  //<input type="text" onChange={() => {change(textRef.current.value,id)}} onClick={() => {selected(id)}} defaultValue={txt} className={`flex-1 no-outline sb-input ${alignInput(direction)}`} ref={textRef}></input>

  const textRef = useRef();
  const {userConfig} = useAuth();

  return (
      <>
       
      {direction===6?<MDEditorSpeechbubble select={select} fragments={fragments} onBlock={onBlock} hash={hash} token={token} id={id} edit={edit} change={change} direction={direction} text={text}/>:<div className={`flex items-center flex-row ${getDirection(direction)}`}>
        {(direction===0||direction===4)&&token&&hash?<AudioPlayer lang={`${getLang(direction,userConfig)}`} token={token} id={hash} play={false}></AudioPlayer>:""}
        <div onClick={(e)=>{e.stopPropagation();select(id)}} className={`${getDesign(direction)} rounded ${get_bubble_class(direction)} ${getHighlightClass(edit)}`}>
          {direction===5? <IoExtensionPuzzleOutline  className="mr-2 h-6 w-6"/>:""}
        {edit?<input onChange={()=>{change(textRef.current.value)}} ref={textRef} className={`${getInputDirection(direction)} sb-input`} defaultValue={text}></input>:(direction===5?btn:text)}
        </div>
        {(direction===1||direction===3||direction===2)&&token&&hash?<AudioPlayer lang={`${getLang(direction,userConfig)}`} token={token} id={hash} play={false}></AudioPlayer>:""}
      </div>}</>
  );
}

EditorSpeechBubble.propTypes = {
  text: PropTypes.string,
  direction: PropTypes.number,
};
