import {
  setDoc,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";

import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

import { IoSaveOutline, IoTrashOutline } from "react-icons/io5";

import { STATES, StateButton } from "../util/StateButton";

export default function Bot({ bot }) {
  const [currentBot, setBot] = useState();
  const templateNameRef = useRef(null);
  const newTopicIconRef = useRef(null);
  const newTopicDescRef = useRef(null);
  const iconRef = useRef(null);
  const promptRef = useRef(null);
  const descriptionRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);

  useEffect(() => {
    onSnapshot(doc(getFirestore(), "chat", bot.id + ""), async (doc) => {
      let data = doc.data();
      console.log(data);
      if (data) {
        if (data) {
          setBot(data);
        } else {
          setBot({});
        }
      }
      setLoading(false);
    });
    if (bot.name && templateNameRef.current) {
      templateNameRef.current.value = bot.name;
    }
    return () => {
      setBot({});
    };
  }, [bot]);

  async function remove() {
    const docSnap = await getDoc(doc(getFirestore(), "chat", "index"));
    if (docSnap.exists()) {
      let data = docSnap.data();

      for (let i = 0; i < data.ids.length; i++) {
        if (data.ids[i] === bot.id) {
          data.ids.splice(i, 1);
          data.names.splice(i, 1);
          data.icons.splice(i, 1);
        }
      }

      await deleteDoc(doc(getFirestore(), "chat", bot.id));
      await setDoc(doc(getFirestore(), "chat", "index"), {
        ids: data.ids,
        names: data.names,
        icons: data.icons,
      });
    }
  }

  async function save() {
    setSafeState(STATES.STATE_PROCESS);
    await setDoc(doc(getFirestore(), "chat", bot.id), {
      name: templateNameRef.current.value,
      icon: iconRef.current.value,
      prompt: promptRef.current.value,
      description: descriptionRef.current.value,
      topics: currentBot.topics,
    });

    const docSnap = await getDoc(doc(getFirestore(), "chat", "index"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      for (let i = 0; i < data.ids.length; i++) {
        if (data.ids[i] === bot.id) {
          data.names[i] = templateNameRef.current.value;
        }
      }
      for (let i = 0; i < data.ids.length; i++) {
        if (data.ids[i] === bot.id) {
          data.icons[i] = iconRef.current.value;
        }
      }
      await setDoc(doc(getFirestore(), "chat", "index"), {
        ids: data.ids,
        names: data.names,
        icons: data.icons,
        lastChange: bot.le,
      });
      setSafeState(STATES.STATE_NORMAL);
    }
  }

  return (
    <div className="w-full">
      <div className="lg:flex h-16 flex-row w-full items-center border-slate-200 dark:border-slate-700 border-dotted border-b p-2 justify-between dark:border-slate-700">
        <img
          src={currentBot?.icon}
          alt="person icon"
          className="w-12 h-12"
        ></img>
        <input
          type="text"
          defaultValue={currentBot?.name}
          className="flex flex-1 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4"
          ref={templateNameRef}
        ></input>
        <div className="flex flex-row justify-end items-center">
          <StateButton
            className="mr-4"
            size="6"
            onClick={save}
            state={safeState}
          >
            <IoSaveOutline className="h-6 w-6 " />
          </StateButton>
          <IoTrashOutline
            onClick={remove}
            className="cursor-pointer h-6 w-6 text-red-500 mr-2"
          />
        </div>
      </div>

      <div className="w-full h-full flex flex-col gap-8 p-4">
        <textarea
          type="text"
          defaultValue={currentBot?.icon}
          className="no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4 border-b"
          ref={iconRef}
        ></textarea>
        <textarea
          type="text"
          defaultValue={currentBot?.prompt}
          className="no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4 border-b"
          rows="5"
          ref={promptRef}
        ></textarea>
        <textarea
          type="text"
          defaultValue={currentBot?.description}
          className="no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4 border-b"
          rows="5"
          ref={descriptionRef}
        ></textarea>
        <div className="flex flex-col gap-2">
          {currentBot?.topics?.map((topic, index) => {
            return (
              <div
                key={index + "_topic"}
                className="flex flex-row items-center "
              >
                <input
                  type="text"
                  onChange={(e) => {
                    let newTopics = currentBot.topics;
                    newTopics[index].icon = e.target.value;
                    setBot({ ...currentBot, topics: newTopics });
                  }}
                  defaultValue={topic.icon}
                  className="p-2 bg-slate-100 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4"
                ></input>
                <input
                  type="text"
                  onChange={(e) => {
                    let newTopics = currentBot.topics;
                    newTopics[index].description = e.target.value;
                    setBot({ ...currentBot, topics: newTopics });
                  }}
                  defaultValue={topic.description}
                  className="p-2 flex-1 bg-slate-100 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4"
                ></input>
                <AiFillMinusCircle className="w-6 h-6 text-red-500"></AiFillMinusCircle>
              </div>
            );
          })}
          <div className="flex flex-row items-center">
            <input
              type="text"
              defaultValue={"emoji"}
              ref={newTopicIconRef}
              className="p-2 bg-slate-100 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4"
            ></input>
            <input
              type="text"
              defaultValue={"text"}
              ref={newTopicDescRef}
              className="p-2 flex-1 bg-slate-100 no-outline bg-white dark:bg-slate-900 dark:text-slate-200 mr-4"
            ></input>
            <AiFillPlusCircle
              className="w-6 h-6 text-green-500"
              onClick={() => {
                let newTopics = currentBot.topics;
                if (!newTopics) newTopics = [];
                newTopics.push({
                  icon: newTopicIconRef.current.value,
                  description: newTopicDescRef.current.value,
                });
                setBot({ ...currentBot, topics: newTopics });
              }}
            ></AiFillPlusCircle>
          </div>
        </div>
      </div>
    </div>
  );
}
