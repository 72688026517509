/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "./auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "./svg/logo_blue.svg";
import { EditorSpeechBubble } from "./editor/EditorSpeechBubble";
import { ReactComponent as UserIcon } from "./svg/user.svg";
import { ReactComponent as FlagRU } from "./svg/flag_ru.svg";
import { IoPaperPlane } from "react-icons/io5";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ReactComponent as HiBlue } from "./svg/blue_hi.svg";
import { ReactComponent as RussianFlag } from "./svg/flag_ru.svg";
import { ReactComponent as SpanishFlag } from "./svg/flag_es.svg";
import SpeechBubbleLocal from "./util/SpeechBubble_local";

/**
 * component with all signup functionality
 * @return {object} jsx sign in component
 */

var emailRegex =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email) {
  if (!email) return false;

  if (email.length > 254) return false;

  var valid = emailRegex.test(email);
  if (!valid) return false;

  // Further checking of some things regex can't handle
  var parts = email.split("@");
  if (parts[0].length > 64) return false;

  var domainParts = parts[1].split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return true;
}

function LanguageChooser({ setLang }) {
  const [selected, setSelected] = useState("RU");
  return (
    <div className="flex flex-row pl-4 cursor-pointer">
      <RussianFlag
        className={`${
          selected === "RU"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
          setSelected("RU");
          setLang("RU");
        }}
      ></RussianFlag>
      <SpanishFlag
        className={`${
          selected === "ES"
            ? "p-1 border-4 border-gray-500 dark:border-gray-200"
            : "p-2"
        } w-16 h-16 rounded-full`}
        onClick={() => {
          setSelected("ES");
          setLang("ES");
        }}
      ></SpanishFlag>
    </div>
  );
}

function DialogChooser({ lang }) {
  if (lang === "RU") {
    return (
      <div className="hidden md:flex flex-1 flex-col items-end relative ">
        <div className="lg:flex flex-row hidden">
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🤷‍♂️</div>
            <div className="font-bold">Where are we?</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🙍‍♀️</div>
            <div className="font-bold">Eat it son!</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🕰️</div>
            <div className="font-bold">What time is it?</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🍴</div>
            <div className="font-bold">Tuesday evening</div>
          </div>
        </div>
        <div className="flex flex-row -mr-16">
          <div className="hidden lg:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🗺️</div>
            <div className="font-bold">How many countries?</div>
          </div>
          <div className=" hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🪆</div>
            <div className="font-bold">Meeting a native speaker</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🌳</div>
            <div className="font-bold">To the forrest</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">📅</div>
            <div className="font-bold">What year is it?</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🏠</div>
            <div className="font-bold">Coming home</div>
          </div>
        </div>
        <div className="flex flex-row">
          <Link to={"/share/buying_bread"}>
            <div className="sm:hidden lg:flex animate-pulse flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🥖</div>
              <div className="font-bold">Buying bread</div>
            </div>
          </Link>
          <Link to={"/share/making_friends_ru"}>
            <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🍻</div>
              <div className="font-bold">Making friends</div>
            </div>
          </Link>
          <Link to={"/share/do_you_have_plans_ru"}>
            <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🗓️</div>
              <div className="font-bold">What are your plans?</div>
            </div>
          </Link>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">👧</div>
            <div className="font-bold">New girl in school</div>
          </div>
        </div>
        <div className="flex flex-row -mr-16">
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🌄</div>
            <div className="font-bold">Good morning</div>
          </div>
          <Link to={"/share/buying_groceries_ru"}>
            <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🛒</div>
              <div className="font-bold">Buying groceries</div>
            </div>
          </Link>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🍴</div>
            <div className="font-bold">Tuesday evening</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🚗</div>
            <div className="font-bold">I want a new car</div>
          </div>
        </div>

        <div className="sm:flex flex-row">
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">👨‍🍼</div>
            <div className="font-bold">Please sleep son!</div>
          </div>
          <div className="animate-pulse lg:hidden flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🥖</div>
            <div className="font-bold">Buying bread</div>
          </div>
          <div className="flex md:hidden  flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">⛰️</div>
            <div className="font-bold">Get the boots!</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🧑‍⚕️</div>
            <div className="font-bold">Go to the doctor?</div>
          </div>
        </div>
        <div className="lg:hidden flex flex-row -mr-16">
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">⛰️</div>
            <div className="font-bold">Get the boots!</div>
          </div>
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🍔</div>
            <div className="font-bold">I want to eat at...</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="hidden md:flex flex-1 flex-col items-end relative ">
        <div className="lg:flex flex-row hidden">
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🤷‍♂️</div>
            <div className="font-bold">Where are we?</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🙍‍♀️</div>
            <div className="font-bold">Eat it son!</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🕰️</div>
            <div className="font-bold">What time is it?</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🚉</div>
            <div className="font-bold">Train to madrid</div>
          </div>
        </div>
        <div className="flex flex-row -mr-16">
          <div className="hidden lg:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🗺️</div>
            <div className="font-bold">How many countries?</div>
          </div>
          <div className=" hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🥵</div>
            <div className="font-bold">What do we do today?</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🌳</div>
            <div className="font-bold">To the forrest</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">📅</div>
            <div className="font-bold">What year is it?</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🤵‍♂️</div>
            <div className="font-bold">Ordering in a Restaurant</div>
          </div>
        </div>
        <div className="flex flex-row">
          <Link to={"/share/buying_eggs"}>
            <div className="sm:hidden lg:flex animate-pulse flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🥚</div>
              <div className="font-bold">Market, buying eggs</div>
            </div>
          </Link>
          <Link to={"/share/making_friends_es"}>
            <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🍻</div>
              <div className="font-bold">Making friends</div>
            </div>
          </Link>
          <Link to={"/share/what_are_your_plans_es"}>
            <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🗓️</div>
              <div className="font-bold">What are your plans?</div>
            </div>
          </Link>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">👧</div>
            <div className="font-bold">New girl in school</div>
          </div>
        </div>
        <div className="flex flex-row -mr-16">
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🌄</div>
            <div className="font-bold">Good morning</div>
          </div>
          <Link to={"/share/buying_groceries_es"}>
            <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
              <div className="text-3xl">🛒</div>
              <div className="font-bold">Buying groceries</div>
            </div>
          </Link>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🍴</div>
            <div className="font-bold">Tuesday evening</div>
          </div>
          <div className="hidden sm:flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🚗</div>
            <div className="font-bold">I want a new car</div>
          </div>
        </div>

        <div className="sm:flex flex-row">
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">👨‍🍼</div>
            <div className="font-bold">Please sleep son!</div>
          </div>
          <div className="animate-pulse lg:hidden flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-500 text-white cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🥖</div>
            <div className="font-bold">Buying bread</div>
          </div>
          <div className="flex md:hidden  flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">⛰️</div>
            <div className="font-bold">Get the boots!</div>
          </div>
          <div className="flex flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🧑‍⚕️</div>
            <div className="font-bold">Go to the doctor?</div>
          </div>
        </div>
        <div className="lg:hidden flex flex-row -mr-16">
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">⛰️</div>
            <div className="font-bold">Get the boots!</div>
          </div>
          <div className="flex-col w-32 text-center m-2 justify-center items-center bg-sky-100 dark:bg-slate-800 text-gray-300 rounded-xl p-4">
            <div className="text-3xl">🍔</div>
            <div className="font-bold">I want to eat at...</div>
          </div>
        </div>
      </div>
    );
  }
}

function DialogChooserSmall(lang) {
  console.log(lang);
  if (lang.lang === "RU") {
    return (
      <div className="grid grid-cols-3 sm:grid-cols-4 mt-4 md:hidden">
        <Link to={"/share/buying_bread"}>
          <div className="flex flex-col animate-pulse flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:bg-slate-900 dark:text-gray-200  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🥖</div>
            <div className="font-bold">Buying bread</div>
          </div>
        </Link>
        <Link to={"/share/making_friends_ru"}>
          <div className="flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:text-gray-200 dark:bg-slate-900  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🍻</div>
            <div className="font-bold">Making friends</div>
          </div>
        </Link>
        <Link to={"/share/do_you_have_plans_ru"}>
          <div className="flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:text-gray-200 dark:bg-slate-900  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🗓️</div>
            <div className="font-bold">What are your plans?</div>
          </div>
        </Link>
        <Link to={"/share/buying_groceries_ru"}>
          <div className="hidden sm:flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:text-gray-200 dark:bg-slate-900  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🛒</div>
            <div className="font-bold">Buying groceries</div>
          </div>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-3 sm:grid-cols-4 mt-4 md:hidden">
        <Link to={"/share/buying_groceries_es"}>
          <div className="flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:text-gray-200 dark:bg-slate-900  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🛒</div>
            <div className="font-bold">Buying groceries</div>
          </div>
        </Link>

        <Link to={"/share/making_friends_es"}>
          <div className="flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500 dark:bg-slate-900   cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🍻</div>
            <div className="font-bold">Making friends</div>
          </div>
        </Link>
        <Link to={"/share/what_are_your_plans_es"}>
          <div className="flex flex-col min-h-[16vh] text-center m-2 justify-center items-center bg-white text-gray-500  dark:bg-slate-900  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🗓️</div>
            <div className="font-bold">What are your plans?</div>
          </div>
        </Link>
        <Link to={"/share/buying_eggs"}>
          <div className="hidden sm:flex animate-pulse flex-col min-h-[16vh] text-center m-2 justify-center items-center dark:bg-slate-900 bg-white text-gray-500 dark:text-slate-200  cursor-pointer hover:shadow-xl rounded-xl p-4">
            <div className="text-3xl">🥚</div>
            <div className="font-bold">Market, buying eggs</div>
          </div>
        </Link>
      </div>
    );
  }
}

function GetRealDialogSection(lang) {
  if (lang.lang === "RU") {
    return (
      <>
        {" "}
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={2}
          text={'say "you meet somebody new at a party"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={4}
          text={'say "Hello how are you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={0}
          text={"Привет, как дела?"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={3}
          text={'say "I am fine and you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={1}
          text={"Я в порядке, а ты?"}
        />
      </>
    );
  } else {
    return (
      <>
        {" "}
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={2}
          text={'say "you meet somebody new at a party"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={4}
          text={'say "Hello how are you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={0}
          text={"Hola, ¿qué tal?"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={3}
          text={'say "I am fine and you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={1}
          text={"Estoy bien, ¿y tú?"}
        />
      </>
    );
  }
}

function GetGrammarSection(lang) {
  const txt1ES =
    "## Why do I  use `presente perfecto`? \n\n It is used for **completed actions or events** with a specific duration, that are either relevant or happend before this moment";
  const txt1RU =
    "## how to form the **simple past**? \n\n |[subject]()|`говорить` *to talk*| ending |\n| - | - | - | \n| male |`говорил`|`-л`| \n| feminine |`говорила`| `-ла`|\n| neuter |`говорил`|`-ло`| \n| plural |`говорили`|`-ли`|";

  if (lang.lang === "RU") {
    return (
      <>
        {" "}
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"ru-RU"}
          primary={true}
          color={"sky"}
          direction={1}
          text={"Я провел здесь два месяца."}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"ru-RU"}
          primary={true}
          color={"sky"}
          direction={6}
          text={txt1RU}
        />
      </>
    );
  } else {
    return (
      <>
        {" "}
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          color={"sky"}
          direction={1}
          text={"He pasado dos meses aquí."}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          color={"sky"}
          direction={6}
          text={txt1ES}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          color={"sky"}
          direction={6}
          text={"[how to form the presente perfecto?]()"}
        />
      </>
    );
  }
}

function GetSentenceSection(lang) {
  if (lang.lang === "RU") {
    return (
      <>
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={4}
          text={'say "I am fine and you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={0}
          text={"Я в порядке, а ты?"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={4}
          text={'say literally "I in alright"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={0}
          text={"Я в порядке"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={4}
          text={'say "I am fine, and"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={0}
          text={"Я в порядке, а"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          color={"sky"}
          direction={6}
          text={'[when to say "а" or "и"?]()'}
        />
      </>
    );
  } else {
    return (
      <>
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={4}
          text={'say "I am fine and you?"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          direction={0}
          text={"Estoy bien, ¿y tú?"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={4}
          text={'say "I am"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={0}
          text={"Estoy"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={true}
          color={"sky"}
          direction={6}
          text={"[how to use the verb 'estar'?]()"}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={4}
          text={'say "I am fine"'}
        />
        <SpeechBubbleLocal
          index={0}
          length={1}
          lang={"es-ES"}
          primary={false}
          direction={0}
          text={"Estoy bien"}
        />
      </>
    );
  }
}

export default function Landing_1() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [lang, setLang] = useState("RU");

  const text =
    "| | | \n | - | - | \n | I want to  |`yo quiero`|\n| you want  |`tú quieres`|\n| she wants to  |`ella quiere`|\n| we want to  |`nosotros queremos`|\n| you want (plural) |`vosotros quereis`| \n| you want (plural, formal)  |`ustedes quieren`|";

  useEffect(() => {
    if (currentUser) {
      navigate("/learn");
    }
  }, [currentUser]);

  return (
    <div className="dark:bg-slate-900 dark:text-slate-200">
      <div className="flex flex-row w-screen bg-items justify-end dark:bg-slate-900 ">
        <div className="flex-1 flex flex-row justify-end">
          <div className="flex flex-col lg:h-screen justify-end md:max-w-md">
            <div className="flex flex-row">
              <Link to={"/signin"}>
                <div className="flex flex-row w-full m-1 justify-center">
                  <div className="flex justify-center rounded text-gray-400 font-bold pointer-cursor p-4">
                    Sign in
                  </div>
                </div>
              </Link>
              <Link to={"/signup"}>
                <div className="flex flex-row w-full m-1 justify-center">
                  <div className="flex animate-pulse justify-center rounded bg-sky-400 text-white rounded font-bold pointer-cursor p-2 m-2">
                    Start Free Trial
                  </div>
                </div>
              </Link>
            </div>
            <div className="text-6xl text-gray-500 dark:text-gray-200  p-4 md:mt-20 lg:mt-0">
              Hi!
            </div>
            <div className="sb-bottom-blue mb-4 text-3xl text-sky-500 p-4 z-10  rounded sp-max-w-mobile sm:sp-max-w  special">
              my name is <strong>Blue</strong>. I help you learning languages
              fast through dialogs
            </div>

            <div className=" text-2xl text-gray-500 dark:text-gray-200  p-4 z-10 sb-bottom-blue rounded sp-max-w-mobile sm:sp-max-w  special">
              I play <strong>bus drivers</strong>, <strong>doctors</strong>,{" "}
              <strong>waitresses</strong> and narrate the dialogs. We train
              situations and I explain grammar too.
            </div>
            <div className="hidden md:flex flex-col py-4">
              <div className="p-4 text-xl text-gray-400">
                Choose a language and try a dialog
              </div>
              <LanguageChooser
                setLang={(lang) => {
                  setLang(lang);
                }}
              ></LanguageChooser>
            </div>

            <div className=" h-1 bg-sky-500 mt-1 rounded mx-2"></div>
            <div className=" ml-32 blue-corner"></div>
            <HiBlue className="h-64 w-64 mt-10 md:mt-1"></HiBlue>
          </div>
        </div>
        <DialogChooser lang={lang}></DialogChooser>
      </div>

      <div className="bg-sky-500 text-white shadow-xl md:hidden">
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12">
          <div className="top flex flex-col sm:w-1/3">
            <div className="flex flex-col py-4 ">
              <div className="p-4 text-xl text-white">
                Choose a language and select a dialog
              </div>
              <LanguageChooser
                setLang={(lang) => {
                  setLang(lang);
                }}
              ></LanguageChooser>
            </div>

            <DialogChooserSmall lang={lang}></DialogChooserSmall>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row container mx-auto justify-between py-24 sm:h-screen items-center">
        <div className="flex flex-col sm:w-1/3 my-24 sm:my-2">
          <h2 className="text-4xl my-6 text-sky-500">
            Learn with real dialogs
          </h2>
          <em className="leading-loose text-amber-500 mt-3">
            How to order in a restaurant?
          </em>
          <em className="leading-loose text-amber-500 mb-3">
            How to talk about my plans?
          </em>
          <p className="leading-loose text-xl">
            Don't learn with random sentences! Learn with dialogs that relate to
            real life situations and grow your vocabulary naturally
          </p>
        </div>
        <div className="flex flex-col sm:w-2/3 lg:w-1/2 w-full">
          <GetRealDialogSection lang={lang}></GetRealDialogSection>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row container mx-auto justify-between py-24 sm:h-screen items-center">
        <div className="flex flex-col sm:w-1/3 mb-24 sm:mt-2">
          <h2 className="text-4xl mt-6 text-sky-500">
            The explanations you need when you need them
          </h2>
          <em className="leading-loose text-amber-500 mt-3">
            Why do I use this tense?
          </em>
          <em className="text-amber-500 mb-3">
            Why this word and not another?{" "}
          </em>
          <div className="leading-loose text-xl">
            Learning grammar is a question of repetition and examples and giving you the
            right information right when you need it.
          </div>
        </div>
        <div className="flex flex-col sm:w-2/3 lg:w-1/2">
          <GetGrammarSection lang={lang}></GetGrammarSection>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row container mx-auto justify-between py-24 sm:h-screen items-center">
        <div className="flex flex-col sm:w-1/3 mb-24 sm:mb-2">
          <h2 className="text-4xl my-6 text-sky-500">
            Don't be afraid of long sentences
          </h2>

          <p className="leading-loose text-xl">
            <strong>step</strong> by <strong>step</strong> construct long
            sentences and embed new vocabulary in a context you already know.
          </p>
        </div>
        <div className="flex flex-col sm:w-2/3 lg:w-1/2 w-full">
          <GetSentenceSection lang={lang}></GetSentenceSection>
        </div>
      </div>

      <div className="flex flex-col container sm:flex-row mb-36 sm:mb-2 sm:h-screen items-center">
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-col sm:w-1/2 w-full lg:mr-20 mb-20">
          <h2 className="text-4xl my-3 text-sky-500">💪 Effective</h2>
              <p className="leading-loose text-xl">
                The brain learns languages through examples and repetitions, there
                is no need to worry about grammar or Orthografie when you start
                out leaning, it is much more effective to learn the language
                orally and then proceede to reading and writing. Especially
                writing in a foreign language can be frustrating and restrict the
                number of learning experiences as you are bugged down over the
                right letters.
              </p>
              <ul className="list-none list-inside p-2 thumbUl">
                <li className="text-sky-500 font-bold p-2 text-lg">
                  don't waste time on words and grammar that do not matter
                </li>
              </ul>
          </div>
          <div className="flex flex-col sm:w-1/2 w-full lg:ml-20">
            <h2 className="text-4xl my-3 text-sky-500">🤔 Philosophy</h2>
            <p className="leading-loose text-xl">
              We believe a language learning app should be used as shortly as
              possible because the most learning happens when you explore the
              language on your own, by talking, by watching movies or reading books.
              We want you to get you going on this journey as fast as possible.
            </p>
            <ul className="list-none list-inside p-2 thumbUl">
              <li className="text-sky-500 font-bold p-2 text-lg ">
                gets going fast!
              </li>
            </ul>
          </div>
        </div>
      
     
      </div>

      <div className="flex flex-col container dark:border-slate-600 justify-center border-4 border-dotted rounded-xl p-4 sm:p-8">
        <div className="flex justify-center text-amber-600 font-bold mb-6">
          *trial without credit card information
        </div>
        <div className="flex flex-col sm:flex-row ">
          <div className="flex flex-col items-center sm:w-1/3">
            <div className="text-4xl">1 Week Free Trial</div>
            <div className="text-xl my-4 text-center">
              Unlimited access to all dialogs and grammar blocks
            </div>
            <div className="text-3xl text-lime-500">Free</div>
            <Link to={"/signup"}>
              <div className="text-xl bg-sky-500 p-2 m-2 rounded text-white font-bold">
                Start Free Trial
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center sm:w-1/3 dark:border-slate-600 border-t-4 border-b-4 my-10 py-10 sm:my-0 sm:py-0 sm:border-t-0 sm:border-b-0 sm:border-l-4 sm:border-r-4 border-dotted">
            <div className="text-4xl">Monthly</div>
            <div className="text-xl my-4 text-center">
              Unlimited access to all dialogs and grammar blocks
            </div>
            <div className="text-3xl text-lime-500">3.99$</div>
            <Link to={"/signup"}>
              <div className="text-xl bg-amber-500 p-2 m-2 rounded text-white font-bold">
                Start Free Trial
              </div>
            </Link>
          </div>
          <div className="flex flex-col items-center sm:w-1/3">
            <div className="text-4xl">Yearly</div>
            <div className="text-xl my-4 text-center">
              Unlimited access to all dialogs and grammar blocks
            </div>
            <div className="text-3xl text-lime-500">
              37.99${" "}
              <span className="bg-amber-900 text-amber-500 px-1 rounded">
                -20%
              </span>
            </div>
            <Link to={"/signup"}>
              <div className="text-xl bg-amber-500 p-2 m-2 rounded text-white font-bold">
                Start Free Trial
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="top flex  container mx-auto justify-left py-36">
        <div className="flex flex-col justify-between  mt-2">
          <a className="font-bold text-sky-500" href="/company">
            Company Details
          </a>
          <a
            href="https://www.iubenda.com/privacy-policy/46179223"
            rel="noreferrer"
            target="_blank"
            className="font-bold text-sky-500"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          <a
            href="https://www.iubenda.com/terms-and-conditions/46179223"
            rel="noreferrer"
            target="_blank"
            className="font-bold text-sky-500"
            title="Privacy Policy "
          >
            Terms and Conditions
          </a>
          <a
            href="https://www.iubenda.com/privacy-policy/46179223/cookie-policy"
            rel="noreferrer"
            target="_blank"
            className="font-bold text-sky-500"
            title="Privacy Policy "
          >
            Cookie Policy
          </a>
        </div>
      </div>
      <div className="hidden">
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12">
          <div className="top flex flex-col sm:w-1/3">
            <h2 className="text-3xl my-6">Why this app</h2>
            <h2 className="text-2xl my-3 font-bold">🤔 Philosophy</h2>
            <p className="leading-loose">
              We believe a language learning app should be used as little as
              possible because the most learning happens when you explore the
              language on your own, by talking, watching movies or reading books
              We want you to get you going on this journey as fast as possible
            </p>
            <ul className="list-none list-inside p-2 thumbUl">
              <li className="text-sky-500 font-bold p-2 text-lg ">
                the language learning app that does not want to look you in
              </li>
            </ul>
          </div>
        </div>
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12">
          <div className="top flex flex-col sm:w-1/3">
            <h2 className="text-2xl my-3 font-bold">💪 Effective</h2>
            <p className="leading-loose">
              The brain learns languages through examples and repetitions, there
              is no need to worry about grammar or Orthografie when you start
              out leaning, it is much more effective to learn the language
              orally and then proceede to reading and writing. Especially
              writing in a foreign language can be frustrating and restrict the
              number of learning experiences as you are bugged down over the
              right letters.
            </p>
            <ul className="list-none list-inside p-2 thumbUl">
              <li className="text-sky-500 font-bold p-2 text-lg">
                don't waste time on words and grammar that do not matter
              </li>
            </ul>
          </div>
        </div>
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12">
          <div className="top flex flex-col sm:w-1/3">
            <h2 className="text-2xl my-3 font-bold">😎 Stress free</h2>
            <p className="leading-loose">
              Do you remember when you where three and spend nights trying to
              cram in Flashcards to learn your mother language? Probably not and
              neither do I. Learning a language should be a stress free
              experience without worrying about grammar or getting everything
              right, eventually your brain will pick everything up on it’s on,
              the question is how to do that as fast as possible
            </p>
            <ul className="list-none list-inside p-2 thumbUl">
              <li className="text-sky-500 font-bold p-2 text-lg">
                constant repitition
              </li>
              <li className="text-sky-500 font-bold p-2 text-lg">
                don't worry about grammar or getting it right all the time
              </li>
            </ul>
          </div>
        </div>
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12 pb-24">
          <div className="top flex flex-col sm:w-1/3">
            <h2 className="text-2xl my-3 font-bold">🌱 Natural</h2>
            <p className="leading-loose">
              A child learns languages without Flashcards and without being able
              to write. Sticking to how children learn a language will make it
              easier for you too. Get as many examples as possible, delivered in
              an conversational context. As adults we have the ability to expose
              ourself to a lot more learning opportunities as children and I am
              convinced it can be even easier to learn a new one as an adult as
              it is for a child.
            </p>
            <ul className="list-none list-inside thumbUl hp-2">
              <li className="text-sky-500 font-bold p-2 text-lg">
                Learn through dialogue
              </li>
              <li className="text-sky-500 font-bold p-2 text-lg">
                natural learning order first hearing and reading then writing
              </li>
            </ul>
          </div>
        </div>
        <div className="top flex flex-col sm:flex-row container mx-auto justify-left py-12 pb-36">
          <div className="top flex flex-col w-full sm:w-1/3">
            <h2 className="text-3xl my-6">Pricing</h2>
            <div className="flex flex-col rounded-xl border">
              <div className="border-b p-4">
                <p className="text-sky-500 font-bold text-xl">Free</p>
                <ul className="list-disc list-inside p-2">
                  <li>get 1 lecture a day for free</li>
                  <li>no ads</li>
                  <li>earn additional lectures for referals</li>
                </ul>
                <div className="flex flex-row">
                  <a href="/signup">
                    <div className="p-2 m-2 bg-sky-500 rounded-full font-bold text-white px-4 cursor-pointer">
                      free during BETA
                    </div>
                  </a>
                </div>
              </div>
              <div className="border-b p-4 bg-sky-100 dark:bg-slate-800">
                <p className="text-sky-500 font-bold text-xl">Monthly</p>
                <ul className="list-disc list-inside p-2">
                  <li>unlimited learning time</li>
                  <li>no ads</li>
                  <li>earn additional lectures for referals</li>
                </ul>
                <div className="flex flex-row">
                  <a href="/signup">
                    <div className="p-2 m-2 bg-sky-500 rounded-full font-bold text-white px-4 cursor-pointer">
                      free during BETA
                    </div>
                  </a>
                </div>
              </div>
              <div className="border-b p-4">
                <p className="text-sky-500 font-bold text-xl">Yearly</p>
                <ul className="list-disc list-inside p-2">
                  <li>unlimited learning time</li>
                  <li>no ads</li>
                  <li>earn additional lectures for referals</li>
                </ul>
                <div className="flex flex-row">
                  <a href="/signup">
                    <div className="p-2 m-2 bg-sky-500 rounded-full font-bold text-white px-4 cursor-pointer">
                      free during BETA
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
