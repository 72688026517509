import React, { useState } from 'react';
import { setDoc, doc, getDoc, onSnapshot , getFirestore, deleteDoc, addDoc, collection  } from "firebase/firestore";

export default function Controller() {

    const SESSION = "3JCCCdgz33JJ3MP38pEu";

    function writeState(state){
        console.log("press",state);
        setDoc(doc(getFirestore(), "controll_session", SESSION), {
            press: state,
            stamp: Date.now()
        });
    }

  return (
    <div className="flex flex-col h-screen">
        <div onClick={()=>{writeState("b")}} className="hover:bg-slate-400 flex flex-1 items-center bg-slate-500 justify-center">
            <p className="text-3xl fond-bold text-white">next</p>
        </div>
        <div onClick={()=>{writeState("m")}} className="hover:bg-green-400 flex flex-1 items-center bg-green-500 justify-center">
            <p className="text-3xl fond-bold text-white">right</p>
        </div>
        <div onClick={()=>{writeState("n")}} className="hover:bg-red-400 flex flex-1 items-center bg-red-500 justify-center">
        
        <p className="text-3xl fond-bold text-white">wrong</p>
        </div>
    </div>
  );
}
