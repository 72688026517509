import React, { useState, useEffect } from "react";
import Navbar from "../AILearn/Navbar";
import { useAuth } from "../auth/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { JapaneseWords } from "./Japanese";
import { query, orderBy, limit } from "firebase/firestore";
import AudioPlayer from '../util/AudioPlayer';

import {
  IoLinkOutline,
  IoChatbubblesOutline,
  IoLogoYoutube,
  IoPaperPlane,
  IoLanguage,
  IoInformation,
} from "react-icons/io5";

import {
  collection,
  addDoc,
  setDoc,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";

const JP_SHORT = "jp-JP";
let sentences = [];

const STATE_LOADING = "loading";
const STATE_NO_ACCESS = "noAccess";
const STATE_START = "start";
const STATE_NEXT = "next";
const STATE_INIT = "init";

export function JapaneseWord({ word, key, pass }) {
  let [state, setState] = useState(pass);
  return (
    <div
      className="inline-block ml-2 inline-flex flex-col text-3xl cursor-pointer hover:bg-black/20"
      key={key}
      onClick={() => {
        setState(state < 6 ? state + 1 : 0);
      }}
    >
      {state === PASS_KANJI ? (
        <div className="whitespace-nowrap font-bold">{word[0]}</div>
      ) : (
        ""
      )}
      {state === PASS_KANA ? <div className="whitespace-nowrap ">{word[1]}&#xfeff;</div> : ""}
      {state === PASS_ROMANJI ? <div className="whitespace-nowrap ">{word[2]}&#xfeff;</div> : ""}
      {state === PASS_DECODED ? (
        <div className="whitespace-nowrap font-thin text-slate-600">
          {word[3]}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export function JapaneseLine({ text, translation, pass }) {
  const functions = getFunctions();
  const getJapaneseSentenceExplanation = httpsCallable(
    functions,
    "getJapaneseSentenceExplanation"
  );
  const [content, setContent] = useState();

  useEffect(() => {
    async function fetch() {
      let temp = await getJapaneseSentenceExplanation({ sentence: text });
      setContent(JSON.parse(temp.data));
      console.log(JSON.parse(temp.data));
    }
    fetch();
  }, []);

  return (
    <div>
      <div className="">
        {content?.map((item, i) => {
          return <JapaneseWord pass={pass} key={i} word={item} />;
        })}
      </div>
    </div>
  );
}

function JPSpeechBubble({ item, pass }) {
  const functions = getFunctions();
  const getAudioLink = httpsCallable(
    functions,
    "getAudioLink"
  );
  const [showTranslation, setShowTranslation] = useState(false);
  const [audioLink, setAudioLink] = useState();

  useEffect(() => {
    async function fetch() {
      let gender = "female";
      let voice = "ja-JP-Wavenet-A";
      if(item.direction === 0){
        gender = "male";
        voice = "ja-JP-Wavenet-C";
      }
      let temp = await getAudioLink({text:item.text, lC:JP_SHORT, voice: voice, gender: gender});
      setAudioLink(temp.data.link);
    }
    fetch();
  }, []);

  return (
    <div
      className={`${
        item.direction === 0 ? "self-end sb-right" : "self-start sb-left"
      } sb`}
    >
      {pass !== PASS_ENGLISH ? <JapaneseLine
        text={item.text}
        translation={item.translation}
        pass={pass}
        className={`text-2xl my-4`}
      ></JapaneseLine>: <div>{item.translation}</div>}
      <div className="flex flex-row items-center gap-2">
            {item.translation ? (
              <IoLanguage
                onClick={() => {
                  setShowTranslation(!showTranslation);
                }}
                className="cursor-pointer w-8 h-8 p-1 bg-black/10 rounded-full text-black/40"
              ></IoLanguage>
            ) : (
              ""
            )}
            {audioLink ? (
              <AudioPlayer inactive={400} color={"orange"} size={6} audio={audioLink} play={false}></AudioPlayer>
            ):("")}
          </div>
    </div>
  );
}

const PASS_ENGLISH = 0;
const PASS_DECODED = 1;
const PASS_ROMANJI = 2;
const PASS_KANA = 3;
const PASS_KANJI = 4;

let proceede = true;

export default function ReadJapanese() {
  const { currentUserState, userConfig, currentUser } = useAuth();

  const [xp, setXP] = useState(currentUserState.xp);
  const [role, setRole] = useState();
  const [currentWord, setCurrentWord] = useState(1);
  const [current, setCurrent] = useState(0);
  const [textBubbles, setTextBubbles] = useState([]);

  let [topics, setTopics] = useState();
  let [currentTopics, setCurrentTopics] = useState();

  const [state, setState] = useState(STATE_LOADING);

  const [pass, setPass] = useState(PASS_ENGLISH);

  const functions = getFunctions();
  const getConversation = httpsCallable(functions, "getConversation");

  useEffect(() => {
    async function fetch() {
      await getTopics();
      setState(STATE_INIT);
    }
    fetch();
  }, []);

  async function getTopics() {
    // get one document from the topics collection sorted by date, only get the latest
    const citiesRef = collection(getFirestore(), "topics");

    const q = query(citiesRef, orderBy("sort"), limit(1));

    const querySnapshot = await getDocs(q);
    let tempTopics = [];
    querySnapshot.forEach((doc) => {
      setCurrentTopics(doc.data().topics);
      tempTopics = doc.data().topics;
    });

    setTopics(tempTopics);

    return tempTopics;
  }

  function onStart(topic) {
    setState(STATE_LOADING);

    //bot.prompt.replace("{{target_lang}}",TargetLangDictionary[userConfig.target_lang])
    const params = {
      botId: "HcVm5gME0IrlzSunqGRw",
      context: {
        name: "Joshua",
        targetLang: "Japanese",
        sourceLang: "English",
      },
      topic: topic,
      conversation: [],
    };

    params.context.currentWord = JapaneseWords[currentWord][0];

    getConversation(params).then((result) => {
      try {
        let data = result.data.response;
        data = data.split("\n");
        data = data.map((item) => {
          return item.trim();
        });
        data = data.map((item) => {
          return item.split("(");
        });
        data = data.filter((item) => {
          return item.length > 0 && item[0] !== "";
        });
        let data_target = data.map((item) => {
          return item[0];
        });
        let data_source = data.map((item) => {
          return item[1];
        });
        data_target = data_target.map((item) => {
          return item.split(":")[1];
        });
        data_source = data_source.map((item) => {
          if (item.includes(":")) {
            return item.split(":")[1].slice(0, -1);
          } else {
            return item.slice(0, -1);
          }
        });

        // remove quotation mark if at the beginning or end of strong of all items in data source
        data_source = data_source.map((item) => {
          item = item.replace('"', "");
          item = item.trim();
          return item;
        });

        data_target = data_target.map((item) => {
          item = item.replace('"', "");
          item = item.trim();
          return item;
        });

        let tb = [];

        for (let i = 0; i < data_target.length; i++) {
          if (i % 2 === 0) {
            tb.push({
              direction: 1,
              text: data_target[i],
              translation: data_source[i],
              topic: topic,
            });
          } else {
            if (true) {
              tb.push({
                direction: 0,
                text: data_target[i],
                translation: data_source[i],
                topic: topic,
              });
            }
          }
        }

        console.log(tb);

        setTextBubbles(tb);

        setState(STATE_START);
      } catch (error) {
        console.log(error);
      }
    });
  }

  return (
    <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
      <Navbar
        role={role}
        xp={xp}
        percentage={`${(current / sentences.length) * 100}%`}
      ></Navbar>
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col h-full overflow-visible pb-2">
            <div className="relative w-full h-full overflow-scroll">
              <div className="mx-10 pt-10 flex flex-col ">
                {state === STATE_LOADING ? (
                  "Loading..."
                ) : state === STATE_INIT ? (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col bg-slate-200 rounded-xl p-4">
                      <div className="text-2xl font-bold">Current Word</div>
                      <div>
                        {JapaneseWords[currentWord][0]}{" "}
                        {JapaneseWords[currentWord][1]}{" "}
                        {JapaneseWords[currentWord][2]}
                      </div>
                     
                    </div>
                    <div className="text-2xl font-bold">Topics</div>
                    <div className="grid grid-cols-2 gap-2 overflow-scroll">
                      {topics?.map((topic, i) => {
                        return (
                          <div
                            key={i}
                            onClick={(e) => {
                              onStart(topic.description);
                            }}
                            className="cursor-pointer p-2 bg-slate-200 dark:bg-slate-800 dark:text-slate-200 rounded text-slate-700"
                          >
                            {topic.icon} {topic.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : state === STATE_START ? (
                  <div className="flex flex-col p-4">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col bg-slate-200 rounded-xl p-4">
                        <div className="text-2xl font-bold">Current Word</div>
                        <div>
                          {JapaneseWords[currentWord][0]}{" "}
                          {JapaneseWords[currentWord][1]}{" "}
                          {JapaneseWords[currentWord][2]}
                        </div>
                        <div onClick={()=>{pass===4?setPass(PASS_ENGLISH):setPass(pass+1)}}>Pass: {pass}</div>
                      </div>
                      {textBubbles.map((item, i) => {
                        return <JPSpeechBubble pass={pass} item={item}></JPSpeechBubble>;
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
