/* eslint-disable no-unused-vars */
import React, {useContext, useState, useEffect} from 'react';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import PropTypes from 'prop-types';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from 'react-router';
import { useAuth } from '../../auth/AuthContext.js';
import LoadingScreen from '../../util/LoadingScreen.js';
import { IoArrowForward } from 'react-icons/io5';

const WordContext = React.createContext();

/**
 * makes the auth context in the react context
 * accessible
 * @return {object} WordContext
 */
export function useWords() {
  return useContext(WordContext);
}

/**
 * firebase auth context to be passed down to components
 * @return {object} auth context for firebase
 */


let target_lang = "";
let origin_lang = "";

export function WordProvider({children}) {
  const [loading, setLoading] = useState(false);
  const [allWords, setAllWords] = useState(); 
  const [allDetectorWords, setAllDetectors] = useState();
  const {userConfig,currentUserState} = useAuth();

  function getWordByRank(rank){
    return allWords[rank];
  }

  function getWordByWord(word){
    for(let i = 0; i < allWords.length; i++){
        if(allWords[i].R === word){
            return allWords[i];
        }
    }
    return "";
  }

  function findWordInfoByDetector(word){
    let ret = "";
    for(let i = 0; i < allDetectorWords.length; i++){
        if(allDetectorWords[i].R === word){
            return [allWords[findRanking(allDetectorWords[i].O)],allDetectorWords[i].T];
        }
    }

    // check for last two letters
    if(userConfig.target_lang === "es-ES"){
      let possible_ending = ["le","me","te","nos","lo","las","la"];
      possible_ending.forEach((ending) => {
        if(word.endsWith(ending)){
          let wordC = word.slice(0,-ending.length);
          ret = findWordInfoByDetector(wordC);
        }
      });
    }
    return ret;
  }

  function findWordByDetector(word){
    let ret = "";
    for(let i = 0; i < allDetectorWords.length; i++){
        if(allDetectorWords[i].R === word){
            return allWords[findRanking(allDetectorWords[i].O)];
        }
    }

    // check for last two letters
    if(userConfig.target_lang === "es-ES"){
      let possible_ending = ["le","me","te","nos","lo","las","la"];
      possible_ending.forEach((ending) => {
        if(word.endsWith(ending)){
          let wordC = word.slice(0,-ending.length);
          ret = findWordByDetector(wordC);
        }
      });
    }
    return ret;
  }

  function getAllRelatedWords(word){
    let ret = {}
    for(let i = 0; i < allDetectorWords.length; i++){
        if(allDetectorWords[i].O === word){
            ret[allDetectorWords[i].T] = allDetectorWords[i].R;
        }
    }
    return ret;
  }

  function findRanking(word){
    for(let i = 0; i < allWords.length; i++){
        if(allWords[i].R === word){
            return allWords[i].RA;
        }
    }
    console.log("not found");
    return -1;
  }

  function getCategoryColor(category) {
    switch (category) {
        case "noun":
            return "red";
        case "verb":
            return "cyan";
        case "adverb":
            return "blue";
        case "adjective":
            return "lime";
        case "preposition":
            return "green";
        case "prep":
            return "green";
        case "conjunction":
            return "green";
        case "particle":
            return "amber";
        case "misc":
            return "amber";
        case "pronoun":
            return "lime";
        case "pron":
            return "lime";
        case "expression":
            return "teal";
        case "cardinal number":
            return "orange";
        default:
            return "slate";
    }
}

  useEffect(() => {
    async function fetchData() {
        const data = await getDoc(doc(getFirestore(),"words",userConfig.target_lang));
        setAllWords(data.data().wRank);
        const wData = await getDoc(doc(getFirestore(),"words",userConfig.target_lang+"-d"));
        const wData1 = await getDoc(doc(getFirestore(),"words",userConfig.target_lang+"-d1"));
        let detectorWordsPre = [...wData.data().words,...wData1.data().words];
        detectorWordsPre.forEach((item,index) => {
          item.id = index;
          detectorWordsPre[index] = item;
        }); 
        setAllDetectors(detectorWordsPre); // limit to 100 entries in list
        target_lang = userConfig.target_lang;
        origin_lang = userConfig.origin_lang;

        setLoading(false);
    }
    if(currentUserState){
      if(currentUserState.hasOwnProperty("admin")){
        if(currentUserState.admin){
          if(userConfig.target_lang !== target_lang || userConfig.origin_lang !== origin_lang){
            setLoading(true);
            fetchData();
          }
        }
      }
    }
    
  }, [userConfig]);

  const value = {
    allWords,
    allDetectorWords,
    setAllWords,
    setAllDetectors,
    findWordByDetector,
    getCategoryColor,
    getWordByRank,
    getAllRelatedWords,
    getWordByWord,
    findWordInfoByDetector
  };

  return (
    <WordContext.Provider value={value}>
      {loading?<LoadingScreen text={"loading words"}/>:children}
    </WordContext.Provider>
  );
}

WordProvider.propTypes = {
  children: PropTypes.node,
};
