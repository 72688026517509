import React, { useEffect,useState, useRef} from 'react';
import Navbar from '../navbar/Navbar';
import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { where, getDocs,doc,addDoc, query, collection } from "firebase/firestore";
import { Dialog } from '@headlessui/react'
import { FcGoogle } from 'react-icons/fc';
import { auth } from '../firebase';
import { LanguageDropDown } from '../navbar/LanguageDropDown';
import { useAuth } from '../auth/AuthContext';
import { httpsCallable, getFunctions } from "firebase/functions";
import { db } from '../firebase.js';
import { StateButton, STATES } from '../util/StateButton';

function DeleteDialog({show,close}) {
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const {clearLocalStorage} = useAuth();

    function del(){
        const auth = getAuth();
        const user = auth.currentUser;
        console.log(user.email);

        if (!passwordRef.current.value) {
            return setError('Password not provided');
        }
        
        const credential = EmailAuthProvider.credential(user.email, passwordRef.current.value);
        reauthenticateWithCredential(user, credential).then(() => {
            deleteUser(user).then(() => {
                clearLocalStorage();
                window.location.reload(false);
            }).catch((error) => {
                console.log(error);
                alert("an error occured, try it later again");
            });
        }).catch((error) => {
            console.log(error);
            alert("an error occured, try it later again");
        });
        
    }

    async function delGoogle(){
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            // The signed-in user info.
            const user = result.user;

            reauthenticateWithCredential(user, credential).then(() => {
                deleteUser(user).then(() => {
                    clearLocalStorage();
                    window.location.reload(false);
                }).catch((error) => {
                    console.log(error);
                    alert("an error occured, try it later again");
                });
            }).catch((error) => {
                console.log(error);
                alert("an error occured, try it later again");
            });
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });


        
    }

    return (
        <Dialog open={show} onClose={() => close(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="w-full max-w-sm rounded bg-white dark:bg-slate-900 dark:text-slate-200 text-slate-500  p-4">
            <Dialog.Title className="text-2xl pb-4 text-red-500">Delete account</Dialog.Title>
            <Dialog.Description>
                This will permanently delete your account
            </Dialog.Description>
    
            <p>
                Are you sure you want to delete your account? All of your data
                will be permanently removed. This action cannot be undone.
            </p>
           
            <div className="mt-6">
              <label htmlFor="password" className="mt-3 ">Type in Password</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                placeholder-slate-500  rounded-full focus:outline-none dark:bg-slate-700

                focus:ring-sky-500 focus:border-sky-500 px-3 py-2"
                id="password"
                name="password"
                type="password"
                placeholder="********"
                ref={passwordRef}/>
            </div>
            <p className="mb-4 text-sm font-bold text-red-600"> {error}
            </p>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div onClick={delGoogle} className="cursor-pointer shadow inline flex flex-row justify-center items-center rounded-full bg-red-700 px-4 h-12 mr-3"><FcGoogle className="h-6 w-6"/></div>
                    <button className="rounded-full p-2 bg-red-700 shadow text-white font-bold h-12" onClick={() => del()}>Delete</button>
                </div>
                
                <button className="rounded-full p-2 bg-white dark:bg-slate-700 shadow mx-4 h-12" onClick={() => close(false)}>Cancel</button>
            </div>
            
            
            </Dialog.Panel>
        </div>
      </Dialog>
    )
  }

  function ManageSubscription(){

    const [subscription, setSubscription] = useState();
    const { currentUser, currentUserState } = useAuth();
    const [openCustomerPortalState, setOpenCustomerPortalState] = useState(STATES.STATE_NORMAL);

    useEffect(()=>{
        async function fetchData(){
            let docs = await getDocs(query(collection(db,"customers",currentUser.uid,"subscriptions"),where('status', 'in', ['trialing', 'active'])))
            let doc = docs.docs[0];
            if(doc){
                setSubscription(doc.data());
            console.log(doc.data());
            }

            
        }
        fetchData();
    },[]);

    async function toCustomerPortal(){
        setOpenCustomerPortalState(STATES.STATE_PROCESS);
        const functionRef = httpsCallable(getFunctions(),'ext-firestore-stripe-payments-createPortalLink');
                const { data } = await functionRef({
                returnUrl: window.location.origin,
                locale: "auto", // Optional, defaults to "auto"
                
                });
                window.location.assign(data.url);
    }


    return <div className="flex flex-row my-2 items-center w-full justify-between">
        {subscription?<><div>Subscription</div>
            <div className="flex flex-row items-center">
                <div className="font-bold mr-2">{subscription?subscription.cancel_at_period_end?"ending at":"next invoice":""}</div>
                <div>{subscription.current_period_end?(new Date(subscription.current_period_end.seconds*1000).toLocaleDateString()):""}</div>
                <div className="flex flex-row items-center cursor-pointer bg-sky-500 text-white p-2 m-2 rounded-full" onClick={toCustomerPortal}><StateButton state={openCustomerPortalState}></StateButton>Manage subscription</div>
            </div></>:
        <><div>Free Access until</div>
        {currentUserState.access?<div>{new Date(currentUserState.access).toLocaleDateString()}</div>:<div>unlimited</div>}
        </>
        }
       
        
        </div>;
}
export default function Settings() {
    
    let [isOpen, setIsOpen] = useState(false);

    function setLight(){
        console.log("set light")
        // Whenever the user explicitly chooses light mode
        localStorage.theme = 'light';

    }

    function setDark(){

        console.log("setdark");
        // Whenever the user explicitly chooses dark mode
        localStorage.theme = 'dark';
        
    }


    function setDefault(){

        // Whenever the user explicitly chooses to respect the OS preference
        localStorage.removeItem('theme');
    }


    
  return (
    <div className="dark:bg-slate-900 dark:text-slate-200 text-slate-500 h-screen">
        <DeleteDialog show={isOpen} close={() => {setIsOpen(false)}}></DeleteDialog>
        <Navbar settings={true}></Navbar>
        <div className="container flex flex-col h-full">
            <h1 className="text-3xl mt-8">Information</h1>
            <div className="flex sm:flex-row flex-col justify-between  mt-2">
                <a className="font-bold text-rose-700" href="/company">Company Details</a>
                <a href="https://www.iubenda.com/privacy-policy/46179223" rel="noreferrer" target="_blank" className="font-bold text-sky-500" title="Privacy Policy ">Privacy Policy</a>
                <a href="https://www.iubenda.com/terms-and-conditions/46179223" rel="noreferrer"  target="_blank" className="font-bold text-sky-500" title="Privacy Policy ">Terms and Conditions</a>
                <a href="https://www.iubenda.com/privacy-policy/46179223/cookie-policy" rel="noreferrer"  target="_blank" className="font-bold text-sky-500" title="Privacy Policy ">Cookie Policy</a>
            </div>
            
            <h1 className="text-3xl mt-8">Settings</h1>
            {/*<p className="text-xl mt-8">Subscription Settings</p>
            <hr></hr>
            <div className="flex flex-row justify-between">
                <div>Plan</div>
                <div className=" rounded-full p-2">Monthly 3.99$</div>
            </div>
            <div className="flex flex-row justify-between">
                <div>Member since</div>
                <div className=" rounded-full p-2">12.22.1998</div>
            </div>
            <div className="flex flex-row justify-between my-2">
                <div>cancel subscription</div>
                <div className=" rounded-full p-2 bg-red-500 text-white">Cancel subscription</div>
            </div>*/}
            <p className="text-xl mt-8">Account Settings</p>
            <hr className="dark:bg-slate-700 dark:border-slate-700"></hr>
            <ManageSubscription></ManageSubscription>
            <div className="flex flex-row items-center justify-between my-2">
                <div>Language</div>
                <LanguageDropDown></LanguageDropDown>
            </div>
            <div className="flex flex-row justify-between my-2">
                <div>Delete account</div>
                <div onClick={()=>setIsOpen(true)} className="cursor-pointer  rounded-full p-2 bg-red-500 text-white">Delete account</div>
            </div>
        </div>
    </div>
  );
}
