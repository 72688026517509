/* eslint-disable linebreak-style */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useRef, useState, useEffect} from 'react';
import {useAuth} from './AuthContext';
import {useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

/**
 * component with all signup functionality
 * @return {object} jsx sign in component
 */
export default function PasswordForgotten() {
  const emailRef = useRef();
  const {currentUser} = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(currentUser){
      navigate("/learn");
    }
  },[]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!emailRef.current.value) {
      return setError('email not provided');
    }

    try {
      setError('');
      setLoading(true);
      const auth = getAuth();
      sendPasswordResetEmail(auth, emailRef.current.value)
        .then(() => {
          setSuccess("email sent!");
          // Password reset email sent!
          // ..
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    } catch (e) {
      console.log(e);
      setError('No signin possible');
    }

    setLoading(false);
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="text-center text-3xl font-extrabold text-sky-500">Password Reset</h2>
        <div className="">
          <form onSubmit={handleSubmit}>
            <p className="text-center text-sm font-bold text-red-800"> {error}
            </p>
            <p className="text-center text-sm font-bold text-green-500"> {success}
            </p>
            <div>
              <label htmlFor="email-address" className='text-slate-700'>Email address</label>
              <input
                className="mt-2 appearance-none rounded-full relative block w-full border border-slate-300
                        placeholder-slate-500 text-slate-500 rounded-full focus:outline-none
                        focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="email@mail.com"
                ref={emailRef}/>
            </div>
       
            <div className="flex justify-between">
              <button disabled={loading} className="mt-6 rounded-full block w-full bg-sky-500 text-center text-white py-3">Send Reset Email</button>
            </div>
          </form>
        </div>
        <div className="flex justify-between">
          <a href="/signIn" className="font-medium text-sky-500 hover:text-sky-300">Sign in</a>

          <a href="/signUp" className="font-medium text-sky-500 hover:text-sky-300">Sign up</a>
        </div>
      </div>
    </div>
  );
}
