import React from 'react'
import EditorNavbar from '../editor/EditorNavbar'
import Bots from './Bots'
import {IoArrowBackOutline} from 'react-icons/io5';
import { useNavigate } from 'react-router';

export default function BotEditor() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen w-full">
      <div className="relative z-50 w-full py-2 h-14 items-center flex text-slate-600 flex-row justify-start border-slate-200 border-dotted border-b dark:bg-slate-900 dark:border-slate-700">
        <div className="flex flex-row mr-4">
            <IoArrowBackOutline onClick={()=>{navigate('/chat');}} className="cursor-pointer text-sky-500 h-6 w-6 ml-6"/>
        </div>
    </div>
      <Bots></Bots>
    </div>
  )
}
