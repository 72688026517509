import React, { useState, useEffect } from "react";
import Navbar from "../AILearn/Navbar";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from "../auth/AuthContext";
import { JapaneseWords } from "./Japanese";

import AudioPlayer from "../util/AudioPlayer";

import {
  IoAdd,
  IoChatbubblesOutline,
  IoLogoYoutube,
  IoRepeat,
  IoLanguage,
  IoCheckmarkDoneOutline,
} from "react-icons/io5";
import { StateButton, STATES } from "../util/StateButton";
import { doc, setDoc, updateDoc, getDoc, getFirestore } from "firebase/firestore"; 

import { db } from '../firebase.js';

const STATE_LOADING = "loading";
const STATE_NO_ACCESS = "noAccess";
const STATE_START = "start";
const STATE_NEXT = "next";
const STATE_INIT = "init";

const PASS_ENGLISH = 0;
const PASS_DECODED = 1;
const PASS_ROMANJI = 2;
const PASS_KANA = 3;
const PASS_KANJI = 4;
const PASS_DONE = 5;

const JP_SHORT = "jp-JP";

const IGNORE_LIST = [
  "！",
  "は",
  "。",
  "、",
  "の",
  "を",
  "が",
  "「",
  "」",
  "（",
  "）",
  ":",
];

export function JapaneseWord({ word, key, pass, onTouched, reset }) {
  let [state, setState] = useState(pass);

  useEffect(() => {
    IGNORE_LIST.includes(word[0]) || word[3] === "-"
      ? pass === PASS_DECODED
        ? setState(PASS_ROMANJI)
        : setState(PASS_KANA)
      : setState(pass);
  }, [pass]);

  useEffect(() => {
    IGNORE_LIST.includes(word[0]) || word[3] === "-"
      ? pass === PASS_DECODED
        ? setState(PASS_ROMANJI)
        : setState(PASS_KANA)
      : setState(pass);
  }, [reset]);

  return (
    <div
      className="inline-block mr-2 inline-flex flex-col cursor-pointer dark:text-slate-200 hover:bg-black/20 dark:hover:bg-white/20"
      key={key}
      onClick={() => {
        onTouched();
        if (state === PASS_KANJI) {
          setState(PASS_KANA);
        } else if (state === PASS_KANA) {
          setState(PASS_ROMANJI);
        } else if (state === PASS_ROMANJI) {
          setState(PASS_DECODED);
        } else if (state === PASS_DECODED) {
          setState(PASS_KANJI);
        }
      }}
    >
      <div>
        {state === PASS_KANJI ? (
          <div className="whitespace-nowrap font-bold">{word[0]}</div>
        ) : (
          ""
        )}
        {state === PASS_KANA ? (
          <div className="whitespace-nowrap ">{word[1]}&#xfeff;</div>
        ) : (
          ""
        )}
        {state === PASS_ROMANJI ? (
          <div className="whitespace-nowrap ">{word[2]}&#xfeff;</div>
        ) : (
          ""
        )}
        {state === PASS_DECODED ? (
          <div className="whitespace-nowrap font-thin text-slate-600 dark:text-slate-100 bg-slate-100 dark:bg-slate-800 hover:bg-slate-200 dark:hover:bg-white/20">
            {word[3]}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export function JapaneseLine({ text, translation, pass, onTouched, reset, key }) {
  const functions = getFunctions();
  const getJapaneseSentenceExplanation = httpsCallable(
    functions,
    "getJapaneseSentenceExplanation"
  );
  const [content, setContent] = useState();

  useEffect(() => {
    async function fetch() {
      let temp = await getJapaneseSentenceExplanation({ sentence: text });
      setContent(JSON.parse(temp.data));
    }
    fetch();
  }, [text]);

  return (
    <div key={key}>
      
      <div className="text-3xl">
       
        {content?.map((item, i) => {
          return (
            
            <JapaneseWord
              onTouched={onTouched}
              pass={pass}
              key={i+"word"}
              reset={reset}
              word={item}
            />

          );
        })}
      </div>
    </div>
  );
}

let lastPass = PASS_ENGLISH;
let lastParam = null;


function Paragraph({ paragraph, onNext, onMistake }) {
  const functions = getFunctions();
  const getAudioLink = httpsCallable(functions, "getAudioLink");
  const [showTranslation, setShowTranslation] = useState(false);
  const [audioLink, setAudioLink] = useState("");

  const [repeat, setRepeat] = useState(false);

  const [pass, setPass] = useState(paragraph.pass);
  const [reset, setReset] = useState(false);

  const [sentences, setSentences] = useState([paragraph.text]);

  useEffect(() => {
    console.log(paragraph);
    async function fetch() {
      console.log("get new audio link");
      setSentences([paragraph.text]);
      let gender = "female";
      let voice = "ja-JP-Wavenet-A";
      let temp = await getAudioLink({
        text: paragraph.text,
        lC: JP_SHORT,
        voice: voice,
        gender: gender,
      });
      console.log(temp.data.link);
      setAudioLink(temp.data.link);
      setPass(paragraph.pass);
    }
    
    
    fetch()
  }, [paragraph]);

  return (
    <div className={`mt-8`}>
      <div className="dark:text-slate-400">
        {pass === PASS_ENGLISH ? "read the english" : ""}
        {pass === PASS_DECODED ? "read the decoded version" : ""}
        {pass === PASS_ROMANJI ? "can you understand the romanji?" : ""}
        {pass === PASS_KANA ? "try to read the Kana" : ""}
        {pass === PASS_KANJI ? "decypher the Kanji to finish" : ""}
      </div>
      {(pass === PASS_ENGLISH || showTranslation) && paragraph.pass !== PASS_KANJI? (
        <div className="text-3xl dark:text-slate-100">
          {paragraph.translation}
        </div>
      ) : (
        <div>
          {sentences.map((sentence,index) => {
            return (
              <JapaneseLine
                onTouched={() => {
                  setRepeat(true);
                  onMistake(sentence);
                }}
                reset={reset}
                text={sentence}
                pass={pass}
                key={index+"sentence"}
              ></JapaneseLine>
            );
          })}
        </div>
      )}
      <div className="flex flex-row justify-between  items-center gap-2 mt-4">
        <div className="flex flex-row items-center gap-2 mt-4">
          <IoLanguage
            onClick={() => {
              setShowTranslation(!showTranslation);
            }}
            className="cursor-pointer w-8 h-8 p-1 bg-black/10 dark:bg-white/10 rounded-full text-black/40 dark:text-white/40"
          ></IoLanguage>
          {audioLink ? (
            <AudioPlayer
              inactive={400}
              color={"gray"}
              size={6}
              audio={audioLink}
              play={false}
            ></AudioPlayer>
          ) : (
            ""
          )}
        </div>
        {repeat ? (
          <div>
            <IoRepeat
              className="text-green-500 cursor-pointer w-8 h-8"
              onClick={() => {
                setRepeat(false);
                setReset(!reset);
              }}
            ></IoRepeat>
          </div>
        ) : (
          <div>
            {pass === PASS_ENGLISH ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_DECODED;
                  setPass(PASS_DECODED);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_DECODED ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_ROMANJI;
                  setPass(PASS_ROMANJI);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_ROMANJI ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_KANA;
                  setPass(PASS_KANA);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_KANA ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  lastPass = PASS_KANJI;
                  setPass(PASS_KANJI);
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
            {pass === PASS_KANJI ? (
              <IoCheckmarkDoneOutline
                className="text-green-500 cursor-pointer w-8 h-8"
                onClick={() => {
                  onNext();
                }}
              ></IoCheckmarkDoneOutline>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}

let current = 0;
let paragraphs = [];

let mistakes = {};

export default function ReadSentences() {
  const functions = getFunctions();
  const getSentences = httpsCallable(functions, "getSentences");

  const [currentWord, setCurrentWord] = useState(0);
  const [currentParagraph, setCurrentParagraph] = useState(0);
  const [sentenceCount, setSentenceCount] = useState(0);

  const [state, setState] = useState(STATE_LOADING);

  const { currentUserState, userConfig, currentUser } = useAuth();
  const [xp, setXP] = useState(currentUserState.xp);
  const [role, setRole] = useState();
  const [paragraphState, setParagraphState] = useState(STATES.NORMAL);
  const [newparagraphState, setNewParagraphState] = useState(STATES.NORMAL);

  console.log(currentUser.uid);

  function parseJapanese(text) {

    let allSentences = JSON.parse(text);


    let pp = [];

    allSentences.forEach((sentence) => {
      pp.push({
        text: sentence[0],
        translation: sentence[1],
        pass: PASS_ENGLISH,
      });
    });

    return pp;
  }

  useEffect(() => {
    let jp = "";
    JapaneseWords.forEach((word) => {
      jp += word[0] + ",";
    });
    console.log(jp);
    fetch();
  }, []);

  async function fetch() {
    let data = await getUserData();
    
    const params = {
      botId: "HcVm5gME0IrlzSunqGRw",
      targetLang: "Japanese",
      sourceLang: "English",
      paragraphs: [],
      currentWord: JapaneseWords[currentWord][0],
    };

    lastParam = params;

    if(data.params){
      console.log(data);
      params.currentWord = data.params.currentWord;
      setCurrentWord(data.currentWord);
      current = data.currentWord;
      setCurrentWord(current)
      if(data.sentenceCount){
        setSentenceCount(data.sentenceCount);
      }
    }
    
    const result = await getSentences(params);
    console.log(result.data);

    paragraphs = [...paragraphs,...parseJapanese(result.data)];

    setState(STATE_START);
  }

  // function get userconfig with uid from firebase 
  async function getUserData(){
    const userRef = doc(db, "user_config", currentUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
  }

  // save last query params to firebase and currentWord
  async function saveProgress(params, num, count) {
    console.log("save progress", params, num, count);
    const userRef = doc(db, "user_config", currentUser.uid);
    await updateDoc(userRef, {
      currentWord: num,
      params: params,
      sentenceCount: count,
    });
  }

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  async function getNextParagraph() {
    setParagraphState(STATES.PROCESS);
    const params = {
      botId: "HcVm5gME0IrlzSunqGRw",
      targetLang: "Japanese",
      sourceLang: "English",
      mistakes: mistakes,
      paragraphs: paragraphs.length > 4 ? paragraphs.slice(-4) : paragraphs,
      currentWord: JapaneseWords[current][0],
    };

    lastParam = params;


    saveProgress(params, current, sentenceCount);

    const result = await getSentences(params);
    // if second last character is a comma remove it
    if (result.data[result.data.length - 2] === ",") {
      result.data = result.data.slice(0, -2) + result.data.slice(-1);
    }
    console.log(result.data);
    setParagraphState(STATES.NORMAL);

    let pp = parseJapanese(result.data);

    

    let prev = getPreviousParagraphs();
    prev = prev.filter((p,index) => {if(index > 4){ return false}else{ return true}});
    prev = prev.map((p) => {
      p.pass = PASS_KANJI;
      return p;
    });

    let mix = [...prev, ...pp];

    mix = shuffle(mix);

    

    paragraphs = [...paragraphs,...mix];
    setCurrentParagraph(currentParagraph+1);

    console.log(mistakes);

    mistakes = {};

    setState(STATE_START);
  }

  // get last 8 sentences without the once with mistakes
  function getPreviousParagraphs() {
    return paragraphs.slice(-8).filter((p) => {
      return !mistakes[p.text];
    });
  }

  function onNext() {
    console.log(currentParagraph, paragraphs.length, currentParagraph < JapaneseWords.length - 1);
    setSentenceCount(sentenceCount+1);
    if (currentParagraph < paragraphs.length - 1) {
      setCurrentParagraph(currentParagraph+1);
      saveProgress(lastParam, current, sentenceCount);
    } else {
      saveProgress(lastParam, current, sentenceCount);
      setState(STATE_LOADING);
      current = current + 1;
      setCurrentWord(current);
      getNextParagraph();
    }
  }

  return (
    <div className="flex flex-col h-screen w-full boring-bg select-none sm:select-auto dark:bg-slate-900">
      <Navbar role={role} xp={xp} percentage={`${0 * 100}%`}></Navbar>
      <div className="flex flex-row w-full flex-1 overflow-hidden">
        <div className="flex flex-col justify-between flex-1">
          <div className="w-full flex flex-col h-full overflow-visible pb-2">
            <div className="relative w-full h-full overflow-scroll">
              <div className="mx-10 flex flex-col items-center justify-center h-full">
                {state === STATE_LOADING ? (
                  "Loading..."
                ) : state === STATE_START ? (
                  <>
                  <div className="max-w-4xl w-full mx-auto dark:text-slate-300 text-xl">Level {currentWord+1}</div>
                  <div className="max-w-4xl w-full mx-auto dark:text-slate-400 text-sm mb-2">Sentence {sentenceCount}</div>
                  <div className="flex flex-col p-4 max-w-4xl w-full mx-auto border rounded-lg border-slate-600">
                    {paragraphs[currentParagraph]?<Paragraph onMistake={(text)=>{
                      if(!mistakes[text]){
                        mistakes[text] = 1;
                      }else{
                        mistakes[text] = mistakes[text]+1;
                      }
                    }} paragraph={paragraphs[currentParagraph]} onNext={()=>{onNext()}}></Paragraph>:""}
                    
                    <div className="flex flex-row gasp-8 hidden">
                    <StateButton state={paragraphState}>
                      {" "}
                      <div
                        className="mt-10 flex flex-row items-center text-gray-500 cursor-pointer"
                        onClick={() => {
                          getNextParagraph();
                        }}
                      >
                        <IoAdd className="w-6 h-6 mr-1"></IoAdd>More
                      </div>
                    </StateButton>

                    <StateButton state={newparagraphState}>
                      {" "}
                      <div
                        className="mt-10 flex flex-row items-center text-gray-500 cursor-pointer"
                        onClick={() => {
                          current = current + 1;
                          setCurrentWord(current);
                          getNextParagraph();
                        }}
                      >
                        <IoAdd className="w-6 h-6 mr-1"></IoAdd>Next Word
                      </div>
                    </StateButton>
                    </div>
                  </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
