import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import Level from './Level';
import Words from './Words';
import sparkMd5 from 'spark-md5';
import { useAuth } from '../../auth/AuthContext';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function Levels() {

  const [currentLevel, setCurrentLevel] = useState();
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const newNameRef = useRef();
  const [levelIndex, setLevelIndex] = useState();
  const {userConfig} = useAuth();

  async function newLevel(){
    if(newNameRef.current.value){
      const docRef = await addDoc(collection(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`), {
        name: newNameRef.current.value,
        allVocab: [0],
        states: [{
          text: 'быть',
          hash: sparkMd5.hash('быть'),
          direction: 0
        }]
      });

      const docSnap = await getDoc(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.ids.push(docRef.id);
        data.names.push(newNameRef.current.value);
        data.vocab[docRef.id] = [8]
        await setDoc(doc(getFirestore(), "levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
          ids: data.ids, 
          names: data.names, 
          vocab: data.vocab
        });

        newNameRef.current.value = "";

        setCurrentLevel({
          name: newNameRef.current.value,
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"levels","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
        let templevels = [];
        let data = doc.data();

        if(!data.hasOwnProperty("ids")){
          setLoading(false);
          return;
        }
        
        for(let i = 0; i < data.ids.length; i++){
          templevels.push({"id": data.ids[i], "name": data.names[i], "le": i});
        };
        if(data.lastChange){
          setCurrentLevel(templevels[data.lastChange]);
        }else{
          setCurrentLevel(templevels[0]);
        }
        
        setLevelIndex(data);
        setLevels(templevels);
        setLoading(false);
    });
  }, [userConfig]);

  return (
    <div className="flex flex-row w-full flex-1 overflow-hidden">
      {!loading && 
      <><Sidebar>
      {levels.map((temp) => (
        <div key={temp.id} onClick={() => {setCurrentLevel(JSON.parse(JSON.stringify(temp)));}}>
          <SidebarElement name={temp.name} current={currentLevel.name}></SidebarElement>
        </div>
      ))}
      <div className="flex flex-row border-b border-slate-200 bg-green-100">
      <input type="text" placeholder="name" className="no-outline flex-1 min-w-0 p-4 bg-green-100" ref={newNameRef} />
        <AiOutlinePlus onClick={newLevel} className="cursor-pointer h-6 w-6 text-green-500 m-4"/>  
      </div>         
    </Sidebar>
    <Words></Words>
    <Level lE={currentLevel.le} levelName={currentLevel.name} levelId={currentLevel.id} levelIndex={levelIndex}/>
      </>
        }
    </div>
  );
}
