
import React, {useRef, useState, useEffect} from 'react';
import { collection, query, getDocs, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 

import { 
   IoSearchOutline, 
   IoCloseOutline} from 'react-icons/io5';
import EditorNavbar from '../editor/EditorNavbar';
import { useAuth } from '../auth/AuthContext';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

// local database for words and detectors
// us synchronized with the sever on save

export default function UserDashboard() {

  const {userConfig} = useAuth();

  const [loadingUserData, setLoadingUserData] = useState(true);
  
  const searchRef = useRef(null);
  const [searchStr, setSearchStr] = useState("");

  const [infoList, setInfoList] = useState([]);
  const [userNumber, setUserNumber] = useState(0);

  // set local database once words and detectors are loaded
  // display everything by putting it in the hook
  useEffect(() => {
    async function fetchData() {
        let usersData = [];
        let userConfigData = [];
        
        const usersSnapshot = await getDocs(collection(getFirestore(),"users"));

        const userConfigSnapshot = await getDocs(collection(getFirestore(),"user_config"));
        usersSnapshot.forEach((item) => {
            usersData.push(item.data());
        });
        
        userConfigSnapshot.forEach((item) => {
            userConfigData.push(item.data());
        });

        let showData = [];

        for(let i = 0; i < usersData.length; i++){
            showData.push([usersData[i].xp,userConfigData[i].target_lang,userConfigData[i].stories.length])
        }

        setInfoList(showData);

        console.log(usersData,userConfigData);
        
        setLoadingUserData(false);
    }
    fetchData();
  }, [userConfig]);

  function searchChange(srStr){
    if(srStr.length === 0){
      stopSearch();
    }else{
      //let filteredWords = filterWords(srStr);
      //let firstElement = filteredWords[0];
      //let filteredDetectorWords = filterDetectorWords(firstElement);
      setSearchStr(srStr);
      //setDetectors(filteredDetectorWords);
      //setWords(filteredWords);
    }
    
  }

  function filterWords(srStr){
    /*let res = [...allWords.filter((item) => {
      if(srStr.length > 0){
        if(item.E.includes(srStr)){
          return true;
        } 
      }else{
        return true;
      }
    })];

    return res;*/
  }


  function stopSearch(){
    searchRef.current.value = "";
    setSearchStr("");
  }

  return (
    <div className="flex flex-col w-full flex-1 overflow-hidden">
      <div className="flex flex-row">
      <EditorNavbar/>
      
      <div className="h-16 flex flex-row w-full items-center border-slate-200 border-b p-2 justify-end h-16">
      <div className="text-amber-500 font-bold text-xl mr-4">{infoList.length} users</div>
        <div className="flex flex-row items-center basis-1/3 border rounded-full bg-white border-slate-200">
          <input onChange={(event) => {searchChange(event.target.value)}} type="text" className="flex flex-1 rounded-full bg-white outline-none focus:outline-none p-2" ref={searchRef}></input>
          {searchStr.length>0?<IoCloseOutline onClick={stopSearch} className="cursor-pointer flex text-slate-500 h-6 w-6 mr-3"/>:<IoSearchOutline className="flex text-slate-500 h-6 w-6 mr-3"/>}
        </div>
      </div>
      </div>
      
      {!loadingUserData?  
      <div className="flex flex-row flex-1 overflow-hidden">
        <div className="overflow-auto flex-1">
            {infoList.map((item) => (
                <div className="flex flex-row">
                    <div className="w-16">{item[0]}</div>
                    <div className="w-16">{item[1]}</div>
                    <div className="w-16">{item[2]}</div>
                    <div className="w-16">{item[3]}</div>
                </div>
            ))}
            
        </div>
      </div>
       :<div>Loading...</div> }
    </div>
  );
}