/* eslint-disable no-unused-vars */
import React, {useContext, useState, useEffect} from 'react';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import {auth, db} from '../firebase.js';
import PropTypes from 'prop-types';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import LoadingScreen from '../util/LoadingScreen.js';

const AuthContext = React.createContext();

/**
 * makes the auth context in the react context
 * accessible
 * @return {object} authContext
 */
export function useAuth() {
  return useContext(AuthContext);
}

/**
 * firebase auth context to be passed down to components
 * @return {object} auth context for firebase
 */

let unSub;

export const USER_STORAGE_CURRENT_USER_STATE = "user_storage_current_user_state";
export const USER_STORAGE_USER_CONFIG = "user_storage_user_config";

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [currentUserState, setCurrentUserState] = useState(); 
  const [userConfig, setUserConfig] = useState();

  /**
   * signs up a user with the firebase auth function
   * @param {string} email
   * @param {string} password
   * @return {object} firebase auth object
   */
  function signup(email, password) {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password);
  }

  /**
   * logs the user with firebase out
   * @return {object} logout promise
   */
  function logout() {
    if(unSub){
      unSub();
    }
    setCurrentUser(null);
    setCurrentUserState(null);
    clearLocalStorage();
    return auth.signOut();
  }

  /**
   * signs in user from with firebase
   * @param {string} email
   * @param {string} password
   * @return {object} signIn object from firebase
   */
  async function signIn(email, password) {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return true;
    } catch(e){
      setLoading(false);
      return false;
    }
  }

  function signInWithGoogle(){
    setLoading(true);
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  function clearLocalStorage(){
    localStorage.removeItem(USER_STORAGE_CURRENT_USER_STATE);
    localStorage.removeItem(USER_STORAGE_USER_CONFIG);
  }

  useEffect(() => {
    let unsub1 = auth.onAuthStateChanged(async (user) => {
      
      if (user != null) {
        setCurrentUser(user);
        if(localStorage.getItem(USER_STORAGE_CURRENT_USER_STATE) && localStorage.getItem(USER_STORAGE_CURRENT_USER_STATE)){
          setCurrentUserState(JSON.parse(localStorage.getItem(USER_STORAGE_CURRENT_USER_STATE)));
          setUserConfig(JSON.parse(localStorage.getItem(USER_STORAGE_USER_CONFIG)));
          setLoading(false);
        }

        let userDoc = await getDoc(doc(db,"users",user.uid));
        const currentUserData = userDoc.data();
        if (userDoc.exists()) {
          if(JSON.parse(localStorage.getItem(USER_STORAGE_CURRENT_USER_STATE))){
            if(JSON.parse(localStorage.getItem(USER_STORAGE_CURRENT_USER_STATE)).access !== currentUserData.access){
              setCurrentUserState(currentUserData);
            }
          }else{
            setCurrentUserState(currentUserData);
          }
          
          localStorage.setItem(USER_STORAGE_CURRENT_USER_STATE, JSON.stringify(currentUserData));
        }

        unSub = onSnapshot(doc(db,"user_config",auth.currentUser.uid+""), (doc) =>{
          if (doc.exists()) {
            let data = doc.data();
    
            if(localStorage.getItem(USER_STORAGE_USER_CONFIG) !== JSON.stringify(data)){
              setUserConfig(data);
              localStorage.setItem(USER_STORAGE_USER_CONFIG, JSON.stringify(data));
            }
            
            setLoading(false);
          }
        });
        
        
      }else{
        clearLocalStorage();
        setCurrentUserState(null);
        setCurrentUser(null);
        setUserConfig(null);
        setLoading(false);
      }
    });

    return ()=>{
      unSub();
      unsub1();
    }
  }, []);

  async function reloadUserData(uid){
    console.log("reload user");
    let userDoc = await getDoc(doc(db,"users",uid));
        if (userDoc.exists()) {
          setCurrentUserState(userDoc.data());
          localStorage.setItem(USER_STORAGE_CURRENT_USER_STATE, JSON.stringify(userDoc.data()));
        } else {
          // doc.data() will be undefined in this case
        }
      let userConfigDoc = await getDoc(doc(db,"user_config",uid));
        if (userConfigDoc.exists()) {
          setUserConfig(userConfigDoc.data());
          localStorage.setItem(USER_STORAGE_USER_CONFIG, JSON.stringify(userConfigDoc.data()));
        } else {
          // doc.data() will be undefined in this case
        }
      return;
  }

  const value = {
    currentUser,
    currentUserState,
    userConfig,
    signup,
    signIn,
    logout,
    signInWithGoogle,
    clearLocalStorage,
    reloadUserData
  };

  return (
    <AuthContext.Provider value={value}>
      {loading?<LoadingScreen text={"Authenticating"}/>:children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
