import { useState, useRef, useEffect } from 'react'
import { setDoc, doc, getDoc, addDoc , getFirestore, collection  } from "firebase/firestore";
import { Dialog } from '@headlessui/react'
import sparkMd5 from 'spark-md5';
import { 
  IoExtensionPuzzle,
  IoCloseOutline,
  IoSearchOutline,
  IoAddOutline
} from 'react-icons/io5';
import WordItem from './Level/WordItem';
import { EditorSpeechBubble } from './EditorSpeechBubble';
import { useWords } from './Words/WordContext';
import { useAuth } from '../auth/AuthContext';
import { getFunctions, httpsCallable } from "firebase/functions";
import { STATES, StateButton } from '../util/StateButton';

let newName = "";

export function BlockChooser({blocks,generator,context,selectedBlock,isOpen,setIsOpen}) {

  const searchRef = useRef(null);
  const [searchStr, setSearchStr] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [words, setWords] = useState(context);
  const [states, setStates] = useState([]);

  const functions = getFunctions();
  const translate = httpsCallable(functions, 'translate');
  const generateBlockAudio = httpsCallable(functions, 'generateBlockAudio');

  const [safeState, setSafeState] = useState(STATES.STATE_NORMAL);
  const [generateState, setGenerateState] = useState(STATES.STATE_NORMAL);

  const {findWordInfoByDetector, getAllRelatedWords} = useWords();
  const {userConfig} = useAuth();
  
  useEffect(()=>{
    if(context){
      setWords(wordAnalytics(context));
    }
  },[context]);

  function searchChange(srStr){
    setSearchStr(srStr);
    setSearchResult(blocks.filter((item)=>{
      if(item.name.toLowerCase().includes(srStr.toLowerCase())){
        return true;
      }else if(item.id === srStr){
        return true;
      }else{
        return false;
      }
    }));
  }

  function contextChange(srStr){
    setWords(wordAnalytics(srStr));
  }

  async function generateBlock(wdo){

    setGenerateState(STATES.STATE_PROCESS);

    switch(userConfig.target_lang+"-"+userConfig.origin_lang){
      case "ru-RU-en-US":
        switch(wdo[0].T[0]){
          case "v":
            await generateBlockVerbUSRU1(wdo);
            setGenerateState(STATES.STATE_NORMAL);
            break;
          default:
            setStates([{
              text: `unsupported word type "${wdo[0].T[0]}"`,
              direction: 2
            }]);
            break;
        }
        break;
      case "es-ES-en-US":
        switch(wdo[0].T[0]){
          case "v":
            await generateBlockVerbUSES(wdo);
            setGenerateState(STATES.STATE_NORMAL);
            break;
          default:
            setStates([{
              text: `unsupported word type "${wdo[0].T[0]}"`,
              direction: 2
            }]);
            setGenerateState(STATES.STATE_ERROR);
            break;
        }
        break;
      default:
        setStates([{
          text: `unsupported language pair "${userConfig.target_lang+"-"+userConfig.origin_lang}"`,
          direction: 2
        }]);
        setGenerateState(STATES.STATE_ERROR);
        break;
    }
  }
  
  async function generateBlockVerbUSES(wdo){
    let wordObj = wdo[0];
    newName = "to "+wordObj.E+ " - " + wordObj.R;

    let pronounDict = {};

    let txt = "";

    if(wdo[1][1] === "N" || wdo[1].length === 2){
      txt = `let's repeat the word "to ${wordObj.E}", in Spanish you say`;
      if(wordObj.A === "R"){
        
        pronounDict = {
          "VN1":["me"],
          "VN2":["te"],
          "VN3":["se"],
          "VNW":["nos"],
          "VNT":["os"],
          "VNF":["se"],
        };
      }else{
        pronounDict = {
          "VN1":["yo"],
          "VN2":["tú"],
          "VN3":["usted","él","ella"],
          "VNW":["nosotros"],
          "VNT":["vosotros"],
          "VNF":["ustedes","ellas","ellos"],
        };
      }
    }else if (wdo[1][1] === "P"){
      newName += " preterite";
      txt = `let's repeat the word "to ${wordObj.E}" in the preterite tense. The infinitive is:`;
      if(wordObj.A === "R"){
        pronounDict = {
          "VP1":["me"],
          "VP2":["te"],
          "VP3":["se"],
          "VPW":["nos"],
          "VPT":["os"],
          "VPF":["se"],
        };
      }else{
        pronounDict = {
          "VP1":["yo"],
          "VP2":["tú"],
          "VP3":["usted","él","ella"],
          "VPW":["nosotros"],
          "VPT":["vosotros"],
          "VPF":["ustedes","ellas","ellos"],
        };
      }
    } else if (wdo[1][1] === "F"){
      newName += " future";
      txt = `let's repeat the word "to ${wordObj.E}" in the future simple tense. The infinitive is:`;
      pronounDict = {
        "VF1":["yo"],
        "VF2":["tú"],
        "VF3":["usted","él","ella"],
        "VFW":["nosotros"],
        "VFT":["vosotros"],
        "VFF":["ustedes","ellas","ellos"],
      };
    }else if (wdo[1][1] === "G"){
      newName += " gerund";
      txt = `let's repeat the gerund of "to ${wordObj.E}". The infinitive is:`;
      pronounDict = {
        "VG":["yo estoy","tú estas","él esta","ella esta"],
      };
    } else if (wdo[1][1] === "S"){
      newName += " subjuntivo";
      txt = `let's repeat the word "to ${wordObj.E}" in the future simple tense. The infinitive is:`;
      console.log("word a", wordObj, wordObj);
      if(wordObj.A === "R"){
        pronounDict = {
          "VS1":["me"],
          "VS2":["te"],
          "VS3":["se"],
          "VSW":["nos"],
          "VST":["os"],
          "VSF":["se"],
        };
      }else{
        pronounDict = {
          "VS1":["yo"],
          "VS2":["tú"],
          "VS3":["usted","él","ella"],
          "VSW":["nosotros"],
          "VST":["vosotros"],
          "VSF":["ustedes","ellas","ellos"],
        };
      }
    } else if (wdo[1][1] === "C"){
      newName += " condicional";
      txt = `let's repeat the word "to ${wordObj.E}" in the conditional tense. The infinitive is:`;
      console.log("word a", wordObj, wordObj);
      if(wordObj.A === "R"){
        pronounDict = {
          "VC1":["me"],
          "VC2":["te"],
          "VC3":["se"],
          "VCW":["nos"],
          "VCT":["os"],
          "VCF":["se"],
        };
      }else{
        pronounDict = {
          "VC1":["yo"],
          "VC2":["tú"],
          "VC3":["usted","él","ella"],
          "VCW":["nosotros"],
          "VCT":["vosotros"],
          "VCF":["ustedes","ellas","ellos"],
        };
      }
    } else if (wdo[1][1] === "I"){
      newName += " imperative";
      txt = `let's repeat the imperative of the word "to ${wordObj.E}". In Spanish you say:`;
      console.log("word a", wordObj, wordObj);
      pronounDict = {
        "VI2":[""],
        "VIF":[""],
        "VIT":[""],
        "VITF":[""],
      };
    }else if (wdo[1][1] === "J"){
      newName += " imperfect";
      txt = `let's repeat the word "to ${wordObj.E}" in the imperfect tense. In Spanish you say:`;
      console.log("word a", wordObj, wordObj);
      if(wordObj.A === "R"){
        pronounDict = {
          "VJ1":["me"],
          "VJS":["te"],
          "VJ3":["se"],
          "VJW":["nos"],
          "VJT":["os"],
          "VJF":["se"],
        };
      }else{
        pronounDict = {
          "VJ1":["yo"],
          "VJS":["tú"],
          "VJ3":["usted","él","ella"],
          "VJW":["nosotros"],
          "VJT":["vosotros"],
          "VJF":["ustedes","ellas","ellos"],
        };
      }
    }

    let commentDict = {
      "yo":"",
      "él":"",
      "ella":"",
      "nosotros":"",
      "vosotros":"(plural)",
      "tú":"", 
      "usted":"(formal)",
      "ellos":"",
      "ellas":"(feminine)",
      "ustedes":"(plural, formal)",
      "VI2":"to one person",
      "VIF":"to one person in a formal way",
      "VIT":"to a group",
      "VITF":"to a group in a formal way",
    };
    
    let wordFamily = getAllRelatedWords(wordObj.R);
    let tempD = [];

    tempD.push({
      text: txt,
      direction: 2,
      hash: sparkMd5.hash(txt)
    });
    

    let lstr = wordObj.R;

    tempD.push({
      text: lstr,
      direction: 1,
      hash: sparkMd5.hash(lstr)
    });

    Object.keys(wordFamily).forEach((word)=>{
      if(pronounDict.hasOwnProperty(word)){
        let pn = pronounDict[word][Math.floor(Math.random() * pronounDict[word].length)];
        let str = pn+" " +wordFamily[word];
        tempD.push({
          text: str,
          direction: 1,
          hash: sparkMd5.hash(str)
        });
      }
    });


    if(wordObj.A === "R"){
      tempD.push({
        text: "the verb is reflexive",
        direction: 2,
        hash: sparkMd5.hash("the verb is reflexive")
      });
    }

    let toTranslate = [];

    let usedPronouns = [];
    let wordTypes = [];

    Object.keys(wordFamily).forEach((word)=>{
      if(pronounDict.hasOwnProperty(word)){
        let pn = pronounDict[word][Math.floor(Math.random() * pronounDict[word].length)];
        
        let str = pn+" " +wordFamily[word];
        toTranslate.push(str);
        wordTypes.push(word);
        usedPronouns.push(pn);
      }
    });

    let translation = await translate({toTranslate: toTranslate, source:"ES", lang:"en-US"});

    toTranslate.forEach((item,i) => {
      console.log(commentDict[wordTypes[i]]);
      tempD.push({
        text: `say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`,
        direction: 4,
        hash: sparkMd5.hash(`say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`)
      });
      tempD.push({
        text: item,
        direction: 0,
        hash: sparkMd5.hash(item)
      });
    })
  
    setStates(tempD)
  }

  async function generateBlockVerbUSRU(wdo){
    let wordObj = wdo[0];
    newName = wordObj.E+ " " + wordObj.R;

    let pronounDict = {};
    let lstr = wordObj.R;
    let txt = "";
    let wordFamily = getAllRelatedWords(wordObj.R);
    let tempD = [];
    let usedPronouns = [];
    let wordTypes = [];

    // restrict generation of question to words that match the original words tense
    if(wdo[1][1] === "N" || wdo[1][1] === "VI"){
      txt = `let's repeat the verb "${wordObj.E}", in Russian you say`;
      pronounDict = {
        "VN1":["я"],
        "VN2":["ты"],
        "VN3":["он","она"],
        "VNW":["мы"],
        "VNF":["вы"],
        "VNT":["они"]
      };
    }else if(wdo[1][1] === "F"){
      txt = `let's repeat the verb "${wordObj.E}", in Russian you say`;
      pronounDict = {
        "VF1":["я"],
        "VF2":["ты"],
        "VF3":["он","она"],
        "VFW":["мы"],
        "VFF":["вы"],
        "VFT":["они"]
      };
    } else if(wdo[1][1] === "P"){
      txt = `let's repeat the verb "${wordObj.E}" in the past tense, in Russian you say`;
      newName += " past";
      pronounDict = {
        "VPM":["я","он","вы"],
        "VPF":["она"],
        "VPN":["оно"],
        "VPP":["они","мы"]
      };
    } else if(wdo[1][1] === "I"){
      txt = `let's repeat the imperative of the verb "${wordObj.E}", in Russian you say`;
      newName += " imperative";
      lstr += ", "+wordFamily["VI1"];
      lstr += ", "+wordFamily["VI2"];
      wordTypes.push("VI1");
      wordTypes.push("VI2");
      usedPronouns.push("");
      usedPronouns.push("");
    }

    let commentDict = {
      "я":"",
      "ты":"",
      "он":"",
      "она":"",
      "мы":"", 
      "вы":"(formal)",
      "они":"",
      "VI2":"(formal)"
    };

    tempD.push({
      text: txt,
      direction: 2,
      hash: sparkMd5.hash(txt)
    });

    tempD.push({
      text: lstr,
      direction: 1,
      hash: sparkMd5.hash(lstr)
    });

    Object.keys(wordFamily).forEach((word)=>{
      if(pronounDict.hasOwnProperty(word)){
        let pn = pronounDict[word][Math.floor(Math.random() * pronounDict[word].length)];
        let str = pn+" " +wordFamily[word];
        tempD.push({
          text: str,
          direction: 1,
          hash: sparkMd5.hash(str)
        });
      }
    });

    if(wordObj.A === "P" && wdo[1][1] !== "I"){
      tempD.push({
        text: "it is perfective, so automatically future tense when conjugated",
        direction: 2,
        hash: sparkMd5.hash("it is perfective, so automatically future tense when conjugated")
      });
    }
    let toTranslate = [];

    
    
    Object.keys(wordFamily).forEach((word)=>{
      if(pronounDict.hasOwnProperty(word)){
        let pn = pronounDict[word][Math.floor(Math.random() * pronounDict[word].length)];
        let str = pn+" " +wordFamily[word];
        toTranslate.push(str);
        wordTypes.push(word);
        usedPronouns.push(pn);
      }
    });

    if(wdo[1][1] === "I"){
      txt = `let's repeat the imperative of the word "to ${wordObj.E}", in Russian you say`;
      toTranslate.push(wordFamily["VI1"]);
      toTranslate.push(wordFamily["VI2"]);
    }
    

    let translation = await translate({toTranslate: toTranslate,source:"RU", lang:"en-US"});

    toTranslate.forEach((item,i) => {
      tempD.push({
        text: `say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`,
        direction: 4,
        hash: sparkMd5.hash(`say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`)
      });
      tempD.push({
        text: item,
        direction: 0,
        hash: sparkMd5.hash(item)
      });
    })
  
    setStates(tempD)
  }

  async function generateBlockVerbUSRU1(wdo){

    function getPronouns(type){
      if (type==="F"){
        return {
          "VF1":["я"],
          "VF2":["ты"],
          "VF3":["он"," она"],
          "VFW":["мы"],
          "VFF":["вы"],
          "VFT":["они"]
        };
      }else if (type==="N"){
        return {
          "VN1":["я"],
          "VN2":["ты"],
          "VN3":["он"," она"],
          "VNW":["мы"],
          "VNF":["вы"],
          "VNT":["они"]
        };
        
      }else if (type==="P"){
        return {
          "VPM":["я"," он"," ты"],
          "VPF":["я"," она"," ты"],
          "VPN":["оно"],
          "VPP":["они"]
        };
        
      }else{
        return {};
      }
    }

    // states that get returned
    let tempStates = [];

    // get all necesary word data
    let wordObj = wdo[0];
    console.log(wordObj);
    let wordFamily = getAllRelatedWords(wordObj.R);

    // pronouns to generate table for
    let pronounDict = getPronouns("N");

    let commentDict = {
      "я":"",
      "ты":"",
      "он":"",
      "она":"",
      "мы":"", 
      "вы":"(formal)",
      "они":"",
      "VI2":"(formal, imperative)",
      "VI1":"(imperative)",
      "VI":"(infinitive)",
      "VPF": "(feminine)",
      "VPM": "(masculine)"
    };

    // 1. generate title 
    newName = wordObj.E+ " " + wordObj.R;

    // 2. generate introduction text
    // markdown for intro state
    let md = "";

    if(wdo[1][1] === "N" || wdo[1] === "VI"){
      md += `## let's train the **verb** \`${wordFamily["VI"]}\` *${wordObj.E}*\n`;
    }else if(wdo[1][1] === "I" && wdo[1] !== "VI"){
      md += `## let's train the **imperative** of [${wordFamily["VI"]}]() *${wordObj.E}*\n`;
      // setting pronouns empty as we do it later manually
      pronounDict = {};
    }else if(wdo[1][1] === "P"){
      md += `## quick let's train the **past** versions of [${wordFamily["VI"]}]() *${wordObj.E}*\n`;
      md += `if you want, repeat [how past verbs are constructed](Xf56h1sOi1foCoPL0Xwo)\n`;
      pronounDict = getPronouns("P");
    }

    // 3. add comment about type of the verb

    if(wordObj.A === "P" && (wdo[1] !== "VI1" && wdo[1] !== "VI2") && wdo[1][1] !== "P"){
      md += `it is [perfective](Cu5hE9bdJai1jUy4iimM), so automatically future when conjugated\n\n`;
      md += `and has the [imperfective](Cu5hE9bdJai1jUy4iimM) partner [link]()\n\n`;

      // set pronoun dict to future because of Perfective form
      pronounDict = getPronouns("F");
    }else if(wordObj.A === "I" && (wdo[1] !== "VI1" && wdo[1] !== "VI2")  && wdo[1][1] !== "P"){
      md += `it is [imperfective](Cu5hE9bdJai1jUy4iimM), so describes ongoing situations\n\n`;
      md += `and has the [perfective](Cu5hE9bdJai1jUy4iimM) partner [link]()\n\n`;
    }

    // 4. get words and translation

    let toTranslate = [];
    let wordTypes = [];
    let usedPronouns = [];
    console.log(wordFamily);

    
    Object.keys(pronounDict).forEach((form)=> {
      wordTypes.push(form);
      usedPronouns.push(pronounDict[form]);
      toTranslate.push(`${pronounDict[form]} ${wordFamily[form]}`);
    })

    // manually adding the imperative words
    if(wdo[1][1] === "I" && wdo[1] !== "VI"){
      wordTypes.push("VI");
      wordTypes.push("VI1");
      wordTypes.push("VI2");
      toTranslate.push(`${wordFamily["VI"]}`);
      toTranslate.push(`${wordFamily["VI1"]}`);
      toTranslate.push(`${wordFamily["VI2"]}`);
    }

    let translation = await translate({toTranslate: toTranslate,source:"RU", lang:"en-US"});

    // 5. show table with the conjugations

    let md1 = `| | |\n`;
    md1 += `| - | - |\n`;

    console.log(translation);

    for(let i = 0; i < toTranslate.length; i++){
      md1 += `| ${translation.data[i].text} ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} |\`${toTranslate[i]}\`|\n`;
    }

    // 6. add intro to states

    tempStates.push({
      text: md,
      direction: 6,
      hash: sparkMd5.hash(md)
    });

    if(wdo[1][1] !== "P"){

    
    tempStates.push({
      text: md1,
      direction: 6,
      hash: sparkMd5.hash(md1)
    });
  }

    // 7. add exercise

    toTranslate.forEach((item,i) => {
      tempStates.push({
        text: `say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`,
        direction: 4,
        hash: sparkMd5.hash(`say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`)
      });
      tempStates.push({
        text: item,
        direction: 0,
        hash: sparkMd5.hash(item)
      });
    })

    /*

    let pronounDict = {};
    let lstr = wordObj.R;
    
    
    
    let usedPronouns = [];
    let wordTypes = [];

    // restrict generation of question to words that match the original words tense
    if(wdo[1][1] === "N" || wdo[1] === "VI"){
      pronounDict = {
        "VN1":["я"],
        "VN2":["ты"],
        "VN3":["он","она"],
        "VNW":["мы"],
        "VNF":["вы"],
        "VNT":["они"]
      };
    }

    let commentDict = {
      "я":"",
      "ты":"",
      "он":"",
      "она":"",
      "мы":"", 
      "вы":"(formal)",
      "они":"",
      "VI2":"(formal)"
    };



    if(wordObj.A === "P"){
      tempD.push({
        text: "it is perfective, so automatically future tense when conjugated",
        direction: 6,
        hash: sparkMd5.hash("it is perfective, so automatically future tense when conjugated")
      });
    }
    let toTranslate = [];

    
    
    Object.keys(wordFamily).forEach((word)=>{
      if(pronounDict.hasOwnProperty(word)){
        let pn = pronounDict[word][Math.floor(Math.random() * pronounDict[word].length)];
        let str = pn+" " +wordFamily[word];
        toTranslate.push(str);
        wordTypes.push(word);
        usedPronouns.push(pn);
      }
    });

    let translation = await translate({toTranslate: toTranslate,source:"RU", lang:"en-US"});

    toTranslate.forEach((item,i) => {
      tempD.push({
        text: `say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`,
        direction: 4,
        hash: sparkMd5.hash(`say "${translation.data[i].text}" ${commentDict[wordTypes[i]]?commentDict[wordTypes[i]]:""} ${commentDict[usedPronouns[i]]?commentDict[usedPronouns[i]]:""}`)
      });
      tempD.push({
        text: item,
        direction: 0,
        hash: sparkMd5.hash(item)
      });
    })*/
  
    setStates(tempStates);
  }

  async function newBlock(name){
    setSafeState(STATES.STATE_PROCESS);
    const docRef = await addDoc(collection(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`), {
      name: name,
      states: states
    });

    const docSnap = await getDoc(doc(getFirestore(),"blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.ids.push(docRef.id);
      data.names.push(name);
      await setDoc(doc(getFirestore(), "blocks","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
        ids: data.ids,
        names: data.names, 
      });
    }

    generateBlockAudio({id:docRef.id,origin_lang:userConfig.origin_lang,target_lang:userConfig.target_lang})
      .then(() => {
        setSafeState(STATES.STATE_NORMAL);
        selectedBlock({id:docRef.id, name:name});
      })
      .catch((e) => {
        setSafeState(STATES.STATE_ERROR);
        console.log(e);
      });
  }

  function removeText(id){
    let t1 = [...states];
      t1.splice(id, 1);
      setStates(t1);
  }

  function wordAnalytics(ctx){
    let words = {};
    let sentenceWords = ctx.split(" ");
        sentenceWords.forEach((word) => {
          word = word.replace(".","");
          word = word.replace("!","");
          word = word.replace("?","");
          word = word.replace(",","");
          word = word.replace(" - ","");
          word = word.replace("  ","");
          word = word.toLocaleLowerCase();
          if(word){
            if(words[word]){
              words[word] = words[word] + 1;
            }else{
              words[word] = 1;
            }
          }
        });
    return words;
  }

  function stopSearch(){
    setSearchStr("");
    searchRef.current.value = "";
    setSearchResult([]);
  }

    return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
    <div className="fixed inset-0 flex items-center justify-center p-8 inset-0 bg-black/30 z-50" aria-hidden="true">
      <Dialog.Panel className="flex flex-col h-full w-full rounded bg-white rounded bg-white rounded-xl z-50">
        <Dialog.Title className="flex flex-row text-xl rounded-t p-4 justify-between content-center font-bold bg-sky-300 text-sky-700">
          <div className="flex flex-row content-center">
            <IoExtensionPuzzle  className=" mr-2 h-6 w-6"/>
          {generator?<p>Generate Block</p>:<p>Choose Block</p>}</div>
          <IoCloseOutline onClick={()=>{setIsOpen(false)}} className="cursor-pointer h-6 w-6"/>
        </Dialog.Title>
        <div className="flex flex-row h-full overflow-auto">
        {blocks?<div className="flex flex-col h-full border-r">
            <div className="flex flex-row items-center border-b bg-white border-slate-200 h-16">
              <input onChange={(event) => {searchChange(event.target.value)}} type="text" className="flex flex-1 rounded-full bg-white outline-none focus:outline-none p-2" ref={searchRef}></input>
              {searchStr.length>0?<IoCloseOutline onClick={stopSearch} className="cursor-pointer flex text-slate-500 h-6 w-6 mr-3"/>:<IoSearchOutline className="flex text-slate-500 h-6 w-6 mr-3"/>}
            </div>
            <div className="flex flex-row overflow-hidden">
              <div className="flex flex-1 flex-col overflow-auto">
                {(searchResult.length>0?searchResult:blocks).map((temp,index) => (
                    <div className={`p-4 hover:bg-slate-100 ${index<blocks.length-1?"border-b":""} p-2 cursor-pointer`} key={temp.id} onClick={() => {setIsOpen(false);selectedBlock(temp)}}>
                      <div><p className="font-bold">{temp.name}</p> <p>{temp.id}</p></div>
                    </div>
                ))}           
              </div>
            </div>
          </div>:""}
          

          <div className="flex flex-col w-full">
            <div className="flex flex-row w-full ">
            {!context?
              <div className="flex flex-row border-b">
                <input onChange={(event) => {contextChange(event.target.value)}} type="text" className="m-2 w-full rounded-full bg-slate-100 outline-none focus:outline-none p-2"></input> 
              </div>:""}
              
              <div className="flex flex-row flex-1 items-center overflow-x-auto overflow-y-hidden border-slate-200 border-b p-2 justify-start">
              {words?
                Object.keys(words).map((s,i) => (
                  <WordItem showType={true} click={(wdo)=>{generateBlock(wdo)}} key={i} w={s} r={true}></WordItem>
                ))
                :""}
              </div>

              {!context?
              <div className="border-l border-b">
                <StateButton className="m-4 text-slate-500" size="6" onClick={()=>{selectedBlock(states);setIsOpen(false)}} 
                state={generateState}><IoAddOutline className="h-6 w-6 "/></StateButton>
              </div>:""}

            {context?
              <div className="border-l">
                <StateButton className="m-4 text-slate-500" size="6" onClick={()=>{
                    newBlock(newName);}} state={safeState}><IoAddOutline className="h-6 w-6 "/></StateButton>
              </div>:""}
            </div>
           
            <div className="flex flex-col flex-1 overflow-auto">
              {states.map((s, index) => (
                 <EditorSpeechBubble
                 key={index}
                 edit={false} 
                 direction={s.direction} 
                 select={(id)=>{removeText(id)}}
                 change={(text)=>{}}
                 id={index}
                 hash={s.hash} 
                 token={s.uuid}
                 text={(s.text)} />
                     
                    ))}
              </div>
          </div>
        </div>
      </Dialog.Panel>
      </div>
    </Dialog>
    
  )
}