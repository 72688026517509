import React from 'react';
import {  IoSync } from "react-icons/io5";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function LoadingScreen({text}) {
  return (
    <div className="flex flex-col justify-center  h-screen w-screen dark:bg-slate-900">
        <div className="flex flex-row justify-center">
            <IoSync className={`animate-spin h-24 w-24 text-sky-500`}/>
        </div>
        <div className="flex flex-row justify-center">
        <div className="inline-block "><p className="font-bold text-3xl dark:text-slate-200">{text}</p></div>
        </div>
    </div>
  );
}