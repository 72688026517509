import React, { useRef, useState, useEffect } from "react";
import Sidebar from "../editor/Sidebar";
import SidebarElement from "../editor/SidebarElement";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { AiOutlineMenu } from "react-icons/ai";
import { useAuth } from "../auth/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { IoChevronDown } from "react-icons/io5";
import { Disclosure } from "@headlessui/react";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function BotView({ select, onNextStory }) {
  const [currentBot, setCurrentBot] = useState("");
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser, userConfig, currentUserState } = useAuth();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      let st = await getDoc(doc(getFirestore(), "chat", "index"));
      let tempBots = [];
      let data = st.data();

      if (!data.hasOwnProperty("ids")) {
        setLoading(false);
        return;
      }

      for (let i = 0; i < data.ids.length; i++) {
        tempBots.push({
          id: data.ids[i],
          icon: data.icons[i],
          name: data.names[i],
          le: i,
        });
      }

      setCurrentBot(tempBots[0].id);

      if (localStorage.getItem("BOT_STORAGE_ID")) {
        setCurrentBot(localStorage.getItem("BOT_STORAGE_ID"));
      }
      setBots(tempBots);

      setLoading(false);
    }
    if (userConfig) {
      fetchData();
    }
  }, [userConfig]);

  return (
    <div className="overflow-auto hide_story_list bg-white dark:bg-slate-900 ">
      {!loading && (
        <div className="border-r border-dotted dark:border-slate-700 h-full">
          {expanded ? (
            <AiOutlineMenu
              onClick={() => {
                setExpanded(false);
              }}
              className="h-12 w-12 p-3 text-slate-600 dark:text-slate-200 rounded-full m-2 lg:hidden"
            />
          ) : (
            <AiOutlineMenu
              onClick={() => {
                setExpanded(true);
              }}
              className="h-12 w-12 p-3 text-slate-600 dark:text-slate-200 rounded-full m-2 lg:hidden"
            />
          )}
          {bots.map((temp, i) => (
            <div
              key={temp.id}
              className={`flex flex-row items-center dark:hover:bg-slate-700 hover:bg-slate-200 cursor-pointer`}
              onClick={() => {
                setCurrentBot(temp.id);
                select(temp.id);
              }}
            >
              <div
                className={`cursor-pointer text-white ${
                  currentBot === temp.id
                    ? "bg-sky-500"
                    : "dark:bg-slate-500 bg-slate-200"
                } rounded-full m-2 w-12 h-12 flex items-center justify-center overflow-hidden`}
              >
                <img
                  src={temp.icon}
                  alt="person icon"
                  className="w-12 h-12"
                ></img>
              </div>
              <p
                className={`dark:text-slate-200 text-slate-600 mr-6 ml-2 ${
                  expanded ? "inline" : "hidden"
                } lg:inline`}
              >
                {temp.name}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
