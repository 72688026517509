import React, {useState} from 'react';
import EditorNavbar from '../EditorNavbar';
import Blocks from './Blocks';
/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function BlockEditor() {
  return (
    <div className="flex flex-col h-screen w-full">
      <EditorNavbar/>
      <Blocks></Blocks>
    </div>
  );
}