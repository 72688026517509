import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import Word from './Word';
import { getCategoryColorComp, getCategoryColor, getWordByRank, getWordByWord} from '../../grammar';
import gg from '../../grammar';
import {STATES, StateButton} from '../../util/StateButton';
import { IoSaveOutline, 
  IoSearchOutline, 
  IoAddCircleOutline,
  IoTrashOutline, 
  IoPencilOutline,
  IoCheckmarkCircleOutline } from 'react-icons/io5';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

export function NewWord({newWord}){
  const raRef = useRef(null);
  const aRef = useRef(null);
  const gRef = useRef(null);
  const rRef = useRef(null);
  const tRef = useRef(null);
  const eRef = useRef(null);

  return <div className="flex flex-row pt-2 px-2">
          <div className="flex flex-row flex-1">
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-12 border-b-2 focus:outline-none" ref={raRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-6 border-b-2 focus:outline-none" ref={aRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-6 border-b-2 focus:outline-none" ref={gRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-32 border-b-2 focus:outline-none" ref={rRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-40 border-b-2 focus:outline-none" ref={tRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex flex-1 border-b-2 focus:outline-none" ref={eRef}></input>
            </div>
            <div className="flex flex-row">
            <IoAddCircleOutline onClick={()=>{newWord({
                RA: raRef.current.value,
                A: aRef.current.value,
                G: gRef.current.value,
                R: rRef.current.value,
                T: tRef.current.value,
                E: eRef.current.value
              });
              raRef.current.value = "-";
              aRef.current.value = "-";
              gRef.current.value = "-";
              rRef.current.value = "-";
              tRef.current.value = "-";
              eRef.current.value = "-";}
              } className="h-4 w-4 text-slate-400 ml-2 cursor-pointer"/>
            </div>
          </div>
}

export function NewDetector({newDetector}){
  const rRef = useRef(null);
  const tRef = useRef(null);

  return <div className="flex flex-row pt-2 px-2">
          <div className="flex flex-row flex-1">
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-32 border-b-2 focus:outline-none" ref={rRef}></input>
              <input defaultValue="-" type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-40 border-b-2 focus:outline-none" ref={tRef}></input>
            </div>
            <div className="flex flex-row">
            <IoAddCircleOutline onClick={()=>{newDetector({
                R: rRef.current.value,
                T: tRef.current.value
              });
              rRef.current.value = "-";
              tRef.current.value = "-";
              }} className="h-4 w-4 text-slate-400 ml-2 cursor-pointer"/>
            </div>
          </div>
}

export function DetectorElement({s,deleteWord, safe, edit, editState}) {

  const rRef = useRef(null);
  const tRef = useRef(null);

  return <div className="flex flex-row pt-2 px-2">
          {editState?
            <div className="flex flex-row flex-1">
              <input defaultValue={s.R} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-32 border-b-2 focus:outline-none" ref={rRef}></input>
              <input defaultValue={s.T} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-24 border-b-2 focus:outline-none" ref={tRef}></input>
            </div>:
            <div className="flex flex-row flex-1">
              <div className="w-32 font-bold">{s.R}</div>
              <div className="w-24">{s.T}</div>
              <div>{s.E}</div>
            </div>}
            <div className="flex flex-row">
              {editState?
              <IoCheckmarkCircleOutline onClick={()=>{safe({
                id: s.id,
                R: rRef.current.value,
                T: tRef.current.value
              })}} className="h-4 w-4 text-slate-400 mr-2 cursor-pointer"/>
              :<IoPencilOutline onClick={() => {edit(s.id)}} className="h-4 w-4 text-slate-400 mr-2 cursor-pointer"/>}
              <IoTrashOutline onClick={()=>{deleteWord(s.id)}} className="h-4 w-4 text-red-400 cursor-pointer"/>
            </div>
          </div>
}

export default function WordElement({s,deleteWord, safe, edit, editState, search}) {
  const aRef = useRef(null);
  const gRef = useRef(null);
  const rRef = useRef(null);
  const tRef = useRef(null);
  const eRef = useRef(null);

  return <div className="flex flex-row pt-2 px-2">
          {editState?
            <div className="flex flex-row flex-1">
              <div className={`font-bold w-12 text-${getCategoryColor(s.T)}-500`} >{s.RA}</div>
              <input defaultValue={s.A} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-6 border-b-2 focus:outline-none" ref={aRef}></input>
              <input defaultValue={s.G} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-6 border-b-2 focus:outline-none" ref={gRef}></input>
              <input defaultValue={s.R} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-32 border-b-2 focus:outline-none" ref={rRef}></input>
              <input defaultValue={s.T} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex w-40 border-b-2 focus:outline-none" ref={tRef}></input>
              <input defaultValue={s.E} type="text" className="dark:bg-slate-900 dark:text-slate-200 dark:border-slate-700 flex flex-1 border-b-2 focus:outline-none" ref={eRef}></input>
            </div>:
            <div className="flex flex-row flex-1">
              <div className={`font-bold w-12 text-${getCategoryColor(s.T)}-500`} >{s.RA}</div>
              <div className="w-6">{s.A==="-"?"":s.A}</div>
              <div className="w-6">{s.G==="-"?"":s.G}</div>
              <div className="w-32 font-bold cursor-pointer" onClick={search}>{s.R}</div>
              <div className="w-40">{s.T}</div>
              <div>{s.E}</div>
            </div>}
            <div className="flex flex-row">
              {editState?
              <IoCheckmarkCircleOutline onClick={()=>{safe({
                RA: s.RA,
                A: aRef.current.value,
                G: gRef.current.value,
                R: rRef.current.value,
                T: tRef.current.value,
                E: eRef.current.value
              })}} className="h-4 w-4 text-slate-400 mr-2 cursor-pointer"/>
              :<IoPencilOutline onClick={edit} className="h-4 w-4 text-slate-400 mr-2 cursor-pointer"/>}
              <IoTrashOutline onClick={()=>{deleteWord(s.RA)}} className="h-4 w-4 text-red-400 cursor-pointer"/>
            </div>
          </div>
}