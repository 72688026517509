import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsDistributeVertical, BsPatchCheckFill } from "react-icons/bs";
import { useAuth } from "../auth/AuthContext";
import ReactMarkdown from "react-markdown";
import {
  IoLinkOutline,
  IoChatbubblesOutline,
  IoLogoYoutube,
  IoPaperPlane,
  IoLanguage,
  IoInformation,
} from "react-icons/io5";
import remarkGfm from "remark-gfm";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import { ReactComponent as HiBlue } from "../svg/blue_hi.svg";
import ShopBubble from "../util/ShopBubble";
import AudioPlayer from "../util/AudioPlayer";
import { TargetLangDictionary } from "./Chat";
import { STATES, StateButton } from "../util/StateButton";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */

function getDirection(direction) {
  if (direction === 1) {
    return "justify-start";
  } else if (direction === 0) {
    return "justify-end";
  } else if (direction === 3) {
    return "justify-start";
  } else if (direction === 4) {
    return "justify-end";
  } else {
    return "justify-center";
  }
}

function get_bubble_class(index, length, direction) {
  if (direction === 1) {
    if (index === length - 1) {
      return "sb-left2";
    } else if (index === length - 2) {
      return "sb-left1";
    } else {
      return "sb-left";
    }
  } else if (direction === 0) {
    if (index === length - 1) {
      return "sb-right2";
    } else if (index === length - 2) {
      return "sb-right1";
    } else {
      return "sb-right";
    }
  } else {
    return "";
  }
}

function getDesign(direction, position) {
  if (direction === 1) {
    return "sb";
  } else if (direction === 0) {
    return "sb";
  } else if (direction === 3) {
    if (position) {
      return "sb-annotation-left-small text-slate-700 dark:text-slate-300";
    } else {
      return "sb-annotation-left text-slate-700 dark:text-slate-300";
    }
  } else if (direction === 4) {
    if (position) {
      return "sb-annotation-right-small text-slate-700 dark:text-slate-300";
    } else {
      return "sb-annotation-right text-slate-700 dark:text-slate-300";
    }
  } else {
    return "border-4 border-dotted p-4 mx-4 mt-4 border-slate-200 dark:border-slate-700 dark:text-slate-300 font-bold";
  }
}

export function MDSpeechbubble({ id, fragments, audio, color, onBlock, text }) {
  const { userConfig } = useAuth();
  function getAudioLink(text) {
    if (fragments && audio) {
      for (let i = 0; i < fragments.length; i++) {
        if (fragments[i] === text[0]) {
          return (
            <AudioPlayer
              inactive={400}
              color={"amber"}
              size={6}
              lang={`${userConfig.target_lang}`}
              audio={audio[i]}
              play={false}
            >
              <div className="inline-flex flex-row items-center">
                <div className="">{text[0]}</div>
              </div>
            </AudioPlayer>
          );
        }
      }
    }

    return text[0];
  }

  function getLink(href, children, ...props) {
    if (href.startsWith("STORY_")) {
      return (
        <div
          className="p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-200 cursor-pointer"
          onClick={() => {
            onBlock(href);
          }}
          {...props}
        >
          <div className="inline-flex flex-row items-center">
            <div className="mr-2">{children[0]}</div>
            <IoChatbubblesOutline className="w-6 h-6"></IoChatbubblesOutline>
          </div>
        </div>
      );
    } else if (href.startsWith("LINK_")) {
      return (
        <div
          className="p-1 underline rounded inline text-sky-500 dark:bg-sky-900 dark:text-sky-200 cursor-pointer"
          onClick={() => {
            onBlock(href);
          }}
          {...props}
        >
          <div className="inline-flex flex-row items-center">
            <div className="">{children[0]}</div>
          </div>
        </div>
      );
    } else if (href.startsWith("YOUTUBE_")) {
      return (
        <div
          className="p-1 bg-red-200 rounded inline text-red-700 dark:bg-red-900 dark:text-red-200 cursor-pointer"
          onClick={() => {
            onBlock(href);
          }}
          {...props}
        >
          <div className="inline-flex flex-row items-center">
            <div className="mr-2">{children[0]}</div>
            <IoLogoYoutube className="w-6 h-6"></IoLogoYoutube>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="p-1 bg-sky-200 rounded inline text-sky-700 dark:bg-sky-900 dark:text-sky-200 cursor-pointer"
          onClick={() => {
            onBlock(href);
          }}
          {...props}
        >
          {getBlockLink(children)}
        </div>
      );
    }
  }

  function getBlockLink(text) {
    return (
      <div className="inline-flex flex-row items-center">
        <div className="mr-2">{text[0]}</div>
        <IoLinkOutline className="w-6 h-6"></IoLinkOutline>
      </div>
    );
  }
  return (
    <div
      className={`border-l-4 md:text-xl text-slate-600 dark:text-slate-300 border-${
        color ? color : "slate"
      }-400 dark:border-${
        color ? color : "slate"
      }-900 border-dotted px-2 pb-1 mx-1 md:mx-2 my-1 ml-2 md:ml-4 md:pl-4`}
    >
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => (
            <p className="mb-2 leading-loose md:mb-4" {...props} />
          ),
          ul: ({ node, ...props }) => (
            <ul className="list-disc list-outside ml-4" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="list-decimal list-outside ml-4" {...props} />
          ),
          li: ({ node, ...props }) => <li className="mb-2" {...props} />,
          hr: ({ node, ...props }) => (
            <div
              className="w-32 h-2 border-b-4 border-dotted border-slate-200 dark:border-slate-700 my-6"
              {...props}
            />
          ),
          em: ({ node, ...props }) => (
            <span className="text-amber-500" {...props} />
          ),
          table: ({ node, ...props }) => (
            <table cellPadding={8} className="" {...props} />
          ),
          a: ({ node, href, children, ...props }) =>
            getLink(href, children, props),
          td: ({ node, children, ...props }) => (
            <td className="m-2 dark:border-slate-800" {...props}>
              {children}
            </td>
          ),
          h2: ({ node, children, ...props }) => (
            <h2 className="text-2xl mb-4" {...props}>
              {children}
            </h2>
          ),
          h1: ({ node, children, ...props }) => (
            <h1 className="text-3xl mb-4" {...props}>
              {children}
            </h1>
          ),
          code: ({ node, children, ...props }) => (
            <div
              className="p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-400"
              {...props}
            >
              {getAudioLink(children)}
            </div>
          ),
        }}
      />
    </div>
  );
}

function getEmojiFromString(s) {
  let emoji = "";
  let str = "";
  for (let i = 0; i < s.length; i++) {
    if (
      !"abcdefghijklmnopqrstuvwxyz ?!'1234567890,.-".includes(
        s[i].toLocaleLowerCase()
      )
    ) {
      emoji += s[i];
    } else {
      str += s[i];
    }
  }
  return { emoji: emoji, str: str };
}

export function Recommendation({ id, fragments, audio, color, onBlock, text }) {
  const { userConfig } = useAuth();

  const functions = getFunctions();
  const getNextStories = httpsCallable(functions, "getNextStories");
  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let configObj = { storyId: null };
      configObj.storyId = "WELCOME"; // only ever checks the first element
      configObj.authToken = await getAuth().currentUser.getIdToken(true);
      configObj.lang = `${userConfig.origin_lang}-${userConfig.target_lang}`;
      let block = await getNextStories(configObj);
      console.log(block);
      setStories(block.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div
      className={`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 border-l-4 md:text-xl text-slate-600 dark:border-${
        color ? color : "slate"
      }-700 border-${
        color ? color : "slate"
      }-400 border-dotted px-2 pb-1 mx-1 md:mx-2 my-1 ml-2 md:ml-4 md:pl-4`}
    >
      {loading ? (
        <strong>loading your stories...</strong>
      ) : (
        stories.map((story, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                onBlock("STORY_" + story.id);
              }}
              className="cursor-pointer  border-dotted bg-amber-100 dark:text-slate-300 dark:bg-slate-800 relative flex flex-col items-center justify-between rounded-lg p-2 m-2 "
            >
              <div className="text-5xl f1lex-1 items-center">
                {getEmojiFromString(story.name).emoji}
              </div>
              <div className="text-center font-bold">
                {getEmojiFromString(story.name).str}
              </div>
              {story.count ? (
                <div className="absolute top-2 left-2 bg-sky-500 rounded px-1 text-white text-xs">
                  repeat
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export function SpeechBubble({ data, index, length }) {
  const functions = getFunctions();
  const getExplanation = httpsCallable(functions, "getExplanation");
  const [explanation, setExplanation] = useState({});
  const [showExplanation, setShowExplanation] = useState(false);
  const [loadingExplanation, setLoadingExplanation] = useState(STATES.NORMAL);
  const [showTranslation, setShowTranslation] = useState(false);

  function explain() {
    if(showExplanation) {
      setShowExplanation(false);
      return;
    }
    console.log("state button", STATES);
    setLoadingExplanation(STATES.PROCESS);
    const params = {
      context: {
        name: "Joshua",
        targetLang: data.targetLang,
        sourceLang: "English",
      },
      topic: data.topic,
      sentence: data.text,
    };

    getExplanation(params).then((result) => {
      console.log(result.data);
      setExplanation(result.data.response);
      setLoadingExplanation(STATES.NORMAL);
      setShowExplanation(true);
    });
  }

  useEffect(() => {
    if (data.targetLang === "Japanese") {
      //console.log(converter.convert("暑"));
    }
  });

  return (
    <>
      <div
        className={`flex relative ${
          data.direction === 1 ? "pl-10" : ""
        }  items-center flex-row ${getDirection(data.direction)}`}
      >
        {data.direction === 0 && data.correct ? (
          <BsPatchCheckFill className="h-6 w-6 text-slate-300"></BsPatchCheckFill>
        ) : (
          ""
        )}
        {data.direction === 0 && index === length - 1 && data.audio ? "" : ""}

        {data.direction === 1 ? (
          <img
            src={data.icon}
            alt="bot icon"
            className="absolute rounded-full bg-white top-1 w-16 h-16 border-4 left-0 border-white dark:bg-slate-800 dark:border-slate-900"
          ></img>
        ) : (
          ""
        )}
        <div
          className={`sp-max-w-mobile sm:sp-max-w flex flex-col items-center overflow-visible z-10 ${
            data.direction === 1 || data.direction === 0 ? "cShadow" : ""
          } ${getDesign(data.direction, index < length - 2)} ${
            data.playing && index === length - 1 ? "blob" : ""
          } rounded ${index === length - 2 ? "special1" : ""} ${
            index === length - 1 ? "special" : ""
          } ${get_bubble_class(index, length, data.direction)}`}
        >
          <div>
            {index < length - 1 &&
            (data.direction === 0 || data.direction === 1) &&
            data.hide
              ? "..."
              : data.text}
          </div>
          {showTranslation ? (
            <div className="text-gray-500 font-thin">{data.translation}</div>
          ) : (
            ""
          )}
          <div className="flex flex-row items-center gap-2">
            {data.translation ? (
              <IoLanguage
                onClick={() => {
                  setShowTranslation(!showTranslation);
                }}
                className="cursor-pointer w-8 h-8 p-1 bg-black/10 rounded-full text-black/40"
              ></IoLanguage>
            ) : (
              ""
            )}
            {data.translation ? (
              <StateButton state={loadingExplanation}>
                <IoInformation
                  onClick={() => {
                    explain();
                  }}
                  className="cursor-pointer w-8 h-8 p-1 bg-black/10 rounded-full text-black/40"
                ></IoInformation>
              </StateButton>
            ) : (
              ""
            )}
          </div>
        </div>

        {(data.direction === 1 || data.direction === 2) &&
        index === length - 1 &&
        data.audio
          ? ""
          : ""}
        {data.direction === 1 && data.correct ? (
          <BsPatchCheckFill className="h-6 w-6 text-slate-300"></BsPatchCheckFill>
        ) : (
          ""
        )}
      </div>
      <div>
        {showExplanation  ? (
          <div
            className={`border-l-4 md:text-xl text-slate-600 dark:text-slate-300 border-slate-400 dark:border-slate-900 border-dotted px-2 pb-1 mx-1 md:mx-2 my-1 ml-2 md:ml-4 md:pl-4`}
          >
            <ReactMarkdown
              children={explanation}
              remarkPlugins={[remarkGfm]}
              components={{
                p: ({ node, ...props }) => (
                  <p className="mb-2 leading-loose md:mb-4" {...props} />
                ),
                ul: ({ node, ...props }) => (
                  <ul className="list-disc list-outside ml-4" {...props} />
                ),
                ol: ({ node, ...props }) => (
                  <ol className="list-decimal list-outside ml-4" {...props} />
                ),
                li: ({ node, ...props }) => <li className="mb-2" {...props} />,
                hr: ({ node, ...props }) => (
                  <div
                    className="w-32 h-2 border-b-4 border-dotted border-slate-200 dark:border-slate-700 my-6"
                    {...props}
                  />
                ),
                em: ({ node, ...props }) => (
                  <span className="text-amber-500" {...props} />
                ),
                table: ({ node, ...props }) => (
                  <table cellPadding={8} className="" {...props} />
                ),
                td: ({ node, children, ...props }) => (
                  <td className="m-2 dark:border-slate-800" {...props}>
                    {children}
                  </td>
                ),
                h2: ({ node, children, ...props }) => (
                  <h2 className="text-2xl mb-4" {...props}>
                    {children}
                  </h2>
                ),
                h1: ({ node, children, ...props }) => (
                  <h1 className="text-3xl mb-4" {...props}>
                    {children}
                  </h1>
                ),
                code: ({ node, children, ...props }) => (
                  <div
                    className="p-1 bg-amber-200 rounded inline text-amber-700 dark:bg-amber-900 dark:text-amber-400"
                    {...props}
                  >
                    {children}
                  </div>
                ),
              }}
            />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

let customTopic = "";

function getType(index, length, data, onTopic) {
  if (data.direction === 6) {
    return (
      <MDSpeechbubble
        audio={data.audio}
        color={data.color}
        onBlock={data.onBlock}
        fragments={data.fragments}
        direction={data.direction}
        text={data.text}
      />
    );
  } else if (data.direction === 9) {
    return (
      <div className="flex flex-col items-center mx-4 mt-2 mb-8">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-center sm:items-start sm:flex-row dark:bg-slate-800 bg-slate-200 rounded-xl p-6">
            <img
              src={data.icon}
              className="w-32 h-32 sm:w-24 sm:h-24 mb-8 sm:mb-2  bg-sky-500 rounded-full"
              alt="person icon"
            ></img>
            <div className="sm:px-6 text-2xl text-slate-600 dark:text-slate-300 max-w-lg">
              <span className="text-4xl">Hi!</span> <br></br> My name is{" "}
              <span className="text-sky-500">{data.name}</span>.{" "}
              {data.description}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data.direction === 12) {
    return (
      <div className="flex flex-col items-center mx-4 mt-2 mb-8">
        <div className="flex flex-col gap-6">
          <div className="text-xl font-bold dark:text-slate-200">
            <div>Current Topics</div>
          </div>

          <div className="grid grid-cols-2 gap-2 max-h-[30vh] overflow-scroll">
            {data.currentTopics?.map((topic, i) => {
              return (
                <div
                  key={i}
                  onClick={(e) => {
                    onTopic(topic.description);
                  }}
                  className="cursor-pointer p-2 bg-slate-200 dark:bg-slate-800 dark:text-slate-200 rounded text-slate-700"
                >
                  {topic.icon} {topic.description}
                </div>
              );
            })}
          </div>
          <div className="text-xl font-bold dark:text-slate-200">
            Your own topic
          </div>
          <div>
            <div className="bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 flex flex-row items-center p-2 rounded">
              <input
                className="p-2 bg-transparent no-outline flex-1"
                placeholder="Type your own topic"
                onChange={(e) => {
                  customTopic = e.target.value;
                }}
              ></input>
              <IoPaperPlane
                className="w-8 h-8 cursor-pointer"
                onClick={() => {
                  onTopic(customTopic);
                }}
              ></IoPaperPlane>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data.direction === 10) {
    return <div>No recomendations</div>;
  } else if (data.direction === 11) {
    return <ShopBubble></ShopBubble>;
  } else {
    return (
      <SpeechBubble data={data} length={length} index={index}></SpeechBubble>
    );
  }
}

export default function ChatSpeechBubble({ length, index, data, onTopic }) {
  return <>{getType(index, length, data, onTopic)}</>;
}
