import { Popover } from '@headlessui/react'
import {MdArrowDropDownCircle} from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import React, {useState, useRef, useEffect} from 'react';
import { doc, onSnapshot , getFirestore, arrayRemove } from "firebase/firestore"; 
import { useAuth } from '../../auth/AuthContext';

function GeneratorItem({id,name,change}){
  return (
      <div onClick={()=>{change(id)}} className={`whitespace-nowrap flex flex-row cursor-pointer align-center text-slate content-center p-2 hover:bg-slate-100`}>
        <div className="font-bold mr-2">{name}</div>
    </div>
  )
}

export default function GeneratorDropdown({change,newCFG}) {
  const [generators, setGenerators] = useState([]);
  const newNameRef = useRef();
  const {userConfig} = useAuth();

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"cfg","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), async (doc) => {
        let data = doc.data();
        let gArr = [];
        
        if(data){
          data.ids.map((id,index) => {gArr.push({"id":id,"name":data.names[index]})});
          setGenerators(gArr);
        }
    });
  },[userConfig]);

  return (
    <Popover className="relative z-50">
    <Popover.Button><MdArrowDropDownCircle className="w-6 h-6 mr-3"></MdArrowDropDownCircle></Popover.Button>
    <Popover.Panel className="flex flex-col w-fit absolute shadow rounded bg-white">
      <div className={`flex flex-1 flex-col w-60 border-slate-200 overflow-y-scroll overflow-x-`}>
          {generators.map((g,i) => (
              <GeneratorItem key={i} id={g.id} change={(id)=>{change(id)}} name={g.name}></GeneratorItem>
          ))}     
      </div>
      <div className="flex flex-row border-slate-200 bg-green-100">
      <input type="text" placeholder="name" className="no-outline flex-1 min-w-0 p-4 bg-green-100" ref={newNameRef} />
        <AiOutlinePlus onClick={()=>{newCFG(newNameRef.current.value)}} className="cursor-pointer h-6 w-6 text-green-500 m-4"/>  
      </div>  
    </Popover.Panel>
    </Popover>
    
  )
}