import React, {useRef, useState, useEffect} from 'react';
import Sidebar from '../Sidebar';
import SidebarElement from '../SidebarElement';
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot , getFirestore } from "firebase/firestore"; 
import { AiOutlinePlus } from 'react-icons/ai';
import Story from './Story';
import { useAuth } from '../../auth/AuthContext';
import { IoBookOutline } from 'react-icons/io5';
import EditorNavbar from '../EditorNavbar';

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function Stories() {

  const [currentStory, setCurrentStory] = useState();
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const newNameRef = useRef();

  const {userConfig} = useAuth();

  async function newStory(){
    if(newNameRef.current.value){
      const docRef = await addDoc(collection(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`), {
        name: newNameRef.current.value,
        states: [{
          text: 'sample text',
          direction: 0
        }]
      });

      const docSnap = await getDoc(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"));
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.ids.push(docRef.id)
        data.names.push(newNameRef.current.value);
        data.states.push(0);
        data.levels.push(0);
        data.timeStamps.push(0);
        await setDoc(doc(getFirestore(), "stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`, "index"), {
          ids: data.ids,
          names: data.names, 
          states: data.states,
          vocab: data.vocab,
          levels: data.levels,
          timeStamps: data.timeStamps
        });

        newNameRef.current.value = "";

        setCurrentStory({
          name: newNameRef.current.value,
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  useEffect(() => {
    onSnapshot(doc(getFirestore(),"stories","lang",`${userConfig.origin_lang}-${userConfig.target_lang}`,"index"), (doc) =>{
        let tempStories = [];
        let data = doc.data();

        if(!data.hasOwnProperty("ids")){
          setLoading(false);
          return;
        }
        
        for(let i = 0; i < data.ids.length; i++){
          tempStories.push({"id": data.ids[i],"level": data.levels[i], "name": data.names[i], "le": i, "state": data.states[i]});
        };
        if(data.lastChange && data.lastChange < tempStories.length){
          setCurrentStory(tempStories[data.lastChange]);
        }else{
          setCurrentStory(tempStories[0]);
        }

        tempStories.sort((a,b) => {
          if(a.level > b.level){
            return 1;
          }else if(a.level === b.level){
            return 0;
          }else{
            return -1;
          }
        })
        
        setStories(tempStories);
        setLoading(false);
    });
  }, [userConfig]);

  return (
    <div className="flex flex-row w-full flex-1 overflow-hidden dark:bg-slate-900 text-slate-500 dark:text-slate-200">
      {!loading && 
        <Sidebar>
          <div className="flex flex-row  h-16 bg-sky-300 border-slate-200 items-center dark:border-slate-700">
            <IoBookOutline className="cursor-pointer h-6 w-6 text-sky-700 m-4"/>  
            <p className="text-sky-700 font-bold">Stories</p>
          </div>
          <EditorNavbar/>
          
          {stories.map((temp) => (
            <div key={temp.id} onClick={() => {setCurrentStory(temp)}}>
              <SidebarElement name={temp.name} current={currentStory.name} level={Math.round(temp.level)} state={temp.state}></SidebarElement>
            </div>
          ))}
          <div className="flex flex-row bg-green-100 dark:bg-green-900">
          <input type="text" placeholder="name" className="no-outline flex-1 min-w-0 p-4 bg-green-100 dark:bg-green-900 dark:text-slate-100" ref={newNameRef} />
            <AiOutlinePlus onClick={newStory} className="cursor-pointer h-6 w-6 text-green-500 m-4 dark:text-green-200"/>  
          </div>         
        </Sidebar>
        }
        {!loading &&
          <Story story={currentStory} />}
    </div>
  );
}
