import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePlus } from 'react-icons/ai'
import WordItem from './WordItem';
import { SearchForWord } from "../../grammar";

/**
 * returns component for documents
 * @return {object} jsx documents component
 */
export default function ArrayEditor({allVocab, arr, onChange}) {

  const [list, setList] = useState(arr)
  const newItem = useRef();

  useEffect(() => {
    if(arr)
      setList(arr);
  },[arr])

  function delItem(index){
    let n = [...list];
    n.splice(index,1)
    setList(n);
    onChange(n);
  }

  function add(){
    let n = [newItem.current.value,...list]
    newItem.current.value = "";
    setList(n);
    onChange(n);
  }

  function isInList(w){
    let sw = SearchForWord(w)[0].RA;
    if(allVocab.has(sw)){
      return "";
    }else{
      return "slate";
    }
  }

  return (
    <div className="flex flex-col mt-2">
      <div className="flex flex-col flex-1 overflow-y-auto max-h-52">
        {list.map((item,index) => (
          <div key={index} className="flex flex-row rounded mb-1 items-center">
            <WordItem c={`${isInList(item)}`} w={item} deletable={true} onDelete={() => {delItem(index);}}></WordItem>
          </div>
        ))}
      </div>
      <div className="flex flex-row rounded border p-1 m-1 bg-slate-100 items-center">
        <input type="Text" ref={newItem} className="flex-1 no-outline bg-slate-100"></input>
        <AiOutlinePlus className="cursor-pointer h-4 w-4 mr-2 " onClick={() => {add();}}></AiOutlinePlus>
      </div>
    </div>
  );
}

ArrayEditor.propTypes = {
  arr: PropTypes.array,
  onChange: PropTypes.func
 };
